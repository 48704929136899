import { EventEmitter } from '@angular/core'
import { CreditTransactionType } from './credit-layer.model'
import { CreditTriggers } from './credit-trigger.model'

export interface CreditStructureEntity {
  creditStructure?: CreditStructure
  creditCalculationStructure?: CreditCalculationStructure
}

/** Used to bridge our SAGE db with `locktonre-sage-crd-api` */
export interface CreditStructure {
  id?: number
  program_id: number
  label: string
  description: string
  credit_calculation_structure_id: string
  status?: string | null // TODO: update to enum / type when statuses are finalized
  checked?: boolean
  imageSrc?: string
}

export interface CreditStructureMetadata {
  saving?: boolean
  isDirty?: boolean
}

/** Contains one to many `CreditTransaction` layers */
export interface CreditCalculationStructure {
  _id?: string
  name: string
  metadata: Record<string, string>
  portfolio_id: string
  transactions: CreditTransaction[]
  ceded_loss_scenarios: CreditCededLossScenario[]
  modified_date: string
  selected_scenario: string
  selected_default_scenario: string
  collateral_percent?: number
}

/** Also known as a `Layer` - contains one to many `Credit Tranche` sub layers */
export interface CreditTransaction {
  _id: string // optional for database, but required for tower UI
  name: string
  metadata: Record<string, string>
  transaction_type: CreditTransactionType
  ceded_loss_scenarios: CreditCededLossScenario[]
  effective_date: string
  maturity_date: string
  call_date: string
  call_type_selector: CallTypeSelector
  saved_pricing_curve_id?: number
  limit_stepdown_type: string
  triggers: CreditTriggers
  clean_up_call?: number
  lockout_period: string
  tranches: CreditTranche[]
  annual_lr_cap?: number
  aggregate_lr_cap?: number
  profit_commission?: number
  ceding_commission?: number
  wacl?: number
  wahc?: number
  modified_date: string
  cession: number
}

export const TRANSACTION_TEMP_PREFIX = 'TRANSACTION'

/** A sub layer of a `Credit Transaction` */
export interface CreditTranche {
  _id?: string
  name: string
  metadata: Record<string, string>
  cession: number
  attach: number
  detach: number
  rol: number
  wacl: number
  wahc: number
  aggregate_limit: number
  ceded_loss_scenarios: CreditCededLossScenario[]
  pricing_curve_toggle: boolean
  modified_date: string
  // optimization properties
  min_cession?: number
  max_cession?: number
  cession_increment?: number
  min_attach?: number
  max_attach?: number
  attach_increment?: number
  min_detach?: number
  max_detach?: number
  detach_increment?: number
  // submission properties
  reinsurer_participation_percent?: number
}

export interface CreditCededLossScenario {
  _id: string
  name: string
  metadata: Record<string, string>
  scenario: string
  model: string
  gross_loss_scenario_id: string
  ceded_loss_scenario_data: string
  return_period: string
  modified_date: string
}

export interface CreditStructureOptimizationInput {
  programId: number
  structure: CreditCalculationStructure
}

export enum StepDownTriggerTest {
  MINIMUM_CE = 'Minimum CE',
  CUMULATIVE_NET_LOSS = 'Cumulative Net Loss',
  DELINQUENCY = 'Delinquency',
  PMIERS_CAPITAL = 'PMIERS Capital',
}

export type CallTypeSelector = 'economic' | 'earliest'

export const redrawGraph = new EventEmitter<void>()

const TEMPLATE_CESSION = 0.25
const TEMPLATE_ATTACH_XL = 0.025
const TEMPLATE_DETACH_XL = 0.05
const TEMPLATE_ATTACH_QS = 0
const TEMPLATE_DETACH_QS = 1
const TEMPLATE_ATTACH_ILN = 0.025
const TEMPLATE_DETACH_ILN = 0.05

const TRANCHE_BASE_XL: Partial<CreditTranche> = {
  cession: TEMPLATE_CESSION,
  attach: TEMPLATE_ATTACH_XL,
  detach: TEMPLATE_DETACH_XL,
}
const TRANCHE_BASE_QS: Partial<CreditTranche> = {
  cession: TEMPLATE_CESSION,
  attach: TEMPLATE_ATTACH_QS,
  detach: TEMPLATE_DETACH_QS,
}
const TRANCHE_BASE_ILN: Partial<CreditTranche> = {
  cession: TEMPLATE_CESSION,
  attach: TEMPLATE_ATTACH_ILN,
  detach: TEMPLATE_DETACH_ILN,
}
export const TRANCHE_BASE_MAPPING: Record<
  CreditTransactionType,
  Partial<CreditTranche>
> = {
  pmi_agg_xl: TRANCHE_BASE_XL,
  pmi_qs: TRANCHE_BASE_QS,
  pmi_iln: TRANCHE_BASE_ILN,
  srt_agg_xl: TRANCHE_BASE_XL,
}

export enum CreditTrancheDefaultColor {
  pmi_agg_xl = '#00aeef',
  pmi_qs = '#f68a33',
  pmi_iln = '#01c96d',
  srt_agg_xl = '#00aeef',
}

export const TRANSACTION_BASE_QS: Partial<CreditTransaction> = {
  cession: TEMPLATE_CESSION,
}

export const NEW_STRUCTURE_LABEL = 'New Structure'
export const NEW_TRANSACTION_LABEL = 'New Transaction'
export const NEW_TRANCHE_LABEL = 'New Tranche'
export const NEW_OPTIMIZATION_STRUCTURE_LABEL = 'New Optimization'
