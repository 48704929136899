import { QuoteReinsurer } from '../quote/models/quote.model'
import { LAYER_PALETTE } from 'src/app/analysis/model/layer-palette.model'

export interface SignatureContract {
  id?: number
  pageSetName: string
  contractName: string
  programId: number | null
  reinsurers: SignatureReinsurer[]
  companyAlias?: string
}
export interface SignatureReinsurer {
  id?: number
  contractName: string
  reinsurerName: string
  referenceType?: SignatureReinsurerReferenceType
  aiinReferenceNumber?: string | null
  feinReferenceNumber?: string | null
  naicReferenceNumber?: string | null
  effectiveDate?: string
  riskRef?: string
  isFinalVersion?: boolean
  programId: number | null
  fotsReinsured: ReinsuredFot[]
  cedents: Cedent[]
  companyAlias?: string
  tpRef?: string
  sequenceNumber?: number
}

export interface ReinsuredFot {
  id?: number
  programGroupId: number | null /* null for structures and shared limits */
  programGroupName: string | null /* null for structures and shared limits */
  structureId: number | null /* null for groups and shared limits */
  structureName: string | null /* null for groups and shared limits */
  sharedLimitId: number | null /* null for structures and groups */
  sharedLimitName: string | null /* null for structures and groups */
  layerRef: string
  layerName: string | null
  layerType: SignatureLayerType
  layerCurrency: string
  reinsurerPhaseVersion: string
  reinsurerPhaseLabel?: string
  subjectivities: string
  occurrence: number
  percentage: number
  brokerageType: SignatureBrokerageType
  brokeragePercent?: number
  reinstatementPercent?: number
  reinsuranceBrokeragePayable?: string
  riskReinsurerId: number | null
  fotPositionInContract: number
}

export type SignatureBrokerageType = 'gross' | 'net'
export type SignatureLayerType = 'Excess of Loss' | 'Quota Share'
export type SignatureLayerLimitType =
  | 'Occurrence Limit'
  | 'Risk Limit'
  | 'Aggregate Limit'
export type SignatureCurrencyType = 'USD' | 'EUR' | 'GBP'
export interface SignatureLayerMapping {
  id: string
  layerType: SignatureLayerType
  layerLimitType: SignatureLayerLimitType
}

export const SignatureLayerTypes: SignatureLayerMapping[] = LAYER_PALETTE.map(
  l => {
    return {
      id: l.id,
      layerType:
        l.id === 'cat_qs' || l.id === 'noncat_qs' || l.id === 'ahl_qs'
          ? 'Quota Share'
          : 'Excess of Loss',
      layerLimitType:
        l.id === 'cat_ag' || l.id === 'noncat_ag' || l.id === 'ahl_ag'
          ? 'Aggregate Limit'
          : l.id === 'noncat_risk'
          ? 'Risk Limit'
          : 'Occurrence Limit',
    }
  }
)

export type SignatureReinsurerReferenceType = 'AIIN' | 'FEIN' | 'NAIC'
export interface SignatureReinsurerReferenceNumbers {
  reinsurer?: string
  aiinReferenceNumber?: string | null
  feinReferenceNumber?: string | null
  naicReferenceNumber?: string | null
}

export interface Cedent {
  id?: number
  cedentName: string
  cedentLocation: string
  tpRef: string
}

export type QuoteWithLayerDetails = QuoteReinsurer & LayerDetails
export interface LayerDetails {
  layerRef: string
  layerName: string
  layerType: string
}
export interface GroupProps {
  programGroupId: number
  programGroupName: string
}
export interface StructureProps {
  structureId: number
  structureName: string
}

export interface SharedLimitProps {
  sharedLimitId: number
  sharedLimitName: string
  layerNames: Record<string, string>
}

export enum SignatureViewType {
  SELECTOR = 'Selector',
  CONTENT = 'Content',
}
export enum SignatureReferenceType {
  AIIN = 'AIIN',
  FEIN = 'FEIN',
  NAIC = 'NAIC',
}
/* This method checks for conditions and resolves the reference type when creating the signature contracts.
  The default reference type is AIIN and it follows the order AIIN -> NAIC -> FEIN.
*/
export function getReferenceTypeOrNumber(
  aiinReferenceNumber: string | null | undefined,
  naicReferenceNumber: string | null | undefined,
  feinReferenceNumber: string | null | undefined
): SignatureReferenceType {
  let result: SignatureReferenceType = SignatureReferenceType.AIIN
  if (aiinReferenceNumber && aiinReferenceNumber !== '0') {
    result = SignatureReferenceType.AIIN
  } else if (naicReferenceNumber && naicReferenceNumber !== '0') {
    result = SignatureReferenceType.NAIC
  } else if (feinReferenceNumber && feinReferenceNumber !== '0') {
    result = SignatureReferenceType.FEIN
  }
  return result
}
