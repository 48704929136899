import { CdkDragDrop } from '@angular/cdk/drag-drop'
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import {
  PricingCurveContextTypes,
  PricingCurveStatus,
  SavedPricingCurveEntry,
} from 'src/app/pricingcurve/model/pricing-curve.model'
import * as fromActions from '../../../store/pricing-curve.actions'
import { MatButtonToggleChange } from '@angular/material/button-toggle'
import { PricingCurveDialogService } from '../../dialog/pricing-curve-dialog.service'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-sidebar',
  styleUrls: ['pricing-curve-sidebar.component.scss'],
  templateUrl: 'pricing-curve-sidebar.component.html',
})
export class PricingCurveSidebarComponent implements AfterViewInit {
  @Input() open: boolean
  @Input() status: PricingCurveStatus
  @Input() context: PricingCurveContextTypes
  @Input() set addedCurves(val: PricingCurve[]) {
    this._addedCurves = val
    if (this.context === 'credit') {
      this.displayedCurves = this.savedCurves.filter(
        curve => !val.map(c => c.id).includes(curve.id)
      )
    }
    if (this.context === 'credit') {
      this.displayedCurves = this.savedCurves.filter(
        curve => !this.addedCurves.map(c => c.id).includes(curve.id)
      )
    }
    // Set displayed curves with active sort
    if (this.activeFilters.length) {
      this.applyFilters().then(result => {
        this.displayedCurves = result
        this.applyQuickSort(undefined, this.activeSortId)
      })
    } else {
      this.applyQuickSort(undefined, this.activeSortId)
    }
  }
  get addedCurves(): PricingCurve[] {
    return this._addedCurves
  }
  _addedCurves: PricingCurve[]
  @Input() set savedCurves(curves: SavedPricingCurveEntry[]) {
    this._savedCurves = curves

    if (this.context === 'credit') {
      this.displayedCurves = this.savedCurves.filter(
        curve => !this.addedCurves.map(c => c.id).includes(curve.id)
      )
    }
    // Set displayed curves with active sort
    if (this.activeFilters.length) {
      this.applyFilters().then(result => {
        this.displayedCurves = result
        this.applyQuickSort(undefined, this.activeSortId)
      })
    } else {
      if (this.context === 'pricing-curve') {
        this.displayedCurves = curves
      }
      this.applyQuickSort(undefined, this.activeSortId)
    }
  }
  get savedCurves(): SavedPricingCurveEntry[] {
    return this._savedCurves
  }
  _savedCurves: SavedPricingCurveEntry[]
  @Input() username: string | null

  displayedCurves: SavedPricingCurveEntry[] = []
  dragTolerance = 400
  activeSortId = 'date-desc'
  activeFilters: string[] = []

  displayLegend = false
  legendMouseEvent: MouseEvent | undefined

  tooltipMouseEvent: MouseEvent | undefined
  tooltipSavedCurve: SavedPricingCurveEntry | undefined

  constructor(
    private store: Store<AppState>,
    private dialogService: PricingCurveDialogService
  ) {}

  @ViewChild('controlsRef')
  self: ElementRef<HTMLDivElement>

  ngAfterViewInit(): void {
    this.dragTolerance = this.self.nativeElement.clientWidth
  }

  drop(event: CdkDragDrop<string[]>): void {
    const { item, distance } = event
    const id = Number(item.element.nativeElement.id)
    if (
      (id >= 0 || this.context === 'credit') &&
      distance.x > this.dragTolerance
    ) {
      this.store.dispatch(fromActions.loadSavedPricingCurve({ id }))
    }
  }

  applySortEvent($event: MatButtonToggleChange): void {
    this.activeFilters = $event.value
    this.applyFilters().then(result => {
      this.displayedCurves = result
      this.applyQuickSort(undefined, this.activeSortId)
    })
  }

  applyQuickSort($event?: MatButtonToggleChange, id?: string): void {
    const sortId = $event?.value || id
    if (sortId) {
      const pairing = sortId.split('-')
      switch (pairing[0]) {
        case 'date': {
          this.displayedCurves = this.sortCurvesByDate(
            this.displayedCurves,
            pairing[1]
          )
          break
        }
        case 'name': {
          this.displayedCurves = this.sortCurvesByName(
            this.displayedCurves,
            pairing[1]
          )
          break
        }
      }
      this.activeSortId = sortId
    }
  }

  applyFilters(): Promise<SavedPricingCurveEntry[]> {
    return new Promise(resolve => {
      let remainingValues = [...this.savedCurves]
      this.activeFilters.forEach(filter => {
        switch (filter) {
          case 'user': {
            if (this.username) {
              const username = this.username.toLocaleLowerCase()
              remainingValues = remainingValues.filter(curve => {
                const createdBy =
                  curve.audit_created_by.toLocaleLowerCase() === username
                const lastUpdatedBy =
                  curve.audit_updated_by.toLocaleLowerCase() === username

                return createdBy || lastUpdatedBy
              })
            }
            break
          }
          case 'manual': {
            remainingValues = remainingValues.filter(
              curve => !curve.included_excluded_layers?.length
            )
            break
          }
          case 'layer': {
            remainingValues = remainingValues.filter(
              curve => !!curve.included_excluded_layers?.length
            )
            break
          }
        }
      })
      resolve(remainingValues)
    })
  }

  sortCurvesByDate(
    curves: SavedPricingCurveEntry[],
    direction: 'asc' | 'desc'
  ): SavedPricingCurveEntry[] {
    return [...curves].sort(
      (a: SavedPricingCurveEntry, b: SavedPricingCurveEntry) => {
        const aDate = new Date(a.audit_update_dt).getTime()
        const bDate = new Date(b.audit_update_dt).getTime()
        if (direction === 'asc') {
          return aDate - bDate
        } else {
          return bDate - aDate
        }
      }
    )
  }

  sortCurvesByName(
    curves: SavedPricingCurveEntry[],
    direction: 'asc' | 'desc'
  ): SavedPricingCurveEntry[] {
    return [...curves].sort((a, b) =>
      direction === 'asc'
        ? a.pc_name.localeCompare(b.pc_name)
        : b.pc_name.localeCompare(a.pc_name)
    )
  }

  openAddDataDialog(tab: 'layers' | 'manual'): void {
    this.store.dispatch(
      fromActions.addDataDialogOpened({ context: this.context })
    )
    const dialogRef = this.dialogService.openAddDataDialog(undefined, {
      isEdit: false,
      tabsToHide: {
        layers: false,
        manual: false,
        saved: false,
      },
      startingTab: tab,
    })
    dialogRef.afterClosed().subscribe(() => {
      // Dispatch data dialog closed to reset state
      this.store.dispatch(fromActions.addDataDialogClosed({}))
    })
  }

  trackByID(index: number, entity: SavedPricingCurveEntry): number {
    return entity && entity.id ? entity.id : index
  }
}
