import {inject, Injectable} from '@angular/core'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { AppState } from 'src/app/core/store'
import { select, Store } from '@ngrx/store'
import * as fromVolatilityMetricsActions from './volatility-metrics.actions'
import { concatMap, map, withLatestFrom } from 'rxjs/operators'
import { concatMapWithInput, rejectErrorWithInput } from 'src/app/api/util'
import { of } from 'rxjs'
import { selectCompareMetricSettingsEntities } from '../analysis.selectors'
import { fetchPortfolioViewDetailMetrics } from './portfolio-detail-metrics.actions'

@Injectable()
export class VolatilityMetricsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
  ) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVolatilityMetricsActions.fetchVolatilityMetrics),
      map(({ type, ...props }) => props),
      concatMap(props => {
        return of(props).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCompareMetricSettingsEntities))
          ),
          map(([_, settings]) => ({
            ...props,
            settings: settings
              .filter(
                setting =>
                  setting.category === 'Volatility Metrics' && setting.year
              )
              .sort((a, b) => (a.saveID >= b.saveID ? 1 : -1)),
          }))
        )
      }),
      concatMapWithInput(
        ({
          cededPortfolioViewID,
          grossPortfolioViewID,
          netPortfolioViewID,
          settings,
        }) => {
          const year1 =
            settings.length > 0 && settings[0].year
              ? parseInt(settings[0].year, 10)
              : 1
          const year2 =
            settings.length > 0 && settings[1].year
              ? parseInt(settings[1].year, 10)
              : 1
          return this.service.getVolatilityMetrics(
            cededPortfolioViewID,
            grossPortfolioViewID,
            netPortfolioViewID,
            year1,
            year2
          )
        }
      ),
      rejectErrorWithInput((error, props) => {
        this.store.dispatch(
          fromVolatilityMetricsActions.fetchVolatilityMetricsFailure({
            error,
            ...props,
          })
        )
        this.store.dispatch(
          fetchPortfolioViewDetailMetrics({
            ...props,
          })
        )
      }
      ),
      concatMap(([results, props]) => {
        const actions = []
        actions.push(
          fromVolatilityMetricsActions.fetchVolatilityMetricsSuccess({
            ...results,
            ...props,
          }),
          fetchPortfolioViewDetailMetrics({
            ...props,
          })
        )
        return actions
      })
    )
  })
}
