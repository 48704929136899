import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { SwiperConfigInterface } from 'ngx-swiper-wrapper-v-13'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import getQuotePanelDefs, {
  AssignedLinesColumnDef,
  assignedLinesDef,
  QuotePanelDefResolved,
  SubjectivityColumnDef,
  subjectivityDef,
  SubjectivityTrackingColumnDef,
  subjectivityTrackingDef,
} from '../quote-panel/quote-panel-defs'
import { LossSetLayer, MonetaryUnit } from '../../api/analyzere/analyzere.model'
import { Layer } from '../../analysis/model/layers.model'
import {
  AssignedLines,
  createAlID,
  createQuoteSubjectivityID,
  EXPIRING_REINSURER_NAME,
  FormattedSubjectivityRow,
  FOT_MASTER_REINSURER_NAME,
  QuickQuoteUpdates,
  QUOTE_TEMP_PREFIX,
  QuoteCustomCompareView,
  QuoteExport,
  QuoteFields,
  QuoteReinsurer,
  ReinsurerPhase,
  ReinsurerPhases,
  ReinsurerSubjectivity,
  Section,
  SlidingScale,
  Subjectivity,
  SUBJECTIVITY_TEMP_PREFIX,
  SubjectivityOptions,
  SubjectivityTracking,
} from '../models/quote.model'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { clone, equals, groupBy } from 'ramda'
import { SectionState } from '../store/section/section.reducer'
import { StudyReinsurersState } from '../../reinsurers/store/study-reinsurers.reducer'
import {
  filterTowerLayerResults,
  isLayerActualTopAndDrop,
} from '../../analysis/model/layers.util'
import { LayerView } from '../../analysis/model/layer-view'
import { Bureaus, ExternalVendorMapping } from '../../api/model/quote.model'
import { Territories } from '../../api/territory/territory.service'
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service'
import { AccountOpportunity } from '../../api/model/backend.model'
import { Study } from '../../core/model/study.model'
import {
  AGENCY_MARKET_USE,
  FUND_MANAGER_MARKET_USE,
  FUND_MANAGER_SEG_ACCOUNT_TEXT,
  FUND_MANAGER_UNKNOWN_TEXT,
  ProgramFactor,
  Reinsurer,
} from '../../core/model/reinsurer.model'
import { layerIds } from '../../analysis/model/layer-palette.model'
import { getAssignedLineBureausStamp } from '../quote.util'
import { QuoteExportConverter } from '../export/quote-export.converter'
import { UtilService } from 'src/app/util.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-content',
  styleUrls: ['./quote-content.component.scss'],
  templateUrl: './quote-content.component.html',
})
export class QuoteContentComponent implements OnInit, OnChanges {
  name: 'Quote'
  isNew: boolean
  rows: Subjectivity[] = []
  subjectivityOptions: string[]
  updatedReinsurerList: QuoteReinsurer[][]
  isFOTMasterCreated = false
  allReinsurers: QuoteReinsurer[]
  reinsurerListFormatted: ReinsurerState[] = []
  formattedSubjectivityArray: FormattedSubjectivityRow[] = []
  selectedQuoteReinsurer: QuoteReinsurer | undefined
  quotePanelDefs: QuotePanelDefResolved[] = []
  selectedSection: Section | undefined
  firstReinsurerName: string
  loadingLayers = true
  view: LayerView | null
  allViews: LayerView[] | undefined
  currentReinsurer: QuoteReinsurer | undefined
  reinsurerNames: string[] = []
  assignedLineRows: AssignedLines[] = []
  isLayerSelected = false
  rowsTracking: SubjectivityTracking[] = []
  updatedStructureRiskSubjectivityRows: ReinsurerSubjectivity[]
  selectedReinsurerMarketTpRef: string
  @Input() structureRiskSubjectivityRows: ReinsurerSubjectivity[]
  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() showSubjectivity = false
  @Input() quoteDirty: boolean
  @Input() externalVendor: ExternalVendorMapping
  @Input() showAssignedLines = false
  @Input() showTrackingModule = false
  @Input() showCustomCompareBuilder = false
  @Input() showCustomCompare = false
  @Input() reinsurerList: ReinsurerState[]
  @Input() layer: LayerState | null | undefined
  @Input() layerState: LayerState[] = []
  @Input() layerGroup: LayerState | null | undefined
  @Input() layerStateGroup: LayerState[] = []
  @Input() selectedReinsurer: string | null
  @Input() sectionID: string | null
  @Input() structureID: string | null
  @Input() isExpanded: boolean
  @Input() expandedName: string | null
  @Input() isSaving: boolean
  @Input() selectedCededLayerID: string | null | undefined
  @Input() sectionList: SectionState[]
  @Input() reinsurersNameList: StudyReinsurersState[]
  @Input() studyID: string
  @Input() selectedClientID?: string | null
  @Input() selectedYearID?: string | null
  @Input() programs: Study[]
  @Input() selectedProgramID?: string | null
  @Input() selectedStructureID?: string | null
  @Input() selectedStructureGroupID?: string | null
  @Input() isGroupSelected = false
  @Input() exportClicked = false
  @Input() exportCustomCompareClicked = false
  @Input() saveSubClicked = false
  @Input() saveAssignedLinesClicked = false
  @Input() showToggle = false
  @Input() showQuickQuote = false
  @Input() saveQuickQuoteClicked = false
  @Input() bureaus: Bureaus[] = []
  @Input() assignedLineReName: string | null
  @Input() assignedLineTpRef: string | null
  @Input() assignedLineReId?: number
  @Input() isLoading: boolean
  @Input() isAutoBuildLoading: boolean
  @Input() selectedSharedLimitID?: string | null
  @Input() isSLSelected = false
  @Input() lossSetLayers: LossSetLayer[]
  @Input() layersSL: Layer[] | null
  @Input() territories: Territories
  @Input() selectedField: string
  @Input() addOrUpdateData: QuickQuoteUpdates[]
  @Input() hasWhitespaceAccess: boolean | null
  @Input() autoFill: boolean
  @Input() allReinsurersList: Reinsurer[]
  @Input() customCompareViews: QuoteCustomCompareView[]
  @Input() currentCompareView: QuoteCustomCompareView | null
  @Output() addOrUpdateVersion = new EventEmitter<{
    reinsurerId?: string
    layer: Layer
    phase: ReinsurerPhase
    version: string
    label?: string
    reinsurerName: string
    sectionID: string
    subjectivity: ReinsurerSubjectivity[]
    assignedLines: AssignedLines[]
    programGroupID?: string
    sharedLimitID?: string
    previousSubjectPremium?: MonetaryUnit
    qqField?: string
    qqValue?: number
    qqLayerType?: string
    isPreferred?: boolean
    accountOpp: AccountOpportunity
    layerstate?: LayerState[]
    isSection?: boolean
    previousRe?: QuoteReinsurer
    section?: Section
    tpRef?: string
    reType?: string
    sequenceNumber?: number
    isQQSave?: boolean
  }>()
  @Output() reinsurerResize = new EventEmitter<{
    field: Partial<QuoteFields>
    id: string
    isSection: boolean
    isQQSave?: boolean
  }>()
  @Output() nameSelection = new EventEmitter<{
    reinsurerName: string
    id: string
    tpRef: string
    isSection?: boolean
  }>()
  @Output() saveClick = new EventEmitter<{
    reinsurer: QuoteReinsurer
    isGroupSelected?: boolean
    isSLSelected?: boolean
  }>()
  @Output() deleteClick = new EventEmitter<{ reinsurer: QuoteReinsurer }>()
  @Output() declineClick = new EventEmitter<{ reinsurer: QuoteReinsurer }>()
  @Output() preferredClick = new EventEmitter<{ reinsurer: QuoteReinsurer }>()
  @Output() exportToggleClick = new EventEmitter<{
    reinsurer: QuoteReinsurer
  }>()
  @Output() selectedID = new EventEmitter<string>()
  @Output() saveSubjectivities = new EventEmitter<{
    reinsurer: QuoteReinsurer
  }>()
  @Output() populateSubjectivities = new EventEmitter<{
    rows: Subjectivity[]
    id: string
  }>()
  @Output() addNewSubjectivity = new EventEmitter<{
    sub: Subjectivity
    id: string
  }>()
  @Output() updateSubjectivity = new EventEmitter<{
    reinsurerID: string
    subjectivityRows: Subjectivity[]
    save?: boolean
    delete?: boolean
  }>()
  @Output() deleteSubjectivity = new EventEmitter<{
    reinsurerID: string
    id: string
  }>()

  @Output() addNewAssignedLines = new EventEmitter<{
    al: AssignedLines
    id: string
  }>()
  @Output() updateAssignedLines = new EventEmitter<{
    reinsurerID: string
    assignedLinesRows: AssignedLines[]
  }>()
  @Output() updateOffMarketLines = new EventEmitter<ReinsurerState>()
  @Output() populateAssignedLinesFromWhiteSpace = new EventEmitter<{
    reinsurerID: string
  }>()
  @Output() deleteAssignedLines = new EventEmitter<{ id: string }>()

  @Output() expandClick = new EventEmitter<{ isOpen: boolean; name: string }>()
  @Output() populateClick = new EventEmitter<{
    from: string
    to: string
    isSectionLayer?: boolean
  }>()
  @Output() slidingValueChange = new EventEmitter<{
    id: string
    slidingTable: SlidingScale[]
  }>()
  @Output() deleteReinstatement = new EventEmitter<{ id: number }>()
  @Output() exportToggleSectionClick = new EventEmitter<{
    sections: Section[]
  }>()
  @Output() showSubjectivityClick = new EventEmitter<{ id: string }>()
  @Output() exportAsExcel = new EventEmitter<QuoteExport>()
  @Output() expandedReinsurer = new EventEmitter<QuoteReinsurer | undefined>()
  @Output() quoteView = new EventEmitter<LayerView | null>()
  @Output() currentReinsurerChange = new EventEmitter<{
    index: number
    currentReinsurer: QuoteReinsurer
  }>()
  @Output() showAssignedLinesClick = new EventEmitter()
  @Output() saveAssignedLines = new EventEmitter<{
    reinsurer: QuoteReinsurer
  }>()
  @Output() updateLabel = new EventEmitter<{
    reinsurerId: string
    label: string
  }>()
  @Output() subAlReNameAndId = new EventEmitter<{
    reName: string
    ralId: number
  }>()
  @Output() backClick = new EventEmitter()
  changeTrigger = true
  @Output() showSegregatedAccountModal = new EventEmitter<{
    programID: string
    fundManager: Reinsurer
  }>()
  @Output() layerViewForLossSets = new EventEmitter<{ layer: LayerView }>()
  @Output() saveQuickQuote = new EventEmitter()
  @Output() updateQuickQuoteField = new EventEmitter<{ field: string }>()
  @Output() addOrUpdateQuickQuote = new EventEmitter<{
    data: QuickQuoteUpdates
  }>()
  @Output() updateAutoFillSelection = new EventEmitter<boolean>()
  @Output() setCompareView = new EventEmitter<{
    view: QuoteCustomCompareView
    save: boolean
  }>()
  @Output() deleteCompareView = new EventEmitter<number>()
  showingScrollMenu = false
  repeat: ReturnType<typeof setInterval>
  reinsurerVersionMap: string[] = []

  config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    keyboard: false,
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
    navigation: true,
    observer: true,
    observeParents: true,
    touchEventsTarget: 'container',
    preventClicks: true,
    simulateTouch: false,
    pagination: false,
    watchOverflow: true,
    touchStartPreventDefault: false,
  }

  get title(): string {
    return 'Quote Sage'
  }

  get subtitle(): string {
    return 'Subtitle for Quote Sage'
  }
  get expiringOpportunity(): AccountOpportunity | undefined {
    const oppMatch = this.accountOpportunities?.find(
      opp => opp.id === this.selectedProgram?.opportunity_id
    )
    return this.accountOpportunities?.find(
      opp => opp.oppId === oppMatch?.opportunityRenewedFrom
    )
  }
  get currentOpportunity(): AccountOpportunity | undefined {
    return this.accountOpportunities?.find(
      opp => opp.id === this.selectedProgram?.opportunity_id
    )
  }
  get selectedProgram(): Study | undefined {
    return this.programs.find(p => p.id === this.selectedProgramID)
  }

  get getFilteredLayerState(): LayerState[] {
    this.loadingLayers = false
    return this.layerState.filter(filterTowerLayerResults)
  }

  get reinsurersNameListUpdated(): Reinsurer[] {
    if (this.reinsurersNameList && this.reinsurersNameList.length > 0) {
      const nameArray = this.reinsurersNameList.filter(
        r => r.programID === this.selectedProgramID
      )[0]
      if (nameArray) {
        return nameArray.reinsurers
          .filter(r => !r.is_default)
          .filter(
            r =>
              r.reinsurerProgramFactor[0].incumbent ||
              r.reinsurerProgramFactor[0].target_market
          )
      } else {
        return []
      }
    } else {
      return []
    }
  }

  get subjectivityColumnDef(): SubjectivityColumnDef[] {
    return subjectivityDef
  }

  get subjectivityTrackingColumnDef(): SubjectivityTrackingColumnDef[] {
    return subjectivityTrackingDef
  }

  get assignedLinesColumnDef(): AssignedLinesColumnDef[] {
    return assignedLinesDef
  }

  get isLoadingQuote(): boolean {
    return this.isLoading || this.isAutoBuildLoading
  }

  constructor(
    private confirmationDialog: ConfirmationDialogService,
    private exportConverter: QuoteExportConverter,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.subjectivityOptions = SubjectivityOptions
    if (this.layer && !this.isGroupSelected && !this.isSLSelected) {
      this.reinsurerListFormatted = this.reinsurerList.filter(
        r => r.reinsurer.cededlayerID === this.selectedCededLayerID
      )
      if (
        this.layer.layer.meta_data.sage_layer_type === layerIds.catMultisection
      ) {
        this.getUpdatedReinsurersForMultiSection()
      }
      this.selectedSection = this.sectionList.find(
        s => s.section.id === this.sectionID
      )?.section
      this.loadingLayers = false
      this.view = new LayerView(this.layerState, this.layer.layer, {
        quoteReinsurer: this.currentReinsurer,
      })
      this.layerViewForLossSets.emit({ layer: this.view })
      // TODO: Add quoteReinsurer to options param
      this.allViews = this.layerState.map(
        ({ layer }) => new LayerView(this.layerState, layer)
      )
      this.quotePanelDefs = getQuotePanelDefs(
        this.view.values,
        false,
        'Both ECO & XPL apply',
        false
      )
      if (this.selectedCededLayerID) {
        this.isLayerSelected = true
      }
    } else if (this.isGroupSelected && this.layerGroup) {
      this.reinsurerListFormatted = this.reinsurerList.filter(
        r =>
          r.reinsurer.programGroupID?.toString() ===
          this.selectedStructureGroupID
      )
      this.selectedSection = this.sectionList.find(
        s => s.section.id === this.sectionID
      )?.section
      this.view = new LayerView(this.layerStateGroup, this.layerGroup.layer, {
        quoteReinsurer: this.currentReinsurer,
      })
      this.layerViewForLossSets.emit({ layer: this.view })
      this.quotePanelDefs = getQuotePanelDefs(
        this.view.values,
        false,
        'Both ECO & XPL apply',
        false
      )
    } else if (this.isSLSelected && this.layersSL && this.layersSL.length > 0) {
      this.reinsurerListFormatted = this.reinsurerList.filter(
        r =>
          r.reinsurer.sharedLimitID?.toString() ===
          this.selectedSharedLimitID?.toString()
      )
      this.selectedSection = this.sectionList.find(
        s => s.section.id === this.sectionID
      )?.section
      this.view = new LayerView([], this.layersSL[0], {
        quoteReinsurer: this.currentReinsurer,
      })
      this.quotePanelDefs = getQuotePanelDefs(
        this.view.values,
        false,
        'Both ECO & XPL apply',
        false
      )
    }
    this.reinsurerNames = this.getReinsurerNames()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.selectedReinsurer) {
        this.selectedReinsurerMarketTpRef = this.findTpRef(
          this.assignedLineReName ??
            this.selectedQuoteReinsurer?.quoteReinsurerName ??
            ''
        )
      }
      if (this.assignedLineReName) {
        this.assignedLineTpRef = this.findTpRef(this.assignedLineReName)
      }
      const subjectivities = this.structureRiskSubjectivityRows
        ? [...this.structureRiskSubjectivityRows]
        : []
      const subjectivityIds = subjectivities.map(sub => sub.riskSubjectivityId)
      this.reinsurerList.forEach(reinsurer => {
        reinsurer.reinsurer.riskSubjectivityLink?.forEach(sub => {
          if (
            (!subjectivityIds.includes(sub.riskSubjectivityId) &&
              sub.riskSubjectivity.applyToStructureForSameMarket) ||
            sub.riskSubjectivity.layerId === this.selectedCededLayerID
          ) {
            subjectivities.push(sub)
          }
          if (subjectivityIds.includes(sub.riskSubjectivityId)) {
            const oldSubIndex = subjectivities.findIndex(
              s => s.riskSubjectivityId === sub.riskSubjectivityId
            )
            const oldSub = subjectivities[oldSubIndex]
            if (JSON.stringify(sub) !== JSON.stringify(oldSub)) {
              subjectivities.splice(oldSubIndex, 1)
              if (
                sub.riskSubjectivity.applyToStructureForSameMarket ||
                sub.riskSubjectivity.layerId === this.selectedCededLayerID
              ) {
                subjectivities.push(sub)
              }
            }
          }
        })
      })
      this.updatedStructureRiskSubjectivityRows = subjectivities
      if (this.layer && !this.isGroupSelected && !this.isSLSelected) {
        this.selectedSection = this.sectionList.find(
          s => s.section.id === this.sectionID
        )?.section
        this.loadingLayers = false
        this.reinsurerListFormatted = this.reinsurerList.filter(
          r => r.reinsurer.cededlayerID === this.selectedCededLayerID
        )
        if (
          this.layer.layer.meta_data.sage_layer_type ===
          layerIds.catMultisection
        ) {
          this.getUpdatedReinsurersForMultiSection()
        }
        if (this.layer.layer.meta_data.sage_layer_type === layerIds.catTd) {
          this.getUpdatedReinsurersForTopAndDrop()
        }
        if (this.isExpanded) {
          if (
            this.layer.layer.meta_data.sage_layer_type ===
              layerIds.catMultisection ||
            this.layer.layer.meta_data.sage_layer_type === layerIds.catTd
          ) {
            this.updatedReinsurerList = this.groupByForExpandedGroupOrSL()
          } else {
            this.updatedReinsurerList = this.groupByForExpanded()
          }
        } else if (this.showCustomCompare) {
          this.updatedReinsurerList = this.groupByForCustomCompare()
        } else {
          this.updatedReinsurerList = this.groupByReinsurerName()
        }
      } else if (this.isGroupSelected && this.layerGroup) {
        this.selectedSection = this.sectionList.find(
          s => s.section.id === this.sectionID
        )?.section
        this.loadingLayers = false
        this.reinsurerListFormatted = this.reinsurerList.filter(
          r =>
            r.reinsurer.programGroupID?.toString() ===
            this.selectedStructureGroupID
        )

        if (this.isExpanded) {
          this.updatedReinsurerList = this.groupByForExpandedGroupOrSL()
        } else {
          this.updatedReinsurerList = this.groupByReinsurerName()
        }
      } else if (
        this.isSLSelected &&
        this.layersSL &&
        this.layersSL.length > 0
      ) {
        this.selectedSection = this.sectionList.find(
          s => s.section.id === this.sectionID
        )?.section
        this.loadingLayers = false
        this.reinsurerListFormatted = this.reinsurerList.filter(
          r =>
            r.reinsurer.sharedLimitID?.toString() ===
            this.selectedSharedLimitID?.toString()
        )
        this.view = new LayerView([], this.layersSL[0], {
          quoteReinsurer: this.currentReinsurer,
        })
        this.layerViewForLossSets.emit({ layer: this.view })
        if (this.isExpanded) {
          this.updatedReinsurerList = this.groupByForExpandedGroupOrSL()
        } else {
          this.updatedReinsurerList = this.groupByReinsurerName()
        }
      }
      if (!this.showCustomCompare) {
        this.setFotPos()
      }
      if (
        this.updatedReinsurerList &&
        this.updatedReinsurerList[0] &&
        this.updatedReinsurerList[0][0]
      ) {
        this.firstReinsurerName = this.updatedReinsurerList[0][0]
          .quoteReinsurerName
          ? this.updatedReinsurerList[0][0].quoteReinsurerName
          : ''
      }

      if (this.reinsurerListFormatted) {
        const reinsurers = this.reinsurerListFormatted.map(r => r.reinsurer)
        this.selectedQuoteReinsurer = reinsurers.find(
          r1 => r1.id === this.selectedReinsurer
        )
        this.rows =
          this.selectedQuoteReinsurer &&
          this.selectedQuoteReinsurer.riskSubjectivityLink
            ? this.selectedQuoteReinsurer.riskSubjectivityLink.map(
                r => r.riskSubjectivity
              )
            : []

        this.assignedLineRows =
          this.selectedQuoteReinsurer &&
          this.selectedQuoteReinsurer.riskAssignedLinesLink
            ? this.selectedQuoteReinsurer.riskAssignedLinesLink.map(ral => {
                return {
                  ...ral,
                  marketTpRef:
                    ral.marketTpRef && ral.marketTpRef !== '0'
                      ? ral.marketTpRef
                      : (this.findTpRef(ral.reinsurer ?? '') ?? '0'),
                }
              })
            : []
        if (this.isGroupSelected || this.isSLSelected) {
          let assignedLinesReinsurerState = this.reinsurerList?.find(
            x => x && x.reinsurer.riskAssignedLinesLink?.length! > 0
          )!
          if (
            assignedLinesReinsurerState &&
            assignedLinesReinsurerState.reinsurer &&
            assignedLinesReinsurerState.reinsurer.riskAssignedLinesLink
          ) {
            this.assignedLineRows =
              assignedLinesReinsurerState.reinsurer.riskAssignedLinesLink!
          }
        }
        if (this.selectedReinsurer === null && this.changeTrigger) {
          const fot = reinsurers.filter(
            r =>
              r.cededlayerID === this.selectedCededLayerID &&
              r.quoteReinsurerName === FOT_MASTER_REINSURER_NAME
          )
          const fotObject: QuoteReinsurer = fot[fot.length - 1]
          if (fotObject !== undefined) {
            this.selectedID.emit(fotObject.id)
            // this.fotChanges = false
          }
          if (
            reinsurers.length > 0 &&
            fot.length === 0 &&
            this.showAssignedLines
          ) {
            this.changeTrigger = false
            this.confirmationDialog
              .open({
                message: 'There is no FOT for this layer.',
                submitLabel: 'Ok',
              })
              .afterClosed()
              .subscribe((confirm: any) => {
                if (confirm) {
                  this.changeTrigger = true
                  this.backClick.emit()
                }
              })
          } else {
          }
        }
        const findFotMaster = reinsurers.find(
          r =>
            r.reinsurerPhase === ReinsurerPhases.FOT &&
            r.quoteReinsurerName === FOT_MASTER_REINSURER_NAME &&
            !r.id?.includes(QUOTE_TEMP_PREFIX)
        )
        if (reinsurers.length > 0 && findFotMaster) {
          this.isFOTMasterCreated = true
        }
        this.allReinsurers = reinsurers
        // create section Subjectivity List
        this.formattedSubjectivityArray = []
        const rowsTrack: SubjectivityTracking[] = []
        this.sectionList.forEach(s => {
          const obj: FormattedSubjectivityRow = {
            id: s.section.id,
          }
          this.reinsurerList
            .map(re => re.reinsurer)
            .forEach(r => {
              if (r.quoteReinsurerName) {
                obj[r.quoteReinsurerName] = r.riskSubjectivityLink?.map(
                  risks => risks.riskSubjectivity
                )
                r.riskSubjectivityLink
                  ?.map(risks => risks.riskSubjectivity)
                  .forEach(rs => {
                    const subTrack: SubjectivityTracking = {
                      id: rs.id?.toString(),
                      reinsurerName: rs.reinsurer
                        ? `${rs.reinsurer}${rs.ralId ? ' (FOT)' : ''}`
                        : r.quoteReinsurerName
                          ? r.quoteReinsurerName
                          : '',
                      layerID:
                        rs.layerId ??
                        this.findLayerNameByCededLayerID(r.cededlayerID ?? ''),
                      type: rs.type,
                      reinsCommentary: rs.reinsCommentary,
                      brokerComments: rs.brokerComments,
                      reinsurerResponse: rs.reinsurerResponse,
                      reinsResponseStatus: rs.reinsResponseStatus,
                      acceptedLineCond: rs.acceptedLineCond,
                      acceptedContractWord: rs.acceptedContractWord,
                      clientAccepted: rs.clientAccepted,
                      reinsurerId: r.id,
                      applyToStructureForSameMarket:
                        rs.applyToStructureForSameMarket,
                      vendorName: rs.vendorName,
                      tpRef: this.findTpRef(rs.reinsurer ?? ''),
                    }
                    const ids = rowsTrack.map(rt => rt.id)
                    if (!ids.includes(subTrack.id)) {
                      rowsTrack.push(subTrack)
                    }
                  })
              }
            })
          this.structureRiskSubjectivityRows
            ?.map(risk => risk.riskSubjectivity)
            .filter(
              sub =>
                sub.applyToStructureForSameMarket ||
                sub.layerId === this.selectedCededLayerID
            )
            .forEach(rs => {
              const subTrack: SubjectivityTracking = {
                id: rs.id?.toString(),
                reinsurerName: `${rs.reinsurer}${rs.ralId ? ' (FOT)' : ''}`,
                layerID: rs.layerId ?? '',
                type: rs.type,
                reinsCommentary: rs.reinsCommentary,
                brokerComments: rs.brokerComments,
                reinsurerResponse: rs.reinsurerResponse,
                reinsResponseStatus: rs.reinsResponseStatus,
                acceptedLineCond: rs.acceptedLineCond,
                acceptedContractWord: rs.acceptedContractWord,
                clientAccepted: rs.clientAccepted,
                reinsurerId: String(rs.riskReinsurerId ?? ''),
                applyToStructureForSameMarket: rs.applyToStructureForSameMarket,
                vendorName: rs.vendorName,
                tpRef: this.findTpRef(rs.reinsurer ?? '') ?? null,
              }
              const ids = rowsTrack.map(rt => rt.id)
              if (!ids.includes(subTrack.id)) {
                rowsTrack.push(subTrack)
              }
            })
          this.formattedSubjectivityArray.push(obj)
        })
        this.rowsTracking = [...new Map(rowsTrack.map(v => [v.id, v])).values()]
        if (this.exportClicked) {
          this.exportClick()
        }
        if (this.exportCustomCompareClicked) {
          this.customCompareExportClick()
        }
        if (this.saveSubClicked) {
          this.saveSubjectivityClick()
        }
        if (this.saveAssignedLinesClicked) {
          this.saveAssignedLinesClick()
        }
        if (this.saveQuickQuoteClicked) {
          this.saveQuickQuoteClick(true)
        }
        this.currentReinsurer = reinsurers.find(
          r => r.id === this.selectedReinsurer
        )
        if (this.layer) {
          this.view = new LayerView(this.layerState, this.layer.layer, {
            quoteReinsurer: this.currentReinsurer,
          })
          // TODO: Add quoteReinsurer to options param
          this.allViews = this.layerState.map(
            ({ layer }) => new LayerView(this.layerState, layer, {})
          )
          this.layerViewForLossSets.emit({ layer: this.view })
        } else if (this.layerGroup) {
          this.view = new LayerView(
            this.layerStateGroup,
            this.layerGroup.layer,
            {
              quoteReinsurer: this.currentReinsurer,
            }
          )
          this.layerViewForLossSets.emit({ layer: this.view })
        }
        if (this.view) {
          this.layerViewForLossSets.emit({ layer: this.view })

          this.quotePanelDefs = getQuotePanelDefs(
            this.view.values,
            false,
            'Both ECO & XPL apply',
            false
          )
          this.quoteView.emit(this.view)
        }
      }
    }
    this.cleanSubs()
    this.isLayerSelected = this.selectedCededLayerID ? true : false
    this.reinsurerNames = this.getReinsurerNames()
  }

  cleanSubs() {
    const FOT: ReinsurerState | undefined = this.reinsurerList.find(
      r =>
        r.reinsurer.quoteReinsurerName === 'FOT MASTER' &&
        r.reinsurer.cededlayerID === this.selectedCededLayerID
    )

    if (FOT) {
      const dirtySubs = FOT.reinsurer.riskSubjectivityLink?.filter(sub =>
        this.subIsDirty(sub.riskSubjectivity)
      )

      const subs = clone(FOT.reinsurer.riskSubjectivityLink)
      if (subs && dirtySubs && dirtySubs.length > 0) {
        for (const rsub of dirtySubs) {
          const index = subs.findIndex(
            r => r.riskSubjectivity.id === rsub.riskSubjectivity.id
          )
          const id = FOT.reinsurer.riskAssignedLinesLink?.filter(
            al =>
              al.reinsurer?.replace(' (FOT)', '') ===
              rsub.riskSubjectivity.reinsurer?.replace(' (FOT)', '')
          )[0].id
          const ralId = id ? Number(id) : 0
          subs[index] = {
            ...rsub,
            riskSubjectivity: {
              ...rsub.riskSubjectivity,
              ralId,
            },
          }
        }
        const cleanSubs =
          subs.filter(sub => this.subIsDirty(sub.riskSubjectivity)).length === 0

        if (cleanSubs) {
          this.updateSubjectivity.emit({
            reinsurerID: FOT.reinsurer.id,
            subjectivityRows: subs.map(sub => sub.riskSubjectivity),
            save: true,
          })
        } else {
          setTimeout(() => this.cleanSubs(), 300)
        }
      }
    }
  }

  subIsDirty(riskSubjectivity: Subjectivity): boolean {
    return !riskSubjectivity.ralId || riskSubjectivity.ralId === 0
  }

  onDeleteSubjectivity($event: { reinsurerID: string; id: string }): void {
    if (
      ($event.id &&
        typeof $event.id === 'string' &&
        $event.id.startsWith(SUBJECTIVITY_TEMP_PREFIX)) ||
      $event.reinsurerID !== this.currentReinsurer?.id
    ) {
      this.deleteSubjectivity.emit($event)
    } else {
      const subjectivityIdToDelete =
        this.currentReinsurer?.riskSubjectivityLink?.find(
          sub => sub.riskSubjectivityId === $event.id
        )
      if (
        subjectivityIdToDelete?.riskReinsurerId &&
        subjectivityIdToDelete.riskSubjectivityId
      ) {
        this.deleteSubjectivity.emit({
          reinsurerID: subjectivityIdToDelete.riskReinsurerId.toString(),
          id: subjectivityIdToDelete.riskSubjectivityId.toString(),
        })
      }
    }
  }

  onUpdateSubjectivity($event: {
    reinsurerID: string
    subjectivityRows: Subjectivity[]
    save?: boolean
    deleteAlSubID?: string
  }): void {
    const { reinsurerID, subjectivityRows, save, deleteAlSubID } = $event
    if (!deleteAlSubID) {
      this.updateSubjectivity.emit({ reinsurerID, subjectivityRows, save })
    } else {
      const re = this.reinsurerList.find(r => r.reinsurer.id === reinsurerID)
      if (re) {
        const rows = re.reinsurer.riskSubjectivityLink
          ?.filter(sub => sub.riskSubjectivityId !== deleteAlSubID)
          .map(s => s.riskSubjectivity)
        if (rows) {
          this.updateSubjectivity.emit({
            reinsurerID,
            subjectivityRows: rows,
            save,
            delete: true,
          })
        }
      }
    }
  }

  onExportToggleSectionClick(
    panelDefToggled: keyof Section | 'all',
    value: boolean
  ): void {
    const sections = this.updateSectionsToggles(panelDefToggled, value)
    this.exportToggleSectionClick.emit({ sections })
  }

  onExportToggleDefaultClick(
    panelDefToggled: keyof Section | 'all' | 'default'
  ): void {
    const sections = this.updateSectionsToggles(panelDefToggled, false)
    this.exportToggleSectionClick.emit({ sections })
  }

  getUpdatedReinsurersForTopAndDrop(): void {
    const virtualLayerId = this.layer?.layer.id ?? ''
    const actualLayerState = this.layerState.find(
      ({ layer }) =>
        isLayerActualTopAndDrop(layer) && layer.id === virtualLayerId
    )
    const refs = actualLayerState?.layer.layerRefs ?? []
    this.reinsurerList.forEach(reinsurerState => {
      const cededLayerId = reinsurerState.reinsurer.cededlayerID as string
      if (refs.includes(cededLayerId)) {
        this.reinsurerListFormatted.push(reinsurerState)
      }
    })
  }

  getUpdatedReinsurersForMultiSection(): void {
    const mainLayer = this.layerState.find(
      ({ layer }) => layer.meta_data.visible_layer_id === this.layer?.layer.id
    )
    const refs = mainLayer?.layer.layerRefs ?? []

    this.reinsurerList.forEach(reinsurerState => {
      const cededLayerId = reinsurerState.reinsurer.cededlayerID as string
      if (refs.includes(cededLayerId)) {
        this.reinsurerListFormatted.push(reinsurerState)
      }
    })
  }

  getGridStyle(re: QuoteReinsurer[]): { 'border-color': string } {
    let found = false
    re.forEach(r => {
      if (r.id === this.selectedReinsurer) {
        found = true
      }
    })
    if (found) {
      return { 'border-color': 'var(--accent)' }
    } else {
      return { 'border-color': 'var(--border)' }
    }
  }

  isUnsavedQuote(): boolean {
    return this.reinsurerList.some(a =>
      a.reinsurer.id?.startsWith(QUOTE_TEMP_PREFIX)
    )
  }

  getOppForProgram(): AccountOpportunity {
    const study = this.programs[0]
    // tslint:disable-next-line: no-non-null-assertion
    return this.accountOpportunities?.find(
      opp => opp.oppId === study.opportunity_id
    )!
  }

  addQuote(): void {
    if (!this.isUnsavedQuote()) {
      if (this.layer && !this.isGroupSelected && !this.isSLSelected) {
        this.addOrUpdateVersion.emit({
          layer: this.layer?.layer,
          phase: ReinsurerPhases.Quote,
          version: '1',
          label: 'Unlabeled 1',
          reinsurerName: '',
          sectionID: this.sectionID ?? '',
          subjectivity: [],
          assignedLines: [],
          isPreferred: true,
          accountOpp: this.getOppForProgram(),
          layerstate: this.layerState,
          section: this.selectedSection,
        })

        if (
          this.layer.layer.meta_data.sage_layer_type ===
          layerIds.catMultisection
        ) {
          const mainLayer = this.layerState.find(
            l => l.layer.meta_data.visible_layer_id === this.layer?.layer.id
          )
          const refs = mainLayer?.layer.layerRefs ?? []
          if (refs.length > 0) {
            this.layerState.forEach(ls => {
              if (refs.includes(ls.layer.id)) {
                const sectionState = this.sectionList.find(
                  s => s.section.layerRef === ls.layer.id
                )
                this.addOrUpdateVersion.emit({
                  layer: ls.layer,
                  phase: ReinsurerPhases.Quote,
                  version: '1',
                  label: 'Unlabeled 1',
                  reinsurerName: '',
                  sectionID: sectionState?.section.id ?? '',
                  subjectivity: [],
                  assignedLines: [],
                  isPreferred: true,
                  accountOpp: this.getOppForProgram(),
                  section: this.selectedSection,
                })
              }
            })
          }
        }

        if (this.layer.layer.meta_data.sage_layer_type === layerIds.catTd) {
          const virtualLayerId = this.layer.layer.id ?? ''
          const actualTopAndDropLayer = this.layerState.find(
            ({ layer }) =>
              isLayerActualTopAndDrop(layer) && layer.id === virtualLayerId
          )
          const refs = actualTopAndDropLayer?.layer?.layerRefs ?? []
          if (refs.length > 0) {
            this.layerState.forEach(ls => {
              if (
                refs.includes(ls.layer.id) &&
                ls.layer.id !== virtualLayerId
              ) {
                const sectionState = this.sectionList.find(
                  s => s.section.layerRef === ls.layer.id
                )
                this.addOrUpdateVersion.emit({
                  layer: ls.layer,
                  phase: ReinsurerPhases.Quote,
                  version: '1',
                  label: 'Unlabeled 1',
                  reinsurerName: '',
                  sectionID: sectionState?.section.id ?? '',
                  subjectivity: [],
                  assignedLines: [],
                  isPreferred: true,
                  accountOpp: this.getOppForProgram(),
                  isSection: true,
                  section: this.selectedSection,
                  layerstate: this.layerState,
                })
              }
            })
          }
        }
      } else if (this.isGroupSelected) {
        const groupLayers = this.sectionList.filter(
          f =>
            f.section.programGroupID?.toString() ===
            this.selectedStructureGroupID
        )
        groupLayers.forEach(gl => {
          const layerFound = this.layerStateGroup.find(
            ls => ls.layer.id === gl.section.layerRef
          )
          if (layerFound && this.selectedStructureGroupID) {
            this.addOrUpdateVersion.emit({
              layer: layerFound.layer,
              phase: ReinsurerPhases.Quote,
              version: '1',
              label: 'Unlabeled 1',
              reinsurerName: '',
              sectionID: gl.section.id,
              subjectivity: [],
              assignedLines: [],
              programGroupID: this.selectedStructureGroupID,
              isPreferred: true,
              accountOpp: this.getOppForProgram(),
              section: this.selectedSection,
            })
          }
        })
      } else if (
        this.isSLSelected &&
        this.layersSL &&
        this.layersSL.length > 0
      ) {
        const slLayers = this.sectionList.filter(
          f =>
            f.section.sharedLimitID?.toString() ===
            this.selectedSharedLimitID?.toString()
        )
        slLayers.forEach(gl => {
          const layerFound = this.layersSL?.find(
            ls => ls.id === gl.section.layerRef
          )
          if (layerFound && this.selectedSharedLimitID) {
            this.addOrUpdateVersion.emit({
              layer: layerFound,
              phase: ReinsurerPhases.Quote,
              version: '1',
              label: 'Unlabeled 1',
              reinsurerName: '',
              sectionID: gl.section.id,
              subjectivity: [],
              assignedLines: [],
              sharedLimitID: this.selectedSharedLimitID,
              isPreferred: true,
              accountOpp: this.getOppForProgram(),
              section: this.selectedSection,
            })
          }
        })
      }
    }
  }

  onAddOrUpdateVersionReinsurer($event: {
    reinsurerId?: string
    version: string
    label?: string
    reinsurerName: string
    phase: ReinsurerPhase
    subjectivity: ReinsurerSubjectivity[]
    assignedLines: AssignedLines[]
    isPreferred?: boolean
  }): void {
    if (this.layer && !this.isGroupSelected && !this.isSLSelected) {
      const reinsurerId = $event.reinsurerId
      const phase = $event.phase
      const version = $event.version
      const label = $event.label || 'Unlabeled 1'
      const isPreferred = $event.isPreferred || false
      /* phase and label are passed in $event props already */
      if (
        this.layer.layer.meta_data.sage_layer_type === layerIds.catMultisection
      ) {
        if (reinsurerId) {
          const reMulti = this.reinsurerListFormatted.filter(
            re =>
              re.reinsurer.reinsurerPhase ===
                this.currentReinsurer?.reinsurerPhase &&
              re.reinsurer.reinsurerPhaseVersion ===
                this.currentReinsurer?.reinsurerPhaseVersion &&
              (re.reinsurer.reType == AGENCY_MARKET_USE
                ? re.reinsurer.tpRef === this.currentReinsurer?.tpRef &&
                  re.reinsurer.sequenceNumber ===
                    this.currentReinsurer?.sequenceNumber
                : re.reinsurer.tpRef === this.currentReinsurer?.tpRef)
          )
          reMulti.forEach(reg => {
            const layerFound = this.layerState.find(
              ls => ls.layer.id === reg.reinsurer.cededlayerID
            )
            if (layerFound) {
              this.addOrUpdateVersion.emit({
                reinsurerId: reg.reinsurer.id,
                layer: layerFound.layer,
                phase,
                version,
                label,
                reinsurerName: $event.reinsurerName,
                sectionID: reg.reinsurer.reinsurerSectionId?.toString() || '',
                subjectivity: $event.subjectivity,
                assignedLines: $event.assignedLines,
                previousSubjectPremium: this.getPreviousSubjectPremium(),
                isPreferred,
                accountOpp: this.getOppForProgram(),
                section: this.selectedSection,
                tpRef: this.findTpRef($event.reinsurerName),
                reType: this.findType($event.reinsurerName),
                sequenceNumber: Number(
                  this.findSeqNumber($event.reinsurerName)
                ),
              })
            }
          })
        } else {
          this.addOrUpdateVersion.emit({
            reinsurerId,
            layer: this.layer?.layer,
            phase,
            version,
            label,
            reinsurerName: $event.reinsurerName,
            sectionID: this.sectionID ? this.sectionID : '',
            subjectivity: $event.subjectivity,
            assignedLines: $event.assignedLines,
            previousSubjectPremium: this.getPreviousSubjectPremium(),
            isPreferred,
            accountOpp: this.getOppForProgram(),
            layerstate: this.layerState,
            section: this.selectedSection,
            tpRef: this.findTpRef($event.reinsurerName),
            reType: this.findType($event.reinsurerName),
            sequenceNumber: Number(this.findSeqNumber($event.reinsurerName)),
          })
          const multiLayer = this.layerState.find(
            l1 => l1.layer.meta_data.visible_layer_id === this.layer?.layer.id
          )
          const refs = multiLayer?.layer.layerRefs
          if (refs && refs?.length > 0) {
            this.layerState.forEach(ls => {
              if (refs.includes(ls.layer.id)) {
                this.addOrUpdateVersion.emit({
                  layer: ls.layer,
                  phase,
                  version,
                  label,
                  reinsurerName: $event.reinsurerName,
                  sectionID: this.sectionID ? this.sectionID : '',
                  subjectivity: $event.subjectivity,
                  assignedLines: $event.assignedLines,
                  previousSubjectPremium: this.getPreviousSubjectPremium(),
                  isPreferred,
                  accountOpp: this.getOppForProgram(),
                  section: this.selectedSection,
                  tpRef: this.findTpRef($event.reinsurerName),
                  reType: this.findType($event.reinsurerName),
                  sequenceNumber: Number(
                    this.findSeqNumber($event.reinsurerName)
                  ),
                })
              }
            })
          }
        }
      } else if (
        this.layer.layer.meta_data.sage_layer_type === layerIds.catTd
      ) {
        if (reinsurerId) {
          const reMulti = this.reinsurerListFormatted.filter(
            re =>
              re.reinsurer.reinsurerPhase ===
                this.currentReinsurer?.reinsurerPhase &&
              re.reinsurer.reinsurerPhaseVersion ===
                this.currentReinsurer?.reinsurerPhaseVersion &&
              (re.reinsurer.reType == AGENCY_MARKET_USE
                ? re.reinsurer.tpRef === this.currentReinsurer?.tpRef &&
                  re.reinsurer.sequenceNumber ===
                    this.currentReinsurer?.sequenceNumber
                : re.reinsurer.tpRef === this.currentReinsurer?.tpRef)
          )
          reMulti.forEach(reg => {
            const layerFound = this.layerState.find(
              ls => ls.layer.id === reg.reinsurer.cededlayerID
            )
            if (layerFound) {
              this.addOrUpdateVersion.emit({
                reinsurerId: reg.reinsurer.id,
                layer: layerFound.layer,
                phase,
                version,
                label,
                reinsurerName: $event.reinsurerName,
                sectionID: reg.reinsurer.reinsurerSectionId?.toString() || '',
                subjectivity: $event.subjectivity,
                assignedLines: $event.assignedLines,
                previousSubjectPremium: this.getPreviousSubjectPremium(),
                isPreferred,
                accountOpp: this.getOppForProgram(),
                isSection:
                  layerFound.layer.meta_data.sage_layer_type === 'drop' ||
                  (layerFound.layer.meta_data.sage_layer_type === 'cat_td' &&
                    layerFound.layer.meta_data.sage_layer_subtype !== 'actual'),
                section: this.selectedSection,
                tpRef: this.findTpRef($event.reinsurerName),
                layerstate: this.layerState,
                reType: this.findType($event.reinsurerName),
                sequenceNumber: Number(
                  this.findSeqNumber($event.reinsurerName)
                ),
              })
            }
          })
        } else {
          this.addOrUpdateVersion.emit({
            reinsurerId,
            layer: this.layer?.layer,
            phase,
            version,
            label,
            reinsurerName: $event.reinsurerName,
            sectionID: this.sectionID ? this.sectionID : '',
            subjectivity: $event.subjectivity,
            assignedLines: $event.assignedLines,
            previousSubjectPremium: this.getPreviousSubjectPremium(),
            isPreferred,
            accountOpp: this.getOppForProgram(),
            layerstate: this.layerState,
            section: this.selectedSection,
            tpRef: this.findTpRef($event.reinsurerName),
            reType: this.findType($event.reinsurerName),
            sequenceNumber: Number(this.findSeqNumber($event.reinsurerName)),
          })
          const refs = this.layer.layer.layerRefs ?? []
          if (refs?.length > 0) {
            this.layerState.forEach(ls => {
              const sectionState = this.sectionList.find(
                s => s.section.layerRef === ls.layer.id
              )
              if (refs.includes(ls.layer.id)) {
                this.addOrUpdateVersion.emit({
                  layer: ls.layer,
                  phase,
                  version,
                  label,
                  reinsurerName: $event.reinsurerName,
                  sectionID: sectionState?.section.id
                    ? sectionState?.section.id
                    : this.sectionID
                      ? this.sectionID
                      : '',
                  subjectivity: $event.subjectivity,
                  assignedLines: $event.assignedLines,
                  previousSubjectPremium: this.getPreviousSubjectPremium(),
                  isPreferred,
                  isSection:
                    ls.layer.meta_data.sage_layer_type === 'drop' ||
                    (ls.layer.meta_data.sage_layer_type === 'cat_td' &&
                      ls.layer.meta_data.sage_layer_subtype !== 'actual'),
                  accountOpp: this.getOppForProgram(),
                  section: this.selectedSection,
                  tpRef: this.findTpRef($event.reinsurerName),
                  layerstate: this.layerState,
                  reType: this.findType($event.reinsurerName),
                  sequenceNumber: Number(
                    this.findSeqNumber($event.reinsurerName)
                  ),
                })
              }
            })
          }
        }
      } else {
        this.addOrUpdateVersion.emit({
          reinsurerId,
          layer: this.layer?.layer,
          phase,
          version,
          label,
          reinsurerName: $event.reinsurerName,
          sectionID: this.sectionID ? this.sectionID : '',
          subjectivity: $event.subjectivity,
          assignedLines: $event.assignedLines,
          previousSubjectPremium: this.getPreviousSubjectPremium(),
          isPreferred,
          accountOpp: this.getOppForProgram(),
          layerstate: this.layerState,
          section: this.selectedSection,
          tpRef: this.findTpRef($event.reinsurerName),
          reType: this.findType($event.reinsurerName),
          sequenceNumber: Number(this.findSeqNumber($event.reinsurerName)),
        })
      }
    } else if (this.isGroupSelected) {
      const reinsurerId = $event.reinsurerId
      const phase = $event.phase
      const version = $event.version
      const label = $event.label || 'Unlabeled 1'
      const isPreferred = $event.isPreferred || false
      /* phase and label are passed in $event props already */
      if (reinsurerId) {
        const reGroups = this.reinsurerListFormatted.filter(
          re =>
            re.reinsurer.programGroupID ===
              this.currentReinsurer?.programGroupID &&
            re.reinsurer.reinsurerPhase ===
              this.currentReinsurer?.reinsurerPhase &&
            re.reinsurer.reinsurerPhaseVersion ===
              this.currentReinsurer?.reinsurerPhaseVersion &&
            (re.reinsurer.reType == AGENCY_MARKET_USE
              ? re.reinsurer.tpRef === this.currentReinsurer?.tpRef &&
                re.reinsurer.sequenceNumber ===
                  this.currentReinsurer?.sequenceNumber
              : re.reinsurer.tpRef === this.currentReinsurer?.tpRef)
        )
        reGroups.forEach(reg => {
          const layerFound = this.layerStateGroup.find(
            ls => ls.layer.id === reg.reinsurer.cededlayerID
          )
          if (layerFound && this.selectedStructureGroupID) {
            this.addOrUpdateVersion.emit({
              reinsurerId: reg.reinsurer.id,
              layer: layerFound.layer,
              phase,
              version,
              label,
              reinsurerName: $event.reinsurerName,
              sectionID: reg.reinsurer.reinsurerSectionId?.toString() || '',
              subjectivity: $event.subjectivity,
              assignedLines: $event.assignedLines,
              programGroupID: this.selectedStructureGroupID,
              isPreferred,
              accountOpp: this.getOppForProgram(),
              section: this.selectedSection,
              tpRef: this.findTpRef($event.reinsurerName),
              reType: this.findType($event.reinsurerName),
              sequenceNumber: Number(this.findSeqNumber($event.reinsurerName)),
            })
          }
        })
      } else {
        const groupLayers = this.sectionList.filter(
          f =>
            f.section.programGroupID?.toString() ===
            this.selectedStructureGroupID
        )
        groupLayers.forEach(gl => {
          const layerFound = this.layerStateGroup.find(
            ls => ls.layer.id === gl.section.layerRef
          )
          if (layerFound && this.selectedStructureGroupID) {
            this.addOrUpdateVersion.emit({
              layer: layerFound.layer,
              phase,
              version,
              label,
              reinsurerName: $event.reinsurerName,
              sectionID: gl.section.id,
              subjectivity: [],
              assignedLines: [],
              programGroupID: this.selectedStructureGroupID,
              isPreferred,
              accountOpp: this.getOppForProgram(),
              section: this.selectedSection,
              tpRef: this.findTpRef($event.reinsurerName),
              reType: this.findType($event.reinsurerName),
              sequenceNumber: Number(this.findSeqNumber($event.reinsurerName)),
            })
          }
        })
      }
    } else if (this.isSLSelected) {
      const reinsurerId = $event.reinsurerId
      const phase = $event.phase
      const version = $event.version
      const label = $event.label || 'Unlabeled 1'
      const isPreferred = $event.isPreferred || false
      /* phase and label are passed in $event props already */
      if (reinsurerId) {
        const reSLs = this.reinsurerListFormatted.filter(
          re =>
            re.reinsurer.sharedLimitID ===
              this.currentReinsurer?.sharedLimitID &&
            re.reinsurer.reinsurerPhase ===
              this.currentReinsurer?.reinsurerPhase &&
            re.reinsurer.reinsurerPhaseVersion ===
              this.currentReinsurer?.reinsurerPhaseVersion &&
            (re.reinsurer.reType == AGENCY_MARKET_USE
              ? re.reinsurer.tpRef === this.currentReinsurer?.tpRef &&
                re.reinsurer.sequenceNumber ===
                  this.currentReinsurer?.sequenceNumber
              : re.reinsurer.tpRef === this.currentReinsurer?.tpRef)
        )
        reSLs.forEach(reg => {
          const layerFound = this.layersSL?.find(
            ls => ls.id === reg.reinsurer.cededlayerID
          )
          if (layerFound && this.selectedSharedLimitID) {
            this.addOrUpdateVersion.emit({
              reinsurerId: reg.reinsurer.id,
              layer: layerFound,
              phase,
              version,
              label,
              reinsurerName: $event.reinsurerName,
              sectionID: reg.reinsurer.reinsurerSectionId?.toString() || '',
              subjectivity: $event.subjectivity,
              assignedLines: $event.assignedLines,
              sharedLimitID: this.selectedSharedLimitID,
              isPreferred,
              accountOpp: this.getOppForProgram(),
              section: this.selectedSection,
              tpRef: this.findTpRef($event.reinsurerName),
              reType: this.findType($event.reinsurerName),
              sequenceNumber: Number(this.findSeqNumber($event.reinsurerName)),
            })
          }
        })
      } else {
        const slLayers = this.sectionList.filter(
          f =>
            f.section.sharedLimitID?.toString() ===
            this.selectedSharedLimitID?.toString()
        )
        slLayers.forEach(gl => {
          const layerFound = this.layersSL?.find(
            ls => ls.id === gl.section.layerRef
          )
          if (layerFound && this.selectedSharedLimitID) {
            this.addOrUpdateVersion.emit({
              layer: layerFound,
              phase,
              version,
              label,
              reinsurerName: $event.reinsurerName,
              sectionID: gl.section.id,
              subjectivity: [],
              assignedLines: [],
              sharedLimitID: this.selectedSharedLimitID,
              isPreferred,
              accountOpp: this.getOppForProgram(),
              section: this.selectedSection,
              tpRef: this.findTpRef($event.reinsurerName),
              reType: this.findType($event.reinsurerName),
              sequenceNumber: Number(this.findSeqNumber($event.reinsurerName)),
            })
          }
        })
      }
    }
  }

  getReGroups(reinsurer: QuoteReinsurer): ReinsurerState[] {
    if (this.isGroupSelected) {
      return this.reinsurerListFormatted.filter(
        re =>
          re.reinsurer.programGroupID === reinsurer.programGroupID &&
          re.reinsurer.reinsurerPhase === reinsurer.reinsurerPhase &&
          re.reinsurer.reinsurerPhaseVersion ===
            reinsurer.reinsurerPhaseVersion &&
          (re.reinsurer.reType == AGENCY_MARKET_USE
            ? re.reinsurer.tpRef === this.currentReinsurer?.tpRef &&
              re.reinsurer.sequenceNumber ===
                this.currentReinsurer?.sequenceNumber
            : re.reinsurer.tpRef === this.currentReinsurer?.tpRef)
      )
    } else if (this.isSLSelected) {
      return this.reinsurerListFormatted.filter(
        re =>
          re.reinsurer.sharedLimitID === reinsurer.sharedLimitID &&
          re.reinsurer.reinsurerPhase === reinsurer.reinsurerPhase &&
          re.reinsurer.reinsurerPhaseVersion ===
            reinsurer.reinsurerPhaseVersion &&
          (re.reinsurer.reType == AGENCY_MARKET_USE
            ? re.reinsurer.tpRef === this.currentReinsurer?.tpRef &&
              re.reinsurer.sequenceNumber ===
                this.currentReinsurer?.sequenceNumber
            : re.reinsurer.tpRef === this.currentReinsurer?.tpRef)
      )
    } else {
      return this.reinsurerListFormatted.filter(
        re =>
          re.reinsurer.reinsurerPhase === reinsurer.reinsurerPhase &&
          re.reinsurer.reinsurerPhaseVersion ===
            reinsurer.reinsurerPhaseVersion &&
          (re.reinsurer.reType == AGENCY_MARKET_USE
            ? re.reinsurer.tpRef === this.currentReinsurer?.tpRef &&
              re.reinsurer.sequenceNumber ===
                this.currentReinsurer?.sequenceNumber
            : re.reinsurer.tpRef === this.currentReinsurer?.tpRef)
      )
    }
  }

  isUpdateSingleLayer(): boolean {
    return (
      !this.isGroupSelected &&
      !this.isSLSelected &&
      this.layer?.layer.meta_data.sage_layer_type !==
        layerIds.catMultisection &&
      this.layer?.layer.meta_data.sage_layer_type !== layerIds.catTd
    )
  }

  onNameSelection($event: {
    reinsurerName: string
    id: string
    tpRef: string
  }): void {
    if (this.isUpdateSingleLayer()) {
      this.nameSelection.emit($event)
    } else {
      const selectedRe = this.reinsurerListFormatted.find(
        rel => rel.reinsurer.id === $event.id
      )?.reinsurer
      if (selectedRe) {
        const reGroups = this.getReGroups(selectedRe)
        if (reGroups && reGroups.length > 0) {
          reGroups.forEach(reg => {
            const data = {
              reinsurerName: $event.reinsurerName,
              id: reg.reinsurer.id as string,
              tpRef: $event.tpRef,
            }
            if (data.id) {
              this.nameSelection.emit(data)
            }
          })
        }
      }
    }
  }

  onExportToggleClick($event: { reinsurer: QuoteReinsurer }): void {
    if (this.isUpdateSingleLayer()) {
      this.exportToggleClick.emit($event)
    } else {
      const reGroups = this.getReGroups($event.reinsurer)
      if (reGroups && reGroups.length > 0) {
        reGroups.forEach(reg => {
          const data = {
            reinsurer: reg.reinsurer,
          }
          this.exportToggleClick.emit(data)
        })
      }
    }
  }

  onDeclineClick($event: { reinsurer: QuoteReinsurer }): void {
    if (this.isUpdateSingleLayer()) {
      this.declineClick.emit($event)
    } else {
      const reGroups = this.getReGroups($event.reinsurer)
      if (reGroups && reGroups.length > 0) {
        reGroups.forEach(reg => {
          const data = {
            reinsurer: reg.reinsurer,
          }
          this.declineClick.emit(data)
        })
      }
    }
  }

  onPreferredClick($event: {
    reinsurer: QuoteReinsurer
    isChange: boolean
  }): void {
    if (this.isUpdateSingleLayer()) {
      let arr: QuoteReinsurer[] | undefined
      if (this.isExpanded) {
        arr = this.updatedReinsurerList
          .filter(re =>
            re[0].reType == AGENCY_MARKET_USE
              ? re[0].tpRef === $event.reinsurer.tpRef &&
                re[0].sequenceNumber === $event.reinsurer.sequenceNumber
              : re[0].tpRef === $event.reinsurer.tpRef
          )
          .flat()
      } else if (this.showCustomCompare && this.currentCompareView) {
        // Use all reinsurers as preferred status needs to change for versions not included in compare view
        const allReinsurers = this.reinsurerListFormatted.map(r => r.reinsurer)
        arr = Object.values(
          // tslint:disable-next-line: no-non-null-assertion
          groupBy(r => r.quoteReinsurerName!, allReinsurers)
        ).find(reList => reList.map(re => re.id).includes($event.reinsurer.id))
      } else {
        arr = this.updatedReinsurerList.find(re =>
          re[0].reType == AGENCY_MARKET_USE
            ? re[0].tpRef === $event.reinsurer.tpRef &&
              re[0].sequenceNumber === $event.reinsurer.sequenceNumber
            : re[0].tpRef === $event.reinsurer.tpRef
        )
      }
      if (
        arr &&
        (arr.length > 1 || this.isExpanded || this.showCustomCompare) &&
        $event.isChange
      ) {
        // If isPreferred is True, set isPreferred false to all other version
        if ($event.reinsurer.isPreferred) {
          arr.forEach(a => {
            if (a.id !== $event.reinsurer.id) {
              const obj: QuoteReinsurer = {
                ...a,
                isPreferred: false,
              }
              this.preferredClick.emit({ reinsurer: obj })
            }
          })
        }
        // Else set isPreferred true to the first quote created
        else {
          arr.forEach((a, i) => {
            if (a.id !== $event.reinsurer.id) {
              const obj: QuoteReinsurer = {
                ...a,
                isPreferred: false,
              }
              if (i === 0) {
                obj.isPreferred = true
              }
              this.preferredClick.emit({ reinsurer: obj })
            }
          })
        }
      }
      // Finally save actual selection
      this.preferredClick.emit($event)
    } else {
      const reGroups = this.getReGroups($event.reinsurer)
      const arr = this.updatedReinsurerList.find(re =>
        re[0].reType == AGENCY_MARKET_USE
          ? re[0].tpRef === $event.reinsurer.tpRef &&
            re[0].sequenceNumber === $event.reinsurer.sequenceNumber
          : re[0].tpRef === $event.reinsurer.tpRef
      )
      if (reGroups && reGroups.length > 0 && arr) {
        const reGList = reGroups.map(re => re.reinsurer)
        arr.forEach(a => {
          const obj: QuoteReinsurer = {
            ...a,
          }
          if (reGList.includes(a)) {
            obj.isPreferred = true
          } else {
            obj.isPreferred = false
          }
          this.preferredClick.emit({ reinsurer: obj })
        })
      }
    }
  }

  onDeleteClick($event: { reinsurer: QuoteReinsurer }): void {
    if (this.isUpdateSingleLayer()) {
      this.deleteClick.emit($event)
    } else {
      const reGroups = this.getReGroups($event.reinsurer)
      if (reGroups && reGroups.length > 0) {
        reGroups.forEach(reg => {
          const data = {
            reinsurer: reg.reinsurer,
          }
          this.deleteClick.emit(data)
        })
      }
    }
  }

  onUpdateLabel($event: { reinsurerId: string; label: string }): void {
    if (this.isUpdateSingleLayer()) {
      this.updateLabel.emit($event)
    } else {
      const selectedRe = this.reinsurerListFormatted.find(
        rel => rel.reinsurer.id === $event.reinsurerId
      )?.reinsurer
      if (selectedRe) {
        const reGroups = this.getReGroups(selectedRe)
        if (reGroups && reGroups.length > 0) {
          reGroups.forEach(reg => {
            const data = {
              reinsurerId: reg.reinsurer.id as string,
              label: $event.label,
            }
            if (data.reinsurerId) {
              this.updateLabel.emit(data)
            }
          })
        }
      }
    }
  }

  onSaveClick($event: { reinsurer: QuoteReinsurer }): void {
    if (!this.isGroupSelected && !this.isSLSelected) {
      this.saveClick.emit($event)
    } else {
      // This will run only for Groups and Shared Limits.
      // It calls onSaveClick() in quote-content.container.ts but this does not receive any parameters
      // It will execute the same code without any changes many times, we might need to remove this code below.
      const reGroups = this.getReGroups($event.reinsurer)
      if (reGroups && reGroups.length > 0) {
        reGroups.forEach(reg => {
          const data = {
            reinsurer: reg.reinsurer,
            isGroupSelected: this.isGroupSelected,
            isSLSelected: this.isSLSelected,
          }
          this.saveClick.emit(data)
        })
      }
    }
  }

  onPopulateClick($event: { from: string; to: string }): void {
    if (this.isUpdateSingleLayer()) {
      this.populateClick.emit($event)
    } else {
      let fromArr: ReinsurerState[] = []
      let toArr: ReinsurerState[] = []
      const selectedFromRe = this.reinsurerListFormatted.find(
        rel => rel.reinsurer.id === $event.from
      )?.reinsurer
      if (selectedFromRe) {
        fromArr = this.getReGroups(selectedFromRe)
      }
      const selectedToRe = this.reinsurerListFormatted.find(
        rel => rel.reinsurer.id === $event.to
      )?.reinsurer
      if (selectedToRe) {
        toArr = this.getReGroups(selectedToRe)
      }
      if (fromArr.length > 0) {
        fromArr.forEach(f => {
          const re = toArr.find(
            t => f.reinsurer.cededlayerID === t.reinsurer.cededlayerID
          )
          if (re) {
            const data = {
              from: f.reinsurer.id as string,
              to: re?.reinsurer.id as string,
              isSectionLayer: this.layer?.layer.id !== f.reinsurer.cededlayerID,
            }
            if (data.from && data.to) {
              this.populateClick.emit(data)
            }
          }
        })
      }
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.updatedReinsurerList,
      event.previousIndex,
      event.currentIndex
    )
    if (this.showCustomCompare && this.currentCompareView) {
      const newOrder = this.updatedReinsurerList.flatMap(val =>
        Number(val[0].id)
      )
      if (!equals(newOrder, this.currentCompareView.members)) {
        this.setCompareView.emit({
          view: {
            ...this.currentCompareView,
            members: newOrder,
            dirty: true,
          },
          save: true,
        })
      }
    }
  }

  groupByReinsurerName(): QuoteReinsurer[][] {
    const allReinsurers = this.reinsurerListFormatted.map(r => r.reinsurer)
    const groupReinsurer = Object.values(
      // tslint:disable-next-line: no-non-null-assertion
      groupBy(r => r.quoteReinsurerName!, allReinsurers)
    )
    let currentExpIdx = -1

    groupReinsurer.forEach(r =>
      r.forEach(u => {
        if (u.quoteReinsurerName === EXPIRING_REINSURER_NAME) {
          currentExpIdx = groupReinsurer.indexOf(r)
        }
      })
    )
    if (currentExpIdx >= 0) {
      moveItemInArray(groupReinsurer, currentExpIdx, 0)
    }
    return groupReinsurer
  }

  groupByForCustomCompare(): QuoteReinsurer[][] {
    if (!this.currentCompareView) {
      return []
    }
    const allReinsurers = this.reinsurerListFormatted.map(r => r.reinsurer)
    const includedReinsurers = allReinsurers.filter(val =>
      this.currentCompareView.members.includes(Number(val.id))
    )
    const members = this.currentCompareView.members
    return includedReinsurers
      .sort(
        (a: QuoteReinsurer, b: QuoteReinsurer) =>
          members.indexOf(Number(a.id)) - members.indexOf(Number(b.id))
      )
      .map(reinsurer => [reinsurer])
  }

  groupByForExpanded(): QuoteReinsurer[][] {
    let allReinsurers = this.reinsurerListFormatted
      .map(r => r.reinsurer)
      .filter(r1 => r1.quoteReinsurerName === this.expandedName)
    const selectedPhase = allReinsurers.find(
      a => a.id === this.selectedReinsurer
    )?.reinsurerPhase
    if (selectedPhase) {
      allReinsurers = allReinsurers.filter(
        r => r.reinsurerPhase === selectedPhase
      )
    }
    return Object.values(groupBy(r => r.id, allReinsurers))
  }

  groupByForExpandedGroupOrSL(): QuoteReinsurer[][] {
    let allReinsurers = this.reinsurerListFormatted
      .map(r => r.reinsurer)
      .filter(r1 => r1.quoteReinsurerName === this.expandedName)
    const selectedRe = allReinsurers.find(a => a.id === this.selectedReinsurer)
    if (selectedRe) {
      allReinsurers = allReinsurers.filter(
        r => r.reinsurerPhase === selectedRe.reinsurerPhase
      )
    }
    return Object.values(
      groupBy(
        r =>
          // tslint:disable-next-line: no-non-null-assertion
          r.quoteReinsurerName! && r.reinsurerPhase && r.reinsurerPhaseVersion,
        allReinsurers
      )
    )
  }

  onSubjectivityALClick($event: { reName: string; ralId: number }): void {
    this.subAlReNameAndId.emit({ reName: $event.reName, ralId: $event.ralId })
    this.showSubjectivityClick.emit()
  }

  onAssignedLinesClick() {
    this.showAssignedLinesClick.emit()
  }

  onAddNewSubjectivity($event: {
    type: string
    reinsurer: string
    layerId: string
  }): void {
    const { type, reinsurer } = $event
    const sub: Subjectivity = {
      id: createQuoteSubjectivityID(),
      type: type,
      reinsCommentary: '',
      brokerComments: '',
      reinsurerResponse: '',
      reinsResponseStatus: '',
      acceptedLineCond: '',
      acceptedContractWord: '',
      clientAccepted: '',
      reinsurer: reinsurer,
      marketTpRef: this.findTpRef(reinsurer),
      deadline: '',
      applyToStructureForSameMarket: true,
      ralId: this.assignedLineReId ? Number(this.assignedLineReId) : undefined,
      vendorName: '',
      layerId: this.selectedCededLayerID,
    }

    if (this.showAssignedLines && this.assignedLineReName) {
      sub.reinsurer = this.assignedLineReName
    }
    // tslint:disable-next-line: no-non-null-assertion
    this.addNewSubjectivity.emit({ sub, id: this.selectedReinsurer! })
  }
  onAddAssignSubject($event: Subjectivity): void {
    const sub: Subjectivity = {
      id: createQuoteSubjectivityID(),
      ralId: $event.ralId,
      type: $event.type,
      reinsCommentary: $event.reinsCommentary,
      brokerComments: $event.brokerComments,
      reinsurerResponse: $event.reinsurerResponse,
      reinsResponseStatus: $event.reinsResponseStatus,
      acceptedLineCond: $event.acceptedLineCond,
      acceptedContractWord: $event.acceptedContractWord,
      clientAccepted: $event.clientAccepted,
      reinsurer: $event.reinsurer,
      deadline: '',
      applyToStructureForSameMarket: $event.applyToStructureForSameMarket,
      vendorName: $event.vendorName,
      layerId: this.selectedCededLayerID,
    }
    // tslint:disable-next-line: no-non-null-assertion
    this.addNewSubjectivity.emit({ sub, id: this.selectedReinsurer! })
  }

  onAddNewAssignedLines(newAssignedLine: AssignedLines): void {
    if (!this.selectedReinsurer) {
      return
    }
    const currentReinsurerBrokerage =
      this.currentReinsurer?.quoteFields?.brokerageCommission
    const currentReinsurerBrokerageRIP =
      this.currentReinsurer?.quoteFields?.brokerageRIPCommission
    const bureaus = getAssignedLineBureausStamp(newAssignedLine, this.bureaus)
    const al: AssignedLines = {
      ...newAssignedLine,
      id: createAlID(),
      bureaus,
      written: newAssignedLine.written ?? 0,
      recommended: newAssignedLine.recommended ?? 0,
      signed: newAssignedLine.signed ?? 0,
      signedOfAuthorized: newAssignedLine.signedOfAuthorized ?? 0,
      brokerage:
        newAssignedLine.brokerage ??
        (currentReinsurerBrokerage !== 0.15 ? currentReinsurerBrokerage : 0.15),
      brokerageRe:
        newAssignedLine.brokerageRe ?? currentReinsurerBrokerageRIP ?? 0,
      contract: '',
      underwriterRef: newAssignedLine.underwriterRef ?? '',
      // use market tpRef on line if it's available; otherwise look it up
      marketTpRef:
        newAssignedLine.marketTpRef && newAssignedLine.marketTpRef !== '0'
          ? newAssignedLine.marketTpRef
          : this.findTpRef(newAssignedLine.reinsurer ?? ''),
    }
    if (this.isGroupSelected || this.isSLSelected) {
      let assignedLinesReinsurerState = this.reinsurerList?.find(
        x => x && x.reinsurer.riskAssignedLinesLink?.length! > 0
      )!
      if (
        assignedLinesReinsurerState &&
        assignedLinesReinsurerState.reinsurer &&
        assignedLinesReinsurerState.reinsurer.riskAssignedLinesLink
      ) {
        this.assignedLineRows =
          assignedLinesReinsurerState.reinsurer.riskAssignedLinesLink!
        this.addNewAssignedLines.emit({
          al,
          id: assignedLinesReinsurerState.reinsurer.id,
        })
      } else {
        this.addNewAssignedLines.emit({ al, id: this.selectedReinsurer })
      }
    } else {
      this.addNewAssignedLines.emit({ al, id: this.selectedReinsurer })
    }
  }

  saveSubjectivityClick(): void {
    if (this.selectedQuoteReinsurer) {
      this.saveSubjectivities.emit({
        reinsurer: {
          ...this.selectedQuoteReinsurer,
          riskSubjectivityLink:
            this.selectedQuoteReinsurer.riskSubjectivityLink?.map(sub => {
              return {
                ...sub,
                riskSubjectivity:
                  this.rows.find(r => r.id === sub.riskSubjectivityId) ??
                  sub.riskSubjectivity,
              }
            }),
        },
      })
    }
  }

  saveAssignedLinesClick(): void {
    if (this.selectedQuoteReinsurer) {
      this.saveAssignedLines.emit({ reinsurer: this.selectedQuoteReinsurer })
    }
  }

  trackByID = (index: number, entity: QuoteReinsurer[]): number => {
    if (
      !this.isExpanded &&
      !this.showCustomCompare &&
      this.reinsurerVersionMap[index]
    ) {
      const reinsurer = this.updatedReinsurerList[index].find(
        re =>
          re.reinsurerPhaseVersion.toString() ===
          this.reinsurerVersionMap[index]
      )
      if (reinsurer) {
        return Number(reinsurer.id)
      }
    }
    return entity && entity.length > 0 ? Number(entity[0].id) : index
  }

  exportClick(): void {
    const exportData = this.exportConverter.export(
      this.sectionList,
      this.layerState,
      this.view,
      this.lossSetLayers,
      this.reinsurerList,
      this.reinsurerListFormatted,
      this.quotePanelDefs,
      this.expiringOpportunity,
      this.currentOpportunity,
      this.isSLSelected,
      this.isGroupSelected,
      this.rowsTracking,
      this.allViews,
      this.subjectivityTrackingColumnDef
    )
    this.exportAsExcel.emit(exportData)
  }

  customCompareExportClick(): void {
    if (!this.currentCompareView) {
      return
    }
    const section = this.sectionList.filter(
      sec => sec.section.id === this.selectedSection.id
    )
    const layerState = this.layerState.filter(
      state => state.layer.id === this.layer.layer.id
    )
    const lossSetLayers = this.lossSetLayers.filter(lossSetLayer =>
      this.layer.layer.lossSetLayers.some(
        layerLossSetLayer => lossSetLayer.id === layerLossSetLayer.id
      )
    )
    const reinsurerList = this.reinsurerList.filter(({ reinsurer }) =>
      this.currentCompareView.members.some(
        member => member === Number(reinsurer.id)
      )
    )
    const exportData = this.exportConverter.export(
      section,
      layerState,
      this.view,
      lossSetLayers,
      reinsurerList,
      this.reinsurerListFormatted,
      this.quotePanelDefs,
      this.expiringOpportunity,
      this.currentOpportunity,
      this.isSLSelected,
      this.isGroupSelected,
      this.rowsTracking,
      this.allViews,
      this.subjectivityTrackingColumnDef
    )
    this.exportAsExcel.emit(exportData)
  }

  getPreviousSubjectPremium(): MonetaryUnit | undefined {
    if (this.updatedReinsurerList?.[0]?.[0]) {
      this.firstReinsurerName =
        this.updatedReinsurerList[0][0].quoteReinsurerName ?? ''
    }
    const reinsurers = this.reinsurerListFormatted.map(r => r.reinsurer)
    this.selectedQuoteReinsurer = reinsurers.find(
      r1 => r1.id === this.selectedReinsurer
    )

    return reinsurers?.find(
      r =>
        r.reinsurerPhase === ReinsurerPhases.Quote &&
        r.reinsurerPhaseVersion ===
          this.currentReinsurer?.reinsurerPhaseVersion &&
        (r.reType == AGENCY_MARKET_USE
          ? r.tpRef === this.currentReinsurer?.tpRef &&
            r.sequenceNumber === this.currentReinsurer?.sequenceNumber
          : r.tpRef === this.currentReinsurer?.tpRef)
    )?.quoteFields?.subjectPremium
  }

  setFotPos(): void {
    let currentFotIdx = -1

    this.updatedReinsurerList.forEach(r =>
      r.forEach(u => {
        if (u.quoteReinsurerName === FOT_MASTER_REINSURER_NAME) {
          currentFotIdx = this.updatedReinsurerList.indexOf(r)
        }
      })
    )

    if (currentFotIdx >= 0) {
      moveItemInArray(
        this.updatedReinsurerList,
        currentFotIdx,
        this.updatedReinsurerList.length - 1
      )
    }
  }

  fotChecker(reinsurer: QuoteReinsurer[]): true | undefined {
    if (
      reinsurer[0].quoteReinsurerName === FOT_MASTER_REINSURER_NAME &&
      !this.showCustomCompare
    ) {
      return true
    }
  }

  sortTerms(defs: QuotePanelDefResolved[]): QuotePanelDefResolved[] {
    return defs
      .sort((a, b) => a.label.localeCompare(b.label))
      .filter(
        def =>
          def.id !== 'totalQuoteExpectedCededLoss' &&
          def.id !== 'totalQuoteExpectedCededPremium' &&
          def.id !== 'quoteCessionPercentage'
      )
  }

  onCurrentReinsurerChange($event: {
    index: number
    currentReinsurer: QuoteReinsurer
  }) {
    if (!this.isExpanded && !this.showCustomCompare) {
      this.reinsurerVersionMap[$event.index] =
        $event.currentReinsurer.reinsurerPhaseVersion.toString()
    }
    this.currentReinsurerChange.emit($event)
  }

  editedSubjectivity($event: { rows: Subjectivity[]; id: string }) {
    if (this.selectedQuoteReinsurer) {
      this.saveSubjectivities.emit({
        reinsurer: {
          ...this.selectedQuoteReinsurer,
          riskSubjectivityLink:
            this.selectedQuoteReinsurer.riskSubjectivityLink?.map(sub => {
              return {
                ...sub,
                riskSubjectivity:
                  $event.rows.find(r => r.id === sub.riskSubjectivityId) ??
                  sub.riskSubjectivity,
              }
            }),
        },
      })
    }
  }

  expandedLayerIndexes: number[] = []
  layerExpanded($event: { arg: number }) {
    const layerIndex = $event.arg
    const layerAlreadyExpanded = this.expandedLayerIndexes.find(
      expandedIndex => expandedIndex === layerIndex
    )
    if (!layerAlreadyExpanded && typeof layerAlreadyExpanded !== 'number') {
      this.expandedLayerIndexes.push(layerIndex)
    }
  }
  layerCollapsed($event: { arg: number }) {
    const layerIndex = $event.arg
    const indexToRemove = this.expandedLayerIndexes.findIndex(
      expandedIndex => expandedIndex === layerIndex
    )
    if (indexToRemove < 0) {
      return
    }
    this.expandedLayerIndexes.splice(indexToRemove, 1)
  }

  toggleScrollMenu(): void {
    this.showingScrollMenu = !this.showingScrollMenu
  }

  startRepeatHorizontal(left: boolean): void {
    this.horizontalSmallScroll(left)
    this.repeat = setInterval(() => {
      this.horizontalSmallScroll(left)
    }, 300)
  }

  endRepeatHorizontal(): void {
    clearInterval(this.repeat)
  }

  horizontalSmallScroll(left: boolean): void {
    const swiperFrame = document.getElementById('swiper-frame')
    if (!swiperFrame) {
      return
    }
    if (left) {
      swiperFrame.scrollLeft -= 150
    } else {
      swiperFrame.scrollLeft += 150
    }
  }

  horizontalEndScroll(left: boolean): void {
    const swiperFrame = document.getElementById('swiper-frame')
    if (!swiperFrame) {
      return
    }
    if (left) {
      swiperFrame.scrollLeft = 0
    } else {
      swiperFrame.scrollLeft = swiperFrame.scrollWidth
    }
  }

  onAddOrUpdateQuickQuote($event: { data: QuickQuoteUpdates }) {
    this.addOrUpdateQuickQuote.emit($event)
  }

  saveQuickQuoteClick(closeQQ: boolean) {
    this.utilService.spinnerCounter = this.addOrUpdateData.length
    // Add or Update each quote in state
    this.addOrUpdateData.forEach((data: QuickQuoteUpdates) => {
      const version = this.getVersion(data.cededLayerId, data.reinsurerName)
      const section = this.sectionList.find(
        s => s.section.layerRef === data.cededLayerId
      )
      const updateLayer = this.layerState.find(
        ls => ls.layer.id === data.cededLayerId
      )
      // Update or Add Quote based on existing reinsurer
      if (data.reinsurerId) {
        const reinsurerBase = this.reinsurerList.find(
          re => re.reinsurer.id === data.reinsurerId
        )
        if (reinsurerBase) {
          if (data.action === 'Update') {
            this.updateReinsurerQQ(data, reinsurerBase)
          }
        }
      } else {
        // Create a Quote based on Layer Properties
        if (updateLayer && data.value != 0) {
          const previousRe = this.getPreviousRe(data.cededLayerId)
          // if updateLayer type is cat_multisection, need special logic to support that
          if (
            updateLayer.layer.meta_data.sage_layer_type ===
            layerIds.catMultisection
          ) {
            const multiLayer = this.layerState.find(l1 => {
              const { layerRefs, meta_data } = l1.layer
              const refsMatch =
                layerRefs && layerRefs.includes(updateLayer.layer.id)
              const idsMatch =
                l1.layer.id === updateLayer.layer.id &&
                meta_data.sage_layer_type === 'cat_multisection' &&
                meta_data.sage_layer_subtype === 'visible-layer'
              return refsMatch || idsMatch
            })
            if (multiLayer) {
              const mainLayer = this.layerState.find(ls => {
                const {
                  sage_layer_type,
                  sage_layer_subtype,
                  visible_layer_id,
                } = ls.layer.meta_data
                return (
                  sage_layer_type === 'cat_multisection' &&
                  sage_layer_subtype === 'main-layer' &&
                  visible_layer_id === multiLayer.layer.id
                )
              })
              const refs = [...(mainLayer?.layer.layerRefs ?? [])]
              refs.push(multiLayer.layer.id)
              if (refs && refs?.length > 0) {
                this.layerState.forEach(ls => {
                  if (refs.includes(ls.layer.id)) {
                    const sectionM = this.sectionList.find(
                      s => s.section.layerRef === ls.layer.id
                    )
                    this.addOrUpdateVersion.emit({
                      layer: ls.layer,
                      phase:
                        data.reinsurerName === FOT_MASTER_REINSURER_NAME
                          ? ReinsurerPhases.FOT
                          : data.reinsurerName === EXPIRING_REINSURER_NAME
                            ? ReinsurerPhases.Expiring
                            : ReinsurerPhases.Quote,
                      version: version.toString(),
                      label: 'Unlabeled' + version.toString(),
                      reinsurerName: data.reinsurerName,
                      sectionID: sectionM?.section.id ?? '',
                      subjectivity: [],
                      assignedLines: [],
                      qqField: data.fieldSelected,
                      qqValue: data.value,
                      qqLayerType: this.getLayerType(updateLayer.layer),
                      isPreferred: true,
                      accountOpp: this.getOppForProgram(),
                      previousRe,
                      section: this.selectedSection,
                      isQQSave: true,
                      tpRef: this.findTpRef(data.reinsurerName),
                      reType: this.findType(data.reinsurerName),
                      sequenceNumber: Number(
                        this.findSeqNumber(data.reinsurerName)
                      ),
                    })
                  }
                })
              }
            }
          } else {
            this.addOrUpdateVersion.emit({
              layer: updateLayer.layer,
              phase:
                data.reinsurerName === FOT_MASTER_REINSURER_NAME
                  ? ReinsurerPhases.FOT
                  : data.reinsurerName === EXPIRING_REINSURER_NAME
                    ? ReinsurerPhases.Expiring
                    : ReinsurerPhases.Quote,
              version: version.toString(),
              label: 'Unlabeled' + version.toString(),
              reinsurerName: data.reinsurerName,
              sectionID: section ? section.section.id : '',
              subjectivity: [],
              assignedLines: [],
              qqField: data.fieldSelected,
              qqValue: data.value,
              qqLayerType: this.getLayerType(updateLayer.layer),
              isPreferred: true,
              accountOpp: this.getOppForProgram(),
              previousRe,
              section: this.selectedSection,
              isQQSave: true,
              tpRef: this.findTpRef(data.reinsurerName),
              reType: this.findType(data.reinsurerName),
              sequenceNumber: Number(this.findSeqNumber(data.reinsurerName)),
            })
          }
        }
      }
    })
    this.saveQuickQuote.emit()
  }

  onSaveQuickQuoteOnChange(): void {
    this.saveQuickQuoteClick(false)
  }

  updateReinsurerQQ(data: QuickQuoteUpdates, reinsurerBase: ReinsurerState) {
    // Get View
    const updateLayer = this.layerState.find(
      ls => ls.layer.id === reinsurerBase?.reinsurer.cededlayerID
    )
    if (updateLayer) {
      const updateView: any = new LayerView(
        this.layerState,
        updateLayer.layer,
        {
          quoteReinsurer: reinsurerBase.reinsurer,
        }
      )
      // Update View and then update state
      updateView[data.fieldSelected] = data.value
      if (updateView && updateView.quoteReinsurer?.quoteFields) {
        this.reinsurerResize.emit({
          field: updateView.quoteReinsurer.quoteFields,
          id: updateView.quoteReinsurer.id,
          isSection:
            updateLayer.layer.meta_data.sage_layer_subtype === 'section-layer',
          isQQSave: true,
        })
      }
    }
  }

  getPreviousRe(cededLayerId: string): QuoteReinsurer | undefined {
    const reArray = this.reinsurerList.filter(
      re => re.reinsurer.cededlayerID === cededLayerId
    )
    if (reArray && reArray.length > 0 && this.autoFill) {
      return reArray[reArray.length - 1].reinsurer
    } else {
      return
    }
  }

  getVersion(cededLayerId: string, reinsurerName: string): number {
    const reArray = this.reinsurerList.filter(
      re =>
        re.reinsurer.cededlayerID === cededLayerId &&
        re.reinsurer.quoteReinsurerName === reinsurerName
    )
    if (reArray && reArray.length > 0) {
      let max = 0
      reArray.forEach(reA => {
        if (Number(reA.reinsurer.reinsurerPhaseVersion) > max) {
          max = Number(reA.reinsurer.reinsurerPhaseVersion)
        }
      })
      return max + 1
    } else {
      return 1
    }
  }

  getLayerType(layer: Layer): string {
    const possibleLayers: string[] = [
      layerIds.ahlAg,
      layerIds.catAg,
      layerIds.noncatAg,
    ]
    if (
      layer.meta_data.sage_layer_type &&
      possibleLayers.includes(layer.meta_data.sage_layer_type)
    ) {
      return 'ag'
    } else {
      return 'occ'
    }
  }

  private getReinsurerNames(): string[] {
    return (this.reinsurersNameListUpdated ?? [])
      .flatMap(reinsurer => this.mapReinsurerToNames(reinsurer))
      .concat(['Unknown'])
      .sort()
  }

  private mapReinsurerToNames(reinsurer: Reinsurer): string[] {
    const programFactor = [...reinsurer.reinsurerProgramFactor].shift()
    return reinsurer.market_use === FUND_MANAGER_MARKET_USE
      ? this.getFundManagerNames(reinsurer, programFactor)
      : [programFactor?.obo_name || reinsurer.name]
  }

  private getFundManagerNames(
    reinsurer: Reinsurer,
    programFactor?: ProgramFactor
  ): string[] {
    const companyPaperNames = (reinsurer.selectedCompanyPapers || []).map(
      companyPaper => companyPaper.agencyName
    )
    const segAccountName = programFactor?.segregated_account
      ? `${reinsurer.name} ${FUND_MANAGER_SEG_ACCOUNT_TEXT}`
      : undefined
    const unknownName = programFactor?.fund_mgr_unknown
      ? `${FUND_MANAGER_UNKNOWN_TEXT} - ${reinsurer.name}`
      : undefined
    return [...companyPaperNames, segAccountName, unknownName].filter(
      name => name !== undefined
    ) as string[]
  }

  private updateSectionsToggles(
    panelDefToggled: keyof Section | 'all' | 'default',
    value: boolean
  ): Section[] {
    const sectionStates = [...this.sectionList]
    return sectionStates.map(s => {
      return this.updateSectionToggles(s.section, panelDefToggled, value)
    })
  }

  private updateSectionToggles(
    section: Section,
    panelDefToggled: keyof Section | 'all' | 'default',
    value: boolean
  ): Section {
    let updates: Partial<Section>
    if (panelDefToggled === 'all') {
      updates = this.updateAllSectionToggles(section, value)
    } else if (panelDefToggled === 'default') {
      updates = this.updateDefaultSectionToggles(section)
    } else {
      updates = this.updateSingleSectionToggle(panelDefToggled, value)
    }
    return {
      ...section,
      ...updates,
    }
  }

  private updateDefaultSectionToggles(section: Section): Partial<Section> {
    let updatedSection: Partial<Section> = {}
    updatedSection = this.getSectionDataByType(section.layerType, this.layer)
    return updatedSection
  }

  private updateAllSectionToggles(
    section: Section,
    value: boolean
  ): Partial<Section> {
    let updatedSection: Partial<Section> = {}
    Object.keys(section).forEach(key => {
      if (key.includes('Toggle')) {
        updatedSection = {
          ...updatedSection,
          [key]: value,
        }
      }
    })
    return updatedSection
  }

  private updateSingleSectionToggle(
    panelDefToggled: keyof Section,
    value: boolean
  ): Partial<Section> {
    return {
      [panelDefToggled]: value,
    }
  }

  private findLayerNameByCededLayerID(cededLayerID: string): string {
    return (
      this.sectionList.find(s => s.section.layerRef === cededLayerID)?.section
        .layerName || ''
    )
  }

  private getSectionDataByType(
    layerType: string,
    layer: LayerState | null | undefined
  ): Partial<Section> {
    let updatedSection: Partial<Section> = {}
    if (layer) {
      updatedSection = {
        occurrenceLimitToggle: true,
        occurrenceAttachmentToggle: false,
        riskLimitToggle: false,
        riskAttachmentToggle: false,
        franchiseDeductibleToggle: false,
        reinstatementsToggle: false,
        aggregateLimitToggle: false,
        aggregateAttachmentToggle: false,
        cedingCommissionToggle: false,
        reinsurerExpenseProvisionToggle: false,
        profitCommissionToggle: false,
        rolPercentageToggle: false,
        rateOnLineSubjectToggle: false,
        pmpmToggle: false,
        premiumToggle: false,
        effectiveDateToggle: false,
        expirationDateToggle: false,
        expiryDateToggle: false,
        payoutToggle: false,
        triggerToggle: false,
        nthToggle: false,
        underwriterToggle: false,
        maolLimitToggle: false,
        terrorismAggSubLimitToggle: false,
        lossRatioCapToggle: false,
        lossRatioCapPercentageToggle: false,
        lossCapApplicationToggle: false,
        limitApplicationToggle: false,
        laeCapToggle: false,
        laeTreatmentToggle: false,
        adjustmentBasisToggle: false,
        ecoCoveragePctToggle: false,
        xplCoveragePctToggle: false,
        subjectPremiumToggle: true,
        minimumPremiumPercentageToggle: false,
        depositPremiumPercentageToggle: false,
        minimumPremiumToggle: false,
        clashPremiumToggle: false,
        profitShareCommissionToggle: false,
        profitShareMinRateToggle: false,
        profitShareMaxRateToggle: false,
        brokerageCommissionToggle: true,
        brokerageTypeToggle: true,
        brokerageRIPCommissionToggle: false,
        orderPercentToggle: false,
        otherFeaturesToggle: false,
        coverageBasisToggle: false,
        indexationtextToggle: false,
        // quoteSignedPercentageToggle: false,
        quoteMinPercentageToggle: false,
        quoteOfferedPercentageToggle: true,
        quoteOfferedLimitToggle: false,
        xplEcoDropdownToggle: false,
        xplEcoConditionsToggle: false,
        quoteDepositPremiumToggle: true,
        quoteMinRateSubjectToggle: false,
        quoteMaxRateSubjectToggle: false,
        quoteMinRatePmpmToggle: false,
        quoteMaxRatePmpmToggle: false,
        quoteSwingRateToggle: false,
        quoteSwingBasisToggle: false,
        quoteIndexationToggle: false,
        quoteFixedIndexValueToggle: false,
        quoteSicOrFranchiseToggle: false,
        quoteExpectedCededLossToggle: true,
        quoteExpectedCededPremiumToggle: true,
        quoteDepositPremiumCalcToggle: true,
        slidingCommToggle: false,
        quoteCessionsBasedPremiumToggle: false,
        structureFXToggle: false,
        premiumFXToggle: false,
        territorialScopeToggle: false,
        vendorToggle: false,
        modelVersionToggle: false,
        feeOrBrokerageToggle: false,
        layerCategoryToggle: false,
        layerClassToggle: false,
        excludeFromPricingCurveToggle: false,
        perilsToggle: false,
        lossImpactedFromPreviousYearToggle: false,
        quoteProbabilityOfAttachToggle: false,
        quoteProbabilityOfExhaustToggle: false,
        quoteTopOccurrenceAttachmentToggle: false,
        quoteTopOccurrenceLimitToggle: false,
        quoteDropOccurrenceAttachmentToggle: false,
        quoteDropOccurrenceLimitToggle: false,
        quoteAggregateLimitTopToggle: false,
        quoteAggregateLimitDropToggle: false,
        cedingCommissionBasisToggle: false,
        feeToggle: false,
        rebateToggle: false,
        quoteIndexToggle: false,
      }
      if (
        layerType === layerIds.catXl ||
        layerType === layerIds.noncatXl ||
        layerType === layerIds.ahlXl ||
        layerType === layerIds.catFhcf
      ) {
        updatedSection = {
          ...updatedSection,
          occurrenceAttachmentToggle: true,
          reinstatementsToggle: true,
          premiumToggle: true,
          minimumPremiumPercentageToggle: true,
          depositPremiumPercentageToggle: true,
          minimumPremiumToggle: true,
          brokerageRIPCommissionToggle: true,
          // quoteSignedPercentageToggle: false,
          slidingCommToggle: false,
        }
      }
      if (
        layerType === layerIds.catQs ||
        layerType === layerIds.noncatQs ||
        layerType === layerIds.ahlQs
      ) {
        updatedSection = {
          ...updatedSection,
          riskLimitToggle: true,
          subjectPremiumToggle: true,
          occurrenceLimitToggle: true,
          cedingCommissionToggle: true,
          lossRatioCapToggle: true,
          lossRatioCapPercentageToggle: true,
          lossCapApplicationToggle: true,
          quoteOfferedPercentageToggle: true,
          limitApplicationToggle: true,
          // quoteSignedPercentageToggle: false,
          brokerageCommissionToggle: true,
          brokerageTypeToggle: true,
          slidingCommToggle: false,
        }
      }
      if (layerType === layerIds.catCa) {
        updatedSection = {
          ...updatedSection,
          occurrenceAttachmentToggle: true,
          franchiseDeductibleToggle: true,
          reinstatementsToggle: true,
          premiumToggle: true,
          minimumPremiumPercentageToggle: true,
          depositPremiumPercentageToggle: true,
          minimumPremiumToggle: true,
          brokerageRIPCommissionToggle: true,
          // quoteSignedPercentageToggle: false,
          slidingCommToggle: false,
        }
      }
      if (
        layerType === layerIds.catAg ||
        layerType === layerIds.noncatAg ||
        layerType === layerIds.ahlAg ||
        layerType === layerIds.catTd ||
        layerType === layerIds.drop
      ) {
        updatedSection = {
          ...updatedSection,
          occurrenceAttachmentToggle: true,
          aggregateLimitToggle: true,
          aggregateAttachmentToggle: true,
          reinstatementsToggle: true,
          premiumToggle: true,
          minimumPremiumPercentageToggle: true,
          depositPremiumPercentageToggle: true,
          minimumPremiumToggle: true,
          brokerageRIPCommissionToggle: true,
          // quoteSignedPercentageToggle: false,
          slidingCommToggle: false,
        }
      }
      if (layerType === layerIds.noncatRisk) {
        updatedSection = {
          ...updatedSection,
          riskLimitToggle: true,
          riskAttachmentToggle: true,
          reinstatementsToggle: true,
          premiumToggle: true,
          minimumPremiumPercentageToggle: true,
          depositPremiumPercentageToggle: true,
          minimumPremiumToggle: true,
          brokerageRIPCommissionToggle: true,
          // quoteSignedPercentageToggle: false,
          slidingCommToggle: false,
        }
      }
      if (
        layerType === layerIds.noncatXl ||
        layerType === layerIds.noncatIndxl ||
        layerType === layerIds.noncatAg ||
        layerType === layerIds.noncatRisk
      ) {
        updatedSection = {
          ...updatedSection,
          rolPercentageToggle: false,
          rateOnLineSubjectToggle: true,
        }
      }
      if (
        layerType === layerIds.ahlAg ||
        layerType === layerIds.ahlXl ||
        layerType === layerIds.ahlQs
      ) {
        updatedSection = {
          ...updatedSection,
          rolPercentageToggle: false,
          rateOnLineSubjectToggle: false,
          pmpmToggle: true,
        }
      }
      if (
        layerType === layerIds.catXl ||
        layerType === layerIds.catCa ||
        layerType === layerIds.catFhcf ||
        layerType === layerIds.catTd ||
        layerType === layerIds.catAg
      ) {
        updatedSection = {
          ...updatedSection,
          rolPercentageToggle: true,
          rateOnLineSubjectToggle: false,
        }
      }
      if (layerType === layerIds.catTd || layerType === layerIds.drop) {
        updatedSection = {
          ...updatedSection,
          quoteTopOccurrenceLimitToggle: true,
          quoteTopOccurrenceAttachmentToggle: true,
          quoteDropOccurrenceLimitToggle: true,
          quoteDropOccurrenceAttachmentToggle: true,
          quoteAggregateLimitTopToggle: true,
          quoteAggregateLimitDropToggle: true,
          quoteOfferedPercentageToggle: true,
          brokerageRIPCommissionToggle: true,
          quoteDepositPremiumToggle: true,
          depositPremiumPercentageToggle: true,
          quoteExpectedCededLossToggle: false,
          quoteExpectedCededPremiumToggle: false,
        }
      }
      if (
        layerType === layerIds.noncatSwing ||
        layerType === layerIds.ahlSwing
      ) {
        updatedSection = {
          ...updatedSection,
          subjectPremiumToggle: true,
          occurrenceLimitToggle: true,
          occurrenceAttachmentToggle: true,
          aggregateLimitToggle: true,
          aggregateAttachmentToggle: true,

          quoteSwingRateToggle: true,
          quoteSwingBasisToggle: true,
          quoteOfferedPercentageToggle: true,
          brokerageCommissionToggle: true,
          brokerageRIPCommissionToggle: true,
        }
        if (layerType === layerIds.noncatSwing) {
          updatedSection = {
            ...updatedSection,
            quoteMinRateSubjectToggle: true,
            quoteMaxRateSubjectToggle: true,
          }
        } else {
          updatedSection = {
            ...updatedSection,
            quoteMinRatePmpmToggle: true,
            quoteMaxRatePmpmToggle: true,
          }
        }
      }
      if (layerType === layerIds.noncatIndxl) {
        updatedSection = {
          ...updatedSection,
          subjectPremiumToggle: true,
          occurrenceLimitToggle: true,
          occurrenceAttachmentToggle: true,
          quoteIndexationToggle: true,
          quoteFixedIndexValueToggle: true,
          quoteSicOrFranchiseToggle: true,
          rateOnLineSubjectToggle: true,
          premiumToggle: true,
          reinstatementsToggle: true,
          minimumPremiumPercentageToggle: true,
          depositPremiumPercentageToggle: true,
          minimumPremiumToggle: true,
          quoteOfferedPercentageToggle: true,
          brokerageCommissionToggle: true,
          brokerageRIPCommissionToggle: true,
        }
      }
      if (
        layerType === layerIds.catMultisection ||
        layerType === layerIds.noncatMultisection
      ) {
        if (
          this.layer?.layer.meta_data.sage_layer_subtype !== 'section-layer'
        ) {
          updatedSection = {
            ...updatedSection,
            occurrenceAttachmentToggle: false,
            rateOnLineSubjectToggle: true,
            premiumToggle: true,
            minimumPremiumPercentageToggle: true,
            minimumPremiumToggle: true,
            reinstatementsToggle: true,
            brokerageCommissionToggle: true,
            brokerageRIPCommissionToggle: true,
            aggregateLimitToggle: true,
            aggregateAttachmentToggle: true,
            rolPercentageToggle: true,
            depositPremiumPercentageToggle: true,
            layerClassToggle: true,
            quoteExpectedCededLossToggle: false,
            quoteExpectedCededPremiumToggle: false,
            quoteOfferedPercentageToggle: false,
          }
        } else {
          updatedSection = {
            ...updatedSection,
            occurrenceAttachmentToggle: true,
            aggregateLimitToggle: true,
            aggregateAttachmentToggle: true,
            subjectPremiumToggle: false,
            quoteDepositPremiumToggle: false,
            quoteExpectedCededLossToggle: false,
            brokerageCommissionToggle: false,
            quoteExpectedCededPremiumToggle: false,
            quoteDepositPremiumCalcToggle: false,
            reinstatementsToggle: false,
            brokerageTypeToggle: false,
            layerClassToggle: false,
          }
        }
      }
      if (layerType === layerIds.ilwBin) {
        updatedSection = {
          ...updatedSection,
          subjectPremiumToggle: false,
          occurrenceLimitToggle: false,
          payoutToggle: true,
          triggerToggle: true,
          nthToggle: true,
          rolPercentageToggle: true,
          premiumToggle: true,
          quoteOfferedPercentageToggle: false,
          brokerageCommissionToggle: true,
          brokerageRIPCommissionToggle: true,
          effectiveDateToggle: true,
          expirationDateToggle: true,
          territorialScopeToggle: true,
          perilsToggle: true,
          quoteDepositPremiumToggle: false,
        }
      }
      if (layerType === layerIds.ilwProRata) {
        updatedSection = {
          ...updatedSection,
          subjectPremiumToggle: false,
          occurrenceLimitToggle: true,
          payoutToggle: true,
          triggerToggle: true,
          rolPercentageToggle: true,
          premiumToggle: true,
          reinstatementsToggle: true,
          aggregateAttachmentToggle: true,
          aggregateLimitToggle: true,
          effectiveDateToggle: true,
          expirationDateToggle: true,
          quoteOfferedPercentageToggle: false,
          brokerageCommissionToggle: true,
          brokerageRIPCommissionToggle: true,
          territorialScopeToggle: true,
          perilsToggle: true,
          quoteDepositPremiumToggle: false,
        }
      }
    }
    return updatedSection
  }
  findTpRef(reinsurerName: string): string {
    const name = reinsurerName ? reinsurerName.replace(' (FOT)', '') : ''
    const reinsurerTpRefMap = new Map<string, string | null>()
    this.allReinsurersList?.forEach(r => {
      const name = r.reinsurerProgramFactor[0].obo_name ?? r.name
      const tpRef = r.tpRef ?? '0'
      if (r.selectedCompanyPapers?.length > 0) {
        r.selectedCompanyPapers.forEach(p => {
          reinsurerTpRefMap.set(p.agencyName, p.agencyTPRef)
        })
      }
      if (r.selectedSegregatedAccounts?.length > 0) {
        r.selectedSegregatedAccounts.forEach(sa => {
          reinsurerTpRefMap.set(sa.agencyName, sa.agencyTPRef)
        })
      }
      reinsurerTpRefMap.set(name, tpRef)
    })
    return reinsurerTpRefMap.get(name) ?? ''
  }

  findType(reinsurerName: string): string {
    const name = reinsurerName ? reinsurerName.replace(' (FOT)', '') : ''
    const reinsurerTypeMap = new Map<string, string | null>()
    this.allReinsurersList?.forEach(r => {
      const name = r.reinsurerProgramFactor[0].obo_name ?? r.name
      const type = r.market_use ?? '0'
      if (r.selectedCompanyPapers?.length > 0) {
        r.selectedCompanyPapers.forEach(p => {
          reinsurerTypeMap.set(p.agencyName, p.type)
        })
      }
      if (r.selectedSegregatedAccounts?.length > 0) {
        r.selectedSegregatedAccounts.forEach(sa => {
          reinsurerTypeMap.set(sa.agencyName, sa.type)
        })
      }
      reinsurerTypeMap.set(name, type)
    })
    return reinsurerTypeMap.get(name) ?? ''
  }

  findSeqNumber(reinsurerName: string): string {
    const name = reinsurerName ? reinsurerName.replace(' (FOT)', '') : ''
    const reinsurerTypeMap = new Map<string, any | null>()
    this.allReinsurersList?.forEach(r => {
      const name = r.reinsurerProgramFactor[0].obo_name ?? r.name
      const type = r.reinsurerProgramFactor[0].relation_seq_number ?? '0'
      if (r.selectedCompanyPapers?.length > 0) {
        r.selectedCompanyPapers.forEach(p => {
          reinsurerTypeMap.set(p.agencyName, p.agencySeqNumber)
        })
      }
      if (r.selectedSegregatedAccounts?.length > 0) {
        r.selectedSegregatedAccounts.forEach(sa => {
          reinsurerTypeMap.set(sa.agencyName, sa.agencySeqNumber)
        })
      }
      reinsurerTypeMap.set(name, type)
    })
    return reinsurerTypeMap.get(name) ?? ''
  }
}
