import {inject, Injectable} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { dissoc } from 'ramda'
import { forkJoin, of, throwError } from 'rxjs'
import {
  concatMap,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { ProgramService } from 'src/app/api/program/program.service'
import * as fromBrokerSelectors from '../../core/store/broker/broker.selectors'
import {
  selectCurrentClient,
  selectCurrentStudyID2,
  selectDesignProgramIDS,
} from '../../core/store/broker/broker.selectors'
import {
  saveTowerPreferences,
  setAllProperties,
  setDirtyProgram,
  updateProgram,
} from '../../core/store/program/program.actions'
import {
  selectCurrentStudyPrograms,
  selectProgramsByID,
} from '../../core/store/program/program.selectors'
import { LogicalPortfolioLayer } from '../../api/analyzere/analyzere.model'
import { AnalyzreService } from '../../api/analyzere/analyzre.service'
import { AnimatedScenariosService } from '../../api/animated-scenarios/animated-scenarios.service'
import { BackendService } from '../../api/backend/backend.service'
import {
  concatMapWithInput,
  rejectError,
  rejectErrorWithInput,
  switchMapWithInput,
} from '../../api/util'
import { AppState } from '../../core/store'
import { selectAuthState } from '../../core/store/auth/auth.selectors'
import {
  setCurrentClient,
  setCurrentStructure,
  setCurrentStudy,
  setCurrentYear,
} from '../../core/store/broker/broker.actions'
import { selectProgramGroupsByID } from '../../core/store/program-group/program-group.selectors'
import { NavService } from '../../nav.service'
import { AddInuranceDialogContainerComponent } from '../layers/add-inurance-dialog/add-inurance-dialog.container'
import { waitFor } from '@shared/observables'
import { convertFromLogicalPortfolioLayers } from '../model/layers.converter'
import { Layer } from '../model/layers.model'
import { extractPortfolioSetID } from '../model/portfolio-set-id.util'
import {
  PortfolioSetID,
  PortfolioSetAndStudyIDs,
} from '../model/portfolio-set.model'
import { toggleLossSetGroupEditor } from './analysis-panels.actions'
import * as fromAnalysisActions from './analysis.actions'
import * as fromAnalysisSelectors from './analysis.selectors'
import {
  selectCurrentProgram,
  selectCurrentStudyID,
} from './analysis.selectors'
import * as fromLayersActions from './ceded-layers/layers.actions'
import { fetchPortfolioFailure } from './ceded-portfolio/portfolio.actions'
import * as fromLossSetLayersActions from './loss-set-layers/loss-set-layers.actions'
import * as fromNetPortfolioActions from './net-portfolio/portfolio.actions'
import { RiskSectionWithMarkets } from 'src/app/api/model/backend.model'
import { ApiResponse } from 'src/app/api/model/api.model'
import { layerIds } from '../model/layer-palette.model'
import { createInuranceTagsAndSymbols } from '../model/inurance-tags-creator'
import { Program } from 'src/app/core/model/program.model'
import { ProgramEntity } from './grouper/program/program.reducer'
import { ProgramGroupEntity } from './grouper/program-group/program-group.reducer'
import { LayerService } from '@shared/services/layer.service'

// tslint:disable: no-non-null-assertion
@Injectable()
export class AnalysisEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
    private backendService: BackendService,
    private programService: ProgramService,
    private nav: NavService,
    private animatedScenariosService: AnimatedScenariosService,
    private dialog: MatDialog,
    private layerService: LayerService
  ) {}

  startAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAnalysisActions.startAnalysis),
      // TODO: Check that token has been loaded (and wait if not loaded)
      delay(50), // Prevents fetching AnalyzeRE before token stored
      withLatestFrom(this.store.pipe(select(selectCurrentStudyPrograms))),
      switchMapWithInput(([action, structures]) => {
        this.store.dispatch(toggleLossSetGroupEditor({ openPanel: false }))
        // If no parentGrossPortfolioID exists, create one
        if (!action.parentGrossPortfolioID) {
          const currentStructure = structures.find(
            s => s.grossPortfolioID === action.grossPortfolioID
          )
          // Clone gross and POST parent gross to AnalyzeRe
          return this.animatedScenariosService
            .updatePortfolio(action.grossPortfolioID, currentStructure!.label)
            .pipe(
              switchMap(res => {
                if (res.error) {
                  return of({ error: res.error })
                }
                // Update our structure in the DB - add parent_gross value
                return this.programService.update(currentStructure!.id, {
                  ...currentStructure,
                  parentGrossPortfolioID: res.data!.id,
                })
              })
            )
        } else {
          return of({ data: undefined })
        }
      }),
      rejectErrorWithInput(error => {
        return this.store.dispatch(
          fromAnalysisActions.updateParentGrossFailure({ error })
        )
      }),
      withLatestFrom(this.store.pipe(select(selectAuthState))),
      switchMap(([[updatedStructure, [action, _]], authState]) => {
        let actions
        if (updatedStructure) {
          actions = [
            fromAnalysisActions.reinitialize({
              ...action,
              parentGrossPortfolioID: updatedStructure.parentGrossPortfolioID,
            }),
            updateProgram({
              programID: updatedStructure.id,
              change: {
                parentGrossPortfolioID: updatedStructure.parentGrossPortfolioID,
              },
            }),
          ]
        } else {
          this.backendService
            .postEvent({
              eventType: 'Portfolio Open',
              eventDetails: {
                user: authState.username,
                carrier: action.clientID,
                year: action.yearID,
                program: action.studyID,
                analysis_profile_id: action.analysisProfileID,
                ceded_portfolio_id: action.cededPortfolioID,
                gross_portfolio_id: action.grossPortfolioID,
                net_portfolio_id: action.netPortfolioID,
              },
            })
            .subscribe(err => throwError(err))
          actions = [
            fromNetPortfolioActions.fetchPortfolio({
              id: action.netPortfolioID,
              analysisStartProps: action
            }),
            fromLossSetLayersActions.fetchLossSetLayersFromGrossPortfolio({
              id: action.grossPortfolioID,
            }),
            fromLossSetLayersActions.fetchLossSetLayersFromParentGrossPortfolio(
              {
                id: action.parentGrossPortfolioID!,
              }
            ),
          ]
        }
        return actions
      })
    )
  )

  reinitialize$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAnalysisActions.reinitialize),
      tap(
        (action: {
          cededPortfolioID: string
          clientID: string
          analysisProfileID: string
          yearID: string
          studyID: string
          structureID: string
        }) => {
          this.store.dispatch(setCurrentClient({ id: action.clientID }))
          this.store.dispatch(setCurrentYear({ id: action.yearID }))
          this.store.dispatch(setCurrentStudy({ id: action.studyID }))
          this.store.dispatch(setCurrentStructure({ id: action.structureID }))
        }
      ),
      withLatestFrom(
        this.store.pipe(
          select(fromAnalysisSelectors.selectEditorPortfolioSetID)
        ),
        this.store.pipe(select(fromAnalysisSelectors.selectCurrentClientID)),
        this.store.pipe(select(fromAnalysisSelectors.selectCurrentStudyID)),
        this.store.pipe(select(fromAnalysisSelectors.selectCurrentYearID)),
        this.store.pipe(select(fromAnalysisSelectors.selectCurrentStructureID))
      ),
      filter(
        ([_, portfolioSetID, clientID, studyID, yearID, structureID]) =>
          portfolioSetID != null &&
          clientID != null &&
          studyID != null &&
          yearID != null &&
          structureID != null
      ),
      map(
        ([_, portfolioSetID, clientID, studyID, yearID, structureID]) =>
          [portfolioSetID!, clientID!, studyID!, yearID!, structureID!] as const
      ),
      map(([portfolioSetID, clientID, studyID, yearID, structureID]) =>
        fromAnalysisActions.startAnalysis({
          ...portfolioSetID!,
          clientID,
          studyID,
          fromSave: false,
          yearID,
          structureID,
        })
      )
    )
  })

  reset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAnalysisActions.resetAnalysis),
      withLatestFrom(
        this.store.pipe(
          select(fromAnalysisSelectors.selectEditorPortfolioSetID)
        ),
        this.store.pipe(select(selectCurrentProgram)),
        this.store.pipe(select(fromAnalysisSelectors.selectLossSetLayersDirty))
      ),
      switchMap(([_, portfolioSetID, currentProgram, lossSetDirty]) => {
        this.store.dispatch(toggleLossSetGroupEditor({ openPanel: false }))

        if (currentProgram) {
          this.store.dispatch(setDirtyProgram(currentProgram))
        }
        // IF Loss sets are dirty, fetch gross loss sets here, then update loss set dirty = false
        if (lossSetDirty) {
          this.store.dispatch(
            fromLossSetLayersActions.fetchLossSetLayersFromGrossPortfolio({
              id: portfolioSetID!.grossPortfolioID,
            })
          )
        }
        return this.service
          .fetchPortfolio(portfolioSetID!.cededPortfolioID)
          .pipe(this.service.appendAggFeederAndRiskVisible())
      }),
      rejectError(error =>
        this.store.dispatch(fetchPortfolioFailure({ error }))
      ),
      map(portfolio => {
        return fromLayersActions.fetchLayersSuccess({
          layers: convertFromLogicalPortfolioLayers(
            portfolio.layers as LogicalPortfolioLayer[]
          ),
        })
      })
    )
  })

  addInuranceTagsByLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAnalysisActions.addInuranceTagsByLevel),
      withLatestFrom(
        this.store.pipe(select(selectCurrentProgram)),
        this.store.pipe(select(fromAnalysisSelectors.selectInuranceSymbolMap)),
        this.store.pipe(select(fromAnalysisSelectors.selectCededLayers))
      ),
      filter(([_, program]) => program !== undefined),
      map(([_, program, symbolMap, cededLayers]) => {
        const structureMap: Map<ProgramEntity, ProgramGroupEntity[]> = new Map<
          ProgramEntity,
          ProgramGroupEntity[]
        >()

        const programEntity: ProgramEntity = {
          program: program as Program,
          cededLayers,
          netLayers: [],
          loading: false,
          error: null,
        }
        structureMap.set(programEntity, [])

        const [tagsByLevel, updatedSymbolMap] = createInuranceTagsAndSymbols(
          structureMap,
          symbolMap
        )

        return fromAnalysisActions.addInuranceTagsByLevelSuccess({
          tagsByLevel,
          symbolMap: updatedSymbolMap,
        })
      })
    )
  )

  uploadStartingCession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAnalysisActions.layerDataUpload),
      withLatestFrom(this.store.pipe(select(selectCurrentProgram))),
      switchMap(([_, currentProgram]) => {
        if (currentProgram) {
          return this.backendService.postLayerData(
            currentProgram.id,
            _.layerData
          )
        } else {
          throw new Error('No program selected.')
        }
      }),
      map(res => {
        if (res.error) {
          return fromAnalysisActions.layerDataUploadFailure({
            error: res.error,
          })
        } else {
          return fromAnalysisActions.layerDataUploadSuccess()
        }
      })
    )
  })

  uploadImage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAnalysisActions.imageUpload),
      withLatestFrom(
        this.store.pipe(
          select(fromAnalysisSelectors.selectCurrentAnalysisProfileID)
        ),
        this.store.pipe(
          select(fromAnalysisSelectors.selectCurrentCededPortfolioID)
        ),
        this.store.pipe(
          select(fromAnalysisSelectors.selectCurrentNetPortfolioID)
        ),
        this.store.pipe(
          select(fromAnalysisSelectors.selectCurrentGrossPortfolioID)
        )
      ),
      switchMap(
        ([
          _,
          analysisProfileID,
          cededPortfolioID,
          netPortfolioID,
          grossPortfolioID,
        ]) => {
          // tslint:disable-next-line: no-non-null-assertion
          const occImageFile = new File(
            [_.imageData.occblob],
            analysisProfileID +
              '-' +
              cededPortfolioID +
              '-' +
              netPortfolioID +
              '-' +
              grossPortfolioID +
              '-' +
              'occurrence',
            {
              type: 'image/png',
            }
          )

          const aggImageFile = new File(
            [_.imageData.aggblob],
            analysisProfileID +
              '-' +
              cededPortfolioID +
              '-' +
              netPortfolioID +
              '-' +
              grossPortfolioID +
              '-' +
              'aggregate',
            {
              type: 'image/png',
            }
          )
          const fd = new FormData(document.forms[0])
          fd.append('occurrence', occImageFile)
          fd.append('aggregate', aggImageFile)
          return this.backendService.postPortfolioThumbnail(fd)
        }
      ),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCurrentStructureID))
      ),
      map(([res, structureID]) => {
        if (res.error) {
          return fromAnalysisActions.imageUploadFailure({ error: res.error })
        } else {
          // tslint:disable-next-line: no-non-null-assertion
          return fromAnalysisActions.imageUploadSuccess({ structureID, res })
        }
      })
    )
  })

  save$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAnalysisActions.saveAnalysis),
      withLatestFrom(
        this.store.pipe(
          select(fromAnalysisSelectors.selectEditorPortfolioSetAndStudyIDs)
        ),
        this.store.pipe(select(fromAnalysisSelectors.selectLossSetLayersDirty)),
        this.store.pipe(select(fromAnalysisSelectors.selectCededLayers))
      ),
      tap(([_, portfolioSetAndStudyIDs, lossSetDirty, cededLayers]) => {
        // check if any ceded loss sets are updated
        const lossSetLayerUpdateList = cededLayers.filter(x => x.layer.meta_data.isLossSetUpdated === true)
        if(lossSetLayerUpdateList.length > 0){
          // For each layer we will update the quote panels
          lossSetLayerUpdateList.forEach(layerState => {
            this.layerService.getQuoteReinsurers(layerState.layer)
          })
        }
        this.store.dispatch(fromLayersActions.saveLayers())
        // Check if lossSetLayers dirty, only update if necessary
        if (lossSetDirty) {
          this.store.dispatch(
            fromAnalysisActions.updateGrossPortfolio({
              grossPortfolioID: portfolioSetAndStudyIDs!.grossPortfolioID,
            })
          )
        }
      }),
      waitFor(this.actions$, [
        [fromLayersActions.saveSuccess, fromLayersActions.saveFailure],
      ]),
      delay(100),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededLayersError)),
        this.store.pipe(select(selectAuthState))
      ),
      filter(
        ([[_, _portfolioSetAndStudyIDs], error, _authState]) => error === null
      ),
      map(([[_, portfolioSetAndStudyIDs], _error, authState]) => {
        if (portfolioSetAndStudyIDs) {
          this.backendService
            .postEvent({
              eventType: 'Portfolio Save',
              eventDetails: {
                user: authState.username,
                carrier: portfolioSetAndStudyIDs.clientID,
                year: portfolioSetAndStudyIDs.yearID,
                program: portfolioSetAndStudyIDs.studyID,
                analysis_profile_id: portfolioSetAndStudyIDs.analysisProfileID,
                ceded_portfolio_id: portfolioSetAndStudyIDs.cededPortfolioID,
                gross_portfolio_id: portfolioSetAndStudyIDs.grossPortfolioID,
                net_portfolio_id: portfolioSetAndStudyIDs.netPortfolioID,
              },
            })
            .subscribe(err => throwError(err))
        }

        // Note: startAnalysis action moved to technical-premium.effects, take no action here
        return { type: 'No Action' }
      })
    )
  })

  saveAs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAnalysisActions.saveAsAnalysis),
        withLatestFrom(
          this.store.pipe(select(selectCurrentProgram)),
          this.store.pipe(select(selectCurrentStudyID))
        ),
        switchMap(([data, program, studyID]) => {
          return this.animatedScenariosService.cloneStructureWithSharedLimit(
            program!.id,
            data.name,
            data.description,
            {
              persistStructure: true,
              handleDirty: true,
              asScenario: data.asScenario,
              studyID: studyID || undefined,
            }
          )
        }),
        withLatestFrom(
          this.store.pipe(
            select(fromAnalysisSelectors.selectEditorPortfolioSetAndStudyIDs)
          ),
          this.store.pipe(select(selectAuthState)),
          this.store.pipe(select(selectCurrentProgram)),
          this.store.pipe(select(selectProgramsByID)),
          this.store.pipe(select(selectProgramGroupsByID))
        ),
        map(([{ error, data }, portfolioIDs, authState, program]) => {
          if (error) {
            return this.store.dispatch(
              fromAnalysisActions.saveAsAnalysisFailure({ error })
            )
          } else {
            this.backendService
              .postEvent({
                eventType: 'Portfolio Save As',
                eventDetails: {
                  user: authState.username,
                  carrier: portfolioIDs!.clientID,
                  year: portfolioIDs!.yearID,
                  program: data!.program.studyID,
                  structure: data!.program.label,
                  analysis_profile_id: data!.program.analysisID,
                  ceded_portfolio_id: data!.program.cededPortfolioID,
                  gross_portfolio_id: data!.program.grossPortfolioID,
                  net_portfolio_id: data!.program.netPortfolioID,
                },
              })
              .subscribe(err => throwError(err))

            this.store.dispatch(
              fromAnalysisActions.saveAsAnalysisSuccess({
                ...portfolioIDs!,
                program: data!.program,
                otherPrograms: data!.otherPrograms,
              })
            )
            this.store.dispatch(
              setCurrentStructure({
                id: data!.program.id,
              })
            )
            this.nav.navigateWithTierPath(
              {
                ...portfolioIDs!,
                ...data!.portfolioIDs,
                structureID: data!.program.id,
              },
              'structure',
              data!.program.id,
              'analysis',
              data!.program.analysisID,
              'portfolios',
              JSON.stringify(
                dissoc('name', {
                  ...portfolioIDs!,
                  ...data!.portfolioIDs,
                  structureID: data!.program.id,
                })
              )
            )
          }
          return { program, data }
        }),
        tap(returnData => {
          if (
            returnData &&
            returnData.program &&
            returnData.program.towerPreferences &&
            returnData.data &&
            returnData.program.towerPreferences.aggregate.incrementsY &&
            returnData.program.towerPreferences.occurrence.incrementsY &&
            returnData.program.towerPreferences.aggregate.maxY &&
            returnData.program.towerPreferences.occurrence.maxY
          ) {
            this.store.dispatch(
              setAllProperties({
                programID: returnData.data.program.id,
                occ: {
                  incrementsY:
                    returnData.program.towerPreferences.occurrence.incrementsY,
                  incrementsYDirty:
                    returnData.program.towerPreferences.occurrence
                      .incrementsYDirty,
                  maxY: returnData.program.towerPreferences.occurrence.maxY,
                  maxYDirty:
                    returnData.program.towerPreferences.occurrence.maxYDirty,
                  most: returnData.program.towerPreferences.occurrence.most,
                },
                agg: {
                  incrementsY:
                    returnData.program.towerPreferences.aggregate.incrementsY,
                  incrementsYDirty:
                    returnData.program.towerPreferences.aggregate
                      .incrementsYDirty,
                  maxY: returnData.program.towerPreferences.aggregate.maxY,
                  maxYDirty:
                    returnData.program.towerPreferences.aggregate.maxYDirty,
                  most: returnData.program.towerPreferences.aggregate.most,
                },
              })
            )
            this.store.dispatch(
              saveTowerPreferences({
                id: returnData.data.program.id,
                preferences: [
                  {
                    increment_y_agg:
                      returnData.program.towerPreferences.aggregate.incrementsY,
                    increment_y_occ:
                      returnData.program.towerPreferences.occurrence
                        .incrementsY,
                    y_max_agg:
                      returnData.program.towerPreferences.aggregate.maxY,
                    y_max_occ:
                      returnData.program.towerPreferences.occurrence.maxY,
                  },
                ],
              })
            )
          }
        })
      ),
    { dispatch: false }
  )

  saveAsClone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAnalysisActions.saveAsCloneAnalysis),
        withLatestFrom(
          this.store.select(selectProgramsByID),
          this.store.select(selectDesignProgramIDS),
          this.store.select(selectCurrentStudyID2),
          this.store.select(fromBrokerSelectors.selectTierPath),
          this.store.select(fromBrokerSelectors.selectTierPath2)
        ),
        mergeMap(
          ([data, structures, programs, studyID, tierPath1, tierPath2]) => {
            const program = data.program ?? programs[data.index ?? 0]!
            const { id, description } = program
            const label = `Clone of ${program.label}`
            let tierPath = data.isClone ? tierPath2 : tierPath1
            if (tierPath.year === null) {
              tierPath = tierPath1
            }

            const portfolioIDs: PortfolioSetAndStudyIDs = {
              clientID: tierPath.client ?? '',
              yearID: tierPath.year ?? '',
              studyID: tierPath.program ?? '',
              structureID: tierPath.structure ?? '',
              analysisProfileID: data.analysisProfileID ?? '',
              cededPortfolioID: structures[id]?.cededPortfolioID ?? '',
              netPortfolioID: structures[id]?.netPortfolioID ?? '',
              grossPortfolioID: structures[id]?.grossPortfolioID ?? '',
              parentGrossPortfolioID:
                structures[id]?.parentGrossPortfolioID ?? '',
            }

            return this.animatedScenariosService
              .cloneBulkStructure(
                id,
                label,
                String(description),
                data.parentGrossPortfolioID ?? '',
                data.analysisProfileID ?? '',
                data.layers || undefined,
                data.cededLayers || undefined,
                data.lossSetNames,
                {
                  persistStructure: true,
                  handleDirty: true,
                  asScenario: data.asScenario,
                  studyID: studyID ?? data.program?.studyID,
                },
                data.autobuildID
              )
              .pipe(
                withLatestFrom(this.store.select(selectAuthState)),
                map(([{ data: data2, error }, authState]) => {
                  if (error) {
                    return this.store.dispatch(
                      fromAnalysisActions.saveAsAnalysisFailure({ error })
                    )
                  } else {
                    this.backendService
                      .postEvent({
                        eventType: 'Portfolio Save As',
                        eventDetails: {
                          user: authState.username,
                          carrier: portfolioIDs!.clientID,
                          year: portfolioIDs!.yearID,
                          program: portfolioIDs!.studyID,
                          structure: data2!.program.label,
                          analysis_profile_id: data2!.program.analysisID,
                          ceded_portfolio_id: data2!.program.cededPortfolioID,
                          gross_portfolio_id: data2!.program.grossPortfolioID,
                          net_portfolio_id: data2!.program.netPortfolioID,
                        },
                      })
                      .subscribe(err => throwError(err))

                    this.store.dispatch(
                      fromAnalysisActions.saveAsAnalysisSuccess({
                        ...portfolioIDs!,
                        program: data2!.program,
                        otherPrograms: data2!.otherPrograms,
                      })
                    )

                    this.store.dispatch(
                      fromAnalysisActions.saveAsCloneAnalysisSuccess({
                        ...portfolioIDs!,
                        program: data2!.program,
                        otherPrograms: data2!.otherPrograms,
                      })
                    )

                    this.nav.navigateWithTierPath({
                      ...portfolioIDs!,
                      ...data2!.portfolioIDs,
                    })
                  }
                  return { program: data2?.program, data: data2 }
                }),
                tap(returnData => {
                  if (
                    returnData &&
                    returnData.program &&
                    returnData.program.towerPreferences &&
                    returnData.data &&
                    returnData.program.towerPreferences.aggregate.incrementsY &&
                    returnData.program.towerPreferences.occurrence
                      .incrementsY &&
                    returnData.program.towerPreferences.aggregate.maxY &&
                    returnData.program.towerPreferences.occurrence.maxY
                  ) {
                    this.store.dispatch(
                      setAllProperties({
                        programID: returnData.data.program.id,
                        occ: {
                          incrementsY:
                            returnData.program.towerPreferences.occurrence
                              .incrementsY,
                          incrementsYDirty:
                            returnData.program.towerPreferences.occurrence
                              .incrementsYDirty,
                          maxY: returnData.program.towerPreferences.occurrence
                            .maxY,
                          maxYDirty:
                            returnData.program.towerPreferences.occurrence
                              .maxYDirty,
                          most: returnData.program.towerPreferences.occurrence
                            .most,
                        },
                        agg: {
                          incrementsY:
                            returnData.program.towerPreferences.aggregate
                              .incrementsY,
                          incrementsYDirty:
                            returnData.program.towerPreferences.aggregate
                              .incrementsYDirty,
                          maxY: returnData.program.towerPreferences.aggregate
                            .maxY,
                          maxYDirty:
                            returnData.program.towerPreferences.aggregate
                              .maxYDirty,
                          most: returnData.program.towerPreferences.aggregate
                            .most,
                        },
                      })
                    )
                    this.store.dispatch(
                      saveTowerPreferences({
                        id: returnData.data.program.id,
                        preferences: [
                          {
                            increment_y_agg:
                              returnData.program.towerPreferences.aggregate
                                .incrementsY,
                            increment_y_occ:
                              returnData.program.towerPreferences.occurrence
                                .incrementsY,
                            y_max_agg:
                              returnData.program.towerPreferences.aggregate
                                .maxY,
                            y_max_occ:
                              returnData.program.towerPreferences.occurrence
                                .maxY,
                          },
                        ],
                      })
                    )
                  }
                })
              )
          }
        )
      ),
    { dispatch: false }
  )
  updateGrossPortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAnalysisActions.updateGrossPortfolio),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectLossSetLayers))
      ),
      switchMapWithInput(([action, lossSetLayers]) => {
        // Update Gross Portfolio
        const lossSetLayerIDs = lossSetLayers.map(l => l.id)
        return this.service.updatePortfolioLayers(
          action.grossPortfolioID,
          lossSetLayerIDs
        )
      }),
      rejectErrorWithInput(error => {
        return this.store.dispatch(
          fromAnalysisActions.updateGrossFailure({ error })
        )
      }),
      concatMap(([grossPortfolio]) => {
        return [
          fromLossSetLayersActions.setDirty({ dirty: false }),
          fromAnalysisActions.updateGrossSuccess({ portfolio: grossPortfolio }),
        ]
      })
    )
  )

  updateLayerNames$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAnalysisActions.updateLayerNames),
      withLatestFrom(
        this.store.pipe(select(fromAnalysisSelectors.selectCededLayers))
      ),
      switchMap(actions => {
        const observables: ApiResponse<RiskSectionWithMarkets>[] = []
        actions[1].forEach(layer => {
          if (layer.dirty) {
            const layerRef = layer.layer.id
            const layerName = layer.layer.physicalLayer.description
            const layerCurrency = layer.layer.currency
            if (layerRef && layerName && layerCurrency) {
              observables.push(
                this.backendService.putLayerName(
                  layerRef,
                  layerName,
                  layerCurrency
                )
              )
            }
          }
        })
        return forkJoin(observables).pipe(
          map(results => {
            for (const result of results) {
              if (result && result.error) {
                return { error: result.error }
              }
            }
          })
        )
      }),
      map(riskSection => {
        if (riskSection && riskSection.error) {
          return fromAnalysisActions.updateLayerNameFailure({
            error: riskSection.error,
          })
        } else {
          return fromAnalysisActions.updateLayerNameSuccess()
        }
      })
    )
  })

  openAddInuranceDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAnalysisActions.openAddInuranceDialog),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        const portfolioId = props.currentProgram?.cededPortfolioID ?? ''
        return this.service.fetchPortfolios([portfolioId])
      }),
      rejectErrorWithInput(_ => ({
        type: 'No Action',
      })),
      concatMapWithInput(([portfolios]) => {
        const layerIDs: string[] = []
        portfolios.map(portfolio =>
          (portfolio.layers as LogicalPortfolioLayer[]).forEach(layer => {
            if (layer.meta_data.backAllocatedForID) {
              layerIDs.push(layer.meta_data.backAllocatedForID)
            }
            if (
              layer.meta_data.sage_layer_type === layerIds.noncatIndxl &&
              layer.meta_data.visible_layer_id
            ) {
              layerIDs.push(layer.meta_data.visible_layer_id)
            }
            layerIDs.push(layer.id)
          })
        )
        return this.service.fetchLayers<LogicalPortfolioLayer>(layerIDs)
      }),
      rejectErrorWithInput(_ => ({ type: 'No action' })),
      concatMap(
        ([
          response,
          [_, { currentLayer, currentProgram, fromMultiSection }],
        ]) => {
          const layers: Layer[] = convertFromLogicalPortfolioLayers(response)
          const targetLayers = layers.filter(
            l =>
              l.meta_data.structureID === currentProgram?.id &&
              l.meta_data.sage_layer_subtype !== 'visible-layer'
          )

          this.dialog.open(AddInuranceDialogContainerComponent, {
            minWidth: '50vw',
            data: {
              currentProgram,
              currentLayer,
              isEdit: false,
              isAdd: true,
              fromMultiSection,
            },
          })

          return [
            fromAnalysisActions.fetchLayersByStructureSuccess({
              structure: currentProgram!,
              layers: targetLayers,
              inurance: 'source',
            }),
            fromAnalysisActions.fetchLayersByStructureSuccess({
              structure: currentProgram!,
              layers: targetLayers,
              inurance: 'swap',
            }),
            fromAnalysisActions.fetchLayersByStructureSuccess({
              structure: currentProgram!,
              layers: targetLayers,
              inurance: 'target',
            }),
          ]
        }
      )
    )
  )

  openAddInuranceDialogForEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAnalysisActions.openAddInuranceDialogForEdit),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        let porfolioId: string

        if (props.group && props.group.cededPortfolioID) {
          porfolioId = props.group.cededPortfolioID
        } else {
          // tslint:disable-next-line: no-non-null-assertion
          porfolioId = props.structure!.cededPortfolioID
        }

        return this.service.fetchPortfolios([
          porfolioId,
          props.currentProgram?.cededPortfolioID!,
        ])
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromAnalysisActions.openAddInuranceDialogForEditFailure({ error })
        )
      ),
      concatMapWithInput(([portfolios]) => {
        const layerIDs: string[] = []
        portfolios.map(portfolio =>
          portfolio.layers.forEach((layer: any) => {
            if (layer.meta_data.backAllocatedForID) {
              layerIDs.push(layer.meta_data.backAllocatedForID)
            }
            if (
              layer.meta_data.sage_layer_type === layerIds.noncatIndxl &&
              layer.meta_data.visible_layer_id
            ) {
              layerIDs.push(layer.meta_data.visible_layer_id)
            }
            layerIDs.push(layer.id)
          })
        )
        return this.service.fetchLayers<LogicalPortfolioLayer>(layerIDs)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromAnalysisActions.openAddInuranceDialogForEditFailure({ error })
        )
      ),
      concatMap(
        ([
          response,
          [
            _,
            {
              currentLayer,
              currentProgram,
              layer,
              structure,
              group,
              relationship,
              isEdit,
              isAdd,
            },
          ],
          ,
        ]) => {
          const actions = []
          const layers: Layer[] = convertFromLogicalPortfolioLayers(response)
          const targetLayers = layers.filter(
            l =>
              l.meta_data.structureID === currentProgram?.id &&
              l.meta_data.sage_layer_subtype !== 'visible-layer'
          )
          const sourceLayers = layers.filter(
            l =>
              l.meta_data.structureID === structure?.id &&
              l.meta_data.sage_layer_subtype !== 'visible-layer'
          )
          const sourceLayer = layers.find(
            l =>
              l.id ===
              (layer?.meta_data.main_layer_id
                ? layer.meta_data.main_layer_id
                : layer?.id)
          )
          actions.push(
            fromAnalysisActions.fetchLayersByStructureSuccess({
              structure: currentProgram!,
              layers: targetLayers,
              inurance: 'target',
            })
          )
          if (
            (relationship === 'layerToLayer' ||
              relationship === 'structureToLayer') &&
            structure
          ) {
            // Update swap and source before opening the dialog
            actions.push(
              fromAnalysisActions.fetchLayersByStructureSuccess({
                structure,
                layers: targetLayers,
                inurance: 'swap',
              })
            )
            actions.push(
              fromAnalysisActions.fetchLayersByStructureSuccess({
                structure,
                layers: sourceLayers,
                inurance: 'source',
              })
            )
          }
          this.dialog.open(AddInuranceDialogContainerComponent, {
            minWidth: '50vw',
            data: {
              currentProgram,
              currentLayer,
              sourceLayer,
              sourceStructure: structure,
              sourceGroup: group,
              relationship,
              isEdit,
              isAdd,
            },
          })
          actions.push(
            fromAnalysisActions.openAddInuranceDialogForEditSuccess()
          )
          return actions
        }
      )
    )
  )

  fetchLayersByStructure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAnalysisActions.fetchLayersByStructure),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        return this.service.fetchPortfolio(props.structure.cededPortfolioID!)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromAnalysisActions.fetchLayersByStructureFailure({
            error,
          })
        )
      ),
      concatMap(res =>
        of(res).pipe(
          withLatestFrom(this.store.pipe(select(selectCurrentClient)))
        )
      ),
      map(([[portfolio, { structure, inurance }]]) => {
        const portfolioSetID: PortfolioSetID | null =
          extractPortfolioSetID(structure)
        if (!portfolioSetID) {
          throw Error(
            'Cannot fetch layers by structure without portfolio set Id'
          )
        }
        return { portfolio, structure, inurance }
      }),
      concatMapWithInput(({ portfolio }) => {
        const layerIDs: string[] = []
        portfolio.layers.forEach((layer: any) => {
          if (layer.meta_data.backAllocatedForID) {
            layerIDs.push(layer.meta_data.backAllocatedForID)
          }
          layerIDs.push(layer.id)
        })

        return this.service.fetchLayers<LogicalPortfolioLayer>(layerIDs)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromAnalysisActions.fetchLayersByStructureFailure({
            error,
          })
        )
      ),
      map(([response, { structure, inurance }]) => {
        const layers: Layer[] = convertFromLogicalPortfolioLayers(response)
        return fromAnalysisActions.fetchLayersByStructureSuccess({
          structure,
          layers,
          inurance,
        })
      })
    )
  )
}
