import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { ApplicationError } from '../../../error/model/error'
import {
  CreditCalculationStructure,
  CreditStructure,
} from '../../model/credit-structure.model'
import { CreditSubmissionStructure } from '../../model/credit-submission.model'

export const CreditCalculationStructureActions = createActionGroup({
  source: 'Credit Calculation Structure',
  events: {
    Fetch: props<{ structureId: string }>(),
    'Fetch Success': props<{ structure: CreditCalculationStructure }>(),
    'Fetch Failure': props<{ error: ApplicationError }>(),
    Save: emptyProps(),
    Post: emptyProps(),
    'Post Success': props<{ structure: CreditCalculationStructure }>(),
    'Post Failure': props<{ error: ApplicationError }>(),
    Put: emptyProps(),
    'Put Success': props<{ structure: CreditCalculationStructure }>(),
    'Put Failure': props<{ error: ApplicationError }>(),
    Create: emptyProps(),
    'Create Success': props<{ structure: CreditCalculationStructure }>(),
    Update: props<{ structure: CreditCalculationStructure }>(),
  },
})

export const CreditStructureActions = createActionGroup({
  source: 'Credit Structure',
  events: {
    'Reset Selected Structure': emptyProps(),
    'Set Selected Structure': props<{ structure: CreditStructure }>(),
    'Update Selected Structure': props<{ structure: CreditStructure }>(),
    'Reinitialize Tower Structure': props<{ structureId: string }>(),
    Fetch: props<{ structureId: string }>(),
    'Fetch Success': props<{ structure: CreditStructure }>(),
    'Fetch Failure': props<{ error: ApplicationError }>(),
    Post: props<{ structure: CreditCalculationStructure }>(),
    'Post Success': props<{ structure: CreditStructure }>(),
    'Post Failure': props<{ error: ApplicationError }>(),
    Put: props<{ structure: CreditCalculationStructure }>(),
    'Put Success': props<{ structure: CreditStructure }>(),
    'Put Failure': props<{ error: ApplicationError }>(),
    Create: emptyProps(),
    'Create Success': props<{ structure: CreditStructure }>(),
  },
})

export const CreditSubmissionStructureActions = createActionGroup({
  source: 'Credit Submission Structure',
  events: {
    'Reset Selected Submission': emptyProps(),
    'Set Selected Submission': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Update Selected Submission': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Reinitialize Tower Submission': props<{ structureId: string }>(),
    Fetch: props<{ structureId: string }>(),
    'Fetch Success': props<{ structure: CreditSubmissionStructure }>(),
    'Fetch Failure': props<{ error: ApplicationError }>(),
    Post: props<{ structure: CreditCalculationStructure }>(),
    'Post Success': props<{ structure: CreditSubmissionStructure }>(),
    'Post Failure': props<{ error: ApplicationError }>(),
    Put: props<{ structure: CreditCalculationStructure }>(),
    'Put Success': props<{ structure: CreditSubmissionStructure }>(),
    'Put Failure': props<{ error: ApplicationError }>(),
    Create: emptyProps(),
    'Create Success': props<{ structure: CreditSubmissionStructure }>(),
  },
})
