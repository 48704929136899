<button mat-icon-button class="menu-icon" id="menu" (click)="navService.open()">
  <mat-icon>menu</mat-icon>
</button>

<a class="logo" routerLink="/home" appLogo></a>

<app-header-title
  class="title"
  name="Design"
  [tierNames]="tierNames"
  [page]="'Design'"
  [subtitle]="subtitle"
  (backClick)="backClick.emit()"
  (parentClick)="parentClick.emit()"
></app-header-title>

<section class="actions">
  <ng-container *ngIf="!activeAction; else actionRunning">
    <button
      *ngIf="!libRETemplate"
      appButtonIcon
      gigantic
      class="more"
      [matMenuTriggerFor]="moreMenu"
      [disabled]="disableActions"
    >
      more_horiz
    </button>

    <button
      appButton
      huge
      link
      [disabled]="!(dirty || canSaveTechnicalPremium) || disableActions"
      (click)="onResetClick()"
    >
      Reset
    </button>

    <button
      appButton
      huge
      link
      accent
      [matMenuTriggerFor]="saveAsMenu"
      [disabled]="disableActions"
    >
      <span>Save As</span>
      <mat-icon inline>arrow_drop_down</mat-icon>
    </button>

    <ng-container *ngIf="mslService.busy$ | async as status">
      <button
        *ngIf="!libRETemplate"
        appButton
        huge
        accent
        border
        [disabled]="
          !(structureDirty || dirty || canSaveTechnicalPremium) ||
          status?.busy ||
          disableActions
        "
        (click)="
          onSaveClick(
            !(structureDirty || dirty || canSaveTechnicalPremium) ||
              status?.busy ||
              disableActions
          )
        "
      >
        Save
      </button>
    </ng-container>

    <mat-menu #moreMenu>
      <button mat-menu-item (click)="editNameClick.emit()">
        <span>Edit Name & Description</span>
      </button>
      <button
        *ngIf="!isLibRE"
        [disabled]="disableOptimization || isScenario"
        mat-menu-item
        (click)="onOptimizeClick()"
      >
        <span>Optimize</span>
      </button>
    </mat-menu>

    <mat-menu #saveAsMenu>
      <div
        matTooltip="Must be in Original loss set scenario to Save As a New Structure"
        [matTooltipDisabled]="!isScenario"
        matTooltipPosition="left"
      >
        <button [disabled]="isScenario" mat-menu-item (click)="onSaveAsClick()">
          <span>New Structure</span>
        </button>
      </div>
      <button
        *ngIf="!libRETemplate && !isLibRE"
        [disabled]="isOptimization"
        mat-menu-item
        (click)="onSaveAsClick(true)"
      >
        <span>Loss Set Scenario</span>
      </button>
    </mat-menu>
  </ng-container>
</section>

<ng-template #actionRunning>
  <app-active-action [value]="activeAction"></app-active-action>
</ng-template>
