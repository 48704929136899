import { MonetaryUnit } from '../../api/analyzere/analyzere.model'
import { SubjectivityTrackingColumnDef } from '../quote-panel/quote-panel-defs'
import { Reinsurer } from '../../core/model/reinsurer.model'
import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'
import { QuoteExportModes, QuoteExportRowValue } from '../export/quote-excel.model'
import { addColumnDefValues } from '../management-information/utils/quote-details.util'

export enum ReinsurerPhases {
  Quote = 'Quote',
  FOT = 'FOT',
  Bind = 'Bind',
  Expiring = 'Expiring',
}
export type ReinsurerPhase = keyof typeof ReinsurerPhases
export const SubjectivityOptions: string[] = [
  'Pandemic / Communicable Disease Exclusion',
  'Cyber Exclusion',
  'Subjectivity',
  'Contract Request',
  'Notes',
  'Document Request',
]

export const placedThroughOptions: string[] = [
  'Lockton Re',
  'Acrisure',
  'Aon Reinsurance Solutions',
  'BMS Intermediaries, Inc',
  'CJ Coleman & Company Limited',
  'Direct',
  'eReinsure',
  'Gallagher Re',
  'Guy Carpenter & Company',
  'Holborn Corporation',
  'Howden Re/TigerRisk',
  'UIB',
  'Willis Re',
]

export const quickQuoteOptions: string[] = [
  'Aggregate Attachment / AAD',
  'Aggregate Limit',
  'Offered %',
  'Rate',
]

export interface BrokerData {
  name: string
  ref: string
}

export const brokerOptions: BrokerData[] = [
  {
    name: ' ',
    ref: ' ',
  },
  {
    name: 'Acrisure RE US Limited',
    ref: '10009704',
  },
  {
    name: 'BMS Intermediaries, Inc',
    ref: '10003679',
  },
  {
    name: 'Lockton Companies LLP',
    ref: '10001795',
  },
  {
    name: 'Lockton Re LLC',
    ref: '20001295',
  },
  {
    name: 'Lockton Re LLP',
    ref: '20001293',
  },
]

export interface QuoteReinsurer {
  id: string
  quoteReinsurerName?: string
  cededlayerID?: string
  cededLayerType?: string
  newCededLayerForViewID?: string
  reinsurerPhase: ReinsurerPhase
  reinsurerPhaseVersion: string
  reinsurerPhaseLabel?: string
  quoteFields?: QuoteFields
  riskSubjectivityLink?: ReinsurerSubjectivity[]
  reinsurerSectionId?: number
  decline?: boolean
  declineReason?: string
  slidingScale?: SlidingScale[]
  exportToggle?: boolean
  riskAssignedLinesLink?: AssignedLines[]
  tpRef?: string
  programGroupID?: number
  sharedLimitID?: number
  isPreferred?: boolean
  reType?: string
  sequenceNumber?: number
}

export interface QuoteFields {
  quoteOccurrenceLimit?: MonetaryUnit
  quoteOccurrenceAttachment?: MonetaryUnit
  quoteTopOccurrenceLimit?: MonetaryUnit
  quoteTopOccurrenceAttachment?: MonetaryUnit
  quoteDropOccurrenceLimit?: MonetaryUnit
  quoteDropOccurrenceAttachment?: MonetaryUnit
  quoteAggregateLimitTop?: MonetaryUnit
  quoteAggregateLimitDrop?: MonetaryUnit
  quoteRiskLimit?: MonetaryUnit
  quoteRiskAttachment?: MonetaryUnit
  quoteFranchiseDeductible?: MonetaryUnit
  quoteReinstatements?: Reinstatements[]
  quoteAggregateLimit?: MonetaryUnit
  quoteAggregateAttachment?: MonetaryUnit
  quoteCessionPercentage?: number
  quoteCedingCommission?: number
  quoteReinsurerExpenseProvision?: number
  quoteProfitCommission?: number
  quoteRolPercentage?: number
  quoteRateOnLineSubject?: number
  quotePmpm?: number
  quotePremium?: MonetaryUnit
  quoteEffectiveDate?: string
  quoteExpirationDate?: string
  quoteExpiryDate?: string
  quotePayout?: MonetaryUnit
  quoteTrigger?: MonetaryUnit
  quoteNth?: number
  underwriter?: string
  maolLimit?: MonetaryUnit
  terrorismAggSubLimit?: MonetaryUnit
  lossRatioCap?: MonetaryUnit
  lossRatioCapPercentage?: number
  lossCapApplication?: string
  limitApplication?: string
  laeCap?: MonetaryUnit
  laeTreatment?: string
  adjustmentBasis?: string
  ecoCoveragePct?: number
  xplCoveragePct?: number
  subjectPremium?: MonetaryUnit
  minimumPremiumPercentage?: number
  depositPremiumPercentage?: number
  minimumPremium?: MonetaryUnit
  clashPremium?: MonetaryUnit
  profitShareCommission?: number
  profitShareMinRate?: number
  profitShareMaxRate?: number
  brokerageCommission?: number
  brokerageType?: string
  brokerageRIPCommission?: number
  orderPercent?: number
  otherFeatures?: string
  coverageBasis?: string
  indexationtext?: string
  quoteSignedPercentage?: number
  quoteMinPercentage?: number
  quoteOfferedPercentage?: number
  quoteOfferedLimit?: MonetaryUnit
  xplEcoDropdown?: string
  xplEcoConditions?: string
  isLimitUnlimited?: boolean
  isAggregateUnlimited?: boolean
  quoteDepositPremium?: MonetaryUnit
  quoteMinRateSubject?: number
  quoteMaxRateSubject?: number
  quoteMinRatePmpm?: number
  quoteMaxRatePmpm?: number
  quoteSwingRate?: number
  quoteSwingBasis?: string
  quoteIndexation?: string
  quoteFixedIndexValue?: number
  quoteSicOrFranchise?: number
  quoteExpectedCededLoss?: MonetaryUnit
  quoteExpectedCededPremium?: MonetaryUnit
  totalQuoteExpectedCededLoss?: number
  totalQuoteExpectedCededPremium?: number
  quoteDepositPremiumCalc?: MonetaryUnit
  slidingComm?: boolean
  layerCurrency?: string
  quoteCessionsBasedPremium?: boolean
  territorialScope?: string[]
  vendor?: string
  feeOrBrokerage?: string
  structureFX?: string
  premiumFX?: string
  premiumFXToUSD?: string
  layerCategory?: string
  layerClass?: string
  layerSubClass?: string
  excludeFromPricingCurve?: boolean
  perils?: string[]
  modelVersion?: number
  lossImpactedFromPreviousYear?: string
  cedingCommissionBasis?: string
  fee?: number
  rebate?: number
  quoteProbabilityOfAttach?: number
  quoteProbabilityOfExhaust?: number
  quoteIndex?: string
}
export interface Subjectivity {
  id?: string
  acceptedContractWord: string
  acceptedLineCond: string
  brokerComments: string
  clientAccepted: string
  deadline?: string
  marketTpRef?: string
  ralId?: number
  reinsCommentary: string
  reinsResponseStatus: string
  reinsurer?: string
  reinsurerResponse?: string
  riskRef?: string
  riskReinsurerId?: number
  type: string
  vendorIdentifier?: string
  applyToStructureForSameMarket: boolean
  vendorName: string
  layerId?: string | null
}

export interface ReinsurerSubjectivity {
  riskReinsurerId?: string
  riskSubjectivityId?: string
  riskSubjectivity: Subjectivity
}

export interface WhitespaceSyncCustomWarning {
  warningType: string
  layer: string
  reinsurer: string
  warning: string
}

export interface UpdateAssignedLines {
  reinsurerID: string
  assignedLinesRows: AssignedLines[]
}

export interface KnownEntity {
  reinsurer: Reinsurer
  tpRef: string
  relationSeqNumber?: number
}

export interface AssignedLines {
  id?: string
  brokerage?: number
  brokerageRe?: number
  bureaus?: string
  contract?: string
  coBroker?: string
  coBrokerRef?: string
  indicator?: boolean
  limit?: number
  layer?: string
  leadMarket?: string
  marketTpRef?: string
  placedThrough?: string
  reinsurer?: string
  relationSeqNumber?: number
  recommended?: number
  signed?: number
  signedOfAuthorized?: number
  subjectivity?: string
  underwriterRef?: string
  written?: number
  whiteSpaceWSetID?: string
  whiteSpaceSyncedAt?: string
  whiteSpaceSyncedBy?: string
  layerRef?: string
  riskRef?: string
  externalVendor?: string
  placeholder?: boolean
  splitLineIndexColor?: number
  whiteSpaceImpressionIdx?: number
  whiteSpaceBusinessUnit?: string
  brokerageCoBrokerShare?: number
  brokerageReCoBrokerShare?: number
}

export interface SignedWarning {
  layer?: string
  line: number
  title?: string
  type?: 'null' | 'signed>written' | 'signed=0'
  message?: string
}

export interface OrderPercentErrors {
  layer: string
  orderPercent: number
  totalSignedPercent: number
}

export interface SubjectivityTracking {
  id?: string
  layerID?: string
  reinsurerName: string
  type: string
  reinsCommentary: string
  brokerComments: string
  reinsurerResponse?: string
  reinsResponseStatus: string
  acceptedLineCond: string
  acceptedContractWord: string
  clientAccepted: string
  reinsurer?: string
  reinsurerId?: string
  tpRef?: string | null
  applyToStructureForSameMarket: boolean
  vendorName: string
}

export interface Risk {
  id: string
  structureID: number
  treatID?: number
  policyFil?: number
  policyFileYear?: number
  policyFileRef?: string
  quoteDescriptionNarr?: string
  riskTypeShort?: string
  riskRef?: string
  riskVersionRef?: string
  riskVersionStatusENum?: string
  insuredTpRef?: string
  clientTpRef?: string
  brokerTpRef?: string
  yearOfAcct?: number
  fiscalYear?: number
  effectiveDateAndTime?: Date
  expiryDateAndTime?: Date
  newRenew?: string
  descriptionNarr?: string
  interestNarr?: string
  extendedRiskTypeValue?: string
  riskType?: string
  riskStatusEnum?: string
  brokerSupportPersonRef?: string
  placingBrokerPersonRef?: string
  productingBrokerPersonRef?: string
  reassuredName?: string
  territoryGroupCodeValue?: string
  baseCcyCodeValue?: string
  openTwinsIdC?: number
  accountId?: number
  opportunityId?: number
  treatyTIV?: number
  installmentFrequencyEnum?: string
  numberOfInstallments?: number
  premium?: number
  commission?: number
  estimatedPremiumIncome?: number
  brokerageEstimated?: number
  brokerage?: number
  reinstatementPremiumBrokerage?: number
  paidClaims?: number
  programID?: number
}

export interface Section {
  id: string
  structureID: number
  layerRef: string
  riskRef?: string
  riskReinsurers?: QuoteReinsurer[]
  sectionNarrOne?: string
  eventLimit?: number
  aggregateAmt?: number
  deductibleAmt?: number
  limitsNarr?: string
  limitsAmt?: number
  excessAmt?: number
  totalSectionSignedPct?: number
  premiumNarr?: number
  initialPremAmt?: number
  minimumPrem?: number
  minimumPremiumAmount?: number
  pcPercentage?: number
  profitCommRoe?: number
  dacAdjRate?: number
  subjectPremium?: number
  premium?: number
  commission?: number
  estimatedPremiumIncome?: number
  brokerageEstimated?: number
  brokerage?: number
  reinstatementPremiumBrokerage?: number
  paidClaims?: number
  riskReinstatements?: Reinstatements
  riskInuringLayers?: InuringLayer
  occurrenceLimitToggle?: boolean
  occurrenceAttachmentToggle?: boolean
  quoteTopOccurrenceLimitToggle?: boolean
  quoteTopOccurrenceAttachmentToggle?: boolean
  quoteDropOccurrenceLimitToggle?: boolean
  quoteDropOccurrenceAttachmentToggle?: boolean
  quoteAggregateLimitTopToggle?: boolean
  quoteAggregateLimitDropToggle?: boolean
  riskLimitToggle?: boolean
  riskAttachmentToggle?: boolean
  franchiseDeductibleToggle?: boolean
  reinstatementsToggle?: boolean
  aggregateLimitToggle?: boolean
  aggregateAttachmentToggle?: boolean
  cedingCommissionToggle?: boolean
  reinsurerExpenseProvisionToggle?: boolean
  profitCommissionToggle?: boolean
  rolPercentageToggle?: boolean
  rateOnLineSubjectToggle?: boolean
  pmpmToggle?: boolean
  premiumToggle?: boolean
  effectiveDateToggle?: boolean
  expirationDateToggle?: boolean
  expiryDateToggle?: boolean
  payoutToggle?: boolean
  triggerToggle?: boolean
  nthToggle?: boolean
  underwriterToggle?: boolean
  maolLimitToggle?: boolean
  terrorismAggSubLimitToggle?: boolean
  lossRatioCapToggle?: boolean
  lossRatioCapPercentageToggle?: boolean
  lossCapApplicationToggle?: boolean
  limitApplicationToggle?: boolean
  laeCapToggle?: boolean
  laeTreatmentToggle?: boolean
  adjustmentBasisToggle?: boolean
  ecoCoveragePctToggle?: boolean
  xplCoveragePctToggle?: boolean
  subjectPremiumToggle?: boolean
  minimumPremiumPercentageToggle?: boolean
  depositPremiumPercentageToggle?: boolean
  minimumPremiumToggle?: boolean
  clashPremiumToggle?: boolean
  profitShareCommissionToggle?: boolean
  profitShareMinRateToggle?: boolean
  profitShareMaxRateToggle?: boolean
  brokerageCommissionToggle?: boolean
  brokerageTypeToggle?: boolean
  brokerageRIPCommissionToggle?: boolean
  orderPercentToggle?: boolean
  otherFeaturesToggle?: boolean
  coverageBasisToggle?: boolean
  indexationtextToggle?: boolean
  quoteSignedPercentageToggle?: boolean
  quoteMinPercentageToggle?: boolean
  quoteOfferedPercentageToggle?: boolean
  quoteOfferedLimitToggle?: boolean
  xplEcoDropdownToggle?: boolean
  xplEcoConditionsToggle?: boolean
  slidingCommToggle?: boolean
  quoteDepositPremiumToggle?: boolean
  quoteMinRateSubjectToggle?: boolean
  quoteMaxRateSubjectToggle?: boolean
  quoteMinRatePmpmToggle?: boolean
  quoteMaxRatePmpmToggle?: boolean
  quoteSwingRateToggle?: boolean
  quoteSwingBasisToggle?: boolean
  quoteIndexationToggle?: boolean
  quoteFixedIndexValueToggle?: boolean
  quoteSicOrFranchiseToggle?: boolean
  quoteCessionsBasedPremiumToggle?: boolean
  quoteExpectedCededLossToggle?: boolean
  quoteExpectedCededPremiumToggle?: boolean
  quoteDepositPremiumCalcToggle?: boolean
  layerName: string
  layerType: string
  layerCurrency: string
  programID?: number
  programGroupID?: number
  sharedLimitID?: number
  structureFXToggle?: boolean
  premiumFXToggle?: boolean
  premiumFXToUSDToggle?: boolean
  territorialScopeToggle?: boolean
  vendorToggle?: boolean
  modelVersionToggle?: boolean
  feeOrBrokerageToggle?: boolean
  layerCategoryToggle?: boolean
  layerClassToggle?: boolean
  excludeFromPricingCurveToggle?: boolean
  perilsToggle?: boolean
  lossImpactedFromPreviousYearToggle?: boolean
  cedingCommissionBasisToggle?: boolean
  feeToggle?: boolean
  rebateToggle?: boolean
  quoteProbabilityOfAttachToggle?: boolean
  quoteProbabilityOfExhaustToggle?: boolean
  quoteIndexToggle?: boolean
  fotCount?: number
  quoteCount?: number
}

export interface Reinstatements {
  id?: number
  premium?: number
  brokerage?: number
}

export interface InuringLayer {
  inuringLayerId: number
  fromLayerRef: string
  toLayerRef: string
}

export interface SlidingScale {
  id: number
  type: string
  commission: number
  lossRatio: number
  slideRate?: number
}

export interface SaveQuoteReinsurerResponse {
  id: string
  tempID: string
  subjectivity: ReinsurerSubjectivity[]
  reinstatement: Reinstatements[]
  assignedLines: AssignedLines[]
  newCededLayerForViewID: string
  isSection?: boolean
}

export interface QuoteExport {
  headers: string[]
  descriptionColumn: string[] // describes each column (i.e. Reinsurer)
  descriptionRows: string[][][] // describes what each value of the row measures (i.e. Subject Premium, Risk Limit, etc.)
  expiringReinsurerRows: QuoteExportRowValue[][][][]
  expiringReinsurerColumns: string[][]
  nonFotAndExpiringReinsurerRows: QuoteExportRowValue[][][][]
  nonFotAndExpiringReinsurerColumns: string[][]
  fotReinsurerRows: QuoteExportRowValue[][][][]
  fotReinsurerColumns: string[][]
  trackingRows: SubjectivityTracking[]
  trackingColumns: SubjectivityTrackingColumnDef[]
  assignedLinesRows: QuoteExportRowValue[][][]
  assignedLinesColumns: string[]
  assignedLinesSubRows: QuoteExportRowValue[][]
  assignedLinesTpRef: QuoteExportRowValue[][]
  quotesSignedPercRow: number[][]
  members: number[]
  xolSubjectPremiumRows: QuoteExportRowValue[][][]
  exportMode: QuoteExportModes
  fotSummaryRows: QuoteExportRowValue[][][]
}

export type AssignedLinesWithCheckFlag = QuoteExportAssignedLines & {
  checked: boolean
}

export type QuoteExportAssignedLines = AssignedLines &
  QuoteExportWrittenSignedLines

export type FormattedSubjectivityRow = {
  id: string
  [k: string]: string | Subjectivity[]
}

export interface TotalExpectedForReinsurerVersion {
  reinsurerName: string
  reinsurerVersion: string
  totalQuoteExpectedCededLoss: number
  totalQuoteExpectedCededPremium: number
}
export interface QuoteExportWrittenSignedLines {
  writtenPercentage?: number
  writtenCurrency?: number
  signedPercentage?: number
  signedCurrency?: number
}

export type MultiTotalExpectedForReinsurerVersion =
  TotalExpectedForReinsurerVersion & MultiSectionMappingList
export interface MultiSectionMappingList {
  sections: MultiSectionMapping[]
}
export interface MultiSectionMapping {
  mainLayerId: string
  mainLayerName: string
  sectionLayerId: string
  sectionLayerName: string
}
export interface RenewedFromOpportunity {
  salesforceOppId: string
}
export interface RenewedFromGroup {
  groupName: string /* `Risk Ref : ${riskRef}` */
  risks: RenewedFromRiskAndSection[]
}
export interface RenewedFromRiskAndSection {
  riskRef: string
  sectionNum: number
  sectionNarrative?: string /* more detailed description for sectionNum */
}
export interface RenewedFromRiskAndSectionAndAB {
  selectedRiskAndSection: RenewedFromRiskAndSection
  lastAutoBuild?: boolean
}
export interface RiskCodeSection {
  risk_code: string
  risk_code_description: string
  account_first_year?: number
  account_last_year?: number
  terrorism_code: string
}
export interface SelectedRiskCode {
  risk_code: string
  risk_code_description: string
  percentage: number
}
export interface ProgramRiskCodeMapping {
  programId: number
  layerRef: string
  riskCode: string
  percentage: number
}

export interface QuickQuoteUpdates {
  action: 'Add' | 'Update'
  value: number
  cededLayerId: string
  reinsurerId?: string
  reinsurerName: string
  fieldSelected: string
}

export interface WhitespaceSyncWarningsData {
  duplicateRiskRefs: Map<string, Set<string>>
  overflowSigned: string[]
  whitespaceSyncWarnings: WhitespaceSyncCustomWarning[]
}

export interface UpdateAssignedLinesReinsurer {
  id: string
  lines: {
    lineId: string
    reinsurer: string
  }[]
}

export interface ReinsurerTrackingSubjectivity {
  contractName: string
  pageSetName: string
  carrierName: string
  programName: string
  year: number
  structureName: string
  riskSectionId: number
  layerName: string
  layerType: string
  reinsurerName: string
  marketTpRef: string
  riskSubjectivityId?: string
  brokerComments?: string
  reinsurerCommentary?: string
  subjectivityType?: string
  deadline?: Date | string
  clientAccepted?: string
  ralId?: number
}

export interface QuoteCustomCompareView {
  id?: number
  name: string
  riskSectionId: number
  members: number[]
  dirty: boolean
}

export type QuoteReinsurerCompareTableEntry = {
  id: number | null
  quoteName: string
  selected: boolean
  quoteVersions: QuoteReinsurerCompareTableEntry[]
}

export const reinsurerTrackingSubjectivityColumns: SortTableColumnDef[] = [
  {
    id: 'carrierName',
    label: 'Carrier Name',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    id: 'programName',
    label: 'Program Name',
    ...addColumnDefValues('text', '15rem', true),
  },
  {
    id: 'year',
    label: 'Year',
    ...addColumnDefValues('text', '5rem', true),
  },
  {
    id: 'structureName',
    label: 'Structure Name',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    id: 'layerName',
    label: 'Section Name',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    id: 'subjectivityType',
    label: 'Subjectivity Type',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    id: 'reinsurerCommentary',
    label: 'Reinsurer Commentary',
    ...addColumnDefValues('text', '25rem', true),
    pinnable: true,
  },
  {
    id: 'brokerComments',
    label: 'Broker Comments',
    ...addColumnDefValues('text', '25rem', true),
    pinnable: true,
  },
  {
    id: 'layerType',
    label: 'Layer Type',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    id: 'clientAccepted',
    label: 'Client Accepted',
    ...addColumnDefValues('text', '8rem', true),
  },
  {
    id: 'deadline',
    label: 'Deadline',
    ...addColumnDefValues('date', '5rem', true),
  },
  {
    id: 'contractName',
    label: 'Contract Name',
    ...addColumnDefValues('text', '10rem', true),
  },
  {
    id: 'pageSetName',
    label: 'Signature Page Set',
    ...addColumnDefValues('text', '10rem', true),
  },
]

export const FOT_MASTER_REINSURER_NAME = 'FOT MASTER'
export const EXPIRING_REINSURER_NAME = 'Expiring'

export const QUOTE_TEMP_PREFIX = 'TEMP'
export const ASSIGNED_LINES_TEMP_PREFIX = 'Al'
export const SUBJECTIVITY_TEMP_PREFIX = 'Sub'
type TempIdPrefix =
  | typeof QUOTE_TEMP_PREFIX
  | typeof ASSIGNED_LINES_TEMP_PREFIX
  | typeof SUBJECTIVITY_TEMP_PREFIX

/* generate temp id for quote before it is saved to database */
export function createQuoteID() {
  return createTempId(QUOTE_TEMP_PREFIX)
}
/* generate temp id for assigned lines before it is saved to database */
export function createAlID() {
  return createTempId(ASSIGNED_LINES_TEMP_PREFIX)
}
/* generate temp id for subjectivity before it is saved to database */
export function createQuoteSubjectivityID() {
  return createTempId(SUBJECTIVITY_TEMP_PREFIX)
}
export function createTempId(prefix: TempIdPrefix) {
  return `${prefix}${new Date().getTime()}${Math.random() * 100000}`
}
