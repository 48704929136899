import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Dictionary } from '@ngrx/entity'
import { select, Store } from '@ngrx/store'
import { uniqBy } from 'ramda'
import { Observable, Subject } from 'rxjs'
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators'
import { ClientYear } from '../../../core/model/client.model'
import { Program } from '../../../core/model/program.model'
import { Study } from '../../../core/model/study.model'
import { AppState, selectWindowSize } from '../../../core/store'
import { WindowSize } from '../../../core/store/app.actions'
import * as fromBroker from '../../../core/store/broker/broker.selectors'
import { selectCurrentClientStudies } from '../../../core/store/broker/broker.selectors'
import { selectProgramsByID } from '../../../core/store/program/program.selectors'
import { setGroup } from '../../animated-scenarios/store/group/group-animated-scenarios.actions'
import {
  InuranceTag,
  InuranceTagsByLevel,
  InuranceView,
} from '../../model/inurance.model'
import {
  createLayerInuranceView,
  createStructureInuranceView,
} from '../../model/inurance.util'
import { Layer } from '../../model/layers.model'
import {
  findRiskActualLayer,
  isLayerActualTopAndDrop,
  isLayerTopOrDrop,
} from '../../model/layers.util'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import {
  PortfolioDetailsDialogContainerComponent,
  PortfolioDetailsDialogData,
} from '../../portfolio/portfolio-details-dialog.container/portfolio-details-dialog.container'
import {
  selectGroupCurrency,
  selectGrouperGroupBarsByID,
  selectGrouperInuranceSource,
  selectGrouperInuranceTagsByLevel,
  selectGrouperInuranceTarget,
  selectGrouperMinimizedProgramsByID,
  selectGrouperProgramEntities,
  selectGrouperProgramGroupEntitiesByID,
  selectGrouperProgramGroupMembers,
  selectGrouperProgramGroups,
  selectGrouperSaving,
  selectGrouperSlideIndex,
  selectGrouperSlideProgramEntities,
  selectGrouperSlides,
  selectGrouperTowerSizePercentage,
} from '../../store/analysis.selectors'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { setGrouperSlideIndex } from '../../store/grouper/grouper-view/grouper-view.actions'
import {
  openDeleteGrouperInurance,
  setGrouperInuranceCard,
} from '../../store/grouper/inurance/grouper-inurance.actions'
import { GrouperInuranceMode } from '../../store/grouper/inurance/grouper-inurance.reducer'
import {
  GroupBar,
  GrouperSlide,
  GroupScenariosDialogData,
  GroupScenariosModel,
  ProgramGroup,
  ProgramGroupMember,
  SharedIDGroup,
} from '../../store/grouper/program-group.model'
import { saveProgramGroupScenarios } from '../../store/grouper/program-group/program-group-scenarios.actions'
import { selectGrouperScenariosByID } from '../../store/grouper/program-group/program-group-scenarios.selector'
import * as ProgramGroupActions from '../../store/grouper/program-group/program-group.actions'
import {
  AddProgramGroupProps,
  addProgramGroupToGroup,
  deleteProgramGroup,
  removeProgramGroup,
  renameProgramGroup,
  setGrouperMinimizedProgramGroup,
} from '../../store/grouper/program-group/program-group.actions'
import { ProgramGroupEntity } from '../../store/grouper/program-group/program-group.reducer'
import * as ProgramActions from '../../store/grouper/program/program.actions'
import {
  AddProgramProps,
  addProgramToGroup,
  moveGrouperProgram,
  MoveProgramProps,
  setGrouperMinimizedProgram,
  setGrouperProgramShowAgg,
  setGrouperProgramShowOcc,
} from '../../store/grouper/program/program.actions'
import { ProgramEntity } from '../../store/grouper/program/program.reducer'
import * as SharedLimitActions from '../../store/grouper/shared-limit/grouper-shared-limit.actions'
import { SharedLimitMode } from '../../store/grouper/shared-limit/grouper-shared-limit.reducer'
import {
  DuplicateStructureDialogComponent,
  DuplicateStructureDialogComponentData,
} from '../components/duplicate-structure-dialog/duplicate-structure-dialog.component'
import { FHCFWarningDialogComponent } from '../components/fhcf-warning-dialog/fhcf-warning-dialog.component'
import { GroupScenariosDialogService } from '../services/group-scenarios-dialog.service'
import { NewGroupDialogService } from '../services/new-group-dialog.service'
import { RenameGroupDialogService } from '../services/rename-group-dialog.service'
import { fetchPortfolioView } from '../../store/views/portfolio-view.actions'
import { CurrencyRate } from '../../tower/mechanics/tower.model'
import { isMultiSectionLayer } from '../../layers/multi-section-layer'
import { layerIds } from '../../model/layer-palette.model'
import { isSwingLayer } from '../../layers/swing-layer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-towers-container',
  templateUrl: './group-towers.container.html',
})
export class GroupTowersContainerComponent implements OnInit, OnDestroy {
  entities$: Observable<ProgramEntity[]>
  filteredEntities$: Observable<ProgramEntity[]>
  slides$: Observable<GrouperSlide[]>
  groups$: Observable<ProgramGroup[]>
  groupsByID$: Observable<Dictionary<ProgramGroupEntity>>
  groupBarsByID$: Observable<Record<string, GroupBar>>
  minimizedByID$: Observable<Record<string, boolean>>
  studies$: Observable<readonly Study[]>
  slideIndex$: Observable<number>
  towerSizePercentage$: Observable<number>
  size$: Observable<WindowSize>
  sharedIDGroup$: Observable<SharedIDGroup[]>
  inuranceTagsByLevel$: Observable<InuranceTagsByLevel>
  years$: Observable<readonly ClientYear[]>
  programsByID$: Observable<Dictionary<Program>>
  inuranceSource$: Observable<InuranceView | null>
  inuranceTarget$: Observable<InuranceView | null>
  programGroupMembers$: Observable<ProgramGroupMember[]>
  groupScenariosByID$: Observable<Record<string, GroupScenariosModel>>
  groupCurrency$: Observable<string | any>
  analysisProfileCurrency: string
  currencyRates$: Observable<CurrencyRate[]>

  @Input() sharedLayers: string[]
  @Input() selectedProgramIDs?: readonly string[]
  @Input() analysisProfileID: string | null
  @Input() dirty = false
  @Input() sharedLimitMode: SharedLimitMode
  @Input() sharedLimitLayers: Layer[]
  @Input() inuranceMode: GrouperInuranceMode
  @Input() nextInuranceSymbol: string
  @Input() allowDelete: boolean
  @Input() programGroups: readonly ProgramGroup[]
  @Input() clientProgramGroupMembers: readonly ProgramGroupMember[]

  @Output() programRemove = new EventEmitter<Program>()
  @Output() saveClick = new EventEmitter()

  get selectMode(): boolean {
    return this.inuranceMode === 'new' || this.sharedLimitMode !== 'none'
  }

  private destroy$ = new Subject()
  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private newGroupDialog: NewGroupDialogService,
    private renameGroupDialog: RenameGroupDialogService,
    private groupScenariosDialog: GroupScenariosDialogService,
    private router: Router
  ) {}

  ngOnInit() {
    this.inuranceSource$ = this.store.pipe(select(selectGrouperInuranceSource))
    this.inuranceTarget$ = this.store.pipe(select(selectGrouperInuranceTarget))
    this.entities$ = this.store.pipe(select(selectGrouperProgramEntities))
    this.filteredEntities$ = this.store.pipe(
      select(selectGrouperSlideProgramEntities)
    )
    this.slides$ = this.store.pipe(select(selectGrouperSlides))
    this.groups$ = this.store.pipe(select(selectGrouperProgramGroups))
    this.groupsByID$ = this.store.pipe(
      select(selectGrouperProgramGroupEntitiesByID)
    )
    this.groupBarsByID$ = this.store.pipe(select(selectGrouperGroupBarsByID))
    this.studies$ = this.store.pipe(select(selectCurrentClientStudies))
    this.slideIndex$ = this.store.pipe(select(selectGrouperSlideIndex))
    this.towerSizePercentage$ = this.store.pipe(
      select(selectGrouperTowerSizePercentage)
    )
    this.size$ = this.store.pipe(select(selectWindowSize))

    this.minimizedByID$ = this.store.pipe(
      select(selectGrouperMinimizedProgramsByID)
    )

    this.sharedIDGroup$ = this.store.pipe(
      select(selectGrouperProgramEntities),
      map(programEntities => {
        const sharedIDGroup: SharedIDGroup[] = []
        const ids: string[] = []
        let count = 1
        const cededLayers = uniqBy(
          l => l.layer.id,
          programEntities.flatMap(p => p.cededLayers)
        )
        cededLayers.forEach(l => {
          let actualRiskId = ''
          if (l.layer.meta_data.sage_layer_type === 'shared_limits') {
            const group: string[] = []
            l.layer.layerRefs.forEach(id => {
              const layerForSharedLimit = cededLayers.find(
                l1 => l1.layer.id === id
              )
              if (
                layerForSharedLimit &&
                layerForSharedLimit.layer.meta_data.riskVisibleLayerID
              ) {
                actualRiskId = id
                group.push(
                  layerForSharedLimit.layer.meta_data.riskVisibleLayerID
                )
              } else if (
                layerForSharedLimit &&
                isLayerActualTopAndDrop(layerForSharedLimit.layer)
              ) {
                group.push(...layerForSharedLimit.layer.layerRefs)
              } else {
                group.push(id)
              }
            })
            if (!this.isGroupsEquals(sharedIDGroup, group)) {
              if (actualRiskId === '') {
                sharedIDGroup.push({
                  sharedID: l.layer.id,
                  numberGroup: count,
                  group,
                })
                ids.push(l.layer.id)
                count++
              } else {
                const oldGroup = sharedIDGroup.find(
                  sg => sg.sharedID === l.layer.id
                )
                const newGroup = {
                  sharedID: l.layer.id,
                  numberGroup: count,
                  group,
                }
                if (oldGroup) {
                  sharedIDGroup.splice(
                    sharedIDGroup.indexOf(oldGroup),
                    1,
                    newGroup
                  )
                } else {
                  sharedIDGroup.push({
                    sharedID: l.layer.id,
                    numberGroup: count,
                    group,
                  })
                  ids.push(l.layer.id)
                  count++
                }
              }
            }
          }
        })

        return sharedIDGroup
      })
    )

    this.inuranceTagsByLevel$ = this.store.pipe(
      select(selectGrouperInuranceTagsByLevel)
    )

    this.years$ = this.store.pipe(select(fromBroker.selectCurrentClientYears))
    this.programsByID$ = this.store.pipe(select(selectProgramsByID))
    this.programGroupMembers$ = this.store.pipe(
      select(selectGrouperProgramGroupMembers)
    )
    this.groupScenariosByID$ = this.store.pipe(
      select(selectGrouperScenariosByID)
    )
    this.store
      .pipe(
        takeUntil(this.destroy$),
        select(fromBroker.selectCurrentAnalysisProfile),
        map(analysisProfile => {
          return analysisProfile
            ? analysisProfile.exchange_rate_profile.exchange_rate_table
                .base_currency
            : 'USD'
        })
      )
      .subscribe((currency: string) => {
        this.analysisProfileCurrency = currency
      })
    this.groupCurrency$ = this.store.pipe(select(selectGroupCurrency))

    this.currencyRates$ = this.store.pipe(
      select(fromBroker.selectCurrencyRates)
    )
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  isGroupsEquals(group: SharedIDGroup[], newGroup: string[]): boolean {
    let isEquals = false

    if (group.length === 0) {
      return false
    }

    let valueA = ''
    newGroup.forEach(a => {
      valueA = valueA + a
    })

    group.forEach(g => {
      let valueB = ''
      g.group.forEach(b => {
        valueB = valueB + b
      })

      if (valueA === valueB) {
        isEquals = true
      }
    })

    return isEquals
  }

  onProgramRemove(program: Program) {
    this.store.dispatch(ProgramActions.removeProgramFromGroup({ program }))
  }

  onShowAggChange({
    entity,
    value,
  }: {
    entity: ProgramEntity
    value: boolean
  }) {
    this.store.dispatch(
      setGrouperProgramShowAgg({
        program: entity.program,
        check: value,
      })
    )
  }

  onShowOccChange({
    entity,
    value,
  }: {
    entity: ProgramEntity
    value: boolean
  }) {
    this.store.dispatch(
      setGrouperProgramShowOcc({
        program: entity.program,
        check: value,
      })
    )
  }

  onLayerClick($event: { entity: ProgramEntity; layerID: string }) {
    const entity = $event.entity
    let layerID = $event.layerID
    let layerEntity = entity.cededLayers.find(l => l.layer.id === layerID)
    let layer = (layerEntity && layerEntity.layer) || null
    const visibleID = $event.layerID
    let hiddenTD: LayerState | undefined
    if (layerEntity && layer?.meta_data.isRiskVisible) {
      const allLayers = entity.cededLayers.map(l => l.layer)
      const actualRiskLayer = findRiskActualLayer(allLayers, layer.id)
      layerEntity = entity.cededLayers.find(
        l => l.layer.id === actualRiskLayer?.id
      )
      layer = (layerEntity && layerEntity.layer) || null
      // tslint:disable-next-line: no-non-null-assertion
      layerID = layer?.id!
    }
    if (layerEntity && isLayerTopOrDrop(layerEntity.layer)) {
      hiddenTD = entity.cededLayers.find(l =>
        l.layer.layerRefs.includes(layerEntity?.layer.id || '')
      )
      layerID = hiddenTD?.layer.id ?? ''
    }
    if (
      layerEntity &&
      layerEntity.layer.meta_data.sage_layer_type === 'cat_fhcf' &&
      this.sharedLimitMode !== 'none'
    ) {
      this.dialog.open(FHCFWarningDialogComponent, {
        width: '30vw',
      })
      return { type: 'No Action' }
    } else {
      if (
        (hiddenTD && hiddenTD.layer.sharedLayerID === '') ||
        (layerEntity && layerEntity.layer.sharedLayerID === '')
      ) {
        if (this.sharedLimitMode === 'new') {
          if (hiddenTD) {
            if (
              hiddenTD.layer.layerRefs.every(ref =>
                this.sharedLayers.includes(ref)
              )
            ) {
              this.sharedLayers = this.sharedLayers.filter(
                e => !hiddenTD?.layer.layerRefs.includes(e)
              )
            } else {
              this.sharedLayers.push(...hiddenTD?.layer.layerRefs)
              this.sharedLayers = this.sharedLayers.slice()
            }
          } else {
            if (this.sharedLayers.includes(visibleID)) {
              this.sharedLayers = this.sharedLayers.filter(e => e !== visibleID)
            } else {
              this.sharedLayers.push(visibleID)
              this.sharedLayers = this.sharedLayers.slice()
            }
          }

          return this.store.dispatch(
            SharedLimitActions.toggleSharedLimitLayerSelection({
              layerID,
              entityID: entity.program.id,
              analysisID: entity.program.analysisID,
              cededLayers: entity.cededLayers,
              cededPortfolioID: entity.program.cededPortfolioID,
              netPortfolioLayersIDs: entity.netLayers,
              netPortfolioID: entity.program.netPortfolioID,
            })
          )
        }
      }

      if (this.inuranceMode === 'new' && layer) {
        const symbol = this.nextInuranceSymbol
        const layers = entity.cededLayers.map(le => le.layer)
        if (layer.meta_data.sage_layer_type) {
          if (
            layer.meta_data.sage_layer_type === layerIds.noncatIndxl &&
            layer.meta_data.sage_layer_subtype === 'visible-layer'
          ) {
            layer = layers.filter(
              d =>
                d.meta_data.sage_layer_subtype === 'main-layer' &&
                d.meta_data.visible_layer_id === layer?.id
            )[0]
          }
          if (
            isMultiSectionLayer(layer) &&
            layer.meta_data.sage_layer_subtype === 'visible-layer'
          ) {
            layer = layers.filter(
              d =>
                isMultiSectionLayer(d) &&
                d.meta_data.sage_layer_subtype === 'main-layer' &&
                d.meta_data?.visible_layer_id === layer?.id
            )[0]
          }
          if (isSwingLayer(layer, 'visible-layer')) {
            layer = layers.filter(
              (d: Layer) => d.meta_data.sage_layer_subtype === 'combined-layer'
            )[0]
          }
        }
        const view = createLayerInuranceView(symbol, entity.program, layer)
        return this.store.dispatch(setGrouperInuranceCard({ view, layers }))
      }

      if (
        !this.dirty &&
        this.sharedLimitMode !== 'new' &&
        ((hiddenTD && hiddenTD.layer.sharedLayerID) || layer)
      ) {
        const sharedLimitLayer = this.sharedLimitLayers.find(l =>
          // tslint:disable-next-line: no-non-null-assertion
          l.layerRefs.includes((hiddenTD?.layer || layer)?.id || '')
        )
        if (sharedLimitLayer) {
          return this.store.dispatch(
            SharedLimitActions.openEditSharedLimit({
              layer: sharedLimitLayer,
              selectedLayer: (hiddenTD?.layer || layer)?.id || '',
              selectedProgram: entity.program.id,
            })
          )
        }
      }
    }
  }

  onInuranceTagClick($event: InuranceTag) {
    if (!this.dirty && !this.selectMode) {
      const { terminal, pair, ...tag } = $event
      const source = terminal === 'source' ? tag : pair
      const target = terminal === 'target' ? tag : pair
      this.store.dispatch(openDeleteGrouperInurance({ source, target }))
    }
  }

  onProgramBarClick(entity: ProgramEntity): void {
    const portfolioSetID = extractPortfolioSetID(entity && entity.program)
    if (!portfolioSetID) {
      return console.error(
        'Cannot show group portfolio details, no program or IDs'
      )
    }
    if (this.inuranceMode === 'new') {
      const symbol = this.nextInuranceSymbol
      const view = createStructureInuranceView(symbol, entity.program)
      return this.store.dispatch(setGrouperInuranceCard({ view }))
    } else {
      let groupCurrency: string | undefined
      this.groupCurrency$.pipe(take(1)).subscribe(grpCurrency => {
        if (!grpCurrency || grpCurrency === 'Default') {
          groupCurrency = entity.program.structureCurrency
        } else {
          groupCurrency = grpCurrency
        }
        if (grpCurrency) {
          const portfolioSetAndStudyIDs: any = {
            ...portfolioSetID,
            clientID: entity.program.id,
            studyID: entity.program.studyID,
            currency: groupCurrency,
          }
          this.store.dispatch(fetchPortfolioView(portfolioSetAndStudyIDs))
        }
      })
      const data: PortfolioDetailsDialogData = {
        portfolioSetID,
        name: entity.program.label,
        showResults: true,
        currency: groupCurrency,
      }
      this.dialog.open(PortfolioDetailsDialogContainerComponent, { data })
    }
  }

  onProgramGroupBarClick(entity: ProgramGroupEntity): void {
    const portfolioSetID = extractPortfolioSetID(entity && entity.programGroup)
    if (!portfolioSetID) {
      return console.error(
        'Cannot show group portfolio details, no group or IDs'
      )
    }
    if (this.inuranceMode === 'new') {
      const symbol = this.nextInuranceSymbol
      const view = createStructureInuranceView(symbol, entity.programGroup)
      return this.store.dispatch(setGrouperInuranceCard({ view }))
    } else {
      let grpBarCurrency = this.analysisProfileCurrency
      this.groupCurrency$.pipe(take(1)).subscribe(grpCurrency => {
        if (grpCurrency === null || grpCurrency === 'Default') {
          grpBarCurrency = this.analysisProfileCurrency
        } else {
          grpBarCurrency = grpCurrency
        }
        if (grpCurrency) {
          const portfolioSetAndStudyIDs: any = {
            ...portfolioSetID,
            clientID: entity.programGroup.id,
            studyID: entity.programGroup.studyID,
            currency: grpBarCurrency,
          }
          this.store.dispatch(fetchPortfolioView(portfolioSetAndStudyIDs))
        }
      })
      const data: PortfolioDetailsDialogData = {
        portfolioSetID,
        name: entity.programGroup.label,
        showResults: true,
        currency: grpBarCurrency,
      }
      this.dialog.open(PortfolioDetailsDialogContainerComponent, { data })
    }
  }

  onProgramGroupAdd(props: AddProgramGroupProps): void {
    if (props.isDuplicate) {
      const data: DuplicateStructureDialogComponentData = {
        isGroup: true,
      }
      this.dialog.open(DuplicateStructureDialogComponent, {
        data,
      })
    } else {
      this.store.dispatch(addProgramGroupToGroup(props))
    }
  }

  onProgramGroupRemove(id: string): void {
    this.store.dispatch(removeProgramGroup({ id }))
  }

  onProgramGroupMinimizeToggle(id: string): void {
    this.store.dispatch(setGrouperMinimizedProgramGroup({ id }))
  }

  onProgramAdd(props: AddProgramProps): void {
    if (props.isDuplicate) {
      const data: DuplicateStructureDialogComponentData = {
        isGroup: false,
      }
      this.dialog.open(DuplicateStructureDialogComponent, {
        data,
      })
    } else {
      this.store.dispatch(addProgramToGroup(props))
    }
  }

  onProgramMove(props: MoveProgramProps): void {
    if (props.isDuplicate) {
      const data: DuplicateStructureDialogComponentData = {
        isGroup: false,
      }
      this.dialog.open(DuplicateStructureDialogComponent, {
        data,
      })
    } else {
      this.store.dispatch(moveGrouperProgram(props))
    }
  }

  onProgramMinimize(program: Program): void {
    this.store.dispatch(setGrouperMinimizedProgram({ program, value: true }))
  }

  onProgramRestore(program: Program): void {
    this.store.dispatch(setGrouperMinimizedProgram({ program, value: false }))
  }

  onSlideIndexChange(index: number): void {
    this.store.dispatch(setGrouperSlideIndex({ index }))
  }

  onSaveUntitled(props: AddProgramGroupProps) {
    let isUntitled = true
    const programIDs: string[] = []
    if (props.id !== 'root') {
      programIDs.push(props.id)
      isUntitled = false
    }
    if (props.isDuplicate) {
      const data: DuplicateStructureDialogComponentData = {
        isGroup: false,
      }
      this.dialog.open(DuplicateStructureDialogComponent, {
        data,
      })
    } else {
      this.newGroupDialog
        .open('')
        .afterClosed()
        .pipe(
          withLatestFrom(
            this.store.pipe(select(fromBroker.selectCurrentYearID))
          )
        )
        .subscribe(([label, yearID]) => {
          if (label) {
            this.store.dispatch(
              ProgramGroupActions.addNewProgramGroup({
                label,
                parentGroupID: props.parentGroupID,
                programIDs,
                isUntitled,
                yearID: yearID as string,
              })
            )
          }
        })
    }
  }

  onProgramGroupDelete(groupBar: GroupBar) {
    this.store.dispatch(
      deleteProgramGroup({ groupBar, allowDelete: this.allowDelete })
    )
  }

  onAnimatedScenarios(event: { groupID: string }) {
    this.store.dispatch(setGroup(event))
    this.router.navigate(['/analysis/group-animated-scenarios'])
  }

  onGroupLayerDetails(event: { groupID: string; groupName: string }): void {
    this.store.dispatch(
      ProgramGroupActions.fetchProgramGroupLayerDetails({
        groupID: event.groupID,
        groupName: event.groupName,
      })
    )
  }

  onGroupScenariosClick(data: GroupScenariosDialogData): void {
    const ref = this.groupScenariosDialog.open({
      ...data,
      saving$: this.store.pipe(select(selectGrouperSaving)),
    })

    ref.componentInstance.onSave$
      .pipe(
        takeUntil(ref.afterClosed()),
        map(res => saveProgramGroupScenarios(res))
      )
      .subscribe(this.store)
  }

  onProgramGroupRename(id: string) {
    this.renameGroupDialog
      .open('')
      .afterClosed()
      .subscribe(label => {
        if (label) {
          this.store.dispatch(renameProgramGroup({ id, newName: label }))
        }
      })
  }
}
