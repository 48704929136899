<div class="gross-summary">
  <div class="top">
    <h1 mat-dialog-title class="inline">Gross Loss Summary</h1>
    <div *ngIf="!!selectedStudySummaryFilterView" class="selected-view-name">
      {{selectedStudySummaryFilterView.name}}
    </div>
    <div class="view-toolbar">
      <div class="selected-view-options" *ngIf="!!selectedStudySummaryFilterView">
        <mat-icon
          matTooltip="Delete Summary View"
          class="delete"
          (click)="onDeleteViewClick()"
        >delete</mat-icon>
        <button
          appButton
          accent
          big
          border
          [disabled]="saveAsDisabled"
          (click)="onSaveAsClick()"
        >
          <span>Update View</span>
        </button>
      </div>
      <button
        appButton
        accent
        big
        border
        [disabled]="saveDisabled"
        (click)="onSaveClick()"
      >
        <span>Save New Summary View</span>
      </button>
      <button
        class="export-button"
        matTooltipPosition="before"
        matTooltip="Click to export XLSX of displayed table."
        appButtonIcon
        huge
        opaque
        (click)="exportSummary()"
      >
        cloud_download
      </button>
    </div>
  </div>
  <div class="subheader">
    <span>Explore Gross Results</span>
    <span *ngIf="subheader.length > 0">
      {{' - '}}
      <span *ngFor="let value of subheader; let i = index; let isLast = last">
        <span [style.color]="getSubheaderColor(i)">{{value}}</span>
        <span *ngIf="!isLast">{{' & '}}</span>
      </span>
    </span>
  </div>
  <ng-container *ngIf="!summaryLoading; else loadingTemplate">
    <div class="table">
      <div class="headers">
        <div
          *ngFor="let header of headers; let i = index"
          [style.min-width.px]="header.width - (header.pinned ? 0 : 16)"
          [style.max-width.px]="header.width - (header.pinned ? 0 : 16)"
          [ngClass]="{
            'hide-border': header.hideBorder,
            'pinned': header.pinned,
            'groupBy': header.id === 'groupBy',
            'groupBy-left-0': header.id === 'groupBy' && i === 0
            }"
          class="header-label"
        >
          <span *ngIf="!header.hideLabel">
            {{ header.label }}
          </span>
        </div>
      </div>
      <div class="sort-table-container">
        <app-sort-table
          [rows]="updatedDataRows"
          [columnDefs]="updatedColumnDefs"
          [useZeroText]="true"
          [disableSort]="true"
        >
        </app-sort-table>
      </div>
    </div>
  </ng-container>
  <ng-template #loadingTemplate>
    <div class="loading-spinner">
      <mat-spinner
        *ngIf="summaryLoading"
        [diameter]="48"
        color="accent"
      ></mat-spinner>
    </div>
  </ng-template>
</div>

