/** Contains one to many `CreditCalculationStructure` structures */
export interface CreditPortfolio {
  _id: string
  name: string
  metadata: Record<string, string>
  asset_class: string
  data_type: string
  exposure_base: string
  total_exposure: number
  expense_ratio: number
  portfolio_data: string
  start_business_covered_date?: string
  end_business_covered_date?: string
  gross_loss_scenarios: CreditGrossLossScenario[]
  actuals: CreditActual[]
  modified_date: string
  portfolio_results: Record<string, number> | null
}

export interface CreditGrossLossScenario {
  _id: string
  name: string
  metadata: Record<string, string>
  scenario: string
  model: string
  gross_loss_scenario_data: string
  loan_gross_loss_scenario_data: string
  is_default: boolean
  scenario_type: ScenarioType
  stochastic_data?: StochasticData[]
  modified_date: string
  isActuals?: boolean
}

export interface CreditActual {
  actual_gls_data: string
  actual_loan_data: string
  as_of_date: string
}

export enum ScenarioType {
  Stochastic = 'Stochastic',
  Deterministic = 'Deterministic',
}
export interface StochasticData {
  return_period: string
  gross_loss_scenario_data: string
}

export interface PortfolioStats {
  statName: string
  statValue: number
}

export const PortfolioStatNameMapping = {
  avgCreditScore: 'Average Credit Score',
  totalOriginalUPB: 'Total Original UPB',
  avgDTI: 'Average DTI',
  avgCLTV: 'Average CLTV',
  avgLTV: 'Average LTV',
  avgOriginalInterestRate: 'Average Original Interest Rate',
}
