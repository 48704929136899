import { Injectable } from '@angular/core'
import QuoteExcel, { QuoteExcelParams } from './quote-excel'
import { DatePipe } from '@angular/common'
import { ReinsurerExportService } from 'src/app/reinsurers/export-service/reinsurer-export.service'
import {
  QuoteExport,
  QuoteExportRowValue,
  SubjectivityTracking,
} from '../models/quote.model'
import { clone } from 'ramda'
import { AccountOpportunity } from 'src/app/api/model/backend.model'
import { AgencyDetails } from 'src/app/core/model/reinsurer.model'
import { SubjectivityTrackingColumnDef } from '../quote-panel/quote-panel-defs'
import { containsObo } from '@shared/util/string'

interface ConvertedTrackingRow {
  applyToStructureForSameMarket: boolean
  data: Array<number | string>
}

@Injectable({
  providedIn: 'root',
})
export class QuoteExportService {
  constructor(
    private datePipe: DatePipe,
    private exportService: ReinsurerExportService
  ) {}

  exportToExcel(
    quoteExport: QuoteExport,
    clientName: string,
    programName: string,
    oppId: string,
    accountOpportunities: AccountOpportunity[],
    structureGroupName: string,
    sharedLimitName: string,
    agencyDetails: AgencyDetails[],
    sectionCurrencies: string[],
    layerId: string | null | undefined
  ) {
    const params = this.getQuoteExcelParams(
      quoteExport,
      clientName,
      programName,
      oppId,
      accountOpportunities,
      structureGroupName,
      sharedLimitName,
      agencyDetails,
      sectionCurrencies,
      layerId
    )
    const excel = new QuoteExcel(this.datePipe, params, this.exportService)
    excel.export()
  }

  private getQuoteExcelParams(
    quoteExport: QuoteExport,
    clientName: string,
    programName: string,
    oppId: string,
    accountOpportunities: AccountOpportunity[],
    structureGroupName: string,
    sharedLimitName: string,
    agencyDetails: AgencyDetails[],
    sectionCurrencies: string[],
    layerId: string | null | undefined
  ): QuoteExcelParams {
    const nonFotAndExpiringRows: any[][] = this.getConvertedReinsurerRows(
      quoteExport.nonFotAndExpiringReinsurerRows,
      quoteExport.nonFotAndExpiringReinsurerColumns
    )
    const fotReinsurerRows: any[][] = this.getConvertedReinsurerRows(
      quoteExport.fotReinsurerRows,
      quoteExport.fotReinsurerColumns
    )
    const expiringReinsurerRows: any[][] = this.getConvertedReinsurerRows(
      quoteExport.expiringReinsurerRows,
      quoteExport.expiringReinsurerColumns
    )
    const trackingColumns: string[] = []
    quoteExport.trackingColumns.forEach(s => {
      if (!s.hide && s.label && typeof s.label === 'string') {
        trackingColumns.push(s.label)
      }
    })
    const convertedTrackingRows = this.getConvertedTrackingRows(
      quoteExport.trackingRows,
      quoteExport.trackingColumns,
      layerId
    )

    const subjectivityAndNotes: Array<(string | number)[]> = []
    const otherData: Array<(string | number)[]> = []
    const subjectivityAndNotesAL: Array<(string | number)[]> = []
    const otherDataAL: Array<(string | number)[]> = []
    convertedTrackingRows.forEach(r => {
      const isNoteOrSubjectivity =
        r.data[2] === 'Notes' || r.data[2] === 'Subjectivity'
      const isFOT = r.data[0].toString().toLocaleLowerCase().includes('fot')
      const applyToAll = r.applyToStructureForSameMarket || r.data
      if (isNoteOrSubjectivity && !isFOT && applyToAll) {
        subjectivityAndNotes.push(r.data)
      }
      if (!isNoteOrSubjectivity && !isFOT && applyToAll) {
        otherData.push(r.data)
      }
      if (isNoteOrSubjectivity && isFOT && applyToAll) {
        subjectivityAndNotesAL.push(r.data)
      }
      if (!isNoteOrSubjectivity && isFOT && applyToAll) {
        otherDataAL.push(r.data)
      }
    })
    otherDataAL.forEach(o => {
      quoteExport.assignedLinesSubRows.forEach(a => {
        if (o[0].toString().includes(a[0].toString())) {
          o[6] = a[2]
          o[7] = a[3]
        }
      })
    })
    const assignedLinesRows = this.getConvertedAssignedLinesRows(
      quoteExport.assignedLinesTpRef,
      quoteExport.assignedLinesRows,
      agencyDetails
    )
    const accOpp = accountOpportunities?.find(opp => opp.id === oppId)
    const params: QuoteExcelParams = {
      clientName,
      programName,
      headers: quoteExport.headers,
      descriptionColumn: quoteExport.descriptionColumn,
      descriptionRows: quoteExport.descriptionRows,
      nonFotAndExpiringRows,
      nonFotAndExpiringColumns: quoteExport.nonFotAndExpiringReinsurerColumns,
      fotReinsurerRows,
      fotReinsurerColumns: quoteExport.fotReinsurerColumns,
      expiringReinsurerRows,
      expiringReinsurerColumns: quoteExport.expiringReinsurerColumns,
      trackingRows1: subjectivityAndNotes,
      trackingRows2: otherData,
      trackingColumns,
      oppDate: accOpp?.opportunityInceptionDate,
      oppName: accOpp?.accountName,
      assignedLinesRows,
      assignedLinesColumns: quoteExport.assignedLinesColumns,
      trackingRowsAL1: subjectivityAndNotesAL,
      trackingRowsAL2: otherDataAL,
      structureGroupName,
      sharedLimitName,
      quotesSignedPercRow: quoteExport.quotesSignedPercRow,
      members: quoteExport.members,
      sectionCurrencies,
    }
    return params
  }

  private getConvertedReinsurerRows(
    reinsurerRows: any[][],
    reinsurerCols: string[][]
  ): any[][] {
    const rowsRe: any[][] = []
    reinsurerRows.forEach((re, i) => {
      const convertedRowsRe: Array<(number | string)[]> = []
      if (re.length > 0) {
        re[0].forEach((_: any, k: string | number) => {
          const record: (number | string)[] = []
          reinsurerCols[i].forEach((__, j) => {
            record.push(re[j][k])
          })
          if (record.length > 0) {
            convertedRowsRe.push(record)
          }
        })
      }
      rowsRe.push(convertedRowsRe)
    })
    return rowsRe
  }
  private getConvertedTrackingRows(
    trackingRows: SubjectivityTracking[],
    trackingColumns: SubjectivityTrackingColumnDef[],
    layerId: string | null | undefined
  ): ConvertedTrackingRow[] {
    const convertedRows: ConvertedTrackingRow[] = []
    const data = [...trackingRows]
    data.sort((a, b) => {
      if (a.type && b.type) {
        if (a.type < b.type) {
          return -1
        }
        if (a.type > b.type) {
          return 1
        }
      }
      return 0
    })
    data.forEach(r => {
      const record: (number | string)[] = []
      trackingColumns.forEach(c => {
        if (!c.hide) {
          let value: string | number = r[c.id] as string | number
          if (c.id === 'layerID' && r.applyToStructureForSameMarket) {
            value = 'All'
          }
          record.push(value)
        }
      })
      convertedRows.push({
        applyToStructureForSameMarket: r.applyToStructureForSameMarket || r.layerID === layerId,
        data: record,
      })
    })
    return convertedRows
  }
  private getConvertedAssignedLinesRows(
    assignedLinesTpRef: QuoteExportRowValue[][],
    assignedLinesRows: any[][],
    agencyDetails: AgencyDetails[]
  ): any[][] {
    const convertedAssignedLinesRows: any[][] = []
    assignedLinesTpRef.forEach((a, i) => {
      const [agencyTPRef, agencySeqNumber] = a
      if (agencySeqNumber === 0) {
        convertedAssignedLinesRows.push(assignedLinesRows[i])
      } else {
        const obos = agencyDetails.filter(
          ad =>
            ad.agencyTPRef === agencyTPRef &&
            ad.agencySeqNumber === agencySeqNumber
        )
        if (obos && obos.length === 1) {
          const temp = clone(assignedLinesRows[i])
          temp[2] = [
            assignedLinesRows[i][2][0] + '/' + obos[0].memberTPRef,
            assignedLinesRows[i][2][1],
          ]
          convertedAssignedLinesRows.push(temp)
        } else if (obos && obos.length > 1) {
          obos.forEach(o => {
            const temp = clone(assignedLinesRows[i])
            const oboDetected =
              containsObo(o.agencyName) || containsObo(o.memberName)
            const temp0Name = `${o.agencyName}${
              !oboDetected ? ` obo ${o.memberName}` : ''
            }`
            temp[0] = [temp0Name, assignedLinesRows[i][1][1]]
            temp[2] = [
              assignedLinesRows[i][2][0] + '/' + o.memberTPRef,
              assignedLinesRows[i][2][1],
            ]
            temp.forEach((t, j) => {
              if (
                t[2] === 'written%' ||
                t[2] === 'written$' ||
                t[2] === 'recommended%' ||
                t[2] === 'signed%' ||
                t[2] === 'signed$'
              ) {
                t[0] = (assignedLinesRows[i][j][0] * o.memberPct) / 100
              }
            })
            convertedAssignedLinesRows.push(temp)
          })
        } else {
          convertedAssignedLinesRows.push(assignedLinesRows[i])
        }
      }
    })
    return convertedAssignedLinesRows
  }
}
