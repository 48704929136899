import { Injectable } from '@angular/core'
import { CreditService } from './../../api/credit/credit.service'
import {
  CreateSubmissionProps,
  CreditSubmissionStructure,
} from '../model/credit-submission.model'
import { CreditCalculationStructure } from '../model/credit-structure.model'
import { forkJoin, map, Observable, switchMap } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CreditSubmissionService {
  constructor(private creditService: CreditService) {}

  createSubmissions(
    props: CreateSubmissionProps
  ): Observable<CreditSubmissionStructure[]> {
    return this.createCalculationStructures(props).pipe(
      switchMap(calculationStructures =>
        this.createSubmissionStructures(props, calculationStructures)
      )
    )
  }

  private createCalculationStructures(
    props: CreateSubmissionProps
  ): Observable<CreditCalculationStructure[]> {
    const requests = Array.from({ length: props.userIds.length }, () =>
      this.creditService.createSubmissionStructure(props.calculationStructure)
    )
    return forkJoin(requests).pipe(
      map(responses => responses.map(res => res.data))
    )
  }

  private createSubmissionStructures(
    props: CreateSubmissionProps,
    calculationStructures: CreditCalculationStructure[]
  ): Observable<CreditSubmissionStructure[]> {
    const requests = Array.from({ length: props.userIds.length }, (_, i) =>
      this.creditService.postCreditSubmissionStructure({
        program_id: props.creditStructure.program_id,
        label: calculationStructures[i].name,
        description: props.description,
        credit_submission_structure_id: calculationStructures[i]._id,
        credit_structure_id: props.creditStructure.id,
        user_id: props.userIds[i],
      })
    )
    return forkJoin(requests).pipe(
      map(responses => responses.map(res => res.data))
    )
  }
}
