import { createSelector } from '@ngrx/store'
import { selectCreditState } from '../credit.selectors'

export const selectGroupState = createSelector(
  selectCreditState,
  state => state.group
)

export const selectCurrentCreditGroup = createSelector(
  selectGroupState,
  state => state.currentCreditStructureGroupEntity
)

export const selectCurrentCreditGroupMembers = createSelector(
  selectGroupState,
  state => state.currentCreditStructureGroupMemberEntities
)

export const isGroupDirty = createSelector(
  selectGroupState,
  state => state.isDirty
)

export const isGroupSaving = createSelector(
  selectGroupState,
  state => state.isSaving
)
