<ng-container
  *ngIf="!summaryView"
>
  <h3>Loss Sets / Groups</h3>
  <div class="loss">
    <div class="loss-set-panel">
      <div (click)="onSelectAllLossSets.emit()" class="parent">
        <mat-icon
          class="child"
          *ngIf="allLossSetsSelected"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="allLossSetsSelected"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!allLossSetsSelected"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span class="unselectable">Select All</span>
        </div>
      </div>
      <h4 (click)="toggleVisibility('groups')" class="sub-header">
        Loss Set Groups
        <mat-icon class="caret-icon">
          {{ isSectionVisible('groups') ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </h4>
      <div *ngIf="isSectionVisible('groups')" [@slideInOut]>
        <div
          *ngFor="let lossSet of lossSetGroups"
          class="parent"
          (click)="lossSetClick(lossSet.id, $event)"
          [class.selected]="checkSelectedLossSet(lossSet.id)"
        >
          <mat-icon
            class="child"
            *ngIf="checkSelectedLossSet(lossSet.id)"
            aria-hidden="false"
            aria-label="check"
            [class.selected]="checkSelectedLossSet(lossSet.id)"
          >
            check_box
          </mat-icon>
          <mat-icon
            class="child"
            *ngIf="!checkSelectedLossSet(lossSet.id)"
            aria-hidden="false"
            aria-label="check"
          >
            check_box_outline_blank
          </mat-icon>
          <div class="child">
            <span
              class="unselectable"
              [matTooltip]="lossSet.name"
              matTooltipPosition="above"
            >
              {{ lossSet.name }}
            </span>
          </div>
        </div>
      </div>
      <h4 (click)="toggleVisibility('layers')" class="sub-header">
        Loss Set Layers
        <mat-icon class="caret-icon">
          {{ isSectionVisible('layers') ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </h4>
      <div *ngIf="isSectionVisible('layers')" [@slideInOut]>
        <div
          *ngFor="let lossSet of lossSetLayers"
          class="parent"
          (click)="lossSetClick(lossSet.id, $event)"
          [class.selected]="checkSelectedLossSet(lossSet.id)"
        >
          <mat-icon
            class="child"
            *ngIf="checkSelectedLossSet(lossSet.id)"
            aria-hidden="false"
            aria-label="check"
            [class.selected]="checkSelectedLossSet(lossSet.id)"
          >
            check_box
          </mat-icon>
          <mat-icon
            class="child"
            *ngIf="!checkSelectedLossSet(lossSet.id)"
            aria-hidden="false"
            aria-label="check"
          >
            check_box_outline_blank
          </mat-icon>
          <div class="child">
            <span
              class="unselectable"
              [matTooltip]="lossSet.meta_data.ls_dim1 + (lossSet.meta_data.ls_dim2 ? ' - ' + lossSet.meta_data.ls_dim2 : '')"
              matTooltipPosition="above"
            >
              {{
                lossSet.meta_data.ls_dim1 +
                (lossSet.meta_data.ls_dim2 ? ' - ' + lossSet.meta_data.ls_dim2 : '')
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="summaryView"
>
  <h3>Summary Filters</h3>
  <mat-slide-toggle
    *ngIf="groupByView"
    [@slideInOut]
    class="toggle-input"
    matTooltip="Display Individual Loss Sets Within Groups"
    matTooltipPosition="below"
    [checked]="showIndividualLossSets"
    (change)="toggleIndividualLossSets($event.checked)"
  >
    <span class="light-chart-toggle-label">Show Individual Loss Sets</span>
  </mat-slide-toggle>
  <h4
    class="sub-header"
    (click)="toggleVisibility('metaOptions')"
  >
    Filter Meta Data
    <mat-icon class="caret-icon">
      {{ isSectionVisible('metaOptions') ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </h4>
  <div
    *ngIf="isSectionVisible('metaOptions')"
    [@slideInOut]
    class="level-container"
  >
    <div
      *ngFor="let option of summaryFilterOptions"
    >
      <div
        *ngIf="getSubOptionsByValueType(option.value).length > 0"
      >
        <h4 (click)="toggleVisibility(option.value)">
          {{option.label}}
          <mat-icon class="caret-icon">
            {{ isSectionVisible(option.value) ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        </h4>
        <div *ngIf="isSectionVisible(option.value)" [@slideInOut] class="level-container">
          <div
            *ngFor="let value of getSubOptionsByValueType(option.value)"
            class="parent"
            (click)="toggleSummaryFilter(option.value + '~' + value)"
          >
            <mat-icon
              class="child"
              *ngIf="checkSummaryFilterSelected(option.value + '~' + value)"
              aria-hidden="false"
              aria-label="check"
              [class.selected]="checkSummaryFilterSelected(option.value + '~' + value)"
            >
              check_box
            </mat-icon>
            <mat-icon
              class="child"
              *ngIf="!checkSummaryFilterSelected(option.value + '~' + value)"
              aria-hidden="false"
              aria-label="check"
            >
              check_box_outline_blank
            </mat-icon>
            <div class="child">
              <span
                class="unselectable"
                [matTooltip]="value"
                matTooltipPosition="above"
              >
                {{ value }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h4
    class="sub-header"
    (click)="toggleVisibility('modelingOptions')"
  >
    Group By
    <mat-icon class="caret-icon">
      {{ isSectionVisible('modelingOptions') ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </h4>
  <div *ngIf="isSectionVisible('modelingOptions')" [@slideInOut] class="level-container">
    <h4 (click)="toggleVisibility('l1')">
      Group By One
      <mat-icon class="caret-icon">
        {{ isSectionVisible('l1') ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </h4>
    <div *ngIf="isSectionVisible('l1')" [@slideInOut] class="level-container">
      <div
        *ngFor="let option
         of displaySummaryFilterOptions"
        class="parent"
        (click)="!checkDisabledLevels(option.value, 0) && updateModelingArray(option.value, 0)"
        [class.selected]="checkSelectedLevels(option.value, 0)"
        [class.disabled]="checkDisabledLevels(option.value, 0)"
        [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.value, 0) ? 'none' : 'auto' }"
      >
        <mat-icon
          class="child"
          *ngIf="checkSelectedLevels(option.value, 0)"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSelectedLevels(option.value, 0)"
          [class.disabled]="checkDisabledLevels(option.value, 0)"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSelectedLevels(option.value, 0)"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="option.label"
            matTooltipPosition="above"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
    <h4 *ngIf="showGroupByLevel(0)" (click)="toggleVisibility('l2')">
      Group By Two
      <mat-icon class="caret-icon">
        {{ isSectionVisible('l2') ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </h4>
    <div *ngIf="isSectionVisible('l2')" [@slideInOut] class="level-container">
      <div
        *ngFor="let option
         of displaySummaryFilterOptions"
        class="parent"
        (click)="!checkDisabledLevels(option.value, 1) && updateModelingArray(option.value, 1)"
        [class.selected]="checkSelectedLevels(option.value, 1)"
        [class.disabled]="checkDisabledLevels(option.value, 1)"
        [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.value, 1) ? 'none' : 'auto' }"
      >
        <mat-icon
          class="child"
          *ngIf="checkSelectedLevels(option.value, 1)"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSelectedLevels(option.value, 1)"
          [class.disabled]="checkDisabledLevels(option.value, 1)"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSelectedLevels(option.value, 1)"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="option.label"
            matTooltipPosition="above"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
    <h4 *ngIf="showGroupByLevel(1)" (click)="toggleVisibility('l3')">
      Group By Three
      <mat-icon class="caret-icon">
        {{ isSectionVisible('l3') ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </h4>
    <div *ngIf="isSectionVisible('l3')" [@slideInOut] class="level-container">
      <div
        *ngFor="let option
         of displaySummaryFilterOptions"
        class="parent"
        (click)="!checkDisabledLevels(option.value, 2) && updateModelingArray(option.value, 2)"
        [class.selected]="checkSelectedLevels(option.value, 2)"
        [class.disabled]="checkDisabledLevels(option.value, 2)"
        [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.value, 2) ? 'none' : 'auto' }"
      >
        <mat-icon
          class="child"
          *ngIf="checkSelectedLevels(option.value, 2)"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSelectedLevels(option.value, 2)"
          [class.disabled]="checkDisabledLevels(option.value, 2)"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSelectedLevels(option.value, 2)"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="option.label"
            matTooltipPosition="above"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
    <h4 *ngIf="showGroupByLevel(2)" (click)="toggleVisibility('l4')">
      Group By Four
      <mat-icon class="caret-icon">
        {{ isSectionVisible('l4') ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </h4>
    <div *ngIf="isSectionVisible('l4')" [@slideInOut] class="level-container">
      <div
        *ngFor="let option
         of displaySummaryFilterOptions"
        class="parent"
        (click)="!checkDisabledLevels(option.value, 3) && updateModelingArray(option.value, 3)"
        [class.selected]="checkSelectedLevels(option.value, 3)"
        [class.disabled]="checkDisabledLevels(option.value, 3)"
        [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.value, 3) ? 'none' : 'auto' }"
      >
        <mat-icon
          class="child"
          *ngIf="checkSelectedLevels(option.value, 3)"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSelectedLevels(option.value, 3)"
          [class.disabled]="checkDisabledLevels(option.value, 3)"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSelectedLevels(option.value, 3)"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="option.label"
            matTooltipPosition="above"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
    <h4 *ngIf="showGroupByLevel(3)" (click)="toggleVisibility('l5')">
      Group By Five
      <mat-icon class="caret-icon">
        {{ isSectionVisible('l5') ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </h4>
    <div *ngIf="isSectionVisible('l5')" [@slideInOut] class="level-container">
      <div
        *ngFor="let option
         of displaySummaryFilterOptions"
        class="parent"
        (click)="!checkDisabledLevels(option.value, 4) && updateModelingArray(option.value, 4)"
        [class.selected]="checkSelectedLevels(option.value, 4)"
        [class.disabled]="checkDisabledLevels(option.value, 4)"
        [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.value, 4) ? 'none' : 'auto' }"
      >
        <mat-icon
          class="child"
          *ngIf="checkSelectedLevels(option.value, 4)"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSelectedLevels(option.value, 4)"
          [class.disabled]="checkDisabledLevels(option.value, 4)"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSelectedLevels(option.value, 4)"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="option.label"
            matTooltipPosition="above"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <h4
    class="sub-header"
    (click)="toggleVisibility('columns')"
  >
    Columns / Column Groups
    <mat-icon class="caret-icon">
      {{ isSectionVisible('columns') ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </h4>
  <div
    *ngIf="isSectionVisible('columns')"
    class="level-container"
    [@slideInOut]
  >
    <div
      *ngFor="let header of headers"
      class="parent"
      (click)="toggleSummaryColumnFilter(header.id)"
    >
    <mat-icon
      class="child"
      *ngIf="checkSummaryColumnFilter(header.id)"
      aria-hidden="false"
      aria-label="check"
      [class.selected]="checkSummaryColumnFilter(header.id)"
    >
      check_box
    </mat-icon>
    <mat-icon
      class="child"
      *ngIf="!checkSummaryColumnFilter(header.id)"
      aria-hidden="false"
      aria-label="check"
    >
      check_box_outline_blank
    </mat-icon>
    <div class="child">
      <span
        class="unselectable"
        [matTooltip]="header.label"
        matTooltipPosition="above"
      >
        {{ header.label }}
      </span>
    </div>
    </div>
  </div>
</ng-container>
