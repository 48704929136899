import { Action, createReducer, on } from '@ngrx/store'
import * as fromActions from './compare-view.actions'
import { CompareView } from 'src/app/analysis/model/compare-view.model'
import * as fromBrokerActions from '../../../../core/store/broker/broker.actions'
import * as fromAuthActions from '../../../../core/store/auth/auth.actions'
import { convertAllProgramGroupsFromResponse } from '../../../../api/program-group/program-group.converter'
import { convertAllProgramsFromResponse } from '../../../../api//program/program.converter'

export interface CompareViewState {
  slidesPerView: number
  towerSizePercentage: number
  compareViews: CompareView[]
  selectedCompareView: CompareView | null
}

export const initialState: CompareViewState = {
  slidesPerView: 5,
  towerSizePercentage: 0.2,
  compareViews: [],
  selectedCompareView: null,
}
const compareViewReducer = createReducer(
  initialState,

  on(fromActions.setCompareSlidesPerView, (state, { value }) => ({
    ...state,
    slidesPerView: value,
  })),

  on(fromActions.setCompareTowerSizePercentage, (state, { percentage }) => ({
    ...state,
    towerSizePercentage: percentage,
  })),

  on(
    fromBrokerActions.setCurrentClientSuccess,
    (state, { programs, programGroups, compareViews }) => {
      const adjustedCompareViews: CompareView[] = []
      compareViews?.map(view => {
        const structIds = view.structure_ids.filter(
          structId => parseInt(structId, 10) !== -1
        )
        const groupIds = view.group_ids.filter(
          groupId => parseInt(groupId, 10) !== -1
        )
        const adjustedCompareView: CompareView = {
          id: view.id,
          view_name: view.view_name,
          structure_ids: structIds,
          group_ids: groupIds,
          client_id: view.client_id,
          groups: programGroups?.filter(group => groupIds.includes(group.id)),
          structures: programs?.filter(structure =>
            structIds.includes(structure.id)
          ),
        }
        adjustedCompareViews.push(adjustedCompareView)
      })
      return {
        ...state,
        compareViews: adjustedCompareViews ? adjustedCompareViews : [],
      }
    }
  ),

  on(
    fromAuthActions.identifyPermissionsSuccess,
    (state, { programs, programGroups, compareViews }) => {
      const adjustedCompareViews: CompareView[] = []
      compareViews?.map(view => {
        const structIds = view.structure_ids.filter(
          structId => parseInt(structId, 10) !== -1
        )
        const groupIds = view.group_ids.filter(
          groupId => parseInt(groupId, 10) !== -1
        )
        const adjustedCompareView: CompareView = {
          id: view.id,
          view_name: view.view_name,
          structure_ids: structIds,
          group_ids: groupIds,
          client_id: view.client_id,
          groups: convertAllProgramGroupsFromResponse(
            programGroups?.filter(group =>
              groupIds.includes(group.id.toString())
            )
          ),
          structures: convertAllProgramsFromResponse(
            programs?.filter(structure =>
              structIds.includes(structure.id.toString())
            )
          ),
        }
        adjustedCompareViews.push(adjustedCompareView)
      })
      return {
        ...state,
        compareViews: adjustedCompareViews ? adjustedCompareViews : [],
      }
    }
  ),

  on(fromActions.saveCompareViewSuccess, (state, { compareView }) => {
    const compareViews = state.compareViews.filter(
      view => view.id !== compareView.id
    )
    compareViews.push(compareView)
    return {
      ...state,
      compareViews,
    }
  }),

  on(fromActions.deleteCompareViewSuccess, (state, { compareViewId }) => {
    const compareViews = state.compareViews
    const updatedCompareViews = compareViews.filter(
      view => view.id.toString() !== compareViewId.toString()
    )
    return {
      ...state,
      compareViews: updatedCompareViews,
    }
  }),

  on(fromActions.updateSelectedCompareView, (state, { compareView }) => {
    const selectedCompareView = state.selectedCompareView?.id === compareView?.id ? null : compareView
    return {
      ...state,
      selectedCompareView
    }
  })
)

export function reducer(state: CompareViewState | undefined, action: Action) {
  return compareViewReducer(state, action)
}
