import { FullDateToYear } from '@shared/pipes/full-date-to-year'
import {
  AssignedLines,
  AssignedLinesWithCheckFlag,
  QuoteExportAssignedLines,
 QuoteExportWrittenSignedLines,
} from '../models/quote.model'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { SectionState } from '../store/section/section.reducer'
import { QuoteExportRowValue } from './quote-excel.model'

export const getAssignedLineColumns = (
  isDefaultExport: boolean,
  fotExpiringLayerSet: AssignedLines[][],
  expiringOppYear: string,
  currentOppYear: string,
  section: SectionState
): string[] => {
  const columns: string[] = []
  if (fotExpiringLayerSet[0].length) {
    // Expiring
    const label = `${
      expiringOppYear && new FullDateToYear().transform(expiringOppYear)
    } ${section.section.layerName}`
    if (isDefaultExport) {
      columns.push(label)
      columns.push(...populateArrayWithFill(3, true))
    } else {
      columns.push(`${label} (Expiring)`)
      columns.push('fill')
    }
  }
  if (fotExpiringLayerSet[1].length) {
    // FOT
    const label = `${
      currentOppYear && new FullDateToYear().transform(currentOppYear)
    } ${section.section.layerName}`
    if (isDefaultExport) {
      columns.push(label)
      columns.push(...populateArrayWithFill(10, true))
    } else {
      columns.push(label)
      columns.push(...populateArrayWithFill(4, true))
    }
  }
  return columns
}

export const getAssignedLineRows = (
  assignedLinesRe: string[][],
  reinsurerListForExport: ReinsurerState[],
  expiringReinsurers: ReinsurerState[],
  fotReinsurers: ReinsurerState[],
  nonFotAndExpiringReinsurers: ReinsurerState[],
  isDefaultExport: boolean,
  assignedLinesTpRef: QuoteExportRowValue[][],
  assignedLinesData: QuoteExportAssignedLines[][][]
): QuoteExportRowValue[][][] => {
  const assignedLinesRows: QuoteExportRowValue[][][] = []
  if (!assignedLinesRe.length || !assignedLinesData.length) {
    return assignedLinesRows
  }
  assignedLinesRe.forEach((re, i) => {
    const row: QuoteExportRowValue[][] = []
    let stamp: string | undefined

    const assignedLinesId = re[0]
    const panelReinsurerPhase = reinsurerListForExport.find(({ reinsurer }) =>
      reinsurer.riskAssignedLinesLink?.some(link => link.id === assignedLinesId)
    ).reinsurer.reinsurerPhase

    const reinsurerList =
      panelReinsurerPhase === 'Expiring'
        ? expiringReinsurers
        : panelReinsurerPhase === 'FOT'
          ? fotReinsurers
          : nonFotAndExpiringReinsurers

    stamp =
      reinsurerList
        .find(({ reinsurer }) =>
          reinsurer.riskAssignedLinesLink.some(
            link => link.id === assignedLinesId
          )
        )
        .reinsurer.riskAssignedLinesLink.find(
          link => link.id === assignedLinesId
        ).bureaus || ' '

    row.push([re[1], 'text', 'name'])
    if (isDefaultExport) {
      const altpRef = assignedLinesTpRef[i]?.[0] ?? '0'
      row.push([stamp, 'text', 'bureauStamp'])
      row.push([altpRef, 'text', 'tpRef'])
      row.push([' '])
    }

    assignedLinesData.forEach(line => {
      line.forEach((r, index) => {
        if (!!r.length) {
          let found = false
          r.forEach((a: AssignedLinesWithCheckFlag) => {
            if (a.reinsurer === re[1] && !found && !a.checked) {
              found = true
              if (isDefaultExport) {
                row.push(...getDefaultExportRow(index, r, a))
              } else {
                row.push(...getXOLExportRow(index, r, a))
              }
              a.checked = true
            }
          })
          if (!found) {
            if (isDefaultExport) {
              row.push(...getDefaultNotFoundExportRow(index))
            } else {
              row.push(...getXOLNotFoundExportRow(index))
            }
          }
        }
      })
    })
    assignedLinesRows.push(row)
  })
  return assignedLinesRows
}

const getDefaultExportRow = (
  index: number,
  allReinsurers: QuoteExportAssignedLines[],
  assignedLine: AssignedLinesWithCheckFlag
): QuoteExportRowValue[][] => {
  const writtenSigned = getAssignedLinesWrittenSigned(
    allReinsurers,
    assignedLine
  )
  if (index === 0) {
    // Expired
    return [
      [writtenSigned.writtenPercentage, 'percentage', 'written%'],
      [writtenSigned.writtenCurrency, 'currency', 'written$'],
      [writtenSigned.signedPercentage, 'percentage', 'signed%'],
      [writtenSigned.signedCurrency, 'currency', 'signed$'],
      [' '],
    ]
  } else {
    return [
      [assignedLine.underwriterRef, 'text', 'underwriterRef'],
      [writtenSigned.writtenPercentage, 'percentage', 'written%'],
      [writtenSigned.writtenCurrency, 'currency', 'written$'],
      [assignedLine.recommended, 'percentage', 'recommended%'],
      [writtenSigned.signedPercentage, 'percentage', 'signed%'],
      [writtenSigned.signedCurrency, 'currency', 'signed$'],
      [assignedLine.placedThrough, 'text', 'placedThrough'],
      [assignedLine.coBroker, 'text', 'coBroker'],
      [assignedLine.leadMarket, 'text', 'leadMarket'],
      [assignedLine.brokerage, 'percentage', 'brokerage'],
      [assignedLine.brokerageRe, 'percentage', 'brokerageRe'],
      [' '],
    ]
  }
}

const getXOLExportRow = (
  index: number,
  allReinsurers: QuoteExportAssignedLines[],
  assignedLine: AssignedLinesWithCheckFlag
): QuoteExportRowValue[][] => {
  const writtenSigned = getAssignedLinesWrittenSigned(
    allReinsurers,
    assignedLine
  )
  if (index === 0) {
    return [
      [writtenSigned.signedPercentage, 'percentage', 'signed%'],
      [writtenSigned.signedCurrency, 'currency', 'signed$'],
    ]
  } else {
    return [
      [writtenSigned.writtenPercentage, 'percentage', 'written%'],
      [writtenSigned.writtenCurrency, 'currency', 'written$'],
      [writtenSigned.signedPercentage, 'percentage', 'signed%'],
      [writtenSigned.signedCurrency, 'currency', 'signed$'],
      [assignedLine.leadMarket, 'text', 'leadMarket'],
      [' '],
    ]
  }
}

const getDefaultNotFoundExportRow = (index: number) => {
  if (index === 0) {
    return [
      [0, 'percentage', 'written%'],
      [0, 'currency', 'written$'],
      [0, 'percentage', 'signed%'],
      [0, 'currency', 'signed$'],
      [' '],
    ]
  } else {
    return [
      [' ', 'text', 'underwriterRef'],
      [0, 'percentage', 'written%'],
      [0, 'currency', 'written$'],
      [0, 'percentage', 'recommended%'],
      [0, 'percentage', 'signed%'],
      [0, 'currency', 'signed$'],
      [' ', 'text', 'placedThrough'],
      [' ', 'text', 'coBroker'],
      [' ', 'text', 'leadMarket'],
      [0, 'percentage', 'brokerage'],
      [0, 'percentage', 'brokerageRe'],
      [' '],
    ]
  }
}

const getXOLNotFoundExportRow = (index: number): QuoteExportRowValue[][] => {
  if (index === 0) {
    return [
      [0, 'percentage', 'signed%'],
      [0, 'currency', 'signed$'],
    ]
  } else {
    return [
      [0, 'percentage', 'written%'],
      [0, 'currency', 'written$'],
      [0, 'percentage', 'signed%'],
      [0, 'currency', 'signed$'],
      [' ', 'text', 'leadMarket'],
      [' '],
    ]
  }
}

export const getAssignedLinesWrittenSigned = (
  allAssignedLines: QuoteExportAssignedLines[],
  assignedLine: AssignedLinesWithCheckFlag
): QuoteExportWrittenSignedLines => {
  const allRowsOfThisReinsurer = allAssignedLines.filter(
    row => row.reinsurer === assignedLine.reinsurer
  )
  return allRowsOfThisReinsurer.reduce(
    (acc: QuoteExportWrittenSignedLines, currentRow) => {
      acc.writtenPercentage += currentRow.writtenPercentage
      acc.writtenCurrency += currentRow.writtenCurrency
      acc.signedPercentage += currentRow.signedPercentage
      acc.signedCurrency += currentRow.signedCurrency
      return acc
    },
    {
      writtenPercentage: 0,
      writtenCurrency: 0,
      signedPercentage: 0,
      signedCurrency: 0,
    } as QuoteExportWrittenSignedLines
  )
}

const populateArrayWithFill = (count: number, emptyAtEnd = false) => {
  const fillArray: string[] = []
  for (let i = 0; i < count; i++) {
    fillArray.push('fill')
  }
  if (emptyAtEnd) {
    fillArray.push(' ')
  }
  return fillArray
}
