import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NavService } from './nav.service'
import { NavItem } from '@shared/nav-list-item.component'
import { Store, select } from '@ngrx/store'
import { AppState } from './core/store'
import { RoleGuard } from './guards/role-guard.service'
import { selectAuthState } from './core/store/auth/auth.selectors'
import { Subscription } from 'rxjs'
import { State } from './core/store/auth/auth.state.facade'

@Component({
  selector: 'app-nav-list-container',
  styleUrls: ['./nav-list.container.scss'],
  template: `
    <mat-nav-list disableRipple>
      <app-nav-list-item
        *ngFor="let item of items"
        [item]="item"
        [expandedMap]="expandedMap"
        (itemSelect)="onItemSelect($event)"
      ></app-nav-list-item>
    </mat-nav-list>
  `,
})
export class NavListContainerComponent implements OnInit, OnDestroy {
  expandedMap: Record<string, boolean> = {}
  allowedGuards: Set<string>
  navSubscription: Subscription

  constructor(
    public router: Router,
    private navService: NavService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private roleGuard: RoleGuard
  ) {}

  ngOnInit() {
    this.navSubscription = this.navService.currentUrl.subscribe(() => {
      this.navService.currentUrl.subscribe(() => this.cd.markForCheck())
      this.store.pipe(select(selectAuthState)).subscribe(data => {
        if (data.userPermissions && data.security) {
          this.allowedGuards = this.roleGuard.checkPermissions(
            data.userPermissions,
            data.security
          )
          this.items.map(item => {
            item.disabled = item.route ? this.checkDisabled(item.route) : false
            if (item.children) {
              item.children.map(child => {
                child.disabled = child.route
                  ? this.checkDisabled(child.route)
                  : false
              })

              if (this.allChildrenItemsDisabled(item)) {
                item.disabled = true
              }
            }
          })
        }
      })
      this.store.pipe(select(selectAuthState)).subscribe(data => {
        /* To protect against refreshes */
        const selectedApp = this.getSelectedApp(data)

        this.items.map(
          item =>
            (item.disabled = this.disableNavigationItem(item, selectedApp))
        )
      })
    })
    this.expandedMap['SAGE Structuring Tool'] = false
    this.expandedMap.Group = true
  }

  getSelectedApp(data: State): string {
    if (data.selectedApp && data.selectedApp !== '') {
      return data.selectedApp
    }

    const isFac =
      this.router.url === '/facultative' ||
      this.router.url === '/facultative-admin' ||
      this.router.url === '/fac-management-information'

    return isFac ? 'facultative' : 'sage'
  }

  disableNavigationItem(
    item: NavItem,
    selectedApp: string
  ): boolean | undefined {
    if (
      selectedApp === 'facultative' &&
      item.displayName !== 'Facultative' &&
      item.displayName !== 'Facultative Administration' &&
      item.displayName !== 'FAC Management Information'
    ) {
      return true
    }

    if (
      selectedApp === 'sage' &&
      (item.displayName === 'Facultative' ||
        item.displayName === 'Facultative Administration' ||
        item.displayName === 'FAC Management Information')
    ) {
      return true
    }

    if (
      selectedApp === 'sage' &&
      item.displayName !== 'Facultative' &&
      item.displayName !== 'Facultative Administration' &&
      item.displayName !== 'FAC Management Information'
    ) {
      return item.disabled
    }

    return false
  }

  getKey(item: NavItem): string {
    return item.route || item.displayName
  }

  onItemSelect(item: NavItem): void {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route])
      this.navService.close()
    } else {
      const _expanded = this.expandedMap[this.getKey(item)]
      this.expandedMap[this.getKey(item)] = !_expanded
    }
  }

  checkDisabled(route: string) {
    if (this.allowedGuards) {
      return !this.allowedGuards.has(route)
    }
  }

  ngOnDestroy() {
    this.navSubscription.unsubscribe()
  }

  items: NavItem[] = [
    {
      displayName: 'Program Initiation',
      iconName: 'create_new_folder',
      route: '/program-initiation',
      disabled: false,
    },
    {
      displayName: 'Reinsurer Selection',
      iconName: 'work',
      route: '/reinsurers',
      disabled: false,
    },
    {
      displayName: 'SAGE Structuring Tool',
      iconName: 'bar_chart',
      home: true,
      children: [
        { displayName: 'Pricing Curve', route: '/pricingcurve' },
        { displayName: 'Design', route: '/home' },
        {
          displayName: 'Group',
          route: '/analysis/group',
          disabled: false,
        },
        {
          displayName: 'Compare',
          route: '/analysis/compare',
          disabled: false,
        },
        {
          displayName: 'Metrics',
          route: '/metrics',
          disabled: false,
        },
      ],
    },
    {
      displayName: 'Credit SAGE',
      iconName: 'credit_card',
      children: [
        {
          displayName: 'Pricing Curve',
          route: '/credit/pricingcurve',
          disabled: false,
        },
        {
          displayName: 'Design',
          route: '/credit/design',
          disabled: false,
        },
        {
          displayName: 'Group',
          route: '/credit/group',
          disabled: false,
        },
        {
          displayName: 'Compare',
          route: '/credit/compare',
          disabled: false,
        },
        {
          displayName: 'Metrics',
          route: '/credit/metrics',
          disabled: false,
        },
        {
          displayName: 'Submission',
          route: '/credit/submission',
          disabled: false,
        },
        {
          displayName: 'Monitoring',
          route: '/credit/monitoring',
          disabled: false,
        },
      ],
    },
    {
      displayName: 'Slip Templates',
      iconName: 'book',
      route: '/sliptemplates',
      disabled: false,
    },
    {
      displayName: 'Quote SAGE',
      iconName: 'request_quote',
      children: [
        { displayName: 'Quotes', route: '/quote/main', disabled: false },
        {
          displayName: 'Dashboards',
          route: '/quote/management-information',
          disabled: false,
        },
      ],
    },
    {
      displayName: 'Signature Page',
      iconName: 'content_paste',
      route: '/signature',
      disabled: true,
    },
    {
      displayName: 'Benchmarking',
      iconName: 'show_chart',
      route: '/benchmark',
      disabled: false,
    },
    {
      displayName: 'PROVE',
      iconName: 'map',
      route: '/prove',
      disabled: false,
    },
    {
      displayName: 'Admin Dashboard',
      iconName: 'lock',
      route: '/admin',
      disabled: false,
    },
    {
      displayName: 'Management Information',
      iconName: 'dashboard',
      route: '/management-information',
      disabled: false,
    },
    {
      displayName: 'Revenue Dashboard',
      iconName: 'dashboard',
      route: '/revenue-dashboard',
      disabled: false,
    },
    {
      displayName: 'Facultative Dashboard',
      iconName: 'dashboard',
      route: '/fac-dashboard',
      disabled: false,
    }

    // Currently Not in use
    // {
    //   displayName: 'Explore',
    //   iconName: 'search',
    //   route: '/home',
    //   disabled: false,
    // },
  ]

  allChildrenItemsDisabled(item: NavItem): boolean {
    const foundEnabled = item.children?.some(c => !c.disabled)

    return !foundEnabled
  }
}
