import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { SectionState } from '../store/section/section.reducer'
import {
  LossSetLayer,
  Reinstatement,
} from 'src/app/api/analyzere/analyzere.model'
import { layerIds } from 'src/app/analysis/model/layer-palette.model'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { AccountOpportunity } from 'src/app/api/model/backend.model'
import {
  AssignedLines,
  MultiSectionMapping,
  MultiTotalExpectedForReinsurerVersion,
  QuoteExport,
  QuoteExportAssignedLines,
  QuoteReinsurer,
  ReinsurerPhases,
  Section,
  SubjectivityTracking,
  TotalExpectedForReinsurerVersion,
} from '../models/quote.model'
import getQuotePanelDefs, {
  QuotePanelDefResolved,
  SubjectivityTrackingColumnDef,
} from '../quote-panel/quote-panel-defs'
import { LayerView, LayerViewValues } from 'src/app/analysis/model/layer-view'
import { clone, isNil, sum } from 'ramda'
import { MetricValueType } from 'src/app/core/model/metric-value-type.model'
import { analyzereConstants } from '@shared/constants/analyzere'
import { CurrencyPipe, DatePipe } from '@angular/common'
import { Injectable } from '@angular/core'
import {
  getAssignedLineColumns,
  getAssignedLineRows,
} from './quote-export-assigned-lines.converter'
import {
  fotSummaryRowIds,
  QuoteExportModes,
  QuoteExportRowValue,
} from './quote-excel.model'
import { FullDateToYear } from '@shared/pipes/full-date-to-year'
import layerMetricDefs from 'src/app/analysis/model/layer-metric-defs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class QuoteExportConverter {
  private sectionList: SectionState[]
  private layerState: LayerState[]
  private lossSetLayers: LossSetLayer[]
  private reinsurerList: ReinsurerState[]
  private reinsurerListFormatted: ReinsurerState[]
  private quotePanelDefs: QuotePanelDefResolved[]
  private expiringOpportunity: AccountOpportunity | undefined
  private currentOpportunity: AccountOpportunity | undefined
  private isSLSelected: boolean
  private isGroupSelected: boolean
  private view: LayerView | undefined
  private allViews: LayerView[] | undefined
  private rowsTracking: SubjectivityTracking[]
  private xolExport: boolean

  constructor(
    private readonly currencyPipe: CurrencyPipe,
    private readonly datePipe: DatePipe
  ) {}

  export(
    sectionList: SectionState[],
    layerState: LayerState[],
    view: LayerView | undefined,
    lossSetLayers: LossSetLayer[],
    reinsurerList: ReinsurerState[],
    reinsurerListFormatted: ReinsurerState[],
    quotePanelDefs: QuotePanelDefResolved[],
    expiringOpportunity: AccountOpportunity | undefined,
    currentOpportunity: AccountOpportunity | undefined,
    isSLSelected: boolean,
    isGroupSelected: boolean,
    rowsTracking: SubjectivityTracking[],
    allViews: LayerView[] | undefined,
    subjectivityTrackingColumnDef: SubjectivityTrackingColumnDef[]
  ): QuoteExport {
    // Set export data members
    this.sectionList = sectionList
    this.layerState = layerState
    this.view = view
    this.lossSetLayers = lossSetLayers
    this.reinsurerList = reinsurerList
    this.reinsurerListFormatted = reinsurerListFormatted
    this.quotePanelDefs = quotePanelDefs
    this.expiringOpportunity = expiringOpportunity
    this.currentOpportunity = currentOpportunity
    this.isSLSelected = isSLSelected
    this.isGroupSelected = isGroupSelected
    this.rowsTracking = rowsTracking
    this.allViews = allViews

    this.xolExport = this.layerState.every(
      ({ layer }) =>
        layer.meta_data.sage_layer_type === layerIds.catXl ||
        layer.meta_data.sage_layer_type === layerIds.noncatXl
    )

    const members: number[] = this.calculateLossSetsMembers()
    /* Set export sections by layer */
    let filteredSections: SectionState[] = []
    if (!this.isSLSelected && !this.isGroupSelected) {
      this.sectionList.map(layer => {
        if (layer.section.layerType === layerIds.catMultisection) {
          this.layerState.map(state => {
            if (
              state.layer.id === layer.section.layerRef &&
              state.layer.meta_data.sage_layer_subtype !== 'visible-layer'
            ) {
              filteredSections.push(layer)
            }
          })
        } else {
          if (layer.section.layerName.trim() !== '') {
            filteredSections.push(layer)
          }
        }
      })
    } else if (this.isGroupSelected) {
      this.reinsurerListFormatted.map(x =>
        this.sectionList.map(ls => {
          if (
            ls.section.layerRef === x.reinsurer.cededlayerID &&
            filteredSections.indexOf(ls) === -1
          ) {
            filteredSections.push(ls)
          }
        })
      )
    } else {
      filteredSections = this.sectionList
    }
    const headers: string[] = this.getExportSectionHeaders(filteredSections) // Names of each layer section i.e. ['Layer 1', 'Layer 2', 'Layer 3']
    const multiSectionMappings = this.getMultiSectionMappings(filteredSections) // Gets all multi section layer ids and main layer ids
    const sharedLimitIds = this.getSharedLimitLayerIds(filteredSections) // Gets all shared limit layer ids
    const expiringOppYear = this.expiringOpportunity?.opportunityInceptionDate
    const currentOppYear = this.currentOpportunity?.opportunityInceptionDate

    /* Split reinsurers by their phase - FOT, Expiring, or neither */
    const reinsurerListForExport = this.getReinsurerListSorted([
      ...this.reinsurerList,
    ])
    const expiringReinsurers = reinsurerListForExport.filter(
      r => r.reinsurer.reinsurerPhase === ReinsurerPhases.Expiring
    )
    const nonFotAndExpiringReinsurers = reinsurerListForExport.filter(
      r =>
        r.reinsurer.reinsurerPhase !== ReinsurerPhases.FOT &&
        r.reinsurer.reinsurerPhase !== ReinsurerPhases.Expiring
    )
    const fotReinsurers = reinsurerListForExport.filter(
      r => r.reinsurer.reinsurerPhase === ReinsurerPhases.FOT
    )

    if (this.xolExport) {
      // For the time being, only 1 FOT and Expiring version can be selected per layer
      const layers = this.layerState.map(({ layer }) => layer)
      this.xolExport = layers.every(
        layer =>
          expiringReinsurers
            .filter(({ reinsurer }) => reinsurer.exportToggle)
            .filter(({ reinsurer }) => reinsurer.cededlayerID === layer.id)
            .length <= 1
      )
    }

    // 17071 - Turning on XOL export until further notice
    if (environment.production) {
      this.xolExport = false
    }

    /* Gets the total expected premium & loss values for each reinsurer version of a multi section layer, group and shared limit */
    const multiExpectedVals = this.getMultiSectionTotalExpectedValues(
      nonFotAndExpiringReinsurers,
      multiSectionMappings
    )
    const groupExpectedVals = this.getGroupTotalExpectedValues(
      nonFotAndExpiringReinsurers
    )
    const sharedExpectedVals = this.getSharedLimitExpectedValues(
      nonFotAndExpiringReinsurers,
      sharedLimitIds
    )

    /* Iterate through each filtered section and add export columns/rows for each reinsurer (by phase - FOT, Expiring, etc.) */
    const descriptionColumn: string[] = ['Reinsurer']
    const descriptionRows: string[][][] = []
    const finalExpiringColumns: string[][] = []
    const finalExpiringRows: QuoteExportRowValue[][][][] = []
    const finalNonFotAndExpiringColumns: string[][] = []
    const finalNonFotAndExpiringRows: QuoteExportRowValue[][][][] = []
    const finalFotColumns: string[][] = []
    const finalFotRows: QuoteExportRowValue[][][][] = []
    const quotesSignedPercRow: number[][] = []
    /* Iterate through each filtered section and add assigned lines columns/rows  */
    const assignedLinesData: QuoteExportAssignedLines[][][] = []
    const assignedLinesColumns: string[] = !this.xolExport
      ? [' ', ' ', ' ', ' ']
      : [' '] // Assigned lines columns should start after reinsurer and tpref values in tab
    const assignedLinesRe: string[][] = []
    const assignedLinesTpRef: QuoteExportRowValue[][] = []
    const assignedLinesSubRows: QuoteExportRowValue[][] = []
    const xolSubjectPremiumRows: QuoteExportRowValue[][][] =
      this.getXOLSubjectPremiumRowsForStructure()

    filteredSections.forEach((s, n) => {
      const exportQuotePanelDefs = this.getQuotePanelDefsForSection(s) // gets all the selected terms for this layer's quote panels
      let sectionRows = this.getSectionRows(s.section, exportQuotePanelDefs)

      // Add total expected ceded loss/premium
      if (!this.xolExport) {
        Object.values(exportQuotePanelDefs).forEach(element => {
          if (
            element.id === 'totalQuoteExpectedCededLoss' ||
            element.id === 'totalQuoteExpectedCededPremium'
          ) {
            sectionRows = [...sectionRows, element]
          }
        })
      }
      const descRows = [...this.getDescriptionRowsForSection(sectionRows)]
      descriptionRows.push(descRows) // gets all the export metrics for this section (i.e. Subject Premium, etc.)

      /* Get non fot or expiring rows & columns for this section and append to arrays */
      const [nonFotOrExpiringRows, nonFotOrExpiringColumns] =
        this.getNonFotOrExpiringColumnsAndRowsForSection(
          s,
          sectionRows,
          nonFotAndExpiringReinsurers,
          multiExpectedVals,
          groupExpectedVals,
          sharedExpectedVals
        )
      if (nonFotOrExpiringRows.length > 0) {
        finalNonFotAndExpiringColumns.push(nonFotOrExpiringColumns)
        finalNonFotAndExpiringRows.push(nonFotOrExpiringRows)
      }

      /* Get expiring rows & columns for this section and append to finalExpiring arrays */
      const [expiringRows, expiringColumns] =
        this.getFotOrExpiringColumnsAndRowsForSection(
          s,
          sectionRows,
          expiringReinsurers,
          multiExpectedVals,
          groupExpectedVals,
          sharedExpectedVals
        )
      if (expiringRows.length > 0) {
        finalExpiringColumns.push(expiringColumns)
        finalExpiringRows.push(expiringRows)
      }
      if (finalNonFotAndExpiringRows.length > finalExpiringRows.length) {
        /* Need to add empty value to match section row/column sizes */
        finalExpiringColumns.push([])
        finalExpiringRows.push([])
      }

      /* Get FOT rows & columns for this section and append to finalFot arrays */
      const [fotRows, fotColumns] =
        this.getFotOrExpiringColumnsAndRowsForSection(
          s,
          sectionRows,
          fotReinsurers
        )
      if (fotRows.length > 0) {
        finalFotColumns.push(fotColumns)
        finalFotRows.push(fotRows)
      }
      if (finalNonFotAndExpiringRows.length > finalFotRows.length) {
        /* Need to add empty value to match section row/column sizes */
        finalFotColumns.push([])
        finalFotRows.push([])
      }

      // Get latest FOTs for assigned lines for export
      const latestFOTTemp = [...fotReinsurers]
      latestFOTTemp.reverse()
      const latestFOTs: ReinsurerState[] = []
      latestFOTTemp.forEach((lf, index) => {
        if (index === 0 || (lf && !latestFOTs.includes(lf))) {
          latestFOTs.push(lf)
        }
      })

      /* Update assigned lines data of export for this section */
      const [sectionAlFOTData, sectionAlRe, sectionAlTpRef, sectionAlSubRows] =
        this.getAssignedLinesColumnsAndRowsForSection(s, latestFOTs.reverse())

      const [
        sectionAlExpiringData,
        sectionAlReExpiring,
        sectionAlTpRefExpiring,
      ] = this.getAssignedLinesColumnsAndRowsForSection(s, expiringReinsurers)
      const fotExpiringLayerSet = [
        ...sectionAlExpiringData,
        ...sectionAlFOTData,
      ]
      const percRow = this.getReducedExpiringForSection(s, expiringReinsurers)
      quotesSignedPercRow.push(percRow)
      assignedLinesData.push(fotExpiringLayerSet)

      // We might need to fix this logic to allow same reinsurer multiple times
      if (n === 0) {
        assignedLinesRe.push(...sectionAlRe)
        assignedLinesTpRef.push(...sectionAlTpRef)
      }
      const deDupSectionRe = [...sectionAlRe, ...sectionAlReExpiring]
      deDupSectionRe.forEach(sectionRe => {
        const [, reinsurerName] = sectionRe
        if (!assignedLinesRe.some(val2 => val2[1] === reinsurerName)) {
          assignedLinesRe.push(sectionRe)
        }
      })
      const deDuptpRef = [...sectionAlTpRef, ...sectionAlTpRefExpiring]
      deDuptpRef.forEach(tpRef => {
        const [agencyTPRef, agencySeqNumber] = tpRef
        if (
          !assignedLinesTpRef.some(
            val2 => val2[0] === agencyTPRef && val2[1] === agencySeqNumber
          )
        ) {
          assignedLinesTpRef.push(tpRef)
        }
      })

      assignedLinesSubRows.push(...sectionAlSubRows)
      /* Get final assigned columns */
      // ntd combine expiring and current fots
      if (sectionAlFOTData[0].length) {
        assignedLinesColumns.push(
          ...getAssignedLineColumns(
            !this.xolExport,
            fotExpiringLayerSet,
            expiringOppYear,
            currentOppYear,
            s
          )
        )
      }
    })
    /* Get final assigned lines rows */
    // ! Sort breaks tpRef order, so removed
    assignedLinesData.forEach(ald => {
      if (ald.length > 0) {
        ald.forEach((al: any) => {
          if (al.length > 1) {
            al.sort((a: any, b: any) =>
              a.reinsurer > b.reinsurer ? 1 : b.reinsurer > a.reinsurer ? -1 : 0
            )
          }
        })
      }
    })
    const assignedLinesRows = getAssignedLineRows(
      assignedLinesRe,
      reinsurerListForExport,
      expiringReinsurers,
      fotReinsurers,
      nonFotAndExpiringReinsurers,
      !this.xolExport,
      assignedLinesTpRef,
      assignedLinesData
    )

    const fotSummaryRows: QuoteExportRowValue[][][] = this.getFotSummaryRows(
      expiringReinsurers,
      fotReinsurers,
      currentOppYear
    )

    return {
      descriptionColumn,
      descriptionRows,
      headers,
      expiringReinsurerRows: finalExpiringRows,
      expiringReinsurerColumns: finalExpiringColumns,
      nonFotAndExpiringReinsurerRows: finalNonFotAndExpiringRows,
      nonFotAndExpiringReinsurerColumns: finalNonFotAndExpiringColumns,
      fotReinsurerRows: finalFotRows,
      fotReinsurerColumns: finalFotColumns,
      trackingRows: this.rowsTracking,
      trackingColumns: subjectivityTrackingColumnDef,
      assignedLinesRows,
      assignedLinesColumns,
      assignedLinesSubRows,
      assignedLinesTpRef,
      quotesSignedPercRow,
      members,
      xolSubjectPremiumRows,
      exportMode: this.xolExport
        ? QuoteExportModes.XOL
        : QuoteExportModes.DEFAULT,
      fotSummaryRows,
    }
  }

  private getExportSectionHeaders(sections: SectionState[]): string[] {
    const headers: string[] = []
    sections.forEach(s => {
      if (this.isGroupSelected || this.isSLSelected) {
        headers.push(s.section.layerName)
      } else {
        if (s.section.layerType === layerIds.catMultisection) {
          headers.push(...this.getSectionHeaderIfMultisection(s))
        } else {
          this.layerState.map(layer => {
            if (s.section.layerRef === layer.layer.id) {
              headers.push(
                layer.layer.meta_data.layerName ??
                  layer.layer.physicalLayer.description
              )
            }
          })
        }
      }
    })
    return headers
  }

  private calculateLossSetsMembers(): number[] {
    const layersMembers: number[] = []
    const layersFromSections = this.sectionList.map((l, idx) => {
      if (this.layerState.some(v => v.layer.id === l.section.layerRef)) {
        return this.layerState[idx]
      }
    })
    layersFromSections.forEach(l => {
      const layerMember = this.lossSetLayers
        .filter(ls => l?.layer.lossSetLayers?.some(_ls => _ls.id === ls.id))
        .reduce(
          (acc, { meta_data }) =>
            meta_data && meta_data.members ? acc + meta_data.members : acc,
          0
        )
      layersMembers.push(layerMember)
    })
    return layersMembers
  }

  private getMultiSectionMappings(
    sections: SectionState[]
  ): MultiSectionMapping[] {
    const mappings: MultiSectionMapping[] = []
    sections.forEach(s => {
      // Check if multisection
      if (s.section.layerType === layerIds.catMultisection) {
        // For each ms layer, iterate through all section layers
        this.layerState.map(data => {
          if (
            data.layer.id === s.section.layerRef &&
            data.layer.meta_data.sage_layer_subtype === 'section-layer'
          ) {
            this.layerState
              .filter(state =>
                state.layer.layerRefs.includes(s.section.layerRef)
              )
              .map(mainLayer => {
                if (
                  mainLayer.layer.meta_data.sage_layer_subtype === 'main-layer'
                ) {
                  mappings.push({
                    mainLayerId: mainLayer.layer.id,
                    mainLayerName:
                      mainLayer.layer.meta_data.layerName ??
                      `Main Layer ${mainLayer.layer.id}`,
                    sectionLayerId: data.layer.id,
                    sectionLayerName:
                      data.layer.physicalLayer.description ?? '',
                  })
                }
              })
          }
        })
      }
    })
    return mappings
  }

  private getMultiSectionTotalExpectedValues(
    nonFotAndExpiringReinsurers: ReinsurerState[],
    multiSections: MultiSectionMapping[]
  ): MultiTotalExpectedForReinsurerVersion[] {
    const sectionAVals = multiSections.filter(multi =>
      multi.sectionLayerName.includes('Section A')
    )
    const multiExpectedVals: MultiTotalExpectedForReinsurerVersion[] = []
    sectionAVals.forEach(aVal => {
      const reFound = nonFotAndExpiringReinsurers.filter(
        r => r.reinsurer.cededlayerID === aVal.sectionLayerId
      )
      reFound.forEach(reinsurer => {
        multiExpectedVals.push({
          reinsurerName: reinsurer.reinsurer.quoteReinsurerName ?? '',
          reinsurerVersion: reinsurer.reinsurer.reinsurerPhaseVersion,
          totalQuoteExpectedCededLoss:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0,
          totalQuoteExpectedCededPremium:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0,
          sections: multiSections.filter(
            multi => multi.mainLayerId === aVal.mainLayerId
          ) /* get all sections that have the same mainLayerId */,
        })
      })
    })
    return multiExpectedVals
  }

  getSharedLimitLayerIds(sections: SectionState[]): string[] {
    return sections
      .map(s =>
        s.section.layerType === 'shared_limits' ? s.section.layerRef : undefined
      )
      .filter(id => id !== undefined) as string[]
  }

  private getReinsurerListSorted(
    reinsurerList: ReinsurerState[]
  ): ReinsurerState[] {
    return reinsurerList.sort((a, b) => {
      if (a.reinsurer.quoteReinsurerName && b.reinsurer.quoteReinsurerName) {
        if (a.reinsurer.quoteReinsurerName < b.reinsurer.quoteReinsurerName) {
          return -1
        }
        if (a.reinsurer.quoteReinsurerName > b.reinsurer.quoteReinsurerName) {
          return 1
        }
      }
      return 0
    })
  }

  private getQuotePanelDefsForSection(
    s: SectionState
  ): QuotePanelDefResolved[] {
    let exportQuotePanelDefs = this.quotePanelDefs
    if (this.allViews) {
      const view = this.allViews.find(
        ({ layer }) => layer.id === s.section.layerRef
      )
      if (!view) {
        return []
      }
      exportQuotePanelDefs = getQuotePanelDefs(
        view.values,
        false,
        'Both ECO & XPL apply',
        false
      )
    }
    return exportQuotePanelDefs
  }

  private getSectionRows(
    s: Section,
    defs: QuotePanelDefResolved[]
  ): QuotePanelDefResolved[] {
    const arr: QuotePanelDefResolved[] = []
    Object.values(defs).forEach(element => {
      for (const [key, value] of Object.entries(s)) {
        if (element.exportID === key && value === true) {
          arr.push(element)
        }
      }
    })
    return arr
  }

  private getDescriptionRowsForSection(
    sectionRows: QuotePanelDefResolved[]
  ): string[][] {
    const head: string[][] = []

    sectionRows.forEach(row => {
      if (row.label === 'Sliding Scale Commission') {
        head.push([row.label, 'text'])
        head.push(['Min', 'text'])
        head.push(['Slide', 'text'])
        head.push(['Provisional', 'text'])
        head.push(['Slide', 'text'])
        head.push(['Max', 'text'])
      } else {
        head.push([row.label, 'text'])
      }
    })

    return head
  }

  private getXOLSubjectPremiumRowsForStructure(): QuoteExportRowValue[][][] {
    // Define the static header values
    const sumOfLossSets = sum(this.lossSetLayers.map(layer => layer.premium.value))
    const subjectPremiumRows: QuoteExportRowValue[][][] = [
      [
        ['Subject Premium', 'text'],
        [
          this.convertCurrencyToSymbol(
            !!sumOfLossSets ? sumOfLossSets : 0,
            this.view.currency
          ),
        ],
      ],
      [[''], ['Occurrence Limit', 'text'], ['Occurrence Attachment', 'text']],
    ]
    this.allViews.forEach(view => {
      const layer = view.layer
      subjectPremiumRows.push([
        [layer.meta_data.layerName ?? '', 'text'],
        [
          this.convertCurrencyToSymbol(view.occurrenceLimit, view.currency),
          'currency',
        ],
        [
          this.convertCurrencyToSymbol(
            view.occurrenceAttachment,
            view.currency
          ),
          'currency',
        ],
      ])
    })
    return subjectPremiumRows
  }

  private getFotOrExpiringColumnsAndRowsForSection(
    s: SectionState,
    sectionRows: QuotePanelDefResolved[],
    fotOrExpiringReinsurers: ReinsurerState[],
    multiExpectedVals?: MultiTotalExpectedForReinsurerVersion[],
    groupExpectedVals?: TotalExpectedForReinsurerVersion[],
    sharedExpectedVals?: TotalExpectedForReinsurerVersion[]
  ): readonly [QuoteExportRowValue[][][], string[]] {
    const reinsurerColumns: string[] = []
    const reinsurerRows: QuoteExportRowValue[][][] = []
    fotOrExpiringReinsurers.forEach(r => {
      const reRow: QuoteExportRowValue[][] = []
      /* For total expected ceded loss / premium */
      let totalQuoteExpectedCededLoss = 0
      let totalQuoteExpectedCededPremium = 0
      const totalExpectedMultiVals = multiExpectedVals?.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const totalExpectedGroupVals = groupExpectedVals?.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const totalExpectedSharedVals = sharedExpectedVals?.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      if (this.validateReinsurer(r.reinsurer, s.section.layerRef)) {
        reinsurerColumns.push(this.labelReinsurer(r.reinsurer))
        if (sectionRows.length > 0 && r.reinsurer.quoteFields) {
          fotOrExpiringReinsurers.forEach(x => {
            if (!this.validateReinsurer(x.reinsurer, s.section.layerRef)) {
              return
            }
            if (
              r.reinsurer.quoteReinsurerName ===
                x.reinsurer.quoteReinsurerName &&
              r.reinsurer.reinsurerPhaseLabel ===
                x.reinsurer.reinsurerPhaseLabel
            ) {
              if (
                s.section.layerType === layerIds.catMultisection ||
                this.isSLSelected
              ) {
                return
              } else {
                if (
                  r.reinsurer.quoteFields &&
                  x.reinsurer.quoteFields?.quoteExpectedCededLoss &&
                  x.reinsurer.quoteFields.quoteExpectedCededPremium
                ) {
                  totalQuoteExpectedCededLoss +=
                    x.reinsurer.quoteFields.quoteExpectedCededLoss.value
                  totalQuoteExpectedCededPremium +=
                    x.reinsurer.quoteFields.quoteExpectedCededPremium.value
                }
              }
            }
          })
          let checkSlidingScale = false
          sectionRows.forEach((sec, ind) => {
            const layerSectionObj = JSON.parse(JSON.stringify(s))
            for (const [key, value] of Object.entries(
              r.reinsurer.quoteFields as any
            )) {
              if (sec.id === key && sec.id !== 'slidingComm') {
                const layerValue = JSON.parse(JSON.stringify(value))
                this.view?.layers?.map(layer => {
                  if (
                    r.reinsurer.cededlayerID === layer.layer.id &&
                    layerValue &&
                    layerValue.currency
                  ) {
                    layerValue.currency = layer.layer.currency
                  }
                  if (r.reinsurer.cededlayerID === layer.layer.id) {
                    layerSectionObj.section.layerCurrency = layer.layer.currency
                  }
                })
                reRow.push([
                  this.calculateValue(
                    layerValue,
                    // tslint:disable-next-line: no-non-null-assertion
                    sec.valueType!,
                    layerSectionObj.section.layerCurrency
                  ),
                  // tslint:disable-next-line: no-non-null-assertion
                  sec.valueType!,
                ])
              }
            }
            if (sec.id === 'slidingComm' && r.reinsurer.slidingScale) {
              checkSlidingScale = true
              reRow.push(['', 'text'])
              const min =
                r.reinsurer.slidingScale[0].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[0].lossRatio * 100 +
                '%'
              reRow.push([min, 'text'])
              const slide1 =
                (r.reinsurer.slidingScale[0].slideRate || 0) * 100 + ' : 1'
              reRow.push([slide1, 'text'])
              const prov =
                r.reinsurer.slidingScale[1].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[1].lossRatio * 100 +
                '%'
              reRow.push([prov, 'text'])
              const slide2 =
                (r.reinsurer.slidingScale[2].slideRate || 0) * 100 + ' : 1'
              reRow.push([slide2, 'text'])
              const max =
                r.reinsurer.slidingScale[2].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[2].lossRatio * 100 +
                '%'
              reRow.push([max, 'text'])
            }
            if (sec.id === 'totalQuoteExpectedCededLoss') {
              if (s.section.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededLoss =
                  totalExpectedMultiVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedGroupVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedSharedVals?.totalQuoteExpectedCededLoss ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.section.layerCurrency
                  ))
                : (reRow[ind][0] = this.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.section.layerCurrency
                  ))
            }
            if (sec.id === 'totalQuoteExpectedCededPremium') {
              if (s.section.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededPremium =
                  totalExpectedMultiVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedGroupVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedSharedVals?.totalQuoteExpectedCededPremium ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.section.layerCurrency
                  ))
                : (reRow[ind][0] = this.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.section.layerCurrency
                  ))
            }
          })
        }
        reinsurerRows.push(reRow)
      }
    })
    return [reinsurerRows, reinsurerColumns] as const
  }

  private getNonFotOrExpiringColumnsAndRowsForSection(
    s: SectionState,
    sectionRows: QuotePanelDefResolved[],
    nonFotAndExpiringReinsurers: ReinsurerState[],
    multiExpectedVals: MultiTotalExpectedForReinsurerVersion[],
    groupExpectedVals: TotalExpectedForReinsurerVersion[],
    sharedExpectedVals: TotalExpectedForReinsurerVersion[]
  ): readonly [QuoteExportRowValue[][][], string[]] {
    const reinsurerColumns: string[] = []
    const reinsurerRows: QuoteExportRowValue[][][] = []
    let weightedRol = 0
    let weightedRateOnLineSubject = 0
    let offeredPercentageTotal = 0
    nonFotAndExpiringReinsurers.forEach(r => {
      /* For total expected ceded loss / premium */
      let totalQuoteExpectedCededLoss = 0
      let totalQuoteExpectedCededPremium = 0
      const totalExpectedMultiVals = multiExpectedVals.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const totalExpectedGroupVals = groupExpectedVals.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const totalExpectedSharedVals = sharedExpectedVals.find(
        expected =>
          expected.reinsurerName === r.reinsurer.quoteReinsurerName &&
          expected.reinsurerVersion === r.reinsurer.reinsurerPhaseVersion
      )
      const reRow: QuoteExportRowValue[][] = []
      if (this.validateReinsurer(r.reinsurer, s.section.layerRef)) {
        let num = 0
        nonFotAndExpiringReinsurers.forEach(q => {
          if (
            this.validateReinsurer(q.reinsurer, s.section.layerRef) &&
            q.reinsurer.quoteReinsurerName === r.reinsurer.quoteReinsurerName
          ) {
            num += 1
          }
        })
        reinsurerColumns.push(this.labelReinsurer(r.reinsurer, 'quote', num))
        if (
          sectionRows.length > 0 &&
          r.reinsurer.quoteFields &&
          !r.reinsurer.decline &&
          this.validateReinsurer(r.reinsurer, s.section.layerRef)
        ) {
          nonFotAndExpiringReinsurers.forEach(x => {
            if (!this.validateReinsurer(x.reinsurer, s.section.layerRef)) {
              return
            }
            if (
              r.reinsurer.quoteReinsurerName ===
                x.reinsurer.quoteReinsurerName &&
              r.reinsurer.reinsurerPhaseLabel ===
                x.reinsurer.reinsurerPhaseLabel
            ) {
              if (
                s.section.layerType === layerIds.catMultisection ||
                this.isSLSelected
              ) {
                return
              } else {
                if (
                  r.reinsurer.quoteFields &&
                  x.reinsurer.quoteFields?.quoteExpectedCededLoss &&
                  x.reinsurer.quoteFields.quoteExpectedCededPremium
                ) {
                  totalQuoteExpectedCededLoss +=
                    x.reinsurer.quoteFields.quoteExpectedCededLoss.value
                  totalQuoteExpectedCededPremium +=
                    x.reinsurer.quoteFields.quoteExpectedCededPremium.value
                }
              }
            }
          })
          let checkSlidingScale = false

          const offeredPctg =
            r.reinsurer.quoteFields.quoteOfferedPercentage ?? 0
          const rol = r.reinsurer.quoteFields.quoteRolPercentage ?? 0
          const rateOnLineSubject =
            r.reinsurer.quoteFields.quoteRateOnLineSubject ?? 0

          weightedRol += rol * offeredPctg
          weightedRateOnLineSubject += rateOnLineSubject * offeredPctg
          offeredPercentageTotal += offeredPctg
          sectionRows.forEach((sec, ind) => {
            const layerSectionObj = clone(s)

            for (const [key, value] of Object.entries(
              r.reinsurer.quoteFields
            )) {
              if (sec.id === key && sec.id !== 'slidingComm') {
                const layerValue = JSON.parse(JSON.stringify(value))

                this.view?.layers?.map(layer => {
                  if (
                    r.reinsurer.cededlayerID === layer.layer.id &&
                    layerValue &&
                    layerValue.currency
                  ) {
                    layerValue.currency = layer.layer.currency
                  }
                  if (r.reinsurer.cededlayerID === layer.layer.id) {
                    layerSectionObj.section.layerCurrency = layer.layer.currency
                  }
                })
                reRow.push([
                  this.calculateValue(
                    layerValue,
                    // tslint:disable-next-line: no-non-null-assertion
                    sec.valueType!,
                    layerSectionObj.section.layerCurrency
                  ),
                  // tslint:disable-next-line: no-non-null-assertion
                  sec.valueType!,
                ])
              }
            }
            if (sec.id === 'slidingComm' && r.reinsurer.slidingScale) {
              checkSlidingScale = true
              reRow.push(['', 'text'])
              const min =
                r.reinsurer.slidingScale[0].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[0].lossRatio * 100 +
                '%'
              reRow.push([min, 'text'])
              const slide1 =
                (r.reinsurer.slidingScale[0].slideRate || 0) * 100 + ' : 1'
              reRow.push([slide1, 'text'])
              const prov =
                r.reinsurer.slidingScale[1].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[1].lossRatio * 100 +
                '%'
              reRow.push([prov, 'text'])
              const slide2 =
                (r.reinsurer.slidingScale[2].slideRate || 0) * 100 + ' : 1'
              reRow.push([slide2, 'text'])
              const max =
                r.reinsurer.slidingScale[2].commission * 100 +
                '%' +
                ' @ ' +
                r.reinsurer.slidingScale[2].lossRatio * 100 +
                '%'
              reRow.push([max, 'text'])
            }
            if (sec.id === 'totalQuoteExpectedCededLoss') {
              if (s.section.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededLoss =
                  totalExpectedMultiVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedGroupVals?.totalQuoteExpectedCededLoss ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededLoss =
                  totalExpectedSharedVals?.totalQuoteExpectedCededLoss ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.section.layerCurrency
                  ))
                : (reRow[ind][0] = this.convertCurrencyToSymbol(
                    totalQuoteExpectedCededLoss,
                    layerSectionObj.section.layerCurrency
                  ))
            }
            if (sec.id === 'totalQuoteExpectedCededPremium') {
              if (s.section.layerType === layerIds.catMultisection) {
                totalQuoteExpectedCededPremium =
                  totalExpectedMultiVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isGroupSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedGroupVals?.totalQuoteExpectedCededPremium ?? 0
              }
              if (this.isSLSelected) {
                totalQuoteExpectedCededPremium =
                  totalExpectedSharedVals?.totalQuoteExpectedCededPremium ?? 0
              }
              checkSlidingScale
                ? (reRow[ind + 5][0] = this.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.section.layerCurrency
                  ))
                : (reRow[ind][0] = this.convertCurrencyToSymbol(
                    totalQuoteExpectedCededPremium,
                    layerSectionObj.section.layerCurrency
                  ))
            }
          })
        }
        reinsurerRows.push(reRow)
      }
    })

    // Adds the summary rows to the left of the quote panels, not needed for XOL
    if (!this.xolExport) {
      const denom = offeredPercentageTotal !== 0 ? offeredPercentageTotal : 1
      const weightedAvgRateOnLine = weightedRol / denom
      const weightedAvgRatePercentageOfSubject =
        weightedRateOnLineSubject / denom
      const summariesValuesRow: any[] = []
      const summariesHeadersRow: any[] = []
      const fields: Record<string, { value: number; label: string }> = {
        quoteOfferedPercentage: {
          value: offeredPercentageTotal,
          label: 'Total Offered %:',
        },
        quoteRolPercentage: {
          value: weightedAvgRateOnLine,
          label: 'Weighted Avg. Rate On-Line, Occ:',
        },
        quoteRateOnLineSubject: {
          value: weightedAvgRatePercentageOfSubject,
          label: 'Weighted Avg. Rate, % of Subject:',
        },
      }
      const keys = Object.keys(fields)
      sectionRows.forEach(section => {
        const included = keys.includes(section.id)
        summariesHeadersRow.push([
          included ? fields[section.id].label : '',
          'text',
        ])
        summariesValuesRow.push([
          included ? fields[section.id].value : '',
          'percentage',
        ])
      })

      reinsurerRows.push(summariesHeadersRow)
      reinsurerRows.push(summariesValuesRow)
      reinsurerColumns.push('', '')
    }

    return [reinsurerRows, reinsurerColumns] as const
  }

  private getReducedExpiringForSection(
    s: SectionState,
    fotReinsurers: ReinsurerState[]
  ): number[] {
    const signPerc: number[] = []
    fotReinsurers.forEach(r => {
      if (this.validateReinsurer(r.reinsurer, s.section.layerRef)) {
        if (r.reinsurer.riskAssignedLinesLink) {
          const signedPercTotal = r.reinsurer.riskAssignedLinesLink.reduce(
            (tot, curr) => (curr.signed ? tot + curr.signed : tot),
            0
          )
          signPerc.push(signedPercTotal)
        }
      }
    })
    return signPerc
  }

  private getAssignedLinesColumnsAndRowsForSection(
    s: SectionState,
    fotReinsurers: ReinsurerState[]
  ): readonly [
    AssignedLines[][],
    string[][],
    QuoteExportRowValue[][],
    QuoteExportRowValue[][],
  ] {
    const assignedLinesData: AssignedLines[][] = []
    const assignedLinesTpRef: QuoteExportRowValue[][] = []
    const assignedLinesSubRows: QuoteExportRowValue[][] = []
    let reinsurerSections: AssignedLines[] = []

    fotReinsurers.forEach(r => {
      if (r && this.validateReinsurer(r.reinsurer, s.section.layerRef)) {
        if (r.reinsurer.riskAssignedLinesLink) {
          let limit = 0
          if (
            s.section.layerType === layerIds.catAg ||
            s.section.layerType === layerIds.noncatAg ||
            s.section.layerType === layerIds.ahlAg
          ) {
            limit = r.reinsurer.quoteFields?.quoteAggregateLimit?.value || 0
          } else if (s.section.layerType === layerIds.noncatRisk) {
            limit = r.reinsurer.quoteFields?.quoteRiskLimit?.value || 0
          } else {
            limit = r.reinsurer.quoteFields?.quoteOccurrenceLimit?.value || 0
          }
          const aLinks = r.reinsurer.riskAssignedLinesLink.map(e => {
            return {
              ...e,
              limit,
              layer: s.section.layerName,
            }
          })

          reinsurerSections = [...reinsurerSections, ...aLinks]
          r.reinsurer.riskAssignedLinesLink.forEach(a => {
            const subjectivityALRow: any[] = []
            assignedLinesTpRef.push([
              a.marketTpRef || '0',
              a.relationSeqNumber || 0,
            ])

            if (a.subjectivity || a.contract) {
              subjectivityALRow.push(
                a.reinsurer,
                s.section.layerName,
                a.contract,
                a.subjectivity,
                ''
              )
              assignedLinesSubRows.push(subjectivityALRow)
            }
          })
        }
      }
    })
    const layerSections = reinsurerSections.map(re => ({
      id: re.id,
      brokerage: re.brokerage,
      brokerageRe: re.brokerageRe,
      recommended: re.recommended,
      signedPercentage: re.signed,
      signedCurrency: re.signed && re.limit ? re.signed * re.limit : 0,
      writtenPercentage: re.written,
      writtenCurrency: re.written && re.limit ? re.written * re.limit : 0,
      placedThrough: re.placedThrough,
      coBroker: re.coBroker,
      leadMarket: re.leadMarket,
      reinsurer: re.reinsurer,
      tpRef: re.marketTpRef,
    }))

    const assignedLinesRe = reinsurerSections.map(re => [re.id, re.reinsurer])
    assignedLinesData.push(layerSections)

    return [
      assignedLinesData,
      assignedLinesRe,
      assignedLinesTpRef,
      assignedLinesSubRows,
    ] as const
  }

  private validateReinsurer(reinsurer: QuoteReinsurer, layerRef: string) {
    return (
      reinsurer.cededlayerID === layerRef &&
      reinsurer.quoteReinsurerName &&
      !reinsurer.decline &&
      reinsurer.exportToggle === true
    )
  }

  private labelReinsurer(
    reinsurer: QuoteReinsurer,
    type?: string,
    num?: number
  ) {
    const {
      quoteReinsurerName: reinsurerName,
      reinsurerPhaseLabel: phaseLabel,
      reinsurerPhaseVersion: phaseVersion,
    } = reinsurer

    let label = isNil(phaseLabel) ? phaseVersion : phaseLabel

    if (type === 'quote') {
      if (num === 1) {
        label = ''
      }
    }
    return `${reinsurerName} ${label}`
  }

  calculateValue(value: any, type: MetricValueType, currency: string) {
    const numberTransform = (val: number) =>
      this.isValueUnlimited(val) ? analyzereConstants.unlimitedValue : val
    if (value === null) {
      if (
        type === 'currency' ||
        type === 'numeric' ||
        type === 'percentage' ||
        type === 'ratio' ||
        type === 'formatted-onedecimal-numeric' ||
        type === 'unformatted-numeric'
      ) {
        value = 0
      } else {
        value = ''
      }
      return value
    }
    if (value && (value.value || value.value === 0) && value.currency) {
      if (value.value === null) {
        value.value = 0
        return this.convertCurrencyToSymbol(value.value, value.currency)
      }
      if (
        value.value.toString().indexOf('e+') > -1 ||
        value.value.toString() === 'Unlimited' ||
        (!isNaN(Number(value.value)) &&
          Number(value.value) >=
            numberTransform(analyzereConstants.unlimitedValue))
      ) {
        return 'Unlimited'
      } else {
        return this.convertCurrencyToSymbol(value.value || 0, value.currency)
      }
    } else {
      if (type === 'currency') {
        return this.convertCurrencyToSymbol(value || 0, currency)
      } else if (type === 'percentage') {
        return value || 0
      } else if (type === 'dropdown') {
        return value || ''
      } else if (type === 'number-list') {
        const re: { counter: number; premium: number }[] = []
        if (value.length > 0) {
          const premiumsList: number[] = []
          value.forEach((v: Reinstatement) => {
            if (v.premium !== undefined) {
              premiumsList.push(v.premium)
            }
          })

          let current = -1
          let counter = 0
          const counterList: number[][] = []

          premiumsList.forEach((p, i) => {
            if (i === premiumsList.length - 1 && premiumsList[i - 1] === p) {
              counterList.push([counter + 1, current])
            }

            if (current !== p) {
              if (current !== -1) {
                counterList.push([counter, current])
              }
              current = p
              counter = 1
            } else {
              counter += 1
            }

            if (i === premiumsList.length - 1 && premiumsList[i - 1] !== p) {
              current = p
              counter = 1
              counterList.push([counter, current])
            }
          })

          counterList.forEach(c => {
            re.push({ counter: c[0], premium: c[1] })
          })
        }

        let val = ''
        if (re.length > 0) {
          re.forEach(r => {
            val += ' ' + r.counter + '@' + r.premium * 100 + '%,'
          })
        }
        return val
      } else if (type === 'date') {
        return this.datePipe.transform(value, 'MM/dd/yyyy') ?? ''
      } else if (type === 'multiselect') {
        return value.join(', ')
      } else {
        return value || ''
      }
    }
  }

  private isValueUnlimited(num: number): boolean {
    return num.toString().toLowerCase().includes('e')
  }

  private getGroupTotalExpectedValues(
    nonFotAndExpiringReinsurers: ReinsurerState[]
  ): TotalExpectedForReinsurerVersion[] {
    const groupExpectedVals: TotalExpectedForReinsurerVersion[] = []
    nonFotAndExpiringReinsurers.forEach(reinsurer => {
      const reFoundIndex = groupExpectedVals.findIndex(
        val =>
          val.reinsurerName === reinsurer.reinsurer.quoteReinsurerName &&
          val.reinsurerVersion === reinsurer.reinsurer.reinsurerPhaseVersion
      )
      if (reFoundIndex < 0) {
        groupExpectedVals.push({
          reinsurerName: reinsurer.reinsurer.quoteReinsurerName ?? '',
          reinsurerVersion: reinsurer.reinsurer.reinsurerPhaseVersion,
          totalQuoteExpectedCededLoss:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0,
          totalQuoteExpectedCededPremium:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0,
        })
      } else {
        const newExpectedCdedLoss =
          groupExpectedVals[reFoundIndex].totalQuoteExpectedCededLoss +
          (reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0)
        const newExpectedCdedPremium =
          groupExpectedVals[reFoundIndex].totalQuoteExpectedCededPremium +
          (reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0)
        groupExpectedVals[reFoundIndex] = {
          ...groupExpectedVals[reFoundIndex],
          totalQuoteExpectedCededLoss: newExpectedCdedLoss,
          totalQuoteExpectedCededPremium: newExpectedCdedPremium,
        }
      }
    })
    return groupExpectedVals
  }

  private getSharedLimitExpectedValues(
    nonFotAndExpiringReinsurers: ReinsurerState[],
    sharedLimitIds: string[]
  ): TotalExpectedForReinsurerVersion[] {
    const sharedExpectedVals: TotalExpectedForReinsurerVersion[] = []
    nonFotAndExpiringReinsurers.forEach(reinsurer => {
      const sharedFound = sharedLimitIds.find(
        sharedId => sharedId === reinsurer.reinsurer.cededlayerID
      )
      if (sharedFound) {
        sharedExpectedVals.push({
          reinsurerName: reinsurer.reinsurer.quoteReinsurerName ?? '',
          reinsurerVersion: reinsurer.reinsurer.reinsurerPhaseVersion,
          totalQuoteExpectedCededLoss:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededLoss?.value ?? 0,
          totalQuoteExpectedCededPremium:
            reinsurer.reinsurer.quoteFields?.quoteExpectedCededPremium?.value ??
            0,
        })
      }
    })
    return sharedExpectedVals
  }

  private convertCurrencyToSymbol(value: number | {value: number | null}, currencyCode: string): any {
    const val = typeof value === 'object' ? value.value ?? 0 : value
    return this.currencyPipe.transform(
      val,
      currencyCode,
      'symbol-narrow',
      '1.0-0'
    )
  }

  private getSectionHeaderIfMultisection(s: SectionState): string[] {
    const multiHeaders: string[] = []
    this.layerState.map(data => {
      if (
        data.layer.id === s.section.layerRef &&
        data.layer.meta_data.sage_layer_subtype === 'section-layer'
      ) {
        this.layerState
          .filter(state => state.layer.layerRefs.includes(s.section.layerRef))
          .map(mainLayer => {
            if (mainLayer.layer.meta_data.sage_layer_subtype === 'main-layer') {
              multiHeaders.push(
                data.layer.physicalLayer.description +
                  ':' +
                  mainLayer.layer.meta_data.layerName
              )
            }
          })
      }
    })
    return multiHeaders
  }

  private getFotSummaryRows(
    expiringReinsurers: ReinsurerState[],
    fotReinsurers: ReinsurerState[],
    currentOppYear: string
  ): QuoteExportRowValue[][][] {
    const rows: QuoteExportRowValue[][][] = []

    const layers = this.layerState.map(({ layer }) => layer)
    layers.forEach(layer => {
      const expiring = expiringReinsurers
        .filter(({ reinsurer }) => reinsurer.exportToggle)
        .find(({ reinsurer }) => reinsurer.cededlayerID === layer.id)

      const fot = fotReinsurers
        .filter(({ reinsurer }) => reinsurer.exportToggle)
        .find(({ reinsurer }) => reinsurer.cededlayerID === layer.id)

      if (expiring || fot) {
        rows.push([
          [layer.meta_data.layerName ?? '', 'text'],
          [' '],
          [' '],
          [' '],
          [' '],
        ])

        // Expiring / FOT Title Row, YoY Rate % Header
        rows.push([
          [' '],
          ['Expiring', 'text'],
          [
            `${currentOppYear && new FullDateToYear().transform(currentOppYear)} FOT`,
            'text',
          ],
          [' '],
          ['YoY Rate % Change', 'text'],
        ])

        fotSummaryRowIds.forEach((id, i) => {
          const metricDef = layerMetricDefs[id]
          const valueType = metricDef.valueType
          const label =
            typeof metricDef.label === 'string'
              ? metricDef.label
              : 'Rate-on-Line, Occurrence'
          const defTitleEntry = [label as string, 'text']
          let expiringEntry: QuoteExportRowValue[] | undefined
          let fotEntry: QuoteExportRowValue[] | undefined

          const emptyEntry = [
            valueType === 'currency'
              ? `${this.convertCurrencyToSymbol(0, layer.currency)}`
              : 0,
            valueType,
          ]
          if (expiring) {
            expiringEntry = this.getFotSummaryRowValueForReinsurer(
              expiring,
              id,
              valueType,
              layer.currency
            )
          } else {
            expiringEntry = emptyEntry
          }
          if (fot) {
            fotEntry = this.getFotSummaryRowValueForReinsurer(
              fot,
              id,
              valueType,
              layer.currency
            )
          } else {
            fotEntry = emptyEntry
          }

          const row = [defTitleEntry, expiringEntry, fotEntry]
          if (i === 0) {
            const expiringVal = !!expiringEntry[0]
              ? Number(expiringEntry[0])
              : 0
            const fotVal = !!fotEntry[0] ? Number(fotEntry[0]) : 0
            const ratePercentageChange = !!expiringVal
              ? (fotVal - expiringVal) / expiringVal
              : 'Infinity'
            row.push(
              [' '],
              [
                this.calculateValue(
                  ratePercentageChange,
                  valueType,
                  layer.currency
                ),
                'percentage',
              ]
            )
          }
          rows.push(row)
        })
      }
    })

    return rows
  }

  private getFotSummaryRowValueForReinsurer(
    reinsurer: ReinsurerState,
    id: keyof LayerViewValues,
    valueType: MetricValueType,
    currency: string
  ): QuoteExportRowValue[] {
    const quoteFields = reinsurer.reinsurer.quoteFields
    const entryValue = (quoteFields as any)[id] ?? ''
    const calculatedValue = this.calculateValue(entryValue, valueType, currency)
    const finalValue = !!calculatedValue
      ? calculatedValue
      : valueType === 'currency'
        ? `${this.convertCurrencyToSymbol(0, currency)}`
        : 0
    return [finalValue, valueType]
  }
}
