import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { clone, groupBy } from 'ramda'
import { LayerView, LayerViewValues } from '../../analysis/model/layer-view'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import {
  QUOTE_PANEL_DEFS_TOGGLE_MAP,
  QuotePanelDefResolved,
  SlidingScaleColumnDef,
  slidingScaleDef,
} from './quote-panel-defs'
import {
  EXPIRING_REINSURER_NAME,
  FOT_MASTER_REINSURER_NAME,
  InuringLayer,
  QuoteFields,
  QuoteReinsurer,
  Reinstatements,
  ReinsurerPhases,
  Section,
  SlidingScale,
} from '../models/quote.model'
import { Layer } from '../../analysis/model/layers.model'
import { QuoteReinsurerService } from '../../api/quote-reinsurer/quote-reinsurer.service'
import { selectCurrentAnalysisProfile } from '../../core/store/broker/broker.selectors'
import { select, Store } from '@ngrx/store'
import { AppState } from '../../core/store'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { Territories } from '../../api/territory/territory.service'
import { SortTableValueChangeEvent } from '@shared/sort-table/sort-table.model'
import { layerIds } from '../../analysis/model/layer-palette.model'
import { selectCurrentProgram } from '../../analysis/store/analysis.selectors'
import { DependentValue } from '@shared/layer-property/layer-property.component'
import { Reinstatement } from '../../api/analyzere/analyzere.model'
import { SectionState } from '../store/section/section.reducer'
import { AccountOpportunity } from 'src/app/api/model/backend.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-panel',
  styleUrls: ['./quote-panel.component.scss'],
  templateUrl: './quote-panel.component.html',
})
export class QuotePanelComponent implements OnInit, OnChanges, OnDestroy {
  quotePanelDefsToggleMap = QUOTE_PANEL_DEFS_TOGGLE_MAP
  isFotMaster = false
  isExpiring = false
  selectedFOTMasterReinsurer: number[] = []
  selectedFOTMasterID: string[] = []
  selectedExpiringReinsurer: number[] = []
  selectedExpiringID: string[] = []
  getAllQuoteReinsurers: QuoteReinsurer[] = []
  getAllGroupReinsurers: QuoteReinsurer[] = []
  finalMax = 0
  finalMin = 0
  finalAvg = 0
  finalAvgEx = 0
  finalAvgExIsVisible = true
  isDecline = false
  cededPremium = 0
  slidingScaleDefCol: SlidingScaleColumnDef[] = []
  slidingScaleRows: SlidingScale[] = []
  isLibRE = false
  private destroy$ = new Subject()

  @Input() layer: LayerState | null | undefined
  @Input() layerGroup: LayerState | null | undefined
  @Input() view: LayerView | null
  @Input() isNew: boolean
  @Input() quotePanelDefs: QuotePanelDefResolved[]
  @Input() currentReinsurer: QuoteReinsurer | undefined
  @Input() selectedReinsurer: string | null
  @Input() isExpanded: boolean
  @Input() expandedName: string | null
  @Input() allReinsurers: QuoteReinsurer[]
  @Input() selectedSection?: Section | null
  @Input() sectionList: SectionState[]
  @Input() firstReinsurerName: string
  @Input() isGroupSelected = false
  @Input() layerStateGroup: LayerState[] = []
  @Input() isSLSelected = false
  @Input() layersSL: Layer[] | null
  @Input() layerSL?: Layer
  @Input() layerState: LayerState[] = []
  @Input() territories: Territories
  @Input() layerInd: number
  @Input() isSection = false
  @Input() accountOpportunity: AccountOpportunity | null

  @Output() subjectivityClick = new EventEmitter<{ id: string }>()
  @Output() reinsurerResize = new EventEmitter<{
    field: Partial<QuoteFields>
    id: string
    isSection: boolean
  }>()
  @Output() slidingValueChange = new EventEmitter<{
    id: string
    slidingTable: SlidingScale[]
  }>()
  @Output() deleteReinstatement = new EventEmitter<{ id: number }>()
  @Output() expandClick = new EventEmitter<{ isOpen: boolean; name: string }>()

  get isDisabled() {
    return (
      (!this.isGroupSelected &&
        !this.isSLSelected &&
        !(this.selectedReinsurer === this.currentReinsurer?.id)) ||
      (this.isGroupSelected && !this.sameGroupRe()) ||
      (this.isSLSelected && !this.sameSLRe())
    )
  }
  constructor(
    private service: QuoteReinsurerService,
    private store: Store<AppState>
  ) {}
  ngOnInit() {
    if (
      this.currentReinsurer?.quoteReinsurerName === FOT_MASTER_REINSURER_NAME
    ) {
      this.isFotMaster = true
    }
    if (this.currentReinsurer?.quoteReinsurerName === EXPIRING_REINSURER_NAME) {
      this.isExpiring = true
    }
    this.getAllQuoteReinsurers = this.allReinsurers
      .filter(
        r =>
          r.reinsurerPhase === ReinsurerPhases.Quote &&
          r.quoteReinsurerName !== FOT_MASTER_REINSURER_NAME &&
          r.quoteReinsurerName !== EXPIRING_REINSURER_NAME
      )
      .filter(r => !r.decline)

    this.isDecline = this.currentReinsurer?.decline
      ? this.currentReinsurer?.decline
      : false
    this.slidingScaleDefCol = slidingScaleDef
    this.slidingScaleRows = this.currentReinsurer?.slidingScale
      ? this.currentReinsurer?.slidingScale
      : [
          {
            id: 1,
            type: 'Min',
            commission: 0,
            lossRatio: 0,
            slideRate: 0,
          },
          {
            id: 2,
            type: 'Provisional',
            commission: 0,
            lossRatio: 0,
          },
          {
            id: 3,
            type: 'Max',
            commission: 0,
            lossRatio: 0,
            slideRate: 0,
          },
        ]
  }

  ngOnChanges(): void {
    if (this.isGroupSelected) {
      this.getAllGroupReinsurers = this.allReinsurers.filter(
        r =>
          r.reinsurerPhase === this.currentReinsurer?.reinsurerPhase &&
          r.reinsurerPhaseVersion ===
            this.currentReinsurer.reinsurerPhaseVersion &&
          r.quoteReinsurerName === this.currentReinsurer.quoteReinsurerName &&
          r.programGroupID === this.currentReinsurer.programGroupID
      )
    } else if (this.isSLSelected) {
      this.getAllGroupReinsurers = this.allReinsurers.filter(
        r =>
          r.reinsurerPhase === this.currentReinsurer?.reinsurerPhase &&
          r.reinsurerPhaseVersion ===
            this.currentReinsurer.reinsurerPhaseVersion &&
          r.quoteReinsurerName === this.currentReinsurer.quoteReinsurerName &&
          r.sharedLimitID === this.currentReinsurer.sharedLimitID
      )
    } else if (this.layer?.layer.meta_data.sage_layer_type) {
      this.getAllGroupReinsurers = this.allReinsurers.filter(
        r =>
          r.reinsurerPhase === this.currentReinsurer?.reinsurerPhase &&
          r.reinsurerPhaseVersion ===
            this.currentReinsurer.reinsurerPhaseVersion &&
          r.quoteReinsurerName === this.currentReinsurer.quoteReinsurerName
      )
    }
    this.store.select(selectCurrentProgram).subscribe(a => {
      this.isLibRE = a?.libRE === 'Y'
    })
  }

  getType(def: QuotePanelDefResolved): string {
    const type = 'text'
    const view = this.view as LayerView
    if (view) {
      if (
        (this.isLibRE &&
          def.id === 'totalQuoteExpectedCededLoss' &&
          (!view['quoteExpectedCededLoss'] ||
            view['quoteExpectedCededLoss'] <= 0)) ||
        (this.isLibRE &&
          def.id === 'totalQuoteExpectedCededPremium' &&
          (!view['quoteExpectedCededPremium'] ||
            view['quoteExpectedCededPremium'] <= 0))
      ) {
        return type
      }
      if (
        (def.id === 'quoteExpectedCededLoss' ||
          def.id === 'quoteExpectedCededPremium') &&
        (!view[def.id] || view[def.id] <= 0) &&
        this.isLibRE
      ) {
        return type
      } else if (def.valueType) {
        return def.valueType
      }
    }
  }

  get isMultiSection(): boolean {
    const layerType = this.layer?.layer.meta_data.sage_layer_type
    return (
      layerType === layerIds.noncatMultisection ||
      layerType === layerIds.catMultisection
    )
  }

  get isTopAndDrop(): boolean {
    const layerType = this.layer?.layer.meta_data.sage_layer_type
    return layerType === layerIds.catTd
  }

  get isHiddenLayerTD(): boolean {
    const layerType = this.layer?.layer.meta_data.sage_layer_type
    const layerSubType = this.layer?.layer.meta_data.sage_layer_subtype
    return layerType === layerIds.catTd && layerSubType === 'actual'
  }

  get isQuotaShare(): boolean {
    if (this.layer) {
      return this.isQSLayerType(this.layer?.layer.meta_data.sage_layer_type)
    } else if (this.layerGroup) {
      return this.isQSLayerType(
        this.layerGroup?.layer.meta_data.sage_layer_type
      )
    } else if (this.layerSL) {
      return this.isQSLayerType(this.layerSL?.meta_data.sage_layer_type)
    }
    return false
  }

  isQSLayerType(layerType: string | undefined): boolean {
    return (
      layerType === layerIds.catQs ||
      layerType === layerIds.noncatQs ||
      layerType === layerIds.ahlQs
    )
  }

  get subjectPremiumChecked() {
    if (this.view) {
      return this.view.subjectPremiumChecked
    }
  }

  sameGroupRe(): false | QuoteReinsurer | undefined {
    if (this.selectedReinsurer === this.currentReinsurer?.id) {
      const allRe = this.allReinsurers.filter(
        ar =>
          ar.programGroupID === this.currentReinsurer?.programGroupID &&
          ar.reinsurerPhase === this.currentReinsurer?.reinsurerPhase &&
          ar.reinsurerPhaseVersion ===
            this.currentReinsurer?.reinsurerPhaseVersion &&
          ar.quoteReinsurerName === this.currentReinsurer?.quoteReinsurerName
      )
      return allRe.find(al => al.id === this.selectedReinsurer)
    }
    return false
  }

  sameSLRe(): false | QuoteReinsurer | undefined {
    if (this.selectedReinsurer === this.currentReinsurer?.id) {
      const allRe = this.allReinsurers.filter(
        ar =>
          ar.sharedLimitID === this.currentReinsurer?.sharedLimitID &&
          ar.reinsurerPhase === this.currentReinsurer?.reinsurerPhase &&
          ar.reinsurerPhaseVersion ===
            this.currentReinsurer?.reinsurerPhaseVersion &&
          ar.quoteReinsurerName === this.currentReinsurer?.quoteReinsurerName
      )
      return allRe.find(al => al.id === this.selectedReinsurer)
    }
    return false
  }

  isGroupSelectedRe(): boolean {
    const layerType = this.layer?.layer.meta_data.sage_layer_type
    if (
      (this.isGroupSelected ||
        this.isSLSelected ||
        layerType === layerIds.catMultisection ||
        layerType === layerIds.catTd) &&
      this.selectedReinsurer
    ) {
      const re = this.allReinsurers.find(al => al.id === this.selectedReinsurer)
      return this.currentReinsurer?.cededlayerID === re?.cededlayerID
    }
    return true
  }

  getValue(
    def: QuotePanelDefResolved
  ):
    | string
    | string[]
    | number
    | number[]
    | Reinstatement[]
    | boolean
    | DependentValue
    | undefined {
    const view = this.view as LayerView
    if (view) {
      if (
        this.isFotMaster &&
        (def.id === 'quoteMinPercentage' || def.id === 'quoteOfferedPercentage')
      ) {
        return this.getPercentageValuesFOT(def)
      }
      if (
        def.id === 'quoteExpectedCededLoss' ||
        def.id === 'quoteExpectedCededPremium'
      ) {
        if (this.isLibRE) {
          return view[def.id] > 0 ? view[def.id] : 'N/A'
        } else {
          return view[def.id] ?? 0
        }
      }
      if (def.id === 'totalQuoteExpectedCededLoss') {
        if (
          this.isLibRE &&
          (!view['quoteExpectedCededLoss'] ||
            view['quoteExpectedCededLoss'] <= 0)
        ) {
          return 'N/A'
        }
        let totalQuoteExpectedCededLoss = 0
        this.getAllGroupReinsurers.map(x => {
          if (
            this.layer &&
            this.layer.layer.meta_data.sage_layer_type ===
              layerIds.catMultisection
          ) {
            this.layerState.map(ls => {
              if (
                x.quoteReinsurerName ===
                  (view.quoteReinsurer as QuoteReinsurer).quoteReinsurerName &&
                x.quoteFields &&
                x.quoteFields.quoteExpectedCededLoss &&
                ls.layer.id === x.cededlayerID &&
                ls.layer.physicalLayer.description === 'Section A'
              ) {
                totalQuoteExpectedCededLoss =
                  this.isLibRE && this.layerInd > 0
                    ? 0
                    : x.quoteFields.quoteExpectedCededLoss.value
              }
            })
          } else {
            if (
              x.quoteReinsurerName ===
                (view.quoteReinsurer as QuoteReinsurer).quoteReinsurerName &&
              x.quoteFields &&
              x.quoteFields.quoteExpectedCededLoss
            ) {
              totalQuoteExpectedCededLoss +=
                this.isLibRE && this.layerInd > 0
                  ? 0
                  : x.quoteFields.quoteExpectedCededLoss.value
            }
          }
        })
        return totalQuoteExpectedCededLoss
      }
      if (def.id === 'totalQuoteExpectedCededPremium') {
        if (
          this.isLibRE &&
          (!view['quoteExpectedCededPremium'] ||
            view['quoteExpectedCededPremium'] <= 0)
        ) {
          return 'N/A'
        }
        let totalQuoteExpectedCededPremium = 0
        this.getAllGroupReinsurers.map(x => {
          if (
            this.layer &&
            this.layer.layer.meta_data.sage_layer_type ===
              layerIds.catMultisection
          ) {
            this.layerState.map(ls => {
              if (
                x.quoteReinsurerName ===
                  (view.quoteReinsurer as QuoteReinsurer).quoteReinsurerName &&
                x.quoteFields &&
                x.quoteFields.quoteExpectedCededPremium &&
                ls.layer.id === x.cededlayerID &&
                ls.layer.physicalLayer.description === 'Section A'
              ) {
                totalQuoteExpectedCededPremium =
                  this.isLibRE && this.layerInd > 0
                    ? 0
                    : x.quoteFields.quoteExpectedCededPremium.value
              }
            })
          } else {
            if (
              x.quoteReinsurerName ===
                (view.quoteReinsurer as QuoteReinsurer).quoteReinsurerName &&
              x.quoteFields &&
              x.quoteFields.quoteExpectedCededPremium
            ) {
              totalQuoteExpectedCededPremium +=
                this.isLibRE && this.layerInd > 0
                  ? 0
                  : x.quoteFields.quoteExpectedCededPremium.value
            }
          }
        })
        return totalQuoteExpectedCededPremium
      }
      if (def.id === 'structureFX') {
        return view.layer.currency
      }
      if (def.id === 'layerClass' && def.childFieldId) {
        const parentValue = this.accountOpportunity
          ? this.accountOpportunity.opportunityClass
          : view[def.id]
        const childValue = this.accountOpportunity
          ? this.accountOpportunity.opportunitySubClass
          : view[def.childFieldId as keyof LayerView]
        return {
          parent: { id: def.id, value: parentValue },
          child: { id: def.childFieldId, value: childValue },
        } as DependentValue
      }
      // Show Contract Occurrence Limit value for Multi-Section layers.
      // `layer-metric-defs.ts`, `layerMetricDefs` and `QUOTE_PANEL_DEFS` use 'quoteOccurrenceLimit' labeled 'Contract Occurrence Limit'.
      if (
        def.id === 'quoteOccurrenceLimit' &&
        this.isMultiSection &&
        view.layer.meta_data.sage_layer_subtype !== 'section-layer'
      ) {
        return view['contractOccurrenceLimit']
      }
      // @ts-ignore
      return view[def.id]
    }
  }

  getLayerName(reinsurer: QuoteReinsurer): string | undefined {
    if (this.isGroupSelected) {
      return (
        this.layerStateGroup.find(f => f.layer.id === reinsurer.cededlayerID)
          ?.layer.meta_data.layerName || 'Layer 1'
      )
    } else if (this.isSLSelected) {
      return (
        this.layersSL?.find(f => f.id === reinsurer.cededlayerID)?.meta_data
          .layerName || 'Shared Limit Layer'
      )
    }
  }

  isSameValue(id: string, def: QuotePanelDefResolved): boolean {
    const currentRe = this.view?.quoteReinsurer
    let currentValue: any
    if (currentRe) {
      currentValue = this.displayDefValue(currentRe, def)
    }
    const setRe = this.allReinsurers.find(a => a.id === id)
    let newValue: any
    if (setRe) {
      newValue = this.displayDefValue(setRe, def)
    }
    return currentValue === newValue
  }

  isSameValueForAll(def: QuotePanelDefResolved): boolean {
    const currentRe = this.view?.quoteReinsurer
    let currentValue: any
    if (currentRe) {
      currentValue = this.displayDefValue(currentRe, def)
    }
    let flag = true
    this.getAllGroupReinsurers.forEach(q => {
      const newValue = this.displayDefValue(q, def)
      if (currentValue !== newValue) {
        flag = false
        return flag
      }
    })
    return flag
  }

  setGroupLayerValue(
    value:
      | string
      | string[]
      | number
      | number[]
      | Reinstatement[]
      | boolean
      | DependentValue
      | undefined,
    reinsurer: QuoteReinsurer,
    def: QuotePanelDefResolved
  ): void {
    const view = this.getView(reinsurer) as LayerView
    // @ts-ignore
    view[def.id] = value
    if (view && view.quoteReinsurer?.quoteFields) {
      this.reinsurerResize.emit({
        field: view.quoteReinsurer.quoteFields,
        id: view.quoteReinsurer.id,
        isSection: this.isViewSection(view),
      })
    }
  }

  setGroupLayerValueForAll(
    value:
      | string
      | string[]
      | number
      | number[]
      | Reinstatement[]
      | boolean
      | DependentValue
      | undefined,
    def: QuotePanelDefResolved
  ): void {
    this.getAllGroupReinsurers.forEach(q => {
      const view = this.getView(q) as LayerView
      // @ts-ignore
      view[def.id] = value
      if (view && view.quoteReinsurer?.quoteFields) {
        this.reinsurerResize.emit({
          field: view.quoteReinsurer.quoteFields,
          id: view.quoteReinsurer.id,
          isSection: this.isViewSection(view),
        })
      }
    })
  }

  getLayerGroup(reinsurer: QuoteReinsurer): LayerState | undefined {
    return this.layerStateGroup.find(f => f.layer.id === reinsurer.cededlayerID)
  }

  getLayerSL(reinsurer: QuoteReinsurer): Layer | undefined {
    return this.layersSL?.find(f => f.id === reinsurer.cededlayerID)
  }

  getView(reinsurer: QuoteReinsurer): LayerView | undefined {
    if (this.isGroupSelected) {
      const re = this.getLayerGroup(reinsurer)
      if (re) {
        return new LayerView(this.layerStateGroup, re.layer, {
          quoteReinsurer: reinsurer,
        })
      }
    } else if (this.isSLSelected) {
      const re = this.getLayerSL(reinsurer)
      if (re) {
        return new LayerView([], re, {
          quoteReinsurer: reinsurer,
        })
      }
    }
  }

  exportSelected(
    def: QuotePanelDefResolved
  ):
    | string
    | number
    | boolean
    | QuoteReinsurer[]
    | Reinstatements
    | InuringLayer
    | undefined {
    let layerName
    if (
      this.layer &&
      this.layer.layer.meta_data.sage_layer_type === layerIds.catMultisection
    ) {
      const layer = this.layerState.find(
        l => l.layer.id === this.currentReinsurer?.cededlayerID
      )?.layer

      layerName = layer?.physicalLayer.description
    }

    // For combined layer, if is multisection layer and layer of current reinsurer show/hide fields.
    if (layerName && this.selectedSection && !this.isSection) {
      switch (def.id) {
        case 'quoteOccurrenceLimit': {
          return this.selectedSection.occurrenceLimitToggle
        }
        case 'quoteOccurrenceAttachment': {
          return this.selectedSection.occurrenceAttachmentToggle
        }
        case 'quoteOfferedPercentage':
        case 'quoteCedingCommission': {
          return true
        }
        case 'layerClass':
        case 'layerSubClass':
        case 'quoteCessionPercentage': {
          return false
        }
      }
    }

    // AB#14530 - Limit display of new terms added to main section of Multi-Section, T&D and Shared Limit.
    const isMainSection =
      this.currentReinsurer?.cededlayerID === this.layer?.layer.id
    const isSLMainSection =
      this.view?.layer.meta_data.sage_layer_type === 'shared_limits'
    if (
      ((this.isHiddenLayerTD || this.isMultiSection) && isMainSection) ||
      isSLMainSection
    ) {
      if (this.quotePanelDefsToggleMap.hasOwnProperty(def.id)) {
        return this.selectedSection?.[this.quotePanelDefsToggleMap[def.id]]
      }
    }

    if (this.isSection && !this.isGroupSelected) {
      const sectionHiddenFields: (keyof LayerViewValues)[] = [
        'subjectPremium',
        'quoteOfferedPercentage',
        'quoteReinstatements',
        'quotePremium',
        'minimumPremium',
        'minimumPremiumPercentage',
        'brokerageCommission',
        'brokerageRIPCommission',
        'quoteDepositPremium',
        'quoteExpectedCededLoss',
        'quoteExpectedCededPremium',
        'brokerageType',
        'quoteCedingCommission',
      ]
      let sectionShownFields: (keyof LayerViewValues)[] = [
        'quoteAggregateLimit',
        'quoteAggregateAttachment',
        'quoteCessionPercentage',
        'quoteOccurrenceLimit',
        'quoteOccurrenceAttachment',
      ]
      if (this.isHiddenLayerTD) {
        sectionShownFields = [
          'quoteAggregateLimit',
          'quoteAggregateAttachment',
          'quoteOccurrenceLimit',
          'quoteOccurrenceAttachment',
        ]
      }

      if (sectionHiddenFields.includes(def.id)) {
        return false
      }
      if (sectionShownFields.includes(def.id)) {
        return true
      }
      // For sections: if not included in shown fields do not show it.
      return false
    }

    if (this.isSection) {
      const sectionHiddenFields: (keyof LayerViewValues)[] = [
        'subjectPremium',
        'quoteOfferedPercentage',
        'quoteReinstatements',
        'quotePremium',
        'minimumPremium',
        'minimumPremiumPercentage',
        'brokerageCommission',
        'brokerageRIPCommission',
        'quoteDepositPremium',
        'quoteExpectedCededLoss',
        'quoteExpectedCededPremium',
        'brokerageType',
      ]
      const sectionShownFields: (keyof LayerViewValues)[] = [
        'quoteAggregateLimit',
        'quoteAggregateAttachment',
      ]
      if (sectionHiddenFields.includes(def.id)) {
        return false
      }
      if (sectionShownFields.includes(def.id)) {
        return true
      }
    }

    if (this.quotePanelDefsToggleMap.hasOwnProperty(def.id)) {
      return this.selectedSection?.[this.quotePanelDefsToggleMap[def.id]]
    }
  }

  getPercentageValuesFOT(def: QuotePanelDefResolved): number {
    const allFinalQuotes: QuoteReinsurer[] = []
    const groupByName = Object.values(
      // tslint:disable-next-line: no-non-null-assertion
      groupBy(r => r.quoteReinsurerName!, this.getAllQuoteReinsurers)
    )
    groupByName.forEach(g => allFinalQuotes.push(g[g.length - 1]))
    let sum = 0
    allFinalQuotes.forEach(q => {
      if (this.displayDefValue(q, def)) {
        sum = sum + this.displayDefValue(q, def)
      }
    })
    return sum
  }

  getUnlimited(def: QuotePanelDefResolved): boolean | undefined {
    const view: any = this.view
    if (view && def.unlimitable) {
      return view[def.unlimitable]
    }
  }
  /*
   * this will cover the PremiumFX change event, Base on the PremiumFX value, we get exchange_rate_table id after that pass in the
   * fetchDatContent to get the file storage location, take the cvs data filter it based on the value and did the divide and setup in the PremiumFX to usd field.
   */
  setValueForPremiumFXUsd(view: any, value: string): void {
    const newDef = this.quotePanelDefs.filter(
      (a: QuotePanelDefResolved) => a.id === 'premiumFXToUSD'
    )
    this.store
      .pipe(select(selectCurrentAnalysisProfile))
      .subscribe((profile: any) => {
        this.service
          .fetchDataContent(
            profile?.exchange_rate_profile?.exchange_rate_table.data.id
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe((d: any) => {
            const rec = d.data
            const usdRateArr = rec.filter((val: any) => val.Currency === 'USD')
            const usdRateValue = usdRateArr[0].Rate
            const rateArr = rec.filter((val: any) => val.Currency === value)
            const rateValue = rateArr[0]?.Rate
            view[newDef[0].id] = (rateValue / usdRateValue).toFixed(6)

            if (view && view.quoteReinsurer?.quoteFields) {
              this.reinsurerResize.emit({
                field: view.quoteReinsurer.quoteFields,
                id: view.quoteReinsurer.id,
                isSection: this.isViewSection(view),
              })
            }
          })
      })
  }

  setValue(def: QuotePanelDefResolved, value: any): void {
    const view: any = this.view
    if (view) {
      if (def.id === 'premium') {
        if (this.isQuotaShare) {
          view.subjectPremiumChecked = false
        } else {
          view.technicalPremiumChecked = false
        }
      }
      if (def.id === 'quoteReinstatements') {
        if (view[def.id].length <= value.length) {
          view[def.id].forEach((v: Reinstatements, i: number) => {
            if (v.id !== value[i].id) {
              value[i].id = v.id
            }
          })
        } else {
          // delete reinstatement
          view[def.id].forEach((v: Reinstatements, i: number) => {
            if (
              value[i] &&
              v.premium === value[i].premium &&
              v.id !== value[i].id
            ) {
              value[i].id = v.id
            }
            const found = value.findIndex(
              (v1: Reinstatements) => v.id === v1.id
            )
            if (v.id && found === -1) {
              this.deleteReinstatement.emit({ id: v.id })
            }
          })
        }
      }
      if (def.childFieldId) {
        // Set dependent values
        view[value.parent.id] = value.parent.value
        view[value.child.id] = value.child.value
      } else {
        view[def.id] = value
      }
      // add extra condition for premiumFX to append the toUSD field as well
      if (def.id === 'premiumFX') {
        this.setValueForPremiumFXUsd(view, value)
      } else {
        if (this.view && this.view.quoteReinsurer?.quoteFields) {
          this.reinsurerResize.emit({
            field: this.view.quoteReinsurer.quoteFields,
            id: this.view.quoteReinsurer.id,
            isSection: this.isViewSection(view),
          })
        }
      }
    }
  }

  onCheckboxChanged(def: QuotePanelDefResolved): void {
    const view = this.view as LayerView
    if (
      this.view &&
      view &&
      def.unlimitable &&
      this.view.quoteReinsurer?.quoteFields
    ) {
      // @ts-ignore
      view[def.unlimitable] = !view[def.unlimitable]
      this.reinsurerResize.emit({
        field: this.view.quoteReinsurer.quoteFields,
        id: this.view.quoteReinsurer.id,
        isSection: this.isViewSection(view),
      })
    }
  }

  onSubjectPremiumChanged(): void {
    if (this.view) {
      this.view.subjectPremiumChecked = !this.view.subjectPremiumChecked
    }
  }

  getTypeCheckboxValue(def: QuotePanelDefResolved) {
    const view: any = this.view
    if (this.view && view) {
      return view[def.id]
    }
  }

  isViewSection(view: LayerView): boolean {
    const cededLayerId = view.quoteReinsurer?.cededlayerID
    const cededLayer = this.layerState.find(l => l.layer.id === cededLayerId)
    return (
      cededLayer?.layer.meta_data.sage_layer_subtype === 'section-layer' ||
      cededLayer?.layer.meta_data.sage_layer_type === 'drop' ||
      (cededLayer?.layer.meta_data.sage_layer_type === 'cat_td' &&
        cededLayer?.layer.meta_data.sage_layer_subtype === 'virtual')
    )
  }

  onTypeCheckboxChange(def: QuotePanelDefResolved): void {
    const view = this.view as LayerView
    if (this.view && view && this.view.quoteReinsurer?.quoteFields) {
      // @ts-ignore
      view[def.id] = !view[def.id]
      this.reinsurerResize.emit({
        field: this.view.quoteReinsurer.quoteFields,
        id: this.view.quoteReinsurer.id,
        isSection: this.isViewSection(view),
      })
    }
  }

  onSubjectivityClick(): void {
    if (!this.isDisabled && !this.isDecline) {
      // tslint:disable-next-line: no-non-null-assertion
      this.subjectivityClick.emit({ id: this.currentReinsurer?.id! })
    }
  }

  displayDefValue(reinsurer: QuoteReinsurer, def: QuotePanelDefResolved) {
    const getKeyValue =
      <U extends keyof T, T extends object>(key: U) =>
      (obj: T) =>
        obj[key]
    const value = getKeyValue<keyof any, any>(def.id)(reinsurer.quoteFields)
    if (value) {
      if (value.value || value.value === 0) {
        return value.value || 0
      } else {
        return value || 0
      }
    }
  }

  isSelected(id: string): boolean {
    return this.isFotMaster
      ? this.selectedFOTMasterID.includes(id)
      : this.selectedExpiringID.includes(id)
  }

  onFOTMasterSelection(value: number, id: string): void {
    const isIDPresent = this.selectedFOTMasterID.indexOf(id)
    if (isIDPresent === -1) {
      this.selectedFOTMasterID.push(id)
      this.selectedFOTMasterReinsurer.push(value)
    } else {
      this.selectedFOTMasterID.splice(isIDPresent, 1)
      this.selectedFOTMasterReinsurer.splice(isIDPresent, 1)
    }
    this._assignFinalValues(clone(this.selectedFOTMasterReinsurer))
  }

  _selectAllReinsurersForFOT(def: QuotePanelDefResolved): void {
    if (!this.isFotMaster) {
      return
    }

    this.selectedFOTMasterID = []
    this.selectedFOTMasterReinsurer = []

    this.getAllQuoteReinsurers.forEach(reinsurer => {
      this.selectedFOTMasterID.push(reinsurer.id)
      this.selectedFOTMasterReinsurer.push(this.displayDefValue(reinsurer, def))
    })

    this._assignFinalValues(clone(this.selectedFOTMasterReinsurer))
  }

  onExpiringSelection(value: number, id: string): void {
    const isIDPresent = this.selectedExpiringID.indexOf(id)
    if (isIDPresent === -1) {
      this.selectedExpiringID.push(id)
      this.selectedExpiringReinsurer.push(value)
    } else {
      this.selectedExpiringID.splice(isIDPresent, 1)
      this.selectedExpiringReinsurer.splice(isIDPresent, 1)
    }
    this._assignFinalValues(clone(this.selectedExpiringReinsurer))
  }

  _selectAllReinsurersForExpiring(def: QuotePanelDefResolved): void {
    if (!this.isExpiring) {
      return
    }

    this.selectedExpiringID = []
    this.selectedExpiringReinsurer = []

    this.getAllQuoteReinsurers.forEach(reinsurer => {
      this.selectedExpiringID.push(reinsurer.id)
      this.selectedExpiringReinsurer.push(this.displayDefValue(reinsurer, def))
    })

    this._assignFinalValues(clone(this.selectedExpiringReinsurer))
  }

  private _assignFinalValues(values: number[]): void {
    values = values.filter(v => v !== undefined)
    this.finalMax = this.getMax(values)
    this.finalMin = this.getMin(values)
    this.finalAvg = this.getAverage(values)
    this.finalAvgEx = this.getAvgEx(values)
  }

  onSetClick(value: number, def: QuotePanelDefResolved): void {
    this.setValue(def, value)
  }

  getMax(values: number[]): number {
    return Math.max(...values)
  }

  getMin(values: number[]): number {
    return Math.min(...values)
  }

  getAverage(values: number[]): number {
    return values.reduce((a, b) => a + b) / values.length
  }

  getAvgEx(values: number[]): number {
    if (values.length > 2) {
      const valuesClone = [...values]
      const max = Math.max(...valuesClone)
      const min = Math.min(...valuesClone)
      valuesClone.splice(
        valuesClone.findIndex(v => v === max),
        1
      )
      valuesClone.splice(
        valuesClone.findIndex(v => v === min),
        1
      )
      this.finalAvgExIsVisible = true
      return this.getAverage(valuesClone)
    } else {
      this.finalAvgExIsVisible = false
      return 0
    }
  }

  onValueChange({
    id,
    column,
    value,
  }: SortTableValueChangeEvent<SlidingScale>): void {
    const tempSlidingRows = clone(this.slidingScaleRows)
    if (column.id === 'commission') {
      tempSlidingRows[parseInt(id, 10) - 1].commission = value
    } else if (column.id === 'lossRatio') {
      tempSlidingRows[parseInt(id, 10) - 1].lossRatio = value
    }
    this.slidingScaleRows = this.calculateSLideRate(tempSlidingRows)
    this.slidingValueChange.emit({
      // tslint:disable-next-line: no-non-null-assertion
      id: this.view?.quoteReinsurer?.id!,
      slidingTable: this.slidingScaleRows,
    })
  }

  calculateSLideRate(table: SlidingScale[]): SlidingScale[] {
    table[0].slideRate =
      ((table[1].commission - table[0].commission) /
        ((table[1].lossRatio - table[0].lossRatio) * 100)) *
      -1
    table[2].slideRate =
      ((table[2].commission - table[1].commission) /
        ((table[2].lossRatio - table[1].lossRatio) * 100)) *
      -1
    return table
  }

  trackByDef(index?: number, def?: QuotePanelDefResolved): string {
    return def ? def.id : String(index)
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
