<div class="pricing-curve-details" *ngIf="pricingCurve">
  <div class="wrapper">
    <div #header class="details-wrapper" [ngClass]="colorClass">
      <div class="curve-label">
        <div class="label">{{ curveLabel }}</div>
        <div *ngIf="hasLine">
          <span>Form: </span><span>{{ curveType }}</span>
          <div *ngIf="context === 'pricing-curve'">
            <div *ngIf="isPowerCurve">
              <div><span>y = ax^b</span></div>
              <div><span>ln(y) = ln(a) + bln(x)</span></div>
            </div>
            <div *ngIf="!isPowerCurve">
              <div><span>y = a + bx</span></div>
            </div>
          </div>
        </div>
        <div *ngIf="!!hasLayers && !hasLine">
          <span>Manually Defined Layer Set</span>
        </div>
        <div *ngIf="">
          <span>Manually Defined Curve</span>
        </div>
      </div>
      <div class="curve-function-details" *ngIf="hasLine">
        <div class="intercept" *ngIf="context === 'pricing-curve'">
          <span>{{ interceptLabel }}: </span
          ><span>{{ yIntercept | number: '1.1-4' }}</span>
        </div>
        <div class="slope" *ngIf="context === 'pricing-curve'">
          <span>{{ slopeLabel }}: </span
          ><span>{{ slope | number: '1.1-4' }}</span>
        </div>
        <div class="rSquared" *ngIf="context === 'credit' && !!rSquared">
          <span>r<sup>2</sup>:&nbsp;</span>
          <span>{{ rSquared | number: '1.1-4' }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="displayLayerChart">
      <app-card-layer-table
        [pricingCurve]="pricingCurve"
        [context]="context"
      ></app-card-layer-table>
    </div>
    <div *ngIf="displayLineChart">
      <app-card-curve-table
        *ngIf="context === 'pricing-curve'"
        [pricingCurve]="pricingCurve"
        [yIntercept]="yIntercept"
        [slope]="slope"
      ></app-card-curve-table>
      <app-card-credit-calc
        *ngIf="context === 'credit'"
        [pricingCurve]="pricingCurve"
      ></app-card-credit-calc>
    </div>
    <div class="buttons-toolbar">
      <span class="settings-button">
        <button appButtonIcon huge opaque [matMenuTriggerFor]="moreMenu">
          more_horiz
        </button>
      </span>
    </div>
  </div>
</div>
<mat-menu #moreMenu="matMenu">
  <button class="menu-item" mat-menu-item (click)="openCurveSettingsDialog()">
    <mat-icon class="mat-icon mat-icon-inline mat-icon-no-color"
      >settings</mat-icon
    >Settings
  </button>
  <button class="menu-item" mat-menu-item (click)="initCurveEdit()">
    <mat-icon class="mat-icon mat-icon-inline mat-icon-no-color"
      >create</mat-icon
    >Edit
  </button>
  <button
    *ngIf="lineVisible && layersVisible && context !== 'credit'"
    class="menu-item"
    mat-menu-item
    (click)="swapCardDisplayMode()"
  >
    <mat-icon class="mat-icon mat-icon-inline mat-icon-no-color"
      >rotate_right</mat-icon
    >Toggle card display
  </button>
  <button class="menu-item" mat-menu-item (click)="confirmRemoveCurve()">
    <mat-icon class="mat-icon mat-icon-inline mat-icon-no-color">close</mat-icon
    >Remove from graph
  </button>
</mat-menu>
