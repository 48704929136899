import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { assoc, assocPath, dissoc, dissocPath, Path, uniqBy } from 'ramda'
import { reduceReducers } from '@shared/util/reduce-reducers'
import {
  BenchmarkCategories,
  CategoryMode,
} from '../model/benchmark-categories'
import {
  BenchmarkSystemModeID,
  benchmarkSystemModeIDs,
} from '../model/benchmark-mode'
import { BenchmarkOptionKey } from '../model/benchmark-options'
import { BenchmarkPeriod } from '../model/benchmark-period'
import {
  BenchmarkGridItemState,
  BenchmarkSubmode,
} from '../model/benchmark-submode'
import { BenchmarkTimeframe } from '../model/benchmark-timeframe'
import {
  BenchmarkCompany,
  BenchmarkLob,
  BenchmarkMetric,
  BenchmarkPeerSet,
  BenchmarkVisibility,
  BenchmarkSubmodeGroupIndexState,
  BenchmarkChartOptionsDict,
  BenchmarkDefBase,
} from '../model/benchmark.model'
import * as fromActions from './benchmark-controls.actions'
import * as fromMetricViewActions from './benchmark-metric-view.actions'
import * as fromMainActions from './benchmark.actions'

export interface BenchmarkControlsState {
  targetCompanyByMode: Record<
    BenchmarkSystemModeID,
    BenchmarkCompany | BenchmarkPeerSet | null
  >
  peers: BenchmarkCompany[]
  savedIndividualPeers: BenchmarkCompany[]
  gridBySubmode: Record<string, Record<number, BenchmarkGridItemState>>
  selectedGridIndex: number
  metricBySubmode: BenchmarkSubmodeGroupIndexState<BenchmarkMetric>
  secondaryMetricBySubmode: BenchmarkSubmodeGroupIndexState<BenchmarkMetric>
  tertiaryMetricBySubmode: BenchmarkSubmodeGroupIndexState<BenchmarkMetric>
  periodBySubmode: Record<string, BenchmarkPeriod>
  timeframeBySubmode: Record<string, BenchmarkTimeframe | BenchmarkTimeframe[]>
  growthTimeframe: BenchmarkTimeframe
  lossRatioTimeframe: BenchmarkTimeframe
  currencyUnit: string
  timeframeEndOffsetBySubmode: Record<string, number>
  optionsBySubmode: Record<string, Record<BenchmarkOptionKey | string, boolean>>
  entityBySubmode: Record<string, BenchmarkPeerSet | BenchmarkCompany | null>
  metricGroupIndexBySubmode: Record<string, number>
  topTargetQuantityBySubmode: Record<string, number>
  categoriesBySubmode: Record<string, BenchmarkCategories>
  metricVisibilityBySubmode: Record<
    string,
    Record<string | number, BenchmarkVisibility>
  >
  lobBySubmode: BenchmarkSubmodeGroupIndexState<BenchmarkLob[]>
  statesBySubmode: BenchmarkSubmodeGroupIndexState<BenchmarkDefBase[]>
  colorScaleByTargetMetric: BenchmarkChartOptionsDict
  hoverID: string | number | null
  restoreTime?: number
  hideIndividualPeers: boolean
}

interface HasSubmodeGroupIndex {
  submode: string
  groupIndices?: number[]
}

const updateIndexMap =
  <A extends HasSubmodeGroupIndex, T>(
    /** Returning a value or `null` will set that at the submode and group index
     * specified in the action payload; returning `undefined` will delete the
     * record at that path.
     */
    valueAccessor: (action: A) => T | null | undefined
  ) =>
    (
      state: BenchmarkSubmodeGroupIndexState<T>,
      action: A
    ): BenchmarkSubmodeGroupIndexState<T> => {
      let paths: Path[] = []
      if (!action.groupIndices?.length) {
        paths[0] = [action.submode, 'value']
      } else {
        paths = action.groupIndices.map(i => [action.submode, 'byIndex', i])
      }

      const value = valueAccessor(action)

      return paths.reduce((acc, path) => {
        return value !== undefined
          ? assocPath(path, value, acc)
          : dissocPath(path, acc)
      }, state)
    }

export const initialState: BenchmarkControlsState = {
  targetCompanyByMode: benchmarkSystemModeIDs.reduce((acc, mode) => {
    acc[mode] = null
    return acc
  }, {} as Record<BenchmarkSystemModeID, BenchmarkCompany | null>),
  peers: [],
  savedIndividualPeers: [],
  gridBySubmode: {},
  selectedGridIndex: 0,
  metricBySubmode: {},
  secondaryMetricBySubmode: {},
  tertiaryMetricBySubmode: {},
  periodBySubmode: {},
  growthTimeframe: { value: 5 },
  lossRatioTimeframe: { value: 5 },
  currencyUnit: 'Thousands',
  timeframeBySubmode: {},
  timeframeEndOffsetBySubmode: {},
  optionsBySubmode: {},
  entityBySubmode: {},
  metricGroupIndexBySubmode: {},
  topTargetQuantityBySubmode: {},
  categoriesBySubmode: {
    lorri: {
      category: 'Commercial',
    },
  },
  metricVisibilityBySubmode: {},
  colorScaleByTargetMetric: {},
  lobBySubmode: {},
  statesBySubmode: {},
  hoverID: null,
  hideIndividualPeers: false,
}

const targetCompanyByMode = createReducer(
  initialState.targetCompanyByMode,
  on(
    fromActions.SetBenchmarkControlsTargetCompany,
    (state, { mode, targetCompany }) => ({ ...state, [mode]: targetCompany })
  ),
  on(fromActions.ClearBenchmarkControls, (state, { mode }) => ({
    ...state,
    [mode]: initialState.targetCompanyByMode[mode],
  }))
)

const savedIndividualPeersReducer = createReducer(
  initialState.savedIndividualPeers,
  on(fromActions.fetchBenchmarkSavedIndividualPeersSuccess, (_, { savedIndividualPeersIds, allPeers }) =>
    allPeers.filter(p => savedIndividualPeersIds.find(savedId => savedId === p.id))
  ),
  on(fromActions.setBenchmarkControlsSavedPeers, (_, { peers }) => uniqBy(p => p.id, peers)),
  on(fromActions.RemoveBenchmarkControlsSavedPeers, (state, { peers }) =>
    state.filter(cp => !peers.some(p => cp.id === p.id))
  ),
  on(fromActions.SetBenchmarkControlsTargetCompany, (state, { targetCompany }) =>
    state.filter(p => p.id !== targetCompany.id)
  ),
  on(fromActions.ClearBenchmarkControls, () => [] as BenchmarkCompany[])
)

// @ts-ignore
const peersReducer = createReducer(
  initialState.peers,
  on(fromActions.setBenchmarkControlsPeers, (_, { peers }) =>
    uniqBy(p => p.id, peers)
  ),
  on(fromActions.AddBenchmarkControlsPeers, (state, { peers }) => [
    ...state,
    ...peers.filter(p => !state.some(cp => cp.id === p.id)),
  ]),
  on(fromActions.RemoveBenchmarkControlsPeers, (state, { peers }) =>
    state.filter(cp => !peers.some(p => cp.id === p.id))
  ),
  on(fromActions.SetBenchmarkControlsTargetCompany, (state, { targetCompany }) =>
    state.filter(p => p.id !== targetCompany.id)
  ),
  // @ts-ignore
  on(fromActions.ClearBenchmarkControls, () => [])
)

const deleteStateSubmodeIDs = <T extends Record<string, unknown>>(
  state: T,
  { submodes }: { submodes: BenchmarkSubmode[] }
): Omit<string | number, 'toString' | 'valueOf'> =>
  submodes.reduce((outerAcc, s) => {
    const ids = s.subheaders ? s.subheaders.map(sh => sh.id) : [s.id]
    return ids.reduce((acc, id) => dissoc(String(id), acc), outerAcc)
  }, state)

const gridBySubmode = createReducer(
  initialState.gridBySubmode,
  on(
    fromActions.setBenchmarkControlsGridItemsBySubmode,
    (state, { submode, value }) => assoc(submode, value, state)
  ),
  on(
    fromActions.SetBenchmarkControlsBySubmode,
    // @ts-ignore
    (state, { submode, gridIndex, value, mutuallyExclusive }) => {
      const prev = state[submode] ?? []

      if (mutuallyExclusive) {
        const submodeState = Object.keys(prev).reduce((acc, k) => {
          const key = Number(k)
          const show = key === gridIndex
          return { ...acc, [key]: { ...acc[key], show } }
        }, prev)

        return assoc(submode, submodeState, state)
      }

      const nextItem = { ...prev[gridIndex], show: value }
      const next = { ...prev, [gridIndex]: nextItem }
      return assoc(submode, next, state)
    }
  ),
  on(
    fromActions.SetBenchmarkControlsSelectedGridIndex,
    // @ts-ignore
    (state, { submode, value, mutuallyExclusive }) => {
      const prev = state[submode]
      const prevValue = prev?.[value]
      // Automatically set a grid item shown by its selected
      if (prevValue?.show === false) {
        if (mutuallyExclusive) {
          const submodeState = Object.keys(prev).reduce((acc, k) => {
            const key = Number(k)
            const show = key === value
            return { ...acc, [key]: { ...acc[key], show } }
          }, prev)

          return assoc(submode, submodeState, state)
        }

        const nextItem = { ...prev[value], show: true }
        const next = { ...prev, [value]: nextItem }
        return assoc(submode, next, state)
      }
      return state
    }
  ),
  on(fromActions.ClearBenchmarkControls, deleteStateSubmodeIDs)
)

const selectedGridIndex = createReducer(
  initialState.selectedGridIndex,
  // @ts-ignore
  on(
    fromActions.SetBenchmarkControlsSelectedGridIndex,
    (_, { value }) => value
  ),
  on(fromMainActions.maximizeBenchmarkChart, (_, { index }) => index),
  on(fromMainActions.setBenchmarkSubmode, () => 0)
)

const metricBySubmode = createReducer(
  initialState.metricBySubmode,
  on(
    fromActions.SetBenchmarkControlsMetricBySubmode,
    updateIndexMap(({ metric }) => metric)
  ),

  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce((acc, s) => dissoc(s.id, acc), state)
  ),
  on(
    fromMetricViewActions.setBenchmarkSelectedMetricView,
    updateIndexMap(({ view }) => view.primary)
  )
)

const secondaryMetricBySubmode = createReducer(
  initialState.secondaryMetricBySubmode,
  on(
    fromActions.SetBenchmarkControlsSecondaryMetricBySubmode,
    updateIndexMap(({ metric }) => metric)
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce((acc, s) => dissoc(s.id, acc), state)
  ),
  on(
    fromMetricViewActions.setBenchmarkSelectedMetricView,
    updateIndexMap(({ view }) => view.secondary)
  )
)

const tertiaryMetricBySubmode = createReducer(
  initialState.tertiaryMetricBySubmode,
  on(
    fromActions.SetBenchmarkControlsTertiaryMetricBySubmode,
    updateIndexMap(({ metric }) => metric)
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce((acc, s) => dissoc(s.id, acc), state)
  ),
  on(
    fromMetricViewActions.setBenchmarkSelectedMetricView,
    updateIndexMap(({ view }) => view.tertiary)
  )
)

const periodBySubmode = createReducer(
  initialState.periodBySubmode,
  on(
    fromActions.SetBenchmarkControlsPeriodBySubmode,
    (state, { submode, period }) =>
      period ? assoc(submode, period, state) : dissoc(submode, state)
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce((acc, s) => dissoc(s.id, acc), state)
  )
)

const timeframeBySubmode = createReducer(
  initialState.timeframeBySubmode,
  on(
    fromActions.SetBenchmarkControlsTimeframeBySubmode,
    (state, { submode, timeframe }) =>
      timeframe ? assoc(submode, timeframe, state) : dissoc(submode, state)
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce((acc, s) => dissoc(s.id, acc), state)
  )
)

const growthTimeframe = createReducer(
  initialState.growthTimeframe,
  on(
    fromActions.SetBenchmarkControlsGrowthTimeframe,
    (_, { timeframe }) => timeframe
  ),
  on(fromActions.ClearBenchmarkControls, () => initialState.growthTimeframe)
)

const lossRatioTimeframe = createReducer(
  initialState.lossRatioTimeframe,
  on(
    fromActions.SetBenchmarkControlsLossRatioTimeframe,
    (_, { timeframe }) => timeframe
  ),
  on(fromActions.ClearBenchmarkControls, () => initialState.lossRatioTimeframe)
)

const currencyUnit = createReducer(
  initialState.currencyUnit,
  on(
    fromActions.SetBenchmarkControlsCurrencyUnit,
    (_, { currencyUnit }) => currencyUnit
  ),
  on(fromActions.ClearBenchmarkControls, () => initialState.currencyUnit)
)

const hideIndividualPeers = createReducer(
  initialState.hideIndividualPeers,
  on(fromActions.SetBenchmarkControlsHideIndividualPeers, (_, { hideIndividualPeers }) => hideIndividualPeers)
)

const timeframeEndOffsetBySubmode = createReducer(
  initialState.timeframeEndOffsetBySubmode,
  on(
    fromActions.SetBenchmarkControlsTimeframeEndOffsetBySubmode,
    (state, { submode, value }) => assoc(submode, value, state)
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce(
      (acc, s) =>
        assoc(s.id, initialState.topTargetQuantityBySubmode[s.id], acc),
      state
    )
  )
)

const optionsBySubmode = createReducer(
  initialState.optionsBySubmode,
  on(
    fromActions.SetBenchmarkControlsOption,
    // @ts-ignore
    (state, { submode, id, value }) => {
      const prev = state[submode] ?? {}
      const next = assoc(id, value, prev)
      return assoc(submode, next, state)
    }
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce(
      (acc, s) => assoc(s.id, initialState.optionsBySubmode[s.id], acc),
      state
    )
  )
)

const entityBySubmode = createReducer(
  initialState.entityBySubmode,
  on(
    fromActions.SetBenchmarkControlsEntityBySubmode,
    (state, { submode, entity }) =>
      entity ? assoc(submode, entity, state) : dissoc(submode, state)
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce(
      (acc, s) => assoc(s.id, initialState.entityBySubmode[s.id], acc),
      state
    )
  )
)

const metricGroupIndexBySubmode = createReducer(
  initialState.metricGroupIndexBySubmode,
  on(
    fromActions.SetBenchmarkControlsMetricGroupIndexBySubmode,
    (state, { submode, index }) => assoc(submode, index, state)
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce(
      (acc, s) =>
        assoc(s.id, initialState.metricGroupIndexBySubmode[s.id], acc),
      state
    )
  )
)

const topTargetQuantityBySubmode = createReducer(
  initialState.topTargetQuantityBySubmode,
  on(
    fromActions.setBenchmarkControlsTopTargetQuantityBySubmode,
    (state, { submode, value }) => assoc(submode, value, state)
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce(
      (acc, s) =>
        assoc(s.id, initialState.topTargetQuantityBySubmode[s.id], acc),
      state
    )
  )
)

const categoriesBySubmode = createReducer(
  initialState.categoriesBySubmode,
  on(
    fromActions.setBenchmarkControlsCategoriesBySubmode,
    (state, { submode, categories }) => {
      const categoryState: BenchmarkCategories = {
        categories: state[submode]?.categories,
        category: categories?.category,
      }
      return assoc(submode, categoryState, state)
    }
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce(
      (acc, s) => assoc(s.id, initialState.categoriesBySubmode[s.id], acc),
      state
    )
  ),
  on(fromActions.fetchBenchmarkCategoriesSuccess, (state, { data }) => {
    const categoriesAsCategory: CategoryMode[] = data.map(
      (category): CategoryMode => ({ id: category, name: category })
    )
    const lorri = state.lorri
    const subcategories: Record<string, BenchmarkCategories> = {
      lorri: {
        ...lorri,
        categories: categoriesAsCategory,
      },
    }
    return {
      ...state,
      ...subcategories,
    }
  })
)
const metricVisibilityBySubmode = createReducer(
  initialState.metricVisibilityBySubmode,
  on(
    fromActions.SetBenchmarkControlsMetricVisibility,
    (state, { submode, change }) => {
      const prev = state[submode] ?? {}
      const next = { ...prev, [change.metricID]: change.visibility }
      return assoc(submode, next, state)
    }
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce(
      (acc, s) =>
        assoc(s.id, initialState.metricVisibilityBySubmode[s.id], acc),
      state
    )
  )
)

const lobBySubmode = createReducer(
  initialState.lobBySubmode,
  on(
    fromActions.SetBenchmarkControlsLobBySubmode,
    updateIndexMap(({ lobs }) =>
      lobs != null && lobs.length > 0 ? lobs : undefined
    )
  ),
  on(
    fromActions.SetBenchmarkControlsTargetCompany,
    () => initialState.lobBySubmode
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce((acc, s) => dissoc(s.id, acc), state)
  )
)

const statesBySubmode = createReducer(
  initialState.statesBySubmode,
  on(
    fromActions.SetBenchmarkControlsStatesBySubmode,
    updateIndexMap(({ states }) =>
      states != null && states.length > 0 ? states : undefined
    )
  ),
  on(
    fromActions.SetBenchmarkControlsTargetCompany,
    () => initialState.statesBySubmode
  ),
  on(fromActions.ClearBenchmarkControls, (state, { submodes }) =>
    submodes.reduce((acc, s) => dissoc(s.id, acc), state)
  )
)

const colorScaleByTargetMetricReducer = createReducer(
  initialState.colorScaleByTargetMetric,
  on(
    fromActions.setBenchmarkControlsColorScaleByTargetMetric,
    (state, { target, metric, colorScale }) =>
      assocPath([String(target), String(metric)], colorScale, state)
  ),
  on(
    fromActions.ClearBenchmarkControls,
    () => initialState.colorScaleByTargetMetric
  )
)

const hoverID = createReducer(
  initialState.hoverID,
  on(fromActions.setBenchmarkControlsHoverID, (_, { id }) => id),
  on(
    fromMainActions.setBenchmarkSubmode,
    fromActions.ClearBenchmarkControls,
    () => initialState.hoverID
  )
)

const restoreTime = createReducer(
  initialState.restoreTime,
  on(fromActions.restoreBenchmarkControlsSuccess, () => Date.now())
)

const _benchmarkControlsReducer = combineReducers<BenchmarkControlsState>({
  targetCompanyByMode,
  peers: peersReducer,
  savedIndividualPeers: savedIndividualPeersReducer,
  gridBySubmode,
  selectedGridIndex,
  metricBySubmode,
  secondaryMetricBySubmode,
  tertiaryMetricBySubmode,
  periodBySubmode,
  timeframeBySubmode,
  growthTimeframe,
  lossRatioTimeframe,
  currencyUnit,
  timeframeEndOffsetBySubmode,
  optionsBySubmode,
  entityBySubmode,
  metricGroupIndexBySubmode,
  topTargetQuantityBySubmode,
  categoriesBySubmode,
  metricVisibilityBySubmode,
  lobBySubmode,
  statesBySubmode,
  colorScaleByTargetMetric: colorScaleByTargetMetricReducer,
  hoverID,
  restoreTime,
  hideIndividualPeers
})

const updateReducer = createReducer(
  initialState,
  on(fromActions.updateBenchmarkControls, (state, { value }) => ({
    ...state,
    ...value,
  }))
)

const benchmarkControlsReducer = reduceReducers(
  _benchmarkControlsReducer,
  updateReducer
)

export function reducer(
  state: BenchmarkControlsState | undefined,
  action: Action
): BenchmarkControlsState {
  return benchmarkControlsReducer(state, action)
}
