import { LoadedLossSet, Metadata, Metrics } from 'src/app/api/analyzere/analyzere.model'
import { LossSetLayer } from '../model/loss-set-layers.model'
import {
  LossDistributionRow,
  CombinedLossDistributionRow,
} from './../../core/model/loss-set-table.model'
import { AggregationMethodType, VaRTVaR} from './../model/metrics.model'
import { ExploreFilterMap, ExploreSummaryDatum, GroupSummaryRequest, MappingOption, SummaryArrayDatum, SummaryDataResponse } from './explore.model'
import { ShortNumberPipe } from '@shared/pipes/short-number.pipe'
import { isLoadedLossSet } from '../model/layers.util'
import { ModifierState } from './store/explore.reducer'
import { ApiResponse } from 'src/app/api/model/api.model'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { SortTableRow } from '@shared/sort-table/sort-table.model'

export function convertCombinedLossDistributionTable(
  combinedTable: CombinedLossDistributionRow[],
  aggregationMethod: AggregationMethodType
): LossDistributionRow[] {
  return combinedTable.map(row => {
    if (aggregationMethod === 'OEP') {
      return {
        returnPeriod: row.returnPeriod,
        value: row.oepValue,
        varianceValue: row.oepVarianceValue,
        lossRatioValue: row.oepLossRatioValue,
        lossRatioVarianceValue: row.oepLossRatioVarianceValue,
      }
    } else {
      return {
        returnPeriod: row.returnPeriod,
        value: row.aepValue,
        varianceValue: row.aepVarianceValue,
        lossRatioValue: row.aepLossRatioValue,
        lossRatioVarianceValue: row.aepLossRatioVarianceValue,
      }
    }
  })
}

export function buildSummaryFilterMap(
  lossSetLayers: LossSetLayer[],
  summaryFilterOptions: MappingOption[]
): ExploreFilterMap {
  const summaryFilterMap: ExploreFilterMap = {}
  const layers = lossSetLayers.map(layer => {
    const lossSetLoaded = isLoadedLossSet(layer.loss_sets[0])
    const loadedLossSet = layer.loss_sets[0] as LoadedLossSet
    const lossScaleFactor = lossSetLoaded ? loadedLossSet.load : 1
    const originalPremium = layer.meta_data.originalPremium || layer.meta_data.originalPremium === 0
      ? layer.meta_data.originalPremium
      : layer.premium.value
    const premiumScaleFactor = originalPremium === 0
      ? 0
      : layer.meta_data.originalPremium
        ? layer.premium.value / originalPremium
        : 1
    return {
      ...layer,
      meta_data: {
        ...layer.meta_data,
        lossScaleFactor,
        premiumScaleFactor
      }
    }
  })
  summaryFilterOptions.forEach(filterOption => {
    const value = filterOption.value
    const optionValue = value as keyof Metadata
    layers.forEach(layer => {
      const option = String(layer.meta_data[optionValue])
        const filterKey = `${value}~${option}`
        if (!(filterKey in summaryFilterMap)) {
          summaryFilterMap[filterKey] = true
        }
    })
  })
  return summaryFilterMap
}

export function getMetaValuesForGroupedSummaryData(metaData: Partial<Metadata>, modelingArray: string[]) {
  const meta_data:
  {
    loss_type: string,
    ls_dim1: string,
    ls_dim2: string,
    map1: string,
    map2: string,
    map3: string,
    map4: string,
    map5: string,
    premiumScaleFactor: string,
    lossScaleFactor: string
  } = {
    loss_type: '',
    ls_dim1: '',
    ls_dim2: '',
    map1: '',
    map2: '',
    map3: '',
    map4: '',
    map5: '',
    premiumScaleFactor: '',
    lossScaleFactor: ''
  }
  const groupByValues: string[] = []
  modelingArray.forEach((key) => {
    if (key && key in metaData) {
      meta_data[key as keyof typeof meta_data] = String(metaData[key as keyof Metadata])
      groupByValues.push(String(metaData[key as keyof Metadata]))
    }
  })
  const groupBy = groupByValues.join('/')
  return {
    meta_data,
    groupBy
  }
}

export function getRowsFromGroupSummaryData(
  groupSummaryData: SummaryDataResponse[],
  summaryData:SummaryDataResponse[],
  modelingArray: string[],
  groups: GroupSummaryRequest[],

): ExploreSummaryDatum[] {
  const lslRows: ExploreSummaryDatum[] = []
  groupSummaryData.forEach((data, i) => {
    const group = groups[i]
    const premium = data.subjectPremiumAmt
    const expense = data.expense ?? 0
    const expenseRatio = expense / premium
    const expectedLoss = data.netLoss ?? 0
    const expectedUWResult = data.uwResultVaR?.data[6]?.mean ?? 0
    const aep100 = data.largeRiskDistributionAEP.data[6]
    let expectedLossRatio = 0
    let expectedCombinedRatio = 0
    let totalLossRatioCV = 0
    if (premium > 0) {
      expectedLossRatio = expectedLoss / premium
      expectedCombinedRatio = getCombinedRatio(expectedUWResult, premium)
      totalLossRatioCV = (Math.sqrt(aep100.variance) / aep100.mean)
    }
    const layers = getLayerScaleFactors(group.layers)

    const layerValues = getMetaValuesForGroupedSummaryData(layers[0].meta_data, modelingArray)

    const {
      loss_type,
      ls_dim1,
      ls_dim2,
      map1,
      map2,
      map3,
      map4,
      map5,
      premiumScaleFactor,
      lossScaleFactor
    } = layerValues.meta_data

    const lossTypeData = getTypeTotals(layers, summaryData)
    const nameSplit = group.name.split('_')
    const tabLength = nameSplit.length - 1
    const tabs = '    '.repeat(tabLength)

    lslRows.push({
      id: group.ids.join(','),
      name: group.name,
      groupBy: tabs + nameSplit[tabLength],
      loss_type: loss_type,
      ls_dim1: ls_dim1,
      ls_dim2: ls_dim2,
      map1: map1,
      map2: map2,
      map3: map3,
      map4: map4,
      map5: map5,
      lossScaleFactor,
      premiumScaleFactor,
      premium,
      expense,
      lossByType_Attritional: lossTypeData.lossByType_Attritional,
      lossByType_Large: lossTypeData.lossByType_Large,
      lossByType_Cat: lossTypeData.lossByType_Cat,
      expectedLoss,
      expectedUWResult,
      expenseRatio,
      lossRatioByType_Attritional: lossTypeData.lossRatioByType_Attritional,
      lossRatioByType_Large: lossTypeData.lossRatioByType_Large,
      lossRatioByType_Cat: lossTypeData.lossRatioByType_Cat,
      expectedLossRatio,
      expectedCombinedRatio,
      lossRatioCV_Attritional: lossTypeData.lossRatioCV_Attritional,
      lossRatioCV_Large: lossTypeData.lossRatioCV_Large,
      lossRatioCV_Cat: lossTypeData.lossRatioCV_Cat,
      totalLossRatioCV,
      largeRisk_Frequency: 0,
      largeRisk_Severity: 0,
      largeRiskDistributionOEP_10: data.largeRiskDistributionOEP?.data[0]?.mean ?? 0,
      largeRiskDistributionOEP_25: data.largeRiskDistributionOEP?.data[1]?.mean ?? 0,
      largeRiskDistributionOEP_50: data.largeRiskDistributionOEP?.data[2]?.mean ?? 0,
      largeRiskDistributionOEP_100: data.largeRiskDistributionOEP?.data[3]?.mean ?? 0,
      largeRiskDistributionOEP_200: data.largeRiskDistributionOEP?.data[4]?.mean ?? 0,
      largeRiskDistributionOEP_250: data.largeRiskDistributionOEP?.data[5]?.mean ?? 0,
      largeRiskDistributionAEP_10: data.largeRiskDistributionAEP?.data[0]?.mean ?? 0,
      largeRiskDistributionAEP_25: data.largeRiskDistributionAEP?.data[1]?.mean ?? 0,
      largeRiskDistributionAEP_50: data.largeRiskDistributionAEP?.data[2]?.mean ?? 0,
      largeRiskDistributionAEP_100: data.largeRiskDistributionAEP?.data[3]?.mean ?? 0,
      largeRiskDistributionAEP_200: data.largeRiskDistributionAEP?.data[4]?.mean ?? 0,
      largeRiskDistributionAEP_250: data.largeRiskDistributionAEP?.data[5]?.mean ?? 0,
      uwResultVaR_10: data.uwResultVaR?.data[0]?.mean ?? 0,
      uwResultVaR_25: data.uwResultVaR?.data[1]?.mean ?? 0,
      uwResultVaR_50: data.uwResultVaR?.data[2]?.mean ?? 0,
      uwResultVaR_100: data.uwResultVaR?.data[3]?.mean ?? 0,
      uwResultVaR_200: data.uwResultVaR?.data[4]?.mean ?? 0,
      uwResultVaR_250: data.uwResultVaR?.data[5]?.mean ?? 0,
      combinedRatiosVaR_10: getCombinedRatio(data.uwResultVaR?.data[0]?.mean ?? 0, premium),
      combinedRatiosVaR_25: getCombinedRatio(data.uwResultVaR?.data[1]?.mean ?? 0, premium),
      combinedRatiosVaR_50: getCombinedRatio(data.uwResultVaR?.data[2]?.mean ?? 0, premium),
      combinedRatiosVaR_100: getCombinedRatio(data.uwResultVaR?.data[3]?.mean ?? 0, premium),
      combinedRatiosVaR_200: getCombinedRatio(data.uwResultVaR?.data[4]?.mean ?? 0, premium),
      combinedRatiosVaR_250: getCombinedRatio(data.uwResultVaR?.data[5]?.mean ?? 0, premium),
      contributionToGroupVolatility_Attritional: data.contributionToGroupVolatilityAttr,
      contributionToGroupVolatility_Large: data.contributionToGroupVolatilityLarge,
      contributionToGroupVolatility_Cat: data.contributionToGroupVolatilityCat,
      totalContributionToGroupVolatility: data.contributionToGroupVolatility,
    })
  })
  return lslRows
}

export function getRowsFromLossSets(
  lossSetLayers: LossSetLayer[],
  summaryData: SummaryDataResponse[]
): ExploreSummaryDatum[] {
  const lslRows: ExploreSummaryDatum[] = []
  lossSetLayers.forEach(layer => {
    const summary = summaryData.filter(s => s.lossSetID === layer.id)[0]
    const {
      loss_type,
      ls_dim1,
      ls_dim2,
      map1,
      map2,
      map3,
      map4,
      map5,
      lossScaleFactor,
      premiumScaleFactor
    } = layer.meta_data
    const premium = layer.premium.value
    const expense = summary?.expense ?? 0
    const expenseRatio = expense / premium
    const expectedLoss = summary?.netLoss ?? 0
    const expectedUWResult = summary?.uwResultVaR?.data[6]?.mean ?? 0
    const aep100 = summary?.largeRiskDistributionAEP.data[6]
    let expectedLossRatio = 0
    let expectedCombinedRatio = 0
    let totalLossRatioCV = 0
    if (premium > 0) {
      expectedLossRatio = expectedLoss / premium
      expectedCombinedRatio = getCombinedRatio(expectedUWResult, premium)
      totalLossRatioCV = (Math.sqrt(aep100.variance) / aep100.mean)
    }

    const lossTypeData = getLossByLayerType(layer, summary, expectedLossRatio, totalLossRatioCV)

    lslRows.push({
      id: layer.id,
      name: layer.description,
      groupBy: '',
      loss_type: loss_type,
      ls_dim1: ls_dim1,
      ls_dim2: ls_dim2,
      map1: map1,
      map2: map2,
      map3: map3,
      map4: map4,
      map5: map5,
      lossScaleFactor,
      premiumScaleFactor,
      premium,
      expense,
      lossByType_Attritional: lossTypeData.lossByType_Attritional,
      lossByType_Large: lossTypeData.lossByType_Large,
      lossByType_Cat: lossTypeData.lossByType_Cat,
      expectedLoss,
      expectedUWResult,
      expenseRatio,
      lossRatioByType_Attritional: lossTypeData.lossRatioByType_Attritional,
      lossRatioByType_Large: lossTypeData.lossRatioByType_Large,
      lossRatioByType_Cat: lossTypeData.lossRatioByType_Cat,
      expectedLossRatio,
      expectedCombinedRatio,
      lossRatioCV_Attritional: lossTypeData.lossRatioCV_Attritional,
      lossRatioCV_Large: lossTypeData.lossRatioCV_Large,
      lossRatioCV_Cat: lossTypeData.lossRatioCV_Cat,
      totalLossRatioCV,
      largeRisk_Frequency: 0,
      largeRisk_Severity: 0,
      largeRiskDistributionOEP_10: summary?.largeRiskDistributionOEP?.data[0]?.mean ?? 0,
      largeRiskDistributionOEP_25: summary?.largeRiskDistributionOEP?.data[1]?.mean ?? 0,
      largeRiskDistributionOEP_50: summary?.largeRiskDistributionOEP?.data[2]?.mean ?? 0,
      largeRiskDistributionOEP_100: summary?.largeRiskDistributionOEP?.data[3]?.mean ?? 0,
      largeRiskDistributionOEP_200: summary?.largeRiskDistributionOEP?.data[4]?.mean ?? 0,
      largeRiskDistributionOEP_250: summary?.largeRiskDistributionOEP?.data[5]?.mean ?? 0,
      largeRiskDistributionAEP_10: summary?.largeRiskDistributionAEP?.data[0]?.mean ?? 0,
      largeRiskDistributionAEP_25: summary?.largeRiskDistributionAEP?.data[1]?.mean ?? 0,
      largeRiskDistributionAEP_50: summary?.largeRiskDistributionAEP?.data[2]?.mean ?? 0,
      largeRiskDistributionAEP_100: summary?.largeRiskDistributionAEP?.data[3]?.mean ?? 0,
      largeRiskDistributionAEP_200: summary?.largeRiskDistributionAEP?.data[4]?.mean ?? 0,
      largeRiskDistributionAEP_250: summary?.largeRiskDistributionAEP?.data[5]?.mean ?? 0,
      uwResultVaR_10: summary?.uwResultVaR?.data[0]?.mean ?? 0,
      uwResultVaR_25: summary?.uwResultVaR?.data[1]?.mean ?? 0,
      uwResultVaR_50: summary?.uwResultVaR?.data[2]?.mean ?? 0,
      uwResultVaR_100: summary?.uwResultVaR?.data[3]?.mean ?? 0,
      uwResultVaR_200: summary?.uwResultVaR?.data[4]?.mean ?? 0,
      uwResultVaR_250: summary?.uwResultVaR?.data[5]?.mean ?? 0,
      combinedRatiosVaR_10: getCombinedRatio(summary?.uwResultVaR?.data[0]?.mean ?? 0, premium),
      combinedRatiosVaR_25: getCombinedRatio(summary?.uwResultVaR?.data[1]?.mean ?? 0, premium),
      combinedRatiosVaR_50: getCombinedRatio(summary?.uwResultVaR?.data[2]?.mean ?? 0, premium),
      combinedRatiosVaR_100: getCombinedRatio(summary?.uwResultVaR?.data[3]?.mean ?? 0, premium),
      combinedRatiosVaR_200: getCombinedRatio(summary?.uwResultVaR?.data[4]?.mean ?? 0, premium),
      combinedRatiosVaR_250: getCombinedRatio(summary?.uwResultVaR?.data[5]?.mean ?? 0, premium),
    })
  })
  return lslRows
}

export function formatSummaryRows(rows: ExploreSummaryDatum[], currency: string, layers: LossSetLayer[]): ExploreSummaryDatum[] {
  const shortNumberPipe = new ShortNumberPipe()
  return styleRows(rows.map(row => {
    return {
      ...row,
      premium: getDisplayValue(row.premium, "currency", currency),
      expense: getDisplayValue(row.expense, "currency", currency),
      lossByType_Attritional: getDisplayValue(row.lossByType_Attritional, "currency", currency),
      lossByType_Large: getDisplayValue(row.lossByType_Large, "currency", currency),
      lossByType_Cat: getDisplayValue(row.lossByType_Cat, "currency", currency),
      expectedLoss: getDisplayValue(row.expectedLoss, "currency", currency),
      expectedUWResult: getDisplayValue(row.expectedUWResult, "currency", currency),
      lossRatioCV_Attritional: getDisplayValue(Number(row.lossRatioCV_Attritional), "fixed"),
      lossRatioCV_Large: getDisplayValue(Number(row.lossRatioCV_Large), "fixed"),
      lossRatioCV_Cat: getDisplayValue(Number(row.lossRatioCV_Cat), "fixed"),
      totalLossRatioCV: getDisplayValue(Number(row.totalLossRatioCV), "fixed"),
      largeRiskDistributionOEP_10: getDisplayValue(row.largeRiskDistributionOEP_10, "currency", currency),
      largeRiskDistributionOEP_25: getDisplayValue(row.largeRiskDistributionOEP_25, "currency", currency),
      largeRiskDistributionOEP_50: getDisplayValue(row.largeRiskDistributionOEP_50, "currency", currency),
      largeRiskDistributionOEP_100: getDisplayValue(row.largeRiskDistributionOEP_100, "currency", currency),
      largeRiskDistributionOEP_200: getDisplayValue(row.largeRiskDistributionOEP_200, "currency", currency),
      largeRiskDistributionOEP_250: getDisplayValue(row.largeRiskDistributionOEP_250, "currency", currency),
      largeRiskDistributionAEP_10: getDisplayValue(row.largeRiskDistributionAEP_10, "currency", currency),
      largeRiskDistributionAEP_25: getDisplayValue(row.largeRiskDistributionAEP_25, "currency", currency),
      largeRiskDistributionAEP_50: getDisplayValue(row.largeRiskDistributionAEP_50, "currency", currency),
      largeRiskDistributionAEP_100: getDisplayValue(row.largeRiskDistributionAEP_100, "currency", currency),
      largeRiskDistributionAEP_200: getDisplayValue(row.largeRiskDistributionAEP_200, "currency", currency),
      largeRiskDistributionAEP_250: getDisplayValue(row.largeRiskDistributionAEP_250, "currency", currency),
      uwResultVaR_10: getDisplayValue(row.uwResultVaR_10, "currency", currency),
      uwResultVaR_25: getDisplayValue(row.uwResultVaR_25, "currency", currency),
      uwResultVaR_50: getDisplayValue(row.uwResultVaR_50, "currency", currency),
      uwResultVaR_100: getDisplayValue(row.uwResultVaR_100, "currency", currency),
      uwResultVaR_200: getDisplayValue(row.uwResultVaR_200, "currency", currency),
      uwResultVaR_250: getDisplayValue(row.uwResultVaR_250, "currency", currency),
    }
  }), layers)
}

export function styleRows(rows: SortTableRow<ExploreSummaryDatum>[], layers: LossSetLayer[]): SortTableRow<ExploreSummaryDatum>[] {
  const layerNames = layers.map(l => l.description)
  return rows.map(r => {
    const style = layerNames.includes(r.name.trim()) ? { backgroundColor: 'var(--subtle)', color: 'var(--body)'} : undefined
    return {
      ...r,
      style
    }
  })
}

export function getGroupedSummaryData(
  groupSummaryData: SummaryDataResponse[],
  summaryData:SummaryDataResponse[],
  modelingArray: string[],
  groups: GroupSummaryRequest[],
  showIndividualLossSets: boolean,
  data: ExploreSummaryDatum[],
  currency: string,
  layers: LossSetLayer[]
): ExploreSummaryDatum[] {
  const model = modelingArray.filter(x => x !== '')
  let result = getRowsFromGroupSummaryData(groupSummaryData, summaryData, modelingArray, groups)
  if (showIndividualLossSets) {
    result = insertIndividualLossSets(data, model, result)
  }
  return formatSummaryRows(result, currency, layers)
}

function insertIndividualLossSets(
  data: ExploreSummaryDatum[],
  modelingArray: string[],
  result: ExploreSummaryDatum[],
): ExploreSummaryDatum[] {
  const lastLevel = modelingArray.length
  let finalResult: ExploreSummaryDatum[] = []
  result.forEach((groupedRow) => {
    const groupParts = groupedRow.name.split('_')
    finalResult.push(groupedRow)
    if (groupParts.length === lastLevel) {
      const matchingRows = data.filter(d => groupedRow.id.includes(d.id))
      matchingRows.forEach(row => {
        const tab = '    '.repeat(lastLevel)
        finalResult.push({
          ...row,
          name: row.name,
          groupBy: tab + groupParts[lastLevel - 1]
        })
      })
    }
  })
  return finalResult
}

export function extractFilterMapKeys(filterMap: Record<string, boolean>, split: string): Record<string, string[]> {
  const result: Record<string, string[]> = {}

  Object.keys(filterMap).forEach(key => {
    const parts = key.split(split)
    const mapKey = parts.slice(0, parts.length - 1).join(split)
    const mapValue = parts[parts.length - 1]
    if (filterMap[key]) {
      if (!result[mapKey]) {
        result[mapKey] = []
      }
      result[mapKey].push(mapValue)
    }
  })
  return result
}

export function getTypeTotals(
  layers: LossSetLayer[],
  summaryData: SummaryDataResponse[]
): {
  lossByType_Attritional: number,
  lossByType_Large: number,
  lossByType_Cat: number,
  lossRatioByType_Attritional: number,
  lossRatioByType_Large: number,
  lossRatioByType_Cat: number,
  lossRatioCV_Attritional: number,
  lossRatioCV_Large: number,
  lossRatioCV_Cat: number,
}  {
  let totals = {
    lossByType_Attritional: 0,
    lossByType_Large: 0,
    lossByType_Cat: 0,
    lossRatioByType_Attritional: 0,
    lossRatioByType_Large: 0,
    lossRatioByType_Cat: 0,
    lossRatioCV_Attritional: 0,
    lossRatioCV_Large: 0,
    lossRatioCV_Cat: 0,
  }
  layers.forEach(layer => {
    const summary = summaryData.filter(s => s.lossSetID === layer.id)[0]
    const premium = layer.premium.value
    const expectedLoss = summary?.netLoss ?? 0
    const aep100 = summary?.largeRiskDistributionAEP.data[6]
    let expectedLossRatio = 0
    let totalLossRatioCV = 0
    if (premium > 0) {
      expectedLossRatio = expectedLoss / premium
      totalLossRatioCV = (Math.sqrt(aep100.variance) / aep100.mean)
    }
    const typeData = getLossByLayerType(layer, summary, expectedLossRatio, totalLossRatioCV)
    totals = {
      lossByType_Attritional: totals.lossByType_Attritional += typeData.lossByType_Attritional,
      lossByType_Large: totals.lossByType_Large += typeData.lossByType_Large,
      lossByType_Cat: totals.lossByType_Cat += typeData.lossByType_Cat,
      lossRatioByType_Attritional: totals.lossRatioByType_Attritional += typeData.lossRatioByType_Attritional,
      lossRatioByType_Large: totals.lossRatioByType_Large += typeData.lossRatioByType_Large,
      lossRatioByType_Cat: totals.lossRatioByType_Cat += typeData.lossRatioByType_Cat,
      lossRatioCV_Attritional: totals.lossRatioCV_Attritional += typeData.lossRatioCV_Attritional,
      lossRatioCV_Large: totals.lossRatioCV_Large += typeData.lossRatioCV_Large,
      lossRatioCV_Cat: totals.lossRatioCV_Cat += typeData.lossRatioCV_Cat,
    }
  })

  return totals
}

export function getLossByLayerType(
  layer: LossSetLayer,
  summary: SummaryDataResponse,
  expectedLossRatio: number,
  totalLossRatioCV: number
): {
  lossByType_Attritional: number,
  lossByType_Large: number,
  lossByType_Cat: number,
  lossRatioByType_Attritional: number,
  lossRatioByType_Large: number,
  lossRatioByType_Cat: number,
  lossRatioCV_Attritional: number,
  lossRatioCV_Large: number,
  lossRatioCV_Cat: number,
}  {
  let data = {
    lossByType_Attritional: 0,
    lossByType_Large: 0,
    lossByType_Cat: 0,
    lossRatioByType_Attritional: 0,
    lossRatioByType_Large: 0,
    lossRatioByType_Cat: 0,
    lossRatioCV_Attritional: 0,
    lossRatioCV_Large: 0,
    lossRatioCV_Cat: 0,
  }
  const { loss_type } = layer.meta_data
  switch (loss_type) {
    case 'large':
      data = {
        ...data,
        lossByType_Large: summary?.netLoss ?? 0,
        lossRatioByType_Large: expectedLossRatio,
        lossRatioCV_Large: totalLossRatioCV,
      }
      break;
    case 'cat':
      data = {
        ...data,
        lossByType_Cat: summary?.netLoss ?? 0,
        lossRatioByType_Cat: expectedLossRatio,
        lossRatioCV_Cat: totalLossRatioCV,
      }
      break;
    case 'attr':
      data = {
        ...data,
        lossByType_Attritional: summary?.netLoss ?? 0,
        lossRatioByType_Attritional: expectedLossRatio,
        lossRatioCV_Attritional: totalLossRatioCV,
      }
      break;
    default:
      break;
  }

  return data
}

export function getSummaryResponse(
  layers: {
    lossID: string
    viewID: string
    lossName: string
    lossType: string
    filterValue: string
    isLossRatioView?: boolean
    subjectPremiumAmt?: number
  }[],
  response: any,
  isGroup?: boolean
): SummaryDataResponse[] {
  const summaryData: SummaryDataResponse[] = []
  const mainLayers = layers?.filter(layer => {
    const isGroupVariance = isGroup && layer.lossName.includes('variance')
    return !isGroupVariance
  })
  const dataLength = 5 + (isGroup ? 1 : 0)
  const varianceResponses = response.slice(dataLength * mainLayers.length)
  mainLayers.forEach((layer, i) => {
    const start = i * dataLength
    const end = (i + 1) * dataLength
    const res = response.slice(start, end)
    const lossSetID = layer.lossID
    const expense = res[0].data.mean ?? 0
    const netLoss = res[1].data.mean ?? 0
    const oep = res[2].data
    const aep = res[3].data
    const uw = res[4].data
    const subjectPremiumAmt = layer.subjectPremiumAmt
    let contributionToGroupVolatility = 0
    let contributionToGroupVolatilityAttr = 0
    let contributionToGroupVolatilityLarge = 0
    let contributionToGroupVolatilityCat = 0
    if (isGroup) {
      const componentCV = res[5].data.groupComponentCovariance.component_metrics.covariance
      const groupCV = res[5].data.groupCovariance.variance
      contributionToGroupVolatility = componentCV / groupCV
      const attrGroupID = layers.find(l => {
        return l.lossName.includes('attr_variance') && l.lossName.split('~')[0] === layer.lossName
      })?.lossID
      if (attrGroupID) {
        const res = varianceResponses.find((res: any) => res.data.groupComponentCovariance.context.component.ref_id === attrGroupID)
        if (res) {
          const cCV = res.data.groupComponentCovariance.component_metrics.covariance
          const gCV = res.data.groupCovariance.variance
          contributionToGroupVolatilityAttr = cCV / gCV
        }
      }
      const largeGroupID = layers.find(l => {
        return l.lossName.includes('large_variance') && l.lossName.split('~')[0] === layer.lossName
      })?.lossID
      if (largeGroupID) {
        const res = varianceResponses.find((res: any) => res.data.groupComponentCovariance.context.component.ref_id === largeGroupID)
        if (res) {
          const cCV = res.data.groupComponentCovariance.component_metrics.covariance
          const gCV = res.data.groupCovariance.variance
          contributionToGroupVolatilityLarge = cCV / gCV
        }
      }
      const catGroupID = layers.find(l => {
        return l.lossName.includes('cat_variance') && l.lossName.split('~')[0] === layer.lossName
      })?.lossID
      if (catGroupID) {
        const res = varianceResponses.find((res: any) => res.data.groupComponentCovariance.context.component.ref_id === catGroupID)
        if (res) {
          const cCV = res.data.groupComponentCovariance.component_metrics.covariance
          const gCV = res.data.groupCovariance.variance
          contributionToGroupVolatilityCat = cCV / gCV
        }
      }
    }
    const largeRiskDistributionOEP: SummaryArrayDatum = {
      data: [
        oep[0],
        oep[1],
        oep[2],
        oep[3],
        oep[4],
        oep[5],
        oep[6]
      ],
      lossName: oep.lossName,
      lossType: oep.lossType,
      rpArray: oep.rpArray,
      lossFilter: oep.lossFilter,
      aggregationMethod: oep.aggregationMethod
    }
    const largeRiskDistributionAEP: SummaryArrayDatum = {
      data: [
        aep[0],
        aep[1],
        aep[2],
        aep[3],
        aep[4],
        aep[5],
        aep[6]
      ],
      lossName: aep.lossName,
      lossType: aep.lossType,
      rpArray: aep.rpArray,
      lossFilter: aep.lossFilter,
      aggregationMethod: aep.aggregationMethod
    }
    const uwResultVaR: SummaryArrayDatum = {
      data: [
        uw[0],
        uw[1],
        uw[2],
        uw[3],
        uw[4],
        uw[5],
        uw[6]
      ],
      lossName: uw.lossName,
      lossType: uw.lossType,
      rpArray: uw.rpArray,
      lossFilter: uw.lossFilter,
      aggregationMethod: uw.aggregationMethod
    }
    const expectedUWResult = uwResultVaR.data[3].mean
    summaryData.push({
      lossSetID,
      subjectPremiumAmt,
      expense,
      netLoss,
      largeRiskDistributionOEP,
      largeRiskDistributionAEP,
      uwResultVaR,
      expectedUWResult,
      contributionToGroupVolatility,
      contributionToGroupVolatilityAttr,
      contributionToGroupVolatilityLarge,
      contributionToGroupVolatilityCat,
    })
  })
  return summaryData
}

export function getCombinedRatio(uwResult: number, premium: number): number {
  const value = premium !== 0 ? 1 - (uwResult / premium) : 0
  return value
}

export function getDisplayValue(value: number | string, type: string, currency?: string): number | string {
  const shortNumberPipe = new ShortNumberPipe()
  let displayValue: number | string = value
  if (Number(value) !== 0) {
    if (type === 'currency') {
      displayValue = shortNumberPipe.transform(Number(value), currency)
    } else if (type === 'fixed') {
      displayValue = Number(value).toFixed(1)
    }
  } else {
    return '-'
  }
  return displayValue
}

export function getGroupedSummaryRequest(
  data: ExploreSummaryDatum[],
  modelingArray: string[],
  lossSetLayers: LossSetLayer[]
): GroupSummaryRequest[] {
  const model = modelingArray.filter(x => x !== '')
  const result = organizeGroupRequests(data, 0, model, '')
  return result.map(r => {
    const layers = lossSetLayers.filter(l => r.ids.includes(l.id)) ?? []
    return {
      ...r,
      layers
    }
  })
}

export function organizeGroupRequests(
  items: ExploreSummaryDatum[],
  groupIndex: number,
  modelingArray: string[],
  previousGroupName: string = ''
): GroupSummaryRequest[] {
  if (groupIndex >= modelingArray.length) return []

  const groupByField = modelingArray[groupIndex]
  const grouped = items.reduce((acc, item) => {
    const key = item[groupByField as keyof ExploreSummaryDatum]
    if (!acc[key]) acc[key] = []
    acc[key].push(item)
    return acc
  }, {} as { [key: string]: ExploreSummaryDatum[] })

  let result: GroupSummaryRequest[] = []

  Object.keys(grouped).forEach(groupKey => {
    const groupItems = grouped[groupKey]
    const groupName = previousGroupName ? `${previousGroupName}_${groupKey}` : groupKey
    const GroupSummaryRequest = getLossSetAggregateRequests(groupItems)
    result.push({
      name: groupName,
      ids: GroupSummaryRequest.ids
    })
    result = result.concat(organizeGroupRequests(groupItems, groupIndex + 1, modelingArray, groupName))
  })

  return result
}

function getLossSetAggregateRequests(items: ExploreSummaryDatum[]): GroupSummaryRequest {
  const ids = items.map(item => item.id)
  return {
    name: '',
    ids: ids
  }
}

export function getExploreSummaryAPIs(
  lossSetLayers: {
    lossID: string
    viewID: string
    lossName: string
    lossType: string
    filterValue: string
    isLossRatioView?: boolean
    subjectPremiumAmt?: number
    groupType?: string
  }[],
  modifiers: ModifierState,
  service: AnalyzreService,
  portfolioViewID: string
): ApiResponse<
    Metrics[] & {
      lossSetID: string
      lossType: string
      lossName: string
      rpArray: number[]
      vartvar: VaRTVaR
      lossFilter: string
      isLossRatioView: boolean
      subjectPremiumAmt: number
      aggregationMethod: string
      mean?: number
    }
>[] {
  const arrRP = [10,25,50,100,200,250,1000]
  const exploreApis: ApiResponse<
  Metrics[] & {
      lossSetID: string
      lossType: string
      lossName: string
      rpArray: number[]
      vartvar: VaRTVaR
      lossFilter: string
      isLossRatioView: boolean
      subjectPremiumAmt: number
      aggregationMethod: string
      mean?: number
    }
  >[] = []
  lossSetLayers?.forEach(l => {
    const oepMetrics = service.getExploreDataValues(
      l.viewID,
      arrRP,
      l.lossType,
      modifiers.perspective,
      'OEP',
      modifiers.vartvar,
      l.filterValue ?? 'all',
      l.lossID,
      l.lossName,
      false,
      l.subjectPremiumAmt || 0
    )
    const aepMetrics = service.getExploreDataValues(
      l.viewID,
      arrRP,
      l.lossType,
      modifiers.perspective,
      'AEP',
      modifiers.vartvar,
      l.filterValue ?? 'all',
      l.lossID,
      l.lossName,
      false,
      l.subjectPremiumAmt || 0
    )
    const UWMetrics = service.getExploreDataValues(
      l.viewID,
      arrRP,
      l.lossType,
      'UW',
      'AEP',
      modifiers.vartvar,
      l.filterValue ?? 'all',
      l.lossID,
      l.lossName,
      false,
      l.subjectPremiumAmt || 0
    )

    const expensePremium = service.getExpensePremium(l.viewID, l.lossType)
    const netLoss = service.getExpectedNetLoss(l.viewID, l.lossType)
    if(l.groupType !== 'Variance') {
      exploreApis.push(expensePremium)
      exploreApis.push(netLoss)
      exploreApis.push(oepMetrics)
      exploreApis.push(aepMetrics)
      exploreApis.push(UWMetrics)
    }
    if (l.lossType === 'Group') {
      exploreApis.push(service.getGroupAndComponentVolatility(l.viewID, portfolioViewID))
    }
  })
  return exploreApis
}

function getLayerScaleFactors(layers: LossSetLayer[]): LossSetLayer[] {
  return layers.map(layer => {
    const lossSetLoaded = isLoadedLossSet(layer.loss_sets[0])
    const loadedLossSet = layer.loss_sets[0] as LoadedLossSet
    const lossScaleFactor = lossSetLoaded ? loadedLossSet.load : 1
    const originalPremium = layer.meta_data.originalPremium || layer.meta_data.originalPremium === 0
      ? layer.meta_data.originalPremium
      : layer.premium.value
    const premiumScaleFactor = originalPremium === 0
      ? 0
      : layer.meta_data.originalPremium
        ? layer.premium.value / originalPremium
        : 1
    return {
      ...layer,
      meta_data: {
        ...layer.meta_data,
        lossScaleFactor,
        premiumScaleFactor
      }
    }
  })
}

export function filterLossSets(lossSetLayers: LossSetLayer[], summaryFilterMap: ExploreFilterMap): LossSetLayer[] {
  const map = extractFilterMapKeys(summaryFilterMap, '~')
  const layers = lossSetLayers.map(layer => {
    const lossSetLoaded = isLoadedLossSet(layer.loss_sets[0])
    const loadedLossSet = layer.loss_sets[0] as LoadedLossSet
    const lossScaleFactor = lossSetLoaded ? loadedLossSet.load : 1
    const originalPremium = layer.meta_data.originalPremium || layer.meta_data.originalPremium === 0
      ? layer.meta_data.originalPremium
      : layer.premium.value
    const premiumScaleFactor = originalPremium === 0
      ? 0
      : layer.meta_data.originalPremium
        ? layer.premium.value / originalPremium
        : 1
    return {
      ...layer,
      meta_data: {
        ...layer.meta_data,
        lossScaleFactor,
        premiumScaleFactor
      }
    }
  })
  return layers.filter(layer => {
    return Object.keys(map).every(key => {
      const metaValue = String(layer.meta_data[key as keyof typeof layer.meta_data])
      const allowedValues = map[key]
      if (!allowedValues || allowedValues.includes("undefined") || allowedValues.length === 0) {
        return true
      }
      return allowedValues.includes(metaValue)
    })
  })
}

