import {
  ChangeDetectionStrategy,
    ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { LossSetGroup, LossSetLayer, ScaledLossSetProps } from '../../model/loss-set-layers.model'
import { PortfolioSetID } from '../../model/portfolio-set.model'
import { AnalysisInitProps } from '../../store/analysis.actions'
import { OmitID, StudyResponse } from 'src/app/api/model/backend.model'
import { Program } from 'src/app/core/model/program.model'
import { LoadedLossSet, Metadata } from 'src/app/api/analyzere/analyzere.model'
import { layerLossSetColDefs } from '../layer-loss-sets-defs'
import { MatSelectChange } from '@angular/material/select'
import { SortTableRow } from '@shared/sort-table/sort-table.model'
import { getRows } from '../lossset-utils/loss-sets.util'
import { isLoadedLossSet } from '../../model/layers.util'
import { DEFAULT_MAPPING_LABELS, MappingLabels } from 'src/app/core/model/study.model'


interface MappingLists {
  map1: string[]
  map2: string[]
  map3: string[]
  map4: string[]
  map5: string[]
}

interface MapList {
  id: string,
  viewValue: string
}

type ExtendedMetaDataFields = 'premiumScaleFactor' | 'lossScaleFactor'

type groupMethods = 'dim' | 'map' | 'attribute'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-auto-group-loss-sets',
  styleUrls: ['./auto-group-loss-sets.component.scss'],
  templateUrl: `./auto-group-loss-sets.component.html`,
})
export class AutoGroupLossSetDialogComponent implements OnInit, OnChanges {
  @Input() parentLossSets: LossSetLayer[]
  @Input() portfolioSetID: PortfolioSetID
  @Input() selectedClientID: string
  @Input() selectedYearID: string
  @Input() selectedStudyID: string
  @Input() selectedStructureID: string
  @Input() currentProgram: Program
  @Input() studies: StudyResponse[]
  lsDim1List: string[] = []
  lsDim2List: string[] = []
  lsDim1SelectedList: string[] = []
  lsDim2SelectedList: string[] = []
  selectedLossSets: LossSetLayer[] = []
  lossSetMappingColumns: MapList[] = []
  selectedColumns: {[key: string]: boolean}
  groupName = ''
  groupMethod: groupMethods  = 'dim'
  mappingLists: MappingLists
  mappingColumns: number[] = []
  selectedLossSetGroups: OmitID<LossSetGroup>[] = []
  attributes: {value: string,  viewValue: string}[] = []
  attributeRows: SortTableRow<ScaledLossSetProps>[] = []
  layerLabels: {[key: string] : string} = {}
  savingDisabled = true
  mappingLabels: MappingLabels = DEFAULT_MAPPING_LABELS

  @Output() closeScaleDialog = new EventEmitter<AnalysisInitProps>()
  @Output() saveLossSetGroupClick = new EventEmitter<OmitID<LossSetGroup>[]>()

  ngOnChanges(changes: SimpleChanges): void {
    this.savingDisabled = !this.canSave()
    if (changes['parentLossSets']) {
      this.lossSetMappingColumns = this.getMappingColumns()
      this.selectedColumns = {}
      this.lossSetMappingColumns.forEach(col => {
        this.selectedColumns[col.id] = false
      })
    }
  }

  constructor(private changeRef: ChangeDetectorRef){}


  ngOnInit(): void {
    const study = this.studies.filter(s => String(s.id) === this.selectedStudyID)[0]
    if (study && study.mapping_labels) {
      this.mappingLabels = study.mapping_labels
    }
    this.parentLossSets.forEach(loss => {
      this.layerLabels[loss.id] = this.getLabel(loss)
    })
    this.attributeRows = getRows(this.parentLossSets)
    this.attributes = layerLossSetColDefs.map(col => {
      return {
        value: col.id as string,
        viewValue: col.label as string
      }
    })

    this.lossSetMappingColumns = this.getMappingColumns()
    this.selectedColumns = {}
    this.lossSetMappingColumns.forEach(col => {
      this.selectedColumns[col.id] = false
    })
    const mappingLists: MappingLists = {
      map1: [],
      map2: [],
      map3: [],
      map4: [],
      map5: [],
    }
    const lsDim1List: string[] = []
    const lsDim2List: string[] = []
    for (const ls of this.parentLossSets) {
      const { ls_dim1, ls_dim2, map1, map2, map3, map4, map5 } = ls.meta_data
      this.pushIfQualified(ls_dim1, lsDim1List)
      this.pushIfQualified(ls_dim2, lsDim2List)
      this.pushIfQualified(map1, mappingLists.map1)
      this.pushIfQualified(map2, mappingLists.map2)
      this.pushIfQualified(map3, mappingLists.map3)
      this.pushIfQualified(map4, mappingLists.map4)
      this.pushIfQualified(map5, mappingLists.map5)
    }
    this.mappingLists = mappingLists
    this.lsDim1List = lsDim1List
    this.lsDim2List = lsDim2List
  }

  pushIfQualified(
    value: string | number | undefined,
    list: (string | number)[],
    secondaryList?: (string | number)[]
  ) {
    if (!value) {
      return
    } else if (secondaryList && list.length > 0) {
      secondaryList.push(value)
      return
    } else if (!secondaryList && !list.includes(value)) {
      list.push(value)
    }
  }

  updateGroupMethod(method: groupMethods): void {
    this.groupMethod = method
    this.selectedLossSetGroups = []
    this.mappingColumns = []
  }

  updateGroupName(value: string): void {
    this.groupName = value
  }

  isSelected(value: string | number, list: (string | number)[]): boolean {
    return list.includes(value)
  }

  toggleSelectedColumn(col: string): void {
    this.selectedColumns[col] = !this.selectedColumns[col]
    this.updateLossSetSelectedGroups()
    this.savingDisabled = !this.canSave()
  }

  nullUndefinedOrEmpty(input: any): boolean {
    return input === undefined || input === null || input === ''
  }

  updateLossSetSelectedGroups(): void {
    let output: OmitID<LossSetGroup>[] = []
    Object.keys(this.selectedColumns).forEach((col: keyof Metadata) => {
      const lossSetGroupsMaps = new Map<string,OmitID<LossSetGroup>>()
      if (!this.selectedColumns[col]){
        return
      }
      this.parentLossSets.forEach(lossSet => {
        if (['premiumScaleFactor', 'lossScaleFactor'].includes(col)){
          this.addUniqueCalculatedFieldToMap(lossSet, col, lossSetGroupsMaps)
        }
        else {
          this.addUniqueMetaDataGroupsToMap(lossSet, col, lossSetGroupsMaps)
        }
      })
      output = output.concat(Array.from(lossSetGroupsMaps.values()))
    })
    this.sortLossSetGroups(output)
    this.selectedLossSetGroups = output
  }

  getLossScaleFactorFromLossSet(lossSet: LossSetLayer): number {
    const loadedLossSet = isLoadedLossSet(lossSet.loss_sets[0])
    const lossSetLoad = lossSet.loss_sets[0] as LoadedLossSet
    return loadedLossSet ? lossSetLoad.load : 1
  }

  getPremiumScaleFactorFromLossSet(lossSet: LossSetLayer): number {
    const originalPremium = lossSet.meta_data.originalPremium || lossSet.meta_data.originalPremium === 0
          ? lossSet.meta_data.originalPremium
          : lossSet.premium.value
    return originalPremium === 0
        ? 0
        : lossSet.meta_data.originalPremium
          ? lossSet.premium.value / originalPremium
          : 1
  }

  addUniqueCalculatedFieldToMap(lossSet: LossSetLayer, col: ExtendedMetaDataFields | keyof Metadata, lossSetGroupsMaps: Map<string,OmitID<LossSetGroup>>): void{
    let value: number
    let groupName: string
    if (col === 'premiumScaleFactor'){
      value = this.getPremiumScaleFactorFromLossSet(lossSet)
      groupName = `${value} : Premium Scale Factor`
    }
    else {
      value = this.getLossScaleFactorFromLossSet(lossSet)
      groupName = `${value} : Loss Scale Factor`
    }
    const lossGroup: OmitID<LossSetGroup> = lossSetGroupsMaps.get(groupName) || {
      name: groupName,
      lossSetLayers: [],
      programID: this.currentProgram.studyID
    }
    lossGroup.lossSetLayers.push(lossSet)
    lossSetGroupsMaps.set(groupName, lossGroup)
  }

  addUniqueMetaDataGroupsToMap(lossSet: LossSetLayer, col: keyof Metadata, lossSetGroupsMaps: Map<string,OmitID<LossSetGroup>>){
    if (!this.nullUndefinedOrEmpty(lossSet.meta_data[col])) {
      const metaDataField = String(lossSet.meta_data[col])
      const lossGroup: OmitID<LossSetGroup> = lossSetGroupsMaps.get(metaDataField) || {
        name: metaDataField,
        lossSetLayers: [],
        programID: this.currentProgram.studyID
      }
      lossGroup.lossSetLayers.push(lossSet)
      lossSetGroupsMaps.set(metaDataField, lossGroup)
    }
  }


  sortLossSetGroups(lossSetGroups: OmitID<LossSetGroup>[]): void{
    lossSetGroups.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)
  }

  toggleSelected(value: string, list: string): void {
    if (list === 'lsDim1') {
      const updatedDim1List = [...this.lsDim1SelectedList]
      if (!this.isSelected(value, this.lsDim1SelectedList)) {
        updatedDim1List.push(value)
      } else {
        const index = updatedDim1List.indexOf(value)
        updatedDim1List.splice(index, 1)
      }
      this.lsDim1SelectedList = updatedDim1List
    }
    if (list === 'lsDim2') {
      const updatedDim2List = [...this.lsDim2SelectedList]
      if (!this.isSelected(value, this.lsDim2SelectedList)) {
        updatedDim2List.push(value)
      } else {
        const index = updatedDim2List.indexOf(value)
        updatedDim2List.splice(index, 1)
      }
      this.lsDim2SelectedList = updatedDim2List
    }
    this.updateSelectedLossSets()
    this.changeRef.markForCheck()
  }

  updateSelectedLossSets(): void {
    const updatedSelectedLossSets: LossSetLayer[] = []
    for (const ls of this.parentLossSets) {
      const { ls_dim1, ls_dim2 } = ls.meta_data
      const addLossSet =
        (ls_dim1 && this.lsDim1SelectedList.includes(ls_dim1)) ||
        (ls_dim2 && this.lsDim2SelectedList.includes(ls_dim2))
      if (addLossSet && !updatedSelectedLossSets.includes(ls)) {
        updatedSelectedLossSets.push(ls)
      }
    }
    this.selectedLossSets = updatedSelectedLossSets
  }

  onCloseClick() {
    const initProps = {
      cededPortfolioID: this.portfolioSetID.cededPortfolioID,
      grossPortfolioID: this.portfolioSetID.grossPortfolioID,
      netPortfolioID: this.portfolioSetID.netPortfolioID,
      parentGrossPortfolioID: this.portfolioSetID.parentGrossPortfolioID,
      analysisProfileID: this.portfolioSetID.analysisProfileID,
      clientID: this.selectedClientID,
      studyID: this.selectedStudyID,
      yearID: this.selectedYearID,
      structureID: this.selectedStructureID,
    } as AnalysisInitProps
    this.closeScaleDialog.emit(initProps)
  }

  onSaveClick(): void {
    if (this.savingDisabled){
      return
    }
    let lossSetGroups: OmitID<LossSetGroup>[] = []
    if (this.groupMethod === 'dim') {
      lossSetGroups = [
        {
          name: this.groupName,
          programID: this.currentProgram.studyID,
          lossSetLayers: this.selectedLossSets,
        },
      ]
    } else {
      lossSetGroups = this.selectedLossSetGroups
    }
    this.saveLossSetGroupClick.emit(lossSetGroups)
    this.handleClear()
  }

  handleClear(): void {
    this.lsDim1SelectedList = []
    this.lsDim2SelectedList = []
    this.selectedLossSets = []
    this.updateGroupName('')
    this.selectedLossSetGroups = []
    this.mappingColumns = []
    Object.keys(this.selectedColumns).forEach(key => {
      this.selectedColumns[key] = false
    })
  }

  canSave(): boolean {
    var hasUnsaveableCondition = false
    if (this.groupMethod === 'dim') {
      hasUnsaveableCondition = [
        this.selectedLossSets.length === 0,
        this.groupName === ''
      ].includes(true)
    }
    else if (this.groupMethod === 'map'){
      hasUnsaveableCondition = [
        this.selectedLossSetGroups.length === 0,
      ].includes(true)
    }
    else if (this.groupMethod === 'attribute'){
      hasUnsaveableCondition = [
        this.selectedLossSetGroups.length === 0,
      ].includes(true)
    }
    return !hasUnsaveableCondition
  }

  getLabel(lossSet: LossSetLayer): string {
    return `${lossSet.meta_data.ls_dim1} - ${lossSet.meta_data.ls_dim2}`
  }

  getMappingColumns(): MapList[] {
    const setOfLossSets = new Map<string, MapList>()
    this.parentLossSets.forEach(lossSet => {
      Object.keys(lossSet.meta_data).forEach(key => {
        if (!lossSet.meta_data[key as keyof Metadata]){
          return
        }
        const mapMatch = key.match(/map[0-9]+/)
        if (mapMatch){ // Check for map1, map2, map3....
          const numberValue = mapMatch[0].match(/[0-9]+/)
          if (!numberValue){
            return
          }
          setOfLossSets.set(key,{
            id: key,
            viewValue: this.getMappingName(key) || `Mapping ${Number(numberValue[0])}`
          })
        }
        const lsDimMatch = key.match(/ls_dim[0-9]+/)
        if (lsDimMatch){ // Check for map1, map2, map3....
          const numberValue = lsDimMatch[0].match(/[0-9]+/)
          if (!numberValue){
            return
          }
          setOfLossSets.set(key,{
            id: key,
            viewValue: `LS Dim ${Number(numberValue[0])}`
          })
        }
      })
    })
    setOfLossSets.set('premiumScaleFactor', {id: 'premiumScaleFactor', viewValue: 'Premium Scale Factor'})
    setOfLossSets.set('lossScaleFactor', {id: 'lossScaleFactor', viewValue: 'Loss Scale Factor'})
    return Array.from(setOfLossSets.values())
  }

  getMappingName(col: string): string | undefined {
    const keys = Object.entries(this.mappingLabels).map(([id, value]) => ({ id, value }))
    return keys.find(x => x.id === col)?.value
  }

  onAttributeSelected($event: MatSelectChange): void{
    const option = $event.value as keyof SortTableRow<ScaledLossSetProps>
    const groups = new Map<string,OmitID<LossSetGroup>>()
    this.attributeRows.forEach(layer => {
      const optionString = String(layer[option])
      const optionViewValue = this.attributes.find(x => x.value === option)?.viewValue
      const realLayer = this.parentLossSets.find(x => x.id === layer.id)!
      const group = groups.get(optionString) || {
        name: `Shared Attribute: ${optionViewValue} | ${optionString} `,
        lossSetLayers: [],
        programID: this.currentProgram.studyID,
      }
      groups.set(optionString,{...group, lossSetLayers: [...group.lossSetLayers, realLayer]})
    })
    const output: OmitID<LossSetGroup>[] = []
    groups.forEach(lossGroup => {
      output.push(lossGroup)
    })

    this.sortLossSetGroups(output)
    this.selectedLossSetGroups = output
  }
}
