import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { map, Observable } from 'rxjs'
import { User } from '../../../api/model/backend.model'
import { CreditService } from './../../../api/credit/credit.service'
import { BackendService } from '../../../api/backend/backend.service'
import {
  CreditCalculationStructure,
  CreditStructure,
} from '../../model/credit-structure.model'

@Component({
  selector: 'app-credit-create-submission-dialog-container',
  templateUrl: './credit-create-submission-dialog.container.html',
})
export class CreditCreateSubmissionDialogContainerComponent implements OnInit {
  calculationStructure$: Observable<CreditCalculationStructure | undefined>
  users$: Observable<User[]>
  constructor(
    private creditService: CreditService,
    private backendService: BackendService,
    private dialogRef: MatDialogRef<CreditCreateSubmissionDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: CreditStructure
  ) {}

  ngOnInit(): void {
    if (!this.data) {
      return
    }
    this.calculationStructure$ = this.creditService
      .getCalculationStructureById(this.data.credit_calculation_structure_id)
      .pipe(map(res => res.data))
    this.users$ = this.backendService.getAllUsers().pipe(map(res => res.data))
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
