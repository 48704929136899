<div
  class="swiper-container"
  [swiper]="config"
  (indexChange)="slideIndexChange.emit($event)"
>
  <div class="swiper-wrapper">
    <div
      class="swiper-slide"
      *ngFor="let e of filteredEntities; let i = index; trackBy: trackByID"
      [ngStyle]="getSlideStyle(i)"
    >
      <app-tower-controls
        [slide]="slides[i]"
        [page]="'Group'"
        [minimizeProgram]="isProgramMinimized(e)"
        [minimizeGroupName]="getMinimizeGroupName(i)"
        [hideShowTower]="isGroupMinimized(i)"
        [hideShowAgg]="isGroupMinimized(i) || isGroupAgg(i)"
        [hideShowOcc]="isGroupMinimized(i)"
        [showAgg]="showAgg(e)"
        [showOcc]="showOcc(e)"
        [name]="getName(e)"
        [ngClass]="getGroupPaletteClasses(i)"
        [selectMode]="selectMode || isGroupAgg(i)"
        (towerMinimize)="programMinimize.emit(e.program)"
        (towerRestore)="programRestore.emit(e.program)"
        (showAggChange)="showAggChange.emit({ entity: e, value: $event })"
        (showOccChange)="showOccChange.emit({ entity: e, value: $event })"
      ></app-tower-controls>
      <app-tower
        *ngIf="showTower(i, e)"
        [maxYOcc]="e.program.towerPreferences.occurrence.maxY"
        [maxYDirtyOcc]="e.program.towerPreferences.occurrence.maxYDirty"
        [maxYAgg]="e.program.towerPreferences.aggregate.maxY"
        [maxYDirtyAgg]="e.program.towerPreferences.aggregate.maxYDirty"
        [widthOverride]="getTowerWidth(i)"
        [heightOverride]="getTowerHeight(i)"
        [readonly]="true"
        [showControls]="false"
        [layers]="e.cededLayers"
        [allLayers]="e.allLayers"
        [showOcc]="showOcc(e)"
        [showAgg]="showAgg(e) || isGroupAgg(i)"
        [selected]="getSelected(e)"
        [sharedIDGroup]="sharedIDGroup"
        [currentStructure]="e.program"
        [structureLayerData]="getLayerData(e)"
        [isGroup]="true"
        [sharedLayers]="sharedLayers"
        [inuranceSource]="inuranceSource"
        [inuranceTarget]="inuranceTarget"
        [inuranceTagsByLayerID]="inuranceTagsByLevel?.layer || {}"
        [groupCurrency]="groupCurrency"
        [currencyRates]="currencyRates"
        [analysisProfileCurrency]="analysisProfileCurrency"
        (setSimulateTouch)="setSimulateTouch($event)"
        (selectedLayerID)="onSelectedLayerID(e, $event)"
        (inuranceTagClick)="inuranceTagClick.emit($event)"
      ></app-tower>
      <app-group-tower-footer
        *ngIf="e.program.libRE === 'Y'"
        [dirty]="dirty"
        [slide]="slides[i]"
        [name]="isGroupAgg(i) ? 'Group Agg' : getName(e)"
        [years]="years"
        [programGroups]="programGroups"
        [programsByID]="programsByID"
        [selectedProgramIDs]="selectedProgramIDs"
        [analysisProfileID]="analysisProfileID"
        [groups]="groups"
        [groupsByID]="groupsByID"
        [groupBarsByID]="groupBarsByID"
        [groupScenariosByID]="groupScenariosByID"
        [inuranceTagsByProgramID]="inuranceTagsByLevel?.program || {}"
        [inuranceTagsByProgramGroupID]="
              inuranceTagsByLevel?.programGroup || {}
            "
        [ifLibreGroup]="ifLibreGroup"
        [selectMode]="selectMode || isGroupAgg(i)"
        [hideName]="!showTower(i, e)"
        [showMinimizedGroupBar]="isGroupMinimizedFirstSlide(i)"
        [minimizeProgram]="isProgramMinimized(e)"
        (programGroupBarClick)="programGroupBarClick.emit($event)"
        (programGroupAdd)="onProgramGroupAdd($event)"
        (programGroupRemove)="programGroupRemove.emit($event)"
        (programGroupMinimizeToggle)="
              programGroupMinimizeToggle.emit($event)
            "
        (programGroupDelete)="programGroupDelete.emit($event)"
        (programAdd)="onProgramAdd($event)"
        (programRemove)="programRemove.emit(e.program)"
        (programMove)="onProgramMove($event)"
        (saveUntitled)="saveUntitled.emit($event)"
        (inuranceTagClick)="inuranceTagClick.emit($event)"
        (animatedScenarios)="animatedScenarios.emit($event)"
        (groupLayerDetails)="groupLayerDetails.emit($event)"
        (groupScenariosClick)="groupScenariosClick.emit($event)"
        (programGroupRename)="programGroupRename.emit($event)"
      ></app-group-tower-footer>
      <app-group-tower-footer
        *ngIf="e.program.libRE !== 'Y'"
        [dirty]="dirty"
        [slide]="slides[i]"
        [name]="isGroupAgg(i) ? 'Group Agg' : getName(e)"
        [years]="years"
        [programGroups]="programGroups"
        [programsByID]="programsByID"
        [selectedProgramIDs]="selectedProgramIDs"
        [analysisProfileID]="analysisProfileID"
        [groups]="groups"
        [groupsByID]="groupsByID"
        [groupBarsByID]="groupBarsByID"
        [groupScenariosByID]="groupScenariosByID"
        [inuranceTagsByProgramID]="inuranceTagsByLevel?.program || {}"
        [inuranceTagsByProgramGroupID]="
              inuranceTagsByLevel?.programGroup || {}
            "
        [ifLibreGroup]="ifLibreGroup"
        [selectMode]="selectMode || isGroupAgg(i)"
        [hideName]="!showTower(i, e)"
        [showMinimizedGroupBar]="isGroupMinimizedFirstSlide(i)"
        [minimizeProgram]="isProgramMinimized(e)"
        (programBarClick)="programBarClick.emit(e)"
        (programGroupBarClick)="programGroupBarClick.emit($event)"
        (programGroupAdd)="onProgramGroupAdd($event)"
        (programGroupRemove)="programGroupRemove.emit($event)"
        (programGroupMinimizeToggle)="
              programGroupMinimizeToggle.emit($event)
            "
        (programGroupDelete)="programGroupDelete.emit($event)"
        (programAdd)="onProgramAdd($event)"
        (programRemove)="programRemove.emit(e.program)"
        (programMove)="onProgramMove($event)"
        (saveUntitled)="saveUntitled.emit($event)"
        (inuranceTagClick)="inuranceTagClick.emit($event)"
        (animatedScenarios)="animatedScenarios.emit($event)"
        (groupLayerDetails)="groupLayerDetails.emit($event)"
        (groupScenariosClick)="groupScenariosClick.emit($event)"
        (programGroupRename)="programGroupRename.emit($event)"
      ></app-group-tower-footer>
    </div>
  </div>
  <div class="swiper-scrollbar">
    <div class="scrollbar-drag" #scrollbarDrag></div>
  </div>
</div>
<app-group-button
  class="button-position"
  [slides]="slides"
  [showAddGroupButton]="showAddButton"
  [programGroupMembers]="programGroupMembers"
  (saveUntitled)="saveUntitled.emit($event)"
></app-group-button>
