import { SwiperConfigInterface } from 'ngx-swiper-wrapper-v-13'
import {
  CreditMetric,
  CreditMetricCategory,
  CreditMetricValueType,
} from './credit-metric.model'

export interface CreditCompareMetric {
  category: CreditMetricCategory
  metric: CreditMetric
  label: string
  type: CreditMetricValueType
  ordinal: number
  weight: number
  value: number
  rank: number
  scenario?: string
}

export interface CreditCompareMetricCategorySetting {
  category: CreditMetricCategory
  metricSettings: CreditCompareMetric[]
}

export interface CreditStructureCompareEntity {
  credit_calculation_structure_id: string
  name: string
  thumbnails: string[]
  metrics: CreditCompareMetric[]
}

export interface CreditCompareTableColumn {
  header: CreditCompareTableHeaderSection
  weightedAverage: string
  categories: CreditCompareTableCategorySection[]
}

export interface CreditCompareTableHeaderSection {
  label: string // empty for metric definition column, structure name for metric data column
  thumbnails: string[] // empty for metric definition column, image urls for metric data columns
}

export interface CreditCompareTableCategorySection {
  label: string // category for metric definition column, empty for metric data columns
  metrics: CreditCompareTableMetricCell[]
}

export interface CreditCompareTableMetricCell {
  value: string | number // metric label for metric definition column, metric value for metric data columns
  type: CreditMetricValueType | 'text'
  rank?: number
}

export const COMPARE_THUMBNAIL_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  observer: true,
  observeParents: true,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction',
  },
  watchOverflow: true,
  touchStartPreventDefault: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  effect: 'slide',
}

export const COMPARE_WEIGHTED_AVERAGE_STRING = 'Weighted Average'

export const COMPARE_METRIC_DEFINITION_CELL_TYPE = 'text'

export const DEFAULT_COMPARE_METRIC_DEFINITION_COLUMN: CreditCompareTableColumn =
  {
    header: {
      label: '',
      thumbnails: [],
    },
    weightedAverage: COMPARE_WEIGHTED_AVERAGE_STRING,
    categories: [],
  }
