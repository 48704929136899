import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { ApplicationError } from 'src/app/error/model/error'
import {
  ClientSearch,
  CreditSubmissionStructure,
} from '../../model/credit-submission.model'
import { CreditCalculationStructure } from '../../model/credit-structure.model'

export const CreditSubmissionStructureActions = createActionGroup({
  source: 'Credit Submission Structure',
  events: {
    'Reset Selected Submission': emptyProps(),
    'Set Selected Filters': props<{ filters: ClientSearch }>(),
    'Get Submission Structures Failure': props<{ error: ApplicationError }>(),
    'Get Submission Structures Success': props<{
      structures: CreditSubmissionStructure[]
    }>(),
    'Set Selected Submission': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Update Selected Submission': props<{
      structure: CreditSubmissionStructure
    }>(),
    'Reinitialize Tower Submission': props<{ structureId: string }>(),
    Fetch: props<{ structureId: string }>(),
    'Fetch Success': props<{ structure: CreditSubmissionStructure }>(),
    'Fetch Failure': props<{ error: ApplicationError }>(),
    Put: props<{ structure: CreditCalculationStructure }>(),
    'Put Success': props<{ structure: CreditSubmissionStructure }>(),
    'Put Failure': props<{ error: ApplicationError }>(),
  },
})
