import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { CreditPortfolio } from '../model/credit-portfolio.model'
import {
  CreditCalculationStructure,
  CreditStructure,
  CreditTransaction,
} from '../model/credit-structure.model'
import { CreditStructureGroup } from '../model/credit-structure-group.model'
import { Stratification } from '.././model/credit-stratification.model'
import { CreditTowerControls } from './../model/credit-tower.model'
import { CreditResults } from '../model/credit-results.model'
import { CreditTransactionActions } from './transaction/credit-transaction.actions'
import {
  CreditCalculationStructureActions,
  CreditStructureActions,
} from './structure/credit-structure.actions'
import { CreditResultsActions } from './results/credit-results.actions'
import { CreditTowerActions } from './tower/credit-tower.actions'
import { CreditSubmissionStructure } from '../model/credit-submission.model'
import { CreditModuleContext } from '../model/credit-routes.model'
import { CreditSubmissionStructureActions } from './submission/credit-submission.actions'
import * as fromCreditDesign from './design/credit-design.reducer'
import * as fromCashFlows from './cash-flows/cash-flows.reducer'
import * as fromCreditAnimatedScenarios from './animated-scenarios/credit-animated-scenarios.reducer'
import * as fromStratification from './stratification/stratifciation.reducer'
import * as fromOptimization from './optimization/credit-optimization.reducer'
import * as fromCreditGroup from './group/credit-group.reducer'
import * as fromCreditCompare from './compare/credit-compare.reducer'
import * as fromCreditMetrics from './metrics/credit-metrics.reducer'
import * as fromCreditSubmission from './submission/credit-submission.reducer'
import * as fromCreditActions from './credit.actions'
import * as fromCreditGroupActions from './group/credit-group.actions'

export const CREDIT_FEATURE_KEY = 'credit'

export interface State {
  moduleContext: CreditModuleContext
  creditStructures: CreditStructure[]
  creditSubmissionStructures: CreditSubmissionStructure[]
  creditStructureGroups: CreditStructureGroup[]
  towerControls: CreditTowerControls
  cashFlows: fromCashFlows.State
  animatedScenarios: fromCreditAnimatedScenarios.State
  stratifications: Stratification
  optimization: fromOptimization.State
  design: fromCreditDesign.State
  group: fromCreditGroup.State
  compare: fromCreditCompare.State
  metrics: fromCreditMetrics.State
  submission: fromCreditSubmission.State
  currentPortfolio?: CreditPortfolio
  currentCalculationStructure?: CreditCalculationStructure
  currentTransaction?: CreditTransaction
  creditResults?: CreditResults
  defaultScenarioResults?: CreditResults
}

export const initialState: State = {
  moduleContext: CreditModuleContext.Design,
  creditStructures: [],
  creditSubmissionStructures: [],
  creditStructureGroups: [],
  towerControls: {
    yAxisAsPercent: true,
  },
  design: fromCreditDesign.initialState,
  cashFlows: fromCashFlows.initialState,
  animatedScenarios: fromCreditAnimatedScenarios.initialState,
  stratifications: fromStratification.initialState,
  optimization: fromOptimization.initialState,
  group: fromCreditGroup.initialState,
  compare: fromCreditCompare.initialState,
  metrics: fromCreditMetrics.initialState,
  submission: fromCreditSubmission.initialState,
}

const moduleContextReducer = createReducer(
  initialState.moduleContext,
  on(fromCreditActions.SetModuleContext, (_, { context }) => context)
)

const creditStructuresReducer = createReducer(
  initialState.creditStructures,
  on(
    fromCreditActions.FetchCreditStructuresForProgramSuccess,
    (_, { creditStructures }) => creditStructures
  ),
  on(
    fromCreditGroupActions.FetchCreditGroupMemberCalculationStructuresSuccess,
    (state, { creditStructures }) => {
      return state.map(structure =>
        creditStructures.find(
          creditStucture => creditStucture.id === structure.id
        )
          ? { ...structure, checked: true }
          : structure
      )
    }
  ),
  on(
    fromCreditActions.ToggleCreditStructureChecked,
    (state, { creditStructure }) => {
      return state.map(structure =>
        structure.id === creditStructure.id ? creditStructure : structure
      )
    }
  ),
  on(
    fromCreditGroupActions.RemoveCreditStructureGroup,
    fromCreditActions.ResetAllCheckedCreditStructuresAndGroups,
    (creditStructures, _) => {
      return creditStructures.map(creditStructure => {
        return {
          ...creditStructure,
          checked: false,
        }
      })
    }
  )
)

const creditSubmissionStructuresReducer = createReducer(
  initialState.creditSubmissionStructures,
  on(
    fromCreditActions.FetchCreditSubmissionStructuresForProgramSuccess,
    (_, { creditSubmissionStructures }) => creditSubmissionStructures
  )
)

const creditStructureGroupsReducer = createReducer(
  initialState.creditStructureGroups,
  on(
    fromCreditGroupActions.FetchCreditStructureGroupsForProgramSuccess,
    (_, { creditStructureGroups }) => creditStructureGroups
  ),
  on(
    fromCreditActions.ToggleCreditStructureGroupChecked,
    (creditStructureGroups, { creditStructureGroup }) => {
      return creditStructureGroups.map(structureGroup =>
        structureGroup.id === creditStructureGroup.id
          ? creditStructureGroup
          : structureGroup
      )
    }
  ),
  on(
    fromCreditGroupActions.RemoveCreditStructureGroup,
    fromCreditActions.ResetAllCheckedCreditStructuresAndGroups,
    (creditStructureGroups, _) => {
      return creditStructureGroups.map(creditStructureGroup => {
        return {
          ...creditStructureGroup,
          checked: false,
        }
      })
    }
  ),
  on(
    fromCreditGroupActions.SaveCreditStructureGroupSuccess,
    (creditStructureGroups, { creditStructureGroupEntity }) => {
      const groupFoundIndex = creditStructureGroups.findIndex(
        creditStructureGroup =>
          creditStructureGroup.id ===
          creditStructureGroupEntity.creditStructureGroup.id
      )
      const updatedGroup: CreditStructureGroup = {
        ...creditStructureGroupEntity.creditStructureGroup,
        checked: true,
      }
      if (groupFoundIndex >= 0) {
        const updatedCreditStructureGroups = [...creditStructureGroups]
        updatedCreditStructureGroups.splice(groupFoundIndex, 1, updatedGroup)
        return updatedCreditStructureGroups
      }
      return [...creditStructureGroups, updatedGroup]
    }
  )
)

const towerControlsReducer = createReducer(
  initialState.towerControls,
  on(
    CreditTowerActions.updateControls,
    (_, { towerControls }) => towerControls
  ),
  on(
    CreditStructureActions.resetSelectedStructure,
    () => initialState.towerControls
  )
)

const currentPortfolioReducer = createReducer(
  initialState.currentPortfolio,
  on(
    fromCreditActions.FetchCreditPortfolioForSelectedProgramSuccess,
    (_, { portfolio }) => portfolio
  )
)

const currentCalculationStructureReducer = createReducer(
  initialState.currentCalculationStructure,
  on(
    CreditCalculationStructureActions.fetchSuccess,
    CreditCalculationStructureActions.postSuccess,
    CreditCalculationStructureActions.putSuccess,
    CreditCalculationStructureActions.createSuccess,
    CreditCalculationStructureActions.update,
    (_, { structure }) => structure
  ),
  on(
    CreditStructureActions.updateSelectedStructure,
    CreditSubmissionStructureActions.updateSelectedSubmission,
    (state, { structure }) => {
      return {
        ...state,
        name: structure.label,
      }
    }
  ),
  on(CreditTransactionActions.addSuccess, (state, { transaction }) => {
    return {
      ...state,
      transactions: [...state.transactions, transaction],
    }
  }),
  on(CreditTransactionActions.updateSuccess, (state, { transaction }) => {
    const indexToUpdate = state.transactions.findIndex(
      t => t._id === transaction._id
    )
    if (indexToUpdate < 0) {
      return state
    }
    const updatedTransactions = [...state.transactions]
    updatedTransactions.splice(indexToUpdate, 1, transaction)
    return {
      ...state,
      transactions: updatedTransactions,
    }
  }),
  on(CreditTransactionActions.delete, (state, { transactionId }) => {
    return {
      ...state,
      transactions: [...state.transactions].filter(
        t => t._id !== transactionId
      ),
    }
  }),
  on(CreditResultsActions.setSelectedScenario, (state, { scenarioId }) => {
    return {
      ...state,
      selected_scenario: scenarioId,
    }
  }),
  on(
    CreditResultsActions.setSelectedDefaultScenario,
    (state, { scenarioId }) => {
      return {
        ...state,
        selected_default_scenario: scenarioId,
      }
    }
  ),
  on(
    fromCreditActions.SetModuleContext,
    CreditStructureActions.resetSelectedStructure,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    _ => undefined
  )
)

const currentTransactionReducer = createReducer(
  initialState.currentTransaction,
  on(
    CreditTransactionActions.selectSuccess,
    CreditTransactionActions.selectByTrancheSuccess,
    CreditTransactionActions.addSuccess,
    CreditTransactionActions.updateSuccess,
    (_, { transaction }) => transaction
  ),
  on(
    CreditCalculationStructureActions.postSuccess,
    CreditCalculationStructureActions.putSuccess,
    CreditCalculationStructureActions.update,
    (state, { structure }) =>
      structure.transactions.find(
        transaction => transaction.name === state?.name
      )
  ),
  on(CreditCalculationStructureActions.fetchSuccess, (_, { structure }) =>
    structure.transactions.slice(0, 1).shift()
  ),
  on(
    fromCreditActions.SetModuleContext,
    CreditTransactionActions.delete,
    CreditStructureActions.resetSelectedStructure,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    _ => undefined
  )
)

const creditResultsReducer = createReducer(
  initialState.creditResults,
  on(CreditResultsActions.getSuccess, (_, { results }) => results),
  on(
    fromCreditActions.SetModuleContext,
    CreditResultsActions.setSelectedScenario,
    CreditStructureActions.resetSelectedStructure,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    _ => undefined
  )
)

const defaultScenarioResultsReducer = createReducer(
  initialState.defaultScenarioResults,
  on(CreditResultsActions.getDefaultSuccess, (_, { results }) => results),
  on(
    fromCreditActions.SetModuleContext,
    CreditResultsActions.setSelectedDefaultScenario,
    CreditStructureActions.resetSelectedStructure,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    _ => undefined
  )
)

const creditReducer = combineReducers<State>({
  moduleContext: moduleContextReducer,
  creditStructures: creditStructuresReducer,
  creditSubmissionStructures: creditSubmissionStructuresReducer,
  creditStructureGroups: creditStructureGroupsReducer,
  towerControls: towerControlsReducer,
  cashFlows: fromCashFlows.reducer,
  animatedScenarios: fromCreditAnimatedScenarios.reducer,
  stratifications: fromStratification.reducer,
  optimization: fromOptimization.reducer,
  design: fromCreditDesign.reducer,
  group: fromCreditGroup.reducer,
  compare: fromCreditCompare.reducer,
  metrics: fromCreditMetrics.reducer,
  submission: fromCreditSubmission.reducer,
  currentPortfolio: currentPortfolioReducer,
  currentTransaction: currentTransactionReducer,
  currentCalculationStructure: currentCalculationStructureReducer,
  creditResults: creditResultsReducer,
  defaultScenarioResults: defaultScenarioResultsReducer,
})

export function reducer(state: State | undefined, action: Action) {
  return creditReducer(state, action)
}
