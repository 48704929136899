import { Injectable } from '@angular/core'
import { ValueType, Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common'
import { Program } from '../../../core/model/program.model'
import { MetricValueType } from 'src/app/core/model/metric-value-type.model'
import { currencySymbol } from '../../model/layers.util'
import { ExploreSummaryBorderColumns, ExploreSummaryDatum, ExploreSummaryHeader } from '../explore.model'
import { SortTableColumnDef, SortTableRow } from '@shared/sort-table/sort-table.model'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { catchAndHandleError, mapToMaybeData } from 'src/app/api/util'
import { environment } from 'src/environments/environment'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}

@Injectable({
  providedIn: 'root',
})
export class GrossSummaryExportXlsxService {

  constructor(
    private datePipe: DatePipe,
    private httpClient: HttpClient,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe
  ) {}

  exportSummaryXlsx(
    clientName: string,
    year: string,
    program: string,
    structure: string,
    effectiveDate: string,
    currency: string | undefined,
    topHeaders: ExploreSummaryHeader[],
    columnDefs: SortTableColumnDef[],
    dataRows: SortTableRow<ExploreSummaryDatum>[],
    isGroupBy: boolean,
    indLossSets: boolean
  ) {
    const black = '000000'
    const white = 'FFFFFF'
    const blue = '00AEEF'
    const grayText = '5B6670'
    const darkGray = '737F8B'
    const lightGray = 'E0E0E0'
    const workbook = new Workbook()
    const horizontalFreeze = isGroupBy ? 2 : 1
    const worksheet = workbook.addWorksheet('Explore Gross Summary', {
      pageSetup: { fitToPage: true },
      views: [{ state: 'frozen', xSplit: horizontalFreeze, ySplit: 10, showGridLines: false }],
    })
    worksheet.pageSetup.margins = {
      left: 0.2,
      right: 0.2,
      top: 0.5,
      bottom: 0.5,
      header: 0.3,
      footer: 0.3,
    }
    let fileName = 'export'
    let exportFileName = 'export'
    if (clientName && program) {
      fileName = `${clientName} ${program.replace(` ${year}`, '')} ${year} Explore Gross Summary ${this.datePipe.transform(new Date(), 'MMM d, y')}.xlsx`
      exportFileName = `${clientName} ${program.replace(` ${year}`, '')} ${year} Explore Gross Summary.xlsx`
    }

    const title1Row = worksheet.addRow([clientName])
    title1Row.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: blue },
    }

    const programStructure = `${program}: ${structure}`
    const title2Row = worksheet.addRow([programStructure])
    title2Row.font = {
      name: 'Segoe Pro',
      size: 12,
      color: { argb: blue },
    }
    const dateString = `Effective: ${effectiveDate}`
    const dateRow = worksheet.addRow([dateString])
    dateRow.font = {
      name: 'Segoe Pro',
      size: 8,
      color: { argb: grayText }
    }

    const grossSummaryRow = worksheet.addRow(['Gross Summary Results'])
    grossSummaryRow.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: blue },
    }
    const date = 'As of ' + this.datePipe.transform(new Date(), 'longDate')
    const asOfDateRow = worksheet.addRow([date])
    asOfDateRow.eachCell(c => {
      c.font = {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: grayText }
      }
    })
    const simulations = ['', 'Based on 200,000 simulations']
    const simulationRow = worksheet.addRow(simulations)
    worksheet.addRow([''])
    let groupings: string[] = []
    if (isGroupBy) {
      groupings = String(columnDefs[1].label).split('/')
      const grossModelingLabel = `Gross Modeling Results - ${groupings.join(' and ')} ($m)`
      const grossModelingLabelArray = [
        '',
        grossModelingLabel
      ]
      columnDefs.forEach((_, i) => {
        if (i > 1) {
          grossModelingLabelArray.push('')
        }
      })
      const grossModelingLabelRow = worksheet.addRow(grossModelingLabelArray)
      grossModelingLabelRow.eachCell((c, i) => {
        if (i > 1) {
          c.style = {
            font: {
              name: 'Segoe Pro',
              size: 14,
              color: { argb: black }
            },
            border: {
              bottom: {
                style: 'medium',
                color: { argb: black }
              }
            }
          }
        }
      })
    }

    const topHeaderArray: string[] = []
    topHeaders.forEach((header, i) => {
      if (!header.hideLabel) {
        topHeaderArray.push(header.label)
      } else {
        topHeaderArray.push('')
      }
      const extraCells = i > 2 ?
        (Number(header.width) / 90) - 1 :
        0
      if (extraCells > 0) {
        for (let i = 0; i < extraCells; i ++) {
          topHeaderArray.push('')
        }
      }
    })
    const topHeaderRow = worksheet.addRow(topHeaderArray)
    const mergeCells: {
      value: string,
      index: number,
      extraCells: number
    }[] = []
    topHeaderRow.height = 24
    topHeaderRow.eachCell((c, i) => {
      if (c.value !== '') {
        const contributionCell = String(c.value).includes('Contribution') ? 0 : -1
        const extraCells = i > horizontalFreeze ?
          (Number(topHeaders.find(h => h.label === c.value).width) / 90)+ contributionCell :
          0
        mergeCells.push({
          value: String(c.value),
          index: i,
          extraCells
        })
        c.style = {
          font: {
            name: 'Segoe Pro',
            size: 12,
            color: { argb: white },
            bold: true
          },
          border: {
            bottom: {
              style: 'medium',
              color: { argb: black }
            }
          },
          alignment: { horizontal: 'center', vertical: 'middle' }
        }
      }
      if (i > horizontalFreeze) {
        c.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: darkGray },
        }
      }
    })
    mergeCells.forEach(c => {
      worksheet.mergeCells([
          topHeaderRow.number,
          c.index,
          topHeaderRow.number,
          c.index + c.extraCells
        ])
    })
    const headerRow = worksheet.addRow(
      columnDefs.map(cd => cd.label)
    )
    headerRow.height = 24
    headerRow.eachCell((cell, i) => {
      let width = 16
      if (i === 1) {
        width = 30
      } else if (i === 2 && isGroupBy) {
        width = 75
      } else if (String(cell.value) === 'Total Contribution to Group Volatility') {
        width = 20
      }
      worksheet.getColumn(i).width = width
      if (!isNaN(Number(cell.value))) {
        cell.value = Number(cell.value)
      }
      cell.alignment = { wrapText: true, horizontal: 'left' }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: blue },
      }
      cell.font = {
        name: 'Segoe Pro',
        size: 8,
        bold: true,
        color: { argb: white },
      }
      // if (ExploreSummaryBorderColumns.left.includes(String(cell.value))) {
      //   cell.border = {
      //     ...cell.border,
      //     left: {
      //       style: 'medium',
      //       color: { argb: black }
      //     }
      //   }
      // }
      // if (ExploreSummaryBorderColumns.right.includes(String(cell.value))) {
      //   cell.border = {
      //     ...cell.border,
      //     right: {
      //       style: 'medium',
      //       color: { argb: black }
      //     }
      //   }
      // }
      // if (ExploreSummaryBorderColumns.bottom.includes(String(cell.value))) {
      //   cell.border = {
      //     ...cell.border,
      //     bottom: {
      //       style: 'medium',
      //       color: { argb: black }
      //     }
      //   }
      // }
    })
    const dataLength = dataRows.length - 1
    dataRows.forEach((data, i) => {
      const row: string[] = []
      columnDefs.forEach(def => {
        const key = def.id as keyof ExploreSummaryDatum
        row.push(String(data[key]))
      })
      const dataRow = worksheet.addRow(row)
      dataRow.eachCell((cell, j) => {
        const columnDef = columnDefs[j - 1]
        let valueType = columnDef.valueType ?? 'currency'
        cell.font = {
          name: 'Segoe Pro',
          size: 8,
          color: { argb: black }
        }
        cell.border = {
          ...cell.border,
          bottom: {
            style: 'thin',
            color: { argb: black },
          }
        }
        if (valueType === 'currency') {
          cell.model = {
            ...cell.model,
            type: ValueType.Number,
          }
          const transformedValue = this.transformValue(Number(cell.value), valueType)
          cell.value = transformedValue
          cell.numFmt = this.numberFormat(
            Math.abs(Number(transformedValue)),
            currency || 'USD'
          )
        } else if (valueType === 'percentage') {
          cell.model = {
            ...cell.model,
            type: ValueType.Number,
          }
          const transformedValue = this.transformValue(Number(cell.value), valueType)
          cell.value = transformedValue
          cell.numFmt = '###,##0.0"%"'
        } else if (valueType === 'ratio') {
          cell.value = Number(cell.value).toFixed(1)
        }
        if (isGroupBy) {
          const nameParts = data.name.split('_')
          const lastNamePartIndex = groupings.length - 1
          const lastLevel =
            (indLossSets && data.rowLayer === 'Level 5') ||
            (
              !indLossSets &&
              data.groupBy.trim() === nameParts[lastNamePartIndex] &&
              lastNamePartIndex !== 0
            )
          if (lastLevel) {
            if (j > 1) {
              const fillColor = indLossSets ? lightGray : white
              const fontColor = black
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor },
              }
              cell.font.color = { argb: fontColor }
            }
          } else {
            const fontColor =
              j === 1 ?
              { argb: 'F1462F' } :
              cell.font.color
            cell.font = {
              ...cell.font,
              bold: true,
              color: fontColor
            }
          }
        }
        // if (ExploreSummaryBorderColumns.left.includes(String(columnDef.label))) {
        //   cell.border = {
        //     ...cell.border,
        //     left: {
        //       style: 'medium',
        //       color: { argb: black }
        //     }
        //   }
        // }
        // if (j === row.length || (ExploreSummaryBorderColumns.right.includes(String(columnDef.label)) && j !== row.length - 1)) {
        //   cell.border = {
        //     ...cell.border,
        //     right: {
        //       style: 'medium',
        //       color: { argb: black }
        //     }
        //   }
        // }
        // if (i === dataLength && ExploreSummaryBorderColumns.bottom.includes(String(columnDef.label))) {
        //   cell.border = {
        //     ...cell.border,
        //     bottom: {
        //       style: 'medium',
        //       color: { argb: black }
        //     }
        //   }
        // }

        if (String(cell.value) === 'NaN') {
          cell.model = {
            ...cell.model,
            type: ValueType.String
          }
          cell.value = ''
        }
      })
    })
    workbook.xlsx.writeBuffer().then((data: BlobPart) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, fileName)
      const base64FromBlob = (blobII: Blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blobII)
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result)
          }
        })
      }
      base64FromBlob(blob).then(base64Response => {
        this.captureExportDetails(
          base64Response,
          exportFileName,
          'ReinsurerSelection'
        )
          .pipe()
          .subscribe()
      })
    })
  }
  captureExportDetails(
    encodedData: any,
    filename: string,
    exportSelection: string
  ) {
    const cr = {
      blobData: encodedData,
      fileName: filename,
      selection: exportSelection,
    }
    // Upload attached fac files
    const url = `${environment.internalApi.base}${environment.internalApi.captureExportDetails}`
    return this.httpClient
      .post(url, JSON.stringify(cr), httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('POST Capture Export Details')
      )
  }

  numberFormat(value: number, currency: string): string {
    const lookup = [
      { value: 1e12, symbol: 'T', commas: ',,,,' },
      { value: 1e9, symbol: 'B', commas: ',,,' },
      { value: 1e6, symbol: 'M', commas: ',,' },
      { value: 1e3, symbol: 'K', commas: ',' },
      { value: 0, symbol: '', commas: '' },
    ]

    const item = lookup.find(i => value >= i.value)
    const positive = `"${currencySymbol(currency)}"#,##0.0${item?.commas}"${
      item?.symbol
    }"`
    const negative = `-${positive}`
    return `${positive};${negative}`
  }

  private transformValue(value: string | number, valueType: MetricValueType) {
    if (value === undefined) {
      return ''
    }

    switch (valueType) {
      case 'ratio':
      case 'percentage':
        return (value as number) * 100
      case 'currency':
        return value as number
      default:
        return value
    }
  }
}
