import { reduceReducers } from '@shared/util/reduce-reducers'
import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import {
  CreditStructureGroupEntity,
  CreditStructureGroupMemberEntity,
} from '../../model/credit-structure-group.model'
import * as fromCreditGroupActions from './credit-group.actions'
import { CreditCalculationStructure } from '../../model/credit-structure.model'
import { isDefined } from '../../utils/credit.utils'

export interface State {
  currentCreditStructureGroupEntity?: CreditStructureGroupEntity
  currentCreditStructureGroupMemberEntities?: CreditStructureGroupMemberEntity[]
  isDirty: boolean
  isSaving: boolean
}

export const initialState: State = {
  isDirty: false,
  isSaving: false,
}

const currentCreditStructureGroupEntityReducer = createReducer(
  initialState.currentCreditStructureGroupEntity,
  on(
    fromCreditGroupActions.AddCreditStructureGroupSuccess,
    fromCreditGroupActions.SaveCreditStructureGroupSuccess,
    (_, { creditStructureGroupEntity }) => creditStructureGroupEntity
  ),
  on(
    fromCreditGroupActions.UpdateCreditStructureGroupSuccess,
    (state, { creditStructureGroupMemberEntity }) => {
      if (!state) {
        return state
      }
      const groupTransactions = state.creditCalculationStructure.transactions
      return {
        ...state,
        creditCalculationStructure: {
          ...state.creditCalculationStructure,
          transactions: [
            ...groupTransactions,
            ...creditStructureGroupMemberEntity.creditCalculationStructure
              .transactions,
          ],
        },
      }
    }
  ),
  on(fromCreditGroupActions.RenameCreditStructureGroup, (state, { label }) => {
    if (!state) {
      return state
    }
    return {
      ...state,
      creditStructureGroup: {
        ...state.creditStructureGroup,
        label,
      },
      creditCalculationStructure: {
        ...state.creditCalculationStructure,
        name: label,
      },
    }
  }),
  on(fromCreditGroupActions.RemoveCreditStructureGroup, _ => undefined),
  on(
    fromCreditGroupActions.RemoveStructureFromGroup,
    (state, { creditCalculationStructure }) => {
      if (!state) {
        return state
      }
      const updatedCreditCalculationStructure: CreditCalculationStructure = {
        ...state.creditCalculationStructure,
        transactions: state.creditCalculationStructure.transactions.filter(
          transaction =>
            creditCalculationStructure.transactions.some(
              transactionToRemove => transactionToRemove._id !== transaction._id
            )
        ),
      }
      return {
        ...state,
        creditCalculationStructure: updatedCreditCalculationStructure,
      }
    }
  ),
  on(
    fromCreditGroupActions.FetchCreditCalculationStructureForGroupSuccess,
    (_, { creditStructureGroup, creditCalculationStructure }) => {
      return {
        creditStructureGroup,
        creditCalculationStructure,
      }
    }
  )
)

const currentCreditStructureGroupMemberEntitiesReducer = createReducer(
  initialState.currentCreditStructureGroupMemberEntities,
  on(
    fromCreditGroupActions.AddCreditStructureGroupSuccess,
    (_, { creditStructureGroupMemberEntity }) => [
      creditStructureGroupMemberEntity,
    ]
  ),
  on(
    fromCreditGroupActions.UpdateCreditStructureGroupSuccess,
    (state, { creditStructureGroupMemberEntity }) => {
      if (!state) {
        return state
      }
      return [...state, creditStructureGroupMemberEntity]
    }
  ),
  on(
    fromCreditGroupActions.SaveCreditStructureGroupMembersSuccess,
    (state, { creditStructureGroupMembers }) => {
      if (!state) {
        return state
      }
      const updatedMembers: CreditStructureGroupMemberEntity[] = state.map(
        member => {
          const found = creditStructureGroupMembers.find(
            m =>
              m.credit_structure_id ===
              member.creditStructureGroupMember.credit_structure_id
          )
          return found
            ? {
                ...member,
                creditStructureGroupMember: found,
              }
            : member
        }
      )
      return updatedMembers
    }
  ),
  on(
    fromCreditGroupActions.RemoveCreditStructureGroup,
    () => [] as CreditStructureGroupMemberEntity[]
  ),
  on(
    fromCreditGroupActions.RemoveStructureFromGroup,
    (state, { creditCalculationStructure }) => {
      if (!state) {
        return state
      }
      return state.filter(
        member =>
          member.creditCalculationStructure._id !==
          creditCalculationStructure._id
      )
    }
  ),
  on(
    fromCreditGroupActions.FetchCreditGroupMemberCalculationStructuresSuccess,
    (
      _,
      {
        creditStructureGroupMembers,
        creditStructures,
        creditCalculationStructures,
      }
    ) => {
      const creditStructureGroupMemberEntities: CreditStructureGroupMemberEntity[] =
        creditStructureGroupMembers
          .map(creditStructureGroupMember => {
            const creditStructure = creditStructures.find(
              s => s.id === creditStructureGroupMember.credit_structure_id
            )
            const creditCalculationStructure = creditCalculationStructures.find(
              cs => cs._id === creditStructure?.credit_calculation_structure_id
            )
            if (creditStructure && creditCalculationStructure) {
              return {
                creditStructureGroupMember,
                creditStructure,
                creditCalculationStructure,
              }
            }
          })
          .filter(isDefined)
      return creditStructureGroupMemberEntities
    }
  )
)

const isDirtyReducer = createReducer(
  initialState.isDirty,
  on(
    fromCreditGroupActions.AddCreditStructureGroup,
    fromCreditGroupActions.UpdateCreditStructureGroup,
    () => true
  ),
  on(
    fromCreditGroupActions.SaveCreditStructureGroupMembersSuccess,
    fromCreditGroupActions.RemoveCreditStructureGroup,
    () => false
  )
)
const isSavingReducer = createReducer(
  initialState.isSaving,
  on(fromCreditGroupActions.SaveCreditStructureGroup, () => true),
  on(fromCreditGroupActions.SaveCreditStructureGroupMembersSuccess, () => false)
)

const creditGroupReducer = combineReducers<State>({
  currentCreditStructureGroupEntity: currentCreditStructureGroupEntityReducer,
  currentCreditStructureGroupMemberEntities:
    currentCreditStructureGroupMemberEntitiesReducer,
  isDirty: isDirtyReducer,
  isSaving: isSavingReducer,
})

const reducedReducer = reduceReducers(creditGroupReducer)

export function reducer(state: State | undefined, action: Action): State {
  return reducedReducer(state, action)
}
