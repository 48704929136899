import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { AppState } from 'src/app/core/store'
import {
  deleteGroup,
  deleteGroupFailure,
  deleteGroupSuccess,
  fetchLossSetGroups,
  saveGroup,
  saveGroupFailure,
  saveGroupSuccess,
  setSelectedLossSetGroup,
  updateGroup,
  updateGroupFailure,
  updateGroupSuccess,
} from './loss-set-group.actions'
import { LossSetGroupService } from '../../../../api/loss-set-group/loss-set-group.service'
import {
  mergeApiResponses,
  mergeMapWithInput,
  rejectError,
  rejectErrorWithInput,
} from 'src/app/api/util'
import {
  LossSetGroup,
  LossSetGroupAndMembers,
  LossSetGroupMember,
  LossSetLayer,
} from 'src/app/analysis/model/loss-set-layers.model'
import { OmitID } from 'src/app/api/model/backend.model'
import { ApiResponse } from 'src/app/api/model/api.model'
import { forkJoin, of } from 'rxjs'
import { fetchParentLossSetLayersSuccess } from '../loss-set-layers.actions'
import { selectLossSetGroups } from 'src/app/core/store/loss-set-group.selectors'
import { selectLossSetGroupMembers } from 'src/app/core/store/loss-set-group-member.selectors'
import { selectCurrentStudyID } from '../../analysis.selectors'
import { toggleLossSetGroupEditor } from '../../analysis-panels.actions'

@Injectable()
export class LossSetGroupEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private lossSetGroupService: LossSetGroupService
  ) {}

  save$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveGroup),
      mergeMap(action => {
        return this.lossSetGroupService.addGroup(action.lossSetGroup)
      }),
      rejectError(error => this.store.dispatch(saveGroupFailure({ error }))),
      mergeMap(res => {
        const memberResponses: ApiResponse<LossSetGroupMember>[] = []
        res.lossSetLayers.forEach(l => {
          const groupMember: OmitID<LossSetGroupMember> = {
            name: l.description,
            lossSetGroupID: String(res.id),
            lossSetLayerID: l.id,
          }
          memberResponses.push(this.lossSetGroupService.addMember(groupMember))
        })
        return forkJoin(memberResponses).pipe(
          mergeApiResponses(),
          rejectError(error =>
            this.store.dispatch(saveGroupFailure({ error }))
          ),
          map(members => {
            const groupAndMembers: LossSetGroupAndMembers = {
              lossSetGroup: res,
              lossSetGroupMembers: members,
            }
            return groupAndMembers
          })
        )
      }),
      map(groupAndMembers => {
        return saveGroupSuccess({ groupAndMembers })
      })
    )
  })

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateGroup),
      withLatestFrom(
        this.store.pipe(select(selectLossSetGroups))
        // this.store.pipe(select(selectLossSetGroupMembers))
      ),
      mergeMapWithInput(([action, allLossSetGroups]) => {
        // Find group we're updating, compare name, and call service.updateGroup if name is different
        const currentGroup = allLossSetGroups.find(
          g => g.id === action.lossSetGroup.id
        )

        if (currentGroup?.name !== action.lossSetGroup.name) {
          return this.lossSetGroupService.updateGroup(
            action.lossSetGroup.id,
            action.lossSetGroup
          )
        } else {
          return of({ data: undefined })
        }
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(updateGroupFailure({ error }))
      ),
      withLatestFrom(this.store.pipe(select(selectLossSetGroupMembers))),
      mergeMap(([[res, [action, _]], allMembers]) => {
        let updatedGroup: LossSetGroup
        if (res) {
          updatedGroup = {
            ...res,
            lossSetLayers: action.lossSetGroup.lossSetLayers,
          }
        } else {
          updatedGroup = action.lossSetGroup
        }
        const memberResponses: ApiResponse<LossSetGroupMember>[] = []
        const oldMembers = allMembers.filter(
          m => m.lossSetGroupID === updatedGroup.id
        )

        // Added members = members that exist in group but do not exist in oldMembers
        const addedMembers = updatedGroup.lossSetLayers.filter(
          l => !oldMembers.some(om => om.lossSetLayerID === l.id)
        )
        addedMembers.forEach(addedMember => {
          const groupMember: OmitID<LossSetGroupMember> = {
            name: addedMember.description,
            lossSetGroupID: String(updatedGroup.id),
            lossSetLayerID: addedMember.id,
          }
          memberResponses.push(this.lossSetGroupService.addMember(groupMember))
        })

        // Deleted members = members that exist in oldMembers but do not exist in current group
        const deletedMembers = oldMembers.filter(
          om =>
            !updatedGroup.lossSetLayers.some(l => l.id === om.lossSetLayerID)
        )
        deletedMembers.forEach(deletedMember => {
          memberResponses.push(
            this.lossSetGroupService.deleteMember(deletedMember.id)
          )
        })
        let groupAndMembers: LossSetGroupAndMembers
        if (memberResponses.length === 0) {
          groupAndMembers = {
            lossSetGroup: updatedGroup,
            lossSetGroupMembers: oldMembers,
          }
          return of(groupAndMembers)
        } else {
          return forkJoin(memberResponses).pipe(
            mergeApiResponses(),
            rejectError(error =>
              this.store.dispatch(updateGroupFailure({ error }))
            ),
            map(members => {
              const filteredOldMembers = oldMembers.filter(
                om => !deletedMembers.includes(om)
              )
              const filteredNewMembers = members.filter(
                m => !deletedMembers.some(dm => dm.id === m.id)
              )
              const updatedMembers = [
                ...filteredOldMembers,
                ...filteredNewMembers,
              ]
              groupAndMembers = {
                lossSetGroup: updatedGroup,
                lossSetGroupMembers: updatedMembers,
              }
              return groupAndMembers
            })
          )
        }
      }),
      map(groupAndMembers => {
        return updateGroupSuccess({ groupAndMembers })
      })
    )
  })

  delete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteGroup),
      mergeMap(action => {
        return this.lossSetGroupService.deleteGroup(action.lossSetGroup.id)
      }),
      rejectError(error => this.store.dispatch(deleteGroupFailure({ error }))),
      map(lossSetGroup => {
        return deleteGroupSuccess({ lossSetGroup })
      })
    )
  })

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchParentLossSetLayersSuccess),
      withLatestFrom(
        this.store.pipe(select(selectCurrentStudyID)),
        this.store.pipe(select(selectLossSetGroups)),
        this.store.pipe(select(selectLossSetGroupMembers))
      ),
      map(([action, programID, allLossSetGroups, allMembers]) => {
        // Filter for matching programID
        const matchingProgramIdGroups = allLossSetGroups.filter(
          g => g.programID === programID
        )

        // If there are matching groups, make sure parent gross
        // contains same loss sets as members
        if (matchingProgramIdGroups.length > 0) {
          // Loop through remaining groups
          const lossSetGroups: LossSetGroup[] = matchingProgramIdGroups.map(
            g => {
              // Filter members for matching current groupID
              const membersInGroup = allMembers.filter(
                m => m.lossSetGroupID === g.id
              )
              // Loop through members, if current member matches a parent loss set layer, add that layer to a list
              // If they all match, then add the list of loss set layers to the current group
              let matchingLayer
              const matchingLayers: LossSetLayer[] = []
              let allLayersMatch = true

              for (const m of membersInGroup) {
                // Parent lossSetLayers contains current member! Find it
                matchingLayer = action.parentLossSetLayers.find(
                  parentLayer => parentLayer.id === m.lossSetLayerID
                )
                if (matchingLayer) {
                  matchingLayers.push(matchingLayer)
                } else {
                  allLayersMatch = false
                  break
                }
              }
              let updatedGroup: LossSetGroup
              if (allLayersMatch && matchingLayers) {
                updatedGroup = {
                  ...g,
                  lossSetLayers: matchingLayers,
                }
                return updatedGroup
              }
              return g
            }
          )
          const finalLossSetGroups = lossSetGroups.filter(
            g => g.lossSetLayers.length > 0
          )
          return fetchLossSetGroups({ lossSetGroups: finalLossSetGroups })
        }

        return fetchLossSetGroups({ lossSetGroups: [] })
      })
    )
  })

  unSetGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(toggleLossSetGroupEditor),
      map(_ => {
        return setSelectedLossSetGroup({ lossSetGroup: null })
      })
    )
  })
}
