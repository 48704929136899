<table
  mat-table
  class="app-table"
  [class.bolded]="!showTitle"
  [class.app-table-small]="!tiny"
  [class.app-table-tiny]="tiny"
  matSort
  [matSortDisabled]="disableSort"
  [dataSource]="dataSource"
  [trackBy]="trackByRow"
  #table
>
  <ng-container
    *ngFor="let col of columns.views; let i = index; trackBy: trackByView"
    [matColumnDef]="col.id"
    [sticky]="col.pinned || col.pinnedCategory"
    [stickyEnd]="col.pinned || col.pinnedCategory"
  >
    <ng-container *ngIf="col.isCategory">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="category"
        [ngStyle]="getCellStyle(col)"
        [ngClass]="getHeaderCellClass(col, true)"
        [attr.colspan]="col.colspan"
      >
        <span
          *ngIf="!getCategoryTemplate(col.categoryTemplateID)"
          [class.pin-flex-end-category]="col.pinnableCategory"
        >
          <i
            *ngIf="col.pinnableCategory"
            class="material-icons pin-icon"
            [class.active]="col.pinnedCategory || col.pinned"
            (click)="pinColumn(col); $event.stopPropagation()"
            >push_pin</i
          >{{ col.label }}</span
        >
        <ng-container *ngIf="hasCategoryTemplates">
          <ng-container
            *ngTemplateOutlet="
              getCategoryTemplate(col.categoryTemplateID);
              context: { $implicit: col }
            "
          ></ng-container>
        </ng-container>
      </th>
    </ng-container>

    <ng-container *ngIf="!col.isCategory">
      <ng-container *ngIf="col.headerInput">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [ngClass]="getHeaderCellClass(col)"
          [ngStyle]="getCellStyle(col)"
          [attr.rowspan]="hasCategories ? col.rowspan : 1"
        >
          <span
            [ngStyle]="getCellSpanStyle(col)"
            [class.pin-flex-end]="col.pinnable"
          >
            <i
              *ngIf="col.pinnable"
              class="material-icons pin-icon"
              [class.active]="col.pinnedCategory || col.pinned"
              (click)="pinColumn(col); $event.stopPropagation()"
              >push_pin</i
            >
            <input
              required
              matInput
              placeholder="{{ col.label }}"
              #headerInput
              maxlength="50"
              [value]="col.label"
              (input)="
                onHeaderValueChange({ id: col.id, value: headerInput.value })
              "
              (keydown.space)="onSpaceBarPress($event)"
              (click)="$event.stopPropagation()"
            />
            <div *ngIf="col.subHeader" class="sub-header">
              {{ col.subHeader }}
            </div>
            <mat-checkbox
              *ngIf="col.valueType === 'checkbox'"
              [disabled]="dataSource?.data?.length === 0"
              [checked]="checkboxes[col.id].allSelected"
              [indeterminate]="checkboxes[col.id].onlySomeSelected"
              (change)="onCheckboxSelectAll(col, $event.checked)"
              (click)="$event.stopPropagation()"
            ></mat-checkbox>
          </span>
          <app-sort-table-filter
            *ngIf="col.filter"
            class="filter"
            [filter]="getColumnFilter(col.id)"
            (filterChange)="updateFilter()"
          ></app-sort-table-filter>
          <mat-icon
            inline
            class="edit-icon"
            (click)="headerInput.focus(); $event.stopPropagation()"
          >
            edit
          </mat-icon>
        </th>
      </ng-container>

      <ng-container *ngIf="col.id !== selectAllColumnID && !col.headerInput">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [ngClass]="getHeaderCellClass(col)"
          [ngStyle]="getCellStyle(col)"
          [attr.rowspan]="hasCategories ? col.rowspan : 1"
        >
          <span
            [ngStyle]="getCellSpanStyle(col)"
            [class.pin-flex-end]="col.pinnable"
          >
            <i
              *ngIf="col.pinnable"
              class="material-icons pin-icon"
              [class.active]="col.pinnedCategory || col.pinned"
              (click)="pinColumn(col); $event.stopPropagation()"
              >push_pin</i
            >
            {{ col.label }}
            <div *ngIf="col.subHeader" class="sub-header">
              {{ col.subHeader }}
            </div>
            <mat-checkbox
              *ngIf="col.valueType === 'checkbox'"
              [disabled]="dataSource?.data?.length === 0"
              [checked]="checkboxes[col.id].allSelected"
              [indeterminate]="checkboxes[col.id].onlySomeSelected"
              (change)="onCheckboxSelectAll(col, $event.checked)"
              (click)="$event.stopPropagation()"
            ></mat-checkbox>
          </span>
          <app-sort-table-filter
            *ngIf="col.filter"
            class="filter"
            [filter]="getColumnFilter(col.id)"
            (filterChange)="updateFilter()"
          ></app-sort-table-filter>
        </th>
      </ng-container>

      <ng-container *ngIf="col.id === selectAllColumnID && !col.headerInput">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="getHeaderCellClass(col)"
          [ngStyle]="getCellStyle(col)"
          [attr.rowspan]="hasCategories ? col.rowspan : 1"
        >
          <span
            class="header-tooltip"
            [matTooltip]="selectAllTooltip"
            matTooltipShowDelay="500"
          >
            <mat-checkbox
              [disabled]="dataSource?.data?.length === 0"
              [checked]="selections.allSelected"
              [indeterminate]="selections.onlySomeSelected"
              (change)="
                selections.setAll(dataSource.filteredData, $event.checked)
              "
            ></mat-checkbox>
          </span>
        </th>
      </ng-container>

      <td
        mat-cell
        *matCellDef="let row; let i = index"
        class="cell"
        [class.non-transparent-rows]="col.pinnable || col.pinnableCategory"
        [ngClass]="getCellClass(col, row)"
        [ngStyle]="getCellStyle(col, row)"
        [attr.row]="i"
        [attr.col]="col.id"
      >
        <ng-container *ngIf="col.addNewLayer">
          <button
            appButtonIcon
            big
            class="icon"
            [matMenuTriggerFor]="moreMenu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon inline>add</mat-icon>
          </button>
          <mat-menu #moreMenu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="moreLossMenu">
              Add Filtered Layer Stats
            </button>
          </mat-menu>
          <mat-menu
            #moreLossMenu="matMenu"
            class="app-mat-theme loss-filter"
            name="filters"
            aria-label="Loss Filters"
            (click)="$event.stopPropagation()"
          >
            <button
              class="loss-filters-button"
              mat-menu-item
              [matMenuTriggerFor]="lossFiltersMenu"
            >
              {{ lossFilterButtonLabel }}
            </button>
            <mat-menu #lossFiltersMenu="matMenu">
              <button
                mat-menu-item
                *ngFor="let f of lossFilters"
                (click)="onLossFilterClick($event.target.innerText, row.id)"
              >
                {{ f.description }}
              </button>
            </mat-menu>
          </mat-menu>
        </ng-container>
        <app-sort-table-value
          *ngIf="col.id !== selectAllColumnID"
          [column]="getCol(col, row)"
          [row]="row"
          [clamp]="clamp"
          [isLayerDetailsTab]="isLayerDetailsTab"
          [noLoadingSpinnerOnSubmit]="noLoadingSpinnerOnSubmit"
          [currentCurrency]="row.currency"
          [isAssignedLines]="isAssignedLines"
          [isQQ]="isQQ"
          [ngClass]="{
            inline:
              col.addNewLayer ||
              col.selectQQLayer ||
              col.deleteButton ||
              col.editButton
          }"
          [spacing]="spacing"
          [removeSuffixCurrencyTemplateOption]="
            removeSuffixCurrencyTemplateOption
          "
          (valueChange)="
            onValueChange({
              id: row.id,
              row: row,
              column: col,
              valueType: col.valueType,
              value: $event
            })
          "
          [readonly]="
            readonly ||
            (isAssignedLines && row.reinsurer === 'Total') ||
            readOnlyRows?.get(row)?.has(col.id)
          "
          [useZeroText]="useZeroText"
          [customClick]="col.customClick"
          (customClickFunction)="onCustomClick(col, row)"
        ></app-sort-table-value>
        <!-- Still need some work on this -->
        <ng-container *ngIf="col.selectQQLayer && row.allLayers.length > 0">
          <button
            appButtonIcon
            big
            class="icon qq-change"
            [matMenuTriggerFor]="moreLayerMenu"
          >
            <mat-icon inline>more_horiz</mat-icon>
          </button>
          <mat-menu #updateVersion="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="moreLayerMenu">
              Change Version
            </button>
          </mat-menu>
          <mat-menu #moreLayerMenu="matMenu">
            <ng-container *ngFor="let layer of row.allLayers">
              <button
                mat-menu-item
                *ngIf="layer && layer.children"
                [matMenuTriggerFor]="moreVersionMenu"
              >
                {{ layer.name }}
              </button>
              <mat-menu #moreVersionMenu="matMenu">
                <ng-container *ngFor="let c of layer.children">
                  <button mat-menu-item (click)="versionClick.emit(c)">
                    <span
                      *ngIf="c.metadata.isPreferred"
                      matTooltip="This is a Preferred Version."
                      matTooltipPosition="above"
                      ><mat-icon>star_border</mat-icon></span
                    >{{ c.name }}
                  </button>
                </ng-container>
              </mat-menu>
            </ng-container>
          </mat-menu>
          <span
            class="icon-color"
            *ngIf="row.isPreferred"
            matTooltip="This is Not a Preferred Version."
            matTooltipPosition="above"
            ><mat-icon>warning</mat-icon></span
          >
        </ng-container>
        <mat-checkbox
          *ngIf="selectAllColumnID !== null && col.id === selectAllColumnID"
          class="select-checkbox"
          [(ngModel)]="selections.dictionary[row.id]"
          (ngModelChange)="selections.update(dataSource.filteredData)"
        ></mat-checkbox>
        <ng-container
          *ngIf="
            isAssignedLines && col.deleteButton && !(row.reinsurer === 'Total')
          "
        >
          <button
            appButton
            big
            border
            class="subjectivity"
            (click)="$event.stopPropagation(); onSubjectivityClick(row.id)"
          >
            Subjectivity
          </button>
        </ng-container>
        <ng-container
          *ngIf="
            (isSubjectivity || isAssignedLines) &&
            col.deleteButton &&
            !(row.reinsurer === 'Total')
          "
        >
          <button
            appButtonIcon
            big
            class="material-icons delete-button"
            (click)="$event.stopPropagation(); onDelete({ id: row.id })"
          >
            <mat-icon inline>delete</mat-icon>
          </button>
        </ng-container>
        <ng-container
          *ngIf="isTracking && col.editButton && !(row.reinsurer === 'Total')"
        >
          <div class="tacking-right">
            <button
              appButtonIcon
              big
              class="material-icons delete-button"
              (click)="openDialog(row, subjectivityOptions)"
            >
              <mat-icon inline>edit</mat-icon>
            </button>
            <button
              appButtonIcon
              big
              class="material-icons delete-button"
              (click)="
                $event.stopPropagation(); deleteSubjectivityFromTracking(row)
              "
            >
              <mat-icon inline>delete</mat-icon>
            </button>
          </div>
        </ng-container>
        <ng-container
          *ngIf="isAssignedLines && col.editButton && row.placeholder"
        >
          <button
            appButtonIcon
            big
            class="material-icons delete-button"
            (click)="updatePlaceholder.emit(row.id)"
          >
            <mat-icon inline>edit</mat-icon>
          </button>
        </ng-container>
      </td>

      <ng-container *ngIf="footer">
        <td
          mat-footer-cell
          *matFooterCellDef
          class="cell"
          [ngClass]="getCellClass(col, footer)"
          [ngStyle]="getCellStyle(col, footer)"
        >
          <app-sort-table-value
            *ngIf="col.id !== selectAllColumnID"
            readonly
            [column]="col"
            [row]="footer"
            [isLayerDetailsTab]="isLayerDetailsTab"
            [clamp]="clamp"
            [currentCurrency]="currentCurrency"
            [isAssignedLines]="isAssignedLines"
            [ngClass]="{ inline: col.addNewLayer || col.deleteButton }"
            [spacing]="spacing"
            [readonly]="readonly || footerReadOnly"
            [useZeroText]="useZeroText"
          ></app-sort-table-value>
        </td>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="footer2">
    <ng-container
      *ngFor="let col of columns.views; let i = index; trackBy: trackByView"
      [matColumnDef]="col.id + '-footer2'"
    >
      <td
        mat-footer-cell
        *matFooterCellDef
        class="cell"
        [ngClass]="getCellClass(col, footer2, 'footer2')"
        [ngStyle]="getCellStyle(col, footer2)"
      >
        <app-sort-table-value
          *ngIf="col.id !== selectAllColumnID"
          readonly
          [column]="col"
          [row]="footer2"
          [clamp]="clamp"
          [currentCurrency]="currentCurrency"
          [isLayerDetailsTab]="isLayerDetailsTab"
          [isAssignedLines]="isAssignedLines"
          [ngClass]="{ inline: col.addNewLayer || col.deleteButton }"
          [spacing]="spacing"
          [noLoadingSpinnerOnSubmit]="noLoadingSpinnerOnSubmit"
          [readonly]="readonly || footer2ReadOnly"
          [useZeroText]="useZeroText"
        ></app-sort-table-value>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns.header1Names; sticky: true"></tr>

  <ng-container *ngIf="hasCategories">
    <tr
      mat-header-row
      *matHeaderRowDef="columns.header2Names; sticky: true"
    ></tr>
  </ng-container>

  <tr
    mat-row
    *matRowDef="let row; columns: columns.names"
    [ngClass]="getRowClass(row)"
    [ngStyle]="(row.type !== this.cas ? row.style : '') || getRowStyle(row)"
    (click)="onRowClick(row, $event)"
    (mouseover)="onRowMouseover(row, $event)"
    (mouseout)="onRowMouseout()"
  ></tr>

  <ng-container *ngIf="footer2">
    <tr
      mat-footer-row
      class="footer2"
      *matFooterRowDef="columns.footer2Names; sticky: true"
    ></tr>
  </ng-container>

  <ng-container *ngIf="footer">
    <tr
      mat-footer-row
      *matFooterRowDef="columns.footer1Names; sticky: true"
    ></tr>
  </ng-container>
</table>

<app-info-text *ngIf="shownEmptyMessage" class="empty" big>
  {{ shownEmptyMessage }}
</app-info-text>

<div class="excel-menu" #excelContextMenu>
  <div
    class="excel-row-group"
    *ngFor="let option of excelMenuOptions; index as i"
  >
    <div
      class="excel-row"
      (click)="excelContextMenuItemSelected(option.id, $event)"
    >
      <label class="view">{{ option.viewText }}</label>
      <label class="shortcut">{{ option.shortcut.join('+') }}</label>
    </div>
  </div>
  <i class="hint">*Right click again for context menu.</i>
</div>

<div class="excel-selection-box" #excelSelectionBox></div>
