import {
  EntitiesResponse,
  StudyResponse,
  CarrierYearResponse,
  PermissionsResponse,
} from '../../api/model/backend.model'
import { convertProgramFromResponse } from '../../api/program/program.converter'
import { getBlobResponses } from './blob.converter'
import { Client, ClientYear } from './client.model'
import { Program } from './program.model'
import { Study } from './study.model'

export const convertStudyResponse = (
  study: StudyResponse,
  programIDs: Array<string | number>
): Study => ({
  id: String(study.id),
  carrierYearID: String(study.carrier_year_id),
  name: study.name,
  programIDs,
  folders: study.folders,
  imageSrc: 'assets/cards/ML.png',
  description: study.description,
  opportunity_id: study.opportunity_id,
  credit_sage_portfolio_id: study.credit_sage_portfolio_id,
  executive_summary: study.executive_summary,
})

const convertYearResponse = (
  year: CarrierYearResponse,
  studies: Array<Study>
): ClientYear => ({
  id: String(year.id),
  year: String(year.year),
  studies,
})

export const convertPermissionsToEntitiesResponse = (
  permissions: PermissionsResponse
): EntitiesResponse => {
  return {
    marketToken: permissions.marketToken ? permissions.marketToken.token : '',
    years: permissions.carrierYears,
    security: permissions.specialPermissions,
    permissions: permissions.permissions,
    blobSAS: permissions.blobSAS,
    carriers: permissions.carriers,
    studies: permissions.studies,
    programs: permissions.programs,
    programGroups: permissions.programGroups,
    programGroupMembers: permissions.programGroupMembers,
    lossSetGroups: permissions.programLossSetGroups,
    lossSetGroupMembers: permissions.programLossSetGroupMembers,
    accountOpportunities: permissions.accountOpportunities.map(a => ({
      ...a,
      id: a.oppId,
    })),
    carrierAccountMap: permissions.carrierAccountMap,
    featureFlags: permissions.featureFlags,
    sharedLimits: permissions.sharedLimits,
    sharedLimitMembers: permissions.sharedLimitMembers,
    compareViews: permissions.compareViews,
    hasWhitespaceAccess: permissions.hasWhitespaceAccess,
    usaBasedUser: permissions.usaBasedUser,
    facSageUrl: permissions.facSageUrl
  }
}

export const parseClientsFromEntitiesResponse = (
  entities: EntitiesResponse
): Client[] => {
  const {
    blobSAS,
    carriers,
    years,
    studies,
    programs,
    programGroups,
    programGroupMembers,
  } = entities
  const blobs = getBlobResponses(blobSAS)

  // now we have a map of carrier id to studies
  const programsByStudyID = programs.reduce((acc, p) => {
    const studyID = p.study_id
    const program = convertProgramFromResponse(p)
    if (!acc[studyID]) {
      acc[studyID] = []
    }
    acc[studyID].push(program)
    return acc
  }, [] as Record<number, Program[]>)

  const studiesByYearID = studies.reduce((acc, s) => {
    const yearID = s.carrier_year_id
    const program = programsByStudyID[s.id]
    const programIDs = program ? program.map(p => p.id) : []
    const study = convertStudyResponse(s, programIDs)
    if (!acc[yearID]) {
      acc[yearID] = []
    }
    acc[yearID].push(study)
    return acc
  }, [] as Record<number, Study[]>)

  const yearsByClientID = years.reduce((acc, y) => {
    const clientID = y.carrier_id
    const year = convertYearResponse(y, studiesByYearID[y.id] || [])
    if (!acc[clientID]) {
      acc[clientID] = []
    }
    acc[clientID].push(year)
    return acc
  }, [] as Record<number, ClientYear[]>)

  return carriers.map(c => {
    const programGroupIDs = programGroups
      .filter(pg => pg.carrier_id === c.id)
      .map(pg => String(pg.id))

    const programGroupMemberIDs = programGroupMembers
      .filter(pgm => pgm.carrier_id === c.id)
      .map(pgm => String(pgm.id))
    return {
      id: String(c.id),
      name: c.name,
      roe_sector: c.roe_sector,
      executive_summary: c.executive_summary,
      eula: c.eula,
      esp_volatility_sector: c.esp_volatility_sector,
      intercept: c.intercept,
      book_value: c.book_value,
      prospective_roe: c.prospective_roe,
      eps_volatility: c.eps_volatility,
      tax_rate: c.tax_rate,
      surplus: c.surplus,
      roe_target: c.roe_target,
      bannerImageSrc:
        blobs.carrier.url +
        '/' +
        blobs.carrier.container_name +
        '/' +
        c.id +
        '.png' +
        blobs.carrier.token,
      clientYears: yearsByClientID[c.id],
      programGroupIDs,
      programGroupMemberIDs,
    }
  })
}
