<div class="toolbar" *ngIf="!showMetrics">
  <div class="left">
    <label *ngIf="!hasClient" big appInfoText>
      <span>Select Company</span>
    </label>
    <label *ngIf="hasClient && !hasStudies" big appInfoText>
      <span>Select Program</span>
    </label>
    <label
      *ngIf="showEmptySelectedLabel || isStudyOnList"
      class="empty"
      appInfoText
      big
    >
    </label>
  </div>
</div>
<ng-container *ngIf="showMetrics">
  <div class="weight-label">
    <div class="metric-info">
      <label appInfoText small
      >The metric options below are presented in analytic families. The
        'enable' toggle determines if a metric will appear for a given
        Program on the Compare page within SAGE. The weights determine the
        relative value for a given metric in the heatmap index, also found
        within Compare.
      </label>
    </div>
    <div class="weight-percent">
      <button
        appButton
        class="popover-button"
        matTooltip="Select from the options to customize all metrics."
        matTooltipPosition="above"
        [matMenuTriggerFor]="moreMenu"
      >
        <mat-icon aria-hidden="false" aria-label="More">
          more_horiz
        </mat-icon>
      </button>
      <mat-menu #moreMenu="matMenu">
        <button mat-menu-item (click)="enableAllClick.emit()">
          Enable All Metrics
        </button>
      </mat-menu>
      <label appInfoText big>
        Total Weight of Selected Metrics:
        <span class="total">{{ totalWeight }}%</span>
      </label>
      <div>
        <label *ngIf="totalWeight !== 100" appInfoText big warn>
          Weight must equal 100%
        </label>
      </div>
    </div>
  </div>
  <div class="categories">
    <ng-container *ngFor="let e of metricCategories">
      <app-metric-settings-categories-table
        *ngIf="e.category != 'Gross Metrics'"
        class="category"
        [isCredit]="isCredit"
        [creditPortfolio]="creditPortfolio"
        [label]="e.category"
        [values]="e.metrics"
        [lossFilters]="lossFilters"
        [metricCategories]="metricCategories"
        (updateMetricElement)="updateMetricElement.emit($event)"
        (updateGrossMetricElement)="onUpdateGrossMetricElement($event)"
        [collapsed]="!isExpanded(e.category)"
        (collapseToggle)="collapseToggle.emit($event)"
      >
      </app-metric-settings-categories-table>
    </ng-container>
  </div>
</ng-container>
<button
  *ngIf="showMetrics"
  class="metric-builder-button"
  appButton
  huge
  accent
  border
  (click)="onMetricBuilderClick()"
>
  Add New Custom Metric
</button>
