import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { isLayerMetricApplicable } from '../analysis/model/layer-metric-applicability'
import { TypeApplicability } from '../analysis/model/layer-metric-defs'
import { MetricValueType } from '../core/model/metric-value-type.model'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { Reference } from './layer-property/layer-property.component'
import { ShortNumberPipe } from './pipes/short-number.pipe'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metric-text',
  template: `
    <ng-container *ngIf="isApplicable; else notApplicableTemplate">
      <ng-container *ngIf="isNumeric; else nonNumericTemplate">
        <ng-container *ngIf="isNonZero; else zeroTemplate">
          <ng-container [ngSwitch]="valueType">
            <ng-container *ngSwitchCase="'dropdown'">
              <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'percentage'">
              <ng-container *ngTemplateOutlet="percentTemplate"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'times'">
              <ng-container *ngTemplateOutlet="timesTemplate"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'numeric'">
              <ng-container
                *ngIf="
                  !isDisplayThreeDecimalPlaces;
                  else threeDecimalPlacesTemplate
                "
              >
                <ng-container
                  *ngTemplateOutlet="numericTemplate"
                ></ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'custom-numeric-SnE'">
              <span>{{ spendAndEfficiencyCustomFormat(value) }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="'numeric-transform'">
              <ng-container
                *ngTemplateOutlet="numericTransformTemplate"
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'nondecimal-numeric'">
              <ng-container
                *ngTemplateOutlet="nonDecimalNumericTransformTemplate"
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'formatted-onedecimal-numeric'">
              <ng-container
                *ngTemplateOutlet="formattedNumericTemplate"
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'unformatted-numeric'">
              <ng-container
                *ngTemplateOutlet="unformattedNumericTemplate"
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'short-numeric'">
              <ng-container
                *ngTemplateOutlet="shortNumericTemplate"
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'ratio'">
              <ng-container *ngIf="numericValue !== 0; else zeroTemplate">
                <span *ngIf="showRatioPercent; else nonRatioPercentTemplate">
                  <ng-container
                    *ngTemplateOutlet="percentTemplate"
                  ></ng-container>
                  /
                </span>
                <span>{{ ratio | number: ratioFormat }}</span>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <ng-container *ngIf="!isUnlimited; else unlimitedTemplate">
                <ng-container *ngIf="!isCurrency; else currencyTemplate">
                  <ng-container *ngIf="!displayBlank; else emptyTemplate">
                    <ng-container
                      *ngIf="
                        !isIdCurrencyFormatted;
                        else currencyFormattedTemplate
                      "
                    >
                      <span>{{ numericValue | number: numberFormat }}</span>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #dropdownTemplate>
      <span>{{ dropdownValue }}</span>
    </ng-template>

    <ng-template #numericTemplate>
      <span>{{ value | number: getNumberFormat }}</span>
    </ng-template>

    <ng-template #threeDecimalPlacesTemplate>
      <span>{{ value | number: '1.3-3' }}</span>
    </ng-template>

    <ng-template #numericTransformTemplate>
      <span>{{ numericValue | number: numberFormat }}</span>
    </ng-template>

    <ng-template #nonDecimalNumericTransformTemplate>
      <span>{{ numericValue | number: '1.0.0' }} </span>
    </ng-template>

    <ng-template #formattedNumericTemplate>
      <span>{{ value | number: '1.1-1' }}</span>
    </ng-template>

    <ng-template #unformattedNumericTemplate>
      <span>{{ value }}</span>
    </ng-template>

    <ng-template #shortNumericTemplate>
      <span>{{ value | appShortNumber }}</span>
    </ng-template>

    <ng-template #percentTemplate>
      <span [matTooltip]="tooltip" matTooltipShowDelay="500">{{
        value <= 0 && isAssignedLines
          ? (value | percent: '1.4-4')
          : (value | percent: percentFormat)
      }}</span>
    </ng-template>

    <ng-template #timesTemplate>
      <span [matTooltip]="tooltip" matTooltipShowDelay="500"
        >{{ value | number: percentFormat }}x</span
      >
    </ng-template>

    <ng-template #nonRatioPercentTemplate>
      <span>1 in </span>
    </ng-template>

    <ng-template #nonNumericTemplate>
      <span
        [matTooltip]="tooltip"
        matTooltipShowDelay="500"
        [ngStyle]="{
          'text-decoration': customClick ? 'underline' : 'none',
          cursor: customClick ? 'pointer' : 'default',
        }"
        >{{ nonNumericValue || '' }}</span
      >
    </ng-template>

    <ng-template #notApplicableTemplate>
      <span>{{ notApplicableText }}</span>
    </ng-template>

    <ng-template #zeroTemplate>
      <span>{{ zeroText }}</span>
    </ng-template>

    <ng-template #currencyTemplate>
      <span>{{
        removeSuffixCurrencyTemplateOption
          ? removeSuffix(numericValue.toString())
          : (numericValue | appShortNumber: currency)
      }}</span>
    </ng-template>

    <ng-template #currencyFormattedTemplate>
      <span>{{ numericValue | currency: 'USD' : 'symbol' : '1.0-0' }}</span>
    </ng-template>

    <ng-template #emptyTemplate>
      <span></span>
    </ng-template>

    <ng-template #unlimitedTemplate>
      <span>{{ unlimitedText }}</span>
    </ng-template>
  `,
  styles: [
    `
      span {
        white-space: pre-wrap;
      }
    `
  ]
})
export class MetricTextComponent {
  @Input() value: any
  @Input() valueType?: MetricValueType
  @Input() currency?: string
  @Input() id?: string
  @Input() precision?: number
  private appShortNumberPipe = new ShortNumberPipe()

  @Input() numberTransform = (val: number) => val
  @Input() numberFormat = '1.1-1'
  @Input() percentFormat = '1.1-1'
  @Input() ratioFormat = '1.1-1'
  @Input() references: Reference[]
  @Input() tooltip?: string
  @Input() isAssignedLines: boolean

  @Input() type?: string
  @Input() subtype?: string
  @Input() whitelist?: TypeApplicability[]
  @Input() blacklist?: TypeApplicability[]
  @Input() removeSuffixCurrencyTemplateOption = false
  @Input() customClick: boolean
  @Input() isLayerDetailsTab: boolean

  @Input() set showRatioPercent(value: any) {
    this._showRatioPercent = coerceBooleanProperty(value)
  }
  get showRatioPercent() {
    return this._showRatioPercent
  }
  _showRatioPercent = false

  @Input() set useZeroText(value: any) {
    this._useZeroText = coerceBooleanProperty(value)
  }
  get useZeroText() {
    return this._useZeroText
  }
  _useZeroText = false

  @Input() zeroText = '-'
  @Input() notApplicableText = 'na'
  @Input() unlimitedText = 'Unlimited'
  private idsToDisplayBlank: string[] = [
    'orderedLimit',
    'expectedLossPercentLimit',
    'contractROL',
    'totalROL',
    'reinstatementsSummary',
  ]
  private idsToDisplayCurrencyFormatted: string[] = [
    'orderedLimit',
    'standardDeviationExpectedLoss',
    'expectedLoss',
    'originalPremium',
    'min_loss',
    'avg_annual_loss',
    'max_loss',
    'num_losses',
    'non_zero_losses',
  ]
  private displayThreeDecimalPlaces: string[] = [
    'premiumMultiple',
    'portfolioEfficiencyScore',
  ]
  private displayAsFormattedDate: string[] = ['inceptionDate']

  get numericValue(): number {
    return !!this.value ? this.numberTransform(Number(this.value)) : 0
  }

  get nonNumericValue(): string {
    const str = String(this.value ?? '')
    if (this.isDisplayAsFormattedDate) {
      return this.formatDate(str)
    }
    return str !== 'NaN' && str !== '' && str !== 'null' ? str : this.zeroText
  }

  spendAndEfficiencyCustomFormat(value: number): string {
    if (value === 0) {
      return '-'
    }

    return new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(value)
  }

  get isNumeric(): boolean {
    return (
      this.valueType != null &&
      this.valueType !== 'text' &&
      this.value != null &&
      !isNaN(Number(this.value))
    )
  }

  get isNonZero(): boolean {
    return !this.useZeroText || this.numericValue !== 0
  }

  get isCurrency(): boolean {
    return this.currency != null && this.valueType === 'currency'
  }

  get isIdCurrencyFormatted(): boolean {
    return this.idsToDisplayCurrencyFormatted.includes(this.id as string)
  }

  get displayBlank(): boolean {
    return (
      this.idsToDisplayBlank.includes(this.id as string) && this.value === ''
    )
  }

  get isUnlimited(): boolean {
    return !!this.value && Number(this.value) >= 1e21
  }

  get ratio(): number {
    return 100 * (1 / (100 * Number(this.value)))
  }

  get isApplicable(): boolean {
    return isLayerMetricApplicable(
      this.type,
      this.subtype,
      this.whitelist,
      this.blacklist
    )
  }

  get dropdownValue() {
    if (this.references) {
      return this.references.find(r => r.value === this.value)?.viewValue || ''
    } else {
      return ''
    }
  }

  get isDisplayThreeDecimalPlaces(): boolean {
    return this.displayThreeDecimalPlaces.includes(this.id as string)
  }

  get isDisplayAsFormattedDate(): boolean {
    return this.displayAsFormattedDate.includes(this.id as string)
  }

  get getNumberFormat(): string {
    if (this.precision > 1) {
      return '1.' + this.precision + '-' + this.precision
    } else {
      return this.numberFormat
    }
  }

  removeSuffix(value: string): string {
    if (this.isLayerDetailsTab) {
      const result = Number(value).toLocaleString('en-US', {
        style: 'currency',
        currency: this.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      return result
    }
    value = this.appShortNumberPipe.transform(Number(value)).toString()
    let output = value
    if (value.charAt(value.length - 1).match(/[A-z]/)) {
      output = value.slice(0, -1)
    }
    return output
  }

  formatDate(value: string): string {
    return value.split('T')[0]
  }
}
