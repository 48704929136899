import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { LayerState } from '../../../store/ceded-layers/layers.reducer'
import { CreateTopHiddenLayers } from '../../properties-panel.model'
import { LossSetLayer } from '../../../model/loss-set-layers.model'
import { Layer, PhysicalLayer } from '../../../model/layers.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-top-drop-dialog-component',
  styleUrls: ['./top-drop-dialog.component.scss'],
  templateUrl: './top-drop-dialog.component.html',
})
export class TopDropDialogComponent {
  topLayer: Layer
  dropLayers: Layer[] = []
  hiddenLayer: Layer | null

  @Input() set topLayerSet(value: LayerState | null) {
    const topLayerState = JSON.parse(JSON.stringify(value)) as LayerState
    this.topLayer = topLayerState.layer
    if (!this.hiddenLayer) {
      this.hiddenLayer = tempHiddenLayer(this.topLayer)
    }
  }

  @Input() set dropLayerSets(dropLayersState: LayerState[]) {
    const dropLayers: Layer[] = []
    dropLayersState.forEach(l => {
      const dropLayerState = JSON.parse(JSON.stringify(l)) as LayerState | null
      if (dropLayerState) {
        dropLayers.push(dropLayerState.layer)
      }
    })
    this.dropLayers = dropLayers
  }

  @Input() set hiddenLayerSet(value: LayerState | null) {
    const hiddenLayerState = JSON.parse(
      JSON.stringify(value)
    ) as LayerState | null
    this.hiddenLayer = hiddenLayerState?.layer ?? null
  }

  @Input() lossSetLayers: LossSetLayer[]
  @Input() currentStructureID: string
  @Input() isNewDropLayerSaving = false

  @Output() createTopHiddenLayers = new EventEmitter<CreateTopHiddenLayers>()
  @Output() createDropLayer = new EventEmitter<void>()
  @Output() physicalLayerChange = new EventEmitter<{
    layer: Layer
    physicalLayerChange: Partial<PhysicalLayer>
  }>()
  @Output() layerChange = new EventEmitter<{
    layer: Layer
    layerChange: Partial<Layer>
  }>()
  @Output() deleteDropLayer = new EventEmitter<string>()

  constructor(
    public dialogRef: MatDialogRef<
      TopDropDialogComponent,
      Partial<PhysicalLayer>[]
    >
  ) {}

  get currentCurrency(): string {
    return this.topLayer.physicalLayer.franchise.currency
  }

  get labelButton(): string {
    if (this.hiddenLayer) {
      return 'Save'
    }
    return 'Create'
  }

  onSaveOrCreate() {
    if (!this.isDisabled) {
      if (this.hiddenLayer && this.dropLayers?.length > 0) {
        // save
        this.dialogRef.close([
          this.topLayer.physicalLayer,
          ...this.dropLayers.map(dropLayer => dropLayer.physicalLayer),
          this.hiddenLayer.physicalLayer,
        ])
      } else {
        this.dialogRef.close([this.topLayer.physicalLayer])
      }
    }
  }

  get isDisabled(): boolean {
    if (this.dropLayers?.length === 0) {
      return true
    }

    let disabled = false

    // All occ limits must be greater than 0
    const dropOccLimit = this.dropLayers.reduce((acc, l) => {
      const occLimit = l.physicalLayer.limit.value
      if (occLimit === 0) {
        disabled = true
      }
      return acc + occLimit
    }, 0)

    /* Early return if some drop layer occ. limit is 0 */
    if (disabled) {
      return true
    }

    const dropAttachment = this.dropLayers.reduce(
      (acc, l) => acc + l.physicalLayer.attachment.value,
      0
    )
    const topOccLimit = this.topLayer.physicalLayer.limit.value
    const topAttachment = this.topLayer.physicalLayer.attachment.value

    if (
      dropOccLimit <= 0 ||
      dropAttachment + dropOccLimit > topAttachment + topOccLimit
    ) {
      disabled = true
    }

    return disabled
  }

  onCreateDropLayer(): void {
    this.createDropLayer.emit()
  }
}

// Probably not needed because hidden is already created by top layer.
function tempHiddenLayer(topLayer: Layer): Layer {
  const logicalIDTemp = new Date().getTime() + '' + Math.random() * 100000
  return {
    id: logicalIDTemp,
    meta_data: {
      client: topLayer.physicalLayer.meta_data.client,
      perspective: topLayer.physicalLayer.meta_data.perspective,
      program_name: topLayer.physicalLayer.meta_data.program_name,
      program_type: topLayer.physicalLayer.meta_data.program_type,
      rol: topLayer.physicalLayer.meta_data.rol,
      rol_type: topLayer.physicalLayer.meta_data.rol_type,
      sage_layer_type: 'drop',
      year: topLayer.physicalLayer.meta_data.year,
      lossfilter_name: '',
      isDrop: true,
      topID: topLayer.id,
      sharedLimitHidden: Number.MAX_VALUE,
      layerName: 'New Layer',
      structureID: '',
    },
    layerRefs: [],
    lossSetFilter: '',
    lossSetLayers: [],
    physicalLayer: {
      logicalLayerID: logicalIDTemp,
      id: (new Date().getTime() + '' + Math.random() * 100000).replace('.', ''),
      type: topLayer.physicalLayer.type,
      attachment: {
        value: 0,
        currency: topLayer.physicalLayer.franchise.currency,
      },
      limit: {
        value: Number.MAX_VALUE,
        currency: topLayer.physicalLayer.franchise.currency,
      },
      participation: 0,
      premium: {
        value: topLayer.physicalLayer.limit.value,
        currency: topLayer.physicalLayer.franchise.currency,
      },
      fees: [],
      aggregateLimit: {
        value: 0,
        currency: topLayer.physicalLayer.franchise.currency,
      },
      aggregateAttachment: {
        value: 0,
        currency: topLayer.physicalLayer.franchise.currency,
      },
      description: 'New Layer',
      meta_data: {
        client: topLayer.physicalLayer.meta_data.client,
        perspective: topLayer.physicalLayer.meta_data.perspective,
        program_name: topLayer.physicalLayer.meta_data.program_name,
        program_type: topLayer.physicalLayer.meta_data.program_type,
        rol: topLayer.physicalLayer.meta_data.rol,
        rol_type: topLayer.physicalLayer.meta_data.rol_type,
        sage_layer_type: 'drop',
        year: topLayer.physicalLayer.meta_data.year,
        isDrop: true,
      },
      reinstatements: [],
      franchise: {
        value: 0,
        currency: topLayer.physicalLayer.franchise.currency,
      },
      xcoord: 469,
      ycoord: 110,
    },
    sharedLayerID: '',
    viewMetrics: {
      error: null,
      loading: false,
      metrics: null,
      rss: null,
    },
  }
}
