import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import {
  LossSetGroup,
  LossSetLayer,
} from '../../../analysis/model/loss-set-layers.model'
import {
  CombinedLossDistributionRow,
  LossDistributionRow,
} from '../../../core/model/loss-set-table.model'
import { Program } from '../../../core/model/program.model'
import {
  Perspective,
  AggregationMethodType,
  VaRTVaR,
} from '../../model/metrics.model'
import * as fromExploreActions from './explore.actions'
import { convertCombinedLossDistributionTable } from '../explore.util'
import { SummaryDataResponse } from '../explore.model'

export interface LossSetTableState {
  lossDistributionDataTable: CombinedLossDistributionRow[]
  lossID: string | null
  lossType: string
  lossName: string
  filterValue: string
  isLossRatioView: boolean
  subjectPremiumAmt: number
  aggMethodLossDistributionTable: LossDistributionRow[]
}

export interface ModifierState {
  perspective: Perspective
  aggregationMethod: AggregationMethodType
  vartvar: VaRTVaR
  isLossRatioView?: boolean
}

export interface State extends EntityState<LossSetTableState> {
  program: Program | null
  lossSetLayers: LossSetLayer[]
  lossSetLayerGroups: LossSetGroup[]
  savedLossSetIDs: string[]
  grossPortfolioView: string | null
  loading: boolean
  error: boolean
  modifiers: ModifierState
  arrRP: number[]
  summaryData: SummaryDataResponse[]
  groupSummaryData: SummaryDataResponse[]
}

export const adapter = createEntityAdapter<LossSetTableState>({
  // tslint:disable-next-line: no-non-null-assertion
  selectId: (lossSetTableState): string => lossSetTableState.lossID!,
})

export const initialModifierState: ModifierState = {
  perspective: 'Loss',
  aggregationMethod: 'OEP',
  vartvar: 'VaR',
  isLossRatioView: false,
}

export const initialState: State = adapter.getInitialState({
  program: null,
  lossSetLayers: [],
  lossSetLayerGroups: [],
  savedLossSetIDs: [],
  grossPortfolioView: null,
  loading: false,
  error: false,
  modifiers: initialModifierState,
  arrRP: [1000, 500, 250, 200, 100, 50, 25, 10, 5, 2, 1],
  summaryData: [],
  groupSummaryData: []
})

const exploreReducer = createReducer(
  initialState,

  on(fromExploreActions.setExploreProgram, (state: State, { program }) => {
    return { ...state, program, loading: true, error: false }
  }),

  on(
    fromExploreActions.getExploreLossSetLayersAndGroups,
    (state: State, { lossSetLayers, lossSetLayerGroups }) => {
      return { ...state, lossSetLayers, lossSetLayerGroups }
    }
  ),

  on(
    fromExploreActions.addLossSetIDs,
    (state: State, { lossSetGroups, lossSetLayers }) => {
      const savedIDs = state.savedLossSetIDs.slice()
      lossSetLayers?.forEach(({ lossID }) => {
        if (savedIDs.indexOf(lossID) === -1) {
          savedIDs.push(lossID)
        }
      })
      lossSetGroups?.forEach(({ lossID }) => {
        if (savedIDs.indexOf(lossID) === -1) {
          savedIDs.push(lossID)
        }
      })
      return { ...state, loading: true, savedLossSetIDs: savedIDs }
    }
  ),

  on(
    fromExploreActions.removeLossSetIDs,
    (state: State, { lossSetGroupsToRemove, lossSetLayersToRemove }) => {
      const savedIDs = state.savedLossSetIDs.slice()
      lossSetGroupsToRemove.forEach(lossID => {
        savedIDs.splice(savedIDs.indexOf(lossID), 1)
      })
      lossSetLayersToRemove.forEach(lossID => {
        savedIDs.splice(savedIDs.indexOf(lossID), 1)
      })
      state = { ...state, savedLossSetIDs: savedIDs }
      return adapter.removeMany(
        [...lossSetGroupsToRemove, ...lossSetLayersToRemove],
        state
      )
    }
  ),

  on(fromExploreActions.clearExplore, (_state: State) => {
    return initialState
  }),

  on(
    fromExploreActions.getGrossPortfolioViewIDSuccess,
    (state: State, { grossPortfolioView }) => {
      return { ...state, grossPortfolioView }
    }
  ),

  on(
    fromExploreActions.getLossDataCollectionSuccess,
    (state: State, { payload }) => {
      const lossSetTableStates: LossSetTableState[] = []
      payload.forEach(p => {
        const lossSetTableState: LossSetTableState = {
          lossDistributionDataTable: p.dataTable,
          lossID: p.lossID,
          lossType: p.lossType,
          lossName: p.lossName,
          filterValue: p.filterValue,
          isLossRatioView: p.isLossRatioView ?? false,
          subjectPremiumAmt: p.subjectPremiumAmt || 0,
          aggMethodLossDistributionTable: convertCombinedLossDistributionTable(
            p.dataTable,
            'OEP'
          ),
        }
        lossSetTableStates.push(lossSetTableState)
      })

      return adapter.upsertMany(lossSetTableStates, {
        ...state,
        loading: false,
      })
    }
  ),
  on(
    fromExploreActions.updateLossDataModifiers,
    (
      state: State,
      { rp, index, perspective, aggregationMethod, vartvar, isLossRatioView }
    ) => {
      const newRP = state.arrRP.slice()
      if (rp && index !== undefined) {
        newRP.splice(index, 1, rp)
      }
      return {
        ...state,
        loading: true,
        error: false,
        arrRP: newRP,
        modifiers: {
          perspective,
          aggregationMethod,
          vartvar,
          isLossRatioView,
        },
      }
    }
  ),

  on(fromExploreActions.updateLossDataModifiersSuccess, (state: State) => {
    return { ...state, loading: false }
  }),

  on(
    fromExploreActions.getGrossPortfolioViewIDFailure,
    fromExploreActions.getLossDataCollectionError,
    fromExploreActions.updateLossDataModifiersError,
    (state: State, {}) => {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }
  ),
  on(fromExploreActions.resetExploreContainer, () => ({ ...initialState })),
  on(fromExploreActions.getSummaryDataSuccess, ((state: State, { summaryData }) => {
    return {
      ...state,
      summaryData
    }
  })),
  on(fromExploreActions.getSummaryGroupDataSuccess, ((state: State, { groupSummaryData }) => {
    return {
      ...state,
      groupSummaryData
    }
  })),
)

export function reducer(state: State | undefined, action: Action) {
  return exploreReducer(state, action)
}
