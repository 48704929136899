import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SelectedUserPreferences } from 'src/app/core/model/reinsurer.model'
import { environment } from '../../../environments/environment'
import { ApiResponse } from '../model/api.model'
import { catchAndHandleError, mapToMaybeData } from '../util'

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  private httpOptions = {
    headers: new HttpHeaders({
      // 'Accept-Encoding': 'gzip, compress, br',
      'Content-Type': 'application/json',
      References: 'Expand',
    }),
  }
  constructor(private httpClient: HttpClient) {}

  getUserPreferences(studyID: string): ApiResponse<SelectedUserPreferences> {
    const url = `${environment.internalApi.base}${environment.internalApi.userPreferences}/${studyID}`
    return this.httpClient
      .get<SelectedUserPreferences>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('Get User Preferences for progam and user')
      )
  }

  saveOrUpdateUserPreferences(
    selectedUserPreferences: SelectedUserPreferences
  ): ApiResponse<SelectedUserPreferences> {
    const url = `${environment.internalApi.base}${environment.internalApi.userPreferences}`

    return this.httpClient
      .post<SelectedUserPreferences>(
        url,
        selectedUserPreferences,
        this.httpOptions
      )
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('Save or Update User Preferences')
      )
  }
}
