import { Injectable } from '@angular/core'
import {
  environment as ENV,
  environment,
} from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { ApiResponse } from '../model/api.model'
import {
  CombinedSelectors,
  PricingCurveLayer,
  SavedPricingCurveEntry,
  PricingCurveContextTypes,
  LayerResponse,
  PricingCurveData,
  CreditCurveLayer,
  PredictionsResponse,
} from 'src/app/pricingcurve/model/pricing-curve.model'
import {
  SavedCreditCurveResponse,
  SavedPricingCurveResponse,
  SelectorsResponse,
  convertCreditSavedCurveResponseToPricingCurve,
  convertLayerResponse,
  convertPricingCurveToCreditCurveDto,
  convertPricingCurveToSavedCurveDto,
  convertSavedCreditCurveResponseToSavedCurveEntries,
  convertSavedCurveResponseToPricingCurve,
  convertSavedCurveResponsesToSavedCurveEntries,
  convertSelectorResponse,
} from './pricing-curve.converter'
import {
  catchAndHandleError,
  encodeParams,
  mapToMaybeData,
  rejectError,
} from '../util'
import { map, switchMap } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import {
  buildCreditSelectorsFromLayers,
  filterCreditLayers,
  filterSelectorsForLayerRequest,
  getKeyValuePairs,
  updateLayerStatusForCurve,
} from 'src/app/pricingcurve/pricing-curve.utils'

const API = ENV.internalApi.base
const SAVED_CURVE_URL = `${API}${environment.internalApi.pricingCurveSavedCurve}`
const SAVED_CREDIT_CURVE_URL = `${API}${environment.internalApi.pricingCurveSavedCreditCurve}`

@Injectable({
  providedIn: 'root',
})
export class PricingCurveService {
  constructor(private http: HttpClient) {}

  getSelectors(
    params: Record<string, string | string[]> | undefined,
    state?: CombinedSelectors
  ): ApiResponse<CombinedSelectors> {
    const url = `${API}${environment.internalApi.pricingCurveSelectors}`

    return this.http
      .get<SelectorsResponse>(url, { params: encodeParams(params) })
      .pipe(
        map(response => convertSelectorResponse(response, state)),
        mapToMaybeData(),
        catchAndHandleError('Get pricing curve selectors')
      )
  }

  getPricingCurveLayers(
    params: Record<string, string | string[]> | undefined
  ): ApiResponse<PricingCurveLayer[]> {
    const url = `${API}${environment.internalApi.pricingCurveLayers}`
    return this.http
      .get<LayerResponse[]>(url, { params: encodeParams(params) })
      .pipe(
        map(response => response.filter(layer => !!layer.el && !!layer.trol)),
        map(response => convertLayerResponse(response)),
        mapToMaybeData(),
        catchAndHandleError('Get pricing curve layers')
      )
  }

  getCreditTrancheData(
    params: Record<string, string | string[]> | undefined
  ): ApiResponse<CreditCurveLayer[]> {
    const url = `${API}${environment.internalApi.pricingCurveTranches}`
    return this.http
      .get<CreditCurveLayer[]>(url, { params: encodeParams(params) })
      .pipe(
        map(response => response.map(val => ({ ...val, include: true }))),
        mapToMaybeData(),
        catchAndHandleError('Get pricing curve tranche data')
      )
  }

  getLayers(
    params: Record<string, string | string[]> | undefined,
    context: PricingCurveContextTypes = 'pricing-curve'
  ): ApiResponse<PricingCurveLayer[] | CreditCurveLayer[]> {
    return context === 'credit'
      ? this.getCreditTrancheData(params)
      : this.getPricingCurveLayers(params)
  }

  getPricingCurves(
    params: Record<string, string | string[]> | undefined,
    context: PricingCurveContextTypes = 'pricing-curve'
  ): ApiResponse<SavedPricingCurveEntry[]> {
    if (context === 'credit') {
      return this.http
        .get<SavedCreditCurveResponse[]>(SAVED_CREDIT_CURVE_URL)
        .pipe(
          map(response =>
            convertSavedCreditCurveResponseToSavedCurveEntries(response)
          ),
          mapToMaybeData(),
          catchAndHandleError('GET saved pricing curves')
        )
    }
    return this.http
      .get<SavedPricingCurveResponse[]>(SAVED_CURVE_URL, {
        params: encodeParams(params),
      })
      .pipe(
        map(response =>
          convertSavedCurveResponsesToSavedCurveEntries(response)
        ),
        mapToMaybeData(),
        catchAndHandleError('GET saved pricing curves')
      )
  }

  getLayersForCreditCurve(
    data: PricingCurveData,
    context: PricingCurveContextTypes
  ): Observable<PricingCurveData> {
    return this.getLayers(undefined, context).pipe(
      rejectError(error => of({ error })),
      map(layers => updateLayerStatusForCurve(data, layers, context))
    )
  }

  getPricingCurve(
    id: number,
    context: PricingCurveContextTypes
  ): ApiResponse<PricingCurveData> {
    if (context === 'credit') {
      const url = `${SAVED_CREDIT_CURVE_URL}/${id}`
      return this.http.get<SavedCreditCurveResponse>(url).pipe(
        map(val => convertCreditSavedCurveResponseToPricingCurve(val)),
        switchMap(val => this.getLayersForCreditCurve(val, context)),
        map(val => buildCreditSelectorsFromLayers(val)),
        switchMap(val => this.getCreditPredictions(val, false)),
        catchAndHandleError('GET saved pricing curve')
      )
    }
    const url = `${SAVED_CURVE_URL}/${id}`
    return this.http.get<SavedPricingCurveResponse>(url).pipe(
      map(response => convertSavedCurveResponseToPricingCurve(response)),
      switchMap(curveData =>
        this.getLayersAndSelectorsForCurve(curveData, context)
      ),
      mapToMaybeData(),
      catchAndHandleError('GET saved pricing curve')
    )
  }

  getLayersAndSelectorsForCurve(
    curveData: PricingCurveData,
    context: PricingCurveContextTypes
  ): Observable<PricingCurveData> {
    if (
      !curveData.layers.length &&
      curveData.dateIntervals &&
      curveData.selectors
    ) {
      const selectors = getKeyValuePairs(
        curveData.dateIntervals,
        filterSelectorsForLayerRequest(curveData.selectors),
        curveData.layerSplitView
      )
      return this.getLayers(selectors, context).pipe(
        rejectError(error => of({ error })),
        switchMap(layers => {
          const updatedCurve = updateLayerStatusForCurve(
            curveData,
            layers,
            context
          )
          if (!updatedCurve.dateIntervals || !updatedCurve.selectors) {
            return of(updatedCurve)
          }
          const combinedSelectors: CombinedSelectors = {
            dateIntervals: updatedCurve.dateIntervals,
            selectors: updatedCurve.selectors,
          }
          return this.getSelectors(selectors, combinedSelectors).pipe(
            rejectError(error => of({ error })),
            map(combinedSelectors => {
              const curveWithSelectors: PricingCurveData = {
                ...updatedCurve,
                selectors: combinedSelectors.selectors,
                dateIntervals: combinedSelectors.dateIntervals,
              }
              return curveWithSelectors
            })
          )
        })
      )
    } else {
      return of(curveData)
    }
  }

  postPricingCurve(
    curve: PricingCurveData,
    context: PricingCurveContextTypes
  ): ApiResponse<PricingCurveData> {
    if (context === 'pricing-curve') {
      const dto = convertPricingCurveToSavedCurveDto(curve)
      return this.http
        .post<SavedPricingCurveResponse>(SAVED_CURVE_URL, dto)
        .pipe(
          map(response => ({ ...curve, id: response.id })),
          switchMap(curveData =>
            this.getLayersAndSelectorsForCurve(curveData, context)
          ),
          mapToMaybeData(),
          catchAndHandleError('POST pricing curve')
        )
    } else {
      const dto = convertPricingCurveToCreditCurveDto(curve)
      return this.http
        .post<SavedCreditCurveResponse>(SAVED_CREDIT_CURVE_URL, dto)
        .pipe(
          map(response => ({ ...curve, id: response.id })),
          switchMap(curveData =>
            this.getLayersForCreditCurve(curveData, context)
          ),
          mapToMaybeData(),
          catchAndHandleError('POST pricing curve')
        )
    }
  }

  updateSavedPricingCurve(
    id: number,
    curve: PricingCurveData,
    context: PricingCurveContextTypes
  ): ApiResponse<PricingCurveData> {
    if (context === 'pricing-curve') {
      const url = `${SAVED_CURVE_URL}/${id}`
      const dto = convertPricingCurveToSavedCurveDto(curve, id)
      return this.http.put<SavedPricingCurveResponse>(url, dto).pipe(
        map(response => ({ ...curve, id: response.id })),
        switchMap(curveData =>
          this.getLayersAndSelectorsForCurve(curveData, context)
        ),
        mapToMaybeData(),
        catchAndHandleError('Put saved pricing cuve')
      )
    } else {
      const url = `${SAVED_CREDIT_CURVE_URL}/${id}`
      const dto = convertPricingCurveToCreditCurveDto(curve, id)
      return this.http.put<SavedCreditCurveResponse>(url, dto).pipe(
        map(response => ({
          ...curve,
          id: response.id,
          includedLayerIds: response.included_tranche_ids.split(','),
        })),
        switchMap(curveData =>
          this.getLayersForCreditCurve(curveData, context)
        ),
        mapToMaybeData(),
        catchAndHandleError('Put saved pricing cuve')
      )
    }
  }

  deletePricingCurve(id: number, context: PricingCurveContextTypes) {
    if (context === 'pricing-curve') {
      const url = `${SAVED_CURVE_URL}/${id}`
      return this.http.delete(url)
    } else {
      const url = `${SAVED_CREDIT_CURVE_URL}/${id}`
      return this.http.delete(url)
    }
  }

  getCreditPredictions(
    curveData: PricingCurveData,
    saveCurve: boolean
  ): ApiResponse<PricingCurveData> {
    const url = `${API}${environment.internalApi.pricingCurveCreditPredictions}`
    const includedTranches = filterCreditLayers(
      curveData.creditLayers,
      curveData.creditSelectors
    ).filter(layer => layer.include)
    const trancheIdString = includedTranches
      .map(layer => layer.trancheId)
      .toString()
      .trim()
    // tslint:disable-next-line: no-non-null-assertion
    const predictionColumns = Object.values(curveData.creditPredictionColumns!)
      .filter(val => val.isActive)
      .map(val => val.key)
      .toString()

    const params: Record<string, string> = { columns_str: predictionColumns }
    // If all tranches are used in curve, don't included param, api automatically assumes all
    if (curveData.creditLayers.length !== includedTranches.length) {
      params['tranche_ids_str'] = trancheIdString
    }
    params['minimum_percent'] = String(curveData.minimumPremium)
    params['scaling_factor'] = String(curveData.scaleFactor)

    return this.http.get<PredictionsResponse>(url, { params }).pipe(
      mapToMaybeData(),
      catchAndHandleError('Get credit predictions'),
      rejectError(error => of({ error })),
      switchMap(predictions => {
        const updatedCurve: PricingCurveData = {
          ...curveData,
          creditPredictions: predictions,
        }
        if (!saveCurve) {
          return of({ data: updatedCurve })
        }
        if (updatedCurve.id >= 0) {
          return this.updateSavedPricingCurve(
            updatedCurve.id,
            updatedCurve,
            'credit'
          )
        } else {
          return this.postPricingCurve(updatedCurve, 'credit').pipe(
            rejectError(error => of({ error })),
            map(response => ({ data: { ...updatedCurve, id: response.id } }))
          )
        }
      })
    )
  }
}
