import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { map, take, withLatestFrom } from 'rxjs/operators'
import { AccountOpportunity } from '../../api/model/backend.model'
import { ClientYear } from '../../core/model/client.model'
import { Program } from '../../core/model/program.model'
import { AppState } from '../../core/store'
import {
  setGroupFilterByAssoc,
  setStructureFilter,
} from '../../core/store/broker/broker.actions'
import {
  selectCurrencyList,
  selectCurrentAnalysisProfile,
  selectCurrentClientID,
  selectCurrentClientYears,
  selectCurrentStudyID,
  selectCurrentYearID,
  selectCurrentYearStudies,
  selectGroupFilterByAssoc,
  selectStructureFilter,
} from '../../core/store/broker/broker.selectors'
import {
  selectCurrentClientProgramGroupsMembers,
  selectProgramGroupMembers,
} from '../../core/store/program-group-member.selectors'
import { selectCurrentClientProgramGroups } from '../../core/store/program-group/program-group.selectors'
import { selectCurrentStudyPrograms } from '../../core/store/program/program.selectors'
import { Layer } from '../model/layers.model'
import { findRiskActualLayer } from '../model/layers.util'
import { PortfolioSetID } from '../model/portfolio-set.model'
import {
  selectAllGrouperProgramCededLayerStates,
  selectCededPortfolioViewID,
  selectCededPortfolioViewLayersSLViewIDs,
  selectGrossPortfolioViewID,
  selectGroupCurrency,
  selectGrouperAnalysisProfileID,
  selectGrouperInuranceMode,
  selectGrouperIsDirty,
  selectGrouperPortfolioSetID,
  selectGrouperProgramEntities,
  selectGrouperProgramGroupIDs,
  selectGrouperProgramGroups,
  selectGrouperProgramIDs,
  selectGrouperPrograms,
  selectGrouperSaving,
  selectGrouperSlidesPerView,
  selectGrouperYearID,
  selectNetPortfolioViewID,
  selectNextGrouperInuranceSymbol,
  selectSharedLimitAddLayerSelections,
  selectSharedLimitLayers,
  selectSharedLimitMode,
  selectSharedLimitProperties,
  selectSharedLimitSaving,
  selectSharedLimitSelectedLayer,
} from '../store/analysis.selectors'
import {
  refreshGrouperTowerSizePercentage,
  setGrouperSlidesPerView,
} from '../store/grouper/grouper-view/grouper-view.actions'
import {
  groupCurrency,
  resetGrouper,
  saveGrouper,
} from '../store/grouper/grouper.actions'
import { GrouperInuranceMode } from '../store/grouper/inurance/grouper-inurance.reducer'
import {
  ProgramGroup,
  ProgramGroupMember,
  SharedLimitLayerSelection,
  SharedLimitProperties,
} from '../store/grouper/program-group.model'
import { createFetchPortfolioViewActionsFromGroups } from '../store/grouper/program-group/create-portfolio-view-action'
import * as ProgramGroupActions from '../store/grouper/program-group/program-group.actions'
import * as ProgramActions from '../store/grouper/program/program.actions'
import { ProgramEntity } from '../store/grouper/program/program.reducer'
import * as SharedLimitActions from '../store/grouper/shared-limit/grouper-shared-limit.actions'
import { SharedLimitMode } from '../store/grouper/shared-limit/grouper-shared-limit.reducer'
import { getSLLayerViewMetricID } from '../store/metrics/layer-metrics.selectors'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { Study } from '../../core/model/study.model'
import { selectAccountOpportunities } from '../../core/store/accountopportunity.selectors'
import { CurrencyCode } from '../../api/analyzere/analyzere.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-container',
  template: `
    <app-theme-container>
      <app-group-compare
        name="Group"
        [analysisProfileID]="analysisProfileID$ | async"
        [id]="portfolioSetID$ | async"
        [yearID]="yearID$ | async"
        [years]="years$ | async"
        [programGroups]="programGroups$ | async"
        [programGroupMembers]="programGroupMembers$ | async"
        [programs]="programs$ | async"
        [programIDs]="programIDs$ | async"
        [clientProgramGroupMembers]="clientProgramGroupMembers$ | async"
        [entities]="entities$ | async"
        [selectedClientID]="selectedClientID$ | async"
        [selectedYearID]="selectedYearID$ | async"
        [cededPortfolioViewID]="cededPortfolioViewID$ | async"
        [grossPortfolioViewID]="grossPortfolioViewID$ | async"
        [netPortfolioViewID]="netPortfolioViewID$ | async"
        [selectedProgramGroupIDs]="selectedProgramGroupIDs$ | async"
        [slidesPerView]="slidesPerView$ | async"
        [saving]="saving$ | async"
        [dirty]="dirty$ | async"
        [sharedLimitMode]="sharedLimitMode$ | async"
        [sharedLimitSaving]="sharedLimitSaving$ | async"
        [sharedLimitProperties]="sharedLimitProperties$ | async"
        [sharedLimitAddLayers]="sharedLimitAddLayers$ | async"
        [sharedLimitLayers]="sharedLimitLayers$ | async"
        [sharedCurrentCurrency]="sharedCurrentCurrency$ | async"
        [inuranceMode]="inuranceMode$ | async"
        [nextInuranceSymbol]="nextInuranceSymbol$ | async"
        [currentClientID]="currentClientID$ | async"
        [structureFilter]="structureFilter$ | async"
        [groupFilterByAssoc]="groupFilterByAssoc$ | async"
        [studyID]="studyID$ | async"
        [selectedViewID]="selectedViewID$ | async"
        [selectedProgramID]="selectedProgramID$ | async"
        [studies]="studies$ | async"
        [accountOpportunities]="accountOpportunities$ | async"
        [currencyList]="currencyList$ | async"
        [filteredCurrencyList]="currencyList$ | async"
        [groupCurrency]="groupCurrency$ | async"
        (selectedCurrency)="getSelectedCurrency($event)"
        (programGroupAdd)="onProgramGroupAdd($event)"
        (programGroupRemove)="onProgramGroupRemove($event)"
        (programAdd)="onProgramAdd($event)"
        (programRemove)="onProgramRemove($event)"
        (slidesPerViewChange)="onSlidesPerViewChange($event)"
        (cancelClick)="onCancelClick()"
        (saveClick)="onSaveClick()"
        (saveSharedLimitClick)="onSaveSharedLimitClick($event)"
        (addSharedLayerClick)="onAddSharedLayerClick()"
        (structureFilterChange)="onStructureFilterChange($event)"
        (groupFilterByAssocChange)="onGroupFilterByAssocChange($event)"
        (export)="onExport($event)"
      ></app-group-compare>
    </app-theme-container>
  `,
})
export class GroupContainerComponent implements OnInit {
  analysisProfileID$: Observable<string | null>
  yearID$: Observable<string | null>
  years$: Observable<readonly ClientYear[]>
  programGroups$: Observable<readonly ProgramGroup[]>
  programs$: Observable<readonly Program[]>
  programIDs$: Observable<readonly string[]>
  entities$: Observable<ProgramEntity[]>
  selectedYearID$: Observable<string | null>
  selectedClientID$: Observable<string | null>
  selectedProgramGroupIDs$: Observable<string[]>
  clientProgramGroupMembers$: Observable<readonly ProgramGroupMember[]>

  sharedLimitMode$: Observable<SharedLimitMode>
  sharedLimitSaving$: Observable<boolean>
  sharedLimitProperties$: Observable<SharedLimitProperties>
  sharedLimitAddLayers$: Observable<SharedLimitLayerSelection[]>
  sharedLimitLayers$: Observable<Layer[]>
  sharedCurrentCurrency$: Observable<string | null>

  slidesPerView$: Observable<number>
  saving$: Observable<boolean>
  dirty$: Observable<boolean>
  selectedViewID$: Observable<string | null>
  portfolioSetID$: Observable<PortfolioSetID | null>

  inuranceMode$: Observable<GrouperInuranceMode>
  nextInuranceSymbol$: Observable<string>

  programGroupMembers$: Observable<readonly ProgramGroupMember[]>
  currentClientID$: Observable<string | null>
  cededPortfolioViewID$: Observable<string | null>
  grossPortfolioViewID$: Observable<string | null>
  netPortfolioViewID$: Observable<string | null>

  structureFilter$: Observable<string | null>
  groupFilterByAssoc$: Observable<boolean>
  studyID$: Observable<string | null>

  selectedProgramID$: Observable<string | null>
  studies$: Observable<readonly Study[]>
  accountOpportunities$: Observable<AccountOpportunity[] | null>
  currencyList$: Observable<CurrencyCode[]>
  groupCurrency$: Observable<string | any>

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit() {
    this.store.dispatch(resetGrouper())
    this.studyID$ = this.store.pipe(select(selectCurrentStudyID))
    this.groupFilterByAssoc$ = this.store.pipe(select(selectGroupFilterByAssoc))
    this.structureFilter$ = this.store.pipe(select(selectStructureFilter))
    this.currentClientID$ = this.store.pipe(select(selectCurrentClientID))
    this.cededPortfolioViewID$ = this.store.pipe(
      select(selectCededPortfolioViewID)
    )
    this.grossPortfolioViewID$ = this.store.pipe(
      select(selectGrossPortfolioViewID)
    )
    this.netPortfolioViewID$ = this.store.pipe(select(selectNetPortfolioViewID))

    this.store.dispatch(refreshGrouperTowerSizePercentage())

    this.analysisProfileID$ = this.store.pipe(
      select(selectGrouperAnalysisProfileID)
    )
    this.yearID$ = this.store.pipe(select(selectGrouperYearID))
    this.years$ = this.store.pipe(select(selectCurrentClientYears))
    this.programGroups$ = this.store.pipe(
      select(selectCurrentClientProgramGroups)
    )
    this.store
      .pipe(select(selectGrouperProgramGroups), take(1))
      .subscribe(programGroups => {
        const actions = createFetchPortfolioViewActionsFromGroups(
          programGroups.filter(g => g.analysisProfileID)
        )
        actions.forEach(action => this.store.dispatch(action))
      })
    this.clientProgramGroupMembers$ = this.store.pipe(
      select(selectCurrentClientProgramGroupsMembers)
    )
    this.programs$ = this.store.pipe(select(selectCurrentStudyPrograms))
    this.store
      .pipe(select(selectGrouperPrograms), take(1))
      .subscribe(programs => {
        programs.forEach(program =>
          this.store.dispatch(
            ProgramActions.fetchGrouperProgramLayers({ program })
          )
        )
      })
    this.programIDs$ = this.store.pipe(select(selectGrouperProgramIDs))
    this.programIDs$.subscribe(res => {
      if (res.length === 0) {
        this.store.dispatch(groupCurrency({ groupCurrency: null }))
      }
    })
    this.entities$ = this.store.pipe(select(selectGrouperProgramEntities))
    this.selectedYearID$ = this.store.pipe(select(selectCurrentYearID))
    this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID))
    this.selectedProgramGroupIDs$ = this.store.pipe(
      select(selectGrouperProgramGroupIDs)
    )

    this.dirty$ = this.store.pipe(select(selectGrouperIsDirty))
    this.saving$ = this.store.pipe(select(selectGrouperSaving))
    this.slidesPerView$ = this.store.pipe(select(selectGrouperSlidesPerView))

    this.sharedLimitMode$ = this.store.pipe(select(selectSharedLimitMode))
    this.sharedLimitSaving$ = this.store.pipe(select(selectSharedLimitSaving))
    this.sharedLimitProperties$ = this.store.pipe(
      select(selectSharedLimitProperties)
    )
    this.sharedLimitAddLayers$ = this.store.pipe(
      select(selectSharedLimitAddLayerSelections)
    )
    this.sharedLimitLayers$ = this.store.pipe(select(selectSharedLimitLayers))

    this.sharedCurrentCurrency$ = this.store.pipe(
      select(selectCurrentAnalysisProfile),
      map(profile =>
        profile
          ? profile.exchange_rate_profile.exchange_rate_table.base_currency
          : null
      )
    )

    this.inuranceMode$ = this.store.pipe(select(selectGrouperInuranceMode))
    this.nextInuranceSymbol$ = this.store.pipe(
      select(selectNextGrouperInuranceSymbol)
    )

    this.programGroupMembers$ = this.store.pipe(
      select(selectProgramGroupMembers)
    )

    this.selectedViewID$ = combineLatest([
      this.store.pipe(select(selectSharedLimitSelectedLayer)),
      this.store.pipe(select(selectCededPortfolioViewLayersSLViewIDs)),
    ]).pipe(
      withLatestFrom(
        this.store.pipe(select(selectAllGrouperProgramCededLayerStates))
      ),
      map(([[id, viewIDMap], layerStates]) => {
        if (id) {
          const layerState = layerStates.find(l => l.layer.id === id)
          const layer = layerState ? layerState.layer : null
          if (layer?.meta_data.isRiskVisible) {
            const allLayers = layerStates.map(l => l.layer)
            const actualRiskLayer = findRiskActualLayer(allLayers, layer.id)
            if (actualRiskLayer) {
              return getSLLayerViewMetricID(
                viewIDMap,
                layerStates,
                actualRiskLayer
              )
            }
          }
          return getSLLayerViewMetricID(viewIDMap, layerStates, layer)
        }
        return null
      })
    )

    this.portfolioSetID$ = this.store.pipe(select(selectGrouperPortfolioSetID))

    this.selectedProgramID$ = this.store.pipe(select(selectCurrentStudyID))
    this.studies$ = this.store.pipe(select(selectCurrentYearStudies))
    this.accountOpportunities$ = this.store.pipe(
      select(selectAccountOpportunities)
    )
    this.currencyList$ = this.store.pipe(select(selectCurrencyList))
    this.groupCurrency$ = this.store.pipe(select(selectGroupCurrency))
  }

  onProgramGroupAdd(programGroup: ProgramGroup) {
    this.store.dispatch(
      ProgramGroupActions.addProgramGroup({ id: programGroup.id })
    )
  }

  onProgramGroupRemove(id: string) {
    this.store.dispatch(ProgramGroupActions.removeProgramGroup({ id }))
  }

  onProgramAdd(programs: Program | Program[]) {
    const ps = Array.isArray(programs) ? programs : [programs]
    ps.map(p => ({ ...p, checkGroup: false, checkGroupOcc: true })).forEach(
      program => {
        this.store.dispatch(ProgramActions.addProgramToGroup({ program }))
      }
    )
  }

  onProgramRemove(programs: Program | Program[]) {
    const ps = Array.isArray(programs) ? programs : [programs]
    ps.forEach(program => {
      this.store.dispatch(ProgramActions.removeProgramFromGroup({ program }))
    })
  }

  onSlidesPerViewChange(value: number) {
    this.store.dispatch(setGrouperSlidesPerView({ value }))
  }

  onCancelClick() {
    this.store.dispatch(SharedLimitActions.cancelSharedLimit())
    this.router.navigate(['/analysis/group'])
  }

  onSaveSharedLimitClick($event: {
    hiddenLayer: Layer
    selectedLayerIDs: SharedLimitLayerSelection[]
  }) {
    this.store.dispatch(
      SharedLimitActions.addSharedLimit({
        layer: $event.hiddenLayer,
        selectedLayerEntities: $event.selectedLayerIDs,
      })
    )
  }

  onSaveClick() {
    this.store.dispatch(saveGrouper())
  }

  onAddSharedLayerClick() {
    this.store.dispatch(SharedLimitActions.openNewSharedLimit())
  }

  onStructureFilterChange(val: string) {
    this.store.dispatch(setStructureFilter({ filter: val }))
  }

  onGroupFilterByAssocChange(value: boolean) {
    this.store.dispatch(setGroupFilterByAssoc({ groupFilterByAssoc: value }))
  }

  onExport(event: 'pdf' | 'csv') {
    if (event === 'pdf') {
      this.exportPDF()
    }
  }

  async exportPDF() {
    const doc = new jsPDF('l', 'mm', 'a4', true)
    const data = document.getElementById('groupPage')
    if (data) {
      await html2canvas(data, {
        allowTaint: true,
        imageTimeout: 15000,
        logging: true,
        useCORS: true,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        backgroundColor: '#000000',
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png')
        const pgW = 300
        const pgH = 235
        const canW = canvas.width
        const canH = canvas.height
        const marginX = 5
        const imgW = pgW - marginX * 2
        const imgH = (canH * imgW) / (canW * 0.8)
        const marginY = (pgH - imgH) / 2
        doc.setFillColor(0, 0, 0)
        doc.rect(0, 0, pgW, pgH, 'F')
        doc.addImage(imgData, 'PNG', marginX, marginY, imgW, imgH)
      })
    }
    doc.save('group-export.pdf')
  }

  getSelectedCurrency(currency: string) {
    this.store.dispatch(groupCurrency({ groupCurrency: currency }))
  }
}
