export enum CreditRoutes {
  Credit = 'credit',
  Design = 'design',
  Portfolio = 'portfolio',
  Structure = 'structure',
  Metrics = 'metrics',
  Group = 'group',
  Compare = 'compare',
  PricingCurve = 'pricingcurve',
  Tower = 'tower',
  CashFlows = 'cash-flows',
  PortfolioExploration = 'portfolio-exploration',
  AnimatedScenarios = 'animated-scenarios',
  Stratifications = 'stratifications',
  Performance = 'performance',
  Submission = 'submission',
  Monitoring = 'monitoring',
}

const STRUCTURE_PATH = `${CreditRoutes.Portfolio}/:portfolioId/${CreditRoutes.Structure}/:structureId`

export const CREDIT_PATHS = {
  Design: CreditRoutes.Design,
  EmptyTower: CreditRoutes.Tower,
  Tower: `${STRUCTURE_PATH}/${CreditRoutes.Tower}`,
  CashFlows: `${STRUCTURE_PATH}/${CreditRoutes.CashFlows}`,
  PortfolioExploration: `${STRUCTURE_PATH}/${CreditRoutes.PortfolioExploration}`,
  AnimatedScenarios: `${STRUCTURE_PATH}/${CreditRoutes.AnimatedScenarios}`,
  Stratifications: `${STRUCTURE_PATH}/${CreditRoutes.Stratifications}`,
  Performance: `${STRUCTURE_PATH}/${CreditRoutes.Performance}`,
  Group: CreditRoutes.Group,
  Compare: CreditRoutes.Compare,
  Metrics: CreditRoutes.Metrics,
  PricingCurve: CreditRoutes.PricingCurve,
  Submission: CreditRoutes.Submission,
  Monitoring: CreditRoutes.Monitoring,
}

export const CREDIT_CONTEXT_PATHS = {
  Design: [CreditRoutes.Credit, CreditRoutes.Design],
  Group: [CreditRoutes.Credit, CreditRoutes.Group],
  Compare: [CreditRoutes.Credit, CreditRoutes.Compare],
  Metrics: [CreditRoutes.Credit, CreditRoutes.Metrics],
  PricingCurve: [CreditRoutes.Credit, CreditRoutes.PricingCurve],
  Submission: [CreditRoutes.Credit, CreditRoutes.Submission],
  Monitoring: [CreditRoutes.Credit, CreditRoutes.Monitoring],
} as const

export enum CreditModuleContext {
  Design = 'DESIGN',
  Group = 'GROUP',
  Compare = 'COMPARE',
  Metrics = 'METRICS',
  PricingCurve = 'PRICING_CURVE',
  Submission = 'SUBMISSION',
  Monitoring = 'MONITORING',
}
