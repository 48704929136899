<div class="left">
  <button
    *ngIf="showAddGroupButton"
    appButton
    big
    translucent
    (click)="onSaveUntitled($event)"
  >
    <span>+ Add Group</span>
  </button>
</div>
