import { CurrencyPipe } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { scaleLinear, ScaleLinear, scaleLog, ScaleLogarithmic } from 'd3'
import * as d3 from 'd3-selection'
import { EventLayer } from '../../animated-scenarios/animated-scenarios.model'
import { isMultiSectionLayer } from '../../layers/multi-section-layer'
import {
  InuranceTag,
  InuranceTagsByID,
  InuranceView,
} from 'src/app/analysis/model/inurance.model'
import { Layer, PhysicalLayer } from 'src/app/analysis/model/layers.model'
import {
  findTDIDs,
  getMultiSectionData,
  isINewBoxAggFeeder,
  isINewBoxAggLayer,
  isLayerAgg,
  isLayerAggFeeder,
  isLayerFHCF,
  isLayerILWBin,
  isLayerILWProRata,
  isLayerRisk,
  isLayerVirtualDrop,
  isLayerVirtualTD,
} from 'src/app/analysis/model/layers.util'
import { LossSetLayer } from 'src/app/analysis/model/loss-set-layers.model'
import { PortfolioSetID } from 'src/app/analysis/model/portfolio-set.model'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import { SharedIDGroup } from 'src/app/analysis/store/grouper/program-group.model'
import { AxisHandler } from 'src/app/analysis/tower/mechanics/axis-handler'
import { BoxFactory } from 'src/app/analysis/tower/mechanics/box-factory'
import { Placement } from 'src/app/analysis/tower/mechanics/box-placement'
import {
  AggFeederProperties,
  BoxCessionX,
  BoxColor,
  CurrencyRate,
  IMoneyBounds,
  INewBox,
  MarginBounds,
  NewMappedLayer,
  TowerChartElement,
  TowerTooltipElement,
} from '../mechanics/tower.model'
import { BackendService } from '../../../api/backend/backend.service'
import { StructureLayerDataResponse } from '../../../api/model/backend.model'
import { Program } from '../../../core/model/program.model'
import { analyzereConstants } from '@shared/constants/analyzere'
import { ShortNumberPipe } from '@shared/pipes/short-number.pipe'
import { layerIds } from '../../model/layer-palette.model'
import { isNil } from 'ramda'

declare var require: any
const DEFAULT_YMAX = 10000000

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tower',
  styleUrls: ['./tower.component.scss'],
  templateUrl: './tower.component.html',
})
export class TowerComponent
  implements OnChanges, OnDestroy, OnInit, AfterViewInit
{
  @Input() portfolioIDs: PortfolioSetID | null
  @Input() highlight: string[]
  @Input() currentStructure: Program
  @Input() groupCurrency: any
  @Input() layers: LayerState[]

  @ViewChild('towerContainer') towerContainer: ElementRef

  private get isLibRE(): boolean {
    return (
      this.currentStructure?.libRE === 'T' ||
      this.currentStructure?.libRE === 'Y'
    )
  }
  private get hasNonLibREDummyLayer(): boolean {
    // Look for a non-deleted layer that doesn't have a limit of 1
    // and an attachment of 0
    return this.layers
      .filter(ls => !ls.deleted)
      .map(ls => ls.layer.physicalLayer)
      .some(ph => !(ph.limit.value === 1 && ph.attachment.value === 0))
  }
  private get libREDummyLayerId(): string | undefined {
    if (this.isLibRE) {
      // Find the first layer with limit === 1 and attachment === 0
      return this.layers
        .filter(ls => !ls.deleted)
        .map(ls => ls.layer)
        .find(
          layer =>
            layer.physicalLayer.limit.value === 1 &&
            layer.physicalLayer.attachment.value === 0
        )?.id
    } else {
      return undefined
    }
  }
  @Input() allLayers: LayerState[]
  @Input() tdLayers: LayerState[]
  @Input() hiddenLayers: LayerState[]
  @Input() selected: string
  @Input() selectedLayer: LayerState
  @Input() widthOverride?: number
  @Input() heightOverride?: number
  @Input() widthPercentage = 1
  @Input() heightPercentage = 1
  /** Number of pixels the tower component is from the top of its parent */
  @Input() offsetTop = 223
  @Input() sharedLayers?: string[]
  @Input() inuranceSource?: InuranceView | null
  @Input() inuranceTarget?: InuranceView | null
  @Input() activeAction: string
  @Input() showControls = false
  @Input() name: string
  @Input() showOcc: boolean
  @Input() showAgg: boolean
  @Input() showZoom: number
  @Input() readonly = false
  @Input() isGroup = false
  @Input() sharedIDGroup: SharedIDGroup[] = []
  /** Map from Layer ID to the corresponding Layer's Inurance views (which
   * includes the inurance terminal—source or target—and tag number to display)
   */
  @Input() inuranceTagsByLayerID: InuranceTagsByID = {}
  @Input() lossSetLayers: LossSetLayer[]

  @Output() selectedLayerID = new EventEmitter<string | null>()
  @Output() layerResize = new EventEmitter<Partial<PhysicalLayer>>()
  @Output() imageUpload = new EventEmitter<any>()
  @Output() setLayerData = new EventEmitter<Program>()
  @Output() setDirtyStructure = new EventEmitter<Program>()
  @Output() setLayer = new EventEmitter<Partial<Layer>>()
  @Output() deleteLayer = new EventEmitter<string>()
  @Output() showAggChange = new EventEmitter<boolean>()
  @Output() setSimulateTouch = new EventEmitter<boolean>()

  @Output() setTowerPropertiesInitialOcc = new EventEmitter<{
    programID: string
    maxY: number
  }>()
  @Output() setTowerPropertiesInitialAgg = new EventEmitter<{
    programID: string
    maxY: number
  }>()
  @Output() firstAggLayerAdd = new EventEmitter<AggFeederProperties>()
  @Output() inuranceTagClick = new EventEmitter<InuranceTag>()

  @Input() incrementsYOcc: number | null
  @Input() incrementsYDirtyOcc: boolean
  @Input() maxYOcc: number | null
  @Input() maxYDirtyOcc: boolean
  @Input() mostOcc: number | null

  @Input() incrementsYAgg: number | null
  @Input() incrementsYDirtyAgg: boolean
  @Input() maxYAgg: number | null
  @Input() maxYDirtyAgg: boolean
  @Input() mostAgg: number | null
  @Input() structureLayerData: StructureLayerDataResponse[]

  @Output() setIncrementsYOcc = new EventEmitter<{
    programID: string
    incrementsY: number
  }>()
  @Output() setSavedCurves = new EventEmitter()
  @Output() setInitDefaultConnectors = new EventEmitter()
  @Output() setMaxYOcc = new EventEmitter<{ programID: string; maxY: number }>()
  @Output() setMaxYOccDirty = new EventEmitter<{
    programID: string
    dirty: boolean
  }>()
  @Output() setOccIncrementDirty = new EventEmitter<{
    programID: string
    dirty: boolean
  }>()

  @Output() setMostOcc = new EventEmitter<{ programID: string; most: number }>()

  @Output() setIncrementsYAgg = new EventEmitter<{
    programID: string
    incrementsY: number
  }>()
  @Output() setMaxYAgg = new EventEmitter<{ programID: string; maxY: number }>()
  @Output() setMostAgg = new EventEmitter<{ programID: string; most: number }>()
  occurrenceAxisScale:
    | ScaleLogarithmic<number, number>
    | ScaleLinear<number, number>
  aggregateAxisScale:
    | ScaleLogarithmic<number, number>
    | ScaleLinear<number, number>
  @Input() log: boolean
  @Input() logMin: number
  @Input() snapping: boolean
  @Input() currentCurrency: string
  @Input() currencyRates: CurrencyRate[]
  @Input() analysisProfileCurrency: string
  towerState = 'onLoad'
  getLimitAttVal: any
  xAxisTicks = 6
  minTickWidth = 135
  width = 0
  height = 0
  chartWidth = 0
  chartHeight = 0
  currency: string | any
  loading = true
  drawn = false
  modifiedBox: INewBox | null = null
  svgwidth: any = 0
  svgheight: any = 0
  svgLeftMargin: any = 0
  svgTopMargin: any = 0
  existingCollisions = new Set()

  newMappedLayer: NewMappedLayer[] = []

  onTower = false
  tooltip: TowerTooltipElement | null = null

  tdIDs: string[] = []
  layerIDMap: { [key: string]: string } = {}
  feeder: { box: INewBox; cessionX: number } | null = null
  towerStartingCession: BoxCessionX = {}
  towerLayerColor: BoxColor = {}
  private chart: Record<string, TowerChartElement> = {}
  private boxFactory: { [key: string]: BoxFactory } = {}
  private axisHandler: { [key: string]: AxisHandler } = {}
  private placement = new Placement()
  private occBoxes: INewBox[] = []
  private aggBoxes: INewBox[] = []
  private boxKeySet: { [key: string]: any } = {}
  saving = false
  theBounds: IMoneyBounds = {
    least: 0,
    mostOccurrence: 0,
    mostAggregate: 0,
    scale: 0,
    delta: 0,
    shortTxt: '',
  }

  margin: MarginBounds = {
    top: 20,
    left: 40,
    right: 20,
    bottom: 50,
    chartTop: 0,
    occurrenceLeft: 0,
    aggregateLeft: 0,
  }
  // @ts-ignore
  resizeObserver: ResizeObserver

  get towerTransform() {
    return `translate(${this.svgLeftMargin},${this.svgTopMargin})`
  }

  constructor(
    private element: ElementRef,
    private currencyPipe: CurrencyPipe,
    private shortNumberPipe: ShortNumberPipe,
    private ref: ChangeDetectorRef,
    private backendService: BackendService
  ) {}

  ngOnInit(): void {
    this.setDirtyStructure.emit(this.currentStructure)
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    this.resizeObserver = new ResizeObserver(() => {
      this.drawGraph()
    })
    this.resizeObserver.observe(this.towerContainer.nativeElement)

    this.element.nativeElement.addEventListener(
      'keydown',
      (event: KeyboardEvent) => {
        if (this.onTower && !this.readonly) {
          // tslint:disable-next-line: deprecation
          if (event.keyCode === 8 || event.keyCode === 46) {
            const hidden = this.hiddenLayers.find(l => {
              if (l.layer.layerRefs.length > 0) {
                if (l.layer.layerRefs[0] === this.selected) {
                  return l
                }
              }
            })
            const aggFeeder = this.layers.find(
              l => l.layer.id === this.selected && isLayerAggFeeder(l.layer)
            )

            if (aggFeeder) {
              const aggLayers = this.layers.filter(
                l =>
                  l.layer.layerRefs.includes(aggFeeder.layer.id) &&
                  isLayerAgg(l.layer)
              )
              aggLayers.forEach(l => {
                this.deleteLayer.emit(l.layer.id)
              })
            }

            if (hidden) {
              this.deleteLayer.emit(hidden.layer.id)
            }

            for (const layerID in this.layerIDMap) {
              if (this.layerIDMap[layerID] === this.selected) {
                const selectedLayer = this.layers.find(
                  l => l.layer.id === this.selected
                )
                if (selectedLayer && isLayerAgg(selectedLayer.layer)) {
                  this.deleteLayer.emit(this.selected)
                } else {
                  if (
                    selectedLayer &&
                    (selectedLayer.layer.meta_data.sage_layer_type ===
                      layerIds.catTd ||
                      selectedLayer.layer.meta_data.sage_layer_type === 'drop')
                  ) {
                    const arrIDs = findTDIDs(
                      this.allLayers,
                      selectedLayer.layer.id
                    )
                    if (arrIDs.length === 0) {
                      this.deleteLayer.emit(this.selected)
                    } else {
                      arrIDs.forEach(id => {
                        this.deleteLayer.emit(id)
                      })
                    }
                  } else {
                    this.deleteLayer.emit(this.selected)
                  }
                }
                break
              }
            }
          }
        }
      },
      true
    )
  }

  ngOnDestroy(): void {
    this.setDirtyStructure.emit(this.currentStructure)
    this.resizeObserver.unobserve(this.towerContainer.nativeElement)
  }

  ngOnChanges(changes: SimpleChanges): void {
    let resetFlag = false
    if (
      (this.chart.occurrence && changes.log) ||
      (this.chart.occurrence && changes.logMin)
    ) {
      this.readonly = this.log
      this.drawGraph()
    }
    if (this.structureLayerData) {
      this.structureLayerData.forEach(
        (startingCession: StructureLayerDataResponse) => {
          this.towerStartingCession[startingCession.layer_id] =
            startingCession.starting_cession
          this.towerLayerColor[startingCession.layer_id] = startingCession.color
        }
      )
    }

    if (this.chart.occurrence && changes.highlight) {
      // unhighlight unselected layers
      if (changes.highlight.previousValue) {
        const unhighlightedLayers = changes.highlight.previousValue.filter(
          (element: string) => {
            let found = false
            changes.highlight.currentValue.filter((item: any) => {
              if (element === item) {
                found = true
              }
            })
            return !found
          }
        )

        unhighlightedLayers.forEach((element: string) => {
          for (const layerID in this.layerIDMap) {
            if (this.layerIDMap[layerID] === element) {
              this.boxFactory.occurrence.unhighlight(
                'LOcc' + layerID.replace('L', '')
              )
              this.boxFactory.aggregate.unhighlight(
                'LAgg' + layerID.replace('L', '')
              )
              break
            }
          }
        })
      }

      // highlight selected ones
      if (changes.highlight.currentValue) {
        changes.highlight.currentValue.forEach((element: string) => {
          for (const layerID in this.layerIDMap) {
            if (this.layerIDMap[layerID] === element) {
              this.boxFactory.occurrence.highlight(
                'LOcc' + layerID.replace('L', '')
              )
              this.boxFactory.aggregate.highlight(
                'LAgg' + layerID.replace('L', '')
              )
              break
            }
          }
        })
      }
    }

    if (
      changes.layers &&
      changes.layers.previousValue &&
      changes.layers.currentValue
    ) {
      const deletedBoxes: LayerState[] = changes.layers.previousValue.filter(
        (element: any) => {
          let found = false
          changes.layers.currentValue.filter((item: any) => {
            if (item.layer.id === element.layer.id) {
              found = true
            }
          })
          return !found
        }
      )

      const addedBoxes: LayerState[] = []
      const changedBoxes: LayerState[] = []
      changes.layers.currentValue.forEach((layer: LayerState) => {
        const oldLayer: LayerState = changes.layers.previousValue.find(
          (prevLayer: LayerState) => prevLayer.layer.id === layer.layer.id
        )
        if (isLayerAgg(layer.layer)) {
          const foundAggBoxes = this.aggBoxes.filter(
            (aggBox: INewBox) => aggBox.logicalID === layer.layer.id
          )
          if (foundAggBoxes.length > 0) {
            const aggBox: INewBox = foundAggBoxes[0]
            if (
              aggBox.attachment !==
                layer.layer.physicalLayer.aggregateAttachment.value ||
              aggBox.limit !== layer.layer.physicalLayer.aggregateLimit.value ||
              aggBox.description !== layer.layer.physicalLayer.description
            ) {
              changedBoxes.push(layer)
              if (
                aggBox.cession !==
                  Math.abs(layer.layer.physicalLayer.participation) ||
                aggBox.cessionX !== this.towerStartingCession[aggBox.id]
              ) {
                // too many changes, redraw
                resetFlag = true
              }
            } else if (
              aggBox.cession !==
                Math.abs(layer.layer.physicalLayer.participation) ||
              aggBox.cessionX !== this.towerStartingCession[aggBox.id] ||
              aggBox.layerColor !== this.towerLayerColor[aggBox.id] ||
              oldLayer.layer.physicalLayer.participation !==
                layer.layer.physicalLayer.participation
            ) {
              changedBoxes.push(layer)
            }
          } else {
            addedBoxes.push(layer)
          }
        } else {
          const foundOccBoxes = this.occBoxes.filter(
            (occBox: INewBox) => occBox.logicalID === layer.layer.id
          )
          if (foundOccBoxes.length > 0) {
            const occBox: INewBox = foundOccBoxes[0]
            if (
              (layer.layer.meta_data.sage_layer_type !== layerIds.noncatRisk &&
                occBox.attachment !==
                  layer.layer.physicalLayer.attachment.value) ||
              (layer.layer.meta_data.sage_layer_type === layerIds.noncatRisk &&
                layer.layer.physicalLayer.riskAttachment &&
                occBox.attachment !==
                  layer.layer.physicalLayer.riskAttachment.value) ||
              (layer.layer.meta_data.sage_layer_type !== layerIds.noncatRisk &&
                occBox.limit !== layer.layer.physicalLayer.limit.value) ||
              (layer.layer.meta_data.sage_layer_type === layerIds.noncatRisk &&
                layer.layer.physicalLayer.riskLimit &&
                occBox.limit !== layer.layer.physicalLayer.riskLimit.value) ||
              occBox.description !== layer.layer.physicalLayer.description ||
              occBox.franchiseDeductible !==
                layer.layer.physicalLayer.franchise.value ||
              occBox.isLimitUnlimited !==
                layer.layer.physicalLayer.meta_data.isLimitUnlimited
            ) {
              if (
                occBox.cession !==
                  Math.abs(layer.layer.physicalLayer.participation) ||
                occBox.cessionX !== this.towerStartingCession[occBox.id]
              ) {
                // too many changes, need to redraw
                resetFlag = true
              }
              if (
                layer.layer.meta_data.sage_layer_type === layerIds.catCa &&
                layer.layer.meta_data.cascadeAttachment
              ) {
                if (
                  layer.layer.meta_data.cascadeAttachment === occBox.attachment
                ) {
                  // don't push
                } else {
                  changedBoxes.push(layer)
                }
              } else {
                changedBoxes.push(layer)
              }
            } else if (
              occBox.cession !==
                Math.abs(layer.layer.physicalLayer.participation) ||
              occBox.cessionX !== this.towerStartingCession[occBox.id] ||
              occBox.layerColor !== this.towerLayerColor[occBox.id] ||
              oldLayer.layer.physicalLayer.participation !==
                layer.layer.physicalLayer.participation
            ) {
              changedBoxes.push(layer)
            }
          } else {
            addedBoxes.push(layer)
          }
        }
      })
      if (resetFlag) {
        this.drawGraph()
      } else {
        if (
          (addedBoxes.length > 0 && !this.drawn) ||
          addedBoxes.length + changedBoxes.length + deletedBoxes.length > 1
        ) {
          if (
            addedBoxes.length === 1 &&
            addedBoxes[0].layer.physicalLayer.xcoord !== -1 &&
            addedBoxes[0].layer.physicalLayer.ycoord !== -1
          ) {
            // we just need to add it
            setTimeout(() => {
              this.addLayer(addedBoxes)
            }, 0)
            setTimeout(() => {
              this.snapLeft()
            }, 0)
          } else {
            let newFound = false
            if (addedBoxes.length > 0) {
              addedBoxes.forEach(ab => {
                if (ab.new) {
                  setTimeout(() => {
                    this.addLayer(addedBoxes)
                  }, 0)
                  setTimeout(() => {
                    this.snapLeft()
                  }, 0)
                  newFound = true
                }
              })
            }
            if (!newFound) {
              this.drawGraph()
            }
          }

          this.drawn = true
        } else if (addedBoxes.length > 0) {
          setTimeout(() => {
            this.addLayer(addedBoxes)
          }, 0)
          setTimeout(() => {
            this.snapLeft()
          }, 0)
        } else if (this.modifiedBox !== null) {
          const modBoxes = this.modifiedBox
          const modifiedLayers = this.layers.filter(
            (layer: LayerState) => layer.layer.id === modBoxes.logicalID
          )
          if (modifiedLayers.length > 0) {
            setTimeout(() => {
              this.modifyDrawingWithoutManipulation(modifiedLayers, 'move')
            }, 0)
            setTimeout(() => {
              this.snapLeft()
            }, 0)
          }
          this.modifiedBox = null
        } else if (changedBoxes.length > 0) {
          setTimeout(() => {
            this.modifyDrawingWithoutManipulation(changedBoxes, 'change')
          }, 0)
        }
        deletedBoxes.forEach((layer: any) => {
          this.deleteBox(layer.layer.physicalLayer.id)
          if (!this.saving) {
            setTimeout(() => {
              this.snapLeft()
            }, 0)
          }
        })
        if (this.saving && deletedBoxes.length > 0) {
          this.saving = false
          this.drawn = false
        }
      }
    }

    if (changes.selected !== undefined) {
      this.setSelectedLayer()
    }
    if (
      changes.activeAction &&
      changes.activeAction.currentValue === 'Saving As'
    ) {
      this.drawn = false
      this.towerStartingCession = {}
      this.setDirtyStructure.emit(undefined)
    }
    if (
      changes.activeAction &&
      changes.activeAction.currentValue === 'Saving'
    ) {
      this.saveThumbnail()
      const layerData: StructureLayerDataResponse[] = []
      // tslint:disable-next-line: forin
      for (const cession in this.towerStartingCession) {
        const layerColor = this.towerLayerColor[cession]
        layerData.push({
          id: 0,
          structure_id: Number(this.currentStructure.id),
          layer_id: cession,
          starting_cession: this.towerStartingCession[cession],
          color: layerColor === undefined ? '' : layerColor,
        })
      }
      this.setLayerData.emit({
        ...this.currentStructure,
        layerData,
      })
      this.saving = true
      this.drawn = false
    }

    // Handle inurance width override
    if (
      changes.widthOverride ||
      changes.heightOverride ||
      changes.showAgg ||
      changes.showOcc ||
      changes.maxYAgg ||
      changes.maxYOcc ||
      changes.showZoom
    ) {
      setTimeout(() => {
        this.drawGraph()

        if (this.isGroup && this.sharedLayers && this.sharedLayers.length > 0) {
          this.sharedLayers.forEach((element: string) => {
            for (const layerID in this.layerIDMap) {
              if (this.layerIDMap[layerID] === element) {
                this.boxFactory.occurrence.highlight(
                  'LOcc' + layerID.replace('L', '')
                )
                this.boxFactory.aggregate.highlight(
                  'LAgg' + layerID.replace('L', '')
                )
                break
              }
            }
          })
        }
      }, 0)
    }

    if (changes.sharedLayers) {
      // unhighlight unselected layers
      if (changes.sharedLayers.previousValue) {
        const unsharedLayers = changes.sharedLayers.previousValue.filter(
          (element: string) => {
            let found = false
            changes.sharedLayers.currentValue.filter((item: any) => {
              if (element === item) {
                found = true
              }
            })
            return !found
          }
        )

        unsharedLayers.forEach((element: string) => {
          for (const layerID in this.layerIDMap) {
            if (this.layerIDMap[layerID] === element) {
              this.boxFactory.occurrence.unhighlight(
                'LOcc' + layerID.replace('L', '')
              )
              this.boxFactory.aggregate.unhighlight(
                'LAgg' + layerID.replace('L', '')
              )
              break
            }
          }
        })
      }

      // highlight selected ones
      if (changes.sharedLayers.currentValue) {
        changes.sharedLayers.currentValue.forEach((element: string) => {
          for (const layerID in this.layerIDMap) {
            if (this.layerIDMap[layerID] === element) {
              this.boxFactory.occurrence.highlight(
                'LOcc' + layerID.replace('L', '')
              )
              this.boxFactory.aggregate.highlight(
                'LAgg' + layerID.replace('L', '')
              )
              break
            }
          }
        })
      }
    }

    if (changes.inuranceSource) {
      if (changes.inuranceSource.previousValue) {
        for (const layerID in this.layerIDMap) {
          if (
            this.layerIDMap[layerID] ===
            changes.inuranceSource.previousValue.layerID
          ) {
            this.boxFactory.occurrence.unhighlight(
              'LOcc' + layerID.replace('L', '')
            )
            this.boxFactory.aggregate.unhighlight(
              'LAgg' + layerID.replace('L', '')
            )
            break
          }
        }
      }

      // highlight selected ones
      if (changes.inuranceSource.currentValue) {
        for (const layerID in this.layerIDMap) {
          if (
            this.layerIDMap[layerID] ===
            changes.inuranceSource.currentValue.layerID
          ) {
            this.boxFactory.occurrence.highlight(
              'LOcc' + layerID.replace('L', '')
            )
            this.boxFactory.aggregate.highlight(
              'LAgg' + layerID.replace('L', '')
            )
            break
          }
        }
      }
    }

    if (changes.inuranceTarget) {
      if (changes.inuranceTarget.previousValue) {
        for (const layerID in this.layerIDMap) {
          if (
            this.layerIDMap[layerID] ===
            changes.inuranceTarget.previousValue.layerID
          ) {
            this.boxFactory.occurrence.unhighlight(
              'LOcc' + layerID.replace('L', '')
            )
            this.boxFactory.aggregate.unhighlight(
              'LAgg' + layerID.replace('L', '')
            )
            break
          }
        }
      }

      // highlight selected ones
      if (changes.inuranceTarget.currentValue) {
        for (const layerID in this.layerIDMap) {
          if (
            this.layerIDMap[layerID] ===
            changes.inuranceTarget.currentValue.layerID
          ) {
            this.boxFactory.occurrence.highlight(
              'LOcc' + layerID.replace('L', '')
            )
            this.boxFactory.aggregate.highlight(
              'LAgg' + layerID.replace('L', '')
            )
            break
          }
        }
      }
    }

    // Set or clear the aggregate watermark
    if (this.aggBoxes.length > 0 && this.axisHandler.aggregate) {
      this.axisHandler.aggregate.clearWaterMark()
    } else if (this.axisHandler.aggregate) {
      this.axisHandler.aggregate.setWaterMark()
    }

    // Set or clear the occurrence watermark
    if (this.occBoxes.length > 0 && this.axisHandler.occurrence) {
      // The libre template has a hidden layer that is removed once the user drags over their
      // first layer. We still want to show the watermark when the hidden layer is the only
      // layer since the tower will look empty to the user.
      if (this.hasNonLibREDummyLayer) {
        // There is a regular layer so hide the watermark
        this.axisHandler.occurrence.clearWaterMark()

        // We need to delete the dummy layer once the first real layer gets added
        const dummyId = this.libREDummyLayerId
        if (dummyId !== undefined) {
          this.deleteLayer.emit(dummyId)
        }
      } else {
        // The hidden dummy layer is the only layer so show the watermark
        this.axisHandler.occurrence.setWaterMark()
      }
    } else if (this.axisHandler.occurrence) {
      this.axisHandler.occurrence.setWaterMark()
    }
  }

  private drawGraph(): void {
    const parent = this.element.nativeElement.offsetParent
    if (isNil(parent)) {
      return
    }

    const towerCount = this.showOcc && this.showAgg ? 2 : 1
    if (this.widthOverride) {
      this.margin.left = 30
      this.margin.right = 50
      const margins = this.margin.left + this.margin.right
      const wFull = (this.widthPercentage * this.widthOverride) / towerCount
      const width = wFull - margins - 20
      this.width = width
      this.chartWidth = width
    } else {
      const pw = parent.offsetWidth
      let w = this.widthPercentage * 0.7 * (pw / towerCount)
      w = this.getAxisZoom(w)
      this.width = w
      this.chartWidth = w
      this.margin.left = pw * 0.035
      this.margin.right = pw * 0.02
    }

    // height
    if (this.heightOverride) {
      this.margin.top = 4
      this.margin.bottom = 36
      const margins = this.margin.top + this.margin.bottom
      const height = this.heightPercentage * this.heightOverride - margins
      this.height = height
      this.chartHeight = height
    } else {
      const ph = this.heightOverride || parent.offsetHeight
      this.margin.top = ph * 0.03
      this.margin.bottom = ph * 0.08
      let h =
        this.heightPercentage * ph -
        this.offsetTop -
        this.margin.top -
        this.margin.bottom

      h = this.getAxisZoom(h)
      this.height = h
      this.chartHeight = h
    }
    this.ref.markForCheck()
    let graphHeight: number
    let graphWidth: number
    if (this.chartWidth && this.chartHeight) {
      graphWidth = this.widthOverride ? this.chartWidth : this.chartWidth + 50
      graphHeight = this.chartHeight
    } else {
      graphWidth =
        this.element.nativeElement.offsetParent.offsetWidth * 0.45 * 2
      graphHeight =
        this.element.nativeElement.offsetParent.offsetHeight * 0.45 * 2
    }
    this.svgwidth = graphWidth + this.margin.left + this.margin.right

    this.svgheight = graphHeight + this.margin.top + this.margin.bottom
    this.constructGrid()
    this.drawFirstLayers()
    this.layers.forEach(l => {
      if ((l as EventLayer).loss) {
        const eventLayer = l as EventLayer
        const layerData = this.structureLayerData.find(s =>
          s.layer_id.includes(l.layer.physicalLayer.id)
        )
        this.boxFactory.occurrence.drawLoss(
          this.occurrenceAxisScale,
          eventLayer.loss + eventLayer.layer.physicalLayer.attachment.value,
          // tslint:disable-next-line: no-non-null-assertion
          layerData ? layerData.starting_cession : 0,
          Math.abs(eventLayer.layer.physicalLayer.participation),
          isLayerAgg(eventLayer.layer)
            ? eventLayer.layer.physicalLayer.aggregateAttachment.value
            : eventLayer.layer.physicalLayer.attachment.value
        )
      }
    })
  }

  private constructGrid(): void {
    this.tooltip = d3
      .select<HTMLBodyElement, any>('body')
      .select<HTMLDivElement>('#d3Tooltip')

    this.chart.occurrence = d3
      .select<HTMLElement, {}>(this.element.nativeElement)
      .select<SVGElement>('#occurrence')
      .select<SVGGElement>('g')

    this.chart.occurrence.selectAll('*').remove()

    this.chart.aggregate = d3
      .select<HTMLElement, {}>(this.element.nativeElement)
      .select<SVGElement>('#aggregate')
      .select<SVGGElement>('g')

    this.chart.aggregate.selectAll('*').remove()

    this.svgLeftMargin = this.margin.left
    this.svgTopMargin = this.margin.top

    this.theBounds.mostOccurrence = this.height
    this.theBounds.mostAggregate = this.height
    this.theBounds.scale = 100
    this.theBounds.delta = 0
    this.theBounds.shortTxt = 'T'

    this.currency = this.currentCurrency

    if (!this.currentCurrency) {
      if (this.groupCurrency && this.groupCurrency !== 'Default') {
        this.currency = this.groupCurrency
      } else {
        this.currency = this.currentStructure.structureCurrency
      }
    }

    let xAxisTicks = 6
    if (this.width < this.minTickWidth) {
      if (this.width < this.minTickWidth / 3) {
        xAxisTicks = 1
      } else {
        xAxisTicks = 3
      }
    }
    this.axisHandler.occurrence = new AxisHandler(
      this,
      this.chart.occurrence,
      this.margin,
      'occurrenceAxis',
      this.currencyPipe,
      this.currency,
      xAxisTicks
    )
    this.axisHandler.aggregate = new AxisHandler(
      this,
      this.chart.aggregate,
      this.margin,
      'aggregateAxis',
      this.currencyPipe,
      this.currency,
      xAxisTicks
    )

    this.boxFactory.occurrence = new BoxFactory(this, this.chart.occurrence, {
      element: this.element.nativeElement,
      width: this.width,
      height: this.height,
      margin: this.margin,
      tooltip: this.tooltip,
      shortNumberPipe: this.shortNumberPipe,
      readonly: this.readonly,
      isGroup: this.isGroup,
      sharedIDGroup: this.sharedIDGroup,
      inuranceTagsByLayerID: this.inuranceTagsByLayerID,
    })

    this.boxFactory.aggregate = new BoxFactory(this, this.chart.aggregate, {
      element: this.element.nativeElement,
      width: this.width,
      height: this.height,
      margin: this.margin,
      tooltip: this.tooltip,
      shortNumberPipe: this.shortNumberPipe,
      readonly: this.readonly,
      isGroup: this.isGroup,
      sharedIDGroup: this.sharedIDGroup,
      inuranceTagsByLayerID: this.inuranceTagsByLayerID,
    })
    this.margin.chartTop = this.margin.top * 2 + this.getRelativePosition().top
    this.margin.occurrenceLeft =
      this.margin.left * 2 + this.getRelativePosition().left
    this.margin.aggregateLeft =
      this.getRelativePosition().left + this.svgwidth + this.margin.left * 2
  }

  drawFirstLayers(): void {
    this.occBoxes = []
    this.aggBoxes = []
    this.boxKeySet = {}
    this.layerIDMap = {}
    this.tdIDs = []
    const nonVirtualIDs = this.layers.filter(
      e => e.layer.meta_data.sage_layer_subtype !== 'virtual'
    )
    if (this.tdLayers !== undefined && this.tdLayers.length > 0) {
      this.tdLayers.forEach((tdLayer, _i) => {
        // tslint:disable-next-line:no-shadowed-variable
        tdLayer.layer.layerRefs.forEach((layerRef, _i) => {
          if (nonVirtualIDs.filter(e => e.layer.id === layerRef).length > 0) {
            this.tdIDs.push(layerRef)
          }
        })
      })
    }
    this.layers.forEach((a, _i) => {
      const item = {
        ...a.layer.physicalLayer,
        modified_date: a.layer.modified_date,
      }
      this.layerIDMap['L' + item.id] = a.layer.id
      if (isLayerAgg(a.layer)) {
        this.defineBox(this.aggBoxes, item, `LAgg${item.id}`, this.layers, a)
        if (item.aggregateAttachment || item.aggregateLimit) {
          const attachmentValue =
            item.meta_data.cascadeAttachment || item.aggregateAttachment.value
          const totalY = attachmentValue + item.aggregateLimit.value
          this.theBounds.mostAggregate = Math.max(
            totalY >= analyzereConstants.unlimitedValue
              ? attachmentValue > DEFAULT_YMAX
                ? attachmentValue + DEFAULT_YMAX
                : DEFAULT_YMAX
              : totalY,
            this.theBounds.mostAggregate
          )
          this.boxFactory.aggregate.top = this.getMostAggregate()
        }
      } else {
        const lossAmount = (a as EventLayer).loss

        let actualAttachment = this.defineBox(
          this.occBoxes,
          item,
          `LOcc${item.id}`,
          this.layers,
          a,
          lossAmount
        )
        if (actualAttachment || item.limit) {
          if (
            (item.meta_data.sage_layer_type === layerIds.catQs ||
              item.meta_data.sage_layer_type === layerIds.noncatQs ||
              item.meta_data.sage_layer_type === layerIds.ahlQs) &&
            item.limit.value >= analyzereConstants.unlimitedValue
          ) {
            // we're ignoring the max value
          } else if (
            item.meta_data.sage_layer_type === layerIds.noncatRisk &&
            item.riskLimit
          ) {
            const attachmentValue =
              actualAttachment ||
              (item.franchise.value > 0
                ? item.franchise.value
                : item.attachment.value)
            const totalY = attachmentValue + item.riskLimit.value
            this.theBounds.mostOccurrence = Math.max(
              totalY >= analyzereConstants.unlimitedValue
                ? attachmentValue > DEFAULT_YMAX
                  ? attachmentValue + DEFAULT_YMAX
                  : DEFAULT_YMAX
                : totalY,
              this.theBounds.mostOccurrence
            )
          } else if (
            item.meta_data.sage_layer_type === layerIds.catMultisection
          ) {
            if (this.occBoxes && this.occBoxes[0].sectionData) {
              actualAttachment = this.occBoxes[0].sectionData[0].attachment
              const contractLimit = item.meta_data.contractOccurrenceLimit
              if (contractLimit) {
                // Not not using default_ymax as it is impossible to have unlimited multisection limit
                if (contractLimit >= analyzereConstants.unlimitedValue) {
                  this.theBounds.mostOccurrence = Math.max(
                    this.occBoxes[0].sectionData[0].limit + actualAttachment,
                    this.theBounds.mostOccurrence
                  )
                } else {
                  this.theBounds.mostOccurrence = Math.max(
                    contractLimit + actualAttachment,
                    this.theBounds.mostOccurrence
                  )
                }
              }
            }
          } else {
            const attachmentValue =
              actualAttachment ||
              (item.franchise.value > 0
                ? item.franchise.value
                : item.attachment.value)
            const totalY = attachmentValue + item.limit.value
            this.theBounds.mostOccurrence = Math.max(
              totalY >= analyzereConstants.unlimitedValue
                ? attachmentValue > DEFAULT_YMAX
                  ? attachmentValue + DEFAULT_YMAX
                  : DEFAULT_YMAX
                : totalY,
              this.theBounds.mostOccurrence
            )
          }
          this.boxFactory.occurrence.top = this.getMostOccurrence()
        }
      }
    })

    let adjustedMaxOcc = this.getMostOccurrence()

    if (
      this.occBoxes.filter(l => {
        return (
          l.sage_type !== layerIds.catQs &&
          l.sage_type !== layerIds.noncatQs &&
          l.sage_type !== layerIds.ahlQs
        )
      }).length === 0 &&
      this.occBoxes.length > 0
    ) {
      if (!this.incrementsYDirtyOcc && !this.maxYDirtyOcc) {
        if (this.theBounds.mostOccurrence < Infinity) {
          adjustedMaxOcc = Math.max(adjustedMaxOcc, 100000000)
        }
      }
    }

    if (this.log) {
      this.occurrenceAxisScale = scaleLog()
        .range([this.chartHeight, 0])
        .domain([this.logMin, adjustedMaxOcc])
      // .nice()
    } else {
      this.occurrenceAxisScale = scaleLinear()
        .range([this.chartHeight, 0])
        .domain([0, adjustedMaxOcc])
        .nice()
    }
    this.theBounds.mostOccurrence = this.axisHandler.occurrence.makeAxises(
      this.occurrenceAxisScale,
      this.readonly,
      'Occ',
      this.isGroup
    )
    this.boxFactory.occurrence.top = this.getMostOccurrence()
    this.boxFactory.occurrence.increment = this.incrementsYOcc

    const feeder = this.occBoxes.find(item => isINewBoxAggFeeder(item))
    if (
      feeder &&
      feeder.limit * 3 > this.getMostAggregate() &&
      !this.maxYDirtyAgg
    ) {
      if (this.log) {
        this.aggregateAxisScale = scaleLog()
          .range([this.chartHeight, 0])
          .domain([this.logMin, feeder.limit * 3])
        // .nice()
      } else {
        this.aggregateAxisScale = scaleLinear()
          .range([this.chartHeight, 0])
          .domain([0, feeder.limit * 3])
          .nice()
      }
      this.theBounds.mostAggregate = this.axisHandler.aggregate.makeAxises(
        this.aggregateAxisScale,
        this.readonly,
        'Agg',
        this.isGroup
      )
    } else {
      if (this.log) {
        this.aggregateAxisScale = scaleLog()
          .range([this.chartHeight, 0])
          .domain([this.logMin, this.getMostAggregate()])
        // .nice()
      } else {
        this.aggregateAxisScale = scaleLinear()
          .range([this.chartHeight, 0])
          .domain([0, this.getMostAggregate()])
          .nice()
      }
      this.theBounds.mostAggregate = this.axisHandler.aggregate.makeAxises(
        this.aggregateAxisScale,
        this.readonly,
        'Agg',
        this.isGroup
      )
    }

    this.boxFactory.aggregate.top = this.getMostAggregate()
    this.boxFactory.aggregate.increment = this.incrementsYAgg

    if (
      !this.incrementsYDirtyOcc &&
      this.portfolioIDs &&
      this.layers.length > 0
    ) {
      this.setIncrementsYOcc.emit({
        programID: this.currentStructure.id,
        incrementsY: this.theBounds.mostOccurrence * 0.025,
      })
    }
    this.setSavedCurves.emit()

    this.setInitDefaultConnectors.emit()
    if (!this.maxYDirtyOcc && this.portfolioIDs && this.layers.length > 0) {
      this.setMaxYOcc.emit({
        programID: this.currentStructure.id,
        maxY: this.theBounds.mostOccurrence,
      })
    }
    if (this.portfolioIDs && this.layers.length > 0) {
      this.setMostOcc.emit({
        programID: this.currentStructure.id,
        most: this.theBounds.mostOccurrence,
      })
    }

    if (feeder && this.portfolioIDs && this.layers.length > 0) {
      if (feeder.limit * 3 > this.getMostAggregate()) {
        if (!this.incrementsYDirtyAgg) {
          this.setIncrementsYAgg.emit({
            programID: this.currentStructure.id,
            incrementsY: feeder.limit * 3 * 0.025,
          })
        }

        if (!this.maxYDirtyAgg) {
          this.setMaxYAgg.emit({
            programID: this.currentStructure.id,
            maxY: feeder.limit * 3,
          })

          this.setMostAgg.emit({
            programID: this.currentStructure.id,
            most: feeder.limit * 3,
          })
        }
      } else {
        if (!this.incrementsYDirtyAgg) {
          this.setIncrementsYAgg.emit({
            programID: this.currentStructure.id,
            incrementsY: this.theBounds.mostAggregate * 0.025,
          })
        }

        if (!this.maxYDirtyAgg && this.portfolioIDs && this.layers.length > 0) {
          this.setMaxYAgg.emit({
            programID: this.currentStructure.id,
            maxY: this.theBounds.mostAggregate,
          })
        }
        if (this.portfolioIDs && this.layers.length > 0) {
          this.setMostAgg.emit({
            programID: this.currentStructure.id,
            most: this.theBounds.mostAggregate,
          })
        }
      }
    }

    // handle occurrence

    if (Object.keys(this.towerStartingCession).length > 0) {
      this.occBoxes.forEach(item => {
        if (this.towerStartingCession[item.id] !== undefined) {
          item.cessionX = this.towerStartingCession[item.id]
        } else {
          item.cessionX = 0
        }
      })
    }

    this.occBoxes = this.occBoxes
      .filter(box => !(box.attachment === 0 && box.limit === 1))
      .slice()
      .sort((a, b) => {
        return a.cessionX - b.cessionX
      })

    let processedBoxes: INewBox[] = []

    this.occBoxes.forEach(item => {
      // calculate layer currency to structure currency0
      if (this.currentStructure.libRE !== 'Y') {
        if (this.currency?.trim() !== item.currency?.trim()) {
          if (
            this.currencyRates &&
            this.currency &&
            this.analysisProfileCurrency
          ) {
            const structureCurrencyRate = this.currencyRates.find(
              (res: CurrencyRate) => res.Currency === this.currency
            )?.Rate
            const AnalysisProfileCurrencyRate = this.currencyRates.find(
              (res: CurrencyRate) =>
                res.Currency === this.analysisProfileCurrency
            )?.Rate
            this.currencyRates.map((x: CurrencyRate) => {
              if (
                structureCurrencyRate &&
                AnalysisProfileCurrencyRate &&
                x.Currency.toLowerCase().trim() ===
                  item.currency?.toLowerCase().trim()
              ) {
                // Rule for conversion - Convert the attachment into the analysis profile currency, then convert the attachment into structure currency
                item.convertedAttachment =
                  (item.attachment * x.Rate) / structureCurrencyRate
                item.convertedLimit =
                  (item.limit * x.Rate) / structureCurrencyRate
              }
            })
          }
        }
      }

      this.placement.setYAxisValues(item, this.height, this.theBounds)
      if (this.towerStartingCession[item.id] === undefined) {
        this.placement.setInitialX(item, this.occBoxes, this.chartWidth)
        this.towerStartingCession[item.id] = item.cessionX
      } else {
        item.x = item.cessionX * this.chartWidth
        if (item.limit === 0) {
          item.cessionX = this.towerStartingCession[item.id]
        } else {
          this.towerStartingCession[item.id] = item.cessionX
        }
      }
      if (item.y < 0) {
        item.y = 0
        const virtualLimit =
          this.theBounds.mostOccurrence -
          (item.franchiseDeductible > 0
            ? item.franchiseDeductible
            : item.attachment)
        item.boxHeight =
          this.chartHeight * (virtualLimit / this.theBounds.mostOccurrence)
      }
      processedBoxes.forEach((processedBox: INewBox) => {
        if (
          !(item.attachment === 0 && item.limit === 1) &&
          this.placement.clash(processedBox, item)
        ) {
          this.existingCollisions.add(item.id)
          this.existingCollisions.add(processedBox.id)
        }
      })
      processedBoxes.push(item)
      if (
        item.sage_type === layerIds.catAg ||
        item.sage_type === layerIds.noncatAg ||
        item.sage_type === layerIds.ahlAg
      ) {
        const feederCession = this.feeder
          ? this.feeder.cessionX
          : this.towerStartingCession[item.id]
        this.feeder = {
          box: item,
          cessionX: feederCession,
        }
        item.cessionX = feederCession
      }
    })

    if (Object.keys(this.towerStartingCession).length > 0) {
      this.aggBoxes.forEach(item => {
        item.cessionX =
          this.towerStartingCession[item.id] !== undefined
            ? this.towerStartingCession[item.id]
            : 0
      })
    }

    this.aggBoxes = this.aggBoxes.slice().sort((a, b) => {
      return a.cessionX - b.cessionX
    })

    processedBoxes = []
    this.aggBoxes.forEach(item => {
      // calculate layer currency to structure currency
      if (this.currentStructure.libRE !== 'Y') {
        if (this.currency !== item.currency) {
          if (
            this.currencyRates &&
            this.currency &&
            this.analysisProfileCurrency
          ) {
            const structureCurrencyRate = this.currencyRates.find(
              (res: CurrencyRate) => res.Currency === this.currency
            )?.Rate
            const AnalysisProfileCurrencyRate = this.currencyRates.find(
              (res: CurrencyRate) =>
                res.Currency === this.analysisProfileCurrency
            )?.Rate
            this.currencyRates.map((x: CurrencyRate) => {
              if (
                structureCurrencyRate &&
                AnalysisProfileCurrencyRate &&
                x.Currency.toLowerCase().trim() ===
                  item.currency?.toLowerCase().trim()
              ) {
                item.convertedAttachment =
                  (item.attachment * x.Rate) / structureCurrencyRate
                item.convertedLimit =
                  (item.limit * x.Rate) / structureCurrencyRate
              }
            })
          }
        }
      }
      this.placement.setYAxisValues(item, this.height, this.theBounds)
      if (this.towerStartingCession[item.id] === undefined) {
        this.placement.setInitialX(item, this.aggBoxes, this.chartWidth)
        this.towerStartingCession[item.id] = item.cessionX
      } else {
        item.x = item.cessionX * this.chartWidth
        this.towerStartingCession[item.id] = item.cessionX
      }
      if (item.y < 0) {
        item.y = 0
        const virtualLimit =
          this.getMostAggregate() -
          (item.franchiseDeductible > 0
            ? item.franchiseDeductible
            : item.attachment)
        item.boxHeight =
          this.chartHeight * (virtualLimit / this.getMostAggregate())
      }
      processedBoxes.forEach((processedBox: INewBox) => {
        if (this.placement.clash(processedBox, item)) {
          this.existingCollisions.add(item.id)
          this.existingCollisions.add(processedBox.id)
        }
      })
      processedBoxes.push(item)
    })

    if (this.showOcc) {
      this.createBoxes(this.occBoxes, false)
    }
    if (this.showAgg) {
      this.createBoxes(this.aggBoxes, false)
    }
    this.emitStartingCession()
    this.loading = false
    if (this.portfolioIDs && this.layers.length > 0) {
      this.backendService
        .checkPortfolioThumbnail(
          this.portfolioIDs.analysisProfileID +
            '-' +
            this.portfolioIDs.cededPortfolioID +
            '-' +
            this.portfolioIDs.netPortfolioID +
            '-' +
            this.portfolioIDs.grossPortfolioID +
            '-occurrence'
        )
        .subscribe((response: any) => {
          if (response.error !== undefined) {
            this.saveThumbnail()
          }
        })
    }
    this.setSelectedLayer()
    if (
      this.currentStructure.layerData &&
      this.currentStructure.layerData.length === 0 &&
      (this.occBoxes.length > 0 || this.aggBoxes.length > 0)
    ) {
      const layerData: StructureLayerDataResponse[] = []
      // tslint:disable-next-line: forin
      for (const cession in this.towerStartingCession) {
        const layerColor = this.towerLayerColor[cession]
        layerData.push({
          id: 0,
          structure_id: Number(this.currentStructure.id),
          layer_id: cession,
          starting_cession: this.towerStartingCession[cession],
          color: layerColor === undefined ? '' : layerColor,
        })
      }
      this.setLayerData.emit({
        ...this.currentStructure,
        layerData,
      })
    }
  }

  modifyDrawingWithoutManipulation(
    modifiedLayers: LayerState[],
    state: string
  ): void {
    let maxY = 0
    modifiedLayers.forEach((modifiedLayer: any) => {
      let item = {
        ...modifiedLayer.layer.physicalLayer,
        modified_date: modifiedLayer.layer.modified_date,
      }
      d3.select<HTMLElement, {}>(this.element.nativeElement)
        .selectAll('#' + 'LOcc' + item.id)
        .remove()
      d3.select<HTMLElement, {}>(this.element.nativeElement)
        .selectAll('#' + 'LAgg' + item.id)
        .remove()
      delete this.boxKeySet[item.id]
      const modifiedBoxes: INewBox[] = []
      this.defineBox(
        modifiedBoxes,
        item,
        (isLayerAgg(modifiedLayer.layer) ? 'LAgg' : 'LOcc') + item.id,
        this.layers,
        modifiedLayer
      )
      item = modifiedBoxes[0]
      this.convertLayerToStructureCurrency(item, state)
      this.placement.setYAxisValues(item, this.height, this.theBounds)
      item.cessionX = this.towerStartingCession[item.id]
      item.x = item.cessionX * this.chartWidth
      if (item.y < 0) {
        item.y = 0
        const virtualLimit =
          (isLayerAgg(modifiedLayer.layer)
            ? this.getMostAggregate()
            : this.getMostOccurrence()) -
          (item.franchiseDeductible > 0
            ? item.franchiseDeductible
            : item.attachment)
        item.boxHeight =
          this.chartHeight *
          (virtualLimit /
            (isLayerAgg(modifiedLayer.layer)
              ? this.getMostAggregate()
              : this.getMostOccurrence()))
      }
      if (item.cession + item.cessionX > 1) {
        const originalParticipation = item.participation
        if (item.participation !== undefined && item.participation < 0) {
          item.participation = item.cession * -1
        } else {
          item.participation = item.cession
        }
        d3.select<HTMLElement, {}>(this.element.nativeElement)
          .selectAll('#' + item.id)
          .remove()
        item.x = item.cessionX * this.chartWidth
        item.boxWidth = this.width * (1 - item.cessionX)
        this.createBoxes([item], false)
        setTimeout(() => {
          if (item.participation !== originalParticipation) {
            // Debounce: Multisection would cause infinite update loop in certain tower configurations
            this.layerResize.emit({
              logicalLayerID: item.logicalID,
              participation: item.participation,
            })
          }
        })
      }
      const processedBoxes = isLayerAgg(modifiedLayer.layer)
        ? this.placement.getOccupied(this.aggBoxes, item)
        : this.placement.getOccupied(this.occBoxes, item)
      processedBoxes.forEach((processedBox: INewBox) => {
        if (this.placement.clash(processedBox, item)) {
          this.existingCollisions.add(item.id)
          this.existingCollisions.add(processedBox.id)
        }
      })
      this.createBoxes(modifiedBoxes, false)
      const limitVal =
        item.limit +
        (item.franchiseDeductible > 0
          ? item.franchiseDeductible
          : item.attachment)
      if (limitVal > maxY) {
        if (limitVal >= analyzereConstants.unlimitedValue) {
          const attachY =
            (item.franchiseDeductible > 0
              ? item.franchiseDeductible
              : item.attachment) + DEFAULT_YMAX
          const currentYMax =
            attachY > this.theBounds.mostOccurrence
              ? attachY
              : this.theBounds.mostOccurrence
          maxY =
            maxY > 0
              ? maxY
              : this.allLayers.length === 1
              ? currentYMax > DEFAULT_YMAX
                ? currentYMax
                : DEFAULT_YMAX
              : currentYMax
        } else {
          maxY =
            item.limit +
            (item.franchiseDeductible > 0
              ? item.franchiseDeductible
              : item.attachment)
        }
      }
      if (isLayerAggFeeder(modifiedLayer.layer)) {
        this.feeder = {
          box: item,
          cessionX: item.cessionX,
        }
        const aggLayers = this.layers.filter(
          l =>
            l.layer.layerRefs.includes(modifiedLayer.layer.id) &&
            isLayerAgg(l.layer)
        )
        aggLayers.forEach(l => {
          setTimeout(() => {
            this.layerResize.emit({
              logicalLayerID: l.layer.id,
              attachment: {
                value: 0,
                currency: this.currency,
              },
              limit: {
                value: analyzereConstants.unlimitedValue,
                currency: this.currency,
              },
            })
          }, 0)
        })
        if (item.limit * 3 > this.getMostAggregate() && !this.maxYDirtyAgg) {
          this.setMaxYAgg.emit({
            // tslint:disable-next-line: no-non-null-assertion
            programID: this.currentStructure.id,
            maxY: item.limit * 3,
          })

          this.setIncrementsYAgg.emit({
            // tslint:disable-next-line: no-non-null-assertion
            programID: this.currentStructure.id,
            incrementsY: item.limit * 3 * 0.025,
          })
        }
      }
      if (isLayerAgg(modifiedLayer.layer)) {
        const boxIndex = this.aggBoxes.findIndex(
          (aggBox: INewBox) =>
            aggBox.logicalID ===
            modifiedLayer.layer.physicalLayer.logicalLayerID
        )
        this.aggBoxes[boxIndex] = item
      } else {
        const boxIndex = this.occBoxes.findIndex(
          (occBox: INewBox) =>
            occBox.logicalID ===
            modifiedLayer.layer.physicalLayer.logicalLayerID
        )
        this.occBoxes[boxIndex] = item
      }
    })
    if (
      maxY >
      (isLayerAgg(modifiedLayers[0].layer)
        ? this.theBounds.mostAggregate
        : this.theBounds.mostOccurrence)
    ) {
      if (isLayerAgg(modifiedLayers[0].layer)) {
        if (!this.maxYDirtyAgg && this.portfolioIDs) {
          this.setMaxYAgg.emit({
            programID: this.currentStructure.id,
            maxY,
          })
        }
      } else if (!this.maxYDirtyOcc && this.portfolioIDs) {
        this.setMaxYOcc.emit({
          programID: this.currentStructure.id,
          maxY,
        })
      }
    }
    this.setSelectedLayer()
  }

  modifyDrawing(modifiedLayers: LayerState[]): void {
    let otherLayers: INewBox[] = []
    if (isLayerAgg(modifiedLayers[0].layer)) {
      otherLayers = this.aggBoxes
    } else {
      otherLayers = this.occBoxes
    }
    otherLayers = otherLayers.filter(
      (box: INewBox) => box.logicalID !== modifiedLayers[0].layer.id
    )
    modifiedLayers = modifiedLayers.slice().sort((a, b) => {
      return (
        this.towerStartingCession[a.layer.physicalLayer.id] -
        this.towerStartingCession[b.layer.physicalLayer.id]
      )
    })
    let maxY = 0
    modifiedLayers.forEach((modifiedLayer: any) => {
      let item = {
        ...modifiedLayer.layer.physicalLayer,
        modified_date: modifiedLayer.layer.modified_date,
      }
      d3.select<HTMLElement, {}>(this.element.nativeElement)
        .selectAll('#' + 'LOcc' + item.id)
        .remove()

      d3.select<HTMLElement, {}>(this.element.nativeElement)
        .selectAll('#' + 'LAgg' + item.id)
        .remove()
      delete this.boxKeySet[item.id]
      const modifiedBoxes: INewBox[] = []
      this.defineBox(
        modifiedBoxes,
        item,
        (isLayerAgg(modifiedLayer.layer) ? 'LAgg' : 'LOcc') + item.id,
        this.layers,
        modifiedLayer
      )
      item = modifiedBoxes[0]
      this.placement.setYAxisValues(item, this.height, this.theBounds)
      item.cessionX = this.towerStartingCession[item.id]
      item.x = item.cessionX * this.chartWidth
      if (isLayerAgg(modifiedLayer.layer) && item.limit === 0) {
        item.cession = 0
        this.deleteLayer.emit(modifiedLayer.layer.id)
      } else {
        if (item.y < 0) {
          item.y = 0
          const virtualLimit =
            (isLayerAgg(modifiedLayer.layer)
              ? this.getMostAggregate()
              : this.getMostOccurrence()) -
            (item.franchiseDeductible > 0
              ? item.franchiseDeductible
              : item.attachment)
          item.boxHeight =
            this.chartHeight *
            (virtualLimit /
              (isLayerAgg(modifiedLayer.layer)
                ? this.getMostAggregate()
                : this.getMostOccurrence()))
        }
      }
      if (isLayerAggFeeder(modifiedLayer.layer)) {
        this.feeder = {
          box: item,
          cessionX: item.cessionX,
        }
        const aggLayers = this.layers.filter(
          l =>
            l.layer.layerRefs.includes(modifiedLayer.layer.id) &&
            isLayerAgg(l.layer)
        )
        aggLayers.forEach(l => {
          setTimeout(() => {
            this.layerResize.emit({
              logicalLayerID: l.layer.id,
              attachment: {
                value: 0,
                currency: this.currency,
              },
              limit: {
                value: analyzereConstants.unlimitedValue,
                currency: this.currency,
              },
            })
          }, 0)
        })
        if (item.limit * 3 > this.getMostAggregate() && !this.maxYDirtyAgg) {
          this.setMaxYAgg.emit({
            programID: this.currentStructure.id,
            maxY: item.limit * 3,
          })

          this.setIncrementsYAgg.emit({
            programID: this.currentStructure.id,
            incrementsY: item.limit * 3 * 0.025,
          })
        }
      }
      this.placement.snapLeft(item, otherLayers)
      this.placement.setYAxisValues(item, this.height, this.theBounds)
      item.x = item.cessionX * this.chartWidth
      this.towerStartingCession[item.id] = item.cessionX
      this.createBoxes(modifiedBoxes, false)
      this.setSelectedLayer()
      this.emitStartingCession()
      if (
        item.limit +
          (item.franchiseDeductible > 0
            ? item.franchiseDeductible
            : item.attachment) >
        maxY
      ) {
        maxY =
          item.limit +
          (item.franchiseDeductible > 0
            ? item.franchiseDeductible
            : item.attachment)
      }
    })
    if (
      maxY >
      (isLayerAgg(modifiedLayers[0].layer)
        ? this.theBounds.mostAggregate
        : this.theBounds.mostOccurrence)
    ) {
      if (isLayerAgg(modifiedLayers[0].layer)) {
        if (!this.maxYDirtyAgg && this.portfolioIDs) {
          this.setMaxYAgg.emit({
            programID: this.currentStructure.id,
            maxY,
          })
        }
      } else if (!this.maxYDirtyOcc && this.portfolioIDs) {
        this.setMaxYOcc.emit({
          programID: this.currentStructure.id,
          maxY,
        })
      }
    }
  }

  emitStartingCession(): void {
    if (this.currentStructure) {
      const layerData: StructureLayerDataResponse[] = []
      // tslint:disable-next-line: forin
      for (const cession in this.towerStartingCession) {
        layerData.push({
          id: 0,
          structure_id: Number(this.currentStructure.id),
          layer_id: cession,
          starting_cession: this.towerStartingCession[cession],
          color: this.towerLayerColor[cession],
        })
      }
      this.setDirtyStructure.emit({
        ...this.currentStructure,
        layerData,
      })
    }
  }

  snapLeft(): void {
    this.occBoxes = this.occBoxes.slice().sort((a, b) => {
      return a.cessionX - b.cessionX
    })

    this.aggBoxes = this.aggBoxes.slice().sort((a, b) => {
      return a.cessionX - b.cessionX
    })

    this.existingCollisions.clear()

    this.occBoxes.forEach((occBox: INewBox) => {
      const otherBoxes = this.placement.getOccupied(this.occBoxes, occBox)
      otherBoxes.forEach((otherBox: INewBox) => {
        if (this.placement.clash(otherBox, occBox)) {
          this.existingCollisions.add(occBox.id)
          this.existingCollisions.add(otherBox.id)
        }
      })
    })

    this.aggBoxes.forEach((aggBox: INewBox) => {
      const otherBoxes = this.placement.getOccupied(this.aggBoxes, aggBox)
      otherBoxes.forEach((otherBox: INewBox) => {
        if (this.placement.clash(otherBox, aggBox)) {
          this.existingCollisions.add(aggBox.id)
          this.existingCollisions.add(otherBox.id)
        }
      })
    })

    this.aggBoxes.forEach(aggBox => {
      if (!this.existingCollisions.has(aggBox.id)) {
        const oldCessionX = aggBox.cessionX
        this.placement.snapLeft(
          aggBox,
          this.placement.getOccupied(this.aggBoxes, aggBox)
        )
        if (oldCessionX !== aggBox.cessionX) {
          if (aggBox.cessionX + aggBox.cession > 1) {
            aggBox.cessionX = oldCessionX
          } else {
            this.towerStartingCession[aggBox.id] = aggBox.cessionX
            d3.select<HTMLElement, {}>(this.element.nativeElement)
              .selectAll('#' + aggBox.id)
              .remove()
            aggBox.x = aggBox.cessionX * this.chartWidth
            this.createBoxes([aggBox], false)
          }
        }
      }
    })
    this.occBoxes.forEach(occBox => {
      if (!this.existingCollisions.has(occBox.id)) {
        const oldCessionX = occBox.cessionX
        this.placement.snapLeft(
          occBox,
          this.placement.getOccupied(this.occBoxes, occBox)
        )
        if (oldCessionX !== occBox.cessionX) {
          if (occBox.cessionX + occBox.cession > 1) {
            occBox.cessionX = oldCessionX
          } else {
            this.towerStartingCession[occBox.id] = occBox.cessionX
            d3.select<HTMLElement, {}>(this.element.nativeElement)
              .selectAll('#' + occBox.id)
              .remove()
            occBox.x = occBox.cessionX * this.chartWidth
            this.createBoxes([occBox], false)
          }
        }
      }
    })
    this.emitStartingCession()
    this.setSelectedLayer()
  }

  addLayer(newLayer: any): void {
    if (isLayerAgg(newLayer[0].layer) && this.aggBoxes.length === 1) {
      // remove duplicate with first agg box
      this.deleteBox(newLayer[0].layer.physicalLayer.id)
    }
    delete this.boxKeySet[newLayer[0].layer.physicalLayer.id]
    let item = {
      ...newLayer[0].layer.physicalLayer,
      modified_date: newLayer[0].layer.modified_date,
    }
    this.layerIDMap['L' + item.id] = newLayer[0].layer.id
    const newBoxes: INewBox[] = []
    let overrideLimit = false
    if (item.xcoord === -1 && item.ycoord === -1) {
      // this is a reset
      this.drawGraph()
    } else {
      let absY = item.ycoord - 2 * this.margin.top
      // TODO: replace with angular viewchild
      const occurrenceElement: HTMLElement | null =
        document.getElementById('occurrence')
      if (occurrenceElement !== null) {
        absY -= occurrenceElement.getBoundingClientRect().top
      }
      const absX =
        item.xcoord -
        (isLayerAgg(newLayer[0].layer)
          ? this.margin.aggregateLeft
          : this.margin.occurrenceLeft)

      this.defineBox(
        newBoxes,
        item,
        (isLayerAgg(newLayer[0].layer) ? 'LAgg' : 'LOcc') + item.id,
        this.layers,
        newLayer[0]
      )
      item = newBoxes[0]

      if (
        isLayerAgg(newLayer[0].layer) &&
        this.aggBoxes.length === 0 &&
        this.feeder !== null
      ) {
        item.limit = this.feeder.box.limit * 2
        item.attachment = 0

        item.cessionX = 0
        item.cession = 0.25
        item.participation = -0.25
        this.towerStartingCession[item.id] = 0
      } else {
        item.x = absX
        item.y = absY
        if (item.isDrop) {
          const topLayer = this.layers.find(
            l => l.layer.id === newLayer[0].layer.meta_data.topID
          )
          item.limit = topLayer?.layer.physicalLayer.limit.value ?? 0
          item.attachment = 0
        } else {
          item.attachment =
            ((this.height - item.y) / this.height) *
            (isLayerAgg(newLayer[0].layer)
              ? this.getMostAggregate()
              : this.getMostOccurrence())
          item.limit =
            0.25 *
            (isLayerAgg(newLayer[0].layer)
              ? this.getMostAggregate()
              : this.getMostOccurrence())
        }
        item.x = absX
        item.y = absY
        if (
          newBoxes[0].sage_type === layerIds.catQs ||
          newBoxes[0].sage_type === layerIds.noncatQs ||
          newBoxes[0].sage_type === layerIds.ahlQs
        ) {
          newBoxes[0].attachment = 0
          item.y = this.chartHeight
          if (
            this.occBoxes.length === 0 &&
            this.portfolioIDs &&
            !this.incrementsYDirtyOcc
          ) {
            overrideLimit = true
            this.setMaxYOcc.emit({
              programID: this.currentStructure.id,
              maxY: 100000000,
            })

            this.setIncrementsYOcc.emit({
              programID: this.currentStructure.id,
              incrementsY: 1000000,
            })
            this.setSavedCurves.emit()
            this.setInitDefaultConnectors.emit()

            this.setMaxYOccDirty.emit({
              programID: this.currentStructure.id,
              dirty: true,
            })

            this.setOccIncrementDirty.emit({
              programID: this.currentStructure.id,
              dirty: true,
            })
          }
        }

        this.towerStartingCession[item.id] = this.mathCalculateCessionX(item)
        this.placement.setYAxisValues(item, this.height, this.theBounds)
        item.attachment = this.calculateRoundedAttachmentValue(item)
        item.limit = this.calculateRoundedLimitValue(item, 0)
        if (
          item.sage_type === layerIds.ilwBin ||
          item.sage_type === layerIds.ilwProRata
        ) {
          item.limit = DEFAULT_YMAX
        }
        if (overrideLimit) {
          item.limit = 0.25 * 100000000
        }
        // rounded, must reset again
        this.placement.setYAxisValues(item, this.height, this.theBounds)
        item.cession = isLayerFHCF(newLayer[0].layer)
          ? -0.9
          : isLayerRisk(newLayer[0].layer)
          ? 1.0
          : isLayerILWBin(newLayer[0].layer) ||
            isLayerILWProRata(newLayer[0].layer)
          ? -1
          : 0.25
        if (isLayerAggFeeder(newLayer[0].layer)) {
          item.participation = 0.25
        } else if (isLayerFHCF(newLayer[0].layer)) {
          item.participation = -0.9
        } else if (isLayerRisk(newLayer[0].layer)) {
          item.participation = 1.0
        } else if (
          isLayerVirtualTD(newLayer[0].layer) ||
          isLayerVirtualDrop(newLayer[0].layer)
        ) {
          item.participation = -0.25
        } else if (
          isLayerILWBin(newLayer[0].layer) ||
          isLayerILWProRata(newLayer[0].layer)
        ) {
          item.participation = -1
        } else {
          item.participation = -0.25
        }

        if (!item.isDrop) {
          item.cessionX = this.towerStartingCession[item.id]
          this.placement.trim(
            isLayerAgg(newLayer[0].layer) ? this.aggBoxes : this.occBoxes,
            item
          )
          // trimmed, must set Y again
          this.placement.setYAxisValues(item, this.height, this.theBounds)
          this.placement.snapLeft(
            item,
            this.placement.getOccupied(
              isLayerAgg(newLayer[0].layer) ? this.aggBoxes : this.occBoxes,
              item
            )
          )
        }
        this.towerStartingCession[item.id] = item.cessionX
        // check if there are any rules broken
        if (
          item.limit <= 0 ||
          item.cessionX + item.cession > 1 ||
          absX < 0 ||
          absX > this.chartWidth ||
          absY < 0 ||
          absY > this.chartHeight ||
          item.boxHeight <= 0 ||
          item.boxWidth <= 0
        ) {
          if (!item.isDrop) {
            // throw it to the top
            let maxDollar = 0
            if (isLayerAgg(newLayer[0].layer)) {
              // get the highest agg
              this.aggBoxes.forEach(aggBox => {
                maxDollar = Math.max(
                  maxDollar,
                  aggBox.attachment + aggBox.limit
                )
              })
            } else {
              this.occBoxes.forEach(occBox => {
                if (
                  (occBox.sage_type === layerIds.catQs ||
                    occBox.sage_type === layerIds.noncatQs ||
                    occBox.sage_type === layerIds.catMultisection ||
                    occBox.sage_type === layerIds.noncatMultisection) &&
                  occBox.limit >= analyzereConstants.unlimitedValue
                ) {
                  // we're ignoring the max value
                  maxDollar = this.theBounds.mostOccurrence
                } else {
                  maxDollar = Math.max(
                    maxDollar,
                    occBox.attachment + occBox.limit
                  )
                }
              })
            }
            item.attachment = maxDollar
            item.limit =
              0.25 *
              (isLayerAgg(newLayer[0].layer)
                ? this.getMostAggregate()
                : this.getMostOccurrence())
            item.cessionX = 0
            item.cession = 0.25
            this.placement.setYAxisValues(item, this.height, this.theBounds)
          }
        }
        item.x = item.cessionX * this.chartWidth
        item.cessionX = Number(item.cessionX.toFixed(4))
        this.towerStartingCession[item.id] = item.cessionX
        item.boxHeight =
          this.chartHeight *
          (item.limit /
            (isLayerAgg(newLayer[0].layer)
              ? this.getMostAggregate()
              : this.getMostOccurrence()))
        if (
          isLayerAggFeeder(newLayer[0].layer) ||
          isLayerFHCF(newLayer[0].layer) ||
          isLayerRisk(newLayer[0].layer) ||
          isLayerILWBin(newLayer[0].layer) ||
          isLayerILWProRata(newLayer[0].layer)
        ) {
          item.participation = item.cession
        } else if (
          !isLayerVirtualTD(newLayer[0].layer) &&
          !isLayerVirtualDrop(newLayer[0].layer)
        ) {
          item.participation = item.cession * -1
        }
        item.boxWidth = this.width * item.cession
      }

      this.placement.setYAxisValues(item, this.height, this.theBounds)

      if (item.y < 0) {
        item.y = 0
        const virtualLimit =
          (isLayerAgg(newLayer[0].layer)
            ? this.getMostAggregate()
            : this.getMostOccurrence()) - item.attachment
        item.boxHeight =
          this.chartHeight *
          (virtualLimit /
            (isLayerAgg(newLayer[0].layer)
              ? this.getMostAggregate()
              : this.getMostOccurrence()))
      }
      // tslint:disable-next-line: no-non-null-assertion
      const physicalLayer = this.layers.find(
        l => l.layer.id === item.logicalID
      )!.layer.physicalLayer

      if (isLayerAgg(newLayer[0].layer)) {
        this.aggBoxes.push(item)
        setTimeout(() => {
          this.layerResize.emit({
            logicalLayerID: item.logicalID,
            aggregateAttachment: {
              value: item.attachment,
              currency: this.currency,
            },
            aggregateLimit: {
              value: item.limit,
              currency: this.currency,
            },
            attachment: {
              value: 0,
              currency: this.currency,
            },
            limit: {
              value: analyzereConstants.unlimitedValue,
              currency: this.currency,
            },
            participation: item.participation,
          })
        }, 0)
      } else {
        this.occBoxes.push(item)
        setTimeout(() => {
          let riskLimit
          let riskAttachment
          let limit = {
            ...physicalLayer.limit,
            value: item.limit,
          }
          let attachment = {
            ...physicalLayer.attachment,
            value: item.attachment,
          }
          if (item.sage_type === layerIds.catAg && item.subtype === 'feeder') {
            limit = {
              ...physicalLayer.limit,
              value:
                physicalLayer.limit.value !== 0
                  ? physicalLayer.limit.value
                  : item.limit,
            }
            attachment = {
              ...physicalLayer.attachment,
              value:
                physicalLayer.attachment.value !== 0
                  ? physicalLayer.attachment.value
                  : item.attachment,
            }
          }
          if (item.sage_type === layerIds.noncatRisk) {
            riskLimit = { ...physicalLayer.limit, value: item.limit }
            riskAttachment = {
              ...physicalLayer.attachment,
              value: item.attachment,
            }
            attachment = {
              ...physicalLayer.attachment,
              value: 0,
            }
            limit = {
              ...physicalLayer.limit,
              value: analyzereConstants.unlimitedValue,
            }
          }
          let participation
          if (item.sage_type === layerIds.topAndDrop) {
            participation =
              physicalLayer.participation !== 0
                ? Math.abs(physicalLayer.participation) * -1
                : item.participation
          } else {
            participation =
              physicalLayer.participation !== 0
                ? physicalLayer.participation
                : item.participation
          }
          this.layerResize.emit({
            logicalLayerID: item.logicalID,
            attachment,
            // Default New Layer Aggregate Limit to this.unlimitedValue (unlimited) for
            // all new Layers except for Aggregate Layers
            aggregateLimit: {
              value:
                item.sage_type === 'drop'
                  ? physicalLayer.aggregateLimit.value
                  : analyzereConstants.unlimitedValue,
              currency: this.currency,
            },
            limit,
            participation,
            riskLimit,
            riskAttachment,
          })
        }, 0)
        if (
          (item.sage_type === layerIds.catAg ||
            item.sage_type === layerIds.noncatAg ||
            item.sage_type === layerIds.ahlAg) &&
          this.aggBoxes.length === 0
        ) {
          this.feeder = {
            box: item,
            cessionX: item.cessionX,
          }
          const aggFeeder = this.layers.find(l => isLayerAggFeeder(l.layer))
          if (aggFeeder !== undefined && this.portfolioIDs) {
            this.firstAggLayerAdd.emit({
              feeder_layer: aggFeeder,
              layers: this.layers,
              lossSetLayers: this.lossSetLayers,
              structureID: this.currentStructure.id,
            })
            if (!this.maxYDirtyOcc) {
              this.setIncrementsYAgg.emit({
                programID: this.currentStructure.id,
                incrementsY: item.limit * 3 * 0.025,
              })
              this.setMaxYAgg.emit({
                programID: this.currentStructure.id,
                maxY: item.limit * 3,
              })
            }
          }
        }
      }
      if (
        item.attachment + item.limit >
        (isLayerAgg(newLayer[0].layer)
          ? this.theBounds.mostAggregate
          : this.theBounds.mostOccurrence)
      ) {
        if (isLayerAgg(newLayer[0].layer)) {
          if (!this.maxYDirtyAgg && this.portfolioIDs) {
            setTimeout(() => {
              if (this.portfolioIDs) {
                this.setMaxYAgg.emit({
                  programID: this.currentStructure.id,
                  maxY:
                    item.attachment + item.limit >=
                    analyzereConstants.unlimitedValue
                      ? item.attachment > DEFAULT_YMAX
                        ? item.attachment + DEFAULT_YMAX
                        : DEFAULT_YMAX
                      : item.attachment + item.limit,
                })
              }
            }, 0)
          }
        } else if (!this.maxYDirtyOcc && this.portfolioIDs) {
          setTimeout(() => {
            if (this.portfolioIDs) {
              this.setMaxYOcc.emit({
                programID: this.currentStructure.id,
                maxY:
                  item.attachment + item.limit >=
                  analyzereConstants.unlimitedValue
                    ? item.attachment > DEFAULT_YMAX
                      ? item.attachment + DEFAULT_YMAX
                      : DEFAULT_YMAX
                    : item.attachment + item.limit,
              })
            }
          }, 0)
        }
      }
      if (item.isDrop) {
        this.placement
          .getOccupied(this.occBoxes, item)
          .forEach((processedBox: INewBox) => {
            if (this.placement.clash(processedBox, item)) {
              this.existingCollisions.add(item.id)
              this.existingCollisions.add(processedBox.id)
            }
          })
      }
      this.emitStartingCession()
      this.createBoxes(newBoxes, false)
    }

    // We need to delete the dummy layer once the first real layer gets added
    const id = this.libREDummyLayerId
    if (id !== undefined) {
      this.deleteLayer.emit(id)
    }
  }

  private setSelectedLayer(): void {
    // set the border
    this.boxFactory.occurrence?.clearBorder()
    this.boxFactory.aggregate?.clearBorder()
    if (this.selected) {
      for (const layerID in this.layerIDMap) {
        if (this.layerIDMap[layerID] === this.selected) {
          const occID = 'LOcc' + layerID.replace('L', '')
          const aggID = 'LAgg' + layerID.replace('L', '')
          this.chart.occurrence
            .select('#' + occID)
            .raise()
            .classed('active', true)
          this.boxFactory.occurrence.setBorder(occID)

          this.chart.aggregate
            .select('#' + aggID)
            .raise()
            .classed('active', true)
          this.boxFactory.aggregate.setBorder(aggID)
        }
      }
    }
  }

  private createBoxes(boxes: INewBox[], opacity: boolean): void {
    boxes.forEach(item => {
      if (this.isOccurrence(item.id)) {
        // to enable resizing on log as well, we'd need to do this
        // and the inverse on calculation
        if (this.log) {
          const topY = this.occurrenceAxisScale(
            item.limit +
              (item.franchiseDeductible > 0
                ? item.franchiseDeductible
                : item.attachment)
          )
          let bottomY = this.chartHeight
          if (
            (item.franchiseDeductible > 0
              ? item.franchiseDeductible
              : item.attachment) > 0
          ) {
            bottomY = this.occurrenceAxisScale(
              item.franchiseDeductible > 0
                ? item.franchiseDeductible
                : item.attachment
            )
          }
          item.y = topY
          item.boxHeight = bottomY - topY
        }
        // Don't draw the box if isLibRE is true and the box height <= 1
        if (!this.isLibRE || item.limit !== 1 || item.attachment !== 0) {
          this.boxFactory.occurrence.create(
            item,
            this.width,
            this.height,
            opacity
          )
        }
      } else {
        // to enable resizing on log as well, we'd need to do this
        // and the inverse on calculation
        if (this.log) {
          const topY = this.aggregateAxisScale(item.limit + item.attachment)
          let bottomY = this.chartHeight
          if (item.attachment > 0) {
            bottomY = this.aggregateAxisScale(item.attachment)
          }
          item.y = topY
          item.boxHeight = bottomY - topY
        }

        this.boxFactory.aggregate.create(item, this.width, this.height, opacity)
      }
      this.towerStartingCession[item.id] = item.cessionX
    })
  }

  getMultiSectionLayerA(layerID: string): LayerState | undefined {
    const layerRefs: string[] =
      this.allLayers.find(l => l.layer.meta_data.visible_layer_id === layerID)
        ?.layer.layerRefs || []
    return this.allLayers?.find(l => l.layer.id === layerRefs[0])
  }

  private defineBox(
    boxes: INewBox[],
    item: PhysicalLayer,
    letter: string,
    layerStates: LayerState[],
    layerState: LayerState,
    lossAmount?: number
  ): number {
    if (
      this.boxKeySet.hasOwnProperty(item.id) &&
      this.boxKeySet[item.id] === letter
    ) {
      return 0
    } else {
      this.boxKeySet[item.id] = letter
      const cession = Math.abs(item.participation)
      let actualAttachment = 0
      let actualLimit: number
      if (this.isOccurrence(letter)) {
        actualAttachment = item.attachment ? item.attachment.value : 0
        actualLimit = item.limit ? item.limit.value : 0
        // adjust limit if layer is multisection
        if (item.meta_data.sage_layer_type === layerIds.catMultisection) {
          const sectionA = this.getMultiSectionLayerA(layerState.layer.id)
          actualLimit = item.meta_data.contractOccurrenceLimit || actualLimit
          actualAttachment =
            sectionA?.layer.physicalLayer.attachment.value || actualAttachment
          if (actualLimit >= analyzereConstants.unlimitedValue) {
            actualLimit =
              sectionA?.layer.physicalLayer.limit.value || actualLimit
          }
        }
        // adjust attachment if it's a cascade
        else if (item.meta_data.sage_layer_type === layerIds.catCa) {
          // tslint:disable-next-line:no-shadowed-variable
          layerStates.forEach(layer => {
            if (layer.layer.id === item.logicalLayerID) {
              actualAttachment = layer.layer.meta_data.cascadeAttachment
                ? layer.layer.meta_data.cascadeAttachment
                : actualAttachment
            }
          })
        }
        if (
          item.meta_data.sage_layer_type === layerIds.noncatRisk &&
          item.riskAttachment &&
          item.riskLimit
        ) {
          actualAttachment = item.riskAttachment.value
          actualLimit = item.riskLimit.value
        }
      } else {
        actualAttachment = item.aggregateAttachment.value
        actualLimit = item.aggregateLimit.value
      }
      const isDrop = item.meta_data.isDrop
      const obj: INewBox = {
        franchiseDeductible: item.franchise.value,
        modified_date:
          item.modified_date !== undefined ? item.modified_date : '0',
        logicalID: item.logicalLayerID,
        _type: item.description || '',
        description: item.description || '',
        attachment: actualAttachment,
        limit: actualLimit,
        cession,
        cessionX:
          this.towerStartingCession[item.id] !== undefined
            ? this.towerStartingCession[item.id]
            : 0,
        participation: item.participation,
        x: 0,
        y: 0,
        boxWidth: cession > 1 ? this.width : this.width * cession,
        boxHeight: 0,
        id: letter,
        label: item.id,
        focus: false,
        sage_type: item.meta_data.sage_layer_type || '',
        subtype: item.meta_data.sage_layer_subtype,
        sharedLayerID: layerState.layer.sharedLayerID,
        isDrop,
        layerColor: this.towerLayerColor[letter],
        lossAmount,
        isLimitUnlimited: item.meta_data.isLimitUnlimited,
        currency: layerState.layer.currency,
        convertedAttachment: 0,
        convertedLimit: 0,
        aggregateAttachment: item.aggregateAttachment.value,
        aggregateLimit: item.aggregateLimit.value,
        contractOccurrenceLimit: item.meta_data.contractOccurrenceLimit,
      }
      if (isMultiSectionLayer(layerState.layer, 'visible-layer')) {
        const layers = this.allLayers
          .map(ls => ls.layer)
          .filter(layer => isMultiSectionLayer(layer))
        obj.sectionData = getMultiSectionData(layers, layerState.layer)
      }

      boxes.push(obj)

      if (isLayerAggFeeder(layerState.layer) && obj.franchiseDeductible > 0) {
        return obj.franchiseDeductible
      } else {
        return actualAttachment
      }
    }
  }

  private emitUpdates(item: INewBox): void {
    let selectedLayer = item.id.replace('LOcc', '')
    selectedLayer = 'L' + selectedLayer.replace('LAgg', '')
    this.selectedLayerID.emit(this.layerIDMap[selectedLayer])
    // tslint:disable-next-line: no-non-null-assertion
    const physicalLayer = this.layers.find(l => l.layer.id === item.logicalID)!
      .layer.physicalLayer
    if (this.isOccurrence(item.id)) {
      if (item.sage_type === layerIds.catCa) {
        const layer = this.layers.find(l => l.layer.id === item.logicalID)
        if (layer) {
          this.setLayer.emit({
            id: item.logicalID,
            meta_data: {
              ...layer.layer.meta_data,
              cascadeAttachment: item.attachment,
            },
            physicalLayer: {
              ...layer.layer.physicalLayer,
              attachment: {
                ...physicalLayer.attachment,
                value: item.attachment,
              },
              limit: { ...physicalLayer.limit, value: item.limit },
              participation: item.participation,
              meta_data: {
                ...physicalLayer.meta_data,
                cascadeAttachment: item.attachment,
              },
            },
          })
        }
      } else if (item.sage_type === layerIds.noncatRisk) {
        setTimeout(() => {
          this.layerResize.emit({
            logicalLayerID: item.logicalID,
            riskAttachment: {
              ...physicalLayer.attachment,
              value: item.attachment,
            },
            riskLimit: { ...physicalLayer.limit, value: item.limit },
            participation: item.participation,
            franchise: {
              ...physicalLayer.franchise,
              value: item.franchiseDeductible,
            },
          })
        }, 0)
      } else if (item.sage_type === layerIds.catMultisection) {
        setTimeout(() => {
          if (
            item.contractOccurrenceLimit &&
            item.contractOccurrenceLimit >= analyzereConstants.unlimitedValue) {
            item.limit = item.contractOccurrenceLimit
          }
          this.layerResize.emit({
            logicalLayerID: item.logicalID,
            attachment: {
              ...physicalLayer.attachment,
              value: item.attachment,
            },
            limit: { ...physicalLayer.limit, value: item.limit },
            participation: item.participation,
            franchise: {
              ...physicalLayer.franchise,
              value: item.franchiseDeductible,
            },
            meta_data: {
              ...physicalLayer.meta_data,
              contractOccurrenceLimit: item.limit,
            },
          })
        }, 0)
      } else {
        setTimeout(() => {
          this.layerResize.emit({
            logicalLayerID: item.logicalID,
            attachment: {
              ...physicalLayer.attachment,
              value: item.attachment,
            },
            limit: { ...physicalLayer.limit, value: item.limit },
            participation: item.participation,
            franchise: {
              ...physicalLayer.franchise,
              value: item.franchiseDeductible,
            },
          })
        }, 0)
      }
    } else {
      setTimeout(() => {
        this.layerResize.emit({
          logicalLayerID: item.logicalID,
          aggregateAttachment: {
            ...physicalLayer.attachment,
            value: item.attachment,
          },
          aggregateLimit: { ...physicalLayer.limit, value: item.limit },
          participation: item.participation,
        })
      }, 0)
    }
  }

  updateBox(d: INewBox, position: string, oldItem: INewBox): void {
    let boxes = this.aggBoxes
    if (this.isOccurrence(d.id)) {
      boxes = this.occBoxes
    }
    boxes.forEach((item, _i) => {
      if (item.id === d.id) {
        if (item.sage_type === layerIds.catMultisection) {
          if (
            item.contractOccurrenceLimit &&
            item.contractOccurrenceLimit >= analyzereConstants.unlimitedValue
          ) {
            item.limit = oldItem.limit
          }
        }
        item._type = d._type
        item.boxWidth = d.boxWidth
        item.x = d.x
        oldItem.cessionX = this.towerStartingCession[item.id]
        this.towerStartingCession[item.id] = this.mathCalculateCessionX(d)
        item.cessionX = this.towerStartingCession[item.id]
        const originalMaxDollar =
          (item.franchiseDeductible > 0
            ? item.franchiseDeductible
            : item.attachment) + item.limit
        const originalMinDollar =
          item.franchiseDeductible > 0
            ? item.franchiseDeductible
            : item.attachment
        if (position !== 'snapRight' && position !== 'snapLeft') {
          const money = this.mathCalculateLimit(d, 0)
          const a = this.mathCalculateAttachment(d)
          if (item.franchiseDeductible > 0) {
            item.franchiseDeductible = a
          } else {
            item.attachment = a
          }
          item.limit = money
        }
        const ces = this.mathCalculateCession(d)
        item.y = d.y
        item.boxHeight = d.boxHeight
        item.cession = ces
        item._type = d._type
        item.dragY = d.dragY
        let collision = false
        if (this.snapping) {
          collision = this.placement.overlap(
            boxes,
            item,
            this.placement.getOccupied(boxes, item),
            oldItem,
            position
          )
        }
        this.towerStartingCession[item.id] = item.cessionX
        if (!collision && this.calculateRoundedLimitValue(item, 0) > 0) {
          const originalAttachment =
            item.franchiseDeductible > 0
              ? item.franchiseDeductible
              : item.attachment
          const originalLimit = item.limit
          if (position === 'bottom') {
            if (item.franchiseDeductible > 0) {
              item.franchiseDeductible = this.calculateRoundedAttachmentValue(
                item,
                true
              )
            } else {
              item.attachment = this.calculateRoundedAttachmentValue(item)
            }
            item.limit =
              originalMaxDollar -
              (item.franchiseDeductible > 0
                ? item.franchiseDeductible
                : item.attachment)
          } else if (position === 'top') {
            item.limit = this.calculateRoundedLimitValue(item, 0)
            if (item.franchiseDeductible > 0) {
              item.franchiseDeductible = originalMinDollar
            } else {
              item.attachment = originalMinDollar
            }
          } else if (position === 'new') {
            if (item.franchiseDeductible > 0) {
              item.franchiseDeductible = this.calculateRoundedAttachmentValue(
                item,
                true
              )
            } else {
              item.attachment = this.calculateRoundedAttachmentValue(item)
            }
            item.limit = oldItem.limit
          }
          // check to see if there's now a collission after rounding
          if (
            this.snapping &&
            this.placement.overlap(
              boxes,
              item,
              this.placement.getOccupied(boxes, item),
              oldItem,
              position
            )
          ) {
            if (item.franchiseDeductible > 0) {
              item.franchiseDeductible = originalAttachment
            } else {
              item.attachment = originalAttachment
            }
            item.limit = originalLimit
          } else {
            this.existingCollisions.delete(item.id)
          }
        } else if (collision && this.existingCollisions.has(item.id)) {
          item.limit = oldItem.limit
          if (item.franchiseDeductible > 0) {
            item.franchiseDeductible = oldItem.franchiseDeductible
          } else {
            item.attachment = oldItem.attachment
          }
          item.cession = oldItem.cession
          item.cessionX = oldItem.cessionX
          this.towerStartingCession[item.id] = item.cessionX
        }
        if (this.isOccurrence(item.id)) {
          this.boxFactory.occurrence.increment = this.incrementsYOcc
        } else {
          this.boxFactory.aggregate.increment = this.incrementsYAgg
        }
        if (item.participation !== undefined && item.participation < 0) {
          item.participation = Math.abs(item.cession) * -1
        } else {
          item.participation = Math.abs(item.cession)
        }
        if (isINewBoxAggFeeder(item)) {
          item.participation = Math.abs(item.participation)
        }
        if (isINewBoxAggLayer(item)) {
          item.participation = Math.abs(item.participation) * -1
        }
        item.cession = Math.abs(item.participation)
        this.placement.setYAxisValues(item, this.height, this.theBounds)
        item.x = item.cessionX * this.chartWidth
        item.boxWidth = this.width * item.cession
        if (item.isLimitUnlimited) {
          item.limit = analyzereConstants.unlimitedValue
        }
        this.modifiedBox = item
        this.emitStartingCession()
        let limitChange = false
        if (item.sage_type === layerIds.catMultisection) {
          const sectionA = this.getMultiSectionLayerA(item.logicalID)
          // Let Dragging drives section A if occ limit is unlimited
          if (sectionA) {
            if (oldItem.attachment !== item.attachment) {
              setTimeout(() => {
                this.layerResize.emit({
                  ...sectionA.layer.physicalLayer,
                  attachment: {
                    ...sectionA.layer.physicalLayer.attachment,
                    value: item.attachment,
                  },
                })
              }, 0)
            }
            const layer = this.allLayers.find(
              l => l.layer.id === item.logicalID
            )
            if (
              layer &&
              (layer.layer.physicalLayer.meta_data.contractOccurrenceLimit ||
                0) >= analyzereConstants.unlimitedValue
            ) {
              if (oldItem.limit !== item.limit) {
                limitChange = true
                setTimeout(() => {
                  this.layerResize.emit({
                    ...sectionA.layer.physicalLayer,
                    attachment: {
                      ...sectionA.layer.physicalLayer.attachment,
                      value: item.attachment,
                    },
                    limit: {
                      ...sectionA.layer.physicalLayer.limit,
                      value: item.limit,
                    },
                  })
                }, 0)
              }
            }
          }
        }
        if (!limitChange) {
          this.emitUpdates(item)
        }
      }
    })
  }

  deleteBox(layerID: string): void {
    delete this.boxKeySet[layerID.replace('L', '')]
    delete this.layerIDMap[layerID]
    delete this.towerStartingCession['LOcc' + layerID]
    delete this.towerStartingCession['LAgg' + layerID]
    this.existingCollisions.delete('LOcc' + layerID)
    this.existingCollisions.delete('LAgg' + layerID)

    d3.select<HTMLElement, {}>(this.element.nativeElement)
      .selectAll('#LOcc' + layerID.replace('L', ''))
      .remove()
    this.occBoxes.forEach((item, index) => {
      if (layerID.replace('L', '') === item.label) {
        this.occBoxes.splice(index, 1)
      }
    })

    d3.select<HTMLElement, {}>(this.element.nativeElement)
      .selectAll('#LAgg' + layerID.replace('L', ''))
      .remove()
    this.aggBoxes.forEach((item, index) => {
      if (layerID.replace('L', '') === item.label) {
        this.aggBoxes.splice(index, 1)
      }
    })
  }

  mathCalculateCession(box: INewBox): number {
    let bWidth = box.boxWidth
    const width = this.width * 0.005
    if (bWidth + box.x >= this.width) {
      bWidth = this.width - box.x
    } else if (Number((bWidth % width).toFixed(4)) !== 0) {
      if (Math.ceil(bWidth / width) * width + box.x <= this.width) {
        bWidth = Math.ceil(bWidth / width) * width
      } else {
        bWidth = Math.floor(bWidth / width) * width
      }
    }
    return box.cession > 1
      ? Number(((bWidth * box.cession) / this.width).toFixed(4))
      : Number((bWidth / this.width).toFixed(4))
  }

  mathCalculateCessionX(box: INewBox): number {
    let bWidth = box.x
    const width = this.width * 0.005
    if (Number((bWidth % width).toFixed(4)) !== 0) {
      if (Math.ceil(bWidth / width) * width <= this.width) {
        bWidth = Math.ceil(bWidth / width) * width
      } else {
        bWidth = Math.floor(bWidth / width) * width
      }
    }
    return Number((bWidth / this.width).toFixed(4))
  }

  private mathCalculateLimit(box: INewBox, extraY: number): number {
    if (box.isDrop) {
      return box.limit
    }
    let extraLimit = 0
    if (box.dragY !== undefined && box.dragY >= extraY) {
      extraLimit = box.dragY - extraY
    }
    const limitVirtualY = this.chartHeight - (this.chartHeight - box.boxHeight)
    const extraPercent = extraLimit / this.chartHeight
    const percent = limitVirtualY / this.chartHeight + extraPercent
    let money = 0
    if (this.isOccurrence(box.id)) {
      money = this.getMostOccurrence() * percent
    } else {
      money = this.getMostAggregate() * percent
    }
    return Math.round(money)
  }
  private mathCalculateAttachment(box: INewBox): number {
    const limitAttachmentY = this.chartHeight - (box.boxHeight + box.y)
    const percent = limitAttachmentY / this.chartHeight
    let money = 0
    if (this.isOccurrence(box.id)) {
      money = this.getMostOccurrence() * percent
    } else {
      money = this.getMostAggregate() * percent // 139, 351
    }
    return Math.round(money)
  }

  calculateRoundedAttachmentValue(box: INewBox, deductible?: boolean): number {
    let attachment = this.mathCalculateAttachment(box)
    const ogAttachment = this.layers.find(
      l =>
        l.layer.physicalLayer.id === box.label || l.layer.id === box.logicalID
    )?.layer.physicalLayer.attachment.value
    if (attachment === ogAttachment && !deductible) {
      return ogAttachment
    }
    if (
      this.isOccurrence(box.id) &&
      this.incrementsYOcc !== null &&
      this.incrementsYOcc !== undefined
    ) {
      attachment =
        Math.round(attachment / this.incrementsYOcc) * this.incrementsYOcc
    } else if (
      this.incrementsYAgg !== null &&
      this.incrementsYAgg !== undefined
    ) {
      attachment =
        Math.round(attachment / this.incrementsYAgg) * this.incrementsYAgg
    }
    return attachment
  }

  getRoundedAttachment(box: INewBox): number {
    return this.shortNumberPipe.transform(
      this.calculateRoundedAttachmentValue(box),
      this.currency,
      false
    )
  }

  calculateRoundedLimitValue(box: INewBox, dragY: number): number {
    let limit = this.mathCalculateLimit(box, dragY)
    if (
      this.isOccurrence(box.id) &&
      this.incrementsYOcc !== null &&
      this.incrementsYOcc !== undefined &&
      !box.isDrop
    ) {
      limit = Math.round(limit / this.incrementsYOcc) * this.incrementsYOcc
    } else if (
      this.incrementsYAgg !== null &&
      this.incrementsYAgg !== undefined
    ) {
      const newLimit =
        Math.round(limit / this.incrementsYAgg) * this.incrementsYAgg
      if (!(newLimit === 0 && limit !== 0)) {
        limit = newLimit
      }
    }
    return limit
  }

  getRoundedLimit(box: INewBox, dragY: number): number {
    return this.shortNumberPipe.transform(
      this.calculateRoundedLimitValue(box, dragY),
      this.currency,
      false
    )
  }

  private isOccurrence(id: string): boolean {
    return id ? id.startsWith('LOcc') : false
  }

  private getMostOccurrence(): number {
    if (!this.maxYOcc) {
      return this.theBounds.mostOccurrence
    } else {
      if (this.maxYDirtyOcc) {
        return this.maxYOcc
      } else {
        return this.theBounds.mostOccurrence
      }
    }
  }

  private getMostAggregate(): number {
    if (!this.maxYAgg) {
      return this.theBounds.mostAggregate
    } else {
      if (this.maxYDirtyAgg) {
        return this.maxYAgg
      } else {
        return this.theBounds.mostAggregate
      }
    }
  }

  private getRelativePosition(): { top: number; left: number } {
    let el = this.element.nativeElement
    let x = 0
    let y = 0
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft
      y += el.offsetTop - el.scrollTop
      el = el.offsetParent
    }
    return {
      top:
        y -
        (document.getElementsByTagName('app-tower-container')[0] === undefined
          ? 0
          : document.getElementsByTagName('app-tower-container')[0].scrollTop),
      left: x,
    }
  }

  private saveThumbnail(): void {
    const saveAsPNG = require('svg-png-nocache')
    saveAsPNG.svgAsPngUri(
      d3.select(this.element.nativeElement).select('#occurrence').node(),
      {
        modifyCss: (selector: string, properties: string) => {
          if (
            selector.startsWith('.app-tower-y-axis') &&
            (selector.endsWith('line') ||
              selector.endsWith('path') ||
              selector.endsWith('text'))
          ) {
            return selector + '{' + 'stroke: #8B8680' + '}'
          } else if (
            selector.startsWith('.app-tower-x-axis') &&
            (selector.endsWith('line') ||
              selector.endsWith('path') ||
              selector.endsWith('text'))
          ) {
            return selector + '{' + 'stroke: #8B8680' + '}'
          }
          return selector + '{' + properties + '}'
        },
      },
      (occuri: any) => {
        saveAsPNG.svgAsPngUri(
          d3.select(this.element.nativeElement).select('#aggregate').node(),
          {
            modifyCss: (selector: string, properties: string) => {
              if (
                selector.startsWith('.app-tower-y-axis') &&
                (selector.endsWith('line') ||
                  selector.endsWith('path') ||
                  selector.endsWith('text'))
              ) {
                return selector + '{' + 'stroke: #8B8680' + '}'
              } else if (
                selector.startsWith('.app-tower-x-axis') &&
                (selector.endsWith('line') ||
                  selector.endsWith('path') ||
                  selector.endsWith('text'))
              ) {
                return selector + '{' + 'stroke: #8B8680' + '}'
              }
              return selector + '{' + properties + '}'
            },
          },
          (agguri: any) => {
            this.imageUpload.emit({
              occblob: this.dataURItoBlob(occuri),
              aggblob: this.dataURItoBlob(agguri),
            })
          }
        )
      }
    )
  }

  private dataURItoBlob(dataURI: any): Blob {
    let byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1])
    } else {
      byteString = unescape(dataURI.split(',')[1])
    }
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ia], { type: mimeString })
  }

  clearSelectedItem(): void {
    this.selectedLayerID.emit(null)
  }

  onInuranceTagClick(
    logicalID: string,
    inuranceTag: InuranceTag | undefined
  ): void {
    if (!inuranceTag) {
      throw Error('No inurance tag on tag click.')
    }
    if (!logicalID) {
      throw Error('No layer ID on tag click.')
    }
    this.inuranceTagClick.emit({ ...inuranceTag, layerID: logicalID })
  }

  onShowAggChange(event: MatCheckboxChange): void {
    this.showAggChange.emit(event.checked)
  }

  getAxisZoom(length: number): number {
    if (this.isGroup) {
      return length
    }
    return length * this.showZoom
  }

  convertLayerToStructureCurrency(item: any, state: string): void {
    // calculate layer currency to structure currency
    if (this.currency !== item.currency) {
      if (this.currencyRates && this.currency && this.analysisProfileCurrency) {
        const structureCurrencyRate = this.currencyRates.find(
          (res: CurrencyRate) => res.Currency === this.currency
        )?.Rate
        const AnalysisProfileCurrencyRate = this.currencyRates.find(
          (res: CurrencyRate) => res.Currency === this.analysisProfileCurrency
        )?.Rate
        this.currencyRates.map((x: CurrencyRate) => {
          if (
            structureCurrencyRate &&
            AnalysisProfileCurrencyRate &&
            x.Currency.toLowerCase().trim() ===
              item.currency?.toLowerCase().trim()
          ) {
            if (state === 'change' && this.isOccurrence(item.id)) {
              item.convertedAttachment =
                (item.attachment * x.Rate) / structureCurrencyRate
              item.convertedLimit =
                (item.limit * x.Rate) / structureCurrencyRate
            }
            if (state === 'change' && !this.isOccurrence(item.id)) {
              item.convertedAttachment =
                (item.aggregateAttachment * x.Rate) / structureCurrencyRate
              item.convertedLimit =
                (item.aggregateLimit * x.Rate) / structureCurrencyRate
            }
            if (item.limit === 1e21) {
              item.convertedLimit =
                (this.theBounds.mostOccurrence * x.Rate) / structureCurrencyRate
              item.convertedAttachment = item.attachment
              if (this.isOccurrence(item.id)) {
                setTimeout(() => {
                  this.layerResize.emit({
                    logicalLayerID: item.logicalID,
                    limit: {
                      value: item.convertedLimit - item.convertedAttachment,
                      currency: item.currency,
                    },
                  })
                }, 0)
              } else {
                setTimeout(() => {
                  this.layerResize.emit({
                    logicalLayerID: item.logicalID,
                    aggregateLimit: {
                      value: item.convertedLimit - item.convertedAttachment,
                      currency: item.currency,
                    },
                  })
                }, 0)
              }
              this.towerState = 'onLoad'
            }
            if (this.towerState.trim() === 'dragEndTop') {
              item.convertedLimit =
                (this.getLimitAttVal.limit * x.Rate) / structureCurrencyRate
              item.convertedAttachment = item.attachment
              if (this.isOccurrence(item.id)) {
                setTimeout(() => {
                  this.layerResize.emit({
                    logicalLayerID: item.logicalID,
                    limit: {
                      value: item.convertedLimit,
                      currency: item.currency,
                    },
                  })
                }, 0)
              } else {
                setTimeout(() => {
                  this.layerResize.emit({
                    logicalLayerID: item.logicalID,
                    aggregateLimit: {
                      value: item.convertedLimit,
                      currency: item.currency,
                    },
                  })
                }, 0)
              }
              this.towerState = 'onLoad'
            }
            if (this.towerState.trim() === 'dragEndBottom') {
              const check =
                ((this.getLimitAttVal.limit + this.getLimitAttVal.attachment) *
                  x.Rate) /
                structureCurrencyRate

              item.convertedAttachment =
                (this.getLimitAttVal.attachment * x.Rate) /
                structureCurrencyRate

              item.convertedLimit = check - item.convertedAttachment
              if (this.isOccurrence(item.id)) {
                setTimeout(() => {
                  this.layerResize.emit({
                    logicalLayerID: item.logicalID,
                    limit: {
                      value: item.convertedLimit,
                      currency: item.currency,
                    },
                    attachment: {
                      value: item.convertedAttachment,
                      currency: item.currency,
                    },
                  })
                }, 0)
              } else {
                setTimeout(() => {
                  this.layerResize.emit({
                    logicalLayerID: item.logicalID,
                    aggregateLimit: {
                      value: item.convertedLimit,
                      currency: item.currency,
                    },
                    aggregateAttachment: {
                      value: item.convertedAttachment,
                      currency: item.currency,
                    },
                  })
                }, 0)
              }
              this.towerState = 'onLoad'
            }
            if (this.towerState.trim() === 'dragEndMove') {
              item.convertedAttachment =
                (this.getLimitAttVal.attachment * x.Rate) /
                structureCurrencyRate
              item.convertedLimit = this.getLimitAttVal.limit
              if (this.isOccurrence(item.id)) {
                setTimeout(() => {
                  this.layerResize.emit({
                    logicalLayerID: item.logicalID,
                    attachment: {
                      value: item.convertedAttachment,
                      currency: item.currency,
                    },
                  })
                }, 0)
              } else {
                setTimeout(() => {
                  this.layerResize.emit({
                    logicalLayerID: item.logicalID,
                    aggregateAttachment: {
                      value: item.convertedAttachment,
                      currency: item.currency,
                    },
                  })
                }, 0)
              }
              this.towerState = 'onLoad'
            }
            if (this.towerState.trim() === 'dragEndRight') {
              item.convertedAttachment =
                (item.attachment * x.Rate) / structureCurrencyRate

              item.convertedLimit =
                (item.limit * x.Rate) / structureCurrencyRate
              this.towerState = 'onLoad'
            }
            if (
              this.towerState.trim() === 'dragEndRight' ||
              this.towerState.trim() === 'dragEndLeft'
            ) {
              item.convertedAttachment =
                (item.attachment * x.Rate) / structureCurrencyRate
              item.convertedLimit =
                (item.limit * x.Rate) / structureCurrencyRate
              this.towerState = 'onLoad'
            }
          }
        })
      }
    }
  }
}
