import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core'
import { Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'

const DEFAULT_PRESETS: number[] = [1, 5, 10, 25, 50, 100, 250, 1000]

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-return-period-selector',
  styles: [
    `
           .app-theme-dark {
             display: block;
             color: var(--body);
             padding: var(--inset-small);
             overflow: hidden;
           }
     
           /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
           mat-slider {
             width: 100%;
             height: 40px;
           }
     
           .presets {
             padding-bottom: var(--inset-small);
           }
     
           input {
             font-family: var(--font-family);
             font-weight: var(--font-weight);
             font-size: var(--font-size-big);
             background-color: var(--bg-1);
             color: var(--body);
             caret-color: var(--accent);
             border: 2px solid var(--border-1);
             border-radius: 5px;
             padding: var(--stack-tiny) var(--inset-tiny);
             width: 60%;
             text-overflow: ellipsis;
             overflow: hidden;
             outline: none;
             box-sizing: border-box;
             transition: border-color 250ms;
           }
           input:hover {
             border-color: var(--border-1-lit);
           }
     
           input:focus,
           input:active {
             border-color: var(--primary);
           }
     
           app-layer-property {
             padding-left: 0px;
             width: 100%;
           }
         `,
  ],
  template: `
    <div class="app-theme-dark">
      <div class="presets">
        <button
          *ngFor="let preset of presets"
          [disabled]="disabled"
          (click)="!disabled && returnPeriodChange.emit(preset)"
          appButton
          tiny
          extraPadding
        >
          {{ preset }}
        </button>
      </div>
      <app-layer-property
        type="numeric"
        name="Return Period Year"
        [readonly]="disabled"
        [value]="returnPeriod"
        (inputChange)="returnPeriodChange.emit($event)"
        >
      </app-layer-property>
    </div>
  `,
})
export class ReturnPeriodSelectorComponent implements OnInit, OnDestroy {
  @Input() returnPeriod: number
  @Input() disabled: boolean
  @Input() presets: number[] = DEFAULT_PRESETS

  @Output() returnPeriodChange = new EventEmitter<number | null>()
  value: string
  viewValue: string | null

  private debounce$ = new Subject<number | null>()
  private destroy$ = new Subject()

  ngOnInit(): void {
    this.debounce$
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe(value => {
        this.returnPeriodChange.emit(value)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
