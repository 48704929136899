import { Dictionary } from '@ngrx/entity'
import { LayerMetrics } from '../../model/layers-metrics.model'
import {
  AllPortfolioDetailMetrics,
  LayerMetricsResponse,
  Metrics,
  PortfolioDetailMetrics,
} from 'src/app/api/analyzere/analyzere.model'
import {
  PortfolioUserInputsResponse,
  SectorAssumptionLookupResponse,
} from 'src/app/core/model/study.model'
import { isSwingLayer } from '../../layers/swing-layer'
import { CapitalMetricsResult } from '../../model/capital-metric.model'
import { CompareMetricSetting } from '../../model/compare-metrics.model'
import { EfficiencyMetricsResult } from '../../model/efficiency-metrics.mode'
import { Layer, PhysicalLayer } from '../../model/layers.model'
import {
  CalculatedPortfolioDetailMetrics,
  PortfolioType,
} from '../../model/portfolio-metrics.model'
import { TailMetricsResult } from '../../model/tail-metrics.model'
import { VolatilityMetricsResult } from '../../model/volatility-metrics.model'
import { LayerState } from '../ceded-layers/layers.reducer'
import { layerIds } from '../../model/layer-palette.model'
import { analyzereConstants } from '@shared/constants/analyzere'

export function reverseRecord(record: Record<string, string>) {
  return Object.keys(record).reduce((acc, current) => {
    acc[record[current]] = current
    return acc
  }, {} as Record<string, string>)
}

export function getLayerFromLayerType(
  layerState: LayerState,
  cededLayersByID: Dictionary<LayerState>
): Layer {
  if (
    layerState &&
    layerState.layer &&
    layerState.layer.meta_data.backAllocatedForID
  ) {
    return (
      cededLayersByID[
        layerState.layer.meta_data.backAllocatedForID as string
      ] as LayerState
    ).layer
  } else {
    return layerState.layer
  }
}

export function getLayerTypeLimit(layer: any, type: string): number {
  if (type === 'Agg') {
    if (layer.physicalLayer) {
      return layer.physicalLayer.aggregateLimit.value - 0.001
    } else {
      if (layer.meta_data.sage_layer_type === layerIds.ilwBin && layer.payout.value) {
        return layer.payout.value - 0.001
      } else if (layer.aggregate_limit?.value) {
        return layer.aggregate_limit.value  - 0.001
      } else {
        return 0
      }
    }
  } else {
    if (layer.physicalLayer) {
      return layer.physicalLayer.limit.value - 0.001
    } else if (layer.limit) {
      return layer.limit.value - 0.001
    } else {
      return 0
    }
  }
}

function getMultiSectionLimit(
  layer: Layer,
  cededLayersByID: Dictionary<LayerState>,
  type?: string
): number {
  const visibleLayerID = layer.meta_data.visible_layer_id
  if (!visibleLayerID) {
    return type && type === 'Agg'
      ? layer.physicalLayer.aggregateLimit.value
      : layer.physicalLayer.limit.value
  }
  const visibleLayer = cededLayersByID[visibleLayerID]
  if (visibleLayer && type && type === 'Agg') {
    return visibleLayer.layer.physicalLayer.aggregateLimit.value
  }
  const contractOccurrenceLimit =
    visibleLayer?.layer.physicalLayer.meta_data.contractOccurrenceLimit
  if (!contractOccurrenceLimit) {
    return layer.physicalLayer.limit.value
  }
  if (contractOccurrenceLimit >= analyzereConstants.unlimitedValue) {
    const sectionAId = layer.layerRefs[0]
    const sectionA = cededLayersByID[sectionAId]
    if (sectionA) {
      return sectionA.layer.physicalLayer.limit.value
    }
  }
  return contractOccurrenceLimit
}

export function getLayerLimit(
  layer: Layer,
  cededLayersByID: Dictionary<LayerState>,
  type?: string
): number {
  let limit: number
  switch (layer.meta_data.sage_layer_type) {
    case layerIds.catAg:
      limit =
        type && type === 'Occ'
          ? layer.physicalLayer.limit.value
          : layer.physicalLayer.aggregateLimit.value
      break
    case layerIds.catMultisection:
      limit = getMultiSectionLimit(layer, cededLayersByID, type)
      break
    case layerIds.noncatMultisection:
      limit = getMultiSectionLimit(layer, cededLayersByID, type)
      break
    default:
      limit =
        type && type === 'Agg'
          ? layer.physicalLayer.aggregateLimit.value
          : layer.physicalLayer.limit.value
      break
  }
  return limit
}

export function calculateLayerViewMetrics(
  result: LayerMetricsResponse,
  cededLayersViewIDs: Record<string, string>,
  cededLayersByID: Dictionary<LayerState>,
  backAllocatedQuotaShare?: PhysicalLayer,
  isGroupPageSL?: boolean,
  sharedLimitSelectedLayerID?: string,
  newCededLayer?: Layer
): LayerMetrics {
  // tslint:disable: no-non-null-assertion
  const viewID = result.id
  const reversedRecord = reverseRecord(cededLayersViewIDs)
  let sharedLayer: Layer | undefined
  if (newCededLayer) {
    sharedLayer = newCededLayer
  } else {
    if (backAllocatedQuotaShare && !isGroupPageSL) {
      const sharedLayerID =
        cededLayersByID[backAllocatedQuotaShare.meta_data.backAllocatedForID!]!
          .layer.sharedLayerID
      sharedLayer = cededLayersByID[sharedLayerID]!.layer
    } else if (backAllocatedQuotaShare && isGroupPageSL) {
      sharedLayer =
        cededLayersByID[backAllocatedQuotaShare.logicalLayerID]!.layer
    }
  }

  const cessionPercentage = sharedLayer
    ? Math.abs(sharedLayer.physicalLayer.participation)
    : Math.abs(
        cededLayersByID[reversedRecord[viewID]]!.layer.physicalLayer
          .participation
      )

  const depositPremium = -result.depositPremium.mean
  const expectedCededPremiumDisp =
    result.expectedCededPremiumBase.mean - depositPremium
  const expectedCededLossDisp = result.expectedCededLossBase.mean
  const expectedCededLossDispForTP =
    result.expectedCededLossBaseNoParticipation.mean

  let cededLayer
  let limit
  let lossOnLineCession
  if (newCededLayer) {
    cededLayer = newCededLayer
    limit = getLayerLimit(cededLayer, cededLayersByID)
    lossOnLineCession = Math.abs(cededLayer.physicalLayer.participation)
  } else {
    if (!isGroupPageSL) {
      cededLayer = backAllocatedQuotaShare
        ? cededLayersByID[
            backAllocatedQuotaShare.meta_data.backAllocatedForID!
          ]!.layer
        : cededLayersByID[reversedRecord[viewID]]!.layer

      if (isSwingLayer(cededLayer, 'combined-layer')) {
        const visibleId = cededLayer.meta_data.visible_layer_id
        if (visibleId) {
          const visibleLayer = cededLayersByID[visibleId]?.layer
          cededLayer = visibleLayer ? visibleLayer : cededLayer
        }
      }

      limit = getLayerLimit(cededLayer, cededLayersByID)
      // Use original ceded layer cession
      lossOnLineCession = Math.abs(cededLayer.physicalLayer.participation)
    } else {
      cededLayer = cededLayersByID[sharedLimitSelectedLayerID!]!.layer

      limit = getLayerLimit(cededLayer, cededLayersByID)
      lossOnLineCession = cessionPercentage
    }
  }

  const lossOnLine = Math.abs(
    expectedCededLossDisp / (limit * lossOnLineCession)
  )

  let grossCovariance = 1
  if (result.grossCovariance?.component_metrics) {
    grossCovariance = result.grossCovariance.component_metrics.covariance
  }
  const purePremiumForTP = Math.abs(
    result.expectedCededLossBaseNoParticipation
      ? result.expectedCededLossBaseNoParticipation.mean
      : 0
  )
  const el = expectedCededLossDisp / limit

  return {
    id: result.id,
    depositPremium,
    depositPremiumNoParticipation: depositPremium / cessionPercentage,
    expectedCededPremium: expectedCededPremiumDisp,
    expectedCededPremiumNoParticipation:
      expectedCededPremiumDisp / cessionPercentage,
    purePremium: expectedCededLossDisp,
    lossOnLine,
    standardDeviationExpectedLoss: Math.sqrt(
      result.expectedCededLossBase.variance
    ),
    expectedCededExpenses: result.expectedCededExpensesBase.mean,
    expectedCededMargin: result.expectedCededMarginBase.mean,
    expectedCededLossRatio: expectedCededLossDisp / expectedCededPremiumDisp,
    cededMarginToStandardDeviation:
      result.expectedCededMarginBase.mean /
      Math.sqrt(result.expectedCededLossBase.variance),
    puremiumELMultiple: expectedCededPremiumDisp / expectedCededLossDisp,
    aepVar100: result.aepBase[0].min,
    aepVar250: result.aepBase[1].min,
    aeptVar100: result.aepBase[0].mean,
    aeptVar250: result.aepBase[1].mean,
    oepWindowVar: result.oepWindowVarBase.mean,
    aepWindowVar: result.aepWindowVarBase.mean,
    entryProbability: result.entryProbability.probability,
    exitProbability: result.exitProbability.probability,
    exitAggProbability: result.exitAggProbability.probability,
    grossCovariance,
    purePremiumForTP,
    standardDeviationExpectedLossForTP: Math.sqrt(
      result.expectedCededLossBaseNoParticipation.variance
    ),
    cededYearTVar: result.cededYearValue?.mean
      ? result.cededYearValue?.mean
      : 0,
    cededYearVar: result.cededYearValue?.min ? result.cededYearValue.min : 0,
    cededLossCV:
      Math.sqrt(result.expectedCededLossBase.variance) / expectedCededLossDisp,
    cededLossCVForTP:
      Math.sqrt(result.expectedCededLossBaseNoParticipation.variance) /
      expectedCededLossDispForTP,
    el,
  }
}

export function calculatePortfolioViewDetailMetrics(
  metrics: AllPortfolioDetailMetrics,
  portfolioType: PortfolioType
): CalculatedPortfolioDetailMetrics {
  const byType = {} as Record<PortfolioType, PortfolioDetailMetrics>
  byType.Ceded = metrics.cededPortfolioViewDetailMetrics
  byType.Gross = metrics.grossPortfolioViewDetailMetrics
  byType.Net = metrics.netPortfolioViewDetailMetrics
  const m = byType[portfolioType]

  let expense
  let margin = null
  let stdDevOfLoss = Math.sqrt(
    m.lossNetPremiumReinstatementAggregateTermAEP.variance
  )
  if (portfolioType === 'Gross') {
    expense = byType.Gross.lossNetOfAggregateTermsAEPReportingPeriod.mean
  } else if (portfolioType === 'Ceded') {
    expense = byType.Ceded.lossNetOfAggregateTermsAEPReportingPeriod.mean
    margin =
      byType.Ceded.lossNetAggregateTermsPremiumReinstatementAEPParticipation.mean
    stdDevOfLoss = Math.sqrt(m.lossNetAggregateTermAEP.variance)
  } else {
    expense = byType.Net.lossNetOfAggregateTermsAEPReportingPeriod.mean
  }
  const expectedLoss = m.lossNetAggregateTermAEP.mean
  const depositPremium = m.premiumAEP.mean
  const premium = depositPremium + m.reinstatementPremiumAEP.mean
  const expectedLossRatio = premium === 0 ? null : expectedLoss / premium
  const expectedCombinedRatio =
    // tslint:disable-next-line: no-non-null-assertion
    premium === 0 ? null : expectedLossRatio! + expense / premium
  const expectedUnderwritingResult =
    m.lossNetAggregateTermsPremiumReinstatementAEP.mean * -1
  const cvOfNetLosses = stdDevOfLoss / expectedLoss

  return {
    expense,
    expectedLoss,
    stdDevOfLoss,
    premium,
    depositPremium,
    expectedLossRatio,
    expectedCombinedRatio,
    expectedUnderwritingResult,
    margin,
    cvOfNetLosses,
    grossCovariance: m.grossCovariance.component_metrics.covariance,
  }
}

const rssContinuum: ReadonlyArray<number> = [0.0001, 0.01] // 0.0100% and 1.000%

export function calculateRSS(
  sectorAssumptions: SectorAssumptionLookupResponse,
  portfolioUserInput: PortfolioUserInputsResponse,
  cededMargin: number,
  grossSD: number,
  netSD: number
): number {
  const fittedPBGross =
    sectorAssumptions.intercept +
    portfolioUserInput.prospectiveROE * 100 * sectorAssumptions.roeSector +
    portfolioUserInput.prospectiveROE *
      100 *
      Math.log(portfolioUserInput.epsVolatility * 100) *
      sectorAssumptions.epsVolatilitySector
  const costOfReinsurance = cededMargin * (1 - portfolioUserInput.taxRate)
  const roeAfterReins =
    (portfolioUserInput.prospectiveROE * portfolioUserInput.bookValue -
      costOfReinsurance) /
    portfolioUserInput.bookValue
  const epsVolatilityAfterReins =
    (portfolioUserInput.epsVolatility * netSD) / grossSD
  const fittedPBNet =
    sectorAssumptions.intercept +
    roeAfterReins * 100 * sectorAssumptions.roeSector +
    roeAfterReins *
      100 *
      Math.log(epsVolatilityAfterReins * 100) *
      sectorAssumptions.epsVolatilitySector
  const valueCreated =
    (fittedPBNet - fittedPBGross) * portfolioUserInput.bookValue
  const costToValue = costOfReinsurance / valueCreated
  const rss =
    costToValue < 0
      ? 1
      : 100 -
        (costToValue > rssContinuum[1]
          ? 99
          : costToValue < rssContinuum[0]
          ? 1
          : ((costToValue - rssContinuum[0]) /
              (rssContinuum[1] - rssContinuum[0])) *
            100)
  return rss
}

export function calculateVolatilityMetrics(
  _cededMetrics: Array<Metrics>,
  grossMetrics: Array<Metrics>,
  netMetrics: Array<Metrics>,
  standardDev: Metrics
): VolatilityMetricsResult {
  return {
    volatilityTransferredPercentage:
      grossMetrics[0].variance === 0
        ? 0
        : 1 -
          Math.sqrt(netMetrics[0].variance) /
            Math.sqrt(grossMetrics[0].variance),
    reductionIn10yrAEPPercentage:
      grossMetrics[1].min === 0
        ? 0
        : 1 - netMetrics[1].min / grossMetrics[1].min,
    reductionIn250yrAEPPercentage:
      grossMetrics[2].min === 0
        ? 0
        : 1 - netMetrics[2].min / grossMetrics[2].min,
    netStandardDeviation: Math.sqrt(standardDev.variance),
  }
}

export function calculateCapitalMetrics(
  _cededAEPMetrics: Metrics,
  _cededOEPMetrics: Metrics,
  grossAEPCatMetrics: Metrics,
  grossOEPCatMetrics: Metrics,
  netAEPCatMetrics: Metrics,
  netOEPCatMetrics: Metrics,
  expectedCededPremium: number,
  expectedCededLoss: number,
  spPremiumValue: number,
  spReserveValue: number,
  spDivesificationValue: number,
  spCatValue: number,
  bcarPremiumValue: number,
  bcarReserveValue: number,
  bcarDivesificationValue: number
): CapitalMetricsResult {
  const tail250GrossVarOEP =
    grossOEPCatMetrics.min !== 0 ? grossOEPCatMetrics.min : 0
  const tail250NetVarOEP = netOEPCatMetrics.min !== 0 ? netOEPCatMetrics.min : 0

  const bcarCapitalBenefit =
    (-expectedCededPremium * bcarPremiumValue +
      -expectedCededLoss * bcarReserveValue +
      (tail250GrossVarOEP - tail250NetVarOEP)) *
    bcarDivesificationValue

  const tail250GrossVar =
    grossAEPCatMetrics.min !== 0 ? grossAEPCatMetrics.min : 0
  const tail250NetVar = netAEPCatMetrics.min !== 0 ? netAEPCatMetrics.min : 0

  const divValue =
    -expectedCededPremium * spPremiumValue +
    -expectedCededLoss * spReserveValue +
    (tail250GrossVar - tail250NetVar) * spCatValue
  const spCapitalBenefit =
    divValue * (1 - spDivesificationValue) * -1 + divValue
  return {
    bcarCapitalBenefit,
    spCapitalBenefit,
    ecmCapitalBenefit: (bcarCapitalBenefit + spCapitalBenefit) / 2,
  }
}

export function calculateEfficiencyMetrics(
  taxRate: number,
  capitalMetrics: CapitalMetricsResult,
  volatility: number,
  expectedCededMargin: number,
  reinsuranceEfficiencyNet: Metrics,
  reinsuranceEfficiencyGross: Metrics,
  netUW: number,
  grossUW: number
): EfficiencyMetricsResult {
  return {
    bcarCostOfCapital:
      capitalMetrics.bcarCapitalBenefit === 0
        ? 0
        : (-expectedCededMargin * (1 - taxRate)) /
          capitalMetrics.bcarCapitalBenefit,
    spCostOfCapital:
      capitalMetrics.spCapitalBenefit === 0
        ? 0
        : (-expectedCededMargin * (1 - taxRate)) /
          capitalMetrics.spCapitalBenefit,
    ecmCostOfCapital:
      capitalMetrics.ecmCapitalBenefit === 0
        ? 0
        : (-expectedCededMargin * (1 - taxRate)) /
          capitalMetrics.ecmCapitalBenefit,
    custom: expectedCededMargin === 0 ? 0 : volatility / -expectedCededMargin,
    reinsuranceEfficiency:
      ((reinsuranceEfficiencyGross.min * -1 -
        reinsuranceEfficiencyNet.min * -1) /
        (grossUW - netUW)) *
      -1,
  }
}

export function calculateTailMetrics(
  tailMetric1: Metrics,
  tailMetric2: Metrics,
  tailMetric3: Metrics,
  tailMetricGross1: Metrics,
  tailMetricGross2: Metrics,
  tailMetricGross3: Metrics,
  tailMetric4: Metrics,
  tailMetric5: Metrics,
  tailMetric6: Metrics,
  tailMetricGross4: Metrics,
  tailMetricGross5: Metrics,
  tailMetricGross6: Metrics,
  metric: CompareMetricSetting[]
): TailMetricsResult {
  return {
    tailMetric1: calculateTail(tailMetric1, metric[0], tailMetricGross1),
    tailMetric2: calculateTail(tailMetric2, metric[1], tailMetricGross2),
    tailMetric3: calculateTail(tailMetric3, metric[2], tailMetricGross3),
    tailMetricGross1: calculateTail(
      tailMetricGross1,
      metric[3],
      tailMetricGross1
    ),
    tailMetricGross2: calculateTail(
      tailMetricGross2,
      metric[4],
      tailMetricGross1
    ),
    tailMetricGross3: calculateTail(
      tailMetricGross3,
      metric[5],
      tailMetricGross1
    ),
    tailMetric4: calculateTail(tailMetric4, metric[6], tailMetricGross4),
    tailMetric5: calculateTail(tailMetric5, metric[7], tailMetricGross5),
    tailMetric6: calculateTail(tailMetric6, metric[8], tailMetricGross6),
    tailMetricGross4: calculateTail(
      tailMetricGross4,
      metric[9],
      tailMetricGross1
    ),
    tailMetricGross5: calculateTail(
      tailMetricGross5,
      metric[10],
      tailMetricGross1
    ),
    tailMetricGross6: calculateTail(
      tailMetricGross6,
      metric[11],
      tailMetricGross1
    ),
  }
}

function calculateTail(
  m: Metrics,
  metric: CompareMetricSetting,
  m2: Metrics
): number {
  const isLoss =
    metric.perspective === 'Loss' || metric.perspective === 'LossRp'
  const portfolioType = metric.grossMetricType ? 'Gross' : metric.portfolioType
  const vartvar = metric.vartvar

  // Use min for VaR and mean for TVaR
  if (vartvar === 'VaR') {
    if (portfolioType === 'Gross') {
      return isLoss ? m.min : m.min * -1
    } else if (portfolioType === 'Net') {
      return isLoss ? m.min : m.min * -1
    } else {
      return isLoss ? m.min - m2.min : m.min * -1 - m2.min * -1
    }
  } else {
    if (portfolioType === 'Gross') {
      return isLoss ? m.mean : m.mean * -1
    } else if (portfolioType === 'Net') {
      return isLoss ? m.mean : m.mean * -1
    } else {
      return isLoss ? m.mean - m2.mean : m.mean * -1 - m2.mean * -1
    }
  }
}
