import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ApplicationError } from "src/app/error/model/error";
import { CreditSubmissionStructure } from "../../model/credit-submission.model";
import { ClientSearch } from "../../submission/credit-submission.modal";

export const CreditSubmissionActions = createActionGroup({
  source: 'Credit Submission',
  events: {
    'Set Selected Filters': props<{ filters: ClientSearch }>(),
    'Get Submission Structures Failure': props<{ error: ApplicationError }>(),
    'Get Submission Structures Success': props<{ structures: CreditSubmissionStructure[]  }>(),
  },
})
