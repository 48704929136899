import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { Subject } from 'rxjs'
import { Size } from '@shared/size.mixin'
import { CreditStructure } from './../../credit/model/credit-structure.model'
import { CreditSubmissionStructure } from './../../credit/model/credit-submission.model'
import { BackendService } from '../../api/backend/backend.service'
import { takeUntil } from 'rxjs/operators'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-credit-structure-card-container',
  styleUrls: ['./credit-structure-card.container.scss'],
  templateUrl: './credit-structure-card.container.html',
})
export class CreditStructureCardContainerComponent {
  private destroy$ = new Subject()
  imageURI: string

  @Input() index: number
  @Input() structure: CreditStructure | CreditSubmissionStructure
  @Input() selectMultiple: boolean
  @Input() canCheck = false
  @Input() canOptimize = false

  // Size mixin
  @Input() size: Size
  @Input() big: boolean
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }

  @Output() structureClick = new EventEmitter<void>()
  @Output() structureCheckChange = new EventEmitter<void>()
  @Output() optimizeClick = new EventEmitter<void>()

  constructor(
    private backendService: BackendService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!this.structure.imageSrc) {
      return
    }
    const timestamp = new Date().toISOString() // used to bust cache
    this.backendService
      .getThumbnailImage(this.structure.imageSrc, timestamp)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Blob) => {
        this.createImageFromBlob(data)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  private createImageFromBlob(image: Blob): void {
    const reader = new FileReader()
    reader.onload = () => {
      this.imageURI = reader.result as string
      this.cdRef.markForCheck()
    }
    if (image) {
      reader.readAsDataURL(image)
    }
  }
}
