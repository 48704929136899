import { Dictionary } from '@ngrx/entity'
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store'
import { chain, filter, groupBy, map } from 'ramda'
import { formatMetricSettingsSave } from '../../api/metric-settings/metric-settings.converter'
import { AppState } from '../../core/store'
import * as fromBroker from '../../core/store/broker/broker.selectors'
import {
  findAllAncestorGroupsWithID,
  findAllPossibleAncestorGroupsWithID,
  selectProgramGroupMembers,
} from '../../core/store/program-group-member.selectors'
import { selectProgramGroupsByID } from '../../core/store/program-group/program-group.selectors'
import { selectCurrentStudyPrograms } from '../../core/store/program/program.selectors'
import { selectRouterStateUrl } from '../../core/store/router.selectors'
import { getNextInuranceSymbol } from '../model/inurance-symbol'
import { createInurancePayload } from '../model/inurance.util'
import {
  filterQuoteTowerLayers,
  filterTowerLayers,
  isLayerActualTopAndDrop,
  isLayerShared,
} from '../model/layers.util'
import { extractPortfolioSetID } from '../model/portfolio-set-id.util'
import {
  PortfolioSetAndStudyIDs,
  PortfolioSetID,
  SharedIDPortfolio,
} from '../model/portfolio-set.model'
import * as fromAnalysis from './analysis.reducer'
import * as fromLayers from './ceded-layers/layers.reducer'
import * as fromCompareMetricSettings from './compare/compare-metric-settings/compare-metric-settings.reducer'
import * as fromCompare from './compare/compare.reducer'
import * as fromGrouperSelectors from './grouper/grouper.selectors'
import * as fromGrouperProgramMember from './grouper/program-group-member/program-group-member.reducer'
import * as fromGroupProgram from './grouper/program-group/program-group.reducer'
import * as fromGrouperProgram from './grouper/program/program.reducer'
import * as fromLayersViewMetrics from './metrics/layers-metrics.reducer'
import { createAllMetricTableCategoryValues } from './metrics/metrics-cart/create-metric-table-categories'
import * as fromMetricTableSettings from './metrics/metrics-cart/metrics-cart.reducer'
import * as fromPortfolioSet from './portfolio-set/portfolio-set.reducer'

import { isIndexedLayer } from 'src/app/analysis/layers/indexed-layer'
import {
  isMultiSectionLayer,
  isMultiSectionMainLayer,
} from 'src/app/analysis/layers/multi-section-layer'
import { isSwingLayer } from 'src/app/analysis/layers/swing-layer'
import {
  CompareMetricSetting,
  CompareMetricValue,
  CompareStructureOptionsDatum,
  CompareStructureOptionsDimension,
  CompareStructureOptionsView,
} from '../model/compare-metrics.model'
import * as fromLossSetGroup from './loss-set-layers/loss-set-group/loss-set-group.reducer'
import { LossSetTableState } from '../explore/store/explore.reducer'
import { isCurveWeightTotalValid } from '../technical-premium/technical-premium.utils'

export const selectAnalysis: MemoizedSelector<AppState, fromAnalysis.State> =
  createFeatureSelector(fromAnalysis.ANALYSIS_FEATURE_KEY)

export const selectEditorState = createSelector(
  selectAnalysis,
  state => state.editor
)

export const selectEditorShowingTowerTab = createSelector(
  selectRouterStateUrl,
  url => url.includes('/tower')
)

export const selectEditorShowingAnimatedScenariosTab = createSelector(
  selectRouterStateUrl,
  url => url.includes('/animated-scenarios')
)

export const selectEditorShowingExploreGrossTab = createSelector(
  selectRouterStateUrl,
  url => url.includes('/explore-gross')
)

export const selectEditorShowingTechnicalPremiumTab = createSelector(
  selectRouterStateUrl,
  url => url.includes('/technical-premium')
)

// Top Level properties
export const selectEditorPortfolioSetID = createSelector(
  selectEditorState,
  extractPortfolioSetID
)

export const selectCurrentCededPortfolioID = createSelector(
  selectEditorState,
  state => state.cededPortfolioID
)

export const selectCurrentGrossPortfolioID = createSelector(
  selectEditorState,
  state => state.grossPortfolioID
)

export const selectCurrentParentGrossPortfolioID = createSelector(
  selectEditorState,
  state => state.parentGrossPortfolioID
)

export const selectCurrentNetPortfolioID = createSelector(
  selectEditorState,
  state => state.netPortfolioID
)

export const selectCurrentClientID = createSelector(
  selectEditorState,
  state => state.clientID
)

export const selectCurrentStudyID = createSelector(
  selectEditorState,
  state => state.studyID
)

export const selectCurrentStructureID = createSelector(
  selectEditorState,
  state => state.structureID
)

export const selectCurrentYearID = createSelector(
  selectEditorState,
  state => state.yearID
)

export const selectIsNewDropLayerSaving = createSelector(
  selectEditorState,
  state => state.isNewDropLayerSaving
)

export const selectEditorPortfolioSetAndStudyIDs = createSelector(
  selectEditorPortfolioSetID,
  selectCurrentClientID,
  fromBroker.selectCurrentYearID,
  selectCurrentStudyID,
  selectCurrentStructureID,
  (ids, clientID, yearID, studyID, structureID) => {
    let props: PortfolioSetAndStudyIDs | null = null
    if (ids && clientID && yearID && studyID && structureID) {
      props = { ...ids, clientID, yearID, studyID, structureID }
    }
    return props
  }
)

export const selectCurrentAnalysisProfileID = createSelector(
  selectEditorState,
  state => state.analysisProfileID
)

export const selectCurrentProgram = createSelector(
  selectCurrentCededPortfolioID,
  selectCurrentStudyPrograms,
  (cededPortfolioID, programs) =>
    programs.find(p => p.cededPortfolioID === cededPortfolioID)
)

export const selectInuranceSymbolMap = createSelector(
  selectEditorState,
  state => state.inurance.symbolMap
)

export const selectCurrentProgramScenarios = createSelector(
  selectCurrentProgram,
  selectCurrentStudyPrograms,
  (currentProgram, programs) => {
    if (currentProgram && !currentProgram.isOptimization) {
      const parentID = currentProgram?.isScenario
        ? currentProgram.parentScenarioID
        : currentProgram.id
      const parent = programs.filter(p => p.id === parentID)
      const scenarios = programs.filter(p => p.parentScenarioID === parentID)
      return parent.concat(scenarios)
    }
    return []
  }
)

export const selectCurrentProgramOptimizations = createSelector(
  selectCurrentProgram,
  selectCurrentStudyPrograms,
  (currentProgram, programs) => {
    if (currentProgram && !currentProgram.isScenario) {
      const parentID = currentProgram?.isOptimization
        ? currentProgram.parentOptimizationID
        : currentProgram.id
      const parent = programs.filter(p => p.id === parentID)
      const optimizations = programs.filter(
        p => p.parentOptimizationID === parentID
      )
      return parent.concat(optimizations)
    }
    return []
  }
)

export const selectCurrentProgramAncestorGroups = createSelector(
  selectCurrentProgram,
  selectProgramGroupMembers,
  selectProgramGroupsByID,
  (program, members, groupsByID) =>
    program
      ? findAllAncestorGroupsWithID(
          { programID: program.id },
          members,
          groupsByID
        )
      : []
)

export const selectCurrentProgramAllAncestorGroups = createSelector(
  selectCurrentProgram,
  selectProgramGroupMembers,
  selectProgramGroupsByID,
  (program, members, groupsByID) =>
    program
      ? findAllPossibleAncestorGroupsWithID(
          { programID: program.id },
          members,
          groupsByID
        )
      : []
)

export const selectEditorSavingAs = createSelector(
  selectEditorState,
  state => state.savingAs
)
export const selectSavingAsError = createSelector(
  selectEditorState,
  state => state.savingAsError
)

export const selectShowAgg = createSelector(
  selectEditorState,
  state => state.showAggregate
)

export const selectShowOcc = createSelector(
  selectEditorState,
  state => state.showOccurrence
)

export const selectZoom = createSelector(
  selectEditorState,
  state => state.zoomValue
)

export const selectlayerDetailsTitle = createSelector(
  selectEditorState,
  state => state.layerDetailsTitleUpdate
)

export const selectCurrentCurrency = createSelector(
  selectEditorState,
  state => state.currentCurrency
)

export const selectMapLossSets = createSelector(
  selectEditorState,
  state => state.mapLossSets
)

// Layers
export const selectCededLayersState = createSelector(
  selectEditorState,
  state => state.cededLayers
)

export const {
  selectAll: selectCededLayers,
  selectEntities: selectCededDictionary,
} = fromLayers.adapter.getSelectors(selectCededLayersState)

export const selectCededSelectedLayer = createSelector(
  selectCededLayersState,
  state => state.selected
)

export const selectCededAggLayers = createSelector(selectCededLayers, state =>
  state.filter(l => {
    return (
      !l.deleted &&
      ((l.layer.meta_data.sage_layer_type === 'cat_ag' &&
        !l.layer.meta_data.sage_layer_subtype) ||
        (l.layer.meta_data.sage_layer_type === 'noncat_ag' &&
          !l.layer.meta_data.sage_layer_subtype) ||
        (l.layer.meta_data.sage_layer_type === 'ahl_ag' &&
          !l.layer.meta_data.sage_layer_subtype))
    )
  })
)

export const selectCededFHCFHiddenLayer = createSelector(
  selectCededLayers,
  state =>
    state.filter(l => {
      return !l.deleted && l.layer.meta_data.isFHCFHidden1
    })
)

export const selectCededRiskLayer = createSelector(selectCededLayers, state =>
  state.filter(l => {
    return !l.deleted && l.layer.meta_data.sage_layer_type === 'noncat_risk'
  })
)

export const selectActiveCededLayers = createSelector(
  selectCededLayers,
  states => states.filter(ls => !ls.deleted)
)

export const selectCededIndexedLayers = createSelector(
  selectActiveCededLayers,
  states => states.map(ls => ls.layer).filter(layer => isIndexedLayer(layer))
)

export const selectCededSwingLayers = createSelector(
  selectActiveCededLayers,
  states => states.map(ls => ls.layer).filter(layer => isSwingLayer(layer))
)

export const selectCededMultiSectionLayers = createSelector(
  selectActiveCededLayers,
  states =>
    states.map(ls => ls.layer).filter(layer => isMultiSectionLayer(layer))
)

export const selectCededMultiSectionMainLayers = createSelector(
  selectCededMultiSectionLayers,
  layers => layers.filter(layer => isMultiSectionMainLayer(layer))
)

export const selectCededLayersFilteredIDs = createSelector(
  selectCededLayers,
  state => state.filter(filterTowerLayers).map(l => l.layer.id)
)
// Added quote selectors specific for filterQuoteTowerLayers
export const selectQuoteCededLayersFilteredIDs = createSelector(
  selectCededLayers,
  state => state.filter(filterQuoteTowerLayers).map(l => l.layer.id)
)

export const selectCurrentCededLayer = createSelector(
  selectCededLayersState,
  state => (state.selected ? state.entities[state.selected] ?? null : null)
)

export const selectCededLayersSaving = createSelector(
  selectCededLayersState,
  state => state.saving
)

export const selectCededLayersLoading = createSelector(
  selectCededLayersState,
  state => state.loading
)

export const selectCededLayersError = createSelector(
  selectCededLayersState,
  state => state.error
)

export const selectMultiSectionContributions = createSelector(
  selectCededLayersState,
  state => state.multiSectionContributions
)

export const selectCededLayersSublayersMetrics = createSelector(
  selectCededLayersState,
  state => state.subLayerMetrics
)

export const selectEditorActiveAction = createSelector(
  selectEditorSavingAs,
  selectCededLayersSaving,
  (savingAs, saving) => (saving ? 'Saving' : savingAs ? 'Saving As' : '')
)

export const selectSharedIDPortfolios = createSelector(
  selectCededLayers,
  state => {
    const groupBySharedLayerID = groupBy<fromLayers.LayerState>(
      l => l.layer.sharedLayerID,
      state
    )
    const sharedIDGroup: SharedIDPortfolio[] = []
    let groupID = 1
    Object.keys(groupBySharedLayerID).forEach(sharedLayerID => {
      if (sharedLayerID) {
        const group: string[] = []
        groupBySharedLayerID[sharedLayerID].forEach(l => {
          if (l.layer.meta_data.riskVisibleLayerID) {
            group.push(l.layer.meta_data.riskVisibleLayerID)
          } else if (isLayerActualTopAndDrop(l.layer)) {
            group.push(...l.layer.layerRefs)
          } else {
            group.push(l.layer.id)
          }
        })
        sharedIDGroup.push({
          sharedID: sharedLayerID,
          numberGroup: groupID++,
          group,
        })
      }
    })
    return sharedIDGroup
  }
)

// Loss Set Layers
export const selectLossSetLayersState = createSelector(
  selectEditorState,
  state => state.lossSetLayers
)
export const selectLossSetLayers = createSelector(
  selectLossSetLayersState,
  state => state.layers
)
export const selectParentGrossLossSetLayers = createSelector(
  selectLossSetLayersState,
  state => state.parentGrossLayers
)

export const selectLossSetLayersLoading = createSelector(
  selectLossSetLayersState,
  state => state.loading
)

export const selectLossSetLayersError = createSelector(
  selectLossSetLayersState,
  state => state.error
)

export const selectLossSetLayersDirty = createSelector(
  selectLossSetLayersState,
  state => state.dirty
)

export const selectLossSetSelectionMode = createSelector(
  selectLossSetLayersState,
  state => state.lossSetSelectionMode
)

export const selectLossSetGroupsMetrics = createSelector(
  selectLossSetLayersState,
  state => state.lossSetGroupsMetrics
)

export const selectLossSetLayerViewIDs = createSelector(
  selectLossSetLayersState,
  state => state.lossSetLayerViewIDs
)

// Loss Set Groups

export const selectLossSetGroupState = createSelector(
  selectLossSetLayersState,
  state => state.lossSetGroups
)

export const {
  selectAll: selectLossSetGroupEntities,
  selectEntities: selectLossSetGroupEntitiesByID,
} = fromLossSetGroup.adapter.getSelectors(selectLossSetGroupState)

export const selectLossSetGroupIDs = createSelector(
  selectLossSetGroupEntities,
  entities => entities.map(e => e.lossSetGroup.id)
)

export const selectLossSetGroups = createSelector(
  selectLossSetGroupEntities,
  state => state.map(lsg => lsg.lossSetGroup)
)

export const selectGroupEditSelectedLossSets = createSelector(
  selectLossSetGroupState,
  state => state.selectedLayers
)

export const selectLossSetGroupEditMode = createSelector(
  selectLossSetGroupState,
  state => state.mode
)

export const selectLossSetSelectedGroup = createSelector(
  selectLossSetGroupState,
  state => state.selectedGroup
)

export const selectLossSetGroupsActiveAction = createSelector(
  selectLossSetGroupState,
  state => (state.saving ? 'Saving' : '')
)

// Ceded Portfolio
export const selectCededPortfolioState = createSelector(
  selectEditorState,
  state => state.cededPortfolio
)
export const selectCededPortfolioLoading = createSelector(
  selectCededPortfolioState,
  state => state.loading
)

export const selectCededPortfolioError = createSelector(
  selectCededPortfolioState,
  state => state.error
)

export const selectCededPortfolioName = createSelector(
  selectCededPortfolioState,
  state => state.name
)

// Net Portfolio
export const selectNetPortfolioState = createSelector(
  selectEditorState,
  state => state.netPortfolio
)
export const selectNetPortfolioLoading = createSelector(
  selectNetPortfolioState,
  state => state.loading
)

export const selectNetPortfolioError = createSelector(
  selectNetPortfolioState,
  state => state.error
)

export const selectNetPortfolioLayersIDs = createSelector(
  selectNetPortfolioState,
  state => state.layersIDs
)

// Portfolio Set

export const selectPorfolioSetState = createSelector(
  selectAnalysis,
  state => state.portfolio
)

export const {
  selectAll: selectPortfolioSetEntities,
  selectEntities: selectPortfolioSetEntitiesByID,
} = fromPortfolioSet.adapter.getSelectors(selectPorfolioSetState)

export interface SelectPortfolioSetProps {
  portfolioSetID?: PortfolioSetID
  layerViewMetricID?: string
}

export const selectPortfolioSet = createSelector(
  selectEditorPortfolioSetID,
  selectPortfolioSetEntitiesByID,
  (
    editorPortfolioSetID: PortfolioSetID | null,
    entities: Dictionary<fromPortfolioSet.PortfolioSetEntity>,
    props?: SelectPortfolioSetProps
  ) => {
    let entity: fromPortfolioSet.PortfolioSetEntity | undefined
    const id = (props && props.portfolioSetID) || editorPortfolioSetID
    if (id) {
      const key = fromPortfolioSet.buildPortfolioSetKey(id)
      entity = entities[key]
    }
    return entity || fromPortfolioSet.initialEntity
  }
)

// Porfolio Properties
export const selectPortfolioProperties = createSelector(
  selectCurrentProgram,
  state => {
    if (state && state.towerPreferences) {
      return state.towerPreferences
    }
  }
)

// Occurrence
export const selectPortfolioPropertiesOccIncrementsY = createSelector(
  selectPortfolioProperties,
  state => (state ? state.occurrence.incrementsY : null)
)

export const selectPortfolioPropertiesOccIncrementsYDirty = createSelector(
  selectPortfolioProperties,
  state => (state ? state.occurrence.incrementsYDirty : null)
)

export const selectPortfolioPropertiesOccMaxY = createSelector(
  selectPortfolioProperties,
  state => (state ? state.occurrence.maxY : null)
)

export const selectPortfolioPropertiesLog = createSelector(
  selectPortfolioProperties,
  state => (state ? state.log : null)
)

export const selectPortfolioPropertiesLogMin = createSelector(
  selectPortfolioProperties,
  state => (state ? state.logMin : null)
)

export const selectPortfolioPropertiesSnapping = createSelector(
  selectPortfolioProperties,
  state => (state ? state.snapping : null)
)

export const selectPortfolioPropertiesOccMaxYDirty = createSelector(
  selectPortfolioProperties,
  state => (state ? state.occurrence.maxYDirty : null)
)

export const selectPortfolioPropertiesOccMost = createSelector(
  selectPortfolioProperties,
  state => (state ? state.occurrence.most : null)
)

// Aggregate
export const selectPortfolioPropertiesAggIncrementsY = createSelector(
  selectPortfolioProperties,
  state => (state ? state.aggregate.incrementsY : null)
)

export const selectPortfolioPropertiesAggIncrementsYDirty = createSelector(
  selectPortfolioProperties,
  state => (state ? state.aggregate.incrementsYDirty : null)
)

export const selectPortfolioPropertiesAggMaxY = createSelector(
  selectPortfolioProperties,
  state => (state ? state.aggregate.maxY : null)
)

export const selectPortfolioPropertiesAggMaxYDirty = createSelector(
  selectPortfolioProperties,
  state => (state ? state.aggregate.maxYDirty : null)
)

export const selectPortfolioPropertiesAggMost = createSelector(
  selectPortfolioProperties,
  state => (state ? state.aggregate.most : null)
)

export const selectPortfolioSetsWithIDs = createSelector(
  selectPortfolioSetEntitiesByID,
  (
    byID: Dictionary<fromPortfolioSet.PortfolioSetEntity>,
    props?: { portfolioSetIDs?: Array<PortfolioSetID | undefined> }
  ) => {
    const ids =
      (props &&
        props.portfolioSetIDs &&
        (props.portfolioSetIDs.filter(id => id != null) as PortfolioSetID[])) ||
      []
    return ids
      .map(fromPortfolioSet.buildPortfolioSetKey)
      .map(id => byID[id])
      .filter(set => set != null) as fromPortfolioSet.PortfolioSetEntity[]
  }
)

// Portfolio View

export const selectPortfolioViewState = createSelector(
  selectPortfolioSet,
  state => state.portfolioView
)
export const selectCededPortfolioViewID = createSelector(
  selectPortfolioViewState,
  state => state.cededPortfolioViewID
)
export const selectGrossPortfolioViewID = createSelector(
  selectPortfolioViewState,
  state => state.grossPortfolioViewID
)
export const selectNetPortfolioViewID = createSelector(
  selectPortfolioViewState,
  state => state.netPortfolioViewID
)

export const selectCededPortfolioViewLayersViewIDs = createSelector(
  selectPortfolioViewState,
  state => state.cededLayersViewIDs
)

export const selectCededLayersViewIDsLoading = createSelector(
  selectPortfolioViewState,
  state => state.loadingCededLayersViews
)

export const selectCededLayersViewIDsError = createSelector(
  selectPortfolioViewState,
  state => state.errorCededLayersViews
)

export const selectPortfolioViewLoading = createSelector(
  selectPortfolioViewState,
  state => state.loading
)

export const selectPortfolioViewError = createSelector(
  selectPortfolioViewState,
  state => state.error
)

// PortfolioViewDetail Metrics
export const selectViewMetricsState = createSelector(
  selectPortfolioSet,
  state => state.viewMetrics
)

export const selectPortfolioViewDetailMetricsState = createSelector(
  selectViewMetricsState,
  state => state.portfolioViewDetailMetrics
)

export const selectPortfolioViewDetailCededMetrics = createSelector(
  selectPortfolioViewDetailMetricsState,
  state => state.cededCalculatedMetrics
)

export const selectPortfolioViewDetailGrossMetrics = createSelector(
  selectPortfolioViewDetailMetricsState,
  state => state.grossCalculatedMetrics
)

export const selectPortfolioViewDetailNetMetrics = createSelector(
  selectPortfolioViewDetailMetricsState,
  state => state.netCalculatedMetrics
)

export const selectPortfolioViewDetailMetricsLoading = createSelector(
  selectPortfolioViewDetailMetricsState,
  state => state.loading
)

export const selectPortfolioViewDetailMetricsError = createSelector(
  selectPortfolioViewDetailMetricsState,
  state => state.error
)

// PortfolioView Metrics
export const selectPortfolioViewMetricsState = createSelector(
  selectViewMetricsState,
  state => state.portfolioViewMetrics
)
export const selectPortfolioViewMetrics = createSelector(
  selectPortfolioViewMetricsState,
  state => state.metrics
)

export const selectPortfolioViewMetricsLoading = createSelector(
  selectPortfolioViewMetricsState,
  state => state.loading
)

export const selectPortfolioViewMetricsError = createSelector(
  selectPortfolioViewMetricsState,
  state => state.error
)

export const selectPortfolioViewMetricsRSS = createSelector(
  selectPortfolioViewMetricsState,
  state => state.rss
)

// Layers View Metrics
export const selectLayersViewMetricsState = createSelector(
  selectViewMetricsState,
  state => state.layersViewMetrics
)

export const selectLayersViewMetricsEntitiesByID = createSelector(
  selectLayersViewMetricsState,
  state => state.byID
)

export const selectLayersViewMetricsEntityWithID = createSelector(
  selectLayersViewMetricsState,
  (state: fromLayersViewMetrics.State, props?: SelectPortfolioSetProps) => {
    const key = props && props.layerViewMetricID
    return (key && state.byID[key]) || null
  }
)

export const selectLayersViewMetricsWithID = createSelector(
  selectLayersViewMetricsEntityWithID,
  state => state && state.metrics
)

export const selectLayersViewMetricsLoading = createSelector(
  selectLayersViewMetricsEntityWithID,
  state => (state ? state.loading : false)
)

export const selectLayersViewMetricsError = createSelector(
  selectLayersViewMetricsEntityWithID,
  state => state && state.error
)

// Grouper Selectors
export const selectGrouperState = createSelector(
  selectAnalysis,
  state => state?.grouper
)
export const selectGrouperLoading = createSelector(
  selectGrouperState,
  state => state?.loading
)
export const selectGrouperError = createSelector(
  selectGrouperState,
  state => state?.error
)
export const selectGrouperSaving = createSelector(
  selectGrouperState,
  selectCededLayersSaving,
  (state, layersSaving) => state?.saving || layersSaving
)
export const selectGrouperAnalysisProfileID = createSelector(
  selectGrouperState,
  state => state.analysisProfileID
)
export const selectGrouperYearID = createSelector(
  selectGrouperState,
  state => state.yearID
)
export const selectGrouperSlides = createSelector(
  selectGrouperState,
  state => state.slides
)
export const selectGrouperTowerSizePercentage = createSelector(
  selectGrouperState,
  state => state.view.towerSizePercentage
)
export const selectGrouperSlidesPerView = createSelector(
  selectGrouperState,
  state => state.view.slidesPerView
)
export const selectGrouperSlideIndex = createSelector(
  selectGrouperState,
  state => state.view.slideIndex
)

export const selectGroupCurrency = createSelector(
  selectGrouperState,
  state => state.groupCurrency
)

// Grouper / Program
export const selectGrouperProgramState = createSelector(
  selectGrouperState,
  state => state.programs
)
export const {
  selectAll: selectGrouperProgramEntities,
  selectEntities: selectGrouperProgramEntitiesByID,
} = fromGrouperProgram.adapter.getSelectors(selectGrouperProgramState)

export const selectGrouperProgramIDs = createSelector(
  selectGrouperProgramEntities,
  entities => entities.map(e => e.program.id)
)
export const selectGrouperPrograms = createSelector(
  selectGrouperProgramEntities,
  state => state.map(p => p.program)
)
export const selectGrouperMinimizedProgramsByID = createSelector(
  selectGrouperProgramState,
  state => state.minimized
)
export const selectAllGrouperProgramCededLayerStates = createSelector(
  selectGrouperProgramEntities,
  chain(e => e.cededLayers)
)

export const selectAllGrouperProgramCededLayerStatesDictionary = createSelector(
  selectAllGrouperProgramCededLayerStates,
  state => {
    const obj: { [key: string]: fromLayers.LayerState } = {}
    state.forEach(s => {
      obj[s.layer.id] = s
    })
    return obj
  }
)

export const selectAllGrouperProgramCededLayers = createSelector(
  selectAllGrouperProgramCededLayerStates,
  map(s => s.layer)
)

export const selectAllGrouperProgramCededLayersFilteredIDs = createSelector(
  selectAllGrouperProgramCededLayerStates,
  state => state.filter(filterTowerLayers).map(l => l.layer.id)
)
export const selectAllQuoteGrouperProgramCededLayersFilteredIDs =
  createSelector(selectAllGrouperProgramCededLayerStates, state =>
    state.filter(filterQuoteTowerLayers).map(l => l.layer.id)
  )

// Grouper / Program Group
export const selectGrouperProgramGroupState = createSelector(
  selectGrouperState,
  state => state.programGroups
)
export const {
  selectAll: selectGrouperProgramGroupEntities,
  selectEntities: selectGrouperProgramGroupEntitiesByID,
} = fromGroupProgram.adapter.getSelectors(selectGrouperProgramGroupState)

export const selectGrouperProgramGroupIDs = createSelector(
  selectGrouperProgramGroupEntities,
  entities => entities.map(e => e.programGroup.id)
)

export const selectGrouperProgramGroups = createSelector(
  selectGrouperProgramGroupEntities,
  state => state.filter(pg => !pg.deleted).map(pg => pg.programGroup)
)
export const selectGrouperDirtyProgramGroups = createSelector(
  selectGrouperProgramGroupEntities,
  state => state.filter(pg => !pg.untitled && (pg.dirty || pg.new))
)
export const selectGrouperMinimizedProgramGroupsByID = createSelector(
  selectGrouperProgramGroupState,
  state => state.minimized
)
export const selectGrouperGroupBarsByID = createSelector(
  selectGrouperSlides,
  fromGrouperSelectors.selectGroupBarsByID
)
export const selectGrouperSlideProgramEntities = createSelector(
  selectGrouperProgramGroupEntitiesByID,
  selectGrouperProgramEntitiesByID,
  selectGrouperSlides,
  fromGrouperSelectors.selectProgramEntitiesForSlides
)
export const selectGrouperSlideProgramEntitiesData = createSelector(
  selectGrouperProgramGroupEntitiesByID,
  selectGrouperProgramEntitiesByID,
  selectGrouperSlides,
  fromGrouperSelectors.selectProgramEntitiesForSlidesData
)

// Grouper / Program Group Member
export const selectGrouperProgramGroupMembersState = createSelector(
  selectGrouperState,
  state => state?.programGroupMembers
)
export const { selectAll: selectGrouperProgramGroupMemberEntities } =
  fromGrouperProgramMember.adapter.getSelectors(
    selectGrouperProgramGroupMembersState
  )
export const selectGrouperProgramGroupMembers = createSelector(
  selectGrouperProgramGroupMemberEntities,
  state => state?.map(pgm => pgm.programGroupMember)
)
export const selectGrouperProgramGroupMembersToSave = createSelector(
  selectGrouperProgramGroupState,
  selectGrouperProgramGroupMemberEntities,
  (groupState, state) =>
    state?.filter(pgm => {
      if ((pgm.dirty || pgm.new) && !pgm.root) {
        const parentID = pgm.programGroupMember.parentGroupID
        const group = fromGroupProgram.selectByID(parentID, groupState)
        return pgm.deleted || (group && !group.untitled)
      }
      return false
    })
)

export const selectGrouperIsDirty = createSelector(
  selectGrouperDirtyProgramGroups,
  selectGrouperProgramGroupMembersToSave,
  (groups, members) =>
    groups != null &&
    members != null &&
    (groups.length > 0 || members.length > 0)
)

export const selectGrouperProgramGroupsToSave = createSelector(
  selectGrouperState,
  selectGrouperDirtyProgramGroups,
  selectGrouperProgramGroupMembersToSave,
  fromGrouperSelectors.selectDirtyProgramGroupsWithPortfolioIDs
)

export const selectGrouperProgramEntitiesByProgramGroupEntity = createSelector(
  selectGrouperSlides,
  selectGrouperProgramEntitiesByID,
  selectGrouperProgramGroupEntitiesByID,
  fromGrouperSelectors.selectStructureMap
)

// Grouper / Shared Limit
export const selectGrouperSharedLimitState = createSelector(
  selectGrouperState,
  state => state.sharedLimit
)
export const selectSharedLimitMode = createSelector(
  selectGrouperSharedLimitState,
  state => state.mode
)
export const selectSharedLimitEditLayer = createSelector(
  selectGrouperSharedLimitState,
  state => state.editLayer
)
export const selectSharedLimitAddLayerSelections = createSelector(
  selectGrouperSharedLimitState,
  state => state.addLayers
)
export const selectSharedLimitProperties = createSelector(
  selectGrouperSharedLimitState,
  state => state.properties
)
export const selectSharedLimitSaving = createSelector(
  selectGrouperSharedLimitState,
  state => state.saving
)
export const selectSharedLimitDeleting = createSelector(
  selectGrouperSharedLimitState,
  state => state.deleting
)
export const selectSharedLimitUpdating = createSelector(
  selectGrouperSharedLimitState,
  state => state.updating
)
export const selectSharedLimitSelectedLayer = createSelector(
  selectGrouperSharedLimitState,
  state => state.selectedLayer
)
export const selectSharedLimitSelectedProgram = createSelector(
  selectGrouperSharedLimitState,
  state => state.selectedProgram
)
export const selectCurrentSLCededLayer = createSelector(
  selectAllGrouperProgramCededLayerStates,
  selectSharedLimitSelectedLayer,
  (state: fromLayers.LayerState[], layerId: string) =>
    // tslint:disable-next-line: no-non-null-assertion
    layerId ? state.find(p => p.layer.id === layerId)! : null
)

export const selectSharedLimitLayers = createSelector(
  selectAllGrouperProgramCededLayers,
  filter(isLayerShared)
)

export const selectGrouperPortfolioSetID = createSelector(
  selectGrouperPrograms,
  selectSharedLimitSelectedProgram,
  (programs, programId) =>
    extractPortfolioSetID(programs.find(p => p.id === programId))
)
export const selectPortfolioSLSet = createSelector(
  selectGrouperPortfolioSetID,
  selectPortfolioSetEntitiesByID,
  (
    editorPortfolioSetID: PortfolioSetID | null,
    entities: Dictionary<fromPortfolioSet.PortfolioSetEntity>,
    props?: SelectPortfolioSetProps
  ) => {
    let entity: fromPortfolioSet.PortfolioSetEntity | undefined
    const id = (props && props.portfolioSetID) || editorPortfolioSetID
    if (id) {
      const key = fromPortfolioSet.buildPortfolioSetKey(id)
      entity = entities[key]
    }
    return entity || fromPortfolioSet.initialEntity
  }
)
export const selectPortfolioViewSLState = createSelector(
  selectPortfolioSLSet,
  state => state.portfolioView
)
export const selectCededPortfolioViewLayersSLViewIDs = createSelector(
  selectPortfolioViewSLState,
  state => state.cededLayersViewIDs
)
export const selectGrossPortfolioSLViewID = createSelector(
  selectPortfolioViewSLState,
  state => state.grossPortfolioViewID
)

export const selectSLViewMetricsState = createSelector(
  selectPortfolioSLSet,
  state => state.viewMetrics
)
export const selectLayersSLViewMetricsState = createSelector(
  selectSLViewMetricsState,
  state => state.layersViewMetrics
)
export const selectLayersSLViewMetricsEntityWithID = createSelector(
  selectLayersSLViewMetricsState,
  (state: fromLayersViewMetrics.State, props?: SelectPortfolioSetProps) => {
    const key = props && props.layerViewMetricID
    return (key && state.byID[key]) || null
  }
)
export const selectLayersSLViewMetricsWithID = createSelector(
  selectLayersSLViewMetricsEntityWithID,
  state => state && state.metrics
)

export const selectSLCurrentStudyID = createSelector(
  selectGrouperPrograms,
  selectSharedLimitSelectedProgram,
  (programs, programId) => programs.find(p => p.id === programId)?.studyID
)

// Grouper Inurance

export const selectGrouperInuranceState = createSelector(
  selectGrouperState,
  state => state.inurance
)

export const selectGrouperInuranceMode = createSelector(
  selectGrouperInuranceState,
  state => state.mode
)

export const selectGrouperInuranceIsOpen = createSelector(
  selectGrouperInuranceMode,
  mode => mode !== 'none'
)

export const selectGrouperInuranceError = createSelector(
  selectGrouperInuranceState,
  state => state.error
)

export const selectGrouperInuranceSymbolMap = createSelector(
  selectGrouperInuranceState,
  state => state.symbolMap
)

export const selectGrouperInuranceTagsByLevel = createSelector(
  selectGrouperInuranceState,
  state => state.tagsByLevel
)

export const selectNextGrouperInuranceSymbol = createSelector(
  selectGrouperInuranceSymbolMap,
  getNextInuranceSymbol
)

export const selectGrouperInuranceSource = createSelector(
  selectGrouperInuranceState,
  state => state.source
)

export const selectGrouperInuranceTarget = createSelector(
  selectGrouperInuranceState,
  state => state.target
)

export const selectGrouperInuranceCursor = createSelector(
  selectGrouperInuranceState,
  state => (state.mode === 'new' ? state.cursor : null)
)

export const selectGrouperInuranceSaving = createSelector(
  selectGrouperInuranceState,
  state => state.saving
)

export const selectGrouperInuranceDeleting = createSelector(
  selectGrouperInuranceState,
  state => state.deleting
)

export const selectGrouperInuranceFromDesignSaved = createSelector(
  selectGrouperInuranceState,
  state => state.fromDesignSaved
)

export const selectInuranceLoadingInDesign = createSelector(
  selectGrouperInuranceState,
  state => state.loadingInDesign
)

export const selectInuranceLoadingInMultiSection = createSelector(
  selectGrouperInuranceState,
  state => state.loadingInMultiSection
)

export const selectGrouperInurancePayload = createSelector(
  selectGrouperInuranceSource,
  selectGrouperInuranceTarget,
  selectGrouperProgramEntitiesByProgramGroupEntity,
  selectGrouperProgramEntitiesByID,
  createInurancePayload
)

export const selectToDeleteFromDesign = createSelector(
  selectGrouperInuranceState,
  state => state.toDeleteFromDesign
)

// Grouper Layers by Structure
export const selectLayersByStructureState = createSelector(
  selectGrouperState,
  state => state.layersByStructure
)

export const selectSourceLayersByStructure = createSelector(
  selectLayersByStructureState,
  state => state.source.layers
)

export const selectSwapLayersByStructure = createSelector(
  selectLayersByStructureState,
  state => state.swap.layers
)

export const selectTargetLayersByStructure = createSelector(
  selectLayersByStructureState,
  state => state.target.layers
)

export const selectSourceLayersByStructureLoading = createSelector(
  selectLayersByStructureState,
  state => state.source.loading
)

export const selectSwapLayersByStructureLoading = createSelector(
  selectLayersByStructureState,
  state => state.swap.loading
)

export const selectTargetLayersByStructureLoading = createSelector(
  selectLayersByStructureState,
  state => state.target.loading
)

// Compare Selectors

export const selectCompareState = createSelector(
  selectAnalysis,
  state => state.compare
)
export const {
  selectAll: selectCompareEntities,
  selectEntities: selectCompareEntitiesByID,
  selectIds: selectCompareEntityIDs,
} = fromCompare.adapter.getSelectors(selectCompareState)

export const selectCompareProgramIDs = createSelector(
  selectCompareEntities,
  entities => entities.map(e => e.program.id)
)
export const selectComparePrograms = createSelector(
  selectCompareEntities,
  entities => entities.map(e => e.program)
)
export const selectCompareLayersList = createSelector(
  fromBroker.selectCurrentClientID,
  selectCompareEntities,
  fromCompare.selectLayersList
)
export const selectCompareExpandedCategories = createSelector(
  selectCompareState,
  state => state.expandedCategories
)
export const selectCompareGrossSelected = createSelector(
  selectCompareState,
  state => state.grossSelected
)
export const selectCompareHiddenMetricRanks = createSelector(
  selectCompareState,
  state => state.hiddenMetricRanks
)
export const selectCompareExpandedChangeMetrics = createSelector(
  selectCompareState,
  state => state.expandedChangeMetrics
)
export const selectCompareSlidesPerView = createSelector(
  selectCompareState,
  state => state.view.slidesPerView
)
export const selectCompareTowerSizePercentage = createSelector(
  selectCompareState,
  state => state.view.towerSizePercentage
)
export const selectCompareProgramGroups = createSelector(
  selectCompareState,
  state => state.programGroups
)

export const selectCompareCurrency = createSelector(
  selectCompareState,
  state => state.compareCurrency
)

export const selectCompareConversion = createSelector(
  selectCompareState,
  state => state.compareConversion
)
export const selectCompareTabIndex = createSelector(
  selectCompareState,
  state => state.tabIndex
)

export const selectCompareStructureOptions = createSelector(
  selectCompareState,
  state => state.structureOptions
)

export const selectCompareViews = createSelector(
  selectCompareState,
  state => state.view.compareViews
)

export const selectSelectedCompareView = createSelector(
  selectCompareState,
  state => state.view.selectedCompareView
)

// Compare / Settings

export const selectCompareMetricSettingsState = createSelector(
  selectCompareState,
  state => state.metricSettings
)

export const {
  selectAll: selectCompareMetricSettingsEntities,
  selectEntities: selectCompareMetricSettingsEntitiesByID,
} = fromCompareMetricSettings.adapter.getSelectors(
  selectCompareMetricSettingsState
)

export const selectCompareMetricSettingsStudyID = createSelector(
  selectCompareMetricSettingsState,
  state => state.studyID
)

export const selectCustomCompareMetricSettings = createSelector(
  selectCompareMetricSettingsEntities,
  entities => {
    return entities.filter(e => e.category === 'Custom Metrics')
  }
)

export const selectCompareStructureOptionsView = createSelector(
  selectCompareStructureOptions,
  selectCompareEntities,
  selectCompareMetricSettingsEntities,
  selectCompareCurrency,
  selectCompareGrossSelected,
  (
    state,
    compareEntites,
    metricSettings,
    compareCurrency,
    grossSelected
  ): CompareStructureOptionsView => {
    if (compareEntites.length === 0) {
      return { loading: true, data: [] }
    }
    if (
      compareEntites.length > 0 &&
      compareEntites[compareEntites.length - 1].metricCategories
    ) {
      let data = compareEntites.map(
        createDatum(state, metricSettings, compareCurrency)
      )

      if (grossSelected) {
        const firstCompareEntites = [compareEntites[0]]
        let grossData = firstCompareEntites.map(
          createDatum(state, metricSettings, compareCurrency, grossSelected)
        )
        grossData = grossData.filter(ls => {
          return ls.x || ls.y
        })
        data.push(...grossData)
      }

      return {
        loading: false,
        data,
      }
    } else {
      return { loading: true, data: [] }
    }
  }
)

// Metrics Settings

export const selectMetricTableSettingsState = createSelector(
  selectAnalysis,
  state => state.metric
)
export const {
  selectAll: selectMetricTableSettingsEntities,
  selectEntities: selectMetricTableSettingsEntitiesByID,
} = fromMetricTableSettings.adapter.getSelectors(selectMetricTableSettingsState)

export const selectMetricTableSettingsStudyID = createSelector(
  selectMetricTableSettingsState,
  state => state.studyID
)

export const selectUniqueMetricTableSettingsEntities = createSelector(
  selectMetricTableSettingsEntities,
  state =>
    state.filter(
      (s, index, self) => index === self.findIndex(t => t.label === s.label)
    )
)

export const selectMetricTableExpandedCategories = createSelector(
  selectMetricTableSettingsState,
  state => state.expandedCategories
)

export const selectMetricTableSelectedStudyName = createSelector(
  fromBroker.selectCurrentClientStudies,
  selectMetricTableSettingsStudyID,
  (study, studyID) => {
    const studySelected = study.find(s => s.id === studyID)
    return studySelected ? studySelected.name : ''
  }
)

export const selectMetricTableSettingsCategories = createSelector(
  selectMetricTableSettingsEntities,
  createAllMetricTableCategoryValues
)

export const selectMetricTableCalculatedWeight = createSelector(
  selectMetricTableSettingsEntities,
  entities => {
    return entities.reduce((weight: number, e) => {
      return e.show ? weight + e.weight : weight
    }, 0)
  }
)

export const selectMetricTableSettingsSaving = createSelector(
  selectMetricTableSettingsState,
  state => state.saving
)

export const selectMetricTableSettingsLoading = createSelector(
  selectMetricTableSettingsState,
  state => state.loading
)

export const selectCurrentStudyMetricTableSettings = createSelector(
  selectMetricTableSettingsEntities,
  selectMetricTableSettingsStudyID,
  (entities, studySave) => {
    return studySave
      ? entities.map(e => formatMetricSettingsSave(e, studySave))
      : []
  }
)

export const selectSavingAsClone = createSelector(
  selectEditorState,
  state => state.savingAsClone
)

export const selectDesignTagsByLevel = createSelector(
  selectEditorState,
  state => state.inurance.tagsByLevel
)

export const selectAddingNewDropLayerSaving = createSelector(
  selectEditorState,
  state => state.isNewDropLayerSaving
)

// Explore

export const selectExploreState = createSelector(
  selectAnalysis,
  state => state.explore
)

export const selectExploreLoading = createSelector(
  selectExploreState,
  state => state.loading
)

export const selectExploreError = createSelector(
  selectExploreState,
  state => state.error
)

export const selectSelectedExploreLossSetIDs = createSelector(
  selectExploreState,
  state => state.savedLossSetIDs
)

export const selectAllLossDistributionDataTable = createSelector(
  selectExploreState,
  state => state.entities
)

export const selectCurrentLossDistributionDataTable = createSelector(
  selectAllLossDistributionDataTable,
  selectSelectedExploreLossSetIDs,
  (entities, savedLossSetIDs) => {
    if (entities) {
      // Sort columns in the same order they were selected in dropdown.
      const values = Object.values(entities)
      const sortedValues: LossSetTableState[] = []
      savedLossSetIDs.forEach(lossID => {
        const value = values.find(v => v?.lossID === lossID)
        if (value) {
          sortedValues.push(value)
        }
      })
      return sortedValues
    }
    return null
  }
)

export const selectExploreProgram = createSelector(
  selectExploreState,
  state => state.program
)

export const selectExploreProgramAnalysisID = createSelector(
  selectExploreProgram,
  state => {
    if (state) {
      return state.analysisID
    }
    return null
  }
)

export const selectExploreLossSetLayers = createSelector(
  selectExploreState,
  state => state.lossSetLayers
)

export const selectExploreSummaryData = createSelector(
  selectExploreState,
  state => state.summaryData
)

export const selectExploreGroupSummaryData = createSelector(
  selectExploreState,
  state => state.groupSummaryData
)

export const selectExploreSummaryViews = createSelector(
  selectExploreState,
  state => state.explore_gross_views
)
export const selectExploreSummaryLoading = createSelector(
  selectExploreState,
  state => state.summaryLoading
)

export const selectExploreLossSetGroups = createSelector(
  selectExploreState,
  state => state.lossSetLayerGroups
)

export const selectExplorePortfolioSetID = createSelector(
  selectExploreProgram,
  program => {
    if (program) {
      const portfolioSetID: PortfolioSetID = {
        analysisProfileID: program.analysisID,
        cededPortfolioID: program.cededPortfolioID,
        grossPortfolioID: program.grossPortfolioID,
        netPortfolioID: program.netPortfolioID,
      }
      return portfolioSetID
    }
  }
)

export const selectExploreGrossPortfolioView = createSelector(
  selectExploreState,
  state => state.grossPortfolioView
)

export const selectExploreRP = createSelector(
  selectExploreState,
  state => state.arrRP
)

export const selectExploreModifiers = createSelector(
  selectExploreState,
  state => state.modifiers
)

// Technical Premium

export const selectTechnicalPremiumState = createSelector(
  selectEditorState,
  state => state.technicalPremium
)

export const selectLayerTypeTechnicalPremiumValues = createSelector(
  selectTechnicalPremiumState,
  state => state.layerTypeDefaults
)

export const selectInitialLayerTypeTechnicalPremiumValues = createSelector(
  selectTechnicalPremiumState,
  state => state.defaultsInitialState
)

export const selectTechnicalPremiumLayerEntries = createSelector(
  selectTechnicalPremiumState,
  state => state.layerEntries
)

export const selectTechnicalPremiumIsDirty = createSelector(
  selectLayerTypeTechnicalPremiumValues,
  state => Object.values(state).some(entry => entry.modified)
)

export const selectTechnicalPremiumIsValid = createSelector(
  selectLayerTypeTechnicalPremiumValues,
  state =>
    Object.values(state).every(entry =>
      isCurveWeightTotalValid(entry.pricingCurves)
    )
)

export const selectTechnicalPremiumIsLoading = createSelector(
  selectTechnicalPremiumState,
  state => state.loading
)

export const selectTechnicalPremiumCanSave = createSelector(
  selectTechnicalPremiumIsDirty,
  selectTechnicalPremiumIsValid,
  selectTechnicalPremiumIsLoading,
  (isDirty, isValid, isLoading) => isDirty && isValid && !isLoading
)

const makeGetValue =
  (
    state: fromCompare.StructureOptions,
    compareEntites: fromCompare.CompareEntity,
    metricSettings: CompareMetricSetting[],
    grossSelected?: boolean
  ) =>
  (dimension: CompareStructureOptionsDimension): number => {
    const propID = state[dimension]
    const prop = metricSettings.find(p => p.category + ' ' + p.label === propID)

    if (propID === 'None') {
      return 500
    }
    if (!prop) {
      return 500
    }
    const metricsArray: CompareMetricValue[][] = []
    compareEntites.metricCategories.forEach(m =>
      metricsArray.push(...m.metrics)
    )
    let value: number | undefined
    if (grossSelected) {
      if (
        prop.category === 'Gross Metrics' ||
        prop.category === 'Ceded Cost Metrics' ||
        prop.category === 'Volatility Metrics' ||
        prop.category === 'Capital Metrics' ||
        prop.category === 'Efficiency Metrics' ||
        prop.category === 'Misc'
      ) {
        return 0
      } else {
        value = metricsArray.flat().find(ma => ma.label === prop.label)?.value
      }
    } else {
      value = metricsArray
        .flat()
        .find(ma => ma.category + ' ' + ma.label === propID)?.value
    }

    if (value == null || typeof value !== 'number') {
      return 0
    }
    if (value >= 1e21) {
      return 0
    }
    return value
  }

const createDatum =
  (
    state: fromCompare.StructureOptions,
    metricSettings: CompareMetricSetting[],
    compareCurrency: string | null,
    grossSelected?: boolean
  ) =>
  (compareEntites: fromCompare.CompareEntity): CompareStructureOptionsDatum => {
    let getValue: any
    let description: string
    if (grossSelected) {
      getValue = makeGetValue(
        state,
        compareEntites,
        metricSettings,
        grossSelected
      )
      description = 'Gross Metrics'
    } else {
      getValue = makeGetValue(state, compareEntites, metricSettings)
      description = compareEntites.program.label
    }
    const x = getValue('x')
    const y = getValue('y')
    const size = getValue('size')

    const currency = compareCurrency || 'USD'
    return { description, x, y, size, currency }
  }
