<app-client-select
  class="field client"
  [clients]="clients"
  [selectedClientID]="selectedClientID"
  [readonly]="readonly"
  (clientChange)="onClientChange($event)"
></app-client-select>

<mat-form-field
  *ngIf="!clientSelect"
  class="app-bar-field field year"
  [ngClass]="{
    'app-bar-field-big': size === 'big',
    'app-bar-field-button': isButton
  }"
>
  <mat-label [ngClass]="{ disabled: years.length === 0 }">
    <span class="dropdown-label">{{ yearLabel }}</span>
  </mat-label>
  <mat-select
    hideSingleSelectionIndicator
    appBackdropClass="app-tier-select-backdrop"
    panelClass="app-bar-panel"
    placeholder="Year..."
    disableRipple
    [value]="selectedYearID"
    [disabled]="years.length === 0 || readonly"
    (selectionChange)="onYearChange($event)"
  >
    <mat-option *ngFor="let year of years.slice().reverse()" [value]="year.id">
      <span>{{ year.year }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field
  *ngIf="!clientSelect"
  class="app-bar-field field program"
  [ngClass]="{
    'app-bar-field-big': size === 'big',
    'app-bar-field-button': isButton
  }"
>
  <mat-label [ngClass]="{ disabled: programs.length === 0 }">
    <span class="dropdown-label">{{ programLabel }}</span>
  </mat-label>
  <mat-select
    hideSingleSelectionIndicator
    appBackdropClass="app-tier-select-backdrop"
    panelClass="app-bar-panel programs"
    placeholder="Program..."
    disableRipple
    [value]="selectedProgramID"
    [disabled]="programDisabled"
    (selectionChange)="onProgramChange($event)"
  >
    <mat-select-trigger *ngIf="selectedProgramID">
      <span>{{ selectedProgramName }} </span>
    </mat-select-trigger>
    <mat-option *ngFor="let program of programs" [value]="program.id">
      <div class="item">
        <span>{{ program.name }} </span>
        <mat-icon
          inline
          *ngIf="program.opportunity_id"
          [matTooltip]="
            'From Salesforce Opportunity: ' +
            getOpportunity(program.opportunity_id)?.opportunityName
          "
          matTooltipPosition="right"
          >cloud</mat-icon
        >
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>

<ng-container
  *ngIf="
    (programGroups || structures || sharedLimits) && (isQuote || isSignature)
  "
>
  <!-- Program Group or Structure Selector -->
  <mat-form-field
    *ngIf="!clientSelect"
    class="app-bar-field field program"
    [ngClass]="{
      'app-bar-field-big': size === 'big',
      'app-bar-field-button': isButton
    }"
  >
    <mat-label [ngClass]="{ disabled: structures.length === 0 }">
      <span class="dropdown-label">Group, SL or Structure Filter</span>
    </mat-label>
    <mat-select
      hideSingleSelectionIndicator
      appBackdropClass="app-tier-select-backdrop"
      panelClass="app-bar-panel"
      placeholder=""
      disableRipple
      [value]="groupOrStructureFilterSelected"
      [disabled]="structures.length === 0 || readonly"
      (selectionChange)="onGroupOrStructureSelectionChange($event)"
    >
      <mat-option value="Group">
        <span>Group</span>
      </mat-option>
      <mat-option value="SL">
        <span>Shared Limit</span>
      </mat-option>
      <mat-option value="Structure">
        <span>Structure</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="isQuote">
    <ng-container *ngIf="groupOrStructureFilterSelected === 'Group'">
      <!-- Program Group Selector -->
      <mat-form-field
        *ngIf="!clientSelect"
        class="app-bar-field field program"
        [ngClass]="{
          'app-bar-field-big': size === 'big',
          'app-bar-field-button': isButton
        }"
      >
        <mat-label [ngClass]="{ disabled: programGroups.length === 0 }">
          <span class="dropdown-label">{{ programGroupLabel }}</span>
        </mat-label>
        <mat-select
          #structureSelect
          hideSingleSelectionIndicator
            appBackdropClass="app-tier-select-backdrop"
          panelClass="app-bar-panel fit-width"
          placeholder="Program Group..."
          disableRipple
          [value]="selectedProgramGroup"
          [disabled]="programGroups.length === 0 || readonly"
          (selectionChange)="onProgramGroupChange($event)"
        >
          <mat-select-trigger *ngIf="selectedProgramGroup">
            {{ getLabel(selectedProgramGroup, 'Group') }}</mat-select-trigger
          >
          <mat-option
            *ngFor="let programGroup of programGroups"
            [value]="programGroup.id"
          >
            <span
              >{{ programGroup.label }}
              <mat-chip-list class="stlabel">
                <mat-chip-row
                  *ngIf="programGroup.fotCount && programGroup.fotCount > 0"
                  class="fot"
                >
                  fot: {{ programGroup.fotCount }}
                </mat-chip-row>
                <mat-chip-row
                  *ngIf="programGroup.quoteCount && programGroup.quoteCount > 0"
                  class="quote"
                >
                  quote: {{ programGroup.quoteCount }}
                </mat-chip-row>
              </mat-chip-list>
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="groupOrStructureFilterSelected === 'SL'">
      <!-- Shared Limit Selector -->
      <mat-form-field
        *ngIf="!clientSelect"
        class="app-bar-field field program"
        [ngClass]="{
          'app-bar-field-big': size === 'big',
          'app-bar-field-button': isButton
        }"
      >
        <mat-label [ngClass]="{ disabled: sharedLimits.length === 0 }">
          <span class="dropdown-label">{{ sharedLimitLabel }}</span>
        </mat-label>
        <mat-select
          #structureSelect
          hideSingleSelectionIndicator
            appBackdropClass="app-tier-select-backdrop"
          panelClass="app-bar-panel fit-width"
          placeholder="Shared Limits..."
          disableRipple
          [value]="selectedSharedLimit"
          [disabled]="sharedLimits.length === 0 || readonly"
          (selectionChange)="onSharedLimitChange($event)"
        >
          <mat-select-trigger *ngIf="selectedSharedLimit">
            {{ getLabel(selectedSharedLimit, 'SL') }}</mat-select-trigger
          >
          <mat-option *ngFor="let sl of sharedLimits" [value]="sl.id">
            <span
              >{{ sl.sl_name }}
              <mat-chip-list class="stlabel">
                <mat-chip-row *ngIf="sl.fot_count && sl.fot_count > 0" class="fot">
                  fot: {{ sl.fot_count }}
                </mat-chip-row>
                <mat-chip-row
                  *ngIf="sl.quote_count && sl.quote_count > 0"
                  class="quote"
                >
                  quote: {{ sl.quote_count }}
                </mat-chip-row>
              </mat-chip-list>
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="groupOrStructureFilterSelected === 'Structure'">
      <!-- Structure Selector -->
      <mat-form-field
        *ngIf="!clientSelect"
        class="app-bar-field field program"
        [ngClass]="{
          'app-bar-field-big': size === 'big',
          'app-bar-field-button': isButton
        }"
      >
        <mat-label [ngClass]="{ disabled: structures.length === 0 }">
          <span class="dropdown-label">{{ structureLabel }}</span>
        </mat-label>
        <mat-select
          #structureSelect
          hideSingleSelectionIndicator
            appBackdropClass="app-tier-select-backdrop"
          panelClass="app-bar-panel fit-width"
          placeholder="Structure..."
          disableRipple
          [value]="selectedStructure"
          [disabled]="structures.length === 0 || readonly"
          (selectionChange)="onStructureChange($event)"
        >
          <mat-select-trigger *ngIf="selectedStructure">
            {{ getLabel(selectedStructure, 'Structure') }}</mat-select-trigger
          >
          <mat-option
            *ngFor="let structure of structures"
            [value]="structure.id"
          >
            {{ structure.label }}
            <mat-chip-list class="stlabel">
              <mat-chip-row
                *ngIf="structure.fotCount && structure.fotCount > 0"
                class="fot"
              >
                fot: {{ structure.fotCount }}
              </mat-chip-row>
              <mat-chip-row
                *ngIf="structure.quoteCount && structure.quoteCount > 0"
                class="quote"
              >
                quote: {{ structure.quoteCount }}
              </mat-chip-row>
            </mat-chip-list>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="!clientSelect"
        class="app-bar-field field program"
        [ngClass]="{
          'app-bar-field-big': size === 'big',
          'app-bar-field-button': isButton
        }"
      >
        <mat-label
          [ngClass]="{ disabled: getFilteredQuoteLayerState.length === 0 }"
        >
          <span class="dropdown-label">{{ qouteLayerLabel }}</span>
        </mat-label>
        <mat-select
          #quoteLayersSelect
          hideSingleSelectionIndicator
            appBackdropClass="app-tier-select-backdrop"
          panelClass="app-bar-panel fit-width"
          placeholder="Layers..."
          disableRipple
          [(value)]="selectedLayer"
          [disabled]="
            getFilteredQuoteLayerState.length === 0 ||
            readonly ||
            !selectedStructureID
          "
          (selectionChange)="onLayerSelection($event.value)"
        >
          <mat-select-trigger *ngIf="selectedLayer">
            {{ getLabel(selectedLayer, 'Layer') }}</mat-select-trigger
          >
          <mat-option
            *ngFor="let layer of getFilteredQuoteLayerState"
            [value]="layer.layer"
          >
            <span
              >{{ layer.layer.physicalLayer.description }}
              {{ SLType(layer.layer) }}
              <mat-chip-list class="stlabel">
                <mat-chip-row
                  *ngIf="layerFOTOrQuote(layer.layer, 'FOT')"
                  class="fot"
                >
                  fot: {{ layerFOTOrQuote(layer.layer, 'FOT', true) }}
                </mat-chip-row>
                <mat-chip-row
                  *ngIf="layerFOTOrQuote(layer.layer, 'QUOTE')"
                  class="quote"
                >
                  quote: {{ layerFOTOrQuote(layer.layer, 'QUOTE', true) }}
                </mat-chip-row>
              </mat-chip-list></span
            >
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="!clientSelect && lobSelect"
        class="app-bar-field field program"
        [ngClass]="{
          'app-bar-field-big': size === 'big',
          'app-bar-field-button': isButton
        }"
      >
        <mat-label [ngClass]="{ disabled: lobs.length === 0 }">
          <span class="dropdown-label">{{ lobLabel }}</span>
        </mat-label>
        <mat-select
          hideSingleSelectionIndicator
          appBackdropClass="app-tier-select-backdrop"
          panelClass="app-bar-panel"
          placeholder="LOB..."
          disableRipple
          [value]="selectedLob"
          [disabled]="lobs.length === 0 || exploreLoading || readonly"
          (selectionChange)="onLobChange($event)"
        >
          <mat-option *ngFor="let lob of lobs" [value]="lob.meta_data.lob">
            <span>{{ lob.meta_data.ls_dim1 }}</span>
          </mat-option>
          <mat-option value="0">
            <span>Total</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </ng-container>
</ng-container>
