<app-pricing-curve-header
  [name]="name"
  [status]="status"
  [pricingCurves]="pricingCurves"
  [context]="context"
  [graphSettings]="graphSettings"
></app-pricing-curve-header>
<main>
  <div class="wrapper">
    <div
      class="sidebar"
      [ngClass]="{ hide: !isSidebarOpen, closed: !isSidebarOpen }"
    >
      <app-pricing-curve-sidebar
        [open]="isSidebarOpen"
        [savedCurves]="savedCurves"
        [username]="username"
        [status]="status"
        [context]="context"
        [addedCurves]="pricingCurves"
      ></app-pricing-curve-sidebar>
    </div>
    <div class="right-content-section">
      <app-panel-toggle-button
        class="toggle-sidebar"
        [open]="isSidebarOpen"
        [hover]="pricingCurveSidebarToggleHover"
        (mouseover)="pricingCurveSidebarToggleHover = true"
        (mouseout)="pricingCurveSidebarToggleHover = false"
        (toggleClick)="isSidebarOpen = !isSidebarOpen"
      ></app-panel-toggle-button>
      <app-pricing-curve-content
        [pricingCurves]="pricingCurves"
        [status]="status"
        [savedCurves]="savedCurves"
        [context]="context"
        [graphSettings]="graphSettings"
      ></app-pricing-curve-content>
    </div>
  </div>
</main>
