import { SavedPricingCurveEntry } from '../../pricingcurve/model/pricing-curve.model'
import {
  LAYER_PALETTE,
  LAYER_PALETTE_PROGRAMS,
} from '../model/layer-palette.model'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import {
  DefaultSavedCurveEntry,
  GLOBAL_DEFAULT_CURVE_ENTRY,
  LayerEntry,
  PRICING_CURVE_LAYER_TYPE_DEFAULT,
  SavedLayerTypeEntry,
} from './technical-premium.model'

export function isCurveWeightTotalValid(
  curves: DefaultSavedCurveEntry[]
): boolean {
  // Multiply by 100 and check ceiling to avoid floating point errors
  return (
    Math.ceil(
      curves.reduce((acc, curve) => acc + curve.percentage, 0) * 100
    ) === 100
  )
}

export function createDefaultCurveObjectForCurve(
  curve: SavedPricingCurveEntry,
  weight: number = 1
): DefaultSavedCurveEntry {
  return {
    id: curve.id,
    pc_name: curve.pc_name,
    percentage: weight,
    value: 0,
  }
}

export function getPricingCurvesForLayer(
  layer?: LayerState,
  pricingCurveString?: string
): DefaultSavedCurveEntry[] {
  const curveString =
    pricingCurveString ?? layer?.layer.physicalLayer.meta_data.pricingCurves
  if (curveString) {
    return curveString.split(';').map(string => {
      const values = string.split(',')
      return {
        id: Number(values[0]),
        percentage: Number(values[1]),
        pc_name: values[2],
        value: Number(values[3]),
      }
    })
  } else {
    return [PRICING_CURVE_LAYER_TYPE_DEFAULT]
  }
}

export function layerEntryToString(
  layerEntry: LayerEntry,
  premiumValue?: number
): string | undefined {
  if (
    layerEntry.pricingCurves.length === 1 &&
    layerEntry.pricingCurves[0].id === GLOBAL_DEFAULT_CURVE_ENTRY.id
  ) {
    return
  }
  let pricingCurvesString = ''
  layerEntry.pricingCurves.forEach(pc => {
    const premiumString = !!premiumValue ? premiumValue : pc.value
    pricingCurvesString =
      pricingCurvesString +
      pc.id +
      ',' +
      pc.percentage +
      ',' +
      pc.pc_name +
      ',' +
      premiumString +
      ';'
  })
  pricingCurvesString = pricingCurvesString.substring(
    0,
    pricingCurvesString.length - 1
  )
  return pricingCurvesString
}

export function initDefaults(
  entries: Record<string, LayerEntry> = {}
): Record<string, LayerEntry> {
  const newEntries = { ...entries }
  const keys = Object.keys(newEntries)
  const missingKeys = LAYER_PALETTE.filter(item => !item.hidden).filter(
    item => !keys.includes(item.id)
  )
  missingKeys.map((item, index) => {
    let layerType = item.id
    newEntries[layerType] = {
      id: index,
      name: `${LAYER_PALETTE_PROGRAMS[item.program]} - ${item.name}`,
      layerType,
      pricingCurves: [
        createDefaultCurveObjectForCurve(GLOBAL_DEFAULT_CURVE_ENTRY),
      ],
      hasError: false,
      pricingCurveIds: [-1],
      modified: false,
      saveForOnlyNewLayers: false,
    }
  })

  return newEntries
}

export function getSavedLayerTypeEntriesForLayerEntry(
  entry: LayerEntry,
  studyId: number
): SavedLayerTypeEntry[] {
  return entry.pricingCurves.map(curve => ({
    program_id: studyId,
    // tslint:disable-next-line: no-non-null-assertion
    pricing_curve_id: curve.id!,
    layer_type: entry.layerType,
    weight_percent: curve.percentage,
  }))
}
