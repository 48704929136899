<app-tier-structures
  *ngIf="!showDialogFlag"
  small
  [clientSelect]="clientSelect"
  [selectedClientID]="selectedClientID"
  [title]="title"
  [selectedYearID]="selectedYearID$ | async"
  [years]="years$ | async"
  [selectedProgramID]="selectedProgramID$ | async"
  [programs]="programs$ | async"
  [isDesign]="true"
  [editFolderMode]="true"
  [executiveSummaryHidden]="executiveSummaryHidden"
  [clientData]="clientData"
  [structures]="structures$ | async"
  [structureFilter]="structureFilter$ | async"
  [structureGroupMembers]="structureGroupMembers$ | async"
  [accountOpportunities]="accountOpportunities$ | async"
  [studies]="studies$ | async"
  [featureFlags]="featureFlags$ | async"
  [folders]="folders$ | async"
  (showDialogFlag)="onButtonClick($event)"
  (structureSelectionChange)="onStructureSelectionChange($event)"
  (setRoute)="onSetRoute($event)"
  (structureNameDescriptionEdit)="onStructureNameDescriptionEdit($event)"
  (scenarioOrOptimizationSelectChange)="
        onScenarioOrOptimizationSelectChange($event)
      "
  (structureFilterChange)="onStructureFilterChange($event)"
  (sageViewSelectionChange)="onSageViewSelectionChange($event)"
  (structureIndexEdit)="onStructureIndexEdit($event)"
  (updatePositionIndexes)="onUpdatePositionIndexes($event)"
  (updateStructureFolderID)="onUpdateStructureFolderID($event)"
  (updateFolders)="fetchFolders($event)"
></app-tier-structures>
<app-tier-structures-menu
  *ngIf="showDialogFlag"
  isButton
  [showDialogClone]="showDialogFlag"
  [analysisProfileID]="analysisProfileID"
  [yearID]="yearID"
  [years]="years$ | async"
  [years2]="years2$ | async"
  [title]="title"
  [structures]="structures$ | async"
  [structures2]="structures2$ | async"
  [selectedClientID]="selectedClientID"
  [selectedClientID2]="selectedClientID2$ | async"
  [selectedYearID2]="selectedYearID2$ | async"
  [selectedStructureIDs]="programIDs$ | async"
  [selectedStructures]="programIDs$ | async"
  [allowScenarioOrOptimizationSelection]="
        allowScenarioOrOptimizationSelection
      "
  [structureFilter]="structureFilter$ | async"
  [studyID]="studyID$ | async"
  [studyID2]="studyID2$ | async"
  [programs]="programs$ | async"
  [selectedProgramID]="selectedProgramID$ | async"
  [accountOpportunities]="accountOpportunities$ | async"
  [studies]="studies$ | async"
  [featureFlags]="featureFlags$ | async"
  (showDialogFlag)="onButtonClick($event)"
  (structureSelectionChange)="onStructureSelectionChange($event)"
  (structureFilterChange)="onStructureFilterChange2($event)"
  (structureAdd)="onProgramGroupAdd($event)"
  (structureRemove)="onProgramGroupRemove($event)"
  (groupFilterByAssocChange)="onGroupFilterByAssocChange($event)"
></app-tier-structures-menu>
