import {inject, Injectable} from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { of } from 'rxjs'
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'
import { ApiResponse } from '../../../api/model/api.model'
import { OptimizationService } from '../../../api/optimization/optimization.service'
import { executeSequentially } from '../../../api/util'
import { AppState } from '../../../core/store'
import { errorPayload } from '../../../error/model/error'
import { selectCurrentStudyReinsurers } from '../../../reinsurers/store/reinsurers.selectors'
import { analyzereConstants } from '../../../shared/constants/analyzere'
import { LayerView } from '../../model/layer-view'
import { isLayerAggFeeder } from '../../model/layers.util'
import {
  selectCededLayers,
  selectCurrentAnalysisProfileID,
  selectGrossPortfolioViewID,
  selectLossSetGroups,
  selectLossSetLayers,
} from '../../store/analysis.selectors'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import {
  isAgg,
  MAX_UNIQUE_LAYERS,
  OptimizationCandidateLayer,
} from '../optimization.model'
import { OptimizationWorkerMessenger } from '../worker/optimization-messenger'
import * as fromOptimizationLayersActions from './optimization-layers.actions'
import * as fromOptimizationSelectors from './optimization.selectors'
import {
  selectDefaultTechnicalFactors,
  selectSavedCurves,
} from 'src/app/pricingcurve/store/pricing-curve.selectors'

@Injectable()
export class OptimizationLayersEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private optimizationService: OptimizationService
  ) {}

  layers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromOptimizationLayersActions.generateLayers),
      withLatestFrom(
        this.store.pipe(select(fromOptimizationSelectors.selectRangesTypes)),
        this.store.pipe(select(selectCededLayers))
      ),
      switchMap(([{ templates }, ranges, layers]) => {
        const actions: ApiResponse<OptimizationCandidateLayer[]>[] = []
        for (const range of ranges) {
          const template = templates.find(t => t.type === range.id)
          const messenger = new OptimizationWorkerMessenger()
          actions.push(
            messenger
              .getLayers(
                template as LayerView,
                range.ranges,
                MAX_UNIQUE_LAYERS,
                range.lossSetGroupIDs
              )
              .pipe(
                map(r => {
                  if (r.error) {
                    return { error: errorPayload(r.error) }
                  } else {
                    const currentRange = ranges.find(
                      ran => ran.id === r.values[0].type
                    )
                    for (const val of r.values) {
                      val.currency = currentRange?.ranges[0].currency
                    }
                    return { data: r.values }
                  }
                })
              ) as ApiResponse<OptimizationCandidateLayer[]>
          )
        }
        return executeSequentially(actions).pipe(
          map(r => ({ ...r, templates, layers }))
        )
      }),
      map(({ data, error, templates, layers }) => {
        if (error) {
          return { error }
        } else {
          // tslint:disable-next-line: no-non-null-assertion
          let flattenData = data!.flatMap(v => v)
          if (templates.length === 1 && isAgg(templates[0].values)) {
            const feeder = layers.find(l =>
              isLayerAggFeeder(l.layer)
            ) as LayerState
            flattenData = flattenData.map(v => ({
              ...v,
              feederOccurrenceLimit: feeder.layer.physicalLayer.limit.value,
              feederOccurrenceAttachment:
                feeder.layer.physicalLayer.attachment.value,
              feederFranchiseDeductible:
                feeder.layer.physicalLayer.franchise.value,
              feederParticipation: feeder.layer.physicalLayer.participation,
              occurrenceAttachment: 0,
              occurrenceLimit: analyzereConstants.unlimitedValue,
              franchiseDeductible: 0,
            }))
          }
          return { data: flattenData }
        }
      }),
      withLatestFrom(
        this.store.pipe(select(selectCurrentAnalysisProfileID)),
        this.store.pipe(select(selectCededLayers)),
        this.store.pipe(select(selectCurrentStudyReinsurers)),
        this.store.pipe(select(selectLossSetLayers)),
        this.store.pipe(select(selectLossSetGroups))
      ),
      map(x => x),
      withLatestFrom(
        this.store.pipe(select(selectDefaultTechnicalFactors)),
        this.store.pipe(select(selectSavedCurves))
      ),
      switchMap(
        ([
          [
            results,
            analysisProfileID,
            cededLayers,
            reinsurers,
            lossSetLayers,
            lossSetGroups,
          ],
          defaultFactors,
          savedPricingCurves,
        ]) => {
          if (results.error) {
            return of({ error: results.error }) as ApiResponse<
              OptimizationCandidateLayer[]
            >
          } else {
            return this.optimizationService.getInitialMetricsForCandidates(
              results.data as OptimizationCandidateLayer[],
              cededLayers,
              reinsurers,
              lossSetLayers,
              analysisProfileID || '',
              lossSetGroups,
              defaultFactors,
              savedPricingCurves
            )
          }
        }
      ),
      map(results => {
        if (results.error) {
          return fromOptimizationLayersActions.generateLayersFailure({
            error: results.error,
          })
        } else {
          return fromOptimizationLayersActions.generateLayersSuccess({
            values: results.data as OptimizationCandidateLayer[],
          })
        }
      })
    )
  })

  metrics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromOptimizationLayersActions.getLayersMetrics),
      withLatestFrom(
        this.store.pipe(
          select(fromOptimizationSelectors.selectCandidateLayers)
        ),
        this.store.pipe(select(selectCurrentAnalysisProfileID)),
        this.store.pipe(select(selectCededLayers)),
        this.store.pipe(select(selectCurrentStudyReinsurers)),
        this.store.pipe(select(selectLossSetLayers))
      ),
      map(
        ([
          { ids },
          results,
          analysisProfileID,
          cededLayers,
          reinsurers,
          lossSetLayers,
        ]) => ({
          ids,
          results,
          analysisProfileID,
          cededLayers,
          reinsurers,
          lossSetLayers,
        })
      ),
      withLatestFrom(
        this.store.pipe(select(selectGrossPortfolioViewID)),
        this.store.pipe(select(selectLossSetGroups))
      ),
      mergeMap(
        ([
          {
            ids,
            results,
            analysisProfileID,
            cededLayers,
            reinsurers,
            lossSetLayers,
          },
          grossPortfolioViewID,
          lossSetGroups,
        ]) => {
          return this.optimizationService.getCandidateLayersMetrics(
            results.filter(r => ids.includes(r.id)),
            cededLayers,
            reinsurers,
            lossSetLayers,
            analysisProfileID || '',
            lossSetGroups,
            grossPortfolioViewID || ''
          )
        }
      ),
      map(response => {
        if (response.error) {
          return fromOptimizationLayersActions.getLayersMetricsFailure({
            error: response.error,
          })
        } else {
          return fromOptimizationLayersActions.getLayersMetricsSuccess({
            // tslint:disable-next-line: no-non-null-assertion
            changes: response.data!,
          })
        }
      })
    )
  })
}
