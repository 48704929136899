<div class="outer-wrapper">
  <div
    class="outer-tabs-container"
    [ngClass]="{ 'hide-tab-underline': hideTabBar }"
  >
    <div class="tabs-container" *ngIf="!hideTabBar">
      <nav class="tab-bar" mat-tab-nav-bar color="accent" [tabPanel]="tabPanel">
        <a
          mat-tab-link
          (click)="tabClicked('layers')"
          [active]="tab === 'layers'"
          [disabled]="status?.dialogLoading"
          *ngIf="!tabHidden.layers"
        >
          <span class="no-select">{{ layerTabLabel }}</span>
        </a>
        <a
          mat-tab-link
          (click)="tabClicked('manual')"
          [active]="tab === 'manual'"
          [disabled]="status?.dialogLoading"
          *ngIf="!tabHidden.manual && context === 'pricing-curve'"
        >
          <span class="no-select">{{ techFactorsTabLabel }}</span>
        </a>
        <a
          mat-tab-link
          (click)="tabClicked('saved')"
          [active]="tab === 'saved'"
          [disabled]="status?.dialogLoading"
          *ngIf="!editMode || !tabHidden.saved"
        >
          <span class="no-select">Saved Curves</span>
        </a>
      </nav>
    </div>
  </div>
  <mat-tab-nav-panel class="wrapper" #tabPanel>
    <section class="add-pricing-curve content" *ngIf="tab === 'layers'">
      <app-add-pricing-curve-layers
        [workingCurve]="workingCurve"
        [dialogData]="dialogData"
        [currentDataSetNames]="currentDataSetNames"
        [form]="form"
        [status]="status"
        [context]="context"
        (selectionChanged)="selectionChanged($event)"
        (layerSplitViewChanged)="layerSplitViewChanged.emit($event)"
        (submitWorkingCurveAsLayerSet)="submitWorkingCurveAsLayerSet()"
        (submitCurve)="submitCurve.emit($event)"
        (navigateToCurveFactors)="navigateToCurveFactors()"
        (predictionColumnsChanged)="predictionColumnsChanged($event)"
      ></app-add-pricing-curve-layers>
    </section>
    <section class="content" *ngIf="tab === 'manual'">
      <app-pricing-curve-technical-factors-form
        [workingCurve]="workingCurve"
        [dialogData]="dialogData"
        [currentDataSetNames]="currentDataSetNames"
        [isNewCurve]="hideTabBar"
        [editManual]="editManual"
        [dataSetId]="dataSetId"
        [labelForm]="form"
        [isQuickEdit]="data.isQuickEdit"
        [savedCurveNameMap]="savedCurveNameMap"
        [context]="context"
        (techFactorsChanged)="techFactorsChanged($event)"
        (submitCurve)="submitCurve.emit($event)"
      ></app-pricing-curve-technical-factors-form>
    </section>
    <section class="content" *ngIf="tab === 'saved'">
      <app-pricing-curve-saved-curves
        [savedCurves]="savedCurves"
        [savedCurveSelectors]="savedCurveSelectors"
        [status]="status"
        [addedCurveIds]="addedCurveIds"
        [usageContext]="context"
      ></app-pricing-curve-saved-curves>
    </section>
  </mat-tab-nav-panel>
</div>
