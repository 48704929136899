<app-loading-item [item]="'item'" [loading]="metricLoading" [name]="title">
  <label appInfoText small italic *ngIf="!layerPresent; else resultsTemplate">
    Please select a layer to view properties and results
  </label>
</app-loading-item>

<ng-template #resultsTemplate>
  <label
    *ngIf="!isDeleteSharedLayer"
    class="label-edit"
    appInfoText
    small
    italic
  >
    Saving above will update your results if changes have been made.
  </label>

  <div *ngFor="let def of metricPropertyDefs; trackBy: trackByDef">
    <label class="metric-label">{{ def.label }}</label>
    <app-action-text big>
      <app-metric-text
        [value]="getValue(def)"
        [valueType]="def.valueType"
        [currency]="isDeleteSharedLayer ?  (layerResultsMetricCurrency ? layerResultsMetricCurrency : sharedLimitCurrency ? sharedLimitCurrency : sharedCurrentCurrency) : currentCurrency"
        [precision]="def.decimals ?? 1"
        showRatioPercent
        useZeroText
      ></app-metric-text>
    </app-action-text>
  </div>

  <label class="exceedance-label">Exceedance Probabilities</label>
  <app-return-period-table-layer
    *ngIf="showMetrics"
    class="rp"
    [data]="returnPeriodData"
    [rp1]="returnPeriod1"
    [rp2]="returnPeriod2"
    [rp3]="returnPeriod3"
    [metrics]="layerMetrics"
    size="tiny"
    noPadding
    showTogglesButton
    (rp1Change)="onReturnPeriod1Change($event)"
    (rp2Change)="onReturnPeriod2Change($event)"
    (rp3Change)="onReturnPeriod3Change($event)"
    (perspectiveChange)="onPerspectiveChange($event)"
    (aggregationMethodChange)="onAggregationMethodChange($event)"
  ></app-return-period-table-layer>
  <div class="loss-parent">
    <h4
      class="loss-header"
      *ngIf="lossSets.length > 0 && !isDeleteSharedLayer"
    >
      Loss Sets AAL
    </h4>
    <button
      *ngIf="!isRiskXOL"
      appButton
      small
      center
      class="loss-button"
      [matTooltip] = "designDirty ? 'Save unsaved changes to calculate' : 'Click for contribution to Expected Loss' "
      [disabled]="designDirty"
      (click)="onContributionClick()"
    >
      Contribution
    </button>
  </div>
  <mat-progress-bar
      *ngIf="layerLoading"
      class="progress"
      color="accent"
      mode="indeterminate"
    ></mat-progress-bar>
  <mat-form-field class="filter" *ngIf="!isDeleteSharedLayer">
    <div class="filter-loss">
      <input
        matInput
        type="text"
        placeholder="Search"
        class="search-text"
        (keyup)="createFilter($event.target.value)"
      />
      <mat-icon>search</mat-icon>
    </div>
  </mat-form-field>
  <div class="loss-sets" *ngIf="!isDeleteSharedLayer">
    <div *ngFor="let l of filterLossSets; trackBy: trackByLossSet">
      <label class="metric-label">{{ l.label }}</label>
      <app-action-text *ngIf="l.value !== 0" big class="value">
        <app-metric-text
          [value]="l.value"
          valueType="currency"
          [currency]="view.currency"
          useZeroText
        ></app-metric-text>
      </app-action-text>

      <app-action-text *ngIf="l.expectedLoss || (l.expectedLoss === 0)" big class="contribution">
        <app-metric-text
          [value]="l.expectedLoss"
          valueType="currency"
          [currency]="view.currency"
          useZeroText
        ></app-metric-text>
      </app-action-text>
    </div>
  </div>
  <div class="currency-selector" *ngIf="isDeleteSharedLayer">
    <label>Layer Results Metric Currency</label>
    <mat-form-field appearance="outline">
      <input type="text"
        matInput
        [formControl]="layerResultsCurrencyControl"
        [matAutocomplete]="layerResultsCurrencyCtrl"
        >
      <mat-autocomplete #layerResultsCurrencyCtrl="matAutocomplete"
      (optionSelected)="onLayerResultsCurrencyChange($event.option.value)" [displayWith]="displayFn">
        <mat-option *ngFor="let currency of filteredCurrencyList" [value]="currency">
          {{currency.code}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
<section class="actions" *ngIf="isDeleteSharedLayer">
  <ng-container *ngIf="!updatingSharedLimit; else updatingSharedLimitAction">
    <button
      *ngIf="showEditButton"
      appButton
      big
      accent
      border
      [disabled]="isDisabled"
      (click)="!isDisabled && onUpdateSharedLimitPropertiesClick()"
    >
      <span
        matTooltip="Click to Update this Shared Limit arrangement"
        matTooltipPosition="above"
        >Update</span
      >
    </button>
  </ng-container>
    <ng-template #updatingSharedLimitAction>
      <app-active-action [value]="'Updating'"></app-active-action>
    </ng-template>
    <ng-container>
      <button
        appButton
        big
        link
        (click)="closeClick.emit()"
      >
        <span>Close</span>
      </button>
    </ng-container>
  </section>
</ng-template>
