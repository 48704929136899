<app-loading-item
  [item]="'item'"
  [loading]="isLoadingQuote"
  name="Quote Selected"
>
  <main class="main">
    <div
      class="data-container"
      *ngIf="isLayerSelected || isGroupSelected || isSLSelected"
    >
      <div
        *ngIf="
          !showSubjectivity &&
          !showTrackingModule &&
          !showToggle &&
          !showAssignedLines &&
          !showQuickQuote &&
          !showCustomCompareBuilder
        "
        class="swiper-container"
        [swiper]="config"
      >
        <div
          cdkDropList
          cdkDropListOrientation="horizontal"
          class="swiper-wrapper"
          id="swiper-frame"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            *ngFor="
              let reinsurer of updatedReinsurerList;
              let i = index;
              trackBy: trackByID
            "
            class="swiper-slide"
            cdkDragLockAxis="x"
            cdkDrag
            [cdkDragDisabled]="fotChecker(reinsurer)"
            [ngStyle]="getGridStyle(reinsurer)"
            tabindex="-1"
          >
            <app-quote-reinsurer
              [reinsurers]="reinsurer"
              [selectedReinsurer]="selectedReinsurer"
              [layer]="layer"
              [layerGroup]="layerGroup"
              [isExpanded]="isExpanded"
              [expandedName]="expandedName"
              [isFOTMasterCreated]="isFOTMasterCreated"
              [allReinsurers]="allReinsurers"
              [reinsurerList]="reinsurerListFormatted"
              [isSaving]="isSaving"
              [selectedSection]="selectedSection"
              [reinsurersNameListUpdated]="reinsurersNameListUpdated"
              [firstReinsurerName]="firstReinsurerName"
              [layerState]="layerState"
              [layerStateGroup]="layerStateGroup"
              [index]="i"
              [selectedStructureGroupID]="selectedStructureGroupID"
              [sectionList]="sectionList"
              [isGroupSelected]="isGroupSelected"
              [isSLSelected]="isSLSelected"
              [layersSL]="layersSL"
              [selectedSharedLimitID]="selectedSharedLimitID"
              [panelExpand]="expandedLayerIndexes"
              [territories]="territories"
              [layerInd]="i"
              [accountOpportunity]="currentOpportunity"
              [showCustomCompare]="showCustomCompare"
              (saveClick)="onSaveClick($event)"
              (deleteClick)="onDeleteClick($event)"
              (declineClick)="onDeclineClick($event)"
              (preferredClick)="onPreferredClick($event)"
              (exportToggleClick)="onExportToggleClick($event)"
              (nameSelection)="onNameSelection($event)"
              (addOrUpdateVersionReinsurer)="
                onAddOrUpdateVersionReinsurer($event)
              "
              (subjectivityClick)="showSubjectivityClick.emit($event)"
              (reinsurerResize)="reinsurerResize.emit($event)"
              (selectedID)="selectedID.emit($event)"
              (expandClick)="expandClick.emit($event)"
              (populateClick)="onPopulateClick($event)"
              (slidingValueChange)="slidingValueChange.emit($event)"
              (deleteReinstatement)="deleteReinstatement.emit($event)"
              (expandedReinsurer)="expandedReinsurer.emit($event)"
              (currentReinsurerChange)="onCurrentReinsurerChange($event)"
              (assignedLinesClick)="onAssignedLinesClick()"
              (updateLabel)="onUpdateLabel($event)"
              (panelExpandClick)="layerExpanded($event)"
              (panelCollapseClick)="layerCollapsed($event)"
            ></app-quote-reinsurer>
          </div>
          <div
            class="swiper-slide dotted-slide"
            cdkDragLockAxis="x"
            cdkDrag
            *ngIf="!isExpanded && !showCustomCompare"
            (click)="addQuote()"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Add new icon"
              class="add"
              matTooltip="Click to create new Quote."
              matTooltipPosition="above"
              >add_box</mat-icon
            >
          </div>
        </div>
        <!-- <div class="swiper-scrollbar"></div> -->
      </div>
      <div *ngIf="showSubjectivity">
        <app-quote-subjectivity
          [subjectivityColumnDef]="subjectivityColumnDef"
          [rows]="rows"
          [subjectivityOptions]="subjectivityOptions"
          [selectedReinsurer]="selectedReinsurer"
          [formattedSubjectivityArray]="formattedSubjectivityArray"
          [sectionID]="sectionID"
          [selectedQuoteReinsurer]="selectedQuoteReinsurer"
          [assignedLineReName]="assignedLineReName"
          [assignedLineReId]="assignedLineReId"
          [showAssignedLines]="showAssignedLines"
          [structureRiskSubjectivityRows]="updatedStructureRiskSubjectivityRows"
          [selectedReinsurerMarketTpRef]="selectedReinsurerMarketTpRef"
          [selectedCededLayerID]="selectedCededLayerID"
          (addNewSubjectivity)="onAddNewSubjectivity($event)"
          (updateSubjectivity)="onUpdateSubjectivity($event)"
          (deleteSubjectivity)="onDeleteSubjectivity($event)"
        ></app-quote-subjectivity>
      </div>
      <div *ngIf="showTrackingModule">
        <app-quote-tracking
          [subjectivityTrackingColumnDef]="subjectivityTrackingColumnDef"
          [subjectivityOptions]="subjectivityOptions"
          [rows]="rowsTracking"
          (updateSubjectivity)="updateSubjectivity.emit($event)"
          (saveSubjectivities)="editedSubjectivity($event)"
          (deleteSubjectivity)="onDeleteSubjectivity($event)"
        ></app-quote-tracking>
      </div>
      <div *ngIf="showToggle">
        <app-quote-toggles
          [selectedSection]="selectedSection"
          [sectionList]="sectionList"
          [layer]="layer"
          [isGroupSelected]="isGroupSelected"
          [isSLSelected]="isSLSelected"
          [quotePanelDefs]="sortTerms(quotePanelDefs)"
          (exportToggleSectionClick)="
            onExportToggleSectionClick($event.panelDefToggled, $event.value)
          "
          (exportToggleDefaultClick)="
            onExportToggleDefaultClick($event.panelDefToggled)
          "
        ></app-quote-toggles>
      </div>
      <div *ngIf="showQuickQuote">
        <app-quick-quote
          [reinsurerList]="reinsurerList"
          [reinsurersNameListUpdated]="reinsurersNameListUpdated"
          [sectionList]="sectionList"
          [selectedField]="selectedField"
          [addOrUpdateData]="addOrUpdateData"
          [layerState]="layerState"
          [autoFill]="autoFill"
          (updateQuickQuoteField)="updateQuickQuoteField.emit($event)"
          (addOrUpdateQuickQuote)="onAddOrUpdateQuickQuote($event)"
          (saveQuickQuoteOnChange)="onSaveQuickQuoteOnChange()"
          (updateAutoFillSelection)="updateAutoFillSelection.emit($event)"
        ></app-quick-quote>
      </div>
      <div class="assign-line-container" *ngIf="showAssignedLines && !showSubjectivity">
        <app-quote-assigned-lines
          [programs]="programs"
          [selectedProgramID]="selectedProgramID"
          [accountOpportunities]="accountOpportunities"
          [selectedCededLayerID]="selectedCededLayerID"
          [layer]="layerState"
          [assignedLinesColumnDef]="assignedLinesColumnDef"
          [assignedLineRows]="assignedLineRows"
          [reinsurers]="reinsurerNames"
          [reinsurerList]="reinsurerList"
          [structureID]="structureID"
          [reinsurersNameListUpdated]="reinsurersNameListUpdated"
          [selectedReinsurer]="selectedReinsurer"
          [externalVendor]="externalVendor"
          [isDirty]="quoteDirty"
          [bureaus]="bureaus"
          [sectionList]="sectionList"
          [structureRiskSubjectivityRows]="updatedStructureRiskSubjectivityRows"
          [hasWhitespaceAccess]="hasWhitespaceAccess"
          (addNewAssignedLines)="onAddNewAssignedLines($event)"
          (addAssignSubject)="onAddAssignSubject($event)"
          (updateAssignedLines)="updateAssignedLines.emit($event)"
          (deleteAssignedLines)="deleteAssignedLines.emit($event)"
          (populateAssignedLinesFromWhiteSpace)="
            populateAssignedLinesFromWhiteSpace.emit($event)
          "
          (subjectivityALClick)="onSubjectivityALClick($event)"
          (updateOffMarketLines)="updateOffMarketLines.emit($event)"
          (showSegregatedAccountModal)="showSegregatedAccountModal.emit($event)"
        ></app-quote-assigned-lines>
      </div>
      <div *ngIf="showCustomCompareBuilder">
        <app-quote-custom-comparison
          [sectionID]="sectionID"
          [reinsurers]="updatedReinsurerList"
          [customCompareViews]="customCompareViews"
          [currentCompareView]="currentCompareView"
          (setCompareView)="setCompareView.emit({ view: $event, save: false })"
          (saveCompareView)="setCompareView.emit({ view: $event, save: true })"
          (deleteCompareView)="deleteCompareView.emit($event)"
        ></app-quote-custom-comparison>
      </div>
    </div>
  </main>
  <div
    *ngIf="
      !showSubjectivity &&
      !showTrackingModule &&
      !showToggle &&
      !showAssignedLines &&
      !showQuickQuote &&
      !showCustomCompareBuilder
    "
  >
    <div
      *ngIf="showingScrollMenu; else showMenuButton"
      class="scroll-buttons-floater"
    >
      <div class="hide-button-container">
        <button appButton accent border (click)="toggleScrollMenu()">
          Hide
        </button>
      </div>
      <div class="scroll-buttons">
        <button appButton accent border (click)="horizontalEndScroll(true)">
          <mat-icon>first_page</mat-icon>
        </button>
        <button
          appButton
          accent
          border
          (mousedown)="startRepeatHorizontal(true)"
          (mouseup)="endRepeatHorizontal()"
          (mouseout)="endRepeatHorizontal()"
        >
          <mat-icon>navigate_before</mat-icon>
        </button>
        <button
          appButton
          accent
          border
          (mousedown)="startRepeatHorizontal(false)"
          (mouseup)="endRepeatHorizontal()"
          (mouseout)="endRepeatHorizontal()"
        >
          <mat-icon>navigate_next</mat-icon>
        </button>
        <button appButton accent border (click)="horizontalEndScroll(false)">
          <mat-icon>last_page</mat-icon>
        </button>
      </div>
    </div>
    <ng-template #showMenuButton>
      <div accent class="show-button-container">
        <button
          class="restore-scroll-menu-button"
          appButton
          accent
          border
          (click)="toggleScrollMenu()"
        >
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
      </div>
    </ng-template>
  </div>
</app-loading-item>
