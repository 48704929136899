import { ScrollingModule } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ColorPickerModule } from 'ngx-color-picker'
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor'

import { ActionTextComponent } from './action-text.component'
import { ActiveActionComponent } from './active-action.component'
import { AddNameDialogComponent } from './add-name-dialog.component'
import { AppLoadingItemComponent } from './app-loading-item.component'
import { AppLogoComponent } from './logo/app-logo.component'
import { AudienceLayerSelectDialogComponent } from './audience-layer-select-dialog/audience-layer-select-dialog.component'
import { BackdropClassDirective } from './services/backdrop-class.directive'
import { ButtonIconComponent } from './button/button-icon.component'
import {
  ButtonComponent,
  IconButtonStylerDirective,
} from './button/button.component'
import { CapitalMetricsCustomComponent } from './capital-metrics-custom.component'
import { CardComponent } from './card.component'
import { CheckboxSelectButtonComponent } from './checkbox-select-button.component'
import { CheckboxSelectDialogComponent } from './checkbox-select-dialog.component'
import { CheckboxSelectComponent } from './checkbox-select.component'
import { ClickElsewhereDirective } from './services/click-elsewhere.directive'
import { ColorChipsComponent } from './color-chips.component'
import { ConfirmationDialogComponent } from './confirmation-dialog.component'
import { ConfirmationDialogService } from './services/confirmation-dialog.service'
import { CopyClipboardDirective } from './services/copy-clipboard.directive'
import { CorpLogoComponent } from './corp-logo.component'
import { EntryDialogComponent } from './entry-dialog.component'
import { EntryDialogService } from './services/entry-dialog.service'
import { EditRowDialogComponent } from './edit-row-dialog/edit-row-dialog.component'
import { FacAppLogoComponent } from './fac-app-logo.component'
import { FieldRadioComponent } from './field-radio.component'
import { FilterInputComponent } from './filter-input.component'
import { FormatCurrency } from './services/format.currency.directive'
import { FormatNumber } from './services/format.onlyNumbers.directive'
import { FormatPercentageDirective } from './services/format.percentage.directive'
import { GeneralHeaderComponent } from './general-header.component'
import { GraphingColorLegendControlsComponent } from './graphing-color-legend-controls.component'
import { GridIconComponent } from './grid-icon.component'
import { HeaderCollapseComponent } from './header-collapse.component'
import { HeaderTitleComponent } from './header-title.component'
import { HierarchicalEntitySelectComponent } from './hierarchical-entity-select.component'
import { HierarchicalEntitySelectService } from './hierarchical-entity-select.service'
import { InfoTextComponent } from './info-text.component'
import { InputComponent } from './input.component'
import { LayerDetailsTitleComponent } from './layer-details-title.component'
import { LayerListComponent } from '../analysis/properties/layer/layer-list/layer-list.component'
import { LayerPaletteItemComponent } from './layer-palette-item.component'
import { LayerPropertiesComponent } from '../analysis/properties/layer/layer-properties/layer-properties.component'
import { LayerPropertyComponent } from './layer-property/layer-property.component'
import { LayerService } from './services/layer.service'
import { LossFilterComponent } from './loss-filter-component'
import { MaterialModule } from './material.module'
import { MessageDialogComponent } from './message-dialog-component'
import { MessageDialogService } from './message-dialog.service'
import { MetricPropertiesComponent } from '../analysis/properties/metric/metric-properties.component'
import { MetricTextComponent } from './metric-text.component'
import { MetricsTogglesComponent } from './metrics-toggles.component'
import { MultiSectionButtonComponent } from '../analysis/properties/layer/multi-section/multi-section-button.component'
import { NamedNgTemplateDirective } from './services/named-ng-template.directive'
import { NavListItemComponent } from './nav-list-item.component'
import { OverviewCardComponent } from './overview-card.component'
import { PanelToggleButtonComponent } from './panel-hide-button.component'
import { PercentageInputComponent } from '../analysis/properties/layer/type-inputs/percentage-input.component'
import { PipesModule } from './pipes/pipes.module'
import { PlaceholderImageComponent } from './placeholder-image.component'
import { PortfolioDetailsComponent } from './portfolio-details/portfolio-details.component'
import { PortfolioTowerPropertiesComponent } from './portfolio-tower-properties/portfolio-tower-properties.component'
import { PortfolioExchangeDialogComponent } from './portfolio-exchange-dialog/portfolio-exchange-dialog.component'
import { PremiumInputComponent } from '../analysis/properties/layer/type-inputs/premium-input.component'
import { PropertiesContainerComponent } from '../analysis/properties/properties-container/properties.container'
import { PropertiesPanelComponent } from '../analysis/properties/properties-panel/properties-panel.component'
import { RankComponent } from './rank.component'
import { ReturnPeriodSelectorComponent } from './return-period-selector.component'
import { ReturnPeriodTogglesComponent } from './return-period-toggles.component'
import { ReturnPeriodTableGroupComponent } from '../analysis/portfolio/return-period-table-group/return-period-table-group.component'
import { ReturnPeriodLayerTableComponent } from '../analysis/portfolio/return-period-table-layer/return-period-table-layer.component'
import { ReturnPeriodTableComponent } from '../analysis/portfolio/return-period-table/return-period-table.component'
import { RibbonCardComponent } from './ribbon-card.component'
import { RibbonTagComponent } from './ribbon-tag.component'
import { SetNameDialogComponent } from './set-name-dialog.component'
import { ImportLossSetsDialogComponent } from 'src/app/shared/import-loss-sets-dialog.component'
import { SliderInputComponent } from './slider-input'
import { SortTableFilterComponent } from './sort-table/sort-table-filter/sort-table-filter.component'
import { SortTableValueComponent } from './sort-table/sort-table-value/sort-table-value.component'
import { SortTableComponent } from './sort-table/sort-table.component/sort-table.component'
import { SvgDefsComponent } from './svg-defs.component'
import { TableDialogComponent } from './table-dialog/table-dialog.component'
import { TableDialogService } from './table-dialog/table-dialog.service'
import { TagComponent } from './tag.component'
import { ThemeComponent } from './theme.component'
import { ThemeContainerComponent } from './theme.container'
import { TopDropInputComponent } from '../analysis/properties/layer/type-inputs/top-drop-input.component'
import { TriggerPositionDialogComponent } from './trigger-position-dialog.component'
import { UserCardComponent } from './user-card.component'
import { WeightSliderComponent } from './weight-slider.component'
import { WheelScrollDirective } from './services/wheel-scroll.directive'
import { MISummaryValueComponent } from './mi-summary-value.component'
import { MultiselectAutocompleteComponent } from './multiselect-autocomplete.component'
import { ColumnsSettingsDialogComponent } from './columns-settings/columns-settings-dialog.component'
import { MultiselectLayerPropertyComponent } from './multiselect-layer-property/multiselect-layer-property.component'
import { MultiselectLayerPropertyDialogComponent } from './multiselect-layer-property/dialog/multiselect-layer-property-dialog.component'
import { DependentLayerPropertyComponent } from './dependent-layer-property/dependent-layer-property.component'
import { DependentLayerPropertyDialogComponent } from './dependent-layer-property/dialog/dependent-layer-property-dialog.component'
import { DynamicMenuComponent } from './dynamic-menu/dynamic-menu.component'
import { PricingCurveSavedCurveTooltipComponent } from './saved-pricing-curve-tooltip/pricing-curve-saved-curve-tooltip.component'
import { PricingCurveSavedCurvesComponent } from './add-saved-curve-dialog/pricing-curve-saved-curves.component'
import { PricingCurveSavedCurveFilterBarComponent } from './add-saved-curve-dialog/saved-curve-filter-bar/pricing-curve-saved-curve-filter-bar.component'
import { MultiselectFilterComponent } from './add-saved-curve-dialog/multiselect-filter-component/multiselect-filter.component'
import { NewGroupDialogComponent } from './new-group-dialog/new-group-dialog.component'
import { RenameGroupDialogComponent } from './rename-group-dialog.component'
import { SkeletonComponent } from './skeleton/skeleton.component'
import { ScenarioSelectorComponent } from './scenario-selector/scenario-selector.component'
import { TestingExcelComponent } from './sort-table-tester/testing-excel.component'
import { PivotTableComponent } from './pivot-table/pivot-table-component/pivot-table.component'
import { HoverClassDirective } from './services/hover-class.directive'

const imports = [
  ColorPickerModule,
  CommonModule,
  FormsModule,
  MaterialModule,
  PipesModule,
  ScrollingModule,
  RichTextEditorModule,
]

const COMPONENTS = [
  AddNameDialogComponent,
  AudienceLayerSelectDialogComponent,
  CheckboxSelectDialogComponent,
  ConfirmationDialogComponent,
  EntryDialogComponent,
  ImportLossSetsDialogComponent,
  MessageDialogComponent,
  SetNameDialogComponent,
  SortTableFilterComponent,
  SortTableValueComponent,
  TableDialogComponent,
  TestingExcelComponent,
]

const EXPORTS = [
  ActionTextComponent,
  ActiveActionComponent,
  AppLoadingItemComponent,
  AppLogoComponent,
  BackdropClassDirective,
  ButtonComponent,
  ButtonIconComponent,
  CapitalMetricsCustomComponent,
  CardComponent,
  CheckboxSelectButtonComponent,
  CheckboxSelectComponent,
  ClickElsewhereDirective,
  ColorChipsComponent,
  ColumnsSettingsDialogComponent,
  CopyClipboardDirective,
  CorpLogoComponent,
  DynamicMenuComponent,
  EditRowDialogComponent,
  FacAppLogoComponent,
  FieldRadioComponent,
  FilterInputComponent,
  FormatCurrency,
  FormatNumber,
  FormatPercentageDirective,
  GeneralHeaderComponent,
  GraphingColorLegendControlsComponent,
  GridIconComponent,
  HeaderCollapseComponent,
  HeaderTitleComponent,
  HierarchicalEntitySelectComponent,
  IconButtonStylerDirective,
  InfoTextComponent,
  InputComponent,
  LayerListComponent,
  LayerDetailsTitleComponent,
  LayerPaletteItemComponent,
  LayerPropertiesComponent,
  LayerPropertyComponent,
  MultiselectLayerPropertyComponent,
  DependentLayerPropertyComponent,
  DependentLayerPropertyDialogComponent,
  MultiSectionButtonComponent,
  MetricPropertiesComponent,
  MetricTextComponent,
  MetricsTogglesComponent,
  NamedNgTemplateDirective,
  NavListItemComponent,
  OverviewCardComponent,
  PanelToggleButtonComponent,
  PercentageInputComponent,
  PlaceholderImageComponent,
  PortfolioDetailsComponent,
  PortfolioTowerPropertiesComponent,
  PortfolioExchangeDialogComponent,
  PremiumInputComponent,
  PropertiesContainerComponent,
  PropertiesPanelComponent,
  RankComponent,
  ReturnPeriodLayerTableComponent,
  ReturnPeriodSelectorComponent,
  ReturnPeriodTableComponent,
  ReturnPeriodTableGroupComponent,
  ReturnPeriodTogglesComponent,
  RibbonCardComponent,
  RibbonTagComponent,
  SliderInputComponent,
  SortTableComponent,
  SvgDefsComponent,
  TagComponent,
  ThemeComponent,
  ThemeContainerComponent,
  TopDropInputComponent,
  TriggerPositionDialogComponent,
  UserCardComponent,
  WeightSliderComponent,
  WheelScrollDirective,
  HoverClassDirective,
  LossFilterComponent,
  MISummaryValueComponent,
  MultiselectAutocompleteComponent,
  MultiselectLayerPropertyDialogComponent,
  NewGroupDialogComponent,
  RenameGroupDialogComponent,
  SkeletonComponent,
  PricingCurveSavedCurveTooltipComponent,
  PricingCurveSavedCurvesComponent,
  PricingCurveSavedCurveFilterBarComponent,
  MultiselectFilterComponent,
  ScenarioSelectorComponent,
  PivotTableComponent,
  TestingExcelComponent
]

const providers = [
  ConfirmationDialogService,
  EntryDialogService,
  HierarchicalEntitySelectService,
  LayerService,
  MessageDialogService,
  TableDialogService,
]

@NgModule({
  declarations: [...COMPONENTS, ...EXPORTS],
  exports: [...imports, ...EXPORTS],
  imports,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers,
})
export class SharedModule {}
