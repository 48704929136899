import { createAction, props } from '@ngrx/store'
import { ApplicationError } from 'src/app/error/model/error'
import { rejectNil, toArray } from '@shared/util/operators'
import { BenchmarkCategories } from '../model/benchmark-categories'
import { BenchmarkModeID, BenchmarkSystemModeID } from '../model/benchmark-mode'
import { BenchmarkOptionKey } from '../model/benchmark-options'
import { BenchmarkPeriod } from '../model/benchmark-period'
import {
  BenchmarkControlsSubmodeProps,
  BenchmarkGridItemState,
  BenchmarkSubmode,
} from '../model/benchmark-submode'
import { BenchmarkTimeframe } from '../model/benchmark-timeframe'
import {
  BenchmarkColorScaleByTargetMetricChange,
  BenchmarkCompany,
  BenchmarkDefBase,
  BenchmarkLob,
  BenchmarkMetric,
  BenchmarkMetricVisibilityActionChange,
  BenchmarkPeerSet,
} from '../model/benchmark.model'
import { BenchmarkControlsState } from './benchmark-controls.reducer'
import { BenchmarkData } from '../model/benchmark-chart'
import { HasError } from 'src/app/api/model/api.model'

export const updateBenchmarkControls = createAction(
  '[Benchmark Controls] Update',
  (value: Partial<BenchmarkControlsState>) => ({ value })
)

export const SetBenchmarkControlsTargetCompany = createAction(
  '[Benchmark Controls] Set Target Company',
  props<{
    mode: BenchmarkSystemModeID
    targetCompany: BenchmarkCompany | BenchmarkPeerSet | null
  }>()
)

export const fetchBenchmarkSavedIndividualPeersSuccess = createAction(
  '[Benchmark Controls] Fetch Benchmark Saved Individual Peers Success',
  props<{
    mode: BenchmarkModeID,
    savedIndividualPeersIds: string[],
    allPeers: BenchmarkCompany[]
  }>()
)

export const fetchBenchmarkSavedIndividualPeersFailure = createAction(
  '[Benchmark Controls] Fetch Benchmark Saved Individual Peers Failure',
  props<HasError>()
)

export const setBenchmarkControlsSavedPeers = createAction(
  '[Benchmark Controls] Set Saved Peers',
  props<{ peers: BenchmarkCompany[] }>()
)

export const RemoveBenchmarkControlsSavedPeers = createAction(
  '[Benchmark Controls] Remove Saved Peers',
  (
    peers: BenchmarkCompany | BenchmarkCompany[],
    opts: { isFullExport?: boolean } = {}
  ) => ({ peers: toArray(peers), isFullExport: opts?.isFullExport === true })
)

export const setBenchmarkControlsPeers = createAction(
  '[Benchmark Controls] Set Peers',
  props<{ peers: BenchmarkCompany[] }>()
)

export const AddBenchmarkControlsPeers = createAction(
  '[Benchmark Controls] Add Peers',
  (
    peers: BenchmarkCompany | BenchmarkCompany[],
    opts: { isFullExport?: boolean } = {}
  ) => ({ peers: toArray(peers), isFullExport: opts?.isFullExport === true })
)

export const RemoveBenchmarkControlsPeers = createAction(
  '[Benchmark Controls] Remove Peers',
  (
    peers: BenchmarkCompany | BenchmarkCompany[],
    opts: { isFullExport?: boolean } = {}
  ) => ({ peers: toArray(peers), isFullExport: opts?.isFullExport === true })
)

export const SetBenchmarkControlsBySubmode = createAction(
  '[Benchmark Controls] Set Grid Item Selection By Submode',
  props<BenchmarkControlsSubmodeProps>()
)

export const setBenchmarkControlsGridItemsBySubmode = createAction(
  '[Benchmark Controls] Set Grid Items By Submode',
  (submode: string, value: Record<string, BenchmarkGridItemState>) => ({
    submode,
    value,
  })
)

export const SetBenchmarkControlsSelectedGridIndex = createAction(
  '[Benchmark Controls] Set Selected Grid Index',
  props<{ submode: string; value: number; mutuallyExclusive: boolean }>()
)

/**
 * The second parameter, `metric`, can be set to `null` to indicate
 * that 'None' metric is selected for submodes with an optional metric
 * dimension, or to `undefined` to clear the user's metric selection
 * and use the default metrics for that dimension.
 */
export const SetBenchmarkControlsMetricBySubmode = createAction(
  '[Benchmark Controls] Set Metric By Submode',
  (
    submode: string,
    metric?: BenchmarkMetric | null,
    ...groupIndices: (number | undefined)[]
  ) => ({
    submode,
    metric,
    groupIndices: rejectNil(groupIndices),
  })
)

/**
 * The second parameter, `metric`, can be set to `null` to indicate
 * that 'None' metric is selected for submodes with an optional metric
 * dimension, or to `undefined` to clear the user's metric selection
 * and use the default metrics for that dimension.
 */
export const SetBenchmarkControlsSecondaryMetricBySubmode = createAction(
  '[Benchmark Controls] Set Secondary Metric By Submode',
  (
    submode: string,
    metric?: BenchmarkMetric | null,
    ...groupIndices: (number | undefined)[]
  ) => ({
    submode,
    metric,
    groupIndices: rejectNil(groupIndices),
  })
)

/**
 * The second parameter, `metric`, can be set to `null` to indicate
 * that 'None' metric is selected for submodes with an optional metric
 * dimension, or to `undefined` to clear the user's metric selection
 * and use the default metrics for that dimension.
 */
export const SetBenchmarkControlsTertiaryMetricBySubmode = createAction(
  '[Benchmark Controls] Set Tertiary Metric By Submode',
  (
    submode: string,
    metric?: BenchmarkMetric | null,
    ...groupIndices: (number | undefined)[]
  ) => ({
    submode,
    metric,
    groupIndices: rejectNil(groupIndices),
  })
)

export const SetBenchmarkControlsPeriodBySubmode = createAction(
  '[Benchmark Controls] Set Period By Submode',
  props<{ submode: string; period?: BenchmarkPeriod | null }>()
)

export const SetBenchmarkControlsTimeframeBySubmode = createAction(
  '[Benchmark Controls] Set Timeframe By Submode',
  props<{
    submode: string
    timeframe?: BenchmarkTimeframe | BenchmarkTimeframe[]
  }>()
)

export const SetBenchmarkControlsGrowthTimeframe = createAction(
  '[Benchmark Controls] Set Growth Timeframe',
  props<{ timeframe: BenchmarkTimeframe }>()
)

export const SetBenchmarkControlsLossRatioTimeframe = createAction(
  '[Benchmark Controls] Set Loss Ratio Timeframe',
  props<{ timeframe: BenchmarkTimeframe }>()
)

export const SetBenchmarkControlsCurrencyUnit = createAction(
  '[Benchmark Controls] Set Currency Unit',
  props<{ currencyUnit: string }>()
)

export const SetBenchmarkControlsHideIndividualPeers = createAction(
  '[Benchmark Controls] Set Hide Individual Peers',
  props<{ hideIndividualPeers: boolean }>()
)

export const BenchmarkControlsSaveIndividualPeersSuccess = createAction(
  '[Benchmark Controls] Save Individual Peers Success',
)

export const BenchmarkControlsSaveIndividualPeersFailure = createAction(
  '[Benchmark Controls] Save Peers Failure',
  props<HasError>()
)

export const SetBenchmarkControlsTimeframeEndOffsetBySubmode = createAction(
  '[Benchmark Controls] Set Timeframe End Offset By Submode',
  props<{ submode: string; value: number }>()
)

export const SetBenchmarkControlsOption = createAction(
  '[Benchmark Controls] Toggle Data Option',
  props<{
    submode: string
    id: BenchmarkOptionKey | string
    value: string | boolean
    refetchOnChange: boolean | undefined
  }>()
)

export const SetBenchmarkControlsEntityBySubmode = createAction(
  '[Benchmark Controls] Set Entity By Submode',
  (submode: string, entity: BenchmarkPeerSet | BenchmarkCompany | null) => ({
    submode,
    entity,
  })
)

export const SetBenchmarkControlsMetricGroupIndexBySubmode = createAction(
  '[Benchmark Controls] Set Metric Group Index By Submode',
  props<{ submode: string; index: number }>()
)

export const setBenchmarkControlsTopTargetQuantityBySubmode = createAction(
  '[Benchmark Controls] Set Top Metric Quantity By Submode',
  props<{ submode: string; value: number }>()
)

export const setBenchmarkControlsCategoriesBySubmode = createAction(
  '[Benchmark Controls] Set Categories By Submode',
  (submode: string, categories: BenchmarkCategories) => ({
    submode,
    categories,
  })
)

export const SetBenchmarkControlsMetricVisibility = createAction(
  '[Benchmark Controls] Set Metric Visibility',
  props<{
    submode: string
    change: BenchmarkMetricVisibilityActionChange
    refetchOnChange: boolean
  }>()
)

export const SetBenchmarkControlsLobBySubmode = createAction(
  '[Benchmark Controls] Set LOB',
  (
    submode: string,
    lobs: BenchmarkLob[] | null,
    ...groupIndices: (number | undefined)[]
  ) => ({
    submode,
    lobs,
    groupIndices: rejectNil(groupIndices),
  })
)

export const SetBenchmarkControlsStatesBySubmode = createAction(
  '[Benchmark Controls] Set States',
  (
    submode: string,
    states: BenchmarkDefBase[] | null,
    ...groupIndices: (number | undefined)[]
  ) => ({
    submode,
    states,
    groupIndices: rejectNil(groupIndices),
  })
)

export const setBenchmarkControlsColorScaleByTargetMetric = createAction(
  '[Prove Chart] Set Chart Options',
  props<BenchmarkColorScaleByTargetMetricChange>()
)

export const setBenchmarkControlsHoverID = createAction(
  '[Benchmark Controls] Set Hover ID',
  (id: string | number | null = null) => ({ id })
)

export const ClearBenchmarkControls = createAction(
  '[Benchmark Controls] Clear',
  props<{ mode: BenchmarkSystemModeID; submodes: BenchmarkSubmode[] }>()
)

export const restoreBenchmarkControls = createAction(
  '[Benchmark Controls] Restore'
)

export const restoreBenchmarkControlsSuccess = createAction(
  '[Benchmark Controls] Restore Success'
)

export const fetchBenchmarkCategories = createAction(
  '[Benchmark] Fetch Categories'
)

export const fetchBenchmarkCategoriesSuccess = createAction(
  '[Benchmark] Fetch Categories Success',
  (data: string[]) => ({ data })
)

export const fetchBenchmarkCategoriesFailed = createAction(
  '[Benchmark] Fetch Categories Failed',
  props<{ error: ApplicationError }>()
)

export const fetchBenchmarkReinsuranceSummary = createAction(
  '[Benchmark Controls] Fetch Benchmark Reinsurance Summary'
)

export const fetchBenchmarkReinsuranceSummarySuccess = createAction(
  '[Benchmark Controls] Fetch Benchmark Reinsurance Summary Success',
  (data: BenchmarkData) => ({ data })
)

export const fetchBenchmarkReinsuranceSummaryFailure = createAction(
  '[Benchmark Controls] Fetch Benchmark Reinsurance Summary Failure',
  props<{ error: ApplicationError }>()
)
