import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  ViewChild,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import {
  PricingCurveContextTypes,
  PricingCurveDatum,
  PricingCurveGraphSettings,
  PricingCurveStatus,
  SavedPricingCurveEntry,
  ZoomProperties,
} from '../../../../model/pricing-curve.model'
import { createGraphingValueFormatter } from '@graphing/utils/graphing-value-formatter'
import { PricingCurveGraph } from '@graphing/pricing-curve-graph'
import html2canvas from 'html2canvas'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/core/store'
import * as fromActions from '../../../../store/pricing-curve.actions'
import { PricingCurveDialogService } from '../../../dialog/pricing-curve-dialog.service'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-graph',
  styleUrls: ['./pricing-curve-graph.component.scss'],
  templateUrl: './pricing-curve-graph.component.html',
})
export class PricingCurveGraphComponent implements AfterViewInit, OnChanges {
  private chart: PricingCurveGraph
  initialZoomProperties: ZoomProperties | null
  currentDataIds: number[] = []

  @Input() pricingCurves: PricingCurve[]
  @Input() status: PricingCurveStatus
  @Input() activeIds: number[] | undefined
  @Input() savedCurves: SavedPricingCurveEntry[]
  @Input() isExport = false
  @Input() context: PricingCurveContextTypes = 'pricing-curve'
  @Input() graphSettings: PricingCurveGraphSettings

  constructor(
    private store: Store<AppState>,
    private dialogService: PricingCurveDialogService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pricingCurves) {
      const newCurveIds: number[] = changes.pricingCurves.currentValue.map(
        (curve: PricingCurve) => curve.id
      )
      const curveDifference = newCurveIds.filter(
        id => !this.currentDataIds.includes(id)
      )
      if (
        !!curveDifference.length ||
        this.currentDataIds.length !== this.currentDataIds.length
      ) {
        this.initialZoomProperties = null
        this.currentDataIds = newCurveIds
      }
    }
    this.draw()
  }

  @ViewChild('chart', { static: false }) chartEl: ElementRef<HTMLDivElement>

  ngAfterViewInit(): void {
    this.draw()
  }

  private get isReady(): boolean {
    return !(!this.chartEl || !this.chartEl.nativeElement)
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.draw()
  }

  onMouseMove(event: MouseEvent): void {
    if (this.chart) {
      this.chart.mouseMove(event)
    }
  }

  getCanvasFromGraphSVG(): Promise<HTMLCanvasElement> {
    return html2canvas(this.chartEl.nativeElement, {
      allowTaint: true,
      useCORS: true,
      backgroundColor: '#000',
    })
  }

  getMaxZoomScale(numTicks: number): number {
    const lineValues = this.getFlattenedLineData(this.pricingCurves).flat()
    const pointValues = this.getFlattenedPointData(this.pricingCurves).flat()

    const absMinX = Math.min(
      ...lineValues.map(val => val.x),
      ...pointValues.map(val => val.x)
    )
    const absMaxX = Math.max(
      ...lineValues.map(val => val.x),
      ...pointValues.map(val => val.x)
    )

    const absMinY = Math.min(
      ...lineValues.map(val => val.y),
      ...lineValues.map(val => val.y)
    )
    const absMaxY = Math.min(
      ...pointValues.map(val => val.y),
      ...pointValues.map(val => val.y)
    )

    const absMin = Math.min(absMinX, absMinY)
    const absMax = Math.max(absMaxX, absMaxY)

    const rangePerTick = Math.abs(absMax - absMin) / numTicks
    const targetTickWidth = 0.00125 // Target width for max zoom is .01%
    return rangePerTick / targetTickWidth
  }

  private draw() {
    if (!this.isReady) {
      return
    }

    const numTicks = 6
    const margin = { top: 10, right: 0, bottom: 20, left: 65 }

    const useDomainForXAxis = ['dti', 'fico', 'oltv', 'wal'].includes(
      this.graphSettings.xAxisDefinition.path
    )
    this.chart = new PricingCurveGraph(this.chartEl.nativeElement, {
      crossValue: (d: PricingCurveDatum) => d.x,
      mainValue: (d: PricingCurveDatum) => d.y,
      colorClass: d =>
        this.pricingCurves.find(dataset => dataset.id === d.datasetId)
          ?.graphColorClass ?? 'gray',
      lineColorClass: d =>
        this.pricingCurves.find(dataset => dataset.id === d.datasetId)
          ?.graphColorClass ?? 'gray',
      yAxisWidth: '4rem',
      xAxisHeight: '3rem',
      xTicks: numTicks,
      yTicks: numTicks,
      yLabel: this.graphSettings.yAxisDefinition.label,
      xLabel: this.graphSettings.xAxisDefinition.label,
      xTickFormat: createGraphingValueFormatter(useDomainForXAxis ? 'short' : 'percent'),
      yTickFormat: createGraphingValueFormatter('percent'),
      context: this.context,
      zoomMin: 1,
      zoomMax: this.getMaxZoomScale(numTicks),
      onLayerPointClick:
        this.context === 'pricing-curve'
          ? (datum: PricingCurveDatum) => {
              if (!this.isExport) {
                this.openExcludeLayerDialog(datum)
              }
            }
          : undefined,
      onZoom: (props: ZoomProperties) => {
        this.initialZoomProperties = props
      },
      height:
        this.chartEl.nativeElement.clientHeight - (margin.top + margin.bottom),
      width:
        this.chartEl.nativeElement.clientWidth - (margin.left + margin.right),
      isExport: this.isExport,
      initalZoomProperties: this.initialZoomProperties,
      margin,
    })

    this.chart.draw(
      {
        pointSets: this.getFlattenedPointData(this.pricingCurves),
        lineSets: this.getFlattenedLineData(this.pricingCurves),
      },
      this.pricingCurves.filter(curve => !curve.isLayerSet)[0]
    )
  }

  private getFlattenedPointData(d: PricingCurve[]): PricingCurveDatum[][] {
    if (this.status.isLoading || this.status.isSavingLayers) {
      return []
    }
    let filteredCurves
    if (this.activeIds) {
      filteredCurves = d.filter(
        dataSet => this.activeIds?.includes(dataSet.id) && !dataSet.isManual
      )
    } else {
      filteredCurves = d.filter(dataSet => dataSet.layersVisible)
    }
    return filteredCurves.map(curve => curve.pointData)
  }

  private getFlattenedLineData(d: PricingCurve[]): PricingCurveDatum[][] {
    if (this.status.isLoading || this.status.isSavingLayers) {
      return []
    }
    let filteredData
    if (this.activeIds) {
      filteredData = d.filter(
        dataSet => this.activeIds?.includes(dataSet.id) && !dataSet.isLayerSet
      )
    } else {
      filteredData = d.filter(dataSet => dataSet.lineVisible)
    }
    return filteredData.map(curve => curve.lineData)
  }

  private openExcludeLayerDialog(d: PricingCurveDatum) {
    this.store.dispatch(
      fromActions.updatePreviouslyClickedId({
        setId: d.datasetId,
        layerId: d.id,
      })
    )
    const pricingCurve = this.pricingCurves.find(
      item => item.id === d.datasetId
    )

    if (pricingCurve) {
      const excludeDialogRef = this.dialogService.openExcludeLayerDialog(
        undefined,
        {
          datum: d,
          curve: pricingCurve,
          currentDataSetNames: this.dataNames,
        }
      )
      excludeDialogRef.afterClosed().subscribe(dialogData => {
        if (dialogData) {
          this.handleDialogResponse(dialogData, d.id, pricingCurve)
        }
      })
    }
  }

  get dataNames(): { id: number; name: string }[] {
    const localNames = this.pricingCurves.map(curve => ({
      id: curve.id,
      name: curve.label.trim(),
    }))
    const existingDbNames = this.savedCurves.map(curve => ({
      id: curve.id,
      name: curve.pc_name.trim(),
    }))
    return localNames
      .concat(existingDbNames)
      .filter(
        (entry, index, array) =>
          array.findIndex(e => e.id === entry.id) === index
      )
  }

  private handleDialogResponse(
    dialogData: {
      save: boolean
      newLabel: string
    },
    layerId: number,
    pricingCurve: PricingCurve
  ) {
    const { save, newLabel } = dialogData

    pricingCurve.removeLayerFromSetAndRecalculate(layerId)
    pricingCurve.label = newLabel ?? pricingCurve.label

    this.store.dispatch(
      fromActions.updatePricingCurve({
        id: pricingCurve.id,
        curveData: {
          ...pricingCurve.curveData,
          isEdit: save,
        },
      })
    )
    if (save) {
      this.store.dispatch(
        fromActions.savePricingCurve({ curve: pricingCurve.curveData })
      )
    }
  }
}
