import { createAction, props } from '@ngrx/store'
import {
  AggregationMethodType,
  Perspective,
  VaRTVaR,
} from '../../../model/metrics.model'
import { PortfolioType } from '../../../model/portfolio-metrics.model'
import { MetricSettingsSaveChanges } from '../../../../api/metric-settings/metric-settings-response.model'
import { ApplicationError } from '../../../../error/model/error'
import { CompareMetricSetting } from '../../../model/compare-metrics.model'
import { MetricValueType } from 'src/app/core/model/metric-value-type.model'

export const fetchMetricTableSettings = createAction(
  '[Metric Table Settings] Fetch',
  props<{ studyID: string, isCredit?: boolean}>()
)

export const fetchMetricTableSettingsSuccess = createAction(
  '[Metric Table Settings] Fetch Success',
  props<{ settings: CompareMetricSetting[], isCredit?: boolean }>()
)

export const fetchMetricTableSettingsFailure = createAction(
  '[Metric Table Settings] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const setExpandedMetricTableCategory = createAction(
  '[Metric Table Settings] Set Expanded Metric Category',
  props<SetExpandedMetricTableCategoryProps>()
)

export const updateMetricTableSettings = createAction(
  '[Metric Table Settings] Update',
  props<{
    show: boolean
    weight: number
    year?: string
    portfolioType?: PortfolioType
    vartvar?: VaRTVaR
    aggregationMethodType?: AggregationMethodType
    perspective?: Perspective
    lossFilter?: string
    label: string
    metrics: CompareMetricSetting
    spPremiumValue?: number
    spReserveValue?: number
    spDivesificationValue?: number
    spCatValue?: number
    deleted?: boolean
    formula?: string
    valueType?: MetricValueType
    ragOrder?: 'Higher' | 'Lower'
    credit_scenario?: string
  }>()
)

export const setDirty = createAction(
  '[Metric Table Settings] Set Dirty',
  props<{ setting: CompareMetricSetting; dirty: boolean }>()
)

export const upsertMetricTableSettings = createAction(
  '[Metric Table Settings] Upsert',
  props<{ isCredit?: boolean }>()
)

export const clearMetricSettings = createAction(
  '[Metric Table Settings] Clear Metric Settings'
)

export const saveMetricTableSettings = createAction(
  '[Metric Table Settings] Save',
  props<{ changes: MetricSettingsSaveChanges[], isCredit?: boolean }>()
)

export const saveMetricTableSettingsFailure = createAction(
  '[Metric Table Settings] Save Failure',
  props<{ error: ApplicationError }>()
)
export const saveMetricTableSettingsSuccess = createAction(
  '[Metric Table Settings] Save Success',
  props<{ studyID: string, isCredit?: boolean }>()
)

export const addNewCustomMetric = createAction(
  '[Metric Table Settings] Add Custom Metric',
  props<{ newCustomMetric: CompareMetricSetting }>()
)

export const enableAllMetrics = createAction(
  '[Metric Table Settings] Enable All Metrics'
)

interface SetExpandedMetricTableCategoryProps {
  category: string
  value?: boolean
}
