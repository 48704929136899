import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  TemplateRef,
} from '@angular/core'
import { CanSize, CanSizeCtor, mixinSize, Size } from '../size.mixin'

@Directive({
  // tslint:disable-next-line
  selector: 'button[appIconButton], a[appIconButton]',
})
export class IconButtonStylerDirective {
  @HostBinding('class.icon-button') buttonClass = true
}

/**
 * Get a color mixin that ButtonComponent can extend to handle color inputs
 */
class ButtonBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof ButtonBase = mixinSize(ButtonBase)

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line
  selector: `button[appButton], a[appButton]`,
  styles: [
    `
      :host {
        position: relative;
        background: var(--bg-1);
        transition: background 250ms, color 250ms;

        /* Display anchors like buttons */
        display: inline-block;
        white-space: nowrap;
        text-decoration: none;
        vertical-align: baseline;
        text-align: center;

        /* Sizing */
        margin: 0;
        min-width: 24px;
        line-height: 24px;
        border-radius: var(--border-radius);

        /* Reset button styles */
        cursor: pointer;
        outline: none;
        border: none;
        user-select: none;

        color: var(--accent);
        font-family: var(--font-header-family);
        font-weight: var(--font-link-weight);
        font-size: var(--font-size);
        padding: 0 10px 3px;
        border: 1px solid transparent;
      }

      :host-context(.app-theme-dark):not(.link),
      :host-context(.app-elevation-2):not(.link) {
        background: var(--bg-2);
      }

      :host-context(.app-theme-dark .app-elevation-2):not(.link) {
        background: var(--bg-3);
      }

      :host:not(:last-of-type) {
        margin-right: var(--inset);
      }

      :host.stack {
        margin-right: inherit;
        border-bottom: 1px solid var(--border-2);
        border-radius: 0;
      }

      :host.stack:first-of-type {
        border-top-left-radius: var(--border-radius-big);
        border-top-right-radius: var(--border-radius-big);
      }

      :host.stack:last-of-type {
        border-bottom-left-radius: var(--border-radius-big);
        border-bottom-right-radius: var(--border-radius-big);
        border-bottom: 0;
      }

      :host-context(.app-elevation-2).stack {
        border-bottom-color: var(--border-2);
      }

      :host-context(.app-theme-dark .app-elevation-2).stack {
        border-bottom-color: var(--border-3);
      }

      :host.inline {
        margin-right: inherit;
        border-bottom: 1px solid var(--border-2);
        border-radius: var(--border-radius-big);
      }

      :host.inline:first-of-type {
        margin-right: var(--inset-tiny);
      }

      :host.icon-button {
        padding: 0;
        min-width: 0;
        flex-shrink: 0;
      }

      /* Sizes */

      :host.mini {
        font-size: var(--font-size-mini);
        padding: 1px 2px 0;
        line-height: calc(var(--font-size-mini) * 1.25);
        min-width: 12px;
      }

      :host.tiny {
        font-size: var(--font-size-tiny);
        padding: 0 2px 0;
        line-height: calc(var(--font-size-tiny) * 1.25);
        min-width: 12px;
      }

      :host.small {
        font-size: var(--font-size-small);
        padding: 1px 3px 1px;
        line-height: calc(var(--font-size-tiny) * 1.25);
        min-width: 12px;
      }

      :host.big {
        font-size: var(--font-size-big);
        line-height: calc(var(--font-size-big) + 0.75rem);
        padding: 0px 16px 3px;
      }

      :host.huge {
        font-size: var(--font-size-huge);
        line-height: calc(var(--font-size-huge) + 0.75rem);
        padding: 1px 23px 4px;
      }

      :host.huge:not(:last-of-type) {
        margin-right: var(--inset-big);
      }

      :host.gigantic {
        font-size: var(--font-size-huge);
        padding: 7px 15px 11px;
        font-weight: var(--font-header-weight);
        line-height: calc(var(--font-size-huge) + 0.75rem);
      }

      :host.extra-padding {
        padding: 10px;
      }

      @media screen and (max-width: 1240px) {
        :host {
          font-size: var(--font-size-small);
          padding: 1px 6px 5px;
          line-height: calc(var(--font-size-small) + 0.5rem);
          min-width: 12px;
        }

        :host.mini {
          font-size: var(--font-size-mini);
          padding: 1px 6px 2px;
          line-height: calc(var(--font-size-mini) * 1.25);
          min-width: 12px;
        }

        :host.tiny {
          font-size: var(--font-size-mini);
          padding: 1px 6px 2px;
          line-height: calc(var(--font-size-mini) * 1.25);
          min-width: 12px;
        }

        :host.small {
          font-size: var(--font-size-tiny);
          padding: 0 2px 0;
          line-height: calc(var(--font-size-tiny) * 1.25);
          min-width: 12px;
        }

        :host.big {
          font-size: var(--font-size);
          padding: 0 10px 3px;
          line-height: 24px;
        }

        :host.huge {
          font-size: var(--font-size-big);
          line-height: calc(var(--font-size-big) + 0.75rem);
          padding: 0px 16px 3px;
        }

        :host.gigantic {
          font-size: var(--font-size-huge);
          line-height: calc(var(--font-size-huge) + 0.75rem);
          padding: 1px 23px 4px;
        }

        :host.gigantic:not(:last-of-type) {
          margin-right: var(--inset-big);
        }

        :host.extra-padding {
          padding: 10px;
        }
      }

      /* Content wrapper */

      .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      :host.icon-button .wrapper .left,
      :host.center .wrapper .left {
        justify-content: center;
      }

      .wrapper .left {
        flex: 1 1 auto;
        min-width: 0;
        display: flex;
      }

      :host.center .wrapper .left {
        justify-content: center;
      }

      .wrapper .right {
        flex: 0 0 auto;
        display: flex;
      }

      .content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content > * {
        vertical-align: middle;
      }

      /* Style modes */

      :host:not(.unclickable):hover,
      :host:not(.unclickable):focus {
        background: var(--bg-1-lit);
        color: var(--accent-lit);
      }

      :host-context(.app-theme-dark):not(.unclickable):hover,
      :host-context(.app-theme-dark):not(.unclickable):focus {
        background: var(--bg-2-lit);
      }

      :host-context(.app-elevation-2):not(.unclickable):hover,
      :host-context(.app-elevation-2):not(.unclickable):focus {
        background: var(--bg-2-lit);
      }

      :host-context(.app-theme-dark .app-elevation-2):not(.unclickable):hover,
      :host-context(.app-theme-dark .app-elevation-2):not(.unclickable):focus {
        background: var(--bg-3-lit);
      }

      :host-context(.app-elevation-2).link,
      :host-context(.app-theme-dark .app-elevation-2).link,
      :host.link {
        background: transparent;
        color: var(--primary);
        padding-left: 0;
        padding-right: 0;
      }

      :host-context(.app-elevation-2).link:not(.unclickable):hover,
      :host-context(.app-elevation-2).link:not(.unclickable):focus,
      :host-context(.app-theme-dark
          .app-elevation-2).link:not(.unclickable):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).link:not(.unclickable):focus,
      :host.link:not(.unclickable):hover,
      :host.link:not(.unclickable):focus {
        background: transparent;
      }

      :host-context(.app-elevation-2).link:not(.unclickable):not(.disabled):hover,
      :host-context(.app-elevation-2).link:not(.unclickable):not(.disabled):focus,
      :host-context(.app-theme-dark
          .app-elevation-2).link:not(.unclickable):not(.disabled):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).link:not(.unclickable):not(.disabled):focus,
      :host.link:not(.unclickable):not(.disabled):hover,
      :host.link:not(.unclickable):not(.disabled):focus {
        background: transparent;
        color: var(--primary-lit);
      }

      :host.link.border,
      :host.primary.border,
      :host.border {
        border: 1px solid var(--border-1);
      }

      :host-context(.app-theme-dark).link.border,
      :host-context(.app-theme-dark).primary.border,
      :host-context(.app-theme-dark).border {
        border: 1px solid var(--border-2);
      }

      :host-context(.app-elevation-2).primary,
      :host-context(.app-theme-dark .app-elevation-2).primary,
      :host.primary {
        color: var(--primary);
      }

      :host-context(.app-elevation-2).primary:not(.unclickable):not(.disabled):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).primary:not(.unclickable):not(.disabled):hover,
      :host-context(.app-elevation-2).primary:not(.unclickable):not(.disabled):focus,
      :host-context(.app-theme-dark
          .app-elevation-2).primary:not(.unclickable):not(.disabled):focus,
      :host.primary:not(.unclickable):not(.disabled):hover,
      :host.primary:not(.unclickable):not(.disabled):focus {
        color: var(--primary-lit);
      }

      :host-context(.app-elevation-2).accent.link,
      :host-context(.app-theme-dark .app-elevation-2).accent.link,
      :host.accent.link {
        color: var(--accent);
      }

      :host-context(.app-elevation-2).accent.link:not(.unclickable):not(.disabled):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).accent.link:not(.unclickable):not(.disabled):hover,
      :host-context(.app-elevation-2).accent.link:not(.unclickable):not(.disabled):focus,
      :host-context(.app-theme-dark
          .app-elevation-2).accent.link:not(.unclickable):not(.disabled):focus,
      :host.accent.link:not(.unclickable):not(.disabled):hover,
      :host.accent.link:not(.unclickable):not(.disabled):focus {
        color: var(--accent-lit);
      }

      :host-context(.app-elevation-2).accent:not(.link):not(.disabled),
      :host-context(.app-theme-dark
          .app-elevation-2).accent:not(.link):not(.disabled),
      :host.accent:not(.link):not(.disabled) {
        color: var(--body-inverse);
        background: var(--accent);
      }

      :host-context(.app-elevation-2).accent:not(.link):not(.disabled):not(.unclickable):hover,
      :host-context(.app-elevation-2).accent:not(.link):not(.disabled):not(.unclickable):focus,
      :host-context(.app-theme-dark
          .app-elevation-2).accent:not(.link):not(.disabled):not(.unclickable):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).accent:not(.link):not(.disabled):not(.unclickable):focus,
      :host.accent:not(.link):not(.disabled):not(.unclickable):hover,
      :host.accent:not(.link):not(.disabled):not(.unclickable):focus {
        background: var(--accent-lit);
      }

      :host.accent.border {
        border: 1px solid var(--accent-lit);
      }

      :host-context(.app-elevation-2).accent-strong.link,
      :host-context(.app-theme-dark .app-elevation-2).accent-strong.link,
      :host.accent-strong.link {
        color: var(--accent-strong);
      }

      :host-context(.app-elevation-2).accent-strong.link:not(.unclickable):not(.disabled):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).accent-strong.link:not(.unclickable):not(.disabled):hover,
      :host-context(.app-elevation-2).accent-strong.link:not(.unclickable):not(.disabled):focus,
      :host-context(.app-theme-dark
          .app-elevation-2).accent-strong.link:not(.unclickable):not(.disabled):focus,
      :host.accent-strong.link:not(.unclickable):not(.disabled):hover,
      :host.accent-strong.link:not(.unclickable):not(.disabled):focus {
        color: var(--accent-strong-lit);
      }

      :host-context(.app-elevation-2).accent-strong:not(.link):not(.disabled),
      :host-context(.app-theme-dark
          .app-elevation-2).accent-strong:not(.link):not(.disabled),
      :host.accent-strong:not(.link):not(.disabled) {
        color: var(--body-inverse);
        background: var(--accent-strong);
      }

      :host-context(.app-elevation-2).accent-strong:not(.link):not(.disabled):not(.unclickable):hover,
      :host-context(.app-elevation-2).accent-strong:not(.link):not(.disabled):not(.unclickable):focus,
      :host-context(.app-theme-dark
          .app-elevation-2).accent-strong:not(.link):not(.disabled):not(.unclickable):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).accent-strong:not(.link):not(.disabled):not(.unclickable):focus,
      :host.accent-strong:not(.link):not(.disabled):not(.unclickable):hover,
      :host.accent-strong:not(.link):not(.disabled):not(.unclickable):focus {
        background: var(--accent-strong-lit);
      }

      :host.accent-strong.border {
        border: 1px solid var(--accent-strong-lit);
      }

      :host-context(.app-elevation-2).warn.link,
      :host-context(.app-theme-dark .app-elevation-2).warn.link,
      :host.warn.link {
        color: var(--warn);
      }

      :host-context(.app-elevation-2).warn.link:not(.unclickable):not(.disabled):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).warn.link:not(.unclickable):not(.disabled):hover,
      :host-context(.app-elevation-2).warn.link:not(.unclickable):not(.disabled):focus,
      :host-context(.app-theme-dark
          .app-elevation-2).warn.link:not(.unclickable):not(.disabled):focus,
      :host.warn.link:not(.unclickable):not(.disabled):hover,
      :host.warn.link:not(.unclickable):not(.disabled):focus {
        color: var(--warn-lit);
      }

      :host-context(.app-elevation-2).warn:not(.link):not(.disabled),
      :host-context(.app-theme-dark
          .app-elevation-2).warn:not(.link):not(.disabled),
      :host.warn:not(.link):not(.disabled) {
        color: var(--body-inverse);
        background: var(--warn);
      }

      :host-context(.app-elevation-2).warn:not(.link):not(.disabled):not(.unclickable):hover,
      :host-context(.app-elevation-2).warn:not(.link):not(.disabled):not(.unclickable):focus,
      :host-context(.app-theme-dark
          .app-elevation-2).warn:not(.link):not(.disabled):not(.unclickable):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).warn:not(.link):not(.disabled):not(.unclickable):focus,
      :host.warn:not(.link):not(.disabled):not(.unclickable):hover,
      :host.warn:not(.link):not(.disabled):not(.unclickable):focus {
        background: var(--warn-lit);
      }

      :host.warn.border {
        border: 1px solid var(--warn-lit);
      }

      :host-context(.app-elevation-2).body.link,
      :host-context(.app-theme-dark .app-elevation-2).body.link,
      :host.body.link {
        color: var(--body);
      }

      :host-context(.app-elevation-2).body.link:not(.unclickable):not(.disabled):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).body.link:not(.unclickable):not(.disabled):hover,
      :host-context(.app-elevation-2).body.link:not(.unclickable):not(.disabled):focus,
      :host-context(.app-theme-dark
          .app-elevation-2).body.link:not(.unclickable):not(.disabled):focus,
      :host.body.link:not(.unclickable):not(.disabled):hover,
      :host.body.link:not(.unclickable):not(.disabled):focus {
        color: var(--primary-lit);
      }

      :host-context(.app-elevation-2).body:not(.link):not(.disabled),
      :host-context(.app-theme-dark
          .app-elevation-2).body:not(.link):not(.disabled),
      :host.body:not(.link):not(.disabled) {
        color: var(--body-inverse);
        background: var(--body);
      }

      :host-context(.app-elevation-2).body:not(.link):not(.disabled):not(.unclickable):hover,
      :host-context(.app-elevation-2).body:not(.link):not(.disabled):not(.unclickable):focus,
      :host-context(.app-theme-dark
          .app-elevation-2).body:not(.link):not(.disabled):not(.unclickable):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).body:not(.link):not(.disabled):not(.unclickable):focus,
      :host.body:not(.link):not(.disabled):not(.unclickable):hover,
      :host.body:not(.link):not(.disabled):not(.unclickable):focus {
        background: var(--primary-lit);
      }

      :host.body.border {
        border: 1px solid var(--primary-lit);
      }

      :host-context(.app-elevation-2).translucent,
      :host.translucent {
        background: rgba(255, 255, 255, 0.6);
      }

      :host-context(.app-theme-dark .app-elevation-2).translucent,
      :host-context(.app-theme-dark).translucent {
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(10px);
      }

      :host-context(.app-elevation-2).translucent:not(.unclickable):hover,
      :host-context(.app-elevation-2).translucent:not(.unclickable):focus,
      :host.translucent:not(.unclickable):hover,
      :host.translucent:not(.unclickable):focus {
        background: rgba(255, 255, 255, 0.8);
      }

      :host-context(.app-theme-dark
          .app-elevation-2).translucent:not(.unclickable):hover,
      :host-context(.app-theme-dark
          .app-elevation-2).translucent:not(.unclickable):focus,
      :host-context(.app-theme-dark).translucent:not(.unclickable):hover,
      :host-context(.app-theme-dark).translucent:not(.unclickable):focus {
        background: rgba(0, 0, 0, 0.8);
      }

      /* Disabled */

      :host-context(.app-elevation-2).disabled:hover,
      :host-context(.app-theme-dark .app-elevation-2).disabled:hover,
      :host-context(.app-elevation-2).disabled:focus,
      :host-context(.app-theme-dark .app-elevation-2).disabled:focus,
      :host-context(.app-elevation-2).disabled,
      :host-context(.app-theme-dark .app-elevation-2).disabled,
      :host.disabled:hover,
      :host.disabled:focus,
      :host.disabled {
        color: var(--subtle);
        cursor: not-allowed;
      }

      :host.disabled.accent:not(.link):hover,
      :host.disabled.accent:not(.link):focus,
      :host.disabled.accent:not(.link),
      :host.disabled.accent-strong:not(.link):hover,
      :host.disabled.accent-strong:not(.link):focus,
      :host.disabled.accent-strong:not(.link) {
        background: var(--bg-1);
      }

      :host-context(.app-theme-dark).disabled.accent:not(.link):hover,
      :host-context(.app-theme-dark).disabled.accent:not(.link):focus,
      :host-context(.app-theme-dark).disabled.accent:not(.link),
      :host-context(.app-theme-dark).disabled.accent-strong:not(.link):hover,
      :host-context(.app-theme-dark).disabled.accent-strong:not(.link):focus,
      :host-context(.app-theme-dark).disabled.accent-strong:not(.link) {
        background: var(--bg-2);
      }

      :host.disabled.border {
        border: 1px solid var(--border-1);
      }

      :host.unclickable,
      :host.unclickable:hover,
      :host.unclickable:focus {
        cursor: auto;
      }
    `,
  ],
  template: `
    <div class="wrapper">
      <div class="left">
        <span class="content">
          <ng-content></ng-content>
        </span>
      </div>
      <div *ngIf="rightTemplate" class="right">
        <ng-container
          *ngIf="rightTemplate"
          [ngTemplateOutlet]="rightTemplate"
        ></ng-container>
      </div>
    </div>
  `,
})
export class ButtonComponent extends _SizeMixinBase implements CanSize {
  // Style mode: Link
  @Input() set link(value: any) {
    this._link = coerceBooleanProperty(value)
  }
  get link() {
    return this._link
  }
  @HostBinding('class.link') _link = false

  // Style mode: Primary
  @Input() set primary(value: any) {
    this._primary = coerceBooleanProperty(value)
  }
  get primary() {
    return this._primary
  }
  @HostBinding('class.primary') _primary = false

  // Style mode: Accent
  @Input() set accent(value: any) {
    this._accent = coerceBooleanProperty(value)
  }
  get accent() {
    return this._accent
  }
  @HostBinding('class.accent') _accent = false

  // Style mode: Accent Strong
  @Input() set accentStrong(value: any) {
    this._accentStrong = coerceBooleanProperty(value)
  }
  get accentStrong() {
    return this._accentStrong
  }
  @HostBinding('class.accent-strong') _accentStrong = false

  // Style mode: Warn
  @Input() set warn(value: any) {
    this._warn = coerceBooleanProperty(value)
  }
  get warn() {
    return this._warn
  }
  @HostBinding('class.warn') _warn = false

  // Style mode: Body
  @Input() set body(value: any) {
    this._body = coerceBooleanProperty(value)
  }
  get body() {
    return this._body
  }
  @HostBinding('class.body') _body = false

  // Border
  @Input() set border(value: any) {
    this._border = coerceBooleanProperty(value)
  }
  get border() {
    return this._border
  }
  @HostBinding('class.border') _border = false

  // Translucent
  @Input() set translucent(value: any) {
    this._translucent = coerceBooleanProperty(value)
  }
  get translucent() {
    return this._translucent
  }
  @HostBinding('class.translucent') _translucent = false

  // Disabled
  @Input() set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value)
  }
  get disabled() {
    return this._disabled
  }
  @HostBinding('class.disabled') _disabled = false

  // Stack (vertical list of buttons)
  @Input() set stack(value: any) {
    this._stack = coerceBooleanProperty(value)
  }
  get stack() {
    return this._stack
  }
  @HostBinding('class.stack') _stack = false

  // Inline (inline list of buttons)
  @Input() set inline(value: any) {
    this._inline = coerceBooleanProperty(value)
  }
  get inline() {
    return this._inline
  }
  @HostBinding('class.inline') _inline = false

  // Center
  @Input() set center(value: any) {
    this._center = coerceBooleanProperty(value)
  }
  get center() {
    return this._center
  }
  @HostBinding('class.center') _center = false

  // Extra Padding
  @Input() set extraPadding(value: any) {
    this._extraPadding = coerceBooleanProperty(value)
  }
  get extraPadding() {
    return this._extraPadding
  }
  @HostBinding('class.extra-padding') _extraPadding = false

  // Unclickable: No visible style/cursor changes on hover
  @Input() set unclickable(value: any) {
    this._unclickable = coerceBooleanProperty(value)
  }
  get unclickable() {
    return this._unclickable
  }
  @HostBinding('class.unclickable') _unclickable = false

  // Size mixin
  @Input() size: Size
  @Input() gigantic: boolean
  @Input() huge: boolean
  @Input() big: boolean
  @Input() small: boolean
  @Input() tiny: boolean
  @Input() mini: boolean
  @HostBinding('class.gigantic')
  get isSizeGigantic() {
    return this.size === 'gigantic'
  }
  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }
  @HostBinding('class.mini')
  get isSizeMini() {
    return this.size === 'mini'
  }

  @ContentChild('rightTemplate') rightTemplate: TemplateRef<any>

  constructor(public elementRef: ElementRef) {
    super(elementRef)
  }
}
