import { getCurrencySymbol } from '@angular/common'
import { curry, either, partition, uniqBy } from 'ramda'
import { analyzereConstants } from '@shared/constants/analyzere'
import { isIndexedLayer } from '../layers/indexed-layer'
import { isMultiSectionLayer } from '../layers/multi-section-layer'
import { isSwingLayer } from '../layers/swing-layer'
import {
  Fee,
  LoadedLossSet,
  LogicalPortfolioLayer,
  LossSetLayer,
  Metadata,
  Ref,
} from '../../api/analyzere/analyzere.model'
import { rejectNil } from '@shared/util/operators'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import { INewBox } from '../tower/mechanics/tower.model'
import { DEFAULT_TEMPLATE_LAYER_ID, Layer, LayerRef } from './layers.model'
import { layerIds } from './layer-palette.model'

export const findLayer = curry((layers: Layer[], id: string) =>
  findLayerById(layers, id)
)

export const findLayerById = (layers: Layer[], id?: string | null) =>
  layers.find(layer => id && layer.id === id)

export const findLayerByVisibleId = (
  layers: Layer[],
  visibleId?: string | null
) =>
  layers.find(
    layer => visibleId && layer.meta_data.visible_layer_id === visibleId
  )

export const isLayerShared = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean => layer.meta_data.sage_layer_type === 'shared_limits'

export const isLayerInuranceSource = (layer?: Layer): boolean =>
  layer != null && layer.meta_data.inuranceSource === true

export const isLayerInuranceTarget = (layer?: Layer): boolean =>
  layer != null && layer.meta_data.inuranceTarget === true

export const isLayerInurance = either(
  isLayerInuranceSource,
  isLayerInuranceTarget
)

export const isLayerActualTopAndDrop = <
  T extends { meta_data: Partial<Metadata> },
>(
  layer: T
): boolean =>
  layer.meta_data?.sage_layer_type === layerIds.catTd &&
  layer.meta_data?.sage_layer_subtype === 'actual'

export const isLayerDrop = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean =>
  layer.meta_data.sage_layer_type === 'drop' && layer.meta_data.isDrop === true

export const isLayerTop = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean =>
  layer.meta_data.sage_layer_type === layerIds.catTd &&
  layer.meta_data.sage_layer_subtype === 'virtual'

export const isLayerTopOrDrop = either(isLayerDrop, isLayerTop)

export const findTopAndDropLayers = (
  layers: Layer[],
  actualTndLayer: Layer
): Layer[] =>
  // @ts-ignore
  rejectNil(actualTndLayer.layerRefs.map(findLayer(layers))).filter(
    isLayerTopOrDrop
  ) as Layer[]

export const findActualTopAndDropLayer = curry(
  (layers: Layer[], topOrDropLayerID: string): Layer | undefined =>
    layers.find(
      l => isLayerActualTopAndDrop(l) && l.layerRefs.includes(topOrDropLayerID)
    )
)

export function areLayerIDsInSameTopAndDrop(
  layers: Layer[],
  idA?: string,
  idB?: string
): boolean {
  if (idA == null || idB == null) {
    return false
  }

  const find = findLayer(layers)
  const layerA = find(idA)
  const layerB = find(idB)
  if (
    !layerA ||
    !layerB ||
    !isLayerTopOrDrop(layerA) ||
    !isLayerTopOrDrop(layerB)
  ) {
    return false
  }

  const findTnd = findActualTopAndDropLayer(layers)
  const tndA = findTnd(layerA.id)
  const tndB = findTnd(layerB.id)
  return tndA != null && tndB != null && tndA === tndB
}

export const partitionLayersByTopAndDrop = (
  layers: Layer[]
): readonly [Layer[], Layer[]] => {
  const [topAndDrop, nonTopAndDrop] = partition(isLayerActualTopAndDrop, layers)
  topAndDrop.forEach(td => {
    const sources = td.layerRefs
    const tdRefs = layers.filter(c => sources.includes(c.id))
    tdRefs.forEach(tdRef => {
      tdRef.meta_data.prevID = tdRef.id
    })
  })
  return [topAndDrop, nonTopAndDrop]
}

export const updateTopAndDropLogicalLayerRefs = (
  topAndDropLayers: Layer[],
  logicalLayers: LogicalPortfolioLayer[]
): void => {
  topAndDropLayers.forEach(td => {
    const layerRefs = logicalLayers.filter(l =>
      // tslint:disable-next-line: no-non-null-assertion
      td.layerRefs.includes(l.meta_data.prevID!)
    )
    td.layerRefs = layerRefs
      .sort(logicalLayerByTopAndDropComparator)
      .map(l => l.id)
  })
}

export const logicalLayerByTopAndDropComparator = (
  a: LogicalPortfolioLayer,
  b: LogicalPortfolioLayer
) => {
  if (
    a.meta_data.sage_layer_type === layerIds.catTd &&
    b.meta_data.sage_layer_type !== layerIds.catTd
  ) {
    return -1
  } else if (a.meta_data.sage_layer_type === b.meta_data.sage_layer_type) {
    return 0
  } else {
    return 1
  }
}

export const createCededLayerRefs = (
  layers: LogicalPortfolioLayer[],
  topAndDropLayers: LogicalPortfolioLayer[],
  FHCFLayers: LogicalPortfolioLayer[]
): LogicalPortfolioLayer[] => {
  const allLayers = [...topAndDropLayers, ...layers, ...FHCFLayers]

  // Add direct child layers (sources)
  const sources = allLayers
    .filter(
      layer =>
        isLayerActualTopAndDrop(layer) ||
        isLayerActualFHCF(layer) ||
        isLayerActualRisk(layer)
    )
    .flatMap(layer =>
      (layer.sources as LogicalPortfolioLayer[]).map(source => source.id)
    )

  const aggFeeder = allLayers.filter(isLayerAggFeeder).map(layer => layer.id)

  const riskVisible = allLayers
    .filter(layer => layer.meta_data.isRiskVisible)
    .map(layer => layer.id)

  const complex = allLayers
    .filter(
      layer =>
        (isIndexedLayer(layer) && !isIndexedLayer(layer, 'main-layer')) ||
        (isSwingLayer(layer) && !isSwingLayer(layer, 'combined-layer')) ||
        (isMultiSectionLayer(layer) &&
          !isMultiSectionLayer(layer, 'main-layer', 'section-layer'))
    )
    .map(layer => layer.id)

  return allLayers.filter(
    layerExclude([...sources, ...aggFeeder, ...riskVisible, ...complex])
  )
}

export const createRef = (id: string): Ref => ({ ref_id: id })

export const convertLayersToRef = (
  layers: LogicalPortfolioLayer[] | LossSetLayer[] | Ref[]
): LogicalPortfolioLayer[] | LossSetLayer[] | Ref[] =>
  uniqBy(
    l => l.ref_id,
    (layers as LogicalPortfolioLayer[]).map(l => createRef(l.id))
  )

export const layerExclude = curry(
  (excludeList: string[], l: Layer | LogicalPortfolioLayer) =>
    !excludeList.includes(l.id)
)

export const isLayerRef = (layer: any): layer is Ref =>
  (layer as Ref).ref_id !== undefined

export const getLayerOrRefID = (
  layer: LogicalPortfolioLayer | LossSetLayer | Ref
): string => (isLayerRef(layer) ? layer.ref_id : layer.id)

export const isLayerAggFeeder = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean => {
  return (
    layer.meta_data.sage_layer_subtype === layerIds.feeder &&
    (layer.meta_data.sage_layer_type === layerIds.catAg ||
      layer.meta_data.sage_layer_type === layerIds.noncatAg ||
      layer.meta_data.sage_layer_type === layerIds.ahlAg)
  )
}

export const isAggLayer = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean => {
  return (
    layer.meta_data.sage_layer_type === layerIds.catAg ||
    layer.meta_data.sage_layer_type === layerIds.noncatAg ||
    layer.meta_data.sage_layer_type === layerIds.ahlAg
  )
}

export const isLayerFHCF = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean => {
  return layer.meta_data.sage_layer_type === layerIds.catFhcf
}

export const isLayerILWBin = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean => {
  return layer.meta_data.sage_layer_type === layerIds.ilwBin
}

export const isLayerILWProRata = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean => {
  return layer.meta_data.sage_layer_type === layerIds.ilwProRata
}

export const isLayerVirtualTD = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean => {
  return (
    layer.meta_data.sage_layer_type === layerIds.catTd &&
    layer.meta_data.sage_layer_subtype === 'virtual'
  )
}

export const isLayerVirtualDrop = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean => {
  return layer.meta_data.sage_layer_type === 'drop'
}

export const isLayerRisk = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean => {
  return layer.meta_data.sage_layer_type === layerIds.noncatRisk
}

export const isLayerActualFHCF = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean =>
  layer.meta_data.sage_layer_type === layerIds.catFhcf &&
  layer.meta_data.isFHCFFinal === true

export function findFHCFActualLayer(
  alllayers: Layer[],
  layerID: string
): Layer | undefined {
  return alllayers.find(
    l => l.meta_data.isFHCFFinal && l.lossSetLayers[0].id === layerID
  )
}

export function findFHCFHidden1Layer(
  allLayers: Layer[],
  layer: Layer
): Layer | undefined {
  const id = layer.lossSetLayers[0].id
  if (id) {
    return allLayers.find(l => l.id === id)
  }
}

export const updateActaulFHCFLogicalLayerRefs = (
  fHCFActualLayers: Layer[],
  logicalLayers: LogicalPortfolioLayer[]
): void => {
  fHCFActualLayers.forEach(td => {
    const oldTDSources = td.lossSetLayers.map(l => l.id)
    const fHCFHidden1 = logicalLayers.find(
      l =>
        l.meta_data.isFHCFHidden1 &&
        oldTDSources.includes(l.meta_data.fromLayerID as string)
    )
    const fHCFHidden2 = logicalLayers.find(
      l =>
        l.meta_data.isFHCFHidden2 &&
        oldTDSources.includes(l.meta_data.fromLayerID as string)
    )
    if (fHCFHidden1 && fHCFHidden2) {
      const sources: LayerRef[] = []
      sources.push({ id: fHCFHidden1.id, meta_data: fHCFHidden1.meta_data })
      sources.push({ id: fHCFHidden2.id, meta_data: fHCFHidden2.meta_data })
      td.lossSetLayers = sources
    }
  })
}

export const isLayerActualRisk = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean =>
  layer.meta_data.sage_layer_type === layerIds.noncatRisk &&
  layer.meta_data.isRiskFinal === true

export const isNoncatIndexlLayer = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean =>
  layer.meta_data.sage_layer_type === layerIds.noncatIndxl &&
  layer.meta_data.sage_layer_subtype === 'main-layer'

export function findRiskActualLayer(
  alllayers: Layer[],
  layerID: string
): Layer | undefined {
  return alllayers.find(
    l => l.meta_data.isRiskFinal && l.meta_data.riskVisibleLayerID === layerID
  )
}

export function findRiskLargeVisibleLayer(
  allLayers: Layer[],
  layer: Layer
): Layer | undefined {
  const id = layer.meta_data.riskVisibleLayerID
  if (id) {
    return allLayers.find(l => l.id === id)
  }
}

export function findMainLayerOfSectionLayer(
  layers: Layer[],
  sectionLayer: Layer
): Layer | undefined {
  return layers.find(
    layer =>
      layer.meta_data.sage_layer_subtype === 'main-layer' &&
      layer.layerRefs.includes(sectionLayer.id)
  )
}

export function findIndexedVisibleLayer(
  allLayers: Layer[],
  layer: Layer
): Layer | undefined {
  const visibleId = layer.meta_data.visible_layer_id
  if (visibleId) {
    return allLayers.find(l => l.id === visibleId)
  }
}

export function findMultiSelectVisibleLayer(
  allLayers: Layer[],
  layer?: Layer
): Layer | undefined {
  if (!layer) {
    return undefined
  }

  const type = layer.meta_data
  if (type) {
    return allLayers.find(l => {
      return (
        l.meta_data.sage_layer_type === layer.meta_data.sage_layer_type &&
        l.meta_data.sage_layer_subtype === 'visible-layer' &&
        layer.meta_data.visible_layer_id === l.id
      )
    })
  }
}

export function findMultiSectionMainLayer(
  allLayers: Layer[],
  layer?: Layer
): Layer | undefined {
  if (!layer) {
    return undefined
  }

  const meta_data = layer.meta_data
  if (meta_data) {
    return allLayers.find(l => {
      return (
        l.meta_data.sage_layer_type === meta_data.sage_layer_type &&
        l.meta_data.sage_layer_subtype === 'main-layer' &&
        l.meta_data.visible_layer_id === layer.id
      )
    })
  }
}

export function findSwingRatedVisibleLayer(
  allLayers: Layer[],
  layer: Layer
): Layer | undefined {
  const type = layer.meta_data
  if (type) {
    return allLayers.find(l => {
      return isSwingLayer(l, 'visible-layer')
    })
  }
}

export function findSwingRatedCombinedLayer(
  allLayers: Layer[],
  layer: Layer
): Layer | undefined {
  const meta_data = layer.meta_data
  if (meta_data) {
    return allLayers.find(l => {
      return (
        isSwingLayer(l, 'combined-layer') &&
        l.meta_data.visible_layer_id === layer.id
      )
    })
  }
}

export const isINewBoxAggFeeder = (item: INewBox): boolean => {
  return (
    item.subtype === layerIds.feeder &&
    (item.sage_type === layerIds.catAg ||
      item.sage_type === layerIds.noncatAg ||
      item.sage_type === layerIds.ahlAg)
  )
}

export const isINewBoxAggLayer = (item: INewBox): boolean => {
  return (
    item.subtype !== layerIds.feeder &&
    (item.sage_type === layerIds.catAg ||
      item.sage_type === layerIds.noncatAg ||
      item.sage_type === layerIds.ahlAg)
  )
}

export const isLayerAgg = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean => {
  return (
    layer.meta_data.sage_layer_subtype !== layerIds.feeder &&
    (layer.meta_data.sage_layer_type === layerIds.catAg ||
      layer.meta_data.sage_layer_type === layerIds.noncatAg ||
      layer.meta_data.sage_layer_type === layerIds.ahlAg)
  )
}

export const isLayerVisibleRisk = <T extends { meta_data: Partial<Metadata> }>(
  layer: T
): boolean => {
  return (
    layer.meta_data.sage_layer_type === layerIds.noncatRisk &&
    layer.meta_data.isRiskVisible === true
  )
}

export const isAHLLayer = (id: string): boolean => {
  return id === layerIds.ahlXl || id === layerIds.ahlQs || id === layerIds.ahlAg || id === layerIds.ahlSwing
}

export const isQSLayer = (id: string): boolean => {
  return id === layerIds.catQs || id === layerIds.noncatQs
}

export const isRateSubjectLayer = (id: string): boolean => {
  return (
    id === layerIds.noncatXl ||
    id === layerIds.noncatIndxl ||
    id === layerIds.noncatRisk ||
    id === layerIds.noncatSwing
  )
}

export const isROLLayer = (id: string): boolean => {
  return !isQSLayer(id) && !isAHLLayer(id)
}

export const findTDIDs = (layerStates: LayerState[], id: string): string[] => {
  const hidden = layerStates.find(
    l =>
      !l.deleted &&
      l.layer.meta_data.sage_layer_subtype === 'actual' &&
      l.layer.layerRefs.includes(id)
  )
  const arr: string[] = []
  if (hidden) {
    hidden.layer.layerRefs.forEach(h => {
      arr.push(h)
    })
    arr.push(hidden.layer.id)
  }
  return arr
}

export const filterValid = (layer: Layer) => {
  const meta = layer.meta_data
  const type = meta.sage_layer_type
  const subtype = meta.sage_layer_subtype

  return (
    !(type === 'drop' && meta.isDrop) &&
    !(type === 'shared_limits' && subtype === 'virtual') &&
    !layer.sharedLayerID &&
    !(
      (type === layerIds.catAg ||
        type === layerIds.noncatAg ||
        type === layerIds.ahlAg) &&
      subtype === layerIds.feeder
    ) &&
    !(
      type === layerIds.catFhcf &&
      (meta.isFHCFHidden1 || meta.isFHCFHidden2)
    ) &&
    !(
      type === layerIds.noncatRisk &&
      (meta.isRiskLargeHidden || meta.isRiskCatHidden || meta.isRiskVisible)
    ) &&
    !(type === layerIds.noncatIndxl && subtype !== 'main-layer') &&
    !isSwingLayer(
      layer,
      'visible-layer',
      'loss-layer',
      'premium-layer',
      'adjustment-layer'
    ) &&
    !isMultiSectionLayer(layer, 'visible-layer', 'flipper-layer')
  )
}

export const filterTowerLayerList = (l: LayerState): boolean => {
  const { meta_data, physicalLayer, layerRefs } = l.layer
  // indeXoL is set to visible: false, so this is a workaround
  const indeXoLCheck =
    meta_data?.sage_layer_subtype === 'visible-layer'
      ? true
      : !meta_data?.hidden
  const noncatRiskCheck =
    meta_data?.sage_layer_type === layerIds.noncatRisk
      ? (meta_data?.isRiskVisible as boolean)
      : true
  return (
    !l.deleted &&
    physicalLayer.description !== '' &&
    indeXoLCheck &&
    layerRefs?.length === 0 &&
    !meta_data?.isFHCFHidden1 &&
    !meta_data?.isFHCFHidden2 &&
    noncatRiskCheck &&
    l.layer.id !== DEFAULT_TEMPLATE_LAYER_ID
  )
}

export const filterTowerLayers = (layerState: LayerState): boolean => {
  const layer = layerState.layer
  const type = layer.meta_data.sage_layer_type
  const subtype = layer.meta_data.sage_layer_subtype

  return (
    !layerState.deleted &&
    !(type === layerIds.catTd && subtype === 'actual') &&
    !(type === layerIds.catFhcf && subtype === 'actual') &&
    !(type === layerIds.noncatRisk && subtype === 'actual') &&
    !(type === layerIds.noncatIndxl && subtype !== 'visible-layer') &&
    !isSwingLayer(
      layer,
      'combined-layer',
      'loss-layer',
      'premium-layer',
      'adjustment-layer'
    ) &&
    !isMultiSectionLayer(
      layer,
      'main-layer',
      'section-layer',
      'flipper-layer'
    ) &&
    type !== 'shared_limits' &&
    type !== 'shared_limit'
  )
}

export const filterQuoteTowerLayers = (layerState: LayerState): boolean => {
  const layer = layerState.layer
  const meta = layer.meta_data
  const type = meta.sage_layer_type
  const subtype = meta.sage_layer_subtype

  return (
    !layerState.deleted &&
    !(type === layerIds.catTd && subtype === 'virtual') &&
    !(type === layerIds.catFhcf && subtype === 'actual') &&
    !(type === layerIds.drop) &&
    !(type === layerIds.noncatRisk && subtype === 'actual') &&
    !(type === layerIds.noncatIndxl && subtype !== 'visible-layer') &&
    !isSwingLayer(
      layer,
      'combined-layer',
      'loss-layer',
      'premium-layer',
      'adjustment-layer'
    ) &&
    !isMultiSectionLayer(
      layer,
      'main-layer',
      'section-layer',
      'flipper-layer'
    ) &&
    type !== 'shared_limits' &&
    type !== 'shared_limit'
  )
}

export const filterTowerLayerResults = (layerState: LayerState) => {
  const layer = layerState.layer
  const meta = layer.meta_data
  const type = meta.sage_layer_type
  const subtype = meta.sage_layer_subtype

  if (layerState.deleted) {
    return false
  }
  if (type === undefined) {
    return false
  }
  if (type === layerIds.catTd || type === layerIds.catFhcf) {
    return subtype === 'actual'
  }
  if (type === layerIds.noncatRisk) {
    return meta.isRiskFinal
  }
  if (isIndexedLayer(layer)) {
    return isIndexedLayer(layer, 'main-layer')
  }
  if (isSwingLayer(layer)) {
    return isSwingLayer(layer, 'combined-layer')
  }
  if (isMultiSectionLayer(layer)) {
    return isMultiSectionLayer(layer, 'main-layer')
  }
  if (type === 'shared_limits' || type === 'shared_limit') {
    return false
  }

  return true
}

export function isLayerBackAllocated<
  T extends { meta_data: Partial<Metadata> },
>(layer: T): boolean {
  return (
    layer.meta_data.sage_layer_type === 'shared_limit' &&
    layer.meta_data.sage_layer_subtype === 'backallocated'
  )
}

export const findBackAllocatedVisibleLayer = curry(
  (allLayers: Layer[], layer: Layer): Layer | undefined => {
    const id = layer.meta_data.backAllocatedForID
    if (id) {
      return allLayers.find(l => l.id === id)
    }
  }
)

export function findBackAllocatedLayerFor(
  allLayers: Layer[],
  layerID: string
): Layer | undefined {
  return allLayers.find(l => l.meta_data.backAllocatedForID === layerID)
}

export function isLoadedLossSet(lossSet: any): lossSet is LoadedLossSet {
  return lossSet._type === 'LoadedLossSet'
}

export function createProportionalExpense(name: string, rate: number): Fee {
  return {
    payout_date: '2035-12-31T19:00:00',
    _type: 'ProportionalExpense',
    rate,
    premiums: [
      { ref: ['Layer', 'Premium'] },
      { ref: ['Layer', 'ReinstatementPremium'] },
    ],
    name,
  }
}

export function currencySymbol(currentCurrency: string): string {
  return currentCurrency ? getCurrencySymbol(currentCurrency, 'narrow') : '$'
}

export function getMultiSectionData(layers: Layer[], visibleLayer: Layer) {
  const mainLayer = findLayerByVisibleId(layers, visibleLayer.id)
  if (!mainLayer) {
    return undefined
  }

  return mainLayer.layerRefs.map(id => {
    const sectionLayer = findLayerById(layers, id)
    if (!sectionLayer) {
      throw Error(`Unable to find the section layer ${id} for ${mainLayer.id}`)
    }

    return {
      aggregateAttachment: sectionLayer.physicalLayer.aggregateAttachment.value,
      cession: sectionLayer.physicalLayer.participation,
      attachment: sectionLayer.physicalLayer.attachment.value,
      limit: sectionLayer.physicalLayer.limit.value,
      currency: sectionLayer.currency,
    }
  })
}

export function canSetContractOccurrenceUnlimited(
  layer: Layer,
  layers: LayerState[] | null | undefined
): boolean {
  layers = layers || []
  const layerRefs: string[] =
    layers.find(l => l.layer.meta_data.visible_layer_id === layer.id)?.layer
      .layerRefs || []
  for (const layerID of layerRefs) {
    const foundLayer = layers?.find(l => l.layer.id === layerID)
    if (
      foundLayer &&
      foundLayer.layer.physicalLayer.limit.value >=
        analyzereConstants.unlimitedValue
    ) {
      return false
    }
  }
  return true
}
