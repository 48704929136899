import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { Numeric } from 'd3'
import { format } from 'd3-format'
import { BubbleChart } from '@graphing/bubble-chart'
import { D3SeriesAnnotation } from '@graphing/models/series-annotation.model'
import {
  CompareMetricCategory,
  CompareMetricSetting,
  CompareStructureOptionsDatum,
  CompareStructureOptionsDimension,
  CompareStructureOptionsDimensionChangeEvent,
} from '../../model/compare-metrics.model'
import {
  CompareEntity,
  StructureOptions,
} from '../../store/compare/compare.reducer'
import { currencySymbol } from '../../model/layers.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-structure-options',
  styleUrls: ['./compare-structure-options.component.scss'],
  templateUrl: './compare-structure-options.component.html',
})
export class CompareStructureOptionsComponent
  implements OnChanges, AfterViewInit, OnInit
{
  private chart: BubbleChart<CompareStructureOptionsDatum>
  noneObj: CompareMetricSetting = {
    saveID: 0,
    category: 'None',
    label: '',
    valueType: 'percentage',
    metricSettingID: 0,
    ordinal: 0,
    show: true,
    weight: 0,
    path: '',
  }

  updatedProps: CompareMetricSetting[] = []
  defaultProp: string
  metricCategory: CompareMetricCategory[] = []

  @Input() loading: boolean
  @Input() error?: string
  @Input() data: CompareStructureOptionsDatum[]
  @Input() state: StructureOptions
  @Input() props: CompareMetricSetting[]
  @Input() propsWithCategory: CompareEntity[]
  @Input() currentCurrency: string

  @Output() dimensionChange =
    new EventEmitter<CompareStructureOptionsDimensionChangeEvent>()

  @ViewChild('chart') chartEl: ElementRef | undefined

  group: CompareMetricCategory[] = []

  ngOnInit(): void {
    this.updatedProps = [...this.props]
    this.updatedProps.splice(0, 0, this.noneObj)
    this.defaultProp = this.findDefaultAxes()!
    this.dimensionChange.emit({ dimension: 'y', prop: this.defaultProp })
  }

  ngAfterViewInit(): void {
    this.init()
  }

  ngOnChanges(): void {
    this.init()
  }

  init() {
    if (
      !this.chartEl ||
      !this.chartEl.nativeElement ||
      this.loading ||
      this.error ||
      this.data.length === 0
    ) {
      return
    }

    this.metricCategory = this.propsWithCategory[0].metricCategories
    this.metricCategory = this.metricCategory.filter(a => {
      return a.category !== 'Gross Metrics'
    })

    const xProp = this.props.find(
      p => p.category + ' ' + p.label === this.state.x
    )
    const yProp = this.props.find(
      p => p.category + ' ' + p.label === this.state.y
    )

    const sizeProp = this.updatedProps.find(
      p => p.category + ' ' + p.label === this.state.size
    )

    if (xProp && yProp) {
      const formatters: Record<
        CompareStructureOptionsDimension,
        ValueFormatter
      > = {
        x: getFormatter(xProp),
        y: getFormatter(yProp),
        size: getFormatter(sizeProp),
      }

      this.chart = new BubbleChart(this.chartEl.nativeElement, {
        crossValue: (d: CompareStructureOptionsDatum) => d.x,
        mainValue: (d: CompareStructureOptionsDatum) => d.y,
        size: (d: CompareStructureOptionsDatum) => d.size,
        label: (d: CompareStructureOptionsDatum) => d.description,
        xLabel: this.currencyFormatter(xProp.valueType, xProp.label),
        yLabel: this.currencyFormatter(yProp.valueType, yProp.label),
        colorClass: colorClassFn,
        annotationBuilder: makeAnnotationBuilder(
          formatters,
          xProp.valueType,
          yProp.valueType,
          xProp.label,
          yProp.label
        ),
        xTickFormat: formatters.x,
        yTickFormat: formatters.y,
        defaultColor: 'var(--accent-strong)',
        lineColorClass: 'app-palette-4',
      })
      this.chart.draw(this.data)
    }
  }

  render(): void {
    if (this.chart) {
      this.chart.render()
    }
  }

  onDimensionChange(dimension: CompareStructureOptionsDimension, prop: string) {
    this.dimensionChange.emit({ dimension, prop })
  }

  currencyFormatter(valueType: string | undefined, axisLabel: string) {
    if (valueType === 'currency') {
      return axisLabel + ' (' + currencySymbol(this.currentCurrency) + 'm)'
    } else {
      return axisLabel
    }
  }

  findDefaultAxes(): string {
    let tailMetrics = this.props!.filter(a => {
      return a.category == 'Tail Metrics' && a.show == true
    })
    let volatilityMetrics = this.props!.filter(a => {
      return a.category == 'Volatility Metrics' && a.show == true
    })
    let capitalMetrics = this.props!.filter(a => {
      return a.category == 'Capital Metrics' && a.show == true
    })
    let miscMetrics = this.props!.filter(a => {
      return a.category == 'Misc' && a.show == true
    })
    let netResults = this.props!.filter(a => {
      return a.category == 'Net Results' && a.show == true
    })
    let cedeCostMetrics = this.props!.filter(a => {
      return a.category == 'Ceded Cost Metrics'
    })

    if (tailMetrics && tailMetrics.length !== 0) {
      return tailMetrics[0].category + ' ' + tailMetrics[0].label
    } else if (volatilityMetrics && volatilityMetrics.length !== 0) {
      return volatilityMetrics[0].category + ' ' + volatilityMetrics[0].label
    } else if (capitalMetrics && capitalMetrics.length !== 0) {
      return capitalMetrics[0].category + ' ' + capitalMetrics[0].label
    } else if (miscMetrics && miscMetrics.length !== 0) {
      return miscMetrics[0].category + ' ' + miscMetrics[0].label
    } else if (netResults && netResults.length !== 0) {
      return netResults[0].category + ' ' + netResults[0].label
    } else if (cedeCostMetrics && cedeCostMetrics.length !== 0) {
      return cedeCostMetrics[0].category + ' ' + cedeCostMetrics[0].label
    }
    return ''
  }
}

/** `,`: thousands comma separator
 * `.3`: 3 significant digits
 * `~`:  trim trailing zeroes
 * `p`:  multiply by 100 and round to the specified sig. digits
 * `s`:  abbreviate w/ SI unit (e.g. `k` for 1000s, `M` for million, etc.)
 */
const formatPercentage = format(',.3~p')
// D3 uses G for billions for backwards compatibility
const formatNumber = (value: number) => format(',.3~s')(value).replace('G', 'B')

const colorClassFn = (d: CompareStructureOptionsDatum): string | undefined => {
  return d.description && `app-palette-blue`
}

const makeAnnotationBuilder =
  (
    formatters: Record<CompareStructureOptionsDimension, ValueFormatter>,
    xPropType: string | undefined,
    yPropType: string | undefined,
    xPropLabel: string | undefined,
    yPropLabel: string | undefined
  ) =>
  (d: CompareStructureOptionsDatum): D3SeriesAnnotation => ({
    note: {
      title: `${xPropLabel}: ${
        xPropType === 'currency' ? currencySymbol(d.currency || 'USD') : ''
      }${formatters.x(d.x)},\n${yPropLabel}: ${
        yPropType === 'currency' ? currencySymbol(d.currency || 'USD') : ''
      }${formatters.y(d.y)},`,
      label: ``,
      wrapSplitter: /\n/,
      bgPadding: 5,
    },
    x: d.x,
    y: d.y,
    dx: 20,
    dy: -20,
  })

type ValueFormatter = (n: number | Numeric) => string

const getFormatter = (prop: any) => {
  switch (prop.valueType) {
    case 'percentage':
    case 'ratio':
      return formatPercentage
  }
  return formatNumber
}
