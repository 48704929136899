import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import {
  CashFlowsGraphData,
  CreditGrossActual,
} from '../../model/credit-cash-flows.model'
import { CreditTransaction } from '../../model/credit-structure.model'
import { CreditResults } from '../../model/credit-results.model'
import {
  CreditStructureActions,
  CreditSubmissionStructureActions,
} from '../structure/credit-structure.actions'
import * as fromCashFlowsActions from './cash-flows.actions'
import * as fromCreditActions from '../credit.actions'

export interface State {
  currentCashFlowsScenario?: CreditGrossActual
  currentCashFlowsTransaction?: CreditTransaction
  currentCashFlowsData?: CashFlowsGraphData
  currentCashFlowsCreditResults?: CreditResults
}

export const initialState: State = {}

const currentCashFlowsScenarioReducer = createReducer(
  initialState.currentCashFlowsScenario,
  on(fromCashFlowsActions.SetCashFlowsScenario, (_, { scenario }) => scenario),
  on(
    fromCreditActions.SetModuleContext,
    CreditStructureActions.resetSelectedStructure,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    _ => undefined
  )
)

const currentCashFlowsTransactionReducer = createReducer(
  initialState.currentCashFlowsTransaction,
  on(
    fromCashFlowsActions.SetCashFlowsTransaction,
    (_, { transaction }) => transaction
  ),
  on(
    fromCreditActions.SetModuleContext,
    CreditStructureActions.resetSelectedStructure,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    _ => undefined
  )
)

const currentCashFlowsDataReducer = createReducer(
  initialState.currentCashFlowsData,
  on(fromCashFlowsActions.GetCashFlowsDataSuccess, (_, { data }) => data),
  on(
    fromCreditActions.SetModuleContext,
    CreditStructureActions.resetSelectedStructure,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    _ => undefined
  )
)

const currentCashFlowsLayerResultsReducer = createReducer(
  initialState.currentCashFlowsCreditResults,
  on(
    fromCashFlowsActions.GetCashFlowsLayerResultsSuccess,
    (_, { results }) => results
  ),
  on(
    fromCreditActions.SetModuleContext,
    CreditStructureActions.resetSelectedStructure,
    CreditSubmissionStructureActions.resetSelectedSubmission,
    _ => undefined
  )
)

const cashFlowsReducer = combineReducers<State>({
  currentCashFlowsScenario: currentCashFlowsScenarioReducer,
  currentCashFlowsTransaction: currentCashFlowsTransactionReducer,
  currentCashFlowsData: currentCashFlowsDataReducer,
  currentCashFlowsCreditResults: currentCashFlowsLayerResultsReducer,
})

export function reducer(state: State | undefined, action: Action) {
  return cashFlowsReducer(state, action)
}
