<button
  appCard
  details
  [size]="size"
  [description]="structure.description"
  [checked]="structure.checked"
  [selectMultiple]="selectMultiple"
  [canCheck]="canCheck"
  [imageSrc]="imageURI"
  [index]="index"
  (click)="structureClick.emit()"
  (handleCheck)="structureCheckChange.emit()"
>
  <span>{{ structure.label }}</span>
</button>

<button
  #moreMenuButton
  appButtonIcon
  gigantic
  class="popover-button"
  [matMenuTriggerFor]="moreMenu"
>
  more_horiz
</button>
<mat-menu
  class="app-menu app-menu-translucent"
  backdropClass="app-theme-dark"
  #moreMenu="matMenu"
  xPosition="before"
>
  <button *ngIf="canOptimize" mat-menu-item (click)="optimizeClick.emit()">
    <span>Optimize</span>
  </button>
  <button
    *ngIf="canCreateSubmission"
    mat-menu-item
    (click)="createSubmissionClick.emit()"
  >
    <span>Create Submission</span>
  </button>
</mat-menu>
