<ng-container>
  <div mat-dialog-content class="mat-dialog-wrapper">
    <div class="title">
      <h2>{{ header }}</h2>
    </div>
    <div class="subtitle">
      <h4>{{ subHeader }}</h4>
    </div>
    <div *ngIf="data.hasPriorData">
      <section class="section-padding">
        <span class="dialog-label"> Include written percentages: </span>
        <mat-radio-group [(ngModel)]="writtenPercentangeValue" (change)="writtenPercentageChange($event)">
          <mat-radio-button
            [value]="'Yes'"
            class="radio-option"
          >
            Yes
          </mat-radio-button>
          <mat-radio-button [value]="'No'" class="radio-btn">
            No
          </mat-radio-button>
        </mat-radio-group>
      </section>
      <section class="section-padding">
        <span class="dialog-label">Select export breakout type: </span>
        <mat-radio-group [(ngModel)]="exportTypeValue" (change)="exportTypeChange($event)">
          <mat-radio-button
            [value]="'Accounting'"
            class="radio-option"
          >
            Accounting
          </mat-radio-button>

          <mat-radio-button [value]="'Custom'" class="radio-btn">
            Custom
          </mat-radio-button>
        </mat-radio-group>
      </section>

      <section class="section-padding">
        <span class="dialog-label">Select fields to output in Reinsurer Selection: </span>
        <mat-form-field>
          <mat-select [(value)]="selectedColumnList" multiple (selectionChange)="onSelectionChange($event.value)">
            <mat-option *ngFor="let column of columnsList; let i = index" [value]="column">
              {{ column }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </section>

      <section class="section-padding" *ngIf="!showBreakoutList">
        <div class="custom-label">
          Select Accounting for an export that includes prior year percentages by
          section. Choose Custom to aggregate sections based on additional risk
          details. Please note that the Custom option will not expose details per
          section unless an applicable risk detail option is chosen which exposes
          unique details.
        </div>
      </section>

      <div *ngIf="showBreakoutList">
        <div class="dialog-label labelPadding">
          Add risk details for prior year percentages:
        </div>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.inceptionDate)"
          [checked]="isbreakoutValueChecked(options,options.inceptionDate)"
          (change)="updateBreakout($event, options.inceptionDate)"
          >{{ options.inceptionDate }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.expireDate)"
          [checked]="isbreakoutValueChecked(options,options.expireDate)"
          (change)="updateBreakout($event, options.expireDate)"
        >
          {{ options.expireDate }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.occLimit)"
          [checked]="isbreakoutValueChecked(options,options.occLimit)"
          (change)="updateBreakout($event, options.occLimit)"
        >
          {{ options.occLimit }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.currency)"
          [checked]="isbreakoutValueChecked(options,options.currency)"
          (change)="updateBreakout($event, options.currency)"
        >
          {{ options.currency }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.riskLimit)"
          [checked]="isbreakoutValueChecked(options,options.riskLimit)"
          (change)="updateBreakout($event, options.riskLimit)"
        >
          {{ options.riskLimit }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.aggLimit)"
          [checked]="isbreakoutValueChecked(options,options.aggLimit)"
          (change)="updateBreakout($event, options.aggLimit)"
        >
          {{ options.aggLimit }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.excess)"
          [checked]="isbreakoutValueChecked(options,options.excess)"
          (change)="updateBreakout($event, options.excess)"
        >
          {{ options.excess }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.aggAttachment)"
          [checked]="isbreakoutValueChecked(options,options.aggAttachment)"
          (change)="updateBreakout($event, options.aggAttachment)"
        >
          {{ options.aggAttachment }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.reinstatements)"
          [checked]="isbreakoutValueChecked(options,options.reinstatements)"
          (change)="updateBreakout($event, options.reinstatements)"
        >
          {{ options.reinstatements }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.deposit)"
          [checked]="isbreakoutValueChecked(options,options.deposit)"
          (change)="updateBreakout($event, options.deposit)"
        >
          {{ options.deposit }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.minimum)"
          [checked]="isbreakoutValueChecked(options,options.minimum)"
          (change)="updateBreakout($event, options.minimum)"
        >
          {{ options.minimum }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.adjustRate)"
          [checked]="isbreakoutValueChecked(options,options.adjustRate)"
          (change)="updateBreakout($event, options.adjustRate)"
        >
          {{ options.adjustRate }}
        </mat-checkbox>
        <mat-checkbox
          class="checkbox-section"
          [value]="getKeyByValue(options, options.notes)"
          [checked]="isbreakoutValueChecked(options,options.notes)"
          (change)="updateBreakout($event, options.notes)"
        >
          {{ options.notes }}
        </mat-checkbox>
      </div>
    </div>

    <div mat-dialog-actions align="end">
      <button appButton accent (click)="onExportClick()" cdkFocusInitial>
        Export
      </button>
      <button appButton accent mat-dialog-close="2" cdkFocusInitial>
        Close
      </button>
    </div>
  </div>
</ng-container>
