import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { Broker } from '../core/model/broker.model'
import { NavService } from '../nav.service'
import { BenchmarkMode } from '../benchmark/model/benchmark-mode'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { State as AuthState } from '../core/store/auth/auth.state.facade'
import { BenchmarkDisplayWarningSettings } from '../benchmark/model/benchmark.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-general-header',
  styles: [
    `
           :host {
             display: flex;
             justify-content: space-between;
             align-items: center;
             flex: 0 0 auto;
             box-sizing: border-box;
             padding: 12px 24px 12px 0;
             height: var(--header-height);
           }
     
           .menu-icon {
             background-color: var(--bg);
             border-color: var(--bg);
             margin: var(--inset);
           }
     
           a {
             flex: 0 0 auto;
           }
     
           a.logo {
             width: calc(
               var(--layer-panel-width) - var(--inset-big) - var(--inset-tiny)
             );
             margin-right: var(--inset-huge);
           }
     
           a.logo:hover {
             cursor: pointer;
           }
     
           .title {
             flex: 1 1 auto;
             overflow: hidden;
           }
     
           app-user-card {
             display: none;
             flex: 0 0 auto;
           }
     
           :host-context(.app-theme-user-card) app-user-card {
             display: flex;
             padding-right: var(--inset);
             padding-left: 100px;
           }
     
           @media screen and (max-width: 1180px) {
             :host {
               height: var(--header-height-xsmall);
               padding: 6px 12px;
             }
     
             .menu-icon {
               margin: 0 var(--inset);
             }
     
             a.logo {
               width: calc(
                 var(--layer-panel-width-small) - var(--inset-huge) -
                   var(--inset-huge)
               );
             }
     
             :host-context(.app-theme-user-card) app-user-card {
               display: none;
             }
           }
         `,
  ],
  template: `
    <button
      mat-icon-button
      class="menu-icon"
      id="menu"
      (click)="navService.open()"
    >
      <mat-icon>menu</mat-icon>
    </button>

    <a class="logo" appLogo noLink (click)="onLogoClick()"></a>

    <app-header-title
      class="title"
      noLink
      [name]="name"
      [authState]="authState"
      [big]="big"
      [breadcrumbs]="breadcrumbs"
      [subtitle]="headerSubtitle"
      [mode]="mode"
      [displayHeaderWarningSettings]="displayHeaderWarningSettings"
      (backClick)="onBackClick()"
      (breadcrumbClick)="onBreadcrumbClick($event)"
      (proveToggleChange)="onProveToggleChange($event)"
    ></app-header-title>

    <app-user-card
      [name]="
        broker?.authenticatingUser ? broker?.authenticatingUser : broker?.name
      "
      [avatarID]="broker?.avatarID"
    ></app-user-card>
  `,
})
export class GeneralHeaderComponent {
  @Input() mode: BenchmarkMode | null
  @Input() name: string
  @Input() baseName: string
  @Input() broker?: Broker
  @Input() subtitle: string
  @Input() authState: AuthState
  @Input() displayHeaderWarningSettings: BenchmarkDisplayWarningSettings

  @Input() set big(value: any) {
    this._big = coerceBooleanProperty(value)
  }
  get big() {
    return this._big
  }
  _big = false

  private _absoluteRoute = false
  @Input() set absoluteRoute(value: any) {
    this._absoluteRoute = coerceBooleanProperty(value)
  }
  get absoluteRoute() {
    return this._absoluteRoute
  }

  get breadcrumbs(): string[] {
    const bs = [this.name]
    if (this.mode) {
      if (this.mode.parentMode) {
        bs.push(this.mode.parentMode.name)
      }
      bs.push(this.mode.name)
    }
    return bs
  }

  constructor(public navService: NavService, private router: Router) {}

  onBreadcrumbClick(index: number) {
    const base = this.baseName
    const prefix = this.absoluteRoute ? '/' : './'
    const path = `${prefix}${base}`
    if (index === 0) {
      this.router.navigate([path])
    } else if (index === 1 && this.mode?.parentMode) {
      this.router.navigate([path, this.mode.parentMode.id])
    } else {
      this.router.navigate([path, this.mode?.id])
    }
    return false
  }

  onLogoClick() {
    this.router.navigate(['/'])
    return false
  }

  onBackClick() {
    this.router.navigate(['/home'])
    return false
  }

  onProveToggleChange(proveMode: 'prove' | 'heatmap') {
    if (proveMode === 'prove') {
      this.router.navigate(['/prove'])
    } else {
      this.router.navigate(['/prove/heatmap'])
    }
  }

  get headerSubtitle(): string {
    return this.subtitle ?? this.mode?.description ?? ''
  }
}
