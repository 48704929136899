import { NgModule } from '@angular/core'
import { PricingCurveRoutingModule } from './pricing-curve-routing.module'
import { PricingCurveContainerComponent } from './pricing-curve-container/pricing-curve.container'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { SharedModule } from '@shared/shared.module'
import { ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import * as fromPricingCurve from './store/pricing-curve.reducer'
import { PricingCurveEffects } from './store/pricing-curve.effects'
import { EffectsModule } from '@ngrx/effects'
import { PricingCurveComponent } from './pricing-curve-component/pricing-curve.component'
import { PricingCurveHeaderComponent } from './components/layout/pricing-curve-header/pricing-curve-header.component'
import { PricingCurveContentComponent } from './components/layout/pricing-curve-content/pricing-curve-content.component'
import { PricingCurveAddLayersComponent } from './components/layout/add-layers/pricing-curve-add-layers.component'
import { PricingCurveFilterBarComponent } from './components/layout/layer-filter-bar/pricing-curve-layer-filter-bar.component'
import { PricingCurveGraphComponent } from './components/charting/pricing-curve-graph/graph-component/pricing-curve-graph.component'
import { PricingCurveDetailsContainerComponent } from './components/layout/details-container/pricing-curve-details-container.component'
import { PricingCurveDetailsCardComponent } from './components/charting/pricing-curve-card/details-card/pricing-curve-details-card.component'
import { PricingCurveSettingsDialogComponent } from './components/layout/settings-dialog/pricing-curve-settings-dialog.component'
import { PricingCurveCardCurveTableComponent } from './components/charting/pricing-curve-card/card-curve-table/pricing-curve-card-curve-table.component'
import { PricingCurveCardLayerTableComponent } from './components/charting/pricing-curve-card/card-layer-table/pricing-curve-card-layer-table.component'
import { PricingCurveSaveAddDialogComponent } from './components/layout/save-add-dialog/pricing-curve-save-add-dialog.component'
import { PricingCurveAddDataDialogComponent } from './components/layout/add-data-dialog/pricing-curve-add-data-dialog.component'
import { PricingCurveTechnicalFactorFormComponent } from './components/charting/technical-factor-form/pricing-curve-technical-factor-form.component'
import { PricingCurveExportPreviewDialogComponent } from './components/layout/export-preview-dialog/pricing-curve-export-preview-dialog.component'
import { PricingCurveExportFormComponent } from './components/charting/export-form/pricing-curve-export-form.component'
import { PricingCurveExcludeLayerDialogComponent } from './components/layout/exclude-layer-dialog/pricing-curve-exclude-layer-dialog.component'
import { TierModule } from '../tier/tier.module'
import { PricingCurveDialogService } from './components/dialog/pricing-curve-dialog.service'
import { PricingCurveSidebarComponent } from './components/layout/sidebar/pricing-curve-sidebar.component'
import { AddDataDialogContainerComponent } from './components/layout/add-data-dialog-container/add-data-dialog.container'
import { PricingCurveCardCreditCalcComponent } from './components/charting/pricing-curve-card/card-credit-calc/pricing-curve-card-credit-calc.component'
import { PricingCurveGraphSettingsDialogComponent } from './components/layout/graph-settings-dialog/pricing-curve-graph-settings-dialog.component'

@NgModule({
  declarations: [
    PricingCurveContainerComponent,
    PricingCurveComponent,
    PricingCurveHeaderComponent,
    PricingCurveContentComponent,
    PricingCurveAddLayersComponent,
    PricingCurveFilterBarComponent,
    PricingCurveGraphComponent,
    PricingCurveDetailsContainerComponent,
    PricingCurveDetailsCardComponent,
    PricingCurveSettingsDialogComponent,
    PricingCurveCardCurveTableComponent,
    PricingCurveCardLayerTableComponent,
    PricingCurveSaveAddDialogComponent,
    PricingCurveAddDataDialogComponent,
    PricingCurveTechnicalFactorFormComponent,
    PricingCurveExportPreviewDialogComponent,
    PricingCurveExportFormComponent,
    PricingCurveExcludeLayerDialogComponent,
    PricingCurveSidebarComponent,
    AddDataDialogContainerComponent,
    PricingCurveCardCreditCalcComponent,
    PricingCurveGraphSettingsDialogComponent
  ],
  imports: [
    CommonModule,
    PricingCurveRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    TierModule,
    StoreModule.forFeature(
      fromPricingCurve.PRICING_CURVE_FEATURE_KEY,
      fromPricingCurve.reducer
    ),
    EffectsModule.forFeature([PricingCurveEffects]),
  ],
  exports: [PricingCurveComponent],
  providers: [PricingCurveDialogService],
})
export class PricingCurveModule {}
