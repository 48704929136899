import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox'
import {
  MatListOption,
  MatSelectionList,
  MatSelectionListChange,
} from '@angular/material/list'
import { mergeRight } from 'ramda'
import { BehaviorSubject, Subject, merge, Observable } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import { Layer } from 'src/app/analysis/model/layers.model'
import { AppState } from 'src/app/core/store'
import {
  MultiSectionService,
  Section,
  SectionLossSet,
} from 'src/app/analysis/properties/layer/multi-section/multi-section.service'
import { CurrencyCode } from 'src/app/api/analyzere/analyzere.model'
import * as fromAnalysisSelectors from 'src/app/analysis/store/analysis.selectors'
import { LossSetGroupEntity } from 'src/app/analysis/store/loss-set-layers/loss-set-group/loss-set-group.reducer'
import {
  LossSetGroup,
  LossSetLayer,
} from 'src/app/analysis/model/loss-set-layers.model'
import { analyzereConstants } from '@shared/constants/analyzere'

interface LossSetGroupEntityStylized extends LossSetGroupEntity {
  style: any
}

@Component({
  selector: 'app-multi-section-card',
  styleUrls: ['./multi-section-card.component.scss'],
  templateUrl: './multi-section-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSectionCardComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  private _section: Section
  @Input() set section(inputSection: Section) {
    this._section = {
      ...inputSection,
      lossSets: [...inputSection.lossSets],
      inuringSections: [...inputSection.inuringSections],
    }
  }

  get section(): Section {
    return this._section
  }

  readonly analyzereConstants = analyzereConstants

  @Input() sections: Section[]
  @Input() disableInuring: string[]
  @Input() currencyList: CurrencyCode[]
  @Input() filteredCurrencyList: CurrencyCode[]
  sectionCurrencyControl = new FormControl()
  @Output() currencyChangeLayer = new EventEmitter<Layer>()
  @Input() layers: Layer[]
  @Input() mainLayer: Layer
  @Input() isLibRE: boolean
  @Input() mainLayerUnlimited = false
  @Output() stateChange = new EventEmitter<boolean>()
  @Output() inuringSelection = new EventEmitter()
  @Output() sectionInurance = new EventEmitter<Section>()

  @HostBinding('class.invalid') invalid = false

  @ViewChild('lossSetsList') lossSetsList: MatSelectionList
  @ViewChild('lossSetsAllNone') lossSetsAllNone: MatCheckbox
  @ViewChild('inuringSectionsList') inuringSectionsList: MatSelectionList

  allLossSets: SectionLossSet[]
  otherSections: Section[]

  get inurableSections() {
    const inuringSections = this.section.inuringSections
    const disabledSections = this.disableInuring

    return this.otherSections.map(other => ({
      letter: other.letter,
      selected: inuringSections.includes(other.letter),
      disabled: disabledSections.includes(other.letter),
    }))
  }

  filteredLossSets$: BehaviorSubject<SectionLossSet[]>
  lossSetGroups$: Observable<LossSetGroupEntity[]>
  lossSetGroups: LossSetGroupEntityStylized[]
  selectedLossSetGroupID: string

  private destroy$ = new Subject<void>()
  private lastLossSetClick: [number, boolean] | null

  constructor(
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    @SkipSelf() private multiSectionService: MultiSectionService
  ) {}

  ngOnInit(): void {
    const occLimit =
      this.mainLayer.physicalLayer.meta_data.contractOccurrenceLimit
    if (occLimit) {
      if (occLimit >= analyzereConstants.unlimitedValue) {
        this.mainLayerUnlimited = true
      }
    }
    if (this.section.occLimit >= analyzereConstants.unlimitedValue) {
      this.section = { ...this.section, occLimit: 1, occUnlimited: true }
    }
    if (this.section.aggLimit >= analyzereConstants.unlimitedValue) {
      this.section = { ...this.section, aggLimit: 1, aggUnlimited: true }
    }

    this.disableInuring = []
    this.allLossSets = this.multiSectionService.getAllLossSets()
    this.filteredLossSets$ = new BehaviorSubject(this.allLossSets)
    this.sectionCurrencyControl.valueChanges.subscribe(searchVal => {
      this.filteredCurrencyList = this.filterCurrencyValues(searchVal)
    })

    this.lossSetGroups$ = this.store.pipe(
      select(fromAnalysisSelectors.selectLossSetGroupEntities)
    )
    this.lossSetGroups$.subscribe((lossGroup: LossSetGroupEntity[]) => {
      const stylized: LossSetGroupEntityStylized[] = []
      lossGroup.forEach((group: LossSetGroupEntity) => {
        stylized.push({
          ...group,
          style: this.getChipStyle(group.lossSetGroup.id),
        })
      })
      this.lossSetGroups = stylized
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.section || changes.sections) {
      this.otherSections = this.sections.filter(
        section => section.letter !== this.section.letter
      )
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngAfterViewInit(): void {
    this.updateLossSetsAllNone()
    this.checkValid()

    if (!this.isLibRE) {
      const options$ = this.lossSetsList.options.changes.pipe(
        takeUntil(this.destroy$)
      )
      const selectedOptions$ = this.lossSetsList.selectedOptions.changed.pipe(
        takeUntil(this.destroy$)
      )

      merge(options$, selectedOptions$)
        .pipe(debounceTime(200))
        .subscribe(() => this.updateLossSetsAllNone())

      selectedOptions$.subscribe(changed => {
        const removeFrom = (set: Set<string>, item: MatListOption) => {
          // We only want to respond to deselected items not items removed from the list
          if (
            this.lossSetsList.options.find(_item => _item.value === item.value)
          ) {
            set.delete(item.value)
          }
          return set
        }
        const lossSets = new Set(this.section.lossSets)
        changed.added.forEach(item => lossSets.add(item.value))
        changed.removed.reduce(removeFrom, lossSets)
        this.updateSectionWith({ lossSets: Array.from(lossSets) })
      })
    }

    if (this.section) {
      this.sectionCurrencyControl.setValue({ code: this.section.currency })
    }
    this.cdr.detectChanges()
  }

  propChange(name: keyof Section, value: string | number | boolean): void {
    if (name === 'occLimit') {
      if (
        (value as number) >= analyzereConstants.unlimitedValue &&
        this.mainLayerUnlimited
      ) {
        this.snackBar.open(
          'Cannot set a Occurrence Limit as unlimited if the main layer -> Contract Occurrence Limit is unlimited.',
          'X',
          { duration: 3000, panelClass: 'app-error' }
        )
      } else {
        this.section = {
          ...this.section,
          [name]: value as number,
          occUnlimited: false,
        }
      }
    } else if (name === 'aggLimit') {
      this.section = {
        ...this.section,
        [name]: value as number,
        aggUnlimited: false,
      }
    } else if (name === 'narrative') {
      this.section = {
        ...this.section,
        narrative: value as string,
      }
    } else {
      this.section = { ...this.section, [name]: value }
    }
  }

  onRemoveSection(): void {
    this.multiSectionService.removeSection(this.section)

    this.invalid = false
    this.stateChange.emit(this.invalid)
  }

  onFilter(filterExpr: string) {
    // Filter the loss sets using the current expression
    const filtered = this.allLossSets.filter(
      ls => ls.label.toLowerCase().indexOf(filterExpr) >= 0
    )

    // Set the newly filtered loss sets
    this.filteredLossSets$.next(filtered)
  }

  onLossSetsAllNone(change: MatCheckboxChange): void {
    if (change.checked) {
      this.lossSetsList.selectAll()
    } else {
      this.lossSetsList.deselectAll()
    }
  }

  updateLossSetsAllNone(): void {
    if (!this.isLibRE) {
      const selected = this.lossSetsList.selectedOptions.selected
      const options = this.lossSetsList.options

      this.lossSetsAllNone.checked = selected.length === options.length
    }
  }

  onLossSetClick(lossSet: SectionLossSet, event: MouseEvent): void {
    const options = this.lossSetsList.options.toArray()
    const index = options.findIndex(item => item.value === lossSet.id)

    // If the user was pressing shift, then select or deselect the range
    if (event.shiftKey && this.lastLossSetClick) {
      const [lastIndex, lastState] = this.lastLossSetClick

      // The range must start with the smaller index and end with the larger one, but it
      // doesn't need to include the start and end since the list items for the start and
      // end will already be toggled.
      const range =
        lastIndex < index
          ? options.slice(lastIndex + 1, index)
          : options.slice(index + 1, lastIndex)

      if (lastState) {
        this.lossSetsList.selectedOptions.select(...range)
      } else {
        this.lossSetsList.selectedOptions.deselect(...range)
      }
    } else {
      this.lastLossSetClick = [index, options[index].selected]
    }
  }

  onSectionInurance(): void {
    this.sectionInurance.emit(this.section)
  }

  onInuringSelection(_: MatSelectionListChange) {
    const selected = this.inuringSectionsList.selectedOptions.selected

    this.updateSectionWith({
      inuringSections: selected.map(item => item.value),
    })

    this.inuringSelection.emit()
  }

  onInuringDisable(disableInuring: string[]): void {
    this.disableInuring = disableInuring
    this.cdr.markForCheck()
  }

  updateSectionWith(values: Partial<Section>): void {
    this.section = mergeRight(this.section, values)
    this.checkValid()
  }

  checkValid() {
    const selectedCount =
      this.section.lossSets.length + this.section.inuringSections.length

    const oldState = this.invalid
    this.invalid = selectedCount === 0

    if (this.invalid !== oldState) {
      this.stateChange.emit(this.invalid)
    }
  }

  trackLossSetBy(_: number, item: SectionLossSet): string {
    return item.id
  }

  trackInuringBy(
    _: number,
    item: {
      letter: string
      selected: boolean
      disabled: boolean
    }
  ) {
    return `${item.letter}-${item.disabled}`
  }

  filterCurrencyValues(searchVal: CurrencyCode): CurrencyCode[] {
    if (searchVal.code) {
      return this.currencyList.filter(
        value =>
          value.code.toLowerCase().indexOf(searchVal.code.toLowerCase()) === 0
      )
    } else {
      const searchValStr = String(searchVal).toLowerCase()
      return this.currencyList.filter(
        value => value.code.toLowerCase().indexOf(searchValStr) === 0
      )
    }
  }

  displayFn(currency: CurrencyCode): string | undefined {
    return currency ? currency.code : undefined
  }

  onCurrencyChange(val: CurrencyCode): void {
    this.section = { ...this.section }
    this.section.currency = val.code
    this.sections.forEach(x => {
      if (x.letter === this.section.letter) {
        x.currency = this.section.currency
      }
    })
    this.layers.forEach(layer => {
      if (layer.meta_data.sage_layer_subtype === 'section-layer') {
        if (
          layer.physicalLayer.description ===
          'Section ' + this.section.letter
        ) {
          const currentLayer = JSON.parse(JSON.stringify(layer))
          currentLayer.physicalLayer.aggregateAttachment.currency = val.code
          currentLayer.physicalLayer.aggregateLimit.currency = val.code
          currentLayer.physicalLayer.attachment.currency = val.code
          currentLayer.physicalLayer.franchise.currency = val.code
          currentLayer.physicalLayer.limit.currency = val.code
          currentLayer.physicalLayer.premium.currency = val.code
          if (currentLayer.physicalLayer.riskLimit) {
            currentLayer.physicalLayer.riskLimit.currency = val.code
          }
          if (currentLayer.physicalLayer.riskAttachment) {
            currentLayer.physicalLayer.riskAttachment.currency = val.code
          }
          if (currentLayer.physicalLayer.event_limit) {
            currentLayer.physicalLayer.event_limit.currency = val.code
          }
          this.currencyChangeLayer.emit(currentLayer)
        }
      }
    })
  }

  getChipStyle(id: string) {
    const defaultStyle = {
      margin: '2px',
      'min-height': 'fit-content',
      'min-width': 'fit-content',
      height: '25px',
    }
    if (id === this.selectedLossSetGroupID) {
      return { ...defaultStyle, 'background-color': 'var(--accent)' }
    }
    return { ...defaultStyle, 'background-color': 'var(--bg-2)' }
  }

  selectChip(group: LossSetGroup): void {
    this.selectedLossSetGroupID =
      this.selectedLossSetGroupID !== group.id ? group.id : ''
    if (this.selectedLossSetGroupID !== '') {
      this.lossSetsList.options.forEach((item: MatListOption) => {
        const expectedState: boolean =
          group.lossSetLayers.find(
            (layer: LossSetLayer) => item.value === layer.id
          ) !== undefined
        if (item.selected !== expectedState) {
          item.toggle()
        }
      })
    } else {
      this.lossSetsList.deselectAll()
    }
    this.lossSetGroups.forEach((lossSet: LossSetGroupEntityStylized) => {
      lossSet.style = this.getChipStyle(lossSet.lossSetGroup.id)
    })
  }
}
