import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { LossSetGroup, LossSetLayer } from '../../model/loss-set-layers.model'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { LoadedLossSet, Metadata } from 'src/app/api/analyzere/analyzere.model'
import { MappingLabels } from 'src/app/core/model/study.model'
import { EXPLORE_SUMMARY_HEADERS, ExploreFilterMap, ExploreSummaryHeader, MappingOption } from '../explore.model'
import { buildSummaryFilterMap } from '../explore.util'
import { isLoadedLossSet } from '../../model/layers.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-gross-sidebar',
  templateUrl: './explore-gross-sidebar.component.html',
  styleUrls: ['./explore-gross-sidebar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 1 })),
      transition(':enter', [
        style({ height: '0px', opacity: 0 }),
        animate('200ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('200ms ease-in', style({ height: '0px', opacity: 0 }))
      ])
    ])
  ]
})
export class ExploreGrossSidebarComponent implements OnChanges, OnInit {
  visibilityMap: ExploreFilterMap = {
    groups: true,
    layers: true,
    l1: true,
    l2: false,
    l3: false,
    l4: false,
    l5: false,
    metaOptions: false,
    modelingOptions: false,
    columns: false
  }
  summaryColumnFilterMap: ExploreFilterMap = {}
  summaryFilterMap: ExploreFilterMap = {}
  headers: ExploreSummaryHeader[]
  groupByView: boolean
  displaySummaryFilterOptions: MappingOption[]
  @Input() summaryView: boolean
  @Input() modelingArray: string[]
  @Input() showIndividualLossSets: boolean
  @Input() selectedLossSetIDs: string[]
  @Input() allLossSetsSelected: boolean
  @Input() lossSetGroups: LossSetGroup[]
  @Input() lossSetLayers: LossSetLayer[]
  @Input() mappingLabels: MappingLabels
  @Input() summaryFilterOptions: MappingOption[]
  @Output() onLossSetChange = new EventEmitter()
  @Output() onSelectAllLossSets = new EventEmitter()
  @Output() onLossSetClick = new EventEmitter<{lossID: string, $event: MouseEvent}>()
  @Output() updateSummaryFilterMap = new EventEmitter<ExploreFilterMap>
  @Output() updateSummaryColumnFilterMap = new EventEmitter<ExploreFilterMap>
  @Output() updateShowIndividualLossSets = new EventEmitter<boolean>()
  @Output() onUpdateModelingArray = new EventEmitter<string[]>

  ngOnInit(): void {
    this.headers = EXPLORE_SUMMARY_HEADERS.filter(h =>
      h.id !== 'groupBy' &&
      h.id !== 'name'
    )
    this.headers.forEach(header => {
      const key = header.id
      if (!(key in this.summaryColumnFilterMap)) {
        if (
          key === 'totalContributionToGroupVolatility' ||
          key === 'contributionToGroupVolatility'
        ) {
          this.summaryColumnFilterMap[key] = false
        } else {
          this.summaryColumnFilterMap[key] = true
        }
      }
    })
    this.updateSummaryColumnFilterMap.emit(this.summaryColumnFilterMap)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.constructFilterOptions()
    if (changes.lossSetLayers && this.lossSetLayers) {
      this.summaryFilterMap = buildSummaryFilterMap(this.lossSetLayers, this.summaryFilterOptions)
      this.displaySummaryFilterOptions = this.filterSummaryFilterOptions()
      this.updateSummaryFilterMap.emit(this.summaryFilterMap)
    }
    if (changes.modelingArray) {
      this.groupByView = this.modelingArray.filter(x => x !== '').length > 0
      if (this.groupByView) {
        this.summaryColumnFilterMap['totalContributionToGroupVolatility'] = true
        this.summaryColumnFilterMap['contributionToGroupVolatility'] = true
      } else {
        this.summaryColumnFilterMap['totalContributionToGroupVolatility'] = false
        this.summaryColumnFilterMap['contributionToGroupVolatility'] = false
      }
      this.updateSummaryColumnFilterMap.emit(this.summaryColumnFilterMap)
    }
  }

  filterSummaryFilterOptions(): MappingOption[] {
    return this.summaryFilterOptions.filter(o => this.getSubOptionsByValueType(o.value).length > 0)
  }

  lossSetClick(lossID: string, $event: MouseEvent): void {
    this.onLossSetClick.emit({ lossID, $event })
    this.constructFilterOptions()
  }

  checkSelectedLossSet(id: string): boolean {
    return this.selectedLossSetIDs.includes(id)
  }

  updateModelingArray(value: string, index: number): void {
    if (this.modelingArray[index] === value) {
      this.modelingArray = this.modelingArray.map((item, i) => i >= index ? '' : item)
    } else {
      this.modelingArray[index] = value
    }
    this.onUpdateModelingArray.emit(this.modelingArray)
  }


  checkSelectedLevels(value: string, level: number): boolean {
    return this.modelingArray[level] === value
  }

  checkDisabledLevels(value: string, level: number): boolean {
    const models = [...this.modelingArray]
    models.splice(level, 1)
    return models.includes(value)
  }

  constructFilterOptions(): void {
    this.summaryFilterOptions.forEach(option => {
      if (!this.visibilityMap[option.value]) {
        this.visibilityMap[option.value] = false
      }
    })
  }

  getSubOptionsByValueType(value: string): string[] {
    const subOptions: string[] = []
    const optionValue = value as keyof Metadata
    const layers = this.lossSetLayers.map(layer => {
      const lossSetLoaded = isLoadedLossSet(layer.loss_sets[0])
      const loadedLossSet = layer.loss_sets[0] as LoadedLossSet
      const lossScaleFactor = lossSetLoaded ? loadedLossSet.load : 1
      const originalPremium = layer.meta_data.originalPremium || layer.meta_data.originalPremium === 0
        ? layer.meta_data.originalPremium
        : layer.premium.value
      const premiumScaleFactor = originalPremium === 0
        ? 0
        : layer.meta_data.originalPremium
          ? layer.premium.value / originalPremium
          : 1
      return {
        ...layer,
        meta_data: {
          ...layer.meta_data,
          lossScaleFactor,
          premiumScaleFactor
        }
      }
    })
    layers.forEach(layer => {
      if (!!layer.meta_data[optionValue]) {
        const option = String(layer.meta_data[optionValue])
        if (!subOptions.includes(option)) {
          subOptions.push(option)
          const visibilityKey = value
          if (!(visibilityKey in this.visibilityMap)) {
            this.visibilityMap[visibilityKey] = false
          }
        }
      }
    })
    return subOptions
  }

  toggleVisibility(section: string): void {
    this.visibilityMap[section] = !this.visibilityMap[section]
  }

  isSectionVisible(section: string): boolean {
    return this.visibilityMap[section]
  }

  showGroupByLevel(index: number): boolean {
    return this.modelingArray[index] !== ''
  }

  checkSummaryFilterSelected(value: string): boolean {
    return this.summaryFilterMap[value]
  }

  checkSummaryColumnFilter(value: string): boolean {
    return this.summaryColumnFilterMap[value]
  }

  toggleSummaryFilter(value: string): void {
    this.summaryFilterMap[value] = !this.summaryFilterMap[value]
    this.updateSummaryFilterMap.emit(this.summaryFilterMap)
  }

  toggleSummaryColumnFilter(value: string): void {
    this.summaryColumnFilterMap[value] = !this.summaryColumnFilterMap[value]
    this.updateSummaryColumnFilterMap.emit(this.summaryColumnFilterMap)
  }

  toggleIndividualLossSets(checked: boolean): void {
    this.showIndividualLossSets = checked
    this.updateShowIndividualLossSets.emit(this.showIndividualLossSets)
  }
}

