<button
  appCard
  details
  [size]="size"
  [description]="structure.description"
  [checked]="structure.checked"
  [selectMultiple]="selectMultiple"
  [canCheck]="canCheck"
  [imageSrc]="imageURI"
  [index]="index"
  (click)="structureClick.emit()"
  (handleCheck)="structureCheckChange.emit()"
>
  <span>{{ structure.label }}</span>
</button>

<button
  *ngIf="canOptimize"
  #moreMenuButton
  appButtonIcon
  gigantic
  class="popover-button"
  [matMenuTriggerFor]="moreMenu"
>
  more_horiz
</button>
<mat-menu
  class="app-menu app-menu-translucent"
  backdropClass="app-theme-dark"
  #moreMenu="matMenu"
  xPosition="before"
>
  <button mat-menu-item (click)="optimizeClick.emit()">Optimize</button>
</mat-menu>
