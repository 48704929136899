import { Injectable } from '@angular/core'
import { ValueType, Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { DatePipe } from '@angular/common'
import { Program } from '../../../core/model/program.model'
import { LossSetTableState } from '../store/explore.reducer'
import { MetricValueType } from 'src/app/core/model/metric-value-type.model'
import { currencySymbol } from '../../model/layers.util'

@Injectable({
  providedIn: 'root',
})
export class GrossLossTableExportXlsxService {
  NUMERIC_TYPES: any

  constructor(private datePipe: DatePipe) {}

  exportXlsx(
    fileName: string,
    clientName: string,
    structure: Program | string | undefined,
    program: string,
    lossSetData: LossSetTableState[],
    aggregationMethod: string,
    vartvar: string,
    prespective: string,
    effectiveDate: string,
    occurrenceRows: any,
    aggregateRows: any,
    currency: string | undefined,
    excelLossRatioRows: any,
    grossOccurrenceResultsOtherValues: any,
    grossAggregateResultsOtherValues: any,
    grossRatiosOtherValues: any,
    view: string
  ) {
    // ****** create spreadsheet
    const blue = '00AEEF'
    const darkGray = '737F8B'
    const workbook = new Workbook()
    const acronymsLabelsMap = new Map()
    acronymsLabelsMap.set('OEP', 'Occurrence Exceedance Probability')
    acronymsLabelsMap.set('AEP', 'Aggregate Exceedance Probability')
    acronymsLabelsMap.set('TVaR', 'Tail Value at Risk')
    acronymsLabelsMap.set('VaR', 'Value at Risk')
    acronymsLabelsMap.set('RP', 'Reinstatement Premium')
    acronymsLabelsMap.set('UW', 'Underwriting Result')
    acronymsLabelsMap.set('Loss', 'Loss')
    acronymsLabelsMap.set('LossRp', 'Loss + Reinstatement Premium')

    workbook.creator = 'Lockton'
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    const isOccAgg = view === 'Occ/Agg'

    const worksheet = workbook.addWorksheet('Explore Gross Data', {
      pageSetup: { fitToPage: true },
      views: [{ showGridLines: false }],
    })

    worksheet.pageSetup.margins = {
      left: 0.2,
      right: 0.2,
      top: 0.5,
      bottom: 0.5,
      header: 0.3,
      footer: 0.3,
    }

    // get the lossFilterValues for all the selected sets and groups
    const lossFilterValues = []
    for (const dataTableValue of lossSetData) {
      lossFilterValues.push(dataTableValue.filterValue)
    }
    const subjectPremiumValues = []
    for (const dataTableValue of lossSetData) {
      subjectPremiumValues.push(dataTableValue.subjectPremiumAmt)
    }

    let currentCurrencySymbol
    if (currency) {
      currentCurrencySymbol = currencySymbol(currency)
    } else {
      currentCurrencySymbol = '$'
    }

    // ****** create first eight rows of client, project and header information
    const exploreGrossRowOneClientName = worksheet.addRow([clientName])
    exploreGrossRowOneClientName.font = {
      name: 'Segoe Pro',
      size: 16,
      color: { argb: blue }
    }

    const programStructure = program + ' : ' + structure
    const exploreGrossTwoProgramStructure = worksheet.addRow([programStructure])
    exploreGrossTwoProgramStructure.font = {
      name: 'Segoe Pro',
      size: 10,
    }

    const effective = 'Effective: ' + effectiveDate
    const exploreGrossThreeEffectiveDate = worksheet.addRow([effective])
    exploreGrossThreeEffectiveDate.font = {
      name: 'Segoe Pro',
      size: 10,
    }

    if (isOccAgg) {
      const exploreGrossFourLayerResults = worksheet.addRow(['Gross Results'])
      exploreGrossFourLayerResults.font = {
        name: 'Segoe Pro',
        size: 16,
        color: { argb: blue }
      }

      const date = 'As of ' + this.datePipe.transform(new Date(), 'longDate')
      const dateRow = worksheet.addRow([date])
      dateRow.eachCell(c => {
        c.font = {
          name: 'Segoe Pro',
          size: 10,
        }
      })

      const grosslossDistributionData =
        'Gross Loss Distribution : ' +
        acronymsLabelsMap.get(vartvar) +
        '/ ' +
        acronymsLabelsMap.get(prespective)

      worksheet.addRow([grosslossDistributionData])

      const exploreGrossOccurrenceResults = worksheet.addRow(['Occurrence'])
      exploreGrossOccurrenceResults.font = {
        name: 'Segoe Pro',
        size: 16,
      }

      // Update the loss Filter values on top of the loss set / groups
      const lossFilterOccurrenceRow = 8
      let lossFilterColumn = 3
      const occFilterRow = worksheet.getRow(lossFilterOccurrenceRow)
      occFilterRow.eachCell((cell, i) => {
        if (i >= 3) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: darkGray },
          }
        }
      })
      for (const lossFilterValue of lossFilterValues) {
        // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
        const row = worksheet.getRow(lossFilterOccurrenceRow)
        const cell = row.getCell(lossFilterColumn)

        // start applying basic, default xlsx properties to this cell
        cell.model = {
          ...cell.model,
          type: ValueType.Number,
        }

        cell.style = {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: darkGray },
          },
          font: {
            name: 'Segoe Pro',
            size: 10,
            color: { argb: 'FFFFFF' }
          },
        }
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right',
          wrapText: true,
        }
        cell.value = 'Loss Filter = ' + lossFilterValue
        lossFilterColumn++
      }

      //////
      // get visible column titles
      const layerColumnTitles: string[] = []

      layerColumnTitles.push('Percentile')
      layerColumnTitles.push('Return Period')
      for (const key in lossSetData) {
        // @ts-ignore
        if (lossSetData[key].lossName) {
          const label = lossSetData[key].lossName
          layerColumnTitles.push(label)
        }
      }
      const layerRowTableHeader = worksheet.addRow(layerColumnTitles)
      layerRowTableHeader.eachCell(cell => {
        cell.font = {
          name: 'Segoe Pro',
          size: 10,
          bold: true,
          color: { argb: "FFFFFF" },
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: blue },
        }
        cell.border = {
          bottom: { style: 'thin', color: { argb: blue } },
        }
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right',
          wrapText: true,
        }
      })
      layerRowTableHeader.getCell(1).alignment = {
        vertical: 'middle',
        horizontal: 'left',
        wrapText: false,
      }

      // ********************************************************************************
      // *************     nested looping to create table rows here     *****************
      let currentOccurrenceRow = 14
      let currentColumn = 1
      let valueType: any
      let transformedValue
      for (const excelRow of occurrenceRows) {
        const keys = Object.keys(excelRow)
        // apply some formatting to value to set as cell's value at the end of the loop
        for (const key in keys) {
          if (key) {
            // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
            const row = worksheet.getRow(currentOccurrenceRow)
            const cell = row.getCell(currentColumn)

            // start applying basic, default xlsx properties to this cell
            cell.model = {
              ...cell.model,
              type: ValueType.Number,
            }

            cell.style = {
              font: {
                name: 'Segoe Pro',
                size: 10,
              },
            }
            if (keys[key] === 'Percentile') {
              valueType = 'percentage'
              transformedValue = this.transformValue(
                excelRow[keys[key]],
                valueType
              )
              cell.alignment = { horizontal: 'center' }
              cell.numFmt = '###,##0.0"%"'
            } else if (keys[key].toString().includes('Lossvalue')) {
              if (excelRow[keys[key]] === 0 || excelRow[keys[key]] === '0') {
                valueType = 'currency'
                cell.model.type = ValueType.Number
                cell.numFmt = `${currentCurrencySymbol}0`
                transformedValue = 0
                cell.alignment = { horizontal: 'right' }
              } else {
                valueType = 'currency'
                transformedValue = this.transformValue(
                  excelRow[keys[key]],
                  valueType
                )

                cell.numFmt = this.numberFormat(
                  Math.abs(Number(transformedValue)),
                  currentCurrencySymbol
                )
                cell.alignment = { horizontal: 'right' }
              }
            } else {
              valueType = 'numeric'
              transformedValue = this.transformValue(
                excelRow[keys[key]],
                valueType
              )
              cell.alignment = { horizontal: 'right' }
            }
            cell.value = transformedValue
            currentColumn++
          }
        }
        currentColumn = 1
        currentOccurrenceRow++
      }
      let grossOccurrenceResultsOtherValuesRow = 24
      let grossOccurrenceResultsOtherValuesColumn = 2
      for (const grossResultsOtherValue of grossOccurrenceResultsOtherValues) {
        const keys = Object.keys(grossResultsOtherValue)
        // apply some formatting to value to set as cell's value at the end of the loop
        for (const key in keys) {
          if (key) {
            // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
            const row = worksheet.getRow(grossOccurrenceResultsOtherValuesRow)
            const cell = row.getCell(grossOccurrenceResultsOtherValuesColumn)
            // start applying basic, default xlsx properties to this cell
            cell.model = {
              ...cell.model,
              type: ValueType.Number,
            }

            cell.style = {
              font: {
                name: 'Segoe Pro',
                size: 10,
              },
            }
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right',
              wrapText: true,
            }

            if (keys[key] === 'label') {
              cell.model.type = ValueType.String
              cell.alignment = { horizontal: 'right' }
              transformedValue = grossResultsOtherValue[keys[key]]
            } else if (keys[key].includes('cvValue')) {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                cell.model.type = ValueType.Number
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              } else {
                cell.model.type = ValueType.Number
                cell.numFmt = `0.0`
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              }
            } else {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                valueType = 'currency'
                cell.model.type = ValueType.Number
                cell.numFmt = `${currentCurrencySymbol}0`
                transformedValue = 0
                cell.alignment = { horizontal: 'right' }
              } else {
                valueType = 'currency'
                transformedValue = this.transformValue(
                  grossResultsOtherValue[keys[key]],
                  valueType
                )
                cell.numFmt = this.numberFormat(
                  Math.abs(Number(transformedValue)),
                  currentCurrencySymbol
                )
                cell.alignment = { horizontal: 'right' }
              }
            }
            if (keys[key] === 'label') {
              cell.model.type = ValueType.String
              cell.alignment = { horizontal: 'right' }
              transformedValue = grossResultsOtherValue[keys[key]]
            } else if (keys[key].includes('cvValue')) {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                cell.model.type = ValueType.Number
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              } else {
                cell.model.type = ValueType.Number
                cell.numFmt = `0.0`
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              }
            } else {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                valueType = 'currency'
                cell.model.type = ValueType.Number
                cell.numFmt = `${currentCurrencySymbol}0`
                transformedValue = 0
                cell.alignment = { horizontal: 'right' }
              } else {
                valueType = 'currency'
                transformedValue = this.transformValue(
                  grossResultsOtherValue[keys[key]],
                  valueType
                )
                cell.numFmt = this.numberFormat(
                  Math.abs(Number(transformedValue)),
                  currentCurrencySymbol
                )
                cell.alignment = { horizontal: 'right' }
              }
            }
            cell.value = transformedValue
            grossOccurrenceResultsOtherValuesColumn++
          }
        }
        grossOccurrenceResultsOtherValuesColumn = 2
        grossOccurrenceResultsOtherValuesRow++
      }

      worksheet.addRow(['']) // Blank Row
      worksheet.addRow(['']) // Blank Row

      const exploreGrossAggregateResults = worksheet.addRow(['Aggregate'])
      exploreGrossAggregateResults.font = {
        name: 'Segoe Pro',
        size: 16,
      }

      worksheet.addRow(['']) // Blank Row
      const lossFilterAggregateRow = 30
      let lossFilterAggregateColumn = 3
      const aggFilterRow = worksheet.getRow(lossFilterAggregateRow)
      aggFilterRow.eachCell((cell, i) => {
        if (i >= 3) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: darkGray },
          }
        }
      })
      for (const lossFilterValue of lossFilterValues) {
        // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
        const row = worksheet.getRow(lossFilterAggregateRow)
        const cell = row.getCell(lossFilterAggregateColumn)
        // start applying basic, default xlsx properties to this cell
        cell.model = {
          ...cell.model,
          type: ValueType.Number,
        }

        cell.style = {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: darkGray },
          },
          font: {
            name: 'Segoe Pro',
            size: 10,
            color: { argb: 'FFFFFF' }
          },
        }
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right',
          wrapText: true,
        }
        cell.value = 'Loss Filter = ' + lossFilterValue
        lossFilterAggregateColumn++
      }

      const aggHeaderRow = worksheet.addRow(layerColumnTitles)
      aggHeaderRow.eachCell(cell => {
        cell.font = {
          name: 'Segoe Pro',
          size: 10,
          bold: true,
          color: { argb: "FFFFFF" },
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: blue },
        }
        cell.border = {
          bottom: { style: 'thin', color: { argb: blue } },
        }
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right',
          wrapText: true,
        }
      })
      aggHeaderRow.getCell(1).alignment = {
        vertical: 'middle',
        horizontal: 'left',
        wrapText: false,
      }

      // ********************************************************************************
      // *************     nested looping to create table rows here     *****************
      let currentAggregateRow = 33
      for (const excelRow of aggregateRows) {
        const keys = Object.keys(excelRow)
        // apply some formatting to value to set as cell's value at the end of the loop
        for (const key in keys) {
          if (key) {
            // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
            const row = worksheet.getRow(currentAggregateRow)
            const cell = row.getCell(currentColumn)

            // start applying basic, default xlsx properties to this cell
            cell.model = {
              ...cell.model,
              type: ValueType.Number,
            }

            cell.style = {
              font: {
                name: 'Segoe Pro',
                size: 10,
              },
            }
            if (keys[key] === 'Percentile') {
              valueType = 'percentage'
              transformedValue = this.transformValue(
                excelRow[keys[key]],
                valueType
              )
              cell.alignment = { horizontal: 'center' }
              cell.numFmt = '###,##0.0"%"'
            } else if (keys[key].toString().includes('Lossvalue')) {
              if (excelRow[keys[key]] === 0 || excelRow[keys[key]] === '0') {
                valueType = 'currency'
                cell.model.type = ValueType.Number
                cell.numFmt = `${currentCurrencySymbol}0`
                transformedValue = 0
                cell.alignment = { horizontal: 'right' }
              } else {
                valueType = 'currency'
                transformedValue = this.transformValue(
                  excelRow[keys[key]],
                  valueType
                )

                cell.numFmt = this.numberFormat(
                  Math.abs(Number(transformedValue)),
                  currentCurrencySymbol
                )
                cell.alignment = { horizontal: 'right' }
              }
            } else {
              valueType = 'numeric'
              transformedValue = this.transformValue(
                excelRow[keys[key]],
                valueType
              )
              cell.alignment = { horizontal: 'right' }
            }
            cell.value = transformedValue
            currentColumn++
          }
        }
        currentColumn = 1
        currentAggregateRow++
      }

      worksheet.addRow(['']) // Blank Row

      // Update the grossResults mean/ std dev and cv values on the excel file
      let grossAggregateResultsOtherValuesRow = 43
      let grossAggregateResultsOtherValuesColumn = 2
      for (const grossResultsOtherValue of grossAggregateResultsOtherValues) {
        const keys = Object.keys(grossResultsOtherValue)
        // apply some formatting to value to set as cell's value at the end of the loop
        for (const key in keys) {
          if (key) {
            // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
            const row = worksheet.getRow(grossAggregateResultsOtherValuesRow)
            const cell = row.getCell(grossAggregateResultsOtherValuesColumn)
            // start applying basic, default xlsx properties to this cell
            cell.model = {
              ...cell.model,
              type: ValueType.Number,
            }

            cell.style = {
              font: {
                name: 'Segoe Pro',
                size: 10,
              },
            }
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right',
              wrapText: true,
            }

            if (keys[key] === 'label') {
              cell.model.type = ValueType.String
              cell.alignment = { horizontal: 'right' }
              transformedValue = grossResultsOtherValue[keys[key]]
            } else if (keys[key].includes('cvValue')) {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                cell.model.type = ValueType.Number
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              } else {
                cell.model.type = ValueType.Number
                cell.numFmt = `0.0`
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              }
            } else {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                valueType = 'currency'
                cell.model.type = ValueType.Number
                cell.numFmt = `${currentCurrencySymbol}0`
                transformedValue = 0
                cell.alignment = { horizontal: 'right' }
              } else {
                valueType = 'currency'
                transformedValue = this.transformValue(
                  grossResultsOtherValue[keys[key]],
                  valueType
                )
                cell.numFmt = this.numberFormat(
                  Math.abs(Number(transformedValue)),
                  currentCurrencySymbol
                )
                cell.alignment = { horizontal: 'right' }
              }
            }
            if (keys[key] === 'label') {
              cell.model.type = ValueType.String
              cell.alignment = { horizontal: 'right' }
              transformedValue = grossResultsOtherValue[keys[key]]
            } else if (keys[key].includes('cvValue')) {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                cell.model.type = ValueType.Number
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              } else {
                cell.model.type = ValueType.Number
                cell.numFmt = `0.0`
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              }
            } else {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                valueType = 'currency'
                cell.model.type = ValueType.Number
                cell.numFmt = `${currentCurrencySymbol}0`
                transformedValue = 0
                cell.alignment = { horizontal: 'right' }
              } else {
                valueType = 'currency'
                transformedValue = this.transformValue(
                  grossResultsOtherValue[keys[key]],
                  valueType
                )
                cell.numFmt = this.numberFormat(
                  Math.abs(Number(transformedValue)),
                  currentCurrencySymbol
                )
                cell.alignment = { horizontal: 'right' }
              }
            }
            cell.value = transformedValue
            grossAggregateResultsOtherValuesColumn++
          }
        }
        grossAggregateResultsOtherValuesColumn = 2
        grossAggregateResultsOtherValuesRow++
      }
    } else {
      const excelRows =
        aggregationMethod === 'OEP' ? occurrenceRows : aggregateRows
      const grossResultsOtherValues =
        aggregationMethod === 'OEP'
          ? grossOccurrenceResultsOtherValues
          : grossAggregateResultsOtherValues
      const exploreGrossFourLayerResults = worksheet.addRow(['Gross Results'])
      exploreGrossFourLayerResults.font = {
        name: 'Segoe Pro',
        size: 16,
      }

      const date = 'As of ' + this.datePipe.transform(new Date(), 'longDate')
      const dateRow = worksheet.addRow([date])
      dateRow.eachCell(c => {
        c.font = {
          name: 'Segoe Pro',
          size: 10,
        }
      })

      worksheet.addRow(['']) // Blank Row

      const grosslossDistributionData =
        'Gross Loss Distribution : ' +
        acronymsLabelsMap.get(aggregationMethod) +
        '/ ' +
        acronymsLabelsMap.get(vartvar) +
        '/ ' +
        acronymsLabelsMap.get(prespective)

      worksheet.addRow([grosslossDistributionData])
      worksheet.addRow(['']) // Blank Row
      worksheet.addRow(['']) // Blank Row

      // Update the loss Filter values on top of the loss set / groups
      const lossFilterRowNumber = 10
      let lossFilterColumn = 3
      for (const lossFilterValue of lossFilterValues) {
        // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
        const row = worksheet.getRow(lossFilterRowNumber)
        const cell = row.getCell(lossFilterColumn)
        // start applying basic, default xlsx properties to this cell
        cell.model = {
          ...cell.model,
          type: ValueType.Number,
        }

        cell.style = {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: darkGray },
          },
          font: {
            name: 'Segoe Pro',
            size: 10,
            color: { argb: 'FFFFFF' }
          },
        }
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right',
          wrapText: true,
        }
        cell.value = 'Loss Filter = ' + lossFilterValue
        lossFilterColumn++
      }

      //////
      // get visible column titles
      const layerColumnTitles: string[] = []

      layerColumnTitles.push('Percentile')
      layerColumnTitles.push('Return Period')
      for (const key in lossSetData) {
        // @ts-ignore
        if (lossSetData[key].lossName) {
          const label = lossSetData[key].lossName
          layerColumnTitles.push(label)
        }
      }
      const layerRowTableHeader = worksheet.addRow(layerColumnTitles)
      layerRowTableHeader.eachCell(cell => {
        cell.font = {
          name: 'Segoe Pro',
          size: 10,
          bold: true,
          color: { argb: "FFFFFF" },
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: blue },
        }
        cell.border = {
          bottom: { style: 'thin', color: { argb: blue } },
        }
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right',
          wrapText: true,
        }
      })
      layerRowTableHeader.getCell(1).alignment = {
        vertical: 'middle',
        horizontal: 'left',
        wrapText: false,
      }

      // ********************************************************************************
      // *************     nested looping to create table rows here     *****************
      let currentRow = 12
      let currentColumn = 1
      let valueType: any
      let transformedValue
      for (const excelRow of excelRows) {
        const keys = Object.keys(excelRow)
        // apply some formatting to value to set as cell's value at the end of the loop
        for (const key in keys) {
          if (key) {
            // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
            const row = worksheet.getRow(currentRow)
            const cell = row.getCell(currentColumn)

            // start applying basic, default xlsx properties to this cell
            cell.model = {
              ...cell.model,
              type: ValueType.Number,
            }

            cell.style = {
              font: {
                name: 'Segoe Pro',
                size: 10,
              },
            }
            if (keys[key] === 'Percentile') {
              valueType = 'percentage'
              transformedValue = this.transformValue(
                excelRow[keys[key]],
                valueType
              )
              cell.alignment = { horizontal: 'center' }
              cell.numFmt = '###,##0.0"%"'
            } else if (keys[key].toString().includes('Lossvalue')) {
              if (excelRow[keys[key]] === 0 || excelRow[keys[key]] === '0') {
                valueType = 'currency'
                cell.model.type = ValueType.Number
                cell.numFmt = `${currentCurrencySymbol}0`
                transformedValue = 0
                cell.alignment = { horizontal: 'right' }
              } else {
                valueType = 'currency'
                transformedValue = this.transformValue(
                  excelRow[keys[key]],
                  valueType
                )

                cell.numFmt = this.numberFormat(
                  Math.abs(Number(transformedValue)),
                  currentCurrencySymbol
                )
                cell.alignment = { horizontal: 'right' }
              }
            } else {
              valueType = 'numeric'
              transformedValue = this.transformValue(
                excelRow[keys[key]],
                valueType
              )
              cell.alignment = { horizontal: 'right' }
            }
            cell.value = transformedValue
            currentColumn++
          }
        }
        currentColumn = 1
        currentRow++
      }
      worksheet.addRow(['']) // Blank Row
      // Update the grossResults mean/ std dev and cv values on the excel file
      let grossResultsOtherValuesRow = 22
      let grossResultsOtherValuesColumn = 2
      for (const grossResultsOtherValue of grossResultsOtherValues) {
        const keys = Object.keys(grossResultsOtherValue)
        // apply some formatting to value to set as cell's value at the end of the loop
        for (const key in keys) {
          if (key) {
            // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
            const row = worksheet.getRow(grossResultsOtherValuesRow)
            const cell = row.getCell(grossResultsOtherValuesColumn)
            // start applying basic, default xlsx properties to this cell
            cell.model = {
              ...cell.model,
              type: ValueType.Number,
            }

            cell.style = {
              font: {
                name: 'Segoe Pro',
                size: 10,
              },
            }
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right',
              wrapText: true,
            }

            if (keys[key] === 'label') {
              cell.model.type = ValueType.String
              cell.alignment = { horizontal: 'right' }
              transformedValue = grossResultsOtherValue[keys[key]]
            } else if (keys[key].includes('cvValue')) {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                cell.model.type = ValueType.Number
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              } else {
                cell.model.type = ValueType.Number
                cell.numFmt = `0.0`
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              }
            } else {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                valueType = 'currency'
                cell.model.type = ValueType.Number
                cell.numFmt = `${currentCurrencySymbol}0`
                transformedValue = 0
                cell.alignment = { horizontal: 'right' }
              } else {
                valueType = 'currency'
                transformedValue = this.transformValue(
                  grossResultsOtherValue[keys[key]],
                  valueType
                )
                cell.numFmt = this.numberFormat(
                  Math.abs(Number(transformedValue)),
                  currentCurrencySymbol
                )
                cell.alignment = { horizontal: 'right' }
              }
            }
            if (keys[key] === 'label') {
              cell.model.type = ValueType.String
              cell.alignment = { horizontal: 'right' }
              transformedValue = grossResultsOtherValue[keys[key]]
            } else if (keys[key].includes('cvValue')) {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                cell.model.type = ValueType.Number
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              } else {
                cell.model.type = ValueType.Number
                cell.numFmt = `0.0`
                transformedValue = Number(grossResultsOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              }
            } else {
              if (Number(grossResultsOtherValue[keys[key]]) === 0) {
                valueType = 'currency'
                cell.model.type = ValueType.Number
                cell.numFmt = `${currentCurrencySymbol}0`
                transformedValue = 0
                cell.alignment = { horizontal: 'right' }
              } else {
                valueType = 'currency'
                transformedValue = this.transformValue(
                  grossResultsOtherValue[keys[key]],
                  valueType
                )
                cell.numFmt = this.numberFormat(
                  Math.abs(Number(transformedValue)),
                  currentCurrencySymbol
                )
                cell.alignment = { horizontal: 'right' }
              }
            }
            cell.value = transformedValue
            grossResultsOtherValuesColumn++
          }
        }
        grossResultsOtherValuesColumn = 2
        grossResultsOtherValuesRow++
      }

      worksheet.addRow(['']) // Blank Row
      const grossRatiosRowResult = worksheet.addRow(['Gross Loss Ratios'])
      grossRatiosRowResult.font = {
        name: 'Segoe Pro',
        size: 16,
      }
      for (let i = 1; i <= layerColumnTitles.length; i++) {
        grossRatiosRowResult.getCell(i).border = {
          bottom: { style: 'thin', color: { argb: '000000' } },
        }
      }
      const subjectPremiumRowResult = worksheet.addRow(['Subject Premium'])
      subjectPremiumRowResult.eachCell(cell => {
        cell.font = {
          name: 'Segoe Pro',
          size: 10,
          bold: true,
          color: { argb: blue },
        }
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'left',
          wrapText: true,
        }
      })
      for (let i = 1; i <= layerColumnTitles.length; i++) {
        subjectPremiumRowResult.getCell(i).border = {
          bottom: { style: 'thin', color: { argb: '000000' } },
        }
      }
      // Update the subject Premium Filter values on top of the loss set / groups
      const subjectPremiumRow = 27
      let subjectPremiumColumn = 3
      for (const subjectPremiumValue of subjectPremiumValues) {
        // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
        const row = worksheet.getRow(subjectPremiumRow)
        const cell = row.getCell(subjectPremiumColumn)

        // start applying basic, default xlsx properties to this cell
        cell.model = {
          ...cell.model,
          type: ValueType.Number,
        }

        cell.style = {
          font: {
            name: 'Segoe Pro',
            size: 10,
          },
          border: {
            bottom: { style: 'thin', color: { argb: '000000' } },
          },
        }
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right',
          wrapText: true,
        }
        if (subjectPremiumValue === 0) {
          valueType = 'currency'
          cell.model.type = ValueType.Number
          cell.numFmt = `${currentCurrencySymbol}0`
          transformedValue = 0
          cell.alignment = { horizontal: 'right' }
        } else {
          valueType = 'currency'
          transformedValue = this.transformValue(subjectPremiumValue, valueType)

          cell.numFmt = this.numberFormat(
            Math.abs(Number(transformedValue)),
            currentCurrencySymbol
          )
        }
        cell.value = transformedValue
        subjectPremiumColumn++
      }

      const layerRatioTableHeader = worksheet.addRow(layerColumnTitles)
      layerRatioTableHeader.eachCell(cell => {
        cell.font = {
          name: 'Segoe Pro',
          size: 10,
          bold: true,
          color: { argb: "FFFFFF" },
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: blue },
        }
        cell.border = {
          bottom: { style: 'thin', color: { argb: blue } },
        }
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right',
          wrapText: true,
        }
      })
      layerRatioTableHeader.getCell(1).alignment = {
        vertical: 'middle',
        horizontal: 'left',
        wrapText: false,
      }

      // ********************************************************************************
      // *************     nested looping to create table rows here     *****************
      let currentRatioRow = 28
      let currentRatioColumn = 1
      for (const excelLossRatioRow of excelLossRatioRows) {
        const keys = Object.keys(excelLossRatioRow)
        // apply some formatting to value to set as cell's value at the end of the loop
        for (const key in keys) {
          if (key) {
            // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
            const row = worksheet.getRow(currentRatioRow)
            const cell = row.getCell(currentRatioColumn)

            // start applying basic, default xlsx properties to this cell
            cell.model = {
              ...cell.model,
              type: ValueType.Number,
            }

            cell.style = {
              font: {
                name: 'Segoe Pro',
                size: 10,
              },
            }
            if (keys[key] === 'Percentile') {
              valueType = 'percentage'
              transformedValue = this.transformValue(
                excelLossRatioRow[keys[key]],
                valueType
              )
              cell.alignment = { horizontal: 'center' }
              cell.numFmt = '###,##0.0"%"'
            } else if (keys[key].toString().includes('Lossvalue')) {
              if (
                excelLossRatioRow[keys[key]] === 0 ||
                excelLossRatioRow[keys[key]] === '0'
              ) {
                transformedValue = 0
                cell.alignment = { horizontal: 'right' }
                cell.numFmt = '###,##0.0"%"'
                cell.model.type = ValueType.Number
              } else if (excelLossRatioRow[keys[key]] === 'N/A') {
                transformedValue = 'N/A'
                cell.alignment = { horizontal: 'right' }
                cell.model.type = ValueType.String
              } else {
                valueType = 'percentage'
                transformedValue = this.transformValue(
                  excelLossRatioRow[keys[key]],
                  valueType
                )
                cell.alignment = { horizontal: 'right' }
                cell.numFmt = '###,##0.0"%"'
              }
            } else {
              valueType = 'numeric'
              transformedValue = this.transformValue(
                excelLossRatioRow[keys[key]],
                valueType
              )
              cell.alignment = { horizontal: 'right' }
            }
            cell.value = transformedValue
            currentRatioColumn++
          }
        }
        currentRatioColumn = 1
        currentRatioRow++
      }

      // Update the grossRatios mean/ std dev and cv values on the excel file
      let grossRatiosOtherValuesRow = 38
      let grossRatiosOtherValuesColumn = 2
      for (const grossRatiosOtherValue of grossRatiosOtherValues) {
        const keys = Object.keys(grossRatiosOtherValue)
        // apply some formatting to value to set as cell's value at the end of the loop
        for (const key in keys) {
          if (key) {
            // use exceljs method's to get the current, blank cell from rows and columns to format as we loop
            const row = worksheet.getRow(grossRatiosOtherValuesRow)
            const cell = row.getCell(grossRatiosOtherValuesColumn)
            // start applying basic, default xlsx properties to this cell
            cell.model = {
              ...cell.model,
              type: ValueType.Number,
            }

            cell.style = {
              font: {
                name: 'Segoe Pro',
                size: 10,
              },
            }
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right',
              wrapText: true,
            }

            if (keys[key] === 'label') {
              cell.model.type = ValueType.String
              cell.alignment = { horizontal: 'right' }
              transformedValue = grossRatiosOtherValue[keys[key]]
            } else if (keys[key].includes('cvValue')) {
              if (Number(grossRatiosOtherValue[keys[key]]) === 0) {
                cell.model.type = ValueType.Number
                transformedValue = Number(grossRatiosOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              } else {
                cell.model.type = ValueType.Number
                cell.numFmt = `0.0`
                transformedValue = Number(grossRatiosOtherValue[keys[key]])
                cell.alignment = { horizontal: 'right' }
              }
            } else {
              if (Number(grossRatiosOtherValue[keys[key]]) === 0) {
                valueType = 'percentage'
                transformedValue = 0
                cell.numFmt = '###,##0.0"%"'
                cell.alignment = { horizontal: 'right' }
              } else {
                valueType = 'percentage'
                transformedValue = this.transformValue(
                  grossRatiosOtherValue[keys[key]],
                  valueType
                )
                cell.alignment = { horizontal: 'right' }
                cell.numFmt = '###,##0.0"%"'
              }
            }
            cell.value = transformedValue
            grossRatiosOtherValuesColumn++
          }
        }
        grossRatiosOtherValuesColumn = 2
        grossRatiosOtherValuesRow++
      }
    }

    this.AdjustColumnWidth(worksheet, 20)
    workbook.xlsx.writeBuffer().then((data: BlobPart) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, fileName)
    })
  }

  private numberFormat(value: number, currency: string): string {
    const lookup = [
      { value: 1e12, symbol: 'T', commas: ',,,,' },
      { value: 1e9, symbol: 'B', commas: ',,,' },
      { value: 1e6, symbol: 'M', commas: ',,' },
      { value: 1e3, symbol: 'K', commas: ',' },
      { value: 0, symbol: '', commas: '' },
    ]

    const item = lookup.find(i => value >= i.value)
    const positive = `"${currencySymbol(currency)}"#,##0.0${item?.commas}"${
      item?.symbol
    }"`
    const negative = `-${positive}`
    return `${positive};${negative}`
  }

  private transformValue(value: string | number, valueType: MetricValueType) {
    if (value === undefined) {
      return ''
    }

    switch (valueType) {
      case 'ratio':
      case 'percentage':
        return (value as number) * 100
      case 'currency':
        return value as number
      default:
        return value
    }
  }

  private AdjustColumnWidth(worksheet: any, width: number) {
    worksheet.columns.forEach((column: { values: number[]; width: number }) => {
      column.width = width
    })
  }
}
