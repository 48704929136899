import { SortTableColumnDef } from '@shared/sort-table/sort-table.model'

export const PC_ADD_LAYERS_COLUMNS: SortTableColumnDef[] = [
  {
    id: 'include',
    label: 'include?',
    valueType: 'checkbox',
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'clientName',
    label: 'Client Name',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'programName',
    label: 'Program Name',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'layerName',
    label: 'Layer Name',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'layerDesc',
    label: 'Layer Description',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'reinsurerName',
    label: 'Reinsurer Name',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'writtenPercent',
    label: 'Written Percent',
    valueType: 'percentage',
    decimals: 3,
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'trol',
    label: 'TROL',
    valueType: 'percentage',
    decimals: 3,
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'el',
    label: 'EL',
    valueType: 'percentage',
    decimals: 3,
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'prob_of_attach',
    label: 'Probability of Attach',
    valueType: 'percentage',
    decimals: 3,
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'prob_of_exhaust',
    label: 'Probability of Exhaust',
    valueType: 'percentage',
    decimals: 3,
    whenLoading: 'show',
    editable: false,
  },
]

export const CREDIT_ADD_LAYERS_COLUMNS: SortTableColumnDef[] = [
  {
    id: 'include',
    label: 'include?',
    valueType: 'checkbox',
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'dealType',
    label: 'Deal Type',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'dealName',
    label: 'Deal Name',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'premium',
    label: 'Premium',
    valueType: 'percentage',
    decimals: 3,
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'dti',
    label: 'DTI',
    valueType: 'numeric',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'expectedLossOnLine',
    label: 'Expected Loss on Line',
    valueType: 'percentage',
    decimals: 3,
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'fico',
    label: 'FICO',
    valueType: 'numeric',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'miAttach',
    label: 'MI Attach',
    valueType: 'percentage',
    whenLoading: 'show',
    editable: false,
    decimals: 3,
  },
  {
    id: 'miDetach',
    label: 'MI Detach',
    valueType: 'percentage',
    decimals: 3,
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'miThickness',
    label: 'MI Thickness',
    valueType: 'percentage',
    whenLoading: 'show',
    editable: false,
    decimals: 3,
  },
  {
    id: 'oltv',
    label: 'OLTV',
    valueType: 'numeric',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'pAttach',
    label: 'Probability of Attach',
    valueType: 'percentage',
    decimals: 3,
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'pDetach',
    label: 'Probability of Detach',
    valueType: 'percentage',
    decimals: 3,
    whenLoading: 'show',
    editable: false,
  },
]

export const SAVED_CURVES_COLUMNS: SortTableColumnDef[] = [
  {
    id: 'pc_name',
    label: 'Curve Name',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'audit_updated_by',
    label: 'Curve Updated By',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'pcClass',
    label: 'Class',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'pcSubClass',
    label: 'Sub-Class',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'layerType',
    label: 'Layer Type',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
  {
    id: 'audit_update_dt',
    label: 'Updated Date',
    valueType: 'date',
    whenLoading: 'show',
    editable: false,
  },
]

export const EXPORT_COLUMNS: SortTableColumnDef[] = [
  {
    id: 'include',
    label: 'Include?',
    valueType: 'checkbox',
    whenLoading: 'show',
    editable: true,
  },
  {
    id: 'label',
    label: 'Label',
    valueType: 'text',
    whenLoading: 'show',
    editable: false,
  },
]

export type SavedCurvesRowDef = {
  id: number
  pc_name: string
  audit_updated_by: string
  pcClass: string
  pcSubClass: string
  layerCategory: string
  audit_update_dt: string
}

export type DefaultAddLayersRowDef = {
  id: number
  clientName: string | null
  layerName: string | null
  layerDesc: string
  el: number
  trol: number
  prob_of_attach: number | null
  prob_of_exhaust: number | null
  include: boolean
  programName: string | null
  writtenPercent: number | null
  reinsurerName: string | null
}

export type CreditAddLayersRowDef = {
  id: number
  include: boolean
  dealName: string
  dealType: string
  premium: number
  pAttach: number
  pDetach: number
  miThickness: number
  expectedLossOnLine: number
  dti: number
  fico: number
  miAttach: number
  miDetach: number
}

export type ExportRowDef = {
  id: number
  label: string
  include: boolean
}
