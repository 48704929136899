import { EXPLORE_SUMMARY_HEADERS, ExploreSummaryHeader, GroupSummaryRequest, SummaryDataResponse } from './../explore.model'
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { LossSetGroup, LossSetLayer } from '../../model/loss-set-layers.model'
import { MappingLabels } from 'src/app/core/model/study.model'
import { ExploreFilterMap, exploreSummaryColumnDefs, ExploreSummaryDatum, MappingOption } from '../explore.model'
import { SortTableColumnDef, SortTableRow } from '@shared/sort-table/sort-table.model'
import { extractFilterMapKeys, filterLossSets, formatSummaryRows, getGroupedSummaryData, getRowsFromLossSets } from '../explore.util'
import { ShortNumberPipe } from '@shared/pipes/short-number.pipe'
import { isLoadedLossSet } from '../../model/layers.util'
import { LoadedLossSet } from 'src/app/api/analyzere/analyzere.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-gross-summary-table',
  templateUrl: './explore-gross-summary-table.component.html',
  styleUrls: ['./explore-gross-summary-table.component.scss']
})
export class ExploreGrossSummaryTableComponent implements OnInit, OnChanges {
  @Input() lossSetGroups: LossSetGroup[]
  @Input() lossSetLayers: LossSetLayer[]
  @Input() mappingLabels: MappingLabels
  @Input() summaryFilterOptions: MappingOption[]
  @Input() summaryFilterMap: { [key: string]: boolean }
  @Input() summaryColumnFilterMap: { [key: string]: boolean }
  @Input() mappingOptions: MappingOption[]
  @Input() showIndividualLossSets: boolean
  @Input() modelingArray: string[]
  @Input() summaryData: SummaryDataResponse[]
  @Input() groupSummaryData: SummaryDataResponse[]
  @Input() currentStructureCurrency: string | undefined
  @Input() analysisProfileCurrency: string
  @Input() groups: GroupSummaryRequest[]

  columnDefs: SortTableColumnDef[] = exploreSummaryColumnDefs
  updatedColumnDefs: SortTableColumnDef[] = exploreSummaryColumnDefs
  rows: SortTableRow<ExploreSummaryDatum>[]
  updatedDataRows: SortTableRow<ExploreSummaryDatum>[]
  headers: ExploreSummaryHeader[]

  shortNumberPipe = new ShortNumberPipe()

  ngOnInit(): void {
    this.headers = EXPLORE_SUMMARY_HEADERS.filter(h => h.id !== 'groupBy')
    this.updatedColumnDefs = exploreSummaryColumnDefs.filter(c => c.id !== 'groupBy')
    this.updateTableData()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.updateTableData()
    }
  }

  updateTableData(): void {
    const model = this.modelingArray.filter(x => x !== '')
    if (this.lossSetLayers) {
      this.rows = getRowsFromLossSets(
        filterLossSets(this.lossSetLayers, this.summaryFilterMap),
        this.summaryData
      )
      if (model.length > 0) {
        this.updatedDataRows =
          getGroupedSummaryData(
            this.groupSummaryData,
            this.summaryData,
            this.modelingArray,
            this.groups,
            this.showIndividualLossSets,
            this.rows,
            this.currentStructureCurrency,
            this.lossSetLayers
          )
      } else {
        this.updatedDataRows = formatSummaryRows(this.rows, this.currentStructureCurrency, this.lossSetLayers)
      }
    }
    this.updatedColumnDefs = this.filterColumnDefs()
  }

  filterColumnDefs(): SortTableColumnDef[] {
    let defs = exploreSummaryColumnDefs
    const model = this.modelingArray.filter(x => x !== '')
    const map = extractFilterMapKeys(this.summaryColumnFilterMap, '_')
    const isGroupBy = model.length > 0
    if (this.summaryColumnFilterMap) {
      defs = defs.filter(def => {
        const keyValue = String(def.id).split('_')[0]
        return Object.keys(map).every(key => {
          const allowedValues = map[key]
          return allowedValues.includes(keyValue)
        }) ||
          keyValue === 'name' ||
          (
            isGroupBy &&
            (
              keyValue === 'groupBy' ||
              keyValue === 'totalContributionToGroupVolatility' ||
              keyValue === 'contributionToGroupVolatility'
            ))
      })
    }
    if (isGroupBy) {
      defs = defs.map(d => {
        const labels = model.map(key => {
          const label = this.summaryFilterOptions.find(o => o.value === key).label
          return label ?? key
        })
        const label = d.id === 'groupBy' ?
          labels.filter(x => x !== '').join('/') :
          d.label
        return {
          ...d,
          label
        }
      })
    }
    this.headers = EXPLORE_SUMMARY_HEADERS.filter(header => {
      const id = header.id
      return Object.keys(map).every(key => {
        const allowedValues = map[key]
          return allowedValues.includes(id)
      }) ||
        id === 'name' ||
        (isGroupBy &&
          (
            id === 'groupBy' ||
            id === 'totalContributionToGroupVolatility' ||
            id === 'contributionToGroupVolatility'
          ))
    })
    return defs
  }
}
