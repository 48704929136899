import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { assoc, dissoc } from 'ramda'
import { ApplicationError } from '../../../../error/model/error'
import { CompareMetricSetting } from '../../../model/compare-metrics.model'
import * as Actions from './metrics-cart.actions'
import { validateFormula } from 'src/app/metrics/metrics.util'

interface ExtendedState {
  studyID: string | null
  loading: boolean
  error: ApplicationError | null
  expandedCategories: Record<string, boolean>
  saving: boolean
}

export type State = EntityState<CompareMetricSetting> & ExtendedState

const addIDPart = <T>(obj: T, prop: keyof T, suffix = false): string => {
  const _prefix = !suffix ? '_' : ''
  const _suffix = suffix ? '_' : ''
  return obj[prop] ? _prefix + `${obj[prop]}` + _suffix : ''
}

const buildID = (setting: CompareMetricSetting): string =>
  addIDPart(setting, 'category', true) +
  setting.label +
  addIDPart(setting, 'portfolioType') +
  addIDPart(setting, 'aggregationMethodType') +
  addIDPart(setting, 'path') +
  addIDPart(setting, 'returnPeriodNumber')

export const adapter = createEntityAdapter<CompareMetricSetting>({
  selectId: setting => buildID(setting),
  sortComparer: (a, b) => a.ordinal - b.ordinal,
})

export const { selectAll: selectMetricCartEntities } = adapter.getSelectors()

export const initialState: State = adapter.getInitialState<ExtendedState>({
  studyID: null,
  loading: false,
  error: null,
  expandedCategories: {},
  saving: false,
})

export const updateReducer = (
  state: State,
  label: string,
  changes: Partial<CompareMetricSetting>
) => {
  let metrics: (CompareMetricSetting | undefined)[]
  metrics = selectByLabel(label, state)
  return adapter.updateMany(
    // tslint:disable-next-line: no-non-null-assertion
    metrics.map(m => ({ id: buildID(m!), changes })),
    state
  )
}

const compareMetricTableReducer = createReducer(
  initialState,

  on(Actions.fetchMetricTableSettings, (state, { studyID }) => ({
    ...state,
    studyID,
    loading: true,
    error: initialState.error,
  })),

  on(Actions.fetchMetricTableSettingsFailure, (state, { error }) => ({
    ...state,
    loading: initialState.loading,
    error,
  })),

  on(Actions.fetchMetricTableSettingsSuccess, (state, { settings, isCredit }) => {
    const updatedSettings = validateFormula(settings, false)
    return adapter.setAll(updatedSettings, {
      ...state,
      loading: false,
      error: initialState.error,
      isCredit
    })
  }),

  on(Actions.clearMetricSettings, (state) => {
    return {
      ...state,
      ...initialState
    }
  }),

  on(Actions.setDirty, (state: State, { setting, dirty }) => {
    const id = buildID(setting)
    if (!state.entities[id]) {
      return state
    }
    return adapter.updateOne({ id, changes: { dirty } }, state)
  }),

  on(Actions.updateMetricTableSettings, (state, { metrics, ...changes }) =>
    adapter.updateOne({ id: buildID(metrics), changes }, state)
  ),

  on(Actions.addNewCustomMetric, (state, { newCustomMetric }) =>
    adapter.addOne(newCustomMetric, state)
  )
)

const expandedCategoriesReducer = createReducer(
  initialState.expandedCategories,
  on(Actions.setExpandedMetricTableCategory, (state, { category, value }) => {
    const expand = value != null ? value : !state[category]
    return expand ? assoc(category, true, state) : dissoc(category, state)
  })
)

const savingReducer = createReducer(
  initialState.loading,
  on(Actions.saveMetricTableSettings, () => true),
  on(
    Actions.saveMetricTableSettingsSuccess,
    Actions.saveMetricTableSettingsFailure,
    () => false
  )
)

export function reducer(state: State | undefined, action: Action) {
  return {
    ...compareMetricTableReducer(state, action),
    expandedCategories: expandedCategoriesReducer(
      state && state.expandedCategories,
      action
    ),
    saving: savingReducer(state && state.saving, action),
  }
}

export const selectByLabel = (
  label: string,
  state: State
): CompareMetricSetting[] => {
  return selectMetricCartEntities(state).filter(e => e.label === label)
}
