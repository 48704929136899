import { Cell, Margins, Row, Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { DatePipe, getCurrencySymbol } from '@angular/common'
import { clone } from 'ramda'
import { ReinsurerExportService } from 'src/app/reinsurers/export-service/reinsurer-export.service'
import { locktonLogoBase64, sageLogoBase64 } from '@shared/util/export'

export interface QuoteExcelParams {
  clientName: string
  programName: string
  headers: any[]
  descriptionColumn: string[] // describes each column (i.e. Reinsurer)
  descriptionRows: string[][][] // describes what each value of the row measures (i.e. Subject Premium, Risk Limit, etc.)
  nonFotAndExpiringRows: any[][]
  nonFotAndExpiringColumns: string[][]
  fotReinsurerRows: any[][]
  fotReinsurerColumns: string[][]
  expiringReinsurerRows: any[][]
  expiringReinsurerColumns: string[][]
  trackingRows1: any[][]
  trackingRows2: any[][]
  trackingColumns: string[]
  oppDate: string | undefined
  oppName: string | undefined
  assignedLinesRows: any[][]
  assignedLinesColumns: string[]
  trackingRowsAL1: any[][]
  trackingRowsAL2: any[][]
  structureGroupName: string | null | undefined
  sharedLimitName: string | null | undefined
  quotesSignedPercRow: any[]
  members: any[]
  sectionCurrencies: string[]
}
interface ExcelOptions {
  margins: Margins
}

const DEFAULT_OPTIONS: ExcelOptions = {
  margins: {
    left: 0.2,
    right: 0.2,
    top: 0.5,
    bottom: 0.5,
    header: 0.3,
    footer: 0.3,
  },
}

type Currency = readonly [string, number]

function parseCurrency(value: any): Currency {
  const m = String(value).match(/([A-Z]{3}|\D)?\s*([\d,.]+)/)
  if (m === null) {
    return ['', 0]
  }

  const code = m[1] ?? ''
  const num = parseFloat(m[2].replace(/,/g, ''))

  return [code, num]
}

function currencyToValue(currency: Currency[][]): number[][] {
  return currency.map(cc => cc.map(c => c[1]))
}

export default class QuoteExcel {
  private workbook: Workbook
  private quoteParams: QuoteExcelParams
  private titleName: string | undefined
  private effectiveDate: string
  private dateAsOf: string
  private fileName = 'export'
  private exportFileName = 'export'
  private maxRows: number
  private sectionCurrencySymbols: string[]
  private logo: number
  private sageLogo: number

  constructor(
    private datePipe: DatePipe,
    quoteParams: QuoteExcelParams,
    private exportService: ReinsurerExportService
  ) {
    this.workbook = new Workbook()
    this.quoteParams = { ...quoteParams }
    this.titleName = this.quoteParams.oppName
      ? this.quoteParams.oppName
      : this.quoteParams.clientName
    let effDate = ''
    if (this.quoteParams.oppDate) {
      const parts = this.quoteParams.oppDate.split('-')
      effDate =
        this.datePipe.transform(
          new Date(
            parseInt(parts[0], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[2], 10)
          ).toString(),
          'longDate'
        ) || ''
    }
    this.effectiveDate = 'Effective: ' + effDate
    this.dateAsOf = 'As of ' + this.datePipe.transform(new Date(), 'longDate')
    if (this.quoteParams.clientName && this.quoteParams.programName) {
      this.fileName =
        this.quoteParams.clientName +
        ' ' +
        this.quoteParams.programName +
        ' Quotes ' +
        this.datePipe.transform(new Date(), 'MM.dd.yyyy') +
        '.xlsx'
      this.exportFileName =
        this.quoteParams.clientName +
        ' ' +
        this.quoteParams.programName +
        ' Quotes ' +
        '.xlsx'
    }
    this.maxRows = 0
    this.sectionCurrencySymbols = this.quoteParams.sectionCurrencies.map(
      currency => getCurrencySymbol(currency ?? 'USD', 'narrow')
    )
    this.logo = this.workbook.addImage({
      base64: locktonLogoBase64,
      extension: 'png',
    })
    this.sageLogo = this.workbook.addImage({
      base64: sageLogoBase64,
      extension: 'png'
    })
  }

  export() {
    this.addQuoteWorksheet()
    this.addQuotedContractRequestsWorksheet()
    this.addAuthAndSignedLinesWorksheet()
    this.addFotContractRequestsWorksheet()
    this.saveFile()
  }

  private addQuoteWorksheet() {
    const worksheet = this.workbook.addWorksheet('Quotes', {
      pageSetup: { fitToPage: true },
      views: [{ showGridLines: false }],
    })
    worksheet.pageSetup.margins = DEFAULT_OPTIONS.margins

    /* Add title rows */
    const title1Row = worksheet.addRow([this.titleName])
    title1Row.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: '00AEEF' }
    }
    const title2Row = worksheet.addRow([
      this.quoteParams.programName + ' Placement',
    ])
    title2Row.font = {
      name: 'Segoe Pro',
      size: 8,
    }
    const title3Row = worksheet.addRow([this.effectiveDate])
    title3Row.font = {
      name: 'Segoe Pro',
      size: 8,
      color: { argb: '5b6670' }
    }
    // Blank Row
    worksheet.addRow([''])
    const title4Row = worksheet.addRow(['Market Quote Summary'])
    title4Row.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: '00AEEF' }
    }
    const dateRow = worksheet.addRow([this.dateAsOf])
    dateRow.eachCell(c => {
      c.font = {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: '5b6670' }
      }
    })

    let reinsurerNames: string[] = []
    if (
      this.quoteParams.structureGroupName ||
      this.quoteParams.sharedLimitName
    ) {
      const nonFotAndExpiringReNames =
        this.quoteParams.nonFotAndExpiringColumns[0].slice(
          0,
          this.quoteParams.nonFotAndExpiringColumns[0].length - 2
        ) /* need to remove empty string columns that are used for Total Offered %, etc */
      reinsurerNames = [
        ...this.quoteParams.descriptionColumn,
        ...this.quoteParams.expiringReinsurerColumns[0],
        ...nonFotAndExpiringReNames,
      ]
    }
    // Add Program Group Name
    let programGroupNameRow: Row
    if (this.quoteParams.structureGroupName) {
      programGroupNameRow = worksheet.addRow([
        this.quoteParams.structureGroupName,
      ])
      programGroupNameRow.getCell(1).font = {
        name: 'Segoe Pro',
        size: 8,
        bold: true,
        color: { argb: 'FFFFFF' },
      }
      reinsurerNames.map((reName, index) => {
        programGroupNameRow.getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '737F8B' },
        }
        return reName
      })
    }

    // Add Shared Limit Name
    let sharedLimitNameRow: Row
    if (this.quoteParams.sharedLimitName) {
      sharedLimitNameRow = worksheet.addRow([this.quoteParams.sharedLimitName])
      sharedLimitNameRow.getCell(1).font = {
        name: 'Segoe Pro',
        size: 8,
        bold: true,
        color: { argb: 'FFFFFF' },
      }
      reinsurerNames.map((name2, index) => {
        sharedLimitNameRow.getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '737F8B' },
        }
        return name2
      })
    }

    /* Add each section to worksheet by layer */
    this.maxRows = 0
    let offeredRow = 0
    let pmpmCurrency = ''
    const membersLayers: any[][] = []
    const occLimitVals: Currency[][] = []
    const subPremiumVals: Currency[][] = []
    const rolVals: number[][] = []
    const rosVals: number[][] = []
    const pmpmVals: number[][] = []
    const priorTotalBlendedRateAll: number[][] = []
    const currTotalBlendedRateAll: number[][] = []
    const modeTotalBlendedOccLimit: Currency[][] = []
    const modeTotalBlendedSubPrem: Currency[][] = []

    const exLength = Math.max(
      ...this.quoteParams.expiringReinsurerColumns.map(e => e.length)
    )
    const curLength = Math.max(
      ...this.quoteParams.nonFotAndExpiringColumns.map(e => e.length)
    )
    const blendedColumn =
      exLength + curLength + 2 > 8 ? exLength + curLength + 2 : 8
    const blendedRow = 8

    if (this.quoteParams.headers.length > 0) {
      let layerNameRow: Row
      let columnNamesRow: Row
      let sectionLayerNameRow: Row
      // for group and SL
      if (
        this.quoteParams.sharedLimitName ||
        this.quoteParams.structureGroupName
      ) {
        // Add column names
        columnNamesRow = worksheet.addRow(reinsurerNames)
        // Add FOT Names
        if (columnNamesRow && this.quoteParams.fotReinsurerColumns.length > 0) {
          const col = columnNamesRow.actualCellCount + 4
          const colNameRowNum = columnNamesRow.number
          const row = colNameRowNum
          this.quoteParams.fotReinsurerColumns[0].forEach((c, i) => {
            const cell = worksheet.getCell(row, col + i)
            cell.value = c
            worksheet.getCell(row - 1, col + i).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '737F8B' },
            }
          })
        }
      }
      let multiSectionCheck = true
      // get blended global blended column

      this.quoteParams.headers.forEach((h, i) => {
        // Add Layer Name
        if (this.quoteParams.sharedLimitName && h.trim() === '') {
          h = 'Shared Limit Layer'
        }
        // Add multisection
        if (
          !this.quoteParams.sharedLimitName &&
          !this.quoteParams.structureGroupName
        ) {
          if (multiSectionCheck && h && h.includes(':')) {
            sectionLayerNameRow = worksheet.addRow([h.split(':')[1]])
            const nonFotAndExpiringReNames =
              this.quoteParams.nonFotAndExpiringColumns[i].slice(
                0,
                this.quoteParams.nonFotAndExpiringColumns[i].length - 2
              ) /* need to remove empty string columns that are used for Total Offered %, etc */
            reinsurerNames = [
              ...this.quoteParams.descriptionColumn,
              ...this.quoteParams.expiringReinsurerColumns[i],
              ...nonFotAndExpiringReNames,
            ]
            multiSectionCheck = false
            columnNamesRow = worksheet.addRow(reinsurerNames)
            if (
              columnNamesRow &&
              this.quoteParams.fotReinsurerColumns.length > 0
            ) {
              const col = columnNamesRow.actualCellCount + 4
              const colNameRowNum = columnNamesRow.number
              const row = colNameRowNum
              this.quoteParams.fotReinsurerColumns[i].forEach((c, index) => {
                const cell = worksheet.getCell(row, col + index)
                cell.value = c
                worksheet.getCell(row - 1, col + index).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '737F8B' },
                }
              })
            }
          }
          if (h && !h.includes(':')) {
            sectionLayerNameRow = worksheet.addRow([h])
            const nonFotAndExpiringReNames =
              this.quoteParams.nonFotAndExpiringColumns[i].slice(
                0,
                this.quoteParams.nonFotAndExpiringColumns[i].length - 2
              ) /* need to remove empty string columns that are used for Total Offered %, etc */
            reinsurerNames = [
              ...this.quoteParams.descriptionColumn,
              ...this.quoteParams.expiringReinsurerColumns[i],
              ...nonFotAndExpiringReNames,
            ]
            columnNamesRow = worksheet.addRow(reinsurerNames)
            const colNameRowNum = columnNamesRow.number
            const row = colNameRowNum

            if (
              columnNamesRow &&
              this.quoteParams.fotReinsurerColumns.length > 0
            ) {
              /* Add FOT column names next to 'Reinsurer' */
              const col = blendedColumn + 5

              this.quoteParams.fotReinsurerColumns[i].forEach((c, index) => {
                const cell = worksheet.getCell(row, col + index)
                cell.value = c
                worksheet.getCell(row - 1, col + index).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '737F8B' },
                }
              })
            }
          }
          if (h && h.includes(':')) {
            h = h.split(':').shift()
            layerNameRow = worksheet.addRow([h])
          }
          sectionLayerNameRow.getCell(1).font = {
            name: 'Segoe Pro',
            size: 8,
            bold: true,
            color: { argb: 'FFFFFF' },
          }
          reinsurerNames.map((_, index) => {
            sectionLayerNameRow.getCell(index + 1).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '737F8B' },
            }
          })
        } else {
          layerNameRow = worksheet.addRow([h])
        }
        if (layerNameRow) {
          layerNameRow.getCell(1).font = {
            name: 'Segoe Pro',
            size: 8,
            bold: true,
            color: { argb: '000000' },
          }
        }

        if (
          this.quoteParams.nonFotAndExpiringColumns[i].length > this.maxRows
        ) {
          this.maxRows = this.quoteParams.nonFotAndExpiringColumns[i].length
        }
        columnNamesRow.height = 30
        columnNamesRow.eachCell(cell => {
          cell.alignment = { wrapText: true }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00AEEF' },
          }
          cell.font = {
            name: 'Segoe Pro',
            size: 8,
            bold: true,
            color: { argb: 'FFFFFF'}
          }
        })

        // display total expected ceded & premium values in the last row
        if (
          this.quoteParams.structureGroupName ||
          (!this.quoteParams.sharedLimitName &&
            !this.quoteParams.structureGroupName &&
            this.quoteParams.headers[i].includes(':')) ||
          this.quoteParams.sharedLimitName
        ) {
          this.quoteParams.descriptionRows[i].forEach((row, ind) => {
            if (
              i !== this.quoteParams.headers.length - 1 &&
              row[0] === 'Total Expected Ceded Premium'
            ) {
              this.quoteParams.nonFotAndExpiringRows[i].splice(ind, 1)
              this.quoteParams.fotReinsurerRows[i].splice(ind, 1)
              if (Object.isFrozen(this.quoteParams.descriptionRows[i])) {
                this.quoteParams.descriptionRows = [
                  ...this.quoteParams.descriptionRows,
                ]
                this.quoteParams.descriptionRows[i] = [
                  ...this.quoteParams.descriptionRows[i],
                ]
              }
              this.quoteParams.descriptionRows[i].splice(ind, 1)
            }
          })
          this.quoteParams.descriptionRows[i].forEach((row, ind) => {
            if (
              i !== this.quoteParams.headers.length - 1 &&
              row[0] === 'Total Expected Ceded Loss'
            ) {
              this.quoteParams.nonFotAndExpiringRows[i].splice(ind, 1)
              this.quoteParams.fotReinsurerRows[i].splice(ind, 1)
              this.quoteParams.descriptionRows[i].splice(ind, 1)
            }
          })
        }
        const mergedRows = this.mergeDataRows()
        mergedRows[i].forEach(row => {
          const rowVals: any[] = []
          row.forEach(r => {
            if (r) {
              rowVals.push(r[0])
            }
          })
          const DataRow = worksheet.addRow(rowVals)
          DataRow.eachCell((cell, j) => {
            const showBorder = j <= DataRow.cellCount - 2 || cell.value !== ''
            cell.style = {
              font: {
                name: 'Segoe Pro',
                size: 8,
              },
              alignment: { horizontal: 'right', wrapText: true },
              border: { bottom: { style: 'thin', color: { argb: showBorder ? '000000' : 'FFFFFFF' } } }
            }
            const cellCol = String.fromCharCode(Number(cell.col) + 62)
            if (
              j > 2 &&
              row[j - 2][0] === 'Total Offered %:' &&
              Number(cell.col) !== 3
            ) {
              const cellValue = (Number(cell.value) * 100).toFixed(2) + '%'
              cell.value = {
                result: cellValue,
                formula: `=SUM(B${cell.row}:${cellCol}${cell.row})`,
                date1904: false,
              }
              offeredRow = Number(cell.row)
            }
            if (
              j > 2 &&
              cell.value !== 0 &&
              (row[j - 2][0] === 'Weighted Avg. Rate On-Line, Occ:' ||
                row[j - 2][0] === 'Weighted Avg. Rate, % of Subject:')
            ) {
              const cellValue = (Number(cell.value) * 100).toFixed(4) + '%'
              cell.value = {
                result: cellValue,
                formula: `=SUMPRODUCT(B${cell.row}:${cellCol}${cell.row},B${offeredRow}:${cellCol}${offeredRow})/SUM(B${offeredRow}:${cellCol}${offeredRow})`,
                date1904: false,
              }
            }
            if (
              row[j - 1] &&
              row[j - 1][1] === 'currency' &&
              cell.value !== 'Unlimited'
            ) {
              if (
                cell.value &&
                typeof cell.value === 'number' &&
                cell.value % 1 !== 0
              ) {
                cell.numFmt = this.formatCellAsCurrency(
                  this.sectionCurrencySymbols[i],
                  true
                )
              } else {
                cell.numFmt = this.formatCellAsCurrency(
                  this.sectionCurrencySymbols[i],
                  false
                )
              }
              cell.value = this.removeCurrencySymbolFromCell(cell)
            }
            if (row[j - 1] && row[j - 1][1] === 'percentage') {
              if (
                row[0][0] === 'Rate-on-Line, Occurrence' ||
                row[0][0] === 'Rate, % of Subject'
              ) {
                cell.numFmt = '0.0000%'
              } else {
                cell.numFmt = '0.00%'
              }
            }

            if (row[0][0] === 'Cessions Based Premium') {
              cell.alignment = { horizontal: 'center' }
              if (j === 1) {
                cell.alignment = { horizontal: 'left' }
              }
              if (row[j - 1][0] === true && row[j - 1][1] === 'checkbox') {
                cell.value = 'x'
              }
            }

            if (typeof cell.value === 'string' && cell.value.includes('@')) {
              cell.alignment = { horizontal: 'right' };
            }
          })
        })

        const priorBlendedNameCell = worksheet.getCell(
          blendedRow,
          blendedColumn
        )

        priorBlendedNameCell.value = 'Prior Blended Total rate'
        worksheet.getCell(blendedRow - 1, blendedColumn).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '737F8B' },
        }
        worksheet.getCell(blendedRow, blendedColumn).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00AEEF' },
        }
        priorBlendedNameCell.alignment = { wrapText: true }

        // Blended Rate Columns - current
        const currentBlendedNameCell = worksheet.getCell(
          blendedRow,
          blendedColumn + 1
        )
        currentBlendedNameCell.value = 'Current Blended Total rate'
        worksheet.getCell(blendedRow - 1, blendedColumn + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '737F8B' },
        }
        worksheet.getCell(blendedRow, blendedColumn + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00AEEF' },
        }
        currentBlendedNameCell.alignment = { wrapText: true }

        //  Blended Rate Column Rate Change
        const BlendedRateChangeNameCell = worksheet.getCell(
          blendedRow,
          blendedColumn + 2
        )
        BlendedRateChangeNameCell.value = 'Blended YoY Rate Change'
        worksheet.getCell(blendedRow - 1, blendedColumn + 2).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '737F8B' },
        }
        worksheet.getCell(blendedRow, blendedColumn + 2).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00AEEF' },
        }
        BlendedRateChangeNameCell.alignment = { wrapText: true }

        if (
          (layerNameRow && this.quoteParams.fotReinsurerRows.length > 0) ||
          (sectionLayerNameRow && this.quoteParams.fotReinsurerRows.length > 0)
        ) {
          let col = blendedColumn + 5
          const layerNameRowNum =
            (layerNameRow && layerNameRow.number) ||
            (sectionLayerNameRow && sectionLayerNameRow.number)
          let row = layerNameRowNum

          row++
          const prevCol = col

          if (
            this.quoteParams.fotReinsurerRows &&
            this.quoteParams.fotReinsurerRows[i]
          ) {
            this.quoteParams.fotReinsurerRows[i].forEach((r, idx) => {
              row++
              col = prevCol
              r.forEach((r1: any, j: number) => {
                if (r1) {
                  let cell
                  if (
                    !this.quoteParams.structureGroupName &&
                    !this.quoteParams.sharedLimitName &&
                    sectionLayerNameRow &&
                    !this.quoteParams.headers[i].includes(':')
                  ) {
                    cell = worksheet.getCell(row, col)
                  } else {
                    cell = worksheet.getCell(row - 1, col)
                  }
                  cell.value = r1[0]
                  cell.alignment = { wrapText: true }
                  cell.style = {
                    font: {
                      name: 'Segoe Pro',
                      size: 8,
                    },
                    alignment: { horizontal: 'right', wrapText: true },
                    border: { bottom: { style: 'thin', color: { argb: '000000' } } }
                  }
                  if (r1[1] === 'currency' && cell.value !== 'Unlimited') {
                    if (
                      cell.value &&
                      typeof cell.value === 'number' &&
                      cell.value % 1 !== 0
                    ) {
                      cell.numFmt = this.formatCellAsCurrency(
                        this.sectionCurrencySymbols[i],
                        true
                      )
                    } else {
                      cell.numFmt = this.formatCellAsCurrency(
                        this.sectionCurrencySymbols[i],
                        false
                      )
                    }
                    cell.value = this.removeCurrencySymbolFromCell(cell)
                  }
                  if (r1[1] === 'percentage') {
                    const columnName =
                      this.quoteParams.nonFotAndExpiringRows[i][idx][0][0]
                    if (
                      columnName === 'Rate-on-Line, Occurrence' ||
                      columnName === 'Rate, % of Subject'
                    ) {
                      cell.numFmt = '0.0000%'
                    } else {
                      cell.numFmt = '0.00%'
                    }
                  }
                  col++
                }
              })
            })
          }
        }

        // check for audience types
        const rolInd = this.quoteParams.descriptionRows[i].findIndex(
          e => e[0] === 'Rate-on-Line, Occurrence'
        )
        const rateOfSubjectInd = this.quoteParams.descriptionRows[i].findIndex(
          e => e[0] === 'Rate, % of Subject'
        )
        const occLimitInd = this.quoteParams.descriptionRows[i].findIndex(
          this.containsOccurrenceLimit
        )
        // Only applicable for top and drop layers
        const sharedLimitInd = this.quoteParams.descriptionRows[i].findIndex(
          e => e[0] === 'Shared Limit'
        )
        const subPremInd = this.quoteParams.descriptionRows[i].findIndex(
          e => e[0] === 'Subject Premium'
        )
        const pmpmInd = this.quoteParams.descriptionRows[i].findIndex(
          e => e[0] === 'PMPM'
        )

        if (occLimitInd >= 0) {
          const expiringrows = this.quoteParams.expiringReinsurerRows[i][
            occLimitInd
          ]
            ? this.quoteParams.expiringReinsurerRows[i][occLimitInd]
            : []
          const nonexpiringrows =
            this.quoteParams.nonFotAndExpiringRows[i][occLimitInd].length > 2
              ? this.quoteParams.nonFotAndExpiringRows[i][occLimitInd]
              : []
          const occLimitValsNonNum = [
            ...expiringrows.map((e: any) => e[0]),
            ...nonexpiringrows.slice(0, -2).map((e: any) => e[0]),
          ]

          occLimitVals.push(occLimitValsNonNum.map(v => parseCurrency(v)))
          modeTotalBlendedOccLimit.push([this.MODE(occLimitVals[i])])
        } else if (sharedLimitInd >= 0) {
          const expiringrows = this.quoteParams.expiringReinsurerRows[i][
            sharedLimitInd
          ]
            ? this.quoteParams.expiringReinsurerRows[i][sharedLimitInd]
            : []
          const nonexpiringrows =
            this.quoteParams.nonFotAndExpiringRows[i][sharedLimitInd].length > 2
              ? this.quoteParams.nonFotAndExpiringRows[i][sharedLimitInd]
              : []
          const occLimitValsNonNum = [
            ...expiringrows.map((e: any) => e[0]),
            ...nonexpiringrows.slice(0, -2).map((e: any) => e[0]),
          ]

          occLimitVals.push(occLimitValsNonNum.map(v => parseCurrency(v)))
          modeTotalBlendedOccLimit.push([this.MODE(occLimitVals[i])])
        }
        if (subPremInd >= 0) {
          const expiringrows = this.quoteParams.expiringReinsurerRows[i][
            subPremInd
          ]
            ? this.quoteParams.expiringReinsurerRows[i][subPremInd]
            : []
          const nonexpiringrows =
            this.quoteParams.nonFotAndExpiringRows[i][subPremInd].length > 2
              ? this.quoteParams.nonFotAndExpiringRows[i][subPremInd]
              : []

          const subjectPremValsNonNum = [
            ...expiringrows.map((e: string) => e[0]),
            ...nonexpiringrows.slice(0, -2).map((e: string) => e[0]),
          ]

          const subjectPremValsNum = subjectPremValsNonNum.map(v =>
            parseCurrency(v)
          )

          subPremiumVals.push(subjectPremValsNum)
          modeTotalBlendedSubPrem.push([this.MODE(subPremiumVals[i])])
        }

        // rate on line, occurance
        if (rolInd >= 0) {
          const rolExpiringBlendedRateTotal = this.calculatePriorBlendedRate(
            i,
            rolInd
          )
          const rolCurrBlendedRateTotal = this.calculateCurrBlendedRate(
            i,
            rolInd
          )

          priorTotalBlendedRateAll.push([rolExpiringBlendedRateTotal])
          currTotalBlendedRateAll.push([rolCurrBlendedRateTotal])

          const rolExTotalCell = worksheet.getCell(
            columnNamesRow.number + rolInd + 1,
            blendedColumn
          )
          rolExTotalCell.value = rolExpiringBlendedRateTotal
          rolExTotalCell.numFmt = '0.00%'

          const rolCurrTotalCell = worksheet.getCell(
            columnNamesRow.number + rolInd + 1,
            blendedColumn + 1
          )
          rolCurrTotalCell.value = rolCurrBlendedRateTotal
          rolCurrTotalCell.numFmt = '0.00%'

          const rolYoyRateChangeCell = worksheet.getCell(
            columnNamesRow.number + rolInd + 1,
            blendedColumn + 2
          )
          if (
            rolExpiringBlendedRateTotal !== 0 &&
            rolCurrBlendedRateTotal !== 0
          ) {
            rolYoyRateChangeCell.value = {
              formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
                columnNamesRow.number + rolInd + 1
              }/${worksheet.getColumn(blendedColumn).letter}${
                columnNamesRow.number + rolInd + 1
              }-1`,
              date1904: false,
            }
            rolYoyRateChangeCell.numFmt = '0.00%'
          } else {
            rolYoyRateChangeCell.value = 0
            rolYoyRateChangeCell.numFmt = '0.00%'
          }

          // build for total blend rates
          const expiringrows = this.quoteParams.expiringReinsurerRows[i][rolInd]
            ? this.quoteParams.expiringReinsurerRows[i][rolInd]
            : []
          const nonexpiringrows =
            this.quoteParams.nonFotAndExpiringRows[i][rolInd].length > 2
              ? this.quoteParams.nonFotAndExpiringRows[i][rolInd]
              : []
          rolVals.push([
            ...expiringrows.map((e: any) => e[0]),
            ...nonexpiringrows.slice(0, -2).map((e: any) => e[0]),
          ])
        }
        // rate of subject %
        if (rateOfSubjectInd >= 0) {
          const rateOfSubjectExpiringBlendedRateTotal: number =
            rateOfSubjectInd >= 0
              ? this.calculatePriorBlendedRate(i, rateOfSubjectInd)
              : 0
          const rateOfSubjectCurrBlendedRateTotal: number =
            rateOfSubjectInd >= 0
              ? this.calculateCurrBlendedRate(i, rateOfSubjectInd)
              : 0

          priorTotalBlendedRateAll.push([rateOfSubjectExpiringBlendedRateTotal])
          currTotalBlendedRateAll.push([rateOfSubjectCurrBlendedRateTotal])

          const roSubExTotalCell = worksheet.getCell(
            columnNamesRow.number + rateOfSubjectInd + 1,
            blendedColumn
          )
          roSubExTotalCell.value = rateOfSubjectExpiringBlendedRateTotal
          roSubExTotalCell.numFmt = '0.00%'

          const roSubCurrTotalCell = worksheet.getCell(
            columnNamesRow.number + rateOfSubjectInd + 1,
            blendedColumn + 1
          )
          roSubCurrTotalCell.value = rateOfSubjectCurrBlendedRateTotal
          roSubCurrTotalCell.numFmt = '0.00%'

          const roSubYoyRateChangeCell = worksheet.getCell(
            columnNamesRow.number + rateOfSubjectInd + 1,
            blendedColumn + 2
          )
          if (
            rateOfSubjectExpiringBlendedRateTotal !== 0 &&
            rateOfSubjectCurrBlendedRateTotal !== 0
          ) {
            roSubYoyRateChangeCell.value = {
              formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
                columnNamesRow.number + rateOfSubjectInd + 1
              }/${worksheet.getColumn(blendedColumn).letter}${
                columnNamesRow.number + rateOfSubjectInd + 1
              }-1`,
              date1904: false,
            }
            roSubYoyRateChangeCell.numFmt = '0.00%'
          } else {
            roSubYoyRateChangeCell.value = 0
            roSubYoyRateChangeCell.numFmt = '0.00%'
          }

          // build for total blend rates
          const expiringrows = this.quoteParams.expiringReinsurerRows[i][
            rateOfSubjectInd
          ]
            ? this.quoteParams.expiringReinsurerRows[i][rateOfSubjectInd]
            : []
          const nonexpiringrows =
            this.quoteParams.nonFotAndExpiringRows[i][rateOfSubjectInd].length >
            2
              ? this.quoteParams.nonFotAndExpiringRows[i][rateOfSubjectInd]
              : []
          rosVals.push([
            ...expiringrows.map((e: any) => e[0]),
            ...nonexpiringrows.slice(0, -2).map((e: any) => e[0]),
          ])
        }

        // PMPM for AHL layers
        if (pmpmInd >= 0) {
          const pmpmExpiringBlendedRateTotal: number =
            pmpmInd >= 0 ? this.calculatePriorBlendedRate(i, pmpmInd) : 0
          const pmpmCurrBlendedRateTotal: number =
            pmpmInd >= 0 ? this.calculateCurrBlendedRate(i, pmpmInd) : 0
          pmpmCurrency =
            pmpmCurrency === ''
              ? parseCurrency(
                  this.quoteParams.nonFotAndExpiringRows[i][pmpmInd][0]
                )[0]
              : pmpmCurrency

          priorTotalBlendedRateAll.push([pmpmExpiringBlendedRateTotal])
          currTotalBlendedRateAll.push([pmpmCurrBlendedRateTotal])

          const pmpmExTotalCell = worksheet.getCell(
            columnNamesRow.number + pmpmInd + 1,
            blendedColumn
          )
          pmpmExTotalCell.value = pmpmExpiringBlendedRateTotal
          pmpmExTotalCell.numFmt = `[$${pmpmCurrency}]#,##0`

          const pmpmCurrTotalCell = worksheet.getCell(
            columnNamesRow.number + pmpmInd + 1,
            blendedColumn + 1
          )
          pmpmCurrTotalCell.value = pmpmCurrBlendedRateTotal
          pmpmCurrTotalCell.numFmt = `[$${pmpmCurrency}]#,##0`

          const pmpmYoyRateChangeCell = worksheet.getCell(
            columnNamesRow.number + pmpmInd + 1,
            blendedColumn + 2
          )
          if (
            pmpmExpiringBlendedRateTotal !== 0 &&
            pmpmCurrBlendedRateTotal !== 0
          ) {
            pmpmYoyRateChangeCell.value = {
              formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
                columnNamesRow.number + pmpmInd + 1
              }/${worksheet.getColumn(blendedColumn).letter}${
                columnNamesRow.number + pmpmInd + 1
              }-1`,
              date1904: false,
            }
            pmpmYoyRateChangeCell.numFmt = '0.00%'
          } else {
            pmpmYoyRateChangeCell.value = 0
            pmpmYoyRateChangeCell.numFmt = '0.00%'
          }

          // build for total blend rates
          const expiringrows = this.quoteParams.expiringReinsurerRows[i][
            pmpmInd
          ]
            ? this.quoteParams.expiringReinsurerRows[i][pmpmInd]
            : []
          const nonexpiringrows =
            this.quoteParams.nonFotAndExpiringRows[i][pmpmInd].length > 2
              ? this.quoteParams.nonFotAndExpiringRows[i][pmpmInd]
              : []

          const pmpmValsNonNum = [
            ...expiringrows.map((e: string) => e[0]),
            ...nonexpiringrows.slice(0, -2).map((e: string) => e[0]),
          ]

          const pmpmValsNum = pmpmValsNonNum.map(v => parseCurrency(v)[1])

          pmpmVals.push(pmpmValsNum)
        }
      })
    }

    worksheet.addRow([''])

    // Total Blend Rates
    const totalRateRow = ['Total Rate']
    this.mergeDataRows().forEach(_ => {
      totalRateRow.push(' ')
    })
    const totalRow = worksheet.addRow(totalRateRow)
    totalRow.eachCell(cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000000' },
      }
      cell.font = {
        name: 'Segoe Pro',
        size: 8,
        bold: true,
        color: { argb: 'FFFFFF' },
      }
    })

    if (rolVals.length && rolVals.some(e => e.length > 0)) {
      const rolTotalBlendRates = [
        'Rate On Line, Occurrence',
        ...this.buildTotalRowBySum(rolVals, currencyToValue(occLimitVals)),
      ]
      const rolTotalBlendRatesRow = worksheet.addRow(rolTotalBlendRates)
      rolTotalBlendRatesRow.eachCell(cell => {
        cell.numFmt = '0.00%'
        cell.border = {
          bottom: {
            style: 'thin',
            color: { argb: '000000' }
          }
        }
      })

      const priorBlendedTotal = this.buildTotalRowBySum(
        priorTotalBlendedRateAll,
        currencyToValue(modeTotalBlendedOccLimit)
      )

      const currBlendedTotal = this.buildTotalRowBySum(
        currTotalBlendedRateAll,
        currencyToValue(modeTotalBlendedOccLimit)
      )

      const rolPriorYoyRateCell = worksheet.getCell(
        rolTotalBlendRatesRow.number,
        blendedColumn
      )
      const rolCurrYoyRateCell = worksheet.getCell(
        rolTotalBlendRatesRow.number,
        blendedColumn + 1
      )
      const rolYoyRateChangeCell = worksheet.getCell(
        rolTotalBlendRatesRow.number,
        blendedColumn + 2
      )
      rolPriorYoyRateCell.value = priorBlendedTotal[0]
      rolPriorYoyRateCell.numFmt = '0.00%'
      rolCurrYoyRateCell.value = currBlendedTotal[0]
      rolCurrYoyRateCell.numFmt = '0.00%'

      if (priorBlendedTotal[0] !== 0 && currBlendedTotal[0] !== 0) {
        rolYoyRateChangeCell.value = {
          formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
            rolTotalBlendRatesRow.number
          }/${worksheet.getColumn(blendedColumn).letter}${
            rolTotalBlendRatesRow.number
          }-1`,
          date1904: false,
        }
        rolYoyRateChangeCell.numFmt = '0.00%'
      } else {
        rolYoyRateChangeCell.value = 0
        rolYoyRateChangeCell.numFmt = '0.00%'
      }
    }

    if (rosVals.length && rosVals.some(e => e.length > 0)) {
      const rosTotalBlendRates = [
        'Rate % of Subject',
        ...this.buildTotalRowByAverage(
          rosVals,
          currencyToValue(subPremiumVals)
        ),
      ]

      const rosTotalBlendRatesRow = worksheet.addRow(rosTotalBlendRates)
      rosTotalBlendRatesRow.eachCell(cell => {
        cell.numFmt = '0.00%'
        cell.border = {
          bottom: {
            style: 'thin',
            color: { argb: '000000' }
          }
        }
      })

      const priorBlendedTotal = this.buildTotalRowByAverage(
        priorTotalBlendedRateAll,
        currencyToValue(modeTotalBlendedSubPrem)
      )

      const currBlendedTotal = this.buildTotalRowByAverage(
        currTotalBlendedRateAll,
        currencyToValue(modeTotalBlendedSubPrem)
      )

      const rosPriorYoyRateCell = worksheet.getCell(
        rosTotalBlendRatesRow.number,
        blendedColumn
      )
      const rosCurrYoyRateCell = worksheet.getCell(
        rosTotalBlendRatesRow.number,
        blendedColumn + 1
      )
      const rosYoyRateChangeCell = worksheet.getCell(
        rosTotalBlendRatesRow.number,
        blendedColumn + 2
      )
      rosPriorYoyRateCell.value = priorBlendedTotal[0]
      rosPriorYoyRateCell.numFmt = '0.00%'
      rosCurrYoyRateCell.value = currBlendedTotal[0]
      rosCurrYoyRateCell.numFmt = '0.00%'
      if (priorBlendedTotal[0] !== 0 && currBlendedTotal[0] !== 0) {
        rosYoyRateChangeCell.value = {
          formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
            rosTotalBlendRatesRow.number
          }/${worksheet.getColumn(blendedColumn).letter}${
            rosTotalBlendRatesRow.number
          }-1`,
          date1904: false,
        }
        rosYoyRateChangeCell.numFmt = '0.00%'
      } else {
        rosYoyRateChangeCell.value = 0
        rosYoyRateChangeCell.numFmt = '0.00%'
      }
    }
    if (pmpmVals.length && pmpmVals.some(e => e.length > 0)) {
      const pmPmTotalBlendRates = [
        'PMPM',
        ...this.buildTotalRowByAverage(
          pmpmVals,
          this.quoteParams.members.map(m => [m])
        ),
      ]
      const pmPmTotalBlendRatesRow = worksheet.addRow(pmPmTotalBlendRates)
      pmPmTotalBlendRatesRow.eachCell(cell => {
        cell.numFmt = `[$${pmpmCurrency}]#,##0`
        cell.border = {
          bottom: {
            style: 'thin',
            color: { argb: '000000' }
          }
        }
      })

      // const priorTotalBlendedRateAllTest = [[0.0394],[0.0694]]
      // const modeTotalBlendedOccLimitTest = [[17500000],[17500000]]
      priorTotalBlendedRateAll.forEach(_ =>
        membersLayers.push([this.quoteParams.members])
      )

      const priorBlendedTotal = this.buildTotalRowByAverage(
        membersLayers,
        priorTotalBlendedRateAll
      )

      const currBlendedTotal = this.buildTotalRowByAverage(
        membersLayers,
        currTotalBlendedRateAll
      )

      const pmPmPriorYoyRateCell = worksheet.getCell(
        pmPmTotalBlendRatesRow.number,
        blendedColumn
      )

      const pmPmCurrYoyRateCell = worksheet.getCell(
        pmPmTotalBlendRatesRow.number,
        blendedColumn + 1
      )

      const pmPmYoyRateChangeCell = worksheet.getCell(
        pmPmTotalBlendRatesRow.number,
        blendedColumn + 2
      )

      pmPmPriorYoyRateCell.value = priorBlendedTotal[0]
      pmPmPriorYoyRateCell.numFmt = `[$${pmpmCurrency}]#,##0`
      pmPmCurrYoyRateCell.value = currBlendedTotal[0]
      pmPmCurrYoyRateCell.numFmt = `[$${pmpmCurrency}]#,##0`

      if (priorBlendedTotal[0] !== 0 && currBlendedTotal[0] !== 0) {
        pmPmYoyRateChangeCell.value = {
          formula: `=${worksheet.getColumn(blendedColumn + 1).letter}${
            pmPmTotalBlendRatesRow.number
          }/${worksheet.getColumn(blendedColumn).letter}${
            pmPmTotalBlendRatesRow.number
          }-1`,
          date1904: false,
        }
        pmPmYoyRateChangeCell.numFmt = '0.00%'
      } else {
        pmPmYoyRateChangeCell.value = 0
        pmPmYoyRateChangeCell.numFmt = '0.00%'
      }
    }

    worksheet.addRow([''])

    // Tracking Module
    const trackingRow = worksheet.addRow(['Subjectivities & Notes'])
    trackingRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '737F8B' },
    }
    trackingRow.getCell(1).font = {
      name: 'Segoe Pro',
      size: 8,
      bold: true,
      color: { argb: 'FFFFFF' },
    }

    // Add tracking column row
    if (this.quoteParams.structureGroupName) {
      this.quoteParams.trackingColumns[1] = 'Group Name'
    }
    if (this.quoteParams.sharedLimitName) {
      this.quoteParams.trackingColumns[1] = 'Shared Limit Name'
    }
    const trackingColumnRow = worksheet.addRow(this.quoteParams.trackingColumns)

    if (this.quoteParams.trackingColumns.length > this.maxRows) {
      this.maxRows = this.quoteParams.trackingColumns.length
    }
    trackingColumnRow.height = 30
    trackingColumnRow.eachCell(cell => {
      cell.alignment = { wrapText: true }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00AEEF' },
      }
      cell.font = {
        name: 'Segoe Pro',
        size: 8,
        bold: true,
        color: { argb: 'FFFFFF'}
      }
    })
    // Merge Cells
    worksheet.mergeCells(
      `A${trackingRow.number}:${this.colName(
        trackingColumnRow.actualCellCount - 1
      )}${trackingRow.number}`
    )

    this.quoteParams.trackingRows1.forEach(row => {
      if (this.quoteParams.structureGroupName) {
        row[1] = this.quoteParams.structureGroupName
      }
      if (this.quoteParams.sharedLimitName) {
        row[1] = this.quoteParams.sharedLimitName
      }
      const DataRow = worksheet.addRow(row)
      DataRow.eachCell(cell => {
        cell.style = {
          alignment: { wrapText: true },
          font: {
            name: 'Segoe Pro',
            size: 8,
          },
          border: {
            bottom: {
              style: 'thin',
              color: { argb: '000000' }
            }
          }
        }
      })
    })

    if (this.maxRows > 0) {
      let count = this.maxRows
      while (count > 0) {
        worksheet.getColumn(count).width = 25
        count--
      }
    }
    worksheet.getColumn(1).width = 30

    const lastrow = worksheet.addRow([''])
    const logoRow = lastrow.number + 3
    worksheet.addImage(this.logo, {
      tl: { col: 5, row: logoRow },
      ext: { width: 120, height: 60 },
      editAs: 'absolute'
    })
    const sageLogoTopLeftCol = 8
    worksheet.addImage(this.sageLogo, {
      tl: { col: sageLogoTopLeftCol, row: logoRow},
      ext: { width: 120, height: 60 },
      editAs: 'absolute'
    })
    const patentLinkRow = logoRow + 4
    const patentLinkCell = worksheet.getCell(patentLinkRow, sageLogoTopLeftCol + 1)
    patentLinkCell.value = {
      text: 'Patent: https://global.lockton.com/re/en/sage-patents',
      hyperlink: 'https://global.lockton.com/re/en/sage-patents'
    }
    patentLinkCell.style = {
      font: {
        name: 'Segoe Pro',
        size: 8,
        underline: true,
        color: { argb: '00AEEF' },
      }
    }
  }

  private addQuotedContractRequestsWorksheet() {
    const worksheet2 = this.workbook.addWorksheet('Quoted Contract Requests', {
      pageSetup: { fitToPage: true },
      views: [{ showGridLines: false }],
    })
    worksheet2.pageSetup.margins = DEFAULT_OPTIONS.margins

    const title1Row2 = worksheet2.addRow([this.titleName])
    title1Row2.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: '00AEEF' }
    }
    const title2Row2 = worksheet2.addRow([
      this.quoteParams.programName + ' Placement',
    ])
    title2Row2.font = {
      name: 'Segoe Pro',
      size: 8,
    }
    const title3Row2 = worksheet2.addRow([this.effectiveDate])
    title3Row2.font = {
      name: 'Segoe Pro',
      size: 8,
      color: { argb: '5b6670' }
    }
    // Blank Row
    worksheet2.addRow([''])
    const title4Row2 = worksheet2.addRow(['Quoted Contract Requests'])
    title4Row2.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: '00AEEF' }
    }
    const dateRow2 = worksheet2.addRow([this.dateAsOf])
    dateRow2.eachCell(c => {
      c.font = {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: '5b6670' }
      }
    })
    // Blank Row
    worksheet2.addRow([''])
    // Tracking Module
    const trackingRow2 = worksheet2.addRow([
      'Contract requests/exclusions and document requests',
    ])
    trackingRow2.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '737F8B' },
    }
    trackingRow2.getCell(1).font = {
      name: 'Segoe Pro',
      size: 8,
      bold: true,
      color: { argb: 'FFFFFF' },
    }
    let maxRows2 = 0
    // Add tracking column row
    const trackingColumnRow2 = worksheet2.addRow(
      this.quoteParams.trackingColumns
    )

    if (this.quoteParams.trackingColumns.length > maxRows2) {
      maxRows2 = this.quoteParams.trackingColumns.length
    }
    trackingColumnRow2.height = 30
    trackingColumnRow2.eachCell(cell => {
      cell.alignment = { wrapText: true }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00AEEF' },
      }
      cell.font = {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: 'FFFFFF'},
        bold: true,
      }
    })
    // Merge Cells
    worksheet2.mergeCells(
      `A${trackingRow2.number}:${this.colName(
        trackingColumnRow2.actualCellCount - 1
      )}${trackingRow2.number}`
    )

    this.quoteParams.trackingRows2.forEach(row => {
      if (this.quoteParams.structureGroupName) {
        row[1] = this.quoteParams.structureGroupName
      }
      if (this.quoteParams.sharedLimitName) {
        row[1] = this.quoteParams.sharedLimitName
      }
      const DataRow = worksheet2.addRow(row)
      DataRow.eachCell(cell => {
        cell.alignment = { wrapText: true }
        cell.font = {
          name: 'Segoe Pro',
          size: 8,
        }
        cell.border = {
          bottom: {
            style: 'thin',
            color: { argb: '000000' }
          }
        }
      })
    })
    if (maxRows2 > 0) {
      let count = maxRows2
      while (count > 0) {
        worksheet2.getColumn(count).width = 20
        count--
      }
    }
    // Blank Row
    worksheet2.addRow([''])
    const lastrow = worksheet2.addRow([''])
    const logoRow = lastrow.number + 3
    worksheet2.addImage(this.logo, {
      tl: { col: 3, row: logoRow },
      ext: { width: 120, height: 60 },
      editAs: 'absolute'
    })
    const sageLogoTopLeftCol = 5
    worksheet2.addImage(this.sageLogo, {
      tl: { col: sageLogoTopLeftCol, row: logoRow},
      ext: { width: 120, height: 60},
      editAs: 'absolute'
    })
    const patentLinkRow = logoRow + 4
    const patentLinkCell = worksheet2.getCell(patentLinkRow, sageLogoTopLeftCol + 1)
    patentLinkCell.value = {
      text: 'Patent: https://global.lockton.com/re/en/sage-patents',
      hyperlink: 'https://global.lockton.com/re/en/sage-patents'
    }
    patentLinkCell.style = {
      font: {
        name: 'Segoe Pro',
        size: 8,
        underline: true,
        color: { argb: '00AEEF' },
      }
    }
  }

  private addAuthAndSignedLinesWorksheet() {
    const worksheet3 = this.workbook.addWorksheet('Auth & Signed Lines', {
      pageSetup: { fitToPage: true },
      views: [{ showGridLines: false }],
    })
    worksheet3.pageSetup.margins = DEFAULT_OPTIONS.margins

    const title1Row3 = worksheet3.addRow([this.titleName])
    title1Row3.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: '00AEEF' }
    }
    const title2Row3 = worksheet3.addRow([
      this.quoteParams.programName + ' Placement',
    ])
    title2Row3.font = {
      name: 'Segoe Pro',
      size: 8,
    }
    const title3Row3 = worksheet3.addRow([this.effectiveDate])
    title3Row3.font = {
      name: 'Segoe Pro',
      size: 8,
      color: { argb: '5b6670' }
    }
    // Blank Row
    worksheet3.addRow([''])
    const title4Row3 = worksheet3.addRow(['Authorizations and Signed Lines'])
    title4Row3.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: '00AEEF' }
    }
    const dateRow3 = worksheet3.addRow([this.dateAsOf])
    dateRow3.eachCell(c => {
      c.font = {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: '5b6670' }
      }
    })
    // Blank Row
    worksheet3.addRow([''])
    let maxRows3 = 0
    // Add Assigned Lined Logic
    const assignedLinesNameRow = worksheet3.addRow(
      this.quoteParams.assignedLinesColumns
    )
    if (this.quoteParams.assignedLinesColumns.length > maxRows3) {
      maxRows3 = this.quoteParams.assignedLinesColumns.length
    }
    const arr: string[] = []
    assignedLinesNameRow.eachCell((cell, i) => {
      const startCol = `${this.colName(i - 1)}`
      const exLengthCol = `${this.colName(i - 1 + 4)}`
      const fotLengthCol = `${this.colName(i - 1 + 11)}`

      if (cell.value !== ' ' && cell.value !== 'fill') {
        if (this.quoteParams.structureGroupName) {
          cell.value = this.quoteParams.structureGroupName + ' ' + cell.value
        }
        if (this.quoteParams.sharedLimitName) {
          cell.value = this.quoteParams.sharedLimitName + ' ' + cell.value
        }
        cell.alignment = { wrapText: true }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '737F8B' },
        }
        cell.font = {
          name: 'Segoe Pro',
          size: 8,
          bold: true,
          color: { argb: 'FFFFFF' },
        }
        if (
          worksheet3.getCell(exLengthCol + assignedLinesNameRow.number)
            .value !== 'fill'
        ) {
          arr.push(
            `${startCol}${assignedLinesNameRow.number}:${this.colName(
              i - 1 + 3
            )}${assignedLinesNameRow.number}`
          )
        }
        if (
          worksheet3.getCell(fotLengthCol + assignedLinesNameRow.number)
            .value !== 'fill'
        ) {
          arr.push(
            `${startCol}${assignedLinesNameRow.number}:${this.colName(
              i - 1 + 10
            )}${assignedLinesNameRow.number}`
          )
        }
      }
    })
    arr.forEach(n => {
      worksheet3.mergeCells(n)
    })

    const rowAL = ['Reinsurer', 'Bureau Stamp', 'TP Ref']
    if (
      this.quoteParams.assignedLinesRows &&
      this.quoteParams.assignedLinesRows.length > 0
    ) {
      this.quoteParams.assignedLinesRows[0].forEach(a => {
        if (a !== ' ' && a.length) {
          switch (a[2]) {
            case 'underwriterRef':
              rowAL.push('Underwriter Ref #')
              break
            case 'written$':
              rowAL.push('$ Written')
              break
            case 'written%':
              rowAL.push('Written %')
              break
            case 'signed$':
              rowAL.push('$ Signed')
              break
            case 'signed%':
              rowAL.push('Signed %')
              break
            case 'recommended%':
              rowAL.push('Recommended %')
              break
            case 'placedThrough':
              rowAL.push('Placed Through')
              break
            case 'coBroker':
              rowAL.push('Correspondent Broker')
              break
            case 'leadMarket':
              rowAL.push('Lead Market')
              break
            case 'brokerage':
              rowAL.push('Brokerage')
              break
            case 'brokerageRe':
              rowAL.push('Brokerage RIP %')
              break
          }
        } else {
          rowAL.push(' ')
        }
      })
    }
    const columnNamesALRow = worksheet3.addRow(rowAL)
    if (rowAL.length > this.maxRows) {
      this.maxRows = rowAL.length
    }
    columnNamesALRow.eachCell(cell => {
      if (cell.value !== ' ') {
        cell.alignment = { horizontal: 'center', wrapText: true }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00AEEF' },
        }
        cell.font = {
          name: 'Segoe Pro',
          size: 8,
          color: { argb: 'FFFFFF'},
          bold: true,
        }
        if (
          cell.value === 'Reinsurer' ||
          cell.value === 'Bureau Stamp' ||
          cell.value === 'TP Ref'
        ) {
          cell.alignment = { horizontal: 'left', wrapText: true }
        }
      }
    })
    // Add table data
    const total: any[] = []
    this.quoteParams.assignedLinesRows = [...this.quoteParams.assignedLinesRows]
    this.quoteParams.assignedLinesRows.sort((a, b) => {
      const i = a.findIndex(e => e[0] === 'Lockton Re')
      const j = b.findIndex(e => e[0] === 'Lockton Re')
      if (
        (i !== -1 && j !== -1 && i < j) ||
        (a[i] && b[i] && a[i][0].localeCompare(b[i][0]) === -1) ||
        (a[i + 1] && b[i + 1] && a[i + 1][0].localeCompare(b[i + 1][0]) === -1)
      ) {
        return -1
      } else {
        return 1
      }
    })
    if (
      this.quoteParams.assignedLinesRows &&
      this.quoteParams.assignedLinesRows.length > 0
    ) {
      this.quoteParams.assignedLinesRows[0].forEach(a1 => {
        if (a1[2] === 'name') {
          total.push('Grand Total')
        } else if (
          a1[2] === 'written%' ||
          a1[2] === 'written$' ||
          a1[2] === 'recommended%' ||
          a1[2] === 'signed%' ||
          a1[2] === 'signed$'
        ) {
          total.push(0)
        } else {
          total.push(' ')
        }
      })
    }

    this.quoteParams.assignedLinesRows.forEach(row => {
      const r: any[] = []
      row?.forEach((r1: any) => {
        if (r1.length && r1 !== ' ') {
          r.push(r1[0])
        } else {
          r.push(' ')
        }
      })
      const DataRow = worksheet3.addRow(r)

      DataRow.eachCell((cell, j) => {
        cell.alignment = { wrapText: true, horizontal: 'center' }
        cell.font = {
          name: 'Segoe Pro',
          size: 8,
        }
        cell.border = {
          bottom: {
            style: 'thin',
            color: { argb: '000000' }
          }
        }
        if (row[j - 1] && row[j - 1][1] === 'percentage') {
          {
            // cell.alignment = { horizontal: 'center' }
            cell.numFmt = '0.0000%'
            if (
              row[j - 1][2] === 'written%' ||
              row[j - 1][2] === 'recommended%' ||
              row[j - 1][2] === 'signed%'
            ) {
              total[j - 1] = total[j - 1] + row[j - 1][0]
            }
          }
        } else if (row[j - 1][1] === 'currency') {
          if (
            cell.value && typeof cell.value === 'number'
              ? cell.value % 1 !== 0
              : false
          ) {
            cell.numFmt = `#,##0.0000`
          } else {
            cell.numFmt = `#,##0`
          }
          if (row[j - 1][2] === 'written$' || row[j - 1][2] === 'signed$') {
            total[j - 1] = total[j - 1] + row[j - 1][0]
          }
        }
        if (j === 1 || j === 2) {
          cell.alignment = { horizontal: 'left', wrapText: true }
        }
      })
    })

    const DataTotalRow = worksheet3.addRow(total)
    DataTotalRow.eachCell((cell, l) => {
      if (rowAL[l - 1] !== ' ') {
        cell.alignment = { wrapText: true, horizontal: 'center' }
        cell.font = {
          name: 'Segoe Pro',
          size: 8,
          bold: true,
          color: { argb: 'FFFFFF' }
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
        }
        if (
          this.quoteParams.assignedLinesRows[0][l - 1] &&
          this.quoteParams.assignedLinesRows[0][l - 1][1] === 'percentage'
        ) {
          {
            // cell.alignment = { horizontal: 'right' }
            cell.numFmt = '0.0000%'
          }
        } else if (
          this.quoteParams.assignedLinesRows[0][l - 1][1] === 'currency'
        ) {
          if (
            cell.value && typeof cell.value === 'number'
              ? cell.value % 1 !== 0
              : false
          ) {
            cell.numFmt = `#,##0.0000`
          } else {
            cell.numFmt = `#,##0`
          }
        }
        if (l === 1) {
          cell.alignment = { horizontal: 'left', wrapText: true }
        }
      }
    })

    worksheet3.addRow([''])
    worksheet3.addRow([''])

    // Assigned Lines Subjectivities
    // const subALHeader = worksheet3.addRow(['Subjectivities'])
    // subALHeader.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: '737F8B' },
    // }
    // subALHeader.getCell(1).font = {
    //   name: 'Segoe Pro',
    //   size: 8,
    //   bold: true,
    //   color: { argb: 'FFFFFF' },
    // }
    // worksheet3.mergeCells(`A${subALHeader.number}:D${subALHeader.number}`)
    // const subALRow = worksheet3.addRow([
    //   'Reinsurer Name',
    //   'Layer',
    //   'FOT Subjectivity',
    //   'Client Accepted',
    // ])
    // subALRow.eachCell(cell => {
    //   cell.alignment = { wrapText: true }
    //   cell.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: '00AEEF' },
    //   }
    //   cell.font = {
    //     name: 'Segoe Pro',
    //     size: 8,
    //   }
    // })
    // assignedLinesSubRows.forEach(row => {
    //   const DataRow = worksheet3.addRow(row)
    //   DataRow.eachCell(cell => {
    //     cell.alignment = { wrapText: true }
    //     cell.font = {
    //       name: 'Segoe Pro',
    //       size: 8,
    //     }
    //   })
    // })

    // Tracking Module
    const trackingRowAL = worksheet3.addRow(['Subjectivities & Notes'])
    trackingRowAL.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '737F8B' },
    }
    trackingRowAL.getCell(1).font = {
      name: 'Segoe Pro',
      size: 8,
      bold: true,
      color: { argb: 'FFFFFF' },
    }
    const aLColumns = clone(this.quoteParams.trackingColumns)
    aLColumns.splice(1, 0, 'TP Ref')
    aLColumns.splice(1, 0, 'Bureau Stamp')
    // Add tracking column row
    const trackingColumnRowAL = worksheet3.addRow(aLColumns)

    if (aLColumns.length > maxRows3) {
      maxRows3 = aLColumns.length
    }
    trackingColumnRowAL.height = 30
    trackingColumnRowAL.eachCell((cell, m) => {
      cell.alignment = { wrapText: true, horizontal: 'center' }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00AEEF' },
      }
      cell.font = {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: 'FFFFFF'},
        bold: true,
      }
      if (m === 1 || m === 2) {
        cell.alignment = { horizontal: 'left', wrapText: true }
      }
    })
    // Merge Cells
    worksheet3.mergeCells(
      `A${trackingRowAL.number}:${this.colName(
        trackingColumnRowAL.actualCellCount - 1
      )}${trackingRowAL.number}`
    )

    this.quoteParams.trackingRowsAL1.forEach(row => {
      if (this.quoteParams.structureGroupName) {
        row[1] = this.quoteParams.structureGroupName
      }
      if (this.quoteParams.sharedLimitName) {
        row[1] = this.quoteParams.sharedLimitName
      }
      const updatedRow = clone(row)
      let found = false
      this.quoteParams.assignedLinesRows.forEach(al => {
        if (al[0] && al[0][0] && row[0].includes(al[0][0])) {
          updatedRow.splice(1, 0, al[2][0])
          updatedRow.splice(1, 0, al[1][0])
          found = true
        }
      })
      if (!found) {
        updatedRow.splice(1, 0, ' ')
        updatedRow.splice(1, 0, ' ')
      }
      const DataRow = worksheet3.addRow(updatedRow)
      DataRow.eachCell((cell, n) => {
        cell.alignment = { wrapText: true, horizontal: 'center' }
        cell.font = {
          name: 'Segoe Pro',
          size: 8,
        }
        if (cell.value !== '') {
          cell.border = {
            bottom: {
              style: 'thin',
              color: { argb: '000000' }
            }
          }
        }
        if (n === 1 || n === 2) {
          cell.alignment = { horizontal: 'left', wrapText: true }
        }
      })
    })

    worksheet3.addRow([''])
    worksheet3.addRow([''])

    if (maxRows3 > 0) {
      let count = maxRows3
      while (count > 0) {
        worksheet3.getColumn(count).width = 20
        count--
      }
    }
    worksheet3.getColumn(1).width = 30
    if (maxRows3 > 0) {
      let count = maxRows3
      while (count > 1) {
        worksheet3.getColumn(count).width = 12.2
        count--
      }
    }

    const lastrow = worksheet3.addRow([''])
    const logoRow = lastrow.number + 3
    worksheet3.addImage(this.logo, {
      tl: { col: 5, row: logoRow },
      ext: { width: 120, height: 60 },
      editAs: 'absolute'
    })
    const sageLogoTopLeftCol = 8
    worksheet3.addImage(this.sageLogo, {
      tl: { col: sageLogoTopLeftCol, row: logoRow},
      ext: { width: 120, height: 60},
      editAs: 'absolute'
    })
    const patentLinkRow = logoRow + 4
    const patentLinkCell = worksheet3.getCell(patentLinkRow, sageLogoTopLeftCol + 1)
    patentLinkCell.value = {
      text: 'Patent: https://global.lockton.com/re/en/sage-patents',
      hyperlink: 'https://global.lockton.com/re/en/sage-patents'
    }
    patentLinkCell.style = {
      font: {
        name: 'Segoe Pro',
        size: 8,
        underline: true,
        color: { argb: '00AEEF' },
      }
    }
  }

  private addFotContractRequestsWorksheet() {
    const worksheet4 = this.workbook.addWorksheet('FOT Contract Requests', {
      pageSetup: { fitToPage: true },
      views: [{ showGridLines: false }],
    })
    worksheet4.pageSetup.margins = DEFAULT_OPTIONS.margins

    const title1Row4 = worksheet4.addRow([this.titleName])
    title1Row4.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: '00AEEF' }
    }
    const title2Row4 = worksheet4.addRow([
      this.quoteParams.programName + ' Placement',
    ])
    title2Row4.font = {
      name: 'Segoe Pro',
      size: 8,
    }
    const title3Row4 = worksheet4.addRow([this.effectiveDate])
    title3Row4.font = {
      name: 'Segoe Pro',
      size: 8,
      color: { argb: '5b6670' }
    }
    // Blank Row
    worksheet4.addRow([''])
    const title4Row4 = worksheet4.addRow(['FOT Contract Requests'])
    title4Row4.font = {
      name: 'Segoe Pro',
      size: 14,
      color: { argb: '00AEEF' }
    }
    const dateRow4 = worksheet4.addRow([this.dateAsOf])
    dateRow4.eachCell(c => {
      c.font = {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: '5b6670' }
      }
    })
    // Blank Row
    worksheet4.addRow([''])

    // Tracking Module
    const trackingRow4 = worksheet4.addRow([
      'Contract requests/exclusions and document requests',
    ])
    trackingRow4.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '737F8B' },
    }
    trackingRow4.getCell(1).font = {
      name: 'Segoe Pro',
      size: 8,
      bold: true,
      color: { argb: 'FFFFFF' },
    }
    let maxRows4 = 0
    // Add tracking column row
    const trackingColALRow4 = [...this.quoteParams.trackingColumns]
    trackingColALRow4.push(
      'Final Contract Changes',
      'Final Signature Page Changes'
    )
    const trackingColumnRow4 = worksheet4.addRow(trackingColALRow4)

    if (trackingColALRow4.length > maxRows4) {
      maxRows4 = trackingColALRow4.length
    }
    trackingColumnRow4.height = 30
    trackingColumnRow4.eachCell(cell => {
      cell.alignment = { wrapText: true }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00AEEF' },
      }
      cell.font = {
        name: 'Segoe Pro',
        size: 8,
        color: { argb: 'FFFFFF'},
        bold: true,
      }
    })
    // Merge Cells
    worksheet4.mergeCells(
      `A${trackingRow4.number}:${this.colName(
        trackingColumnRow4.actualCellCount - 1
      )}${trackingRow4.number}`
    )
    this.quoteParams.trackingRowsAL2.forEach(row => {
      if (this.quoteParams.structureGroupName) {
        row[1] = this.quoteParams.structureGroupName
      }
      if (this.quoteParams.sharedLimitName) {
        row[1] = this.quoteParams.sharedLimitName
      }
      const DataRow = worksheet4.addRow(row)
      DataRow.eachCell(cell => {
        cell.alignment = { wrapText: true }
        cell.font = {
          name: 'Segoe Pro',
          size: 8,
        }
        cell.border = {
          bottom: {
            style: 'thin',

          }
        }
      })
    })
    if (maxRows4 > 0) {
      let count = maxRows4
      while (count > 0) {
        worksheet4.getColumn(count).width = 20
        count--
      }
    }
    // Blank Row
    const lastrow = worksheet4.addRow([''])
    const logoRow = lastrow.number + 3
    worksheet4.addImage(this.logo, {
      tl: { col: 5, row: logoRow },
      ext: { width: 120, height: 60 },
      editAs: 'absolute'
    })
    const sageLogoTopLeftCol = 8
    worksheet4.addImage(this.sageLogo, {
      tl: { col: sageLogoTopLeftCol, row: logoRow},
      ext: { width: 120, height: 60},
      editAs: 'absolute'
    })
    const patentLinkRow = logoRow + 4
    const patentLinkCell = worksheet4.getCell(patentLinkRow, sageLogoTopLeftCol + 1)
    patentLinkCell.value = {
      text: 'Patent: https://global.lockton.com/re/en/sage-patents',
      hyperlink: 'https://global.lockton.com/re/en/sage-patents'
    }
    patentLinkCell.style = {
      font: {
        name: 'Segoe Pro',
        size: 8,
        underline: true,
        color: { argb: '00AEEF' },
      }
    }
  }

  private saveFile() {
    this.workbook.xlsx.writeBuffer().then((data: BlobPart) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, this.fileName)
      const base64FromBlob = (blob1: Blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob1)
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result)
          }
        })
      }
      base64FromBlob(blob).then(base64Response => {
        this.exportService
          .captureExportDetails(
            base64Response,
            this.exportFileName,
            'QuoteSelection'
          )
          .pipe()
          .subscribe()
      })
    })
  }

  private colName(n: number) {
    const ordA = 'A'.charCodeAt(0)
    const ordZ = 'Z'.charCodeAt(0)
    const len = ordZ - ordA + 1

    let s = ''
    while (n >= 0) {
      s = String.fromCharCode((n % len) + ordA) + s
      n = Math.floor(n / len) - 1
    }
    return s
  }

  private mergeDataRows() {
    // TODO: Clean this up (variable names, etc.)
    let dRows: any[][][] = []
    this.quoteParams.descriptionRows.forEach(drow => {
      const r: any[] = []
      drow.forEach((r1: any) => {
        if (r1) {
          r.push([r1])
        }
      })
      dRows.push(r)
    })
    this.quoteParams.expiringReinsurerRows.forEach((expRow, i) => {
      dRows = [...dRows]
      expRow.forEach((r1, j) => {
        if (r1) {
          dRows[i][j].push(...r1)
        }
      })
    })
    this.quoteParams.nonFotAndExpiringRows.forEach((nonRow, i) => {
      dRows = [...dRows]
      nonRow.forEach((r1, j) => {
        if (r1) {
          dRows[i][j].push(...r1)
        }
      })
    })
    return dRows
  }

  private calculatePriorBlendedRate(i: number, metricInd: number): number {
    if (i < 0 || metricInd < 0) {
      return 0
    }

    let metricRow = this.quoteParams.expiringReinsurerRows[i][metricInd]
    if (!metricRow) {
      return 0
    }

    metricRow = metricRow.map((v: any) => parseCurrency(v[0])[1])

    const signedRow: number[] = this.quoteParams.quotesSignedPercRow[i]
    // const metricRowTest = [0.03, 0.03, 0.05]
    // const signedRowTest = [0.1, 0.1277, 0.2]

    const sumProductExpiring = this.SUMPRODUCT(
      (x: number) => x,
      metricRow,
      signedRow
    )

    const sumExpiringPerc: number = signedRow.reduce((tot, curr) => {
      return tot + curr
    }, 0)

    let expiringBlendedRateTotal = 0

    if (sumProductExpiring !== 0 && sumExpiringPerc !== 0) {
      expiringBlendedRateTotal = sumProductExpiring / sumExpiringPerc
    }

    return expiringBlendedRateTotal
  }

  private calculateCurrBlendedRate(i: number, metricInd: number): number {
    if (i < 0 || metricInd < 0) {
      return 0
    }

    let metricRow = this.quoteParams.nonFotAndExpiringRows[i][metricInd]
    if (!metricRow) {
      return 0
    }

    metricRow = metricRow.slice(0, -2).map((v: any) => parseCurrency(v[0])[1])

    const offeredInd = this.quoteParams.descriptionRows[i].findIndex(
      e => e[0] === 'Offered Percentage'
    )
    const offeredPercRow: number[] =
      this.quoteParams.nonFotAndExpiringRows[i][offeredInd]
        ?.slice(0, -2)
        ?.map((e: any) => e[0]) ?? []
    if (offeredPercRow.length === 0) {
      return 0
    }
    // const metricRowTest = [0.06, 0.045, 0.1]
    // const offeredRowTest = [0, 0.45,1]

    const sumProductCurr = this.SUMPRODUCT(
      (x: number) => x,
      metricRow,
      offeredPercRow
    )

    const sumOfferedPerc: number = offeredPercRow.reduce((tot, curr) => {
      return tot + curr
    }, 0)

    let currBlendedRateTotal = 0

    if (sumProductCurr !== 0 && sumOfferedPerc !== 0) {
      currBlendedRateTotal = sumProductCurr / sumOfferedPerc
    }

    return currBlendedRateTotal
  }

  private buildTotalRowBySum(row1: number[][], row2: number[][]) {
    const multiRow: number[][] = []

    row1.forEach((e, idx) => {
      const layerMult: number[] = []

      e.forEach((v, i) => {
        const val = v * (row2[idx] && row2[idx][i] ? row2[idx][i] : 1)
        layerMult.push(val)
      })

      multiRow.push(layerMult)
    })

    const totalSumRow = row2.reduce((tot, curr) => {
      if (curr.length > 0) {
        return curr.map((num, idx) => num + (tot[idx] ?? 0))
      }

      return tot
    })

    const totalMultiRow = multiRow.reduce((tot, curr) => {
      if (curr.length > 0) {
        return curr.map((num, idx) => num + (tot[idx] ?? 0))
      }

      return tot
    })

    const totalBlendedRowFinal = totalMultiRow.map((e, idx) => {
      const sumNum = totalSumRow[idx]
      return e && sumNum ? e / sumNum : 0
    })

    return totalBlendedRowFinal
  }

  private buildTotalRowByAverage(row1: number[][], row2: number[][]) {
    const multiRow: number[][] = []

    row1.forEach((e, idx) => {
      const layerMult: number[] = []

      e.forEach((v, i) => {
        const val = v * (row2[idx] && row2[idx][i] ? row2[idx][i] : 1)
        layerMult.push(val)
      })

      multiRow.push(layerMult)
    })

    const totalSumRow = row2.reduce((tot, curr) => {
      if (curr.length > 0) {
        return curr.map((num, idx) => num + (tot[idx] ?? 0))
      }
      return tot
    })

    const totalAverageRow: number[] = totalSumRow.map(e =>
      row2.length > 0 ? e / row2.length : 0
    )

    const totalMultiRow: number[] = multiRow.reduce((tot, curr) => {
      if (curr.length > 0) {
        const sum = curr.map((num, idx) => num + (tot[idx] ?? 0))
        return sum
      }
      return tot
    })

    const totalBlendedRowFinal: number[] = totalMultiRow.map((e, idx) => {
      const avgNum = totalAverageRow[idx]
      return e && avgNum ? e / avgNum : 0
    })

    return totalBlendedRowFinal
  }

  private SUMPRODUCT(
    callback: (ar1: number, ar2: number) => number,
    ar1: number[],
    ar2: number[]
  ) {
    if (ar1.length !== ar2.length) {
      throw new RangeError()
    }
    let sum = 0
    ar1.forEach((_, i) => {
      if (callback(ar1[i], ar2[i])) {
        sum += ar1[i] * ar2[i]
      }
    })
    return sum
  }

  private MODE(values: Currency[]): Currency {
    if (!values?.length) {
      return ['', 0]
    }

    const count = (array: Currency[], x: Currency) =>
      array.filter(v => v[1] === x[1]).length

    const sorted = values.sort((a, b) => count(values, a) - count(values, b))
    return [...sorted].pop() ?? ['', 0]
  }

  private formatCellAsCurrency(
    currencySymbol: string,
    hasDecimals: boolean
  ): string {
    const decimals = hasDecimals ? '.0000' : ''
    return `${currencySymbol}#,##0${decimals}`
  }

  private removeCurrencySymbolFromCell(cell: Cell): number {
    const parsedCellVal = cell.value?.toString().replace(/,/g, '')
    if (!parsedCellVal) {
      return 0
    }
    // Get numeric value after the currency symbols (e.g. £, $, 'MVR', 'COP')
    const currencyRegex = /[a-zA-Z£€$]+/
    return parseFloat(parsedCellVal.split(currencyRegex)[1])
  }

  // Added Shared Condition for top and drop layers
  private containsOccurrenceLimit(element: string[]) {
    return (
      element[0] === 'Occurrence Limit' ||
      element[0] === 'Shared Per Occurrence Limit' ||
      element[0] === 'Top Occurrence Limit'
    )
  }
}
