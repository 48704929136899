import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { keys, values } from 'ramda'
import { Observable, UnaryFunction, forkJoin, of } from 'rxjs'
import { map, switchMap, withLatestFrom } from 'rxjs/operators'
import { isIndexedLayer } from 'src/app/analysis/layers/indexed-layer'
import { isMultiSectionLayer } from 'src/app/analysis/layers/multi-section-layer'
import { isSwingLayer } from 'src/app/analysis/layers/swing-layer'
import { CapitalMetricsResult } from 'src/app/analysis/model/capital-metric.model'
import { LayerMetrics } from 'src/app/analysis/model/layers-metrics.model'
import {
  convertFromLogicalPortfolioLayers,
  convertToLogicalLayersRequest,
  convertToPhysicalLayersRequest,
  getSources,
} from 'src/app/analysis/model/layers.converter'
import { Layer } from 'src/app/analysis/model/layers.model'
import { findLayerById, isLayerAgg } from 'src/app/analysis/model/layers.util'
import { toPortfolioViewMetrics } from 'src/app/analysis/model/metrics.converter'
import {
  AggregationMethodType,
  Perspective,
  VaRTVaR,
} from 'src/app/analysis/model/metrics.model'
import { MiscMetricsResult } from 'src/app/analysis/model/misc-metrics.model'
import { constructPortfolioRequest } from 'src/app/analysis/model/portfolio-converter'
import { PortfolioMetrics } from 'src/app/analysis/model/portfolio-metrics.model'
import { VolatilityMetricsResult } from 'src/app/analysis/model/volatility-metrics.model'
import { LayerState } from 'src/app/analysis/store/ceded-layers/layers.reducer'
import {
  calculateCapitalMetrics,
  calculateLayerViewMetrics,
  calculateVolatilityMetrics,
  getLayerFromLayerType,
  getLayerLimit,
  reverseRecord,
} from 'src/app/analysis/store/metrics/calculations'
import { PortfolioViewMetricsAndAction } from 'src/app/analysis/store/metrics/portfolio-metrics.effects'
import { LayersType } from 'src/app/api/analyzere/analyzere-batch.models'
import {
  AllPortfolioDetailMetrics,
  AllPortfolioDetailMetricsCompare,
  AnalysisProfile,
  Currencies,
  Data,
  EventCatalog,
  ExceedanceProbability,
  LayerMetricsResponse,
  LayerMetricsResponseBase,
  LayerViewRequest,
  LayerViewResponse,
  LoadedLossSet,
  LogicalPortfolioLayer,
  LossFilterResponse,
  LossSet,
  LossSetLayer,
  Metrics,
  PhysicalPortfolioLayer,
  Portfolio,
  PortfolioDetailMetrics,
  PortfolioViewRequest,
  PortfolioViewResponse,
  PortfolioViewsResponse,
  PortfolioViewsResponseCompare,
  Simulation,
  Update,
  asRef,
} from 'src/app/api/analyzere/analyzere.model'
import { ApiResponse, MaybeData, MaybeError } from 'src/app/api/model/api.model'
import { StructureLayerDataResponse } from 'src/app/api/model/backend.model'
import {
  catchAndHandleError,
  executeSequentiallyInGroup,
  mapResponse,
  mapToMaybeData,
  mergeApiResponses,
} from 'src/app/api/util'
import { LossSetTableResponse } from 'src/app/core/model/loss-set-table.model'
import { AppState } from 'src/app/core/store'
import { selectCurrentLossFilters } from '../../core/store/broker/broker.selectors'
import { TechnicalFactors } from 'src/app/pricingcurve/model/pricing-curve.model'
import { environment } from 'src/environments/environment'

export type HttpOptions = Parameters<HttpClient['post']>[2]

// tslint:disable: no-non-null-assertion
@Injectable({
  providedIn: 'root',
})
export class AnalyzreService {
  private httpOptions = {
    headers: new HttpHeaders({
      // 'Accept-Encoding': 'gzip, compress, br',
      'Content-Type': 'application/json',
      References: 'Expand',
    }),
  }

  constructor(private httpClient: HttpClient, private store: Store<AppState>) {}

  fetchAnalysisProfile(
    analysisProfileId: string
  ): ApiResponse<AnalysisProfile> {
    const url = `${environment.api.base}${environment.api.analysisProfiles}/${analysisProfileId}`
    return this.httpClient
      .get<AnalysisProfile>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Analysis Profile'))
  }

  fetchExchangeRateCurrencies(
    exchangeRateTableId: string
  ): ApiResponse<Currencies> {
    const url = `${environment.api.base}${environment.api.exchangeRateCurrencies}/${exchangeRateTableId}/currencies`
    return this.httpClient
      .get<Currencies>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Currency List'))
  }

  fetchSimulation(simulationId: string): ApiResponse<Simulation> {
    const url = `${environment.api.base}${environment.api.simulations}/${simulationId}`
    return this.httpClient
      .get<Simulation>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Simulation'))
  }

  fetchPortfolioViewYELT(viewId: string): ApiResponse<string> {
    const url = `${environment.api.base}${environment.api.portfolioViewPost}/${viewId}/yelt`
    return this.httpClient
      .get(url, { responseType: 'text' })
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Portfolio YELT'))
  }

  fetchLayerViewYELT(viewId: string): ApiResponse<string> {
    const url = `${environment.api.base}${environment.api.layerViewsPost}/${viewId}/yelt`
    return this.httpClient
      .get(url, { responseType: 'text' })
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Layer YELT'))
  }

  fetchContent(id: string): ApiResponse<string> {
    const url = `${environment.api.base}/uploads${environment.api.files}/${id}`
    return this.httpClient
      .get(url, { responseType: 'text' })
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Content'))
  }

  postData(content: string, name: string): ApiResponse<Data> {
    const url = `${environment.api.base}${environment.api.files}`
    const formData: FormData = new FormData()
    formData.append('content', new File([content], name))
    return this.httpClient
      .post<Data>(url, formData, {
        headers: new HttpHeaders({ References: 'Expand' }),
      })
      .pipe(mapToMaybeData(), catchAndHandleError('Post Data'))
  }

  fetchData(id: string): ApiResponse<Data> {
    const url = `${environment.api.base}${environment.api.files}/${id}`
    return this.httpClient
      .get<Data>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Data'))
  }

  postEventCatalog(
    eventCatalog: Partial<EventCatalog>
  ): ApiResponse<EventCatalog> {
    const url = `${environment.api.base}${environment.api.eventCatalogs}`
    return this.httpClient
      .post<EventCatalog>(url, eventCatalog, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Post Event Catalog'))
  }

  fetchEventCatalog(id: string): ApiResponse<EventCatalog> {
    const url = `${environment.api.base}${environment.api.eventCatalogs}/${id}`
    return this.httpClient
      .get<EventCatalog>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Event Catalog'))
  }

  postLossSet(lossSet: Partial<LossSet>): ApiResponse<LossSet> {
    const url = `${environment.api.base}${environment.api.lossSets}`
    return this.httpClient
      .post<LossSet>(url, lossSet, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Post LossSet'))
  }

  postLoadedLossSet(
    lossSet: Partial<LoadedLossSet>
  ): ApiResponse<LoadedLossSet> {
    const url = `${environment.api.base}${environment.api.lossSets}`
    return this.httpClient
      .post<LoadedLossSet>(url, lossSet, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Post LossSet'))
  }

  fetchLossSet(id: string): ApiResponse<LossSet> {
    const url = `${environment.api.base}${environment.api.lossSets}/${id}`
    return this.httpClient
      .get<LossSet>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch LossSet'))
  }

  fetchPortfolio(id: string): ApiResponse<Portfolio> {
    const url = `${environment.api.base}${environment.api.portfolios}/${id}`
    return this.httpClient
      .get<Portfolio>(url, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Portfolio'))
  }

  fetchPortfolios(ids: string[]): ApiResponse<Portfolio[]> {
    const actions: ApiResponse<Portfolio>[] = []
    ids.forEach(id => {
      actions.push(this.fetchPortfolio(id))
    })
    return forkJoin(actions).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  fetchPortfoliosWithAggFeederAndRiskVisible(
    ids: string[]
  ): ApiResponse<Portfolio[]> {
    const actions: ApiResponse<Portfolio>[] = []
    ids.forEach(id => {
      actions.push(
        this.fetchPortfolio(id).pipe(this.appendAggFeederAndRiskVisible())
      )
    })
    return forkJoin(actions).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  fetchPortfolioWithAggFeederAndRiskVisible(
    id: string
  ): ApiResponse<Portfolio> {
    return this.fetchPortfolio(id).pipe(this.appendAggFeederAndRiskVisible())
  }

  appendAggFeederAndRiskVisible(): UnaryFunction<
    Observable<MaybeData<Portfolio> & MaybeError>,
    Observable<MaybeData<Portfolio> & MaybeError>
  > {
    return switchMap(res => {
      if (res.error) {
        return of({ error: res.error })
      } else {
        const ids: string[] = []

        // tslint:disable: no-non-null-assertion
        const portfolio = res.data as Portfolio
        const portfolioLayers = portfolio.layers as LogicalPortfolioLayer[]

        const cededLayers = convertFromLogicalPortfolioLayers(portfolioLayers)

        const aggFeederLayerID = cededLayers.find(l => isLayerAgg(l))
          ?.layerRefs[0]
        if (aggFeederLayerID) {
          ids.push(aggFeederLayerID)
        }

        portfolioLayers.forEach(layer => {
          const meta = layer.meta_data
          if (meta.isRiskFinal && meta.riskVisibleLayerID) {
            ids.push(meta.riskVisibleLayerID)
          } else if (
            isIndexedLayer(layer, 'main-layer') &&
            meta.visible_layer_id
          ) {
            ids.push(meta.visible_layer_id)
          } else if (
            isSwingLayer(layer, 'combined-layer') &&
            meta.visible_layer_id
          ) {
            ids.push(meta.visible_layer_id)
          } else if (
            isMultiSectionLayer(layer, 'main-layer') &&
            meta.visible_layer_id
          ) {
            ids.push(meta.visible_layer_id)
          }
        })

        if (ids.length > 0) {
          return this.fetchLayers(ids).pipe(
            map(layerRes => {
              if (layerRes.error) {
                return { error: res.error }
              } else {
                const layers = [...res.data!.layers]
                layerRes.data!.forEach((l: LogicalPortfolioLayer) => {
                  layers.push(l)
                })
                return {
                  data: {
                    ...res.data!,
                    layers,
                  },
                } as MaybeData<Portfolio> & MaybeError
              }
            })
          )
        } else {
          return of(res)
        }
      }
    })
  }

  postPortfolio(portfolio: Portfolio): ApiResponse<Portfolio> {
    const url = `${environment.api.base}${environment.api.portfolios}`
    return this.httpClient
      .post<Portfolio>(url, portfolio, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Post Portfolio'))
  }

  patchPortfolio(update: Update<Portfolio>): ApiResponse<Portfolio> {
    const url = `${environment.api.base}${environment.api.portfolios}/${update.id}`
    return this.httpClient
      .patch<Portfolio>(url, update.change, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Patch Portfolio'))
  }

  postPhysicalPortfolioLayer(
    layer: PhysicalPortfolioLayer
  ): ApiResponse<PhysicalPortfolioLayer> {
    return this.postLayer(layer)
  }

  postPhysicalPortfolioLayers(
    layers: PhysicalPortfolioLayer[]
  ): ApiResponse<Array<PhysicalPortfolioLayer>> {
    const observables = []
    for (const layer of layers) {
      observables.push(this.postPhysicalPortfolioLayer(layer))
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }

        return { data: results.map(r => r.data!) }
      })
    )
  }

  postLogicalPortfolioLayers(
    layers: Partial<LogicalPortfolioLayer>[]
  ): ApiResponse<Array<LogicalPortfolioLayer>> {
    const observables = []
    for (const layer of layers) {
      observables.push(this.postLogicalPortfolioLayer(layer))
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  patchLogicalPortfolioLayers(
    updates: Update<LogicalPortfolioLayer>[]
  ): ApiResponse<Array<LogicalPortfolioLayer>> {
    const observables = []
    for (const update of updates) {
      observables.push(this.patchLogicalPortfolioLayer(update))
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  patchPhysicalPortfolioLayers(
    updates: Update<PhysicalPortfolioLayer>[]
  ): ApiResponse<Array<PhysicalPortfolioLayer>> {
    const observables = []
    for (const update of updates) {
      if (update.id !== '') {
        observables.push(this.patchPhysicalPortfolioLayer(update))
      }
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  postLogicalPortfolioLayer(
    layer: Partial<LogicalPortfolioLayer>
  ): ApiResponse<LogicalPortfolioLayer> {
    return this.postLayer(layer)
  }

  patchLogicalPortfolioLayer(
    update: Update<LogicalPortfolioLayer>
  ): ApiResponse<LogicalPortfolioLayer> {
    return this.patchLayer(update)
  }

  patchPhysicalPortfolioLayer(
    update: Update<PhysicalPortfolioLayer>
  ): ApiResponse<PhysicalPortfolioLayer> {
    return this.patchLayer(update)
  }

  patchLossSetLayer(update: Update<LossSetLayer>): ApiResponse<LossSetLayer> {
    return this.patchLayer(update)
  }

  patchLayer<T extends LayersType>(update: Update<T>): ApiResponse<T> {
    const url = `${environment.api.base}${environment.api.layers}/${update.id}`
    return this.httpClient
      .patch<T>(url, update.change, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Patch Layer'))
  }

  postLayer<T extends LayersType>(
    layer: Partial<T>,
    httpOptions?: HttpOptions
  ): ApiResponse<T> {
    const url = `${environment.api.base}${environment.api.layers}`
    return this.httpClient
      .post<T>(url, layer, { ...this.httpOptions, ...httpOptions })
      .pipe(mapToMaybeData(), catchAndHandleError('Post Layer'))
  }

  postLayers<T extends LayersType>(
    layers: Partial<T>[]
  ): ApiResponse<Array<T>> {
    const observables: ApiResponse<T>[] = []
    for (const layer of layers) {
      observables.push(this.postLayer(layer))
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  postLossSetLayer(layer: Partial<LossSetLayer>): ApiResponse<LossSetLayer> {
    return this.postLayer(layer)
  }

  postLossSetLayers(
    layers: Partial<LossSetLayer>[]
  ): ApiResponse<Array<LossSetLayer>> {
    const observables = []
    for (const layer of layers) {
      observables.push(this.postLossSetLayer(layer))
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  // fetchLayers<T extends LayersType>(ids: string[]): ApiResponse<T[]> {
  //   const url = `${environment.api.base}${
  //     environment.api.batchLayers
  //   }${ids.join(',')}`
  //   return this.httpClient
  //     .get<BatchPortfolioLayer<T>>(url, this.httpOptions)
  //     .pipe(
  //       map(res => {
  //         return res.items.map(item => item)
  //       }),
  //       mapToMaybeData(),
  //       catchAndHandleError('Fetch Layers')
  //     )
  // }

  fetchLayer<T extends LayersType>(
    id: string,
    httpOptions?: HttpOptions
  ): ApiResponse<T> {
    const url = `${environment.api.base}${environment.api.layers}/${id}`
    return this.httpClient
      .get<T>(url, { ...this.httpOptions, ...httpOptions })
      .pipe(mapToMaybeData(), catchAndHandleError('Fetch Layer'))
  }

  fetchLayers<T extends LayersType>(
    ids: string[],
    httpOptions?: HttpOptions
  ): ApiResponse<T[]> {
    const actions: ApiResponse<T>[] = []

    for (const id of ids) {
      actions.push(this.fetchLayer<T>(id, httpOptions))
    }

    return forkJoin(actions).pipe(
      map(responses => {
        for (const response of responses) {
          if (response.error) {
            return { error: response.error }
          }
        }
        const data: T[] = responses.map(r => r.data!)
        return { data }
      })
    )
  }

  postLayerViewInline<T extends LogicalPortfolioLayer>(
    layer: Partial<T>,
    analysisProfile: string,
    currency?: string
  ): ApiResponse<LayerViewResponse> {
    const url = `${environment.api.base}${environment.api.layerViewsPost}`
    const request: LayerViewRequest = {
      analysis_profile: asRef(analysisProfile),
      layer: {
        ...layer,
      },
    }
    if (currency) {
      request.target_currency = currency
    }
    return this.httpClient
      .post<LayerViewResponse>(url, request, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Post Layer View'))
  }

  postLayerView(
    layerID: string,
    analysisProfileID?: string,
    currency?: string
  ): ApiResponse<LayerViewResponse & { layerID: string }> {
    const url = `${environment.api.base}${environment.api.layerViewsPost}`
    const request: LayerViewRequest = {
      analysis_profile: {
        ref_id: analysisProfileID,
      },
      layer: {
        ref_id: layerID,
      },
    }
    if (currency) {
      request.target_currency = currency
    }
    return this.httpClient
      .post<LayerViewResponse>(url, request, this.httpOptions)
      .pipe(
        map(res => ({ ...res, layerID })),
        mapToMaybeData(),
        catchAndHandleError('Post Layer View')
      )
  }

  postPortfolioViewAdhoc(
    layerViewIDs: string[],
    analysisProfileID: string
  ): ApiResponse<string> {
    const url = `${environment.api.base}${environment.api.portfolioViewPost}`
    const request: PortfolioViewRequest = {
      analysis_profile: {
        ref_id: analysisProfileID,
      },
      layer_views: layerViewIDs.map(id => ({ ref_id: id })),
    }
    return this.httpClient
      .post<PortfolioViewResponse>(url, request, this.httpOptions)
      .pipe(
        map(res => {
          return res.id
        }),
        mapToMaybeData(),
        catchAndHandleError('Post Portfolio View')
      )
  }

  postLayerViewAdhoc(
    layer: Layer,
    referenceID: string,
    analysisProfileID: string
  ): ApiResponse<LayerViewResponse & { layerID: string }> {
    const url = `${environment.api.base}${environment.api.layerViewsPost}`
    const request: LayerViewRequest = {
      analysis_profile: {
        ref_id: analysisProfileID,
      },
      layer: {
        ...convertToLogicalLayersRequest(layer, ''),
        sink: { ...convertToPhysicalLayersRequest(layer.physicalLayer) } as any,
      },
    }
    return this.httpClient
      .post<LayerViewResponse>(url, request, this.httpOptions)
      .pipe(
        map(res => ({ ...res, layerID: referenceID })),
        mapToMaybeData(),
        catchAndHandleError('Post Layer View')
      )
  }

  postMultiLayerViewAdhoc(
    layers: Layer[],
    referenceIDs: string[],
    analysisProfileID: string
  ): ApiResponse<Array<LayerViewResponse & { layerID: string }>> {
    const actions = []
    for (let i = 0; i < layers.length; i++) {
      actions.push(
        this.postLayerViewAdhoc(layers[i], referenceIDs[i], analysisProfileID)
      )
    }
    return executeSequentiallyInGroup(actions, 250)
  }

  postMultiLayerView(
    layerID: string[],
    analysisProfileID: string,
    lobsID?: number[],
    currency?: string
  ): ApiResponse<Record<string, LossSetTableResponse>> {
    const observables: Array<ApiResponse<LossSetTableResponse>> = []
    for (const [i, id] of layerID.entries()) {
      if (lobsID) {
        observables.push(this.postLayerViewID(id, analysisProfileID, lobsID[i]))
      } else if (currency) {
        observables.push(
          this.postLayerViewID(id, analysisProfileID, undefined, currency)
        )
      } else {
        observables.push(this.postLayerViewID(id, analysisProfileID))
      }
    }
    if (observables.length === 0) {
      return of({ data: {} })
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        const record = results.reduce(
          // tslint:disable-next-line: no-non-null-assertion
          (acc, current, index) => ({
            ...acc,
            [layerID[index]]: current.data!,
          }),
          {} as Record<string, LossSetTableResponse>
        )
        return { data: record }
      })
    )
  }

  postLayerViewID(
    layerID: string,
    analysisProfileID: string,
    lobID?: number,
    currency?: string
  ): ApiResponse<LossSetTableResponse> {
    const url = `${environment.api.base}${environment.api.layerViewsPost}`
    const request: LayerViewRequest = {
      analysis_profile: {
        ref_id: analysisProfileID,
      },
      layer: {
        ref_id: layerID,
      },
    }
    if (currency) {
      request.target_currency = currency
    }
    return this.httpClient
      .post<LayerViewResponse>(url, request, this.httpOptions)
      .pipe(
        map(res => {
          if (lobID) {
            return { ...res, layerID, lobID }
          } else {
            return { ...res, layerID }
          }
        }),
        mapToMaybeData(),
        catchAndHandleError('Post Layer View ID')
      )
  }

  postLayersViews(
    layerIDs: string[],
    analysisProfileID: string,
    currency?: string[]
  ): ApiResponse<Array<LayerViewResponse & { layerID: string }>> {
    const actions = []
    let curr = ''
    let singleCurrency = false
    if (currency && currency.length === 1 && currency[0]) {
      singleCurrency = true
      curr = currency[0]
    }
    for (let i = 0; i < layerIDs.length; i++) {
      if (currency && currency[i] && !singleCurrency) {
        curr = currency[i]
      }
      actions.push(
        this.postLayerView(
          layerIDs[i],
          analysisProfileID,
          curr !== '' ? curr : undefined
        )
      )
    }
    return forkJoin(actions).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  postLayersViewsForManyIds(
    analysisProfileIDMapping: Record<string, string[]>
  ): ApiResponse<Array<Array<LayerViewResponse & { layerID: string }>>> {
    const actions = Object.entries(analysisProfileIDMapping).map(
      ([analysisProfileID, layerIDs]) =>
        this.postLayersViews(layerIDs, analysisProfileID)
    )
    return forkJoin(actions).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  getGrossPortfolioView(
    grossPortfolioID: string,
    analysisProfileID: string
  ): ApiResponse<string> {
    const url = `${environment.api.base}${environment.api.portfolioViewPost}`
    const request: PortfolioViewRequest = {
      analysis_profile: {
        ref_id: analysisProfileID,
      },
      portfolio: {
        ref_id: grossPortfolioID,
      },
    }

    return this.httpClient
      .post<PortfolioViewResponse>(url, request, this.httpOptions)
      .pipe(
        map(res => {
          return res.id
        }),
        mapToMaybeData(),
        catchAndHandleError('Post Portfolio View')
      )
  }

  postPortfolioView(
    portfolioID: string,
    grossPortfolioID: string,
    netPortfolioID: string,
    analysisProfileID: string,
    currency?: string
  ): ApiResponse<PortfolioViewsResponse> {
    const url = `${environment.api.base}${environment.api.portfolioViewPost}`
    const requests: PortfolioViewRequest[] = [
      {
        analysis_profile: {
          ref_id: analysisProfileID,
        },
        portfolio: {
          ref_id: portfolioID,
        },
      },
      {
        analysis_profile: {
          ref_id: analysisProfileID,
        },
        portfolio: {
          ref_id: grossPortfolioID,
        },
      },
      {
        analysis_profile: {
          ref_id: analysisProfileID,
        },
        portfolio: {
          ref_id: netPortfolioID,
        },
      },
    ]
    // Set target currency if it exists
    if (currency) {
      for (const request of requests) {
        request.target_currency = currency
      }
    }
    const observables: Array<ApiResponse<PortfolioViewResponse>> = []
    for (const request of requests) {
      observables.push(
        this.httpClient
          .post<PortfolioViewResponse>(url, request, this.httpOptions)
          .pipe(mapToMaybeData(), catchAndHandleError('Post Portfolio View'))
      )
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return {
          data: {
            cededPortfolioView: results[0].data,
            grossPortfolioView: results[1].data,
            netPortfolioView: results[2].data,
          },
        }
      })
    ) as ApiResponse<PortfolioViewsResponse>
  }

  postPortfolioViewCompare(
    portfolioID: string,
    grossPortfolioID: string,
    netPortfolioID: string,
    analysisProfileID: string,
    id: string
  ): ApiResponse<PortfolioViewsResponseCompare> {
    const url = `${environment.api.base}${environment.api.portfolioViewPost}`
    const requests: PortfolioViewRequest[] = [
      {
        analysis_profile: {
          ref_id: analysisProfileID,
        },
        portfolio: {
          ref_id: portfolioID,
        },
      },
      {
        analysis_profile: {
          ref_id: analysisProfileID,
        },
        portfolio: {
          ref_id: grossPortfolioID,
        },
      },
      {
        analysis_profile: {
          ref_id: analysisProfileID,
        },
        portfolio: {
          ref_id: netPortfolioID,
        },
      },
    ]
    const observables: Array<ApiResponse<PortfolioViewResponse>> = []
    for (const request of requests) {
      observables.push(
        this.httpClient
          .post<PortfolioViewResponse>(url, request, this.httpOptions)
          .pipe(mapToMaybeData(), catchAndHandleError('Post Portfolio View'))
      )
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return {
          data: {
            cededPortfolioView: results[0].data,
            grossPortfolioView: results[1].data,
            netPortfolioView: results[2].data,
            id,
          },
        }
      })
    ) as ApiResponse<PortfolioViewsResponseCompare>
  }

  getAllPortfolioDetailViewMetrics(
    cededPortfolioViewID: string,
    grossPortfolioViewID: string,
    netPortfolioViewID: string
  ): ApiResponse<AllPortfolioDetailMetrics> {
    const ids = [cededPortfolioViewID, grossPortfolioViewID, netPortfolioViewID]
    const observables: Array<ApiResponse<PortfolioDetailMetrics>> = []
    for (const id of ids) {
      observables.push(
        this.getPortfolioDetailViewMetrics(id, grossPortfolioViewID)
      )
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        // tslint:disable: no-non-null-assertion
        return {
          data: {
            cededPortfolioViewDetailMetrics: results[0].data!,
            grossPortfolioViewDetailMetrics: results[1].data!,
            netPortfolioViewDetailMetrics: results[2].data!,
          },
        }
      })
    )
  }

  getAllPortfolioDetailViewMetricsCompare(
    cededPortfolioViewID: string,
    grossPortfolioViewID: string,
    netPortfolioViewID: string,
    EntityID: string
  ): ApiResponse<AllPortfolioDetailMetricsCompare> {
    const ids = [cededPortfolioViewID, grossPortfolioViewID, netPortfolioViewID]
    const observables: Array<ApiResponse<PortfolioDetailMetrics>> = []
    for (const id of ids) {
      observables.push(
        this.getPortfolioDetailViewMetrics(id, grossPortfolioViewID)
      )
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        // tslint:disable: no-non-null-assertion
        return {
          data: {
            cededPortfolioViewDetailMetrics: results[0].data!,
            grossPortfolioViewDetailMetrics: results[1].data!,
            netPortfolioViewDetailMetrics: results[2].data!,
            id: EntityID,
          },
        }
      })
    )
  }

  getPortfolioDetailViewMetrics(
    portfolioViewID: string,
    grossPortfolioViewID: string
  ): ApiResponse<PortfolioDetailMetrics> {
    const fetchName = 'Get Portfolio View Detail Metrics'
    const cfg = environment.api.metrics.portfolioView
    const baseUrl = `${environment.api.base}${cfg.base}/${portfolioViewID}`
    return this.forkJoinUrlMap<PortfolioDetailMetrics>(baseUrl, fetchName, {
      lossNetAggregateTermAEP: `${cfg.detail.lossNetAggregateTermAEP}`,
      lossNetPremiumReinstatementAggregateTermAEP: `${cfg.detail.lossNetPremiumReinstatementAggregateTermAEP}`,
      lossNetAggregateTermsPremiumReinstatementAEP: `${cfg.detail.lossNetAggregateTermsPremiumReinstatementAEP}`,
      reinstatementPremiumAEP: `${cfg.detail.reinstatementPremiumAEP}`,
      lossNetOfAggregateTermsAEPReportingPeriod: `${cfg.detail.lossNetOfAggregateTermsAEPReportingPeriod}`,
      lossNetAggregateTermsPremiumReinstatementAEPParticipation: `${cfg.detail.lossNetAggregateTermsPremiumReinstatementAEPParticipation}`,
      lossNetAggregateTermAEPParticipation: `${cfg.detail.lossNetAggregateTermAEPParticipation}`,
      grossCovariance: {
        fullUrl: `${environment.api.base}${cfg.base}/${grossPortfolioViewID}/co_metrics/1?component_id=${portfolioViewID}&component_type=PortfolioView&apply_participation=true`,
      },
      premiumAEP: `${cfg.detail.premiumAEP}`,
    })
  }

  getGroupAndComponentVolatility(
    viewID: string,
    grossPortfolioViewID: string
  ): ApiResponse<any> {
    const fetchName = 'Get Loss Set Volatility'
    const cfg = environment.api.metrics.portfolioView
    const baseUrl = `${environment.api.base}${cfg.base}/${viewID}`
    return this.forkJoinUrlMap<any>(baseUrl, fetchName, {
      groupComponentCovariance: {
        fullUrl: `${environment.api.base}${cfg.base}/${grossPortfolioViewID}/co_metrics/0.5?component_id=${viewID}&component_type=PortfolioView&perspective=NetLoss`
      },
      groupCovariance: `/tail_metrics/0.5?perspective=NetLoss`,
      typeExpectedLoss: environment.api.metrics.layersView.expectedCededLossBaseNoParticipation
    })
  }

  getMultiPortfolioViewMetrics(
    ids: string[],
    aggregationMethod: AggregationMethodType,
    // tslint:disable-next-line: max-line-length
    perspective: Perspective,
    lossFilter: string,
    returnPeriod1: number,
    returnPeriod2: number,
    returnPeriod3: number,
    returnPeriod4: number,
    returnPeriod5: number
  ): ApiResponse<Record<string, Metrics[]>> {
    const observables: Array<ApiResponse<Metrics[]>> = []
    for (const id of ids) {
      observables.push(
        this.getPortfolioViewMetrics(
          id,
          aggregationMethod,
          perspective,
          lossFilter,
          returnPeriod1,
          returnPeriod2,
          returnPeriod3,
          returnPeriod4,
          returnPeriod5
        )
      )
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        const record = results.reduce(
          // tslint:disable-next-line: no-non-null-assertion
          (acc, current, index) => ({ ...acc, [ids[index]]: current.data! }),
          {} as Record<string, Metrics[]>
        )
        return { data: record }
      })
    )
  }

  getPortfolioViewMetrics(
    id: string,
    aggregationMethod: AggregationMethodType,
    perspective: Perspective,
    lossFilter: string,
    returnPeriod1: number,
    returnPeriod2: number,
    returnPeriod3: number,
    returnPeriod4: number,
    returnPeriod5: number
  ): ApiResponse<Metrics[]> {
    // tslint:disable-next-line: max-line-length
    const url =
      `${environment.api.base}${environment.api.metrics.portfolioView.base}/${id}${environment.api.metrics.portfolioView.url}`
        .replace(
          '{perspective}',
          perspective === 'Loss'
            ? 'NetLoss'
            : perspective === 'LossRp'
            ? 'NetLoss,ReinstatementPremium'
            : 'Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus'
        )
        // Loss Filter applied only if perspective = Loss or LossRP
        .replace(
          '{lossFilter}',
          perspective === 'Loss' || perspective === 'LossRp'
            ? `&filter=${lossFilter}`
            : ''
        )
        .replace('{aggregation}', aggregationMethod)
        .replace('{returnPeriod1}', returnPeriod1 + '')
        .replace('{returnPeriod2}', returnPeriod2 + '')
        .replace('{returnPeriod3}', returnPeriod3 + '')
        .replace('{returnPeriod4}', returnPeriod4 + '')
        .replace('{returnPeriod5}', returnPeriod5 + '')

    return this.httpClient
      .get(url, { ...this.httpOptions, responseType: 'text' })
      .pipe(
        // tslint:disable-next-line: no-eval
        map(text => eval(`(${text})`)),
        mapToMaybeData(),
        catchAndHandleError('Get Portfolio View Metrics')
      )
  }

  getMultiLayerRPViewMetrics(
    ids: string[],
    aggregationMethod: AggregationMethodType,
    // tslint:disable-next-line: max-line-length
    perspective: Perspective,
    returnPeriod1: number,
    returnPeriod2: number,
    returnPeriod3: number,
    returnPeriod4: number,
    returnPeriod5: number
  ): ApiResponse<Record<string, Metrics[]>> {
    const observables: Array<ApiResponse<Metrics[]>> = []
    for (const id of ids) {
      observables.push(
        this.getLayerRPViewMetrics(
          id,
          aggregationMethod,
          perspective,
          returnPeriod1,
          returnPeriod2,
          returnPeriod3,
          returnPeriod4,
          returnPeriod5
        )
      )
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        const record = results.reduce(
          // tslint:disable-next-line: no-non-null-assertion
          (acc, current, index) => ({ ...acc, [ids[index]]: current.data! }),
          {} as Record<string, Metrics[]>
        )
        return { data: record }
      })
    )
  }

  getLayerRPViewMetrics(
    id: string,
    aggregationMethod: AggregationMethodType,
    perspective: Perspective,
    returnPeriod1: number,
    returnPeriod2: number,
    returnPeriod3: number,
    returnPeriod4: number,
    returnPeriod5: number
  ): ApiResponse<Metrics[]> {
    // tslint:disable-next-line: max-line-length
    const url =
      `${environment.api.base}${environment.api.metrics.layersView.base}/${id}${environment.api.metrics.layersView.url}`
        .replace(
          '{perspective}',
          perspective === 'Loss'
            ? 'NetLoss'
            : perspective === 'LossRp'
            ? 'NetLoss,ReinstatementPremium'
            : 'Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus'
        )
        .replace('{aggregation}', aggregationMethod)
        .replace('{returnPeriod1}', returnPeriod1 + '')
        .replace('{returnPeriod2}', returnPeriod2 + '')
        .replace('{returnPeriod3}', returnPeriod3 + '')
        .replace('{returnPeriod4}', returnPeriod4 + '')
        .replace('{returnPeriod5}', returnPeriod5 + '')

    return this.httpClient
      .get(url, { ...this.httpOptions, responseType: 'text' })
      .pipe(
        // tslint:disable-next-line: no-eval
        map(text => eval(`(${text})`)),
        mapToMaybeData(),
        catchAndHandleError('Get Layer RP View Metrics')
      )
  }

  getManyLayersViewViewMetricsAndCalculate(
    viewIDs: string[],
    grossViewID: string | null | undefined,
    layersViewIDs: Record<string, string>,
    layersByID: Record<string, LayerState | undefined>,
    layers: LayerState[],
    isSL: boolean,
    lossFilter?: string,
    sharedLimitSelectedLayerID?: string,
    defaultTechnicalFactors?: TechnicalFactors,
    yearUpdate?: number,
    methodUpdate?: string
  ): ApiResponse<LayerMetrics[]> {
    const observables: Array<ApiResponse<LayerMetrics>> = viewIDs.map(viewID =>
      this.getLayersViewViewMetricsAndCalculate(
        viewID,
        grossViewID,
        layersViewIDs,
        layersByID,
        layers,
        isSL,
        lossFilter,
        sharedLimitSelectedLayerID,
        defaultTechnicalFactors,
        yearUpdate,
        methodUpdate
      )
    )
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  getLayersViewViewMetricsAndCalculate(
    viewID: string,
    grossViewID: string | null | undefined,
    layersViewIDs: Record<string, string>,
    layersByID: Record<string, LayerState | undefined>,
    layers: LayerState[],
    isSL: boolean,
    lossFilter?: string,
    sharedLimitSelectedLayerID?: string,
    defaultTechnicalFactors?: TechnicalFactors,
    yearUpdate?: number,
    methodUpdate?: string
  ): ApiResponse<LayerMetrics> {
    const reversedRecord = reverseRecord(layersViewIDs)
    // tslint:disable: no-non-null-assertion
    const selectedLayerID = reversedRecord[viewID]
    let layerState: LayerState
    if (layersByID[selectedLayerID]) {
      layerState = layersByID[selectedLayerID]!
    } else {
      layerState = layers.find(
        l => l.layer.physicalLayer.id === selectedLayerID
      )!
    }

    let layer = getLayerFromLayerType(layerState, layersByID)
    if (isSwingLayer(layer, 'combined-layer')) {
      layer =
        findLayerById(
          layers.map(ls => ls.layer),
          layer.meta_data.visible_layer_id
        ) ?? layer
    }

    const limit = getLayerLimit(layer, layersByID, 'Occ')
    const aggLimit = getLayerLimit(layer, layersByID, 'Agg')

    if (isSL) {
      viewID =
        layerState.layer.meta_data.sage_layer_type === 'shared_limits'
          ? layersViewIDs[layerState.layer.id]
          : viewID
    } else {
      viewID = layerState.layer.meta_data.backAllocatedForID
        ? layersViewIDs[layerState.layer.id]
        : viewID
    }
    const previousViewId = viewID // saving the previousViewId to update it to the original viewId after successful response
    if (
      layerState.layer.meta_data.sage_layer_type === 'cat_td' ||
      layerState.layer.meta_data.sage_layer_type === 'drop'
    ) {
      const actualLayerState = layers.find(
        l => l.layer.meta_data.sage_layer_subtype === 'actual'
      )
      if (actualLayerState) {
        viewID = layersViewIDs[actualLayerState.layer.id] // Updating the view id to the hidden layer viewId
      }
    }

    return this.getLayersViewMetrics(
      viewID,
      limit,
      aggLimit,
      grossViewID,
      lossFilter,
      yearUpdate,
      methodUpdate,
      defaultTechnicalFactors,
      layer.meta_data.sage_layer_type
    ).pipe(
      switchMap(res => {
        if (res.error) {
          return of({ error: res.error })
        } else {
          let physicalLayer
          if (isSL) {
            physicalLayer =
              layerState.layer.meta_data.sage_layer_type === 'shared_limits'
                ? layerState.layer.physicalLayer
                : undefined
          } else {
            physicalLayer = layerState.layer.meta_data.backAllocatedForID
              ? layerState.layer.physicalLayer
              : undefined
          }
          const data = calculateLayerViewMetrics(
            res.data!,
            layersViewIDs,
            layersByID,
            physicalLayer,
            isSL,
            sharedLimitSelectedLayerID
          )
          if (
            layerState.layer.meta_data.sage_layer_type === 'cat_td' ||
            layerState.layer.meta_data.sage_layer_type === 'drop'
          ) {
            data.id = previousViewId
          }
          return of({ data })
        }
      })
    )
  }

  getLayersViewMetrics(
    viewID: string,
    limit: number,
    aggLimit: number,
    grossPortfolioViewID?: string | null,
    lossFilter?: string,
    yearUpdate?: number,
    methodUpdate?: string,
    defaultTechnicalFactors?: TechnicalFactors,
    layerType?: string
  ): ApiResponse<LayerMetricsResponse> {
    const op = 'Get Layer View Metrics'
    const cfg = environment.api.metrics.layersView
    const baseUrl = `${environment.api.base}${cfg.base}/${viewID}`
    const aepBaseYear = yearUpdate ? (1 / yearUpdate).toString() : '0.01'
    const aepBaseMethod = methodUpdate ? methodUpdate : 'AEP'
    const qsMethod = layerType?.includes('qs') ? 'OEP' : 'AEP'
    let grossCovariance: { fullUrl: string } | string | undefined
    let cededYearValue: string | undefined
    if (grossPortfolioViewID) {
      const base = `${environment.api.base}${environment.api.metrics.portfolioView.base}`
      const query = `?component_id=${viewID}&component_type=LayerView&apply_participation=true`
      grossCovariance = {
        fullUrl: `${base}/${grossPortfolioViewID}/co_metrics/1${query}`,
      }
    }
    if (defaultTechnicalFactors) {
      const volatilityMetric = defaultTechnicalFactors.volatility_metric
      if (volatilityMetric) {
        const parts = volatilityMetric.split(' ')
        const year = Number(parts[1]) ? Number(parts[1]) : 250
        cededYearValue = `${cfg.cededYearValue}`.replace(
          '{yearParam}',
          (1 / year).toString()
        )
      }
    } else {
      const year = 250
      cededYearValue = `${cfg.cededYearValue}`.replace(
        '{yearParam}',
        (1 / year).toString()
      )
    }
    return this.forkJoinUrlMap<LayerMetricsResponseBase>(baseUrl, op, {
      depositPremium: `${cfg.depositPremium}`,
      expectedCededPremiumBase: `${this.manipulateAPI(
        cfg.expectedCededPremiumBase,
        lossFilter
      )}`,
      expectedCededLossBase: `${this.manipulateAPI(
        cfg.expectedCededLossBase,
        lossFilter
      )}`,
      expectedCededExpensesBase: `${this.manipulateAPI(
        cfg.expectedCededExpensesBase,
        lossFilter
      )}`,
      expectedCededMarginBase: `${this.manipulateAPI(
        cfg.expectedCededMarginBase
      )}`,
      aepBase: `${cfg.aepBase}`
        .replace('{yearParam}', aepBaseYear)
        .replace('{methodParam}', aepBaseMethod)
        .toString(),
      oepWindowVarBase: `${cfg.oepWindowVarBase}`,
      aepWindowVarBase: `${cfg.aepWindowVarBase}`,
      entryProbability: `${cfg.probability}`
        .replace('{param}', '0.01')
        .toString(),
      exitProbability: `${cfg.probability}&aggregation_method=${qsMethod}&`
        .replace('{param}', limit - 1 + '')
        .toString(),
      exitAggProbability: `${cfg.probability}&aggregation_method=${qsMethod}&`
        .replace('{param}', aggLimit - 1 + '')
        .toString(),
      grossCovariance,
      expectedCededExpensesBaseNoParticipation: `${this.manipulateAPI(
        cfg.expectedCededExpensesBaseNoParticipation,
        lossFilter
      )}`,
      expectedCededLossBaseNoParticipation: `${this.manipulateAPI(
        cfg.expectedCededLossBaseNoParticipation,
        lossFilter
      )}`,
      cededYearValue,
    }).pipe(
      map(res => {
        let data: LayerMetricsResponse | undefined
        if (res.data) {
          data = { ...res.data, id: viewID }
        }
        return { error: res.error, data }
      })
    )
  }

  manipulateAPI(API: string, lossFilter?: string): string {
    if (lossFilter) {
      API = API.concat('&filter=' + lossFilter)
    }
    return API
  }

  createPhysicalLayer(layer: Layer): ApiResponse<PhysicalPortfolioLayer> {
    const url = `${environment.api.base}${environment.api.layers}`
    return this.httpClient
      .post<PhysicalPortfolioLayer>(
        url,
        convertToPhysicalLayersRequest(layer.physicalLayer),
        this.httpOptions
      )
      .pipe(mapToMaybeData(), catchAndHandleError('Create Physical Layer'))
  }

  createPhysicalLayers(
    layers: Layer[]
  ): ApiResponse<Array<PhysicalPortfolioLayer>> {
    if (layers.length === 0) {
      return of({ data: [] })
    }
    const observables = []
    for (const layer of layers) {
      observables.push(this.createPhysicalLayer(layer))
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  createLogicalLayers(
    layers: Layer[],
    physicalLayerIDs: string[],
    layerData: StructureLayerDataResponse[]
  ): ApiResponse<Array<LogicalPortfolioLayer>> {
    if (layers.length === 0) {
      return of({ data: [] })
    }
    const observables: Array<ApiResponse<LogicalPortfolioLayer>> = []
    layers.forEach((layer, index) => {
      const layerD = layerData.find(
        item =>
          item.layer_id ===
          (isLayerAgg(layer) ? 'LAgg' : 'LOcc') + layer.physicalLayer.id
      )
      if (layerD) {
        observables.push(
          this.createLogicalLayerWithLayerData(
            layer,
            physicalLayerIDs[index],
            layerD
          )
        )
      } else {
        observables.push(
          this.createLogicalLayer(layer, physicalLayerIDs[index])
        )
      }
    })
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        return { data: results.map(r => r.data!) }
      })
    )
  }

  createLogicalLayerWithLayerData(
    layer: Layer,
    physicalLayerID: string,
    layerData: StructureLayerDataResponse
  ): ApiResponse<LogicalPortfolioLayer> {
    const url = `${environment.api.base}${environment.api.layers}`
    return this.httpClient
      .post<LogicalPortfolioLayer>(
        url,
        convertToLogicalLayersRequest(layer, physicalLayerID),
        this.httpOptions
      )
      .pipe(
        map(response => {
          response.startingCession = layerData.starting_cession
          response.color = layerData.color
          return {
            data: response,
          }
        }),
        catchAndHandleError('Create Logical Layer')
      )
  }

  createLogicalLayer(
    layer: Layer,
    physicalLayerID: string
  ): ApiResponse<LogicalPortfolioLayer> {
    const url = `${environment.api.base}${environment.api.layers}`
    return this.httpClient
      .post<LogicalPortfolioLayer>(
        url,
        convertToLogicalLayersRequest(layer, physicalLayerID),
        this.httpOptions
      )
      .pipe(mapToMaybeData(), catchAndHandleError('Create Logical Layer'))
  }

  updatePortfolioLayers(
    portfolioID: string,
    layersIDs: string[]
  ): ApiResponse<Portfolio> {
    const url = `${environment.api.base}${environment.api.portfolios}`
    return this.httpClient
      .patch<Portfolio>(
        `${url}/${portfolioID}`,
        constructPortfolioRequest(layersIDs),
        this.httpOptions
      )
      .pipe(mapToMaybeData(), catchAndHandleError('Update Portfolio Layers'))
  }

  createFullLayer(layer: Layer): ApiResponse<LogicalPortfolioLayer> {
    return this.createPhysicalLayer(layer).pipe(
      switchMap(result => {
        if (result.error) {
          return of({
            error: result.error,
          }) as ApiResponse<LogicalPortfolioLayer>
        } else {
          // tslint:disable-next-line: no-non-null-assertion
          return this.createLogicalLayer(layer, result.data!.id)
        }
      })
    )
  }

  createFullLayers(layers: Layer[]): ApiResponse<LogicalPortfolioLayer[]> {
    const actions: Array<ApiResponse<LogicalPortfolioLayer>> = layers.map(l =>
      this.createFullLayer(l)
    )
    return forkJoin(actions).pipe(
      map(responses => {
        const data: LogicalPortfolioLayer[] = []
        for (const response of responses) {
          if (response.error) {
            return { error: response.error }
          } else {
            data.push(response.data as LogicalPortfolioLayer)
          }
        }
        return { data }
      })
    )
  }

  createLayersForPortfolio(
    layers: Layer[],
    existingLayers: string[],
    portfolioID: string
  ): ApiResponse<{ layersIDs: string[] }> {
    const observables: Array<ApiResponse<LogicalPortfolioLayer>> = []
    for (const layer of layers) {
      observables.push(
        this.createPhysicalLayer(layer).pipe(
          switchMap(result => {
            if (result.error) {
              return of({
                error: result.error,
              }) as ApiResponse<LogicalPortfolioLayer>
            } else {
              // tslint:disable-next-line: no-non-null-assertion
              return this.createLogicalLayer(layer, result.data!.id)
            }
          })
        )
      )
    }
    return forkJoin(observables).pipe(
      switchMap(results => {
        for (const result of results) {
          if (result.error) {
            return of({ error: result.error })
          }
        }
        // tslint:disable-next-line: no-non-null-assertion
        const newLayersID = results.map(result => result.data!.id)
        return this.updatePortfolioLayers(portfolioID, [
          ...existingLayers,
          ...newLayersID,
        ]).pipe(
          map(response => {
            if (response.error) {
              return { error: response.error }
            } else {
              return {
                data: { layersIDs: newLayersID },
              }
            }
          })
        )
      })
    )
  }

  createLossFilters<T extends LossFilterResponse>(
    lossFilters: Partial<T>[]
  ): ApiResponse<Array<T>> {
    if (lossFilters.length === 0) {
      return of({ data: [] })
    }

    const observables = []
    for (const lossFilter of lossFilters) {
      observables.push(this.createLossFilter(lossFilter))
    }

    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }

        return { data: results.map(r => r.data!) }
      })
    )
  }

  createLossFilter<T extends LossFilterResponse>(
    lossFilter: Partial<T>
  ): ApiResponse<T> {
    const url = `${environment.api.base}${environment.api.lossFilters}`

    return this.httpClient
      .post<T>(url, lossFilter, this.httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('Post LossFilter'))
  }

  saveLayers<T extends LayersType>(layers: Layer[]): ApiResponse<T[]> {
    const url = `${environment.api.base}${environment.api.layers}`
    const observables: Array<ApiResponse<T>> = []

    for (const layer of layers) {
      observables.push(
        this.httpClient
          .patch<T>(
            `${url}/${layer.physicalLayer.id}`,
            convertToPhysicalLayersRequest(layer.physicalLayer),
            this.httpOptions
          )
          .pipe(
            mapToMaybeData(),
            catchAndHandleError('Save Physical Layer'),
            switchMap((result: MaybeData<T> & MaybeError) => {
              if (result.error) {
                return of({ error: result.error }) as ApiResponse<T>
              } else {
                if (layer.meta_data.isRiskLargeHidden) {
                  return of({})
                } else {
                  return this.httpClient
                    .patch(
                      `${url}/${layer.id}`,
                      {
                        sources: getSources(layer),
                        meta_data: layer.meta_data,
                      },
                      this.httpOptions
                    )
                    .pipe(
                      mapToMaybeData(),
                      catchAndHandleError('Save Logical Layer LossSet Layers')
                    ) as ApiResponse<T>
                }
              }
            })
          )
      )
    }
    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return {
              error: result.error,
            }
          } else {
            return {
              data: results.map(r => r.data),
            }
          }
        }
      })
    ) as ApiResponse<T[]>
  }

  getMultiPortfolioViewMetricsAAL(
    ids: string[],
    lossSetLayerIDs: string[]
  ): ApiResponse<Record<string, Metrics>> {
    const observables: Array<ApiResponse<Metrics>> = []
    for (const id of ids) {
      observables.push(this.getPortfolioViewMetricsAAL(id))
    }

    return forkJoin(observables).pipe(
      map(results => {
        for (const result of results) {
          if (result.error) {
            return { error: result.error }
          }
        }
        const record = results.reduce(
          // tslint:disable-next-line: no-non-null-assertion
          (acc, current, index) => ({
            ...acc,
            [lossSetLayerIDs[index]]: current.data!,
          }),
          {} as Record<string, Metrics>
        )
        return { data: record }
      })
    )
  }

  getPortfolioViewMetricsAAL(id: string): ApiResponse<Metrics> {
    // tslint:disable-next-line: max-line-length
    const url = `${environment.api.base}${environment.api.metrics.layersView.base}/${id}${environment.api.metrics.layersView.expectedCededLossBase}`
    return this.httpClient
      .get(url, { ...this.httpOptions, responseType: 'text' })
      .pipe(
        // tslint:disable-next-line: no-eval
        map(text => eval(`(${text})`)),
        mapToMaybeData(),
        catchAndHandleError('Get Portfolio View AAL Metrics')
      )
  }

  getMeanLossMetrics(layerViewID: string): ApiResponse<Metrics> {
    const url = `${environment.api.base}${environment.api.metrics.layersView.base}/${layerViewID}${environment.api.metrics.layersView.expectedCededLossBaseNoParticipation}`
    return this.httpClient
      .get(url, { ...this.httpOptions, responseType: 'text' })
      .pipe(
        // tslint:disable-next-line: no-eval
        map(text => eval(`(${text})`)),
        mapToMaybeData(),
        catchAndHandleError('Get Layer Mean Loss Metrics')
      )
  }

  getMetrics(url: string): ApiResponse<Array<Metrics> | Metrics> {
    return this.httpClient
      .get(url, {
        ...this.httpOptions,
        responseType: 'text',
      })
      .pipe(
        // tslint:disable-next-line: no-eval
        map(text => eval(`(${text})`)),
        mapToMaybeData(),
        catchAndHandleError('Get View Metrics')
      )
  }

  getCapitalMetrics(
    cededPortfolioViewID: string,
    grossPortfolioViewID: string,
    netPortfolioViewID: string,
    expectedCededPremium: number,
    expectedCededLoss: number,
    spPremiumValue: number,
    spReserveValue: number,
    spDivesificationValue: number,
    spCatValue: number,
    bcarPremiumValue: number,
    bcarReserveValue: number,
    bcarDivesificationValue: number,
    spCapitalYear: number
  ): ApiResponse<CapitalMetricsResult> {
    const cededAEPPURL =
      `${environment.api.base}${environment.api.metrics.portfolioView.base}/${cededPortfolioViewID}${environment.api.metrics.portfolioView.capital.lossNetAggregateTermAEP}`.replace(
        '{year}',
        (1 / spCapitalYear).toString()
      )
    const cededOEPURL = `${environment.api.base}${environment.api.metrics.portfolioView.base}/${cededPortfolioViewID}${environment.api.metrics.portfolioView.capital.lossNetAggregateTermOEP}`
    const grossAEPURL =
      `${environment.api.base}${environment.api.metrics.portfolioView.base}/${grossPortfolioViewID}${environment.api.metrics.portfolioView.capital.lossNetAggregateTermAEP}`.replace(
        '{year}',
        (1 / spCapitalYear).toString()
      )
    const grossOEPURL = `${environment.api.base}${environment.api.metrics.portfolioView.base}/${grossPortfolioViewID}${environment.api.metrics.portfolioView.capital.lossNetAggregateTermOEP}`
    const netAEPURL =
      `${environment.api.base}${environment.api.metrics.portfolioView.base}/${netPortfolioViewID}${environment.api.metrics.portfolioView.capital.lossNetAggregateTermAEP}`.replace(
        '{year}',
        (1 / spCapitalYear).toString()
      )
    const netOEPURL = `${environment.api.base}${environment.api.metrics.portfolioView.base}/${netPortfolioViewID}${environment.api.metrics.portfolioView.capital.lossNetAggregateTermOEP}`
    const actions: Array<ApiResponse<Metrics>> = [
      this.getMetrics(cededAEPPURL) as ApiResponse<Metrics>,
      this.getMetrics(cededOEPURL) as ApiResponse<Metrics>,
      this.getMetrics(grossAEPURL) as ApiResponse<Metrics>,
      this.getMetrics(grossOEPURL) as ApiResponse<Metrics>,
      this.getMetrics(netAEPURL) as ApiResponse<Metrics>,
      this.getMetrics(netOEPURL) as ApiResponse<Metrics>,
    ]
    return forkJoin(actions).pipe(
      withLatestFrom(this.store.pipe(select(selectCurrentLossFilters))),
      map(
        ([
          [
            cededAEPMetrics,
            cededOEPMetrics,
            grossAEPMetrics,
            grossOEPMetrics,
            netAEPMetrics,
            netOEPMetrics,
          ],
          lossFilters,
        ]) => {
          if (lossFilters?.filter(lf => lf.name === 'cat').length === 0) {
            // No cat loss filter in this analysis profile
            // Pass in zero for AEP/OEP response if cat filter doesn't exist
            const zeroMetric: Metrics = {
              _type: '',
              kurtosis: 0,
              max: 0,
              mean: 0,
              min: 0,
              skewness: 0,
              variance: 0,
              context: undefined,
            }
            return {
              data: calculateCapitalMetrics(
                zeroMetric,
                zeroMetric,
                zeroMetric,
                zeroMetric,
                zeroMetric,
                zeroMetric,
                expectedCededPremium,
                expectedCededLoss,
                spPremiumValue,
                spReserveValue,
                spDivesificationValue,
                spCatValue,
                bcarPremiumValue,
                bcarReserveValue,
                bcarDivesificationValue
              ),
            }
          } else if (
            cededAEPMetrics.error ||
            cededOEPMetrics.error ||
            grossAEPMetrics.error ||
            grossOEPMetrics.error ||
            netAEPMetrics.error ||
            netOEPMetrics.error
          ) {
            const error =
              cededAEPMetrics.error ||
              cededOEPMetrics.error ||
              grossAEPMetrics.error ||
              grossOEPMetrics.error ||
              netAEPMetrics.error ||
              netOEPMetrics.error
            return { error }
          } else {
            return {
              data: calculateCapitalMetrics(
                cededAEPMetrics.data!,
                cededOEPMetrics.data!,
                grossAEPMetrics.data!,
                grossOEPMetrics.data!,
                netAEPMetrics.data!,
                netOEPMetrics.data!,
                expectedCededPremium,
                expectedCededLoss,
                spPremiumValue,
                spReserveValue,
                spDivesificationValue,
                spCatValue,
                bcarPremiumValue,
                bcarReserveValue,
                bcarDivesificationValue
              ),
            }
          }
        }
      )
    )
  }

  getVolatilityMetrics(
    cededPortfolioViewID: string,
    grossPortfolioViewID: string,
    netPortfolioViewID: string,
    year1: number,
    year2: number
  ): ApiResponse<VolatilityMetricsResult> {
    const cededURL =
      `${environment.api.base}${environment.api.metrics.portfolioView.base}/${cededPortfolioViewID}${environment.api.metrics.portfolioView.volatility.lossNetAggregateTermAEP}`
        .replace('{year1}', (1 / year1).toString())
        .replace('{year2}', (1 / year2).toString())
    const grossURL =
      `${environment.api.base}${environment.api.metrics.portfolioView.base}/${grossPortfolioViewID}${environment.api.metrics.portfolioView.volatility.lossNetAggregateTermAEP}`
        .replace('{year1}', (1 / year1).toString())
        .replace('{year2}', (1 / year2).toString())
    const netURL =
      `${environment.api.base}${environment.api.metrics.portfolioView.base}/${netPortfolioViewID}${environment.api.metrics.portfolioView.volatility.lossNetAggregateTermAEP}`
        .replace('{year1}', (1 / year1).toString())
        .replace('{year2}', (1 / year2).toString())
    const standardDev = `${environment.api.base}${environment.api.metrics.portfolioView.base}/${netPortfolioViewID}${environment.api.metrics.portfolioView.volatility.netStandardDeviation}`

    const actions: Array<ApiResponse<Array<Metrics>>> = [
      this.getMetrics(cededURL) as ApiResponse<Array<Metrics>>,
      this.getMetrics(grossURL) as ApiResponse<Array<Metrics>>,
      this.getMetrics(netURL) as ApiResponse<Array<Metrics>>,
      this.getMetrics(standardDev) as ApiResponse<Array<Metrics>>,
    ]
    return forkJoin(actions).pipe(
      map(([cededMetrics, grossMetrics, netMetrics, standardDevMetrics]) => {
        if (
          cededMetrics.error ||
          grossMetrics.error ||
          netMetrics.error ||
          standardDevMetrics.error
        ) {
          const error =
            cededMetrics.error ||
            grossMetrics.error ||
            netMetrics.error ||
            standardDevMetrics.error
          return { error }
        } else {
          return {
            data: calculateVolatilityMetrics(
              cededMetrics.data!,
              grossMetrics.data!,
              netMetrics.data!,
              standardDevMetrics.data![0]
            ),
          }
        }
      })
    )
  }

  getEfficiencyVolatility(cededPortfolioViewID: string): ApiResponse<Metrics> {
    const url = `${environment.api.base}${environment.api.metrics.portfolioView.base}/${cededPortfolioViewID}${environment.api.metrics.portfolioView.efficiency.volatility}`
    return this.getMetrics(url) as ApiResponse<Metrics>
  }

  getExceedanceMetrics(url: string): ApiResponse<ExceedanceProbability> {
    return this.httpClient
      .get(url, {
        ...this.httpOptions,
        responseType: 'text',
      })
      .pipe(
        // tslint:disable-next-line: no-eval
        map(text => eval(`(${text})`)),
        mapToMaybeData(),
        catchAndHandleError('Get View Exceedance Metrics')
      )
  }

  getMiscMetrics(
    cededPortfolioViewID: string,
    id: string,
    aggregationMethod: AggregationMethodType,
    perspective: Perspective,
    threshold: string,
    lossFilter: string
  ): ApiResponse<MiscMetricsResult> {
    const attachOccURL = `${environment.api.base}${environment.api.metrics.portfolioView.base}/${cededPortfolioViewID}${environment.api.metrics.portfolioView.misc.programAttachOcc}`
    const attachAggURL = `${environment.api.base}${environment.api.metrics.portfolioView.base}/${cededPortfolioViewID}${environment.api.metrics.portfolioView.misc.programAttachAgg}`
    const probabilityMetricURL =
      `${environment.api.base}${environment.api.metrics.portfolioView.base}/${id}${environment.api.metrics.portfolioView.misc.probabilityMetric}`
        .replace(
          '{perspective}',
          perspective === 'Loss'
            ? 'NetLoss'
            : perspective === 'LossRp'
            ? 'NetLoss,ReinstatementPremium'
            : 'Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus'
        )
        .replace(
          '{lossFilter}',
          perspective === 'Loss' || perspective === 'LossRp'
            ? `&filter=${lossFilter}`
            : ''
        )
        .replace('{aggregation}', aggregationMethod)
        .replace('{threshold}', threshold.toString())

    const actions: Array<ApiResponse<ExceedanceProbability>> = [
      this.getExceedanceMetrics(
        attachOccURL
      ) as ApiResponse<ExceedanceProbability>,
      this.getExceedanceMetrics(
        attachAggURL
      ) as ApiResponse<ExceedanceProbability>,
      this.getExceedanceMetrics(
        probabilityMetricURL
      ) as ApiResponse<ExceedanceProbability>,
    ]
    return forkJoin(actions).pipe(
      map(([attachOcc, attachAgg, probabilityMetric]) => {
        if (attachOcc.error || attachAgg.error || probabilityMetric.error) {
          const error =
            attachOcc.error || attachAgg.error || probabilityMetric.error
          return { error }
        } else {
          return {
            data: {
              programAttachOcc: attachOcc.data!.probability,
              programAttachAgg: attachAgg.data!.probability,
              probabilityMetric: probabilityMetric.data!.probability,
            },
          }
        }
      })
    )
  }

  getTailMetrics(
    id: string,
    aggregationMethod: AggregationMethodType,
    perspective: Perspective,
    year: number,
    lossFilter: string
  ): ApiResponse<Metrics> {
    const url =
      `${environment.api.base}${environment.api.metrics.portfolioView.base}/${id}${environment.api.metrics.portfolioView.tail.url}`
        .replace(
          '{perspective}',
          perspective === 'Loss'
            ? 'NetLoss'
            : perspective === 'LossRp'
            ? 'NetLoss,ReinstatementPremium'
            : 'Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus'
        )
        // Loss Filter applied only if perspective = Loss or LossRp
        .replace(
          '{lossFilter}',
          perspective === 'Loss' || perspective === 'LossRp'
            ? `&filter=${lossFilter}`
            : ''
        )
        .replace('{aggregation}', aggregationMethod)
        .replace('{year}', (1 / year).toString())
    return this.httpClient
      .get(url, { ...this.httpOptions, responseType: 'text' })
      .pipe(
        // tslint:disable-next-line: no-eval
        map(text => eval(`(${text})`)),
        mapToMaybeData(),
        catchAndHandleError('Get Customize Metrics')
      )
  }

  getExpensePremium(
    id: string,
    lossType: string
  ): ApiResponse<any> {
    let url = `${environment.api.base}${environment.api.metrics.layersView.base}/${id}${environment.api.metrics.layersView.exploreExpense}`
    if (lossType === 'Group') {
      url = `${environment.api.base}${environment.api.metrics.portfolioView.base}/${id}${environment.api.metrics.layersView.exploreExpense}`
    }
    return this.httpClient
      .get(url, this.httpOptions)
      .pipe(
        map(res => res),
        mapToMaybeData(),
        catchAndHandleError('Get Expense Premium for Layer: ' + id)
      )
  }

  getExpectedNetLoss(
    id: string,
    lossType: string
  ): ApiResponse<any> {
    let url = `${environment.api.base}${environment.api.metrics.layersView.base}/${id}${environment.api.metrics.layersView.expectedCededLossBaseNoParticipation}`
    if (lossType === 'Group') {
      url = `${environment.api.base}${environment.api.metrics.portfolioView.base}/${id}${environment.api.metrics.layersView.expectedCededLossBaseNoParticipation}`
    }
    return this.httpClient
      .get(url, this.httpOptions)
      .pipe(
        map(res => res),
        mapToMaybeData(),
        catchAndHandleError('Get Expense Premium for Layer: ' + id)
      )
  }

  /* Defalut Table Values */

  getExploreDataValues(
    viewID: string,
    rpArray: number[],
    lossType: string,
    perspective: string,
    aggregationMethod: string,
    vartvar: VaRTVaR,
    lossFilter: string,
    lossSetID: string,
    lossName: string,
    isLossRatioView: boolean,
    subjectPremiumAmt: number
  ): ApiResponse<
    Metrics[] & {
      lossSetID: string
      lossType: string
      lossName: string
      rpArray: number[]
      vartvar: VaRTVaR
      lossFilter: string
      isLossRatioView: boolean
      subjectPremiumAmt: number
      aggregationMethod: string
    }
  > {
    let url = `${environment.api.base}${environment.api.metrics.layersView.base}/${viewID}${environment.api.metrics.layersView.explore}`
    if (lossType === 'Group') {
      url = `${environment.api.base}${environment.api.metrics.portfolioView.base}/${viewID}${environment.api.metrics.portfolioView.detail.explore}`
    }
    const arrRP: number[] = []
    rpArray.forEach(rp => {
      if (
        perspective === 'Loss' ||
        perspective === 'LossRP'
      ) {
        arrRP.push(1 / rp)
      } else {
        arrRP.push(1 - (1 / rp))
      }
    })
    url = url
      .replace('{returnPeriods}', arrRP.toString())
      .replace(
        '{perspective}',
        perspective === 'Loss'
          ? 'NetLoss'
          : perspective === 'LossRp'
          ? 'NetLoss,ReinstatementPremium'
          : 'NetLoss,ReinstatementPremium,ReinstatementBrokerage,Premium,FixedExpense,ProportionalExpense,ProfitCommission'
      )
      // Loss Filter applied only if perspective = Loss or LossRp
      .replace(
        '{lossFilter}',
        perspective === 'Loss' || perspective === 'LossRp'
          ? `&filter=${lossFilter}`
          : ''
      )
      .replace('{aggregation}', aggregationMethod)

    return this.httpClient
      .get(url, { ...this.httpOptions, responseType: 'text' })
      .pipe(
        // tslint:disable-next-line: no-eval
        map(text => eval(`(${text})`)),
        map(res => ({
          ...res,
          lossSetID,
          lossType,
          lossName,
          rpArray,
          vartvar,
          lossFilter,
          isLossRatioView,
          subjectPremiumAmt,
          aggregationMethod,
        })),
        mapToMaybeData(),
        catchAndHandleError('Get Explore Data Value')
      )
  }

  getLossSetGroupMetrics(
    lossSetGroupId: string,
    layerViewIDs: string[],
    analysisProfileID: string,
    aggregationMethod: AggregationMethodType,
    perspective: Perspective,
    lossFilter: string,
    returnPeriod1: number,
    returnPeriod2: number,
    returnPeriod3: number
  ): ApiResponse<Record<string, PortfolioMetrics>> {
    return this.postPortfolioViewAdhoc(layerViewIDs, analysisProfileID).pipe(
      switchMap(response => {
        if (response.error) {
          return of({ error: response.error })
        }
        return this.getMultiPortfolioViewMetrics(
          [response.data || ''],
          aggregationMethod,
          perspective,
          lossFilter,
          this.transformReturnPeriod(returnPeriod1),
          this.transformReturnPeriod(returnPeriod2),
          this.transformReturnPeriod(returnPeriod3),
          1,
          1
        ).pipe(
          map(r => ({
            ...r,
            id: response.data,
          })),
          map(res => {
            if (res.error) {
              return { error: res.error }
            } else {
              const metricsAndAction = {
                aggregationMethod,
                perspective,
                portfolioType: 'Gross',
                lossFilter,
                returnPeriod1,
                returnPeriod2,
                returnPeriod3,
                returnPeriod4: 1,
                returnPeriod5: 1,
                metrics: res.data,
              } as PortfolioViewMetricsAndAction

              const metrics = toPortfolioViewMetrics(
                metricsAndAction,
                response.data || '',
                '',
                ''
              )

              metrics.returnPeriodData = metrics.returnPeriodData.map(r => {
                return {
                  ...r,
                  value: r.mean,
                }
              })

              return {
                data: {
                  [lossSetGroupId]: metrics,
                } as Record<string, PortfolioMetrics>,
              }
            }
          })
        )
      })
    )
  }

  private makeEvalGetter(baseUrl: string, fetchName?: string) {
    return <T = {}>(
      urlOptions: string | { fullUrl: string }
    ): ApiResponse<T> => {
      const url =
        typeof urlOptions === 'string'
          ? `${baseUrl}${urlOptions}`
          : urlOptions.fullUrl
      return this.httpClient
        .get(url, { ...this.httpOptions, responseType: 'text' })
        .pipe(
          // tslint:disable-next-line: no-eval
          map(text => eval(`(${text})`)),
          mapToMaybeData(),
          catchAndHandleError(fetchName)
        )
    }
  }

  forkJoinUrlMap<T>(
    baseUrl: string,
    fetchName: string | undefined,
    urlMap: Record<keyof T, string | { fullUrl: string } | undefined>
  ): ApiResponse<T> {
    const get = this.makeEvalGetter(baseUrl, fetchName)
    const props = keys(urlMap).filter(
      (_, index) => values(urlMap)[index] != null
    )
    const urls = values(urlMap).filter(url => url != null)
    const reqs = urls.map(url => get<T[keyof T]>(url!))
    return forkJoin(reqs).pipe(
      mergeApiResponses(),
      mapResponse(
        res =>
          res.reduce((acc, d, i) => {
            acc[props[i]] = d
            return acc
          }, {} as Partial<T>) as T
      )
    )
  }

  private transformReturnPeriod(value: number) {
    return 1 / value
  }
}
