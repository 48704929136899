import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core'
import {
  PricingCurveContextTypes,
  PricingCurveGraphSettings,
  PricingCurveStatus,
  SavedPricingCurveEntry,
} from '../model/pricing-curve.model'
import { PricingCurve } from '../model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve',
  styleUrls: ['./pricing-curve.component.scss'],
  templateUrl: './pricing-curve.component.html',
})
export class PricingCurveComponent implements OnInit {
  name = 'Pricing Curve'
  isSidebarOpen = true
  pricingCurveSidebarToggleHover = false

  @Input() pricingCurves: PricingCurve[]
  @Input() status: PricingCurveStatus
  @Input() savedCurves: SavedPricingCurveEntry[]
  @Input() username: string | null
  @Input() context: PricingCurveContextTypes
  @Input() graphSettings: PricingCurveGraphSettings

  ngOnInit(): void {
    if (this.context === 'credit') {
      this.name = 'Credit Pricing Curve'
    }
  }
}
