import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { TrackingService } from 'src/app/api/tracking/tracking.service'
import { AppState } from 'src/app/core/store'
import * as fromTrackingActions from './tracking.actions'
import { concatMap, map, switchMap } from 'rxjs'
import { rejectError } from 'src/app/api/util'
import { TrackingData } from '../tracking.model'
import { parseDate, sortByInceptionDate } from '../tracking.utils'

@Injectable()
export class TrackingEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(private service: TrackingService) {}

  fetchTracking$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTrackingActions.fetchTracking),
      switchMap(() => {
        return this.service.getTracking()
      }),
      rejectError(error =>
        this.store.dispatch(fromTrackingActions.fetchTrackingFailure({ error }))
      ),
      map(trackingData => {
        return fromTrackingActions.fetchTrackingSuccess({
          trackingData: sortByInceptionDate(trackingData),
        })
      })
    )
  })


  fetchTrackingDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTrackingActions.fetchTrackingDetails),
      concatMap(props => {
        return this.service.getTrackingDetails(props.programId)
      }),
      rejectError(error =>
        this.store.dispatch(
          fromTrackingActions.fetchTrackingDetailsFailure({ error })
        )
      ),
      map(trackingDetailsData => {
        return fromTrackingActions.fetchTrackingDetailsSuccess({
          trackingDetailsData,
        })
      })
    )
  })
}
