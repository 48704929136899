import { createAction, props } from '@ngrx/store'
import { ApplicationError } from './../../../error/model/error'
import { CreditProgramMetricSetting } from './../../model/credit-metric.model'

enum CreditMetricActions {
  GetMetricSettingsByProgram = '[Credit Metrics] Get Metric Settings by Program',
  GetMetricSettingsByProgramSuccess = '[Credit Metrics] Get Metric Settings by Program - Success',
  GetMetricSettingsByProgramFailure = '[Credit Metrics] Get Metric Settings by Program - Failure',
  SaveMetricSettings = '[Credit Metrics] Save Metric Settings',
  SaveMetricSettingsSuccess = '[Credit Metrics] Save Metric Settings - Success',
  SaveMetricSettingsFailure = '[Credit Metrics] Save Metric Settings - Failure',
  UpdateMetricSetting = '[Credit Metrics] Update Metric Setting',
  AddMetricSetting = '[Credit Metrics] Add Metric Setting',
  DeleteMetricSetting = '[Credit Metrics] Delete Metric Setting by Id',
  DeleteMetricSettingSuccess = '[Credit Metrics] Delete Metric Setting by Id - Success',
  DeleteMetricSettingFailure = '[Credit Metrics] Delete Metric Setting by Id - Failure',
  EnableAllMetricSettings = '[Credit Metrics] Enable All Metric Settings',
}

export const GetCreditMetricSettingsByProgram = createAction(
  CreditMetricActions.GetMetricSettingsByProgram,
  props<{ programId: number }>()
)
export const GetCreditMetricSettingsByProgramFailure = createAction(
  CreditMetricActions.GetMetricSettingsByProgramFailure,
  props<{ error: ApplicationError }>()
)
export const GetCreditMetricSettingsByProgramSuccess = createAction(
  CreditMetricActions.GetMetricSettingsByProgramSuccess,
  props<{ programMetricSettings: CreditProgramMetricSetting[] }>()
)

export const SaveCreditMetricSettings = createAction(
  CreditMetricActions.SaveMetricSettings
)
export const SaveCreditMetricSettingsFailure = createAction(
  CreditMetricActions.SaveMetricSettingsFailure,
  props<{ error: ApplicationError }>()
)
export const SaveCreditMetricSettingsSuccess = createAction(
  CreditMetricActions.SaveMetricSettingsSuccess,
  props<{ programMetricSettings: CreditProgramMetricSetting[] }>()
)

export const UpdateCreditMetricSetting = createAction(
  CreditMetricActions.UpdateMetricSetting,
  props<{ programMetricSetting: CreditProgramMetricSetting }>()
)

export const AddCreditMetricSetting = createAction(
  CreditMetricActions.AddMetricSetting,
  props<{ programMetricSetting: CreditProgramMetricSetting }>()
)

export const DeleteCreditMetricSetting = createAction(
  CreditMetricActions.DeleteMetricSetting,
  props<{ programMetricSetting: CreditProgramMetricSetting }>()
)
export const DeleteCreditMetricSettingFailure = createAction(
  CreditMetricActions.DeleteMetricSettingFailure,
  props<{ error: ApplicationError }>()
)
export const DeleteCreditMetricSettingSuccess = createAction(
  CreditMetricActions.DeleteMetricSettingSuccess,
  props<{ programMetricSetting: CreditProgramMetricSetting }>()
)

export const EnableAllCreditMetricSettings = createAction(
  CreditMetricActions.EnableAllMetricSettings
)
