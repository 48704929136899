import { createAction, props } from '@ngrx/store'
import {
  LossSetGroup,
  LossSetLayer,
} from '../../../analysis/model/loss-set-layers.model'
import { CombinedLossDistributionRow } from '../../../core/model/loss-set-table.model'
import { Program } from '../../../core/model/program.model'
import { ApplicationError } from '../../../error/model/error'
import {
  AggregationMethodType,
  Perspective,
  VaRTVaR,
} from '../../model/metrics.model'
import { GrossLossTableExportXlsx, LossSetToAdd } from './explore.model'
import { GroupSummaryRequest, SummaryViewRequest, SummaryDataResponse } from '../explore.model'
import { ExploreSummaryView } from 'src/app/api/model/backend.model'

export const setExploreProgram = createAction(
  '[Explore Study] Set Program',
  props<{ program: Program }>()
)

export const getExploreLossSetLayersAndGroups = createAction(
  '[Explore Study] Get Loss Set Layers And Groups',
  props<{ lossSetLayers: LossSetLayer[]; lossSetLayerGroups: LossSetGroup[] }>()
)

export const addLossSetIDs = createAction(
  '[Explore Study] Add Selected LossSet (Layer/Group) ID',
  props<{
    lossSetLayers?: LossSetToAdd[]
    lossSetGroups?: LossSetToAdd[]
  }>()
)

export const removeLossSetIDs = createAction(
  '[Explore Study] Remove Selected LossSet IDs',
  props<{ lossSetGroupsToRemove: string[]; lossSetLayersToRemove: string[] }>()
)

export const clearExplore = createAction('[Explore Study] Clear Explore')

export const getGrossPortfolioViewID = createAction(
  '[Explore Study] Get Gross Portfolio View ID'
)

export const exploreGrossXlsx = createAction(
  '[Explore Gross] Export As Xlsx',
  props<GrossLossTableExportXlsx>()
)

export const getGrossPortfolioViewIDSuccess = createAction(
  '[Explore Study] Get Gross Portfolio View ID Success',
  props<{ grossPortfolioView: string | null }>()
)

export const getGrossPortfolioViewIDFailure = createAction(
  '[Explore Study] Get Gross Portfolio View ID Failure',
  props<{ error: ApplicationError }>()
)

export const getLossDataCollection = createAction(
  '[Explore Study] Get LOBS Data Collection',
  props<{
    viewID: string | undefined
    lossSetGroups: {
      lossID: string
      viewID: string
      lossName: string
      lossType: string
      filterValue: string
      isLossRatioView?: boolean
      subjectPremiumAmt?: number
    }[]
    lossSetLayers: {
      lossID: string
      viewID: string
      lossName: string
      lossType: string
      filterValue: string
      isLossRatioView?: boolean
      subjectPremiumAmt?: number
    }[]
  }>()
)

export const getLossDataCollectionSuccess = createAction(
  '[Explore Study] Get LOBS Collection Data Success',
  props<{
    payload: {
      dataTable: CombinedLossDistributionRow[]
      lossID: string
      lossType: string
      lossName: string
      filterValue: string
      isLossRatioView?: boolean
      subjectPremiumAmt?: number
    }[]
  }>()
)

export const getLossDataCollectionError = createAction(
  '[Explore Study] Get LOBs Collection Data Error',
  props<{ error: ApplicationError }>()
)

export const getSummaryDataCollection = createAction(
  '[Explore Study] Get Summary Data Collection',
  props<{
    viewID: string | undefined
    lossSetLayers: {
      lossID: string
      viewID: string
      lossName: string
      lossType: string
      filterValue: string
      isLossRatioView?: boolean
      subjectPremiumAmt?: number
    }[]
  }>()
)

export const getSummaryDataCollectionError = createAction(
  '[Explore Study] Get Summary Collection Data Error',
  props<{ error: ApplicationError }>()
)

export const getGroupSummaryDataCollection = createAction(
  '[Explore Study] Get Group Summary Data Collection',
  props<{
    viewID: string | undefined
    lossSetLayers: {
      lossID: string
      viewID: string
      lossName: string
      lossType: string
      filterValue: string
      isLossRatioView?: boolean
      subjectPremiumAmt?: number
    }[]
  }>()
)

export const getGroupSummaryDataCollectionError = createAction(
  '[Explore Study] Get Summary Collection Data Error',
  props<{ error: ApplicationError }>()
)
// Updated RP + Modifiers

export const updateLossDataModifiers = createAction(
  '[Explore Study] Update Loss Data Modifier',
  props<{
    rp?: number
    index?: number
    perspective: Perspective
    aggregationMethod: AggregationMethodType
    vartvar: VaRTVaR
    isLossRatioView?: boolean
  }>()
)

export const updateLossDataModifiersError = createAction(
  '[Explore Study] Update Loss Data Modifier Failure',
  props<{ error: ApplicationError }>()
)

export const updateLossDataModifiersSuccess = createAction(
  '[Explore Study] Update Loss Data Modifier Success'
)

export const resetExploreContainer = createAction(
  '[Explore Study] Reset Explore'
)

export const getSummaryData = createAction(
  '[Explore Study] Get Summary Data',
  props<{
    lossSetLayers: LossSetToAdd[]
  }>()
)

export const getSummaryDataSuccess = createAction(
  '[Explore Study] Get Summary Data Success',
  props<{summaryData: SummaryDataResponse[]}>()
)

export const getSummaryDataFailure = createAction(
  '[Explore Study] Get Summary Data Failure',
  props<{ error: ApplicationError }>()
)

export const getSummaryGroupData = createAction(
  '[Explore Study] Get Summary Group Data',
  props<{groups: GroupSummaryRequest[]}>()
)

export const getSummaryGroupDataSuccess = createAction(
  '[Explore Study] Get Summary Data Group Success',
  props<{groupSummaryData: SummaryDataResponse[]}>()
)

export const getSummaryGroupDataFailure = createAction(
  '[Explore Study] Get Summary Data Group Failure',
  props<{ error: ApplicationError }>()
)

export const saveSummaryFilterView = createAction(
  '[Explore Study] Save Summary Filter View',
  props<{ data: SummaryViewRequest }>()
)

export const saveSummaryFilterViewFailure = createAction(
  '[Explore Study] Save Summary Filter View Failure',
  props<{ error: ApplicationError }>()
)

export const saveSummaryFilterViewSuccess = createAction(
  '[Explore Study] Save Summary Filter View Success',
  props<{ data: ExploreSummaryView }>()
)

export const updateSummaryFilterView = createAction(
  '[Explore Study] Update Summary Filter View',
  props<{ data: ExploreSummaryView }>()
)

export const updateSummaryFilterViewFailure = createAction(
  '[Explore Study] Update Summary Filter View Failure',
  props<{ error: ApplicationError }>()
)

export const updateSummaryFilterViewSuccess = createAction(
  '[Explore Study] Update Summary Filter View Success',
  props<{ data: ExploreSummaryView }>()
)

export const deleteSummaryFilterView = createAction(
  '[Explore Study] Delete Summary Filter View',
  props<{ id: number }>()
)

export const deleteSummaryFilterViewFailure = createAction(
  '[Explore Study] Delete Summary Filter View Failure',
  props<{ error: ApplicationError }>()
)

export const deleteSummaryFilterViewSuccess = createAction(
  '[Explore Study] Delete Summary Filter View Success',
  props<{ id: number }>()
)

export const fetchSummaryViews = createAction(
  '[Explore Study] Fetch Summary Views',
  props<{ programId: number}>()
)

export const fetchSummaryViewsFailure = createAction(
  '[Explore Study] Fetch Summary Views Failure',
  props<{ error: ApplicationError }>()
)

export const fetchSummaryViewsSuccess = createAction(
  '[Explore Study] Fetch Summary Views Success',
  props<{ explore_gross_views: ExploreSummaryView[] }>()
)

export const updateSummaryLoading = createAction(
  '[Explore Study] Update Summary Loading',
  props<{ loading: boolean }>()
)
