import { createAction, props } from '@ngrx/store'
import { ApplicationError } from 'src/app/error/model/error'
import { TrackingData, TrackingDetailsData } from '../tracking.model'

export const fetchTracking = createAction('[Tracking] Fetch')

export const fetchTrackingSuccess = createAction(
  '[Tracking] Fetch Success',
  props<{ trackingData: TrackingData[] }>()
)

export const fetchTrackingFailure = createAction(
  '[Tracking] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const filtersChanged = createAction(
  '[Tracking] Filters changed',
  props<{ filters?: Record<string, string[]> }>()
)

export const inceptionDateChanged = createAction(
  '[Tracking] Inception Date changed',
  props<{ minInceptionDate: string; maxInceptionDate: string }>()
)

export const fetchTrackingDetails = createAction(
  '[Tracking Details] Fetch',
  props<{ programId: number }>()
)

export const fetchTrackingDetailsSuccess = createAction(
  '[Tracking Details] Fetch Success',
  props<{ trackingDetailsData: TrackingDetailsData[] }>()
)

export const fetchTrackingDetailsFailure = createAction(
  '[Tracking Details] Fetch Failure',
  props<{ error: ApplicationError }>()
)
