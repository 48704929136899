<button mat-icon-button class="menu-icon" id="menu" (click)="navService.open()">
  <mat-icon>menu</mat-icon>
</button>
<a class="logo" routerLink="/home" appLogo></a>
<app-header-title
  class="title"
  [name]="name"
  [subtitle]="subtitle"
  (backClick)="onBackClick()"
></app-header-title>
<div *ngIf="showSFMappingButton">
  <section class="actions">
    <ng-container>
      <button appButton big accent border (click)="onSFMappingClick()">
        SF Mapping
      </button>
    </ng-container>
  </section>
</div>
