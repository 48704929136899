<button mat-icon-button class="menu-icon" id="menu" (click)="navService.open()">
  <mat-icon>menu</mat-icon>
</button>
<a class="logo" routerLink="/home" appLogo></a>
<app-header-title
  class="title"
  [name]="name"
  (backClick)="onBackClick()"
></app-header-title>
<div>
  <section class="actions">
    <ng-container>
      <div
        class="button-container"
        matTooltip="Add data to the graph before exporting"
        [matTooltipDisabled]="!!pricingCurves?.length"
        *ngIf="context === 'pricing-curve'"
      >
        <button
          appButton
          primary
          border
          big
          (click)="onExportClick()"
          [disabled]="status.isLoading || !pricingCurves?.length"
        >
          <span>Export</span>
        </button>
      </div>
      <button
        appButton
        primary
        border
        big
        (click)="onGraphSettingsClick()"
        [disabled]="status.isLoading"
        *ngIf="context === 'credit'"
      >
        Graph Settings
      </button>
      <button
        appButton
        primary
        border
        big
        (click)="onAddClick()"
        [disabled]="status.isLoading"
      >
        <span>+ Add Pricing Curve</span>
      </button>
    </ng-container>
  </section>
</div>
