import { EntityState, createEntityAdapter } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import {
  CreditCompareMetricCategorySetting,
  CreditStructureCompareEntity,
} from '../../model/credit-compare.model'
import * as fromCreditCompareActions from './credit-compare.actions'
import { getCompareMetricCategorySettings } from '../../utils/credit-compare.utils'

export interface State extends EntityState<CreditStructureCompareEntity> {
  metricCategorySettings: CreditCompareMetricCategorySetting[]
}

export const adapter = createEntityAdapter<CreditStructureCompareEntity>({
  selectId: (creditStructureCompareEntity): string =>
    creditStructureCompareEntity.credit_calculation_structure_id,
})

export const initialState: State = adapter.getInitialState({
  metricCategorySettings: [],
})

const creditStructureCompareEntityReducer = createReducer(
  initialState,
  on(
    fromCreditCompareActions.GetCreditCompareMetricsForStructureSuccess,
    fromCreditCompareActions.GetCreditCompareMetricsForStructureGroupSuccess,
    (state, { creditStructureCompareEntity }) =>
      adapter.addOne(creditStructureCompareEntity, state)
  ),
  on(
    fromCreditCompareActions.GetCreditCompareMetricCategorySettings,
    (state, { creditStructureCompareEntity }) => {
      if (state.metricCategorySettings.length > 1) {
        return state
      }
      const metricCategorySettings = getCompareMetricCategorySettings(
        creditStructureCompareEntity.metrics
      )
      return { ...state, metricCategorySettings }
    }
  ),
  on(
    fromCreditCompareActions.RemoveCreditStructureCompareEntity,
    (state, { structureId }) => {
      if (!state.entities[structureId]) {
        return state
      }
      return adapter.removeOne(structureId, state)
    }
  ),
  on(
    fromCreditCompareActions.RemoveAllCreditStructureCompareEntities,
    state => {
      return adapter.removeAll({ ...state, metricCategorySettings: [] })
    }
  )
)

export function reducer(state: State | undefined, action: Action) {
  return creditStructureCompareEntityReducer(state, action)
}
