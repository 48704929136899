<div class="wrapper">
  <h1 mat-dialog-title>Auto Group Loss Sets</h1>
  <div class="selection">
    <mat-button-toggle-group
    class="app-mat-theme app-button-toggle-group"
    name="groupMethod"
    aria-label="Auto Group Method"
    [value]="groupMethod"
    (click)="$event.stopPropagation()"
    (change)="updateGroupMethod($event.value)"
  >
    <mat-button-toggle value="dim" disableRipple>Group by dim</mat-button-toggle>
    <mat-button-toggle value="map" disableRipple>Auto Mapping</mat-button-toggle>
  </mat-button-toggle-group>
  </div>
  <div
    *ngIf="groupMethod === 'dim'"
    class="dialog-container auto-group-container"
    >
    <div class="group-criteria">
      <h3>Group By:</h3>
      <div class="list">
        <h4>ls_dim1</h4>
        <div class="list-value" *ngFor="let lsdim of lsDim1List">
          <app-layer-property
            checkboxRow
            [name]="lsdim"
            [value]="isSelected(lsdim, lsDim1SelectedList)"
            [type]="'checkbox'"
            (inputChange)="toggleSelected(lsdim, 'lsDim1')"
          ></app-layer-property>
        </div>
        <h4>ls_dim2</h4>
        <div class="list-value" *ngFor="let lsdim of lsDim2List">
          <app-layer-property
            checkboxRow
            [name]="lsdim"
            [value]="isSelected(lsdim, lsDim2SelectedList)"
            [type]="'checkbox'"
            (inputChange)="toggleSelected(lsdim, 'lsDim2')"
          ></app-layer-property>
        </div>
      </div>
    </div>
    <div class="new-group">
      <h3>New Loss Set Group</h3>
      <input
        matInput
        class="name-input"
        placeholder="...Group Name"
        (change)="updateGroupName($event.target.value)"
      />
      <h4>Included Loss Sets</h4>
      <div class="loss-sets">
        <div class="loss-set" *ngFor="let ls of selectedLossSets">
          {{ layerLabels[ls.id] }}
        </div>
      </div>
    </div>
  </div>
  <div
  *ngIf="groupMethod === 'map'"
  class="dialog-container auto-group-container"
  >
    <div class="auto-map">
      <div class="maps">
        <h3>Mapping Columns</h3>
        <div
          class="map"
          *ngFor="let column of lossSetMappingColumns"
          >
          <app-layer-property
            checkboxRow
            [name]="column.viewValue"
            [value]="selectedColumns[column.id]"
            [type]="'checkbox'"
            (inputChange)="toggleSelectedColumn(column.id)"
          ></app-layer-property>
        </div>
      </div>
      <div class="groups">
        <h3>New Groups</h3>
        <div
          *ngFor="let group of selectedLossSetGroups"
          class="group"
        >
          <h4>{{group.name}}</h4>
          <div class="loss-sets">
            <div class="loss-set" *ngFor="let ls of group.lossSetLayers">{{ layerLabels[ls.id] }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="groupMethod === 'attribute'"
    class="dialog-container auto-group-container"
    >
    <div class="auto-map">
      <div class="maps">
        <h3>Mapping Columns</h3>
        <div>
          <mat-select
            (selectionChange)="onAttributeSelected($event)"
            placeholder="Choose an Attribute"
            >
            <mat-option *ngFor="let attribute of attributes" [value]="attribute.value">
              {{attribute.viewValue}}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="groups">
        <h3>New Groups</h3>
        <div
          *ngFor="let group of selectedLossSetGroups"
          class="group"
        >
          <h4>{{group.name}}</h4>
          <div class="loss-sets">
            <div class="loss-set" *ngFor="let ls of group.lossSetLayers">{{layerLabels[ls.id]}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="button-group">
    <button
      appButton
      link
      (click)="onCloseClick()"
    >Close</button>
    <button
      appButton
      border
      (click)="handleClear()"
    >Clear</button>
    <button
      [disabled]="savingDisabled"
      appButton
      accent
      border
      (click)="onSaveClick()"
    >Save Group(s)</button>
  </div>
</div>
