import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
} from '@angular/core'
import { hideMetric } from '../../../../metrics/metrics.util'
import { Size } from '@shared/size.mixin'
import { CompareMetricValue } from '../../../model/compare-metrics.model'
import { CompareEntity } from '../../../store/compare/compare.reducer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-metric-category',
  styleUrls: ['./compare-metric-category.component.scss'],
  templateUrl: './compare-metric-category.component.html',
})
export class CompareMetricCategoryComponent implements OnChanges {
  @Input() entitiesLength: number
  @Input() label: string
  @Input() values: CompareMetricValue[][]
  @Input() firstEntity: CompareEntity
  @Input() hasPortfolioTypes: boolean
  @Input() rankMax: number
  @Input() size: Size
  @Input() hiddenMetricRanks: Record<string, boolean>
  @Input() expandedChangeMetrics: Record<string, boolean>
  @Input() structureIndex: number
  @Input() precision: number
  mappedValues: CompareMetricValue[][]

  ngOnChanges() {
    if (this.label === 'Net Results') {
      this.mappedValues = this.values.map(value => {
        if (value[0].label === 'CV of Net Losses') {
          value[0].conversion = 'Base'
        }
        return value
      })
    }
  }

  @Input() set collapsed(value: any) {
    this._collapsed = coerceBooleanProperty(value)
  }
  get collapsed() {
    return this._collapsed
  }
  _collapsed = false

  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }
  @HostBinding('class.tiny')
  get isSizeTiny() {
    return this.size === 'tiny'
  }
  @HostBinding('class.mini')
  get isSizeMini() {
    return this.size === 'mini'
  }

  get hideMetric() {
    return hideMetric
  }

  trackByID(index: number, value: CompareMetricValue | undefined) {
    return (value && value.label) || index
  }

  trackByIDs(index: number, value: CompareMetricValue[] | undefined) {
    return (value && value[0] && value[0].label) || index
  }

  isChangeExpanded(value: CompareMetricValue) {
    const categoryLabel = value.grossMetricType
      ? value.grossMetricType + '-' + value.label
      : value.category + '-' + value.label
    return (
      (this.expandedChangeMetrics &&
        this.expandedChangeMetrics[categoryLabel] &&
        this.entitiesLength > 1) ||
      false
    )
  }

  // Subtract the first Entity's value from current value for the given metric
  computeChangeFromFirst(value: CompareMetricValue) {
    const metrics = this.firstEntity.metricCategories
      .find(c => c.category === this.label)
      ?.metrics.map(x => x[0])
    const metricsValue = metrics?.find(m => m.label === value.label)?.value
    return value.value - (metricsValue ?? 0)
  }

  // Compute the percentage version of change from first
  computeChangeFromFirstPercent(value: CompareMetricValue) {
    const change = this.computeChangeFromFirst(value)
    const metrics = this.firstEntity.metricCategories
      .find(c => c.category === this.label)
      ?.metrics.map(x => x[0])
    const original = metrics?.find(m => m.label === value.label)?.value ?? 0

    return change / original
  }
}
