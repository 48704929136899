import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface SetNameDialogData {
  actionName?: string
  entityName?: string
  currentName?: string
  otherNames?: string[]
  currentDescription?: string
  showDescription?: boolean
  requireDescription?: boolean
  nameDisplayField?: string
  analysisProfileID?: string
  parentGrossPortfolioID?: string
}

export interface SetNameDialogResult {
  name: string
  description: string
  analysisProfileID?: string
  parentGrossPortfolioID?: string
}

/** Error when invalid control is dirty, touched, or submitted. */
export class AnyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    )
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-set-name-dialog',
  styles: [
    `
      .full-width {
        width: 100%;
      }

      .duplicate-error {
        font-size: small;
      }

      mat-form-field:not(:last-of-type) {
        margin-bottom: var(--stack);
      }

      .mat-mdc-dialog-actions {
        padding: 20px 24px;
      }
      .edit-field {
        border-bottom: 1px solid var(--subtle);
        margin-top: 16px;
      }
      .edit-field.error {
        border-bottom: 1px solid red !important;
      }
      :host ::ng-deep .mat-mdc-text-field-wrapper {
        padding-top: 1rem !important;
        padding-left: 0 !important;
      }
      input {
        padding: 0 0 4px 0 !important;
      }
      :host ::ng-deep .mdc-text-field .mdc-floating-label {
        position: absolute !important;
        font-size: 16px;
        padding-bottom: 4px !important;
      }
      :host ::ng-deep .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
        padding-left: 0 !important;
      }
      :host ::ng-deep .mat-mdc-form-field-subscript-wrapper {
        height: 0px !important;
        .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
          padding-left: 0 !important;
        }
      }
      :host ::ng-deep .mdc-text-field--filled .mdc-floating-label--float-above {
        transform: translateY(-126%) scale(.85);
      }
    `,
  ],
  template: `
    <h1 mat-dialog-title>{{ title }}</h1>

    <div mat-dialog-content>
      <mat-form-field
        class="full-width edit-field"
        [ngClass]="{'error': name.invalid}"
        subscriptSizing="dynamic"
        >
        <mat-label>{{nameDisplayField ? nameDisplayField : 'Name'}}</mat-label>
        <input
          matInput
          required
          [formControl]="name"
          [errorStateMatcher]="matcher"
          [maxLength]="50"
          (keydown.enter)="onSave()"
        />
        <mat-error *ngIf="name.hasError('required')">
          {{ nameDisplayField ? nameDisplayField : 'Name' }} is required.
        </mat-error>
        <mat-error *ngIf="name.hasError('duplicateName')">
          {{ nameDisplayField ? nameDisplayField : 'Name' }} must be unique.
        </mat-error>
      </mat-form-field>

      <mat-form-field
        *ngIf="showDescription"
        class="full-width edit-field"
        [ngClass]="{'error': name.invalid}"
        subscriptSizing="dynamic"
        >
        <mat-label>Description</mat-label>
        <input
          [required]="requireDescription"
          matInput
          [formControl]="description"
          [maxLength]="100"
          (keydown.enter)="onSave()"
        />
        <mat-error *ngIf="description.errors?.required"
          >Value is required.</mat-error
        >
      </mat-form-field>

      <div
        class="duplicate-error"
        *ngIf="
          duplicate &&
          !name.invalid &&
          duplicateLength === name.value.length &&
          duplicateName === name.value
        "
      >
        <mat-error
          >Duplicate
          {{
            data.nameDisplayField ? data.nameDisplayField : 'Name'
          }}.</mat-error
        >
      </div>
    </div>

    <div mat-dialog-actions>
      <button
        appButton
        accent
        type="submit"
        [disabled]="name.invalid"
        (click)="onSave()"
      >
        {{ actionName }}
      </button>
      <button appButton primary link mat-dialog-close>Cancel</button>
    </div>
  `,
})
export class SetNameDialogComponent {
  name: FormControl
  description: FormControl
  analysisProfileID: FormControl
  parentGrossPortfolioID: FormControl
  matcher = new AnyErrorStateMatcher()
  nameDisplayField: string | null = null

  actionName: string
  entityName: string
  otherNames: string[]
  showDescription: boolean
  requireDescription: boolean

  duplicate = false
  duplicateLength: number
  duplicateName: string

  get title(): string {
    const suffix = this.entityName ? ` ${this.entityName}` : ''
    return `${this.actionName}${suffix}`
  }

  constructor(
    public dialogRef: MatDialogRef<SetNameDialogComponent, SetNameDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: SetNameDialogData
  ) {
    this.actionName = data.actionName || 'Add'
    this.entityName = data.entityName || ''
    this.showDescription = data.showDescription === true
    this.requireDescription = data.requireDescription === true
    if (data.nameDisplayField) {
      this.nameDisplayField = data.nameDisplayField
    }
    this.name = new FormControl(data.currentName || '', [
      Validators.required,
      uniqueNameValidator(data.otherNames),
    ])

    this.description = new FormControl(data.currentDescription || '')
    this.analysisProfileID = new FormControl(data.analysisProfileID || '')
    this.parentGrossPortfolioID = new FormControl(
      data.parentGrossPortfolioID || ''
    )
  }

  onSave() {
    if (!this.name.invalid && !this.description.errors) {
      this.dialogRef.close({
        name: this.name.value,
        description: this.description.value,
        analysisProfileID: this.analysisProfileID.value,
        parentGrossPortfolioID: this.parentGrossPortfolioID.value,
      })
    }
  }
}

function uniqueNameValidator(otherNames: string[] = []): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = ((control.value as string) || '').toLowerCase()
    const unique = otherNames.every(name => name.toLowerCase() !== value)
    return unique ? null : { duplicateName: { value } }
  }
}
