import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable, Subject } from 'rxjs'
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators'
import { isIndexedLayer } from '../../layers/indexed-layer'
import { isMultiSectionLayer } from '../../layers/multi-section-layer'
import { isSwingLayer } from '../../layers/swing-layer'
import {
  CurrencyCode,
  LossFilter,
} from '../../../api/analyzere/analyzere.model'
import {
  BlobResponse,
  OmitID,
  StructureLayerDataResponse,
  StudyResponse,
} from '../../../api/model/backend.model'
import { Program } from '../../../core/model/program.model'
import { AppState } from '../../../core/store'
import { State as AuthState } from '../../../core/store/auth/auth.state.facade'
import {
  hasPricingCurveAccess,
  selectAuthState,
  selectMarketContentBlob,
  selectStudies,
} from '../../../core/store/auth/auth.selectors'
import { setCurrentStructure } from '../../../core/store/broker/broker.actions'
import {
  selectCurrencyList,
  selectCurrentClientID,
  selectCurrentLossFilters,
  selectCurrentStudyID,
  selectCurrentYearID,
} from '../../../core/store/broker/broker.selectors'
import {
  setProgramNameAndDescription,
  StructureNameEditEvent,
} from '../../../core/store/program/program.actions'
import {
  selectCurrentStudyProgramNames,
  selectDirtyProgram,
} from '../../../core/store/program/program.selectors'
import { State as ThemeState } from '../../../core/store/theme/theme.state.facade'
import { selectReinsurersState } from '../../../reinsurers/store/reinsurers.selectors'
import { State as ReinsurerState } from '../../../reinsurers/store/study-reinsurers.reducer'
import {
  SetNameDialogComponent,
  SetNameDialogData,
  SetNameDialogResult,
} from '@shared/set-name-dialog.component'
import { TierNames } from '../../../tier/tier.model'
import { RiskLossSetLayerModel } from '../../layers/loss-set-layer-selector/loss-set-layer-selector.component'
import { Layer, PhysicalLayer } from '../../model/layers.model'
import {
  findLayer,
  findLayerByVisibleId,
  findRiskActualLayer,
} from '../../model/layers.util'
import { LossSetGroup, LossSetLayer } from '../../model/loss-set-layers.model'
import { AggregationMethodType, Perspective } from '../../model/metrics.model'
import {
  PortfolioMetrics,
  PortfolioType,
} from '../../model/portfolio-metrics.model'
import { extractPortfolioSetID } from '../../model/portfolio-set-id.util'
import {
  StructureSaveAsEvent,
  StructureSaveEvent,
} from '../../model/portfolio-set.model'
import {
  PortfolioSetID,
  SharedIDPortfolio,
} from '../../model/portfolio-set.model'
import { OptimizationService } from '../../optimization/optimization.service'
import * as fromPanelActions from '../../store/analysis-panels.actions'
import { toggleLossSetGroupEditor } from '../../store/analysis-panels.actions'
import {
  selectAnalysisLayersPanelOpen,
  selectAnalysisLossSetGroupEditor,
  selectAnalysisPanelsCollapseBySection,
  selectAnalysisPortfolioMetricsOpen,
  selectAnalysisPropertiesPanelOpen,
} from '../../store/analysis-panels.selectors'
import * as fromAnalysisActions from '../../store/analysis.actions'
import {
  selectCededDictionary,
  selectCededLayers,
  selectCededPortfolioViewLayersViewIDs,
  selectCededSelectedLayer,
  selectCurrentCededLayer,
  selectCurrentCurrency,
  selectCurrentProgram,
  selectCurrentProgramOptimizations,
  selectCurrentProgramScenarios,
  selectEditorActiveAction,
  selectEditorPortfolioSetID,
  selectEditorShowingAnimatedScenariosTab,
  selectEditorShowingExploreGrossTab,
  selectEditorShowingTechnicalPremiumTab,
  selectEditorShowingTowerTab,
  selectGrossPortfolioViewID,
  selectGroupEditSelectedLossSets,
  selectLossSetGroupEditMode,
  selectLossSetGroupEntities,
  selectLossSetGroupsActiveAction,
  selectLossSetGroupsMetrics,
  selectLossSetLayers,
  selectLossSetLayersDirty,
  selectLossSetLayerViewIDs,
  selectLossSetSelectedGroup,
  selectLossSetSelectionMode,
  selectParentGrossLossSetLayers,
  selectPortfolioViewMetrics,
  selectPortfolioViewMetricsLoading,
  selectPortfolioViewMetricsRSS,
  selectSharedIDPortfolios,
  selectShowAgg,
  selectShowOcc,
  selectTechnicalPremiumCanSave,
  selectZoom,
} from '../../store/analysis.selectors'
import {
  addRiskLossSetsToLayer,
  removeRiskLossSetsToLayer,
  setSelectedLayer,
  updateLayer,
  updatePhysicalLayer,
} from '../../store/ceded-layers/layers.actions'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import * as fromLossSetGroupActions from '../../store/loss-set-layers/loss-set-group/loss-set-group.actions'
import { LossSetGroupEntity } from '../../store/loss-set-layers/loss-set-group/loss-set-group.reducer'
import {
  getAllLossSetGroupsMetrics,
  setLossSetSelectionMode,
  updateGrossLossSets,
} from '../../store/loss-set-layers/loss-set-layers.actions'
import { getLayerViewMetricID } from '../../store/metrics/layer-metrics.selectors'
import { updateAndFetch } from '../../store/metrics/portfolio-metrics.actions'
import { selectPortfolioHeaderTierNames } from '../../store/portfolio-header.selectors'
import { SubmitMarketDialogComponent } from '../../submit-to-market/submit-market-dialog.component'
import {
  PortfolioDetailsDialogContainerComponent,
  PortfolioDetailsDialogData,
} from '../portfolio-details-dialog.container/portfolio-details-dialog.container'
import { PortfolioExchangeDialogContainerComponent } from '../portfolio-exchange-dialog-container/portfolio-exchange-dialog.container'
import { PortfolioPropertiesDialogContainerComponent } from '../portfolio-properties-dialog.container/portfolio-properties-dialog.container'
import { ProgramService } from '../../../api/program/program.service'
import {
  resetLayerTypeValues,
  saveLayerTypeDefaults,
  saveLayerTypeValuesGoingForward,
} from '../../store/technical-premium/technical-premium.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-portfolio-current',
  templateUrl: './current-portfolio.container.html',
})
export class CurrentPortfolioContainerComponent implements OnInit, OnDestroy {
  @Input() programs: Program[]

  currentProgram$: Observable<Program | undefined>
  currentProgramStructureNames$: Observable<string[]>
  reinsurers$: Observable<ReinsurerState>
  authState$: Observable<AuthState>
  portfolioSetID$: Observable<PortfolioSetID | null>
  submitMarketPermission$: Observable<boolean | undefined>
  layerData$: Observable<StructureLayerDataResponse[] | undefined>
  tierNames$: Observable<TierNames>
  portfolioMetrics$: Observable<PortfolioMetrics | null>
  activeAction$: Observable<string>
  dirty$: Observable<boolean>
  layers$: Observable<LayerState[]>
  portfolioMetricsLoading$: Observable<boolean>
  showAgg$: Observable<boolean>
  showOcc$: Observable<boolean>
  showingTowerTab$: Observable<boolean>
  showZoom$: Observable<number>
  marketBlob$: Observable<BlobResponse | null>
  selectedViewID$: Observable<string | null>
  rss$: Observable<number | null>
  sharedIDPortfolio$: Observable<SharedIDPortfolio[]>
  portfolioMetricsOpen$: Observable<boolean>
  layersPanelOpen$: Observable<boolean>
  propertiesPanelOpen$: Observable<boolean>
  showingAnimatedScenariosTab$: Observable<boolean>
  showingExploreGrossTab$: Observable<boolean>
  selectedClientID$: Observable<string | null>
  selectedYearID$: Observable<string | null>
  currentLossFilters$: Observable<LossFilter[] | undefined>
  scenarios$: Observable<Program[]>
  optimizations$: Observable<Program[]>
  showLossSetGroupEditor$: Observable<boolean>
  groupEditSelectedLossSets$: Observable<LossSetLayer[]>
  lossSetGroupEditMode$: Observable<string>
  lossSetGroups$: Observable<LossSetGroupEntity[]>
  lossSetGroupsMetrics$: Observable<Record<string, PortfolioMetrics>[]>
  lossSetSelectedGroup$: Observable<string | null>
  lossSetSelectionMode$: Observable<string>
  lossSetGroupsActiveAction$: Observable<string>
  currentCededLayer$: Observable<LayerState | null>
  collapseBySection$: Observable<
    Record<fromPanelActions.AnalysisPanelsSection, boolean>
  >
  parentLossSets$: Observable<LossSetLayer[]>
  grossLossSets$: Observable<LossSetLayer[]>
  layerViewIDs$: Observable<Record<string, string>>
  grossPortfolioViewID$: Observable<string | null>
  hideTechPremiumTab$: Observable<boolean>
  showingTechPremiumTab$: Observable<boolean>
  canSaveTechnicalPremium$: Observable<boolean>
  selectedProgramID$: Observable<string | null>
  studies$: Observable<StudyResponse[] | null>

  theme: ThemeState
  currentCurrency: string
  currencyList$: Observable<CurrencyCode[]>
  structureCurrency: any

  private destroy$ = new Subject()

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private submitDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private optimizationService: OptimizationService,
    private programService: ProgramService
  ) {}

  ngOnInit(): void {
    this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID))
    this.selectedYearID$ = this.store.pipe(select(selectCurrentYearID))
    this.sharedIDPortfolio$ = this.store.pipe(select(selectSharedIDPortfolios))
    this.currentProgram$ = this.store.pipe(select(selectCurrentProgram))
    this.currentProgramStructureNames$ = this.store.pipe(
      select(selectCurrentStudyProgramNames)
    )
    this.portfolioSetID$ = this.store.pipe(select(selectEditorPortfolioSetID))
    this.portfolioMetrics$ = this.store.pipe(select(selectPortfolioViewMetrics))
    this.marketBlob$ = this.store.pipe(select(selectMarketContentBlob))
    this.layerData$ = combineLatest([
      this.store.pipe(select(selectCurrentProgram)),
      this.store.pipe(select(selectDirtyProgram)),
    ]).pipe(
      map(([currentProgram, dirtyProgram]) => {
        if (dirtyProgram !== null) {
          return dirtyProgram.layerData
        } else if (currentProgram) {
          return currentProgram.layerData
        } else {
          return []
        }
      })
    )
    this.submitMarketPermission$ = this.store.pipe(
      select(selectAuthState),
      map(authState => {
        let submitPermissions = false
        if (authState.security) {
          authState.security.forEach((entry: string) => {
            if (entry === 'Submit to Market') {
              submitPermissions = true
            }
          })
        }
        return submitPermissions
      })
    )

    this.dirty$ = combineLatest([
      this.store.pipe(select(selectCededLayers)),
      this.store.pipe(select(selectLossSetLayersDirty)),
    ]).pipe(
      map(([layers, lossSetDirty]) => {
        return (
          lossSetDirty ||
          layers.filter(l => l.deleted || l.dirty || l.new).length > 0
        )
      })
    )
    this.activeAction$ = this.store.pipe(select(selectEditorActiveAction))

    this.layers$ = this.store.pipe(select(selectCededLayers))

    this.portfolioMetricsLoading$ = this.store.pipe(
      select(selectPortfolioViewMetricsLoading)
    )

    this.selectedViewID$ = combineLatest([
      this.store.pipe(select(selectCededSelectedLayer)),
      this.store.pipe(select(selectCededPortfolioViewLayersViewIDs)),
    ]).pipe(
      withLatestFrom(
        this.store.pipe(select(selectCededDictionary)),
        this.store.pipe(select(selectCededLayers))
      ),
      map(([[id, viewIDMap], layerStatesByID, layerStates]) => {
        if (id === null) {
          return null
        }

        const allLayers = layerStates.map(ls => ls.layer)

        const layer = layerStatesByID[id]?.layer
        if (layer) {
          if (layer.meta_data.isRiskVisible) {
            const actualLayer = findRiskActualLayer(allLayers, layer.id)
            if (actualLayer) {
              return getLayerViewMetricID(viewIDMap, layerStates, actualLayer)
            }
          } else if (isIndexedLayer(layer, 'visible-layer')) {
            const mainLayerId = layer.meta_data.main_layer_id ?? ''
            const mainLayer = findLayer(allLayers, mainLayerId)
            if (mainLayer) {
              return getLayerViewMetricID(viewIDMap, layerStates, mainLayer)
            }
          } else if (isSwingLayer(layer, 'visible-layer')) {
            const combinedLayer = findLayerByVisibleId(allLayers, layer.id)
            if (combinedLayer) {
              return getLayerViewMetricID(viewIDMap, layerStates, combinedLayer)
            }
          } else if (isMultiSectionLayer(layer, 'visible-layer')) {
            const mainLayer = findLayerByVisibleId(allLayers, layer.id)
            if (mainLayer) {
              return getLayerViewMetricID(viewIDMap, layerStates, mainLayer)
            }
          }
        }

        return getLayerViewMetricID(viewIDMap, layerStates, layer)
      })
    )

    this.layerViewIDs$ = this.store.pipe(
      select(selectCededPortfolioViewLayersViewIDs)
    )

    this.grossPortfolioViewID$ = this.store.pipe(
      select(selectGrossPortfolioViewID)
    )

    this.reinsurers$ = this.store.pipe(select(selectReinsurersState))

    this.rss$ = this.store.pipe(select(selectPortfolioViewMetricsRSS))

    this.portfolioMetricsOpen$ = this.store.pipe(
      select(selectAnalysisPortfolioMetricsOpen)
    )
    this.layersPanelOpen$ = this.store.pipe(
      select(selectAnalysisLayersPanelOpen)
    )
    this.propertiesPanelOpen$ = this.store.pipe(
      select(selectAnalysisPropertiesPanelOpen)
    )
    this.showLossSetGroupEditor$ = this.store.pipe(
      select(selectAnalysisLossSetGroupEditor)
    )
    this.selectedProgramID$ = this.store.select(selectCurrentStudyID)
    this.studies$ = this.store.select(selectStudies)
    this.currentProgram$ = this.store.pipe(select(selectCurrentProgram))
    this.authState$ = this.store.pipe(select(selectAuthState))
    this.tierNames$ = this.store.pipe(select(selectPortfolioHeaderTierNames))

    this.showAgg$ = this.store.pipe(select(selectShowAgg))
    this.showOcc$ = this.store.pipe(select(selectShowOcc))
    this.showZoom$ = this.store.pipe(select(selectZoom))
    this.showingTowerTab$ = this.store.pipe(select(selectEditorShowingTowerTab))
    this.showingAnimatedScenariosTab$ = this.store.pipe(
      select(selectEditorShowingAnimatedScenariosTab)
    )
    this.showingExploreGrossTab$ = this.store.pipe(
      select(selectEditorShowingExploreGrossTab)
    )
    this.currentLossFilters$ = this.store.pipe(select(selectCurrentLossFilters))
    this.scenarios$ = this.store.pipe(select(selectCurrentProgramScenarios))
    this.optimizations$ = this.store.pipe(
      select(selectCurrentProgramOptimizations)
    )

    this.parentLossSets$ = this.store.pipe(
      select(selectParentGrossLossSetLayers)
    )
    this.grossLossSets$ = this.store.pipe(select(selectLossSetLayers))
    this.groupEditSelectedLossSets$ = this.store.pipe(
      select(selectGroupEditSelectedLossSets)
    )
    this.lossSetGroupEditMode$ = this.store.pipe(
      select(selectLossSetGroupEditMode)
    )

    this.lossSetGroups$ = this.store.pipe(select(selectLossSetGroupEntities))

    this.showLossSetGroupEditor$ = this.store.pipe(
      select(selectAnalysisLossSetGroupEditor)
    )
    this.lossSetSelectedGroup$ = this.store.pipe(
      select(selectLossSetSelectedGroup)
    )
    this.lossSetSelectionMode$ = this.store.pipe(
      select(selectLossSetSelectionMode)
    )
    this.lossSetGroupsActiveAction$ = this.store.pipe(
      select(selectLossSetGroupsActiveAction)
    )
    this.currentCededLayer$ = this.store.pipe(select(selectCurrentCededLayer))
    this.collapseBySection$ = this.store.pipe(
      select(selectAnalysisPanelsCollapseBySection)
    )
    this.store
      .pipe(
        takeUntil(this.destroy$),
        select(selectCurrentCurrency),
        map(currency => {
          return currency ? currency : 'USD'
        })
      )
      .subscribe((currency: string) => {
        this.currentCurrency = currency
      })
    this.currencyList$ = this.store.pipe(select(selectCurrencyList))
    this.showingTechPremiumTab$ = this.store.pipe(
      select(selectEditorShowingTechnicalPremiumTab)
    )
    this.hideTechPremiumTab$ = this.store.pipe(
      select(hasPricingCurveAccess),
      map(hasAccess => !hasAccess)
    )
    this.canSaveTechnicalPremium$ = this.store.pipe(
      select(selectTechnicalPremiumCanSave)
    )
    this.store.pipe(select(selectCurrentProgram), take(1)).subscribe(data => {
      if (data) {
        this.programService.get(data.id).subscribe(res => {
          if (res && res.data) {
            let currentCurrency: any = null
            if (res.data.structureCurrency) {
              currentCurrency = res.data.structureCurrency
              this.store.dispatch(
                fromAnalysisActions.currentCurrency({ currentCurrency })
              )
            } else {
              this.store
                .pipe(select(selectCededLayers), take(2))
                .subscribe(layers => {
                  if (layers.length > 0) {
                    currentCurrency = layers[0].layer.currency
                    this.store.dispatch(
                      fromAnalysisActions.currentCurrency({ currentCurrency })
                    )
                  }
                })
            }
          }
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onReturnPeriod1Change($event: number): void {
    this.store.dispatch(updateAndFetch({ change: { returnPeriod1: $event } }))
    this.store.dispatch(
      getAllLossSetGroupsMetrics({ change: { returnPeriod1: $event } })
    )
  }

  onReturnPeriod2Change($event: number): void {
    this.store.dispatch(updateAndFetch({ change: { returnPeriod2: $event } }))
    this.store.dispatch(
      getAllLossSetGroupsMetrics({ change: { returnPeriod2: $event } })
    )
  }

  onReturnPeriod3Change($event: number): void {
    this.store.dispatch(updateAndFetch({ change: { returnPeriod3: $event } }))
    this.store.dispatch(
      getAllLossSetGroupsMetrics({ change: { returnPeriod3: $event } })
    )
  }

  onReturnPeriod4Change($event: number): void {
    this.store.dispatch(updateAndFetch({ change: { returnPeriod4: $event } }))
  }

  onReturnPeriod5Change($event: number): void {
    this.store.dispatch(updateAndFetch({ change: { returnPeriod5: $event } }))
  }

  onPortfolioTypeChange($event: PortfolioType): void {
    this.store.dispatch(updateAndFetch({ change: { portfolioType: $event } }))
  }

  onAggregationMethodChange($event: AggregationMethodType): void {
    this.store.dispatch(
      updateAndFetch({ change: { aggregationMethod: $event } })
    )
    this.store.dispatch(
      getAllLossSetGroupsMetrics({ change: { aggregationMethod: $event } })
    )
  }

  onPerspectiveChange($event: Perspective): void {
    this.store.dispatch(updateAndFetch({ change: { perspective: $event } }))
    this.store.dispatch(
      getAllLossSetGroupsMetrics({ change: { perspective: $event } })
    )
  }

  onLossFilterClick($event: string): void {
    this.store.dispatch(updateAndFetch({ change: { lossFilter: $event } }))
    this.store.dispatch(
      getAllLossSetGroupsMetrics({ change: { lossFilter: $event } })
    )
  }

  onResetClick(): void {
    this.store.dispatch(fromAnalysisActions.resetAnalysis())
    this.store.dispatch(resetLayerTypeValues())
  }

  onSaveClick(event: StructureSaveEvent): void {
    if (event.saveAnalysis) {
      this.store.pipe(select(selectCurrentProgram), take(1)).subscribe(data => {
        if (data) {
          const obj = JSON.parse(JSON.stringify(data))
          if (this.structureCurrency) {
            obj.structureCurrency = this.structureCurrency
          }
          this.programService.update(obj.id, obj).subscribe(res => {
            if (res && res.data?.structureCurrency) {
              this.structureCurrency = res.data.structureCurrency
              this.store.dispatch(
                fromAnalysisActions.currentCurrency({
                  currentCurrency: this.structureCurrency,
                })
              )
            }
          })
        }
      })
      this.store.dispatch(fromAnalysisActions.updateLayerNames())
      this.store.dispatch(fromAnalysisActions.saveAnalysis())
    }
    if (event.saveTechPremiumLayerTypes) {
      this.store.dispatch(saveLayerTypeValuesGoingForward())
      this.store.dispatch(saveLayerTypeDefaults())
    }
  }

  onShowDetails(name: string): void {
    const data: PortfolioDetailsDialogData = {
      name,
      currency: this.currentCurrency,
    }
    this.dialog.open(PortfolioDetailsDialogContainerComponent, { data })
  }

  onShowExchange(): void {
    this.dialog.open(PortfolioExchangeDialogContainerComponent, { data: null })
  }

  onShowProperties(): void {
    this.dialog.open(PortfolioPropertiesDialogContainerComponent, {
      data: null,
    })
  }

  onTogglePortfolioMetricsClick(): void {
    this.store.dispatch(fromPanelActions.togglePortfolioMetrics())
  }

  onToggleLayersPanelClick(): void {
    this.store.dispatch(fromPanelActions.toggleLayersPanel())
  }

  onTogglePropertiesPanelClick(): void {
    this.store.dispatch(fromPanelActions.togglePropertiesPanel())
  }

  onSaveAsClick($event: StructureSaveAsEvent): void {
    const nameSuffix = $event.asScenario ? 'Scenario' : 'New Structure'
    const data: SetNameDialogData = {
      actionName: `Save As ${nameSuffix}`,
      otherNames: this.programs.map(s => s.label),
      currentName: $event.name,
      currentDescription: $event.description,
      showDescription: true,
      analysisProfileID: $event.analysisProfileID,
      parentGrossPortfolioID: $event.parentGrossPortfolioID,
    }
    this.dialog
      .open(SetNameDialogComponent, { width: '30vw', data })
      .afterClosed()
      .pipe(
        filter<SetNameDialogResult>(res => !!res),
        map(res =>
          fromAnalysisActions.saveAsAnalysis({
            ...res,
            asScenario: $event.asScenario,
          })
        )
      )
      .subscribe(this.store)
  }

  onStructureNameEdit($event: StructureNameEditEvent): void {
    this.store.dispatch(setProgramNameAndDescription($event))
  }

  onSubmitMarketClick(event$: {
    layers: LayerState[]
    program: Program
    auth: AuthState
    reinsurers: ReinsurerState
    startingCession: StructureLayerDataResponse[]
    marketBlob: BlobResponse
    sharedIDPortfolio: SharedIDPortfolio[]
    selectedClientID: string | null
    selectedYearID: string | null
    layerViewIDs: Record<string, string>
    grossPortfolioViewID: string | null
  }): void {
    if (event$.reinsurers.ids.length > 0) {
      this.submitDialog
        .open(SubmitMarketDialogComponent, {
          width: '100vw',
          height: '90vw',
          data: {
            marketBlob: event$.marketBlob,
            layers: event$.layers,
            currentProgram: event$.program,
            authState: event$.auth,
            reinsurers: event$.reinsurers,
            startingCession: event$.startingCession,
            sharedIDPortfolio: event$.sharedIDPortfolio,
            selectedClientID: event$.selectedClientID,
            selectedYearID: event$.selectedYearID,
            layerViewIDs: event$.layerViewIDs,
            grossPortfolioViewID: event$.grossPortfolioViewID,
          },
        })
        .afterClosed()
        .subscribe(result => {
          if (result !== undefined) {
            // post result to rest endpoint
          }
        })
    }
  }

  onShowAggChange(showAggregate: boolean): void {
    this.store.dispatch(fromAnalysisActions.toggleAggregate({ showAggregate }))
  }

  onShowOccChange(showOccurrence: boolean): void {
    this.store.dispatch(
      fromAnalysisActions.toggleOccurrence({ showOccurrence })
    )
  }

  onShowZoomChange(zoomValue: number): void {
    this.store.dispatch(fromAnalysisActions.updateZoom({ zoomValue }))
  }

  onTowerTabSelected(): void {
    this.store.dispatch(setSelectedLayer({ id: null }))
  }

  onBackClick(): boolean {
    this.router.navigate(['/home'])
    return false
  }

  onScenarioOrOptimizationClick($event: Program): void {
    const portfolioSetID = extractPortfolioSetID($event)
    if (!portfolioSetID) {
      throw new Error(
        'Unexpected Error: Selected Structure has no Analysis Profile ID or Portfolio IDs.'
      )
    }
    const { analysisProfileID, ...portfolioIDs } = portfolioSetID
    this.router
      .navigate(
        [
          '../../../../../../structure',
          $event.id,
          'analysis',
          $event.analysisID,
          'portfolios',
          JSON.stringify(portfolioIDs),
        ],
        { relativeTo: this.route }
      )
      .then(() => {
        this.store.dispatch(setCurrentStructure({ id: $event.id }))
      })
  }

  onGrossSelectionModeClick(): void {
    this.store.dispatch(
      setLossSetSelectionMode({ lossSetSelectionMode: 'Gross' })
    )
  }

  onCededSelectionModeClick(): void {
    this.store.dispatch(
      setLossSetSelectionMode({ lossSetSelectionMode: 'Ceded' })
    )
  }

  onNewLossSetGroupClick(): void {
    this.store.dispatch(
      fromLossSetGroupActions.updateGroupEditLossSets({ lossSetLayers: [] })
    )
    this.store.dispatch(
      fromLossSetGroupActions.updateLossSetGroupMode({ mode: 'new' })
    )
  }

  onCancelLossSetGroupClick(): void {
    this.store.dispatch(
      fromLossSetGroupActions.updateLossSetGroupMode({ mode: '' })
    )
    this.store.dispatch(fromLossSetGroupActions.clearAllDirtyGroups())
  }

  onSaveLossSetGroupClick(lossSetGroup: OmitID<LossSetGroup>): void {
    this.store.dispatch(
      fromLossSetGroupActions.saveGroup({
        lossSetGroup,
      })
    )
  }

  onUpdateLossSetGroupClick(lossSetGroup: LossSetGroup): void {
    this.store.dispatch(
      fromLossSetGroupActions.updateGroup({
        lossSetGroup,
      })
    )
  }

  onLossSetGroupEditorClick(openPanel: boolean): void {
    this.lossSetGroupsMetrics$ = this.store.pipe(
      select(selectLossSetGroupsMetrics)
    )

    combineLatest([
      this.store.pipe(select(selectLossSetLayerViewIDs)),
      this.store.pipe(select(selectLossSetGroupEntities)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([viewIDs, groups]) => {
        if (Object.keys(viewIDs).length > 0 && groups && groups.length > 0) {
          this.store.dispatch(getAllLossSetGroupsMetrics({ change: {} }))
        }
      })

    this.store.dispatch(toggleLossSetGroupEditor({ openPanel }))
  }

  onGroupEditLossSetSelection(lossSetLayers: LossSetLayer[]): void {
    this.store.dispatch(
      fromLossSetGroupActions.updateGroupEditLossSets({ lossSetLayers })
    )
  }

  onLossSetGroupClick($event: any): void {
    this.store.dispatch(
      fromLossSetGroupActions.updateLossSetGroupMode({ mode: $event.mode })
    )
    this.store.dispatch(
      fromLossSetGroupActions.setSelectedLossSetGroup({
        lossSetGroup: $event.lossSetGroup,
      })
    )
  }

  onDeleteLossSetGroupClick(lossSetGroup: LossSetGroup): void {
    this.store.dispatch(fromLossSetGroupActions.deleteGroup({ lossSetGroup }))
  }

  onLossSetSelection(partialLayer: Partial<Layer>): void {
    this.store.dispatch(
      updateLayer({
        // tslint:disable-next-line: no-non-null-assertion
        id: partialLayer.id!,
        change: {
          ...partialLayer,
          lossSetLayers: partialLayer.lossSetLayers,
          meta_data: {
            ...partialLayer.meta_data,
            isChangedInDesign: true,
          },
        },
      })
    )
  }

  onGrossLossSetSelection(lossSetLayers: LossSetLayer[]): void {
    this.store.dispatch(updateGrossLossSets({ lossSetLayers }))
  }

  onLossSetGroupSetDirty(lossSetGroupID: string): void {
    this.store.dispatch(
      fromLossSetGroupActions.setDirtyLossSetGroup({
        lossSetGroupID,
        dirty: true,
      })
    )
  }

  onRemoveRiskLossSets(removeLossSets: RiskLossSetLayerModel): void {
    this.store.dispatch(
      removeRiskLossSetsToLayer({
        id: removeLossSets.id,
        lossSets: removeLossSets.lossSets,
      })
    )
  }

  onAddRiskLossSets(addLossSets: RiskLossSetLayerModel): void {
    this.store.dispatch(
      addRiskLossSetsToLayer({
        id: addLossSets.id,
        lossSets: addLossSets.lossSets,
      })
    )
  }

  onCollapseChange($event: fromPanelActions.AnalysisPanelsCollapseEvent): void {
    this.store.dispatch(
      fromPanelActions.setAnalysisPanelsSectionCollapse($event)
    )
  }

  onOptimizeClick(): void {
    this.optimizationService.open()
  }

  onOpenAddInuranceDialogClick($event: {
    structure: Program
    layer: LayerState
  }): void {
    this.store.dispatch(
      fromAnalysisActions.openAddInuranceDialog({
        currentProgram: $event.structure,
        currentLayer: $event.layer.layer,
        isAdd: true,
        isEdit: false,
      })
    )
  }

  onUpdatePhysicalLayer($event: {
    id: string
    change: Partial<PhysicalLayer>
    debounce?: boolean
    property?: string
  }): void {
    this.store.dispatch(updatePhysicalLayer($event))
  }

  onUpdateLayer($event: {
    id: string
    change: Partial<Layer>
    debounce?: boolean
  }): void {
    this.store.dispatch(updateLayer($event))
  }
}
