// This file holds the base environment configuration. The ng build commands:
//   `ng build --configuration=local`
//   `ng build --configuration=development`
//   `ng build --configuration=uat`
//   `ng build --prod`
// replace the `environment.ts` the respective files:
//   `environment.local.ts`
//   `environment.dev.ts`
//   `environment.uat.ts`
//   `environment.prod.ts`
//
// The list of file replacements are found in `angular.json`,
// and the build commands are found in `package.json`.

export interface EnvValidation {
  name: string
  validator: string
  message: string
}

export const commonEnvironment = {
  production: false,
  timeout: 7200,
  internalApi: {
    facDataVersions: '/facdataversions',
    facPropertySubmission: '/facpropertysubmission',
    facReinsurer: '/facreinsurer',
    facbasisofacceptance: '/facbasisofacceptance',
    faccurrency: '/faccurrency',
    facnamedinsured: '/facnamedinsured',
    facreinsuranceconditions: '/facreinsuranceconditions',
    factermsandconditions: '/factermsandconditions',
    factypeofinsurance: '/factypeofinsurance',
    facBinder: '/facbinder',
    facData: '/facdata',
    sendEmail: '/sendEmail',
    facMailingGroups: '/facmailinggroups',
    facUserType: '/fac_user_type',
    facFromSalesForce: '/facultativefromsalesforce',
    folder: '/folder',
    base: 'https://dev.sage.lockton.com/api',
    users: '/users',
    slipTemplates: '/sliptemplates',
    userRoles: '/userroles',
    roles: '/roles',
    sageFunctionality: '/sagefunctionality',
    permissions: '/permissions',
    market: '/market',
    log: '/log',
    analyzereAuth: '/analyzere/auth',
    portfolioThumbnails: '/imagethumbnails',
    creditThumbnails: '/imagethumbnails/credit',
    facThumbnails: '/imagethumbnails/facthumbnail',
    layerData: '/layerdata',
    marketLayer: '/marketlayer',
    carrierThumbnails: '/imagecarriers',
    deleteFacDocument: '/deleteFacDocument',
    program: {
      base: '/programs',
      tailMetrics: '/tail_metrics',
    },
    captureExportDetails: '/captureExportDetails',
    carrier: '/carriers',
    accountInitiation: '/accountInitiation',
    study: '/study',
    year: '/carrieryears',
    metricSettings: '/studymetricsetting',
    reinsurers: '/reinsurerprogramfactors',
    grossLossGraph: '/grossloss/{grossPortfolioID}/lob/{id}',
    towerPreferences: '/towerpreferences/{id}',
    risk: '/risks',
    riskSection: '/risk_sections',
    riskReinsurer: '/risk_reinsurers',
    riskSubjectivity: '/risk_subjectivities',
    riskExclusion: '/risk_exclusions',
    riskReinstatement: '/risk_reinstatements',
    riskInuringLayer: '/risk_inuring_layers',
    riskReinsurerSubjectivities: '/risk_reinsurer_subjectivities',
    riskAssignedLines: '/risk_assignedLines',
    managementInformation: '/management-information',
    quoteManagementInformation: '/quote-management-information',
    signature: '/signature',
    revenue: '/revenue',
    signatureSalesforceClients: '/signature/salesforceClients',
    subjectivitiesByReinsurer: '/subjectivities/byReinsurerTpRef',
    agencies: '/agencies',
    agenciesByProgram: '/agencies/byProgram',
    agencyDetails: '/agencyDetails',
    agencyDetailsSage: '/agencyDetailsSAGE',
    bureaus: '/bureaus',
    ot_risk: '/ot_risk',
    ot_risk_exportlipTemplate: '/ot_risk/DownloadAndReplaceWordTemplates',
    ot_risk_confirm: '/ot_risk/confirmation',
    ot_risk_sections: '/ot_risk/risksections',
    ot_risk_details: '/ot_risk/riskdetails',
    ot_risk_createquote: '/ot_risk/createquote',
    ot_risk_sections_with_markets: '/ot_risk/risksectionswithmarkets',
    ot_risk_confirm_risk_details: '/ot_risk/confirmRiskDetails',
    processLogs: '/processlogs',
    sharedLimit: '/shared_limits',
    sharedLimitMembers: '/shared_limit_members',
    technicalPremium: '/layer-types-pricing-curves',
    technicalPremiumMappings: '/pricing-curve-layerRef',
    compareView: '/compareviews',
    programRiskCode: '/programriskcode',
    reinsurerPriorYear: '/reinsurer_prior_year_view',
    perils: '/perils',
    territory: '/territory',
    whitespace: '/whitespace',
    whitespace_save_template: '/whitespace/risks/saveTemplate/',
    azureb2c: '/b2c-config',
    whitespace_risks: '/whitespace/risks',
    evm: '/evm/',
    signSets: '/whitespace/risks/{id}/signSets',
    riskCodes: '/lloydsriskcodes',
    savedPricingCurve: '/saved-pricing-curve',
    pricingCurveSelectors: '/pricingcurve/selectors',
    pricingCurveLayers: '/pricingcurve/layers',
    pricingCurveTranches: '/credit-saved-pricing-curve/data',
    pricingCurveSavedCurve: '/saved-pricing-curve',
    pricingCurveSavedCreditCurve: '/credit-saved-pricing-curve',
    pricingCurveCreditPredictions: '/credit-saved-pricing-curve/predictions',
    creditPortfolio: '/credit-portfolio',
    creditCalculation: '/creditcalculation',
    creditStructure: '/creditstructure',
    creditStructureGroup: '/creditgroup',
    creditStructureGroupMember: '/creditgroupmember',
    creditStructureOptimize: '/optimize',
    creditOptimizer: '/optimizer',
    creditMetrics: '/creditmetrics',
    creditSubmissionStructure: '/credit-submission-structure',
    pbiToken: '/security/pbiembedtoken',
    userPreferences: '/user-preferences',
    quoteCustomComparison: '/quotecomparegroup'
  },
  marketApi: {
    base: 'https://lockton-re-site-dev.azurewebsites.net/api',
    authenticate: '/user/authenticate',
    program: '/programs?PartyReference={carrierID}',
    quotations: '/programs/{id}/quotations?PartyReference={carrierID}',
  },
  documentApi: {
    base: 'https://lockton-re-docs-dev.azurewebsites.net/api',
    files: '/files',
    documents: '/documents?PartyReference={carrierID}',
    deleteFacDocuments:
      '/documents/delete?PartyReference={carrierID}&fac-name={facName}&fac-id={facID}&DocumentClasses=All',
    facDocuments:
      '/documents?PartyReference={carrierID}&fac-name={facName}&fac-id={facID}',
  },
  salesforceURI: {
    base: 'https://locktonre--uatfull.sandbox.lightning.force.com',
  },
  whitespaceURI: {
    base: 'https://sandbox.whitespace.co.uk',
  },
  api: {
    base: 'https://lockton-api.analyzere.net',
    portfolios: '/portfolios',
    layers: '/layers',
    batchLayers: '/layers/?ids=',
    portfolioViewPost: '/portfolio_views',
    layerViewsPost: '/layer_views',
    files: '/files',
    eventCatalogs: '/event_catalogs',
    lossFilters: '/loss_filters',
    lossSets: '/loss_sets',
    analysisProfiles: '/analysis_profiles',
    simulations: '/simulations',
    exchangeRateCurrencies: '/exchange_rate_tables',
    metrics: {
      layersView: {
        base: '/layer_views',
        url:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/{returnPeriod1},{returnPeriod2},{returnPeriod3},{returnPeriod4},{returnPeriod5}?perspective={perspective}&aggregation_method={aggregation}&apply_participation=true',
        depositPremium:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=true&perspective=Premium&aggregation_method=AEP',
        expectedCededPremiumBase:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=true&perspective=ReinstatementPremium&aggregation_method=AEP',
        expectedCededLossBase:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=true&perspective=NetLoss&aggregation_method=AEP',
        expectedCededExpensesBase:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=true&perspective=FixedExpense,ProportionalExpense,ProfitCommission&aggregation_method=AEP',
        expectedCededMarginBase:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=true&perspective=Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus&aggregation_method=AEP',
        aepBase:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/{yearParam},0.004?apply_participation=true&perspective=NetLoss&aggregation_method={methodParam}',
        oepWindowVarBase:
          // tslint:disable-next-line: max-line-length
          '/window_metrics/0.100_0.250?apply_participation=true&perspective=NetLoss&aggregation_method=OEP',
        aepWindowVarBase:
          // tslint:disable-next-line: max-line-length
          '/window_metrics/0.100_0.250?apply_participation=true&perspective=NetLoss&aggregation_method=AEP',
        probability: '/exceedance_probabilities/{param}?perspective=NetLoss',
        expensePremium:
          '/tail_metrics/1?loss_perspective=LossNetOfAggregateTerms[Premium]',
        expensePremiumWithoutLoss:
          '/tail_metrics/1?perspective=LossNetOfAggregateTerms[Premium]',
        expectedCededExpensesBaseNoParticipation:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=false&perspective=FixedExpense,ProportionalExpense,ProfitCommission&aggregation_method=AEP',
        expectedCededLossBaseNoParticipation:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=false&perspective=NetLoss&aggregation_method=AEP',
        explore:
          '/tail_metrics/{returnPeriods}?perspective={perspective}&aggregation_method={aggregation}&apply_participation=true{lossFilter}',
        exploreExpense:
          '/tail_metrics/1?apply_participation=false&perspective=FixedExpense,ProportionalExpense,ProfitCommission&aggregation_method=AEP',
        cededYearValue:
          '/tail_metrics/{yearParam}?perspective=NetLoss&aggregation_method=AEP&apply_participation=false',
      },
      portfolioView: {
        base: '/portfolio_views',
        url:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/{returnPeriod1},{returnPeriod2},{returnPeriod3},{returnPeriod4},{returnPeriod5}?perspective={perspective}&aggregation_method={aggregation}&apply_participation=true{lossFilter}',
        detail: {
          lossNetAggregateTermAEP:
            '/tail_metrics/1?perspective=NetLoss&aggregation_method=AEP',
          lossNetPremiumReinstatementAggregateTermAEP:
            '/tail_metrics/1?perspective=NetLoss,ReinstatementPremium&aggregation_method=AEP',
          lossNetAggregateTermsPremiumReinstatementAEP:
            '/tail_metrics/1?perspective=Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus&aggregation_method=AEP',
          reinstatementPremiumAEP:
            '/tail_metrics/1?perspective=ReinstatementPremium&aggregation_method=AEP',
          lossNetOfAggregateTermsAEPReportingPeriod:
            '/tail_metrics/1?perspective=FixedExpense,ProportionalExpense,ProfitCommission&aggregation_method=AEP',
          lossNetAggregateTermAEPParticipation:
            '/tail_metrics/1?perspective=NetLoss&aggregation_method=AEP&apply_participation=true',
          lossNetAggregateTermsPremiumReinstatementAEPParticipation:
            '/tail_metrics/1?perspective=Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus&aggregation_method=AEP&apply_participation=true',
          explore:
            '/tail_metrics/{returnPeriods}?perspective={perspective}&aggregation_method={aggregation}&apply_participation=true{lossFilter}',
          grossTableTotalExpensePremium:
            '/tail_metrics/1?loss_perspective=LossNetOfAggregateTerms[Premium]',
          grossTableTotalExpensePremiumWithoutLoss:
            '/tail_metrics/1?perspective=LossNetOfAggregateTerms[Premium]',
          premiumAEP:
            '/tail_metrics/1?perspective=Premium&aggregation_method=AEP',
        },
        volatility: {
          lossNetAggregateTermAEP:
            '/tail_metrics/1,{year1},{year2}?perspective=NetLoss,ReinstatementPremium&aggregation_method=AEP',
          netStandardDeviation:
            '/tail_metrics/1,0.100?perspective=NetLoss,ReinstatementPremium&aggregation_method=AEP&apply_participation=true',
          lossNetOfAggregateTermsAEPReportingPeriod:
            '/tail_metrics/1,0.100?perspective=FixedExpense,ProportionalExpense,ProfitCommission&aggregation_method=AEP',
        },
        capital: {
          lossNetAggregateTermAEP:
            '/tail_metrics/{year}?perspective=NetLoss&aggregation_method=AEP&filter=cat',
          lossNetAggregateTermOEP:
            '/tail_metrics/0.004?perspective=NetLoss&aggregation_method=OEP&filter=cat',
        },
        efficiency: {
          volatility:
            '/tail_metrics/1?perspective=Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus&aggregation_method=AEP&apply_participation=true',
        },
        misc: {
          programAttachOcc:
            '/exceedance_probabilities/0?perspective=NetLoss&aggregatation_method=OEP',
          programAttachAgg:
            '/exceedance_probabilities/0?perspective=NetLoss&aggregatation_method=AEP',
          probabilityMetric:
            '/exceedance_probabilities/{threshold}?perspective={perspective}&aggregatation_method={aggregation}{lossFilter}',
        },
        tail: {
          url: '/tail_metrics/{year}?perspective={perspective}&aggregation_method={aggregation}{lossFilter}',
        },
      },
    },
  },
}
