import { Injectable } from '@angular/core'
import {
  Fills,
  Fonts,
  QuoteExcelParams,
  QuoteExportModes,
  QuoteExportRowValue,
} from './quote-excel.model'
import { Border, Borders, Cell, Worksheet } from 'exceljs'
import { clone, length, maxBy, reduce } from 'ramda'
import { ExcelExportService } from '@shared/services/excel-export.service'

const AssignedLinesTextMappings: Record<string, string> = {
  underwriterRef: 'Underwriter Ref #',
  written$: '$ Written',
  'written%': 'Written %',
  signed$: '$ Signed',
  'signed%': 'Signed %',
  'recommended%': 'Recommended %',
  placedThrough: 'Placed Through',
  coBroker: 'Correspondent Broker',
  leadMarket: 'Lead Market',
  brokerage: 'Brokerage',
  brokerageRe: 'Brokerage RIP %',
  name: 'Reinsurer',
}

@Injectable({ providedIn: 'root' })
export class QuoteExcelUtils {
  constructor(private exportService: ExcelExportService) {}

  public convertTempAssignedLinesValsToText(
    val: string,
    isDefault: boolean
  ): string | undefined {
    if (Object.keys(AssignedLinesTextMappings).includes(val)) {
      if (val === 'name' && isDefault) {
        return
      }
      return AssignedLinesTextMappings[val]
    }
    return
  }

  public findDescRowIndex(
    rows: string[][],
    header: string,
    isOccLimit: boolean = false
  ): number {
    if (!isOccLimit) {
      return rows.findIndex(val => val[0] === header)
    } else {
      return rows.findIndex(this.containsOccurrenceLimit)
    }
  }

  public getValsAtIndex<T>(
    rowIndex: number,
    valIndex: number,
    quoteParams: QuoteExcelParams
  ): T[] {
    const isDefaultExport = quoteParams.exportMode === QuoteExportModes.DEFAULT
    const expiringrows = quoteParams.expiringReinsurerRows[rowIndex]?.[valIndex]
      ? quoteParams.expiringReinsurerRows[rowIndex][valIndex]
      : []
    const nonexpiringrows = quoteParams.nonFotAndExpiringRows[rowIndex]?.[
      valIndex
    ].length
      ? quoteParams.nonFotAndExpiringRows[rowIndex][valIndex]
      : []

    const slicedNonExpiringRows = isDefaultExport
      ? nonexpiringrows.slice(0, -2)
      : nonexpiringrows

    const firstRows = isDefaultExport ? expiringrows : slicedNonExpiringRows
    const secondRows = isDefaultExport ? slicedNonExpiringRows : expiringrows

    return [
      ...firstRows.map((e: QuoteExportRowValue[]) => e[0] as T),
      ...secondRows.map((e: QuoteExportRowValue[]) => e[0] as T),
    ]
  }

  public getAssignedLinesBorderByIndex(
    isDefault: boolean,
    worksheet: Worksheet,
    cell: Cell
  ): Partial<Borders> {
    const definedBorder: Partial<Border> = {
      style: 'thin',
      color: { argb: '000000' },
    }
    const bottomBorder = {
      bottom: definedBorder,
    }
    if (isDefault) {
      return bottomBorder
    } else {
      const isEmptyColumn = this.isCellInEmptyColumn(worksheet, cell)
      let borders = !isEmptyColumn ? bottomBorder : {}
      const adjustedCellIndex = Number(cell.col) - 1

      if (adjustedCellIndex === 0 || isEmptyColumn) {
        return borders
      }

      if (adjustedCellIndex % 2 === 1) {
        borders = {
          ...borders,
          left: definedBorder,
        }
        if ((adjustedCellIndex + 1) % 8 === 0) {
          borders = {
            ...borders,
            right: definedBorder,
          }
        }
      } else if (adjustedCellIndex % 2 === 0) {
        borders = {
          ...borders,
          right: definedBorder,
        }
      }
      return borders
    }
  }

  public isCellInEmptyColumn(
    worksheet: Worksheet,
    cell?: Cell,
    cellCol?: string
  ): boolean {
    if (!cell && !cellCol) {
      return false
    }

    const col = cell.col || cellCol
    return worksheet
      .getColumn(col)
      .values.every(val => !String(val).trim().length)
  }

  public getSpacesForCurrentRow(num: number = 1): string[] {
    const spaces: string[] = []
    for (let i = 0; i < num; i++) {
      spaces.push('')
    }
    return spaces
  }

  public findLongestArrayLength(input: any[][]): number {
    return reduce(maxBy(length), [], input).length
  }

  public addXOLSubjectPremiumSummaryToWorksheet(
    worksheet: Worksheet,
    quoteParams: QuoteExcelParams
  ): void {
    const subjectPremiumRows = clone(quoteParams.xolSubjectPremiumRows)
    this.exportService.appendBlankRows(worksheet)

    const subjectPremiumRowVals = subjectPremiumRows[0].map(val => val[0])
    const subjectPremiumRow = worksheet.addRow(subjectPremiumRowVals)
    subjectPremiumRow.getCell(1).fill = Fills.Solid_Gray
    subjectPremiumRow.getCell(1).font = Fonts.Segoe_8_White_Bold

    const subjectPremiumCell = subjectPremiumRow.getCell(2)
    subjectPremiumCell.style = {
      font: Fonts.Segoe_8,
      alignment: { horizontal: 'right', wrapText: true },
    }
    const currencySymbol = String(subjectPremiumRowVals[1]).charAt(0)
    if (
      subjectPremiumCell.value &&
      typeof subjectPremiumCell.value === 'number' &&
      subjectPremiumCell.value % 1 !== 0
    ) {
      subjectPremiumCell.numFmt = this.formatCellAsCurrency(
        currencySymbol,
        true
      )
    } else {
      subjectPremiumCell.numFmt = this.formatCellAsCurrency(
        currencySymbol,
        false
      )
    }
    subjectPremiumCell.value =
      this.removeCurrencySymbolFromCell(subjectPremiumCell)

    const headerRow = worksheet.addRow(subjectPremiumRows[1].map(val => val[0]))
    headerRow.eachCell(cell => {
      cell.alignment = { wrapText: true }
      cell.fill = Fills.Solid_Blue
      cell.font = Fonts.Segoe_8_White_Bold
    })

    const sectionRows = subjectPremiumRows.slice(2)
    sectionRows.forEach(row => {
      const sectionRow = worksheet.addRow(row.map(val => val[0]))
      sectionRow.eachCell((cell, colNum) => {
        const cellEntry = row[colNum - 1]
        cell.alignment = { wrapText: true }
        cell.style = {
          font: Fonts.Segoe_8,
          alignment: { horizontal: 'right', wrapText: true },
          border: {
            bottom: { style: 'thin', color: { argb: '000000' } },
          },
        }
        if (cellEntry[1] === 'currency' && cell.value !== 'Unlimited') {
          const currencySymbol = String(cellEntry[0]).charAt(0)
          if (
            cell.value &&
            typeof cell.value === 'number' &&
            cell.value % 1 !== 0
          ) {
            cell.numFmt = this.formatCellAsCurrency(currencySymbol, true)
          } else {
            cell.numFmt = this.formatCellAsCurrency(currencySymbol, false)
          }
          cell.value = this.removeCurrencySymbolFromCell(cell)
        }
      })
    })

    this.exportService.appendBlankRows(worksheet)
  }

  public removeCurrencySymbolFromCell(cell: Cell): number {
    const parsedCellVal = cell.value?.toString().replace(/,/g, '')
    if (!parsedCellVal) {
      return 0
    }
    // Get numeric value after the currency symbols (e.g. £, $, 'MVR', 'COP')
    const currencyRegex = /[a-zA-Z£€$]+/
    return parseFloat(parsedCellVal.split(currencyRegex)[1])
  }

  public formatCellAsCurrency(
    currencySymbol: string,
    hasDecimals: boolean
  ): string {
    const decimals = hasDecimals ? '.0000' : ''
    return `${currencySymbol}#,##0${decimals}`
  }

  // Added Shared Condition for top and drop layers
  private containsOccurrenceLimit(element: string[]) {
    return (
      element[0] === 'Occurrence Limit' ||
      element[0] === 'Shared Per Occurrence Limit' ||
      element[0] === 'Top Occurrence Limit'
    )
  }
}
