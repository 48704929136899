import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
} from '@angular/core'
import {
  AggregationMethodType,
  Perspective,
  VaRTVaR,
} from '../../model/metrics.model'
import { LossSetTableState, ModifierState } from '../store/explore.reducer'
import { LossFilter } from '../../../api/analyzere/analyzere.model'
import { ShortNumberPipe } from '@shared/pipes/short-number.pipe'
import { GrossLossTableExportXlsx } from '../store/explore.model'
import { convertCombinedLossDistributionTable } from '../explore.util'

export interface ModifierData {
  perspectiveChange: Perspective
  aggregationMethodChange: AggregationMethodType
  vartvarChange: VaRTVaR
  isLossRatioViewChange: boolean
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-gross-loss-table',
  styleUrls: ['./gross-loss-table.component.scss'],
  templateUrl: './gross-loss-table.component.html',
})
export class GrossLossDistributionTableComponent implements OnInit, OnChanges {
  @Input() loading: boolean
  @Input() error: boolean
  @Input() dataTable: LossSetTableState[]
  @Input() selectedModifiers: ModifierState
  @Input() lossFilters: LossFilter[]
  @Input() currentStructureCurrency: string | undefined
  @Input() analysisProfileCurrency: string
  @Input() currencyRates: any

  modifierData: ModifierData
  convertedDataTable: LossSetTableState[]
  @Output() modifiersChange = new EventEmitter<{
    rp?: number
    index?: number
    perspective: Perspective
    aggregationMethod: AggregationMethodType
    vartvar: VaRTVaR
    isLossRatioView: boolean
  }>()

  @Output() lossFilterChange = new EventEmitter<{
    lossID?: string
    lossName?: string
    filterValue?: string
    lossType?: string
  }>()

  @Output() exportAsXlsx = new EventEmitter<GrossLossTableExportXlsx>()

  @Output() lossRatioViewChange = new EventEmitter<boolean>()

  get perspective(): Perspective {
    return this.selectedModifiers.perspective
  }

  get aggregationMethod(): AggregationMethodType {
    return this.selectedModifiers.aggregationMethod
  }

  get vartvar(): VaRTVaR {
    return this.selectedModifiers.vartvar
  }

  get isLossRatioView(): boolean {
    return this.selectedModifiers.isLossRatioView || false
  }

  get isError(): string | null {
    if (this.error) {
      return 'Error getting table information'
    }
    return null
  }

  lossFilter = 'all'

  private appShortNumberPipe = new ShortNumberPipe()

  ngOnInit(): void {
    this.modifierData = {
      perspectiveChange: this.perspective,
      aggregationMethodChange: this.aggregationMethod,
      vartvarChange: this.vartvar,
      isLossRatioViewChange: this.isLossRatioView || false,
    }
    this.convertedDataTable = this.dataTable.map(loss => {
      return {
        ...loss,
        aggMethodLossDistributionTable: convertCombinedLossDistributionTable(
          loss.lossDistributionDataTable,
          this.aggregationMethod
        ),
      }
    })
  }

  ngOnChanges(): void {
    this.convertedDataTable = this.dataTable.map(loss => {
      return {
        ...loss,
        aggMethodLossDistributionTable: convertCombinedLossDistributionTable(
          loss.lossDistributionDataTable,
          this.aggregationMethod
        ),
      }
    })
  }

  onExportClick(view: string): void {
    const firstGrossOccurrenceResultData = []
    let occurrenceRows: any = []
    const firstGrossAggregateResultData = []
    let aggregateRows: any = []
    const firstLossRatioResultData = []
    let allGrossLossRatioResultRows: any = []
    const grossOccurrenceResultsOtherValues: any = [] // Contains Mean, std dev and CV values for gross occurrence results
    const grossAggregateResultsOtherValues: any = [] // Contains Mean, std dev and CV values for gross occurrence results
    const grossRatiosOtherValues: any = [] // Contains Mean, std dev and CV values for gross ratios

    for (let i = 0; i < this.convertedDataTable.length; i++) {
      // update gross results details
      if (grossOccurrenceResultsOtherValues[0]) {
        grossOccurrenceResultsOtherValues[0] = {
          ...grossOccurrenceResultsOtherValues[0],
          ['meanValue' + i]:
            this.convertedDataTable[i].lossDistributionDataTable[10].oepValue,
        }
      } else {
        grossOccurrenceResultsOtherValues[0] = {
          label: 'Mean',
          meanValue:
            this.convertedDataTable[i].lossDistributionDataTable[10].oepValue,
        }
      }
      if (grossOccurrenceResultsOtherValues[1]) {
        grossOccurrenceResultsOtherValues[1] = {
          ...grossOccurrenceResultsOtherValues[1],
          ['stdDev' + i]: this.getStdDev(
            this.convertedDataTable[i].lossDistributionDataTable[10]
              .oepVarianceValue
          ),
        }
      } else {
        grossOccurrenceResultsOtherValues[1] = {
          label: 'Std Dev',
          stdDev: this.getStdDev(
            this.convertedDataTable[i].lossDistributionDataTable[10]
              .oepVarianceValue
          ),
        }
      }
      if (grossOccurrenceResultsOtherValues[2]) {
        grossOccurrenceResultsOtherValues[2] = {
          ...grossOccurrenceResultsOtherValues[2],
          ['cvValue' + i]: this.getCV(
            this.getStdDev(
              this.convertedDataTable[i].lossDistributionDataTable[10]
                .oepVarianceValue
            ),
            this.convertedDataTable[i].lossDistributionDataTable[10].oepValue
          ),
        }
      } else {
        grossOccurrenceResultsOtherValues[2] = {
          label: 'CV',
          cvValue: this.getCV(
            this.getStdDev(
              this.convertedDataTable[i].lossDistributionDataTable[10]
                .oepVarianceValue
            ),
            this.convertedDataTable[i].lossDistributionDataTable[10].oepValue
          ),
        }
      }
    }
    for (let i = 0; i < this.convertedDataTable.length; i++) {
      // update gross results details
      if (grossAggregateResultsOtherValues[0]) {
        grossAggregateResultsOtherValues[0] = {
          ...grossAggregateResultsOtherValues[0],
          ['meanValue' + i]:
            this.convertedDataTable[i].lossDistributionDataTable[10].aepValue,
        }
      } else {
        grossAggregateResultsOtherValues[0] = {
          label: 'Mean',
          meanValue:
            this.convertedDataTable[i].lossDistributionDataTable[10].aepValue,
        }
      }
      if (grossAggregateResultsOtherValues[1]) {
        grossAggregateResultsOtherValues[1] = {
          ...grossAggregateResultsOtherValues[1],
          ['stdDev' + i]: this.getStdDev(
            this.convertedDataTable[i].lossDistributionDataTable[10]
              .aepVarianceValue
          ),
        }
      } else {
        grossAggregateResultsOtherValues[1] = {
          label: 'Std Dev',
          stdDev: this.getStdDev(
            this.convertedDataTable[i].lossDistributionDataTable[10]
              .aepVarianceValue
          ),
        }
      }
      if (grossAggregateResultsOtherValues[2]) {
        grossAggregateResultsOtherValues[2] = {
          ...grossAggregateResultsOtherValues[2],
          ['cvValue' + i]: this.getCV(
            this.getStdDev(
              this.convertedDataTable[i].lossDistributionDataTable[10]
                .aepVarianceValue
            ),
            this.convertedDataTable[i].lossDistributionDataTable[10].aepValue
          ),
        }
      } else {
        grossAggregateResultsOtherValues[2] = {
          label: 'CV',
          cvValue: this.getCV(
            this.getStdDev(
              this.convertedDataTable[i].lossDistributionDataTable[10]
                .aepVarianceValue
            ),
            this.convertedDataTable[i].lossDistributionDataTable[10].aepValue
          ),
        }
      }

      // Update gross ratios other values
      if (grossRatiosOtherValues[0]) {
        grossRatiosOtherValues[0] = {
          ...grossRatiosOtherValues[0],
          ['meanValue' + i]:
            this.convertedDataTable[i].aggMethodLossDistributionTable[10]
              .lossRatioValue,
        }
      } else {
        grossRatiosOtherValues[0] = {
          label: 'Mean',
          meanValue:
            this.convertedDataTable[i].aggMethodLossDistributionTable[10]
              .lossRatioValue,
        }
      }

      if (grossRatiosOtherValues[1]) {
        grossRatiosOtherValues[1] = {
          ...grossRatiosOtherValues[1],
          ['stdDev' + i]:
            this.convertedDataTable[i].aggMethodLossDistributionTable[10]
              .lossRatioVarianceValue,
        }
      } else {
        grossRatiosOtherValues[1] = {
          label: 'Std Dev',
          stdDev:
            this.convertedDataTable[i].aggMethodLossDistributionTable[10]
              .lossRatioVarianceValue,
        }
      }
    }
    for (
      let i = 0;
      i < this.convertedDataTable[0].aggMethodLossDistributionTable.length - 1;
      i++
    ) {
      firstLossRatioResultData.push({
        Percentile:
          1 -
          1 /
            this.convertedDataTable[0].aggMethodLossDistributionTable[i]
              .returnPeriod,
        ReturnPeriod:
          this.convertedDataTable[0].aggMethodLossDistributionTable[i]
            .returnPeriod,
        Lossvalue:
          this.convertedDataTable[0].subjectPremiumAmt > 0
            ? this.convertedDataTable[0].aggMethodLossDistributionTable[i]
                .lossRatioValue
            : 'N/A',
      })

      firstGrossOccurrenceResultData.push({
        Percentile:
          1 -
          1 /
            this.convertedDataTable[0].lossDistributionDataTable[i]
              .returnPeriod,
        ReturnPeriod:
          this.convertedDataTable[0].lossDistributionDataTable[i].returnPeriod,
        Lossvalue:
          this.convertedDataTable[0].lossDistributionDataTable[i].oepValue,
      })

      firstGrossAggregateResultData.push({
        Percentile:
          1 -
          1 /
            this.convertedDataTable[0].lossDistributionDataTable[i]
              .returnPeriod,
        ReturnPeriod:
          this.convertedDataTable[0].lossDistributionDataTable[i].returnPeriod,
        Lossvalue:
          this.convertedDataTable[0].lossDistributionDataTable[i].aepValue,
      })
    }

    if (this.convertedDataTable.length > 1) {
      for (let i = 1; i < this.convertedDataTable.length; i++) {
        for (
          let j = 0;
          j <
          this.convertedDataTable[i].aggMethodLossDistributionTable.length - 1;
          j++
        ) {
          if (occurrenceRows[j]) {
            occurrenceRows[j] = {
              ...occurrenceRows[j],
              ['Lossvalue' + i]:
                this.convertedDataTable[i].lossDistributionDataTable[j]
                  .oepValue,
            }
            aggregateRows[j] = {
              ...aggregateRows[j],
              ['Lossvalue' + i]:
                this.convertedDataTable[i].lossDistributionDataTable[j]
                  .aepValue,
            }
            allGrossLossRatioResultRows[j] = {
              ...allGrossLossRatioResultRows[j],
              ['Lossvalue' + i]:
                this.convertedDataTable[i].subjectPremiumAmt > 0
                  ? this.convertedDataTable[i].aggMethodLossDistributionTable[j]
                      .lossRatioValue
                  : 'N/A',
            }
          } else {
            occurrenceRows.push({
              ...firstGrossOccurrenceResultData[j],
              ['Lossvalue' + i]:
                this.convertedDataTable[i].lossDistributionDataTable[j]
                  .oepValue,
            })
            aggregateRows.push({
              ...firstGrossAggregateResultData[j],
              ['Lossvalue' + i]:
                this.convertedDataTable[i].lossDistributionDataTable[j]
                  .aepValue,
            })
            allGrossLossRatioResultRows.push({
              ...firstLossRatioResultData[j],
              ['Lossvalue' + i]:
                this.convertedDataTable[i].subjectPremiumAmt > 0
                  ? this.convertedDataTable[i].aggMethodLossDistributionTable[j]
                      .lossRatioValue
                  : 'N/A',
            })
          }
        }
      }
    } else {
      occurrenceRows = firstGrossOccurrenceResultData
      aggregateRows = firstGrossAggregateResultData
      allGrossLossRatioResultRows = firstLossRatioResultData
    }

    this.exportAsXlsx.emit({
      lossSetDataTable: this.convertedDataTable,
      aggregationMethodType: this.aggregationMethod.toString(),
      vartvarType: this.vartvar.toString(),
      perspectiveType: this.perspective.toString(),
      occurrenceRows,
      aggregateRows,
      excelLossRatioRows: allGrossLossRatioResultRows,
      grossOccurrenceResultsOtherValues,
      grossAggregateResultsOtherValues,
      grossRatiosOtherValues,
      view,
    })
  }

  getValue(
    value: number,
    isLossRatioView: boolean,
    subjectPremium: number
  ): string | number {
    if (isLossRatioView) {
      return this.getLossRatioValue(value, subjectPremium)
    } else {
      return this.appShortNumberPipe.transform(
        value,
        this.currentStructureCurrency
      )
    }
  }

  getLossRatioValue(value: number, subjectPremium: number): string | number {
    return subjectPremium > 0 ? value : 'N/A'
  }

  getStdDev(variance: number): number {
    return Math.sqrt(variance)
  }

  getCV(stdDev: any, mean: number): number | string {
    if (Number(mean.toFixed(2)) === 0) {
      return 0
    } else {
      return (stdDev / Number(mean.toFixed(2))).toFixed(1)
    }
  }

  onRPChange(rp: number, index: number): void {
    this.modifiersChange.emit({
      rp,
      index,
      perspective: this.perspective,
      aggregationMethod: this.aggregationMethod,
      vartvar: this.vartvar,
      isLossRatioView: this.isLossRatioView || false,
    })
  }

  onUpdateModifiersClick(): void {
    this.modifiersChange.emit({
      perspective: this.modifierData.perspectiveChange,
      aggregationMethod: this.modifierData.aggregationMethodChange,
      vartvar: this.modifierData.vartvarChange,
      isLossRatioView: this.modifierData.isLossRatioViewChange,
    })
  }

  onLossFilterClick(event: {
    lossID: string
    lossName: string
    filterValue: string
    lossType: string
  }): void {
    this.lossFilterChange.emit({
      lossID: event.lossID,
      lossName: event.lossName,
      filterValue: event.filterValue,
      lossType: event.lossType,
    })
  }

  onLossRatioViewChange(): void {
    this.modifierData.isLossRatioViewChange =
      !this.modifierData.isLossRatioViewChange
  }
}
