<h1 mat-dialog-title>Submit to OpenTWINS</h1>
<mat-dialog-content class="dialog-content">
  <div>
    <mat-spinner *ngIf="isConfirming" color="accent" [diameter]="20"></mat-spinner>    
    <div class="confirmations" *ngIf="!submitting && !isComplete">
      <span *ngIf="confirmResponse && confirmResponse.isConfirmed">Please confirm the following before submitting:
        <ul *ngIf="confirmResponse.isRenewal">
          <li>Renewal details: {{confirmResponse.renewalRisk.clientName}} ({{confirmResponse.renewalRisk.riskRef}})
            <ul>
              <li>{{confirmResponse.renewalRisk.riskDescription}}</li>
              <li>{{confirmResponse.renewalRisk.riskTypeShort}}</li>
              <li>Status: {{confirmResponse.renewalRisk.riskVersionStatusEnum}}</li>
              <li>Inception: {{formatDate(confirmResponse.renewalRisk.inceptionDateAndTime)}}</li>
              <li>Expiry: {{formatDate(confirmResponse.renewalRisk.expiryDateAndTime)}}</li>
            </ul>
          </li>
        </ul>
        <ul *ngIf="!confirmResponse.isRenewal">
          <li>Reinsured: {{confirmResponse.reinsuredName}} ({{confirmResponse.reinsuredCity}}, {{confirmResponse.reinsuredSubCountry}}, {{confirmResponse.reinsuredCountry}})
            <ul>
              <li>TpRef: {{confirmResponse.reinsuredTpRef}}</li>
            </ul>
          </li>
        </ul>        
      </span>
      <span *ngIf="confirmResponse && !confirmResponse.isConfirmed">You cannot submit because of the following:
        <ul>
          <li *ngFor="let confirmErrorMessage of confirmResponse.confirmErrorMessages">
            {{confirmErrorMessage}}
          </li>
        </ul>
      </span>
      <div class="acknowledgements" *ngIf="confirmResponse && confirmResponse.isConfirmed && confirmResponse.acknowledgements && confirmResponse.acknowledgements.length > 0">
        <span>Review these acknowledgements before proceeding:
          <ul>
            <li *ngFor="let ack of confirmResponse.acknowledgements">
              <input type="checkbox" [(ngModel)]="ack.isAcknowledged"> {{ack.description}}
            </li>
          </ul>
        </span>
      </div>
    </div>
    <div class="errorMessage">
      <span class="error-alert" *ngIf="errorMessage">{{errorMessage}}</span>
    </div>  
    <div class="statusMessage">
      <span *ngIf="processLog" [ngClass]="{'error-alert' : processLog.status === 'ERROR'}" >Status: {{processLog.status}}</span>
    </div>      
    <div class="processLogMessages" *ngIf="processLog">
      <span>Details:</span>
      <ul>
        <li *ngFor="let detail of processLog.processLogDetails">{{detail.step}}
          <ul>
            <li>{{detail.message}}</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button appButton primary link mat-dialog-close class="close">Close</button>
  <button *ngIf="!submitting && !isConfirming && isConfirmed && !isComplete" mat-raised-button (click)="submit()" class="submit">Submit</button>
  <button *ngIf="submitting" mat-raised-button disabled="true" class="submit">Submitting</button><mat-spinner *ngIf="submitting" class="submit" [diameter]="20"></mat-spinner>
</mat-dialog-actions>
