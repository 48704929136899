import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { Program } from '../../../core/model/program.model'
import { NavService } from '../../../nav.service'
import { TierNames } from '../../../tier/tier.model'
import {
  PortfolioSetID,
  StructureSaveAsEvent,
  StructureSaveEvent,
} from '../../model/portfolio-set.model'
import { MultiSectionLayerService } from '../../layers/multi-section-layer.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-portfolio-header',
  styleUrls: ['./portfolio-header.component.scss'],
  templateUrl: './portfolio-header.component.html',
})
export class PortfolioHeaderComponent {
  @Input() tierNames: TierNames
  @Input() dirty: boolean
  @Input() portfolioSetID: PortfolioSetID | null
  @Input() submitMarketPermission: boolean
  @Input() currentStructure?: Program
  @Input() disableActions: boolean
  @Input() disableOptimization: boolean
  @Input() libRETemplate: boolean
  @Input() isLibRE: boolean
  @Input() structureDirty: boolean
  @Input() canSaveTechnicalPremium: boolean
  @Input() activeAction: string

  @Output() resetClick = new EventEmitter<void>()
  @Output() saveClick = new EventEmitter<StructureSaveEvent>()
  @Output() saveAsClick = new EventEmitter<StructureSaveAsEvent>()
  @Output() backClick = new EventEmitter()
  @Output() parentClick = new EventEmitter()
  @Output() editNameClick = new EventEmitter()
  @Output() optimizeClick = new EventEmitter()
  @Output() importLossSetsClick = new EventEmitter()

  constructor(
    readonly navService: NavService,
    readonly mslService: MultiSectionLayerService
  ) {}

  get isOptimization(): boolean {
    return !!this.currentStructure?.isOptimization
  }

  get isScenario(): boolean {
    return !!this.currentStructure?.isScenario
  }

  get subtitle(): string {
    return 'Your core control panel for customizing risk transfer models and opportunities.'
  }

  onSaveClick(disabled: boolean): void {
    if (!disabled) {
      this.saveClick.emit({
        saveAnalysis: this.structureDirty || this.dirty,
        saveTechPremiumLayerTypes: this.canSaveTechnicalPremium,
      })
    }
  }

  onOptimizeClick(): void {
    if (!this.disableOptimization) {
      this.optimizeClick.emit()
    }
  }

  onSaveAsClick(asScenario?: boolean): void {
    if (!this.disableActions) {
      this.saveAsClick.emit({
        name:
          this.tierNames.scenarioOrOptimization ??
          this.tierNames.structure ??
          '',
        description: this.currentStructure?.description ?? '',
        asScenario,
      })
    }
  }

  onResetClick(): void {
    if ((this.dirty || this.canSaveTechnicalPremium) && !this.disableActions) {
      this.resetClick.emit()
    }
  }
}
