import { Selection } from 'd3-selection'
import { Coord, GraphingScale, GraphingScaleType, RectMap } from '../utils/coord'
import { D3SeriesAnnotation } from './series-annotation.model'
import { BenchmarkQuantileDatum } from '../../api/benchmark/data/benchmark-data.model'

export type D3fcLineSelection = Selection<
  SVGGElement,
  readonly [number],
  SVGGElement,
  any
>

export interface D3SeriesDatumWithAnnotations<T, TLine = never> {
  annotations: D3SeriesAnnotation[]
  data: T[]
  xLines?: GraphingLineDatum[]
  yLines?: GraphingLineDatum[]
  quantileSeries?: BenchmarkQuantileDatum[]
  secondaryLines?: TLine[][]
}

export interface D3HexbinCount extends Coord {
  n: number
}

export type GraphingLineDatum =
  | number
  | {
      id: string | number
      name: string
      value: number
    }

export const getGraphLineDatumValue = (d: GraphingLineDatum) => {
  return typeof d === 'number' ? d : d.value
}

export interface GraphingTimeframeRange {
  start: Date
  end: Date
  targetStart: Date
  targetEnd: Date
}

export interface GraphingLineData<TLine = Coord> {
  xLines?: GraphingLineDatum[]
  yLines?: GraphingLineDatum[]
  secondaryLines?: TLine[][]
  quantileSeries?: BenchmarkQuantileDatum[]
  dateRange?: GraphingTimeframeRange
}

export interface GraphingSeriesWithLineData<T, TLine = Coord>
  extends GraphingLineData<TLine> {
  series: T[]
}

type GraphingAxisOption = (scale: GraphingScaleType) => any

type GraphingAxisSideOptions = Partial<RectMap<GraphingAxisOption>>

export interface GraphingCartestianAxes {
  xScale: GraphingScale
  yScale: GraphingScale
  xAxis?: GraphingAxisSideOptions
  yAxis?: GraphingAxisSideOptions
}

export type GraphingStrokeStyle =
  | 'solid'
  | 'bold'
  | 'thin'
  | 'wide'
  | 'dash'
  | 'dot'
  | 'dot-only'
  | 'none'

export type Periodicity = 'annual' | 'interim' | 'quarterly' | 'monthly'

const MONTH_MS = 1000 * 60 * 60 * 24 * 30
export const GRAPHING_PAD_3_MONTHS = MONTH_MS * 3
export const GRAPHING_PAD_6_MONTHS = MONTH_MS * 6
export const GRAPHING_PAD_45_DAYS = MONTH_MS * 1.5
export const GRAPHING_PAD_15_DAYS = MONTH_MS * 0.5

export interface GraphingMetricExtents {
  x?: [number, number]
  y?: [number, number]
}
