import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from '../../core/store'
import {
  selectCurrentClientID,
  selectCurrentStudyID,
  selectCurrentYearStudies,
} from '../../core/store/broker/broker.selectors'
import {
  doesCurrentLayerHaveExpiring,
  isExpanded,
  selectLoading,
  selectQuoteCededLayerID,
  selectQuoteIsGroupSelected,
  selectQuoteIsSLSelected,
  selectQuoteSelectedCompareView,
  selectReinsurers,
  selectRenewedFromRisks,
} from '../store/quote.selectors'
import { expandClick } from '../store/reinsurer/reinsurer.actions'
import {
  QuoteCustomCompareView,
  RenewedFromRiskAndSection,
} from '../models/quote.model'
import { LossSetLayer } from '../../analysis/model/loss-set-layers.model'
import {
  selectCurrentCededLayer,
  selectParentGrossLossSetLayers,
} from '../../analysis/store/analysis.selectors'
import {
  fetchQuoteFieldsForSelectedRiskAndSection,
  openRiskCodeDialog,
  saveQuoteComparison,
} from '../store/quote.actions'
import { backQuickQuote } from '../store/quick-quote/quick-quote.action'
import { Study } from '../../core/model/study.model'
import { AccountOpportunity } from '../../api/model/backend.model'
import { selectAccountOpportunities } from '../../core/store/accountopportunity.selectors'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-container',
  templateUrl: './quote.container.html',
})
export class QuoteContainerComponent implements OnInit {
  clientSelect = false
  selectedClientID$: Observable<string | null>
  isExpanded$: Observable<boolean>
  selectedCededLayerID$: Observable<string | null | undefined>
  isGroupSelected$: Observable<boolean>
  isSLSelected$: Observable<boolean>
  renewedFromRisks$: Observable<RenewedFromRiskAndSection[] | undefined>
  doesCurrentLayerHaveExpiring$: Observable<boolean>
  isLoading$: Observable<boolean>
  lossSetLayers$: Observable<LossSetLayer[]>
  currentProgramId$: Observable<string | null | undefined>
  programs$: Observable<readonly Study[]>
  accountOpportunities$: Observable<AccountOpportunity[] | null>
  layer$: Observable<LayerState | null>
  reinsurers$: Observable<ReinsurerState[]>
  compareView$: Observable<QuoteCustomCompareView | null>

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.selectedClientID$ = this.store.pipe(select(selectCurrentClientID))
    this.isExpanded$ = this.store.pipe(select(isExpanded))
    this.selectedCededLayerID$ = this.store.pipe(
      select(selectQuoteCededLayerID)
    )
    this.isGroupSelected$ = this.store.pipe(select(selectQuoteIsGroupSelected))
    this.isSLSelected$ = this.store.pipe(select(selectQuoteIsSLSelected))
    this.renewedFromRisks$ = this.store.pipe(select(selectRenewedFromRisks))
    this.doesCurrentLayerHaveExpiring$ = this.store.pipe(
      select(doesCurrentLayerHaveExpiring)
    )
    this.isLoading$ = this.store.pipe(select(selectLoading))
    this.lossSetLayers$ = this.store.pipe(
      select(selectParentGrossLossSetLayers)
    )
    this.currentProgramId$ = this.store.pipe(select(selectCurrentStudyID))
    this.programs$ = this.store.pipe(select(selectCurrentYearStudies))
    this.accountOpportunities$ = this.store.pipe(
      select(selectAccountOpportunities)
    )
    this.layer$ = this.store.pipe(select(selectCurrentCededLayer))
    this.reinsurers$ = this.store.pipe(select(selectReinsurers))
    this.compareView$ = this.store.pipe(select(selectQuoteSelectedCompareView))
  }

  onExpandClick($event: { isOpen: boolean; name: string }): void {
    this.store.dispatch(
      expandClick({ isOpen: $event.isOpen, name: $event.name })
    )
  }

  onFetchQuoteFieldsForSelectedRiskAndSection(
    selectedRiskAndSection: RenewedFromRiskAndSection
  ): void {
    const selectedRiskAndSectionAndAB = {
      selectedRiskAndSection,
    }
    this.store.dispatch(
      fetchQuoteFieldsForSelectedRiskAndSection({ selectedRiskAndSectionAndAB })
    )
  }

  onRiskCodeClick(programId: string): void {
    this.store.dispatch(openRiskCodeDialog({ programId }))
  }

  onBackQuickQuoteClick(): void {
    this.store.dispatch(backQuickQuote())
  }

  onSaveCustomComparison(): void {
      this.store.dispatch(saveQuoteComparison({ openAfterSave: true }))
  }
}
