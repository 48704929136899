<div class="table-container">
  <div class="table-container-left-items">
    <h1 mat-dialog-title class="inline">Gross Loss Distribution</h1>
    <button
      appButton
      class="popover-button inline"
      matTooltip="Select from the options to customize this metric."
      matTooltipPosition="above"
      [matMenuTriggerFor]="popoverMenu"
    >
      {{
        ' ' +
        aggregationMethod +
        ' / ' +
        vartvar +
        ' / ' +
        perspective +
        ' '
      }}
    </button>
  </div>
  <div class="table-container-right-items">
    <mat-slide-toggle
      class="loss-ratio-toggle"
      matTooltip="Show table as Loss Ratio"
      matTooltipPosition="below"
      [checked]="modifierData.isLossRatioViewChange"
      (change)="onLossRatioViewChange()"
    >
      <span class="filter-groups">Loss Ratio View</span>
    </mat-slide-toggle>
  </div>
  <div class="table-container-right-items">
    <div class="options">
      <button
        matTooltipPosition="before"
        matTooltip="Click to export XLSX of displayed table."
        appButtonIcon
        huge
        opaque
        [matMenuTriggerFor]="popoverExportMenu"
      >
        cloud_download
      </button>
    </div>
  </div>
</div>
<mat-menu
  panelClass="app-menu-translucent"
  backdropClass="app-theme-dark"
  class="export-popover-menu"
  #popoverExportMenu="matMenu"
  yPosition="above"
  [hasBackdrop]="true"
>
  <button class="export-menu-button" (click)="onExportClick('Occ/Agg')">
    Occ/Agg View
  </button>
  <button class="export-menu-button" (click)="onExportClick('Ratio')">
    Currency/Ratio View
  </button>
</mat-menu>
<mat-menu
  panelClass="app-menu-translucent"
  backdropClass="app-theme-dark"
  #popoverMenu="matMenu"
  yPosition="above"
  [hasBackdrop]="true"
>
  <div class="selection">
    <mat-button-toggle-group
      class="app-mat-theme app-button-toggle-group"
      name="aggregation"
      aria-label="Aggregation"
      [value]="aggregationMethod"
      (click)="$event.stopPropagation()"
      (change)="modifierData.aggregationMethodChange = $event.value"
    >
      <mat-button-toggle value="OEP" disableRipple>OEP</mat-button-toggle>
      <mat-button-toggle value="AEP" disableRipple>AEP</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-button-toggle-group
      class="app-mat-theme app-button-toggle-group"
      name="vartvar"
      aria-label="vartvar"
      [value]="vartvar"
      (click)="$event.stopPropagation()"
      (change)="modifierData.vartvarChange = $event.value"
    >
      <mat-button-toggle value="VaR" disableRipple>VaR</mat-button-toggle>
      <mat-button-toggle value="TVaR" disableRipple>TVaR</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-button-toggle-group
      class="app-mat-theme"
      name="perspective"
      aria-label="Perspective"
      [value]="perspective"
      (click)="$event.stopPropagation()"
      (change)="modifierData.perspectiveChange = $event.value"
    >
      <mat-button-toggle value="Loss" disableRipple>Loss</mat-button-toggle>
      <mat-button-toggle value="LossRp" disableRipple
      >Loss+RP</mat-button-toggle
      >
      <mat-button-toggle value="UW" disableRipple>UW</mat-button-toggle>
    </mat-button-toggle-group>
    <div class="update-button">
      <button
        class="metric-toggles-button"
        appButton
        big
        accent
        border
        (click)="onUpdateModifiersClick()"
      >
        Update
      </button>
    </div>
  </div>
</mat-menu>
<mat-progress-bar
  *ngIf="loading"
  class="progress"
  color="accent"
  mode="indeterminate"
></mat-progress-bar>
<table class="tidy-auto-table">
  <thead>
  <tr>
    <th>Percentile</th>
    <th>Return Period</th>
    <ng-container *ngFor="let row of convertedDataTable">
      <th>
        <div class="header-div">
          <div>{{ row.lossName }}</div>
          <div
            *ngIf="row.lossType === 'Layer' || row.lossType === 'Group'"
          >
            <app-loss-filter
              [lossFilters]="lossFilters"
              [lossID]="row.lossID"
              [lossName]="row.lossName"
              [lossFilter]="row.filterValue"
              [lossType]="row.lossType"
              (lossFilterClick)="onLossFilterClick($event)"
            ></app-loss-filter>
          </div>
        </div>
      </th>
    </ng-container>
  </tr>
  </thead>
  <tbody>
  <ng-container
    *ngFor="let row of convertedDataTable[0].aggMethodLossDistributionTable;
            let i = index">
    <tr *ngIf="i <  convertedDataTable[0].aggMethodLossDistributionTable.length - 1">
      <td>{{ 1 - 1 / row.returnPeriod | percent: '1.1' }}</td>
      <td>
        <button appButton [matMenuTriggerFor]="sliderMenu">
          <span>{{ row.returnPeriod }}</span>
        </button>
        <mat-menu
          #sliderMenu="matMenu"
          yPosition="above"
          class="app-menu-no-max-width"
        >
          <app-return-period-selector
            [returnPeriod]="row.returnPeriod"
            (returnPeriodChange)="onRPChange($event, i)"
            (click)="$event.preventDefault(); $event.stopPropagation()"
          >
          </app-return-period-selector>
        </mat-menu>
      </td>
      <ng-container *ngFor="let row1 of convertedDataTable">
        <td *ngIf="modifierData.isLossRatioViewChange && row1.subjectPremiumAmt > 0;
                  else nonLossRatioView">
          {{
            getValue(
              row1.aggMethodLossDistributionTable[i].lossRatioValue,
              modifierData.isLossRatioViewChange,
              row1.subjectPremiumAmt
            ) | percent: '1.1'
          }}
        </td>
        <ng-template #nonLossRatioView>
          <td>
            {{
              getValue(
                row1.aggMethodLossDistributionTable[i].value,
                modifierData.isLossRatioViewChange,
                row1.subjectPremiumAmt
              )
            }}
          </td>
        </ng-template>
      </ng-container>
    </tr>
  </ng-container>
  <tr>
    <td></td>
    <td>Mean</td>
    <ng-container *ngFor="let row1 of convertedDataTable">
      <td *ngIf="modifierData.isLossRatioViewChange; else nonLossMeanView">
        {{
          row1.aggMethodLossDistributionTable[10].lossRatioValue
            | percent: '1.1'
        }}
      </td>
      <ng-template #nonLossMeanView>
        <td>
          {{
            row1.aggMethodLossDistributionTable[10].value
              | appShortNumber: currentStructureCurrency
          }}
        </td>
      </ng-template>
    </ng-container>
  </tr>
  <tr>
    <td></td>
    <td>Std Dev</td>
    <ng-container *ngFor="let row1 of convertedDataTable">
      <td *ngIf="modifierData.isLossRatioViewChange; else nonLossStdDevView">
        {{
          row1.aggMethodLossDistributionTable[10].lossRatioVarianceValue
            | percent: '1.1'
        }}
      </td>
      <ng-template #nonLossStdDevView>
        <td>
          {{
            getStdDev(
              row1.aggMethodLossDistributionTable[10].varianceValue
            ) | appShortNumber: currentStructureCurrency
          }}
        </td>
      </ng-template>
    </ng-container>
  </tr>
  <tr>
    <td></td>
    <td>CV</td>
    <ng-container *ngFor="let row1 of convertedDataTable">
      <td>
        {{getCV(
            getStdDev(row1.aggMethodLossDistributionTable[10].varianceValue),
            row1.aggMethodLossDistributionTable[10].value)
        }}
      </td>
    </ng-container>
  </tr>
  <tr>
    <td></td>
    <td>Subject Premium</td>
    <ng-container *ngFor="let row1 of convertedDataTable">
      <td>
        {{ row1.subjectPremiumAmt
            | appShortNumber: currentStructureCurrency }}
      </td>
    </ng-container>
  </tr>
  </tbody>
</table>
