<app-compare-structure-options
  *ngIf="view$ | async as view"
  [loading]="view.loading"
  [error]="view.error"
  [props]="props$ | async"
  [propsWithCategory]="propsWithCategory$ | async"
  [data]="view.data"
  [state]="state$ | async"
  [currentCurrency]="compareCurrency"
  (dimensionChange)="onDimensionChange($event)"
></app-compare-structure-options>
