import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '../../shared/shared.module'
import { ExploreRoutingModule } from './explore-routing.module'
import { ExploreComponent } from './explore/explore.component'
import { ExploreContainerComponent } from './explore.container/explore.container'
import { GrossLossDistributionTableComponent } from './gross-loss-table/gross-loss-table.component'
import { GrossLossDistributionGraphComponent } from './gross-loss-graph/gross-loss-graph.component'
import { TierModule } from '../../tier/tier.module'
import { ReactiveFormsModule } from '@angular/forms'
import { ExploreEffects } from './store/explore.effects'
import { EffectsModule } from '@ngrx/effects';
import { ExploreGrossSidebarComponent } from './explore-gross-sidebar/explore-gross-sidebar.component';
import { ExploreGrossSummaryTableComponent } from './explore-gross-summary-table/explore-gross-summary-table.component';
import { ExploreGrossSummaryFilterViewContainerComponent } from './explore-gross-summary-filter-view-container/explore-gross-summary-filter-view-container.component';
import { ExploreGrossSummaryFilterViewDialogComponent } from './explore-gross-summary-filter-view-container/explore-gross-summary-filter-view-dialog/explore-gross-summary-filter-view-dialog.component'

@NgModule({
  declarations: [
    ExploreContainerComponent,
    ExploreComponent,
    GrossLossDistributionTableComponent,
    GrossLossDistributionGraphComponent,
    ExploreGrossSidebarComponent,
    ExploreGrossSummaryTableComponent,
    ExploreGrossSummaryFilterViewContainerComponent,
    ExploreGrossSummaryFilterViewDialogComponent,
  ],
  imports: [
    CommonModule,
    ExploreRoutingModule,
    SharedModule,
    TierModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([ExploreEffects]),
  ],
})
export class ExploreModule {}
