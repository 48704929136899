import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { PortfolioType } from '../analysis/model/portfolio-metrics.model'
import {
  Perspective,
  AggregationMethodType,
  VaRTVaR,
} from '../analysis/model/metrics.model'
import { CompareMetricSetting } from '../analysis/model/compare-metrics.model'
import { LossFilter } from '../api/analyzere/analyzere.model'
import { MatMenuTrigger } from '@angular/material/menu'

export interface MetricUpdateData {
  portfolioTypeChange: PortfolioType
  perspectiveChange: Perspective
  aggregationMethodChange: AggregationMethodType
  vartvarChange: VaRTVaR
  lossFilterChange: string
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metrics-toggles',
  styles: [
    `
           /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
           mat-button-toggle-group {
             margin-bottom: var(--stack);
             width: 100%;
           }

           /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
           mat-button-toggle {
             width: 50%;
           }

           .loss-filter {
             height: 1.5rem;
           }

           .loss-filters-button {
             width: 100%;
             background-color: var(--primary);
             height: auto;
             padding: 0;
             text-align: center;
             line-height: normal;
             display: block;
             min-height: auto;
           }

           .loss-filters-button::after {
             display: none;
           }

           .selected {
             background-color: var(--primary);
             color: var(--body-inverse);
           }

           .reset-selected {
             background-color: var(--body-inverse);
             color: var(--primary);
           }

           .update-button {
             display: flex;
           }

           .metric-toggles-button {
             margin: auto;
             width: fit-content;
           }
         `,
  ],
  template: `
    <mat-button-toggle-group
      class="app-mat-theme"
      name="portfolioType"
      aria-label="Portfolio Type"
      [value]="portfolioType"
      (click)="$event.stopPropagation()"
      (change)="metricUpdateData.portfolioTypeChange = $event.value"
    >
      <mat-button-toggle value="Ceded" disableRipple> Ceded </mat-button-toggle>
      <mat-button-toggle value="Net" disableRipple> Net </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group
      class="app-mat-theme"
      name="vartvar"
      aria-label="VaR/TVaR"
      *ngIf="metrics.category !== 'Misc'"
      (click)="$event.stopPropagation()"
      (change)="metricUpdateData.vartvarChange = $event.value"
      [value]="vartvar"
    >
      <mat-button-toggle value="VaR" disableRipple>VaR</mat-button-toggle>
      <mat-button-toggle value="TVaR" disableRipple>TVaR</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group
      class="app-mat-theme"
      name="aggregation"
      aria-label="Aggregation"
      (click)="$event.stopPropagation()"
      (change)="metricUpdateData.aggregationMethodChange = $event.value"
      [value]="aggregationMethod"
    >
      <mat-button-toggle value="OEP" disableRipple>OEP</mat-button-toggle>
      <mat-button-toggle value="AEP" disableRipple>AEP</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group
      class="app-mat-theme"
      name="perspective"
      (click)="$event.stopPropagation()"
      (change)="onPerspectiveChange($event)"
      aria-label="Perspective"
      [value]="perspective"
    >
      <mat-button-toggle value="Loss" disableRipple>Loss</mat-button-toggle>
      <mat-button-toggle value="LossRp" disableRipple
        >Loss+RP</mat-button-toggle
      >
      <mat-button-toggle value="UW" disableRipple>UW</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group
      class="app-mat-theme loss-filter"
      name="filters"
      aria-label="Loss Filters"
      matTooltip="Set perspective to Loss or Loss+RP to select Loss Filter"
      (click)="$event.stopPropagation()"
    >
      <button
        class="loss-filters-button"
        mat-menu-item
        [disabled]="metricUpdateData.perspectiveChange === 'UW'"
        [matMenuTriggerFor]="lossFiltersMenu"
        [ngClass]="resetLossFilter ? 'reset-selected' : 'selected'"
      >
        {{ lossFilterButtonLabel }}
      </button>
      <mat-menu #lossFiltersMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let f of lossFilters"
          (click)="onLossFilterClick($event.target.innerText)"
        >
          {{ f.description }}
        </button>
      </mat-menu>
    </mat-button-toggle-group>
    <div class="update-button">
      <button
        class="metric-toggles-button"
        appButton
        big
        accent
        border
        (click)="onUpdateMetricClick()"
      >
        Update
      </button>
    </div>
  `,
})
export class MetricsTogglesComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger

  metricUpdateData: MetricUpdateData
  @Input() metrics: CompareMetricSetting
  @Input() lossFilters: LossFilter[]

  @Output() updateMetricClick = new EventEmitter<MetricUpdateData>()

  get portfolioType() {
    return this.metrics.portfolioType
  }

  get perspective() {
    return this.metrics.perspective
  }

  get vartvar() {
    return this.metrics.vartvar
  }

  get aggregationMethod() {
    return this.metrics.aggregationMethodType
  }

  get lossFilter() {
    return this.metrics.lossFilter
  }

  get lossFilterButtonLabel() {
    const description = this.lossFilters.find(
      f => f.name === this.metricUpdateData.lossFilterChange
    )?.description as string
    return description
  }

  get resetLossFilter(): boolean {
    return this.metrics.lossFilter === 'all' || this.perspective !== 'Loss'
  }

  ngOnInit() {
    this.metricUpdateData = {
      portfolioTypeChange: this.metrics.portfolioType
        ? this.metrics.portfolioType
        : 'Net',
      perspectiveChange: this.metrics.perspective
        ? this.metrics.perspective
        : 'Loss',
      aggregationMethodChange: this.metrics.aggregationMethodType
        ? this.metrics.aggregationMethodType
        : 'AEP',
      vartvarChange: this.metrics.vartvar ? this.metrics.vartvar : 'VaR',
      lossFilterChange: this.metrics.lossFilter
        ? this.metrics.lossFilter
        : 'all',
    }
  }

  onLossFilterClick(description: string) {
    const name = this.lossFilters.find(f => f.description === description)?.name
    this.metricUpdateData.lossFilterChange = name ? name : 'all'
    this.trigger.closeMenu()
  }

  onUpdateMetricClick() {
    this.updateMetricClick.emit(this.metricUpdateData)
  }

  onPerspectiveChange($event: any) {
    this.metricUpdateData.perspectiveChange = $event.value
    // Reset loss filter if perspective changed to UW
    if (this.metricUpdateData.perspectiveChange === 'UW') {
      this.metricUpdateData.lossFilterChange = 'all'
    }
  }
}
