import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core'
import { getKeyByValue } from '@shared/util/object'
import {
  CustomDialogData,
  EXPORT_DIALOG_DATA,
  SelectedUserPreferences,
} from '../../core/model/reinsurer.model'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatRadioChange } from '@angular/material/radio'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { Client } from 'src/app/core/model/client.model'
import { AccountOpportunity } from 'src/app/api/model/backend.model'
import { ProgramEvents } from 'src/app/program-events/model/program-events.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-custom-breakouts-dialog',
  styleUrls: ['./reinsurer-custom-breakouts-dialog.component.scss'],
  templateUrl: './reinsurer-custom-breakouts-dialog.component.html',
})
export class ReinsurerCustomBreakoutDialogComponent implements OnInit {
  columnsList: string[]
  breakoutList: string[] = []
  selectedColumnList: string[] = []
  showBreakoutList = false
  activeEntry = true
  includePct: boolean = false
  writtenPercentangeValue: string = 'Yes'
  userPreferencesUpdated: boolean = false
  exportTypeValue: string = 'Accounting'
  header: string = 'Export'
  subHeader: string = 'No prior year data available for this opportunity.'
  options: CustomDialogData = EXPORT_DIALOG_DATA
  getKeyByValue = getKeyByValue
  @Output() exportClick = new EventEmitter<{
    selectedOptions?: string[]
    selectedDisplayColumns?: string[]
    selectedUserPreferences: SelectedUserPreferences
    userPreferencesUpdated: boolean
    programEvents: ProgramEvents
  }>()

  constructor(
    public dialogRef: MatDialogRef<ReinsurerCustomBreakoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      hasPriorData: boolean
      client: Client
      selectedProgramID: string | null | undefined
      opportunityDetails: AccountOpportunity
      userPreferences: SelectedUserPreferences
      userPreferencesColumnList: string[]
      defaultUSAUserPreferences: SelectedUserPreferences
    }
  ) {
    this.breakoutList.push('includePct')
    this.includePct = true
  }

  ngOnInit(): void {
    this.userPreferencesUpdated = false
    this.data.userPreferencesColumnList = this.updateClientApprovalColumn(
      this.data.userPreferencesColumnList
    )
    this.columnsList = this.data.userPreferencesColumnList
    if (this.data.hasPriorData) {
      this.header = 'Export Options'
      this.subHeader =
        'Marketing list includes prior year signed percentages per incumbent markets, if applicable.'
    }
    if (this.data.userPreferences) {
      const parsedPreferenceObject = JSON.parse(
        this.data.userPreferences.preferenceValue
      )
      const customriskDetailsColumns =
        parsedPreferenceObject.customriskDetailsColumns
      if (customriskDetailsColumns.length > 0) {
        this.breakoutList = customriskDetailsColumns
      }
      if (parsedPreferenceObject.selectedColumns.length > 0) {
        this.selectedColumnList = parsedPreferenceObject.selectedColumns
      }
      this.showBreakoutList = parsedPreferenceObject.isCustomBreakoutList
      this.includePct = parsedPreferenceObject.isWritten
      if (this.includePct) {
        this.writtenPercentangeValue = 'Yes'
        this.breakoutList.push('includePct')
      } else {
        this.writtenPercentangeValue = 'No'
        this.breakoutList = this.breakoutList.filter(e => e !== 'includePct')
      }
      if (this.showBreakoutList) {
        this.exportTypeValue = 'Custom'
      } else {
        this.exportTypeValue = 'Accounting'
        this.breakoutList = this.breakoutList.filter(e => e === 'includePct')
      }
    } else if (this.data.opportunityDetails) {
      if (this.data.opportunityDetails.opportunityBU === 'USA') {
        const defaultParsedPreferencesObject = JSON.parse(
          this.data.defaultUSAUserPreferences.preferenceValue
        )
        let defaultSelectedColumns: string[] =
          defaultParsedPreferencesObject.selectedColumns
        if (defaultSelectedColumns.length > 0) {
          this.selectedColumnList = this.updateClientApprovalColumn(
            defaultSelectedColumns
          )
        }
      }
    }
  }

  onExportClick(): void {
    this.selectedColumnList = Array.from(new Set(this.selectedColumnList))
    let customBreakoutList = this.breakoutList
    const programEvents: ProgramEvents = {
      eventType: 'Export Reinsurer Selection',
      programId: this.data.selectedProgramID,
    }
    const userPreferences: SelectedUserPreferences = {
      preferenceType: 'Reinsurer Selection',
      programId: this.data.selectedProgramID,
      preferenceValue: JSON.stringify({
        selectedColumns: this.selectedColumnList,
        isWritten: this.includePct,
        isCustomBreakoutList: this.showBreakoutList,
        customriskDetailsColumns: this.showBreakoutList
          ? customBreakoutList
          : [],
      }),
    }
    if (this.data.userPreferences) {
      const savedUserPreferencesObject: SelectedUserPreferences = {
        preferenceType: this.data.userPreferences.preferenceType,
        programId: this.data.userPreferences.programId,
        preferenceValue: this.data.userPreferences.preferenceValue,
      }
      if (
        JSON.stringify(savedUserPreferencesObject) !==
        JSON.stringify(userPreferences)
      ) {
        this.userPreferencesUpdated = true
      }
    } else if (
      this.data.opportunityDetails &&
      this.data.defaultUSAUserPreferences &&
      this.data.opportunityDetails.opportunityBU === 'USA'
    ) {
      const parsedPreferenceObject = JSON.parse(
        this.data.defaultUSAUserPreferences.preferenceValue
      )
      let defaultColumnList: string[] = parsedPreferenceObject.selectedColumns
      defaultColumnList = this.updateClientApprovalColumn(defaultColumnList)
      const customriskDetailsColumns: string[] =
        parsedPreferenceObject.customriskDetailsColumns
      const isBreakOutList: boolean =
        parsedPreferenceObject.isCustomBreakoutList
      const isWritten: boolean = parsedPreferenceObject.isWritten

      const areColumnsEqual =
        defaultColumnList.length === this.selectedColumnList.length &&
        defaultColumnList
          .sort()
          .every(
            (value, index) => value === this.selectedColumnList.sort()[index]
          )

      const areCustomRiskDetailsColumnsEqual =
        customriskDetailsColumns.length === this.breakoutList.length &&
        customriskDetailsColumns
          .sort()
          .every((value, index) => value === this.breakoutList.sort()[index])

      if (
        !areColumnsEqual ||
        isBreakOutList !== this.showBreakoutList ||
        isWritten !== this.includePct ||
        !areCustomRiskDetailsColumnsEqual
      ) {
        this.userPreferencesUpdated = true
      }
    } else {
      if (
        this.selectedColumnList.length > 0 ||
        !this.includePct ||
        this.showBreakoutList
      ) {
        this.userPreferencesUpdated = true
      }
    }
    if (
      this.selectedColumnList.includes('A.M. Best Rating') &&
      !this.selectedColumnList.includes('A.M. Best Rating Date')
    ) {
      this.selectedColumnList.push('A.M. Best Rating Date')
    }
    if (
      this.selectedColumnList.includes('S&P Rating') &&
      !this.selectedColumnList.includes('S&P Rating Date')
    ) {
      this.selectedColumnList.push('S&P Rating Date')
    }
    if (
      this.selectedColumnList.includes('S&P Outlook') &&
      !this.selectedColumnList.includes('S&P Outlook Date')
    ) {
      this.selectedColumnList.push('S&P Outlook Date')
    }
    if (this.showBreakoutList) {
      this.exportClick.emit({
        selectedOptions: this.breakoutList,
        selectedDisplayColumns: this.selectedColumnList,
        selectedUserPreferences: userPreferences,
        userPreferencesUpdated: this.userPreferencesUpdated,
        programEvents,
      })
    } else {
      if (this.includePct) {
        this.exportClick.emit({
          selectedOptions: ['includePct'],
          selectedDisplayColumns: this.selectedColumnList,
          selectedUserPreferences: userPreferences,
          userPreferencesUpdated: this.userPreferencesUpdated,
          programEvents,
        })
      } else {
        this.exportClick.emit({
          selectedDisplayColumns: this.selectedColumnList,
          selectedUserPreferences: userPreferences,
          userPreferencesUpdated: this.userPreferencesUpdated,
          programEvents,
        })
      }
    }
    this.dialogRef.close()
  }

  writtenPercentageChange(radioChange: MatRadioChange): void {
    this.writtenPercentangeValue = radioChange.value
    this.includePct = radioChange.value == 'Yes' ? true : false
    if (this.includePct) {
      this.breakoutList.push('includePct')
    } else {
      this.breakoutList = this.breakoutList.filter(e => e !== 'includePct')
    }
  }
  onSelectionChange(columns: string[]) {
    this.selectedColumnList = columns
  }

  exportTypeChange(radioChange: MatRadioChange): void {
    this.exportTypeValue = radioChange.value
    this.showBreakoutList = radioChange.value == 'Custom' ? true : false
    if (radioChange.value !== 'Custom') {
      this.breakoutList = this.breakoutList.filter(e => e === 'includePct')
    }
  }

  updateBreakout(event: MatCheckboxChange, breakoutName: string): void {
    if (event.checked) {
      this.breakoutList.push(getKeyByValue(EXPORT_DIALOG_DATA, breakoutName))
    } else {
      this.breakoutList = this.breakoutList.filter(
        e => e !== getKeyByValue(EXPORT_DIALOG_DATA, breakoutName)
      )
    }
  }

  isbreakoutValueChecked(options: CustomDialogData, option: string) {
    return this.breakoutList.includes(getKeyByValue(options, option))
  }
  updateClientApprovalColumn(columnsList: string[]) {
    columnsList = columnsList.map(item =>
      item === 'Client Approval' ? this.data.client.name + ' Approval' : item
    )
    return columnsList
  }
}
