import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core'
import { Router } from '@angular/router'
import { NavService } from '../../nav.service'
import {
  QuoteCustomCompareView,
  QuoteReinsurer,
  RenewedFromRiskAndSection,
} from '../models/quote.model'
import { MatDialog } from '@angular/material/dialog'
import { QuoteExpiringDetailsDialogComponent } from '../expiring-details-dialog/quote-expiring-details-dialog.component'
import { Study } from 'src/app/core/model/study.model'
import { AccountOpportunity } from 'src/app/api/model/backend.model'
import { OpenTwinNewRiskDialogContainerComponent } from 'src/app/slip-templates/opentwins-submission/open-twin-new-risk-dialog-container/open-twin-new-risk-dialog.container'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'

const EXPIRING_DIALOG_ID = 'app-quote-expiring-details-dialog'
@Component({
  selector: 'app-quote-header',
  styleUrls: ['./quote-header.component.scss'],
  templateUrl: './quote-header.component.html',
})
export class QuoteHeaderComponent implements OnInit, OnChanges {
  @Input() showSubjectivity = false
  @Input() showAssignedLines = false
  @Input() showTrackingModule = false
  @Input() showQuickQuote = false
  @Input() showCustomCompareBuilder = false
  @Input() showCustomCompare = false
  @Input() expandedReinsurer: QuoteReinsurer | undefined
  @Input() isExpanded: boolean
  @Input() selectedCededLayerID: string | null | undefined
  @Input() showToggle = false
  @Input() isGroupSelected = false
  @Input() isSLSelected = false
  @Input() renewedFromRisks: RenewedFromRiskAndSection[]
  @Input() doesCurrentLayerHaveExpiring: boolean
  @Input() isLoading: boolean
  @Input() currentProgramId: string | null | undefined
  @Input() programs: Study[]
  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() reinsurers: ReinsurerState[]
  @Input() compareView: QuoteCustomCompareView | null
  @Output() expandClick = new EventEmitter<{ isOpen: boolean; name: string }>()
  @Output() backClick = new EventEmitter()
  @Output() trackingClick = new EventEmitter()
  @Output() exportClick = new EventEmitter()
  @Output() exportCustomCompareClick = new EventEmitter()
  @Output() saveSubjectivityClick = new EventEmitter()
  @Output() saveAssignedLinesClick = new EventEmitter()
  @Output() toggleClick = new EventEmitter()
  @Output() showAssignedLinesClick = new EventEmitter()
  @Output() backAssignedSubjectivitiesClick = new EventEmitter()
  @Output() fetchQuoteFieldsForSelectedRiskAndSection =
    new EventEmitter<RenewedFromRiskAndSection>()
  @Output() showQuickQuoteClick = new EventEmitter()
  @Output() saveQuickQuoteClick = new EventEmitter()
  @Output() riskCodeClick = new EventEmitter<string>()
  @Output() backQuickQuoteClick = new EventEmitter()
  @Output() createdNewRiskRef = new EventEmitter<string>()
  @Output() showCustomCompareClick = new EventEmitter()
  @Output() viewCustomCompareClick = new EventEmitter()
  @Output() saveCustomCompareClick = new EventEmitter()

  name = 'Quote SAGE'
  subtitle =
    'Control panel for gathering quotes and determining firm order terms.'
  isLayerSelected = false
  canCreateRiskRef = false
  subjectivitySaving = false
  hasFOT: boolean
  selectedProgram?: Study | null
  selectedSFOpportunity?: AccountOpportunity | null

  constructor(
    public navService: NavService,
    private router: Router,
    private dialog: MatDialog,
    public otSubmissionDialog: MatDialog
  ) {}

  get expiringTooltip(): string {
    return this.doesCurrentLayerHaveExpiring
      ? `Click to Choose Another Risk Section to Link`
      : `Click to Choose Risk Section to Link`
  }

  get saveButtonDisabled(): boolean {
    if (this.showCustomCompareBuilder) {
      return (
        !this.compareView ||
        !this.compareView.dirty ||
        !this.compareView.name ||
        !this.compareView.members.length
      )
    }
  }

  get saveButtonText(): string {
    if (this.showQuickQuote) {
      return 'Save and Exit'
    }
    return 'Save'
  }

  ngOnInit(): void {
    if (this.selectedCededLayerID) {
      this.isLayerSelected = true
    }
  }

  ngOnChanges(): void {
    this.initializeLayerSelection()
    this.updateSelectedProgram()
    this.updateSFOpportunityAndRiskRef()
    this.checkForFOT()
    this.checkSavingStatus()
  }

  initializeLayerSelection(): void {
    this.isLayerSelected = !!this.selectedCededLayerID
  }

  updateSelectedProgram(): void {
    if (this.currentProgramId && this.programs) {
      this.selectedProgram = this.programs.find(
        program => program.id === this.currentProgramId
      )
    } else {
      this.resetProgramSelection()
    }
  }

  updateSFOpportunityAndRiskRef(): void {
    if (this.selectedProgram?.opportunity_id && this.accountOpportunities) {
      this.setSelectedSFOpportunity()
      this.canCreateRiskRef = true
    } else {
      this.resetOpportunityAndRiskRef()
    }
  }

  setSelectedSFOpportunity(): void {
    if (this.accountOpportunities && this.selectedProgram) {
      this.selectedSFOpportunity = this.accountOpportunities.find(
        opportunity =>
          opportunity.oppId === this.selectedProgram?.opportunity_id
      )
    } else {
      this.selectedSFOpportunity = null
    }
  }

  resetProgramSelection(): void {
    this.selectedProgram = null
    this.resetOpportunityAndRiskRef()
  }

  resetOpportunityAndRiskRef(): void {
    this.selectedSFOpportunity = null
    this.canCreateRiskRef = false
  }

  checkForFOT(): void {
    this.hasFOT = false
    if (this.reinsurers && this.reinsurers.length > 0) {
      for (const re of this.reinsurers) {
        if (re.reinsurer.quoteReinsurerName === 'FOT MASTER') {
          this.hasFOT = true
        }
      }
    }
  }

  onBackClick(): void {
    this.router.navigate(['/analysis/quote']).then(r => r)
  }

  onBackExpandSubjClick(): void {
    if (this.expandedReinsurer && this.expandedReinsurer.quoteReinsurerName) {
      this.expandClick.emit({
        isOpen: true,
        name: this.expandedReinsurer.quoteReinsurerName,
      })
      this.backClick.emit()
    }
  }

  onBackExpand(): void {
    this.expandClick.emit({
      isOpen: false,
      name: '',
    })
  }

  onBackButtonClick(): void {
    if (this.showSubjectivity && this.showAssignedLines) {
      this.backAssignedSubjectivitiesClick.emit()
    } else if (this.showQuickQuote) {
      this.backQuickQuoteClick.emit()
    } else {
      this.backClick.emit()
    }
  }

  onSaveButtonClick(): void {
    if (this.showAssignedLines && !this.showSubjectivity) {
      this.saveAssignedLinesClick.emit()
    } else if (this.showSubjectivity) {
      this.onSaveSubjectivityClick()
    } else if (this.showCustomCompareBuilder) {
      this.saveCustomCompareClick.emit()
    } else if (this.showQuickQuote) {
      this.saveQuickQuoteClick.emit()
    }
  }

  onLinkExpiringClick(): void {
    const dialogRef = this.dialog.open(QuoteExpiringDetailsDialogComponent, {
      id: EXPIRING_DIALOG_ID,
      width: '50%',
      data: {
        renewedFromRisks: this.renewedFromRisks,
      },
      panelClass: [EXPIRING_DIALOG_ID, 'app-dialog-with-sidebar'],
      backdropClass: 'backdropBackground',
      autoFocus: false,
    })

    dialogRef
      .afterClosed()
      .subscribe((selectedRiskAndSection: RenewedFromRiskAndSection) => {
        if (!selectedRiskAndSection) {
          return
        }
        /* Trigger creation of new quote panel w/ reinsurer selected as 'Expiring' and all fields prefilled from OT data */
        this.fetchQuoteFieldsForSelectedRiskAndSection.emit(
          selectedRiskAndSection
        )
      })
  }

  onRiskCodeClick(): void {
    if (this.currentProgramId) {
      this.riskCodeClick.emit(this.currentProgramId)
    }
  }

  createNewRiskRef() {
    if (this.canCreateRiskRef) {
      const dialogRef = this.otSubmissionDialog.open(
        OpenTwinNewRiskDialogContainerComponent
      )
      dialogRef.afterClosed().subscribe(() => {
        this.createdNewRiskRef.emit()
      })
    }
  }

  onSaveSubjectivityClick() {
    this.subjectivitySaving = true
    this.saveSubjectivityClick.emit()
  }

  checkSavingStatus() {
    if (this.subjectivitySaving && !this.showSubjectivity) {
      this.subjectivitySaving = false
    }
  }

  onExportClick(): void {
    if (this.showCustomCompare) {
      this.exportCustomCompareClick.emit()
    } else {
      this.exportClick.emit()
    }
  }
}
