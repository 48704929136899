import { Action, combineReducers, createReducer, on } from '@ngrx/store'
import { reduceReducers } from '@shared/util/reduce-reducers'
import { CreditProgramMetricSetting } from './../../model/credit-metric.model'
import * as fromCreditMetricActions from './credit-metrics.actions'

export interface State {
  currentMetricSettings: CreditProgramMetricSetting[]
  currentCustomMetricSettings: CreditProgramMetricSetting[]
  isLoading: boolean
  isDirty: boolean
  isSaving: boolean
}

export const initialState: State = {
  currentMetricSettings: [],
  currentCustomMetricSettings: [],
  isLoading: false,
  isDirty: false,
  isSaving: false,
}

const currentMetricSettingsReducer = createReducer(
  initialState.currentMetricSettings,
  on(
    fromCreditMetricActions.GetCreditMetricSettingsByProgramSuccess,
    fromCreditMetricActions.SaveCreditMetricSettingsSuccess,
    (_, { programMetricSettings }) =>
      programMetricSettings.filter(m => m.category !== 'Custom')
  ),
  on(
    fromCreditMetricActions.UpdateCreditMetricSetting,
    (state, { programMetricSetting }) => {
      if (programMetricSetting.category === 'Custom') {
        return state
      }
      const programMetricSettings = [...state]
      const metricSettingIndex = programMetricSettings.findIndex(
        s =>
          s.category === programMetricSetting.category &&
          s.ordinal === programMetricSetting.ordinal
      )
      if (metricSettingIndex >= 0) {
        programMetricSettings.splice(
          metricSettingIndex,
          1,
          programMetricSetting
        )
      }
      return programMetricSettings
    }
  ),
  on(
    fromCreditMetricActions.AddCreditMetricSetting,
    (state, { programMetricSetting }) => {
      if (programMetricSetting.category === 'Custom') {
        return state
      }
      return [...state, programMetricSetting]
    }
  ),
  on(
    fromCreditMetricActions.DeleteCreditMetricSettingSuccess,
    (state, { programMetricSetting }) =>
      state.filter(s => s.id !== programMetricSetting.id)
  ),
  on(fromCreditMetricActions.EnableAllCreditMetricSettings, state =>
    state.map(s => {
      return { ...s, show: true }
    })
  )
)

const currentCustomMetricSettingsReducer = createReducer(
  initialState.currentCustomMetricSettings,
  on(
    fromCreditMetricActions.GetCreditMetricSettingsByProgramSuccess,
    fromCreditMetricActions.SaveCreditMetricSettingsSuccess,
    (_, { programMetricSettings }) =>
      programMetricSettings.filter(m => m.category === 'Custom')
  ),
  on(
    fromCreditMetricActions.UpdateCreditMetricSetting,
    (state, { programMetricSetting }) => {
      if (programMetricSetting.category !== 'Custom') {
        return state
      }
      const programMetricSettings = [...state]
      const metricSettingIndex = programMetricSettings.findIndex(
        s =>
          s.category === programMetricSetting.category &&
          s.ordinal === programMetricSetting.ordinal
      )
      if (metricSettingIndex >= 0) {
        programMetricSettings.splice(
          metricSettingIndex,
          1,
          programMetricSetting
        )
      }
      return programMetricSettings
    }
  ),
  on(
    fromCreditMetricActions.AddCreditMetricSetting,
    (state, { programMetricSetting }) => {
      if (programMetricSetting.category !== 'Custom') {
        return state
      }
      return [...state, programMetricSetting]
    }
  ),
  on(
    fromCreditMetricActions.DeleteCreditMetricSettingSuccess,
    (state, { programMetricSetting }) =>
      state.filter(s => s.id !== programMetricSetting.id)
  ),
  on(fromCreditMetricActions.EnableAllCreditMetricSettings, state =>
    state.map(s => {
      return { ...s, show: true }
    })
  )
)

const isLoadingReducer = createReducer(
  initialState.isLoading,
  on(fromCreditMetricActions.GetCreditMetricSettingsByProgram, () => true),
  on(
    fromCreditMetricActions.GetCreditMetricSettingsByProgramSuccess,
    () => false
  )
)

const isDirtyReducer = createReducer(
  initialState.isDirty,
  on(
    fromCreditMetricActions.UpdateCreditMetricSetting,
    fromCreditMetricActions.AddCreditMetricSetting,
    () => true
  ),
  on(fromCreditMetricActions.SaveCreditMetricSettingsSuccess, () => false)
)

const isSavingReducer = createReducer(
  initialState.isSaving,
  on(fromCreditMetricActions.SaveCreditMetricSettings, () => true),
  on(
    fromCreditMetricActions.SaveCreditMetricSettingsSuccess,
    fromCreditMetricActions.SaveCreditMetricSettingsFailure,
    () => false
  )
)

const creditMetricsReducer = combineReducers<State>({
  currentMetricSettings: currentMetricSettingsReducer,
  currentCustomMetricSettings: currentCustomMetricSettingsReducer,
  isLoading: isLoadingReducer,
  isDirty: isDirtyReducer,
  isSaving: isSavingReducer,
})

const reducedReducer = reduceReducers(creditMetricsReducer)

export function reducer(state: State | undefined, action: Action): State {
  return reducedReducer(state, action)
}
