import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators'
import { selectMetricTableSettingsStudyID } from '../analysis/store/analysis.selectors'
import { fetchMetricTableSettings } from '../analysis/store/metrics/metrics-cart/metrics-cart.actions'
import { AppState } from '../core/store'
import { NavService } from '../nav.service'
import { createTierPath } from '../tier/tier.util'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-metrics-route',
  template: `
    <app-metric-container></app-metric-container>
  `,
})
export class MetricsRouteContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private nav: NavService
  ) {}

  ngOnInit(): void {
    this.nav
      .handleTierRoute(this.route)
      .pipe(takeUntil(this.destroy$))
      .subscribe()

    this.route.paramMap
      .pipe(
        map(params => createTierPath(params)),
        map(path => path.program),
        filter<string>(programID => programID != null && programID !== 'none'),
        withLatestFrom(
          this.store.pipe(select(selectMetricTableSettingsStudyID))
        ),
        filter(([next, prev]) => !prev || next !== prev),
        map(([next]) => fetchMetricTableSettings({ studyID: next }))
      )
      .subscribe(this.store)
  }

  ngOnDestroy(): void {
  this.destroy$.next(true)
    this.destroy$.complete()
  }
}
