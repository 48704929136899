import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostBinding,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core'
import { PercentPipe } from '@angular/common'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { race, Subject, timer } from 'rxjs'
import { debounce, takeUntil } from 'rxjs/operators'

interface Metadata {
  type: string
  subtype: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-percentage-input',
  styleUrls: ['./percentage-input.component.scss'],
  templateUrl: './percentage-input.component.html',
})
export class PercentageInputComponent implements OnInit, OnDestroy {
  formattedValue: string | number

  @Input() noLoadingSpinnerOnSubmit = false
  @Input() readonly = false
  @Input() whiteLabel = false
  @Input() isCession: boolean
  @Input() isROL: boolean
  @Input() isReinstatements: boolean
  @Input() isNegative: boolean
  @Input() name: string
  @Input() value: string | number
  @Input() decimal: string
  @Input() metadata: Metadata
  @Input() submitting = false
  @Input() withDraggable = false
  @Input() isAssignedLines: boolean
  @Input() columnName: string | number | symbol
  @Input() isQQ: boolean
  @Input() displayCheckbox: boolean
  @Input() checkboxDisabled: boolean
  @Input() checkboxStatus: boolean
  @Input() checkboxTooltip: string
  @Input() checkboxTooltipDisabled: boolean

  _selectOnFocus = false
  @Input() set selectOnFocus(value: any) {
    this._selectOnFocus = coerceBooleanProperty(value)
  }
  get selectOnFocus() {
    return this._selectOnFocus
  }

  @Input() set cell(value: any) {
    this._cell = coerceBooleanProperty(value)
  }
  get cell() {
    return this._cell
  }
  @HostBinding('class.cell') _cell = false

  @Input() set alignRight(value: any) {
    this._alignRight = coerceBooleanProperty(value)
  }
  get alignRight() {
    return this._alignRight
  }
  @HostBinding('class.align-right') _alignRight = false

  private _spacing: 'tight' | 'loose' = 'loose'
  @Input() set spacing(value: 'tight' | 'loose') {
    this._spacing = value
  }
  get spacing() {
    return this._spacing
  }

  @Input() set hideLabel(value: any) {
    this._hideLabel = coerceBooleanProperty(value)
  }
  get hideLabel() {
    return this._hideLabel
  }
  _hideLabel = false

  @HostBinding('class.tight') get isSpacingTight() {
    return this._spacing === 'tight'
  }

  @HostBinding('class.checkbox') get isCheckboxPresent() {
    return this.displayCheckbox
  }

  @Output() inputChange = new EventEmitter<string | number>()
  @Output() inputKeyup = new EventEmitter<Metadata>()
  @Output() inputSubmit = new EventEmitter<void>()
  @Output() checkboxChange = new EventEmitter<boolean>()

  @ViewChild('input') inputElement: ElementRef

  inputChangeDebounce$ = new Subject<number>()
  focusOutSubject = new Subject<void>()

  private destroy$ = new Subject()

  get isCustomText(): boolean {
    return this.value === 'N/A' || isNaN(Number(this.value))
  }

  get spinnerSize(): number {
    return this.cell ? 16 : 32
  }

  ngOnInit(): void {
    if (!this.isCustomText) {
      this.setNumericValues()
    }

    this.inputChangeDebounce$
      .pipe(
        debounce(() => {
          return race(timer(50000), this.focusOutSubject)
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(val => {
        this.inputChange.emit(val)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onFocus(): void {
    if (this.selectOnFocus && this.inputElement) {
      const el = this.inputElement.nativeElement as HTMLInputElement | undefined
      if (el) {
        el.select()
      }
    }
  }

  onFocusOut(): void {
    this.focusOutSubject.next()
    this.inputSubmit.emit()
  }

  onInput(): void {
    this.inputSubmit.emit()
  }

  blur(): void {
    const els = [this.inputElement]
    els
      .filter(el => el != null)
      .map(el => el.nativeElement as HTMLInputElement)
      .forEach(el => el.blur())
  }

  percentInputChanged(value: number | string): number {
    if (value || (typeof value === 'number' && value === 0)) {
      let valueStr = value.toString()
      valueStr = valueStr.replace(/[%,]/g, '')
      const converted = valueStr ? parseFloat(valueStr) / 100 : 1
      if (this.isCession || this.isReinstatements || this.isROL) {
        return isNaN(converted) ? 0 : converted
      } else {
        return converted > 1 ? 1 : isNaN(converted) ? 0 : converted
      }
    } else {
      return 0
    }
  }

  onChange($event: number | string): void {
    this.inputChange.emit(this.percentInputChanged($event))
  }

  private setNumericValues(): void {
    this.formattedValue = this.setPipePercentage(String(this.value))
  }

  private setPipePercentage(value: string | number): string {
    if (value === 'null') {
      return ''
    }
    if (this.isQQ && (value === '0' || value === 0)) {
      return '-'
    }
    const pipe = new PercentPipe('en-US')
    let percentage: string
    if (this.isCession || this.isROL) {
      percentage = String(
        pipe.transform(value, `1.${this.decimal}-${this.decimal}`)
      )
    } else {
      percentage = String(pipe.transform(value))
    }
    return percentage || ''
  }
}
