import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpHeaders } from '@angular/common/http'
import { md5 } from '@shared/util/hash'
import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import {
  B2CConfig,
  OTCreateNewQuoteRequest,
  OTRiskConfirmRequest,
  OTRiskConfirmResponse,
  OTRiskRequest,
  OTRiskResponse,
  ProcessLog,
  RiskSectionWithMarkets,
  UserInfo,
  StudyDetailsResponse,
} from '../model/backend.model'
import { environment } from '../../../environments/environment'
import { ApiResponse, MaybeError } from '../model/api.model'
import {
  AnalyzeReAuthResponse,
  PermissionsResponse,
  MarketTokenResponse,
  LogEvent,
  User,
  UserRole,
  Role,
  SageFunctionality,
  NewUser,
  NewUserRole,
} from '../model/backend.model'
import { catchAndHandleError, mapToMaybeData, handleError } from '../util'
import { LayerData } from 'src/app/analysis/store/analysis.actions'

import { errorPayload } from '../../error/model/error'
import { AccountMappingData } from '../../program-initiation/model/program-initiation.model'
import { LossSetMappingLabelsResponse, MappingLabels } from 'src/app/core/model/study.model'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(private httpClient: HttpClient) {}

  permissions(): ApiResponse<PermissionsResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.permissions}`
    return this.httpClient
      .get<PermissionsResponse>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Permissions'))
  }

  getMarketToken(): ApiResponse<MarketTokenResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.market}`
    return this.httpClient
      .get<MarketTokenResponse>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Market Token'))
  }

  getAnalyzereToken(): ApiResponse<AnalyzeReAuthResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.analyzereAuth}`
    return this.httpClient
      .get<AnalyzeReAuthResponse>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Analyzere Token'))
  }

  getAllUsers(): ApiResponse<User[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.users}`
    return this.httpClient
      .get<User[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Users'))
  }

  async getEndpoint(endpoint: string) {
    const endpointURL = JSON.parse(JSON.stringify(environment.internalApi))[
      endpoint
    ]
    let url = `${environment.internalApi.base}`
    url += endpointURL
    return await this.httpClient.get<any>(url).toPromise()
  }

  postUser(user: NewUser): Observable<MaybeError> {
    const url = `${environment.internalApi.base}${environment.internalApi.users}`
    return this.httpClient
      .post(url, user)
      .pipe(catchAndHandleError('Create New User'))
  }

  putUser(id: number, userRequest: NewUser): any {
    const url = `${environment.internalApi.base}${environment.internalApi.users}/${id}`
    return this.httpClient
      .put(url, userRequest, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('PUT User'))
  }

  putUserTimeStamp(id: number, userRequest: string) {
    const url = `${environment.internalApi.base}${environment.internalApi.users}/${id}/approval_timestamp`
    return this.httpClient
      .put(url, userRequest, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('PUT User TimeStamp'))
  }

  deleteUser(id: string): any {
    const url = `${environment.internalApi.base}${environment.internalApi.users}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE User'))
  }

  getUserRolesByUserId(userId: number): ApiResponse<UserRole> {
    const url = `${environment.internalApi.base}${environment.internalApi.userRoles}/byuserid/${userId}`
    return this.httpClient
      .get<UserRole>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('User Roles By UserId'))
  }

  postUserRole(newUserRole: NewUserRole[]): Observable<MaybeError> {
    const url = `${environment.internalApi.base}${environment.internalApi.userRoles}`
    return this.httpClient
      .post(url, newUserRole)
      .pipe(catchAndHandleError('Create/Delete User Role'))
  }

  getAllRoles(): ApiResponse<Role> {
    const url = `${environment.internalApi.base}${environment.internalApi.roles}`
    return this.httpClient
      .get<Role>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Analyzere Token'))
  }

  getAllSageFunctionalities(): ApiResponse<SageFunctionality> {
    const url = `${environment.internalApi.base}${environment.internalApi.sageFunctionality}`
    return this.httpClient
      .get<SageFunctionality>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Analyzere Token'))
  }

  postPortfolioThumbnail(fd: FormData): Observable<MaybeError> {
    const url = `${environment.internalApi.base}${environment.internalApi.portfolioThumbnails}`
    return this.httpClient
      .post(url, fd)
      .pipe(catchAndHandleError('Post Portfolio Thumbnail'))
  }

  postCreditThumbnail(fd: FormData): Observable<MaybeError> {
    const url = `${environment.internalApi.base}${environment.internalApi.creditThumbnails}`
    return this.httpClient
      .post(url, fd)
      .pipe(catchAndHandleError('Post Credit Thumbnail'))
  }

  postFacThumbnail(fd: FormData): Observable<MaybeError> {
    const url = `${environment.internalApi.base}${environment.internalApi.facThumbnails}`
    return this.httpClient
      .post(url, fd)
      .pipe(catchAndHandleError('Post Portfolio Thumbnail'))
  }

  checkPortfolioThumbnail(id: string): Observable<MaybeError> {
    const url = `${environment.internalApi.base}${environment.internalApi.portfolioThumbnails}/${id}`
    return this.httpClient
      .get(url)
      .pipe(catchAndHandleError('Check Portfolio Thumbnail'))
  }

  postLayerData(id: string, layerData: LayerData[]): Observable<MaybeError> {
    const url = `${environment.internalApi.base}${environment.internalApi.layerData}/${id}`
    return this.httpClient
      .post(url, layerData)
      .pipe(catchAndHandleError('Post Layer Data'))
  }

  putLayerName(
    layerRef: string,
    layerName: string,
    layerCurrency: string
  ): ApiResponse<RiskSectionWithMarkets> {
    const url = `${environment.internalApi.base}${environment.internalApi.riskSection}/updateLayerNameAndCurrency`
    return this.httpClient
      .put(url, {
        layerRef,
        layerName,
        layerCurrency,
      })
      .pipe(catchAndHandleError('Put Layer Name'))
  }

  postEvent(eventDetails: LogEvent): Observable<MaybeError> {
    const url = `${environment.internalApi.base}${environment.internalApi.log}`
    return this.httpClient
      .post(url, eventDetails)
      .pipe(catchAndHandleError('Post Event'))
  }

  postCarrierThumbnail(fd: FormData): Observable<MaybeError> {
    const url = `${environment.internalApi.base}${environment.internalApi.carrierThumbnails}`
    return this.httpClient
      .post(url, fd)
      .pipe(catchAndHandleError('Post Carrier Thumbnail'))
  }

  getCarriers(): any {
    const url = `${environment.internalApi.base}${environment.internalApi.carrier}`
    return this.httpClient
      .get(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Carriers'))
  }

  getCarrierImage(id: number): any {
    const url = `${environment.internalApi.base}/blobs/carrier-images/${id}`
    return this.httpClient.get(url, { responseType: 'blob' })
  }

  // getReinsurerImage(id: number): any {
  //   const url = `${environment.internalApi.base}/blobs/reinsurer-images/${id}`
  //   return this.httpClient.get(url, { responseType: 'blob' })
  // }

  getThumbnailImage(id: string | undefined, lastModified?: string) {
    let url
    // Use a hash of last modified date to pull thumbnail.
    // Bust the cache with a new hash value if it's been recently modified.
    if (lastModified) {
      const hash = md5(lastModified)
      url = `${environment.internalApi.base}/blobs/thumbnails/${id}?v=${hash}`
    } else {
      url = `${environment.internalApi.base}/blobs/thumbnails/${id}`
    }

    return this.httpClient.get(url, { responseType: 'blob' })
  }

  getExecutiveSummaries(id: string | undefined): any {
    const url = `${environment.internalApi.base}/blobs/executive-summaries/${id}`
    return this.httpClient.get(url, { responseType: 'blob' })
  }

  postCarrier(carrierRequest: any): any {
    const url = `${environment.internalApi.base}${environment.internalApi.carrier}`
    return this.httpClient
      .post(url, carrierRequest, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('POST Carrier'))
  }

  // Salesforce Account Initiation
  postAccountInitiationCarrier(carrierRequest: any): any {
    const url = `${environment.internalApi.base}${environment.internalApi.carrier}${environment.internalApi.accountInitiation}`
    return this.httpClient.post(url, carrierRequest, httpOptions).pipe(
      mapToMaybeData(),
      catchError((e: HttpErrorResponse) => {
        if (e.status === 409) {
          // if backend returns 409 - Conflict then it's a db conflict on the carrier/sfaccount bridge table
          const message = e.error.Message
          const text = 'Carrier conflict'
          const error: any = errorPayload(message, [], { text })
          error.conflict = true
          return of({ error })
        }
        return handleError('postAccountInitiationCarrierError')(e)
      })
    )
  }

  putCarrier(id: number, carrierRequest: any): any {
    const url = `${environment.internalApi.base}${environment.internalApi.carrier}/${id}`
    return this.httpClient
      .put(url, carrierRequest, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('PUT Carrier'))
  }

  deleteCarrier(id: string): any {
    const url = `${environment.internalApi.base}${environment.internalApi.carrier}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE Carrier'))
  }

  updateCarrierMapping(
    accountMapping: AccountMappingData
  ): ApiResponse<AccountMappingData> {
    const url = `${environment.internalApi.base}${environment.internalApi.carrier}/updateCarrierMapping`
    return this.httpClient
      .put<AccountMappingData>(url, accountMapping, httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('UPDATE SF Account Carrier Mapping')
      )
  }

  getYears(): any {
    const url = `${environment.internalApi.base}${environment.internalApi.year}`
    return this.httpClient
      .get(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Years'))
  }

  postYear(yearRequest: any): any {
    const url = `${environment.internalApi.base}${environment.internalApi.year}`
    return this.httpClient
      .post(url, yearRequest, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('POST Year'))
  }

  putYear(id: number, yearRequest: any): any {
    const url = `${environment.internalApi.base}${environment.internalApi.year}/${id}`
    return this.httpClient
      .put(url, yearRequest, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('PUT Year'))
  }

  deleteYear(id: string): any {
    const url = `${environment.internalApi.base}${environment.internalApi.year}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE Year'))
  }
  // get programs
  getStudies(): any {
    const url = `${environment.internalApi.base}${environment.internalApi.study}`
    return this.httpClient
      .get(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Studies'))
  }

  // get single program
  getStudy(programId: string): any {
    const url = `${environment.internalApi.base}${environment.internalApi.study}/${programId}`
    return this.httpClient
      .get(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Study'))
  }

  // get single program
  getStudyByOpportunityId(opportunityId: string): ApiResponse<StudyDetailsResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.study}/byOpportunityId/${opportunityId}`
    return this.httpClient
      .get<StudyDetailsResponse>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Study by Opportunity Id'))
  }

  // post program
  postStudy(studiesRequest: any): any {
    const url = `${environment.internalApi.base}${environment.internalApi.study}`
    return this.httpClient
      .post(url, studiesRequest, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('POST Study'))
  }
  // put program
  putStudy(id: number, studiesRequest: any): any {
    const url = `${environment.internalApi.base}${environment.internalApi.study}/${id}`
    return this.httpClient
      .put(url, studiesRequest, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('PUT Study'))
  }
  // delete program
  deleteStudy(id: string): any {
    const url = `${environment.internalApi.base}${environment.internalApi.study}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE Study'))
  }

  postOpenTWINSRisk(body: OTRiskRequest): ApiResponse<OTRiskResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.ot_risk}`
    return this.httpClient
      .post<OTRiskResponse>(url, body, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('POST OT Risk Submission'))
  }

  confirmOpenTWINSRisk(
    body: OTRiskConfirmRequest
  ): ApiResponse<OTRiskConfirmResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.ot_risk_confirm}`
    return this.httpClient
      .post<OTRiskConfirmResponse>(url, body, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('POST OT Risk Confirmation'))
  }

  getProcessLog(processId: string): ApiResponse<ProcessLog> {
    const url = `${environment.internalApi.base}${environment.internalApi.processLogs}/${processId}`
    return this.httpClient
      .get<ProcessLog>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Process Log'))
  }

  postOpenTWINSCreateQuote(
    body: OTCreateNewQuoteRequest
  ): ApiResponse<OTRiskResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.ot_risk_createquote}`
    return this.httpClient
      .post<OTRiskResponse>(url, body, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('POST OT Risk Submission'))
  }

  getRiskSectionsWithMarketsByOpportunityId(
    salesforceOppId: string
  ): ApiResponse<RiskSectionWithMarkets[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.ot_risk_sections_with_markets}?salesforceOppId=${salesforceOppId}`
    return this.httpClient
      .get<RiskSectionWithMarkets[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Risk Sections with Markets')
      )
  }

  getAzureB2CConfig(
    email: string | null = null,
    passwordReset: boolean = false
  ): ApiResponse<B2CConfig> {
    const url = passwordReset
      ? `${environment.internalApi.base}${environment.internalApi.azureb2c}?passwordReset=true`
      : email
      ? `${environment.internalApi.base}${environment.internalApi.azureb2c}?email=${email}`
      : `${environment.internalApi.base}${environment.internalApi.azureb2c}`

    return this.httpClient
      .get<B2CConfig>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET B2C Config'))
  }

  getUserInfo(): ApiResponse<UserInfo> {
    return this.httpClient
      .post<UserInfo>(
        `${environment.internalApi.base}${environment.internalApi.permissions}`,
        null
      )
      .pipe(mapToMaybeData(), catchAndHandleError('POST Permissions'))
  }

  pushToWSContract(selectContractId: string): ApiResponse<any> {
    const url =
      `${environment.internalApi.base}${environment.internalApi.signSets}`.replace(
        '{id}',
        selectContractId
      )
    return this.httpClient.post<any>(url, {}, httpOptions).pipe(
      mapToMaybeData(),
      catchError((e: HttpErrorResponse) => {
        return handleError('POST Signed % to WhiteSpace ')(e)
      })
    )
  }

  //Post Mapping Labels
  postLossSetMappingLabels(
    id: string,
    mappingLabels: MappingLabels
  ): ApiResponse<LossSetMappingLabelsResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.study}/mappinglabels`
    const body = {
      programid: id,
      mapping_labels: mappingLabels
    }
    return this.httpClient.post<LossSetMappingLabelsResponse>(url, body, httpOptions).pipe(
      mapToMaybeData(),
      catchAndHandleError('POST Mapping Labels')
    )
  }

  //Update Mapping Labels
  putLossSetMappingLabels(
    id: string,
    mappingLabels: MappingLabels
  ): ApiResponse<LossSetMappingLabelsResponse> {
    const url = `${environment.internalApi.base}${environment.internalApi.study}/mappinglabels`
    const body = {
      programid: id,
      mapping_labels: mappingLabels
    }
    return this.httpClient.put<LossSetMappingLabelsResponse>(url, body, httpOptions).pipe(
      mapToMaybeData(),
      catchAndHandleError('POST Mapping Labels')
    )
  }
}
