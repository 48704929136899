<div class="comparison-wrapper">
  <div class="section-left">
    <h3>Saved Comparisons</h3>
    <div class="saved-content">
      <table *ngIf="selectedComparisonView">
        <tr class="new-view-row">
          <td>
            <span class="new-view view-value" (click)="addNewComparison()"
              >Add New Comparison</span
            >
          </td>
        </tr>
        <tr
          class="saved-view"
          *ngFor="let view of customCompareViews"
          appHoverClass
          [ngClass]="{ selected: selectedComparisonView.id === view.id }"
          (click)="selectComparisonView(view)"
        >
          <td class="view-value">{{ view.name }}</td>
          <td class="view-value more">
            <button appButtonIcon big [matMenuTriggerFor]="options">
              more_vert
            </button>
          </td>
          <mat-menu #options="matMenu">
            <button mat-menu-item (click)="onDeleteClick(view.id)">
              Delete
            </button>
          </mat-menu>
        </tr>
      </table>
    </div>
  </div>
  <div class="section-main">
    <mat-form-field appearance="fill" subscriptSizing="dynamic">
      <mat-label>Comparison Name</mat-label>
      <input matInput [formControl]="labelControl" />
    </mat-form-field>
    <table *ngIf="!!reinsurers && !!quoteEntries; else loadingTemplate">
      <tr
        class="parent-row"
        *ngFor="let parentRow of quoteEntries; index as parentIndex"
      >
        <div class="parent-info">
          <td>
            <mat-checkbox
              [checked]="parentRow.selected && !isIndeterminate(parentRow)"
              [indeterminate]="isIndeterminate(parentRow)"
              matTooltip="Select All"
              [matTooltipDisabled]="!parentRow.quoteVersions.length"
              (change)="checkboxChange($event, parentIndex)"
            ></mat-checkbox>
          </td>
          <td>
            <span>{{ parentRow.quoteName }}</span>
          </td>
        </div>
        <div class="child-info" *ngIf="!!parentRow.quoteVersions.length">
          <tr
            class="child-row"
            *ngFor="
              let childRow of parentRow.quoteVersions;
              index as childIndex
            "
          >
            <td>
              <mat-checkbox
                [checked]="childRow.selected"
                (change)="checkboxChange($event, parentIndex, childIndex)"
              ></mat-checkbox>
            </td>
            <td>
              <span>{{ childRow.quoteName }}</span>
            </td>
          </tr>
        </div>
      </tr>
    </table>
  </div>
</div>
<ng-template #loadingTemplate>
  <label appInfoText big class="loading">Loading...</label>
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</ng-template>
