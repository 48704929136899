import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { colors } from '@shared/constants/presetColors'
import {
  SortTableRow,
  SortTableValueChangeEvent,
} from '@shared/sort-table/sort-table.model'
import { AssignedLinesColumnDef } from '../quote-panel/quote-panel-defs'
import {
  AssignedLines,
  brokerOptions,
  BrokerData,
  ReinsurerSubjectivity,
  Subjectivity,
  SignedWarning,
  OrderPercentErrors,
  QuoteReinsurer,
  ReinsurerPhase,
  ReinsurerPhases,
  FOT_MASTER_REINSURER_NAME,
  KnownEntity,
} from '../models/quote.model'
import { clone } from 'ramda'
import { Bureaus, ExternalVendorMapping } from '../../api/model/quote.model'
import {
  AGENCY_MARKET_USE,
  FUND_MANAGER_MARKET_USE,
  FUND_MANAGER_SEG_ACCOUNT_TEXT,
  FUND_MANAGER_UNKNOWN_TEXT,
  LOCKTON_LLP_NAME,
  LOCKTON_LLP_SHARE_DIVISOR,
  Reinsurer,
  SALESFORCE_US_BU,
} from '../../core/model/reinsurer.model'
import { parse } from 'date-fns'
import { Study } from '../../core/model/study.model'
import { AccountOpportunity } from '../../api/model/backend.model'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import { BackendService } from '../../api/backend/backend.service'
import {
  filterTowerLayers,
  isLayerAggFeeder,
} from '../../analysis/model/layers.util'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { MatSnackBar } from '@angular/material/snack-bar'
import { QuotePushWsWarningsDialogComponent } from '../quote-push-ws-warnings-dialog/quote-push-ws-warnings-dialog.component'
import { QuotePushWsConfirmationDialogComponent } from '../quote-push-ws-confirmation-dialog/quote-push-ws-confirmation-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { DynamicMenuItem } from '@shared/dynamic-menu/dynamic-menu.model'
import { Layer } from '../../analysis/model/layers.model'
import { SectionState } from '../store/section/section.reducer'
import { SetOffMarketReinsurerDialogComponent } from '../set-off-market-reinsurer-dialog/set-off-market-reinsurer-dialog.component'
import { isLineSplitLine } from '../quote.util'
import { QuoteExpiringPanelConfirmationDialogComponent } from '../quote-expiring-panel-confirmation-dialog/quote-expiring-panel-confirmation-dialog.component'
import { QuoteReinsurerSubjectivityContainerComponent } from '../reinsurer-subjectivity-dialog/reinsurer-subjectivity-dialog.container'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-assigned-lines',
  styleUrls: ['./quote-assigned-lines.component.scss'],
  templateUrl: './quote-assigned-lines.component.html',
})
export class QuoteAssignedLinesComponent implements OnChanges, OnInit {
  signedWhitespaceWarnings: SignedWarning[]
  signedWhitespaceErrors: SignedWarning[]
  signedConfirmation: Map<string, Map<string, number>>
  tpRefLookup: Map<string, string>
  orderPercentErrors: OrderPercentErrors[]
  pushToWhitespaceTooltip: string | null
  pushToWhitespaceIsDisabled = false
  userPushedToWhitespace = false
  assignedLineRowsTotal: SortTableRow<AssignedLines>[] = []
  totalRow: AssignedLines
  filteredQuoteLayerStates: LayerState[] = []
  allFotVersions: Record<string, QuoteReinsurer[]> = {}
  allExpiringVersions: Record<string, QuoteReinsurer[]> = {}
  populateFromMenuItems: DynamicMenuItem[]
  brokerList: BrokerData[] = brokerOptions
  offPlatformLines: AssignedLines[]

  @Input() sectionList: SectionState[]
  @Input() structureRiskSubjectivityRows: ReinsurerSubjectivity[]
  @Input() selectedCededLayerID: string
  @Input() reinsurers: string[]
  @Input() externalVendor: ExternalVendorMapping
  @Input() isDirty: boolean
  @Input() structureID: string
  @Input() assignedLinesColumnDef: AssignedLinesColumnDef[]
  @Input() assignedLineRows: AssignedLines[] = []
  @Input() selectedReinsurer: string | null
  @Input() reinsurersNameListUpdated: Reinsurer[]
  @Input() layer: LayerState[]
  @Input() reinsurerList: ReinsurerState[]
  @Input() programs: Study[]
  @Input() selectedProgramID?: string | null
  @Input() accountOpportunities: AccountOpportunity[]
  @Input() hasWhitespaceAccess: boolean
  isMarketingListAddInProgress = false

  private _bureaus: Bureaus[] = []
  @Input() set bureaus(value: Bureaus[]) {
    this._bureaus = value
    this.assignedLineRows.forEach(row => {
      if (
        row.bureaus &&
        !this.bureaus.find(
          bureau =>
            row.reinsurer === bureau.name && row.bureaus === bureau.bureau_stamp
        )
      ) {
        this._bureaus = [
          ...this._bureaus,
          {
            id: 0,
            salesforce_id: '',
            name: row.reinsurer ?? '',
            bureau_stamp: row.bureaus,
            bureau_type: '',
            tpRef: row.marketTpRef ?? ''
          },
        ]
      }
    })
  }
  get bureaus() {
    return this._bureaus
  }

  @Output() addNewAssignedLines = new EventEmitter<AssignedLines>()

  @Output() addAssignSubject = new EventEmitter<any>()
  @Output() updateAssignedLines = new EventEmitter<{
    reinsurerID: string
    assignedLinesRows: AssignedLines[]
  }>()
  @Output() populateAssignedLinesFromWhiteSpace = new EventEmitter<{
    reinsurerID: string | null
  }>()
  @Output() deleteAssignedLines = new EventEmitter<{ id: string }>()
  @Output() updateOffMarketLines =
    new EventEmitter<ReinsurerState[]>()
  @Output() subjectivityALClick = new EventEmitter<{
    reName: string
    ralId: number
  }>()
  @Output() showSegregatedAccountModal = new EventEmitter<{
    programID: string
    fundManager: Reinsurer
  }>()

  readonly invalidDate = this.parseDate(
    '1900-01-01T00:00:00',
    "yyyy-MM-dd'T'HH:mm:ss"
  )

  whiteSpaceSyncDate: string

  constructor(
    private backendService: BackendService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getTotalRow()
  }

  findAndSetSplitLines(
    rows: SortTableRow<AssignedLines>[]
  ): SortTableRow<AssignedLines>[] {
    const linesClone: SortTableRow<AssignedLines>[] = JSON.parse(
      JSON.stringify(rows)
    )

    let colorIdx = 0
    linesClone.forEach(line => {
      // Finding if line is split
      if (!line.whiteSpaceWSetID) {
        return
      }
      if (line.splitLineIndexColor) {
        return
      }
      const otherSplitLines = linesClone.filter(
        l =>
        l !== line &&
          l.whiteSpaceImpressionIdx === line.whiteSpaceImpressionIdx &&
          l.whiteSpaceWSetID === line.whiteSpaceWSetID
      )
      if (otherSplitLines.length > 0) {
        line.splitLineIndexColor = colorIdx
        otherSplitLines.forEach(l => {
          l.splitLineIndexColor = colorIdx
        })
        colorIdx++
      }
    })
    return linesClone
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.layer) {
      this.userPushedToWhitespace = false
      this.filteredQuoteLayerStates = this.layer
        .filter(filterTowerLayers)
        .filter(
          ls =>
            !isLayerAggFeeder(ls.layer) &&
            ls.layer.physicalLayer.limit.value > 0
        )
    }
    if (
      changes &&
      changes.assignedLineRows &&
      changes.assignedLineRows?.previousValue !==
        changes.assignedLineRows?.currentValue
    ) {
      if (changes.assignedLineRows?.currentValue.length > 0) {
        this.whiteSpaceSyncDate = ''
        changes.assignedLineRows?.currentValue.forEach(
          (assignedLineObj: AssignedLines) => {
            if (assignedLineObj && assignedLineObj.whiteSpaceSyncedAt) {
              this.whiteSpaceSyncDate = assignedLineObj.whiteSpaceSyncedAt
            }
          }
        )
      }
      this.getTotalRow()
      this.getPushToWhiteSpaceErrors()
    }
    if (changes.reinsurerList) {
      const fots = this.reinsurerList.filter(
        r => r.reinsurer.reinsurerPhase === ReinsurerPhases.FOT
      )
      const expirings = this.reinsurerList.filter(
        r => r.reinsurer.reinsurerPhase === ReinsurerPhases.Expiring
      )
      this.allFotVersions = this.mapToReinsurersByLayerId(fots)
      this.allExpiringVersions = this.mapToReinsurersByLayerId(expirings)
      this.populateFromMenuItems = this.buildPopulateFromMenuItems()
    }
    this.offPlatformLines = this.assignedLineRows.filter(
      line => !line.marketTpRef
    )
  }

  openSetLineReinsurerDialog(): void {
    new Promise<ReinsurerState[]>((resolve, reject) => {
      const dialogRef = this.dialog.open(SetOffMarketReinsurerDialogComponent, {
        disableClose: true,
        data: {
          reinsurerOptions: this.reinsurers,
          reinsurers: this.reinsurerList,
          sectionList: this.sectionList,
          bureaus: this.bureaus,
        },
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          resolve(result)
        } else {
          reject()
        }
      })
    }).then(result => {
      this.updateOffMarketLines.emit(result)
    })
  }

  onMenuItemClick(item: DynamicMenuItem): void {
    const layer = this.filteredQuoteLayerStates.find(
      f => f.layer.id === item.metadata?.quoteLayerId
    )?.layer
    const phase = item.metadata?.quotePhase
    if (!layer || !phase) {
      return
    }
    if (phase === ReinsurerPhases.Quote) {
      this.getItemSelected(layer, phase)
    } else {
      this.getItemSelected(layer, phase, item.metadata?.quoteVersionLabel)
    }
  }

  addIndicator(): AssignedLines[] {
    const newData: AssignedLines[] = []
    this.assignedLineRows.forEach(obj => {
      const obj1 = this.reinsurersNameListUpdated.filter(
        x => x.tpRef === obj.marketTpRef
      )
      const d = {
        indicator: this.isReinsurerOutsideOpportunityTermDate(obj1[0]),
      }
      const rowData: AssignedLines = { ...obj, ...d }
      newData.push(rowData)
    })
    return newData
  }

  setPushToWhitespceButtonState(disabled: boolean, message: string): void {
    this.pushToWhitespaceIsDisabled = disabled
    this.pushToWhitespaceTooltip = message
  }

  pushToWhiteSpaceHardStops(): boolean {
    if (!this.whiteSpaceSyncDate) {
      this.setPushToWhitespceButtonState(
        true,
        'You must sync from Whitespace first to push to Whitespace'
      )
      return false
    }
    if (this.externalVendor && this.externalVendor.signedBy) {
      this.setPushToWhitespceButtonState(
        true,
        `Already signed by ${this.externalVendor.signedBy} at ${new Date(
          this.externalVendor.signedAt
        ).toString()}`
      )
      return false
    }
    if (this.userPushedToWhitespace) {
      this.setPushToWhitespceButtonState(
        true,
        `You have already signed this structure`
      )
      return false
    }
    if (this.isDirty) {
      this.setPushToWhitespceButtonState(
        true,
        `Quote must be saved before pushing to whitespace`
      )
      return false
    }
    return true
  }

  stripPrecision(value: number): number {
    return Math.round(value * 10000)
  }

  getPushToWhiteSpaceErrors(): void {
    if (!this.pushToWhiteSpaceHardStops()) {
      return
    }
    this.setPushToWhitespceButtonState(false, '')
    const currentWarnings: SignedWarning[] = []
    const currentErrors: SignedWarning[] = []
    const currentSignedLines = new Map<string, Map<string, number>>() // Map<marketTPRef, Map<LayerName, SignedValue>>
    const tpRefLookup = new Map<string, string>()
    const orderPercentErrors: OrderPercentErrors[] = []
    const orderPercentIsActive = !!this.sectionList.find(
      x => x.section.orderPercentToggle
    )
    this.reinsurerList.forEach((reinsurer, reinsurerIndex) => {
      const foundLayerName =
        this.sectionList.find(
          x => x.section.layerRef === reinsurer.reinsurer.cededlayerID
        )?.section.layerName || `Layer ${reinsurerIndex + 1}` // Gives layer name if no name is found

      const orderPercentCeiling =
        (orderPercentIsActive
          ? reinsurer.reinsurer.quoteFields?.orderPercent
          : Infinity) || Infinity
      let cumulativeSignedPercent = 0

      reinsurer.reinsurer.riskAssignedLinesLink?.forEach(
        (assignedLine, index) => {
          cumulativeSignedPercent += assignedLine.signed || 0
          if (!assignedLine.whiteSpaceSyncedBy) {
            // Only check whitespace lines
            return
          }

          let marketTpRef = assignedLine.marketTpRef
          if (!marketTpRef) {
            if (assignedLine.reinsurer) {
              // In the case of no TpRef, check to see if user has manually set name and use that for grouping
              marketTpRef = assignedLine.reinsurer
            } else {
              marketTpRef = ''
            }
          }
          let reinsurerMap = currentSignedLines.get(marketTpRef)
          if (!reinsurerMap) {
            reinsurerMap = new Map<string, number>()
          }
          if (assignedLine.signed !== undefined) {
            if (reinsurerMap.has(foundLayerName)) {
              reinsurerMap.set(
                foundLayerName,
                assignedLine.signed + (reinsurerMap.get(foundLayerName) || 0)
              )
            } else {
              currentSignedLines.set(marketTpRef, reinsurerMap)
              reinsurerMap.set(foundLayerName, assignedLine.signed)
            }
          }
          if (assignedLine.reinsurer !== undefined) {
            tpRefLookup.set(marketTpRef, assignedLine.reinsurer)
          }

          if (
            isLineSplitLine(
              assignedLine,
              reinsurer.reinsurer.riskAssignedLinesLink as AssignedLines[]
            )
          ) {
            // If row is a split line, don't show warnings/errors
            return
          }
          if (
            (!assignedLine.signed && typeof assignedLine.signed !== 'number') ||
            (!assignedLine.written && typeof assignedLine.written !== 'number')
          ) {
            currentErrors.push({
              layer: foundLayerName,
              line: index,
              title: assignedLine.reinsurer,
              type: 'null',
            })
            return
          }
          if (assignedLine.signed > assignedLine.written) {
            currentErrors.push({
              layer: foundLayerName,
              line: index,
              title: assignedLine.reinsurer,
              type: 'signed>written',
            })
          }
          if (assignedLine.signed === 0) {
            currentWarnings.push({
              layer: foundLayerName,
              line: index,
              title: assignedLine.reinsurer,
              type: 'signed=0',
            })
          }
        }
      )
      if (this.stripPrecision(cumulativeSignedPercent) > this.stripPrecision(orderPercentCeiling)) {
        orderPercentErrors.push({
          layer: foundLayerName,
          orderPercent: orderPercentCeiling,
          totalSignedPercent: cumulativeSignedPercent,
        })
      }
    })
    this.tpRefLookup = tpRefLookup
    this.orderPercentErrors = orderPercentErrors
    this.signedWhitespaceErrors = currentErrors
    this.signedWhitespaceWarnings = currentWarnings
    this.signedConfirmation = currentSignedLines
  }

  getTotalRow(): void {
    const rows = this.addIndicator()

    const lines: SortTableRow<AssignedLines>[] = this.findAndSetSplitLines(rows)
    lines.forEach(line => {
      if (line.splitLineIndexColor) {
        line.style = {
          'background-color': colors[line.splitLineIndexColor],
          'background-image': 'linear-gradient(rgb(0 0 0/80%) 0 0)',
        }
      }
    })
    this.assignedLineRowsTotal = lines.map(a => {
      let signedOfAuthorized = (a.signed || 0) / (a.written || 1)
      if (a.signedOfAuthorized !== 0) {
        signedOfAuthorized = a.signedOfAuthorized || 0
      }
      if (a.written === 0) {
        signedOfAuthorized = 0
      }
      if (a.whiteSpaceWSetID) {
        a.externalVendor = 'Whitespace'
      }
      return { ...a, signedOfAuthorized }
    })
    this.totalRow = {
      reinsurer: 'Total',
      written: this.calculateTotal('written'),
      recommended: this.calculateTotal('recommended'),
      signed: this.calculateTotal('signed'),
      signedOfAuthorized:
        this.calculateTotal('signed') / this.calculateTotal('written') || 0,
    }
  }

  calculateTotal(type: string): number {
    let total = 0
    this.assignedLineRows.forEach(a => {
      if (type === 'written') {
        total += a.written || 0
      } else if (type === 'recommended') {
        total += a.recommended || 0
      } else if (type === 'signed') {
        total += a.signed || 0
      }
    })
    return total
  }

  onValueChange({
    id,
    column,
    value,
  }: SortTableValueChangeEvent<AssignedLines>) {
    if (this.selectedReinsurer) {
      const tempRows: any[] = clone(this.assignedLineRows) || []
      const row: AssignedLines | undefined = tempRows.find(r => r.id === id)
      if (!row) {
        return
      }
      // @ts-ignore
      row[column.id] = value
      if (
        (column.id === 'written' || column.id === 'signed') &&
        row.written &&
        row.signed
      ) {
        row.signedOfAuthorized = row.signed / row.written || 0
      }
      if (column.id === 'coBroker') {
        // Assign co_broker_ref based on co_broker value
        const cbData = this.brokerList.find(b => b.name === value) || {
          name: ' ',
          ref: ' ',
        }
        row.coBrokerRef = cbData.ref

        const reList = this.reinsurerList.filter(
          r => r.reinsurer.cededlayerID === this.selectedCededLayerID
        )
        const fotMaster = reList.find(
          r => r.reinsurer.quoteReinsurerName === 'FOT MASTER'
        )
        row.brokerage =
          fotMaster?.reinsurer.quoteFields?.brokerageCommission || 0.15
        row.brokerageRe =
          fotMaster?.reinsurer.quoteFields?.brokerageRIPCommission || 0

        const currentAccountOpp = this.getCurrentAccountOpportunity()

        if (currentAccountOpp){
          if (value === LOCKTON_LLP_NAME && row.brokerageCoBrokerShare === 0){
            // only default brokerage co-broker share for US -> UK (LLP) transactions
            row.brokerageCoBrokerShare = row.brokerage && currentAccountOpp.opportunityBU === SALESFORCE_US_BU ? row.brokerage / LOCKTON_LLP_SHARE_DIVISOR : 0
          }
        }
      }

      this.assignedLineRows = tempRows
      this.updateAssignedLines.emit({
        reinsurerID: this.selectedReinsurer,
        assignedLinesRows: this.assignedLineRows,
      })
    }
  }

  onDeleteAssignedLines($event: { id: string }) {
    if (this.selectedReinsurer) {
      const tempRows = clone(this.assignedLineRows)
      // tslint:disable-next-line: no-non-null-assertion
      this.assignedLineRows = tempRows.filter(r => r.id !== $event.id)!
      this.deleteAssignedLines.emit({ id: $event.id })
      this.updateAssignedLines.emit({
        reinsurerID: this.selectedReinsurer,
        assignedLinesRows: this.assignedLineRows,
      })
    }
  }

  onAddClick(reinsurerName: string): void {
    const fundManagerUnknownEntity =
      this.getFundManagerUnknownEntity(reinsurerName)
    if (fundManagerUnknownEntity) {
      this.onSegregatedAccountModalClick(fundManagerUnknownEntity)
      return
    }
    const knownEntity =
      this.getReinsurerKnownEntity(reinsurerName) ||
      this.getFundManagerKnownEntity(reinsurerName) ||
      this.getAgencyKnownEntity(reinsurerName)
    if (!knownEntity) {
      return
    }
    const { brokerage, brokerageRe, brokerageCoBrokerShare } =
      this.getBrokerageAndReinstatement(knownEntity.reinsurer)
    const programFactor = knownEntity.reinsurer.reinsurerProgramFactor[0]
    const newAssignedLine: AssignedLines = {
      reinsurer: reinsurerName,
      marketTpRef: knownEntity.tpRef,
      relationSeqNumber: knownEntity.relationSeqNumber,
      placedThrough:
        reinsurerName === 'Unknown'
          ? 'Unknown'
          : programFactor.placed_through ?? 'Lockton Re',
      coBroker: programFactor.co_broker ?? '',
      coBrokerRef: programFactor.co_broker_ref ?? '',
      brokerage,
      brokerageRe,
      brokerageCoBrokerShare,
      brokerageReCoBrokerShare: 0,
      signedOfAuthorized: 0,
    }
    if (this.assignedLineExists(newAssignedLine)) {
      this.snackBar.open(
        'Reinsurer added but already exists. Assign a different Underwriter Reference Number',
        'X',
        {
          duration: undefined,
        }
      )
    }
    this.addNewAssignedLines.emit(newAssignedLine)
  }

  onMarketingAddClick(
    reinsurerName: string,
    reinsurers: ReinsurerState[]
  ): void {
    const fundManagerUnknownEntity =
      this.getFundManagerUnknownEntity(reinsurerName)
    if (fundManagerUnknownEntity) {
      this.onSegregatedAccountModalClick(fundManagerUnknownEntity)
      return
    }
    const knownEntity =
      this.getReinsurerKnownEntity(reinsurerName) ||
      this.getFundManagerKnownEntity(reinsurerName) ||
      this.getAgencyKnownEntity(reinsurerName)
    if (!knownEntity) {
      return
    }
    const { brokerage, brokerageRe, brokerageCoBrokerShare } = this.getBrokerageAndReinstatement(
      knownEntity.reinsurer
    )
    const programFactor = knownEntity.reinsurer.reinsurerProgramFactor[0]
    let newAssignedLine: AssignedLines
    newAssignedLine = {
      reinsurer: reinsurerName,
      marketTpRef: knownEntity.tpRef,
      relationSeqNumber: knownEntity.relationSeqNumber,
      placedThrough:
        reinsurerName === 'Unknown'
          ? 'Unknown'
          : programFactor.placed_through ?? 'Lockton Re',
      coBroker: programFactor.co_broker ?? '',
      coBrokerRef: programFactor.co_broker_ref ?? '',
      brokerage,
      brokerageRe,
      brokerageCoBrokerShare,
      signedOfAuthorized: 0
    }

    reinsurers.forEach(reinsurer => {
      reinsurer.reinsurer.riskAssignedLinesLink?.forEach(line => {
        if (
          knownEntity.tpRef === line.marketTpRef &&
          knownEntity.relationSeqNumber === line.relationSeqNumber
        ) {
          newAssignedLine = {
            reinsurer: reinsurerName,
            marketTpRef: knownEntity.tpRef,
            relationSeqNumber: knownEntity.relationSeqNumber,
            placedThrough:
              reinsurerName === 'Unknown'
                ? 'Unknown'
                : programFactor.placed_through ?? 'Lockton Re',
            coBroker: programFactor.co_broker ?? '',
            coBrokerRef: programFactor.co_broker_ref ?? '',
            underwriterRef: (line.underwriterRef as string) || '',
            brokerage: line.brokerage,
            brokerageRe: line.brokerageRe,
            bureaus: line.bureaus,
            written: line.written,
            recommended: line.recommended,
            signed: line.signed,
            signedOfAuthorized: line.signedOfAuthorized,
            subjectivity: line.subjectivity,
            leadMarket: line.leadMarket,
            whiteSpaceWSetID: line.whiteSpaceWSetID,
            whiteSpaceSyncedAt: line.whiteSpaceSyncedAt,
            whiteSpaceSyncedBy: line.whiteSpaceSyncedBy,
            layerRef: line.layerRef,
            riskRef: line.riskRef,
            whiteSpaceImpressionIdx: line.whiteSpaceImpressionIdx,
            whiteSpaceBusinessUnit: line.whiteSpaceBusinessUnit,
          }
        }
      })
    })
    this.addNewAssignedLines.emit(newAssignedLine)
  }

  async marketingListAdd() {
    if (this.isMarketingListAddInProgress) {
      return
    }
    this.isMarketingListAddInProgress = true

    try {
      const expirings = this.reinsurerList.filter(
        r => r.reinsurer.reinsurerPhase === ReinsurerPhases.Expiring
      )

      if (expirings.length !== 0) {
        this.reinsurers.forEach(element => {
          if (element !== 'Unknown') {
            this.onMarketingAddClick(element, expirings)
          }
        })
      } else {
        await new Promise<void>((resolve, reject) => {
          const dialogRef = this.dialog.open(
            QuoteExpiringPanelConfirmationDialogComponent,
            {
              disableClose: true,
              data: {
                signedLines: this.signedConfirmation,
                tpRefLookup: this.tpRefLookup,
              },
            }
          )
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.reinsurers.forEach(element => {
                if (element !== 'Unknown') {
                  this.onMarketingAddClick(element, expirings)
                }
              })
              resolve()
            } else {
              reject()
            }
          })
        })
      }
    } finally {
      this.isMarketingListAddInProgress = false
    }
  }

  populateFromWhiteSpace(): void {
    this.populateAssignedLinesFromWhiteSpace.emit({
      reinsurerID: this.selectedReinsurer,
    })
  }

  onSubjectivityClick($event: { id: string }): void {
    if (this.selectedReinsurer) {
      const tempRows = clone(this.assignedLineRows)
      // tslint:disable-next-line: no-non-null-assertion
      const row: AssignedLines = tempRows.find(r => r.id === $event.id)!
      this.subjectivityALClick.emit({
        reName: row.reinsurer || '',
        ralId: Number($event.id),
      })
    }
  }
  onUpdatePlaceholder(rowId?: string): void {
    if (!rowId) {
      return
    }
    const assignedLine = this.assignedLineRowsTotal.find(
      row => row.id === rowId
    )
    const fundManager =
      assignedLine &&
      this.reinsurersNameListUpdated.find(
        r => r.name === assignedLine.reinsurer
      )
    if (fundManager) {
      this.onSegregatedAccountModalClick(fundManager)
    }
  }
  getItemSelected(layer: Layer, phase: ReinsurerPhase, versionLabel?: string): void {
    const filteredReinsurers: ReinsurerState[] = this.reinsurerList.filter(
      r =>
        r.reinsurer.cededlayerID === layer.id &&
        r.reinsurer.reinsurerPhase === phase &&
        (!versionLabel || r.reinsurer.reinsurerPhaseLabel === versionLabel)
    )

    /* Remove duplicates based on quoteReinsurerName */
    const uniqueReinsurers: ReinsurerState[] = []
    const seenReinsurers: Set<string> = new Set()

    filteredReinsurers.forEach(reinsurer => {
      const quoteReinsurerName = reinsurer.reinsurer.quoteReinsurerName
      if (!quoteReinsurerName) {
        return
      }
      if (!seenReinsurers.has(quoteReinsurerName)) {
        seenReinsurers.add(quoteReinsurerName)
        uniqueReinsurers.push(reinsurer)
      }
    })

    if (phase === ReinsurerPhases.FOT || phase === ReinsurerPhases.Expiring) {
      uniqueReinsurers.forEach(reinsurer => {
        const riskArry: AssignedLines[] =
          reinsurer.reinsurer.riskAssignedLinesLink || []
        riskArry.forEach(ele => this.processAssignedLines(ele))
      })
      uniqueReinsurers.forEach(reinsurer => {
        const subjectArry: ReinsurerSubjectivity[] =
          reinsurer.reinsurer.riskSubjectivityLink || []
        subjectArry.forEach(ele =>
          this.processSubjectivity(ele.riskSubjectivity)
        )
      })
    } else {
      /* Process quote phase */
      uniqueReinsurers.forEach(reinsurer => {
        const quoteReinsurerName = reinsurer.reinsurer.quoteReinsurerName
        if (quoteReinsurerName && quoteReinsurerName !== '') {
          this.onAddClick(quoteReinsurerName)
        }
      })
    }
  }

  onSegregatedAccountModalClick(fundManager: Reinsurer): void {
    this.showSegregatedAccountModal.emit({
      programID: fundManager.reinsurerProgramFactor[0].study_id.toString(),
      fundManager,
    })
  }

  isReinsurerOutsideOpportunityTermDate(reinsurer: Reinsurer): boolean {
    const selectedProgramOpportunityId = this.programs.find(
      f => f.id === this.selectedProgramID
    )?.opportunity_id
    const selectedProgramOpportunity = this.accountOpportunities.find(
      f => f.oppId === selectedProgramOpportunityId
    )
    const reinsuranceApprovalToDate = this.parseDate(
      reinsurer?.reinsuranceApprovalToDate,
      "yyyy-MM-dd'T'HH:mm:ss"
    )
    const reinsuranceApprovalFromDate = this.parseDate(
      reinsurer?.reinsuranceApprovalFromDate,
      "yyyy-MM-dd'T'HH:mm:ss"
    )
    const opportunityInceptionDate = this.parseDate(
      selectedProgramOpportunity?.opportunityInceptionDate,
      'yyyy-MM-dd'
    )
    const opportunityTerminationDate = this.parseDate(
      selectedProgramOpportunity?.opportunityTerminationDate,
      'yyyy-MM-dd'
    )

    return !!(
      opportunityInceptionDate &&
      opportunityTerminationDate &&
      reinsuranceApprovalFromDate &&
      reinsuranceApprovalFromDate.getTime() !== this.invalidDate?.getTime() &&
      reinsuranceApprovalToDate &&
      reinsuranceApprovalToDate.getTime() !== this.invalidDate?.getTime() &&
      (reinsuranceApprovalFromDate > opportunityInceptionDate ||
        reinsuranceApprovalToDate < opportunityInceptionDate)
    )
  }

  parseDate(dateString: string | null | undefined, format: string): Date | null {
    if (!dateString) {
      return null
    }

    const dateToParse: string = dateString ? dateString : ''
    return parse(dateToParse, format, new Date())
  }

  async pushToWhiteSpaceCont(): Promise<void> {
    if (this.pushToWhitespaceIsDisabled) {
      return
    }
    let dialogConfirmedPush = true
    let finalConfirmedPush = true
    if (
      this.signedWhitespaceWarnings.length > 0 ||
      this.signedWhitespaceErrors.length > 0 ||
      this.orderPercentErrors.length > 0
    ) {
      // Warnings and error popup
      await new Promise<void>((resolve, reject) => {
        const dialogRef = this.dialog.open(QuotePushWsWarningsDialogComponent, {
          disableClose: true,
          data: {
            warnings: this.signedWhitespaceWarnings,
            errors: this.signedWhitespaceErrors,
            orderErrors: this.orderPercentErrors,
          },
        })
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            resolve()
          } else {
            reject()
          }
        })
      })
        .then(() => (dialogConfirmedPush = true))
        .catch(() => (dialogConfirmedPush = false))
    }
    if (dialogConfirmedPush && this.structureID) {
      // Confirmation popup
      await new Promise<void>((resolve, reject) => {
        const dialogRef = this.dialog.open(
          QuotePushWsConfirmationDialogComponent,
          {
            disableClose: true,
            data: {
              signedLines: this.signedConfirmation,
              tpRefLookup: this.tpRefLookup,
            },
          }
        )
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            resolve()
          } else {
            reject()
          }
        })
      })
        .then(() => (finalConfirmedPush = true))
        .catch(() => (finalConfirmedPush = false))
      if (!finalConfirmedPush) {
        return
      }
      this.userPushedToWhitespace = true
      this.getPushToWhiteSpaceErrors()
      this.backendService
        .pushToWSContract(this.structureID.toString())
        .subscribe((response: any) => {
          if (response.error !== undefined) {
            this.userPushedToWhitespace = false
            this.getPushToWhiteSpaceErrors()
            this.snackBar.open(response.error.details, 'X', {
              duration: 4000,
            })
          } else {
            this.snackBar.open(
              'Signed % Pushed to WhiteSpace Successfully',
              'X',
              {
                duration: 2000,
              }
            )
          }
        })
    }
  }

  private assignedLineExists(ele: AssignedLines): boolean {
    return this.assignedLineRows.some(
      x =>
        x.marketTpRef === ele.marketTpRef &&
        x.relationSeqNumber === ele.relationSeqNumber &&
        x.underwriterRef === ele.underwriterRef
    )
  }

  private processAssignedLines(ele: AssignedLines): void {
    if (!this.assignedLineExists(ele)) {
      this.addNewAssignedLines.emit({
        reinsurer: ele.reinsurer as string,
        marketTpRef: ele.marketTpRef || '0',
        relationSeqNumber: ele.relationSeqNumber,
        placedThrough: ele.placedThrough as string,
        coBroker: ele.coBroker as string,
        coBrokerRef: ele.coBrokerRef as string,
        underwriterRef: (ele.underwriterRef as string) || '',
        brokerage: ele.brokerage,
        brokerageRe: ele.brokerageRe,
        bureaus: ele.bureaus,
        written: ele.written,
        recommended: ele.recommended,
        signed: ele.signed,
        signedOfAuthorized: ele.signedOfAuthorized,
        subjectivity: ele.subjectivity,
        leadMarket: ele.leadMarket,
        whiteSpaceWSetID: ele.whiteSpaceWSetID,
        whiteSpaceSyncedAt: ele.whiteSpaceSyncedAt,
        whiteSpaceSyncedBy: ele.whiteSpaceSyncedBy,
        layerRef: ele.layerRef,
        riskRef: ele.riskRef,
        whiteSpaceImpressionIdx: ele.whiteSpaceImpressionIdx,
        whiteSpaceBusinessUnit: ele.whiteSpaceBusinessUnit,
      })
    }
  }

  private processSubjectivity(subjectivity: Subjectivity): void {
    const structureSubjectivityIds = this.structureRiskSubjectivityRows.map(
      sr => sr.riskSubjectivity.id
    )
    if (!structureSubjectivityIds.includes(subjectivity.id)) {
      this.addAssignSubject.emit(subjectivity)
    }
  }

  private mapToReinsurersByLayerId(
    reinsurers: ReinsurerState[]
  ): Record<string, QuoteReinsurer[]> {
    return reinsurers.reduce((acc, state) => {
      const { cededlayerID, ...rest } = state.reinsurer
      if (!cededlayerID) {
        return acc
      }
      acc[cededlayerID] = acc[cededlayerID] || []
      acc[cededlayerID].push({ cededlayerID, ...rest })
      return acc
    }, {} as Record<string, QuoteReinsurer[]>)
  }

  private buildPopulateFromMenuItems(): DynamicMenuItem[] {
    return this.filteredQuoteLayerStates.map((layerState, i) => {
      const quoteLayerId = layerState.layer.id
      return {
        name: layerState.layer.meta_data.layerName ?? `Layer${i}`,
        children: [
          {
            name: 'Quote',
            metadata: { quoteLayerId, quotePhase: ReinsurerPhases.Quote },
          },
          this.buildVersionMenuItem(
            quoteLayerId,
            ReinsurerPhases.FOT,
            this.allFotVersions[layerState.layer.id]
          ),
          this.buildVersionMenuItem(
            quoteLayerId,
            ReinsurerPhases.Expiring,
            this.allExpiringVersions[layerState.layer.id]
          ),
        ],
      }
    })
  }

  private buildVersionMenuItem(
    quoteLayerId: string,
    quotePhase: ReinsurerPhase,
    versions?: QuoteReinsurer[]
  ): DynamicMenuItem {
    const singleMenuItem = {
      name: `${quotePhase} Assigned Lines`,
      metadata: {
        quoteLayerId /* to identify the layer this version is from */,
        quotePhase,
      },
    }
    if (!versions || versions.length <= 1) {
      return singleMenuItem
    }
    return {
      ...singleMenuItem,
      children: versions.map(reinsurer => {
        return {
          name: `${quotePhase} ${reinsurer.reinsurerPhaseVersion} - ${reinsurer.reinsurerPhaseLabel}`,
          metadata: {
            ...singleMenuItem.metadata,
            quoteVersionLabel: reinsurer.reinsurerPhaseLabel,
          },
        }
      }),
    }
  }

  private getFundManagerUnknownEntity(
    reinsurerName: string
  ): Reinsurer | undefined {
    const fundManagers = this.reinsurersNameListUpdated.filter(
      r => r.market_use === FUND_MANAGER_MARKET_USE
    )
    const segAccountMatch = reinsurerName.match(
      new RegExp(`^(.*?)\\b${FUND_MANAGER_SEG_ACCOUNT_TEXT}\\b`)
    )
    const unknownMatch = reinsurerName.match(
      new RegExp(`\\b${FUND_MANAGER_UNKNOWN_TEXT} - (.*)$`)
    )
    const fundManagerName = segAccountMatch
      ? segAccountMatch[1].trim()
      : unknownMatch
      ? unknownMatch[1].trim()
      : ''
    return fundManagers.find(
      fundManager => fundManager.name === fundManagerName
    )
  }

  private getReinsurerKnownEntity(
    reinsurerName: string
  ): KnownEntity | undefined {
    const reinsurerFound = this.reinsurersNameListUpdated.find(
      r => r.name === reinsurerName
    )
    if (reinsurerFound) {
      return {
        reinsurer: reinsurerFound,
        tpRef: reinsurerFound.tpRef ?? '0',
        relationSeqNumber:
          reinsurerFound.reinsurerProgramFactor[0].relation_seq_number ??
          undefined,
      }
    }
  }
  private getFundManagerKnownEntity(
    reinsurerName: string
  ): KnownEntity | undefined {
    const fundManagers = this.reinsurersNameListUpdated.filter(
      r => r.market_use === FUND_MANAGER_MARKET_USE
    )
    const fundManagerFound = fundManagers.find(fundManager =>
      fundManager.selectedCompanyPapers.some(
        paper => paper.agencyName === reinsurerName
      )
    )
    const tpRefSeqNum = fundManagerFound?.selectedCompanyPapers.find(
      paper => paper.agencyName === reinsurerName
    )
    if (fundManagerFound) {
      return {
        reinsurer: fundManagerFound,
        tpRef: tpRefSeqNum?.agencyTPRef ?? '0',
        relationSeqNumber: tpRefSeqNum?.agencySeqNumber,
      }
    }
  }
  private getAgencyKnownEntity(reinsurerName: string): KnownEntity | undefined {
    const agencies = this.reinsurersNameListUpdated.filter(
      r => r.market_use === AGENCY_MARKET_USE
    )
    const agencyFound = agencies.find(
      agency => agency.reinsurerProgramFactor[0].obo_name === reinsurerName
    )
    if (agencyFound?.tpRef) {
      return {
        reinsurer: agencyFound,
        tpRef: agencyFound.tpRef,
        relationSeqNumber:
          agencyFound.reinsurerProgramFactor[0].relation_seq_number ??
          undefined,
      }
    }
  }

  private getBrokerageAndReinstatement(reinsurer: Reinsurer): {
    brokerage?: number
    brokerageRe?: number
    brokerageCoBrokerShare?: number
  } {
    const currentFot = this.reinsurerList
      .filter(r => r.reinsurer.cededlayerID === this.selectedCededLayerID)
      .find(r => r.reinsurer.quoteReinsurerName === FOT_MASTER_REINSURER_NAME)
    const isLocktonLLPCoBroker =
      reinsurer.reinsurerProgramFactor[0]?.co_broker === LOCKTON_LLP_NAME

    const brokerage = currentFot?.reinsurer.quoteFields?.brokerageCommission
    const brokerageRe =
      currentFot?.reinsurer.quoteFields?.brokerageRIPCommission

    const currentAccountOpp = this.getCurrentAccountOpportunity()

    let brokerageCoBrokerShare = 0

    if (currentAccountOpp){
      // only default brokerage co-broker share for US -> UK (LLP) transactions
      brokerageCoBrokerShare = isLocktonLLPCoBroker && currentAccountOpp.opportunityBU === SALESFORCE_US_BU
      ? brokerage
        ? brokerage / LOCKTON_LLP_SHARE_DIVISOR
        : 0
      : 0
    }

    return { brokerage, brokerageRe, brokerageCoBrokerShare }
  }

  private getCurrentAccountOpportunity() : AccountOpportunity | undefined {
    const selectedProgramOpportunityId = this.programs.find(
      f => f.id === this.selectedProgramID
    )?.opportunity_id
    return this.accountOpportunities.find(
      f => f.oppId === selectedProgramOpportunityId
    )
  }

  onReinsurerClick(tpRef: string, reinsurerName: string): void {
    this.dialog.open(QuoteReinsurerSubjectivityContainerComponent,
      {
        data: {
          tpRef,
          reinsurerName
        }
      }
    )
  }
}
