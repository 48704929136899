import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { TrackingSelectors } from '../tracking.model'
import { IControl } from 'src/app/management-information/store/management-information.reducer'
import { BasicControl } from 'src/app/management-information/model/management-information.model'

@Component({
  selector: 'app-tracking-sidebar',
  templateUrl: './tracking-sidebar.component.html',
  styleUrls: ['./tracking-sidebar.component.scss'],
})
export class TrackingSideBarComponent implements OnInit {
  @ViewChild('controlsRef') controlsRef: ElementRef

  @Input() selectors: TrackingSelectors
  @Input() isLoading: boolean
  @Input() inceptionInterval: BasicControl

  form: FormGroup
  minInceptionDate: Date
  maxInceptionDate: Date

  @Output() filtersChanged = new EventEmitter<{
    filters: Record<string, string[]>
  }>()
  @Output() inceptionDateChanged = new EventEmitter<{
    minInceptionDate: string
    maxInceptionDate: string
  }>()

  constructor(
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      clientName: new FormControl([]),
      year: new FormControl([]),
      businessUnit: new FormControl([]),
      complete: new FormControl([]),
    })
    this.minInceptionDate = new Date(this.inceptionInterval.minValue)
    this.maxInceptionDate = new Date(this.inceptionInterval.maxValue)
  }

  dispatchFilters(): void {
    const filters = this.getFiltersFromControls()
    this.filtersChanged.emit({ filters })
    this.form.markAsPristine()
  }

  clearFilter(event: MouseEvent, filter: IControl): void {
    event.stopPropagation()
    this.form.controls[filter.columnName].setValue([])
    const filters = this.getFiltersFromControls()
    this.filtersChanged.emit({ filters })
  }

  intervalChanged(prefix: 'min' | 'max', value: string): void {
    const isMin = prefix === 'min'
    if (isMin) {
      this.minInceptionDate = new Date(value)
    } else {
      this.maxInceptionDate = new Date(value)
    }
    this.inceptionDateChanged.emit({
      minInceptionDate: this.minInceptionDate.toDateString(),
      maxInceptionDate: this.maxInceptionDate.toDateString(),
    })
  }

  private getFiltersFromControls(): Record<string, string[]> {
    const filters = Object.entries(this.form.controls).reduce(
      (acc, [key, formControl]) => {
        acc = {
          ...acc,
          [key]: formControl.value,
        }
        return acc
      },
      {}
    )
    return filters
  }
}
