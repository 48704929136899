import { partition, uniqBy } from 'ramda'
import { isIndexedLayer } from 'src/app/analysis/layers/indexed-layer'
import {
  asMultiSectionLayer,
  isMultiSectionLayer,
  splitMultiSectionLayerSources,
} from 'src/app/analysis/layers/multi-section-layer'
import { asSwingLayer, isSwingLayer } from 'src/app/analysis/layers/swing-layer'
import {
  Layer,
  LayerRef,
  PhysicalLayer,
} from 'src/app/analysis/model/layers.model'
import {
  isLayerActualTopAndDrop,
  isLayerAgg,
  isLayerTopOrDrop,
} from 'src/app/analysis/model/layers.util'
import {
  LogicalPortfolioLayer,
  LossSetLayer,
  Metadata,
  MonetaryUnit,
  PhysicalPortfolioLayer,
  Ref,
  Reinstatement,
} from 'src/app/api/analyzere/analyzere.model'
import { QuoteFields } from '../../quote/models/quote.model'
import {
  LAYER_PALETTE,
  LAYER_PALETTE_CATEGORY_MAPPINGS,
  layerIds,
} from './layer-palette.model'

export function convertFromLogicalPortfolioLayers(
  logicalLayers: LogicalPortfolioLayer[],
  sharedLayerID: string = ''
): Layer[] {
  const layers: Layer[] = []

  for (const logicalLayer of logicalLayers) {
    const lossSetLayers: LayerRef[] = []
    const layerRefs: string[] = []
    let layerMetadata = logicalLayer.meta_data
    let physicalLayer: any = {}

    if (!logicalLayer.meta_data.isRiskLargeHidden) {
      physicalLayer = convertFromPhysicalPortfolioLayer(
        logicalLayer.sink as PhysicalPortfolioLayer,
        logicalLayer.id
      )
    }

    // Cat TOP and Drop participation should be saved as positive while hidden is negative
    // Converting to standered negative form
    if (isLayerTopOrDrop(logicalLayer)) {
      physicalLayer.participation = Math.abs(physicalLayer.participation) * -1
    }

    if (
      logicalLayer.meta_data.sage_layer_type === 'cat_td' &&
      logicalLayer.meta_data.sage_layer_subtype === 'actual'
    ) {
      // Top and Drop hidden layer. Sources contains XOL + Virtual T&D that's rendered on the screen
      // Can contain inured layers too
      const layerSource = logicalLayer.sources as LogicalPortfolioLayer[]
      const sourceTuple = partition(
        s => !!s.meta_data.inuranceSource,
        layerSource
      )
      lossSetLayers.push(...sourceTuple[0])
      layerRefs.push(...sourceTuple[1].map(s => s.id))

      const sources: LogicalPortfolioLayer[] = sourceTuple[1]
      const convertedTDLayers = convertFromLogicalPortfolioLayers(sources)
      const layerIDs = layers.map(l => l.id)
      convertedTDLayers.forEach(c => {
        if (!layerIDs.includes(c.id)) {
          layers.push(c)
        }
      })
    } else if (logicalLayer.meta_data.isFHCFFinal) {
      // FHCF hidden layer. Sources hidden FHCF layers that's rendered on the screen
      const layerSource = logicalLayer.sources as LogicalPortfolioLayer[]
      layerSource.forEach(s => {
        if (s.meta_data.isFHCFHidden1 || s.meta_data.isFHCFHidden2) {
          const LossSets = s.sources as LossSetLayer[]
          layers.push({
            currency: '',
            modified_date: s.modified,
            id: s.id,
            lossSetFilter: s.meta_data.lossfilter_name,
            layerRefs,
            lossSetLayers: [
              {
                id: LossSets[0].id,
                meta_data: LossSets[0].meta_data,
                loss_sets: LossSets[0].loss_sets,
                mean: 0,
              },
            ],
            physicalLayer: convertFromPhysicalPortfolioLayer(
              s.sink as PhysicalPortfolioLayer,
              s.id
            ),
            meta_data: s.meta_data,
            sharedLayerID,
            viewMetrics: {
              error: null,
              loading: false,
              metrics: null,
              rss: null,
            },
            layerResultsMetricCurrency: '',
          })
        }
      })
      lossSetLayers.push(
        ...(logicalLayer.sources as LossSetLayer[]).map(s => ({
          id: s.id,
          meta_data: s.meta_data,
          loss_sets: s.loss_sets,
        }))
      )
    } else if (
      logicalLayer.meta_data.isRiskFinal &&
      logicalLayer.meta_data.sage_layer_type === 'noncat_risk'
    ) {
      // Risk hidden layer. Sources hidden Risk layers that's rendered on the screen
      const layerSource = logicalLayer.sources as
        | PhysicalPortfolioLayer[]
        | LogicalPortfolioLayer[]
      let s: any
      for (s of layerSource) {
        if (s.meta_data.isRiskLargeHidden) {
          layers.push({
            currency: '',
            modified_date: s.modified,
            id: s.id,
            lossSetFilter: s.meta_data.lossfilter_name,
            layerRefs,
            lossSetLayers: s.loss_sets,
            physicalLayer: convertFromPhysicalPortfolioLayer(
              s as PhysicalPortfolioLayer,
              s.id,
              logicalLayer.sink as PhysicalPortfolioLayer
            ),
            meta_data: s.meta_data,
            sharedLayerID: '',
            viewMetrics: {
              error: null,
              loading: false,
              metrics: null,
              rss: null,
            },
            layerResultsMetricCurrency: '',
          })
          lossSetLayers.push({
            id: s.id,
            meta_data: s.meta_data,
            loss_sets: s.loss_sets,
            mean: 0,
          })
        } else if (s.meta_data.isRiskCatHidden) {
          const LossSets = s.sources as LossSetLayer[]
          layers.push({
            currency: '',
            modified_date: s.modified,
            id: s.id,
            lossSetFilter: s.meta_data.lossfilter_name,
            layerRefs,
            lossSetLayers: [
              {
                id: LossSets[0].id,
                meta_data: LossSets[0].meta_data,
                loss_sets: LossSets[0].loss_sets,
                mean: 0,
              },
            ],
            physicalLayer: convertFromPhysicalPortfolioLayer(
              s.sink as PhysicalPortfolioLayer,
              s.id,
              logicalLayer.sink as PhysicalPortfolioLayer
            ),
            meta_data: s.meta_data,
            sharedLayerID: '',
            viewMetrics: {
              error: null,
              loading: false,
              metrics: null,
              rss: null,
            },
            layerResultsMetricCurrency: '',
          })
          lossSetLayers.push({
            id: s.id,
            meta_data: s.meta_data,
            loss_sets: s.sources,
          })
        } else if (s.meta_data.sage_layer_type !== 'noncat_risk') {
          lossSetLayers.push({
            id: s.id,
            meta_data: s.meta_data,
            loss_sets: s.sources,
          })
        }
      }
    } else if (logicalLayer.meta_data.isRiskVisible) {
      const isRiskActualLayer = logicalLayers.find(
        l => l.meta_data.riskVisibleLayerID === logicalLayer.id
      )
      lossSetLayers.push(
        ...(logicalLayer.sources as LossSetLayer[]).map(s => ({
          id: s.id,
          meta_data: s.meta_data,
          loss_sets: s.loss_sets,
        }))
      )
      if (isRiskActualLayer) {
        physicalLayer = convertFromPhysicalPortfolioLayer(
          logicalLayer.sink as PhysicalPortfolioLayer,
          logicalLayer.id,
          isRiskActualLayer.sink as PhysicalPortfolioLayer
        )
      }
    } else if (logicalLayer.meta_data.isRiskLargeHidden) {
      if (logicalLayer.sources) {
        lossSetLayers.push(
          ...(logicalLayer.sources as LossSetLayer[]).map(s => ({
            id: s.id,
            meta_data: s.meta_data,
            loss_sets: s.loss_sets,
          }))
        )
      }
      if (logicalLayer.sink) {
        physicalLayer = convertFromPhysicalPortfolioLayer(
          logicalLayer.sink as PhysicalPortfolioLayer,
          logicalLayer.id
        )
      } else {
        continue
      }
    } else if (
      logicalLayer.meta_data.sage_layer_type === 'shared_limits' &&
      logicalLayer.meta_data.sage_layer_subtype === 'virtual'
    ) {
      layerRefs.push(...(logicalLayer.sources as LossSetLayer[]).map(s => s.id))
      const sources = logicalLayer.sources as LogicalPortfolioLayer[]
      const convertedReferencedLayers = convertFromLogicalPortfolioLayers(
        sources,
        logicalLayer.id
      )
      layers.push(...convertedReferencedLayers)
    } else if (
      logicalLayer.meta_data.sage_layer_type === 'shared_limit' &&
      logicalLayer.meta_data.sage_layer_subtype === 'backallocated'
    ) {
      const sources = logicalLayer.sources as LogicalPortfolioLayer[]
      if (sources) {
        const sourceTuple = partition(
          s => !!s.meta_data.inuranceSource,
          sources
        )
        lossSetLayers.push(
          ...sources.map(s => ({ id: s.id, meta_data: s.meta_data }))
        )
        const backAllocated = sourceTuple[1][0] as LogicalPortfolioLayer
        const hidden = backAllocated.sink as LogicalPortfolioLayer
        const convertedReferencedLayers = convertFromLogicalPortfolioLayers([
          hidden,
        ])

        // const layerIDs = layers.map(l => l.id)
        // convertedReferencedLayers
        //   .filter(
        //     l =>
        //       l.id === logicalLayer.meta_data.backAllocatedForID ||
        //       l.id === hidden.id ||
        //       isLayerTopOrDrop(l) ||
        //       isLayerActualTopAndDrop(l)
        //   )
        //   .forEach(c => {
        //     const index = layerIDs.indexOf(c.id)
        //     if (index > -1) {
        //       layers.splice(index, 1)
        //       layerIDs.slice(index, 1)
        //     }
        //   })
        let filteredArray: Layer[] = []
        const riskActual = convertedReferencedLayers.find(
          l => l.id === logicalLayer.meta_data.backAllocatedForID
        )
        if (riskActual && riskActual.meta_data.isRiskFinal) {
          filteredArray = convertedReferencedLayers.filter(
            l =>
              l.id === logicalLayer.meta_data.backAllocatedForID ||
              l.id === hidden.id ||
              (l.meta_data.sage_layer_type === 'noncat_risk' &&
                !layers.includes(l))
          )
        } else {
          const isTDHidden = convertedReferencedLayers.find(
            l =>
              l.id === logicalLayer.meta_data.backAllocatedForID &&
              isLayerActualTopAndDrop(l)
          )
          filteredArray = convertedReferencedLayers.filter(l => {
            return (
              l.id === logicalLayer.meta_data.backAllocatedForID ||
              l.id === hidden.id ||
              (isTDHidden && isLayerTopOrDrop(l))
            )
          })
        }
        layers.push(...filteredArray)
      }
    } else if (
      isLayerAgg(logicalLayer) ||
      logicalLayer.meta_data.sage_layer_type === 'cat_ca'
    ) {
      const sources = logicalLayer.sources as LogicalPortfolioLayer[]
      sources.forEach(s => {
        if (
          s.meta_data.sage_layer_type === 'gross_layer_loss_set' ||
          s.meta_data.inuranceSource
        ) {
          lossSetLayers.push({ id: s.id, meta_data: s.meta_data })
        } else {
          layerRefs.push(s.id)
        }
      })
    } else if (isIndexedLayer(logicalLayer, 'main-layer')) {
      const visibleLayerRef = logicalLayers.find(
        l => l.id === logicalLayer.meta_data.visible_layer_id
      )
      if (visibleLayerRef) {
        const { mainLayerMetadata, updatedPhysicalLayer } =
          syncLayerNameBetweenComplexLayers(
            logicalLayer,
            physicalLayer,
            visibleLayerRef
          )
        layerMetadata = mainLayerMetadata
        physicalLayer = updatedPhysicalLayer
      }
      const sources = logicalLayer.sources as LogicalPortfolioLayer[]
      sources.forEach(s => {
        if (s.meta_data.inuranceSource) {
          lossSetLayers.push({ id: s.id, meta_data: s.meta_data })
        } else {
          layerRefs.push(s.id)
        }
      })
    } else if (isSwingLayer(logicalLayer, 'combined-layer')) {
      const sources = logicalLayer.sources as LogicalPortfolioLayer[]
      const swingLayers = sources.map(subLayer =>
        asSwingLayer(
          subLayer,
          convertFromPhysicalPortfolioLayer(
            subLayer.sink as PhysicalPortfolioLayer,
            subLayer.id
          )
        )
      )
      const filteredSwingLayers = swingLayers.filter(
        e => !e.meta_data.inuranceSource
      )
      layers.push(...filteredSwingLayers)
      sources.forEach(s => {
        if (s.meta_data.inuranceSource) {
          lossSetLayers.push({ id: s.id, meta_data: s.meta_data })
        } else {
          layerRefs.push(s.id)
        }
      })
    } else if (isMultiSectionLayer(logicalLayer, 'main-layer')) {
      const mainSources = logicalLayer.sources as LogicalPortfolioLayer[]

      // Push the section layer ids
      // The main layer sources are the section layers. Push the section layers
      // so they also get loaded, and for each section layer also get its flipper
      // layer if one exists (there is an inuring section).
      mainSources.forEach(layer => {
        const [lossSets, flipper] = splitMultiSectionLayerSources(layer)

        const sectionLayer = asMultiSectionLayer(
          layer,
          convertFromPhysicalPortfolioLayer(
            layer.sink as PhysicalPortfolioLayer,
            layer.id
          ),
          lossSets,
          flipper
        )

        if (layer.meta_data.inuranceSource) {
          lossSetLayers.push({ id: layer.id, meta_data: layer.meta_data })
        } else {
          layerRefs.push(layer.id)
        }

        if (!sectionLayer.meta_data.inuranceSource) {
          layers.push(sectionLayer)
        }

        if (flipper) {
          const flipperLayer = asMultiSectionLayer(
            flipper,
            convertFromPhysicalPortfolioLayer(
              flipper.sink as PhysicalPortfolioLayer,
              flipper.id
            ),
            []
          )

          const inuringSections = flipper.sources as LogicalPortfolioLayer[]
          flipperLayer.layerRefs = inuringSections.map(
            inuringLayer => inuringLayer.id
          )

          layers.push(flipperLayer)
        }
      })
    } else if (isMultiSectionLayer(logicalLayer, 'flipper-layer')) {
      const flipperLayer = asMultiSectionLayer(
        logicalLayer,
        convertFromPhysicalPortfolioLayer(
          logicalLayer.sink as PhysicalPortfolioLayer,
          logicalLayer.id
        ),
        []
      )

      const inuringSections = logicalLayer.sources as LogicalPortfolioLayer[]
      flipperLayer.layerRefs = inuringSections.map(
        inuringLayer => inuringLayer.id
      )

      layers.push(flipperLayer)
    } else {
      lossSetLayers.push(
        ...(logicalLayer.sources as LossSetLayer[]).map(s => ({
          id: s.id,
          meta_data: s.meta_data,
          loss_sets: s.loss_sets,
          mean: 0,
        }))
      )
    }
    let layerResultsCurrency = ''
    let layerCurrency = ''
    const sinkLayer = logicalLayer?.sink as PhysicalPortfolioLayer
    if (sinkLayer) {
      if (sinkLayer.franchise) {
        layerCurrency = sinkLayer.franchise.currency
      } else if (sinkLayer.event_limit) {
        layerCurrency = sinkLayer.event_limit.currency
      } else if (sinkLayer.premium) {
        layerCurrency = sinkLayer.premium.currency
      }
    }
    if (sinkLayer && sinkLayer.meta_data.layerResultsMetricCurrency) {
      layerResultsCurrency = sinkLayer.meta_data.layerResultsMetricCurrency
    }
    layers.push({
      currency: layerCurrency,
      id: logicalLayer.id,
      modified_date: logicalLayer.modified,
      lossSetFilter: logicalLayer.meta_data.lossfilter_name,
      layerRefs,
      lossSetLayers,
      physicalLayer,
      meta_data: layerMetadata,
      sharedLayerID: logicalLayer.meta_data.isRiskVisible ? '' : sharedLayerID,
      viewMetrics: {
        error: null,
        loading: false,
        metrics: null,
        rss: null,
      },
      layerResultsMetricCurrency: layerResultsCurrency,
    })
  }

  // filter duplicate shared_limit hidden layers
  return uniqBy(layer => layer.id, layers)
}

export function syncLayerNameBetweenComplexLayers(
  mainLayer: LogicalPortfolioLayer,
  physicalLayer: PhysicalPortfolioLayer,
  visibleLayer: LogicalPortfolioLayer
): {
  mainLayerMetadata: Partial<Metadata>
  updatedPhysicalLayer: PhysicalPortfolioLayer
} {
  let newLayerMetaData = { ...mainLayer.meta_data }
  let newPhysicalLayer = { ...physicalLayer }
  // The visible layer has been updated historically, try and sync proper name to main and physical layers
  if (mainLayer.meta_data.layerName !== visibleLayer.meta_data.layerName) {
    newLayerMetaData = {
      ...mainLayer.meta_data,
      layerName:
        visibleLayer.meta_data.layerName ?? mainLayer.meta_data.layerName,
    }
  }
  if (physicalLayer.description !== visibleLayer.meta_data.layerName) {
    newPhysicalLayer = {
      ...newPhysicalLayer,
      description:
        visibleLayer.meta_data.layerName ?? newPhysicalLayer.description,
      meta_data: {
        ...newPhysicalLayer.meta_data,
        layerName: visibleLayer.meta_data.layerName,
      },
    }
  }
  return {
    mainLayerMetadata: newLayerMetaData,
    updatedPhysicalLayer: newPhysicalLayer,
  }
}

export function convertFromPhysicalPortfolioLayer(
  physical: PhysicalPortfolioLayer,
  logicalLayerID: string,
  riskActualPhysical?: PhysicalPortfolioLayer
): PhysicalLayer {
  const defaultMonetary: MonetaryUnit = {
    value: 0,
    currency: riskActualPhysical?.premium?.currency ?? physical.premium?.currency ?? 'USD',
    value_date: null,
    rate: null,
    rate_currency: null,
  }

  let limit = physical.limit ?? defaultMonetary
  let attachment = physical.attachment ?? defaultMonetary
  let premium = physical.premium
  let reinstatements = physical.reinstatements ?? []

  let riskLimit
  let riskAttachment

  if (
    physical.meta_data.isRiskLargeHidden ||
    physical.meta_data.isRiskVisible
  ) {
    riskLimit = physical.limit
    riskAttachment = physical.attachment

    if (riskActualPhysical) {
      limit = riskActualPhysical.limit ?? limit
      attachment = riskActualPhysical.attachment ?? attachment
      premium = riskActualPhysical.premium ?? premium
      reinstatements = riskActualPhysical.reinstatements ?? reinstatements
    }
  }

  const response: PhysicalLayer = {
    type: physical._type,
    id: physical.id,
    logicalLayerID,
    attachment,
    limit,
    participation: physical.participation,
    premium,
    fees: physical.fees,
    aggregateLimit: physical.aggregate_limit ?? defaultMonetary,
    aggregateAttachment: physical.aggregate_attachment ?? defaultMonetary,
    description: physical.description,
    reinstatements,
    meta_data: physical.meta_data,
    franchise: physical.franchise ?? defaultMonetary,
    riskLimit,
    riskAttachment,
    xcoord: -1,
    ycoord: -1,
    loss_sets: physical.loss_sets,
    event_limit: physical.event_limit,
    inception_date: physical.inception_date,
    expiry_date: physical.expiry_date,
  }
  if (
    physical._type === 'IndustryLossWarranty' &&
    physical.payout &&
    physical.trigger
  ) {
    response.payout = physical.payout
    response.limit = physical.payout
    response.trigger = physical.trigger
    response.attachment = physical.trigger
    response.nth = physical.nth
  }
  if (
    physical.meta_data.sage_layer_type === 'cat_ilw_pro_rata' &&
    physical.meta_data
  ) {
    response.payout = {
      value: physical.meta_data.payout || 0,
      currency: physical.premium.currency,
    }
    response.trigger = physical.attachment
  }
  return response
}

export function convertToPhysicalLayersRequest(
  layer: PhysicalLayer
): Partial<PhysicalPortfolioLayer> {
  let limit = layer.limit
  let attachment = layer.attachment
  let premium = layer.premium
  let reinstatements: Reinstatement[] = []
  let participation = layer.participation
  layer.reinstatements.forEach(re => {
    if (re.premium !== undefined) {
      reinstatements.push(re)
    }
  })

  // Top and drop layers need to go in as Positive Participation but hidden layer stays negative #14069
  if (isLayerTopOrDrop(layer)) {
    participation = Math.abs(participation)
  }

  if (
    (layer.meta_data.isRiskLargeHidden || layer.meta_data.isRiskVisible) &&
    layer.riskLimit &&
    layer.riskAttachment
  ) {
    limit = layer.riskLimit
    attachment = layer.riskAttachment
    premium = {
      value: 0,
      currency: layer.premium.currency,
    }
    reinstatements = []
  }

  const lossSets: { ref_id: any }[] = []

  if (
    layer.loss_sets &&
    layer.loss_sets.length > 0 &&
    layer.meta_data.sage_layer_type === 'noncat_risk'
  ) {
    layer.loss_sets.forEach((l: any) =>
      lossSets.push({ ref_id: l.loss_sets ? l.loss_sets[0].id : l.id })
    )
  }
  const request: Partial<PhysicalPortfolioLayer> = {
    _type: layer.type,
    attachment,
    limit,
    participation,
    premium,
    fees: layer.fees,
    aggregate_limit: layer.aggregateLimit,
    aggregate_attachment: layer.aggregateAttachment,
    description: layer.description,
    meta_data: layer.meta_data,
    reinstatements,
    franchise: layer.franchise,
    loss_sets: lossSets,
    event_limit: layer.event_limit,
    inception_date: layer.inception_date,
    expiry_date: layer.expiry_date,
  }
  if (layer.type === 'IndustryLossWarranty') {
    request.payout = layer.limit
    request.trigger = layer.attachment
    request.nth = layer.nth
  }
  if (layer.meta_data.sage_layer_type === 'cat_ilw_pro_rata') {
    request.meta_data = {
      ...request.meta_data,
      payout: layer.payout?.value,
    }
  }
  return request
}

export function getSources(layer: Layer): Ref[] {
  const lossSetRefs = layer.lossSetLayers.map<Ref>(lossSetLayer => ({
    ref_id: lossSetLayer.id,
  }))
  const layerRefs = layer.layerRefs.map<Ref>(id => ({ ref_id: id }))

  return lossSetRefs.concat(layerRefs)
}

export function convertToLogicalLayersRequest(
  layer: Layer,
  physicalLayerID: string
): Partial<LogicalPortfolioLayer> {
  const sources = getSources(layer)
  return {
    _type: 'NestedLayer',
    sink: {
      ref_id: physicalLayerID,
    },
    sources,
    meta_data: layer.meta_data,
  }
}

export function convertToHiddenLayer(layer: Layer): Layer {
  return {
    ...layer,
    meta_data: {
      ...layer.meta_data,
      sage_layer_type: 'cat_td',
      sage_layer_subtype: 'actual',
      isDrop: false,
      topID: '',
      sharedLimitHidden: 0,
      layerName: 'Top & Drop',
    },
    physicalLayer: {
      ...layer.physicalLayer,
      description: 'Top & Drop',
      meta_data: {
        ...layer.physicalLayer.meta_data,
        sage_layer_type: 'cat_td',
        sage_layer_subtype: 'actual',
      },
      limit: {
        ...layer.physicalLayer.limit,
        // tslint:disable-next-line:no-non-null-assertion
        value: layer.meta_data.sharedLimitHidden!,
      },
      attachment: {
        ...layer.physicalLayer.attachment,
        value: 0,
      },
      aggregateAttachment: {
        ...layer.physicalLayer.aggregateAttachment,
        value: 0,
      },
      aggregateLimit: {
        ...layer.physicalLayer.aggregateLimit,
        // tslint:disable-next-line:no-non-null-assertion
        value: layer.meta_data.sharedLimitHidden!,
      },
      participation: -1.0,
    },
    lossSetLayers: [],
    // tslint:disable-next-line:no-non-null-assertion
    layerRefs: [layer.meta_data.topID!, layer.id],
  }
}

export function convertToLogicalFromPhysicalResponse(
  physical: PhysicalPortfolioLayer
): LogicalPortfolioLayer {
  return {
    _type: physical._type,
    id: physical.id,
    created: physical.created,
    modified: physical.modified,
    sink: {
      ...physical,
    },
    sources: physical.loss_sets,
    meta_data: physical.meta_data,
    description: physical.description,
  }
}

export function mapLayerTypeToLayerCategory(
  layerType: string,
  quoteFields: QuoteFields | undefined
): string {
  let layerCategory = ''
  const aggregateAttachment = quoteFields?.quoteAggregateAttachment?.value ?? 0
  const layerPaletteItem = LAYER_PALETTE.find(item => item.id === layerType)
  const filterableLayerType = layerPaletteItem?.type

  if (
    layerPaletteItem?.id &&
    [layerIds.ahlQs, layerIds.ahlAg, layerIds.ahlXl, layerIds.ahlSwing].includes(
      layerPaletteItem.id as layerIds
    )
  ) {
    return 'AHL'
  }
  if (filterableLayerType) {
    const categoryValues = Object.entries(LAYER_PALETTE_CATEGORY_MAPPINGS).find(
      ([key]) => key === filterableLayerType
    )?.[1]
    layerCategory =
      categoryValues?.length === 1
        ? categoryValues[0].name
        : categoryValues?.find(value =>
            aggregateAttachment === 0 ? 'EQUAL' : 'GREATER' === value.condition
          )?.name ?? ''
  }

  return layerCategory
}
