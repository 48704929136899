<button appButtonIcon big class="closeX clear" (click)="destroyDialog()">close</button>
<app-pricing-curve-add-data-dialog
  [workingCurveData]="workingCurveData$ | async"
  [dialogData]="dialogData$ | async"
  [savedCurves]="savedCurves$ | async"
  [savedCurveSelectors]="savedCurveSelectors$ | async"
  [status]="status$ | async"
  [currentDataSetNames]="currentDataSetNames$ | async"
  [savedCurveNameMap]="savedCurveNameMap$ | async"
  [addedCurveIds]="addedCurveIds$ | async"
  [context]="context$ | async"
  [graphSettings]="graphSettings$ | async"
  [data]="data"
  (tabChanged)="tabChanged($event)"
  (layerSplitViewChanged)="layerSplitViewChanged($event)"
  (submitCurve)="submitCurve($event)"
></app-pricing-curve-add-data-dialog>
