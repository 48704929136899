import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { Reference } from '@shared/layer-property/layer-property.component'
import { map, Observable, startWith } from 'rxjs'
import {
  CreditCalculationStructure,
  CreditStructure,
  CreditTranche,
  CreditTransaction,
} from '../../model/credit-structure.model'
import { User } from '../../../api/model/backend.model'
import { CreditSubmissionService } from '../../services/credit-submission.service'
import { CreateSubmissionProps } from '../../model/credit-submission.model'

@Component({
  selector: 'app-credit-create-submission-dialog',
  templateUrl: './credit-create-submission-dialog.component.html',
  styleUrls: ['./credit-create-submission-dialog.component.scss'],
})
export class CreditCreateSubmissionDialogComponent implements OnChanges {
  transactionId: string
  references: Reference[] = []
  label = 'Unlabled Submission'
  description = 'No Description'
  filteredUsers: Observable<User[]>
  filterCtrl = new FormControl('', Validators.required)
  selectedUsers: User[] = []
  creatingSubmissions = false
  @Input() creditStructure: CreditStructure
  @Input() calculationStructure: CreditCalculationStructure
  @Input() users: User[]
  @Output() close = new EventEmitter()

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger

  constructor(
    private submissionService: CreditSubmissionService,
    private snackbar: MatSnackBar
  ) {}

  ngOnChanges(): void {
    this.setTransaction()
    this.setUsers()
  }

  toggleUser(user: User): void {
    const indexOf = this.selectedUsers.indexOf(user)
    if (indexOf < 0) {
      this.selectedUsers.push(user)
    } else {
      this.selectedUsers.splice(indexOf, 1)
    }
  }

  onCreateClick(): void {
    if (this.selectedUsers.length < 1) {
      return
    }
    // Create submission for selected transaction only
    const calculationStructure = this.prepStructureForSubmission()
    const props: CreateSubmissionProps = {
      creditStructure: this.creditStructure,
      calculationStructure,
      description: this.description,
      userIds: this.selectedUsers.map(user => user.id),
    }
    this.creatingSubmissions = true
    this.submissionService.createSubmissions(props).subscribe(submissions => {
      this.creatingSubmissions = false
      this.close.emit()
      this.snackbar.open(
        `Successfully Created ${submissions.length} Submission(s)`,
        'Dismiss',
        {
          duration: 3000,
        }
      )
    })
  }

  private setTransaction(): void {
    if (!this.calculationStructure) {
      return
    }
    const transactions = this.calculationStructure.transactions
    this.transactionId = transactions[transactions.length - 1]._id
    this.references = transactions.map(transaction => {
      return {
        value: transaction._id,
        viewValue: transaction.name,
      }
    })
  }

  private setUsers(): void {
    this.filteredUsers = this.filterCtrl.valueChanges.pipe(
      startWith(''),
      map(
        filterBy =>
          typeof filterBy === 'string' && filterBy.length > 0
            ? this.filterUsers(filterBy)
            : this.users /* default to all users if no filter typed */
      )
    )
  }

  private filterUsers(filter: string): User[] {
    return this.users.filter(user => {
      return (
        user.first_name.toLowerCase().includes(filter.toLowerCase()) ||
        user.last_name.toLowerCase().includes(filter.toLowerCase())
      )
    })
  }

  private prepStructureForSubmission(): CreditCalculationStructure {
    const transactions = this.prepTransactionsForSubmission()
    const { _id, ...calculationStructureWithoutId } = this.calculationStructure
    return {
      ...calculationStructureWithoutId,
      transactions,
      collateral_percent: 0.2,
      name: this.label,
    }
  }

  private prepTransactionsForSubmission(): CreditTransaction[] {
    const transaction = this.calculationStructure.transactions.find(
      t => t._id === this.transactionId
    )
    if (!transaction) {
      return []
    }
    return [transaction].map(transaction => {
      const { saved_pricing_curve_id, ...transactionWithoutPricingCurve } =
        transaction
      return {
        ...transactionWithoutPricingCurve,
        tranches: transaction.tranches.map(tranche => {
          return { ...tranche, rol: 1 }
        }),
      }
    })
  }
}
