import { createAction, props } from '@ngrx/store'
import {
  AssignedLines,
  QuoteFields,
  QuoteReinsurer,
  ReinsurerSubjectivity,
  ReinsurerTrackingSubjectivity,
  SaveQuoteReinsurerResponse,
  SlidingScale,
  Subjectivity,
  WhitespaceSyncCustomWarning,
} from '../../models/quote.model'
import { ApplicationError } from 'src/app/error/model/error'
import { ReinsurerState } from './reinsurer.reducer'

export const fetchQuoteReinsurerByLayerRef = createAction(
  '[Quote Reinsurer] Fetch',
  props<{ ids: string[] }>()
)

export const fetchQuoteReinsurerByLayerRefSuccess = createAction(
  '[Quote Reinsurer] Fetch Success',
  props<{ reinsurers: QuoteReinsurer[]; layerRef?: string }>()
)

export const fetchQuoteReinsurerByLayerRefFailure = createAction(
  '[Quote Reinsurer] Fetch Failure',
  props<{ error: ApplicationError }>()
)

export const addOrUpdateQuoteReinsurer = createAction(
  '[Quote Reinsurer] Add/Update',
  props<{
    reinsurer: QuoteReinsurer
    isLastAutoBuildSection?: boolean
    isLinkExpiring?: boolean
    isSection?: boolean
    isQQSave?: boolean
  }>()
)

export const addOrUpdateQuoteReinsurerSuccess = createAction(
  '[Quote Reinsurer] Add/Update Success',
  props<{ reinsurer: QuoteReinsurer; isSection?: boolean; isQQSave?: boolean }>()
)

export const saveQuoteReinsurer = createAction(
  '[Quote Reinsurer] Save',
  props<{ reinsurer: QuoteReinsurer }>()
)

export const saveQuoteReinsurerSuccess = createAction(
  '[Quote Reinsurer] Save Success',
  props<SaveQuoteReinsurerResponse>()
)

export const saveQuoteReinsurerFailure = createAction(
  '[Quote Reinsurer] Save Failure',
  props<{ error: ApplicationError }>()
)

export const updateQuoteFields = createAction(
  '[Quote Reinsurer] Update QuoteFields',
  props<{ id: string; change: Partial<QuoteFields>; isSection: boolean }>()
)

export const updateReinsurerName = createAction(
  '[Quote Reinsurer] Update Reinsurer Name',
  props<{ id: string; name: string; tpRef: string }>()
)

export const updateSelectedID = createAction(
  '[Quote Reinsurer] Update Selected ID',
  props<{ id: string }>()
)

export const saveSubjectivitiesArray = createAction(
  '[Quote Reinsurer] Save Subjectivity',
  props<{ id: string; subjectivity: Subjectivity[] }>()
)

export const saveSubjectivitiesArraySuccess = createAction(
  '[Quote Reinsurer] Save Subjectivity Success',
  props<{ id: string; subjectivity: ReinsurerSubjectivity[], bypassFOT: boolean }>()
)

export const populateSubjectivitiesArray = createAction(
  '[Quote Reinsurer] Populate From Subjectivity',
  props<{ id: string; subjectivity: Subjectivity[] }>()
)

export const updateSubjectivitiesArray = createAction(
  '[Quote Reinsurer] Update Subjectivity',
  props<{ id: string; subjectivity: ReinsurerSubjectivity[], bypassFOT: boolean }>()
)

export const addSubjectivity = createAction(
  '[Quote Reinsurer] Add New Subjectivity',
  props<{ id: string; sub: Subjectivity }>()
)

export const deleteUnsavedSubjectivity = createAction(
  '[Quote Reinsurer] Delete Unsaved Subjectivity',
  props<{ id: string }>()
)

export const deleteSubjectivity = createAction(
  '[Quote Reinsurer] Delete Subjectivity',
  props<{ id: string; reinsurerID: string }>()
)
export const deleteSubjectivitySuccess = createAction(
  '[Quote Reinsurer] Delete Subjectivity Success',
  props<{ id: string; reinsurerID: string }>()
)

export const expandClick = createAction(
  '[Quote Reinsurer] Update Expand Click',
  props<{ isOpen: boolean; name: string }>()
)

export const populateFromTo = createAction(
  '[Quote Reinsurer] Populate From To Click',
  props<{ fromID: string; toID: string; isSection?: boolean }>()
)

export const deleteUnsavedQuoteReinsurer = createAction(
  '[Quote Reinsurer] Delete Unsaved',
  props<{ reinsurer: QuoteReinsurer }>()
)

export const deleteQuoteReinsurer = createAction(
  '[Quote Reinsurer] Delete',
  props<{ id: string }>()
)

export const deleteQuoteReinsurerSuccess = createAction(
  '[Quote Reinsurer] Delete Success',
  props<{ id: string }>()
)

export const deleteQuoteReinsurerFailure = createAction(
  '[Quote Reinsurer] Delete Failure',
  props<{ error: ApplicationError }>()
)

export const declineQuoteReinsurer = createAction(
  '[Quote Reinsurer] Decline',
  props<{ reinsurer: QuoteReinsurer }>()
)

export const preferredQuoteReinsurer = createAction(
  '[Quote Reinsurer] Is Preferred',
  props<{ reinsurer: QuoteReinsurer }>()
)

export const exportToggleReinsurer = createAction(
  '[Quote Reinsurer] Export Toggle',
  props<{ reinsurer: QuoteReinsurer }>()
)

export const slidingValueUpdate = createAction(
  '[Quote Reinsurer] Sliding Scale Value Update',
  props<{ id: string; slidingTable: SlidingScale[] }>()
)

export const deleteReinstatement = createAction(
  '[Quote Reinsurer] Delete Reinstatement',
  props<{ id: number }>()
)

export const updateAssignedLinesArray = createAction(
  '[Quote Reinsurer] Update Assigned Lines',
  props<{ id: string; assignedLines: AssignedLines[] }>()
)

export const addWhitespaceAssignedLinesSyncWarning = createAction(
  '[Quote Reinsurer] Add whitespace sync warning',
  props<{ warning: WhitespaceSyncCustomWarning }>()
)

export const postedWhitespaceSyncWarnings = createAction(
  '[Quote Sage] Posted Whitespace Sync Warnings'
)

export const updateAssignedLineReinsurer = createAction(
  '[Quote Reinsurer] Change Assigned Line Reinsurer',
  props<{ reinsurerState: ReinsurerState[] }>()
)

export const updateAssignedLinesArrayFromWS = createAction(
  '[Quote Reinsurer] Update Assigned Lines From White Space',
  props<{ id: string; assignedLines: AssignedLines[] }>()
)
export const addAssignedLines = createAction(
  '[Quote Reinsurer] Add New Assigned Lines',
  props<{ id: string; al: AssignedLines }>()
)

export const deleteAssignedLines = createAction(
  '[Quote Reinsurer] Delete Assigned Lines',
  props<{ id: string }>()
)

export const updateLabel = createAction(
  '[Quote Reinsurer] Update Label',
  props<{ id: string; label: string }>()
)

export const setSubAlReNameAndId = createAction(
  '[Quote Reinsurer] Set Assigned Lines Reinsurer / ID',
  props<{ reName: string; ralId: number }>()
)

export const saveLayerClassPerSection = createAction(
  '[Quote Reinsurer] Save Layer Class Per Section'
)

export const fetchSubjectivitiesByReinsurer = createAction(
  '[Quote Reinsurer] Fetch Subjectivities By Reinsurer',
  props<{ tpRef: string }>()
)
export const fetchSubjectivitiesByReinsurerFailure = createAction(
  '[Quote Reinsurer] Fetch Subjectivities By Reinsurer Faliure',
  props<{ error: ApplicationError }>()
)
export const fetchSubjectivitiesByReinsurerSuccess = createAction(
  '[Quote Reinsurer] Fetch Subjectivities By Reinsurer Success',
  props<{ subjectivities: ReinsurerTrackingSubjectivity[] }>()
)
