import { createAction, props } from '@ngrx/store'
import { Program } from '../../core/model/program.model'
import { ProgramGroup } from './grouper/program-group.model'
import { ApplicationError } from '../../error/model/error'
import { PortfolioSetID } from '../model/portfolio-set.model'
import {
  Portfolio,
  LogicalPortfolioLayer,
} from 'src/app/api/analyzere/analyzere.model'
import { CompareView } from '../model/compare-view.model'
import { Layer, LayerRef } from '../model/layers.model'
import { LossSetLayer } from '../model/loss-set-layers.model'
import {
  InuranceRelationship,
  InuranceTagsByLevel,
} from '../model/inurance.model'
import { LogicalPortfolioLayerWithChecked } from '../../tier/swap-loss-sets-dialog/swap-loss-sets-dialog.component'
import { Dictionary } from '@ngrx/entity/src'

export interface AnalysisInitProps extends PortfolioSetID {
  clientID: string
  studyID: string
  yearID: string
  structureID: string
  compareViews?: CompareView[]
  programs?: Program[]
  programGroups?: ProgramGroup[]
}

export interface AnalysisStartProps extends AnalysisInitProps {
  fromSave: boolean
}

export interface LayerData {
  layer_id: string
  starting_cession: number
  color: string
  lossSetGroupIDs?: string[]
}

export const layerDataUpload = createAction(
  '[Analysis] Layer Data Upload',
  props<{ layerData: LayerData[] }>()
)

export const layerDataUploadSuccess = createAction(
  '[Analysis] Layer Data Upload Success'
)

export const layerDataUploadFailure = createAction(
  '[Analysis] Layer Data Upload Failure',
  props<{ error: ApplicationError }>()
)

export const startAnalysis = createAction(
  '[Analysis] Start',
  props<AnalysisStartProps>()
)

export const setIsNewDropLayerSaving = createAction(
  '[Ceded Layers] Is New Drop Layer Saving',
  props<{ value: boolean }>()
)

export const imageUpload = createAction(
  '[Analysis] Image Upload',
  props<{ imageData: any }>()
)

export const imageUploadSuccess = createAction(
  '[Analysis] Image Upload Success',
  props<{ structureID: string | null; res: any }>()
)

export const imageUploadFailure = createAction(
  '[Analysis] Image Upload Failure',
  props<{ error: ApplicationError }>()
)

export const reinitialize = createAction(
  '[Analysis] Reinitialize',
  props<AnalysisInitProps>()
)

export const saveAnalysis = createAction('[Analysis] Save')

export const resetAnalysis = createAction('[Analysis] Reset')

export const saveAsAnalysis = createAction(
  '[Analysis] Save As',
  props<{
    name?: string
    description?: string
    analysisProfileID?: string
    parentGrossPortfolioID?: string
    asScenario?: boolean
  }>()
)

export const saveAsAnalysisFailure = createAction(
  '[Analysis] Save As Failure',
  props<{ error: ApplicationError }>()
)

export const saveAsAnalysisSuccess = createAction(
  '[Analysis] Save As Success',
  props<{
    program: Program
    clientID: string
    studyID: string
    otherPrograms?: Program[]
  }>()
)

export const toggleAggregate = createAction(
  '[Analysis] Toggle Aggregate',
  props<{ showAggregate: boolean }>()
)

export const toggleOccurrence = createAction(
  '[Analysis] Toggle Occurrence',
  props<{ showOccurrence: boolean }>()
)

export const updateZoom = createAction(
  '[Analysis] Update Zoom',
  props<{ zoomValue: number }>()
)

export const currentCurrency = createAction(
  '[Analysis] current Currency',
  props<{ currentCurrency: string }>()
)

export const mapLossSets = createAction(
  '[Analysis] Map Loss Sets',
  props<{ mapLossSets: LogicalPortfolioLayerWithChecked[][] }>()
)

export const updateParentGrossFailure = createAction(
  '[Analysis] Update Parent Gross Failure',
  props<{ error: ApplicationError }>()
)

export const updateGrossSuccess = createAction(
  '[Analysis] Update Gross Success',
  props<{ portfolio: Portfolio }>()
)

export const updateGrossFailure = createAction(
  '[Analysis] Update Gross Failure',
  props<{ error: ApplicationError }>()
)

export const updateGrossPortfolio = createAction(
  '[Analysis] Update Gross Portfolio',
  props<{ grossPortfolioID: string }>()
)

export const saveAsCloneAnalysis = createAction(
  '[Analysis] Save As Clone',
  props<{
    analysisProfileID?: string
    parentGrossPortfolioID?: string
    name?: string
    description?: string
    asScenario?: boolean
    layers?: LossSetLayer[] | LayerRef[]
    cededLayers?: LogicalPortfolioLayer[] | undefined
    lossSetNames?: string[]
    index?: number
    program?: {
      id: string
      label: string
      description?: string
      studyID?: string
    }
    autobuildID?: string
    isClone: boolean
  }>()
)

export const saveAsCloneAnalysisFailure = createAction(
  '[Analysis] Save As Clone Failure',
  props<{ error: ApplicationError }>()
)

export const saveAsCloneAnalysisSuccess = createAction(
  '[Analysis] Save As Clone Success',
  props<{
    program: Program
    clientID: string
    studyID: string
    otherPrograms?: Program[]
  }>()
)

export const addInuranceTagsByLevel = createAction(
  '[Analysis] Add Inurance Tags By Level in Design'
)

export const addInuranceTagsByLevelSuccess = createAction(
  '[Analysis] Add Inurace Tags by Level in Design Success',
  props<{ tagsByLevel: InuranceTagsByLevel; symbolMap: Dictionary<string> }>()
)

export const openAddInuranceDialog = createAction(
  '[Analysis] Open Add Inurance Dialog',
  props<{
    currentProgram: Program | undefined
    currentLayer: Layer | undefined
    isEdit: boolean
    isAdd: boolean
    fromMultiSection?: boolean
  }>()
)

export const openAddInuranceDialogForEdit = createAction(
  '[Analysis] Open Add Inurance Dialog For Edit',
  props<{
    currentProgram: Program | undefined
    currentLayer: Layer | undefined
    layer: LossSetLayer | Layer | undefined
    structure: Program | undefined
    group: ProgramGroup | undefined
    relationship: InuranceRelationship
    isEdit: boolean
    isAdd: boolean
  }>()
)

export const openAddInuranceDialogForEditFailure = createAction(
  '[Analysis] Open Add Inurance Dialog For Edit Failure',
  props<{ error: ApplicationError }>()
)

export const openAddInuranceDialogForEditSuccess = createAction(
  '[Analysis] Open Add Inurance Dialog For Edit Success'
)

export const fetchLayersByStructure = createAction(
  '[Analysis] Fetch Layers By Structure',
  props<{ structure: Program; inurance: string }>()
)

export const fetchLayersByStructureSuccess = createAction(
  '[Analysis] Fetch Layers By Structure Success',
  props<{
    structure: Program
    layers: Layer[]
    inurance: string
  }>()
)

export const fetchLayersByStructureFailure = createAction(
  '[Analysis] Fetch Layers By Structure Failure',
  props<{ error: ApplicationError }>()
)

export const addGroupForInurance = createAction(
  '[Analysis] Add Group for Inurance',
  props<{ group: ProgramGroup }>()
)

export const addProgramForInurance = createAction(
  '[Analysis] Add Structure for Inurance',
  props<{ structure: Program; layers: Layer[] }>()
)

export const updateLayerNames = createAction('[Ceded Layers] Update Layer Name')

export const updateLayerNameSuccess = createAction(
  '[Ceded Layers] Update Layer Name Success'
)

export const updateLayerNameFailure = createAction(
  '[Ceded Layers] Update Layer Name Failure',
  props<{ error: ApplicationError }>()
)
