import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { Router } from '@angular/router'
import { NavService } from '../../nav.service'
import { MatDialog } from '@angular/material/dialog'
import { ReinsurerCustomBreakoutDialogComponent } from '../reinsurer-custom-breakouts-dialog/reinsurer-custom-breakouts-dialog.component'
import { Client } from 'src/app/core/model/client.model'
import { Study } from 'src/app/core/model/study.model'
import { AccountOpportunity } from 'src/app/api/model/backend.model'
import { SelectedUserPreferences } from 'src/app/core/model/reinsurer.model'
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-reinsurer-header',
  styleUrls: ['./reinsurer-header.component.scss'],
  templateUrl: './reinsurer-header.component.html',
})
export class ReinsurerHeaderComponent {
  @Input() client: Client | null
  @Input() name: string
  @Input() activeAction: string
  @Input() reinsurerDirty: boolean
  @Input() accountOpportunities: AccountOpportunity[] | null
  @Input() programs: Study[]
  @Input() selectedProgramID: string | null | undefined
  @Input() selectedUserPreferences: SelectedUserPreferences | null
  @Input() userPreferencesColumnsList: string[]
  @Input() defaultUSAUserPreferences: SelectedUserPreferences

  @Input() isAddSharedLayer: boolean
  @Input() isDeleteSharedLayer: boolean

  @Input() opportunityRenewed: boolean

  @Output() saveClick = new EventEmitter()
  @Output() addSharedLayerClick = new EventEmitter()
  @Output() deleteSharedLayerClick = new EventEmitter()
  @Output() exportClick = new EventEmitter<string[]>()

  get disableSave(): boolean {
    return !this.reinsurerDirty
  }

  get subtitle(): string {
    return 'SAGE offers a variety of analytics-driven insights. Please select from the available programs for your chosen company.\nYou can return to the selection page to choose another company.'
  }

  constructor(
    public navService: NavService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  onBackClick(): void {
    this.router.navigate(['/home'])
  }

  onSaveClick(): void {
    if (!this.disableSave) {
      this.saveClick.emit()
    }
  }

  onExportClick(): void {
    const selectedProgram = this.programs?.find(
      p => p.id === this.selectedProgramID
    )
    const opportunity = this.accountOpportunities?.find(
      o => o.id === selectedProgram?.opportunity_id
    )
    const data = {
      hasPriorData: this.opportunityRenewed,
      client: this.client,
      selectedProgramID: this.selectedProgramID,
      opportunityDetails: opportunity,
      userPreferences: this.selectedUserPreferences,
      userPreferencesColumnList: this.userPreferencesColumnsList,
      defaultUSAUserPreferences: this.defaultUSAUserPreferences,
    }
    const height = data.hasPriorData ? '75vh' : '18vh'
    const dialogRef = this.dialog.open(ReinsurerCustomBreakoutDialogComponent, {
      minWidth: '40vw',
      width: '40vw',
      maxWidth: '40vw',
      height: height,
      minHeight: height,
      data,
    })
    dialogRef.componentInstance.exportClick.subscribe((dialogData: any) => {
      this.exportClick.emit(dialogData)
    })
  }

  onDeleteSharedLimitClick(): void {
    if (this.isAddSharedLayer) {
      this.deleteSharedLayerClick.emit()
    }
    if (this.isDeleteSharedLayer) {
      this.addSharedLayerClick.emit()
    }
  }
}
