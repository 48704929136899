import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { Reinsurer } from '../../core/model/reinsurer.model'
import { LayerView } from '../../analysis/model/layer-view'
import { Layer } from '../../analysis/model/layers.model'
import { LayerState } from '../../analysis/store/ceded-layers/layers.reducer'
import { ReinsurerState } from '../store/reinsurer/reinsurer.reducer'
import { SectionState } from '../store/section/section.reducer'
import getQuotePanelDefs, {
  QuotePanelDefResolved,
} from '../quote-panel/quote-panel-defs'
import {
  AssignedLines,
  FOT_MASTER_REINSURER_NAME,
  QuoteFields,
  QuoteReinsurer,
  ReinsurerPhase,
  ReinsurerSubjectivity,
  Section,
  SlidingScale,
} from '../models/quote.model'
import { Territories } from '../../api/territory/territory.service'
import { layerIds } from '../../analysis/model/layer-palette.model'
import { AccountOpportunity } from 'src/app/api/model/backend.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quote-reinsurer',
  styleUrls: ['./quote-reinsurer.component.scss'],
  templateUrl: './quote-reinsurer.component.html',
})
export class QuoteReinsurerComponent implements OnChanges {
  view: LayerView | null
  quotePanelDefs: QuotePanelDefResolved[] = []
  currentReinsurer: QuoteReinsurer | undefined
  groupReinsurers: QuoteReinsurer[]
  layerIds = layerIds
  @Input() reinsurers: QuoteReinsurer[]
  @Input() selectedReinsurer: string | null
  @Input() layerState: LayerState[] = []
  @Input() layer: LayerState | null | undefined
  @Input() layerStateGroup: LayerState[] = []
  @Input() layerGroup: LayerState | null | undefined
  @Input() isExpanded: boolean
  @Input() expandedName: string | null
  @Input() isFOTMasterCreated: boolean
  @Input() allReinsurers: QuoteReinsurer[]
  @Input() isSaving: boolean
  @Input() reinsurerList: ReinsurerState[]
  @Input() reinsurersNameListUpdated: Reinsurer[]
  @Input() selectedSection: Section | null
  @Input() firstReinsurerName: string
  @Input() index: number
  @Input() selectedStructureGroupID?: string | null
  @Input() isGroupSelected = false
  @Input() sectionList: SectionState[]
  @Input() selectedSharedLimitID?: string | null
  @Input() isSLSelected = false
  @Input() layersSL: Layer[] | null
  @Input() panelExpand: number[]
  @Input() territories: Territories
  @Input() layerInd: number
  @Input() accountOpportunity: AccountOpportunity | null
  @Input() showCustomCompare: boolean
  @Output() panelExpandClick = new EventEmitter<{ arg: number }>()
  @Output() panelCollapseClick = new EventEmitter<{ arg: number }>()
  @Output() subjectivityClick = new EventEmitter<{ id: string }>()
  @Output() reinsurerResize = new EventEmitter<{
    field: Partial<QuoteFields>
    id: string
  }>()
  @Output() saveClick = new EventEmitter<{ reinsurer: QuoteReinsurer }>()
  @Output() deleteClick = new EventEmitter<{ reinsurer: QuoteReinsurer }>()
  @Output() declineClick = new EventEmitter<{ reinsurer: QuoteReinsurer }>()
  @Output() preferredClick = new EventEmitter<{ reinsurer: QuoteReinsurer }>()
  @Output() exportToggleClick = new EventEmitter<{
    reinsurer: QuoteReinsurer
  }>()
  @Output() nameSelection = new EventEmitter<{
    reinsurerName: string
    id: string
    tpRef: string
  }>()
  @Output() addOrUpdateVersionReinsurer = new EventEmitter<{
    reinsurerId?: string
    version: string
    label?: string
    reinsurerName: string
    phase: ReinsurerPhase
    subjectivity: ReinsurerSubjectivity[]
    assignedLines: AssignedLines[]
    isPreferred?: boolean
  }>()
  @Output() selectedID = new EventEmitter<string>()
  @Output() expandClick = new EventEmitter<{ isOpen: boolean; name: string }>()
  @Output() populateClick = new EventEmitter<{ from: string; to: string }>()
  @Output() slidingValueChange = new EventEmitter<{
    id: string
    slidingTable: SlidingScale[]
  }>()
  @Output() deleteReinstatement = new EventEmitter<{ id: number }>()
  @Output() expandedReinsurer = new EventEmitter<QuoteReinsurer | undefined>()
  @Output() currentReinsurerChange = new EventEmitter<{
    index: number
    currentReinsurer: QuoteReinsurer
  }>()
  @Output() assignedLinesClick = new EventEmitter<{ id: string }>()
  @Output() updateLabel = new EventEmitter<{
    reinsurerId: string
    label: string
  }>()
  @Output() scrollChangeNum = new EventEmitter<number>()
  @Output() isUKReinsurerEvent = new EventEmitter<boolean>()

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (this.layer || this.layerGroup || this.layersSL)) {
      this.currentReinsurer = this.reinsurers.find(
        r => r.id === this.selectedReinsurer
      )

      if (changes.selectedReinsurer) {
        this.currentReinsurer = this.reinsurers.find(
          r => r.id === changes.selectedReinsurer.currentValue
        )
      }

      // Fallback: Pick according to current layer.
      if (!this.currentReinsurer) {
        this.currentReinsurer = this.reinsurers.find(
          r => r.cededlayerID === this.layer?.layer.id
        )
      }

      // Second Fallback: Pick last reinsurer in the list.
      if (!this.currentReinsurer) {
        this.currentReinsurer = this.reinsurers[this.reinsurers.length - 1]
      }

      this.currentReinsurerChange.emit({
        index: this.index,
        currentReinsurer: this.currentReinsurer,
      })
      if (this.layer) {
        this.view = new LayerView(this.layerState, this.layer.layer, {
          quoteReinsurer: this.currentReinsurer,
        })
      } else if (this.layerGroup) {
        this.view = new LayerView(this.layerStateGroup, this.layerGroup.layer, {
          quoteReinsurer: this.currentReinsurer,
        })
      } else if (this.layersSL) {
        this.view = new LayerView([], this.layersSL[0], {
          quoteReinsurer: this.currentReinsurer,
        })
      }
      const isDecline = this.currentReinsurer.decline
        ? this.currentReinsurer.decline
        : false
      const xplEcoType = this.currentReinsurer.quoteFields?.xplEcoDropdown
        ? this.currentReinsurer.quoteFields?.xplEcoDropdown
        : 'Neither apply'
      const fotMaster =
        this.currentReinsurer.quoteReinsurerName === FOT_MASTER_REINSURER_NAME
      if (this.view) {
        this.quotePanelDefs = getQuotePanelDefs(
          this.view.values,
          isDecline,
          xplEcoType,
          fotMaster
        )
      }
      const isMultiSection =
        this.layer?.layer.meta_data.sage_layer_type === layerIds.catMultisection
      const isTnD =
        this.layer?.layer.meta_data.sage_layer_type === layerIds.catTd &&
        this.layer?.layer.meta_data.sage_layer_subtype === 'actual'
      const updateGroupReinsurers =
        this.isGroupSelected || this.isSLSelected || isMultiSection || isTnD
      if (updateGroupReinsurers && this.currentReinsurer) {
        this.groupReinsurers = this.reinsurers.filter(f => {
          if (f.reinsurerPhase !== this.currentReinsurer?.reinsurerPhase) {
            return false
          }
          if (
            f.reinsurerPhaseVersion !==
            this.currentReinsurer.reinsurerPhaseVersion
          ) {
            return false
          }
          if (this.layer?.layer.meta_data.sage_layer_type === layerIds.catTd) {
            return f.cededlayerID !== this.layer.layer.id
          }
          if (
            this.layer?.layer.meta_data.sage_layer_type ===
            layerIds.catMultisection
          ) {
            const visibleLayerId = this.currentReinsurer.cededlayerID
            const ownsSection = this.layerState.find(
              ({ layer }) =>
                layer.meta_data.sage_layer_subtype === 'main-layer' &&
                layer.meta_data.visible_layer_id === visibleLayerId &&
                layer.layerRefs.includes(f.cededlayerID ?? '')
            )
            return f.cededlayerID !== visibleLayerId && ownsSection
          }
          return true
        })
      }
    }
  }

  reinsurerClick(): void {
    this.selectedID.emit(this.currentReinsurer?.id)
  }

  getLayerGroup(reinsurer: QuoteReinsurer): LayerState | undefined {
    return this.layerStateGroup.find(f => f.layer.id === reinsurer.cededlayerID)
  }

  getLayerSL(reinsurer: QuoteReinsurer): Layer | undefined {
    return this.layersSL?.find(f => f.id === reinsurer.cededlayerID)
  }

  getLayerMulti(reinsurer: QuoteReinsurer): Layer | undefined {
    return this.layerState?.find(f => f.layer.id === reinsurer.cededlayerID)
      ?.layer
  }

  getLayerForSL(): Layer[] | undefined {
    if (this.layersSL && this.layersSL.length > 0) {
      return this.layersSL
    }
  }

  getView(reinsurer: QuoteReinsurer): LayerView | null | undefined {
    if (this.isGroupSelected) {
      const re = this.getLayerGroup(reinsurer)
      if (re) {
        return new LayerView(this.layerStateGroup, re.layer, {
          quoteReinsurer: reinsurer,
        })
      }
    }

    if (this.isSLSelected) {
      const re = this.getLayerSL(reinsurer)
      if (re) {
        return new LayerView([], re, {
          quoteReinsurer: reinsurer,
        })
      }
    }

    const layerType = this.layer?.layer.meta_data.sage_layer_type
    if (
      layerType === layerIds.catMultisection ||
      layerType === layerIds.catTd
    ) {
      const re = this.getLayerMulti(reinsurer)
      if (re) {
        return new LayerView([], re, {
          quoteReinsurer: reinsurer,
        })
      }
    }

    return this.view
  }

  getQuotePanelDefValues(
    reinsurer: QuoteReinsurer
  ): QuotePanelDefResolved[] | undefined {
    const view = this.getView(reinsurer)
    if (view) {
      return getQuotePanelDefs(
        view.values,
        reinsurer.decline ?? false,
        reinsurer.quoteFields?.xplEcoDropdown ?? 'Neither apply',
        reinsurer.quoteReinsurerName === FOT_MASTER_REINSURER_NAME
      )
    }
  }

  getLayerName(reinsurer: QuoteReinsurer): string {
    if (this.isGroupSelected) {
      return (
        this.layerStateGroup.find(f => f.layer.id === reinsurer.cededlayerID)
          ?.layer.meta_data.layerName || 'Layer 1'
      )
    } else if (this.isSLSelected) {
      return (
        this.layersSL?.find(f => f.id === reinsurer.cededlayerID)?.meta_data
          .layerName || 'Shared Limit Layer'
      )
    } else {
      return (
        this.layerState.find(f => f.layer.id === reinsurer.cededlayerID)?.layer
          .physicalLayer.description || 'Layer 1'
      )
    }
  }

  getSelectedSection(reinsurer: QuoteReinsurer): Section | undefined {
    const sectionFound = this.sectionList.find(
      s => s.section.layerRef === reinsurer.cededlayerID
    )?.section
    if (sectionFound) {
      return sectionFound
    } else {
      return this.sectionList.find(
        s => s.section.layerType === layerIds.catMultisection
      )?.section
    }
  }

  isLayerExpanded(layerIndex: number): boolean {
    if (this.panelExpand.length === 0) {
      return false
    }
    return this.panelExpand.includes(layerIndex)
  }

  isSection(): boolean {
    return !this.isSLSelected && !this.isGroupSelected
  }
}
