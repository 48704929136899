import { clone, lensPath, set, view } from 'ramda'

import { EventEmitter } from '@angular/core'
import {
  customIndexValuesFromString,
  customIndexValuesToString,
} from 'src/app/analysis/layers/indexed-layer'
import { isSwingLayer } from 'src/app/analysis/layers/swing-layer'
import { Fee, Reinstatement } from '../../api/analyzere/analyzere.model'
import { Reinsurer } from '../../core/model/reinsurer.model'
import { analyzereConstants } from '@shared/constants/analyzere'
import { QuoteReinsurer, Reinstatements } from '../../quote/models/quote.model'
import { LayerState } from '../store/ceded-layers/layers.reducer'
import { LayerMetrics } from './layers-metrics.model'
import { Layer } from './layers.model'
import {
  createProportionalExpense,
  isLayerAgg,
  isLayerAggFeeder,
  canSetContractOccurrenceUnlimited,
} from './layers.util'
import { LossSetLayer } from './loss-set-layers.model'
import { ShortNumberPipe } from '@shared/pipes/short-number.pipe'
import {
  AggLayers,
  LAYER_PALETTE,
  layerIds,
  OccLayers,
  QsLayers,
} from './layer-palette.model'
import {
  SavedPricingCurveEntry,
  TechnicalFactors,
} from 'src/app/pricingcurve/model/pricing-curve.model'
import { Program } from 'src/app/core/model/program.model'
import { isMultiSectionLayer } from '../layers/multi-section-layer'
import { DefaultSavedCurveEntry } from '../technical-premium/technical-premium.model'
import { getPricingCurvesForLayer } from '../technical-premium/technical-premium.utils'

export const unlimitedSetAttempt = new EventEmitter<string>()

/** `LayerView` props not copied to its `values` prop. */
const omittedLayerValues = [
  'values',
  'layer',
  'visibleLayer',
  'quoteReinsurer',
  'lossSets',
  'metrics',
  'reinsurers',
  'updateValues',
  'setFromValues',
  'programDefaultPricingCurves',
  'savedPricingCurves',
  'currentProgram',
] as const

const isGetter =
  (o: any) =>
  (name: string): boolean =>
    (Object.getOwnPropertyDescriptor(o, name) || {}).get != null

const isSetter =
  (o: any) =>
  (name: string): boolean =>
    (Object.getOwnPropertyDescriptor(o, name) || {}).set != null

const getGetters = (o: any, omitted: readonly string[] = []): string[] =>
  Object.getOwnPropertyNames(o)
    .filter(isGetter(o))
    .filter(name => !omitted.includes(name))

const getSetters = (o: any, omitted: readonly string[] = []): string[] =>
  Object.getOwnPropertyNames(o)
    .filter(isSetter(o))
    .filter(name => !omitted.includes(name))

type Writable<T> = {
  -readonly [K in keyof T]: T[K]
}

export type LayerValues = Writable<
  Omit<LayerView, (typeof omittedLayerValues)[number]>
>

export interface LayerViewValues extends LayerValues, LayerMetrics {
  expectedCededPremium: number
  pmpmExpectedCededPremium: number
  efficiencyVolatility: number
  efficiencyTVaR: number
  expectedReinstatementPremium: number
  grossVolatilityCeded: number
  totalQuoteExpectedCededLoss: number
  totalQuoteExpectedCededPremium: number
  expectedLoss: number
  reinstatementsSummary: string
  expectedLossPercentLimit: number | string
  contractROL: number | string
  totalROL: number | string
  premiumMultiple: number | undefined
  probabilityExhaust: number | undefined
  currencyChange: string
  portfolioEfficiencyScore: number
}

export class LayerView {
  private _lossSets: LossSetLayer[] = []
  private _metrics: LayerMetrics | undefined | null
  private _grossPortfolioCovariance: number | undefined | null
  private _reinsurers: Reinsurer[] | undefined
  private _savedPricingCurves: SavedPricingCurveEntry[] | undefined
  private _quoteReinsurer: QuoteReinsurer | undefined
  private _programDefaultPricingCurves: DefaultSavedCurveEntry[] | undefined
  private _currentProgram: Program | undefined

  private _layerType: string | undefined
  private _programType: string | undefined
  private _prevOccurrenceLimit = 0
  private _prevContractOccurrenceLimit = 0
  private _prevAggregateLimit = 0
  private _prevQuoteOccurrenceLimit = 0
  private _prevQuoteTopOccurrenceLimit = 0
  private _prevQuoteDropOccurrenceLimit = 0
  private _prevQuoteAggregateLimit = 0
  private _prevQuoteAggregateLimitTop = 0
  private _prevQuoteAggregateLimitDrop = 0

  private _sumOfLossOrSubPrem = 0

  layers: LayerState[] | null | undefined
  layer: Layer
  values: LayerViewValues
  parentLayerId?: string
  isParent?: boolean

  /* Set in LayerDetailsComponent for complex layers */
  visibleLayer?: Layer

  get description(): string | undefined {
    const shortNumberPipe = new ShortNumberPipe()
    function formatCurrency(n: number, currency: string | undefined) {
      return shortNumberPipe.transform(n, currency)
    }

    if (AggLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      return `${formatCurrency(
        this.aggregateLimit,
        this.currency
      )} x ${formatCurrency(
        this.aggregateAttachment,
        this.currency
      )} with ${formatCurrency(this.occurrenceAttachment, this.currency)} EELD`
    }
    if (OccLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      return `${formatCurrency(
        this.occurrenceLimit,
        this.currency
      )} x ${formatCurrency(this.occurrenceAttachment, this.currency)}`
    }
    if (QsLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      return `${formatCurrency(this.occurrenceLimit, this.currency)} QS`
    }
  }

  get lossSets(): LossSetLayer[] {
    return this._lossSets
  }
  set lossSets(value: LossSetLayer[]) {
    this._lossSets = value
    this._updateLossSetsValues()
  }

  get metrics(): LayerMetrics | undefined | null {
    return this._metrics
  }
  set metrics(value: LayerMetrics | undefined | null) {
    this._metrics = value
    this._updateMetricsValues()
  }

  get grossPortfolioCovariance(): number | null | undefined {
    return this._grossPortfolioCovariance
  }
  set grossPortfolioCovariance(value: number | null | undefined) {
    this._grossPortfolioCovariance = value
    this._updateGrossCovarianceValues()
  }

  get reinsurers(): Reinsurer[] | undefined {
    return this._reinsurers
  }
  set reinsurers(value: Reinsurer[] | undefined) {
    this._reinsurers = value
    this._updateReinsurersValues()
  }

  get programDefaultPricingCurves(): DefaultSavedCurveEntry[] | undefined {
    return this._programDefaultPricingCurves
  }

  set programDefaultPricingCurves(value: DefaultSavedCurveEntry[] | undefined) {
    this._programDefaultPricingCurves = value
  }

  get savedPricingCurves(): SavedPricingCurveEntry[] | undefined {
    return this._savedPricingCurves
  }
  set savedPricingCurves(value: SavedPricingCurveEntry[] | undefined) {
    this._savedPricingCurves = value
  }

  get currentProgram(): Program | undefined {
    return this._currentProgram
  }
  set currentProgram(value: Program | undefined) {
    this._currentProgram = value
  }

  get quoteReinsurer(): QuoteReinsurer | undefined {
    return this._quoteReinsurer
  }
  set quoteReinsurer(value: QuoteReinsurer | undefined) {
    this._quoteReinsurer = value
  }
  get inuringBenefit(): string | undefined {
    return this.layer.meta_data.inuranceTarget ? 'Yes' : 'No'
  }
  get id(): string {
    return this.layer.id
  }
  get type(): string | undefined {
    return this.layer.meta_data.sage_layer_type
  }
  get subtype(): string | undefined {
    return this.layer.meta_data.sage_layer_subtype
  }
  get sharedLayerID(): string | undefined {
    return this.layer.sharedLayerID
  }
  get layerType(): string | undefined {
    return this._layerType
  }
  get layerDetailsType(): string | undefined {
    const layerObj = LAYER_PALETTE.find(
      layer => layer.id === this.layer.meta_data.sage_layer_type
    )
    return layerObj?.role_type
  }
  get programType(): string | undefined {
    return this._programType
  }

  private _nameLens = this._physicalLens('description')
  get name(): string {
    return view(this._nameLens, this.layer) || 'Unnamed'
  }
  set name(value: string) {
    this.layer = set(this._nameLens, value, this.layer)
    this.layer.meta_data.layerName = value
    this.layer.physicalLayer.meta_data.layerName = value
  }

  private _narrativeLens = this._physicalLens('_narrativeLens')
  get sectionNarrative(): string {
    return view(this._narrativeLens, this.layer) || ''
  }
  set sectionNarrative(value: string) {
    this.layer = set(this._narrativeLens, value, this.layer)
    this.layer.meta_data.layer_narrative = value
  }

  private _currencyLens = this._physicalLens('franchise', 'currency')
  get currency(): string | undefined {
    if (this.layer.currency?.trim() !== '') {
      return this.layer.currency
    }
    return view(this._currencyLens, this.layer)
  }
  set currency(value: string | undefined) {
    this.layer = set(this._currencyLens, value, this.layer)
  }

  get isOccurrenceUnlimited(): boolean {
    return this.layer.physicalLayer.meta_data.isLimitUnlimited
      ? this.layer.physicalLayer.meta_data.isLimitUnlimited
      : this.layer.physicalLayer.meta_data.isLimitUnlimited === false
        ? false
        : this.occurrenceLimit >= analyzereConstants.unlimitedValue
  }
  set isOccurrenceUnlimited(value: boolean) {
    this.layer.physicalLayer.meta_data.isLimitUnlimited = value
    if (value) {
      this._prevOccurrenceLimit =
        this.occurrenceLimit < analyzereConstants.unlimitedValue
          ? this.occurrenceLimit
          : this.premium
      this.occurrenceLimit = analyzereConstants.unlimitedValue
      return
    }
    this.occurrenceLimit = this._prevOccurrenceLimit ?? this.premium
  }

  get isAggregateUnlimited(): boolean {
    return this.layer.physicalLayer.meta_data.isAggregateUnlimited
      ? this.layer.physicalLayer.meta_data.isAggregateUnlimited
      : this.layer.physicalLayer.meta_data.isAggregateUnlimited === false
        ? false
        : this.aggregateLimit >= analyzereConstants.unlimitedValue
  }
  set isAggregateUnlimited(value: boolean) {
    this.layer.physicalLayer.meta_data.isAggregateUnlimited = value
    if (value) {
      this._prevAggregateLimit = this.aggregateLimit
      this.aggregateLimit = analyzereConstants.unlimitedValue
      return
    }
    if (this._prevAggregateLimit) {
      this.aggregateLimit = this._prevAggregateLimit
    }
  }

  private _occLimitLens = this._physicalLens('limit', 'value')
  get occurrenceLimit(): number {
    if (isLayerAgg(this.layer) && this.layers) {
      // display feeder
      const feederLayer = this.layers.filter(layer =>
        isLayerAggFeeder(layer.layer)
      )
      return feederLayer[0].layer.physicalLayer.limit.value
    } else if (isMultiSectionLayer(this.layer)) {
      return this.contractOccurrenceLimit
    } else {
      return view(this._occLimitLens, this.layer)
    }
  }
  set occurrenceLimit(value: number) {
    this._prevOccurrenceLimit = this.occurrenceLimit
    this.layer = set(this._occLimitLens, value, this.layer)
    if (view(this._rolTypeLens, this.layer) === 'occ') {
      this.layer = set(
        this._rolPercentageLens,
        this.premium / value,
        this.layer
      )
    }
    if (this.layer && this.layer.meta_data.sage_layer_type === 'cat_ilw_bin') {
      this.layer = set(this._payoutLens, value, this.layer)
    }
    if (
      this.layer &&
      this.layer.meta_data.sage_layer_type === 'cat_ilw_pro_rata'
    ) {
      this.layer = set(
        this._cessionPercentageLens,
        -1 * (this.payout / value),
        this.layer
      )
    }
  }

  private _contractOccurrenceLimitLens = this._physicalLens(
    'meta_data',
    'contractOccurrenceLimit'
  )
  get contractOccurrenceLimit() {
    return view(this._contractOccurrenceLimitLens, this.layer)
  }

  set contractOccurrenceLimit(value: number) {
    if (
      value >= analyzereConstants.unlimitedValue &&
      !canSetContractOccurrenceUnlimited(this.layer, this.layers)
    ) {
      unlimitedSetAttempt.emit(
        'Cannot set Contract Occurrence Limit as unlimited if a section layer -> Occurrence Limit is unlimited.'
      )
      return
    }
    let limit = value
    // If contract occ. Limit is unlimited use first section occ. limit.
    if (
      this.layerType === 'multisection' &&
      value === analyzereConstants.unlimitedValue
    ) {
      const mainLayer = this.layers?.find(
        ({ layer }) =>
          layer.meta_data.sage_layer_subtype === 'main-layer' &&
          layer.meta_data.visible_layer_id === this.layer.id
      )
      const firstSectionId = mainLayer?.layer.layerRefs[0]
      const sectionLayer = this.layers?.find(
        ({ layer }) => layer.id === firstSectionId
      )
      limit = sectionLayer?.layer.physicalLayer.limit.value ?? limit
    }
    this.layer = set(this._premiumLens, limit * this.rolPercentage, this.layer)
    this.layer = set(this._contractOccurrenceLimitLens, value, this.layer)
  }

  get isContractOccurrenceUnlimited(): boolean {
    return this.contractOccurrenceLimit >= analyzereConstants.unlimitedValue
  }
  set isContractOccurrenceUnlimited(value: boolean) {
    if (value) {
      if (canSetContractOccurrenceUnlimited(this.layer, this.layers)) {
        this._prevContractOccurrenceLimit = this.contractOccurrenceLimit
        this.contractOccurrenceLimit = analyzereConstants.unlimitedValue
      } else {
        unlimitedSetAttempt.emit(
          'Cannot set Contract Occurrence Limit as unlimited if a section layer -> Occurrence Limit is unlimited.'
        )
      }
    } else {
      this.contractOccurrenceLimit = this._prevContractOccurrenceLimit
    }
  }

  private _occAttachLens = this._physicalLens('attachment', 'value')
  get occurrenceAttachment(): number {
    if (isLayerAgg(this.layer) && this.layers) {
      // display feeder
      const feederLayer = this.layers.filter(layer =>
        isLayerAggFeeder(layer.layer)
      )
      return feederLayer[0].layer.physicalLayer.attachment.value
    } else {
      return view(this._occAttachLens, this.layer)
    }
  }
  set occurrenceAttachment(value: number) {
    this.layer = set(this._occAttachLens, value, this.layer)
    if (
      (this.layer && this.layer.meta_data.sage_layer_type === 'cat_ilw_bin') ||
      this.layer.meta_data.sage_layer_type === 'cat_ilw_pro_rata'
    ) {
      this.layer = set(this._triggerLens, value, this.layer)
    }
  }

  private _franchiseDeductibleLens = this._physicalLens('franchise', 'value')
  get franchiseDeductible(): number {
    if (isLayerAgg(this.layer) && this.layers) {
      const feederLayer = this.layers.filter(layer =>
        isLayerAggFeeder(layer.layer)
      )
      return feederLayer[0].layer.physicalLayer.franchise.value
    } else {
      return view(this._franchiseDeductibleLens, this.layer)
    }
  }
  set franchiseDeductible(value: number) {
    this.layer = set(this._franchiseDeductibleLens, value, this.layer)
  }

  private _aggLimitLens = this._physicalLens('aggregateLimit', 'value')
  get aggregateLimit(): number {
    return view(this._aggLimitLens, this.layer)
  }
  set aggregateLimit(value: number) {
    this._prevAggregateLimit = this.aggregateLimit
    this.layer = set(this._aggLimitLens, value, this.layer)
    if (view(this._rolTypeLens, this.layer) === 'agg') {
      this.layer = set(
        this._rolPercentageLens,
        this.premium / value,
        this.layer
      )
    }
  }

  private _aggAttachLens = this._physicalLens('aggregateAttachment', 'value')
  get aggregateAttachment(): number {
    return view(this._aggAttachLens, this.layer)
  }
  set aggregateAttachment(value: number) {
    this.layer = set(this._aggAttachLens, value, this.layer)
  }

  private _indexationLens = this._physicalLens('meta_data', 'indexation')
  get indexation(): number {
    return view(this._indexationLens, this.layer)
  }
  set indexation(value: number) {
    this.layer = set(this._indexationLens, value, this.layer)
  }

  private _fixedIndexValueLens = this._physicalLens(
    'meta_data',
    'fixedIndexValue'
  )
  get fixedIndexValue(): number {
    return view(this._fixedIndexValueLens, this.layer)
  }
  set fixedIndexValue(value: number) {
    this.layer = set(this._fixedIndexValueLens, value, this.layer)
  }

  private _customIndexValuesLens = this._physicalLens(
    'meta_data',
    'customIndexValues'
  )
  get customIndexValues(): number[] {
    const values: string = view(this._customIndexValuesLens, this.layer)
    return values?.length > 0 ? customIndexValuesFromString(values) : []
  }
  set customIndexValues(values: number[]) {
    this.layer = set(
      this._customIndexValuesLens,
      customIndexValuesToString(values),
      this.layer
    )
  }

  private _isCustomIndexLens = this._physicalLens('meta_data', 'isCustomIndex')
  get isCustomIndex(): boolean {
    return view(this._isCustomIndexLens, this.layer)
  }
  set isCustomIndex(value: boolean) {
    this.layer = set(this._isCustomIndexLens, value, this.layer)
  }

  private _sicOrFranchiseLens = this._physicalLens(
    'meta_data',
    'sicOrFranchise'
  )
  get sicOrFranchise(): number {
    return view(this._sicOrFranchiseLens, this.layer)
  }
  set sicOrFranchise(value: number) {
    this.layer = set(this._sicOrFranchiseLens, value, this.layer)
  }

  private _reinstatementsLens = this._physicalLens('reinstatements')
  get reinstatements(): Reinstatement[] {
    return view(this._reinstatementsLens, this.layer)
  }
  set reinstatements(value: Reinstatement[]) {
    this.layer = set(this._reinstatementsLens, value, this.layer)
    if (value.length > 0) {
      const reLen = value.map(v => v.premium).filter(r => r !== undefined)
      this.layer = set(
        this._aggLimitLens,
        (reLen.length + 1) * this.occurrenceLimit,
        this.layer
      )
    }
  }

  private _cessionPercentageLens = this._physicalLens('participation')
  get cessionPercentage(): number {
    const n: number = view(this._cessionPercentageLens, this.layer)
    return isLayerAggFeeder(this.layer) ? n : -n
  }
  set cessionPercentage(value: number) {
    value = isLayerAggFeeder(this.layer) ? value : -value
    this.layer = set(this._cessionPercentageLens, value, this.layer)
    if (
      this.layer &&
      this.layer.meta_data.sage_layer_type === 'cat_ilw_pro_rata'
    ) {
      this.layer = set(
        this._payoutLens,
        value * this.occurrenceLimit,
        this.layer
      )
    }
  }

  private _cedingCommissionLens = this._physicalLens('fees', 0)
  get cedingCommission(): number {
    const fee: Fee | undefined = view(this._cedingCommissionLens, this.layer)
    if (fee && fee.name !== 'flat_cede') {
      fee.name = 'flat_cede'
      this.layer = set(this._cedingCommissionLens, fee, this.layer)
    }
    if (this.cedingCommissionChecked && this.technicalPremium) {
      return this.technicalPremium
    }
    return fee ? fee.rate : 0
  }
  set cedingCommission(value: number) {
    let fee: Fee | undefined = view(this._cedingCommissionLens, this.layer)
    if (!fee) {
      fee = this.createProportionalExpense('flat_cede')
    }
    value = value > 1 ? 1 : value
    fee.rate = value
    if (!fee.payout_date) {
      fee.payout_date = '2035-12-31T19:00:00'
    }
    this.layer = set(this._cedingCommissionLens, fee, this.layer)
    this.updateRExpAndRExpRev()
  }

  private _reinsurerExpenseProvisionRExp = this._physicalLens('fees', 1)
  private _reinsurerExpenseProvisionRExpRev = this._physicalLens('fees', 2)
  private _reinsurerExpenseProvision = this._physicalLens(
    'meta_data',
    'reinsurerExpenseProvision'
  )
  get reinsurerExpenseProvision(): number {
    return view(this._reinsurerExpenseProvision, this.layer) || 0
  }

  set reinsurerExpenseProvision(value: number) {
    this.layer = set(this._reinsurerExpenseProvision, value, this.layer)
    this.updateRExpAndRExpRev()
  }

  private updateRExpAndRExpRev() {
    let rexpRate = this.reinsurerExpenseProvision * (1 - this.cedingCommission)
    let rexpRevRate =
      -this.reinsurerExpenseProvision * (1 - this.cedingCommission)

    let fee: Fee | undefined = view(
      this._reinsurerExpenseProvisionRExp,
      this.layer
    )
    if (!fee) {
      fee = this.createProportionalExpense('RExp')
    }
    rexpRate = rexpRate > 1 ? 1 : rexpRate
    fee.rate = rexpRate
    if (!fee.payout_date) {
      fee.payout_date = '2035-12-31T19:00:00'
    }
    this.layer = set(this._reinsurerExpenseProvisionRExp, fee, this.layer)

    fee = view(this._reinsurerExpenseProvisionRExpRev, this.layer)
    if (!fee) {
      fee = this.createProportionalExpense('RExpRev')
    }
    rexpRevRate = rexpRevRate > 1 ? 1 : rexpRevRate
    fee.rate = rexpRevRate
    if (!fee.payout_date) {
      fee.payout_date = '2035-12-31T19:00:00'
    }
    this.layer = set(this._reinsurerExpenseProvisionRExpRev, fee, this.layer)
  }

  private _profitCommissionLens = this._physicalLens('fees', 3)
  get profitCommission(): number {
    const fee: Fee | undefined = view(this._profitCommissionLens, this.layer)
    return fee ? fee.rate : 0
  }
  set profitCommission(value: number) {
    let fee: Fee | undefined = view(this._profitCommissionLens, this.layer)
    if (!fee) {
      fee = this.createProfitCommission()
    }
    value = value > 1 ? 1 : value
    fee.rate = value
    if (!fee.payout_date) {
      fee.payout_date = '2035-12-31T19:00:00'
    }
    this.layer = set(this._profitCommissionLens, fee, this.layer)
    if (!view(this._cedingCommissionLens, this.layer)) {
      this.layer = set(
        this._cedingCommissionLens,
        this.createProportionalExpense('flat_cede'),
        this.layer
      )
    }
    if (!view(this._reinsurerExpenseProvisionRExp, this.layer)) {
      this.layer = set(
        this._reinsurerExpenseProvisionRExp,
        this.createProportionalExpense('RExp'),
        this.layer
      )
    }
    if (!view(this._reinsurerExpenseProvisionRExpRev, this.layer)) {
      this.layer = set(
        this._reinsurerExpenseProvisionRExpRev,
        this.createProportionalExpense('RExpRev'),
        this.layer
      )
    }
  }

  private _rolPercentageLens = this._physicalLens('meta_data', 'rol')
  get rolPercentage(): number {
    const rolPer: number | undefined = view(this._rolPercentageLens, this.layer)
    return rolPer ? rolPer : 0
  }
  set rolPercentage(value: number) {
    this.layer = set(this._rolPercentageLens, value, this.layer)
    if (value && !this.isAHL()) {
      let limit = this._getOccOrAggLimit()
      // If contract occ. Limit is unlimited use first section occ. limit.
      if (
        this.layerType === 'multisection' &&
        limit === analyzereConstants.unlimitedValue
      ) {
        const mainLayer = this.layers?.find(
          ({ layer }) =>
            layer.meta_data.sage_layer_subtype === 'main-layer' &&
            layer.meta_data.visible_layer_id === this.layer.id
        )
        const firstSectionId = mainLayer?.layer.layerRefs[0]
        const sectionLayer = this.layers?.find(
          ({ layer }) => layer.id === firstSectionId
        )
        limit = sectionLayer?.layer.physicalLayer.limit.value ?? limit
      }
      this.layer = set(this._premiumLens, value * limit, this.layer)
    }
  }

  private _rolTypeLens = this._physicalLens('meta_data', 'rol_type')
  get rolType(): string {
    return view(this._rolTypeLens, this.layer) === 'agg'
      ? 'Rate-on-Line, Aggregate'
      : 'Rate-on-Line, Occurrence'
  }

  private _premiumLens = this._physicalLens('premium', 'value')
  get premium(): number {
    if (this.subjectPremiumChecked) {
      const value = this._sumOfLossSetPremiums()
      if (value > 0) {
        this.premium = value
      }
      return value
    } else if (this.technicalPremiumChecked && this.technicalPremium) {
      return this.technicalPremium
    } else {
      return view(this._premiumLens, this.layer)
    }
  }
  set premium(value: number) {
    this.layer = set(this._premiumLens, value, this.layer)
    const limit = this._getOccOrAggLimit()
    this.layer = set(
      this._rolPercentageLens,
      limit < analyzereConstants.unlimitedValue ? value / limit : 0,
      this.layer
    )

    // Set ROL, % of Subject only if sum of Loss Set Premiums is > 0
    const sumLossSetPremiums = this._sumOfLossSetPremiums()
    if (sumLossSetPremiums > 0) {
      this.layer = set(
        this._rateOnLineSubjectLens,
        value / sumLossSetPremiums,
        this.layer
      )
    } else {
      this.layer = set(this._rateOnLineSubjectLens, 0, this.layer)
    }
    // Set PMPM only if sum of Loss Set Members is > 0
    if (value && this.isAHL()) {
      const sumLossSetMembers = this._sumOfLossSetMembers()
      if (sumLossSetMembers > 0) {
        this.layer = set(
          this._pmpmLens,
          value / (sumLossSetMembers * 12),
          this.layer
        )
      } else {
        this.layer = set(this._pmpmLens, 0, this.layer)
      }
    }
  }

  private _technicalPremiumCheckedLens = this._physicalLens(
    'meta_data',
    'technicalPremiumChecked'
  )
  get technicalPremiumChecked(): boolean {
    return !!view(this._technicalPremiumCheckedLens, this.layer)
  }

  set technicalPremiumChecked(value: boolean) {
    this.layer = set(this._technicalPremiumCheckedLens, value, this.layer)
    if (value) {
      this.layer = set(this._premiumLens, this.technicalPremium, this.layer)
    }
  }

  private _cedingCommissionCheckedLens = this._physicalLens(
    'meta_data',
    'cedingCommissionChecked'
  )
  get cedingCommissionChecked(): boolean {
    return !!view(this._cedingCommissionCheckedLens, this.layer)
  }

  set cedingCommissionChecked(value: boolean) {
    this.layer = set(this._cedingCommissionCheckedLens, value, this.layer)
    if (value !== undefined && this.technicalPremium) {
      const fee = this.createProportionalExpense('flat_cede')
      fee.rate = this.technicalPremium > 1 ? 1 : this.technicalPremium
      this.layer = set(this._cedingCommissionLens, fee, this.layer)
    }
  }

  private _subjectPremiumCheckedLens = this._physicalLens(
    'meta_data',
    'subjectPremiumChecked'
  )
  get subjectPremiumChecked(): boolean {
    return !!view(this._subjectPremiumCheckedLens, this.layer)
  }

  set subjectPremiumChecked(value: boolean) {
    this.layer = set(this._subjectPremiumCheckedLens, value, this.layer)
    this.layer = set(this._premiumLens, this.premium, this.layer)
  }

  private _subjectPremiumQSLens = this._physicalLens(
    'meta_data',
    'subjectPremiumQS'
  )
  set subjectPremiumQS(value: number) {
    this.layer = set(this._subjectPremiumQSLens, value, this.layer)
  }

  private _payoutLens = this._physicalLens('payout', 'value')
  get payout(): number {
    return view(this._payoutLens, this.layer)
  }
  set payout(value: number) {
    this.layer = set(this._payoutLens, value, this.layer)
    if (this.layer && this.layer.meta_data.sage_layer_type === 'cat_ilw_bin') {
      this.layer = set(this._occLimitLens, value, this.layer)
    }
    if (
      this.layer &&
      this.layer.meta_data.sage_layer_type === 'cat_ilw_pro_rata'
    ) {
      this.layer = set(
        this._cessionPercentageLens,
        -1 * (value / this.occurrenceLimit),
        this.layer
      )
    }
  }

  private _triggerLens = this._physicalLens('trigger', 'value')
  get trigger(): number {
    return view(this._triggerLens, this.layer)
  }
  set trigger(value: number) {
    this.layer = set(this._triggerLens, value, this.layer)
    if (
      (this.layer && this.layer.meta_data.sage_layer_type === 'cat_ilw_bin') ||
      (this.layer &&
        this.layer.meta_data.sage_layer_type === 'cat_ilw_pro_rata')
    ) {
      this.layer = set(this._occAttachLens, value, this.layer)
    }
  }

  private _nthLens = this._physicalLens('nth')
  get nth(): number {
    return view(this._nthLens, this.layer)
  }
  set nth(value: number) {
    this.layer = set(this._nthLens, value, this.layer)
  }

  private _inceptionDateLens = this._physicalLens('inception_date')
  get inception_date(): string {
    return view(this._inceptionDateLens, this.layer)
  }
  set inception_date(value: string) {
    this.layer = set(this._inceptionDateLens, value, this.layer)
  }

  private _expiryDateLens = this._physicalLens('expiry_date')
  get expiry_date(): string {
    return view(this._expiryDateLens, this.layer)
  }
  set expiry_date(value: string) {
    this.layer = set(this._expiryDateLens, value, this.layer)
  }

  private _techPremiumLens = this._physicalLens('meta_data', 'technicalPremium')
  private _defaultReinsurerLens = this._physicalLens(
    'meta_data',
    'pricingcurve_is_default'
  )
  get technicalPremium(): number | null {
    if (!this.savedPricingCurves || !this.savedPricingCurves.length) {
      return null
    }
    // Use the curves on the layer if they exist, else use the program defaults
    if (this.layer.physicalLayer.meta_data.pricingCurves) {
      const curveEntries = getPricingCurvesForLayer(
        undefined,
        this.layer.physicalLayer.meta_data.pricingCurves
      )
      return this.pricingCurvesPremium(curveEntries)
    }
    if (this.programDefaultPricingCurves) {
      return this.pricingCurvesPremium(this.programDefaultPricingCurves)
    }
    return view(this._techPremiumLens, this.layer) || null
  }
  set technicalPremium(value: number | null) {
    if (view(this._techPremiumLens, this.layer) !== value) {
      this.layer = set(this._defaultReinsurerLens, false, this.layer)
    }
    this.layer = set(this._techPremiumLens, value, this.layer)
    if (this.technicalPremiumChecked) {
      this.layer = set(this._premiumLens, value, this.layer)
    }
  }

  private _rateOnLineSubjectLens = this._physicalLens(
    'meta_data',
    'rate_on_subject'
  )
  get rateOnLineSubject(): number | undefined {
    // Get from layer meta_data if possible
    const rolSubject: number | undefined = view(
      this._rateOnLineSubjectLens,
      this.layer
    )
    if (rolSubject) {
      return rolSubject
    } else {
      // Otherwise calculate using loss sets and premium
      const sumLossSetPremiums = this._sumOfLossSetPremiums()
      return sumLossSetPremiums ? this.premium / sumLossSetPremiums : 0
    }
  }
  set rateOnLineSubject(value: number | undefined) {
    this.layer = set(this._rateOnLineSubjectLens, value, this.layer)
    if (value && !this.isAHL()) {
      this.layer = set(
        this._premiumLens,
        value * this._sumOfLossSetPremiums(),
        this.layer
      )
    }
  }

  private _pmpmLens = this._physicalLens('meta_data', 'pmpm')

  get pmpm(): number | undefined {
    // Get from layer meta_data if possible
    const pmpmValue: number | undefined = view(this._pmpmLens, this.layer)

    if (pmpmValue) {
      return pmpmValue
    } else {
      // Otherwise calculate using loss sets and premium
      const sumLossSetMembers = this._sumOfLossSetMembers()
      return sumLossSetMembers ? this.premium / (sumLossSetMembers * 12) : 0
    }
  }

  set pmpm(value: number | undefined) {
    this.layer = set(this._pmpmLens, value, this.layer)
    if (value && this.isAHL()) {
      this.layer = set(
        this._premiumLens,
        value * this._sumOfLossSetMembers() * 12,
        this.layer
      )
    }
  }

  private _minRatePmpmLens = this._physicalLens('meta_data', 'min_rate_pmpm')
  get minRatePmpm(): number | undefined {
    return view(this._minRatePmpmLens, this.layer)
  }

  set minRatePmpm(value: number | undefined) {
    this.layer = set(this._minRatePmpmLens, value, this.layer)
  }

  private _maxRatePmpmLens = this._physicalLens('meta_data', 'max_rate_pmpm')
  get maxRatePmpm(): number | undefined {
    return view(this._maxRatePmpmLens, this.layer)
  }

  set maxRatePmpm(value: number | undefined) {
    this.layer = set(this._maxRatePmpmLens, value, this.layer)
  }

  // TODO Swing-rated Layer
  private _minRateSubjectLens = this._physicalLens(
    'meta_data',
    'min_rate_subject'
  )
  get minRateSubject(): number {
    return view(this._minRateSubjectLens, this.layer)
  }
  set minRateSubject(value: number) {
    this.layer = set(this._minRateSubjectLens, value, this.layer)
  }

  private _maxRateSubjectLens = this._physicalLens(
    'meta_data',
    'max_rate_subject'
  )
  get maxRateSubject(): number | undefined {
    return view(this._maxRateSubjectLens, this.layer)
  }
  set maxRateSubject(value: number | undefined) {
    this.layer = set(this._maxRateSubjectLens, value, this.layer)
  }

  private _swingRateLens = this._physicalLens('meta_data', 'swing_rate')
  get swingRate(): number {
    return view(this._swingRateLens, this.layer)
  }
  set swingRate(value: number) {
    this.layer = set(this._swingRateLens, value, this.layer)
  }

  private _swingBasisLens = this._physicalLens('meta_data', 'swing_basis')
  get swingBasis(): string {
    return view(this._swingBasisLens, this.layer)
  }
  set swingBasis(value: string) {
    this.layer = set(this._swingBasisLens, value, this.layer)
  }

  private _cascadeLens = this._physicalLens('meta_data', 'cascadeLowerLayerID')
  get cascadeLowerLayerID(): string | undefined {
    return view(this._cascadeLens, this.layer)
  }
  set cascadeLowerLayerID(value: string | undefined) {
    this.layer = set(this._cascadeLens, value, this.layer)
  }

  private _riskAttachLens = this._physicalLens('riskAttachment', 'value')
  get riskAttachment(): number {
    return view(this._riskAttachLens, this.layer)
  }
  set riskAttachment(value: number) {
    this.layer = set(this._riskAttachLens, value, this.layer)
  }

  private _riskLimitLens = this._physicalLens('riskLimit', 'value')
  get riskLimit(): number {
    return view(this._riskLimitLens, this.layer)
  }
  set riskLimit(value: number) {
    this.layer = set(this._riskLimitLens, value, this.layer)
  }
  private _quoteTopOccurrenceLimitLens = this._quoteReinsurerLens(
    'quoteTopOccurrenceLimit',
    'value'
  )
  get quoteTopOccurrenceLimit(): number {
    return view(this._quoteTopOccurrenceLimitLens, this.quoteReinsurer)
  }
  set quoteTopOccurrenceLimit(value: number) {
    this.quoteReinsurer = set(
      this._quoteTopOccurrenceLimitLens,
      value,
      this.quoteReinsurer
    )
  }

  get isQuoteTopOccurrenceUnlimited(): boolean {
    // tslint:disable-next-line: no-non-null-assertion
    return this.quoteReinsurer?.quoteFields!.isLimitUnlimited
      ? this.quoteReinsurer?.quoteFields.isLimitUnlimited
      : // tslint:disable-next-line: no-non-null-assertion
        this.quoteReinsurer?.quoteFields!.isLimitUnlimited === false
        ? false
        : this.quoteTopOccurrenceLimit >= analyzereConstants.unlimitedValue
  }
  set isQuoteTopOccurrenceUnlimited(value: boolean) {
    if (value) {
      this._prevQuoteTopOccurrenceLimit = this.quoteTopOccurrenceLimit
      this.quoteTopOccurrenceLimit = analyzereConstants.unlimitedValue
      return
    } else {
      this.quoteTopOccurrenceLimit = this._prevQuoteTopOccurrenceLimit
    }
  }

  private _quoteDropOccurrenceLimitLens = this._quoteReinsurerLens(
    'quoteDropOccurrenceLimit',
    'value'
  )
  get quoteDropOccurrenceLimit(): number {
    return view(this._quoteDropOccurrenceLimitLens, this.quoteReinsurer)
  }
  set quoteDropOccurrenceLimit(value: number) {
    this.quoteReinsurer = set(
      this._quoteDropOccurrenceLimitLens,
      value,
      this.quoteReinsurer
    )
  }

  get isQuoteDropOccurrenceUnlimited(): boolean {
    // tslint:disable-next-line: no-non-null-assertion
    return this.quoteReinsurer?.quoteFields!.isLimitUnlimited
      ? this.quoteReinsurer?.quoteFields.isLimitUnlimited
      : // tslint:disable-next-line: no-non-null-assertion
        this.quoteReinsurer?.quoteFields!.isLimitUnlimited === false
        ? false
        : this.quoteDropOccurrenceLimit >= analyzereConstants.unlimitedValue
  }
  set isQuoteDropOccurrenceUnlimited(value: boolean) {
    if (value) {
      this._prevQuoteDropOccurrenceLimit = this.quoteDropOccurrenceLimit
      this.quoteDropOccurrenceLimit = analyzereConstants.unlimitedValue
      return
    } else {
      this.quoteDropOccurrenceLimit = this._prevQuoteDropOccurrenceLimit
    }
  }

  private _quoteOccurrenceLimitLens = this._quoteReinsurerLens(
    'quoteOccurrenceLimit',
    'value'
  )
  get quoteOccurrenceLimit(): number {
    return view(this._quoteOccurrenceLimitLens, this.quoteReinsurer)
  }
  set quoteOccurrenceLimit(value: number) {
    this.quoteReinsurer = set(
      this._quoteOccurrenceLimitLens,
      value,
      this.quoteReinsurer
    )
    const quotePremium = this.quoteRolPercentage * value
    this.quoteReinsurer = set(
      this._quotePremiumLens,
      quotePremium,
      this.quoteReinsurer
    )
    this.updateMinAndDepositPremiumValues(quotePremium)
  }

  get isQuoteOccurrenceUnlimited(): boolean {
    // tslint:disable-next-line: no-non-null-assertion
    return this.quoteReinsurer?.quoteFields!.isLimitUnlimited
      ? this.quoteReinsurer?.quoteFields.isLimitUnlimited
      : // tslint:disable-next-line: no-non-null-assertion
        this.quoteReinsurer?.quoteFields!.isLimitUnlimited === false
        ? false
        : this.quoteOccurrenceLimit >= analyzereConstants.unlimitedValue
  }
  set isQuoteOccurrenceUnlimited(value: boolean) {
    if (value) {
      this._prevQuoteOccurrenceLimit = this.quoteOccurrenceLimit
      this.quoteOccurrenceLimit = analyzereConstants.unlimitedValue
      return
    } else {
      this.quoteOccurrenceLimit = this._prevQuoteOccurrenceLimit
    }
  }

  get isQuoteAggregateUnlimited(): boolean {
    // tslint:disable-next-line: no-non-null-assertion
    return this.quoteReinsurer?.quoteFields!.isAggregateUnlimited
      ? this.quoteReinsurer?.quoteFields.isAggregateUnlimited
      : // tslint:disable-next-line: no-non-null-assertion
        this.quoteReinsurer?.quoteFields!.isAggregateUnlimited === false
        ? false
        : this.quoteAggregateLimit >= analyzereConstants.unlimitedValue
  }
  set isQuoteAggregateUnlimited(value: boolean) {
    if (value) {
      this._prevQuoteAggregateLimit = this.quoteAggregateLimit
      this.quoteAggregateLimit = analyzereConstants.unlimitedValue
      return
    } else {
      this.quoteAggregateLimit = this._prevQuoteAggregateLimit
    }
  }

  get isQuoteAggregateUnlimitedTop(): boolean {
    // tslint:disable-next-line: no-non-null-assertion
    return this.quoteReinsurer?.quoteFields!.isAggregateUnlimited
      ? this.quoteReinsurer?.quoteFields.isAggregateUnlimited
      : // tslint:disable-next-line: no-non-null-assertion
        this.quoteReinsurer?.quoteFields!.isAggregateUnlimited === false
        ? false
        : this.quoteAggregateLimitTop >= analyzereConstants.unlimitedValue
  }
  set isQuoteAggregateUnlimitedTop(value: boolean) {
    if (value) {
      this._prevQuoteAggregateLimitTop = this.quoteAggregateLimitTop
      this.quoteAggregateLimitTop = analyzereConstants.unlimitedValue
      return
    } else {
      this.quoteAggregateLimitTop = this._prevQuoteAggregateLimitTop
    }
  }

  private _quoteAggregateLimitTopLens = this._quoteReinsurerLens(
    'quoteAggregateLimitTop',
    'value'
  )
  get quoteAggregateLimitTop(): number {
    return view(this._quoteAggregateLimitTopLens, this.quoteReinsurer)
  }
  set quoteAggregateLimitTop(value: number) {
    this.quoteReinsurer = set(
      this._quoteAggregateLimitTopLens,
      value,
      this.quoteReinsurer
    )
    if (view(this._rolTypeLens, this.layer) === 'agg') {
      this.quoteReinsurer = set(
        this._quoteRolPercentageLens,
        this.quotePremium / value,
        this.quoteReinsurer
      )
    }
  }

  get isQuoteAggregateUnlimitedDrop(): boolean {
    // tslint:disable-next-line: no-non-null-assertion
    return this.quoteReinsurer?.quoteFields!.isAggregateUnlimited
      ? this.quoteReinsurer?.quoteFields.isAggregateUnlimited
      : // tslint:disable-next-line: no-non-null-assertion
        this.quoteReinsurer?.quoteFields!.isAggregateUnlimited === false
        ? false
        : this.quoteAggregateLimitDrop >= analyzereConstants.unlimitedValue
  }
  set isQuoteAggregateUnlimitedDrop(value: boolean) {
    if (value) {
      this._prevQuoteAggregateLimitDrop = this.quoteAggregateLimitDrop
      this.quoteAggregateLimitDrop = analyzereConstants.unlimitedValue
      return
    } else {
      this.quoteAggregateLimitDrop = this._prevQuoteAggregateLimitDrop
    }
  }

  private _quoteAggregateLimitDropLens = this._quoteReinsurerLens(
    'quoteAggregateLimitDrop',
    'value'
  )
  get quoteAggregateLimitDrop(): number {
    return view(this._quoteAggregateLimitDropLens, this.quoteReinsurer)
  }
  set quoteAggregateLimitDrop(value: number) {
    this.quoteReinsurer = set(
      this._quoteAggregateLimitDropLens,
      value,
      this.quoteReinsurer
    )
    if (view(this._rolTypeLens, this.layer) === 'agg') {
      this.quoteReinsurer = set(
        this._quoteRolPercentageLens,
        this.quotePremium / value,
        this.quoteReinsurer
      )
    }
  }

  private _quoteAttachLens = this._quoteReinsurerLens(
    'quoteOccurrenceAttachment',
    'value'
  )
  get quoteOccurrenceAttachment(): number {
    return view(this._quoteAttachLens, this.quoteReinsurer)
  }
  set quoteOccurrenceAttachment(value: number) {
    this.quoteReinsurer = set(this._quoteAttachLens, value, this.quoteReinsurer)
  }

  private _quoteAttachmentTopAttachLens = this._quoteReinsurerLens(
    'quoteTopOccurrenceAttachment',
    'value'
  )
  get quoteTopOccurrenceAttachment(): number {
    return view(this._quoteAttachmentTopAttachLens, this.quoteReinsurer)
  }
  set quoteTopOccurrenceAttachment(value: number) {
    this.quoteReinsurer = set(
      this._quoteAttachmentTopAttachLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteAttachmentDropAttachLens = this._quoteReinsurerLens(
    'quoteDropOccurrenceAttachment',
    'value'
  )
  get quoteDropOccurrenceAttachment(): number {
    return view(this._quoteAttachmentDropAttachLens, this.quoteReinsurer)
  }
  set quoteDropOccurrenceAttachment(value: number) {
    this.quoteReinsurer = set(
      this._quoteAttachmentDropAttachLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteRiskLimitLens = this._quoteReinsurerLens(
    'quoteRiskLimit',
    'value'
  )
  get quoteRiskLimit(): number {
    return view(this._quoteRiskLimitLens, this.quoteReinsurer)
  }
  set quoteRiskLimit(value: number) {
    this.quoteReinsurer = set(
      this._quoteRiskLimitLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteRiskAttachLens = this._quoteReinsurerLens(
    'quoteRiskAttachment',
    'value'
  )
  get quoteRiskAttachment(): number {
    return view(this._quoteRiskAttachLens, this.quoteReinsurer)
  }
  set quoteRiskAttachment(value: number) {
    this.quoteReinsurer = set(
      this._quoteRiskAttachLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteFranchiseDeductibleLens = this._quoteReinsurerLens(
    'quoteFranchiseDeductible',
    'value'
  )
  get quoteFranchiseDeductible(): number {
    if (isLayerAgg(this.layer) && this.layers) {
      const feederLayer = this.layers.filter(layer =>
        isLayerAggFeeder(layer.layer)
      )
      return feederLayer[0].layer.physicalLayer.franchise.value
    } else {
      return view(this._quoteFranchiseDeductibleLens, this.quoteReinsurer)
    }
  }
  set quoteFranchiseDeductible(value: number) {
    this.quoteReinsurer = set(
      this._quoteFranchiseDeductibleLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteAggregateLimitLens = this._quoteReinsurerLens(
    'quoteAggregateLimit',
    'value'
  )
  get quoteAggregateLimit(): number {
    return view(this._quoteAggregateLimitLens, this.quoteReinsurer)
  }
  set quoteAggregateLimit(value: number) {
    this.quoteReinsurer = set(
      this._quoteAggregateLimitLens,
      value,
      this.quoteReinsurer
    )
    if (view(this._rolTypeLens, this.layer) === 'agg') {
      this.quoteReinsurer = set(
        this._quoteRolPercentageLens,
        this.quotePremium / value,
        this.quoteReinsurer
      )
    }
  }

  private _quoteAggregateAttachLens = this._quoteReinsurerLens(
    'quoteAggregateAttachment',
    'value'
  )
  get quoteAggregateAttachment(): number {
    return view(this._quoteAggregateAttachLens, this.quoteReinsurer)
  }
  set quoteAggregateAttachment(value: number) {
    this.quoteReinsurer = set(
      this._quoteAggregateAttachLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteReinstatementsLens = this._quoteReinsurerLens(
    'quoteReinstatements'
  )
  get quoteReinstatements(): Reinstatements[] {
    return view(this._quoteReinstatementsLens, this.quoteReinsurer)
  }
  set quoteReinstatements(value: Reinstatements[]) {
    this.quoteReinsurer = set(
      this._quoteReinstatementsLens,
      value,
      this.quoteReinsurer
    )
    if (value.length > 0) {
      const reLen = value.map(v => v.premium).filter(r => r !== undefined)
      this.quoteReinsurer = set(
        this._quoteAggregateLimitLens,
        (reLen.length + 1) * this.quoteOccurrenceLimit,
        this.quoteReinsurer
      )
    }
  }

  private _quoteCessionPercentageLens = this._quoteReinsurerLens(
    'quoteCessionPercentage'
  )
  get quoteCessionPercentage(): number {
    const n: number =
      view(this._quoteCessionPercentageLens, this.quoteReinsurer) || 0
    return isLayerAggFeeder(this.layer) ||
      this.quoteReinsurer?.programGroupID !== null
      ? n
      : -n
  }
  set quoteCessionPercentage(value: number) {
    value =
      isLayerAggFeeder(this.layer) ||
      this.quoteReinsurer?.programGroupID !== null
        ? value
        : -value
    this.quoteReinsurer = set(
      this._quoteCessionPercentageLens,
      value,
      this.quoteReinsurer
    )
  }

  get orderedLimit(): number | string {
    if (AggLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      if (this.layer.meta_data.sage_layer_subtype === 'feeder') {
        return ''
      } else {
        return this.aggregateLimit * this.cessionPercentage
      }
    }
    if (OccLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      // Risk XOL has its own formula
      if (
        this.layer.meta_data.sage_layer_type === 'noncat_risk' &&
        this.layers
      ) {
        const riskVisible = this.layers?.find(
          l => l.layer.id === this.layer.meta_data.riskVisibleLayerID
        )
        if (riskVisible && riskVisible.layer.physicalLayer.riskLimit) {
          return (
            riskVisible.layer.physicalLayer.riskLimit.value *
            this.cessionPercentage
          )
        }
        return this.riskLimit * this.cessionPercentage
      } else {
        return this.occurrenceLimit * this.cessionPercentage
      }
    }
    // if layer is quote share, no ordered limit
    return ''
  }

  get reinstatementsSummary(): string {
    if (this.aggregateLimit === this.occurrenceLimit) {
      return 'Single Shot'
    }
    // transform reinstatemant object where all are individual to group by premiums
    if (this.reinstatements) {
      const groupByPremiums = (arr: any[], key: string) => {
        const initialValue = {}
        return arr.reduce((acc, cval) => {
          const myAttribute = cval[key]
          acc[myAttribute] = [...(acc[myAttribute] || []), cval]
          return acc
        }, initialValue)
      }

      const reinstatementsByPremium = groupByPremiums(
        this.reinstatements,
        'premium'
      )

      // loop through object, count no of each premium and its value, and build up text string of all
      let txt = ''
      let counter = 0
      // tslint:disable-next-line:forin
      for (const r in reinstatementsByPremium) {
        counter++
        const num = reinstatementsByPremium[r].length
        const percent = Number(r) * 100
        // need reinstatements' txt seperated by commas unless only 1 or the last one
        const rein =
          counter !== Object.keys(reinstatementsByPremium).length
            ? `${num}@${percent}%, `
            : `${num}@${percent}%`
        txt = txt + rein
      }
      return txt
    }
    return ''
  }

  // expectedLoss = purePremium  no formula, assigned in _updateMetricsValues()

  private getExpectedLossPercentLimit(purePremium: number): number | string {
    if (OccLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      // Risk XOL has its own formula
      if (
        this.layer.meta_data.sage_layer_type === 'noncat_risk' &&
        this.layers
      ) {
        const riskVisible = this.layers?.find(
          l => l.layer.id === this.layer.meta_data.riskVisibleLayerID
        )
        if (riskVisible && riskVisible.layer.physicalLayer.riskLimit) {
          return purePremium / riskVisible.layer.physicalLayer.riskLimit.value
        }
        return purePremium / this.riskLimit
      } else {
        return purePremium / this.occurrenceLimit
      }
    }
    if (AggLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      return purePremium / this.aggregateLimit
    }
    // if layer is quote share, blank
    return ''
  }

  private getContractROL(depositPremium: number): number | string {
    if (OccLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      // Risk XOL has its own formula
      if (
        this.layer.meta_data.sage_layer_type === 'noncat_risk' &&
        this.layers
      ) {
        const riskVisible = this.layers?.find(
          l => l.layer.id === this.layer.meta_data.riskVisibleLayerID
        )
        if (riskVisible && riskVisible.layer.physicalLayer.riskLimit) {
          return (
            depositPremium / riskVisible.layer.physicalLayer.riskLimit.value
          )
        }
        return depositPremium / this.riskLimit
      } else {
        return depositPremium / this.occurrenceLimit
      }
    }
    if (AggLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      return depositPremium / this.aggregateLimit
    }
    // if layer is quote share, blank
    return ''
  }

  private getTotalROL(expectedCededPremium: number): number | string {
    if (OccLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      // Risk XOL has its own formula
      if (
        this.layer.meta_data.sage_layer_type === 'noncat_risk' &&
        this.layers
      ) {
        const riskVisible = this.layers?.find(
          l => l.layer.id === this.layer.meta_data.riskVisibleLayerID
        )
        if (riskVisible && riskVisible.layer.physicalLayer.riskLimit) {
          return (
            expectedCededPremium /
            riskVisible.layer.physicalLayer.riskLimit.value
          )
        }
        return expectedCededPremium / this.riskLimit
      } else {
        return expectedCededPremium / this.occurrenceLimit
      }
    }
    if (AggLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      return expectedCededPremium / this.aggregateLimit
    }
    // if layer is quote share, blank
    return ''
  }

  private getPremiumMultiple(
    expectedCededPremium: number,
    purePremium: number
  ): number | undefined {
    // expectedCededPremiumDisp/expectedCededLossDisp non OCC layers, but both vars are equal to the others
    return expectedCededPremium / purePremium
  }

  private getProbabilityExhaust(
    exitProbability: number | undefined,
    exitAggProbability: number | undefined
  ): number | undefined {
    if (AggLayers.includes(this.layer.meta_data.sage_layer_type as string)) {
      return exitAggProbability
    }
    return exitProbability
  }

  private _quoteCedingCommissionLens = this._quoteReinsurerLens(
    'quoteCedingCommission'
  )
  get quoteCedingCommission(): number {
    return view(this._quoteCedingCommissionLens, this.quoteReinsurer) || 0
  }
  set quoteCedingCommission(value: number) {
    this.quoteReinsurer = set(
      this._quoteCedingCommissionLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteReinsurerExpenseProvisionRExp = this._quoteReinsurerLens(
    'quoteReinsurerExpenseProvision'
  )
  get quoteReinsurerExpenseProvision(): number {
    return (
      view(this._quoteReinsurerExpenseProvisionRExp, this.quoteReinsurer) || 0
    )
  }

  set quoteReinsurerExpenseProvision(value: number) {
    this.quoteReinsurer = set(
      this._quoteReinsurerExpenseProvisionRExp,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteProfitCommissionLens = this._quoteReinsurerLens(
    'quoteProfitCommission'
  )
  get quoteProfitCommission(): number {
    return view(this._quoteProfitCommissionLens, this.quoteReinsurer) || 0
  }
  set quoteProfitCommission(value: number) {
    this.quoteReinsurer = set(
      this._quoteProfitCommissionLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteRolPercentageLens =
    this._quoteReinsurerLens('quoteRolPercentage')
  get quoteRolPercentage(): number {
    const rolPer: number | undefined = view(
      this._quoteRolPercentageLens,
      this.quoteReinsurer
    )
    return rolPer ? rolPer : 0
  }
  set quoteRolPercentage(value: number) {
    value = value > 1 ? 1 : value
    this.quoteReinsurer = set(
      this._quoteRolPercentageLens,
      value,
      this.quoteReinsurer
    )
    const limit = this._getQuoteOccOrAggLimit()
    if (value && !this.isAHL()) {
      const quotePremium = value * limit
      this.quoteReinsurer = set(
        this._quotePremiumLens,
        quotePremium,
        this.quoteReinsurer
      )
      const quoteRateOnLineSubject =
        this.subjectPremium > 0 ? quotePremium / this.subjectPremium : 0
      this.quoteReinsurer = set(
        this._quoteRateOnLineSubjectLens,
        quoteRateOnLineSubject,
        this.quoteReinsurer
      )
      this.updateMinAndDepositPremiumValues(quotePremium)
    }
  }

  private _quoteRateOnLineSubjectLens = this._quoteReinsurerLens(
    'quoteRateOnLineSubject'
  )
  get quoteRateOnLineSubject(): number | undefined {
    // Get from layer meta_data if possible
    const rolSubject: number | undefined = view(
      this._quoteRateOnLineSubjectLens,
      this.quoteReinsurer
    )
    if (rolSubject) {
      return rolSubject
    } else {
      // Otherwise calculate using loss sets and premium
      const sumLossSetPremiums = this._sumOfLossSetPremiums()
      return sumLossSetPremiums ? this.quotePremium / sumLossSetPremiums : 0
    }
  }
  set quoteRateOnLineSubject(value: number | undefined) {
    this.quoteReinsurer = set(
      this._quoteRateOnLineSubjectLens,
      value,
      this.quoteReinsurer
    )
    if (value && !this.isAHL()) {
      const quotePremium = value * this._sumOfLossOrSubPrem
      this.quoteReinsurer = set(
        this._quotePremiumLens,
        quotePremium,
        this.quoteReinsurer
      )
      const quoteRolPercentage =
        this._getQuoteOccOrAggLimit() > 0
          ? quotePremium / this._getQuoteOccOrAggLimit()
          : 0
      this.quoteReinsurer = set(
        this._quoteRolPercentageLens,
        quoteRolPercentage,
        this.quoteReinsurer
      )
      this.updateMinAndDepositPremiumValues(quotePremium)
    }
  }

  private _quotePmpmLens = this._quoteReinsurerLens('quotePmpm')
  get quotePmpm(): number | undefined {
    // Get from layer meta_data if possible
    const pmpmVal: number | undefined = view(
      this._quotePmpmLens,
      this.quoteReinsurer
    )
    if (pmpmVal) {
      return pmpmVal
    } else {
      // Otherwise calculate using loss sets and members
      const sumLossSetMembers = this._sumOfLossSetMembers()
      return sumLossSetMembers ? this.premium / (sumLossSetMembers * 12) : 0
    }
  }
  set quotePmpm(value: number | undefined) {
    this.quoteReinsurer = set(this._quotePmpmLens, value, this.quoteReinsurer)
    if (value && this.isAHL()) {
      const quotePremium = value * this._sumOfLossSetMembers() * 12
      this.quoteReinsurer = set(
        this._quotePremiumLens,
        quotePremium,
        this.quoteReinsurer
      )
      this.updateMinAndDepositPremiumValues(quotePremium)
    }
  }

  private _quotePremiumLens = this._quoteReinsurerLens('quotePremium', 'value')
  get quotePremium(): number {
    return view(this._quotePremiumLens, this.quoteReinsurer)
  }

  set quotePremium(value: number) {
    const quoteLimit = this._getQuoteOccOrAggLimit()
    const quoteRol = this.quoteRolPercentage
    const sumLoss = this._sumOfLossOrSubPrem
      ? this._sumOfLossOrSubPrem
      : this._sumOfLossSetPremiums()
    const quoteRate = this.quoteRateOnLineSubject
      ? this.quoteRateOnLineSubject
      : 0
    const quotePrem =
      quoteLimit * quoteRol ? quoteLimit * quoteRol : quoteRate * sumLoss

    if (value) {
      this.quoteReinsurer = set(
        this._quotePremiumLens,
        value,
        this.quoteReinsurer
      )
    } else {
      if (this.subjectPremiumChecked) {
        value = sumLoss
        this.quotePremium = value
        this.quoteReinsurer = set(
          this._quotePremiumLens,
          value,
          this.quoteReinsurer
        )
      } else {
        value = quotePrem
        this.quoteReinsurer = set(
          this._quotePremiumLens,
          value,
          this.quoteReinsurer
        )
      }
    }

    // Set PMPM only if sum of Loss Set Members is > 0
    if (value && this.isAHL()) {
      const sumLossSetMembers = this._sumOfLossSetMembers()
      if (sumLossSetMembers > 0) {
        this.quoteReinsurer = set(
          this._quotePremiumLens,
          value * sumLossSetMembers * 12,
          this.quoteReinsurer
        )
      } else {
        this.quoteReinsurer = set(this._quotePmpmLens, 0, this.quoteReinsurer)
      }
    }

    this.quoteReinsurer = set(
      this._quoteRateOnLineSubjectLens,
      value ? value / sumLoss : 0,
      this.quoteReinsurer
    )
    this.quoteReinsurer = set(
      this._quoteRolPercentageLens,
      quoteLimit < analyzereConstants.unlimitedValue ? value / quoteLimit : 0,
      this.quoteReinsurer
    )
    this.updateMinAndDepositPremiumValues(value)
  }

  private updateMinAndDepositPremiumValues(quotePremium: number) {
    const quoteMinPremiumPercentage = this.minimumPremiumPercentage
    const quoteDepositPremiumPercentage = this.depositPremiumPercentage
    this.quoteReinsurer = set(
      this._minimumPremiumLens,
      quotePremium * quoteMinPremiumPercentage,
      this.quoteReinsurer
    )
    this.quoteReinsurer = set(
      this._quoteDepositPremiumLens,
      quotePremium * quoteDepositPremiumPercentage,
      this.quoteReinsurer
    )
  }

  private _quoteCessionsBasedPremiumLens = this._quoteReinsurerLens(
    'quoteCessionsBasedPremium'
  )
  get quoteCessionsBasedPremium(): boolean {
    return view(this._quoteCessionsBasedPremiumLens, this.quoteReinsurer)
  }

  set quoteCessionsBasedPremium(value: boolean) {
    this.quoteReinsurer = set(
      this._quoteCessionsBasedPremiumLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quotePayoutLens = this._quoteReinsurerLens('quotePayout', 'value')
  get quotePayout(): number {
    return view(this._quotePayoutLens, this.quoteReinsurer)
  }

  set quotePayout(value: number) {
    this.quoteReinsurer = set(this._quotePayoutLens, value, this.quoteReinsurer)
  }

  private _quoteTriggerLens = this._quoteReinsurerLens('quoteTrigger', 'value')
  get quoteTrigger(): number {
    return view(this._quoteTriggerLens, this.quoteReinsurer)
  }

  set quoteTrigger(value: number) {
    this.quoteReinsurer = set(
      this._quoteTriggerLens,
      value,
      this.quoteReinsurer
    )
    if (
      this.quoteReinsurer &&
      this.quoteReinsurer.cededLayerType === 'cat_ilw_pro_rata'
    ) {
      this.quoteReinsurer = set(
        this._quoteAttachLens,
        value,
        this.quoteReinsurer
      )
    }
  }

  private _quoteNthLens = this._quoteReinsurerLens('quoteNth')
  get quoteNth(): number {
    return view(this._quoteNthLens, this.quoteReinsurer)
  }

  set quoteNth(value: number) {
    this.quoteReinsurer = set(this._quoteNthLens, value, this.quoteReinsurer)
  }

  private _quoteEffectiveDateLens =
    this._quoteReinsurerLens('quoteEffectiveDate')
  get quoteEffectiveDate(): string {
    return view(this._quoteEffectiveDateLens, this.quoteReinsurer)
  }

  set quoteEffectiveDate(value: string) {
    this.quoteReinsurer = set(
      this._quoteEffectiveDateLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteExpirationDateLens = this._quoteReinsurerLens(
    'quoteExpirationDate'
  )
  get quoteExpirationDate(): string {
    return view(this._quoteExpirationDateLens, this.quoteReinsurer)
  }
  set quoteExpirationDate(value: string) {
    this.quoteReinsurer = set(
      this._quoteExpirationDateLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteExpiryDateLens = this._quoteReinsurerLens('quoteExpiryDate')
  get quoteExpiryDate(): string {
    return view(this._quoteExpiryDateLens, this.quoteReinsurer)
  }
  set quoteExpiryDate(value: string) {
    this.quoteReinsurer = set(
      this._quoteExpiryDateLens,
      value,
      this.quoteReinsurer
    )
  }

  private _underwriterLens = this._quoteReinsurerLens('underwriter')
  get underwriter(): string {
    return view(this._underwriterLens, this.quoteReinsurer) || ''
  }

  set underwriter(value: string) {
    this.quoteReinsurer = set(this._underwriterLens, value, this.quoteReinsurer)
  }

  private _maolLimitLens = this._quoteReinsurerLens('maolLimit', 'value')
  get maolLimit(): number {
    return view(this._maolLimitLens, this.quoteReinsurer)
  }

  set maolLimit(value: number) {
    this.quoteReinsurer = set(this._maolLimitLens, value, this.quoteReinsurer)
  }

  private _terrorismAggSubLimitLens = this._quoteReinsurerLens(
    'terrorismAggSubLimit',
    'value'
  )
  get terrorismAggSubLimit(): number {
    return view(this._terrorismAggSubLimitLens, this.quoteReinsurer)
  }

  set terrorismAggSubLimit(value: number) {
    this.quoteReinsurer = set(
      this._terrorismAggSubLimitLens,
      value,
      this.quoteReinsurer
    )
  }

  private _lossRatioCapLens = this._quoteReinsurerLens('lossRatioCap', 'value')
  get lossRatioCap(): number {
    return view(this._lossRatioCapLens, this.quoteReinsurer)
  }

  set lossRatioCap(value: number) {
    this.quoteReinsurer = set(
      this._lossRatioCapLens,
      value,
      this.quoteReinsurer
    )
  }

  private _lossRatioCapPercentageLens = this._quoteReinsurerLens(
    'lossRatioCapPercentage'
  )
  get lossRatioCapPercentage(): number {
    return view(this._lossRatioCapPercentageLens, this.quoteReinsurer) || 0
  }

  set lossRatioCapPercentage(value: number) {
    this.quoteReinsurer = set(
      this._lossRatioCapPercentageLens,
      value,
      this.quoteReinsurer
    )
  }

  private _lossCapApplicationLens =
    this._quoteReinsurerLens('lossCapApplication')
  get lossCapApplication(): string {
    return view(this._lossCapApplicationLens, this.quoteReinsurer) || ''
  }

  set lossCapApplication(value: string) {
    this.quoteReinsurer = set(
      this._lossCapApplicationLens,
      value,
      this.quoteReinsurer
    )
  }

  private _limitApplicationLens = this._quoteReinsurerLens('limitApplication')
  get limitApplication(): string {
    return view(this._limitApplicationLens, this.quoteReinsurer) || ''
  }

  set limitApplication(value: string) {
    this.quoteReinsurer = set(
      this._limitApplicationLens,
      value,
      this.quoteReinsurer
    )
  }

  private _laeCapLens = this._quoteReinsurerLens('laeCap', 'value')
  get laeCap(): number {
    return view(this._laeCapLens, this.quoteReinsurer)
  }

  set laeCap(value: number) {
    this.quoteReinsurer = set(this._laeCapLens, value, this.quoteReinsurer)
  }

  private _xplEcoDropdownLens = this._quoteReinsurerLens('xplEcoDropdown')
  get xplEcoDropdown(): string {
    return (
      view(this._xplEcoDropdownLens, this.quoteReinsurer) || 'Neither apply'
    )
  }

  set xplEcoDropdown(value: string) {
    this.quoteReinsurer = set(
      this._xplEcoDropdownLens,
      value,
      this.quoteReinsurer
    )
  }

  private _xplEcoConditionsLens = this._quoteReinsurerLens('xplEcoConditions')
  get xplEcoConditions(): string {
    return view(this._xplEcoConditionsLens, this.quoteReinsurer) || ''
  }

  set xplEcoConditions(value: string) {
    this.quoteReinsurer = set(
      this._xplEcoConditionsLens,
      value,
      this.quoteReinsurer
    )
  }

  private _ecoCoveragePctLens = this._quoteReinsurerLens('ecoCoveragePct')
  get ecoCoveragePct(): number {
    return view(this._ecoCoveragePctLens, this.quoteReinsurer) || 0
  }

  set ecoCoveragePct(value: number) {
    this.quoteReinsurer = set(
      this._ecoCoveragePctLens,
      value,
      this.quoteReinsurer
    )
    if (this.xplEcoDropdown === 'Both ECO & XPL apply') {
      this.quoteReinsurer = set(
        this._xplCoveragePctLens,
        value,
        this.quoteReinsurer
      )
    }
  }

  private _xplCoveragePctLens = this._quoteReinsurerLens('xplCoveragePct')
  get xplCoveragePct(): number {
    return view(this._xplCoveragePctLens, this.quoteReinsurer) || 0
  }

  set xplCoveragePct(value: number) {
    this.quoteReinsurer = set(
      this._xplCoveragePctLens,
      value,
      this.quoteReinsurer
    )
  }

  private _subjectPremiumLens = this._quoteReinsurerLens(
    'subjectPremium',
    'value'
  )
  get subjectPremium(): number {
    this._sumOfLossOrSubPrem = view(
      this._subjectPremiumLens,
      this.quoteReinsurer
    )
    return view(this._subjectPremiumLens, this.quoteReinsurer)
  }

  set subjectPremium(value: number) {
    this._sumOfLossOrSubPrem = value
    this.quoteReinsurer = set(
      this._subjectPremiumLens,
      value,
      this.quoteReinsurer
    )
    const quotePremium =
      (this.quoteRateOnLineSubject ?? 0) * this.subjectPremium
    this.quoteReinsurer = set(
      this._quotePremiumLens,
      quotePremium,
      this.quoteReinsurer
    )
    this.updateMinAndDepositPremiumValues(quotePremium)
  }

  private _minimumPremiumPercentageLens = this._quoteReinsurerLens(
    'minimumPremiumPercentage'
  )
  get minimumPremiumPercentage(): number {
    return view(this._minimumPremiumPercentageLens, this.quoteReinsurer) || 0
  }

  set minimumPremiumPercentage(value: number) {
    this.quoteReinsurer = set(
      this._minimumPremiumPercentageLens,
      value,
      this.quoteReinsurer
    )
    const limit = this.quotePremium
    this.quoteReinsurer = set(
      this._minimumPremiumLens,
      value * limit,
      this.quoteReinsurer
    )
  }

  private _depositPremiumPercentageLens = this._quoteReinsurerLens(
    'depositPremiumPercentage'
  )
  get depositPremiumPercentage(): number {
    return view(this._depositPremiumPercentageLens, this.quoteReinsurer) || 0
  }

  set depositPremiumPercentage(value: number) {
    this.quoteReinsurer = set(
      this._depositPremiumPercentageLens,
      value,
      this.quoteReinsurer
    )
    const limit = this.quotePremium
    this.quoteReinsurer = set(
      this._quoteDepositPremiumLens,
      value * limit,
      this.quoteReinsurer
    )
  }

  private _minimumPremiumLens = this._quoteReinsurerLens(
    'minimumPremium',
    'value'
  )
  get minimumPremium(): number {
    return view(this._minimumPremiumLens, this.quoteReinsurer)
  }

  set minimumPremium(value: number) {
    this.quoteReinsurer = set(
      this._minimumPremiumLens,
      value,
      this.quoteReinsurer
    )
    const limit = this.quotePremium
    this.quoteReinsurer = set(
      this._minimumPremiumPercentageLens,
      value / limit,
      this.quoteReinsurer
    )
  }

  private _clashPremiumLens = this._quoteReinsurerLens('clashPremium', 'value')
  get clashPremium(): number {
    return view(this._clashPremiumLens, this.quoteReinsurer)
  }

  set clashPremium(value: number) {
    this.quoteReinsurer = set(
      this._clashPremiumLens,
      value,
      this.quoteReinsurer
    )
  }

  private _profitShareCommissionLens = this._quoteReinsurerLens(
    'profitShareCommission'
  )
  get profitShareCommission(): number {
    return view(this._profitShareCommissionLens, this.quoteReinsurer) || 0
  }

  set profitShareCommission(value: number) {
    this.quoteReinsurer = set(
      this._profitShareCommissionLens,
      value,
      this.quoteReinsurer
    )
  }

  private _profitShareMinRateLens =
    this._quoteReinsurerLens('profitShareMinRate')
  get profitShareMinRate(): number {
    return view(this._profitShareMinRateLens, this.quoteReinsurer) || 0
  }

  set profitShareMinRate(value: number) {
    this.quoteReinsurer = set(
      this._profitShareMinRateLens,
      value,
      this.quoteReinsurer
    )
  }

  private _profitShareMaxRateLens =
    this._quoteReinsurerLens('profitShareMaxRate')
  get profitShareMaxRate(): number {
    return view(this._profitShareMaxRateLens, this.quoteReinsurer) || 0
  }

  set profitShareMaxRate(value: number) {
    this.quoteReinsurer = set(
      this._profitShareMaxRateLens,
      value,
      this.quoteReinsurer
    )
  }

  private _brokerageCommissionLens = this._quoteReinsurerLens(
    'brokerageCommission'
  )
  get brokerageCommission(): number {
    return view(this._brokerageCommissionLens, this.quoteReinsurer) || 0
  }

  set brokerageCommission(value: number) {
    this.quoteReinsurer = set(
      this._brokerageCommissionLens,
      value,
      this.quoteReinsurer
    )
  }

  private _brokerageTypeLens = this._quoteReinsurerLens('brokerageType')
  get brokerageType(): string {
    return view(this._brokerageTypeLens, this.quoteReinsurer)
  }

  set brokerageType(value: string) {
    this.quoteReinsurer = set(
      this._brokerageTypeLens,
      value,
      this.quoteReinsurer
    )
  }

  private _brokerageRIPCommissionLens = this._quoteReinsurerLens(
    'brokerageRIPCommission'
  )
  get brokerageRIPCommission(): number {
    return view(this._brokerageRIPCommissionLens, this.quoteReinsurer) || 0
  }

  set brokerageRIPCommission(value: number) {
    this.quoteReinsurer = set(
      this._brokerageRIPCommissionLens,
      value,
      this.quoteReinsurer
    )
  }

  private _orderPercentLens = this._quoteReinsurerLens('orderPercent')
  get orderPercent(): number {
    return view(this._orderPercentLens, this.quoteReinsurer) || 0
  }

  set orderPercent(value: number) {
    this.quoteReinsurer = set(
      this._orderPercentLens,
      value,
      this.quoteReinsurer
    )
  }

  private _otherFeaturesLens = this._quoteReinsurerLens('otherFeatures')
  get otherFeatures(): string {
    return view(this._otherFeaturesLens, this.quoteReinsurer) || ''
  }

  set otherFeatures(value: string) {
    this.quoteReinsurer = set(
      this._otherFeaturesLens,
      value,
      this.quoteReinsurer
    )
  }

  private _coverageBasisLens = this._quoteReinsurerLens('coverageBasis')
  get coverageBasis(): string {
    return view(this._coverageBasisLens, this.quoteReinsurer) || ''
  }

  set coverageBasis(value: string) {
    this.quoteReinsurer = set(
      this._coverageBasisLens,
      value,
      this.quoteReinsurer
    )
  }

  private _indexationtextLens = this._quoteReinsurerLens('indexationtext')
  get indexationtext(): string {
    return view(this._indexationtextLens, this.quoteReinsurer) || ''
  }

  set indexationtext(value: string) {
    this.quoteReinsurer = set(
      this._indexationtextLens,
      value,
      this.quoteReinsurer
    )
  }

  private _laeTreatmentLens = this._quoteReinsurerLens('laeTreatment')
  get laeTreatment(): string {
    return view(this._laeTreatmentLens, this.quoteReinsurer)
  }

  set laeTreatment(value: string) {
    this.quoteReinsurer = set(
      this._laeTreatmentLens,
      value,
      this.quoteReinsurer
    )
  }

  private _adjustmentBasisLens = this._quoteReinsurerLens('adjustmentBasis')
  get adjustmentBasis(): string {
    return view(this._adjustmentBasisLens, this.quoteReinsurer)
  }

  set adjustmentBasis(value: string) {
    this.quoteReinsurer = set(
      this._adjustmentBasisLens,
      value,
      this.quoteReinsurer
    )
  }
  private _quoteSignedPercentageLens = this._quoteReinsurerLens(
    'quoteSignedPercentage'
  )
  get quoteSignedPercentage(): number {
    return view(this._quoteSignedPercentageLens, this.quoteReinsurer) || 0
  }

  set quoteSignedPercentage(value: number) {
    this.quoteReinsurer = set(
      this._quoteSignedPercentageLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteMinPercentageLens =
    this._quoteReinsurerLens('quoteMinPercentage')
  get quoteMinPercentage(): number {
    return view(this._quoteMinPercentageLens, this.quoteReinsurer) || 0
  }

  set quoteMinPercentage(value: number) {
    this.quoteReinsurer = set(
      this._quoteMinPercentageLens,
      value,
      this.quoteReinsurer
    )
  }

  private _territorialScopeLens = this._quoteReinsurerLens('territorialScope')
  get territorialScope(): string {
    return view(this._territorialScopeLens, this.quoteReinsurer)
  }

  set territorialScope(value: string) {
    this.quoteReinsurer = set(
      this._territorialScopeLens,
      value,
      this.quoteReinsurer
    )
  }

  private _vendorLens = this._quoteReinsurerLens('vendor')
  get vendor(): string {
    return view(this._vendorLens, this.quoteReinsurer)
  }

  set vendor(value: string) {
    this.quoteReinsurer = set(this._vendorLens, value, this.quoteReinsurer)
  }

  private _feeOrBrokerageLens = this._quoteReinsurerLens('feeOrBrokerage')
  get feeOrBrokerage(): string {
    return view(this._feeOrBrokerageLens, this.quoteReinsurer)
  }

  set feeOrBrokerage(value: string) {
    this.quoteReinsurer = set(
      this._feeOrBrokerageLens,
      value,
      this.quoteReinsurer
    )
  }

  private _layerCategoryLens = this._quoteReinsurerLens('layerCategory')
  get layerCategory(): string {
    return view(this._layerCategoryLens, this.quoteReinsurer)
  }

  set layerCategory(value: string) {
    this.quoteReinsurer = set(
      this._layerCategoryLens,
      value,
      this.quoteReinsurer
    )
  }

  private _layerClassLens = this._quoteReinsurerLens('layerClass')
  get layerClass(): string {
    return view(this._layerClassLens, this.quoteReinsurer)
  }

  set layerClass(value: string) {
    this.quoteReinsurer = set(this._layerClassLens, value, this.quoteReinsurer)
  }

  private _layerSubClassLens = this._quoteReinsurerLens('layerSubClass')
  get layerSubClass(): string {
    return view(this._layerSubClassLens, this.quoteReinsurer)
  }

  set layerSubClass(value: string) {
    this.quoteReinsurer = set(
      this._layerSubClassLens,
      value,
      this.quoteReinsurer
    )
  }

  private _excludeFromPricingCurveLens = this._quoteReinsurerLens(
    'excludeFromPricingCurve'
  )
  get excludeFromPricingCurve(): boolean {
    return view(this._excludeFromPricingCurveLens, this.quoteReinsurer)
  }

  set excludeFromPricingCurve(value: boolean) {
    this.quoteReinsurer = set(
      this._excludeFromPricingCurveLens,
      value,
      this.quoteReinsurer
    )
  }

  private _modelVersionLens = this._quoteReinsurerLens('modelVersion')
  get modelVersion(): number {
    return view(this._modelVersionLens, this.quoteReinsurer)
  }

  set modelVersion(value: number) {
    this.quoteReinsurer = set(
      this._modelVersionLens,
      value,
      this.quoteReinsurer
    )
  }

  private _perilsLens = this._quoteReinsurerLens('perils')
  get perils(): string {
    return view(this._perilsLens, this.quoteReinsurer)
  }

  set perils(value: string) {
    this.quoteReinsurer = set(this._perilsLens, value, this.quoteReinsurer)
  }

  private _lossImpactedFromPreviousYearLens = this._quoteReinsurerLens(
    'lossImpactedFromPreviousYear'
  )
  get lossImpactedFromPreviousYear(): string {
    return view(this._lossImpactedFromPreviousYearLens, this.quoteReinsurer)
  }

  set lossImpactedFromPreviousYear(value: string) {
    this.quoteReinsurer = set(
      this._lossImpactedFromPreviousYearLens,
      value,
      this.quoteReinsurer
    )
  }

  private _cedingCommissionBasisLens = this._quoteReinsurerLens(
    'cedingCommissionBasis'
  )
  get cedingCommissionBasis(): string {
    return view(this._cedingCommissionBasisLens, this.quoteReinsurer)
  }

  set cedingCommissionBasis(value: string) {
    this.quoteReinsurer = set(
      this._cedingCommissionBasisLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteIndexLens = this._quoteReinsurerLens('quoteIndex')
  get quoteIndex(): string {
    return view(this._quoteIndexLens, this.quoteReinsurer)
  }

  set quoteIndex(value: string) {
    this.quoteReinsurer = set(this._quoteIndexLens, value, this.quoteReinsurer)
  }

  private _feeLens = this._quoteReinsurerLens('fee')
  get fee(): number {
    return view(this._feeLens, this.quoteReinsurer) || 0
  }

  set fee(value: number) {
    this.quoteReinsurer = set(this._feeLens, value, this.quoteReinsurer)
  }

  private _rebateLens = this._quoteReinsurerLens('rebate')
  get rebate(): number {
    return view(this._rebateLens, this.quoteReinsurer) || 0
  }

  set rebate(value: number) {
    this.quoteReinsurer = set(this._rebateLens, value, this.quoteReinsurer)
  }

  private _quoteProbabilityOfExhaustLens = this._quoteReinsurerLens(
    'quoteProbabilityOfExhaust'
  )
  get quoteProbabilityOfExhaust(): number {
    return view(this._quoteProbabilityOfExhaustLens, this.quoteReinsurer) || 0
  }

  set quoteProbabilityOfExhaust(value: number) {
    this.quoteReinsurer = set(
      this._quoteProbabilityOfExhaustLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteProbabilityAttachmentLens = this._quoteReinsurerLens(
    'quoteProbabilityOfAttach'
  )
  get quoteProbabilityOfAttach(): number {
    return view(this._quoteProbabilityAttachmentLens, this.quoteReinsurer) || 0
  }

  set quoteProbabilityOfAttach(value: number) {
    this.quoteReinsurer = set(
      this._quoteProbabilityAttachmentLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteOfferedPercentageLens = this._quoteReinsurerLens(
    'quoteOfferedPercentage'
  )
  get quoteOfferedPercentage(): number {
    return view(this._quoteOfferedPercentageLens, this.quoteReinsurer) || 0
  }

  set quoteOfferedPercentage(value: number) {
    this.quoteReinsurer = set(
      this._quoteOfferedPercentageLens,
      value,
      this.quoteReinsurer
    )
    const limit = this._getQuoteOccOrAggLimit()
    this.quoteReinsurer = set(
      this._quoteOfferedLimitLens,
      limit < analyzereConstants.unlimitedValue
        ? limit * this.quoteOfferedPercentage
        : 0,
      this.quoteReinsurer
    )
  }

  private _quoteOfferedLimitLens = this._quoteReinsurerLens(
    'quoteOfferedLimit',
    'value'
  )
  get quoteOfferedLimit(): number {
    return view(this._quoteOfferedLimitLens, this.quoteReinsurer)
  }

  set quoteOfferedLimit(value: number) {
    this.quoteReinsurer = set(
      this._quoteOfferedLimitLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteDepositPremiumLens = this._quoteReinsurerLens(
    'quoteDepositPremium',
    'value'
  )
  get quoteDepositPremium(): number {
    return view(this._quoteDepositPremiumLens, this.quoteReinsurer)
  }

  set quoteDepositPremium(value: number) {
    const quoteRol = this.quoteRolPercentage
    const sumLoss = this._sumOfLossOrSubPrem
      ? this._sumOfLossOrSubPrem
      : this._sumOfLossSetPremiums()
    const quoteRate = this.quoteRateOnLineSubject
      ? this.quoteRateOnLineSubject
      : 0
    const quotePrem =
      sumLoss * quoteRol ? sumLoss * quoteRol : sumLoss * quoteRate

    if (value) {
      this.quoteReinsurer = set(
        this._quoteDepositPremiumLens,
        value,
        this.quoteReinsurer
      )
    } else {
      value = quotePrem
      this.quoteReinsurer = set(
        this._quoteDepositPremiumLens,
        value,
        this.quoteReinsurer
      )
    }

    this.quoteReinsurer = set(
      this._depositPremiumPercentageLens,
      value / this.quotePremium,
      this.quoteReinsurer
    )
  }

  private _quoteMinRateSubjectLens = this._quoteReinsurerLens(
    'quoteMinRateSubject'
  )
  get quoteMinRateSubject(): number {
    return view(this._quoteMinRateSubjectLens, this.quoteReinsurer)
  }
  set quoteMinRateSubject(value: number) {
    this.quoteReinsurer = set(
      this._quoteMinRateSubjectLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteMaxRateSubjectLens = this._quoteReinsurerLens(
    'quoteMaxRateSubject'
  )
  get quoteMaxRateSubject(): number {
    return view(this._quoteMaxRateSubjectLens, this.quoteReinsurer)
  }
  set quoteMaxRateSubject(value: number) {
    this.quoteReinsurer = set(
      this._quoteMaxRateSubjectLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteMinRatePmpmLens = this._quoteReinsurerLens('quoteMinRatePmpm')
  get quoteMinRatePmpm(): number {
    return view(this._quoteMinRatePmpmLens, this.quoteReinsurer)
  }
  set quoteMinRatePmpm(value: number) {
    this.quoteReinsurer = set(
      this._quoteMinRatePmpmLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteMaxRatePmpmLens = this._quoteReinsurerLens('quoteMaxRatePmpm')
  get quoteMaxRatePmpm(): number {
    return view(this._quoteMaxRatePmpmLens, this.quoteReinsurer)
  }
  set quoteMaxRatePmpm(value: number) {
    this.quoteReinsurer = set(
      this._quoteMaxRatePmpmLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteSwingRateLens = this._quoteReinsurerLens('quoteSwingRate')
  get quoteSwingRate(): number {
    return view(this._quoteSwingRateLens, this.quoteReinsurer)
  }
  set quoteSwingRate(value: number) {
    this.quoteReinsurer = set(
      this._quoteSwingRateLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteSwingBasisLens = this._quoteReinsurerLens('quoteSwingBasis')
  get quoteSwingBasis(): string {
    return view(this._quoteSwingBasisLens, this.quoteReinsurer)
  }
  set quoteSwingBasis(value: string) {
    this.quoteReinsurer = set(
      this._quoteSwingBasisLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteIndexationLens = this._quoteReinsurerLens('quoteIndexation')
  get quoteIndexation(): string {
    return view(this._quoteIndexationLens, this.quoteReinsurer)
  }
  set quoteIndexation(value: string) {
    this.quoteReinsurer = set(
      this._quoteIndexationLens,
      value,
      this.quoteReinsurer
    )
  }
  private _structureFXLens = this._quoteReinsurerLens('structureFX')
  get structureFX(): string {
    return view(this._structureFXLens, this.quoteReinsurer)
  }
  set structureFX(value: string) {
    this.quoteReinsurer = set(this._structureFXLens, value, this.quoteReinsurer)
  }
  private _premiumFXLens = this._quoteReinsurerLens('premiumFX')
  get premiumFX(): string {
    return view(this._premiumFXLens, this.quoteReinsurer)
  }
  set premiumFX(value: string) {
    this.quoteReinsurer = set(this._premiumFXLens, value, this.quoteReinsurer)
  }
  private _premiumFXToUSDLens = this._quoteReinsurerLens('premiumFXToUSD')
  get premiumFXToUSD(): string {
    return view(this._premiumFXToUSDLens, this.quoteReinsurer)
  }
  set premiumFXToUSD(value: string) {
    this.quoteReinsurer = set(
      this._premiumFXToUSDLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteFixedIndexValueLens = this._quoteReinsurerLens(
    'quoteFixedIndexValue'
  )
  get quoteFixedIndexValue(): number {
    return view(this._quoteFixedIndexValueLens, this.quoteReinsurer)
  }
  set quoteFixedIndexValue(value: number) {
    this.quoteReinsurer = set(
      this._quoteFixedIndexValueLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteSicOrFranchiseLens = this._quoteReinsurerLens(
    'quoteSicOrFranchise'
  )
  get quoteSicOrFranchise(): number {
    return view(this._quoteSicOrFranchiseLens, this.quoteReinsurer)
  }
  set quoteSicOrFranchise(value: number) {
    this.quoteReinsurer = set(
      this._quoteSicOrFranchiseLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteExpectedCededLossLens = this._quoteReinsurerLens(
    'quoteExpectedCededLoss',
    'value'
  )
  get quoteExpectedCededLoss(): number {
    return view(this._quoteExpectedCededLossLens, this.quoteReinsurer)
  }
  set quoteExpectedCededLoss(value: number) {
    this.quoteReinsurer = set(
      this._quoteExpectedCededLossLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteExpectedCededPremiumLens = this._quoteReinsurerLens(
    'quoteExpectedCededPremium',
    'value'
  )
  get quoteExpectedCededPremium(): number {
    return view(this._quoteExpectedCededPremiumLens, this.quoteReinsurer)
  }
  set quoteExpectedCededPremium(value: number) {
    this.quoteReinsurer = set(
      this._quoteExpectedCededPremiumLens,
      value,
      this.quoteReinsurer
    )
  }

  private _totalQuoteExpectedCededPremiumLens = this._quoteReinsurerLens(
    'totalQuoteExpectedCededPremium',
    'value'
  )
  get totalQuoteExpectedCededPremium(): number {
    return view(this._totalQuoteExpectedCededPremiumLens, this.quoteReinsurer)
  }
  set totalQuoteExpectedCededPremium(value: number) {
    this.quoteReinsurer = set(
      this._totalQuoteExpectedCededPremiumLens,
      value,
      this.quoteReinsurer
    )
  }

  private _totalQuoteExpectedCededLossLens = this._quoteReinsurerLens(
    'totalQuoteExpectedCededLoss',
    'value'
  )
  get totalQuoteExpectedCededLoss(): number {
    return view(this._totalQuoteExpectedCededLossLens, this.quoteReinsurer)
  }
  set totalQuoteExpectedCededLoss(value: number) {
    this.quoteReinsurer = set(
      this._totalQuoteExpectedCededLossLens,
      value,
      this.quoteReinsurer
    )
  }

  private _quoteDepositPremiumCalcLens = this._quoteReinsurerLens(
    'quoteDepositPremiumCalc',
    'value'
  )
  get quoteDepositPremiumCalc(): number {
    return view(this._quoteDepositPremiumCalcLens, this.quoteReinsurer)
  }
  set quoteDepositPremiumCalc(value: number) {
    this.quoteReinsurer = set(
      this._quoteDepositPremiumCalcLens,
      value,
      this.quoteReinsurer
    )
  }

  private _slidingCommLens = this._quoteReinsurerLens('slidingComm')
  get slidingComm(): boolean {
    return view(this._slidingCommLens, this.quoteReinsurer)
  }

  set slidingComm(value: boolean) {
    this.quoteReinsurer = set(this._slidingCommLens, value, this.quoteReinsurer)
  }

  constructor(
    layers: LayerState[] | null,
    layer: Layer,
    options?: {
      lossSets?: LossSetLayer[]
      metrics?: LayerMetrics | null
      grossPortfolioCovariance?: number | null
      reinsurers?: Reinsurer[]
      quoteReinsurer?: QuoteReinsurer
      prevView?: LayerView
      programDefaultPricingCurves?: DefaultSavedCurveEntry[]
      savedPricingCurves?: SavedPricingCurveEntry[]
      currentProgram?: Program
    }
  ) {
    const prevValue = options?.prevView

    this.layers = layers
    this.layer = clone(layer)
    this._buildProgramAndLayerTypes()
    this._initializeOccurrenceLimit(
      prevValue?._prevOccurrenceLimit ?? this.premium
    )
    this._initializeContractOccurrenceLimit(
      prevValue?._prevContractOccurrenceLimit ?? this.premium
    )
    this._initializeAggregateLimit(
      prevValue?._prevAggregateLimit ?? this.premium
    )
    this.lossSets = (options && options.lossSets) || []
    this.quoteReinsurer = options && options.quoteReinsurer
    this._updateLayerValues()
    this.metrics = options && options.metrics
    this.grossPortfolioCovariance = options && options.grossPortfolioCovariance
    this.reinsurers = (options && options.reinsurers) || []
    this.savedPricingCurves = (options && options.savedPricingCurves) || []
    this.programDefaultPricingCurves =
      (options && options.programDefaultPricingCurves) || []
    this.currentProgram = (options && options.currentProgram) || undefined
  }

  setFromValues(values: LayerViewValues, skip?: string[]) {
    // this.values = values
    this._setLayerValues(values, skip || [])
    // this._updateMetricsValues()
    // this._updateLossSetsValues()
    // this._updateGrossCovarianceValues()
    this.updateValues()
    this.values.id = values.id
  }

  private _setLayerValues(values: LayerViewValues, skip: string[]) {
    getSetters(LayerView.prototype, [...omittedLayerValues, ...skip]).forEach(
      prop => {
        const value = (values as any)[prop] as any
        const self = this as any
        if (value !== undefined && !isNaN(value)) {
          self[prop] = value
        }
      }
    )
  }

  updateValues(): void {
    this._updateLayerValues()
    this._updateMetricsValues()
    this._updateLossSetsValues()
    this._updateGrossCovarianceValues()
  }

  private _updateLayerValues(): void {
    if (isSwingLayer(this.layer, 'combined-layer') && this.visibleLayer) {
      const physical = this.visibleLayer.physicalLayer

      this.name = physical.description ?? ''
      this.occurrenceLimit = physical.limit.value
      this.occurrenceAttachment = physical.attachment.value
      this.aggregateLimit = physical.aggregateLimit.value
      this.aggregateAttachment = physical.aggregateAttachment.value
    }

    this.values = getGetters(LayerView.prototype, omittedLayerValues).reduce(
      (acc, name) => ({ ...acc, [name]: this[name as keyof LayerView] }),
      {} as any
    )
  }

  private _updateMetricsValues(): void {
    const l = this.lossSets
    const m = this.metrics // Alias

    const depositPremium =
      m && m.depositPremium !== 0 ? Math.abs(m.depositPremium) : 0

    const depositPremiumNoParticipation =
      m && m.depositPremiumNoParticipation !== 0
        ? Math.abs(m.depositPremiumNoParticipation)
        : 0

    const expectedCededPremium = m ? Math.abs(m.expectedCededPremium) : 0

    const expectedCededPremiumNoParticipation = m
      ? Math.abs(m.expectedCededPremiumNoParticipation)
      : 0

    let pmpmExpectedCededPremium = 0
    const sumLossSetMembers = l ? this._sumOfLossSetMembers() : 0
    if (sumLossSetMembers > 0) {
      pmpmExpectedCededPremium = m
        ? Math.abs(m.expectedCededPremium) / 12 / sumLossSetMembers
        : 0
    }

    const purePremium = m ? Math.abs(m.purePremium) : 0

    const expectedCededLossRatio =
      m && !isNaN(m.expectedCededLossRatio) ? m.expectedCededLossRatio : 0

    // This translates to result.expectedCededPremiumBase.mean * cessionPercentage
    const expectedReinstatementPremium = m
      ? expectedCededPremium - depositPremium
      : 0

    const expectedCededExpenses = m ? Math.abs(m.expectedCededExpenses) : 0

    const purePremiumForTP = m && m.purePremiumForTP ? m.purePremiumForTP : 0

    const standardDeviationExpectedLossForTP =
      m && m.standardDeviationExpectedLossForTP
        ? m.standardDeviationExpectedLossForTP
        : 0

    const expectedLoss = purePremium

    const expectedLossPercentLimit =
      this.getExpectedLossPercentLimit(purePremiumForTP)

    const contractROL = this.getContractROL(depositPremiumNoParticipation)

    const totalROL = this.getTotalROL(expectedCededPremiumNoParticipation)

    const premiumMultiple = this.getPremiumMultiple(
      expectedCededPremium,
      purePremium
    )

    const probabilityExhaust = this.getProbabilityExhaust(
      this.metrics?.exitProbability,
      this.metrics?.exitAggProbability
    )

    this.values = {
      ...this.metrics,
      ...this.values,
      depositPremium,
      expectedCededPremium,
      pmpmExpectedCededPremium,
      purePremium,
      expectedCededLossRatio,
      efficiencyVolatility: m ? m.aepVar100 / m.expectedCededMargin : 0,
      efficiencyTVaR: m ? m.aeptVar100 / m.expectedCededMargin : 0,
      expectedReinstatementPremium,
      expectedCededExpenses,
      purePremiumForTP,
      standardDeviationExpectedLossForTP,
      expectedLoss,
      expectedLossPercentLimit,
      contractROL,
      totalROL,
      premiumMultiple,
      probabilityExhaust,
    }
  }

  private _updateLossSetsValues() {
    const sumLossSetPremiums = this._sumOfLossSetPremiums()
    if (sumLossSetPremiums > 0) {
      this.layer = set(
        this._rateOnLineSubjectLens,
        this.premium / this._sumOfLossSetPremiums(),
        this.layer
      )
    } else {
      const rateOnLineSubjectValue =
        this.layer.physicalLayer.meta_data.rate_on_subject! > 0
          ? this.layer.physicalLayer.meta_data.rate_on_subject!
          : 0
      this.layer = set(
        this._rateOnLineSubjectLens,
        rateOnLineSubjectValue,
        this.layer
      )
    }

    // Set PMPM only if sum of Loss Set Members is > 0
    const sumLossSetMembers = this._sumOfLossSetMembers()
    if (sumLossSetMembers > 0) {
      this.layer = set(
        this._pmpmLens,
        this.premium / (sumLossSetMembers * 12),
        this.layer
      )
    } else {
      if (
        this.layer.physicalLayer.meta_data.pmpm === undefined ||
        this.layer.physicalLayer.meta_data.pmpm <= 0
      ) {
        this.layer = set(this._pmpmLens, 0, this.layer)
      }
    }
  }

  private _updateGrossCovarianceValues() {
    this.values.grossVolatilityCeded =
      this.metrics && this.grossPortfolioCovariance
        ? (-1 * this.metrics.grossCovariance) / this.grossPortfolioCovariance
        : 0
  }

  private _updateReinsurersValues() {
    this.values.technicalPremium = this.technicalPremium
  }

  // private _updateQuoteReinsurerValues() {
  //   this.values = getGetters(LayerView.prototype, omittedLayerValues).reduce(
  //     (acc, name) => ({ ...acc, [name]: this[name as keyof LayerView] }),
  //     {} as any
  //   )
  // }

  private _buildProgramAndLayerTypes(): void {
    const parts = this.type ? this.type.split('_') : []
    this._layerType = parts.pop()
    this._programType = parts.pop()
  }

  private _initializeOccurrenceLimit(defaultValue: number): void {
    if (this.occurrenceLimit >= analyzereConstants.unlimitedValue) {
      this._prevOccurrenceLimit = defaultValue
    }
  }

  private _initializeContractOccurrenceLimit(defaultValue: number): void {
    if (this.contractOccurrenceLimit >= analyzereConstants.unlimitedValue) {
      this._prevContractOccurrenceLimit = defaultValue
    }
  }

  private _initializeAggregateLimit(defaultValue: number): void {
    if (this.aggregateLimit >= analyzereConstants.unlimitedValue) {
      this._prevAggregateLimit = defaultValue
    }
  }

  private _quoteReinsurerLens(...path: Array<string | number>) {
    return lensPath(['quoteFields', ...path])
  }

  private _physicalLens(...path: Array<string | number>) {
    return lensPath(['physicalLayer', ...path])
  }

  private _getOccOrAggLimit() {
    if (this.layerType === 'ag') {
      return this.aggregateLimit
    }
    if (this.layerType === 'multisection') {
      return this.contractOccurrenceLimit
    }
    return this.occurrenceLimit
  }

  private _getQuoteOccOrAggLimit() {
    return this.layerType === 'ag'
      ? this.quoteAggregateLimit
      : this.quoteOccurrenceLimit
  }

  private _sumOfLossSetPremiums(): number {
    return this._lossSets
      .filter(ls => this.layer.lossSetLayers.some(_ls => _ls.id === ls.id))
      .reduce((acc, ls) => {
        return ls.premium ? acc + ls.premium.value : acc
      }, 0)
  }

  private _sumOfLossSetMembers(): number {
    return this._lossSets
      .filter(ls => this.layer.lossSetLayers.some(_ls => _ls.id === ls.id))
      .reduce((acc, ls) => {
        return ls.meta_data && ls.meta_data.members
          ? acc + ls.meta_data.members
          : acc
      }, 0)
  }

  private calculateTechnicalPremium(
    factor: TechnicalFactors,
    values: LayerViewValues,
    reinstatements: Reinstatement[]
  ): number {
    const volatilityMetricValue = this.getVolatilityMetricValue(factor, values)
    const rpRelativity = this.getRprelativityValue(values, reinstatements)
    const layerType = this.layer.meta_data.sage_layer_type
    if (
      layerType === layerIds.noncatAg ||
      layerType === layerIds.catAg ||
      layerType === layerIds.ahlAg
    ) {
      if (values.aggregateLimit >= 1e21) {
        return (
          (Math.pow(
            values.expectedCededLossRatio / values.aggregateLimit,
            factor.expected_loss_power
          ) *
            factor.expected_loss_multiplier +
            (volatilityMetricValue / values.aggregateLimit) *
              factor.volatility_multiplier +
            factor.fixed_cost / values.aggregateLimit) *
          values.aggregateLimit
        )
      } else {
        return (
          Math.max(
            Math.pow(
              values.purePremiumForTP / values.aggregateLimit,
              factor.expected_loss_power
            ) *
              factor.expected_loss_multiplier +
              (volatilityMetricValue / values.aggregateLimit) *
                factor.volatility_multiplier +
              factor.fixed_cost / values.aggregateLimit,
            factor.minimum_rate_on_line
          ) * values.aggregateLimit
        )
      }
    } else if (
      layerType === layerIds.noncatQs ||
      layerType === layerIds.catQs ||
      layerType === layerIds.ahlQs
    ) {
      const expectedCededLossRatio = Math.abs(values.expectedCededLossRatio)
      return Math.max(
        Math.min(
          1 - factor.reinsurer_margin_percentage - expectedCededLossRatio,
          factor.max_ceding_commission_percentage
        ),
        0
      )
    } else {
      if (values.occurrenceLimit >= 1e21) {
        return (
          (Math.pow(
            values.expectedCededLossRatio /
              (values.occurrenceLimit * rpRelativity),
            factor.expected_loss_power
          ) *
            factor.expected_loss_multiplier +
            (volatilityMetricValue / (values.occurrenceLimit * rpRelativity)) *
              factor.volatility_multiplier +
            factor.fixed_cost / values.occurrenceLimit) *
          values.occurrenceLimit
        )
      } else {
        return (
          Math.max(
            Math.pow(
              values.purePremiumForTP / (values.occurrenceLimit * rpRelativity),
              factor.expected_loss_power
            ) *
              factor.expected_loss_multiplier +
              (volatilityMetricValue /
                (values.occurrenceLimit * rpRelativity)) *
                factor.volatility_multiplier +
              factor.fixed_cost / values.occurrenceLimit,
            factor.minimum_rate_on_line
          ) * values.occurrenceLimit
        )
      }
    }
  }

  private getVolatilityMetricValue(
    { volatility_metric }: TechnicalFactors,
    values: LayerViewValues
  ): number {
    switch (volatility_metric) {
      case 'Ceded Standard Deviation': {
        return Math.abs(values.standardDeviationExpectedLossForTP)
      }
      case 'Ceded Loss CV': {
        return Math.abs(values.cededLossCVForTP)
      }
      case 'Probability of Attachment': {
        return Math.abs(values.entryProbability)
      }
      default: {
        if (volatility_metric.includes('year TVar')) {
          return Math.abs(values.cededYearTVar)
        } else if (volatility_metric.includes('year Var')) {
          return Math.abs(values.cededYearVar)
        } else {
          return Math.abs(values.standardDeviationExpectedLossForTP)
        }
      }
    }
  }

  private getRprelativityValue(
    values: LayerViewValues,
    reinstatements: Reinstatement[]
  ): number {
    const absDepositPremium = Math.abs(values.depositPremium)
    let rpRelativity = values.expectedCededPremium / values.depositPremium
    if (reinstatements.length === 0 || absDepositPremium === 0) {
      rpRelativity = 1.0
    } else if (absDepositPremium === 1) {
      const sumProductValue = reinstatements.reduce((acc, val) => {
        return val.premium !== undefined ? acc + val.premium : acc
      }, 0)
      rpRelativity = 1 + Number(sumProductValue.toFixed(5))
    }
    return rpRelativity
  }

  private isAHL(): boolean {
    return (
      this.layer.meta_data.sage_layer_type === layerIds.ahlXl ||
      this.layer.meta_data.sage_layer_type === layerIds.ahlQs ||
      this.layer.meta_data.sage_layer_type === layerIds.ahlAg ||
      this.layer.meta_data.sage_layer_type === layerIds.ahlSwing
    )
  }

  // we need to revisit this method. We are updating the method for resolving the tech premium loading issue
  private areFieldsPresentForTechnicalPremium(
    values: LayerViewValues
  ): boolean {
    const layerType = this.layer.meta_data.sage_layer_type
    // QS layers require expected ceded loss to calculate technical premium
    if (
      layerType === layerIds.noncatQs ||
      layerType === layerIds.catQs ||
      layerType === layerIds.ahlQs
    ) {
      return values.expectedCededLossRatio >= 0
      // Ag layers require purePremiumForTP
    } else if (
      layerType === layerIds.noncatAg ||
      layerType === layerIds.catAg ||
      layerType === layerIds.ahlAg
    ) {
      return values.purePremiumForTP >= 0
    } else {
      // 0 Rp relativity will cause a divide by 0 if technical premium is calculated
      return this.getRprelativityValue(values, this.reinstatements) !== 0
    }
  }

  private pricingCurvesPremium(
    pricingCurves: DefaultSavedCurveEntry[]
  ): number | null {
    if (
      !this.layer.physicalLayer.meta_data.isAutoBuild &&
      (this.layer.viewMetrics.loading ||
        !this.areFieldsPresentForTechnicalPremium(this.values))
    ) {
      return null
    }
    return pricingCurves.reduce((acc, curve) => {
      const savedCurve = this.savedPricingCurves?.find(l => l.id === curve.id)
      if (!savedCurve || !savedCurve.techFactors) {
        return acc
      }
      return (
        acc +
        this.calculateTechnicalPremium(
          savedCurve.techFactors,
          this.values,
          this.reinstatements
        ) *
          curve.percentage
      )
    }, 0)
  }

  private createProfitCommission(): Fee {
    return {
      payout_date: '2035-12-31T19:00:00',
      _type: 'ProfitCommission',
      rate: 0,
      fees: [
        { ref: ['Layer', 'Fees', 'flat_cede'] },
        { ref: ['Layer', 'Fees', 'RExp'] },
      ],
      premiums: [
        { ref: ['Layer', 'Premium'] },
        { ref: ['Layer', 'ReinstatementPremium'] },
      ],
      losses: [
        { ref: ['Layer', 'Losses'] },
        { ref: ['Layer', 'ReinstatementBrokerage'] },
      ],
      name: 'PC',
    }
  }

  private createProportionalExpense(name: string): Fee {
    return createProportionalExpense(name, 0)
  }
}
