import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment as ENV } from '../../../environments/environment'
import { ApiResponse } from '../model/api.model'
import { catchAndHandleError, mapToMaybeData } from '../util'
import { convertAnimatedScenariosData } from '../../credit/utils/credit.utils'
import { CreditPortfolio } from '../../credit/model/credit-portfolio.model'
import {
  CreditStructure,
  CreditCalculationStructure,
  CreditStructureOptimizationInput,
  CreditAdminCalculationStructure,
} from './../../credit/model/credit-structure.model'
import {
  CreditStructureGroup,
  CreditStructureGroupMember,
} from './../../credit/model/credit-structure-group.model'
import { CreditCompareMetric } from './../../credit/model/credit-compare.model'
import { CreditProgramMetricSetting } from './../../credit/model/credit-metric.model'
import {
  CreditResults,
  StructureResultGroup,
} from '../../credit/model/credit-results.model'
import { CashFlowsData } from '../../credit/model/credit-cash-flows.model'
import {
  AnimatedScenariosResults,
  AnimatedScenariosResultsData,
} from '../../credit/model/credit-animated-scenarios.model'
import {
  StratificationAxisField,
  StratificationResults,
} from '../../credit/model/credit-stratification.model'
import { CreditOptimizationResult } from '../../credit/model/credit-optimization.model'
import {
  PerformanceField,
  PerformanceResults,
} from '../../credit/model/credit-performance.model'
import {
  CreditSubmissionStructure,
  CreditSubmissionStructureWithProgramData,
} from '../../credit/model/credit-submission.model'
import {
  CreditFileType,
  CreditPortfolioUploadResults,
} from './../../admin/model/credit-admin.model'

const API = ENV.internalApi

enum CreditRequests {
  Portfolio = 'Credit Portfolio(s)',
  SelectedPortfolio = 'Selected Credit Portfolio',
  CreditStructure = 'Credit Structure(s)',
  CreditStructureAdmin = 'Credit Admin Structure(s)',
  SelectedCreditStructure = 'Selected Credit Structure',
  CalculationStructure = 'Credit Calculation Structure(s)',
  SelectedCalculationStructure = 'Selected Credit Calculation Structure',
  CreditResults = 'Credit Results',
  SimulatedResults = 'Credit Simulated Results',
  DeterministicResults = 'Credit Deterministic Results',
  CashFlows = 'Credit Cash Flows',
  AnimatedScenarios = 'Credit Animated Scenarios Data',
  CreditStructureGroup = 'Credit Structure Group(s)',
  SelectedCreditStructureGroup = 'Selected Credit Structure Group',
  CreditStructureGroupMember = 'Credit Structure Group Member(s)',
  SelectedCreditStructureGroupMember = 'Selected Credit Structure Group Member',
  CreditStructureOptimize = 'Credit Structure Optimize',
  CreditOptimizerResults = 'Credit Optimization Results',
  CompareMetrics = 'Credit Compare Metrics',
  Stratification = 'Credit Stratification',
  Performance = 'Credit Performance',
  Metrics = 'Credit Metrics',
  CreditSubmissionStructure = 'Credit Submission Structure(s)',
  SelectedCreditSubmissionStructure = 'Selected Credit Submission Structure',
  Upload = 'Upload',
}

enum CreditApiRoutes {
  Portfolio = '/portfolio',
  Structure = '/structure',
  Admin = '/admin',
  LayerResults = '/layerResults',
  Stratifications = '/stratification',
  SimulatedCreditResults = '/simulatedCreditResults',
  DeterministicCreditResults = '/deterministicCreditResults',
  CashFlows = '/cashFlows',
  AnimatedScenarios = '/animatedScenarios',
  CompareMetrics = '/compareMetrics',
  Performance = '/performance',
  Upload = '/upload',
  Submission = '/submission',
}

enum CreditApiParams {
  PortfolioId = 'portfolioId',
  ProgramId = 'programId',
  Years = 'years',
  GroupId = 'groupId',
  TransactionId = 'transactionId',
  Period = 'period',
  GrossLossScenarioId = 'grossLossScenarioId',
  ColType = 'colType',
  RowType = 'rowType',
  States = 'states',
  Field = 'field',
  GrossLossScenarioIds = 'grossLossScenarioIds',
  ActualLossScenarioIds = 'actualLossScenarioIds',
  ActualGLSIds = 'actualGLSIds',
  IsActual = 'isActual',
  IsSubmission = 'isSubmission',
  FileType = 'fileType',
}

@Injectable({
  providedIn: 'root',
})
export class CreditService {
  constructor(private http: HttpClient) {}

  /* using locktonre-sage-crd-api -> retrieves from mongo db database */
  getPortfolios(): ApiResponse<CreditPortfolio[]> {
    const endpoint = `${API.creditPortfolio}`
    return this.getEntity<CreditPortfolio[]>(endpoint, CreditRequests.Portfolio)
  }
  getPortfolioById(portfolioId: string): ApiResponse<CreditPortfolio> {
    const endpoint = `${API.creditPortfolio}/${portfolioId}`
    return this.getEntity<CreditPortfolio>(
      endpoint,
      CreditRequests.SelectedPortfolio
    )
  }
  postPortfolio(portfolio: CreditPortfolio): ApiResponse<CreditPortfolio> {
    const endpoint = `${API.creditPortfolio}`
    return this.postEntity<CreditPortfolio>(
      portfolio,
      endpoint,
      CreditRequests.Portfolio
    )
  }
  putPortfolio(portfolio: CreditPortfolio): ApiResponse<CreditPortfolio> {
    const endpoint = `${API.creditPortfolio}/${portfolio._id}`
    return this.putEntity<CreditPortfolio>(
      portfolio,
      endpoint,
      CreditRequests.Portfolio
    )
  }
  uploadPortfolioData(
    fileType: CreditFileType,
    file: FormData
  ): ApiResponse<CreditPortfolioUploadResults> {
    const queryParam = this.buildQueryParam(CreditApiParams.FileType, fileType)
    const url = `${API.base}${API.creditPortfolio}${CreditApiRoutes.Upload}?${queryParam}`
    return this.http
      .post<CreditPortfolioUploadResults>(url, file)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError(`POST ${CreditRequests.Upload}`)
      )
  }
  getCalculationStructures(): ApiResponse<CreditCalculationStructure[]> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}`
    return this.getEntity<CreditCalculationStructure[]>(
      endpoint,
      CreditRequests.CalculationStructure
    )
  }
  getCreditAdminStructures(): ApiResponse<CreditAdminCalculationStructure[]> {
    const endpoint = `${API.creditStructure}${CreditApiRoutes.Admin}`
    return this.getEntity<CreditAdminCalculationStructure[]>(
      endpoint,
      CreditRequests.CreditStructure
    )
  }
  deleteCreditStructureById(id: string): ApiResponse<string> {
    const endpoint = `${API.creditStructure}/${id}`
    return this.deleteEntity(endpoint, CreditRequests.CreditStructure)
  }
  getCalculationStructureById(
    id: string
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${id}`
    return this.getEntity<CreditCalculationStructure>(
      endpoint,
      CreditRequests.SelectedCalculationStructure
    )
  }
  postCalculationStructure(
    structure: CreditCalculationStructure
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}`
    return this.postEntity<CreditCalculationStructure>(
      structure,
      endpoint,
      CreditRequests.CalculationStructure
    )
  }
  putCalculationStructure(
    structure: CreditCalculationStructure
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structure._id}`
    return this.putEntity<CreditCalculationStructure>(
      structure,
      endpoint,
      CreditRequests.CalculationStructure
    )
  }
  getCreditResults(
    structureId: string,
    grossLossScenarioId: string
  ): ApiResponse<CreditResults> {
    const queryParam = this.buildQueryParam(
      CreditApiParams.GrossLossScenarioId,
      grossLossScenarioId
    )
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.LayerResults}`
    return this.getEntity<CreditResults>(
      endpoint,
      CreditRequests.DeterministicResults,
      queryParam
    )
  }
  getCreditStratificationResults(
    portfolioId: string,
    rowType: StratificationAxisField,
    colType: StratificationAxisField,
    states?: string[]
  ): ApiResponse<StratificationResults> {
    const rowsAndCols: { key: string; value: string | number }[] = [
      { key: CreditApiParams.RowType, value: rowType },
      { key: CreditApiParams.ColType, value: colType },
    ]
    if (states) {
      rowsAndCols.push({
        key: CreditApiParams.States,
        value: states.join(','),
      })
    }
    const queryParams = this.buildQueryParams(rowsAndCols)
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Portfolio}/${portfolioId}${CreditApiRoutes.Stratifications}`
    return this.getEntity<StratificationResults>(
      endpoint,
      CreditRequests.Stratification,
      queryParams
    )
  }
  getPerformanceResults(
    structureId: string,
    field: PerformanceField,
    grossLossIds: string[],
    actualLossIds: string[]
  ): ApiResponse<PerformanceResults> {
    const rowsAndCols: { key: string; value: string | number }[] = [
      { key: CreditApiParams.Field, value: field },
      {
        key: CreditApiParams.GrossLossScenarioIds,
        value: grossLossIds.join(','),
      },
      {
        key: CreditApiParams.ActualLossScenarioIds,
        value: actualLossIds.join(','),
      },
    ]
    const queryParams = this.buildQueryParams(rowsAndCols)
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.Performance}`
    return this.getEntity<PerformanceResults>(
      endpoint,
      CreditRequests.Performance,
      queryParams
    )
  }
  getSimulatedExceedanceProbabilities(
    structureId: string,
    grossLossScenarioId: string,
    years: number
  ): ApiResponse<StructureResultGroup> {
    const kvps = [
      {
        key: CreditApiParams.GrossLossScenarioId,
        value: grossLossScenarioId,
      },
      { key: CreditApiParams.Period, value: years },
    ]
    const queryParams = this.buildQueryParams(kvps)
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.SimulatedCreditResults}`
    return this.getEntity<StructureResultGroup>(
      endpoint,
      CreditRequests.SimulatedResults,
      queryParams
    )
  }
  getDeterministicExceedanceProbabilities(
    structureId: string,
    grossLossScenarioId: string
  ): ApiResponse<StructureResultGroup> {
    const queryParam = this.buildQueryParam(
      CreditApiParams.GrossLossScenarioId,
      grossLossScenarioId
    )
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.DeterministicCreditResults}`
    return this.getEntity<StructureResultGroup>(
      endpoint,
      CreditRequests.DeterministicResults,
      queryParam
    )
  }
  getCreditCashFlows(
    structureId: string,
    grossLossScenarioId: string,
    transactionId: string,
    isActual: boolean,
    isSubmission: boolean
  ): ApiResponse<CashFlowsData> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.CashFlows}`
    const kvps = [
      {
        key: CreditApiParams.GrossLossScenarioId,
        value: grossLossScenarioId,
      },
      { key: CreditApiParams.TransactionId, value: transactionId },
      { key: CreditApiParams.IsActual, value: String(isActual) },
      {
        key: CreditApiParams.IsSubmission,
        value: String(isSubmission),
      },
    ]
    const queryParams = this.buildQueryParams(kvps)
    return this.getEntity<CashFlowsData>(
      endpoint,
      CreditRequests.CashFlows,
      queryParams
    )
  }
  getAnimatedScenariosData(
    structureId: string | undefined,
    grossLossScenarioIds: string,
    actualGLSIds: string
  ): ApiResponse<AnimatedScenariosResults[]> {
    const params = [
      {
        key: CreditApiParams.GrossLossScenarioIds,
        value: grossLossScenarioIds,
      },
      { key: CreditApiParams.ActualGLSIds, value: actualGLSIds },
    ]
    const queryParams = this.buildQueryParams(params)
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.AnimatedScenarios}`
    return this.getEntityWithMapping<
      AnimatedScenariosResults[],
      AnimatedScenariosResultsData
    >(
      endpoint,
      CreditRequests.AnimatedScenarios,
      convertAnimatedScenariosData,
      queryParams
    )
  }

  getCompareMetricsForStructure(
    structureId: string,
    programId: number,
    portfolioId: string
  ): ApiResponse<CreditCompareMetric[]> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}/${structureId}${CreditApiRoutes.CompareMetrics}`
    const kvps = [
      {
        key: CreditApiParams.ProgramId,
        value: programId,
      },
      { key: CreditApiParams.PortfolioId, value: portfolioId },
    ]
    const queryParams = this.buildQueryParams(kvps)
    return this.getEntity<CreditCompareMetric[]>(
      endpoint,
      CreditRequests.CompareMetrics,
      queryParams
    )
  }

  /* using locktonre-sage-services-standalone -> retrieves from SAGE sql server database */
  getCreditStructureByCalculationStructureId(
    structureId: string
  ): ApiResponse<CreditStructure> {
    const endpoint = `${API.creditStructure}/${structureId}`
    return this.getEntity<CreditStructure>(
      endpoint,
      CreditRequests.SelectedCreditStructure
    ) /* by credit_calculation_structure_id */
  }
  getCreditStructuresByProgramId(
    programId: number
  ): ApiResponse<CreditStructure[]> {
    const endpoint = `${API.creditStructure}`
    const queryParam = this.buildQueryParam(
      CreditApiParams.ProgramId,
      programId
    )
    return this.getEntity<CreditStructure[]>(
      endpoint,
      CreditRequests.CreditStructure,
      queryParam
    )
  }
  postCreditStructure(
    structure: CreditStructure
  ): ApiResponse<CreditStructure> {
    const endpoint = `${API.creditStructure}`
    return this.postEntity<CreditStructure>(
      structure,
      endpoint,
      CreditRequests.CreditStructure
    )
  }
  putCreditStructure(structure: CreditStructure): ApiResponse<CreditStructure> {
    const endpoint = `${API.creditStructure}/${structure.id}`
    return this.putEntity<CreditStructure>(
      structure,
      endpoint,
      CreditRequests.CreditStructure
    )
  }
  getCreditStructureGroupByCalculationStructureId(
    structureId: string
  ): ApiResponse<CreditStructureGroup> {
    const endpoint = `${API.creditStructureGroup}/${structureId}`
    return this.getEntity<CreditStructureGroup>(
      endpoint,
      CreditRequests.SelectedCreditStructureGroup
    ) /* by credit_calculation_structure_id */
  }
  getCreditStructureGroupsByProgramId(
    programId: number
  ): ApiResponse<CreditStructureGroup[]> {
    const endpoint = `${API.creditStructureGroup}`
    const queryParam = this.buildQueryParam(
      CreditApiParams.ProgramId,
      programId
    )
    return this.getEntity<CreditStructureGroup[]>(
      endpoint,
      CreditRequests.CreditStructureGroup,
      queryParam
    )
  }
  postCreditStructureGroup(
    structureGroup: CreditStructureGroup
  ): ApiResponse<CreditStructureGroup> {
    const endpoint = `${API.creditStructureGroup}`
    return this.postEntity<CreditStructureGroup>(
      structureGroup,
      endpoint,
      CreditRequests.CreditStructureGroup
    )
  }
  putCreditStructureGroup(
    structureGroup: CreditStructureGroup
  ): ApiResponse<CreditStructureGroup> {
    const endpoint = `${API.creditStructureGroup}/${structureGroup.id}`
    return this.putEntity<CreditStructureGroup>(
      structureGroup,
      endpoint,
      CreditRequests.CreditStructureGroup
    )
  }
  getCreditStructureGroupMembersByGroupId(
    groupId: number
  ): ApiResponse<CreditStructureGroupMember[]> {
    const endpoint = `${API.creditStructureGroupMember}`
    const queryParam = this.buildQueryParam(CreditApiParams.GroupId, groupId)
    return this.getEntity<CreditStructureGroupMember[]>(
      endpoint,
      CreditRequests.SelectedCreditStructureGroupMember,
      queryParam
    ) /* by credit_structure_group_id'*/
  }
  postCreditStructureGroupMember(
    structureGroupMember: CreditStructureGroupMember
  ): ApiResponse<CreditStructureGroupMember> {
    const endpoint = `${API.creditStructureGroupMember}`
    return this.postEntity<CreditStructureGroupMember>(
      structureGroupMember,
      endpoint,
      CreditRequests.CreditStructureGroupMember
    )
  }
  putCreditStructureGroupMember(
    structureGroupMember: CreditStructureGroupMember
  ): ApiResponse<CreditStructureGroupMember> {
    const endpoint = `${API.creditStructureGroupMember}/${structureGroupMember.id}`
    return this.putEntity<CreditStructureGroupMember>(
      structureGroupMember,
      endpoint,
      CreditRequests.CreditStructureGroupMember
    )
  }
  getCreditMetricSettingsByProgram(
    programId: number,
    portfolioId: string
  ): ApiResponse<CreditProgramMetricSetting[]> {
    const endpoint = `${API.creditMetrics}`
    const kvps = [
      {
        key: CreditApiParams.ProgramId,
        value: programId,
      },
      { key: CreditApiParams.PortfolioId, value: portfolioId },
    ]
    const queryParams = this.buildQueryParams(kvps)
    return this.getEntity<CreditProgramMetricSetting[]>(
      endpoint,
      CreditRequests.Metrics,
      queryParams
    )
  }
  saveCreditMetricSettings(
    programMetricSettings: CreditProgramMetricSetting[]
  ): ApiResponse<CreditProgramMetricSetting[]> {
    const endpoint = `${API.creditMetrics}`
    return this.postEntity<CreditProgramMetricSetting[]>(
      programMetricSettings,
      endpoint,
      CreditRequests.Metrics
    )
  }
  updateCreditMetricSettings(
    programMetricSettings: CreditProgramMetricSetting[]
  ): ApiResponse<CreditProgramMetricSetting[]> {
    const endpoint = `${API.creditMetrics}`
    return this.putEntity<CreditProgramMetricSetting[]>(
      programMetricSettings,
      endpoint,
      CreditRequests.Metrics
    )
  }
  deleteCreditMetricSettingById(
    id: number
  ): ApiResponse<CreditProgramMetricSetting> {
    const endpoint = `${API.creditMetrics}/${id}`
    return this.deleteEntity<CreditProgramMetricSetting>(
      endpoint,
      CreditRequests.Metrics
    )
  }
  // TODO: change any to string (processId)
  postCalculateOptimizedStructure(
    creditStructureOptimizationInput: CreditStructureOptimizationInput
  ): ApiResponse<any> {
    const endpoint = `${API.creditCalculation}${CreditApiRoutes.Structure}${API.creditStructureOptimize}`
    return this.postEntity<CreditStructureOptimizationInput>(
      creditStructureOptimizationInput,
      endpoint,
      CreditRequests.CreditStructureOptimize
    )
  }
  getOptimizationResults(
    processId: string
  ): ApiResponse<CreditOptimizationResult[]> {
    const endpoint = `${API.creditCalculation}${API.creditOptimizer}/${processId}`
    return this.getEntity<CreditOptimizationResult[]>(
      endpoint,
      CreditRequests.CreditOptimizerResults
    )
  }
  getCreditSubmissionStructureBySubmissionStructureId(
    structureId: string
  ): ApiResponse<CreditSubmissionStructure> {
    const endpoint = `${API.creditSubmissionStructure}/${structureId}`
    return this.getEntity<CreditSubmissionStructure>(
      endpoint,
      CreditRequests.SelectedCreditSubmissionStructure
    ) /* by credit_submission_structure_id */
  }
  getCreditSubmissionStructuresByProgramId(
    programId: number
  ): ApiResponse<CreditSubmissionStructure[]> {
    const endpoint = `${API.creditSubmissionStructure}`
    const queryParam = this.buildQueryParam(
      CreditApiParams.ProgramId,
      programId
    )
    return this.getEntity<CreditSubmissionStructure[]>(
      endpoint,
      CreditRequests.CreditSubmissionStructure,
      queryParam
    )
  } /* filters by userId on backend as well */
  getCreditSubmissionStructuresByYears(
    years: string[]
  ): ApiResponse<CreditSubmissionStructureWithProgramData[]> {
    const endpoint = `${API.creditSubmissionStructure}`
    const queryParam = this.buildQueryParam(
      CreditApiParams.Years,
      years.join(',')
    )
    return this.getEntity<CreditSubmissionStructureWithProgramData[]>(
      endpoint,
      CreditRequests.CreditSubmissionStructure,
      queryParam
    )
  } /* filters by userId on backend as well */
  postCreditSubmissionStructure(
    structure: CreditSubmissionStructure
  ): ApiResponse<CreditSubmissionStructure> {
    const endpoint = `${API.creditSubmissionStructure}`
    return this.postEntity<CreditSubmissionStructure>(
      structure,
      endpoint,
      CreditRequests.CreditSubmissionStructure
    )
  }
  putCreditSubmissionStructure(
    structure: CreditSubmissionStructure
  ): ApiResponse<CreditSubmissionStructure> {
    const endpoint = `${API.creditSubmissionStructure}/${structure.id}`
    return this.putEntity<CreditSubmissionStructure>(
      structure,
      endpoint,
      CreditRequests.CreditSubmissionStructure
    )
  }
  getCreditSubmissionResults(
    structureId: string,
    grossLossScenarioId: string,
    isActual: boolean
  ): ApiResponse<CreditResults> {
    const kvps = [
      {
        key: CreditApiParams.GrossLossScenarioId,
        value: grossLossScenarioId,
      },
      { key: CreditApiParams.IsActual, value: String(isActual) },
    ]
    const queryParams = this.buildQueryParams(kvps)
    // TODO: update frontend endpoint layerResults and other camelCase routes to hyphen separated
    const endpoint = `${API.creditSubmissionStructure}/${structureId}/layer-results`
    return this.getEntity<CreditResults>(
      endpoint,
      CreditRequests.DeterministicResults,
      queryParams
    )
  }
  createSubmissionStructure(
    structure: CreditCalculationStructure
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditSubmissionStructure}${CreditApiRoutes.Submission}`
    return this.postEntity<CreditCalculationStructure>(
      structure,
      endpoint,
      CreditRequests.CalculationStructure
    )
  }
  updateSubmissionStructure(
    structure: CreditCalculationStructure
  ): ApiResponse<CreditCalculationStructure> {
    const endpoint = `${API.creditSubmissionStructure}${CreditApiRoutes.Submission}/${structure._id}`
    return this.putEntity<CreditCalculationStructure>(
      structure,
      endpoint,
      CreditRequests.CalculationStructure
    )
  }

  private getEntity<T>(
    endpoint: string,
    requestType: CreditRequests,
    queryParams?: string
  ): ApiResponse<T> {
    const params = queryParams ? `?${queryParams}` : ''
    const url = `${API.base}${endpoint}${params}`
    return this.http
      .get<T>(url)
      .pipe(mapToMaybeData(), catchAndHandleError(`GET ${requestType}`))
  }
  /**
   * @param T type getting mapped to
   * @param U type getting mapped from
   */
  private getEntityWithMapping<T, U>(
    endpoint: string,
    requestType: CreditRequests,
    mapCustom: (data: U) => T,
    queryParams?: string
  ): ApiResponse<T> {
    const params = queryParams ? `?${queryParams}` : ''
    const url = `${API.base}${endpoint}${params}`
    return this.http.get<U>(url).pipe(
      map((data: U) => mapCustom(data)),
      mapToMaybeData(),
      catchAndHandleError(`GET ${requestType}`)
    )
  }
  private postEntity<T>(
    entity: T,
    endpoint: string,
    requestType: CreditRequests
  ): ApiResponse<T> {
    const url = `${API.base}${endpoint}`
    return this.http
      .post<T>(url, entity)
      .pipe(mapToMaybeData(), catchAndHandleError(`POST ${requestType}`))
  }
  private putEntity<T>(
    entity: T,
    endpoint: string,
    requestType: CreditRequests
  ): ApiResponse<T> {
    const url = `${API.base}${endpoint}`
    return this.http
      .put<T>(url, entity)
      .pipe(mapToMaybeData(), catchAndHandleError(`PUT ${requestType}`))
  }
  private deleteEntity<T>(
    endpoint: string,
    requestType: CreditRequests
  ): ApiResponse<T> {
    const url = `${API.base}${endpoint}`
    return this.http
      .delete<T>(url)
      .pipe(mapToMaybeData(), catchAndHandleError(`DELETE ${requestType}`))
  }
  private buildQueryParams(
    kvps: { key: string; value: string | number }[]
  ): string {
    return kvps.map(kvp => this.buildQueryParam(kvp.key, kvp.value)).join('&')
  }
  private buildQueryParam(key: string, value: string | number): string {
    return `${key}=${value}`
  }
}
