<button
  mat-icon-button
  class="menu-icon"
  id="menu"
  (click)="navService.open()"
>
  <mat-icon>menu</mat-icon>
</button>
<a class="logo" routerLink="/home" appLogo></a>
<app-header-title
  class="title"
  [name]="title"
  [subtitle]="getSubtitle()"
  (backClick)="onBackClick()"
></app-header-title>

<section class="actions" *ngIf="name === 'Group'">
  <ng-container *ngIf="!activeAction; else actionRunning">
    <button
      *ngIf="!hideSave"
      appButton
      huge
      accent
      border
      [disabled]="disableSave"
      (click)="onSaveClick($event)"
    >
          <span
            matTooltip="Click to Save all Structure Groups as depicted"
            matTooltipPosition="above"
            [matTooltipDisabled]="disableSave"
          >{{ saveLabel }}</span
          >
    </button>
  </ng-container>
</section>

<ng-template #actionRunning>
  <app-active-action [value]="activeAction"></app-active-action>
</ng-template>
