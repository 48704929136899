import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { CanSize, CanSizeCtor, Size, mixinSize } from '@shared/size.mixin'
import { CreditStructure } from './../../credit/model/credit-structure.model'
import { CreditSubmissionStructure } from './../../credit/model/credit-submission.model'

/*
 * Get a color mixin that TierBarComponent can extend to handle color inputs
 */
class CreditStructureCardBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof CreditStructureCardBase = mixinSize(
  CreditStructureCardBase
)

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-credit-structure-card',
  styleUrls: ['./credit-structure-card.component.scss'],
  templateUrl: './credit-structure-card.component.html',
})
export class CreditStructureCardComponent
  extends _SizeMixinBase
  implements CanSize
{
  @Input() index: number
  @Input() structure: CreditStructure | CreditSubmissionStructure
  @Input() selectMultiple: boolean
  @Input() canCheck: boolean
  @Input() canOptimize = false
  @Input() imageURI: string

  // Size mixin
  @Input() size: Size
  @Input() big: boolean
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }

  @Output() structureClick = new EventEmitter<void>()
  @Output() structureCheckChange = new EventEmitter<void>()
  @Output() optimizeClick = new EventEmitter<void>()
}
