import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { LossSetLayer } from '../../model/loss-set-layers.model'
import { Program } from 'src/app/core/model/program.model'
import { Reinsurer } from 'src/app/core/model/reinsurer.model'
import { AppState } from 'src/app/core/store'
import { SavedPricingCurveEntry } from 'src/app/pricingcurve/model/pricing-curve.model'
import {
  fetchSavedCurves,
  updatePricingCurveContext,
} from 'src/app/pricingcurve/store/pricing-curve.actions'
import { selectSavedCurvesIncludingDefault } from 'src/app/pricingcurve/store/pricing-curve.selectors'
import { selectCurrentStudyReinsurers } from 'src/app/reinsurers/store/reinsurers.selectors'
import { filterTowerLayerList } from '../../model/layers.util'
import {
  selectCededLayers,
  selectCededPortfolioViewLayersViewIDs,
  selectCurrentProgram,
  selectLayersViewMetricsEntitiesByID,
  selectLayerTypeTechnicalPremiumValues,
  selectParentGrossLossSetLayers,
} from '../../store/analysis.selectors'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { LayerMetricsState } from '../../store/metrics/layers-metrics.reducer'
import { LayerTypeDefaultEntry } from '../technical-premium.model'
import {
  initLayerTypeValues,
  resetLayerTypeValues,
} from '../../store/technical-premium/technical-premium.actions'
import { resetAnalysis } from '../../store/analysis.actions'
import { layerIds } from '../../model/layer-palette.model'
import { StudyResponse } from 'src/app/api/model/backend.model'
import { selectCurrentStudyID } from 'src/app/core/store/broker/broker.selectors'
import { selectStudies } from 'src/app/core/store/auth/auth.selectors'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-technical-premium-content-container',
  styleUrls: ['technical-premium-content.container.scss'],
  templateUrl: './technical-premium-content.container.html',
})
export class TechnicalPremiumContentContainerComponent
  implements OnInit, OnDestroy
{
  currentProgram$: Observable<Program | undefined>
  programLayers$: Observable<LayerState[]>
  towerLayers$: Observable<LayerState[]>
  savedCurves$: Observable<SavedPricingCurveEntry[]>
  layerMetrics$: Observable<Record<string, LayerMetricsState>>
  layersViewIds$: Observable<Record<string, string>>
  reinsurersList$: Observable<Reinsurer[] | null>
  lossSetLayers$: Observable<LossSetLayer[]>
  layerTypeEntries$: Observable<LayerTypeDefaultEntry>
  selectedProgramID$: Observable<string | null>
  studies$: Observable<StudyResponse[] | null>

  constructor(private store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.store.dispatch(resetAnalysis())
    this.store.dispatch(resetLayerTypeValues())
  }

  ngOnInit(): void {
    this.store.dispatch(
      updatePricingCurveContext({ context: 'technical-premium' })
    )
    this.store.dispatch(fetchSavedCurves({ useSavedCurveSelectors: false }))
    this.store.dispatch(initLayerTypeValues())
    this.currentProgram$ = this.store.pipe(select(selectCurrentProgram))
    this.savedCurves$ = this.store.pipe(
      select(selectSavedCurvesIncludingDefault)
    )
    this.layerMetrics$ = this.store.pipe(
      select(selectLayersViewMetricsEntitiesByID)
    )
    this.layersViewIds$ = this.store.pipe(
      select(selectCededPortfolioViewLayersViewIDs)
    )
    this.reinsurersList$ = this.store.select(selectCurrentStudyReinsurers)
    this.lossSetLayers$ = this.store.select(selectParentGrossLossSetLayers)
    this.layerTypeEntries$ = this.store.select(
      selectLayerTypeTechnicalPremiumValues
    )
    this.selectedProgramID$ = this.store.select(selectCurrentStudyID)
    this.studies$ = this.store.select(selectStudies)
    this.towerLayers$ = this.store.pipe(
      select(selectCededLayers),
      map((layers: LayerState[]) =>
        layers.filter(
          // Filter out drop layers to avoid confusion, technical premium is on combined t&d layer
          layer => layer.layer.meta_data.sage_layer_type !== layerIds.drop
        )
      ),
      map((layers: LayerState[]) => {
        const sortLayers = layers.filter(filterTowerLayerList)
        sortLayers.sort((a, b) => {
          const aZOrder = a.layer.meta_data.z_order
            ? a.layer.meta_data.z_order
            : 0
          const bZOrder = b.layer.meta_data.z_order
            ? b.layer.meta_data.z_order
            : 0
          return aZOrder > bZOrder ? 1 : -1
        })
        return sortLayers
      })
    )
  }
}
