<app-loading-item
  [item]="'item'"
  [loading]="layerLoading"
  name="Layer Selected"
>
  <label class="label-edit" appInfoText small italic *ngIf="showHelper">
    Please select a layer to view properties and results!
  </label>

  <div class="label-shared-layer">
    <label appInfoText small italic *ngIf="showSharedHelper">
      Click on the layer that belongs to a shared boundary to see the
      properties.
    </label>
  </div>

  <div *ngIf="isEditPage && view">
    <label class="label-edit" appInfoText small italic>
      Saving above will update your results if changes have been made.
    </label>

    <div *ngIf="name" class="layer-title">
      <div *ngIf="programLabel" class="program" [ngClass]="nameClass">
        {{ programLabel }}
      </div>
      <div class="name-wrapper">
        <div class="name" [ngClass]="nameClass">
          <span>{{ name }}</span>
        </div>
        <i
          *ngIf="showEditDeleteButton"
          matTooltip="Delete"
          class="material-icons delete-button"
          (click)="onDeleteClick()"
        >
          delete
        </i>
      </div>
    </div>

    <div>
      <ng-container *ngFor="let def of layerPropertyDefs; trackBy: trackByDef">
        <ng-container *ngIf="!def.unlimitable; else unlimitableTemplate">
          <ng-container *ngTemplateOutlet="layerPropTemplate"></ng-container>
        </ng-container>

        <ng-template #unlimitableTemplate>
          <div class="div-check">
            <div class="check-input">
              <ng-container
                *ngTemplateOutlet="layerPropTemplate"
              ></ng-container>
            </div>
            <mat-checkbox
              matTooltip="Check/ Uncheck for Unlimited"
              matTooltipPosition="above"
              [checked]="getUnlimited(def)"
              (click)="onUnlimitableCheckboxChanged(def)"
            ></mat-checkbox>
          </div>
        </ng-template>

        <ng-template #layerPropTemplate>
          <ng-container [ngSwitch]="def['valueType']">
            <ng-container *ngSwitchCase="'custom'">
              <ng-container *ngTemplateOutlet="customTemplate"></ng-container>
            </ng-container>

            <ng-container *ngIf="isQuotaShare && def.id === 'premium'">
              <div class="div-check">
                <div class="check-input">
                  <app-layer-property
                    [currentCurrency]="view.currency"
                    [name]="def.label"
                    [type]="def['valueType'] || 'text'"
                    [value]="$any(view)[def.id]"
                    [readonly]="!def.editable"
                    [isQuotaShare]="isQuotaShare"
                    (inputChange)="setValue(def, $event)"
                    (premiumValueChange)="setDef(def, $event)"
                  ></app-layer-property>
                </div>
                <mat-checkbox
                  matTooltip="Use Subject Premium"
                  matTooltipPosition="above"
                  [disabled]="!view || view.technicalPremium === null"
                  [checked]="subjectPremiumChecked"
                  (click)="onSubjectPremiumChanged()"
                ></mat-checkbox>
              </div>
            </ng-container>

            <ng-container *ngIf="!isQuotaShare && def.id === 'premium'">
              <div class="div-check">
                <div class="check-input">
                  <app-layer-property
                    *ngIf="
                      (!isNew || !technicalPremiumChecked) &&
                      ($any(view)['technicalPremium'] !== null ||
                        !technicalPremiumChecked)
                    "
                    [currentCurrency]="view.currency"
                    [name]="def.label"
                    [type]="def['valueType'] || 'text'"
                    [value]="$any(view)[def.id]"
                    [readonly]="!def.editable"
                    [isQuotaShare]="isQuotaShare"
                    (inputChange)="setValue(def, $event)"
                    (premiumValueChange)="setDef(def, $event)"
                  ></app-layer-property>
                  <div
                    class="alt-display-text"
                    *ngIf="
                      technicalPremiumChecked &&
                      (isNew || $any(view)['technicalPremium'] === null)
                    "
                  >
                    <label>Premium</label>
                    <span
                      class="empty"
                      *ngIf="isNew && technicalPremiumChecked"
                    >
                      <label class="empty" appInfoText>
                        Value will calculate after save
                      </label>
                    </span>
                    <span
                      class="loading"
                      *ngIf="$any(view)['technicalPremium'] === null"
                      ><label class="empty" appInfoText big>
                        Loading...
                        <mat-spinner
                          [diameter]="20"
                          color="accent"
                        ></mat-spinner></label
                    ></span>
                  </div>
                </div>
                <mat-checkbox
                  matTooltip="Use Technical Premium"
                  matTooltipPosition="above"
                  [disabled]="
                    (!view || view.technicalPremium === null) && !isNew
                  "
                  [checked]="technicalPremiumChecked"
                  (click)="onTechnicalPremiumChanged()"
                ></mat-checkbox>
              </div>
            </ng-container>

            <ng-container *ngIf="isQuotaShare && def.id === 'cedingCommission'">
              <div class="div-check">
                <div class="check-input">
                  <app-percentage-input
                    *ngIf="
                      (!isNew || !cedingCommissionChecked) &&
                      ($any(view)['technicalPremium'] !== null ||
                        !cedingCommissionChecked)
                    "
                    [name]="getLabel(def.label)"
                    type="percentage"
                    [readonly]="!def.editable"
                    [value]="$any(view)[def.id]"
                    [isCession]="true"
                    [decimal]="'1'"
                    (inputChange)="setValue(def, $event)"
                  ></app-percentage-input>
                  <div
                    class="alt-display-text"
                    *ngIf="
                      cedingCommissionChecked &&
                      (isNew || $any(view)['technicalPremium'] === null)
                    "
                  >
                    <label>Ceding Commission</label>
                    <span
                      class="empty"
                      *ngIf="isNew && cedingCommissionChecked"
                    >
                      <label class="empty" appInfoText>
                        Value will calculate after save
                      </label>
                    </span>
                    <span
                      class="loading"
                      *ngIf="$any(view)['technicalPremium'] === null"
                      ><label class="empty" appInfoText big>
                        Loading...
                        <mat-spinner
                          [diameter]="20"
                          color="accent"
                        ></mat-spinner></label
                    ></span>
                  </div>
                </div>
                <mat-checkbox
                  matTooltip="Use Technical Ceding Commission"
                  matTooltipPosition="above"
                  [disabled]="
                    (!view || view.technicalPremium === null) && !isNew
                  "
                  [checked]="cedingCommissionChecked"
                  (click)="onCedingCommissionChanged()"
                ></mat-checkbox>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                def.id !== 'premium' &&
                !(isQuotaShare && def.id === 'cedingCommission')
              "
            >
              <h4
                *ngIf="
                  def.label === 'Reinsurer Expense Provision' &&
                  hasProfitCommission
                "
              >
                <button appButtonIcon (click)="onCollapseClick()">
                  {{ openIcon }}
                </button>
                <span>Profit Commission</span>
              </h4>
              <ng-container *ngSwitchCase="'percentage'">
                <ng-container
                  *ngIf="!(isLibRE && def.id === 'rateOnLineSubject')"
                >
                  <app-percentage-input
                    *ngIf="
                      checkIfOpen(def.label) &&
                      (!def.showUndefinedUnlessTypes ||
                        $any(view)[def.id] !== null)
                    "
                    [name]="getLabel(def.label)"
                    [value]="$any(view)[def.id]"
                    [decimal]="getDecimals(def)"
                    [isCession]="true"
                    [isNegative]="def.label === 'Cession Percentage'"
                    [isROL]="def.label === 'Rate-on-Line, Occurrence'"
                    [readonly]="
                      !def.editable ||
                      (def.id === 'rolPercentage' &&
                        technicalPremiumChecked &&
                        $any(view)['technicalPremium'] === null)
                    "
                    (inputChange)="setValue(def, $event)"
                  ></app-percentage-input>

                  <app-percentage-input
                    *ngIf="
                      def.showUndefinedUnlessTypes &&
                      !def.showUndefinedUnlessTypes.includes(view.type) &&
                      $any(view)[def.id] === undefined
                    "
                    [name]="def.label"
                    [readonly]="true"
                    value="N/A"
                  ></app-percentage-input>
                </ng-container>
              </ng-container>

              <app-layer-property
                *ngSwitchCase="'dropdown'"
                type="dropdown"
                [name]="def.label"
                [value]="$any(view)[def.id]"
                [currentCurrency]="view.currency"
                [references]="getDropdownOptions(def)"
                [isNewLayer]="isNew"
                (inputChange)="onDropdownChange(def, $event)"
              ></app-layer-property>

              <app-layer-property
                *ngSwitchDefault
                [currentCurrency]="view.currency"
                [name]="def.label"
                [type]="def['valueType'] || 'text'"
                [value]="$any(view)[def.id]"
                [readonly]="!def.editable"
                [decimal]="def.decimals || 0"
                (inputChange)="setValue(def, $event)"
              ></app-layer-property>
            </ng-container>
          </ng-container>
        </ng-template>

        <ng-template #customTemplate>
          <ng-container [ngSwitch]="def.id">
            <ng-container *ngIf="!isLibRE && !isDrop">
              <app-premium-input
                *ngSwitchCase="'technicalPremium'"
                [isNew]="isNew"
                [pricingCurves]="pricingCurves"
                [currentProgramDetails]="currentProgramDetails"
                [layerDetails]="layerDetails"
                [isQuotaShare]="isQuotaShare"
                [value]="$any(view)[def.id]"
                [currentCurrency]="view.currency"
                (inputChange)="setValue(def, $event)"
              ></app-premium-input>
            </ng-container>
            <app-top-drop-input
              *ngSwitchCase="'topAndDropProperties'"
              [topLayer]="getTopLayer"
              [dropLayer]="getDropLayer"
              [hiddenLayer]="
                view.type === 'drop' ? topAndDropLayerEdit : topAndDropLayer
              "
              (showTopDropDialog)="showTopDropDialog.emit($event)"
            ></app-top-drop-input>
            <app-multi-section-button
              *ngSwitchCase="'multiSection'"
              [layerState]="layer"
              [isLibRE]="isLibRE"
              (showMultiSectionDialog)="showMultiSectionDialog.emit($event)"
            ></app-multi-section-button>
            <ng-container *ngSwitchCase="'fixedIndexValue'">
              <div class="div-check">
                <div class="check-input">
                  <app-percentage-input
                    [name]="def.label"
                    [value]="getCustomIndex() ? 'custom' : $any(view)[def.id]"
                    [isCession]="true"
                    [isNegative]="false"
                    [decimal]="def['decimals'] || 2"
                    [readonly]="false"
                    (inputChange)="setValue(def, $event)"
                  ></app-percentage-input>
                </div>
                <mat-checkbox
                  #isCustomIndexed
                  matTooltip="Check/Uncheck for Custom Index %s"
                  matTooltipPosition="above"
                  [disabled]="!view"
                  [checked]="getCustomIndex()"
                  (click)="onCustomIndex()"
                >
                </mat-checkbox>
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>

    <div class="currency-color-wrapper">
      <div class="color-palette">
        <mat-icon
          [(colorPicker)]="color"
          [cpAlphaChannel]="'disabled'"
          [cpPosition]="'left'"
          [cpDisableInput]="true"
          (colorPickerChange)="changeColor($event)"
          >palette</mat-icon
        >
      </div>
      <div class="currency-selector">
        <label class="currency-label">Layer Currency</label>
        <mat-form-field appearance="outline" *ngIf="!isLibRE">
          <input
            type="text"
            matInput
            [formControl]="currencyControl"
            [matAutocomplete]="currencyCtrl"
          />
          <mat-autocomplete
            #currencyCtrl="matAutocomplete"
            (optionSelected)="onCurrencyChange($event.option.value)"
            [displayWith]="displayFn"
          >
            <mat-option
              *ngFor="let currency of layerFilteredCurrencyList"
              [value]="currency"
            >
              {{ currency.code }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="isLibRE">
          <input
            type="text"
            matInput
            [formControl]="currencyControl"
            [matAutocomplete]="currencyCtrl"
          />
          <mat-autocomplete
            #currencyCtrl="matAutocomplete"
            (optionSelected)="onCurrencyChange($event.option.value)"
            [displayWith]="displayFn"
          >
            <mat-option
              *ngFor="let currency of layerFilteredCurrencyList"
              [value]="currency"
            >
              {{ currency.code }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div class="copy-layer-section">
      <div>
        <button
          appButton
          accent
          outline
          class="copy-button-container"
          (click)="onControlCPressed()"
        >
          Copy Layer
        </button>
      </div>
      <div
        class="copy-confirmation"
        [ngClass]="{ 'pulsed-short': copyingLayer }"
      >
        Copied
      </div>
    </div>
  </div>

  <div class="copy-layer-section" *ngIf="canPasteLayer || (isEditPage && view)">
    <div>
      <button
        appButton
        accent
        outline
        class="copy-button-container"
        [disabled]="!canPasteLayer"
        (click)="onControlVPressed()"
      >
        Paste Layer
      </button>
    </div>
    <div class="copy-confirmation" [ngClass]="{ pulsed: pastingLayer }">
      Pasting
    </div>
  </div>

  <div *ngIf="isEditPage && view">
    <div class="layer-id">{{ displayLayerID }}</div>
  </div>

  <div *ngIf="isAddSharedLayer || (selectedSharedLayer && isDeleteSharedLayer)">
    <app-layer-property
      name="Layer Name"
      type="text"
      [value]="layerName"
      (inputChange)="onSharedLimitLayerNameChange($event)"
    >
    </app-layer-property>
    <div class="error" *ngIf="!layerName">Layer Name is required.</div>
    <app-layer-property
      [currentCurrency]="selSLCurrency"
      name="Shared Aggregate Limit"
      type="currency"
      [value]="sharedAggLimit"
      (inputChange)="sharedAggLimit = $event; onSharedLimitPropChange()"
    >
    </app-layer-property>
    <div class="error" *ngIf="sharedAggLimit === 0">
      Aggregate limit is required.
    </div>

    <app-layer-property
      [currentCurrency]="selSLCurrency"
      name="Shared Occurrence Limit"
      type="currency"
      [value]="sharedOccLimit"
      (inputChange)="sharedOccLimit = $event; onSharedLimitPropChange()"
    >
    </app-layer-property>
    <div class="error" *ngIf="sharedOccLimit === 0">
      Occurrence limit is required.
    </div>

    <app-layer-property
      [currentCurrency]="selSLCurrency"
      name="Shared Aggregate Ded"
      type="currency"
      [value]="sharedAggDed"
      (inputChange)="sharedAggDed = $event; onSharedLimitPropChange()"
    ></app-layer-property>

    <app-layer-property
      [currentCurrency]="selSLCurrency"
      name="Ceded Premium"
      type="currency"
      [value]="sharedCededPremium"
      (inputChange)="sharedCededPremium = $event; onSharedLimitPropChange()"
    >
    </app-layer-property>

    <app-layer-property
      *ngIf="!isAddSharedLayer"
      [currentCurrency]="selSLCurrency"
      name="Technical Premium"
      type="currency"
      [value]="getTechnicalPremiumSLValue()"
      [readonly]="true"
    >
    </app-layer-property>

    <app-percentage-input
      name="Cession"
      [value]="sharedCession"
      [isCession]="true"
      [isNegative]="false"
      [decimal]="'1'"
      (inputChange)="sharedCession = $event; onSharedLimitPropChange()"
    ></app-percentage-input>
    <app-layer-property
      [currentCurrency]="selSLCurrency"
      name="Reinstatements"
      type="number-list"
      [isReinstatements]="true"
      [value]="sharedReinstatements"
      (inputChange)="sharedReinstatements = $event; onSharedLimitPropChange()"
    ></app-layer-property>

    <div class="currency-selector">
      <label>Shared Limit Structure Currency</label>
      <mat-form-field appearance="outline">
        <input
          type="text"
          matInput
          [formControl]="slCurrencyControl"
          [matAutocomplete]="slCurrencyCtrl"
        />
        <mat-autocomplete
          #slCurrencyCtrl="matAutocomplete"
          (optionSelected)="onSLCurrencyChange($event.option.value)"
          [displayWith]="displayFn"
        >
          <mat-option
            *ngFor="let currency of filteredCurrencyList"
            [value]="currency"
          >
            {{ currency.code }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="selected-view-id">{{ displayLayerID }}</div>
  </div>

  <section class="actions">
    <ng-container *ngIf="!updatingSharedLimit; else updatingSharedLimitAction">
      <button
        *ngIf="showEditButton"
        appButton
        big
        accent
        border
        [disabled]="isDisabled"
        (click)="!isDisabled && onUpdateSharedLimitPropertiesClick()"
      >
        <span
          matTooltip="Click to Update this Shared Limit arrangement"
          matTooltipPosition="above"
          >Update</span
        >
      </button>
    </ng-container>
    <ng-template #updatingSharedLimitAction>
      <app-active-action [value]="'Updating'"></app-active-action>
    </ng-template>
    <ng-container *ngIf="!deletingSharedLimit; else deletingSharedLimitAction">
      <button
        *ngIf="showDeleteButton"
        appButton
        big
        warn
        border
        (click)="onDeleteClick()"
      >
        <span
          matTooltip="Click to Delete this Shared Limit arrangement"
          matTooltipPosition="above"
          >Delete</span
        >
      </button>
      <button
        *ngIf="showCloseButton"
        appButton
        big
        link
        (click)="closeClick.emit()"
      >
        <span>Close</span>
      </button>
    </ng-container>
    <ng-template #deletingSharedLimitAction>
      <app-active-action [value]="'Deleting'"></app-active-action>
    </ng-template>
  </section>
</app-loading-item>
