import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core'
import { CanSize, CanSizeCtor, mixinSize, Size } from '@shared/size.mixin'

/**
 * Get a color mixin that ButtonComponent can extend to handle color inputs
 */
class CardBase {
  constructor(public _elementRef: ElementRef) {}
}
const _SizeMixinBase: CanSizeCtor & typeof CardBase = mixinSize(CardBase)

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line
  selector: `app-card button[appCard], a[appCard]`,
  styles: [
    `
           :host {
             --card-title-size: var(--font-size-big);
             --card-size: var(--font-size-small);
             --card-image-size: 100px;
     
             /* Display anchors like buttons */
             display: inline-block;
             white-space: nowrap;
             text-decoration: none;
             vertical-align: baseline;
             text-align: center;
     
             /* Sizing */
             margin: var(--inset-tiny);
             padding: var(--inset-small) var(--inset-small) 0;
             min-width: 24px;
             line-height: calc(var(--card-size) + 0.75rem);
             overflow: hidden;
     
             /* Reset button styles */
             cursor: pointer;
             outline: none;
             user-select: none;
     
             background: var(--bg-1);
             color: var(--body);
             font-family: var(--font-header-family);
             font-weight: var(--font-link-weight);
             font-size: var(--font-size);
     
             border: 5px solid transparent;
             border-radius: var(--border-radius-big);
           }
     
           :host.indicate-focus,
           :host:focus,
           :host:hover {
             border: 5px solid var(--bg-1-lit);
           }
     
           :host.checked {
             background: var(--bg-accent);
           }
     
           :host-context(.app-elevation-1) {
             background: var(--bg-2);
           }
     
           :host-context(.app-elevation-1).checked {
             background: var(--bg-accent);
           }
     
           :host-context(.app-elevation-1).indicate-focus,
           :host-context(.app-elevation-1):focus,
           :host-context(.app-elevation-1):hover {
             border: 5px solid var(--bg-3-lit);
           }
     
           :host-context(.app-elevation-1).checked.indicate-focus,
           :host-context(.app-elevation-1).checked:focus,
           :host-context(.app-elevation-1).checked:hover {
             border: 5px solid var(--accent);
           }
     
           :host-context(.app-elevation-1):active,
           :host:active {
             border: 5px solid var(--accent-lit);
           }
     
           /* Sizes */
     
           :host.small {
             --card-title-size: var(--font-size);
             --card-size: var(--font-size-tiny);
             border-radius: var(--border-radius);
             --card-image-size: 80px;
           }
     
           :host.big {
             --card-title-size: var(--font-size-huge);
             --card-size: var(--font-size);
             --card-image-size: 150px;
           }
     
           :host.huge {
             --card-title-size: var(--font-size-gigantic);
             --card-size: var(--font-size-big);
             --card-image-size: 200px;
             padding: 1px 15px 4px;
           }
     
           :host.huge:not(:last-of-type) {
             margin-right: var(--inset-big);
           }
     
           :host.gigantic {
             --card-title-size: var(--font-size-massive);
             --card-size: var(--font-size-huge);
             --card-image-size: 250px;
             padding: 7px 15px 11px;
             font-weight: var(--font-header-weight);
           }
     
           @media screen and (max-width: 1240px) {
             :host,
             :host.small {
               --card-title-size: var(--font-size);
               --card-size: var(--font-size-tiny);
               --card-image-size: 80px;
               border-radius: var(--border-radius);
             }
     
             :host.big {
               --card-title-size: var(--font-size-big);
               --card-size: var(--font-size-small);
               --card-image-size: 130px;
             }
     
             :host.huge {
               --card-title-size: var(--font-size-huge);
               --card-size: var(--font-size);
               --card-image-size: 180px;
             }
     
             :host.gigantic {
               --card-title-size: var(--font-size-gigantic);
               --card-size: var(--font-size-big);
               --card-image-size: 250px;
               padding: 1px 15px 4px;
             }
     
             :host.gigantic:not(:last-of-type) {
               margin-right: var(--inset-big);
             }
           }
     
           /* Content wrapper */
     
           .title {
             display: flex;
             align-items: baseline;
           }
     
           label {
             font-weight: var(--font-header-weight);
             white-space: nowrap;
             overflow: hidden;
             text-overflow: ellipsis;
             display: inline-block;
             max-width: 200px;
             margin-top: var(--stack-small);
             cursor: pointer;
             flex: 1;
           }
     
           /* Background */
     
           .image {
             display: block;
             width: var(--card-image-size);
             height: var(--card-image-size);
             border-radius: var(--border-radius-big);
             box-shadow: var(--shadow);
           }
     
           :host:hover .image {
             opacity: 0.8;
           }
     
           /* Highlight the libRE cards */
     
           :host.libRE {
             background: var(--bg-3);
           }
     
           /* Details mode */
     
           :host.details {
             display: flex;
             flex-direction: row;
             margin: 0;
             padding: var(--inset-small);
             width: 100%;
             text-align: left;
           }
     
           :host.details .info {
             width: calc(100% - var(--card-image-size) - var(--inset-small));
             overflow: hidden;
             height: calc(var(--card-image-size) + var(--stack-small));
             box-sizing: border-box;
             display: flex;
             flex-direction: column;
           }
     
           :host.details .image,
           :host.details .icon {
             flex: 0 0 var(--card-image-size);
             width: var(--card-image-size);
             height: var(--card-image-size);
             margin-right: var(--inset-small);
             font-size: var(--card-image-size);
           }
     
           :host.details .title {
             flex: 0 0 auto;
           }
     
           :host.details label {
             font-size: var(--card-title-size);
             white-space: normal;
             display: -webkit-box;
             -webkit-line-clamp: 2;
             -webkit-box-orient: vertical;
             overflow: hidden;
             line-height: 23px;
             margin: var(--stack-tiny) 0;
             max-width: inherit;
           }
     
           :host.big.details label {
             line-height: 29px;
           }
     
           .desc {
             color: var(--subtle);
             font-family: var(--font-family);
             font-weight: var(--font-weight);
             font-size: var(--card-size);
             white-space: normal;
             overflow: auto;
             line-height: 18px;
             flex: 1 1 auto;
           }
     
           :host.big .desc {
             line-height: 20px;
           }
     
           :host.indicate-focus label,
           :host:hover label,
           :host:focus label {
             color: var(--primary-lit);
           }
     
           :host.indicate-focus .desc,
           :host:hover .desc,
           :host:focus .desc {
             color: var(--primary);
           }
     
           :host:active label {
             color: var(--body);
           }
     
           :host:active .desc {
             color: var(--primary-lit);
           }
     
           /* Checkbox */
           .checkbox {
             margin: var(--stack-tiny) var(--inset-small);
             z-index: 99;
           }
     
           /* Title Action Template */
           .title-action {
             z-index: 20;
           }
         `,
  ],
  template: `
    <mat-checkbox
      *ngIf="selectMultiple"
      class="checkbox"
      [checked]="checked"
      [matTooltip]="checkboxTooltip"
      [matTooltipDisabled]="!checkboxTooltip"
      [(ngModel)]="checked"
      (click)="onCheckboxClick($event)"
    ></mat-checkbox>

    <ng-container *ngIf="!libRETemplate">
      <img *ngIf="hasImage" class="image" [src]="imageSrc" />
      <app-placeholder-image
        *ngIf="!hasImage"
        class="image"
        [index]="index"
      ></app-placeholder-image>
    </ng-container>

    <ng-container *ngIf="libRETemplate">
      <mat-icon color="primary" class="icon">add_box</mat-icon>
    </ng-container>

    <div class="info">
      <div class="title">
        <label>
          <ng-content></ng-content>
        </label>

        <div *ngIf="titleActionTemplate" class="title-action">
          <ng-template
            *ngIf="titleActionTemplate"
            [ngTemplateOutlet]="titleActionTemplate"
          ></ng-template>
        </div>
      </div>

      <div
        *ngIf="details && description"
        class="desc app-markdown"
        [innerHTML]="description | appMarkdown"
      ></div>
    </div>
  `,
})
export class CardComponent extends _SizeMixinBase implements CanSize {
  @Input() description?: string
  @Input() index: number
  @Input() imageSrc?: string
  @Input() canCheck: boolean
  @Input() checkboxTooltip = ''
  // The libRE input property sets a css class to changes the background color from the default
  @Input() @HostBinding('class.libRE') libRE: boolean
  // The libRETemplate property causes the template replace the thumbnail image with a plus icon and
  // replace the ... menu with a building icon.
  @Input() libRETemplate: boolean

  @HostBinding('class.no-image') get hasImage() {
    return this.imageSrc != null
  }

  // Layout mode
  @Input() set details(value: any) {
    this._details = coerceBooleanProperty(value)
  }
  get details() {
    return this._details
  }
  @HostBinding('class.details') _details = false

  // Checked
  @Input() set checked(value: any) {
    this._checked = coerceBooleanProperty(value)
  }
  get checked() {
    return this._checked
  }
  @HostBinding('class.checked') _checked = false

  // Multiple selection
  @Input() set selectMultiple(value: any) {
    this._selectMultiple = coerceBooleanProperty(value)
  }
  get selectMultiple() {
    return this._selectMultiple
  }
  _selectMultiple = false

  // Focus
  @Input() set indicateFocus(value: any) {
    this._indicateFocus = coerceBooleanProperty(value)
  }
  get indicateFocus() {
    return this._indicateFocus
  }
  @HostBinding('class.indicate-focus') _indicateFocus = false

  // Size mixin
  @Input() size: Size
  @Input() gigantic: boolean
  @Input() huge: boolean
  @Input() big: boolean
  @Input() small: boolean
  @HostBinding('class.gigantic')
  get isSizeGigantic() {
    return this.size === 'gigantic'
  }
  @HostBinding('class.huge')
  get isSizeHuge() {
    return this.size === 'huge'
  }
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }
  @HostBinding('class.small')
  get isSizeSmall() {
    return this.size === 'small'
  }

  @Output() handleCheck = new EventEmitter()

  @ContentChild('titleActionTemplate') titleActionTemplate: TemplateRef<any>

  constructor(public elementRef: ElementRef) {
    super(elementRef)

    if (this.libRETemplate) {
      this.libRE = true
    }
  }

  onCheckboxClick(event: Event): void {
    if (this.canCheck) {
      event.preventDefault()
      event.stopPropagation()
      this.handleCheck.emit()
    }
  }
}
