import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { HasMetadata } from 'src/app/analysis/layers/indexed-layer'
import { letterFromDescription } from 'src/app/analysis/layers/multi-section-layer'
import { Layer } from 'src/app/analysis/model/layers.model'
import {
  findLayer,
  findLayerByVisibleId,
} from 'src/app/analysis/model/layers.util'
import { LossSetLayer } from 'src/app/api/analyzere/analyzere.model'
import { HasId } from './../../../layers/indexed-layer'

export interface Section {
  letter: string

  occLimit: number
  occUnlimited: boolean
  occAttach: number
  aggLimit: number
  aggUnlimited: boolean
  aggAttach: number
  participation: number
  narrative: string

  lossSets: string[]
  inuringSections: string[]
  currency: string
}

export interface SectionLossSet {
  id: string
  label: string
}

@Injectable({
  providedIn: 'root',
})
export class MultiSectionService {
  private visibleLayer: Layer
  private layers: Layer[]
  private parentGrossLossSetLayers: LossSetLayer[]

  private sectionsSubject = new BehaviorSubject<Section[]>([])
  readonly sections$ = this.sectionsSubject.asObservable()

  get currentSections() {
    return this.sectionsSubject.getValue()
  }

  initialize(
    visibleLayer: Layer,
    layers: Layer[],
    parentGrossLossSetLayers: LossSetLayer[]
  ) {
    this.visibleLayer = visibleLayer
    this.layers = layers
    this.parentGrossLossSetLayers = parentGrossLossSetLayers

    const mainLayer = findLayerByVisibleId(this.layers, this.visibleLayer.id)
    if (mainLayer === undefined) {
      throw Error('Unable to find the main layer.')
    }

    const sectionLayers = mainLayer.layerRefs.map(id => {
      const layer = findLayer(this.layers, id)
      if (layer === undefined) {
        throw Error('Unable to find section layer.')
      }
      return layer
    })

    const sections = sectionLayers.map(layer =>
      this.existingSection(layer, sectionLayers)
    )

    this.sectionsSubject.next(sections)
  }

  getAllLossSets(): SectionLossSet[] {
    return this.parentGrossLossSetLayers
      .map(this.asSectionLossSet)
      .sort(this.labelOrder)
  }

  getCededLossSets(): SectionLossSet[] {
    if (!this.visibleLayer.lossSetLayers) {
      return []
    }

    // We will need to sort the loss sets by label since they are not necessarily sorted
    return this.visibleLayer.lossSetLayers
      .map(this.asSectionLossSet)
      .sort(this.labelOrder)
  }

  asSectionLossSet<T extends HasId & HasMetadata>({
    id,
    meta_data,
  }: T): SectionLossSet {
    return {
      id,
      label: `${meta_data.ls_dim1} - ${meta_data.ls_dim2}`,
    }
  }

  labelOrder<T extends { label: string }>(x: T, y: T) {
    return x.label < y.label ? -1 : x.label > y.label ? 1 : 0
  }

  addSection() {
    const currentSections = this.sectionsSubject.getValue()

    // There will be at least one section because we don't allow deleting all of them
    const [lastSection] = currentSections.slice(-1)

    // Get the next letter after the last section
    const nextSectionLetter = nextLetter(lastSection.letter)

    this.sectionsSubject.next([
      ...currentSections,
      this.newSection(nextSectionLetter),
    ])
  }

  removeSection(sectionToRemove: Section) {
    const currentSections = this.sectionsSubject.getValue()
    this.sectionsSubject.next(
      currentSections.filter(
        section => section.letter !== sectionToRemove.letter
      )
    )
  }

  private newSection(letter: string): Section {
    const physicalLayer = this.visibleLayer.physicalLayer

    return {
      letter,
      occLimit: physicalLayer.limit.value,
      occUnlimited: physicalLayer.limit.value >= 1e21,
      occAttach: physicalLayer.attachment.value,
      aggLimit: physicalLayer.aggregateLimit.value,
      aggUnlimited: physicalLayer.aggregateLimit.value >= 1e21,
      aggAttach: physicalLayer.aggregateAttachment.value,
      participation: 1.0,
      lossSets: this.getCededLossSets().map(sls => sls.id),
      inuringSections: [],
      currency: physicalLayer.limit.currency,
      narrative: '',
    }
  }

  private existingSection(
    sectionLayer: Layer,
    sectionLayers: Layer[]
  ): Section {
    const letter = letterFromDescription(sectionLayer.physicalLayer.description)
    if (letter === null) {
      throw Error(
        'The section layer does not have a description in the proper form.'
      )
    }

    const lossSets = sectionLayer.lossSetLayers.map(layer => layer.id)
    let inuringSections: string[] = []

    if (sectionLayer.layerRefs.length === 1) {
      const flipperId = sectionLayer.layerRefs[0]
      const flipperLayer = findLayer(this.layers, flipperId)
      if (!flipperLayer) {
        throw Error(`Flipper layer ${flipperId} cannot be found.`)
      }

      inuringSections = flipperLayer.layerRefs.flatMap(id => {
        const layer = findLayer(sectionLayers, id)
        if (!layer) {
          console.warn(
            `Cannot find Section ref ${id} in flipper layer ${flipperId}.`
          )
          return []
        }

        const inuringLetter = letterFromDescription(
          layer.physicalLayer.description
        )
        if (!inuringLetter) {
          throw Error(`Unable to get the letter for layer ${layer.id}.`)
        }

        return inuringLetter
      })
    }

    return {
      letter,
      occLimit: sectionLayer.physicalLayer.limit.value,
      occUnlimited: sectionLayer.physicalLayer.limit.value >= 1e21,
      occAttach: sectionLayer.physicalLayer.attachment.value,
      aggLimit: sectionLayer.physicalLayer.aggregateLimit.value,
      aggUnlimited: sectionLayer.physicalLayer.aggregateLimit.value >= 1e21,
      aggAttach: sectionLayer.physicalLayer.aggregateAttachment.value,
      participation: sectionLayer.physicalLayer.participation,
      lossSets,
      inuringSections,
      currency: sectionLayer.physicalLayer.limit.currency,
      narrative: sectionLayer.meta_data.layer_narrative || '',
    }
  }

  checkValid(): boolean {
    return this.currentSections.every(
      section => section.lossSets.length + section.inuringSections.length > 0
    )
  }
}

// Given 'A' return 'B', 'E' return 'F', and 'Z' return 'a'
function nextLetter(c: string): string {
  // Run through A-Z, then a-z
  return c === 'Z' ? 'a' : String.fromCharCode(c.charCodeAt(0) + 1)
}
