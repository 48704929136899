
import { SortTableColumnDef } from "@shared/sort-table/sort-table.model"
import { Metadata, Metrics } from "src/app/api/analyzere/analyzere.model"
import { LossSetLayer } from "../model/loss-set-layers.model"

export interface MappingOption {
  label: string
  value: keyof Metadata
}

export interface ExploreFilterMap {
   [key: string]: boolean
}

export interface SummaryArrayDatum {
  data: Metrics[]
  lossType: string
  lossName: string
  rpArray: number[]
  lossFilter: string
  aggregationMethod: string
}

export interface SummaryDataResponse {
  largeRiskDistributionOEP: SummaryArrayDatum
  largeRiskDistributionAEP: SummaryArrayDatum
  uwResultVaR: SummaryArrayDatum
  expense: number
  subjectPremiumAmt: number
  netLoss: number
  expectedUWResult: number
  lossSetID: string
  contributionToGroupVolatility?: number
  contributionToGroupVolatilityAttr?: number,
  contributionToGroupVolatilityLarge?: number,
  contributionToGroupVolatilityCat?: number,
}

export interface GroupSummaryRequest {
  name: string
  ids: string[]
  layers?: LossSetLayer[]
}

export interface ExploreSummaryDatum {
  id: string
  name: string
  groupBy: string
  lossScaleFactor: number | string
  premiumScaleFactor: number | string
  loss_type: string
  ls_dim1: string
  ls_dim2: string
  map1: string
  map2: string
  map3: string
  map4: string
  map5: string
  premium: number | string
  expense: number | string
  lossByType_Attritional: number | string
  lossByType_Large: number | string
  lossByType_Cat: number | string
  expectedLoss: number | string
  expectedUWResult: number | string
  expenseRatio: number | string
  lossRatioByType_Attritional: number | string
  lossRatioByType_Large: number | string
  lossRatioByType_Cat: number | string
  expectedLossRatio: number | string
  expectedCombinedRatio: number | string
  lossRatioCV_Attritional: number | string
  lossRatioCV_Large: number | string
  lossRatioCV_Cat: number | string
  totalLossRatioCV: number | string
  largeRisk_Frequency: number | string
  largeRisk_Severity: number | string
  largeRiskDistributionOEP_10: number | string
  largeRiskDistributionOEP_25: number | string
  largeRiskDistributionOEP_50: number | string
  largeRiskDistributionOEP_100: number | string
  largeRiskDistributionOEP_200: number | string
  largeRiskDistributionOEP_250: number | string
  largeRiskDistributionAEP_10: number | string
  largeRiskDistributionAEP_25: number | string
  largeRiskDistributionAEP_50: number | string
  largeRiskDistributionAEP_100: number | string
  largeRiskDistributionAEP_200: number | string
  largeRiskDistributionAEP_250: number | string
  uwResultVaR_10: number | string
  uwResultVaR_25: number | string
  uwResultVaR_50: number | string
  uwResultVaR_100: number | string
  uwResultVaR_200: number | string
  uwResultVaR_250: number | string
  combinedRatiosVaR_10: number | string
  combinedRatiosVaR_25: number | string
  combinedRatiosVaR_50: number | string
  combinedRatiosVaR_100: number | string
  combinedRatiosVaR_200: number | string
  combinedRatiosVaR_250: number | string
  contributionToGroupVolatility_Attritional?: number | string
  contributionToGroupVolatility_Large?: number | string
  contributionToGroupVolatility_Cat?: number | string
  totalContributionToGroupVolatility?: number | string
}

export interface ExploreSummaryHeader {
  id: string
  label: string
  width: string
  hideBorder?: boolean
  hideLabel?: boolean
}

export const EXPLORE_SUMMARY_HEADERS: ExploreSummaryHeader[] = [
  {
    id: 'name',
    label: '',
    width: '262',
    hideBorder: true
  },
  {
    id: 'groupBy',
    label: '',
    width: '422',
    hideBorder: true,
  },
  {
    id: 'premium',
    label: 'Premium',
    width: '90',
    hideBorder: true,
    hideLabel: true,
  },
  {
    id: 'expense',
    label: 'Expense',
    width: '90',
    hideBorder: true,
    hideLabel: true,
  },
  {
    id: 'lossByType',
    label: 'Expected Loss By Type',
    width: '270',
  },
  {
    id: 'expectedLoss',
    label: 'Total Expected Loss',
    width: '90',
    hideBorder: true,
    hideLabel: true
  },
  {
    id: 'expectedUWResult',
    label: 'Expected UW Result',
    width: '90',
    hideBorder: true,
    hideLabel: true,
  },
  {
    id: 'expenseRatio',
    label: 'Expense Ratio',
    width: '90',
    hideBorder: true,
    hideLabel: true
  },
  {
    id: 'lossRatioByType',
    label: 'Expected Loss Ratio By Type',
    width: '270'
  },
  {
    id: 'expectedLossRatio',
    label: 'Expected Total Loss Ratio',
    width: '90',
    hideBorder: true,
    hideLabel: true
  },
  {
    id: 'expectedCombinedRatio',
    label: 'Expected Combined Ratio',
    width: '90',
    hideBorder: true,
    hideLabel: true
  },
  {
    id: 'lossRatioCV',
    label: 'Loss Ratio CV By Type',
    width: '270'
  },
  {
    id: 'totalLossRatioCV',
    label: 'Total Loss Ratio CV',
    width: '90',
    hideBorder: true,
    hideLabel: true
  },
  // {
  //   id: 'largeRisk',
  //   label: 'Large Risk',
  //   width: '180'
  // },
  {
    id: 'largeRiskDistributionOEP',
    label: 'Large Risk Distribution (OEP)',
    width: '540'
  },
  {
    id: 'largeRiskDistributionAEP',
    label: 'Large Risk Distribution (AEP)',
    width: '540'
  },
  {
    id: 'uwResultVaR',
    label: 'UW Result - VaR',
    width: '540'
  },
  {
    id: 'combinedRatiosVaR',
    label: 'Combined Ratios - VaR',
    width: '540'
  },
  {
    id: 'contributionToGroupVolatility',
    label: 'Contribution To Group Volatility',
    width: '270'
  },
  {
    id: 'totalContributionToGroupVolatility',
    label: 'Total Contribution To Group Volatility',
    width: '90',
    hideBorder: true,
    hideLabel: true
  },
]


export const exploreSummaryColumnDefs: SortTableColumnDef[] = [
  {
    id: 'name',
    label: 'Loss Set Name',
    valueType: 'text',
    minWidth: '15rem',
    headerStyle: {
      paddingLeft: '12px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'groupBy',
    label: 'Group By',
    valueType: 'text',
    minWidth: '25rem',
    bodyStyle: {
      backgroundColor: '#00aeef',
      color: '#FFFFFF',
      paddingLeft: '4px'
    },
    headerStyle: {
      backgroundColor: '#00aeef',
      color: '#FFFFFF',
      paddingLeft: '4px',
      paddingRight: '4px',
      borderBottom: '1px solid #FFFFFF'
    }
  },
  {
    id: 'premium',
    label: 'Premium',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'expense',
    label: 'Expense',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'lossByType_Attritional',
    label: 'Attritional',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'lossByType_Large',
    label: 'Large',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'lossByType_Cat',
    label: 'Cat',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'expectedLoss',
    label: 'Total Expected Loss',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'expectedUWResult',
    label: 'Expected UW Result',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'expenseRatio',
    label: 'Expense Ratio',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'lossRatioByType_Attritional',
    label: 'Attritional',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'lossRatioByType_Large',
    label: 'Large',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'lossRatioByType_Cat',
    label: 'Cat',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'expectedLossRatio',
    label: 'Total Expected Loss Ratio',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'expectedCombinedRatio',
    label: 'Expected Combined Ratio',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'lossRatioCV_Attritional',
    label: 'Attritional',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'lossRatioCV_Large',
    label: 'Large',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'lossRatioCV_Cat',
    label: 'Cat',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'totalLossRatioCV',
    label: 'Total Loss Ratio CV',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '8px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  // {
  //   id: 'largeRisk_Frequency',
  //   label: 'Frequency',
  //   width: '90px',
  //   minWidth: '90px',
  //   maxWidth: '90px',
  //   headerStyle: {
  //     paddingLeft: '0px',
  //     paddingRight: '4px',
  //     backgroundColor: 'var(--bg-lit)',
  //     color: '#ffffff',
  //     borderBottom: '1px solid var(--subtle)',
  //     top: '24px'
  //   }
  // },
  // {
  //   id: 'largeRisk_Severity',
  //   label: 'Severity',
  //   width: '90px',
  //   minWidth: '90px',
  //   maxWidth: '90px',
  //   headerStyle: {
  //     paddingLeft: '0px',
  //     paddingRight: '4px',
  //     backgroundColor: 'var(--bg-lit)',
  //     color: '#ffffff',
  //     borderBottom: '1px solid var(--subtle)',
  //     top: '24px'
  //   }
  // },
  {
    id: 'largeRiskDistributionOEP_10',
    label: '10',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'largeRiskDistributionOEP_25',
    label: '25',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'largeRiskDistributionOEP_50',
    label: '50',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'largeRiskDistributionOEP_100',
    label: '100',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'largeRiskDistributionOEP_200',
    label: '200',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'largeRiskDistributionOEP_250',
    label: '250',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'largeRiskDistributionAEP_10',
    label: '10',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'largeRiskDistributionAEP_25',
    label: '25',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'largeRiskDistributionAEP_50',
    label: '50',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'largeRiskDistributionAEP_100',
    label: '100',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'largeRiskDistributionAEP_200',
    label: '200',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'largeRiskDistributionAEP_250',
    label: '250',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'uwResultVaR_10',
    label: '10',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'uwResultVaR_25',
    label: '25',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'uwResultVaR_50',
    label: '50',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'uwResultVaR_100',
    label: '100',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'uwResultVaR_200',
    label: '200',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'uwResultVaR_250',
    label: '250',
    width: '90px',
    maxWidth: '90px',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'combinedRatiosVaR_10',
    label: '10',
    width: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'combinedRatiosVaR_25',
    label: '25',
    width: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'combinedRatiosVaR_50',
    label: '50',
    width: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'combinedRatiosVaR_100',
    label: '100',
    width: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'combinedRatiosVaR_200',
    label: '200',
    width: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'combinedRatiosVaR_250',
    label: '250',
    width: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'contributionToGroupVolatility_Attritional',
    label: 'Attritional',
    width: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'contributionToGroupVolatility_Large',
    label: 'Large',
    width: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'contributionToGroupVolatility_Cat',
    label: 'Cat',
    width: '90px',
    maxWidth: '90px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '4px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px'
    }
  },
  {
    id: 'totalContributionToGroupVolatility',
    label: 'Total Contribution to Group Volatility',
    width: '99px',
    maxWidth: '99px',
    valueType: 'percentage',
    headerStyle: {
      paddingLeft: '0px',
      paddingRight: '0px',
      backgroundColor: 'var(--bg-lit)',
      color: '#ffffff',
      borderBottom: '1px solid var(--subtle)',
      top: '24px',
      overflow: 'visible'
    }
  },

]
