<ng-container [ngSwitch]="compareMetricValue.valueType">
  <app-action-text [size]="size" *ngSwitchCase="'percentage'">
    {{ compareMetricValue.value | percent: formatter }}
  </app-action-text>
  <app-action-text [size]="size" *ngSwitchCase="'ratio'">
    <ng-container *ngIf="compareMetricValue.value === 0">
      0.0%
    </ng-container>
    <ng-container
      *ngIf="compareMetricValue.value && compareMetricValue.value !== 0"
    >
      {{ compareMetricValue.value | percent: formatter }} /
      <ng-container
        *ngIf="100 * (1 / (compareMetricValue.value! * 100)) >= 1000"
        >{{
          100 * (1 / (compareMetricValue.value! * 100))
            | appShortNumber: undefined:true:false:precision
        }}</ng-container
      >
      <ng-container
        *ngIf="100 * (1 / (compareMetricValue.value! * 100)) < 1000"
        >{{
          100 * (1 / (compareMetricValue.value! * 100)) | number: formatter
        }}</ng-container
      >
    </ng-container>
  </app-action-text>
  <app-action-text [size]="size" *ngSwitchDefault>
    <ng-container *ngIf="!isEfficiencyMetric">
      {{
        compareMetricValue.value
          | currencyConversion
            : compareMetricValue.currencyCode
            : true
            : false
            : precision
            : compareMetricValue.conversion
      }}
    </ng-container>
    <ng-container
      *ngIf="
        isEfficiencyMetric &&
        (compareMetricValue.value || compareMetricValue.value === 0)
      "
    >
      {{
        compareMetricValue.value
          | currency: compareMetricValue.currencyCode:'symbol':formatter
      }}
    </ng-container>
  </app-action-text>
</ng-container>
<app-rank
  *ngIf="
    (compareMetricValue.rank &&
      !isMiscMetric &&
      compareMetricValue.value) ||
    (compareMetricValue.value === 0 && !isMiscMetric)
  "
  [rank]="compareMetricValue.rank"
  [max]="rankMax"
  [size]="size"
  [ngStyle]="{
    visibility: isRankHidden(compareMetricValue) ? 'hidden' : 'visible'
  }"
></app-rank>
