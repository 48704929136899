import { Action, createReducer, on } from '@ngrx/store'
import {
  DEFAULT_INCEPTION_DATE,
  DEFAULT_TRACKING_SELECTORS,
  TrackingData,
  TrackingSelectors,
} from '../tracking.model'
import * as fromTrackingActions from './tracking.actions'
import { IControl } from 'src/app/management-information/store/management-information.reducer'
import { getSelectors } from '../tracking.utils'
import { BasicControl } from 'src/app/management-information/model/management-information.model'

export const TRACKING_KEY = 'tracking'

export interface TrackingState {
  trackingData: TrackingData[]
  selectors: TrackingSelectors
  inceptionTimeInterval: BasicControl
  isLoading: boolean
  isDetailsLoading: boolean
}

const initialState: TrackingState = {
  trackingData: [],
  selectors: DEFAULT_TRACKING_SELECTORS,
  inceptionTimeInterval: DEFAULT_INCEPTION_DATE,
  isLoading: false,
  isDetailsLoading: false
}

const trackingReducer = createReducer(
  initialState,
  on(fromTrackingActions.fetchTracking, state => {
    return {
      ...state,
      isLoading: true,
    }
  }),
  on(
    fromTrackingActions.fetchTrackingSuccess,
    (state: TrackingState, { trackingData }) => {
      return {
        ...state,
        trackingData,
        selectors: getSelectors(state.selectors, trackingData),
        isLoading: false,
      }
    }
  ),
  on(fromTrackingActions.fetchTrackingFailure, state => {
    return {
      ...state,
      isLoading: false,
    }
  }),
  on(
    fromTrackingActions.filtersChanged,
    (state: TrackingState, { filters }) => {
      if (!filters) {
        return state
      }
      const newSelectors: TrackingSelectors = { ...state.selectors }
      const entries: [string, IControl][] = Object.entries(state.selectors)
      for (const [selectorKey, selector] of entries) {
        newSelectors[selectorKey as keyof TrackingSelectors] = {
          ...selector,
          selectedValues: filters[selectorKey] ?? [],
        }
      }
      return { ...state, selectors: newSelectors }
    }
  ),
  on(fromTrackingActions.inceptionDateChanged, (state: TrackingState, {minInceptionDate, maxInceptionDate}) => {
    return {
      ...state,
      inceptionTimeInterval: {
        ...state.inceptionTimeInterval,
        minValue: minInceptionDate,
        maxValue: maxInceptionDate,
      },
    }
  }),
  on(fromTrackingActions.fetchTrackingDetails, state => {
    return {
      ...state,
      isDetailsLoading: true,
    }
  }),
  on(
    fromTrackingActions.fetchTrackingDetailsSuccess,
    (state: TrackingState, { trackingDetailsData }) => {
      return {
        ...state,
        trackingData: state.trackingData.map(existingData =>
          trackingDetailsData &&
          trackingDetailsData[0] &&
          existingData.programId === trackingDetailsData[0].programId
            ? { ...existingData, details: trackingDetailsData }
            : existingData
        ),
        isDetailsLoading: false,
      }
    }
  ),
  on(fromTrackingActions.fetchTrackingDetailsFailure, state => {
    return {
      ...state,
      isDetailsLoading: false,
    }
  })
)

export function reducer(
  state: TrackingState | undefined,
  action: Action
): TrackingState {
  return trackingReducer(state, action)
}

