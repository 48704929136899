<div class="gross-summary">
  <h1 mat-dialog-title class="inline">Gross Loss Summary</h1>
  <div class="table">
    <div class="headers">
      <div
        *ngFor="let header of headers"
        [style.min-width.px]="header.width - 16"
        [ngClass]="{'hide-border': header.hideBorder}"
        class="header-label"
      >
        <span *ngIf="!header.hideLabel">
          {{ header.label }}
        </span>
      </div>
    </div>
    <div class="sort-table-container">
      <app-sort-table
        [rows]="updatedDataRows"
        [columnDefs]="updatedColumnDefs"
        [useZeroText]="true"
      >
      </app-sort-table>
    </div>
  </div>
</div>
