import { Injectable } from '@angular/core'
import { MatSidenav } from '@angular/material/sidenav'
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationExtras,
  Router,
} from '@angular/router'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { concatMap, map, withLatestFrom } from 'rxjs/operators'
import { PortfolioSetAndStudyIDs } from './analysis/model/portfolio-set.model'
import { AppState } from './core/store'
import {
  setCurrentAnalysisProfile,
  setCurrentClient,
  setCurrentStructure,
  setCurrentTierPath,
} from './core/store/broker/broker.actions'
import { selectTierPath } from './core/store/broker/broker.selectors'
import { TierPath } from './tier/tier.model'
import {
  areTierPathsEqual,
  buildTierRouteCommands,
  createTierPath,
  isTierPathEmpty,
} from './tier/tier.util'
import { selectClients } from './core/store/clients.selectors'
import { fetchOrSaveQuoteData } from './quote/store/quote.actions'

@Injectable()
export class NavService {
  _sidenav: MatSidenav | undefined
  currentUrl = new BehaviorSubject<string>('')

  constructor(private router: Router, private store: Store<AppState>) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects)
      }
    })
  }

  register(sidenav: MatSidenav): void {
    this._sidenav = sidenav
  }

  close() {
    if (!this._sidenav) {
      return console.error('Cannot close sidenav, not registered.')
    }
    this._sidenav.close()
  }

  open() {
    if (!this._sidenav) {
      return console.error('Cannot close sidenav, not registered.')
    }
    this._sidenav.open()
  }
  secondBar: boolean

  setData(newData: boolean) {
    this.secondBar = newData
  }
  handleTierRoute(route: ActivatedRoute): Observable<void> {
    return route.paramMap.pipe(
      map(params => createTierPath(params)),
      concatMap(path =>
        of(path).pipe(
          withLatestFrom(
            this.store.pipe(select(selectTierPath)),
            this.store.pipe(select(selectClients)),
            route.data
          )
        )
      ),
      map(([path, prevPath, clients, data]) => {
        const contextPath: string[] =
          data && Array.isArray(data.contextPath) ? data.contextPath : []
        if (data && data.clientSelect) {
          // Handle special case where we are on the client select page
          if (path.client) {
            // User selected the client
            this.store.dispatch(setCurrentClient({ id: path.client }))
          } else if (clients.length === 1) {
            // Auto-select the client if there is only one
            const clientPath = createTierPath(clients[0].id)
            this._navigateWithTierPath(clientPath, ['select'], {
              replaceUrl: true,
            })
          }
          return
        }

        if (isTierPathEmpty(path)) {
          if (!isTierPathEmpty(prevPath)) {
            this._navigateWithTierPath(prevPath, contextPath, {
              replaceUrl: true,
            })
          } else if (clients.length === 1) {
            // Auto-select the client if there is only one
            const clientPath = createTierPath(clients[0].id)
            this._navigateWithTierPath(clientPath, contextPath, {
              replaceUrl: true,
            })
          }
          if (contextPath.includes('quote') && contextPath.includes('main')) {
            if (!!prevPath.structure) {
              this.store.dispatch(
                setCurrentStructure({ id: prevPath.structure })
              )
              this.store.dispatch(
                fetchOrSaveQuoteData({ structureID: prevPath.structure })
              )
            }
          }
          return
        }

        if (!areTierPathsEqual(path, prevPath, contextPath)) {
          if (prevPath.client != null && prevPath.client !== path.client) {
            // If client changes, `setCurrentClient` will cause the analysis
            // state to be reset, thus clearing any existing group/compare state
            this.store.dispatch(setCurrentClient({ id: path.client }))
          } else {
            this.store.dispatch(setCurrentTierPath(path))
            // If selecting new program, fetch AnalysisProfile from AnalyzeRe
            if (path.program != null && path.program !== prevPath.program) {
              this.store.dispatch(setCurrentAnalysisProfile({ id: undefined }))
            }
          }
        }
      })
    )
  }

  navigateWithTierPath(
    path: TierPath | PortfolioSetAndStudyIDs,
    ...contextPath: string[]
  ): void {
    this._navigateWithTierPath(path, contextPath)
  }

  private _navigateWithTierPath(
    path: TierPath | PortfolioSetAndStudyIDs,
    contextPath: string[],
    extras?: NavigationExtras
  ): void {
    this.router.navigate(buildTierRouteCommands(path, ...contextPath), extras)
  }
}
