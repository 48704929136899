import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { ApiResponse } from '../model/api.model'
import { catchAndHandleError, encodeParams, mapToMaybeData } from '../util'
import { RiskRefDetail } from '../model/signature.model'
import {
  OTRenewalDetailsResponse,
  WhiteSpaceCloneResponse,
  ExternalVendorMapping,
  WhiteSpaceRes,
} from '../model/sliptemplates.model'
import { OTRenewalDetails, SearchApiFormat, SearchResultFormat } from '../../slip-templates/slip-templates.model'
import {
  convertRenewalDetailsResponse,
  convertRenewalDetailsPostWS,
} from './slip-templates.convertor'
import { map, switchMap } from 'rxjs/operators'
import { of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SlipTemplatesService {
  url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.internalApi.base}${environment.internalApi.slipTemplates}`
  }

  getAllTemplates(): ApiResponse<string[]> {
    const url = `${this.url}`
    return this.http
      .get<string[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Get All Programs'))
  }
  getTemplate(template: string | null): ApiResponse<string> {
    const url = `${this.url}/${template}`
    return this.http
      .get<string>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Get All Programs'))
  }
  /* Risk Renewals API */
  getAllRenewalRiskRefs(
    programId: string | null,
    renewedFrom: boolean
  ): ApiResponse<RiskRefDetail[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.signature}/riskRefs?programId=${programId}&renewedFromOnly=${renewedFrom}`
    return this.http
      .get<RiskRefDetail[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET All Signature Risk Ref(s) By ProgramId')
      )
  }

  getRenewalDetails(riskRef: string | null): ApiResponse<OTRenewalDetails> {
    const url = `${environment.internalApi.base}${environment.internalApi.ot_risk_confirm_risk_details}?riskRef=${riskRef}`
    return this.http
      .get<OTRenewalDetailsResponse>(url)
      .pipe(
        map(convertRenewalDetailsResponse),
        mapToMaybeData(),
        catchAndHandleError('GET Renewal Details by riskRef')
      )
  }
  postWhiteSpace(
    riskRef: string | null,
    details: any,
    structureId: string | null,
    layerIds: string[],
    layerNames: string[],
    currentTemplate: string,
    currentVendorId: string | undefined
  ): ApiResponse<WhiteSpaceCloneResponse[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.whitespace_save_template}${riskRef}`
    const req = convertRenewalDetailsPostWS(
      details,
      structureId,
      layerIds,
      layerNames,
      currentTemplate,
      currentVendorId
    )
    if (structureId == null) {
      of()
    }
    return this.getTemplateDataByStructureId(structureId).pipe(
      switchMap((templateData: WhiteSpaceRes) => {
        if (
          templateData &&
          templateData?.data &&
          templateData.data.structureId
        ) {
          const error = new HttpErrorResponse({
            error: 'Exists',
          })
          return of(error)
        } else {
          return this.http
            .post<WhiteSpaceCloneResponse[]>(url, req)
            .pipe(
              mapToMaybeData(),
              catchAndHandleError('Error Creating Template in Whitespace')
            )
        }
      })
    )
  }

  getTemplateDataByStructureId(
    structureId: string | null
  ): ApiResponse<ExternalVendorMapping> {
    const url = `${environment.internalApi.base}${environment.internalApi.evm}${structureId}`
    return this.http
      .get<ExternalVendorMapping>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Template data by structure')
      )
  }
  getWordDocument(
    riskRef: string | null | undefined,
    fileTemplate: string | null
  ): ApiResponse<Blob> {
    const url = `${environment.internalApi.base}${environment.internalApi.ot_risk_exportlipTemplate}?riskRef=${riskRef}&filePath=${fileTemplate}`
    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Renewal Details by riskRef')
      )
  }

  getCoBrokerDetails(
    keyValuePairs: Record<string, string | string[]>
  ): ApiResponse<boolean> {
    if (!keyValuePairs) {
      return of({})
    }
    const url = `${this.url}/fetch_cobroked_details`
    return this.http
      .get<boolean>(url, { params: encodeParams(keyValuePairs) })
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('Error during US/UK Cobroker fetch')
      )
  }

  getWhitespaceRiskReferenceSearchResults(searchStructure: SearchApiFormat): ApiResponse<SearchResultFormat[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.whitespace_risks}/summary`
    return this.http
      .post<SearchResultFormat[]>(url, searchStructure)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('Error while searching for Whitespace risks')
      )
  }
}
