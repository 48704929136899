import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { LossSetGroup, LossSetLayer } from '../../model/loss-set-layers.model'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { LoadedLossSet, Metadata } from 'src/app/api/analyzere/analyzere.model'
import { MappingLabels } from 'src/app/core/model/study.model'
import { CombinedFilterState, EXPLORE_SIDEBAR_FILTER_TERMS, EXPLORE_SUMMARY_HEADERS, ExploreFilterMap, ExploreSummaryHeader, LossTypeColumnFilters, MappingOption } from '../explore.model'
import { buildSummaryFilterMap,  updateSelectedFilterMap } from '../explore.util'
import { isLoadedLossSet } from '../../model/layers.util'
import { ExploreSummaryView } from 'src/app/api/model/backend.model'
import { environment } from 'src/environments/environment'

type FilterKey = keyof typeof EXPLORE_SIDEBAR_FILTER_TERMS

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-gross-sidebar',
  templateUrl: './explore-gross-sidebar.component.html',
  styleUrls: ['./explore-gross-sidebar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 1 })),
      transition(':enter', [
        style({ height: '0px', opacity: 0 }),
        animate('200ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('200ms ease-in', style({ height: '0px', opacity: 0 }))
      ])
    ])
  ]
})
export class ExploreGrossSidebarComponent implements OnChanges, OnInit {
  filterTerms = EXPLORE_SIDEBAR_FILTER_TERMS
  isProd = environment.production
  visibilityMap: ExploreFilterMap = {
    summaryViews: false,
    groups: true,
    layers: true,
    l1: true,
    l2: false,
    l3: false,
    l4: false,
    l5: false,
    metaOptions: false,
    modelingOptions: false,
    columns: false,
    columnLossTypes: false
  }
  summaryColumnFilterMap: ExploreFilterMap = {}
  summaryFilterMap: ExploreFilterMap = {}
  headers: ExploreSummaryHeader[]
  groupByView: boolean
  displaySummaryFilterOptions: MappingOption[]
  @Input() summaryView: boolean
  @Input() modelingArray: string[]
  @Input() showIndividualLossSets: boolean = false
  @Input() selectedLossSetIDs: string[]
  @Input() allLossSetsSelected: boolean
  @Input() lossSetGroups: LossSetGroup[]
  @Input() lossSetLayers: LossSetLayer[]
  @Input() mappingLabels: MappingLabels
  @Input() summaryFilterOptions: MappingOption[]
  @Input() summaryViews: ExploreSummaryView[]
  @Input() selectedStudySummaryFilterView: ExploreSummaryView | null
  @Input() studySummaryColumnFilters: string[]
  @Input() isSavedViewDirty: boolean

  @Output() onLossSetChange = new EventEmitter()
  @Output() onSelectAllLossSets = new EventEmitter()
  @Output() onLossSetClick = new EventEmitter<{lossID: string, $event: MouseEvent}>()
  @Output() updateSummaryFilterMap = new EventEmitter<ExploreFilterMap>
  @Output() updateSummaryColumnFilterMap = new EventEmitter<ExploreFilterMap>()
  @Output() updateSelectedStudySummaryView = new EventEmitter<ExploreSummaryView>()
  @Output() updateShowIndividualLossSets = new EventEmitter<boolean>()
  @Output() onUpdateModelingArray = new EventEmitter<string[]>
  @Output() updateCombinedFilterState = new EventEmitter<CombinedFilterState>()

  ngOnInit(): void {
    this.headers = EXPLORE_SUMMARY_HEADERS.filter(h =>
      h.id !== 'groupBy'
    )
    const lossTypeFilters = Object.keys(LossTypeColumnFilters)
    lossTypeFilters.forEach(key => {
      if (!(key in this.summaryColumnFilterMap)) {
        this.summaryColumnFilterMap[key] = true
      }
    })
    this.headers.forEach(header => {
      const key = header.id
      if (!(key in this.summaryColumnFilterMap)) {
        if (
          key === 'totalContributionToGroupVolatility' ||
          key === 'contributionToGroupVolatility'
        ) {
          this.summaryColumnFilterMap[key] = false
        } else {
          this.summaryColumnFilterMap[key] = true
        }
      }
    })
    this.emitCombinedFilterState()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.constructFilterOptions()
    if (!this.isProd) {
      let shouldEmit = false
      if (changes.lossSetLayers && this.lossSetLayers) {
        this.summaryFilterMap = buildSummaryFilterMap(this.lossSetLayers, this.summaryFilterOptions)
        this.displaySummaryFilterOptions = this.filterSummaryFilterOptions()
        shouldEmit = true
      }
      if (changes.modelingArray) {
        const previousGroupByView = this.groupByView
        this.groupByView = this.modelingArray.filter(x => x !== '').length > 0
        if (this.groupByView !== previousGroupByView) {
          if (this.groupByView) {
            this.summaryColumnFilterMap['totalContributionToGroupVolatility'] = true
            this.summaryColumnFilterMap['contributionToGroupVolatility'] = true
          } else {
            this.summaryColumnFilterMap['totalContributionToGroupVolatility'] = false
            this.summaryColumnFilterMap['contributionToGroupVolatility'] = false
          }
          shouldEmit = true
        }
      }
      if (changes.selectedStudySummaryFilterView) {
        if (this.selectedStudySummaryFilterView === null) {
          this.toggleSelectedStudySummaryFilterView(null, true)
        }
      }
      if (shouldEmit) {
        this.emitCombinedFilterState()
      }
    }
  }

  filterSummaryFilterOptions(): MappingOption[] {
    return this.summaryFilterOptions.filter(o => this.getSubOptionsByValueType(o.value).length > 0)
  }

  lossSetClick(lossID: string, $event: MouseEvent): void {
    this.onLossSetClick.emit({ lossID, $event })
    this.constructFilterOptions()
  }

  checkSelectedLossSet(id: string): boolean {
    return this.selectedLossSetIDs.includes(id)
  }

  checkSelectedLevels(value: string, level: number): boolean {
    return this.modelingArray[level] === value
  }

  checkDisabledLevels(value: string, level: number): boolean {
    const models = [...this.modelingArray]
    models.splice(level, 1)
    return models.includes(value)
  }

  constructFilterOptions(): void {
    this.summaryFilterOptions.forEach(option => {
      if (!this.visibilityMap[option.value]) {
        this.visibilityMap[option.value] = false
      }
    })
  }

  getSubOptionsByValueType(value: string): string[] {
    const subOptions: string[] = []
    const optionValue = value as keyof Metadata
    const layers = this.lossSetLayers.map(layer => {
      const lossSetLoaded = isLoadedLossSet(layer.loss_sets[0])
      const loadedLossSet = layer.loss_sets[0] as LoadedLossSet
      const lossScaleFactor = lossSetLoaded ? loadedLossSet.load : 1
      const originalPremium = layer.meta_data.originalPremium || layer.meta_data.originalPremium === 0
        ? layer.meta_data.originalPremium
        : layer.premium?.value ?? 0
      const premiumScaleFactor = originalPremium === 0
        ? 0
        : layer.meta_data.originalPremium
          ? layer.premium.value / originalPremium
          : 1
      return {
        ...layer,
        meta_data: {
          ...layer.meta_data,
          lossScaleFactor,
          premiumScaleFactor
        }
      }
    })
    layers.forEach(layer => {
      if (!!layer.meta_data[optionValue]) {
        const option = String(layer.meta_data[optionValue])
        if (!subOptions.includes(option)) {
          subOptions.push(option)
          const visibilityKey = value
          if (!(visibilityKey in this.visibilityMap)) {
            this.visibilityMap[visibilityKey] = false
          }
        }
      }
    })
    return subOptions
  }

  toggleVisibility(section: string): void {
    this.visibilityMap[section] = !this.visibilityMap[section]
  }

  isSectionVisible(section: string): boolean {
    return this.visibilityMap[section]
  }

  showGroupByLevel(index: number): boolean {
    return this.modelingArray[index] !== ''
  }

  checkSummaryFilterSelected(value: string): boolean {
    return this.summaryFilterMap[value]
  }

  checkSummaryColumnFilter(value: string): boolean {
    return this.summaryColumnFilterMap[value]
  }

  checkSelectedStudySummaryFilterView(id: number): boolean {
    return this.selectedStudySummaryFilterView && this.selectedStudySummaryFilterView.id === id
  }

  updateModelingArray(value: string, index: number): void {
    if (this.modelingArray[index] === value) {
      this.modelingArray = this.modelingArray.map((item, i) => {
        if (i > index) {
          const key = `l${i + 1}`
          this.visibilityMap[key] = false
        }
        return i >= index ? '' : item
      })
    } else {
      this.modelingArray[index] = value
    }
    this.isSavedViewDirty = true
    this.emitCombinedFilterState()
  }


  toggleSummaryFilter(value: string): void {
    this.summaryFilterMap[value] = !this.summaryFilterMap[value]
    this.isSavedViewDirty = true
    this.emitCombinedFilterState()
  }

  toggleSummaryColumnFilter(value: string): void {
    this.summaryColumnFilterMap[value] = !this.summaryColumnFilterMap[value]
    this.isSavedViewDirty = true
    this.emitCombinedFilterState()
  }

  toggleIndividualLossSets(checked: boolean): void {
    this.showIndividualLossSets = checked
    this.isSavedViewDirty = true
    this.emitCombinedFilterState()
  }

  emitCombinedFilterState(): void {
    const combinedFilterState: CombinedFilterState = {
      selectedStudySummaryFilterView: this.selectedStudySummaryFilterView,
      summaryColumnFilterMap: { ...this.summaryColumnFilterMap },
      summaryFilterMap: { ...this.summaryFilterMap },
      modelingArray: [...this.modelingArray],
      showIndividualLossSets: this.showIndividualLossSets,
      isSavedViewDirty: this.isSavedViewDirty
    }
    this.updateCombinedFilterState.emit(combinedFilterState)
  }

  toggleSelectedStudySummaryFilterView(view: ExploreSummaryView, onReset?: boolean): void {
    let reset = onReset
    if (this.selectedStudySummaryFilterView && this.selectedStudySummaryFilterView.id === view.id || reset) {
      reset = true
      this.selectedStudySummaryFilterView = null
      this.modelingArray = ['', '', '', '', '']
      this.showIndividualLossSets = false
    } else {
      this.selectedStudySummaryFilterView = {...view}
      this.modelingArray = [ ...view.modeling ]
      this.showIndividualLossSets = view.showIndividualLossSets
    }
    this.summaryColumnFilterMap = { ...updateSelectedFilterMap(this.summaryColumnFilterMap, view.columnFilters, reset) }
    this.summaryFilterMap = { ... updateSelectedFilterMap(this.summaryFilterMap, view.filters, reset) }
    this.isSavedViewDirty = false
    this.emitCombinedFilterState()
  }

  createFilter(list: any[], key: string, label: string): any[] {
    const searchTerm = this.filterTerms[key as FilterKey]
    if (!searchTerm) {
      return list
    }
    return list.filter(item => {
      return this.getLabel(item, label).toLowerCase().includes(searchTerm.toLowerCase())
    })
  }

  onSearchFilterChange(key: FilterKey, value: string): void {
    this.filterTerms[key as FilterKey] = value
  }

  getLabel(item: any, label: string): string {
    if (label === 'layers') {
      return `${item.meta_data.ls_dim1} - ${item.meta_data.ls_dim2 ?? ''}`
    } else if (label === 'meta') {
      return item
    }
    return item[label]
  }
}

