import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'
import { CreditCurveLayer } from 'src/app/pricingcurve/model/pricing-curve.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-card-credit-calc',
  styleUrls: ['./pricing-curve-card-credit-calc.component.scss'],
  templateUrl: './pricing-curve-card-credit-calc.component.html',
})
export class PricingCurveCardCreditCalcComponent {
  @Input() set pricingCurve(val: PricingCurve) {
    this._pricingCurve = val
    if (val.creditPredictions) {
      const coPairs = val.creditPredictions.coefficient_pairs
      this.interceptConst = val.creditPredictions.intercept
      this.dtiConst = coPairs.dti
      this.eLoLConst = coPairs.expectedLossOnLine
      this.ficoConst = coPairs.fico
      this.miAttachConst = coPairs.mi_attach
      this.miDetachConst = coPairs.mi_detach
      this.oltvConst = coPairs.oltv
      this.pAttachConst = coPairs.pAttach
      this.pDetachConst = coPairs.pDetach
      this.miThicknessConst = coPairs.mi_thickness
      this.initForm()
    }
  }
  get pricingCurve(): PricingCurve {
    return this._pricingCurve
  }
  _pricingCurve: PricingCurve
  form: FormGroup

  interceptConst: number
  dtiConst: number
  eLoLConst: number
  ficoConst: number
  miAttachConst: number
  miDetachConst: number
  oltvConst: number
  pAttachConst: number
  pDetachConst: number
  miThicknessConst: number

  integerFields = ['inputDTI', 'inputFICO', 'inputOLTV']
  constructor(private readonly formBuilder: FormBuilder) {}

  initForm(): void {
    const firstTranche = (
      this.pricingCurve.includedLayers as CreditCurveLayer[]
    )[0]
    this.form = this.formBuilder.group(
      {
        dtiConst: new FormControl({
          value: this.dtiConst.toFixed(8),
          disabled: true,
        }),
        eLoLConst: new FormControl({
          value: this.eLoLConst.toFixed(8),
          disabled: true,
        }),
        ficoConst: new FormControl({
          value: this.ficoConst.toFixed(8),
          disabled: true,
        }),
        miAttachConst: new FormControl({
          value: this.miAttachConst.toFixed(8),
          disabled: true,
        }),
        miDetachConst: new FormControl({
          value: this.miDetachConst.toFixed(8),
          disabled: true,
        }),
        oltvConst: new FormControl({
          value: this.oltvConst.toFixed(8),
          disabled: true,
        }),
        pAttachConst: new FormControl({
          value: this.pAttachConst.toFixed(8),
          disabled: true,
        }),
        pDetachConst: new FormControl({
          value: this.pDetachConst.toFixed(8),
          disabled: true,
        }),
        miThicknessConst: new FormControl({
          value: this.miThicknessConst.toFixed(8),
          disabled: true,
        }),
        inputDTI: new FormControl(firstTranche.dti),
        inputELoL: new FormControl(
          `${(firstTranche.expectedLossOnLine * 100).toFixed(2)}%`
        ),
        inputFICO: new FormControl(`${firstTranche.fico.toFixed(2)}`),
        inputMIAttach: new FormControl(
          `${(firstTranche.miAttach * 100).toFixed(2)}%`
        ),
        inputMIDetach: new FormControl(
          `${(firstTranche.miDetach * 100).toFixed(2)}%`
        ),
        inputOLTV: new FormControl(`${firstTranche.oltv}`),
        inputPAttach: new FormControl(
          `${(firstTranche.pAttach * 100).toFixed(2)}%`
        ),
        inputPDetatch: new FormControl(
          `${(firstTranche.pDetach * 100).toFixed(2)}%`
        ),
        inputMiThickness: new FormControl(`${firstTranche.miThickness.toFixed(8)}%`),
        pricingPrediction: new FormControl({ value: '', disabled: true }),
      },
      { updateOn: 'blur' }
    )
    this.form.valueChanges.subscribe(() => {
      this.recalculatePricingPrediction()
    })
    this.recalculatePricingPrediction()
  }

  setFormValue(fieldName: string, val: string): void {
    if (this.form) {
      const hasPercent = val.includes('%')
      const newVal = this.integerFields.includes(fieldName)
        ? Number(val)
        : Number(val.replace('%', ''))
      this.form
        .get(fieldName)
        ?.setValue(
          `${newVal.toFixed(2)}${
            !hasPercent && !this.integerFields.includes(fieldName) ? '%' : ''
          }`,
          {
            emitEvent: false,
          }
        )
    }
  }

  updatePercentSign(controlName: string): void {
    const newVal = this.form.get(controlName)?.value
    if (typeof newVal !== 'undefined') {
      this.setFormValue(controlName, newVal.toString())
    }
  }

  get calculatedPrediction(): number | undefined {
    const inputs = this.pricingCurve.activeCreditPredictionColumns
    return inputs?.reduce((acc, val) => {
      const constVal = this.form.get(val.calcColumnName)?.value
      const inputVal = this.form.get(val.calcColumnInputName)?.value
      if (typeof constVal !== 'undefined' && typeof inputVal !== undefined) {
        const parsedConstValue = Number(constVal)
        const parsedInputValue = !this.integerFields.includes(
          val.calcColumnInputName
        )
          ? Number(inputVal.replace('%', '')) / 100
          : Number(inputVal)
        return acc + parsedConstValue * parsedInputValue
      } else {
        return acc
      }
    }, this.interceptConst)
  }

  isPercentFieldValid(): boolean {
    return false
  }

  recalculatePricingPrediction(): void {
    if (this.calculatedPrediction !== undefined) {
      this.setFormValue(
        'pricingPrediction',
        `${(this.calculatedPrediction * 100).toFixed(5)}`
      )
    }
  }
}
