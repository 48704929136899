// NOTES
// ------------------------
// Metadata objects are relatively free-form
// dictionaries subject to change by whoever adds the object
// So far I'm typing them per object type
// Typings are based on API testing and docs at http://docs.analyzere.net/

import { LayerRef } from 'src/app/analysis/model/layers.model'

// boilerplate attributes on every root API response
interface BaseResponse {
  id: string
  _type: string
  created?: string
  modified?: string
  description: string | null
}

export interface Reinstatement {
  id?: number
  premium?: number
  brokerage: number
}

export interface MonetaryUnit {
  value: number
  currency: string
  value_date?: any
  rate?: any
  rate_currency?: any
}
export const asMonetaryUnit = (value: number, currency: string) => {
  return { value, currency } as MonetaryUnit
}
export const asUSD = (value: number) => asMonetaryUnit(value, 'USD')

export const defaultAnalylisProfile = 'e0e90e28-7b5b-41fd-b7aa-00b80531a265'

export interface Fee {
  payout_date?: string
  _type: string
  rate: number
  premiums: FeeRef[]
  name: string
  fees?: FeeRef[]
  losses?: FeeRef[]
}

export interface FeeRef {
  ref: string[]
}

// tslint:disable-next-line: max-line-length
// AnalysisProfile https://lockton-api.analyzere.net/analysis_profiles/e0e90e28-7b5b-41fd-b7aa-00b80531a265
export interface AnalysisProfile {
  id: string
  created: string
  modified: string
  description: string
  status: string
  status_message: string
  loss_filters: LossFilter[]
  event_catalogs: EventCatalog[] | Ref[]
  exchange_rate_profile: ExchangeRateProfile
  simulation: Simulation
}

export interface Simulation extends BaseResponse {
  name: string
  data: Ref
  event_catalogs: Ref[]
  meta_data: Partial<Metadata>
  start_date: string
  status: string
  status_message: string
  trial_count: number
  one_based_sequencing: boolean
  adjust_leap_years: boolean
  href?: string
  ref_id?: string
}

// tslint:disable-next-line: max-line-length
// LossFilter https://lockton-api.analyzere.net/loss_filters/96a627dd-cb35-41c0-af7c-4bf07aab0f18
export interface LossFilter extends BaseResponse {
  name: string
}

type LossFilterTypes =
  | 'AnyOfFilter'
  | 'AnyFilter'
  | 'RecordTypeAnyOfFilter'
  | 'LTFilter'
  | 'GTFilter'
  | 'RangeFilter'
  | 'AndFilter'
  | 'OrFilter'
export interface LossFilterResponse {
  id: string
  _type: LossFilterTypes
  name: string
  created: string
  modified: string
  description: string
  invert: boolean
  attribute: string
  values: string[]
  meta_data: Partial<Metadata>
}

// tslint:disable-next-line: max-line-length
// Portfolio https://lockton-api.analyzere.net/portfolios/c354dfbc-0113-47ea-b291-6fe1e8f40263
export interface Portfolio extends BaseResponse {
  name: string
  layers: LogicalPortfolioLayer[] | Ref[] | LossSetLayer[]
  meta_data: Partial<Metadata>
}

// tslint:disable-next-line: max-line-length
// Occurrence Logical Layer https://lockton-api.analyzere.net/layers/b858856d-2f7e-4436-abfb-d0880606d4c5
export interface LogicalPortfolioLayer extends BaseResponse {
  sink: PhysicalPortfolioLayer | Ref | LogicalPortfolioLayer | LossSetLayer // points to physical layer
  // tslint:disable-next-line: max-line-length
  sources:
    | Array<LossSetLayer | LogicalPortfolioLayer | PhysicalPortfolioLayer>
    | Ref[] // array of loss set layers, requires at least one to be valid
  source_id?: string
  meta_data: Partial<Metadata>
  startingCession?: number
  color?: string
}

// tslint:disable-next-line: max-line-length
// Occurrence Physical Layer https://lockton-api.analyzere.net/layers/40b9d865-be57-488f-98d2-b9d89a6c434b
export interface PhysicalPortfolioLayer extends BaseResponse {
  loss_sets: any[]
  policy?: any
  inception_date?: string | null
  expiry_date?: string | null
  premium: MonetaryUnit
  fees: Fee[]
  aggregate_attachment: MonetaryUnit
  aggregate_limit: MonetaryUnit
  attachment: MonetaryUnit
  franchise: MonetaryUnit
  limit: MonetaryUnit
  reinstatements: Reinstatement[]
  participation: number
  meta_data: Partial<Metadata>
  startingCession?: number
  event_limit?: MonetaryUnit
  payout?: MonetaryUnit
  trigger?: MonetaryUnit
  nth?: number
}

// tslint:disable-next-line: max-line-length
// Occurrence Loss Set Layer https://lockton-api.analyzere.net/layers/79c952b5-7b95-43d9-8538-e662bb259ee4
// TODO: This is really a QuotaShare version of a generic LossSetLayer
// TODO: Multiply defined in loss-set-layers.model.ts
export interface LossSetLayer extends BaseResponse {
  loss_sets: LossSet[] | Ref[] | LayerRef[]
  policy?: any
  inception_date?: any
  expiry_date?: any
  premium: MonetaryUnit
  fees: Fee[]
  event_limit: MonetaryUnit
  participation: number
  meta_data: Partial<Metadata>
  invert: boolean
  count: number
  criterion: string
}

export interface Metadata {
  correlationId: string
  program_type: string
  year: string
  perspective: string
  rol: number
  lossfilter_name: string
  client: string
  program_name: string
  rol_type: string
  layer_narrative: string
  sage_layer_type: string
  sage_layer_subtype: string
  hidden: boolean /* provide a simple common property to hide ARe layers in the tower */
  cascadeAttachment: number
  cascadeLowerLayerID: string
  oldDropSelected: string
  prevID: string
  backAllocatedForID: string
  nestedLayersCededPortfolioRecord: string
  nestedLayersNetPortfolioRecord: string
  analysisProfileID: string
  inuranceSource: boolean
  inuranceSourceFor: string /* inuranceSourceFor is a JSON string. Type is InuranceRef[] */
  inuranceTarget: boolean
  inuranceTargetFor: string /* inuranceTargetFor is a JSON string. Type is InuranceRef[] */
  reinsurers: string
  region: string
  reinsurer_is_default: boolean
  pricingcurve_is_default: boolean
  technicalPremium: number
  structureID: string
  layerName: string
  lob: number
  ls_dim1: string
  loss_type: string
  isDrop: boolean
  topID: string
  sharedLimitHidden: number
  ls_dim2: string
  tempSources: string
  tempInuranceSource: boolean
  tempInuranceTarget: boolean
  fromLayerID: string
  isFHCFFinal: boolean
  isFHCFHidden1: boolean
  isFHCFHidden2: boolean
  isRiskLargeHidden: boolean
  isRiskVisible: boolean
  isRiskCatHidden: boolean
  isRiskFinal: boolean
  riskVisibleLayerID: string
  riskActualLayerID: string
  isLimitUnlimited: boolean
  isAggregateUnlimited: boolean
  reinsurerExpenseProvision: number
  originalPremium: number
  scaledIdentifier: string
  loadedForLayerID: string
  subjectPremiumChecked: boolean
  technicalPremiumChecked: boolean
  cedingCommissionChecked: boolean
  rate_on_subject: number
  min_rate_subject: number
  max_rate_subject: number
  swing_rate: number
  swing_basis: string
  loss_layer_id: string
  combined_layer_id: string
  premium_layer_id: string
  adjustment_layer_id: string
  visible_layer_id: string
  main_layer_id: string
  indexation: number
  fixedIndexValue: number
  customIndexValues: string
  isCustomIndex: boolean
  sicOrFranchise: number
  contractOccurrenceLimit: number
  members: number
  pmpm: number
  min_rate_pmpm: number
  max_rate_pmpm: number
  z_order?: number
  pricingCurves: string
  subjectPremiumQS: number
  payout?: number
  isAutoBuild?: boolean
  isChangedInDesign?: boolean
  autoBuildSections?: string /* autoBuildSections is a JSON string. Type is RiskRefWithSections */
  layerResultsMetricCurrency?: string
  map1?: string
  map2?: string
  map3?: string
  map4?: string
  map5?: string
  isLossSetUpdated?: boolean
  lossScaleFactor: number | string
  premiumScaleFactor: number | string
}

export interface Data {
  id: string
  created: string
  modified: string
  name: string
  content: string
  size: number
}

export interface EventCatalog {
  id: string
  created: string
  modified: string
  data: Data
  source: string
  status: string
  status_message: string
  description: string
  meta_data: Partial<Metadata>
  tags: string[]
}

export interface ExchangeRateProfile {
  id: string
  created: string
  modified: string
  exchange_rate_table: ExchangeRateTable
}

export interface ExchangeRateTable {
  id: string
  created: string
  modified: string
  base_currency: string
  data: ExchangeRateTableData
}

export interface ExchangeRateTableData {
  content: string
  created: string
  id: string
  modified: string
  name: string
  size: string
}

export interface Currencies {
  currencies: CurrencyCode[]
}

export interface CurrencyCode {
  code: string
}

// tslint:disable-next-line: max-line-length
// Loss set: https://lockton-api.analyzere.net/loss_sets/dac21cf8-d1a6-4c57-bea7-af87de66757a
export interface LossSet extends BaseResponse {
  loss_type: string
  profile: LossSetProfile
  currency: string
  data: Data | Ref
  event_catalogs: EventCatalog[] | Ref[]
  status: string
  status_message: string
  meta_data: Partial<Metadata>
}

/* type guard for data property of LossSet */
export const isData = (obj: Data | Ref): obj is Data => {
  return obj && typeof obj === 'object' && 'size' in obj
}

export interface LoadedLossSet extends BaseResponse {
  load: number
  source: LossSet | Ref
  profile: LossSetProfile
  loss_type: string
  meta_data: Partial<Metadata>
}

interface LossSetProfile {
  currency: string
  attributes: LossSetProfileAttributes
  min_loss: number
  avg_annual_loss: number
  max_loss: number
  num_losses: number
  non_zero_losses: number
}

interface LossSetProfileAttributes {
  [key: string]: string[]
}

export interface Ref {
  ref_id: string
  href?: string
  ref_type?: string
}
export const asRef = (id: string): Ref => {
  return { ref_id: id }
}

export interface PortfolioViewRequest {
  analysis_profile: Ref
  portfolio?: Ref
  layer_views?: Ref[]
  target_currency?: string
}

export interface PortfolioViewResponse {
  id: string
  analysis_profile: Ref
  layer_views: Ref[]
  portfolio: Ref
  target_currency: string
  ylt_id: string
}

export interface LayerViewRequest {
  analysis_profile: Ref
  layer: Ref | Partial<LogicalPortfolioLayer>
  target_currency?: string
}

export interface LayerViewResponse {
  id: string
  analysis_profile: Ref
  layer: LogicalPortfolioLayer
}

// we're going to have a LOT of calls that return
// these generic layer view metrics objects
// these are also used for portfolio metrics
export interface Metrics {
  _type: string
  kurtosis: number
  max: number
  mean: number
  min: number
  skewness: number
  variance: number
  context: MetricsContext | undefined
}

interface MetricsContext {
  _type: string
  perspective: string
  secondary_uncertainty: boolean
  filter: string
  reporting_period_begin: string
  aggregation_method: string
  currency: string
  apply_participation: boolean
  probability: number
}

export interface CoMetrics {
  _type: 'PortfolioViewCoMetrics' | 'LayerViewCoMetrics'
  context: MetricsContext
  component_metrics: {
    mean: number
    min: number
    covariance: number
    correlation: number
  }
}

export interface PortfolioDetailMetrics {
  lossNetAggregateTermAEP: Metrics
  lossNetPremiumReinstatementAggregateTermAEP: Metrics
  lossNetAggregateTermsPremiumReinstatementAEP: Metrics
  reinstatementPremiumAEP: Metrics
  lossNetOfAggregateTermsAEPReportingPeriod: Metrics
  lossNetAggregateTermsPremiumReinstatementAEPParticipation: Metrics
  lossNetAggregateTermAEPParticipation: Metrics
  grossCovariance: CoMetrics
  premiumAEP: Metrics
}

export interface AllPortfolioDetailMetrics {
  cededPortfolioViewDetailMetrics: PortfolioDetailMetrics
  grossPortfolioViewDetailMetrics: PortfolioDetailMetrics
  netPortfolioViewDetailMetrics: PortfolioDetailMetrics
}

export interface AllPortfolioDetailMetricsCompare {
  cededPortfolioViewDetailMetrics: PortfolioDetailMetrics
  grossPortfolioViewDetailMetrics: PortfolioDetailMetrics
  netPortfolioViewDetailMetrics: PortfolioDetailMetrics
  id: string
}

export interface PortfolioViewsResponse {
  cededPortfolioView: PortfolioViewResponse
  grossPortfolioView: PortfolioViewResponse
  netPortfolioView: PortfolioViewResponse
}

export interface PortfolioViewsResponseCompare {
  cededPortfolioView: PortfolioViewResponse
  grossPortfolioView: PortfolioViewResponse
  netPortfolioView: PortfolioViewResponse
  id: string
}

export interface LayerMetricsResponseBase {
  depositPremium: Metrics
  expectedCededPremiumBase: Metrics
  expectedCededLossBase: Metrics
  expectedCededExpensesBase: Metrics
  expectedCededMarginBase: Metrics
  aepBase: Metrics[]
  oepWindowVarBase: Metrics
  aepWindowVarBase: Metrics
  entryProbability: ExceedanceProbability
  exitProbability: ExceedanceProbability
  exitAggProbability: ExceedanceProbability
  grossCovariance?: CoMetrics
  expectedCededExpensesBaseNoParticipation: Metrics
  expectedCededLossBaseNoParticipation: Metrics
  cededYearValue?: Metrics
}

export interface LayerMetricsResponse extends LayerMetricsResponseBase {
  id: string
}

export interface ExceedanceProbability {
  _type: string
  probability: number
  context: ExceedanceProbabilityContext
}

export interface ExceedanceProbabilityContext {
  perspective: string
  secondary_uncertainty: boolean
  filter: string
  aggregation_method: string
  threshold: number
  inclusive_threshold: boolean
  threshold_currency: string
  threshold_includes_participation: boolean
}

export interface Update<T> {
  id: string
  change: Partial<T>
}
