import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import {
  CompareMetricSetting,
  CompareStructureOptionsDimensionChangeEvent,
  CompareStructureOptionsView,
} from '../../model/compare-metrics.model'
import { AppState } from '../../../core/store'
import {
  selectCompareEntities,
  selectCompareMetricSettingsEntities,
  selectCompareStructureOptions,
  selectCompareStructureOptionsView,
} from '../../store/analysis.selectors'
import {
  CompareEntity,
  StructureOptions,
} from '../../store/compare/compare.reducer'
import { CompareStructureOptionsComponent } from './compare-structure-options.component'
import { setCompareStructureOptionsDimensionProp } from '../../store/compare/compare.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-compare-structure-options-container',
  styleUrls: ['./compare-structure-options.container.scss'],
  templateUrl: './compare-structure-options.container.html',
})
export class CompareStructureOptionsContainerComponent
  implements OnChanges, OnDestroy
{
  private destroy$ = new Subject()
  @Input() tabIndex = 0
  @Input() compareCurrency: string

  props$: Observable<CompareMetricSetting[]>
  view$: Observable<CompareStructureOptionsView>
  state$: Observable<StructureOptions>
  propsWithCategory$: Observable<CompareEntity[]>

  @ViewChild(CompareStructureOptionsComponent)
  component: CompareStructureOptionsComponent

  constructor(public store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.tabIndex && changes.tabIndex.currentValue === 1) {
      this.props$ = this.store.pipe(select(selectCompareMetricSettingsEntities))
      this.view$ = this.store.pipe(select(selectCompareStructureOptionsView))
      this.state$ = this.store.pipe(select(selectCompareStructureOptions))
      this.propsWithCategory$ = this.store.pipe(select(selectCompareEntities))
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onDimensionChange($event: CompareStructureOptionsDimensionChangeEvent): void {
    this.store.dispatch(setCompareStructureOptionsDimensionProp($event))
  }
}
