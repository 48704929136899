<div class="layer-list" *ngIf="layerList?.length > 0">
  <h3>LAYER LIST</h3>
  <div *ngFor="let l of layerList; let i = index; trackBy: trackByFn">
    <div
      class="layer-item"
      (click)="clickSelectLayer(l.id)"
      [ngClass]="{
        'layer-selected': layerListId === l.id && groupValue === 'Layer',
      }"
    >
      <span
        class="layer-number"
        [ngClass]="{
          'index-selected': layerListId === l.id && groupValue === 'Layer',
        }"
      >
        {{ i + 1 }}:
      </span>
      <span
        class="sage-layer-type"
        [ngClass]="{
          xol:
            l.layerType === layerIds.catXl ||
            l.layerType === layerIds.ahlXl ||
            l.layerType === layerIds.noncatXl ||
            l.layerType === layerIds.noncatIndxl,
          qs:
            l.layerType === layerIds.catQs ||
            l.layerType === layerIds.noncatQs ||
            l.layerType === layerIds.ahlQs,
          ca: l.layerType === layerIds.catCa,
          td: l.layerType === layerIds.catTd || l.layerType === layerIds.drop,
          risk: l.layerType === layerIds.noncatRisk,
          fhcf: l.layerType === layerIds.catFhcf,
          ag:
            l.layerType === layerIds.noncatAg ||
            l.layerType === layerIds.catAg ||
            l.layerType === layerIds.ahlAg ||
            l.layerType === layerIds.feeder,
          multi:
            l.layerType === layerIds.catMultisection ||
            l.layerType === layerIds.noncatMultisection,
          swing:
            l.layerType === layerIds.noncatSwing ||
            l.layerType === layerIds.ahlSwing,
          ilw:
            l.layerType === layerIds.ilwProRata ||
            l.layerType === layerIds.ilwBin ||
            l.layerType === layerIds.catIlw,
        }"
      >
        {{ l.description }}
        <span
          class="sub-type xol"
          *ngIf="
            l.layerType === layerIds.catXl ||
            l.layerType === layerIds.ahlXl ||
            l.layerType === layerIds.noncatXl
          "
        >
          XoL
        </span>
        <span class="sub-type xol" *ngIf="l.layerType === layerIds.noncatIndxl">
          Indexed XL
        </span>
        <span
          class="sub-type qs"
          *ngIf="
            l.layerType === layerIds.catQs ||
            l.layerType === layerIds.noncatQs ||
            l.layerType === layerIds.ahlQs
          "
        >
          QS
        </span>
        <span class="sub-type ca" *ngIf="l.layerType === layerIds.catCa">
          CA
        </span>
        <span class="sub-type td" *ngIf="l.layerType === layerIds.catTd">
          T&amp;D
        </span>
        <span class="sub-type td" *ngIf="l.layerType === layerIds.drop">
          Drop
        </span>
        <span class="sub-type risk" *ngIf="l.layerType === layerIds.noncatRisk">
          Risk
        </span>
        <span class="sub-type fhcf" *ngIf="l.layerType === layerIds.catFhcf">
          FHCF
        </span>
        <span
          class="sub-type ag"
          *ngIf="
            l.layerType === layerIds.noncatAg ||
            l.layerType === layerIds.catAg ||
            l.layerType === layerIds.ahlAg
          "
        >
          Agg Feed
        </span>
        <span class="sub-type ag" *ngIf="l.layerType === layerIds.feeder">
          Feeder
        </span>
        <span
          class="sub-type multi"
          *ngIf="
            l.layerType === layerIds.catMultisection ||
            l.layerType === layerIds.noncatMultisection
          "
        >
          Multi
        </span>
        <span
          class="sub-type swing"
          *ngIf="
            l.layerType === layerIds.noncatSwing ||
            l.layerType === layerIds.ahlSwing
          "
        >
          Swing
        </span>
        <span
          class="sub-type ilw"
          *ngIf="
            l.layerType === layerIds.catIlw || l.layerType === layerIds.ilwBin
          "
        >
          ILW
        </span>
        <span class="sub-type ilw" *ngIf="l.layerType === layerIds.ilwProRata">
          ILW Pro
        </span>
        <span
          class="sub-type ahl"
          *ngIf="
            l.layerType === layerIds.ahlQs ||
            l.layerType === layerIds.ahlXl ||
            l.layerType === layerIds.ahlAg ||
            l.layerType === layerIds.ahlSwing
          "
        >
          AHL
        </span>
      </span>
      <div class="display-text">{{ l.layerInfoText }}</div>
    </div>
  </div>
</div>
