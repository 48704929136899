<button
  mat-icon-button
  class="menu-icon"
  id="menu"
  (click)="navService.open()"
>
  <mat-icon>menu</mat-icon>
</button>
<a class="logo" routerLink="/home" appLogo></a>
<app-header-title
  class="title"
  [name]="name"
  [subtitle]="subtitle"
  (backClick)="onBackClick()"
></app-header-title>
<div>
  <section class="actions">
    <ng-container *ngIf="!activeAction; else actionRunning">
      <button
        (click)="onExportClick()"
        appButton
        big
        accent
        [disabled]="!disableSave"
        tabindex="-1"
      >
        Export
        <mat-icon class="icon"> cloud_download </mat-icon>
      </button>
      <button
        appButton
        huge
        accent
        border
        [disabled]="disableSave"
        (click)="onSaveClick()"
      >
        Save
      </button>
    </ng-container>
  </section>
  <ng-template #actionRunning>
    <app-active-action [value]="activeAction"></app-active-action>
  </ng-template>
</div>
