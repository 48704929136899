<app-credit-structure-card
  [size]="size"
  [index]="index"
  [structure]="structure"
  [selectMultiple]="selectMultiple"
  [canCheck]="canCheck"
  [canOptimize]="canOptimize"
  [imageURI]="imageURI"
  (structureClick)="structureClick.emit()"
  (structureCheckChange)="structureCheckChange.emit()"
  (optimizeClick)="optimizeClick.emit()"
></app-credit-structure-card>
