import { CompareMetricSetting } from 'src/app/analysis/model/compare-metrics.model'
import {
  StudyMetricSettingsResponse,
  MetricSettingsSaveChanges,
} from './metric-settings-response.model'

// tslint:disable: no-non-null-assertion

export const formatMetricSettingsFetch = (
  data: StudyMetricSettingsResponse[]
): CompareMetricSetting[] => {
  return data.map((s, i) => ({
    saveID: s.id,
    metricSettingID: s.metric_setting_id,
    path: s.metricSetting
      ? s.metricSetting.path
      : `customMetrics/${s.label.replace(/\s+/g, '')}`,
    label: s.label ? s.label : s.metricSetting!.label,
    portfolioType: s.portfolio_type
      ? s.portfolio_type
      : s.metricSetting
      ? s.metricSetting.portfolio_type || undefined
      : undefined,
    vartvar: s.var_tvar
      ? s.var_tvar
      : s.metricSetting
      ? s.metricSetting.var_tvar || undefined
      : undefined,
    category: s.metricSetting ? s.metricSetting!.category : 'Custom Metrics',
    valueType: s.value_type
      ? s.value_type
      : s.metricSetting
      ? s.metricSetting.value_type
      : 'currency',
    show: s.show,
    weight: s.weight,
    ordinal: s.ordinal === 0 || !s.ordinal ? i + 2 : s.ordinal,
    year: s.year
      ? s.year.toString()
      : s.metricSetting
      ? s.metricSetting!.year || undefined
      : undefined,
    ragOrder: s.rag_order
      ? s.rag_order
      : s.metricSetting
      ? s.metricSetting.rag_order
      : 'Higher',
    aggregationMethodType: s.aggregation_method_type
      ? s.aggregation_method_type
      : s.metricSetting
      ? s.metricSetting!.aggregation_method_type || undefined
      : undefined,
    perspective: s.perspective
      ? s.perspective
      : s.metricSetting
      ? s.metricSetting!.perspective || undefined
      : undefined,
    lossFilter: s.loss_filter
      ? s.loss_filter
      : s.metricSetting
      ? s.metricSetting!.loss_filter || undefined
      : undefined,
    grossMetricType: s.metricSetting
      ? s.metricSetting.gross_metric_type
      : undefined,
    grossMetricSettingID: s.metricSetting
      ? s.metricSetting.gross_metric_id
      : undefined,
    spPremiumValue: s.capital_premium
      ? s.capital_premium
      : s.metricSetting
      ? s.metricSetting!.capital_premium || undefined
      : undefined,
    spReserveValue: s.capital_reserve
      ? s.capital_reserve
      : s.metricSetting
      ? s.metricSetting!.capital_reserve || undefined
      : undefined,
    spDivesificationValue: s.capital_divesification
      ? s.capital_divesification
      : s.metricSetting
      ? s.metricSetting!.capital_divesification || undefined
      : undefined,
    spCatValue: s.capital_cat
      ? s.capital_cat
      : s.metricSetting
      ? s.metricSetting!.capital_cat || undefined
      : undefined,
    formula: s.formula ? s.formula : undefined,
    credit_scenario: s.credit_scenario
      ? s.credit_scenario
      : s.metricSetting
      ? s.metricSetting.credit_scenario || undefined
      : undefined,

  }))
}

export const formatMetricSettingsSave = (
  metric: CompareMetricSetting,
  studySave: string
): MetricSettingsSaveChanges => {
  return {
    id: metric.saveID ? metric.saveID : undefined,
    study_id: studySave,
    metric_setting_id: metric.metricSettingID,
    ordinal: metric.ordinal,
    weight: metric.weight,
    show: metric.show,
    year: metric.year,
    portfolio_type: metric.portfolioType,
    var_tvar: metric.vartvar,
    aggregation_method_type: metric.aggregationMethodType,
    perspective: metric.perspective,
    loss_filter: metric.lossFilter,
    label: metric.label,
    capital_premium: metric.spPremiumValue,
    capital_reserve: metric.spReserveValue,
    capital_divesification: metric.spDivesificationValue,
    capital_cat: metric.spCatValue,
    formula: metric.formula,
    value_type: metric.valueType,
    rag_order: metric.ragOrder!,
    deleted: metric.deleted,
    credit_scenario: metric.credit_scenario,
  }
}
