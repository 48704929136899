import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { MatDialog } from '@angular/material/dialog'
import { Size } from '@shared/size.mixin'
import { AppState } from './../../core/store/index'
import { Observable, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { BackendService } from '../../api/backend/backend.service'
import { CreditStructure } from './../../credit/model/credit-structure.model'
import { CreditSubmissionStructure } from './../../credit/model/credit-submission.model'
import { CREDIT_ADMIN_PERMISSION } from './../../admin/model/credit-admin.model'
import { selectSecurity } from './../../core/store/clients.selectors'
import { CreditCreateSubmissionDialogContainerComponent } from './../../credit/design/credit-create-submission-dialog/credit-create-submission-dialog.container'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-credit-structure-card-container',
  styleUrls: ['./credit-structure-card.container.scss'],
  templateUrl: './credit-structure-card.container.html',
})
export class CreditStructureCardContainerComponent {
  canCreateSubmission$: Observable<boolean>
  private destroy$ = new Subject()
  imageURI: string

  @Input() index: number
  @Input() structure: CreditStructure | CreditSubmissionStructure
  @Input() selectMultiple: boolean
  @Input() canCheck = false
  @Input() canOptimize = false

  // Size mixin
  @Input() size: Size
  @Input() big: boolean
  @HostBinding('class.big')
  get isSizeBig() {
    return this.size === 'big'
  }

  @Output() structureClick = new EventEmitter<void>()
  @Output() structureCheckChange = new EventEmitter<void>()
  @Output() optimizeClick = new EventEmitter<void>()

  constructor(
    private backendService: BackendService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    if (!this.structure.imageSrc) {
      return
    }
    const timestamp = new Date().toISOString() // used to bust cache
    this.backendService
      .getThumbnailImage(this.structure.imageSrc, timestamp)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Blob) => {
        this.createImageFromBlob(data)
      })
    this.canCreateSubmission$ = this.store.pipe(
      select(selectSecurity),
      map(
        security =>
          this.canOptimize &&
          !!security.find(s => s === CREDIT_ADMIN_PERMISSION)
      )
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  onCreateSubmissionClick(): void {
    this.dialog.open(CreditCreateSubmissionDialogContainerComponent, {
      data: this.structure,
      minWidth: '40vw',
      maxWidth: '40vw',
    })
  }

  private createImageFromBlob(image: Blob): void {
    const reader = new FileReader()
    reader.onload = () => {
      this.imageURI = reader.result as string
      this.cdRef.markForCheck()
    }
    if (image) {
      reader.readAsDataURL(image)
    }
  }
}
