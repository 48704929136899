import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { clone } from 'ramda'
import {
  QUOTE_TEMP_PREFIX,
  QuoteReinsurer,
  ReinsurerSubjectivity,
  WhitespaceSyncCustomWarning,
} from '../../models/quote.model'
import { startAnalysis } from '../../../analysis/store/analysis.actions'
import {
  fetchOrSaveQuoteData,
  startGroupQuote,
  startSLQuote,
} from '../quote.actions'
import * as fromReinsurerActions from './reinsurer.actions'

export interface ReinsurerState {
  mapped?: boolean
  reinsurer: QuoteReinsurer
  dirty: boolean
  new: boolean
}

export interface State extends EntityState<ReinsurerState> {
  error: string | null
  loading: boolean
  saving: boolean
  selected: string | null
  isExpand: boolean
  expandedReinsurerName: string | null
  assignedLineReName: string | null
  assignedLineReId?: number
  quoteRiskSubjectivityLinks: ReinsurerSubjectivity[] | null
  whitespaceSyncWarnings: WhitespaceSyncCustomWarning[]
}

export const adapter = createEntityAdapter<ReinsurerState>({
  selectId: (reinsurerState): string => reinsurerState.reinsurer.id,
})

export const initialState: State = adapter.getInitialState({
  error: null,
  loading: false,
  saving: false,
  selected: null,
  isExpand: false,
  expandedReinsurerName: null,
  assignedLineReName: null,
  quoteRiskSubjectivityLinks: null,
  whitespaceSyncWarnings: [],
})

const ReinsurerReducer = createReducer(
  initialState,

  on(fetchOrSaveQuoteData, startGroupQuote, startSLQuote, () => ({
    ...initialState,
    // @ts-ignore
    selected: null,
  })),

  on(
    fromReinsurerActions.addOrUpdateQuoteReinsurerSuccess,
    (state, { reinsurer, isSection }) => {
      let modifiedState
      let newState: State = { ...state }

      if (!isSection) {
        newState = {
          ...state,
          selected: reinsurer.id,
        }
      }

      if (state.entities[reinsurer.id]) {
        modifiedState = adapter.updateOne(
          {
            id: reinsurer.id,
            changes: {
              reinsurer,
              dirty: true,
            },
          },
          newState
        )
      } else {
        modifiedState = adapter.addOne(
          {
            new: true,
            dirty: true,
            reinsurer,
          },
          newState
        )
      }

      return modifiedState
    }
  ),

  on(
    fromReinsurerActions.updateAssignedLineReinsurer,
    (state, { reinsurerState }) => {
      reinsurerState = reinsurerState.filter(
        reinsurer => state.entities[reinsurer.reinsurer.id]
      )
      const updates = reinsurerState.map(reinsurer => {
        const foundState = state.entities[
          reinsurer.reinsurer.id
        ] as ReinsurerState
        return {
          id: reinsurer.reinsurer.id,
          changes: {
            reinsurer: {
              ...foundState.reinsurer,
              riskAssignedLinesLink:
                foundState.reinsurer.riskAssignedLinesLink?.map(line => {
                  const foundUpdatedLine =
                    reinsurer.reinsurer.riskAssignedLinesLink?.find(
                      updatedLine => updatedLine.id === line.id
                    )
                  return foundUpdatedLine ?? line
                }),
            },
            dirty: true,
          },
        }
      })
      return adapter.updateMany(updates, state)
    }
  ),

  on(fromReinsurerActions.updateReinsurerName, (state, { id, name, tpRef }) => {
    const reinsurerState = state.entities[id]
    if (!reinsurerState) {
      return state
    }
    return adapter.updateOne(
      {
        id,
        changes: {
          reinsurer: {
            ...reinsurerState.reinsurer,
            quoteReinsurerName: name,
            tpRef,
          },
          dirty: true,
        },
      },
      {
        ...state,
        selected: id,
      }
    )
  }),

  on(
    fromReinsurerActions.addWhitespaceAssignedLinesSyncWarning,
    (state, { warning }) => {
      return {
        ...state,
        whitespaceSyncWarnings: [...state.whitespaceSyncWarnings, warning],
      }
    }
  ),

  on(fromReinsurerActions.postedWhitespaceSyncWarnings, state => {
    return {
      ...state,
      whitespaceSyncWarnings: [],
    }
  }),

  on(fromReinsurerActions.updateLabel, (state, { id, label }) => {
    const reinsurerState = state.entities[id]
    if (!reinsurerState) {
      return state
    }
    return adapter.updateOne(
      {
        id,
        changes: {
          reinsurer: {
            ...reinsurerState.reinsurer,
            reinsurerPhaseLabel: label,
          },
          dirty: true,
        },
      },
      {
        ...state,
        selected: id,
      }
    )
  }),

  on(fromReinsurerActions.updateLabel, (state, { id, label }) => {
    const reinsurerState = state.entities[id]
    if (!reinsurerState) {
      return state
    }
    return adapter.updateOne(
      {
        id,
        changes: {
          reinsurer: {
            ...reinsurerState.reinsurer,
            reinsurerPhaseLabel: label,
          },
          dirty: true,
        },
      },
      {
        ...state,
        selected: id,
      }
    )
  }),

  on(fromReinsurerActions.saveQuoteReinsurer, (state: State) => ({
    ...state,
    saving: true,
  })),

  on(fromReinsurerActions.saveQuoteReinsurerFailure, (state: State) => ({
    ...state,
    saving: false,
  })),

  on(
    fromReinsurerActions.saveQuoteReinsurerSuccess,
    (
      state,
      {
        id,
        tempID,
        subjectivity,
        reinstatement,
        assignedLines,
        newCededLayerForViewID,
      }
    ) => {
      const reinsurerState = state.entities[tempID]
      if (!reinsurerState) {
        return state
      }
      let newState: State = { ...state, saving: false }
      let modifiedState = adapter.updateOne(
        {
          id: tempID.startsWith(QUOTE_TEMP_PREFIX) ? tempID : id,
          changes: {
            reinsurer: {
              ...reinsurerState.reinsurer,
              id,
              riskSubjectivityLink: subjectivity,
              quoteFields: {
                ...reinsurerState.reinsurer.quoteFields,
                quoteReinstatements: reinstatement,
              },
              riskAssignedLinesLink: assignedLines,
              newCededLayerForViewID,
            },
            new: false,
            dirty: false,
          },
        },
        newState
      )
      Object.values(modifiedState.entities).forEach(reinsurer => {
        if (!reinsurer) {
          return
        }
        modifiedState = adapter.updateOne(
          {
            id: reinsurer.reinsurer.id,
            changes: {
              reinsurer: {
                ...reinsurer.reinsurer,
              },
              new: false,
              dirty: false,
            },
          },
          modifiedState
        )
      })
      return modifiedState
    }
  ),

  on(fromReinsurerActions.deleteQuoteReinsurerSuccess, (state, { id }) => {
    if (!state.entities[id]) {
      return state
    }
    return adapter.removeOne(id, state)
  }),

  on(
    fromReinsurerActions.deleteUnsavedQuoteReinsurer,
    (state, { reinsurer }) => {
      if (!state.entities[reinsurer.id]) {
        return state
      }
      return adapter.removeOne(reinsurer.id, state)
    }
  ),

  on(
    fromReinsurerActions.updateQuoteFields,
    (state: State, { id, change, isSection }) => {
      const reinsurerState = state.entities[id]
      if (!reinsurerState) {
        return state
      }

      let newState = { ...state }
      if (!isSection) {
        newState = {
          ...state,
          selected: id,
        }
      }

      return adapter.updateOne(
        {
          id,
          changes: {
            reinsurer: {
              ...reinsurerState.reinsurer,
              quoteFields: {
                ...reinsurerState.reinsurer.quoteFields,
                ...change,
              },
            },
            dirty: true,
          },
        },
        newState
      )
    }
  ),

  // fetch Reinsurers
  on(
    fromReinsurerActions.fetchQuoteReinsurerByLayerRefSuccess,
    (state, { reinsurers }) => {
      const riskSubjectivityLinks: ReinsurerSubjectivity[] = [
        ...(state.quoteRiskSubjectivityLinks ?? []),
      ]
      reinsurers.forEach(re => {
        re.riskSubjectivityLink?.forEach(sub => {
          if (sub.riskSubjectivity.applyToStructureForSameMarket) {
            riskSubjectivityLinks.push(sub)
          }
        })
      })
      return adapter.addMany(
        reinsurers.map(r => ({
          reinsurer: r,
          dirty: false,
          new: false,
        })),
        {
          ...state,
          loading: false,
          error: null,
          saving: false,
          selected:
            state.selected
              ? state.selected
              : null,
          quoteRiskSubjectivityLinks: riskSubjectivityLinks,
        }
      )
    }
  ),

  on(
    fromReinsurerActions.deleteSubjectivitySuccess,
    (state, { id, reinsurerID }) => {
      const reinsurerState = state.entities[reinsurerID]
      if (
        !state.quoteRiskSubjectivityLinks ||
        !reinsurerState?.reinsurer.riskSubjectivityLink
      ) {
        return state
      }

      const updatedQuoteRiskSubjectivityLinks = [
        ...state.quoteRiskSubjectivityLinks,
      ].filter(
        sub =>
          sub.riskReinsurerId !== reinsurerID && sub.riskSubjectivityId !== id
      ) /* remove deleted subjectivity from the shared 'pool' of subjectivities */
      return {
        ...state,
        quoteRiskSubjectivityLinks: updatedQuoteRiskSubjectivityLinks,
      }
    }
  ),

  on(
    fromReinsurerActions.saveSubjectivitiesArraySuccess,
    fromReinsurerActions.updateSubjectivitiesArray,
    (state, { id, subjectivity, bypassFOT }) => {
      const reinsurerState = state.entities[id]
      if (!reinsurerState) {
        return state
      }
      let riskSubjectivityLink = clone(subjectivity)
      if (
        reinsurerState.reinsurer.quoteReinsurerName?.includes('FOT') &&
        !bypassFOT
      ) {
        reinsurerState.reinsurer.riskSubjectivityLink?.forEach(sub => {
          if (
            !riskSubjectivityLink
              .map(s => s.riskSubjectivityId)
              .includes(sub.riskSubjectivityId)
          ) {
            riskSubjectivityLink.push(sub)
          }
        })
      }
      return adapter.updateOne(
        {
          id,
          changes: {
            reinsurer: {
              ...reinsurerState.reinsurer,
              riskSubjectivityLink,
            },
            dirty: true,
          },
        },
        {
          ...state,
        }
      )
    }
  ),

  on(fromReinsurerActions.addSubjectivity, (state, { id, sub }) => {
    const reinsurerState = state.entities[id]
    if (!reinsurerState) {
      return state
    }
    const subArray = clone(reinsurerState.reinsurer.riskSubjectivityLink)
    subArray?.push({
      riskReinsurerId: id,
      riskSubjectivityId: sub.id,
      riskSubjectivity: sub,
    })
    return adapter.updateOne(
      {
        id,
        changes: {
          reinsurer: {
            ...reinsurerState.reinsurer,
            riskSubjectivityLink: subArray,
          },
          dirty: true,
        },
      },
      {
        ...state,
        selected: id,
      }
    )
  }),

  on(
    fromReinsurerActions.updateAssignedLinesArray,
    (state, { id, assignedLines }) => {
      const reinsurerState = state.entities[id]
      if (!reinsurerState) {
        return state
      }
      return adapter.updateOne(
        {
          id,
          changes: {
            reinsurer: {
              ...reinsurerState.reinsurer,
              riskAssignedLinesLink: assignedLines,
            },
            dirty: true,
          },
        },
        {
          ...state,
        }
      )
    }
  ),

  on(
    fromReinsurerActions.updateAssignedLinesArrayFromWS,
    (state, { id, assignedLines }) => {
      const reinsurerState = state.entities[id]
      if (!reinsurerState) {
        return state
      }

      return adapter.updateOne(
        {
          id,
          changes: {
            reinsurer: {
              ...reinsurerState.reinsurer,
              riskAssignedLinesLink: assignedLines,
            },
            dirty: true,
          },
        },
        {
          ...state,
          selected: id,
        }
      )
    }
  ),

  on(fromReinsurerActions.addAssignedLines, (state, { id, al }) => {
    const reinsurerState = state.entities[id]
    if (!reinsurerState) {
      return state
    }
    const alArray = clone(reinsurerState.reinsurer.riskAssignedLinesLink) || []
    alArray?.push(al)
    return adapter.updateOne(
      {
        id,
        changes: {
          reinsurer: {
            ...reinsurerState.reinsurer,
            riskAssignedLinesLink: alArray,
          },
          dirty: true,
        },
      },
      {
        ...state,
        selected: id,
      }
    )
  }),

  on(fromReinsurerActions.updateSelectedID, (state: State, { id }) => ({
    ...state,
    selected: id,
  })),

  on(fromReinsurerActions.expandClick, (state: State, { isOpen, name }) => ({
    ...state,
    isExpand: isOpen,
    expandedReinsurerName: name,
  })),

  on(
    fromReinsurerActions.populateFromTo,
    (state, { fromID, toID, isSection }) => {
      const fromReinsurer = state.entities[fromID]
      const toReinsurer = state.entities[toID]
      if (!fromReinsurer || !toReinsurer) {
        return state
      }
      let newState: State = { ...state }
      if (!isSection) {
        newState = {
          ...state,
          selected: toID,
        }
      }
      return adapter.updateOne(
        {
          id: toID,
          changes: {
            reinsurer: {
              ...toReinsurer.reinsurer,
              quoteFields: {
                ...fromReinsurer.reinsurer.quoteFields,
                quoteReinstatements:
                  fromReinsurer.reinsurer.quoteFields?.quoteReinstatements?.map(
                    ({ id, ...rest }) => rest
                  ),
              },
            },
            dirty: true,
          },
        },
        newState
      )
    }
  ),

  on(fromReinsurerActions.declineQuoteReinsurer, (state, { reinsurer }) => {
    const reinsurerState = state.entities[reinsurer.id]
    if (!reinsurerState) {
      return state
    }
    return adapter.updateOne(
      {
        id: reinsurer.id,
        changes: {
          reinsurer: {
            ...reinsurerState.reinsurer,
            decline: true,
            declineReason: reinsurer.declineReason,
          },
          dirty: true,
        },
      },
      {
        ...state,
        selected: reinsurer.id,
      }
    )
  }),

  on(fromReinsurerActions.preferredQuoteReinsurer, (state, { reinsurer }) => {
    const reinsurerState = state.entities[reinsurer.id]
    if (!reinsurerState) {
      return state
    }
    return adapter.updateOne(
      {
        id: reinsurer.id,
        changes: {
          reinsurer: {
            ...reinsurerState.reinsurer,
            isPreferred: reinsurer.isPreferred,
          },
          dirty: true,
        },
      },
      state
    )
  }),

  on(fromReinsurerActions.exportToggleReinsurer, (state, { reinsurer }) => {
    const reinsurerState = state.entities[reinsurer.id]
    if (!reinsurerState) {
      return state
    }
    return adapter.updateOne(
      {
        id: reinsurer.id,
        changes: {
          reinsurer: {
            ...reinsurerState.reinsurer,
            exportToggle: reinsurer.exportToggle,
          },
          dirty: true,
        },
      },
      {
        ...state,
        selected: reinsurer.id,
      }
    )
  }),

  on(fromReinsurerActions.slidingValueUpdate, (state, { id, slidingTable }) => {
    const reinsurerState = state.entities[id]
    if (!reinsurerState) {
      return state
    }
    return adapter.updateOne(
      {
        id,
        changes: {
          reinsurer: {
            ...reinsurerState.reinsurer,
            slidingScale: slidingTable,
          },
          dirty: true,
        },
      },
      {
        ...state,
        selected: id,
      }
    )
  }),
  on(startAnalysis, state => {
    return adapter.removeAll(state)
  }),

  on(
    fromReinsurerActions.setSubAlReNameAndId,
    (state: State, { reName, ralId }) => ({
      ...state,
      assignedLineReName: reName,
      assignedLineReId: ralId,
    })
  )
)

export function reducer(state: State | undefined, action: Action) {
  return ReinsurerReducer(state, action)
}
