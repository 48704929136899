import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { ColumnFormat } from 'src/app/admin/dashboard/admin.models'
import {
  BenchmarkDataRefreshStatus,
  BenchmarkDataRefreshStatusSummary,
} from '../../model/benchmark.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-benchmark-data-availability',
  styleUrls: ['./benchmark-data-availability-dialog.component.scss'],
  templateUrl: './benchmark-data-availability-dialog.component.html',
})
export class BenchmarkDataAvailabilityDialogComponent {
  filersDataSource: MatTableDataSource<BenchmarkDataRefreshStatusSummary>
  filersColumns: ColumnFormat[] = [
    {
      actual: [
        'name',
        'approxCompaniesFiledCount',
        'approxTotalCompaniesCount',
        'approxCompaniesFiledPercentage',
        'approxNpeCompaniesFiledPercentage',
      ],
      display: [
        'Company Name',
        '# of Companies Filed',
        '# of Total Companies',
        '% of Companies Filed',
        '% of Companies With By-Line Underwriting Data'
      ],
    },
  ]

  // This table has no headers.
  keyDateDataSource: MatTableDataSource<{ key: string; date: string }> =
    new MatTableDataSource<{ key: string; date: string }>([
      {
        key: 'Individual Company Data, Excluding By-Line Underwriting',
        date: 'Mid March',
      },
      {
        key: 'Group Data, Excluding By-Line Underwriting',
        date: 'Late March',
      },
      {
        key: 'Industry Aggregate Data',
        date: 'Mid April',
      },
      {
        key: 'By-Line Underwriting Data',
        date: 'Early May',
      },
    ])
  keyDateColumns: ColumnFormat[] = [
    {
      actual: ['key', 'date'],
      display: ['', ''],
    },
  ]

  @Input() dataRefreshStatus: BenchmarkDataRefreshStatus

  constructor(
    public dialogRef: MatDialogRef<
      BenchmarkDataAvailabilityDialogComponent,
      boolean
    >,
    public cdr: ChangeDetectorRef
  ) {
    this.initDataSource()
  }

  ngOnInit(): void {
    this.initDataSource()
  }

  ngOnChanges(): void {
    this.initDataSource()
  }

  initDataSource(): void {
    if (this.dataRefreshStatus?.summary) {
      this.filersDataSource =
        new MatTableDataSource<BenchmarkDataRefreshStatusSummary>(
          this.dataRefreshStatus?.summary
        )
    }
  }

  close(): void {
    this.dialogRef.close()
  }
}
