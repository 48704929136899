import { DatePipe } from '@angular/common'
import { inject, Injectable } from '@angular/core'
import { BASE_DIMENSIONS } from '@graphing/utils/coord'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { rejectNil } from '@shared/util/operators'
import { format } from 'date-fns'
import { forkJoin, of } from 'rxjs'
import {
  concatMap,
  debounceTime,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { CompareExportService } from '../../group/services/compare-export-service'
import {
  CompareMetricCategory,
  CompareMetricGrossCategory,
  CompareMetricValue,
} from 'src/app/analysis/model/compare-metrics.model'
import { convertFromLogicalPortfolioLayers } from 'src/app/analysis/model/layers.converter'
import { Layer } from 'src/app/analysis/model/layers.model'
import {
  AggregationMethodType,
  Perspective,
} from 'src/app/analysis/model/metrics.model'
import { PortfolioType } from 'src/app/analysis/model/portfolio-metrics.model'
import { extractPortfolioSetID } from 'src/app/analysis/model/portfolio-set-id.util'
import {
  selectCompareCurrency,
  selectCompareEntities,
  selectCompareGrossSelected,
} from 'src/app/analysis/store/analysis.selectors'
import { setCompareMetricProbabilityValues } from 'src/app/analysis/store/compare/compare-metric-settings/compare-metric-settings.actions'
import { createGrossCompareMetricCategory } from 'src/app/analysis/store/compare/compare-metric-settings/create-compare-metric-values'
import * as CompareActions from 'src/app/analysis/store/compare/compare.actions'
import { CompareEntity } from 'src/app/analysis/store/compare/compare.reducer'
import { getLayerTypeLimit } from 'src/app/analysis/store/metrics/calculations'
import { fetchPortfolioView } from 'src/app/analysis/store/views/portfolio-view.actions'
import {
  AnalysisProfile,
  LogicalPortfolioLayer,
  Metrics,
} from 'src/app/api/analyzere/analyzere.model'
import { AnalyzreService } from 'src/app/api/analyzere/analyzre.service'
import { mergeMapWithInput, rejectErrorWithInput } from 'src/app/api/util'
import { MetricValueType } from 'src/app/core/model/metric-value-type.model'
import { AppState } from 'src/app/core/store'
import { selectAccountOpportunities } from 'src/app/core/store/accountopportunity.selectors'
import * as fromBroker from '../../../core/store/broker/broker.selectors'
import {
  selectCurrentAnalysisProfile,
  selectCurrentClient,
} from '../../../core/store/broker/broker.selectors'
import { hideMetric } from 'src/app/metrics/metrics.util'
import { AggLayers } from '../../model/layer-palette.model'
import { isSwingLayer } from '../../layers/swing-layer'

const LOCAL_STORAGE_BASE = 'sage.compareStructureOptions.'

export interface PortfolioViewMetricsAndActionCompare {
  metrics: Record<string, Metrics[]>
  aggregationMethod: AggregationMethodType
  perspective: Perspective
  portfolioType: PortfolioType
  returnPeriod1: number
  returnPeriod2: number
  returnPeriod3: number
  returnPeriod4: number
  returnPeriod5: number
  id: string
}

export type Value = {
  value: string | number
  valueType: MetricValueType
  isCategory?: boolean
  category?: string
}

export type RValue = {
  category?: string
  value: Value
}

@Injectable()
export class CompareEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: AnalyzreService,
    private compareExportService: CompareExportService,
    private datePipe: DatePipe
  ) {}

  fetchLayersOnAddProgram$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(CompareActions.addProgramToCompare),
        map(action => action.program),
        mergeMapWithInput(program =>
          this.service.fetchPortfolio(program.cededPortfolioID)
        ),
        rejectErrorWithInput((error, program) =>
          this.store.dispatch(
            CompareActions.addProgramToCompareFailure({ id: program.id, error })
          )
        ),
        concatMap(res =>
          of(res).pipe(
            withLatestFrom(this.store.pipe(select(selectCurrentClient)))
          )
        ),
        map(([[portfolio, program], client]) => {
          const portfolioSetID = extractPortfolioSetID(program)
          if (!client || !portfolioSetID) {
            throw Error(
              'Cannot add compare program without portfolio, yearID and study IDs'
            )
          }
          const clientID = client.id
          const studyID = program.studyID
          const portfolioSetAndStudyIDs = {
            ...portfolioSetID,
            clientID,
            studyID,
          }
          return { portfolio, program, portfolioSetAndStudyIDs }
        }),
        withLatestFrom(this.store.pipe(select(selectCurrentAnalysisProfile))),
        mergeMapWithInput(([data, analysisProfile]) => {
          const layerIDs: string[] = []
          data.portfolio.layers.forEach((layer: any) => {
            if (layer.meta_data.backAllocatedForID) {
              layerIDs.push(layer.meta_data.backAllocatedForID)
            }
            layerIDs.push(layer.id)
          })

          const currency = (analysisProfile as AnalysisProfile)
            .exchange_rate_profile.exchange_rate_table.base_currency

          const layerViewIDs = this.service.postLayersViews(
            layerIDs,
            data.program.analysisID,
            [currency]
          )

          const portfolioViewIDs = this.service.postPortfolioView(
            data.program.cededPortfolioID,
            data.program.grossPortfolioID,
            data.program.netPortfolioID,
            data.program.analysisID,
            currency
          )
          return forkJoin([layerViewIDs, portfolioViewIDs]).pipe(
            map(results => {
              return {
                data: {
                  // tslint:disable-next-line: no-non-null-assertion
                  layerViews: results[0].data!,
                  // tslint:disable-next-line: no-non-null-assertion
                  portfolioViews: results[1].data!,
                  data,
                },
              }
            })
          )
        }),
        rejectErrorWithInput((error, [data]) =>
          this.store.dispatch(
            CompareActions.addProgramToCompareFailure({
              id: data.program.id,
              error,
            })
          )
        )
      )
      .pipe(
        map(([data]) => {
          const layers: any[] = []
          if (data && data.layerViews) {
            data.layerViews.forEach((layer: any) => {
              layers.push(layer)
            })
          }
          let probAttachment: number
          let probExhaust: number
          layers.forEach((layer, i) => {
            let actualLayer = layer
            if (layer.layer.meta_data.backAllocatedForID) {
              actualLayer = data.layerViews.find(
                layerView =>
                  layerView.layerID === layer.layer.meta_data.backAllocatedForID
              )
            }
            let layerLimit = actualLayer.layer.sink
            if (actualLayer.layer && isSwingLayer(actualLayer.layer)) {
              layerLimit =
                actualLayer.layer.sources &&
                actualLayer.layer.sources.length > 0
                  ? actualLayer.layer.sources[0].sink
                  : actualLayer.layer.sink
            }
            const limit = getLayerTypeLimit(layerLimit, 'Occ')
            const aggLimit = getLayerTypeLimit(layerLimit, 'Agg')
            this.service
              .getLayersViewMetrics(
                actualLayer.id,
                limit,
                aggLimit,
                data.portfolioViews.grossPortfolioView.id,
                undefined,
                undefined,
                undefined,
                undefined,
                actualLayer.layer.meta_data.sage_layer_type
              )
              .subscribe(res => {
                if (res.data) {
                  if (probAttachment) {
                    if (
                      probAttachment < res.data.entryProbability.probability
                    ) {
                      probAttachment = res.data.entryProbability.probability
                    }
                  } else {
                    probAttachment = res.data.entryProbability.probability
                  }
                  let prob = res.data.exitProbability.probability
                  if (
                    AggLayers.includes(
                      actualLayer.layer.meta_data.sage_layer_type as string
                    )
                  ) {
                    prob = res.data.exitAggProbability.probability
                  }
                  if (probExhaust) {
                    if (probExhaust > prob) {
                      probExhaust = prob
                    }
                  } else {
                    probExhaust = prob
                  }
                  if (i === layers.length - 1) {
                    this.store.dispatch(
                      setCompareMetricProbabilityValues({
                        probabilityExhaust: probExhaust,
                        probabilityAttachment: probAttachment,
                        programID: parseFloat(data.data.program.id),
                      })
                    )
                  }
                }
              })
          })
          return {
            portfolio: data.data.portfolio,
            program: data.data.program,
            portfolioSetAndStudyIDs: data.data.portfolioSetAndStudyIDs,
          }
        }),
        mergeMap(({ portfolio, program, portfolioSetAndStudyIDs }) => {
          const id = program.id
          const cededLayers: Layer[] = convertFromLogicalPortfolioLayers(
            portfolio.layers as LogicalPortfolioLayer[]
          )
          const actions = [
            CompareActions.addProgramToCompareSuccess({ id, cededLayers }),
            fetchPortfolioView({
              ...portfolioSetAndStudyIDs,
              isGroupCompare: true,
              currency: program.structureCurrency,
            }),
          ]
          return actions
        })
      )
  })

  exportAsExcel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompareActions.exportCompareAsExcel),
        withLatestFrom(
          this.store.pipe(select(selectCompareEntities)),
          this.store.pipe(select(selectCompareGrossSelected)),
          this.store.pipe(select(fromBroker.selectCurrentExchangeRateCurrency)),
          this.store.pipe(select(selectCompareCurrency))
        ),
        map(
          ([_, entities, grossSelected, analysisCurrency, compareCurrency]) => {
            const headers: Value[] = [
              { value: 'Metric', valueType: 'text' },
              { value: 'Weighted Average', valueType: 'text' },
            ]
            const columns: Value[][] = []
            const currencies: string[] = []

            // Gross Metrics column
            let compareMetricsGrossCategory: CompareMetricGrossCategory[] = []
            if (grossSelected && entities.length > 0) {
              const firstEntity = entities[0]
              const grossCategories =
                (firstEntity.metricCategories &&
                  firstEntity.metricCategories.filter(
                    m => m.category === 'Gross Metrics'
                  )) ||
                []

              if (grossCategories.length !== 0) {
                const notGrossMetrics =
                  (firstEntity.metricCategories &&
                    firstEntity.metricCategories.filter(
                      m => m.category !== 'Gross Metrics'
                    )) ||
                  []

                compareMetricsGrossCategory = createGrossCompareMetricCategory(
                  grossCategories,
                  notGrossMetrics
                )

                const grossLabel: Value = {
                  value: 'Gross',
                  valueType: 'text',
                }

                const columnData: Value[] = [grossLabel]

                // Add weighted average to Gross column
                columnData.push({ value: '', valueType: 'text' })

                compareMetricsGrossCategory.map(({ metrics }) => {
                  const values = this.getValues(
                    metrics as CompareMetricValue[][]
                  )

                  // Check if all values for this metrics has show = false. If so, then use this flag to remove this header from export
                  const allElementsHaveFalseShow = values.every(row =>
                    row.every(element => element.show === false)
                  )
                  if (!allElementsHaveFalseShow) {
                    columnData.push({ value: '', valueType: 'text' })
                  }
                  values.map(rowValues => {
                    const { show, value, valueType } = rowValues[0]
                    if (show) {
                      columnData.push({ value, valueType })
                    }
                  })
                })

                columns.push(columnData)
                currencies.push(
                  compareCurrency ||
                    firstEntity.program.structureCurrency ||
                    analysisCurrency ||
                    'USD'
                )
              }
            }

            entities.map((e, i) => {
              // This could be: e?.metricCategories.filter(m => m.category !== 'Gross Metrics') || []
              const metricCategories = this.getMetricCategories(entities, i)

              const programLabel: Value = {
                value: e.program.label,
                valueType: 'text',
              }

              const metricsWeight: Value = {
                value: this.getMetricsWeight(metricCategories),
                valueType: 'numeric',
              }

              const columnData: Value[] = [programLabel, metricsWeight]

              metricCategories.map(({ category, metrics }) => {
                if (i === 0) {
                  headers.push({
                    value: category.toUpperCase(),
                    valueType: 'text',
                    isCategory: true,
                  })
                }

                const values = this.getValues(metrics)

                // Check if all values for this metrics has show = false. If so, then use this flag to remove this header from export
                const allElementsHaveFalseShow = values.every(row =>
                  row.every(element => element.show === false)
                )
                if (!allElementsHaveFalseShow) {
                  columnData.push({ value: '', valueType: 'text' })
                }

                values.map(rowValues => {
                  const {
                    show,
                    label,
                    value,
                    valueType,
                    category: categoryFromValues,
                  } = rowValues[0]

                  if (show) {
                    if (i === 0) {
                      headers.push({
                        value: `${label}--${categoryFromValues}`,
                        valueType: 'text',
                        category: categoryFromValues,
                      })
                    }
                    columnData.push({ value, valueType })
                  }
                })

                if (allElementsHaveFalseShow) {
                  if (i === 0) {
                    headers.splice(-1, 1)
                  }
                }
              })

              columns.push(columnData)
              currencies.push(
                compareCurrency ||
                  e.program.structureCurrency ||
                  analysisCurrency ||
                  'USD'
              )
            })

            const convertedRows: Array<Record<string, RValue>> = []

            columns.forEach(c => {
              const record: Record<string, RValue> = {}
              headers.forEach(({ value, category }, i) => {
                record[value] = { value: c[i], category }
              })
              convertedRows.push(record)
            })

            return { convertedRows, headers, currencies, entities }
          }
        ),
        withLatestFrom(
          this.store.pipe(select(selectCurrentClient)),
          this.store.pipe(select(fromBroker.selectCurrentStudy)),
          this.store.pipe(select(selectAccountOpportunities)),
          this.store.pipe(select(fromBroker.selectCurrentYearStudies))
        ),
        tap(
          ([
            { convertedRows, headers, currencies, entities },
            currentClient,
            currentStudy,
            accOpportunities,
            studies,
          ]) => {
            const studyFound = studies.find(
              s => s.id === entities[0].program.studyID
            )

            const accOpp = accOpportunities?.find(
              opp =>
                opp.id ===
                (currentStudy?.opportunity_id || studyFound?.opportunity_id)
            )

            const oppDate = accOpp?.opportunityInceptionDate
            let effectiveDate = ''
            if (oppDate) {
              const parts = oppDate.split('-')
              const date = new Date(
                parseInt(parts[0], 10),
                parseInt(parts[1], 10) - 1,
                parseInt(parts[2], 10)
              ).toString()

              effectiveDate = this.datePipe.transform(date, 'longDate') || ''
            }

            const clientName = currentClient?.name ?? ''
            const opportunityName =
              currentStudy?.name ?? (studyFound?.name || '')
            const currentDate = format(Date.now(), 'MMMM dd yyyy')
            const fileName = `${clientName} ${opportunityName} Comparison as of ${currentDate}.xlsx`

            this.compareExportService.exportXLSX(
              fileName,
              clientName,
              opportunityName,
              effectiveDate,
              convertedRows,
              headers,
              currencies
            )
          }
        )
      ),
    {
      dispatch: false,
    }
  )

  storeOnSetDimensionProp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompareActions.setCompareStructureOptionsDimensionProp),
        debounceTime(100),
        tap(({ dimension, prop }) => {
          localStorage.setItem(`${LOCAL_STORAGE_BASE}${dimension}`, prop)
        })
      ),
    { dispatch: false }
  )

  restoreSettingsFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompareActions.restoreCompareStructureOptionsSettings),
      debounceTime(100),
      map(() => [] as Action[]),
      concatMap(() =>
        rejectNil(
          BASE_DIMENSIONS.map(dimension => {
            const path = `${LOCAL_STORAGE_BASE}${dimension}`
            const val = localStorage.getItem(path)
            if (val) {
              const prop = val as string
              return CompareActions.setCompareStructureOptionsDimensionProp({
                dimension,
                prop,
              })
            }
          })
        )
      )
    )
  )

  private getMetricCategories(
    entities: CompareEntity[],
    index: number
  ): CompareMetricCategory[] {
    return (
      (entities[index] &&
        entities[index].metricCategories &&
        entities[index].metricCategories.filter(
          m => m.category !== 'Gross Metrics'
        )) ||
      []
    )
  }

  private getValues(metrics: CompareMetricValue[][]) {
    const returnMetrics: CompareMetricValue[][] = JSON.parse(
      JSON.stringify(metrics)
    )
    const hideIndex = metrics.findIndex((value: CompareMetricValue[]) => {
      return hideMetric(value[0])
    })
    if (hideIndex !== -1) {
      returnMetrics.splice(hideIndex, 1)
    }
    return returnMetrics
  }

  private getMetricsWeight(categories: CompareMetricCategory[]): number {
    const total = categories.reduce(
      (weight: number, category) =>
        category.metrics.reduce(
          (innerWeight: number, metric) =>
            // tslint:disable-next-line: no-non-null-assertion
            innerWeight + metric[0].rank! * (metric[0].weight / 100),
          weight
        ),
      0
    )
    return Math.round(total * 100) / 100
  }
}
