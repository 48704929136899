<h1 mat-dialog-title>Create Submission</h1>
<div mat-dialog-content>
  <app-layer-property
    [type]="'dropdown'"
    [name]="'Transaction'"
    [value]="transactionId"
    [references]="references"
    (inputChange)="transactionId = $event"
  ></app-layer-property>
  <app-layer-property
    [type]="'text'"
    [name]="'Label'"
    [value]="label"
    (inputChange)="label = $event"
  ></app-layer-property>
  <app-layer-property
    [type]="'text'"
    [name]="'Description'"
    [value]="description"
    (inputChange)="description = $event"
  ></app-layer-property>
  <mat-form-field floatLabel="always">
    <mat-label>Users to Receive Submission</mat-label>
    <input
      matInput
      placeholder="Type to add user..."
      [formControl]="filterCtrl"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="autoUsers"
    />
    <mat-autocomplete #autoUsers="matAutocomplete">
      <ng-container *ngFor="let user of filteredUsers | async">
        <mat-option [value]="user.id">
          <mat-checkbox
            [checked]="selectedUsers.indexOf(user) > -1"
            (click)="$event.stopPropagation()"
            (change)="toggleUser(user)"
          >
            {{ user.first_name }} {{ user.last_name }} ({{ user.email_id }})
          </mat-checkbox>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-chip-grid #chipGrid>
    <mat-chip-row
      class="app-chip-palette app-palette-body"
      *ngFor="let user of selectedUsers"
      (removed)="toggleUser(user)"
      >{{ user.first_name }} {{ user.last_name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
  </mat-chip-grid>
  <mat-progress-bar
    *ngIf="creatingSubmissions"
    color="accent"
    mode="indeterminate"
  ></mat-progress-bar>
</div>
<div mat-dialog-actions align="start">
  <button
    appButton
    border
    accent
    [disabled]="selectedUsers.length < 1"
    (click)="onCreateClick()"
  >
    Create
  </button>
  <button appButton link mat-dialog-close>Cancel</button>
</div>
