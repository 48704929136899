import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core'
import { Dictionary } from '@ngrx/entity'
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper-v-13'
import { Program } from '../../../core/model/program.model'
import { ClientYear } from '../../../core/model/client.model'
import { getPaletteClassByIndex } from '../../../core/model/palette'
import { Study } from '../../../core/model/study.model'
import { WindowSize } from '../../../core/store/app.actions'
import {
  InuranceTag,
  InuranceTagsByLevel,
  InuranceView,
} from '../../model/inurance.model'
import {
  GroupBar,
  GrouperSlide,
  GroupScenariosDialogData,
  GroupScenariosModel,
  ProgramGroup,
  ProgramGroupMember,
  SharedIDGroup,
} from '../../store/grouper/program-group.model'
import { AddProgramGroupProps } from '../../store/grouper/program-group/program-group.actions'
import { ProgramGroupEntity } from '../../store/grouper/program-group/program-group.reducer'
import {
  AddProgramProps,
  MoveProgramProps,
} from '../../store/grouper/program/program.actions'
import { ProgramEntity } from '../../store/grouper/program/program.reducer'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { CurrencyRate } from '../../tower/mechanics/tower.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-group-towers',
  styleUrls: ['./group-towers.component.scss'],
  templateUrl: './group-towers.component.html',
})

export class GroupTowersComponent implements OnChanges {
  @Input() dirty: boolean
  @Input() years: readonly ClientYear[]
  @Input() programsByID: Dictionary<Program>
  @Input() selectedProgramIDs?: readonly string[]
  @Input() entities: ProgramEntity[]
  @Input() filteredEntities: ProgramEntity[]
  @Input() slides: GrouperSlide[]
  @Input() groups: ProgramGroup[]
  @Input() groupsByID: Dictionary<ProgramGroupEntity>
  @Input() groupBarsByID: Record<string, GroupBar>
  @Input() minimizedByID: Record<string, boolean>
  @Input() analysisProfileID: string | null
  @Input() studies: readonly Study[]
  @Input() slideIndex: number
  @Input() towerSizePercentage: number
  @Input() size: WindowSize
  @Input() sharedIDGroup: SharedIDGroup[]
  @Input() selectMode: boolean
  @Input() isSharedLimitPanelOpen: boolean
  @Input() isInuranceToolbarOpen: boolean
  @Input() sharedLayers: string[]
  @Input() inuranceSource: InuranceView | null
  @Input() inuranceTarget: InuranceView | null
  @Input() programGroupMembers: ProgramGroupMember[]
  @Input() programGroups: readonly ProgramGroup[]
  @Input() clientProgramGroupMembers: readonly ProgramGroupMember[]
  @Input() groupScenariosByID: Record<string, GroupScenariosModel>
  /** Maps from Layer, Program, and Program Group ID to the corresponding Layer's
   * Inurance views (which includes the inurance terminal—source or target—and
   * tag number to display)
   */
  @Input() inuranceTagsByLevel: InuranceTagsByLevel
  @Input() groupCurrency: string
  @Input() currencyRates: CurrencyRate[]
  @Input() analysisProfileCurrency: string
  allLayers: LayerState[] = []

  @Output() showAggChange = new EventEmitter<{
    entity: ProgramEntity
    value: boolean
  }>()

  @Output() showOccChange = new EventEmitter<{
    entity: ProgramEntity
    value: boolean
  }>()

  @Output() programBarClick = new EventEmitter<void>()
  @Output() programGroupBarClick = new EventEmitter<ProgramGroupEntity>()
  @Output() programGroupAdd = new EventEmitter<AddProgramGroupProps>()
  @Output() programGroupRemove = new EventEmitter<string>()
  @Output() programGroupMinimizeToggle = new EventEmitter<string>()
  @Output() programAdd = new EventEmitter<AddProgramProps>()
  @Output() programRemove = new EventEmitter<Program>()
  @Output() programMove = new EventEmitter<MoveProgramProps>()
  @Output() programMinimize = new EventEmitter<Program>()
  @Output() programRestore = new EventEmitter<Program>()
  @Output() slideIndexChange = new EventEmitter<number>()
  @Output() saveUntitled = new EventEmitter<AddProgramGroupProps>()
  @Output() programGroupDelete = new EventEmitter<GroupBar>()
  @Output() programRemove1 = new EventEmitter<Program>()
  @Output() layerClick = new EventEmitter<{
    entity: ProgramEntity
    layerID: string | null
  }>()
  @Output() inuranceTagClick = new EventEmitter<InuranceTag>()
  @Output() animatedScenarios = new EventEmitter<{ groupID: string }>()
  @Output() groupLayerDetails = new EventEmitter<{
    groupID: string
    groupName: string
  }>()
  @Output() groupScenariosClick = new EventEmitter<GroupScenariosDialogData>()
  @Output() programGroupRename = new EventEmitter<string>()

  @ViewChild(SwiperDirective) swiper: SwiperDirective
  @ViewChild('scrollbarDrag', { static: false }) scrollbarDrag: ElementRef

  get towersWidth(): number {
    return this.size.width - 64 - (this.isSharedLimitPanelOpen ? 281 : 0)
  }

  get showAddButton() {
    return (
      !(this.isInuranceToolbarOpen || this.isSharedLimitPanelOpen) &&
      this.filteredEntities.length !== 0
    )
  }

  get ifLibreGroup() {
    const groups: { id: string | undefined; libre: string | undefined }[] = []
    for (let i = 1; i < this.groups.length; i++) {
      groups.push({ id: this.groups[i].id, libre: 'false' })
    }
    const filteredEntity: {
      id: string | undefined
      libre: string | undefined
      parentGroupID: string | undefined
    }[] = []
    const programs = this.programGroupMembers.filter(p => p.type === 'program')
    const programGroups = this.programGroupMembers.filter(
      p => p.type === 'programGroup'
    )
    programs.forEach(p => {
      this.filteredEntities.forEach(entity => {
        if (p.programID === entity.program.id) {
          filteredEntity.push({
            id: p.programID,
            libre: entity.program.libRE,
            parentGroupID: p.parentGroupID,
          })
        }
      })
    })
    const groupedEntities: { [parentGroupID: string]: any[] } = {}

    for (const { id, libre, parentGroupID } of filteredEntity) {
      if (parentGroupID) {
        if (!groupedEntities[parentGroupID]) {
          groupedEntities[parentGroupID] = []
        }
        groupedEntities[parentGroupID].push({ id, libre })
      }
    }
    for (const groupName in groupedEntities) {
      if (
        groupedEntities[groupName].find(e => e.libre === 'Y') &&
        groupName !== 'untitled_936'
      ) {
        if (groups.find(group => group.id === groupName)) {
          groups[groups.findIndex(group => group.id === groupName)].libre = 'Y'
        }
      }
    }
    programGroups.forEach(p => {
      if (groups.find(e => e.id === p.programGroupID)) {
        const groupsIndex =
          groups[groups.findIndex(e => e.id === p.programGroupID)]
        if (groupsIndex.libre === 'Y') {
          if (p.parentGroupID === 'untitled_2813') {
            groups[groups.findIndex(e => e.id === p.programGroupID)].libre = 'Y'
          } else if (groups.findIndex(e => e.id === p.parentGroupID) !== -1) {
            groups[groups.findIndex(e => e.id === p.parentGroupID)].libre = 'Y'
          }
        }
      }
    })
    return groups
  }

  config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    keyboard: true,
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
      hide: false,
      dragClass: '.scrollbar-drag',
      dragSize: 'auto',
      snapOnRelease: true,
    },
    navigation: true,
    observer: true,
    observeParents: true,
    touchEventsTarget: 'wrapper',
    preventClicks: true,
    simulateTouch: true,
    pagination: false,
    watchOverflow: true,
    touchStartPreventDefault: false,
    mousewheel: {
      invert: true,
      eventsTarget: 'wrapper'
    }
  }

  ngOnChanges() {
    if (this.swiper) {
      this.swiper.setIndex(this.slideIndex)
      this.updateScrollbar()
      this.swiper.update()
    }
    this.filteredEntities = this.filteredEntities.map(a => {
      return { ...a, allLayers: this.getAllProgramLayers(a) }
    })
  }

  updateScrollbar() {
    const dragElement = this.scrollbarDrag.nativeElement
    const totalSlides = this.filteredEntities.length
    const visibleSlides = this.calculateVisibleSlides()
    const dragWidth = (visibleSlides / totalSlides) * this.towersWidth
    const progress = this.calculateProgress()

    dragElement.style.width = `${dragWidth}px`
    dragElement.style.transform = `translateX(${progress * (this.towersWidth - dragWidth)}px)`
  }

  calculateVisibleSlides(): number {
    const containerWidth = this.towersWidth
    const slideWidth = containerWidth / Math.min(this.slides.length, 3)
    return Math.floor(containerWidth / slideWidth)
  }

  calculateProgress(): number {
    const currentIndex = this.slideIndex
    const totalSlides = this.filteredEntities.length
    return currentIndex / (totalSlides - this.calculateVisibleSlides())
  }

  getAllProgramLayers(e: ProgramEntity) {
    return this.entities.find(e1 => e1.program.id === e.program.id)?.cededLayers
  }

  getTowerWidth(i: number): number {
    const slide = this.slides[i]
    const multiplier = slide.isGroupAgg ? 0.5 : 1
    const width = this.towersWidth * this.towerSizePercentage * multiplier - 12
    return slide.isGroupAgg ? Math.max(width, 200) : width
  }

  getTowerHeight(i: number): number {
    const bars = this.slides[i].groupBars.length
    return this.size.height - 280 - 36 * (bars + 1)
  }

  getSlideStyle(index: number) {
    const slide = this.slides[index]
    const programMin = slide.minimized
    const minGroupBar = this.getMinimizedGroupBar(index)
    const min = programMin || minGroupBar != null

    let width: string
    let minWidth: string | undefined
    let overflow: string | undefined
    if (minGroupBar) {
      let w = 50
      if (minGroupBar.first) {
        w += minGroupBar.leftEdges.length > 0 ? 20 : 0
        w += minGroupBar.leftEdges.length * 6
      }
      if (minGroupBar.last) {
        w += minGroupBar.rightEdges.length > 0 ? 20 : 0
        w += minGroupBar.rightEdges.length * 6
      }
      width = `${w}px`
    } else if (programMin) {
      // TODO: Calculate edge widths too (including outer margin)
      let w = 24
      w += (slide.groupBars.length - 1) * 6 + 25 * 2
      width = `${w}px`
      overflow = min && 'hidden'
    } else {
      const multiplier = slide.isGroupAgg ? 50 : 100
      width = `${this.towerSizePercentage * multiplier}%`
      if (slide.isGroupAgg) {
        minWidth = '200px'
      }
      overflow = min && 'hidden'
    }
    if (!minWidth) {
      minWidth = width
    }
    return { width, minWidth, overflow }
  }

  getGroupPaletteClasses(index: number) {
    const minGroupBar = this.getMinimizedGroupBar(index)
    if (minGroupBar) {
      return {
        [getPaletteClassByIndex(minGroupBar.paletteIndex)]: true,
      }
    }
    return { hide: true }
  }

  onProgramRemove(program: Program) {
    this.programRemove1.emit(program)
  }

  getName(entity: ProgramEntity): string {
    const program = entity.program.label
    const study = this.studies.find(s => s.id === entity.program.studyID)
    return study ? `${study.name} – ${program}` : program
  }

  isGroupAgg(index: number): boolean {
    const slide = this.slides[index]
    return slide.isGroupAgg
  }

  isProgramMinimized(entity: ProgramEntity): boolean {
    const programID =
      entity.program.id +
      (entity.program.parentGroupID ? '_' + entity.program.parentGroupID : '')
    return this.minimizedByID[programID] === true
  }

  isGroupMinimized(index: number): boolean {
    const slide = this.slides[index]
    return slide.groupMinimizedIndex != null
  }

  isGroupMinimizedFirstSlide(index: number): boolean {
    const minGroupBar = this.getMinimizedGroupBar(index)
    return minGroupBar != null && minGroupBar.first
  }

  getMinimizedGroupBar(index: number): GroupBar | null {
    const slide = this.slides[index]
    const i = slide.groupMinimizedIndex
    return i ? slide.groupBars[i] : null
  }

  getMinimizeGroupName(index: number): string | undefined {
    const minGroupBar = this.getMinimizedGroupBar(index)
    if (minGroupBar && minGroupBar.first) {
      return minGroupBar && minGroupBar.first && minGroupBar.label
    }
  }

  getLayerData(entity: ProgramEntity) {
    return entity.program.layerData
  }

  showTower(index: number, entity: ProgramEntity): boolean {
    return !this.isGroupMinimized(index) && !this.isProgramMinimized(entity)
  }

  showAgg(entity: ProgramEntity): boolean {
    return entity.program.checkGroup === true
  }

  showOcc(entity: ProgramEntity): boolean {
    return entity.program.checkGroupOcc === true
  }

  setSimulateTouch(value: boolean) {
    this.config.simulateTouch = value
  }

  getSelected(entity: ProgramEntity): string {
    return entity.program.groupSelected || ''
  }

  trackByID(entity: ProgramEntity, index: number) {
    return (entity && entity.program && entity.program.id) || index
  }

  onSelectedLayerID(entity: ProgramEntity, layerID: string) {
    const unfiltered = this.entities.find(
      e => e.program.id === entity.program.id
    ) as ProgramEntity
    this.layerClick.emit({ entity: unfiltered, layerID })
  }

  onProgramMove(props: MoveProgramProps) {
    if (props.fromGroupID !== props.toGroupID) {
      const programIDs = this.programGroupMembers
        .filter(member => member.parentGroupID === props.toGroupID)
        .map(m => {
          return m.programID
        })
      if (programIDs.includes(props.id)) {
        props.isDuplicate = true
      }
    }
    this.programMove.emit(props)
  }
  onProgramAdd(props: AddProgramProps) {
    const programIDs = this.programGroupMembers
      .filter(member => member.parentGroupID === props.parentGroupID)
      .map(m => {
        return m.programID
      })
    if (programIDs.includes(props.program.id)) {
      props.isDuplicate = true
    }
    this.programAdd.emit(props)
  }

  onProgramGroupAdd(props: AddProgramGroupProps) {
    if (this.groups.filter(g => g.id === props.id).length > 0) {
      props.isDuplicate = true
    } else {
      for (const g of this.groups) {
        const def = this.clientProgramGroupMembers.filter(
          member =>
            member.programGroupID === g.id && member.parentGroupID === props.id
        )
        if (def.length > 0) {
          props.isDuplicate = true
          break
        }
      }
    }
    this.programGroupAdd.emit(props)
  }
}
