<section class="add-curve-container">
  <div class="curve-details">
    <div class="label-buttons">
      <form class="curve-details-input" [formGroup]="form">
        <div class="curve-input-wrapper curve-input">
          <mat-form-field appearance="fill">
            <mat-label>Data Label</mat-label>
            <input type="text" matInput formControlName="label" />
          </mat-form-field>
        </div>
        <mat-form-field
          class="curve-input"
          appearance="fill"
          *ngIf="!isEdit || (isEdit && curveType)"
        >
          <mat-label>Select Curve Type</mat-label>
          <mat-select
            (selectionChange)="onDropdownChange()"
            disableOptionCentering
            hideSingleSelectionIndicator
            formControlName="curveType"
          >
            <mat-option>None</mat-option>
            <mat-option
              *ngFor="let option of dropdownOptions"
              [value]="option.value"
            >
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="context === 'credit'" class="curve-input-wrapper">
          <mat-form-field appearance="fill" class="curve-input">
            <mat-label>Minimum Premium</mat-label>
            <input
              type="text"
              matInput
              formatPercentage
              formControlName="minPremium"
              (focus)="onPercentageFieldFocus('minPremium')"
              (blur)="onPercentageFieldBlur('minPremium')"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" class="curve-input">
            <mat-label>Scale Factor</mat-label>
            <input
              type="text"
              matInput
              formControlName="scaleFactor"
            />
          </mat-form-field>
        </div>
      </form>
      <div class="button-bar">
        <div class="split-view-toggle" *ngIf="context === 'pricing-curve'">
          <mat-slide-toggle
            [disabled]="status.isLoading || status.dialogLoading"
            [checked]="workingCurve.layerSplitView"
            (change)="layerSplitViewChanged.emit($event.checked)"
            >Use Split View</mat-slide-toggle
          >
        </div>
        <div
          class="button-container"
          [matTooltip]="disabledTooltip"
          [matTooltipDisabled]="!addLayerPointsDisabled"
        >
          <button
            appButton
            big
            accent
            *ngIf="!isEdit && context === 'pricing-curve'"
            [disabled]="addLayerPointsDisabled"
            (click)="initLayerPoints()"
          >
            <span>Add Layer Points</span>
          </button>
        </div>
        <div class="button-container" *ngIf="context === 'credit'">
          <mat-checkbox
            class="save-curve"
            labelPosition="before"
            [(ngModel)]="saveCurve"
            >Save to DB</mat-checkbox
          >
        </div>
        <div
          class="button-container"
          [matTooltip]="disabledTooltip"
          [matTooltipDisabled]="!fitCurveDisabled"
        >
          <button
            appButton
            big
            accent
            [disabled]="fitCurveDisabled"
            (click)="initFitOrSaveCurve()"
          >
            <span>{{ fitCurveButtonText }}</span>
          </button>
        </div>
      </div>
    </div>
    <div
      class="credit-prediction-selection"
      *ngIf="context === 'credit' && workingCurve.creditPredictionColumns"
    >
      <span>Prediction Columns:</span>
      <div class="prediction-checkboxes">
        <mat-checkbox
          *ngFor="let column of workingCurve.creditPredictionColumns | keyvalue"
          [checked]="column.value.isActive"
          (change)="updatePredictionColumn(column.key, $event)"
          >{{ column.value.label }}</mat-checkbox
        >
      </div>
    </div>
  </div>
  <div class="curve-data">
    <div class="filter-container">
      <app-pricing-curve-filter-bar
        *ngIf="(selectors && dateIntervals) || creditSelectors"
        [selectors]="selectors"
        [creditSelectors]="creditSelectors"
        [dateIntervals]="dateIntervals"
        [status]="status"
        [context]="context"
      >
      </app-pricing-curve-filter-bar>
    </div>
    <div class="client-data-container">
      <div class="table">
        <app-sort-table
          [columnDefs]="addLayerColumnDefs"
          [rows]="layerRows"
          [paginator]="paginator"
          [selectAllColumnID]="'include'"
          (selectedChange)="onSelectedChange($event)"
        ></app-sort-table>
      </div>
      <mat-paginator
        class="paginator"
        showFirstLastButtons
        pageSize="30"
        [pageSizeOptions]="[15, 30, 50]"
      ></mat-paginator>
    </div>
  </div>
</section>
