import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import {
  AGENCY_MARKET_USE,
  AgencyDetails,
  AgencyDetailsSage,
  ProgramFactor,
  Reinsurer,
} from '../../core/model/reinsurer.model'
import { Study } from '../../core/model/study.model'
import { containsObo } from '@shared/util/string'
import { MatCheckboxChange } from '@angular/material/checkbox'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-agency-detail-dialog-component',
  styleUrls: ['./agency-detail.component.scss'],
  templateUrl: './agency-detail.component.html',
})
export class AgencyDetailDialogComponent implements OnInit, OnDestroy {
  isDisabled = true
  isNewOBO = false
  errorMsg = ''
  @Input() reinsurer: Reinsurer
  @Input() reinsurers: Reinsurer[] | null
  @Input() studyID: string | null
  @Input() studies: readonly Study[]
  @Input() reinsurerID: number
  @Input() savingReinsurer: boolean
  @Input() agencyDetails: AgencyDetails[] = []
  @Input() select: string
  newAgencyDetail: AgencyDetailsSage[]
  filteredReinsurers: Reinsurer[] = []

  @Output() setReinsurer = new EventEmitter<{
    programID: string
    reinsurer: Reinsurer
  }>()
  @Output() updateOrAddDirty = new EventEmitter<Reinsurer>()
  @Output() saveClick = new EventEmitter()
  @Output() saveNewOBO = new EventEmitter<AgencyDetailsSage[]>()
  @Output() deselectAgencyRelationship = new EventEmitter<AgencyDetails>()
  @Output() closeDialog = new EventEmitter<string>()

  constructor(public dialogRef: MatDialogRef<AgencyDetailDialogComponent>) {}
  ngOnDestroy(): void {
    if (this.agencyDetails){
      this.closeDialog.emit(this.reinsurer.tpRef)
    }
  }

  get activeAction(): string {
    return this.savingReinsurer ? 'Saving' : ''
  }

  ngOnInit(): void {
    const date = new Date(new Date().getFullYear(), 0, 1)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const c = new Date(year + 30, month, day)

    if (this.agencyDetails){
      // narrow down list of agency relationships to only those that are not already incumbent/targets
      this.agencyDetails = this.agencyDetails.filter(x => !this.isAlreadySelected(x))
    }    

    this.newAgencyDetail = [
      {
        agencyTPRef: this.reinsurer.tpRef,
        agencySeqNumberSAGE: this.getSeqNumber(),
        agencyName: this.reinsurer.name,
        memberTPRef: null,
        memberName: null,
        memberSeqNum: 1,
        effectiveDate: date,
        expiryDate: c,
        memberPct: 100,
        burxStamp: null,
        burxTypeCodeValue: null,
        lorsCode: null,
        type: null,
        doNotUseInd: this.reinsurer.doNotUseInd,
        internalNarr: this.reinsurer.internalNarr,
        approvalStatusCodeValue: 'AUTHRQ',
      },
    ]
  }

  isAlreadySelected(details: AgencyDetails): boolean {
    return this.reinsurers.some(r => r.tpRef === details.agencyTPRef 
      && r.reinsurerProgramFactor 
      && r.reinsurerProgramFactor.length>0 
      && r.reinsurerProgramFactor[0].relation_seq_number === details.agencySeqNumber
      && (r.reinsurerProgramFactor[0].incumbent || r.reinsurerProgramFactor[0].target_market))
  }

  showExistingOBO(): boolean {
    return this.agencyDetails.length > 0
  }

  relationshipChange(details: AgencyDetails, event: MatCheckboxChange): void {
 
    if (!event.checked){
      this.deselectAgencyRelationship.emit(details)
      return
    }

    this.isDisabled = false
    this.isNewOBO = false
    let newReinsurer = this.reinsurer
    const factor: ProgramFactor = {
      appetite: this.reinsurer.reinsurerProgramFactor[0].appetite || null,
      carrier_id: this.reinsurer.reinsurerProgramFactor[0].carrier_id,
      ceded_margin_percentage:
        this.reinsurer.reinsurerProgramFactor[0].ceded_margin_percentage,
      cmp_selected: this.reinsurer.reinsurerProgramFactor[0].cmp_selected,
      elm_selected: this.reinsurer.reinsurerProgramFactor[0].elm_selected,
      expected_loss_multiplier:
        this.reinsurer.reinsurerProgramFactor[0].expected_loss_multiplier,
      incumbent: false,
      is_default: this.reinsurer.reinsurerProgramFactor[0].is_default,
      key_uw_contact: this.reinsurer.reinsurerProgramFactor[0].key_uw_contact,
      market_pricing_factor:
        this.reinsurer.reinsurerProgramFactor[0].market_pricing_factor,
      notes: this.reinsurer.reinsurerProgramFactor[0].notes || null,
      reinsurer_id: this.reinsurer.reinsurerProgramFactor[0].reinsurer_id,
      std_dev_percentage:
        this.reinsurer.reinsurerProgramFactor[0].std_dev_percentage,
      stp_selected: this.reinsurer.reinsurerProgramFactor[0].stp_selected,
      study_id: this.reinsurer.reinsurerProgramFactor[0].study_id,
      target_market: false,
      is_new_relation: false,
      relation_seq_number: details.agencySeqNumber,
      obo_name: null,
      placed_through: this.reinsurer.reinsurerProgramFactor[0].placed_through,
      co_broker: this.reinsurer.reinsurerProgramFactor[0].co_broker,
      co_broker_ref: this.reinsurer.reinsurerProgramFactor[0].co_broker_ref,
      expected_loss_power:
        this.reinsurer.reinsurerProgramFactor[0].expected_loss_power,
      volatility_multiplier:
        this.reinsurer.reinsurerProgramFactor[0].volatility_multiplier,
      fixed_cost: this.reinsurer.reinsurerProgramFactor[0].fixed_cost,
      minimum_rate_on_line:
        this.reinsurer.reinsurerProgramFactor[0].minimum_rate_on_line,
      volatility_metric:
        this.reinsurer.reinsurerProgramFactor[0].volatility_metric,
      reinsurer_margin_percentage:
        this.reinsurer.reinsurerProgramFactor[0].reinsurer_margin_percentage,
      max_ceding_commission_percentage:
        this.reinsurer.reinsurerProgramFactor[0]
          .max_ceding_commission_percentage,
    }
    if (this.select === 'inc') {
      factor.incumbent = true
    } else {
      factor.target_market = true
    }
    let count = 0
    this.agencyDetails.forEach(a => {
      if (a.agencySeqNumber === details.agencySeqNumber) {
        count++
      }
    })
    if (count > 1) {
      factor.obo_name =
        newReinsurer.name + ' obo relation ' + details.agencySeqNumber
    } else {
      const oboDetected =
        containsObo(newReinsurer.name) || containsObo(details.memberName)
      factor.obo_name = `${newReinsurer.name}${
        !oboDetected ? ` obo ${details.memberName}` : ''
      }`
    }
    if (
      !this.reinsurer.reinsurerProgramFactor[0].id ||
      this.reinsurer.reinsurerProgramFactor[0].id === 0
    ) {
      newReinsurer = {
        ...newReinsurer,
        reinsurerProgramFactor: [
          this.reinsurer.reinsurerProgramFactor[0],
          factor,
        ],
      }
    } else {
      newReinsurer = {
        ...newReinsurer,
        reinsurerProgramFactor: [factor],
      }
    }
    this.updateOrAddDirty.emit(newReinsurer)

    this.setReinsurer.emit({
      // tslint:disable-next-line: no-non-null-assertion
      programID: this.studyID!,
      reinsurer: newReinsurer,
    })
  }

  relationshipOBOChange() {
    this.isNewOBO = true
    this.isDisabled = false
  }

  relationshipOBOChangeData() {
    const newSeqNumber = this.getSeqNumber()
    let newReinsurer = this.reinsurer

    const factor: ProgramFactor = {
      appetite: this.reinsurer.reinsurerProgramFactor[0].appetite || null,
      carrier_id: this.reinsurer.reinsurerProgramFactor[0].carrier_id,
      ceded_margin_percentage:
        this.reinsurer.reinsurerProgramFactor[0].ceded_margin_percentage,
      cmp_selected: this.reinsurer.reinsurerProgramFactor[0].cmp_selected,
      elm_selected: this.reinsurer.reinsurerProgramFactor[0].elm_selected,
      expected_loss_multiplier:
        this.reinsurer.reinsurerProgramFactor[0].expected_loss_multiplier,
      incumbent: false,
      is_default: this.reinsurer.reinsurerProgramFactor[0].is_default,
      key_uw_contact: this.reinsurer.reinsurerProgramFactor[0].key_uw_contact,
      market_pricing_factor:
        this.reinsurer.reinsurerProgramFactor[0].market_pricing_factor,
      notes: this.reinsurer.reinsurerProgramFactor[0].notes || null,
      reinsurer_id: this.reinsurer.reinsurerProgramFactor[0].reinsurer_id,
      std_dev_percentage:
        this.reinsurer.reinsurerProgramFactor[0].std_dev_percentage,
      stp_selected: this.reinsurer.reinsurerProgramFactor[0].stp_selected,
      study_id: this.reinsurer.reinsurerProgramFactor[0].study_id,
      target_market: false,
      is_new_relation: true,
      relation_seq_number: newSeqNumber,
      obo_name: null,
      placed_through: this.reinsurer.reinsurerProgramFactor[0].placed_through,
      co_broker: this.reinsurer.reinsurerProgramFactor[0].co_broker,
      co_broker_ref: this.reinsurer.reinsurerProgramFactor[0].co_broker_ref,
      expected_loss_power:
        this.reinsurer.reinsurerProgramFactor[0].expected_loss_power,
      volatility_multiplier:
        this.reinsurer.reinsurerProgramFactor[0].volatility_multiplier,
      fixed_cost: this.reinsurer.reinsurerProgramFactor[0].fixed_cost,
      minimum_rate_on_line:
        this.reinsurer.reinsurerProgramFactor[0].minimum_rate_on_line,
      volatility_metric:
        this.reinsurer.reinsurerProgramFactor[0].volatility_metric,
      reinsurer_margin_percentage:
        this.reinsurer.reinsurerProgramFactor[0].reinsurer_margin_percentage,
      max_ceding_commission_percentage:
        this.reinsurer.reinsurerProgramFactor[0]
          .max_ceding_commission_percentage,
    }
    if (this.select === 'inc') {
      factor.incumbent = true
    } else {
      factor.target_market = true
    }
    if (this.newAgencyDetail.length > 1) {
      factor.obo_name = newReinsurer.name + ' obo relation ' + newSeqNumber
    } else {
      const oboDetected =
        containsObo(newReinsurer.name) ||
        containsObo(this.newAgencyDetail[0].memberName)
      factor.obo_name = `${newReinsurer.name}${
        !oboDetected ? ` obo ${this.newAgencyDetail[0].memberName}` : ''
      }`
    }

    if (
      !this.reinsurer.reinsurerProgramFactor[0].id ||
      this.reinsurer.reinsurerProgramFactor[0].id === 0
    ) {
      newReinsurer = {
        ...newReinsurer,
        reinsurerProgramFactor: [
          this.reinsurer.reinsurerProgramFactor[0],
          factor,
        ],
      }
    } else {
      newReinsurer = {
        ...newReinsurer,
        reinsurerProgramFactor: [factor],
      }
    }

    this.updateOrAddDirty.emit(newReinsurer)

    this.setReinsurer.emit({
      // tslint:disable-next-line: no-non-null-assertion
      programID: this.studyID!,
      reinsurer: newReinsurer,
    })
  }

  relationshipSelected(details: AgencyDetails) {
    if (!this.isNewOBO) {
      return (
        details.agencySeqNumber ===
        this.reinsurer.reinsurerProgramFactor[0].relation_seq_number
      )
    } else {
      return false
    }
  }

  relationshipOBOSelected() {
    if (this.isNewOBO) {
      return true
    }
  }

  isLast(index: number) {
    return index === this.newAgencyDetail.length - 1
  }

  getSeqNumber() {
    if (this.agencyDetails.length > 0) {
      return (
        Math.max(...this.agencyDetails.map(a => a.agencySeqNumber)) + 1 || 1
      )
    } else {
      return 1
    }
  }

  removeRelation(index: number) {
    this.newAgencyDetail.splice(index, 1)
    if (this.newAgencyDetail.length === 0) {
      const date = new Date(new Date().getFullYear(), 0, 1)
      const year = date.getFullYear()
      const month = date.getMonth()
      const day = date.getDate()
      const c = new Date(year + 30, month, day)
      this.newAgencyDetail = [
        {
          agencyTPRef: this.reinsurer.tpRef,
          agencySeqNumberSAGE: this.getSeqNumber(),
          agencyName: this.reinsurer.name,
          memberTPRef: null,
          memberName: null,
          memberSeqNum: 1,
          effectiveDate: date,
          expiryDate: c,
          memberPct: 0,
          burxStamp: null,
          burxTypeCodeValue: null,
          lorsCode: null,
          type: null,
          doNotUseInd: this.reinsurer.doNotUseInd,
          internalNarr: this.reinsurer.internalNarr,
          approvalStatusCodeValue: 'AUTHRQ',
        },
      ]
    }
  }

  addRelation() {
    const date = new Date(new Date().getFullYear(), 0, 1)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const c = new Date(year + 30, month, day)
    this.newAgencyDetail.push({
      agencyTPRef: this.reinsurer.tpRef,
      agencySeqNumberSAGE: this.getSeqNumber(),
      agencyName: this.reinsurer.name,
      memberTPRef: null,
      memberName: null,
      memberSeqNum: this.newAgencyDetail.length + 1,
      effectiveDate: date,
      expiryDate: c,
      memberPct: 0,
      burxStamp: null,
      burxTypeCodeValue: null,
      lorsCode: null,
      type: null,
      doNotUseInd: this.reinsurer.doNotUseInd,
      internalNarr: this.reinsurer.internalNarr,
      approvalStatusCodeValue: 'AUTHRQ',
    })
  }

  onAutocompleteChange(inputValue: string | number) {
    // tslint:disable-next-line: no-non-null-assertion
    this.filteredReinsurers = this.reinsurers!.filter(
      re => re.market_use !== AGENCY_MARKET_USE
    ).filter(r =>
      r.name.toLowerCase().startsWith(String(inputValue).toLowerCase())
    )
  }

  isSaveDisable() {
    if (!this.isNewOBO) {
      if (this.isDisabled) {
        this.errorMsg = 'Please select OBO relationship to proceed'
      }
      this.errorMsg = ''
      return this.isDisabled
    } else {
      if (this.newAgencyDetail.length > 0) {
        let sum = 0
        this.newAgencyDetail.forEach(a => {
          if (!a.memberName || a.memberName === '' || !a.memberPct) {
            this.errorMsg =
              'New OBO relationship is missing reinsurer name or member percentage'
            return true
          }
          sum += a.memberPct
        })
        if (sum !== 100) {
          this.errorMsg =
            'New OBO relationship total percentage should match 100%'
          return true
        }
        this.errorMsg = ''
        return false
      } else {
        this.errorMsg = 'Please add correct details to new OBO relationship'
        return true
      }
    }
  }

  onSaveClick() {
    if (!this.isSaveDisable()) {
      if (!this.isNewOBO) {
        this.saveClick.emit()
      } else {
        this.relationshipOBOChangeData()
        this.newAgencyDetail.forEach(a => {
          const tpRefM = this.reinsurers
            ?.filter(re => re.market_use !== AGENCY_MARKET_USE)
            .find(r => r.name === a.memberName)?.tpRef
          a.memberTPRef = tpRefM || null
        })
        this.saveNewOBO.emit(this.newAgencyDetail)
      }
    }
  }
}
