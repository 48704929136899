import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  Input,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter,
} from '@angular/core'
import { MatMenuTrigger } from '@angular/material/menu'
import {
  TechnicalFactors,
  DialogData,
  PRICING_CURVE_TYPE_OPTIONS,
  CurveTypeOption,
  PricingCurveTypes,
  PricingCurveContextTypes,
  SubmitPricingCurvePayload,
} from '../../../model/pricing-curve.model'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Subject, timer } from 'rxjs'
import { takeUntil, debounce } from 'rxjs/operators'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { PricingCurve } from 'src/app/pricingcurve/model/pricing-curve'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pricing-curve-technical-factors-form',
  styleUrls: ['./pricing-curve-technical-factor-form.component.scss'],
  templateUrl: './pricing-curve-technical-factor-form.component.html',
})
export class PricingCurveTechnicalFactorFormComponent
  implements OnInit, OnDestroy
{
  @Input() workingCurve: PricingCurve
  @Input() dialogData: DialogData
  @Input() isNewCurve: boolean
  @Input() editManual: boolean
  @Input() dataSetId: number
  @Input() currentDataSetNames: string[]
  @Input() labelForm: FormGroup
  @Input() savedCurveNameMap: { id: number; name: string }[]
  @Input() isQuickEdit = false
  @Input() context: PricingCurveContextTypes

  private destroy$ = new Subject()

  @Output() submitCurve = new EventEmitter<SubmitPricingCurvePayload>()
  @Output() techFactorsChanged = new EventEmitter<TechnicalFactors>()

  @ViewChild('volatilityMenu') volatilityMenuTrigger: MatMenuTrigger

  form: FormGroup
  dropdownOptions = PRICING_CURVE_TYPE_OPTIONS
  curveType: CurveTypeOption | undefined
  selectedVolatilityMetric?: string | null
  parentMenuItemValue: string

  cededVar = 'Ceded 250 year Var'
  cededTVar = 'Ceded 250 year TVar'
  saveCurve = true
  editMode = false
  disableELOLMult = false
  disableELOLPow = false
  disableFixedCost = false
  isSaved = false
  minValid = true
  maxValid = true
  minTooltip: string | undefined
  maxTooltip: string | undefined
  inputTechFactors: TechnicalFactors

  constructor(private readonly formBuilder: FormBuilder) {}

  get curveLabel(): string {
    return this.workingCurve.label
  }

  ngOnInit(): void {
    if (this.dialogData) {
      this.editMode = this.dialogData.editMode
      if (this.workingCurve && this.workingCurve.technicalFactors) {
        const { isSaved, curveType, technicalFactors } =
          this.workingCurve
        this.inputTechFactors = technicalFactors
        this.form = this.formBuilder.group({
          expected_loss_multiplier: new FormControl(
            technicalFactors.expected_loss_multiplier,
            Validators.required
          ),
          expected_loss_power: new FormControl(
            technicalFactors.expected_loss_power
          ),
          volatility_multiplier: new FormControl(
            technicalFactors.volatility_multiplier,
            Validators.required
          ),
          fixed_cost: new FormControl(
            `${technicalFactors.fixed_cost * 100}%`,
            Validators.required
          ),
          minimum_rate_on_line: new FormControl(
            `${technicalFactors.minimum_rate_on_line * 100}%`,
            Validators.required
          ),
          maximum_rate_on_line: new FormControl(
            `${Number(
              (technicalFactors.maximum_rate_on_line * 100).toFixed(3)
            )}%`,
            [Validators.required]
          ),
          reinsurer_margin_percentage: new FormControl(
            `${technicalFactors.reinsurer_margin_percentage * 100}%`,
            Validators.required
          ),
          max_ceding_commission_percentage: new FormControl(
            `${technicalFactors.max_ceding_commission_percentage * 100}%`,
            Validators.required
          ),
        })
        this.selectedVolatilityMetric = technicalFactors.volatility_metric

        if (curveType) {
          this.curveType = this.getDropdownType(curveType)
          if (!this.labelForm.get('curveType')?.value) {
            this.labelForm.get('curveType')?.setValue(this.curveType?.value)
          }
        }

        // For any given curve, if it is a new curve or editing a curve, disable fitted inputs
        if (this.inputDisableCondition) {
          if (this.curveType?.value === PricingCurveTypes.EL_POWER) {
            this.form.get('expected_loss_multiplier')?.disable()
            this.form.get('expected_loss_power')?.disable()
          } else if (this.curveType?.value === PricingCurveTypes.EL_LINEAR) {
            this.form.get('expected_loss_multiplier')?.disable()
            this.form.get('fixed_cost')?.disable()
          }
        }

        this.isSaved = isSaved

        if (this.curveLabel) {
          this.labelForm.get('label')?.setValue(this.curveLabel)
          if (this.isSaved) {
            this.labelForm.get('label')?.disable()
          }
        }

        if (this.labelForm.get('curveType')?.value) {
          this.labelForm.get('curveType')?.disable()
        }

        this.form.valueChanges
          .pipe(
            debounce(() => timer(250)),
            takeUntil(this.destroy$)
          )
          .subscribe(value => {
            this.techFactorsChanged.emit(this.parseTechFactorsFromForm())
            if (typeof value.maximum_rate_on_line === 'string') {
              const parsedValue = Number.parseFloat(
                value.maximum_rate_on_line.replace('%', '')
              )
              if (parsedValue > 999.999) {
                this.form.get('maximum_rate_on_line')?.setValue(999.999, {
                  emitEvent: false,
                })
              }
            }
          })
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  updateWithDefaultIfEmpty(key: string): void {
    const formReference = this.form.get(key)
    if (
      formReference &&
      (`${formReference.value}`.length === 0 || isNaN(formReference.value))
    ) {
      formReference.setValue((this.inputTechFactors as any)[key])
    }
  }

  removePercentFromField(key: string): void {
    const formReference = this.form.get(key)
    if (formReference) {
      const value = `${formReference.value}`
      formReference.setValue(value.replace('%', ''), { emitEvent: false })
    }
  }

  addPercentToField(key: string) {
    const formReference = this.form.get(key)
    if (formReference) {
      let value = `${formReference.value}`
      if (value.length === 0 || isNaN(formReference.value)) {
        value = `${Number(
          (((this.inputTechFactors as any)[key] ?? 0) * 100).toFixed(3)
        )}`
      }
      formReference.setValue(`${value}%`, { emitEvent: false })
    }
  }

  get isFormValid(): boolean {
    return !(
      this.form.invalid ||
      !this.minValid ||
      !this.maxValid ||
      !this.curveLabel.length ||
      (this.doesCurrentLabelConflictWithSavedCurve && this.saveCurve)
    )
  }

  get submitDisabledTooltip() {
    if (!this.curveLabel.length) {
      return 'A label must be entered'
    } else if (this.doesCurrentLabelConflictWithSavedCurve) {
      return 'Current label already exists in the database'
    } else if (!this.minValid) {
      return this.minTooltip
    } else if (!this.maxValid) {
      return this.maxTooltip
    }
  }

  get inputDisableCondition(): boolean {
    return (this.isNewCurve || this.editMode) && !this.editManual
  }

  get doesCurrentLabelConflictWithSavedCurve(): boolean {
    return this.savedCurveNameMap.some(
      curve => curve.name === this.curveLabel && curve.id !== this.dataSetId
    )
  }

  get dataAdditionButtonText(): string {
    if (this.editMode && this.saveCurve && this.context === 'pricing-curve') {
      return 'Save'
    } else if (this.editMode) {
      return 'Confirm'
    } else if (this.saveCurve) {
      return `${this.context === 'pricing-curve' ? 'Save and ' : ''}Add`
    } else {
      return 'Add to Graph'
    }
  }

  enforceRateOnLineRestrictions(): void {
    const minRate = this.form.get('minimum_rate_on_line')
    const maxRate = this.form.get('maximum_rate_on_line')

    if (minRate && maxRate) {
      const minRateValue = Number(minRate.value.replace('%', ''))
      const maxRateValue = Number(maxRate.value.replace('%', ''))

      if (minRateValue >= maxRateValue) {
        this.minValid = false
        this.maxValid = false
        this.minTooltip = this.maxTooltip =
          'Max Rate on Line must be larger than Min Rate on Line'
      } else if (minRateValue < 0 || maxRateValue < 0) {
        this.minValid = minRateValue >= 0
        this.maxValid = maxRateValue >= 0
        this.minTooltip = !this.minValid
          ? 'Min Rate on Line can not be less than 0'
          : undefined
        this.maxTooltip = !this.maxValid
          ? 'Max Rate on Line can not be less than 0'
          : undefined
      } else {
        this.minValid = true
        this.maxValid = true
        this.minTooltip = undefined
        this.maxTooltip = undefined
      }
    }
  }

  onSubmitCurve(): void {
    if (!this.isFormValid) {
      return
    }
    this.submitCurve.emit({
      data: this.workingCurve.curveData,
      save: this.saveCurve,
    })
  }

  parseTechFactorsFromForm(): TechnicalFactors {
    const techFactors: Record<string, any> = {}

    Object.entries(this.form.controls).forEach(([key, control]) => {
      techFactors[key as keyof TechnicalFactors] =
        typeof control.value === 'string'
          ? Number.parseFloat(control.value.replace('%', ''))
          : control.value
    })
    techFactors.volatility_metric = this.selectedVolatilityMetric

    return {
      ...(techFactors as TechnicalFactors),
      fixed_cost: techFactors.fixed_cost / 100,
      minimum_rate_on_line: techFactors.minimum_rate_on_line / 100,
      maximum_rate_on_line: techFactors.maximum_rate_on_line / 100,
      reinsurer_margin_percentage:
        techFactors.reinsurer_margin_percentage / 100,
      max_ceding_commission_percentage:
        techFactors.max_ceding_commission_percentage / 100,
    }
  }

  onDropdownChange() {
    this.curveType = this.getDropdownType(this.form.controls.curveType.value)
  }

  getDropdownType(type: string): CurveTypeOption | undefined {
    return this.dropdownOptions.find(option => option.value === type)
  }

  updateCheckboxState($event: MatCheckboxChange) {
    this.saveCurve = $event.checked
  }

  getData(event: any): void {
    this.parentMenuItemValue = event.srcElement.innerText.toString()
  }

  setVolatilityMetric(value: string): void {
    this.selectedVolatilityMetric = value
  }

  onPeriodSelected(year: number, parentMenuItemValue: string): void {
    if (parentMenuItemValue.includes('year TVar')) {
      this.setVolatilityMetric('Ceded ' + year + ' year TVar')
      this.cededTVar = 'Ceded ' + year + ' year TVar'
    } else {
      this.setVolatilityMetric('Ceded ' + year + ' year Var')
      this.cededVar = 'Ceded ' + year + ' year Var'
    }
    this.volatilityMenuTrigger.closeMenu()
  }
}
