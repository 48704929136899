<ng-container
  *ngIf="!summaryView"
>
  <h3>Loss Sets / Groups</h3>
  <div class="loss">
    <div class="loss-set-panel">
      <div (click)="onSelectAllLossSets.emit()" class="parent">
        <mat-icon
          class="child"
          *ngIf="allLossSetsSelected"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="allLossSetsSelected"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!allLossSetsSelected"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span class="unselectable">Select All</span>
        </div>
      </div>
      <h4 (click)="toggleVisibility('groups')" class="sub-header">
        Loss Set Groups
        <mat-icon class="caret-icon">
          {{ isSectionVisible('groups') ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </h4>
      <div *ngIf="isSectionVisible('groups')" [@slideInOut]>
        <mat-form-field>
          <input
            matInput class="filter-search"
            placeholder="Search Groups"
            [(ngModel)]="filterTerms.lossSetGroups"
            (input)="onSearchChange('lossSetGroups', $event.target.value)"
          >
        </mat-form-field>
        <div
          *ngFor="let lossSet of createFilter(lossSetGroups, 'lossSetGroups', 'name')"
          class="parent"
          (click)="lossSetClick(lossSet.id, $event)"
          [class.selected]="checkSelectedLossSet(lossSet.id)"
        >
          <mat-icon
            class="child"
            *ngIf="checkSelectedLossSet(lossSet.id)"
            aria-hidden="false"
            aria-label="check"
            [class.selected]="checkSelectedLossSet(lossSet.id)"
          >
            check_box
          </mat-icon>
          <mat-icon
            class="child"
            *ngIf="!checkSelectedLossSet(lossSet.id)"
            aria-hidden="false"
            aria-label="check"
          >
            check_box_outline_blank
          </mat-icon>
          <div class="child">
            <span
              class="unselectable"
              [matTooltip]="lossSet.name"
              matTooltipPosition="above"
            >
              {{ lossSet.name }}
            </span>
          </div>
        </div>
      </div>
      <h4 (click)="toggleVisibility('layers')" class="sub-header">
        Loss Set Layers
        <mat-icon class="caret-icon">
          {{ isSectionVisible('layers') ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </h4>
      <div *ngIf="isSectionVisible('layers')" [@slideInOut]>
        <mat-form-field>
          <input
            matInput class="filter-search"
            placeholder="Search Layers"
            [(ngModel)]="filterTerms.lossSetLayers"
            (input)="onSearchChange('lossSetLayers', $event.target.value)"
          >
        </mat-form-field>
        <div
          *ngFor="let lossSet of createFilter(lossSetLayers, 'lossSetLayers', 'layers')"
          class="parent"
          (click)="lossSetClick(lossSet.id, $event)"
          [class.selected]="checkSelectedLossSet(lossSet.id)"
        >
          <mat-icon
            class="child"
            *ngIf="checkSelectedLossSet(lossSet.id)"
            aria-hidden="false"
            aria-label="check"
            [class.selected]="checkSelectedLossSet(lossSet.id)"
          >
            check_box
          </mat-icon>
          <mat-icon
            class="child"
            *ngIf="!checkSelectedLossSet(lossSet.id)"
            aria-hidden="false"
            aria-label="check"
          >
            check_box_outline_blank
          </mat-icon>
          <div class="child">
            <span
              class="unselectable"
              [matTooltip]="lossSet.meta_data.ls_dim1 + (lossSet.meta_data.ls_dim2 ? ' - ' + lossSet.meta_data.ls_dim2 : '')"
              matTooltipPosition="above"
            >
              {{
                lossSet.meta_data.ls_dim1 +
                (lossSet.meta_data.ls_dim2 ? ' - ' + lossSet.meta_data.ls_dim2 : '')
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="summaryView"
>
<h3>Summary Filters</h3>
<h4
  *ngIf="summaryViews.length > 0"
  class="sub-header views"
  (click)="toggleVisibility('summaryViews')"
  >
  Summary Filter Views
  <mat-icon class="caret-icon">
    {{ isSectionVisible('summaryViews') ? 'expand_less' : 'expand_more' }}
  </mat-icon>
</h4>
<div
  *ngIf="isSectionVisible('summaryViews')"
  [@slideInOut]
  class="level-container"
>
  <mat-form-field>
    <input
      matInput class="filter-search"
      placeholder="Search Views"
      [(ngModel)]="filterTerms.summaryViews"
      (input)="onSearchChange('summaryViews', $event.target.value)"
    >
  </mat-form-field>
  <div
    *ngFor="let view of createFilter(summaryViews, 'summaryViews', 'name')"
    class="parent"
    (click)="toggleSelectedStudySummaryFilterView(view)"
  >
    <mat-icon
      class="child"
      *ngIf="checkSelectedStudySummaryFilterView(view.id)"
      aria-hidden="false"
      aria-label="check"
      [class.selected]="checkSelectedStudySummaryFilterView(view.id)"
    >
      check_box
    </mat-icon>
    <mat-icon
      class="child"
      *ngIf="!checkSelectedStudySummaryFilterView(view.id)"
      aria-hidden="false"
      aria-label="check"
    >
      check_box_outline_blank
    </mat-icon>
    <div class="child">
      <span
        class="unselectable"
        [matTooltip]="view.name"
        matTooltipPosition="above"
      >
        {{ view.name }}
      </span>
    </div>
  </div>
</div>
  <mat-slide-toggle
    *ngIf="groupByView"
    [@slideInOut]
    class="toggle-input"
    matTooltip="Display Individual Loss Sets Within Groups"
    matTooltipPosition="below"
    [checked]="showIndividualLossSets"
    (change)="toggleIndividualLossSets($event.checked)"
  >
    <span class="light-chart-toggle-label">Show Individual Loss Sets</span>
  </mat-slide-toggle>
  <h4
    class="sub-header"
    (click)="toggleVisibility('metaOptions')"
  >
    Filter Meta Data
    <mat-icon class="caret-icon">
      {{ isSectionVisible('metaOptions') ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </h4>
  <div
    *ngIf="isSectionVisible('metaOptions')"
    [@slideInOut]
    class="level-container"
  >
    <div
      *ngFor="let option of summaryFilterOptions"
    >
      <div
        *ngIf="getSubOptionsByValueType(option.value).length > 0"
      >
        <h4 (click)="toggleVisibility(option.value)">
          {{option.label}}
          <mat-icon class="caret-icon">
            {{ isSectionVisible(option.value) ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        </h4>
        <div *ngIf="isSectionVisible(option.value)" [@slideInOut] class="level-container">
          <mat-form-field>
            <input
              matInput class="filter-search"
              placeholder="Search {{option.label}}s"
              [(ngModel)]="filterTerms[option.id]"
              (input)="onSearchChange(option.id, $event.target.value)"
            >
          </mat-form-field>
          <div
            *ngFor="let value of createFilter(getSubOptionsByValueType(option.value), option.id, 'meta')"
            class="parent"
            (click)="toggleSummaryFilter(option.value + '~' + value)"
          >
            <mat-icon
              class="child"
              *ngIf="checkSummaryFilterSelected(option.value + '~' + value)"
              aria-hidden="false"
              aria-label="check"
              [class.selected]="checkSummaryFilterSelected(option.value + '~' + value)"
            >
              check_box
            </mat-icon>
            <mat-icon
              class="child"
              *ngIf="!checkSummaryFilterSelected(option.value + '~' + value)"
              aria-hidden="false"
              aria-label="check"
            >
              check_box_outline_blank
            </mat-icon>
            <div class="child">
              <span
                class="unselectable"
                [matTooltip]="value"
                matTooltipPosition="above"
              >
                {{ value }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h4
    class="sub-header"
    (click)="toggleVisibility('modelingOptions')"
  >
    Group By
    <mat-icon class="caret-icon">
      {{ isSectionVisible('modelingOptions') ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </h4>
  <div *ngIf="isSectionVisible('modelingOptions')" [@slideInOut] class="level-container">
    <h4 (click)="toggleVisibility('l1')">
      Group By One
      <mat-icon class="caret-icon">
        {{ isSectionVisible('l1') ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </h4>
    <div *ngIf="isSectionVisible('l1')" [@slideInOut] class="level-container">
      <mat-form-field>
        <input
          matInput class="filter-search"
          placeholder="Search Meta Fields"
          [(ngModel)]="filterTerms.groupByOne"
          (input)="onSearchChange('groupByOne', $event.target.value)"
        >
      </mat-form-field>
      <div
        *ngFor="let option
         of createFilter(displaySummaryFilterOptions, 'groupByOne', 'label')"
        class="parent"
        (click)="!checkDisabledLevels(option.value, 0) && updateModelingArray(option.value, 0)"
        [class.selected]="checkSelectedLevels(option.value, 0)"
        [class.disabled]="checkDisabledLevels(option.value, 0)"
        [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.value, 0) ? 'none' : 'auto' }"
      >
        <mat-icon
          class="child"
          *ngIf="checkSelectedLevels(option.value, 0)"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSelectedLevels(option.value, 0)"
          [class.disabled]="checkDisabledLevels(option.value, 0)"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSelectedLevels(option.value, 0)"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="option.label"
            matTooltipPosition="above"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
    <h4 *ngIf="showGroupByLevel(0)" (click)="toggleVisibility('l2')">
      Group By Two
      <mat-icon class="caret-icon">
        {{ isSectionVisible('l2') ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </h4>
    <div *ngIf="isSectionVisible('l2')" [@slideInOut] class="level-container">
      <mat-form-field>
        <input
          matInput class="filter-search"
          placeholder="Search Meta Fields"
          [(ngModel)]="filterTerms.groupByTwo"
          (input)="onSearchChange('groupByTwo', $event.target.value)"
        >
      </mat-form-field>
      <div
        *ngFor="let option
         of createFilter(displaySummaryFilterOptions, 'groupByTwo', 'label')"
        class="parent"
        (click)="!checkDisabledLevels(option.value, 1) && updateModelingArray(option.value, 1)"
        [class.selected]="checkSelectedLevels(option.value, 1)"
        [class.disabled]="checkDisabledLevels(option.value, 1)"
        [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.value, 1) ? 'none' : 'auto' }"
      >
        <mat-icon
          class="child"
          *ngIf="checkSelectedLevels(option.value, 1)"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSelectedLevels(option.value, 1)"
          [class.disabled]="checkDisabledLevels(option.value, 1)"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSelectedLevels(option.value, 1)"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="option.label"
            matTooltipPosition="above"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
    <h4 *ngIf="showGroupByLevel(1)" (click)="toggleVisibility('l3')">
      Group By Three
      <mat-icon class="caret-icon">
        {{ isSectionVisible('l3') ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </h4>
    <div *ngIf="isSectionVisible('l3')" [@slideInOut] class="level-container">
      <mat-form-field>
        <input
          matInput class="filter-search"
          placeholder="Search Meta Fields"
          [(ngModel)]="filterTerms.groupByThree"
          (input)="onSearchChange('groupByThree', $event.target.value)"
        >
      </mat-form-field>
      <div
        *ngFor="let option
         of createFilter(displaySummaryFilterOptions, 'groupByThree', 'label')"
        class="parent"
        (click)="!checkDisabledLevels(option.value, 2) && updateModelingArray(option.value, 2)"
        [class.selected]="checkSelectedLevels(option.value, 2)"
        [class.disabled]="checkDisabledLevels(option.value, 2)"
        [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.value, 2) ? 'none' : 'auto' }"
      >
        <mat-icon
          class="child"
          *ngIf="checkSelectedLevels(option.value, 2)"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSelectedLevels(option.value, 2)"
          [class.disabled]="checkDisabledLevels(option.value, 2)"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSelectedLevels(option.value, 2)"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="option.label"
            matTooltipPosition="above"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
    <h4 *ngIf="showGroupByLevel(2)" (click)="toggleVisibility('l4')">
      Group By Four
      <mat-icon class="caret-icon">
        {{ isSectionVisible('l4') ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </h4>
    <div *ngIf="isSectionVisible('l4')" [@slideInOut] class="level-container">
      <mat-form-field>
        <input
          matInput class="filter-search"
          placeholder="Search Meta Fields"
          [(ngModel)]="filterTerms.groupByFour"
          (input)="onSearchChange('groupByFour', $event.target.value)"
        >
      </mat-form-field>
      <div
        *ngFor="let option
         of createFilter(displaySummaryFilterOptions, 'groupByFour', 'label')"
        class="parent"
        (click)="!checkDisabledLevels(option.value, 3) && updateModelingArray(option.value, 3)"
        [class.selected]="checkSelectedLevels(option.value, 3)"
        [class.disabled]="checkDisabledLevels(option.value, 3)"
        [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.value, 3) ? 'none' : 'auto' }"
      >
        <mat-icon
          class="child"
          *ngIf="checkSelectedLevels(option.value, 3)"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSelectedLevels(option.value, 3)"
          [class.disabled]="checkDisabledLevels(option.value, 3)"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSelectedLevels(option.value, 3)"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="option.label"
            matTooltipPosition="above"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
    <h4 *ngIf="showGroupByLevel(3)" (click)="toggleVisibility('l5')">
      Group By Five
      <mat-icon class="caret-icon">
        {{ isSectionVisible('l5') ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </h4>
    <div *ngIf="isSectionVisible('l5')" [@slideInOut] class="level-container">
      <mat-form-field>
        <input
          matInput class="filter-search"
          placeholder="Search Meta Fields"
          [(ngModel)]="filterTerms.groupByFive"
          (input)="onSearchChange('groupByFive', $event.target.value)"
        >
      </mat-form-field>
      <div
        *ngFor="let option
         of createFilter(displaySummaryFilterOptions, 'groupByFive', 'label')"
        class="parent"
        (click)="!checkDisabledLevels(option.value, 4) && updateModelingArray(option.value, 4)"
        [class.selected]="checkSelectedLevels(option.value, 4)"
        [class.disabled]="checkDisabledLevels(option.value, 4)"
        [ngStyle]="{ 'pointer-events': checkDisabledLevels(option.value, 4) ? 'none' : 'auto' }"
      >
        <mat-icon
          class="child"
          *ngIf="checkSelectedLevels(option.value, 4)"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSelectedLevels(option.value, 4)"
          [class.disabled]="checkDisabledLevels(option.value, 4)"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSelectedLevels(option.value, 4)"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="option.label"
            matTooltipPosition="above"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <h4
    class="sub-header"
    (click)="toggleVisibility('columns')"
  >
    Columns / Column Groups
    <mat-icon class="caret-icon">
      {{ isSectionVisible('columns') ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </h4>

  <div
    *ngIf="isSectionVisible('columns')"
    class="level-container"
    [@slideInOut]
  >
    <mat-form-field>
      <input
        matInput class="filter-search"
        placeholder="Search Columns"
        [(ngModel)]="filterTerms.columns"
        (input)="onSearchChange('columns', $event.target.value)"
      >
    </mat-form-field>
    <h4 (click)="toggleVisibility('columnLossTypes')">
      Loss Types
      <mat-icon class="caret-icon">
        {{ isSectionVisible('columnLossTypes') ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </h4>
    <div *ngIf="isSectionVisible('columnLossTypes')" [@slideInOut] class="level-container">
      <div
        class="parent"
        (click)="toggleSummaryColumnFilter('Attritional')"
      >
        <mat-icon
          class="child"
          *ngIf="checkSummaryColumnFilter('Attritional')"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSummaryColumnFilter('Attritional')"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSummaryColumnFilter('Attritional')"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="'Attritional'"
            matTooltipPosition="above"
          >
            Attritional
          </span>
        </div>
      </div>
      <div
        class="parent"
        (click)="toggleSummaryColumnFilter('Large')"
      >
        <mat-icon
          class="child"
          *ngIf="checkSummaryColumnFilter('Large')"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSummaryColumnFilter('Large')"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSummaryColumnFilter('Large')"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="'Large'"
            matTooltipPosition="above"
          >
            Large
          </span>
        </div>
      </div>
      <div
        class="parent"
        (click)="toggleSummaryColumnFilter('Cat')"
      >
        <mat-icon
          class="child"
          *ngIf="checkSummaryColumnFilter('Cat')"
          aria-hidden="false"
          aria-label="check"
          [class.selected]="checkSummaryColumnFilter('Cat')"
        >
          check_box
        </mat-icon>
        <mat-icon
          class="child"
          *ngIf="!checkSummaryColumnFilter('Cat')"
          aria-hidden="false"
          aria-label="check"
        >
          check_box_outline_blank
        </mat-icon>
        <div class="child">
          <span
            class="unselectable"
            [matTooltip]="'Cat'"
            matTooltipPosition="above"
          >
            Cat
          </span>
        </div>
      </div>
    </div>
    <div
      *ngFor="let header
       of createFilter(headers, 'columns', 'label')"
      class="parent"
      (click)="toggleSummaryColumnFilter(header.id)"
    >
      <mat-icon
        class="child"
        *ngIf="checkSummaryColumnFilter(header.id)"
        aria-hidden="false"
        aria-label="check"
        [class.selected]="checkSummaryColumnFilter(header.id)"
      >
        check_box
      </mat-icon>
      <mat-icon
        class="child"
        *ngIf="!checkSummaryColumnFilter(header.id)"
        aria-hidden="false"
        aria-label="check"
      >
        check_box_outline_blank
      </mat-icon>
      <div class="child">
        <span
          class="unselectable"
          [matTooltip]="header.label"
          matTooltipPosition="above"
        >
          {{ header.label }}
        </span>
      </div>
    </div>
  </div>
</ng-container>
