import { toArray } from '@shared/util/operators'
import * as d3 from 'd3'
import { select, Selection } from 'd3-selection'
import { ScaleLinear, ScaleLogarithmic } from 'd3'
import { InuranceTagsByID } from 'src/app/analysis/model/inurance.model'
import { SharedIDGroup } from 'src/app/analysis/store/grouper/program-group.model'
import {
  BoxFactoryOptions,
  INewBox,
  MarginBounds,
  TowerBoxDatum,
  TowerBoxElement,
  TowerChartElement,
  TowerTagElement,
  TowerTextElement,
  TowerTooltipElement,
} from 'src/app/analysis/tower/mechanics/tower.model'
import { TowerComponent } from 'src/app/analysis/tower/tower-component/tower.component'
import { analyzereConstants } from 'src/app/shared/constants/analyzere'
import { ShortNumberPipe } from 'src/app/shared/pipes/short-number.pipe'
import {
  createRibbonTagElement,
  updateRibbonTagElementX,
  updateRibbonTagElementY,
} from 'src/app/shared/ribbon-tag.util'
import Color from 'color'
import { layerIds } from '../../model/layer-palette.model'

export class BoxFactory {
  private tower: TowerComponent
  private chart: TowerChartElement
  private width: number
  private element: HTMLElement
  private height: number
  private margin: MarginBounds
  private tooltip: TowerTooltipElement
  private shortNumberPipe: ShortNumberPipe
  private readonly: boolean
  private sharedIDGroup: SharedIDGroup[]
  private inuranceTagsByLayerID: InuranceTagsByID
  private textElement: TowerTextElement
  private lastActive = 'none'
  private movement = false
  private mouseDragY = -1
  private oldItem: any

  top: number
  increment: number | null

  constructor(
    tower: TowerComponent,
    chart: TowerChartElement,
    options: BoxFactoryOptions
  ) {
    this.tower = tower
    this.chart = chart
    this.width = options.width
    this.height = options.height
    this.margin = options.margin
    this.tooltip = options.tooltip
    this.shortNumberPipe = options.shortNumberPipe
    this.readonly = options.readonly
    this.sharedIDGroup = options.sharedIDGroup
    this.inuranceTagsByLayerID = options.inuranceTagsByLayerID
    this.element = options.element
  }

  create(item: INewBox, width: number, height: number, hide: boolean): INewBox {
    let boxOpacity = 0
    if (!hide) {
      boxOpacity = 1
    }
    const logicalID = item.logicalID
    const xDragBarWidth = item.boxWidth * 0.05
    const yDragBarWidth = item.boxHeight * 0.05
    const xDragBarWidthHalf = xDragBarWidth / 2
    const yDragBarWidthHalf = yDragBarWidth / 2
    let move = false
    let boxWidth = item.boxWidth
    let boxHeight = item.boxHeight
    const x = item.x
    const y = item.y
    const id = item.id
    const label = item.label
    const attachment = item.attachment
    const limit = item.limit
    const cession = item.cession
    const _type = item._type
    const clr = item.clr
    const self = this.tower
    const sage_type = item.sage_type
    const isLimitUnlimited = item.isLimitUnlimited
    const box = this.chart
      .append('g')
      .data([
        {
          franchiseDeductible: item.franchiseDeductible,
          x,
          y,
          boxWidth,
          boxHeight,
          width: this.width,
          height: this.height,
          id,
          attachment,
          limit,
          cession,
          _type,
          label,
          clr,
          isLimitUnlimited,
        },
      ])
      .attr('transform', () => {
        return 'translate(' + [this.margin.left, this.margin.top] + ')'
      })
      .attr('id', (d: any) => {
        return d.id
      })
      .style('opacity', boxOpacity)

    if (!this.readonly) {
      box.call(
        d3
          .drag()
          .on('start', (_: any, d: INewBox) => dragStarted(d, this))
          .on('drag', (event: any, d: INewBox) => {
            dragMove(event, d)
          })
          .on('end', (_: any, d: INewBox) => dragEndedNew(d, this))
      )
    } else {
      box.call(
        d3
          .drag()
          .on('start', (_: any, d: INewBox) => dragStarted(d, this))
          .on('end', (_: any, d: INewBox) => dragEndedNew(d, this))
      )
    }

    const dragRect = box
      .append('rect')
      .data([
        {
          x,
          y,
          id: 'r_' + id,
        },
      ])
      .attr('x', x)
      .attr('y', y)
      .attr('height', boxHeight)
      .attr('width', boxWidth)
      .attr('stroke-width', 2)
      .attr('id', 'r_' + id)
      .style('outline', 'thin solid var(--body)')
      .style('outline-offset', '-2px')

    if (
      sage_type === layerIds.catXl ||
      sage_type === layerIds.otherXl ||
      sage_type === layerIds.noncatXl ||
      sage_type === layerIds.noncatIndxl ||
      sage_type === layerIds.catQs ||
      sage_type === layerIds.noncatQs ||
      sage_type === layerIds.ahlQs ||
      sage_type === layerIds.ahlXl ||
      sage_type === layerIds.ahlAg ||
      sage_type === layerIds.otherQs ||
      sage_type === layerIds.catCa ||
      sage_type === layerIds.catCascade ||
      sage_type === layerIds.catCascading ||
      sage_type === layerIds.catTd ||
      sage_type === layerIds.topAndDrop ||
      sage_type === layerIds.noncatAggXl ||
      sage_type === layerIds.otherAg ||
      sage_type === layerIds.catAggXl ||
      sage_type === layerIds.catAg ||
      sage_type === layerIds.noncatAg ||
      sage_type === layerIds.drop ||
      sage_type === layerIds.catFhcf ||
      sage_type === layerIds.catIlw ||
      sage_type === layerIds.ilwProRata ||
      sage_type === layerIds.ilwBin ||
      sage_type === layerIds.noncatRisk ||
      sage_type === layerIds.noncatSwing ||
      sage_type === layerIds.ahlSwing ||
      sage_type === layerIds.catMultisection ||
      sage_type === layerIds.noncatMultisection
    ) {
      const classNames = ['app-tower-layer']
      if (item.layerColor) {
        this.createCSSSelector(
          '.' + item.id + '-style',
          '--' + this.hexToRGB(item.layerColor) + ';--bg-alpha: 0.1;'
        )
        classNames.push(item.id + '-style')
      } else {
        classNames.push(`app-palette-${sage_type}`)
      }
      if (this.sharedIDGroup.length > 0) {
        const group = this.sharedIDGroup.find(g => {
          return g.group.indexOf(logicalID) >= 0
        })

        if (group) {
          classNames.push(`app-tower-shared-layer`)
          classNames.push(`app-tower-shared-layer-group-${group.numberGroup}`)
        }
      }
      dragRect.attr('class', classNames.join(' '))
    } else {
      dragRect.attr('fill', '#ffffff').attr('stroke', '#808080')
    }

    if (sage_type === layerIds.catCa || sage_type === layerIds.catTd) {
      dragRect.attr('stroke-dasharray', '5,10')
    }

    select<HTMLElement, {}>(this.element)
      .selectAll(`#r_${id}`)
      .on('mouseout', () => {
        this.tooltip.transition().duration(0).style('opacity', 0)
      })

    select<HTMLElement, {}>(this.element)
      .selectAll(`#r_${id}`)
      .on('mouseover', (event: any) => {
        if (
          select<HTMLElement, {}>(this.element)
            .selectAll(`#drag-text-${id}`)
            .attr('fullLine') !== 'null' &&
          self.tooltip
        ) {
          self.tooltip.transition().duration(1).style('opacity', 0.9)
          self.tooltip
            .html(
              select<HTMLElement, {}>(this.element)
                .selectAll(`#drag-text-${id}`)
                .attr('fullLine')
            )

            .style('left', `${event.pageX}px`)
            .style('top', `${event.pageY}px`)
        }
      })

    if (!this.readonly) {
      select('app-portfolio-header.header').on('click', () => {
        self.clearSelectedItem()
      })

      select('app-tower-container').on('click', (event: any) => {
        if (event.target) {
          let selectedLayer = (event.target as HTMLElement).id.replace('r_', '')
          selectedLayer = selectedLayer.replace('LOcc', '')
          selectedLayer = 'L' + selectedLayer.replace('LAgg', '')
          self.selectedLayerID.emit(self.layerIDMap[selectedLayer])
        }
      })

      select('app-properties-container').on('click', () => {
        self.onTower = false
      })

      select('.buttonProperties').on('click', () => {
        self.onTower = false
      })

      select('app-portfolio-metrics').on('click', () => {
        self.onTower = false
      })
    }

    let textColor = 'black'
    if (item.layerColor) {
      if (item.layerColor.length > 0) {
        const sessionColor = Color(item.layerColor)
        if (sessionColor.luminosity() < 0.1) {
          textColor = 'white'
        }
      }
    }

    this.textElement = box
      .append('text')
      .attr('fullLine', 'null')
      .attr('id', `drag-text-${id}`)
      .attr('x', x)
      .attr('y', y)
      .attr('class', 'app-tower-box-label')
      .attr('transform', `translate(${[10, 20]})`)
      .style('fill', textColor)

    this.textElement
      .append('tspan')
      .attr('x', x)
      .attr('dy', '1em')
      .style('fill', textColor)
      .text(() => {
        if (
          (item.sage_type === layerIds.catQs ||
            item.sage_type === layerIds.noncatQs ||
            item.sage_type === layerIds.ahlQs) &&
          item.limit >= analyzereConstants.unlimitedValue
        ) {
          return (
            item._type +
            ' | ' +
            (cession * 100).toFixed(2) +
            '% of Unlimited' +
            ' xs ' +
            this.shortNumberPipe.transform(attachment, item.currency) +
            (item.lossAmount
              ? ', ' +
                this.shortNumberPipe.transform(
                  item.lossAmount || 0,
                  item.currency
                ) +
                ' loss'
              : '')
          )
        } else if (item.sectionData !== undefined) {
          const formatCurrency = (n: number, currency: string | undefined) =>
            this.shortNumberPipe.transform(n, currency)
          // Main layer values
          if (item.contractOccurrenceLimit) {
            item.limit = item.contractOccurrenceLimit
          }
          const occLimitFormatted = formatCurrency(item.limit, item.currency)
          const attachmentFormatted = formatCurrency(
            0, // item.attachment // attachment main layer attachment should always appear as 0
            item.currency
          )
          const aggAttachmentFormatted = formatCurrency(
            item.aggregateAttachment,
            item.currency
          )
          // Sections mapped
          const sectionLabel = toArray(item.sectionData)
            .map((section, idx) => {
              // If section limit is unlimited then use main layer limit
              if (section.limit >= analyzereConstants.unlimitedValue) {
                section.limit = item.limit
                section.currency = item.currency
              }
              const sectionAttachment = formatCurrency(
                section.attachment,
                section.currency
              )
              const sectionLimit = formatCurrency(
                section.limit,
                section.currency
              )

              return (
                `Section ${String.fromCharCode(
                  idx + 65
                )}: ${sectionLimit} xs ${sectionAttachment}` +
                (section.aggregateAttachment > 0
                  ? ` xs ${formatCurrency(
                      section.aggregateAttachment,
                      section.currency || item.currency
                    )}`
                  : '')
              )
            })
            .join('; ')
          // Main layer text
          let sharedLimit
          if (item.aggregateAttachment > 0) {
            sharedLimit = `${occLimitFormatted} xs ${attachmentFormatted} xs ${aggAttachmentFormatted}`
          } else {
            sharedLimit = `${occLimitFormatted}`
          }
          // Main layer text concatenated with section
          return (
            `${item._type} ` +
            ' | ' +
            `Shared Limit: ${sharedLimit}; ${sectionLabel}`
          )
        } else {
          let franchiseDeductible = 0
          if (
            (item.sage_type === layerIds.catAg ||
              item.sage_type === layerIds.noncatAg ||
              item.sage_type === layerIds.ahlAg) &&
            item.subtype === layerIds.feeder &&
            item.franchiseDeductible > 0
          ) {
            franchiseDeductible = item.franchiseDeductible
          }
          return (
            item._type +
            ' | ' +
            (cession * 100).toFixed(2) +
            '% of ' +
            this.shortNumberPipe.transform(limit, item.currency) +
            ' xs ' +
            this.shortNumberPipe.transform(
              franchiseDeductible > 0 ? franchiseDeductible : attachment,
              item.currency
            ) +
            (item.lossAmount
              ? ', ' +
                this.shortNumberPipe.transform(
                  item.lossAmount || 0,
                  item.currency
                ) +
                ' loss'
              : '') +
            (franchiseDeductible > 0 ? ' (Franchise)' : '') +
            (item.sage_type === layerIds.catMultisection
              ? ' | ' + '(Description will update on save)'
              : '')
          )
        }
      })
      .call(
        this.wrap,
        boxWidth - 2 * xDragBarWidth,
        0,
        item.id,
        boxHeight - 2 * yDragBarWidth,
        this.element
      )

    const textElement = this.textElement

    const dragBarLeft = box
      .append('rect')
      .attr('x', x - xDragBarWidthHalf)
      .attr('y', y)
      .attr('height', boxHeight)
      .attr('width', xDragBarWidth)
      .attr('fill', item.color || 'inherit')
      .attr('fill-opacity', 0)
      .attr('cursor', 'ew-resize')

    if (!this.readonly) {
      dragBarLeft
        .call(
          d3
            .drag()
            .on('start', (_, d: INewBox) => setOldItem(d, this))
            .on('drag', (event: any, d: INewBox) => {
              dragLeftResizer(event, d)
              this.tooltip.transition().duration(1).style('opacity', 0.9)
              this.tooltip
                .html(
                  '' + (self.mathCalculateCession(d) * 100).toFixed(2) + '%'
                )
                .style('left', event.sourceEvent.pageX - 55 + 'px')
                .style('top', event.sourceEvent.pageY - 28 + 'px')
            })
            .on('end', (_, d: INewBox) => dragEndedSnapLeft(d))
        )
        .on('mouseout', () => {
          this.tooltip.transition().duration(0).style('opacity', 0)
        })
    }

    const dragBarRight = box
      .append('rect')
      .attr('x', x + boxWidth - xDragBarWidthHalf)
      .attr('y', y + yDragBarWidthHalf)
      .attr('height', boxHeight - yDragBarWidth)
      .attr('width', xDragBarWidth)
      .attr('fill', item.color || 'inherit')
      .attr('fill-opacity', 0)
      .attr('cursor', 'ew-resize')

    if (!this.readonly) {
      dragBarRight
        .call(
          d3
            .drag()
            .on('drag', (event: any, d: INewBox) => {
              rightDragResizer(event, d)
              this.tooltip.transition().duration(1).style('opacity', 0.9)
              this.tooltip
                .html(
                  '' + (self.mathCalculateCession(d) * 100).toFixed(2) + '%'
                )
                .style('left', event.sourceEvent.pageX + 'px')
                .style('top', event.sourceEvent.pageY - 28 + 'px')
            })
            .on('end', (_, d: INewBox) => dragEndedSnapRight(d))
        )
        .on('mouseout', () => {
          this.tooltip.transition().duration(0).style('opacity', 0)
        })
    }

    const dragBarTop = box
      .append('rect')
      .attr('x', x)
      .attr('y', y)
      .attr('height', yDragBarWidth)
      .attr('width', boxWidth - xDragBarWidth)
      .attr('fill', item.color || 'inherit')
      .attr('fill-opacity', 0)
      .attr('cursor', 'ns-resize')

    if (!this.readonly) {
      dragBarTop
        .call(
          d3
            .drag()
            .on('drag', (event: any, d: INewBox) => {
              const mouseY = topDragResizer(event, d, this.mouseDragY)
              if (mouseY !== undefined) {
                this.mouseDragY = mouseY
              }
              this.tooltip.transition().duration(1).style('opacity', 0.9)
              this.tooltip
                .html(
                  'Limit: ' + self.getRoundedLimit(d, event.sourceEvent.pageY)
                )
                .style('left', event.sourceEvent.pageX + 'px')
                .style('top', event.sourceEvent.pageY - 28 + 'px')
            })
            .on('end', (event: any, d: INewBox) => {
              self.towerState = 'dragEndTop'
              self.getLimitAttVal = {
                limit: self.calculateRoundedLimitValue(d, 0),
                attachment: self.calculateRoundedAttachmentValue(d),
              }
              return dragEndedTop(event, d)
            })
        )
        .on('mouseout', () => {
          this.tooltip.transition().duration(0).style('opacity', 0)
        })
    }

    const dragBarBottom = box
      .append('rect')
      .attr('x', x)
      .attr('y', y + boxHeight - yDragBarWidth)
      .attr('height', yDragBarWidth)
      .attr('width', boxWidth)
      .attr('fill', item.color || 'inherit')
      .attr('fill-opacity', 0)
      .attr('cursor', 'ns-resize')

    if (!this.readonly) {
      dragBarBottom
        .call(
          d3
            .drag()
            .on('drag', (event: any, d: INewBox) => {
              bottomDragResizer(event, d)
              this.tooltip.transition().duration(1).style('opacity', 0.9)
              this.tooltip
                .html(
                  'Limit: ' +
                    self.getRoundedLimit(d, 0) +
                    '<br>' +
                    (d.franchiseDeductible > 0
                      ? 'Franchise: '
                      : 'Attachment: ') +
                    self.getRoundedAttachment(d)
                )
                .style('left', event.sourceEvent.pageX + 'px')
                .style('top', event.sourceEvent.pageY - 28 + 'px')
            })
            .on('end', (_, d: INewBox) => {
              self.towerState = 'dragEndBottom'
              self.getLimitAttVal = {
                limit: self.calculateRoundedLimitValue(d, 0),
                attachment: self.calculateRoundedAttachmentValue(d),
              }
              return dragEndedBottom(d)
            })
        )
        .on('mouseout', () => {
          this.tooltip.transition().duration(0).style('opacity', 0)
        })
    }

    const tagElements = this.createInuranceTagElements(item.logicalID, box)

    tagElements.forEach(tag => {
      tag.on('mouseenter', (event: MouseEvent) => {
        event.stopPropagation()
        event.preventDefault()
      })
      tag.on('mousedown', (event: MouseEvent) => {
        event.stopPropagation()
        event.preventDefault()
      })
      tag.on('mouseup', (event: MouseEvent, d: TowerBoxDatum) => {
        event.stopPropagation()
        self.onInuranceTagClick(item.logicalID, d.inuranceTag)
      })
    })

    function topDragResizer(event: any, d: INewBox, mouseDragY: number) {
      const origY = d.y
      d.y = Math.max(0, Math.min(d.y + boxHeight - yDragBarWidthHalf, event.y))
      boxHeight = boxHeight + (origY - d.y)
      d.boxHeight = boxHeight

      dragBarTop.attr('y', d.y - yDragBarWidthHalf)

      dragRect.attr('y', d.y).attr('height', boxHeight)

      textElement
        .attr('y', d.y + yDragBarWidthHalf)
        .attr('height', boxHeight - yDragBarWidth)

      tagElements.forEach(updateRibbonTagElementY(d.y))

      dragBarLeft
        .attr('y', d.y + yDragBarWidthHalf)
        .attr('height', boxHeight - yDragBarWidth)

      dragBarRight
        .attr('y', d.y + yDragBarWidthHalf)
        .attr('height', boxHeight - yDragBarWidth)

      if (d.y === 0 && mouseDragY === -1) {
        d.dragY = event.sourceEvent.pageY
        return event.sourceEvent.pageY
      }
    }

    function bottomDragResizer(event: any, d: INewBox) {
      const dragY = Math.max(
        d.y + yDragBarWidthHalf,
        Math.min(height, d.y + boxHeight + event.dy)
      )

      boxHeight = dragY - d.y
      d.boxHeight = boxHeight

      dragBarBottom.attr('y', () => {
        return dragY - yDragBarWidthHalf
      })

      dragRect.attr('height', boxHeight)
      textElement.attr('height', boxHeight - yDragBarWidth)
      dragBarLeft.attr('height', boxHeight - yDragBarWidth)
      dragBarRight.attr('height', boxHeight - yDragBarWidth)
    }

    function dragStarted(d: INewBox, thisData: any) {
      if (thisData.lastActive !== 'none') {
        select<HTMLElement, {}>(thisData.element)
          .selectAll('#r_' + thisData.lastActive)
          .attr('fill', 'rgba(var(--rgb), 0.8)')
          .attr('stroke', 'rgba(var(--rgb), 0.9)')
      }
      thisData.lastActive = id
      select<HTMLElement, {}>(thisData.element)
        .selectAll('#r_' + id)
        .attr('fill', 'rgba(var(--rgb), 0.5)')
        .attr('stroke', 'rgba(var(--rgb), 0.9)')
      textElement.attr('visibility', 'hidden')
      self.onTower = true
      thisData.oldItem = Object.assign({}, d)
    }

    function setOldItem(d: INewBox, thisData: any) {
      thisData.oldItem = Object.assign({}, d)
    }

    function clearTooltip() {
      if (self.tooltip) {
        self.tooltip.transition().duration(0).style('opacity', 0)
      }
    }

    function dragEndedTop(event: any, d: any) {
      clearTooltip()
      const oldItem = Object.assign({}, d)
      d.dragY = d.dragY - event.sourceEvent.pageY
      textElement
        .attr('transform', () => {
          return 'translate(' + [10, 30] + ')'
        })
        .attr('opacity', 1.0)
      d.cession =
        d.cession > 1
          ? (d.boxWidth * d.cession) / d.width
          : d.boxWidth / d.width
      const ibox: INewBox = { ...d }
      self.updateBox(ibox, 'top', oldItem)
    }

    function dragEndedBottom(d: any) {
      clearTooltip()
      const oldItem = Object.assign({}, d)
      textElement
        .attr('transform', () => {
          return 'translate(' + [10, 30] + ')'
        })
        .attr('opacity', 1.0)
      d.cession =
        d.cession > 1
          ? (d.boxWidth * d.cession) / d.width
          : d.boxWidth / d.width
      const ibox: INewBox = { ...d }
      self.updateBox(ibox, 'bottom', oldItem)
    }

    function dragEndedSnapRight(d: any) {
      self.towerState = 'dragEndRight'
      clearTooltip()
      const oldItem = Object.assign({}, d)
      textElement
        .attr('transform', () => {
          return 'translate(' + [10, 30] + ')'
        })
        .attr('opacity', 1.0)
      d.cession =
        d.cession > 1
          ? (d.boxWidth * d.cession) / d.width
          : d.boxWidth / d.width
      const ibox: INewBox = { ...d }
      self.updateBox(ibox, 'snapRight', oldItem)
    }

    function dragEndedSnapLeft(d: any) {
      self.towerState = 'dragEndLeft'
      clearTooltip()
      const oldItem = Object.assign({}, d)
      textElement
        .attr('transform', () => {
          return 'translate(' + [10, 30] + ')'
        })
        .attr('opacity', 1.0)
      d.cession =
        d.cession > 1
          ? (d.boxWidth * d.cession) / d.width
          : d.boxWidth / d.width
      const ibox: INewBox = { ...d }
      self.updateBox(ibox, 'snapLeft', oldItem)
    }

    function dragEndedNew(d: any, thisData: any) {
      self.towerState = 'dragEndMove'
      self.getLimitAttVal = {
        limit: self.calculateRoundedLimitValue(d, 0),
        attachment: self.calculateRoundedAttachmentValue(d),
      }
      if (d.x === thisData.oldItem.x && d.y === thisData.oldItem.y) {
        textElement
          .attr('transform', () => {
            return 'translate(' + [10, 20] + ')'
          })
          .attr('visibility', 'visible')
      }
      clearTooltip()
      if (
        move ||
        document.getElementsByClassName('swiper-wrapper').length > 0
      ) {
        d.cession =
          d.cession > 1
            ? (d.boxWidth * d.cession) / d.width
            : d.boxWidth / d.width
        const ibox: INewBox = { ...d }
        self.updateBox(ibox, 'new', thisData.oldItem)
      }
      move = false
    }

    function dragMove(event: any, d: any) {
      move = true
      const theX = (d.x = Math.max(0, Math.min(d.width - d.boxWidth, event.x)))
      const theY = (d.y = Math.max(
        0,
        Math.min(d.height - d.boxHeight, event.y)
      ))

      if (self.tooltip) {
        self.tooltip.transition().duration(200).style('opacity', 0.9)

        self.tooltip
          .html(
            (d.franchiseDeductible > 0 ? 'Franchise: ' : 'Attachment: ') +
              self.getRoundedAttachment(d)
          )
          .style('left', event.sourceEvent.pageX - 55 + 'px')
          .style('top', event.sourceEvent.pageY - 28 + 'px')
      }

      dragRect.attr('x', theX).attr('y', theY)

      textElement
        .attr('x', 10 + d.x - xDragBarWidthHalf)
        .attr('y', d.y - yDragBarWidthHalf)
        .attr('opacity', 0.0)

      tagElements.forEach(updateRibbonTagElementY(d.y))
      tagElements.forEach(updateRibbonTagElementX(d.x, d.boxWidth))

      dragBarLeft
        .attr('x', d.x - xDragBarWidthHalf)
        .attr('y', d.y + yDragBarWidthHalf)

      dragBarRight
        .attr('x', d.x + boxWidth - xDragBarWidthHalf)
        .attr('y', d.y + yDragBarWidthHalf)

      dragBarTop
        .attr('x', d.x + xDragBarWidthHalf)
        .attr('y', d.y - yDragBarWidthHalf)

      dragBarBottom
        .attr('x', d.x + xDragBarWidthHalf)
        .attr('y', d.y + boxHeight - yDragBarWidthHalf)
    }

    function dragLeftResizer(event: any, d: INewBox) {
      const origX = d.x
      d.x = Math.max(0, Math.min(d.x + boxWidth - xDragBarWidthHalf, event.x))
      boxWidth += origX - d.x

      d.boxWidth = boxWidth
      textElement.attr('x', d.x - xDragBarWidthHalf)

      dragBarLeft.attr('x', d.x - xDragBarWidthHalf)

      dragRect.attr('x', d.x).attr('width', boxWidth)

      dragBarTop
        .attr('x', d.x + xDragBarWidthHalf)
        .attr('width', boxWidth - xDragBarWidth)

      dragBarBottom
        .attr('x', d.x + xDragBarWidthHalf)
        .attr('width', boxWidth - xDragBarWidth)
    }

    function rightDragResizer(event: any, d: INewBox) {
      const dragX = Math.max(
        d.x + xDragBarWidthHalf,
        Math.min(width, d.x + boxWidth + event.dx)
      )
      boxWidth = dragX - d.x
      d.boxWidth = boxWidth

      tagElements.forEach(updateRibbonTagElementX(d.x, d.boxWidth))

      dragBarRight.attr('x', () => {
        return dragX - xDragBarWidthHalf
      })
      dragRect.attr('width', boxWidth)
      dragBarTop.attr('width', boxWidth - xDragBarWidth)
      dragBarBottom.attr('width', boxWidth - xDragBarWidth)
    }

    return item
  }


  drawLoss(
    yScale: ScaleLogarithmic<number, number> | ScaleLinear<number, number>,
    lossAmount: number,
    x0: number,
    x1: number,
    attachment: number
  ) {
    const xScale = d3
      .scaleLinear()
      .domain([0.0, 1.0])
      .range([0, this.tower.width])
    this.chart
      .append('g')
      .append('line')
      .attr('x1', xScale(x0))
      .attr('y1', yScale(attachment))
      .attr('x2', xScale(x1 + x0))
      .attr('y2', yScale(attachment))
      .attr('transform', () => {
        return 'translate(' + [this.margin.left, this.margin.top] + ')'
      })
      .attr('stroke-width', 3)
      .attr('stroke', 'red')
      .attr('stroke-dasharray', '5,5')
      .style('stroke-opacity', '0.8')
      .transition()
      .duration(2000)
      .attr('x1', xScale(x0))
      .attr('y1', yScale(lossAmount))
      .attr('x2', xScale(x1 + x0))
      .attr('y2', yScale(lossAmount))
  }

  unhighlight(id: string) {
    this.chart
      .select('#r_' + id)
      .style('fill', 'rgba(var(--rgb), 0.8)')
      .style('stroke', 'rgba(var(--rgb), 0.9)')
      .style('stroke-width', 2)
      .style('outline', 'thin solid var(--body)')
      .style('outline-offset', '-2px')
  }

  highlight(id: string) {
    this.chart
      .select('#r_' + id)
      .style('fill', 'rgba(var(--rgb), 1.0)')
      .style('stroke', 'rgba(var(--rgb), 0.9)')
      .style('outline', 'medium solid var(--body)')
      .style('outline-offset', '-3px')
  }
  clearBorder() {
    this.chart
      .selectAll('.app-tower-layer')
      .style('fill', 'rgba(var(--rgb), 0.8)')
      .style('stroke', 'rgba(var(--rgb), 0.9)')
      .style('stroke-width', 2)
      .style('outline', 'thin solid var(--body)')
      .style('outline-offset', '-2px')
      .lower()
      .classed('active', false)
  }

  setBorder(id: any) {
    if (this.lastActive !== 'none' && this.lastActive !== id) {
      this.clearBorder()
    }
    this.lastActive = id
    this.chart
      .select('#r_' + id)
      .style('fill', 'rgba(var(--rgb), 1.0)')
      .style('stroke', 'rgba(var(--rgb), 0.9)')
      .style('outline', 'medium solid var(--body)')
      .style('outline-offset', '-3px')
  }

  private wrap(
    texts: Selection<SVGTSpanElement, TowerBoxDatum, HTMLElement, any>,
    width: number,
    dy: number,
    id: string,
    height: number,
    element: HTMLElement
  ) {
    let tooSmall = false
    let fullLine = ''
    // tslint:disable-next-line: space-before-function-paren
    texts.each(function () {
      const text = select(this)
      const words = text.text().split(/\s+/).reverse()
      let line: string[] = []
      let lineNumber = 1
      const lineHeight = 1
      const x = text.attr('x')
      const y = text.attr('y')
      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', x)
        .attr('y', y)
        .attr('dy', dy + 'em')
      let firstPipe = false

      const reversed = words.reverse()

      reversed.forEach(word => {
        let forceNewLine = false
        if (word === '|' && !firstPipe) {
          firstPipe = true
          word = ''
          forceNewLine = true
        } else {
          if (fullLine === '') {
            fullLine += word
          } else {
            fullLine += ' ' + word
          }
        }
        line.push(word)
        tspan.text(line.join(' '))
        // tslint:disable-next-line:no-non-null-assertion
        if (tspan.node()!.getComputedTextLength() > width || forceNewLine) {
          line.pop()
          if (!forceNewLine) {
            tspan.text(line.join(' '))
          }
          line = [word]
          tspan = text
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr(
              'dy',
              (lineNumber = lineNumber + 0.1) * lineHeight + dy + 'em'
            )
            .text(word)
          if (word !== '') {
            if (
              // tslint:disable-next-line:no-non-null-assertion
              tspan.node()!.getComputedTextLength() > width ||
              18 * (10 * (lineNumber - Math.floor(lineNumber))) > height
            ) {
              tooSmall = true
            }
          } else if (lineNumber === 1.1) {
            if (
              // tslint:disable-next-line:no-non-null-assertion
              tspan.node()!.getComputedTextLength() > width ||
              18 * (10 * (lineNumber - Math.floor(lineNumber)) + 1) > height
            ) {
              tooSmall = true
            }
          }
        }
        forceNewLine = false
      })
    })

    if (tooSmall) {
      select<HTMLElement, {}>(element)
        .selectAll('#drag-text-' + id)
        .html('...')
      select<HTMLElement, {}>(element)
        .selectAll('#drag-text-' + id)
        .attr('fullLine', fullLine)
    } else {
      select<HTMLElement, {}>(element)
        .selectAll('#drag-text-' + id)
        .attr('fullLine', 'null')
    }
  }

  private createCSSSelector(selector: any, style: any) {
    if (!document.styleSheets) {
      return
    }
    if (document.getElementsByTagName('head').length === 0) {
      return
    }

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < document.styleSheets.length; i++) {
      const ruleStyle = document.styleSheets[i].rules[0] as CSSStyleRule
      if (ruleStyle && ruleStyle.selectorText === selector) {
        ruleStyle.style.cssText = style
        if (ruleStyle.parentStyleSheet) {
          // we have to do this, if not then the boxes appear black
          // at times based on the number of times a box color was
          // manipulated.
          // @ts-ignore
          // ruleStyle.parentStyleSheet.cssRules[0].style = style
        }
        return
      }
    }

    let styleSheet
    let mediaType

    if (document.styleSheets.length > 0) {
      for (let i = 0, l = document.styleSheets.length; i < l; i++) {
        if (document.styleSheets[i].disabled) {
          continue
        }
        const media = document.styleSheets[i].media
        if (mediaType === 'object') {
          if (
            media.mediaText === '' ||
            media.mediaText.indexOf('screen') !== -1
          ) {
            styleSheet = document.styleSheets[i]
          }
        }
        if (typeof styleSheet !== 'undefined') {
          break
        }
      }
    }

    if (typeof styleSheet === 'undefined') {
      const styleSheetElement = document.createElement('style')
      // tslint:disable-next-line: deprecation
      styleSheetElement.type = 'text/css'
      document.getElementsByTagName('head')[0].appendChild(styleSheetElement)

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < document.styleSheets.length; i++) {
        if (document.styleSheets[i].disabled) {
          continue
        }
        styleSheet = document.styleSheets[i]
      }
      if (styleSheet) {
        mediaType = typeof styleSheet.media
      }
    }

    if (styleSheet) {
      if (mediaType === 'string') {
        for (let i = 0, l = styleSheet.rules.length; i < l; i++) {
          const ruleStyle = styleSheet.rules[i] as CSSStyleRule
          if (
            ruleStyle.selectorText &&
            ruleStyle.selectorText.toLowerCase() === selector.toLowerCase()
          ) {
            ruleStyle.style.cssText = style
            return
          }
        }
        styleSheet.addRule(selector, style)
      } else if (mediaType === 'object') {
        const styleSheetLength = styleSheet.cssRules
          ? styleSheet.cssRules.length
          : 0
        for (let i = 0; i < styleSheetLength; i++) {
          const ruleStyle = styleSheet.cssRules[i] as CSSStyleRule
          if (
            ruleStyle.selectorText &&
            ruleStyle.selectorText.toLowerCase() === selector.toLowerCase()
          ) {
            ruleStyle.style.cssText = style
            return
          }
        }
        styleSheet.insertRule(selector + '{' + style + '}', styleSheetLength)
      }
    }
  }

  private hexToRGB(hex: any) {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return `rgb:${r}, ${g}, ${b}`
  }

  private createInuranceTagElements(
    logicalLayerID: string | undefined,
    box: TowerBoxElement
  ): TowerTagElement[] {
    if (!logicalLayerID) {
      return []
    }
    const views = this.inuranceTagsByLayerID[logicalLayerID]
    if (!views || views.length === 0) {
      return []
    }
    return views.map(createRibbonTagElement(box))
  }
}
