import { Injectable } from '@angular/core'
import LayerMetricDefs, {
  LayerMetricDefMap,
  resolveLayerMetricDef,
} from '../model/layer-metric-defs'
import { SortTableColumnDef } from '../../shared/sort-table/sort-table.model'
import { LayerViewValues } from '../model/layer-view'
import { StudyResponse } from 'src/app/api/model/backend.model'
import {
  LAYER_PALETTE_VIEWS,
  LayerPaletteView,
} from '../model/layer-palette.model'
import { ColumnDef } from './layer-details.model'

const mergeMetricDef =
  (defs: Partial<LayerMetricDefMap>) =>
  (col: ColumnDef): SortTableColumnDef<LayerViewValues> => {
    const def = defs[col.id]
    if (!def) {
      throw new Error(`Cannot find layer metric definition for '${col.id}`)
    }
    return { ...resolveLayerMetricDef(def), ...col }
  }

@Injectable({
  providedIn: 'root',
})
export class LayerDetailsColumnDefsService {
  columnDefs: SortTableColumnDef
  views: LayerPaletteView[] = LAYER_PALETTE_VIEWS

  // 1
  returnStandardView(): ColumnDef[] {
    return [
      {
        id: 'name',
        minWidth: '12rem',
        whenLoading: 'show',
        addNewLayer: true,
        pinnable: true,
        pinned: true,
      },
      {
        id: 'description',
        minWidth: '14rem',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'layerDetailsType', whenLoading: 'show', pinnable: true },
      {
        id: 'reinstatementsSummary',
        minWidth: '9rem',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'inuringBenefit', whenLoading: 'show', pinnable: true },
      { id: 'occurrenceLimit', whenLoading: 'show', pinnable: true },
      { id: 'occurrenceAttachment', whenLoading: 'show', pinnable: true },
      {
        id: 'aggregateLimit',
        whenLoading: 'show',
        minWidth: '5rem',
        pinnable: true,
      },
      { id: 'aggregateAttachment', whenLoading: 'show', pinnable: true },
      { id: 'cessionPercentage', whenLoading: 'show', pinnable: true },
      {
        id: 'cedingCommission',
        whenLoading: 'show',
        pinnable: true,
      },
      {
        id: 'profitCommission',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'orderedLimit', whenLoading: 'show', pinnable: true },
      { id: 'payout', whenLoading: 'show', pinnable: true },
      { id: 'trigger', whenLoading: 'show', pinnable: true },
      { id: 'nth', whenLoading: 'show', pinnable: true },
      {
        id: 'purePremium',
        label: 'Expected Ceded Loss',
        minWidth: '4.5rem',
        pinnable: true,
      },
      { id: 'rateOnLineSubject', whenLoading: 'show', pinnable: true },
      { id: 'pmpm', whenLoading: 'show', pinnable: true },
      { id: 'pmpmExpectedCededPremium', whenLoading: 'show', pinnable: true },
      { id: 'expectedCededExpenses', whenLoading: 'show', pinnable: true },
      { id: 'expectedLoss', whenLoading: 'show', pinnable: true },
      { id: 'expectedLossPercentLimit', whenLoading: 'show', pinnable: true },
      { id: 'contractROL', whenLoading: 'show', pinnable: true },
      { id: 'totalROL', whenLoading: 'show', pinnable: true },
      { id: 'premiumMultiple', whenLoading: 'show', pinnable: true },
      {
        id: 'depositPremium',
        whenLoading: 'show',
        minWidth: '5rem',
        pinnable: true,
      },
      {
        id: 'expectedReinstatementPremium',
        minWidth: '5rem',
        whenLoading: 'message',
        pinnable: true,
      },
      { id: 'expectedCededPremium', minWidth: '6.3rem', pinnable: true },
      { id: 'expectedCededMargin', minWidth: '5.5rem', pinnable: true },
      {
        id: 'expectedCededLossRatio',
        label: 'Expected Ceded Loss Ratio',
        minWidth: '4.5rem',
        pinnable: true,
      },
      {
        id: 'cededMarginToStandardDeviation',
        whenLoading: 'show',
        pinnable: true,
      },
      {
        id: 'standardDeviationExpectedLoss',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'entryProbability', minWidth: '5.6rem', pinnable: true },
      { id: 'exitProbability', minWidth: '5.6rem', pinnable: true },
      { id: 'exitAggProbability', minWidth: '6.2rem', pinnable: true },
      {
        id: 'aeptVar100',
        category: '100yr AEP',
        categoryTemplateID: 'layerTailMetrics',
        pinnable: true,
      },
      {
        id: 'aepVar100',
        category: '100yr AEP',
        categoryTemplateID: 'layerTailMetrics',
        pinnable: true,
      },
      { id: 'efficiencyTVaR', category: 'Efficiency', pinnable: true },
      { id: 'efficiencyVolatility', category: 'Efficiency', pinnable: true },
      { id: 'el', whenLoading: 'show', pinnable: true },
    ]
  }

  // 2
  returnAhlView(): ColumnDef[] {
    return [
      {
        id: 'name',
        minWidth: '12rem',
        whenLoading: 'show',
        addNewLayer: true,
        pinnable: true,
        pinned: true,
      },
      {
        id: 'description',
        minWidth: '14rem',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'layerDetailsType', whenLoading: 'show', pinnable: true },
      {
        id: 'reinstatementsSummary',
        minWidth: '9rem',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'inuringBenefit', whenLoading: 'show', pinnable: true },
      { id: 'occurrenceLimit', whenLoading: 'show', pinnable: true },
      { id: 'occurrenceAttachment', whenLoading: 'show', pinnable: true },
      {
        id: 'aggregateLimit',
        whenLoading: 'show',
        minWidth: '5rem',
        pinnable: true,
      },
      { id: 'aggregateAttachment', whenLoading: 'show', pinnable: true },
      { id: 'cessionPercentage', whenLoading: 'show', pinnable: true },
      {
        id: 'cedingCommission',
        whenLoading: 'show',
        pinnable: true,
      },
      {
        id: 'profitCommission',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'orderedLimit', whenLoading: 'show', pinnable: true },
      {
        id: 'purePremium',
        label: 'Expected Ceded Loss',
        minWidth: '4.5rem',
        pinnable: true,
      },
      { id: 'pmpm', whenLoading: 'show', pinnable: true },
      { id: 'pmpmExpectedCededPremium', whenLoading: 'show', pinnable: true },
      { id: 'expectedCededExpenses', whenLoading: 'show', pinnable: true },
      {
        id: 'depositPremium',
        whenLoading: 'show',
        minWidth: '5rem',
        pinnable: true,
      },
      {
        id: 'expectedReinstatementPremium',
        minWidth: '5rem',
        whenLoading: 'message',
        pinnable: true,
      },
      { id: 'expectedCededPremium', minWidth: '6.3rem', pinnable: true },
      { id: 'expectedCededMargin', minWidth: '5.5rem', pinnable: true },
      {
        id: 'expectedCededLossRatio',
        label: 'Expected Ceded Loss Ratio',
        minWidth: '4.5rem',
        pinnable: true,
      },
      {
        id: 'cededMarginToStandardDeviation',
        whenLoading: 'show',
        pinnable: true,
      },
      {
        id: 'standardDeviationExpectedLoss',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'entryProbability', minWidth: '5.6rem', pinnable: true },
      { id: 'probabilityExhaust', whenLoading: 'show', pinnable: true },
    ]
  }

  // 3
  returnPropertyView(): ColumnDef[] {
    return [
      {
        id: 'name',
        minWidth: '12rem',
        whenLoading: 'show',
        addNewLayer: true,
        pinnable: true,
        pinned: true,
      },
      {
        id: 'description',
        minWidth: '14rem',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'layerDetailsType', whenLoading: 'show', pinnable: true },
      {
        id: 'reinstatementsSummary',
        minWidth: '9rem',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'inuringBenefit', whenLoading: 'show', pinnable: true },
      { id: 'occurrenceLimit', whenLoading: 'show', pinnable: true },
      { id: 'occurrenceAttachment', whenLoading: 'show', pinnable: true },
      {
        id: 'aggregateLimit',
        whenLoading: 'show',
        minWidth: '5rem',
        pinnable: true,
      },
      { id: 'aggregateAttachment', whenLoading: 'show', pinnable: true },
      { id: 'cessionPercentage', whenLoading: 'show', pinnable: true },
      {
        id: 'cedingCommission',
        whenLoading: 'show',
        pinnable: true,
      },
      {
        id: 'profitCommission',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'orderedLimit', whenLoading: 'show', pinnable: true },
      { id: 'payout', whenLoading: 'show', pinnable: true },
      { id: 'trigger', whenLoading: 'show', pinnable: true },
      { id: 'nth', whenLoading: 'show', pinnable: true },
      { id: 'expectedLoss', whenLoading: 'show', pinnable: true },
      { id: 'expectedLossPercentLimit', whenLoading: 'show', pinnable: true },
      { id: 'contractROL', whenLoading: 'show', pinnable: true },
      { id: 'totalROL', whenLoading: 'show', pinnable: true },
      { id: 'premiumMultiple', whenLoading: 'show', pinnable: true },
      {
        id: 'depositPremium',
        whenLoading: 'show',
        minWidth: '5rem',
        pinnable: true,
      },
      {
        id: 'expectedReinstatementPremium',
        minWidth: '5rem',
        whenLoading: 'message',
        pinnable: true,
      },
      { id: 'expectedCededPremium', minWidth: '6.3rem', pinnable: true },
      { id: 'expectedCededMargin', minWidth: '5.5rem', pinnable: true },
      {
        id: 'expectedCededLossRatio',
        label: 'Expected Ceded Loss Ratio',
        minWidth: '4.5rem',
        pinnable: true,
      },
      {
        id: 'cededMarginToStandardDeviation',
        whenLoading: 'show',
        pinnable: true,
      },
      {
        id: 'standardDeviationExpectedLoss',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'entryProbability', minWidth: '5.6rem', pinnable: true },
      { id: 'probabilityExhaust', whenLoading: 'show', pinnable: true },
      { id: 'el', whenLoading: 'show', pinnable: true },
    ]
  }

  // 4
  returnCasualtySpecialtyView(): ColumnDef[] {
    return [
      {
        id: 'name',
        minWidth: '12rem',
        whenLoading: 'show',
        addNewLayer: true,
        pinnable: true,
        pinned: true,
      },
      {
        id: 'description',
        minWidth: '14rem',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'layerDetailsType', whenLoading: 'show', pinnable: true },
      {
        id: 'reinstatementsSummary',
        minWidth: '9rem',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'inuringBenefit', whenLoading: 'show', pinnable: true },
      { id: 'occurrenceLimit', whenLoading: 'show', pinnable: true },
      { id: 'occurrenceAttachment', whenLoading: 'show', pinnable: true },
      {
        id: 'aggregateLimit',
        whenLoading: 'show',
        minWidth: '5rem',
        pinnable: true,
      },
      { id: 'aggregateAttachment', whenLoading: 'show', pinnable: true },
      { id: 'cessionPercentage', whenLoading: 'show', pinnable: true },
      {
        id: 'cedingCommission',
        whenLoading: 'show',
        pinnable: true,
      },
      {
        id: 'profitCommission',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'orderedLimit', whenLoading: 'show', pinnable: true },
      {
        id: 'purePremium',
        label: 'Expected Ceded Loss',
        minWidth: '4.5rem',
        pinnable: true,
      },
      { id: 'rateOnLineSubject', whenLoading: 'show', pinnable: true },
      { id: 'expectedCededExpenses', whenLoading: 'show', pinnable: true },
      {
        id: 'depositPremium',
        whenLoading: 'show',
        minWidth: '5rem',
        pinnable: true,
      },
      {
        id: 'expectedReinstatementPremium',
        minWidth: '5rem',
        whenLoading: 'message',
        pinnable: true,
      },
      { id: 'expectedCededPremium', minWidth: '6.3rem', pinnable: true },
      { id: 'expectedCededMargin', minWidth: '5.5rem', pinnable: true },
      {
        id: 'expectedCededLossRatio',
        label: 'Expected Ceded Loss Ratio',
        minWidth: '4.5rem',
        pinnable: true,
      },
      {
        id: 'standardDeviationExpectedLoss',
        whenLoading: 'show',
        pinnable: true,
      },
      { id: 'entryProbability', minWidth: '5.6rem', pinnable: true },
      { id: 'probabilityExhaust', whenLoading: 'show', pinnable: true },
    ]
  }

  getColumnDef(activeAudience: number) {
    let cd: ColumnDef[]
    switch (Number(activeAudience)) {
      case 2:
        cd = this.returnAhlView()
        break
      case 3:
        cd = this.returnPropertyView()
        break
      case 4:
        cd = this.returnCasualtySpecialtyView()
        break
      default:
        cd = this.returnStandardView()
    }
    return cd
  }

  returnColumnDefs(studyID?: string, studies?: StudyResponse[]) {
    let activeAudience = '1'
    const selectedStudyView = studies?.find(
      s => s.id.toString() === studyID
    )?.sage_view
    if (selectedStudyView) {
      activeAudience =
        this.views.find(v => v.name === selectedStudyView)?.id || '1'
    }
    const columnDefs = this.getColumnDef(Number(activeAudience))
    return columnDefs.map(mergeMetricDef(LayerMetricDefs))
  }
}
