import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { selectThemeState } from '../../../core/store/theme/theme.selectors'
import { LossSetGroup, LossSetLayer } from '../../model/loss-set-layers.model'
import {
  selectCurrentLossDistributionDataTable,
  selectExploreGrossPortfolioView,
  selectExploreLoading,
  selectExploreProgram,
  selectExploreError,
  selectSelectedExploreLossSetIDs,
  selectCurrentProgram,
  selectExploreLossSetLayers,
  selectExploreLossSetGroups,
  selectExploreModifiers,
  selectCurrentCurrency,
  selectExploreSummaryData,
  selectExploreGroupSummaryData,
} from '../../store/analysis.selectors'
import {
  addLossSetIDs,
  clearExplore,
  exploreGrossXlsx,
  getSummaryData,
  getSummaryGroupData,
  removeLossSetIDs,
  resetExploreContainer,
  setExploreProgram,
  updateLossDataModifiers,
} from '../store/explore.actions'
import { Program } from '../../../core/model/program.model'
import { AppState } from '../../../core/store'
import { State as ThemeState } from '../../../core/store/theme/theme.state.facade'
import { LossSetTableState, ModifierState } from '../store/explore.reducer'
import {
  Perspective,
  AggregationMethodType,
  VaRTVaR,
} from '../../model/metrics.model'
import { LossFilter } from '../../../api/analyzere/analyzere.model'
import {
  selectCurrencyRates,
  selectCurrentAnalysisProfile,
  selectCurrentLossFilters,
} from '../../../core/store/broker/broker.selectors'
import { GrossLossTableExportXlsx } from '../store/explore.model'
import { CurrencyRate } from '../../tower/mechanics/tower.model'
import { LossSetToAdd } from '../store/explore.model'
import { selectStudies } from 'src/app/core/store/clients.selectors'
import { StudyResponse } from 'src/app/api/model/backend.model'
import { GroupSummaryRequest, SummaryDataResponse } from '../explore.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-explore-container',
  templateUrl: './explore.container.html',
})
export class ExploreContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject()
  program$: Observable<Program | undefined>
  selectedStructure$: Observable<Program | null>
  lossSetLayers$: Observable<LossSetLayer[]>
  lossSetGroups$: Observable<LossSetGroup[]>
  grossPortfolioView$: Observable<string | null>
  dataTable$: Observable<(LossSetTableState | undefined)[] | null>
  loading$: Observable<boolean>
  error$: Observable<boolean>
  savedLossSetIDs$: Observable<string[] | null>
  selectedModifiers$: Observable<ModifierState>
  currentLossFilters$: Observable<LossFilter[] | undefined>
  theme: ThemeState
  currentStructureCurrency$: Observable<string | undefined>
  currencyRates$: Observable<CurrencyRate[]>
  analysisProfileCurrency: string
  studies$: Observable<StudyResponse[] | null>
  summaryData$: Observable<SummaryDataResponse[] | null>
  groupSummaryData$: Observable<SummaryDataResponse[] | null>

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(select(selectExploreLoading))
    this.error$ = this.store.pipe(select(selectExploreError))
    this.program$ = this.store.pipe(select(selectCurrentProgram))
    this.selectedStructure$ = this.store.pipe(select(selectExploreProgram))
    this.lossSetLayers$ = this.store.pipe(select(selectExploreLossSetLayers))
    this.summaryData$ = this.store.pipe(select(selectExploreSummaryData))
    this.groupSummaryData$ = this.store.pipe(select(selectExploreGroupSummaryData))
    this.lossSetGroups$ = this.store.pipe(select(selectExploreLossSetGroups))
    this.studies$ = this.store.select(selectStudies)
    this.store
      .pipe(takeUntil(this.destroy$), select(selectThemeState))
      .subscribe(value => {
        this.theme = value
        this.cdRef.markForCheck()
      })

    this.grossPortfolioView$ = this.store.pipe(
      select(selectExploreGrossPortfolioView)
    )
    this.dataTable$ = this.store.pipe(
      select(selectCurrentLossDistributionDataTable)
    )

    this.savedLossSetIDs$ = this.store.pipe(
      select(selectSelectedExploreLossSetIDs)
    )
    this.selectedModifiers$ = this.store.pipe(select(selectExploreModifiers))

    this.currentLossFilters$ = this.store.pipe(select(selectCurrentLossFilters))
    this.currentStructureCurrency$ = this.store.pipe(
      select(selectCurrentCurrency)
    )
    this.store
      .pipe(select(selectCurrentAnalysisProfile))
      .subscribe(analysisProfile => {
        if (analysisProfile) {
          const analysisTable =
            analysisProfile.exchange_rate_profile.exchange_rate_table
          this.analysisProfileCurrency = analysisTable.base_currency
        }
      })
    this.currencyRates$ = this.store.pipe(select(selectCurrencyRates))
    this.resetExploreContainer()
  }
  resetExploreContainer() {
    this.store.dispatch(resetExploreContainer())
    this.currentStructureCurrency$ = this.store.pipe(
      select(selectCurrentCurrency)
    )
  }

  onBackClick(): boolean {
    this.router.navigate(['/home'])
    return false
  }

  onProgramAddExplore(program: Program): void {
    this.store.dispatch(clearExplore())
    this.store.dispatch(setExploreProgram({ program }))
  }

  onAddLossSetIDs($event: {
    lossSetGroupsToAdd: LossSetToAdd[]
    lossSetLayersToAdd: LossSetToAdd[]
  }): void {
    this.store.dispatch(
      addLossSetIDs({
        lossSetGroups: $event.lossSetGroupsToAdd,
        lossSetLayers: $event.lossSetLayersToAdd,
      })
    )
  }

  onRemoveLossSetIDs($event: {
    lossSetGroupsToRemove: string[]
    lossSetLayersToRemove: string[]
  }): void {
    this.store.dispatch(
      removeLossSetIDs({
        lossSetGroupsToRemove: $event.lossSetGroupsToRemove,
        lossSetLayersToRemove: $event.lossSetLayersToRemove,
      })
    )
  }

  onGetSummaryData($event: {
    lossSetLayers: LossSetToAdd[]
  }): void {
    const { lossSetLayers } = $event
    this.store.dispatch(
      getSummaryData({lossSetLayers})
    )
  }
  onGetGroupedSummaryData($event: {
    groups: GroupSummaryRequest[]
  }): void {
    const { groups } = $event
    this.store.dispatch(
      getSummaryGroupData({groups})
    )
  }

  onExportAsXlsx(grossLossTableExportXlsx: GrossLossTableExportXlsx): void {
    this.store.dispatch(exploreGrossXlsx(grossLossTableExportXlsx))
  }

  onModifiersChange(event: {
    rp?: number
    index?: number
    perspective: Perspective
    aggregationMethod: AggregationMethodType
    vartvar: VaRTVaR
    isLossRatioView: boolean
  }): void {
    this.store.dispatch(
      updateLossDataModifiers({
        rp: event.rp,
        index: event.index,
        perspective: event.perspective,
        aggregationMethod: event.aggregationMethod,
        vartvar: event.vartvar,
        isLossRatioView: event.isLossRatioView,
      })
    )
  }

  onExploreClear(): void {
    this.store.dispatch(clearExplore())
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
