import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AppState } from '../core/store'
import { fetchTracking, fetchTrackingDetails, filtersChanged, inceptionDateChanged } from './store/tracking.actions'
import { Observable } from 'rxjs'
import { TrackingData, TrackingSelectors } from './tracking.model'
import { selectInceptionTimeInterval, selectIsDetailsLoading, selectIsLoading, selectTrackingData, selectTrackingSelectors } from './store/tracking.selectors'
import { BasicControl } from '../management-information/model/management-information.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tracking-container',
  template: `<app-theme-container
    ><app-tracking
      [trackingData]="trackingData$ | async"
      [selectors]="selectors$ | async"
      [inceptionInterval]="inceptionInterval$ | async"
      [isLoading]="isLoading$ | async"
      [isDetailsLoading]="isDetailsLoading$ | async"
      (filtersChanged)="this.onFiltersChanged($event)"
      (fetchTrackingDetails)="this.onFetchTrackingDetailsData($event)"
      (inceptionDateChanged)="this.onInceptionDateChanged($event)"
    ></app-tracking
  ></app-theme-container>`,
})
export class TrackingContainerComponent implements OnInit {
  trackingData$: Observable<TrackingData[] | null>
  selectors$: Observable<TrackingSelectors | null>
  inceptionInterval$: Observable<BasicControl>
  isLoading$: Observable<boolean>
  isDetailsLoading$: Observable<boolean>

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.trackingData$ = this.store.pipe(select(selectTrackingData))
    this.selectors$ = this.store.pipe(select(selectTrackingSelectors))
    this.inceptionInterval$ = this.store.pipe(
      select(selectInceptionTimeInterval)
    )
    this.isLoading$ = this.store.pipe(select(selectIsLoading))
    this.isDetailsLoading$ = this.store.pipe(select(selectIsDetailsLoading))

    setTimeout(() => {
      this.store.dispatch(fetchTracking())
    }, 2000)
  }

  onFiltersChanged(props: { filters: Record<string, string[]> }): void {
    this.store.dispatch(
      filtersChanged({
        filters: props.filters,
      })
    )
  }

  onFetchTrackingDetailsData(programId: number) {
    this.store.dispatch(
      fetchTrackingDetails({
        programId,
      })
    )
  }

  onInceptionDateChanged($event: {
    minInceptionDate: string
    maxInceptionDate: string
  }) {
    this.store.dispatch(inceptionDateChanged($event))
  }
}
