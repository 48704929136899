<div class="add">
  <button appButton big border [matMenuTriggerFor]="addMenu">
    Add New Row
  </button>
  <mat-menu #addMenu="matMenu" class="add-menu">
    <button
      mat-menu-item
      *ngFor="let s of reinsurers"
      [matTooltip]="s"
      matTooltipPosition="right"
      (click)="onAddClick(s)"
    >
      {{ s }}
    </button>
  </mat-menu>
  <ng-container *ngTemplateOutlet="populateFrom"></ng-container>
  <button
    *ngIf="hasWhitespaceAccess"
    appButton
    big
    border
    (click)="populateFromWhiteSpace()"
    matTooltipPosition="above"
    [matTooltip]="
          whiteSpaceSyncDate
            ? 'Last synced at ' + whiteSpaceSyncDate
            : 'Has not been synced yet'
        "
  >
    Sync From Whitespace
  </button>
  <button
    *ngIf="hasWhitespaceAccess"
    appButton
    big
    accent
    border
    tabindex="-1"
    matTooltipPosition="above"
    [disabled]="pushToWhitespaceIsDisabled"
    [matTooltip]="pushToWhitespaceTooltip"
    (click)="pushToWhiteSpaceCont()"
  >
    <span>Push Signed% to WhiteSpace</span>
  </button>
  <button
    *ngIf="offPlatformLines.length > 0"
    appButton
    big
    border
    matTooltipPosition="above"
    matTooltip="Set reinsurered line from whitespace"
    (click)="openSetLineReinsurerDialog()"
  >
    Set Line Reinsurer
  </button>
  <div class="table">
    <app-sort-table
      *ngIf="assignedLineRows && assignedLineRowsTotal"
      [excelEnabled]="true"
      [columnDefs]="assignedLinesColumnDef"
      [rows]="assignedLineRowsTotal"
      [isAssignedLines]="true"
      [spacing]="'wide'"
      [bureaus]="bureaus"
      [footer]="totalRow"
      [footerReadOnly]="true"
      (valueChange)="onValueChange($event)"
      (deleteAssignedLines)="onDeleteAssignedLines($event)"
      (subjectivityClick)="onSubjectivityClick($event)"
      (updatePlaceholder)="onUpdatePlaceholder($event)"
      (handleCustomClickFunction)="onReinsurerClick($event.marketTpRef, $event.reinsurer)"
    >
    </app-sort-table>
  </div>
</div>

<ng-template #populateFrom>
  <button appButton big border [matMenuTriggerFor]="addPopulate">
    Populate From
  </button>
  <mat-menu #addPopulate="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="dynamic.menu">Layer</button>
    <app-dynamic-menu
      #dynamic
      [items]="populateFromMenuItems"
      (menuItemClick)="onMenuItemClick($event)"
    ></app-dynamic-menu>
    <button mat-menu-item (click)="marketingListAdd()">
      Marketing List
    </button>
  </mat-menu>
</ng-template>
