import { AppState } from '../index'
import { createSelector } from '@ngrx/store'

export const selectAuthState = (state: AppState) => state.auth

export const selectThumbnailBlob = createSelector(
  selectAuthState,
  state => state.blobs.thumbnail || null
)

export const selectMarketContentBlob = createSelector(
  selectAuthState,
  state => state.blobs.market || null
)

export const selectAuthenticatingUser = createSelector(
  selectAuthState,
  state => state.authenticatingUser
)

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  state => state.authenticated
)

export const selectReinsurerBlob = createSelector(
  selectAuthState,
  state => state.blobs.reinsurer || null
)

export const selectStudies = createSelector(
  selectAuthState,
  state => state.studies
)

export const selectSageLogicalRole = createSelector(
  selectAuthState,
  state => state.sageLogicalRole
)

export const selectFeatureFlags = createSelector(
  selectAuthState,
  state => state.featureFlags
)

export const selectSharedLimits = createSelector(
  selectAuthState,
  state => state.sharedLimits || []
)

export const selectSharedLimitMembers = createSelector(
  selectAuthState,
  state => state.sharedLimitMembers || []
)

export const selectHasWhitespaceAccess = createSelector(
  selectAuthState,
  state => state.hasWhitespaceAccess
)

export const selectIsUsaBasedUser = createSelector(
  selectAuthState,
  state => state.usaBasedUser
)

export const selectFacSageUrl = createSelector(
  selectAuthState,
  state => state.facSageUrl
)

export const hasPricingCurveAccess = createSelector(
  selectAuthState,
  state => state.security?.includes('Pricing Curve') ?? false
)
