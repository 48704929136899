import { createReducer, on, Action } from '@ngrx/store'
import {
  fetchTailMetrics,
  fetchTailMetricsFailure,
  fetchTailMetricsSuccess,
} from './tail-metrics.actions'
import { TailMetricsResult } from '../../model/tail-metrics.model'

export interface State extends TailMetricsResult {
  loading: boolean
  error: string | null
}

export const initialState: State = {
  loading: false,
  error: null,
  tailMetric1: 0,
  tailMetric2: 0,
  tailMetric3: 0,
  tailMetricGross1: 0,
  tailMetricGross2: 0,
  tailMetricGross3: 0,
  tailMetric4: 0,
  tailMetric5: 0,
  tailMetric6: 0,
  tailMetricGross4: 0,
  tailMetricGross5: 0,
  tailMetricGross6: 0,
}

const tailMetricsReducer = createReducer(
  initialState,
  on(fetchTailMetrics, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(fetchTailMetricsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  })),

  on(fetchTailMetricsSuccess, (state, { tailMetrics }) => ({
    ...state,
    ...tailMetrics,
    loading: false,
    error: null,
  }))
)

export function reducer(state: State | undefined, action: Action) {
  return tailMetricsReducer(state, action)
}
