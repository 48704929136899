<app-explore
  [program]="program$ | async"
  [selectedStructure]="selectedStructure$ | async"
  [lossSetLayers]="lossSetLayers$ | async"
  [lossSetGroups]="lossSetGroups$ | async"
  [grossPortfolioView]="grossPortfolioView$ | async"
  [dataTable]="dataTable$ | async"
  [loading]="loading$ | async"
  [currentStructureCurrency]="currentStructureCurrency$ | async"
  [analysisProfileCurrency]="analysisProfileCurrency"
  [currencyRates]="currencyRates$ | async"
  [error]="error$ | async"
  [savedLossSetIDs]="savedLossSetIDs$ | async"
  [selectedModifiers]="selectedModifiers$ | async"
  [lossFilters]="currentLossFilters$ | async"
  [studies]="studies$ | async"
  [summaryData]="summaryData$ | async"
  [groupSummaryData]="groupSummaryData$ | async"
  (programAddExplore)="onProgramAddExplore($event)"
  (modifiersChange)="onModifiersChange($event)"
  (exploreClear)="onExploreClear()"
  (exportAsXlsx)="onExportAsXlsx($event)"
  (addLossSetIDs)="onAddLossSetIDs($event)"
  (removeLossSetIDs)="onRemoveLossSetIDs($event)"
  (getSummaryData)="onGetSummaryData($event)"
  (onGetGroupedSummaryData)="onGetGroupedSummaryData($event)"
></app-explore>
