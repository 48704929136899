import * as dates from 'date-fns'
import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators'
import { ReinsurerService } from '../../api/reinsurer/reinsurer.service'
import {
  concatMapWithInput,
  rejectErrorWithInput,
  mergeMapWithInput,
  rejectError,
} from '../../api/util'
import { AppState } from '../../core/store'
import { ReinsurerExportService } from '../export-service/reinsurer-export.service'
import {
  selectStudyReinsurerDirty,
  selectSelectedStudyReinsurers,
} from './reinsurers.selectors'
import * as fromStudyReinsurerActions from './study-reinsurers.actions'
import { convertStudyReinsurersToFactors } from './study-reinsurers.converter'
import * as fromBroker from '../../core/store/broker/broker.selectors'
import { selectAccountOpportunities } from '../../core/store/accountopportunity.selectors'
import { MatDialog } from '@angular/material/dialog'
import { AgencyDetailDialogContainerComponent } from '../agency-detail-container/agency-detail.container'
import { FundManagerDetailDialogContainerComponent } from '../fund-manager-detail-container/fund-manager-detail.container'
import {
  AGENCY_MARKET_USE,
  AgencyDetails,
  CompanyPaperAgencySeqNum,
  FUND_MANAGER_MARKET_USE,
  Reinsurer,
  ReinsurerPriorYearView,
} from 'src/app/core/model/reinsurer.model'
import { ReinsurerDetailDialogContainerComponent } from '../reinsurer-detail-container/reinsurer-detail.container'
import { ApiResponse } from 'src/app/api/model/api.model'
import { forkJoin, of } from 'rxjs'
import { AccountOpportunity } from 'src/app/api/model/backend.model'
import { QuoteSegregatedAccountDetailsDialogContainerComponent } from '../../quote/quote-seg-account-detail/quote-seg-account-detail.container'
import * as fromReinsurersActions from '../../quote/store/reinsurer/reinsurer.actions'
import { QuoteReinsurerService } from 'src/app/api/quote-reinsurer/quote-reinsurer.service'

@Injectable()
export class StudyReinsurersEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppState>)

  constructor(
    private service: ReinsurerService,
    private exportService: ReinsurerExportService,
    private quoteReinsurerService: QuoteReinsurerService,
    private dialog: MatDialog
  ) {}

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudyReinsurerActions.fetchStudyReinsurer),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        return this.service.getReinsurers(props.carrierID, props.studyID)
      }),
      rejectErrorWithInput((error, props) =>
        this.store.dispatch(
          fromStudyReinsurerActions.fetchStudyReinsurerFailure({
            programID: props.studyID,
            error,
          })
        )
      ),
      map(([result, props]) => {
        return fromStudyReinsurerActions.fetchStudyReinsurerSuccess({
          programID: props.studyID,
          reinsurers: this.formatRes(result),
        })
      })
    )
  })

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromStudyReinsurerActions.updateReinsurer),
      withLatestFrom(this.store.pipe(select(selectStudyReinsurerDirty))),
      map(([_, changes]) =>
        fromStudyReinsurerActions.saveReinsurer({ reinsurers: changes })
      )
    )
  )

  save$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudyReinsurerActions.saveReinsurer),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        return this.service.updateReinsurers(
          convertStudyReinsurersToFactors(props.reinsurers)
        )
      }),
      rejectErrorWithInput((error, props) =>
        this.store.dispatch(
          fromStudyReinsurerActions.updateReinsurerFailure({
            programID:
              props.reinsurers[0].reinsurerProgramFactor[0].study_id.toString(),
            error,
          })
        )
      ),
      concatMap(data => [
        fromStudyReinsurerActions.fetchStudyReinsurer({
          carrierID:
            data[1].reinsurers[0].reinsurerProgramFactor[0].carrier_id.toString(),
          studyID:
            data[1].reinsurers[0].reinsurerProgramFactor[0].study_id.toString(),
        }),
        fromStudyReinsurerActions.updateReinsurerSuccess(),
        fromStudyReinsurerActions.deleteClientStudiesReinsurerDirty(),
      ])
    )
  })

  delete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudyReinsurerActions.deleteAgencyTemp),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        return this.service.deleteReinsurer(
          // tslint:disable-next-line: no-non-null-assertion
          props.re.reinsurerProgramFactor[0].id!
        )
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromStudyReinsurerActions.fetchStudiesReinsurerFailure({
            error,
          })
        )
      ),
      concatMap(data => [
        fromStudyReinsurerActions.fetchStudyReinsurer({
          carrierID: data[1].re.reinsurerProgramFactor[0].carrier_id.toString(),
          studyID: data[1].re.reinsurerProgramFactor[0].study_id.toString(),
        }),
      ])
    )
  })

  populateFromReinsurer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudyReinsurerActions.populateFromReinsurer),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        return this.service.getReinsurers(props.client, props.program)
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromStudyReinsurerActions.fetchPopulateFromReinsurerFailure({
            error,
          })
        )
      ),
      map(([re]) => {
        return fromStudyReinsurerActions.fetchPopulateFromReinsurerSuccess({
          reinsurers: this.formatRes(re),
        })
      })
    )
  })

  exportReinsurers$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromStudyReinsurerActions.exportReinsurers),
        withLatestFrom(
          this.store.pipe(select(fromBroker.selectCurrentStudy)),
          this.store.pipe(select(selectAccountOpportunities))
        ),
        concatMap(([props, program, accountOpportunities]) => {
          const accOpp: AccountOpportunity | undefined =
            accountOpportunities?.find(
              opp => opp.id === program?.opportunity_id
            )
          if (!accOpp?.opportunityRenewedFrom) {
            return [
              {
                priorYearData: null,
                customBreakOutSelections: null,
                customDisplayFieldSelections: null,
              },
            ]
          }
          return this.service
            .getReinsurerPriorYearBySfID(
              accOpp?.opportunityRenewedFrom
                ? accOpp.opportunityRenewedFrom
                : '0'
            )
            .pipe(
              map(res => {
                return {
                  priorYearData: res,
                  customBreakOutSelections: props.customBreakOutSelections,
                  customDisplayFieldSelections:
                    props.customDisplayFieldSelections,
                }
              })
            )
        }),
        withLatestFrom(this.store.pipe(select(selectSelectedStudyReinsurers))),
        mergeMapWithInput(([_, reinsurers]) => {
          const actions: Array<ApiResponse<AgencyDetails[]>> = []
          const fetchedAgencies: Reinsurer[] = []
          if (reinsurers) {
            for (const re of reinsurers) {
              if (!re.tpRef) {
                continue
              }
              if (
                (re.reinsurerProgramFactor[0].incumbent ||
                  re.reinsurerProgramFactor[0].target_market) &&
                re.market_use === AGENCY_MARKET_USE
              ) {
                actions.push(this.service.getAgencyDetailsByTpRef(re.tpRef))
                fetchedAgencies.push(re)
              }
            }
          }
          if (actions.length > 0) {
            return forkJoin(actions).pipe(
              map(responses => {
                const data: Array<AgencyDetails[]> = []
                for (const response of responses) {
                  if (response.error) {
                    return { error: response.error }
                  } else {
                    if (response.data && response.data.length > 0) {
                      const res = response.data
                      const reIndex = fetchedAgencies.findIndex(r =>
                        res.find(
                          agency =>
                            agency.agencyTPRef === r.tpRef &&
                            agency.agencySeqNumber ===
                              r.reinsurerProgramFactor[0].relation_seq_number
                        )
                      )
                      const re =
                        reIndex >= 0 ? fetchedAgencies[reIndex] : undefined
                      if (reIndex >= 0) {
                        fetchedAgencies.splice(reIndex, 1)
                      }
                      const result = res.filter(
                        r =>
                          r.agencySeqNumber ===
                          re?.reinsurerProgramFactor[0].relation_seq_number
                      )
                      data.push(result)
                    }
                  }
                }
                return { data }
              })
            )
          } else {
            const data: Array<AgencyDetails[]> = []
            return of({ data })
          }
        }),
        rejectErrorWithInput(error => {
          this.store.dispatch(
            fromStudyReinsurerActions.fetchAgencyDetailsFailure({ error })
          )
        }),
        withLatestFrom(
          this.store.pipe(select(selectSelectedStudyReinsurers)),
          this.store.pipe(select(fromBroker.selectCurrentStudyID))
        ),
        concatMap(([data, reinsurers, programID]) => {
          if (reinsurers && programID) {
            const fundManagers = reinsurers.filter(
              reinsurer => reinsurer.market_use === FUND_MANAGER_MARKET_USE
            )
            const fundManagerTpRefs: string[] = reinsurers
              .filter(
                reinsurer => reinsurer.market_use === FUND_MANAGER_MARKET_USE
              )
              .map(fundManager => fundManager.tpRef)
              .filter((tpRef): tpRef is string => typeof tpRef === 'string')
            return this.service
              .getMultipleSelectedCompanyPapers(programID, fundManagerTpRefs)
              .pipe(
                map(response => {
                  const companyPapers: AgencyDetails[][] = []
                  for (const [key, value] of Object.entries(
                    response.data ?? {}
                  )) {
                    companyPapers[fundManagerTpRefs.indexOf(key)] = value
                  }
                  const companyPapersRecord = companyPapers.reduce<
                    Record<string, AgencyDetails[]>
                  >(
                    (acc, reins, i) => ({
                      ...acc,
                      [fundManagerTpRefs[i]]: reins,
                    }),
                    {}
                  )

                  this.store.dispatch(
                    fromStudyReinsurerActions.fetchMultipleCompanyPapersSuccess(
                      {
                        programID,
                        fundManagers,
                        companyPaperPairs: companyPapersRecord,
                      }
                    )
                  )

                  return {
                    data,
                    programID,
                    reinsurers,
                    fundManagers,
                    fundManagerTpRefs,
                    companyPapersRecord,
                  }
                })
              )
          } else {
            return of({
              data,
              programID,
              reinsurers,
              fundManagers: null,
              fundManagerTpRefs: null,
              companyPapersRecord: null,
            })
          }
        }),
        withLatestFrom(
          this.store.pipe(select(selectAccountOpportunities)),
          this.store.pipe(select(fromBroker.selectCurrentStudy))
        )
      )
      .pipe(
        concatMap(([data, opportunities, program]) => {
          const opportunity = opportunities?.find(
            opp => opp.id === program?.opportunity_id
          )
          let fundManTpRefs: string[] = []
          if (data.reinsurers) {
            fundManTpRefs = data.reinsurers
              .filter(
                reinsurer => reinsurer.market_use === FUND_MANAGER_MARKET_USE
              )
              .map(fundManager => fundManager.tpRef)
              .filter((tpRef): tpRef is string => typeof tpRef === 'string')
          } else if (data.fundManagers && data.fundManagerTpRefs) {
            fundManTpRefs = data.fundManagerTpRefs
          }
          return this.service
            .getMultipleSegregatedAccountDetails(
              fundManTpRefs,
              data.programID ?? ''
            )
            .pipe(
              map(response => {
                if (response.data) {
                  let segregatedAccounts: AgencyDetails[][] = []
                  for (const value of Object.values(response.data)) {
                    segregatedAccounts.push(value)
                  }
                  segregatedAccounts.map(accounts =>
                    accounts.map(account => {
                      account.ilsInfo =
                        account.ilsName + ' - ' + account.internalNarr
                    })
                  )
                  segregatedAccounts = segregatedAccounts.map(accounts =>
                    accounts.filter(
                      account =>
                        account.expiryDate &&
                        account.effectiveDate &&
                        account.effectiveDate <=
                          dates.parseISO(
                            opportunity?.opportunityInceptionDate ?? ''
                          ) &&
                        dates.parseISO(
                          opportunity?.opportunityInceptionDate ?? ''
                        ) <= account.expiryDate
                    )
                  )
                  const segregatedAccountsRecord = segregatedAccounts.reduce<
                    Record<string, AgencyDetails[]>
                  >(
                    (acc, seg, i) => ({
                      ...acc,
                      [fundManTpRefs[i]]: seg,
                    }),
                    {}
                  )
                  this.store.dispatch(
                    fromStudyReinsurerActions.fetchMultipleSegregatedAccountDetailsSuccess(
                      {
                        programID: data.programID ?? '',
                        fundManagers: data.fundManagers ?? [],
                        segregatedAccountPairs: segregatedAccountsRecord,
                      }
                    )
                  )
                  return {
                    ...data,
                    segregatedAccountsRecord,
                  }
                } else {
                  return { ...data, segregatedAccountsRecord: null }
                }
              })
            )
        })
      )
      .pipe(
        withLatestFrom(
          this.store.pipe(select(fromBroker.selectCurrentClient)),
          this.store.pipe(select(fromBroker.selectCurrentStudy)),
          this.store.pipe(select(selectAccountOpportunities)),
          this.store.pipe(select(fromBroker.selectCurrentYear))
        ),
        map(
          ([
            { data, companyPapersRecord, segregatedAccountsRecord },
            client,
            program,
            accountOpportunities,
            clientYear,
          ]) => {
            let customBreakOutSelections: string[] = []
            let customDisplayFieldSelections: string[] = []
            const agencyDetails = data[0]
            const priorYearData = data[1][0]
            const reinsurers = data[1][1]
            const accOpp = accountOpportunities?.find(
              opp => opp.id === program?.opportunity_id
            )
            const year = clientYear?.year
            if (
              priorYearData.customBreakOutSelections &&
              priorYearData.customBreakOutSelections.length > 0
            ) {
              customBreakOutSelections = priorYearData.customBreakOutSelections
            }

            if (
              priorYearData.customDisplayFieldSelections &&
              priorYearData.customDisplayFieldSelections.length > 0
            ) {
              customDisplayFieldSelections =
                priorYearData.customDisplayFieldSelections
            }

            let priorData: ReinsurerPriorYearView[] = []
            if (
              priorYearData.priorYearData &&
              priorYearData.priorYearData.data &&
              priorYearData.priorYearData.data.length > 0
            ) {
              priorData = priorYearData.priorYearData.data
            }
            return this.exportService.exportXLSX(
              client,
              program,
              year,
              reinsurers,
              accOpp?.opportunityInceptionDate,
              accOpp?.accountName,
              accOpp?.opportunityBU,
              !!accOpp?.germanInvolvement,
              agencyDetails,
              priorData,
              companyPapersRecord,
              segregatedAccountsRecord,
              customBreakOutSelections,
              customDisplayFieldSelections
            )
          }
        ),
        map(() => {
          return fromStudyReinsurerActions.exportReinsurersSuccess()
        })
      )
  })

  openAgencyDetailsDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudyReinsurerActions.openAgencyDetailsDialog),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        // tslint:disable-next-line: no-non-null-assertion
        return this.service.getAgencyDetailsByTpRefAndProgram(
          props.data.tpRef!,
          props.programId
        )
      }),
      rejectErrorWithInput((error, _) =>
        this.store.dispatch(
          fromStudyReinsurerActions.fetchAgencyDetailsFailure({
            error,
          })
        )
      ),
      map(([result, props]) => {
        this.dialog.open(AgencyDetailDialogContainerComponent, {
          data: {
            reinsurer: props.data,
            select: props.select,
            agencyDetails:
              result.length > 0
                ? result.sort((a, b) => a.agencySeqNumber - b.agencySeqNumber)
                : [],
          },
        })
        return { type: 'No Action' }
      })
    )
  })

  openFundManagerDetailsDialog$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromStudyReinsurerActions.openFundManagerDetailsDialog),
        map(({ type, ...props }) => props),
        withLatestFrom(
          this.store.pipe(select(selectAccountOpportunities)),
          this.store.pipe(select(fromBroker.selectCurrentStudy))
        ),
        concatMapWithInput(([props, opportunities, program]) => {
          const opportunity = opportunities?.find(
            opp => opp.id === program?.opportunity_id
          )
          return this.service.getCompanyPapers(
            // tslint:disable-next-line: no-non-null-assertion
            props.reinsurer.tpRef!,
            opportunity?.opportunityInceptionDate
          )
        }),
        rejectErrorWithInput((error, _) =>
          this.store.dispatch(
            fromStudyReinsurerActions.fetchCompanyPapersFailure({
              error,
            })
          )
        ),
        concatMapWithInput(([_, [props, opportunities, program]]) => {
          const opportunity = opportunities?.find(
            opp => opp.id === program?.opportunity_id
          )
          return this.service.getSegregatedAccountDetails(
            // tslint:disable-next-line: no-non-null-assertion
            props.reinsurer.tpRef!,
            opportunity?.opportunityInceptionDate
          )
        }),
        rejectErrorWithInput((error, _) =>
          this.store.dispatch(
            fromStudyReinsurerActions.fetchSegregatedAccountDetailsFailure({
              error,
            })
          )
        ),
        withLatestFrom(
          this.store.pipe(select(selectAccountOpportunities)),
          this.store.pipe(select(fromBroker.selectCurrentStudy))
        ),
        concatMap(
          ([[result, [companyPapers, [props]]], opportunities, program]) => {
            return this.service
              .getSelectedSegregatedAccountDetails(
                // tslint:disable-next-line: no-non-null-assertion
                props.reinsurer.tpRef!,
                props.programID
              )
              .pipe(
                map(res => {
                  const opportunity = opportunities?.find(
                    opp => opp.id === program?.opportunity_id
                  )
                  const segregatedAccounts = result
                  const selectedSegregatedAccounts = res.data

                  segregatedAccounts.map(
                    account =>
                      (account.ilsInfo = `${account.ilsName} - ${account.internalNarr}`)
                  )

                  selectedSegregatedAccounts?.map(
                    account =>
                      (account.ilsInfo = `${account.ilsName} - ${account.internalNarr}`)
                  )
                  // tslint:disable
                  const filteredSegregatedAccounts = segregatedAccounts?.filter(
                    account =>
                      account.effectiveDate! <=
                        dates.parseISO(
                          opportunity?.opportunityInceptionDate!
                        ) &&
                      dates.parseISO(opportunity?.opportunityInceptionDate!) <=
                        account.expiryDate!
                  )

                  const filteredSelectedSegregatedAccounts =
                    selectedSegregatedAccounts?.filter(
                      account =>
                        account.effectiveDate! <=
                          dates.parseISO(
                            opportunity?.opportunityInceptionDate!
                          ) &&
                        dates.parseISO(
                          opportunity?.opportunityInceptionDate!
                        ) <= account.expiryDate!
                    )

                  return {
                    companyPapers,
                    segregatedAccounts: filteredSegregatedAccounts,
                    selectedSegregatedAccounts:
                      filteredSelectedSegregatedAccounts,
                    props,
                  }
                })
                // tslint:enable
              )
          }
        )
      )
      .pipe(
        concatMap(
          ({
            companyPapers,
            segregatedAccounts,
            selectedSegregatedAccounts,
            props,
          }) => {
            return this.service
              .getSelectedCompanyPapers(
                // tslint:disable-next-line: no-non-null-assertion
                props.reinsurer.tpRef!,
                props.programID
              )
              .pipe(
                map(res => {
                  return {
                    companyPapers,
                    selectedCompanyPapers: res.data,
                    segregatedAccounts,
                    selectedSegregatedAccounts,
                    props,
                  }
                })
              )
          }
        ),
        concatMap(
          ({
            companyPapers,
            selectedCompanyPapers,
            segregatedAccounts,
            selectedSegregatedAccounts,
            props,
          }) => {
            const actions = []
            const filteredCompanyPapers = companyPapers
            const filteredSelectedCompanyPapers = selectedCompanyPapers

            this.dialog.open(FundManagerDetailDialogContainerComponent, {
              minWidth: '90vw',
              data: {
                programId:
                  props.reinsurer.reinsurerProgramFactor[0].study_id.toString(),
                reinsurer: props.reinsurer,
                companyPapers: filteredCompanyPapers,
                selectedCompanyPapers: filteredSelectedCompanyPapers,
                incumbentOrTargetMarket: props.select,
              },
            })

            actions.push(
              fromStudyReinsurerActions.fetchSegregatedAccountDetailsSuccess({
                programID: props.programID,
                fundManager: props.reinsurer,
                segregatedAccounts,
                selectedSegregatedAccounts: selectedSegregatedAccounts
                  ? selectedSegregatedAccounts
                  : [],
              })
            )

            actions.push(
              fromStudyReinsurerActions.fetchCompanyPapersSuccess({
                programID:
                  props.reinsurer.reinsurerProgramFactor[0].study_id.toString(),
                reinsurer: props.reinsurer,
                companyPapers: filteredCompanyPapers,
                selectedCompanyPapers: filteredSelectedCompanyPapers
                  ? filteredSelectedCompanyPapers
                  : [],
              })
            )

            return actions
          }
        )
      )
  })

  fetchMultipleCompanyPapers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudyReinsurerActions.fetchMultipleCompanyPapers),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        return this.service.getMultipleSelectedCompanyPapers(
          props.programID,
          // tslint:disable-next-line: no-non-null-assertion
          props.fundManagers.map(fundManager => fundManager.tpRef!)
        )
      }),
      rejectErrorWithInput(error => {
        this.store.dispatch(
          fromStudyReinsurerActions.fetchAgencyDetailsFailure({ error })
        )
      }),
      withLatestFrom(this.store.pipe(select(selectSelectedStudyReinsurers))),
      concatMap(([[result, props], reinsurers]) => {
        const actions = []
        if (reinsurers) {
          const fundManagerTpRefs = props.fundManagers.map(
            // tslint:disable-next-line: no-non-null-assertion
            fundManager => fundManager.tpRef!
          )
          const companyPapers: AgencyDetails[][] = []
          for (const [key, value] of Object.entries(result)) {
            // tslint:disable-next-line: no-non-null-assertion
            companyPapers[fundManagerTpRefs.indexOf(key)] = value
          }
          const companyPapersRecord = companyPapers.reduce<
            Record<string, AgencyDetails[]>
          >(
            (acc, reins, i) => ({
              ...acc,
              [fundManagerTpRefs[i]]: reins,
            }),
            {}
          )
          actions.push(
            fromStudyReinsurerActions.fetchMultipleCompanyPapersSuccess({
              programID: props.programID,
              fundManagers: props.fundManagers,
              companyPaperPairs: companyPapersRecord,
            })
          )
        }
        return actions
      })
    )
  })

  saveCompanyPapers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudyReinsurerActions.saveCompanyPapers),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        const companyPaperAgencySeqNum: CompanyPaperAgencySeqNum[] =
          props.selectedCompanyPapers.map(d => {
            return {
              companyPaper: d.agencyTPRef,
              agencySeqNumber: d.agencySeqNumber,
            }
          })
        return this.service.postCompanyPapers(
          // tslint:disable-next-line: no-non-null-assertion
          props.reinsurer.tpRef!,
          companyPaperAgencySeqNum,
          props.programID
        )
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromStudyReinsurerActions.saveCompanyPapersFailure({
            error,
          })
        )
      ),
      map(([response, props]) => {
        return fromStudyReinsurerActions.saveCompanyPapersSuccess({
          programID: props.programID,
          reinsurer: props.reinsurer,
          selectedCompanyPapers: response,
        })
      })
    )
  })

  saveSegregatedAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudyReinsurerActions.saveSegregatedAccountDetails),
      map(({ type, ...props }) => props),
      concatMapWithInput(props => {
        return this.service.postSegregatedAccounts(
          // tslint:disable-next-line: no-non-null-assertion
          props.reinsurer.tpRef!,
          // tslint:disable-next-line: no-non-null-assertion
          props.selectedSegregatedAccounts.map(account => account.agencyTPRef!),
          props.programID
        )
      }),
      rejectErrorWithInput(error =>
        this.store.dispatch(
          fromStudyReinsurerActions.saveSegregatedAccountDetailsFailure({
            error,
          })
        )
      ),
      map(([response, props]) => {
        return fromStudyReinsurerActions.saveSegregatedAccountDetailsSuccess({
          programID: props.programID,
          reinsurer: props.reinsurer,
          selectedSegregatedAccounts: response,
        })
      })
    )
  })

  openSegregatedAccountDetailsDialog$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromStudyReinsurerActions.openSegregatedAccountDetailsDialog),
        map(({ type, ...props }) => props),
        withLatestFrom(
          this.store.pipe(select(selectAccountOpportunities)),
          this.store.pipe(select(fromBroker.selectCurrentStudy))
        ),
        concatMapWithInput(([props, opportunities, program]) => {
          const opportunity = opportunities?.find(
            opp => opp.id === program?.opportunity_id
          )
          return this.service.getSegregatedAccountDetails(
            // tslint:disable-next-line: no-non-null-assertion
            props.fundManager.tpRef!,
            opportunity?.opportunityInceptionDate
          )
        }),
        rejectErrorWithInput((error, _) =>
          this.store.dispatch(
            fromStudyReinsurerActions.fetchSegregatedAccountDetailsFailure({
              error,
            })
          )
        ),
        concatMap(([result, [props]]) => {
          return this.service
            .getSelectedSegregatedAccountDetails(
              // tslint:disable-next-line: no-non-null-assertion
              props.fundManager.tpRef!,
              props.programID
            )
            .pipe(
              map(res => {
                const segregatedAccountsResult = result
                const selectedSegregatedAccountsResult = res.data

                segregatedAccountsResult.map(
                  account =>
                    (account.ilsInfo = `${account.ilsName} - ${account.internalNarr}`)
                )

                selectedSegregatedAccountsResult?.map(
                  account =>
                    (account.ilsInfo = `${account.ilsName} - ${account.internalNarr}`)
                )

                return {
                  segregatedAccounts: segregatedAccountsResult,
                  selectedSegregatedAccounts: selectedSegregatedAccountsResult,
                  props,
                }
              })
              // tslint:enable
            )
        })
      )
      .pipe(
        map(({ segregatedAccounts, selectedSegregatedAccounts, props }) => {
          this.dialog.open(
            QuoteSegregatedAccountDetailsDialogContainerComponent,
            {
              minWidth: '90vw',
              data: {
                programID: props.programID,
                fundManager: props.fundManager,
                segregatedAccounts,
                selectedSegregatedAccounts,
              },
            }
          )

          return fromStudyReinsurerActions.fetchSegregatedAccountDetailsSuccess(
            {
              programID: props.programID,
              fundManager: props.fundManager,
              segregatedAccounts,
              selectedSegregatedAccounts: selectedSegregatedAccounts
                ? selectedSegregatedAccounts
                : [],
            }
          )
        })
      )
  })

  openReinsurerDetailsDialog$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromStudyReinsurerActions.openReinsurerDetailsDialog),
        map(({ type, ...props }) => props),
        concatMapWithInput(props => {
          // tslint:disable-next-line: no-non-null-assertion
          return this.service.getAgencyDetailsByTpRef(props.data.tpRef!)
        }),
        rejectErrorWithInput((error, _) =>
          this.store.dispatch(
            fromStudyReinsurerActions.fetchAgencyDetailsFailure({
              error,
            })
          )
        ),
        concatMap(([result, props]) => {
          const results = {
            updatedAgencies:
              result.length > 0
                ? result.filter(
                    a =>
                      a.agencySeqNumber ===
                      props.data.reinsurerProgramFactor[0].relation_seq_number
                  )
                : [],
            reinsurer: props.data,
            programID: props.programID,
          }

          return this.service
            .getSelectedCompanyPapers(
              // tslint:disable-next-line: no-non-null-assertion
              results.reinsurer.tpRef!,
              results.reinsurer.reinsurerProgramFactor[0].study_id.toString()
            )
            .pipe(
              map(res => {
                const filteredSelectedCompanyPapers = res.data
                if (res.data) {
                  // tslint:disable-next-line: no-non-null-assertion
                  this.store.dispatch(
                    fromStudyReinsurerActions.fetchCompanyPapersSuccess({
                      programID:
                        results.reinsurer.reinsurerProgramFactor[0].study_id.toString(),
                      reinsurer: results.reinsurer,
                      companyPapers: [],
                      selectedCompanyPapers: filteredSelectedCompanyPapers
                        ? filteredSelectedCompanyPapers
                        : [],
                    })
                  )
                }

                return {
                  ...results,
                  selectedCompanyPapers: filteredSelectedCompanyPapers
                    ? filteredSelectedCompanyPapers
                    : [],
                }
              })
            )
        })
      )
      .pipe(
        withLatestFrom(this.store.pipe(select(selectSelectedStudyReinsurers))),
        map(([result, reinsurers]) => {
          const relationReinsurers: Reinsurer[] = []
          if (
            result.updatedAgencies.length > 0 &&
            reinsurers &&
            reinsurers.length > 0
          ) {
            result.updatedAgencies.forEach(a => {
              const found = reinsurers.find(r => r.tpRef === a.memberTPRef)
              if (found) {
                relationReinsurers.push(found)
              }
            })
          }
          this.dialog.open(ReinsurerDetailDialogContainerComponent, {
            data: {
              reinsurer: result.reinsurer,
              agencyDetails: result.updatedAgencies,
              relatedReinsurers: relationReinsurers,
              selectedCompanyPapers: result.selectedCompanyPapers,
            },
            maxHeight: '80vh',
          })
          return { type: 'No Action' }
        })
      )
  })

  saveAgencyDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromStudyReinsurerActions.saveAgencyDetails),
      map(({ type, ...props }) => props),
      withLatestFrom(this.store.pipe(select(fromBroker.selectCurrentStudy))),
      concatMapWithInput(([props, program]) => {
        return this.service.postAgencyDetailsToOpenTWINS(
          props.agencyDetails,
          program
        )
      }),
      rejectErrorWithInput((error, _) =>
        this.store.dispatch(
          fromStudyReinsurerActions.postAgencyDetailsToOpenTWINSFailure({
            error,
          })
        )
      ),
      concatMap(() => {
        const actions = [
          fromStudyReinsurerActions.saveAgencyDetailsSuccess(),
          fromStudyReinsurerActions.updateReinsurer(),
        ]

        return actions
      })
    )
  )

  fetchSubjectivitiesByReinsurer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReinsurersActions.fetchSubjectivitiesByReinsurer),
      switchMap(action => {
        return this.quoteReinsurerService.fetchSubjectivitiesByReinsurer(
          String(action.tpRef)
        )
      }),
      rejectError(error =>
        this.store.dispatch(
          fromReinsurersActions.fetchSubjectivitiesByReinsurerFailure({ error })
        )
      ),
      map(subjectivities => {
        return fromReinsurersActions.fetchSubjectivitiesByReinsurerSuccess({
          subjectivities,
        })
      })
    )
  })

  private formatRes(reinsurers: Reinsurer[]) {
    const res: Reinsurer[] = []
    reinsurers.forEach(r => {
      if (
        r &&
        r.reinsurerProgramFactor &&
        r.reinsurerProgramFactor.length > 1
      ) {
        if (r.market_use == FUND_MANAGER_MARKET_USE) {
          const reinsurer: Reinsurer = {
            ...r,
            reinsurerProgramFactor: [r.reinsurerProgramFactor[0]],
          }
          res.push(reinsurer)
        } else {
          r.reinsurerProgramFactor.forEach(r1 => {
            const reinsurer: Reinsurer = {
              ...r,
              reinsurerProgramFactor: [],
            }
            reinsurer.reinsurerProgramFactor = [r1]
            res.push(reinsurer)
          })
        }
      } else {
        res.push(r)
      }
    })
    return res
  }
}
