import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { Reinsurer } from 'src/app/core/model/reinsurer.model'
import {
  SavedPricingCurveEntry,
} from 'src/app/pricingcurve/model/pricing-curve.model'
import { LayerView } from '../../model/layer-view'
import { Layer, PhysicalLayer } from '../../model/layers.model'
import { LossSetLayer } from '../../model/loss-set-layers.model'
import { LayerState } from '../../store/ceded-layers/layers.reducer'
import { LayerMetricsState } from '../../store/metrics/layers-metrics.reducer'
import {
  CurveEntryUpdatePayload,
  DefaultSavedCurveEntry,
  LayerEntry,
  LayerTypeDefaultEntry,
  PRICING_CURVE_LAYER_TYPE_DEFAULT,
} from '../technical-premium.model'
import { layerIds } from '../../model/layer-palette.model'
import { currencySymbol } from '../../model/layers.util'
import {
  getPricingCurvesForLayer,
  isCurveWeightTotalValid,
  layerEntryToString,
} from '../technical-premium.utils'
import { Program } from 'src/app/core/model/program.model'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-layer-default-list',
  styleUrls: ['./layer-default-list.component.scss'],
  templateUrl: './layer-default-list.component.html',
})
export class LayerDefaultListComponent implements OnChanges {
  @Input() towerLayers: LayerState[]
  @Input() savedCurves: SavedPricingCurveEntry[]
  @Input() reinsurersList: Reinsurer[]
  @Input() lossSetLayers: LossSetLayer[]
  @Input() layersViewIds: Record<string, string>
  @Input() layerTypeEntries: LayerTypeDefaultEntry
  @Input() layerMetrics: Record<string, LayerMetricsState>
  @Input() currentProgram: Program | undefined

  @Output() layerResize = new EventEmitter<Partial<PhysicalLayer>>()
  @Output() dialogClosed = new EventEmitter()
  @Output() dispatchLayerEntries = new EventEmitter<LayerEntry[]>()
  @Output() dispatchLayerEntryUpdate = new EventEmitter<LayerEntry>()

  layerMetricToLayerMapping: Record<string, LayerMetricsState> = {}
  initialLayerEntries: LayerEntry[]
  entryHasError = false
  premiumMapping: Record<string, number | null> = {}
  layerViews: Record<string, LayerView> = {}
  layerEntries: LayerEntry[]

  updateLayerEntryCurveValue(
    layerEntryId: number,
    curveIndex: number,
    value: number
  ): void {
    const newLayerEntries = [...this.layerEntries]
    const entryIndex = newLayerEntries.findIndex(
      ({ id }) => id === layerEntryId
    )
    if (entryIndex < 0) {
      return
    }
    const entry = newLayerEntries[entryIndex]
    const curveEntry = entry.pricingCurves[curveIndex]
    const newCurveEntry: DefaultSavedCurveEntry = {
      ...curveEntry,
      value,
    }
    entry.pricingCurves.splice(curveIndex, 1, newCurveEntry)
    this.layerEntries = newLayerEntries
  }

  getIsQSLayer(layer: Layer) {
    return (
      layer.meta_data.sage_layer_type === layerIds.noncatQs ||
      layer.meta_data.sage_layer_type === layerIds.catQs ||
      layer.meta_data.sage_layer_type === layerIds.ahlQs
    )
  }

  initLayerEntries(): void {
    this.layerEntries = this.towerLayers.map(
      (layer: LayerState, index: number) => {
        const layerType = layer.layer.meta_data.sage_layer_type ?? ''
        const previousLayerEntry = this.layerEntries?.find(
          entry => entry.layerId === layer.layer.id
        )
        const pricingCurves = getPricingCurvesForLayer(layer)
        const pricingCurveIds: number[] = pricingCurves.reduce<number[]>(
          (acc, val) => {
            if (val.id != null) {
              acc.push(val.id)
            }
            return acc
          },
          []
        )
        return {
          id: index,
          layerId: layer.layer.id,
          physicalLayerId: layer.layer.physicalLayer.id,
          name:
            layer.layer.meta_data.layerName ??
            layer.layer.physicalLayer.description ??
            'Layer Name',
          layerType,
          pricingCurves,
          pricingCurveIds,
          hasError: !isCurveWeightTotalValid(pricingCurves),
          isQS: this.getIsQSLayer(layer.layer),
          currency: layer.layer.currency
            ? currencySymbol(layer.layer.currency)
            : '$',
          modified: previousLayerEntry?.modified ?? false,
          saveForOnlyNewLayers: null,
        }
      }
    )
    if (
      this.layerEntries.length &&
      this.layerEntries.every(entry => !entry.modified)
    ) {
      this.initialLayerEntries = [...this.layerEntries]
      this.dispatchLayerEntries.emit(this.initialLayerEntries)
    }
  }

  initLayerMetricsMapping(): void {
    if (this.towerLayers && this.layersViewIds) {
      this.towerLayers
        .map(layer => layer.layer.id)
        .forEach(id => {
          const viewId = this.layersViewIds[id]
          this.layerMetricToLayerMapping[id] = this.layerMetrics[viewId]
        })
    }
  }

  initLayerViews(): void {
    if (this.towerLayers && this.savedCurves) {
      const updateLayers = this.towerLayers.filter(layer => {
        const layerEntry = this.layerEntries.find(
          entry => entry.layerId === layer.layer.id
          // tslint:disable-next-line: no-non-null-assertion
        )!
        const layerTypeEntry = this.layerTypeEntries[layerEntry.layerType]
        return (
          !layerTypeEntry.hasError &&
          !!this.layerMetricToLayerMapping[layer.layer.id]
        )
      })
      updateLayers.map(layer => {
        const layerEntry = this.layerEntries.find(
          entry => entry.layerId === layer.layer.id
          // tslint:disable-next-line: no-non-null-assertion
        )!
        const layerTypeEntry = this.layerTypeEntries[layerEntry.layerType]
        this.layerViews[layer.layer.id] = new LayerView(
          this.towerLayers,
          layer.layer,
          {
            // tslint:disable-next-line: no-non-null-assertion
            metrics: this.layerMetricToLayerMapping[layer.layer.id].metrics!,
            // lossSets: this.lossSetLayers,
            // reinsurers: this.reinsurersList,
            programDefaultPricingCurves:
              this.layerTypeEntries[layerTypeEntry.layerType].pricingCurves,
            savedPricingCurves: this.savedCurves,
            currentProgram: this.currentProgram,
          }
        )
      })
    }
  }

  initPremiums(): void {
    if (
      this.towerLayers &&
      this.towerLayers.every(layer => !!this.layerViews[layer.layer.id])
    ) {
      this.towerLayers.map(layer => {
        const layerId = layer.layer.id
        this.premiumMapping[layerId] = this.layerViews[layerId].technicalPremium
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.towerLayers) {
      this.initLayerEntries()
    }
    // Check and see if the metric states are done loading
    if (
      Object.values(this.layerMetrics).length &&
      Object.values(this.layerMetrics).every(
        metricState => !metricState.loading
      )
    ) {
      this.initLayerMetricsMapping()
      this.initLayerViews()
      this.initPremiums()
    }
    if (changes.layerTypeEntries && !changes.layerTypeEntries.firstChange) {
      // Layer type entries have changed, figure out which keys are newly updated
      // Not using the modified flag included in object to prevent duplicate physical layer updates
      const change = changes.layerTypeEntries
      if (
        Object.keys(change.currentValue).length &&
        Object.keys(change.previousValue).length
      ) {
        this.findAndUpdateDefaultLayersOnLayerTypeChange(
          change.currentValue,
          change.previousValue
        )
      }
    }
  }

  findAndUpdateDefaultLayersOnLayerTypeChange(
    currentEntries: LayerTypeDefaultEntry,
    previousEntries: LayerTypeDefaultEntry
  ): void {
    const updatedKeys = this.findUpdatedLayerTypeKeys(
      currentEntries,
      previousEntries
    )
    if (!updatedKeys.length) {
      return
    }
    const updatedLayers = this.layerEntries.filter(layer => {
      const towerLayer = this.towerLayers.find(
        tLayer => tLayer.layer.id === layer.layerId
      )
      // Only update the layers that need to have premium / ceding commission updated
      return (
        towerLayer?.layer.physicalLayer.meta_data.technicalPremiumChecked &&
        layer.layerType &&
        // The layer type default entry has a null curve id
        !!layer.pricingCurves.find(curve => curve.id == null)
      )
    })
    if (!updatedLayers.length) {
      return
    }
    updatedLayers.forEach(layer => {
      const layerTypeEntry = this.layerTypeEntries[layer.layerType]
      this.checkForValidityAndUpdate(
        {
          ...layer,
          pricingCurveIds: layerTypeEntry.pricingCurveIds,
          pricingCurves: layerTypeEntry.pricingCurves,
        },
        true
      )
    })
  }

  findUpdatedLayerTypeKeys(
    currentEntries: LayerTypeDefaultEntry,
    previousEntries: LayerTypeDefaultEntry
  ): string[] {
    return Object.entries(currentEntries)
      .filter(([key, val]) => {
        const prevEntry = previousEntries[key]
        const curvesChanged = this.checkForIdChanges(val, prevEntry)
        if (curvesChanged) {
          return true
        }
        // All curves are the same, check if their weight changed
        return val.pricingCurves.some(curve => {
          const previousCurveValue = prevEntry.pricingCurves.find(
            prevCurve => prevCurve.id === curve.id
          )
          if (!previousCurveValue) {
            return false
          }
          // Check weights against one another
          return curve.percentage !== previousCurveValue.percentage
        })
      })
      .map(([key]) => key)
  }

  checkForIdChanges(currentEntry: LayerEntry, prevEntry: LayerEntry): boolean {
    // Check if any curve id is in previous that isn't in current and vice versa
    return (
      currentEntry.pricingCurveIds.some(
        id => !prevEntry.pricingCurveIds.includes(id)
      ) ||
      prevEntry.pricingCurveIds.some(
        id => !currentEntry.pricingCurveIds.includes(id)
      )
    )
  }

  checkForValidityAndUpdate(
    entry: LayerEntry,
    layerTypeUpdate?: boolean
  ): void {
    if (isCurveWeightTotalValid(entry.pricingCurves)) {
      const layer = this.towerLayers.find(
        layer => layer.layer.id === entry.layerId
      )
      if (!layer) {
        return
      }
      const view = this.layerViews[layer.layer.id]
      const ignoreCurveString =
        (entry.pricingCurves.length === 1 && !entry.pricingCurves[0].id) ||
        layerTypeUpdate
      const updateString = !ignoreCurveString ? layerEntryToString(entry) : ''

      view.layer.physicalLayer.meta_data.reinsurers = updateString
      view.layer.physicalLayer.meta_data.pricingCurves = updateString
      const newPremiumValue = view.technicalPremium

      if (newPremiumValue == null) {
        return
      }

      const newCurveString = !ignoreCurveString
        ? layerEntryToString(entry, newPremiumValue)
        : ''

      // TODO Going to need to handle ceding commission as well
      this.layerResize.emit({
        ...layer.layer.physicalLayer,
        meta_data: {
          ...layer.layer.physicalLayer.meta_data,
          pricingcurve_is_default:
            entry.pricingCurves.length === 1 &&
            entry.pricingCurves[0].id == null,
          reinsurers: newCurveString,
          pricingCurves: newCurveString,
          technicalPremium: newPremiumValue,
        },
        premium: {
          ...layer.layer.physicalLayer.premium,
          value: view.technicalPremiumChecked
            ? newPremiumValue
            : layer.layer.physicalLayer.premium.value,
        },
      })
    }
  }

  updateHasError(): void {
    this.entryHasError = this.layerEntries.some(entry => entry.hasError)
  }

  handleCurveOperation(data: CurveEntryUpdatePayload): void {
    const index = this.layerEntries.findIndex(({ id }) => id === data.entryId)
    if (index >= 0) {
      this.layerEntries.splice(index, 1, data.newEntry)
      this.updateHasError()
      this.checkForValidityAndUpdate(data.newEntry)
      this.dispatchLayerEntryUpdate.emit(data.newEntry)
    }
  }

  resetEntryToInitialValue(entryId: number): void {
    const index = this.initialLayerEntries.findIndex(({ id }) => id === entryId)
    if (index >= 0) {
      const initialEntry = this.initialLayerEntries[index]
      this.layerEntries[index] = initialEntry
      this.updateHasError()
      this.checkForValidityAndUpdate(initialEntry)
      this.dispatchLayerEntryUpdate.emit(initialEntry)
    }
  }

  setEntryToDefault(entryId: number): void {
    const index = this.layerEntries.findIndex(({ id }) => id === entryId)
    if (index >= 0) {
      const item = this.layerEntries[index]
      this.layerEntries[index] = {
        ...item,
        pricingCurves: [PRICING_CURVE_LAYER_TYPE_DEFAULT],
        hasError: false,
        modified: true,
      }
      this.updateHasError()
      this.checkForValidityAndUpdate(this.layerEntries[index])
      this.dispatchLayerEntryUpdate.emit(this.layerEntries[index])
    }
  }
}
