<app-tier-structures
  class="app-elevation-1"
  small
  selectMultiple
  showCloseButton
  elevation="1"
  [title]="dialog"
  [contextPath]="contextPath"
  [routerUrl]="routerUrl"
  [yearID]="yearID"
  [years]="years"
  [years2]="years2"
  [programs]="programs"
  [programs2]="programs2"
  [structureGroups]="structureGroups"
  [structures]="structures"
  [structures2]="structures2"
  [creditStructures]="creditStructures"
  [creditStructureGroups]="creditStructureGroups"
  [compareViews]="compareViews"
  [selectedCompareView]="selectedCompareView"
  [selectedYearID]="selectedYearID"
  [selectedYearID2]="selectedYearID2"
  [selectedClientID]="selectedClientID"
  [selectedClientID2]="selectedClientID2"
  [selectedStructureIDs]="selectedStructureIDs"
  [selectedStructures]="selectedStructures"
  [selectedStructureGroupIDs]="selectedStructureGroupIDs"
  [allowScenarioOrOptimizationSelection]="allowScenarioOrOptimizationSelection"
  [showDialogClone]="showDialogClone"
  [structureFilter]="structureFilter"
  [structureGroupMembers]="structureGroupMembers"
  [groupFilterByAssoc]="groupFilterByAssoc"
  [selectedProgramID]="studyID"
  [selectedProgramID2]="studyID2"
  [studies]="studies"
  [featureFlags]="featureFlags"
  (importLossSetsClick)="onImportLossSetsClick($event)"
  (structureFilterChange)="onStructureFilterChange($event)"
  (structureGroupSelectionChange)="onStructureGroupSelectionChange($event)"
  (structureSelectionChange)="onStructureSelectionChange($event)"
  (compareViewSelectionChange)="onCompareViewSelectionChange($event)"
  (scenarioOrOptimizationSelectChange)="
    onScenarioOrOptimizationSelectChange($event)
  "
  (groupFilterByAssocChange)="onGroupFilterByAssoc($event)"
  (tierChange)="onTierChange($event)"
  (creditStructureCheckChange)="onCreditStructureCheckChange($event)"
  (creditStructureGroupSelectionChange)="
    onCreditStructureGroupSelectionChange($event)
  "
  (closeClick)="onClose()"
></app-tier-structures>
