import { Dictionary } from '@ngrx/entity'
import {
  BenchmarkData,
  BenchmarkDataInfo,
  BenchmarkMetricEstimateInfo,
  DateDatum,
} from '../../../benchmark/model/benchmark-chart'
import { BenchmarkChartView } from '../../../benchmark/model/benchmark-chart-view'
import { BenchmarkLob } from '../../../benchmark/model/benchmark.model'
import { GraphingTimeframeRange } from '@graphing/models/graphing.model'
import { SortTableRow } from '@shared/sort-table/sort-table.model'
import { BenchmarkModeResponse } from '../benchmark.converter'
import { MetricValueFilter } from './aggregate-data'

export type BenchmarkDataURLOptions = BenchmarkChartView & {
  expandPeerSetCompanies?: boolean
}

// Response Data

export interface BenchmarkDatumResponse {
  id: number
  companyId?: string | number
  peerSetId?: string | number
  metricItemId: number
  metricName: string
  fiscalYear?: number
  date?: string
  periodEndDate?: string
  periodTypeId?: number
  dataItemValue: string
  dataItemValue_num?: string | null
  dataItemValue_txt?: string | null
  annualization?: number
  magnitude?: string
  roe?: string
}

// Request for `POST /benchmark/metrics`

export interface BenchmarkMetricEstimate {
  id: string | number
  year: number
}

export interface BenchmarkMetricsRequest {
  type?: BenchmarkModeResponse
  metrics: (string | number)[]
  metricEstimates?: BenchmarkMetricEstimate[]
  entities: (string | number)[]
  lob?: string | number | (string | number)[]
  lobs?: (string | number | number[] | null)[]
  allLinesLob?: number
  states?: string[]
  endYearOffset?: number
  weightUSStatePopulations?: boolean
  lobSet?: string
  topTargetLobCount?: number
  topTargetStateCount?: number
  targetValuesMetricID?: number
  invalidate?: boolean
  includeTerritories?: boolean
  includeCountrywideData?: boolean
  submode?: string
  isCompositeTarget?: boolean
  compositeTargetEntityCount?: number
  timeframe?: number
  rankedPeers?: (string | number)[]
}

export interface BenchmarkEntityRequest {
  entityID?: string
  peerSetID?: number
  name?: string
}

export interface BenchmarkGeoMarketShareRequest
  extends Omit<BenchmarkMetricsRequest, 'entities'> {
  entities: BenchmarkEntityRequest[]
}

export interface BenchmarkFinancialOverviewRequest {
  entityID: string
  endYearOffset?: number
  invalidate?: boolean
  datasetType?: string
}

export interface BenchmarkSpendAndEfficiencyRequest {
  amBestNumber: number
  timeframeGrowth: number
  timeframeLossRatio: number
}

/*******************************************************************/

// Response for `POST /benchmark/metrics`
export type BenchmarkResponse =
  | BenchmarkUSMetricsResponse[]
  | BenchmarkGlobalMetricsResponse[]
  | BenchmarkGeoResponse

export const isBenchmarkUSMetricsResponse = (
  response: BenchmarkResponse
): response is BenchmarkUSMetricsResponse[] =>
  Array.isArray(response) && response.length > 0 && 'companyId' in response[0]

export const isBenchmarkGlobalMetricsResponse = (
  response: BenchmarkResponse
): response is BenchmarkGlobalMetricsResponse[] =>
  Array.isArray(response) && 'instEntityId' in response[0]

/*******************************************************************/

export interface BenchmarkUSMetricsResponse {
  companyId: string | number
  companyMetricList: BenchmarkUSMetricsDatumResponse[]
}

export interface BenchmarkUSMetricsDatumResponse {
  /* Date field, year of data point */
  fiscalYear?: number
  /* ID of the period type (e.g. ID corresponding to Annual, Quarterly, etc.) */
  periodTypeId?: number
  /* Metric values by metric ID per LOB */
  lobMetrics: BenchmarkLOBMetricsResponse[]
}

export interface BenchmarkLOBMetricsResponse {
  /* LOB ID for this set of metrics and values */
  lob: number
  /* Dictionary of metric IDs to metric data value */
  values: BenchmarkLOBMetricsValues
}

export type BenchmarkLOBMetricsValues = Record<
  string,
  number | { metricValue: number; magnitude?: string }
>

/*******************************************************************/

export interface BenchmarkGlobalMetricsResponse {
  instEntityId: string | number
  instEntityMetricList: BenchmarkGlobalMetricsDatumResponse[]
  instEstimateMetricList?: BenchmarkGlobalMetricEstimatesDatumResponse[]
}

export interface BenchmarkGlobalMetricsDatumResponse {
  /* Date field, date of data point */
  periodEndDate: string
  /* ID of the period type (e.g. ID corresponding to Annual, Quarterly, etc.) */
  periodTypeId: number
  /* ID of the metric for this datum */
  metricItemId: string | number
  /* Number of datums per year */
  annualization: number
  /* Data type and magnitude of the value */
  magnitude?: string
  /* Value of the datum */
  dataItemValue_num: string | null
  /* Text value of the datum */
  dataItemValue_txt: string | null
}

export interface BenchmarkGlobalMetricEstimatesDatumResponse {
  /* Year of the metric estimate datum */
  fiscalYear: number
  /* ID of the metric for this datum */
  metricItemId: string | number
  /* Value of the datum */
  dataItemValue: string | number | null
  /* Date the estimate was made */
  asOfDate: string
  numAnalysts: number
  numAnalystsUp: number
  numAnalystsDown: number
  numAnalystsNoChange: number
}

/*******************************************************************/

export interface BenchmarkGeoDatumResponse {
  lob: number
  mId: number
  val: number
  mag: string
  state: string
}

export interface BenchmarkGeoYearResponse {
  year: number
  metrics: BenchmarkGeoDatumResponse[]
}

export interface BenchmarkGeoCompanyResponse {
  companyId: string | number
  years: BenchmarkGeoYearResponse[]
}

export interface BenchmarkGeoResponse {
  data: BenchmarkGeoCompanyResponse[]
  lobValues?: Record<number, number> | null
  stateValues?: Record<string, number> | null
  lobs: number[]
  states: string[]
}

export interface BenchmarkGeoMarketShareRowResponse {
  id: string
  rank?: number
  entityID: string
  entityName: string
  dpw: number
  marketShare: number
  lr: number
  lr5yr: number
}

export interface BenchmarkGeoMarketShareResponse {
  rowsPerLobSet: BenchmarkGeoMarketShareRowResponse[][]
  targetValuesStates: Array<{ state: string; value: number }>
  targetValuesLobs: Array<{ lob: number; value: number }>
  endYear: number
  lobOverrides?: (number | null)[]
}

export interface BenchmarkFinancialOverviewTableColumnDefResponse {
  id: string
  label: string
  valueType?: 'numeric' | 'percentage'
  decimals?: number
}

export interface BenchmarkFinancialOverviewTableResponse {
  name: string
  columnDefs: BenchmarkFinancialOverviewTableColumnDefResponse[]
  rows: SortTableRow[]
}

export interface BenchmarkFinancialOverviewResponse {
  endYear: number
  tables: BenchmarkFinancialOverviewTableResponse[]
}

// Parsed Data

export type BenchmarkDataByMetric = Partial<BenchmarkMetricEstimateInfo> & {
  entityID?: string | number
  date: Date
  namedCatastrophe?: string
  metrics: Record<string | number, number>
  metricsByLob?: Record<number, Record<string | number, number>>
  metricsByState?: Record<string, Record<string | number, number>>
  metricsByStateLob?: Record<string, Record<string | number, number>>
}

export type BenchmarkDataByDateMetric = Record<string, BenchmarkDataByMetric>

export type BenchmarkDataByCompanyDateMetric = Record<
  string | number,
  BenchmarkDataByDateMetric
>

export interface BenchmarkQuantileDatum {
  date: Date
  p25?: number
  p50?: number
  p75?: number
}

export type BenchmarkDataDictResult = Omit<BenchmarkDataInfo, 'dateRange'> & {
  dataDict: BenchmarkDataByCompanyDateMetric
  dateRange: GraphingTimeframeRange
  dateRangePerChart: GraphingTimeframeRange[]
  lobs?: Dictionary<BenchmarkLob>
}

export type BenchmarkDataParseState = BenchmarkData & BenchmarkDataDictResult

export interface BenchmarkParseContext
  extends Omit<BenchmarkDataDictResult, 'dateRangePerChart'> {
  view: BenchmarkChartView
  metricDictConverter: (
    dataByMetric: BenchmarkDataByMetric | BenchmarkDataByMetric[]
  ) => WithIssues<DateDatum>
  timeframeTransform: (data: (DateDatum | null)[]) => (DateDatum | null)[]
  metricValueFilter?: MetricValueFilter
}

export interface WithIssues<T> {
  value: T
  issues?: string[]
}

// Time Series Category models

// TODO: Change to match backend response
export interface BenchmarkTimeSeriesCategoryDataResponseDatum {
  date: string
  ticker?: string
  companyName: string
  institutionId: string | number
  category: string
  rateChange: number
  reportedSegment?: string
  size?: number
  subline1?: string
  subline2?: string
  subline3?: string
}

export interface BenchmarkTimeSeriesCategoryDataResponse {
  data: BenchmarkTimeSeriesCategoryDataResponseDatum[]
}

export const BENCHMARK_ALL_ITEMS_ID = '__all'
