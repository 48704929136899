<app-group-compare-header
  [name]="name"
  [disableSave]="!dirty"
  [hideSave]="hideSave"
  [activeAction]="activeAction"
  (saveClick)="saveClick.emit()"
>
</app-group-compare-header>
<main>
  <div class="content">
    <div class="toolbar">
      <div class="left" [class.inurance]="!showAddMenu">
        <app-tier-structures-menu
          *ngIf="showAddMenu"
          isButton
          [analysisProfileID]="analysisProfileID"
          [yearID]="yearID"
          [years]="years"
          [structureGroups]="programGroups"
          [structures]="programs"
          [compareViews]="compareViews"
          [selectedCompareView]="selectedCompareView"
          [selectedYearID]="selectedYearID"
          [selectedClientID]="selectedClientID"
          [selectedStructureIDs]="programIDs"
          [selectedStructureGroupIDs]="selectedProgramGroupIDs"
          [allowScenarioOrOptimizationSelection]="
            allowScenarioOrOptimizationSelection
          "
          [contextPath]="['analysis', name.toLowerCase()]"
          [structureFilter]="structureFilter"
          [structureGroupMembers]="programGroupMembers"
          [groupFilterByAssoc]="groupFilterByAssoc"
          [studyID]="studyID"
          [programs]="studies"
          [selectedProgramID]="selectedProgramID"
          [accountOpportunities]="accountOpportunities"
          (structureFilterChange)="onStructureFilterChange($event)"
          (structureAdd)="programAdd.emit($event)"
          (structureRemove)="programRemove.emit($event)"
          (structureGroupAdd)="programGroupAdd.emit($event)"
          (structureGroupRemove)="onProgramGroupRemove($event)"
          (updateSelectedCompareView)="onUpdateSelectedCompareView($event)"
          (groupFilterByAssocChange)="onGroupFilterByAssocChange($event)"
        ></app-tier-structures-menu>
        <div class="actions">
          <app-group-inurance-bar-container *ngIf="showInuranceBar">
          </app-group-inurance-bar-container>
          <button
            *ngIf="showSharedLimitAddButton"
            appButton
            big
            translucent
            [disabled]="dirty"
            (click)="!dirty && addSharedLayerClick.emit()"
          >
            <mat-icon inline>add</mat-icon>
            <span>Add Shared Limit...</span>
          </button>
        </div>
        <div *ngIf="programIDs.length > 0">
          <div *ngIf="name === 'Group' || 'Compare'">
            <div class="currency-input">
              <label class="compare-currency" *ngIf="name === 'Group'">Group Metric Currency</label>
              <label class="compare-currency" *ngIf="name === 'Compare'">
                Compare Metric Currency
              </label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    type="text"
                    matInput
                    [formControl]="currencyControl"
                    [matAutocomplete]="currencyCtrl"
                    placeholder="Currency"
                  />
                  <mat-autocomplete
                    #currencyCtrl="matAutocomplete"
                    (optionSelected)="
                      getSelectedCurrency($event.option.value.code)
                    "
                    [displayWith]="displayFn"
                  >
                    <mat-option [value]="{ code: 'Default' }">
                      Default
                    </mat-option>
                    <mat-option
                      *ngFor="let currency of filteredCurrencyList"
                      [value]="currency"
                    >
                      {{ currency.code }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
            </div>
            <div *ngIf="name === 'Compare'" class="precision-input">
              <label> Precision </label>
              <button appButton (click)="onPrecisionButtonClick('-')">
                <span>&#8592;</span>
              </button>
              <button appButton (click)="onPrecisionButtonClick('+')">
                <span>&#8594;</span>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="programIDs.length > 0">
          <div *ngIf="name === 'Compare'">
            <div>
              <mat-form-field appearance="fill" class="select" subscriptSizing="dynamic">
                <mat-select
                  [(value)]="defaultConversion"
                  (selectionChange)="getSelectedConversion($event.value)"
                >
                  <mat-option
                    *ngFor="let re1 of currencyValues"
                    [value]="re1"
                    [matTooltip]="re1"
                    matTooltipClass="metric-tooltip"
                    matTooltipShowDelay="250"
                    matTooltipPosition="after"
                  >
                    <span [ngClass]="{ 'fot-label': re1 === 'FOT MASTER' }">{{
                      re1
                    }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="programIDs.length > 0 && tabSelected === 0" class="right">
        <div *ngIf="name === 'Compare'" class="compare-view">
          <div class="view-name">
            <div>
              <mat-form-field
                [ngClass]="{ display: 'unset' }"
              >
                <mat-label>View Name</mat-label>
                <input
                  matInput
                  [(ngModel)]="compareViewName"
                  (ngModelChange)="onCompareViewNameChange()"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="action-buttons">
            <button
              appButton
              big
              accent
              *ngIf="compareView"
              (click)="onDeleteCompareViewClick()"
            >
              <span>Delete View</span>
            </button>
          </div>
          <div *ngIf="compareView" class="action-buttons">
            <button
              appButton
              big
              accent
              [disabled]="saveButtonDisabled"
              (click)="onSaveCompareViewClick()"
            >
              <span>Save View</span>
            </button>
          </div>
          <div class="action-buttons">
            <button
              appButton
              big
              accent
              (click)="onSaveAsCompareViewClick()"
              matTooltip="Save As Successful"
              #myTooltip="matTooltip"
              matTooltipPosition="above"
              [matTooltipDisabled]="tooltipDisabled"
            >
              <span>Save As</span>
            </button>
          </div>
        </div>
        <div [matMenuTriggerFor]="menu">
          <button appButtonIcon huge opaque class="export">
            cloud_download
          </button>
          <!-- <mat-icon inline>cloud_download</mat-icon> -->
        </div>

        <mat-menu #menu="matMenu">
          <button
            *ngIf="name === 'Group'"
            mat-menu-item
            (click)="export.emit('pdf')"
          >
            Export as PDF
          </button>
          <button
            *ngIf="name === 'Compare'"
            mat-menu-item
            (click)="export.emit('xlsx')"
          >
            Export as Excel
          </button>
        </mat-menu>
        <ng-container *ngIf="programIDs.length > 1">
          <label class="zoom">Zoom</label>
          <!-- TODO: The 'tickInterval' property no longer exists -->
          <mat-slider
            min="1"
            [max]="slidesPerViewMax"
            step="1"
            thumbLabel="false"
            [ngModel]="slidesPerViewMax + 1 - slidesPerView"
           #ngSlider><input matSliderThumb (change)="onSlidesPerViewChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" /></mat-slider>
        </ng-container>
      </div>
    </div>
    <label *ngIf="showEmptySelectedLabel" class="empty" appInfoText big>
      Please select a Structure or Structure Group to begin.
    </label>
    <app-compare-tabs-component
      *ngIf="name === 'Compare'"
      id="comparePage1"
      [tabSelected]="tabSelected"
      [precision]="precision"
      [compareCurrency]="compareCurrency"
      (programAdd)="onProgramAddCompare($event)"
      (programRemove)="onProgramRemoveCompare($event)"
      (tabIndex)="tabIndex.emit($event)"
    ></app-compare-tabs-component>
    <app-group-towers-container
      *ngIf="name === 'Group'"
      id="groupPage"
      [analysisProfileID]="analysisProfileID"
      [dirty]="dirty"
      [sharedLimitMode]="sharedLimitMode"
      [sharedLimitLayers]="sharedLimitLayers"
      [inuranceMode]="inuranceMode"
      [allowDelete]="allowDelete"
      [nextInuranceSymbol]="nextInuranceSymbol"
      [programGroups]="programGroups"
      [clientProgramGroupMembers]="clientProgramGroupMembers"
      (programRemove)="onProgramRemoveGroup($event)"
      (saveClick)="saveClick.emit()"
      [sharedLayers]="sharedLayers"
    ></app-group-towers-container>
  </div>
  <app-properties-panel
    *ngIf="showSharedLimitPanel"
    class="props app-elevation-1"
    [ngClass]="{
      closed: !true,
      hover: propertiesPanelToggleHover
    }"
    [id]="id"
    [selectedViewID]="selectedViewID"
    [activeAction]="sharedLimitActiveAction"
    [sharedLimitAddLayers]="sharedLimitAddLayers"
    [isAddSharedLayer]="sharedLimitMode === 'new'"
    [isDeleteSharedLayer]="sharedLimitMode === 'delete'"
    [sharedLimitProperties]="sharedLimitProperties"
    [designDirty]="dirty"
    [isLibRE]="isLibRE"
    (mouseover)="propertiesPanelToggleHover = true"
    (mouseout)="propertiesPanelToggleHover = false"
    (click)="onPropertiesPanelClick($event)"
    (cancelClick)="onCancelClick()"
    (saveSharedLimitClick)="onSaveSharedLimitClick()"
    (selectedSLCurrency)="getSelectedSLCurrency($event)"
  ></app-properties-panel>
</main>
